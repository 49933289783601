import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import Checkbox from '../../../../Components/Checkbox';
import ColorInput from '../../../../Components/ColorInput';
import { updateBotdetails } from '../../../../Features/configure/configureSlice';
import {
	setAIMessageBubbleColor,
	setAIMessageTextColor,
	setResponseLevelFeedBackBckColor,
	setResponseLevelFeedBackBckRange,
	setResponseLevelFeedBackBorderColor,
	setResponseLevelFeedBackBorderRange,
	setResponseLevelFeedBackIconColor,
	setShowAvatar,
	setTimeStampBckColor,
	setTimeStampBckRange,
	setTimeStampTextColor,
	setUserComment,
	setUserFeedback,
	setUserMessageBubbleColor,
	setUserMessageTextColor,
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import './Messages.scss';
import Slider from '../../../../assets/images/Slider.png';

const Messages = () => {
	const {
		userMessageBubbleColor,
		userMessageTextColor,
		aiMessageBubbleColor,
		aiMessageTextColor,
		showavatar,
		timeStampBckColor,
		timeStampTextColor,
		timeStampBckRange,
		timeStampTextRange,
		responseLevelFeedBackBckColor,
		responseLevelFeedBackBckRange,
		responseLevelFeedBackBorderColor,
		responseLevelFeedBackBorderRange,
		responseLevelFeedBackIconColor,
		userComment,
		userFeedBack,
	} = useSelector((state: RootState) => state.experienceonweb);

	const dispatch = useAppDispatch();
	return (
		<div className='ff-eow-messages'>
			<div className='ai-messages'>
				<h5>AI Messages</h5>
				<p className='body1'>Message background color</p>
				<div
					className='color-input-div'
					style={{
						backgroundColor: aiMessageBubbleColor,
					}}>
					<ColorInput
						className='color-input'
						type='color'
						value={aiMessageBubbleColor}
						onValueChange={(value) => {
							dispatch(setAIMessageBubbleColor(value));
						}}
					/>
				</div>
				<p className='body1 text-color'>Message text color</p>
				<div
					className='color-input-div'
					style={{
						backgroundColor: aiMessageTextColor,
					}}>
					<ColorInput
						className='color-input'
						type='color'
						value={aiMessageTextColor}
						onValueChange={(value) => {
							dispatch(setAIMessageTextColor(value));
						}}
					/>
				</div>
				<hr />
				<div className='checkbox-messages'>
					<Checkbox
						label={<p className='body1'>Show the avatar with AI’s messages</p>}
						isChecked={showavatar}
						index={0}
						onValueChange={(val) => dispatch(setShowAvatar(val))}
					/>
				</div>
			</div>
			<div className='user-messages'>
				<h5>User Messages</h5>
				<p className='body1'>Message background color</p>
				<div
					className='color-input-div'
					style={{
						backgroundColor: userMessageBubbleColor,
					}}>
					<ColorInput
						className='color-input'
						type='color'
						value={userMessageBubbleColor}
						onValueChange={(value) => {
							dispatch(setUserMessageBubbleColor(value));
						}}
					/>
				</div>
				<p className='body1 text-color'>Message text color</p>
				<div
					className='color-input-div'
					style={{
						backgroundColor: userMessageTextColor,
					}}>
					<ColorInput
						className='color-input'
						type='color'
						value={userMessageTextColor}
						onValueChange={(value) => {
							dispatch(setUserMessageTextColor(value));
						}}
					/>
				</div>
			</div>
			<div className='timestamp-messages'>
				<h5>Timestamp</h5>
				<p className='body1'>Background color</p>
				<div className='color-input-with-opacity'>
					<div
						className='color-input-div'
						style={{
							backgroundColor: timeStampBckColor,
						}}>
						<ColorInput
							className='color-input'
							type='color'
							value={timeStampBckColor}
							onValueChange={(value) => {
								dispatch(setTimeStampBckColor(value));
							}}
						/>
					</div>
					<div>
						<p className='body2'>Opacity</p>
						<div className='opacity'>
							<ColorInput
								className='range-input'
								style={{
									background: `linear-gradient(to left, ${timeStampBckColor} 0%, white 100%), url(${Slider})`,
									backgroundBlendMode: 'multiply',
								}}
								type='range'
								value={timeStampBckRange}
								min={0}
								max={100}
								onValueChange={(value) => dispatch(setTimeStampBckRange(value))}
							/>
							<ColorInput
								className='number-input'
								type='number'
								value={timeStampBckRange}
								min={0}
								max={100}
								onValueChange={(value) => dispatch(setTimeStampBckRange(value))}
							/>
							<p>%</p>
						</div>
					</div>
				</div>
				<p className='body1 text-color'>Timestamp text color</p>
				<div
					className='color-input-div'
					style={{
						backgroundColor: timeStampTextColor,
					}}>
					<ColorInput
						className='color-input'
						type='color'
						value={timeStampTextColor}
						onValueChange={(value) => {
							dispatch(setTimeStampTextColor(value));
						}}
					/>
				</div>
			</div>
			<div className='message-feedback'>
				<h5>Message Feedback</h5>
				<Checkbox
					isChecked={userFeedBack}
					label='Show option to give feedback for each AI message'
					index={0}
					onValueChange={(val) => {
						dispatch(setUserFeedback(val));
					}}
				/>
				{userFeedBack[0] ? (
					<>
						<Checkbox
							isChecked={userComment}
							label='Collect user comments with feedback'
							index={0}
							onValueChange={(val) => dispatch(setUserComment(val))}
						/>

						<hr />
						<p className='body1'>Background color</p>
						<div className='color-input-with-opacity'>
							<div
								className='color-input-div'
								style={{
									backgroundColor: responseLevelFeedBackBckColor,
								}}>
								<ColorInput
									className='color-input'
									type='color'
									value={responseLevelFeedBackBckColor}
									onValueChange={(value) =>
										dispatch(setResponseLevelFeedBackBckColor(value))
									}
								/>
							</div>
							<div>
								<p className='body2'>Opacity</p>
								<div className='opacity'>
									<ColorInput
										className='range-input'
										style={{
											background: `linear-gradient(to left, ${responseLevelFeedBackBckColor} 0%, white 100%), url(${Slider})`,
											backgroundBlendMode: 'multiply',
										}}
										type='range'
										value={responseLevelFeedBackBckRange}
										min={0}
										max={100}
										onValueChange={(value) =>
											dispatch(setResponseLevelFeedBackBckRange(value))
										}
									/>
									<ColorInput
										className='number-input'
										type='number'
										value={responseLevelFeedBackBckRange}
										min={0}
										max={100}
										onValueChange={(value) =>
											dispatch(setResponseLevelFeedBackBckRange(value))
										}
									/>
									<p>%</p>
								</div>
							</div>
						</div>
						<p className='body1 text-color'>Icon color</p>
						<div
							className='color-input-div'
							style={{
								backgroundColor: responseLevelFeedBackIconColor,
							}}>
							<ColorInput
								className='color-input'
								type='color'
								value={responseLevelFeedBackIconColor}
								onValueChange={(value) =>
									dispatch(setResponseLevelFeedBackIconColor(value))
								}
							/>
						</div>
						<p className='body1'>Border color</p>
						<div className='color-input-with-opacity'>
							<div
								className='color-input-div'
								style={{
									backgroundColor: responseLevelFeedBackBorderColor,
								}}>
								<ColorInput
									className='color-input'
									type='color'
									value={responseLevelFeedBackBorderColor}
									onValueChange={(value) =>
										dispatch(setResponseLevelFeedBackBorderColor(value))
									}
								/>
							</div>
							<div>
								<p className='body2'>Opacity</p>
								<div className='opacity'>
									<ColorInput
										className='range-input'
										style={{
											background: `linear-gradient(to left, ${responseLevelFeedBackBorderColor} 0%, white 100%), url(${Slider})`,
											backgroundBlendMode: 'multiply',
										}}
										type='range'
										value={responseLevelFeedBackBorderRange}
										min={0}
										max={100}
										onValueChange={(value) =>
											dispatch(setResponseLevelFeedBackBorderRange(value))
										}
									/>
									<ColorInput
										className='number-input'
										type='number'
										value={responseLevelFeedBackBorderRange}
										min={0}
										max={100}
										onValueChange={(value) =>
											dispatch(setResponseLevelFeedBackBorderRange(value))
										}
									/>
									<p>%</p>
								</div>
							</div>
						</div>
					</>
				) : null}
			</div>
		</div>
	);
};

export default Messages;
