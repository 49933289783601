import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Chip from '../../../../Components/Chip';
import './CRMCard.scss';
import { EnabledTick } from '../../../../assets/Icons';
import InteractiveButton from '../../../../Components/Button/InteractiveButton';
import Menu, { Options } from '../../../../Components/Menu';
import { useEffect, useState } from 'react';
import { CardMenuIcon } from '../../../../Pages/MyStudio/icon';
import { loadState, saveState } from '../../../../utils/utils';
type Props = {
	crmname: string;
	icon: string;
	cardname: string;
	status: string;
	index: number;
	type: string;
};

const CRMCard = ({ crmname, icon, cardname, status, index, type }: Props) => {
	const navigate = useNavigate();
	const path = useLocation().pathname;
	const {
		leadSquaredData,
		statusData,
		freshChatLinked,
		zohoData,
		emailData,
		wooCommData,
		salesforceData,
	} = useSelector((state: RootState) => state.teamsetting);
	const { leadAllowed } = useSelector((state: RootState) => state.subscription);
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [options, setOptions] = useState<Array<Options>>([]);
	const [onHover, setHover] = useState(-1);
	const [isDisable, setDisable] = useState(false);
	const enableSelect = () => {
		if (isDisable) {
			navigate('error-subscription')
		}
		else {
			if (cardname === 'Email') {
				navigate('email');
			} else if (cardname === 'Shopify') {
				navigate('/app/Ingest/link-product/link-platform/shopify');
			} else if (cardname === 'LeadSquared') {
				navigate('link-leadsquared');
			} else if (cardname === 'Freshchat') {
				navigate('freshchat/link');
			} else if (cardname === 'Zoho') {
				navigate('zoho');
			} else if (cardname === 'Salesforce') {
				navigate('salesforce');
			} else if (cardname.toLowerCase() === 'woocommerce') {
				navigate('/app/Ingest/link-product/link-platform/woocommerce');
			} else if (cardname.toLowerCase() === 'flyfish store') {
				navigate('/app/Ingest/link-product/link-platform/flyfish');
			} else {
				let crmId = -1;
				if (cardname === 'HubSpot') {
					crmId = 0;
				} else if (cardname === 'Google Sheets') {
					crmId = 3;
				}
				let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,

      width=600,height=300,left=600,top=300`;
				// tenantId &&
				// 	window.open(
				// 		`${window.location.origin}/oauth/v1/setup?crmId=${CRM_Integration[crmId].encryptCRMId}&userId=${user.encryptedUserId}&tenantId=${tenantId}`,
				// 		'integrating',
				// 		params
				// 	);
			}
		}
	};
	useEffect(() => {

		if (loadState('leadAllowed') && loadState('leadAllowed').length === 1 && loadState('leadAllowed')[0].toLowerCase === 'email' && (crmname === 'leadsquared' || crmname === 'salesforce' || crmname === 'zoho')) {
			let value = loadState('leadAllowed') && loadState('leadAllowed').filter((crmItem: any) => crmItem.toLowerCase().indexOf(crmname.toLowerCase()) > -1).length
			setDisable(value > 0 ? false : true)
		}
		if (loadState('leadAllowed') && loadState('leadAllowed').length === 1 && loadState('leadAllowed')[0] === '' && (crmname.toLowerCase() === 'email' || crmname === 'leadsquared' || crmname === 'salesforce' || crmname === 'zoho')) {
			let value = loadState('leadAllowed') && loadState('leadAllowed').filter((crmItem: any) => crmItem.toLowerCase().indexOf(crmname.toLowerCase()) > -1).length
			setDisable(value > 0 ? false : true)
		}
	}, [crmname])
	useEffect(() => {
		console.log(crmname, isDisable)
	}, [isDisable])

	const handleSetOpen = () => {
		open ? setOpen(false) : setOpen(true);
		let opt = [
			{
				label: <p className='menu-label'>Disable</p>,
				onClick: () => {
					setOpen(false);
					if (crmname === 'shopify') {
						navigate('unlink-shopify');
					} else if (crmname === 'freshchat') {
						navigate('freshchat/unlink');
					} else if (crmname === 'zoho') {
						navigate('unlink-zoho');
					} else if (crmname === 'email') {
						navigate('unlink-email');
					} else if (crmname === 'leadsquared') {
						navigate('unlink-leadsquared');
					} else if (crmname === 'hubspot') {
						// navigate('unlink-hubspot'); not available for hubspot
					} else if (crmname === 'google-sheets') {
						// navigate('unlink-google-sheets'); not available for google-sheets
					} else if (crmname === 'salesforce') {
						navigate('unlink-salesforce');
					}
				},
			},
		];
		// if (crmname === 'zoho') {
		// 	opt.push({
		// 		label: <p className='menu-label'>Modify Permissions Scope</p>,
		// 		onClick: () => {
		// 			setOpen(false);
		// 			navigate('zoho-scope-modify');
		// 		},
		// 	});
		// }
		setOptions(opt);
	};

	const disableButton = () => {
		return (
			<>
				<div className='disable-button'>
					<InteractiveButton
						//icon={<img src={ThreeDots} alt='' />}
						icon={<CardMenuIcon width='25' />}
						onClick={(event) => {
							event.stopPropagation();
							setOpen(true);
							setAnchorEl(event.target as HTMLElement);
							handleSetOpen();
						}}
						hoverable='ring'
					/>
				</div>
			</>
		);
	};

	return (

		<>
			<div
				className={`ff-crmcard ${crmname} ${isDisable ? 'disable' : ''}`}
				onMouseOver={() => {
					setHover(index);
				}}
				onMouseLeave={() => {
					setHover(-1);
				}}>
				<div>
					{type === 'svg' ? (
						icon
					) : (
						<img src={icon} alt='' className={`${crmname}`} />
					)}
					<div className={`${status}`}>
						<span className='body1'>{cardname}</span>
					</div>
					{status === 'in-use' ? (
						crmname === 'leadsquared' ? (
							<>
								{leadSquaredData.active ? (
									<Chip
										label='ENABLED'
										icon={
											<EnabledTick hovered={onHover == index ? true : false} />
										}
									/>
								) : (
									<Button
										buttonType='light'
										variant='outlined'
										onClick={enableSelect}>
										Enable
									</Button>
								)}
							</>
						) : crmname === 'shopify' ? (
							<>
								{statusData &&
									statusData.serviceType.toLowerCase() === 'shopify' &&
									statusData.status.toLowerCase() === 'success' ? (
									<Chip
										label='ENABLED'
										icon={
											<EnabledTick hovered={onHover == index ? true : false} />
										}
									/>
								) : (
									<Button
										buttonType='light'
										variant='outlined'
										onClick={enableSelect}>
										{path.indexOf('sales-ai') > -1
											? 'Import Catalog'
											: 'Enable'}
									</Button>
								)}
							</>
						) : crmname === 'zoho' ? (
							<>
								{zohoData.active ? (
									<Chip
										label='ENABLED'
										icon={
											<EnabledTick hovered={onHover == index ? true : false} />
										}
									/>
								) : (
									<Button
										buttonType='light'
										variant='outlined'
										onClick={enableSelect}>
										Enable
									</Button>
								)}
							</>
						) : crmname === 'woocommerce' ? (
							<>
								{statusData &&
									statusData.serviceType.toLowerCase() === 'woocommerce' &&
									statusData.status.toLowerCase() === 'success' ? (
									<Chip
										label='ENABLED'
										icon={
											<EnabledTick hovered={onHover == index ? true : false} />
										}
									/>
								) : (
									<Button
										buttonType='light'
										variant='outlined'
										onClick={enableSelect}>
										Enable
									</Button>
								)}
							</>
						) : crmname === 'email' ? (
							<>
								{emailData.active ? (
									<>
										<Chip
											label='ENABLED'
											icon={
												<EnabledTick
													hovered={onHover == index ? true : false}
												/>
											}
										/>
									</>
								) : (
									<Button
										buttonType='light'
										variant='outlined'
										onClick={enableSelect}>
										Enable
									</Button>
								)}
								<p className='crm-emailId caption'>
									{emailData.crmUserId ? emailData.crmUserId : null}
								</p>
							</>
						) : crmname === 'freshchat' ? (
							<>
								{freshChatLinked ? (
									<>
										<Chip
											label='ENABLED'
											icon={
												<EnabledTick
													hovered={onHover == index ? true : false}
												/>
											}
										/>
									</>
								) : (
									<Button
										buttonType='light'
										variant='outlined'
										onClick={enableSelect}>
										Enable
									</Button>
								)}
							</>
						) : crmname === 'salesforce' ? (
							<>
								{salesforceData.active ? (
									<>
										<Chip
											label='ENABLED'
											icon={
												<EnabledTick
													hovered={onHover == index ? true : false}
												/>
											}
										/>
									</>
								) : (
									<Button
										buttonType='light'
										variant='outlined'
										onClick={enableSelect}>
										Enable
									</Button>
								)}
							</>
						) : (
							<Button
								buttonType='light'
								variant='outlined'
								onClick={enableSelect}>
								Enable
							</Button>
						)
					) : (
						<div className='coming-soon-integration'>
							<Chip label='Coming soon' />
						</div>
					)}
				</div>
				{crmname === 'shopify' &&
					statusData &&
					statusData.serviceType.toLowerCase() === 'shopify' &&
					statusData.status.toLowerCase() === 'success'
					? disableButton()
					: null}
				{crmname === 'woocommerce' &&
					statusData &&
					statusData.serviceType.toLowerCase() === 'woocommerce' &&
					statusData.status.toLowerCase() === 'success'
					? disableButton()
					: null}
				{crmname === 'zoho' && zohoData.active ? disableButton() : null}
				{crmname === 'freshchat' && freshChatLinked ? disableButton() : null}
				{crmname === 'email' && emailData.active ? disableButton() : null}
				{crmname === 'leadsquared' && leadSquaredData.active
					? disableButton()
					: null}
				{crmname === 'salesforce' && salesforceData.active
					? disableButton()
					: null}

				{/* {crmname === 'salesforce' && emailData.active ? disableButton() : null}
				{crmname === 'hubspot' && emailData.active ? disableButton() : null}
				{crmname === 'google-sheets' && emailData.active
					? disableButton()
					: null} */}

				<Menu
					anchorEl={anchorEl}
					open={open}
					setOpen={setOpen}
					options={options}
				/>
				{/* {zohoData.active || linkState.status === "success"
          ? disableButton()
          : null}
        <Menu
          anchorEl={anchorEl}
          open={open}
          setOpen={setOpen}
          options={options}
        /> */}
				<div className={`overlay ${crmname}`}></div>
			</div>
		</>
	);
};

export default CRMCard;
