import React, { useEffect, useState } from 'react';
import {
	AnalyticsDialog,
	MiniCardData,
} from '../../../Features/Analytics/analyticsSlice';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import { getValuewithK } from '../../../utils/utils';
import './dialogminicard.scss';
import {
	Conversations,
	ViewAllTemplatesArrow,
	Dialogminitimescompleted,
	DialogminicompletionRate,
} from '../../../assets/Icons';
import { AnalyticsDecreasePercent, AnalyticsIncreasePercent } from '../icon';

export type MiniCardsObjectType = {
	heading: string;
	data: any;
	dataChange: any;
	onClick?: Function | null;
	icon: JSX.Element;
};

function DialogMinicards() {
	const [miniCardsData, setMiniCardsData] = React.useState<
		MiniCardsObjectType[]
	>([]);

	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { activeBot } = useSelector((state: RootState) => state.bots);
	const {
		selectedDates,
		calendarstatus,
		selectedPreviousDates,
		selectedTriggeredQuery,
		analyticsDialog,
		analyticsoldDialog,
		activeAnalyticsBot,
	} = useSelector((state: RootState) => state.analytics);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (calendarstatus && tenantId) {
			dispatch(
				AnalyticsDialog({
					startdate: selectedPreviousDates[0],
					enddate: selectedPreviousDates[1],
					timezone: -330,
					tenantId: tenantId,
					botId: activeAnalyticsBot.botId,
					isPreviousData: true,
				})
			);
			dispatch(
				AnalyticsDialog({
					startdate: selectedDates[0],
					enddate: selectedDates[1],
					timezone: -330,
					tenantId: tenantId,
					botId: activeAnalyticsBot.botId,
				})
			);
		}
		getMiniCardsData();
	}, [selectedDates, selectedPreviousDates]);

	useEffect(() => {
		document.querySelector('.ff_analytics_container')?.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);
	const getMiniCardsData = () => {
		// if (analyticsoldDialog.data.length !== 0) {
		// if(analyticsoldDialog.)

		//below analyticsoldDialog should come from backend api

		const analyticsoldDialog = {
			total: null,
			data: [
				{
					wfStart: 80,
					wfEnd: 95,
					wfId: 5968,
					triggerQuery: 'Test - 1',
				},
				{
					wfStart: 29,
					wfEnd: 88,
					wfId: 7297,
					triggerQuery: 'Test - 2',
				},
				{
					wfStart: 87,
					wfEnd: 10,
					wfId: 5474,
					triggerQuery: 'Test - 3',
				},
				{
					wfStart: 87,
					wfEnd: 10,
					wfId: 5474,
					triggerQuery: 'Test - 4',
				},
			],
		};
		//commenting newworkflowCompletionData for now for dummy data

		// const newworkflowCompletionData = analyticsoldDialog?.data.map(
		// 	(item: any, i: number) => {
		// 		let objectWithOldSufix = Object.keys(item).reduce(
		// 			(a, c) => ({ ...a, [c + 'Old']: item[c] }),
		// 			{}
		// 		);
		// 		return { ...objectWithOldSufix, ...analyticsDialog.data[i] };
		// 	}
		// );

		//for temporary we are assining direct data to newworkflowCompletionData to display dummy data
		const newworkflowCompletionData = analyticsoldDialog.data;
		let newDialogData: any = [];
		newworkflowCompletionData.length !== 0 &&
			newworkflowCompletionData?.map((item: any, key: any) => {
				newDialogData.push({
					triggeredQueryId: item.wfId,
					timesCompleted: item.wfEnd,
					triggeredQuery: item.triggerQuery,
					triggeredQueryCount: item.wfStart,
					completeRate:
						item.wfEnd === 0 || item.wfStart === 0
							? 0
							: ((item.wfEnd / item.wfStart) * 100).toFixed(2),
					triggeredQueryIdold: item.wfIdOld,
					timesCompletedold: item.wfEndOld,
					triggeredQueryold: item.triggerQueryOld,
					triggeredQueryCountold: item.wfStartOld,
					completeRateold:
						item.wfEnd === 0 || item.wfStart === 0
							? 0
							: ((item.wfEndOld / item.wfStartOld) * 100).toFixed(2),
				});
			});
		let triggeredQueryData = newDialogData.filter(
			(item: any) =>
				item.triggeredQueryId == selectedTriggeredQuery.triggeredQueryId
		);

		const minicardData = [
			{
				heading: 'Times trigerred',
				data: getValuewithK(triggeredQueryData[0]?.triggeredQueryCount),
				dataChange: getChangePercent(
					triggeredQueryData[0]?.triggeredQueryCount,
					triggeredQueryData[0]?.triggeredQueryCountold
				),
				icon: <Conversations />,
				onClick: () => {},
			},
			{
				heading: 'Times completed',
				data: getValuewithK(triggeredQueryData[0]?.timesCompleted),
				dataChange: getChangePercent(
					triggeredQueryData[0]?.timesCompleted,
					triggeredQueryData[0]?.timesCompletedold
				),
				icon: <Dialogminitimescompleted />,
				onClick: () => {},
			},
			{
				heading: 'Completion rate for dialog',
				data: triggeredQueryData[0]?.completeRate + '%',
				dataChange: getChangePercent(
					triggeredQueryData[0]?.completeRate,
					triggeredQueryData[0]?.completeRateold
				),
				icon: <DialogminicompletionRate />,
				onClick: () => {},
			},
		];
		setMiniCardsData(minicardData);
		// }
	};

	const getChangePercent = (num1: number, num2: number) => {
		const percent =
			num1 !== 0 && num2 !== 0
				? ((num1 - num2) / num2 !== 0 ? (num1 - num2) / num2 : 1) * 100
				: '';
		const [T, H] = percent.toString().split('.');
		const value = H !== undefined ? [T, H[0]].join('.') : T;

		return value;
	};

	return (
		<div>
			<div className='ff_Analytics_miniCards_dialog'>
				{miniCardsData.map((card) => {
					return (
						<div
							className=' ff_Analytics_miniCard_dialog'
							style={{
								backgroundColor:
									card.heading === 'Times trigerred'
										? '#fbf1fe'
										: card.heading === 'Times completed'
										? '#edf1ff'
										: '#e7f0ee',
							}}>
							<div className='ff_analytics_card_title_dialog'>
								<div className={'ff_Analytics_miniCard_icon_dialog '}>
									{card.icon}
								</div>
								<p className='ff_analytics_minicard_heading_dialog body2'>
									{card.heading}
								</p>
							</div>
							<div className='ff_analytics_check_count_percent'>
								<div className='ff_analytic_data_count_dialog'>
									<h3 className='ff_analytics_count_dialog'>
										{card.data === 'undefined%' ? 0 : card.data}
									</h3>
								</div>
								<div className='ff_check_percent caption'>
									{card.dataChange < 0 ? (
										<div style={{ marginRight: '8px' }}>
											<AnalyticsDecreasePercent />
										</div>
									) : card.dataChange > 0 ? (
										<div style={{ marginRight: '8px' }}>
											<AnalyticsIncreasePercent />
										</div>
									) : (
										''
									)}

									<div
										className={` ${
											card.dataChange < 0
												? 'ff_change_nochange_data body2'
												: 'ff_change_positive_data'
										}`}>
										{card.dataChange === 'Infinity' || card.dataChange === 'NaN'
											? '-'
											: card.dataChange != 0
											? card.dataChange < 0
												? card.dataChange * -1 + '%'
												: card.dataChange + '%'
											: 'No change'}
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default DialogMinicards;
