import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import {
	resetCreateInstaWatsapp,
	resetOnBoardWabaStatus,
	resetUpdateNumbers,
} from '../../../../../Features/WhatsApp-BSP/auth/authSlice';
import { useAppDispatch } from '../../../../../app/store';

const ErrorInSetup = () => {
	const navigate = useNavigate();
	const location = useLocation();
	let path = location.pathname;
	const dispatch = useAppDispatch();
	let pathArr = path.split('/');
	useEffect(() => {
		return () => {
			dispatch(resetUpdateNumbers());
			dispatch(resetCreateInstaWatsapp());
			dispatch(resetOnBoardWabaStatus());
		};
	});
	return (
		<Modal
			//empty funciton for misbehaving if we click outside modal
			onClose={() => { }}
			header={`Error in setting up your WhatsApp Number`}
			actionButtons={
				<Button
					onClick={() => {
						if (pathArr) {
							if (pathArr[2] === 'launch') {
								navigate('/app/launch');
							} else if (pathArr[2] === 'welcome') {
								navigate('/app/welcome');
							}
						}
					}}>
					Done
				</Button>
			}>
			<div>
				<p className='body1'>
					Sorry, there was an error in setting up your WhatsApp Number. Please
					try again by re-entering your business ID and confirming your WhatsApp
					number again.
				</p>
			</div>
		</Modal>
	);
};

export default ErrorInSetup;
