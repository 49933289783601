import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import Dropdown, { DropdownValue } from '../../../Components/Dropdown';
import { getUserResponseAction } from '../../../Features/Analytics/analyticsSlice';
import {
	APICallOption,
	ConfirmCollectedOption,
	DropdownIcon,
	KeyboardOption,
	MultiChoiceOption,
	MultiSelectOption,
	SendCollectOption,
	StarOption,
	TextOrImage,
	TriggedQueryOption,
} from '../icon';
import './userResponse.scss';

function UserResponseAction() {
	const Icons: any = {
		1: <TextOrImage width='22px' />,
		3: <MultiSelectOption width='22px' />,
		5: <MultiChoiceOption width='22px' />,
		8: <ConfirmCollectedOption width='22px' />,
		10: <APICallOption width='22px' />,
		11: <StarOption width='22px' />,
		12: <TriggedQueryOption width='21px' />,
		150: <KeyboardOption width='22px' />,
		14: <SendCollectOption width='22px' />,
	};
	const {
		UserResponseActionstatusChatData,
		calendarstatus,
		selectedTriggeredQuery,
		selectedDates,
		activeAnalyticsBot,
	} = useSelector((state: RootState) => state.analytics);

	const { activeBot } = useSelector((state: RootState) => state.bots);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const dispatch = useAppDispatch();

	const options =
		UserResponseActionstatusChatData.length !== 0
			? UserResponseActionstatusChatData.map((option: any) => {
					return {
						label: (
							<div className='action-label'>
								<div className='icon'>{Icons[option.userResponseTypeId]}</div>
								<p>{option.taskName}</p>
							</div>
						),
						value: option.taskName,
					};
			  })
			: [];

	const [selectedfield, setSelectedField] = useState<DropdownValue | any>(
		options.length !== 0 ? options[0] : { value: '' }
	);

	const labelData =
		UserResponseActionstatusChatData.length !== 0
			? UserResponseActionstatusChatData.map((option: any) => {
					return {
						taskname: option.taskName,
						labels: Object.keys(option.data),
					};
			  })
			: [];
	const labelCount =
		UserResponseActionstatusChatData.length !== 0
			? UserResponseActionstatusChatData.map((option: any) => {
					return {
						taskname: option.taskName,
						labels: Object.values(option.data),
					};
			  })
			: [];

	const filterData =
		labelData.length !== 0
			? labelData.filter((task: any) => {
					return task.taskname === selectedfield.value;
			  })
			: [];
	const filterCount =
		labelCount !== 0
			? labelCount.filter((task: any) => {
					return task.taskname === selectedfield.value;
			  })
			: [];

	const data = {
		labels: filterData[0] ? filterData[0].labels : '',
		datasets: [
			{
				barPercentage: 0,
				barThickness: 100,
				maxBarThickness: 34,
				minBarLength: 55,
				data: filterCount[0] ? filterCount[0].labels : '',
				backgroundColor: ['#3f3fef'],
				borderRadius: 2,
			},
		],
	};
	useEffect(() => {
		if (calendarstatus) {
			dispatch(
				getUserResponseAction({
					startdate: selectedDates[0],
					enddate: selectedDates[1],
					timezone: -330,
					tenantId: tenantId,
					botId: activeAnalyticsBot.botId,
					type: selectedfield ? selectedfield.value : '',
					wfId: selectedTriggeredQuery.triggeredQueryId,
				})
			);
		}
	}, [selectedDates]);

	return (
		<div>
			<div className='ff_ananlytics_User_Response'>
				<h5 className='ff_ananlytics_User_Response_header'>
					User response analysis for actions
				</h5>
				<div>
					<Dropdown
						value={selectedfield}
						onChange={setSelectedField}
						options={options}
						className='no-of-convo'
						// lableIcon={<DropdownIcon />}
						// isLableIcon={true}
					/>
				</div>
			</div>
			{UserResponseActionstatusChatData.length === 0 ? (
				<div className='ff_User_Response_bar_chart_no_data'>
					No data for {selectedfield.value} to display
				</div>
			) : (
				<div className='ff_analytics_User_Response_bar_chart'>
					<Bar
						className='analytics-bar'
						height={310}
						width={250}
						data={data}
						options={{
							scales: {
								x: {
									grid: {
										display: false,
										drawBorder: false,
									},
									ticks: {
										color: '#8991a3',
										font: {
											size: 15,
											weight: '600',
											family: "'Exo 2', sans-serif",
										},
									},
								},
								y: {
									// callback: function (value, index, values) {
									// 	return value / 1000 + 'k';
									// },

									ticks: {
										color: '#8991a3',
										maxTicksLimit: 7,
										font: {
											size: 15,
											weight: '600',
											family: "'Exo 2', sans-serif",
										},
									},
								},
							},
							maintainAspectRatio: false,
							responsive: true,

							plugins: {
								legend: {
									display: false,
								},
							},
						}}
					/>
				</div>
			)}
		</div>
	);
}

export default UserResponseAction;
