import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Modal from '../../../Components/Modal';
import { reDeployVersion } from '../../../Features/deploy/deploySlice';

const ReDeploy = () => {
	const location = useLocation();
	const state = location.state as { value: any };
	const navigate = useNavigate();

	const dispatch = useAppDispatch();
	const { activeBot } = useSelector((state: RootState) => state.bots);
	const { tenantId } = useSelector((state: RootState) => state.builder);
	const { reDeployedLoader, isReDeployedSuccess } = useSelector(
		(state: RootState) => state.deploy
	);

	const handleRedeploy = () => {
		tenantId &&
			dispatch(
				reDeployVersion({
					botId: activeBot.encBotId,
					versionId: state.value.versionId,
					versionName: state.value.versionName,
					tenantId: tenantId.toString(),
				})
			);
	};
	useEffect(() => {
		(isReDeployedSuccess === 'Success' || isReDeployedSuccess === 'Failed') &&
			navigate(-1);
	}, [isReDeployedSuccess]);
	const buttons = () => {
		return (
			<>
				<Button buttonType='light' onClick={() => navigate(-1)}>
					Cancel
				</Button>
				<Button
					disabled={false}
					onClick={() => handleRedeploy()}
					loading={reDeployedLoader}>
					Deploy
				</Button>
			</>
		);
	};
	return (
		<Modal header='Redeploy Version?' actionButtons={buttons()}>
			<span style={{ color: '#1b2050', fontSize: '16px' }}>
				Please confirm that you wish to redeploy the version
				<b> "{state.value.versionName}" </b>. This will replace the currently
				deployed version of the bot in all channels.
			</span>
		</Modal>
	);
};

export default ReDeploy;
