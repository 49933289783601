import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../Components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import { loadState, saveState } from '../../../utils/utils';
import Input from '../../../Components/Input';
import './editpdfsuccess.scss';
import Modal from '../../../Components/Modal';
import { SnackModal } from '../../../assets/Icons';
import {
	editPDFAPI,
	setEditAPIStatus,
} from '../../../Features/Ingest/IngestSlice';
import { isHTMLTagsPresent } from '../../../utils/utils';
import styled from 'styled-components';
const MAX_COUNT = 100;

export const SuccessContainer = styled.div`
	.modal-container footer {
		justify-content: center;
	}
`;
export function EditPDFSuccess() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	return (
		<SuccessContainer>
			<Modal
				actionButtons={
					<div className='ff-input-edit-actions'>
						<Button
							onClick={() => {
								navigate(-1);
							}}>
							OK
						</Button>
					</div>
				}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}>
					<SnackModal />
					<h5 style={{ marginTop: '8px' }}>Document Details Updated</h5>
					<div
						style={{ marginTop: '20px', fontWeight: '400' }}
						className='button1'>
						Document details were successfully updated
					</div>
				</div>
			</Modal>
		</SuccessContainer>
	);
}
