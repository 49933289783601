import React, { useEffect } from 'react';
import { PreviewQuicklinksDiv } from '../../../../../styles/EOW/Body/body.styled';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/store';

type Props = {
	lists: string[];
};

const PreviewQuicklinks = ({ lists }: Props) => {
	const { colorScale, selectedColor } = useSelector(
		(state: RootState) => state.experienceonweb
	);
	useEffect(() => {}, [selectedColor]);
	return (
		<>
			{lists.length > 0 &&
				lists.map((list, i) => (
					<PreviewQuicklinksDiv
						className='ff-preview-quicklinks'
						colorScale={colorScale}
						selectedColor={selectedColor}>
						<p key={list + i} className='body2'>
							{list}
						</p>
					</PreviewQuicklinksDiv>
				))}
		</>
	);
};

export default PreviewQuicklinks;
