import './AddNewBot.scss';
import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import Modal from '../../../Components/Modal';
import {
	BotImagePreview,
	ButtonForwardIcon,
	CheckboxTick,
	SearchClose,
} from '../../../assets/Icons';
import { useEffect, useRef, useState } from 'react';
import { RootState, useAppDispatch } from '../../../app/store';
import { useSelector } from 'react-redux';
import {
	createBotFromScratch,
	init,
	reset,
	setName,
	validateConfig,
} from '../../../Features/builder/builderSlice';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { isHTMLTagsPresent, saveState } from '../../../utils/utils';
import { setActiveBot } from '../../../Features/Bots/BotSlice';
import { uploadFile } from '../../../Features/configure/configureSlice';
import DefaultAvatar from '../../../assets/images/bot-icons/DefaultAvatar.png';
import BotAvatar1 from '../../../assets/images/bot-icons/botIcon1.png';
import BotAvatar2 from '../../../assets/images/bot-icons/botIcon2.png';
import BotAvatar3 from '../../../assets/images/bot-icons/botIcon3.png';
import BotAvatar4 from '../../../assets/images/bot-icons/botIcon4.png';
import BotAvatar5 from '../../../assets/images/bot-icons/botIcon5.png';
import BotAvatar6 from '../../../assets/images/bot-icons/botIcon6.png';
import BotAvatar7 from '../../../assets/images/bot-icons/botIcon7.png';
import BotAvatar8 from '../../../assets/images/bot-icons/botIcon8.png';
import BotAvatar9 from '../../../assets/images/bot-icons/botIcon9.png';
import BotAvatar10 from '../../../assets/images/bot-icons/botIcon10.png';
import BotAvatar11 from '../../../assets/images/bot-icons/botIcon11.png';
import BotAvatar12 from '../../../assets/images/bot-icons/botIcon12.png';
import BotAvatar13 from '../../../assets/images/bot-icons/botIcon13.png';
import BotAvatar14 from '../../../assets/images/bot-icons/botIcon14.png';
import BotAvatar15 from '../../../assets/images/bot-icons/botIcon15.png';

const MAX_FILE_SIZE = 5120;

export let BOT_ICONS = [
	DefaultAvatar,
	BotAvatar1,
	BotAvatar2,
	BotAvatar3,
	BotAvatar4,
	BotAvatar5,
	BotAvatar6,
	BotAvatar7,
	BotAvatar8,
	BotAvatar9,
	BotAvatar10,
	BotAvatar11,
	BotAvatar12,
	BotAvatar13,
	BotAvatar14,
	BotAvatar15,
];

const AddNewBot = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const context: any = useOutletContext();

	const { isNameValid, botId, botAdded, isBotCreating } = useSelector(
		(state: RootState) => state.builder
	);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { BotList } = useSelector((state: RootState) => state.bots);
	const { botAvatar } = useSelector((state: RootState) => state.configure);

	const fileRef = useRef<HTMLInputElement>(null);
	const [file, setFile] = useState<any>(null);
	const [fileError, setFileError] = useState<Array<string>>([]);
	const [filePassed, setFilePassed] = useState<Array<string>>([]);
	const [preview, setPreview] = useState('');

	const [botName, setBotName] = useState('');
	const [isBotNameValid, setIsBotNameValid] = useState<boolean | null>(null);
	const [welcomeMessage, setWelcomeMessage] = useState(
		'Hi! How can I help you today?'
	);
	const [type, setType] = useState('Ad');
	const [error, setError] = useState<undefined | string>('');
	let defaultBotIcon = BOT_ICONS[Math.floor(Math.random() * BOT_ICONS.length)]; //NOSONAR

	useEffect(() => {
		if (context.type === 'intelligent-ad') {
			setType('Ad');
		} else if (context.type === 'multichannel-storefront') {
			setType('Storefront');
		} else {
			//error redirect
		}
	}, [context.type]);

	useEffect(() => {
		dispatch(reset());
	}, []);

	useEffect(() => {
		dispatch(setName(botName ?? 'Bot Name'));
	}, [botName]);

	useEffect(() => {
		if (!file) {
			setPreview('');
			return;
		}

		const objectUrl = URL.createObjectURL(file);
		setPreview(objectUrl);

		dispatch(
			uploadFile({
				uploadfile: file,
				uploadTo: 'General',
			})
		);

		return () => URL.revokeObjectURL(objectUrl);
	}, [file]);

	useEffect(() => {
		if (isNameValid) {
			tenantId &&
				dispatch(
					createBotFromScratch({
						botDesc: botName,
						botId: 0,
						bot_displayname: botName,
						bot_icon: botAvatar.length > 0 ? botAvatar : defaultBotIcon,
						bot_name: botName,
						clientBrand: 'CBRAND',
						clientCustomerCareEmail: 'demo@demo.com',
						clientCustomerCareNumber: '0000000000',
						clientNationality: 'India',
						isMultiLingualEnabled: false,
						skillsID: '',
						templateID: '',
						tenantId: tenantId,
						workflows: 'none',
						welcomeMessage,
						// botType: context.type,
						botType: context.type === 'intelligent-ad' ? 'CA' : 'VSR',
					})
				);
		} else {
			setIsBotNameValid(isNameValid);
		}
	}, [isNameValid]);

	useEffect(() => {
		if (botId && botAdded) {
			let activeBot = BotList.filter((bot: any) => bot.botId === botId);
			if (activeBot.length > 0) {
				saveState('botDetails', activeBot[0]);
				saveState('botId', botId);
				dispatch(init({ botId, tenantId, name: botName }));
				dispatch(setActiveBot(activeBot[0]));
				navigate(`/app/catalog/${context.type}/design/build`);
			}
		}
	}, [botId, botAdded, BotList]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			let file_errors = [];
			let file_passed = [];
			const image = event.target.files[0];

			if (image.size / 1024 > MAX_FILE_SIZE) {
				file_errors.push('File size should be maximum 5 MB');
			} else {
				file_passed.push('File size should be maximum 5 MB');
			}

			if (image.type === 'image/jpeg' || image.type === 'image/png') {
				file_passed.push('Format should be PNG or JPEG');
			} else {
				file_errors.push('Format should be PNG or JPEG');
			}

			file_errors.length === 0 && setFile(image);
			setFileError(file_errors);
			setFilePassed(file_passed);
		}
	};

	const handleReset = () => {
		setFile(null);
		fileRef.current && (fileRef.current.value = '');
		setFileError([]);
		setFilePassed([]);
	};

	const isButtonDisabled = () =>
		botName.trim().length <= 0 ||
		welcomeMessage.length <= 0 ||
		isBotNameValid === false ||
		isHTMLTagsPresent(botName);

	const handleBotNameChange = (name: string) => {
		setBotName(name);
		setIsBotNameValid(null);
		dispatch(reset());
	};

	const handleCreate = () => {
		if (!isButtonDisabled() && tenantId) {
			dispatch(
				validateConfig({
					fieldName: 'BOT_NAME',
					fieldValue: botName.trim(),
					tenantId,
				})
			);
		}
	};

	const validateName = (value: string) => {
		if (isHTMLTagsPresent(value)) {
			setError(`Special characters not allowed in ${type} name`);
		} else {
			setError(undefined);
		}
	};

	return (
		<Modal
			headerCloseBtn
			header={`Create new ${type}`}
			className='add-new-bot-modal'
			actionButtons={
				<div className='button-container'>
					<Button
						fullWidth
						loading={isBotCreating}
						size='large'
						onClick={handleCreate}
						disabled={isButtonDisabled()}>
						Continue
					</Button>
				</div>
			}>
			<div className='add-new-bot'>
				<div className='bot-avatar'>
					{preview.length > 0 && fileError.length <= 0 ? (
						<img src={preview} alt='' />
					) : (
						<div className='preview'>
							<BotImagePreview width='29px' />
						</div>
					)}
					<div className='buttons'>
						<div className='buttons-container'>
							<input
								ref={fileRef}
								type='file'
								name='avatar'
								id='avatar'
								onChange={handleFileChange}
								autoFocus
							/>
							{!file && fileError.length <= 0 ? (
								<label htmlFor='avatar' className='avatar-btn'>
									Add avatar
								</label>
							) : (
								<>
									<label htmlFor='avatar' className='avatar-btn'>
										Change avatar
									</label>
									<label className='avatar-btn reset-btn' onClick={handleReset}>
										Reset
									</label>
								</>
							)}
						</div>
						{fileError.length > 0 ? (
							<div className='warnings'>
								<p className='warning default'>Avatar failed to add</p>
								{fileError.map((error) => (
									<p className='warning'>
										<SearchClose width='12px' />
										{error}
									</p>
								))}
								{filePassed.map((passed) => (
									<p className='success'>
										<CheckboxTick width='12px' />
										{passed}
									</p>
								))}
							</div>
						) : (
							<div className='infos'>
								<p className='info'>File size should be maximum 5 MB</p>
								<p className='info'>Format should be PNG or JPEG</p>
							</div>
						)}
					</div>
				</div>

				<div className='input-container'>
					<Input
						fullWidth
						label={`Name your ${type}*`}
						value={botName}
						placeholder='Enter name'
						onChange={(event) => {
							handleBotNameChange(event.target.value);
							validateName(event.target.value);
						}}
						error={isBotNameValid === false || Boolean(error)}
						helperText={error}
						disAllowSpecialChar={true}
					/>
					{isBotNameValid === false && (
						<div className='error-text'>
							There's already a {type} with this name. Please pick a different
							name.
						</div>
					)}
				</div>
				<div className='input-container'>
					<Input
						fullWidth
						label='Welcome message*'
						value={welcomeMessage}
						placeholder='Enter message'
						onChange={(event) => setWelcomeMessage(event.target.value)}
					/>
				</div>
			</div>
		</Modal>
	);
};
export default AddNewBot;
