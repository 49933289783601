import { createSlice } from '@reduxjs/toolkit';
import { State } from './modal';

const initialState: State = {
	open: false,
	message: '',
	testcase: 'neutral', //positive on success, negative on error, neutral for info
	bgColor: '',
	isLoader: false,
	openModal: false,
	modalMesssage: 'Your action was successful',
	modalMode: 'positive',
};

const messageSlice = createSlice({
	name: 'message',
	initialState,
	reducers: {
		reset: (state) => {
			state.open = false;
			state.message = '';
		},
		setLoader: (state, action) => {
			state.isLoader = action.payload;
		},
		set: (state, action) => {
			state.open = true;
			state.message = action.payload;
			state.testcase = 'neutral';
		},
		setBg: (state, action) => {
			state.bgColor = action.payload;
		},
		setSnack: (state, action) => {
			state.open = true;
			state.message = action.payload.message;
			state.testcase = action.payload.testcase;
		},
		setSnackModal: (state, action) => {
			state.openModal = action.payload.openModal;
			state.modalMesssage = action.payload.modalMesssage;
			state.modalMode = action.payload.modalMode ?? 'positive';
		},
	},
});

export const { reset, set, setSnack, setBg, setLoader, setSnackModal } =
	messageSlice.actions;

export default messageSlice.reducer;
