import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../app/store';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import { linkedStatus } from '../../../../../Features/teamsetting/teamsettingSlice';
import { loadState, saveState } from '../../../../../utils/utils';
import { EmailSentTick } from '../../../../../assets/Icons';

const LinkShopifyPopup = () => {
	const navigate = useNavigate();
	const popupdescription = 'Shopify store linked';
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const shopifydata = loadState('storeName');
	const dispatch = useAppDispatch();
	return (
		<Modal
			modalcontainerClassName='shopify-success-popup'
			imagePath={EmailSentTick}
			header={popupdescription}
			actionButtons={
				<>
					<Button
						onClick={() => {
							tenantId && dispatch(linkedStatus(tenantId));
							saveState('linkstatus', 'unlinked');
							navigate(-2);
						}}>
						Okay!
					</Button>
				</>
			}>
			<div className='modal-body-popup'>
				Congrats! You have successfully linked your team with{' '}
				<b>{shopifydata}.</b> <br></br>
				You can now directly sell products from the store using AI
			</div>
		</Modal>
	);
};

export default LinkShopifyPopup;
