import { ButtonProps } from '@mui/material';
import './Options.scss';

const Options = ({ children, ...rest }: ButtonProps) => {
	return (
		<div className='ff-rich-button'>
			<button className='button2' {...rest}>
				{children}
			</button>
			<button className='overlay'></button>
		</div>
	);
};

export default Options;
