import { InputAdornment, TextFieldProps } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import React, { useState } from 'react';
import { SearchClose, SearchIcon } from '../../assets/Icons';
import { FFLoader } from '../../assets/Icons/Loader';
import Input from '../Input';
import './Search.scss';
import { CrossIconRounded } from '../../assets/Icons/Components';
type Props = {
	placeholder?: string;
	onChange?: (arg0: string) => void;
	isLoading?: boolean;
};

function Search({ placeholder, onChange, isLoading = false }: Props) {
	const [value, setValue] = useState('');

	const clearSearchBox = () => {
		setValue('');
		if (onChange) {
			onChange('');
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		if (onChange) {
			onChange(e.target.value);
		}
	};

	return (
		<Input
			value={value}
			autoComplete='off'
			placeholder={placeholder ? placeholder : 'Search Bots...'}
			onChange={handleChange}
			endIcon={
				<>
					{isLoading && (
						<span className='ff-select-loader'>
							<FFLoader fill='inherit' />
						</span>
					)}
					{value && !isLoading && (
						<div onClick={clearSearchBox} style={{ cursor: 'pointer' }}>
							<CrossIconRounded />
						</div>
					)}
					{!Boolean(value) && !isLoading && <SearchIcon />}
				</>
			}
		/>
	);
}

export default Search;
