import './chats.scss';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import SendButton from '@mui/icons-material/Send';
import {
	getCurrentTime,
	getDay,
	loadState,
	saveState,
} from '../../utils/utils';
import { RootState, useAppDispatch } from '../../app/store';
import Input from '../../Components/Input/index';
import Button from '../../Components/Button';
import { format, toDate } from 'date-fns';
import {
	chatData,
	// chatSource,
	initialValue,
	// newChats,
	SortedMessages,
	wsAllConversationChats,
} from './model';
import ChatBubble from './ChatBubble';
import { Outlet, useNavigate } from 'react-router-dom';
import UserInfo from './UserInfo';
import { ChatAvatar, ChatAvatarName } from './ChatAvatarDetails';
import { set } from '../../Features/message/messageSlice';
import { BOT_ICONS } from '../CatalogScreen/AddNewBot';
import { FFLoader } from '../../assets/Icons/Loader';
import { Tooltip } from '@mui/material';
import { WebLink, WebsiteLogo } from './Icons';
import whatsapp from '../../assets/images/whatsapp.png';
import instagram from '../../assets/images/instagram.png';
import thumbsup from '../../assets/images/thumbs-up.png';
import { useSelector } from 'react-redux';
import FreshChat from '../../assets/images/chat-screen-freshchat.png';
import { checkFreshchatStatus } from '../../Features/teamsetting/teamsettingSlice';
import { getWholeConversation } from '../../Features/Analytics/analyticsSlice';
import { switchTab } from '../../Features/auth/authSlice';
import { CLOSED, CLOSING, OPEN } from 'ws';
import { getConData, setLiveChatNotification } from '../../Features/chats/ChatSlice';
import PopupNotification from '../Notification/PopupNotification';
import { toast } from 'react-toastify';
import { notificationInit } from '../../utils/DesktopNotification';
// var webSocket: WebSocket;
var LivewebSocket: WebSocket;
var originName: string;
var apiBaseURL: string;
var socketBaseURL: string;

function Chats() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {
		selectedDates,
		chatConversationalData,
		analyticConversationData,
		isConversationIdValid,
	} = useSelector((state: RootState) => state.analytics);
	const { bot_AI_ID } = useSelector((state: RootState) => state.teams);
	const [messagesByTime, setMessagesByTime] = useState<SortedMessages>({});
	const [setupAgentChat, setSetupAgentChat] = useState(false);
	const [isTabVisible, setIsTabVisible] = useState(true);
	const [newchats, setChats] = useState<wsAllConversationChats[]>([
		// ...newChats, //enable for local also uncomment in model.ts
	]);
	const [updatedchats, setUpdateChats] = useState<wsAllConversationChats[]>([]);

	const [chats, setSelectedChat] = useState<chatData[]>([
		// ...chatSource, //enable for local also uncomment in model.ts
	]);
	const scrollRef = useRef<HTMLDivElement>(null);
	const [isUserSelected, setIsUserSelected] = useState(true);
	const [value, setValue] = useState('');
	const [transcriptLoader, setTranscriptLoader] = useState(false);

	const [selectedUser, setUserSelected] =
		useState<wsAllConversationChats>(initialValue);

	const [chatScreen, showChatScreen] = useState('');
	const [showInputBar, setshowInputBar] = useState(false);
	const { freshChatLinked } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { convData } = useSelector((state: RootState) => state.chats);
	const [call, setCall] = useState(false);
	const handleChatSelected = (eachChat: wsAllConversationChats) => {
		handleRespondUser();
		setIsUserSelected(true);
		setUserSelected(eachChat);
		setTranscriptLoader(true); // make false for local

		const transcriptData = {
			sessionId: eachChat.conversationId,
			// s_id: eachChat.s_id,
			// tabName: eachChat.tabName,
			// agentId: eachChat.agentId,
			// timezoneOffset: -330,
			// time: eachChat.time,
			// channel: eachChat.channel,
			// deviceInfo: eachChat.deviceInfo,
			// agentSessionID: eachChat.agentId,
		};

		// fetch(apiBaseURL + originName + 'HybridChat/getTranscript', {
		// 	method: 'POST',
		// 	body: JSON.stringify(transcriptData),
		// })
		// 	.then((res) => res.json())
		// 	.then((data) => {
		// 		setTranscriptLoader(false);
		// 		setSelectedChat(data.chat);
		// 	});
		saveState('convSessionId', transcriptData.sessionId);
		dispatch(
			getWholeConversation({
				conversationID: transcriptData.sessionId.toString(),
				botId: bot_AI_ID ? bot_AI_ID : loadState('botId'),
				path: window.location.origin.indexOf('preprod') > -1 ? '' : '/chat',
			})
		);
		setTranscriptLoader(false);
		// setSelectedChat(chatSource);  // make for local
		setSelectedChat(analyticConversationData);
	};
	useEffect(() => {
		dispatch(setLiveChatNotification(false))
	}, [])
	useEffect(() => {
		setTranscriptLoader(false);
		setSelectedChat(analyticConversationData);
	}, [analyticConversationData]);
	const handleRespondUser = () => {
		// acceptChat();
		setUserSelected(initialValue);
		showChatScreen('');
	};

	const sendAgentInput = (message: string) => {
		if (LivewebSocket) {
			LivewebSocket.send(
				// JSON.stringify({
				// message: message.trim(),
				// channel: selectedUser.channel,
				// 	sessionId: selectedUser.sessionId,
				// 	queue: selectedUser.type,
				// 	action: 'agent_client',
				// 	IPaddress: '127.0.0.1',
				// 	time: selectedUser.time,
				// 	agentId: loadState('user').userID,
				// 	isMedia: false,
				// 	mediaMeta: {},
				// 	botId: selectedUser.botId,
				// 	phoneNumber: selectedUser.phoneNumber,
				// 	calledFrom: 'agent',
				// 	messageOutgoingQName: selectedUser.messageOutgoingQName,
				// 	wabaPhoneNoId: selectedUser.wabaPhoneNoId,
				// 	wabaNo: selectedUser.wabaNo,
				// 	instaBusinessId: selectedUser.instaBusinessId,
				// 	serviceProviderName: selectedUser.serviceProviderName,
				// })
				JSON.stringify({
					mappedSessionId: selectedUser.conversationId,
					metaData: {},
					message: message.trim(),
					channel: selectedUser.channel,
				})
			);
			let appendData: chatData[] = [
				{
					body: message.trim(),
					botId: loadState('botId'),
					conversationId: selectedUser.conversationId,
					createdDate: new Date().toISOString(),
					id: 0,
					metaData: {},
					source: 'agent',
					status: 'Delivered',
				},
			];

			setSelectedChat((chats) => [...chats, ...appendData]);
			setValue('');
		}
	};
	// const acceptChat = () => {
	// 	sessionStorage.setItem('startsessionID', selectedUser.sessionId);
	// 	if (webSocket) {
	// 		if (webSocket.readyState === WebSocket.OPEN) {
	// 			setSetupAgentChat(true);
	// 		}

	// 		webSocket.onopen = () => {
	// 			if (webSocket) {
	// 				console.log('sent session to socket');
	// 				webSocket.send(
	// 					JSON.stringify({
	// 						agentSessionID: sessionStorage.getItem('agentActiveSession'),
	// 					})
	// 				);
	// 			}
	// 		};
	// 	}

	// };

	// const releaseChat = () => {
	// 	sessionStorage.setItem('startsessionID', selectedUser.sessionId);

	// 	setChatState(
	// 		'close',
	// 		'',
	// 		111,

	// 		-1,
	// 		'',
	// 		false,
	// 		selectedUser.collectstatus,
	// 		(response: { result: string }) => {
	// 			if (response.result === 'success') {
	// 				// console.log(response.result);
	// 			}
	// 		}
	// 	);
	// };

	const agentSocketListener = (
		event: MessageEvent,
		selectedSessionID: string
	) => {
		// console.log(event)
		// const { message, time, disambQuestions, sessionId, date, s_id } =
		// 	JSON.parse(event.data);
		const response = JSON.parse(event.data);
		if (Object.keys(response)[0] !== 'pong') {
			if (window.location.href.indexOf('/app/chats/my-chats') <= -1) {
				dispatch(setLiveChatNotification(true))
				toast(
					<PopupNotification
						type={"chat"}
						message={"This is a live chat notification."}
						time={""}
						link={'/app/chats'}
					/>
				);
			}

			if (isTabVisible) {
				notificationInit('Message Received from the customer');
			}
			if (response.id === selectedSessionID) {
				let appendData: chatData[] = [
					{
						body: response.message.trim(),
						botId: loadState('botId'),
						conversationId: response.id,
						createdDate: response.time
							? getISTTime(response.time)
							: new Date().toISOString(),
						id: 0,
						metaData: {},
						source: 'user',
						status: 'Delivered',
					},
				];
				setTimeout(() => {
					setSelectedChat((chats) => [...chats, ...appendData]);
				}, 300)

			}

			if (
				response.eventType &&
				response.eventType === 'Customer_Disconnected'
			) {
				dispatch(set('Customer disconnected from the chat'));
				setshowInputBar(true);

				setshowInputBar(false);
				showChatScreen('');
			}
		}
	};
	const getISTTime = (time: any) => {
		var dateUTC = new Date(time);
		var dateget = dateUTC.getTime();
		var dateIST = new Date(dateget);
		//date shifting for IST timezone (+5 hours and 30 minutes)
		dateIST.setHours(dateIST.getHours() + 5);
		dateIST.setMinutes(dateIST.getMinutes() + 30);

		return dateIST.toString();
	};
	const handleEndChat = () => {
		// releaseChat();
		if (LivewebSocket) {
			LivewebSocket.send(
				JSON.stringify({
					mappedSessionId: selectedUser.conversationId,
					metaData: {},
					message: 'Release Chat',
					channel: selectedUser.channel,
				})
			);
		}
		dispatch(set('Chat Ended'));
		showChatScreen('');
		setUserSelected(initialValue);
		navigate(-1);
	};
	const websocketEndPointheartbeat = () => {
		if (!LivewebSocket) return;
		if (LivewebSocket.readyState !== 1) return;
		LivewebSocket.send(JSON.stringify({ ping: 'ping' }));

		setTimeout(() => {
			websocketEndPointheartbeat();
		}, 5000);
	};

	const getAllConversation = () => {
		fetch(
			apiBaseURL +
			originName +
			`livechat/tenant/${loadState('tenantId')}/bot/${loadState(
				'botId'
			)}/agent/${loadState('user').userID}/users?id=${loadState(
				'currentSessionID'
			)}`,
			{
				credentials: 'same-origin',
				method: 'GET',
				headers: {
					Authorization: `Bearer ${loadState('token')}`,
					'Content-Type': 'application/json',
					'X-Tenant-Id': loadState('tenantId').toString(),
				},
			}
		)
			.then((res) => res.json())
			.then((data) => {
				if (data) {
					let checkChat = data.data;
					setChats(checkChat);
					dispatch(getConData(checkChat));

					dispatch(getConData(checkChat));

					if (checkChat && checkChat.length === 0) {
						showChatScreen('');
					}
				}
			});
	};
	useEffect(() => {
		saveState('location', window.location.pathname);

		// if (loadState('LiveWebsocket') !== true) {
		if (
			LivewebSocket === undefined ||
			loadState('switchTeam') === 'true' ||
			(LivewebSocket && LivewebSocket.readyState !== WebSocket.OPEN)
		) {
			if (window.location.protocol === 'https:') {
				apiBaseURL = 'https://';
				socketBaseURL = 'wss://';
				originName = window.location.host + '/';
			} else if (
				window.location.origin.indexOf('localhost:3000') > -1 ||
				window.location.origin.indexOf('localhost:8989') > -1 ||
				window.location.origin.indexOf('localhost:8080') > -1
			) {
				// apiBaseURL = 'http://';
				// socketBaseURL = 'ws://';
				// originName = 'localhost:3000/';
				apiBaseURL = 'https://';
				socketBaseURL = 'wss://';
				originName = 'dev.flyfish.ai' + '/';
			} else {
				apiBaseURL = 'http://';
				socketBaseURL = 'ws://';
				originName = window.location.host + '/';
			}
			if (window.location.protocol === 'https:') {
				apiBaseURL = 'https://';
				socketBaseURL = 'wss://';
				originName = window.location.host + '/';
			} else if (
				window.location.origin.indexOf('localhost:3000') > -1 ||
				window.location.origin.indexOf('localhost:8989') > -1 ||
				window.location.origin.indexOf('localhost:8080') > -1
			) {
				// apiBaseURL = 'http://';
				// socketBaseURL = 'ws://';
				// originName = 'localhost:3000/';
				apiBaseURL = 'https://';
				socketBaseURL = 'wss://';
				originName = 'dev.flyfish.ai' + '/';
			} else {
				apiBaseURL = 'http://';
				socketBaseURL = 'ws://';
				originName = window.location.host + '/';
			}

			fetch(
				apiBaseURL +
				originName +
				`livechat/tenant/${tenantId}/bot/${bot_AI_ID}/user/${loadState('user').userID
				}`,
				{
					credentials: 'same-origin',
					method: 'GET',
					headers: {
						Authorization: `Bearer ${loadState('token')}`,
						'Content-Type': 'application/json',
						'X-Tenant-Id': loadState('tenantId').toString(),
					},
				}
			)
				.then((res) => res.json())
				.then((data) => {
					saveState('currentSessionID', data.agentSessionId);

					let botID = bot_AI_ID ? bot_AI_ID : loadState('botId');
					// if (LivewebSocket) {

					LivewebSocket = new WebSocket(
						socketBaseURL +
						originName +
						'livechat/webServer?id=' +
						loadState('currentSessionID') +
						'&source=Agent' +
						'&tenantId=' +
						loadState('tenantId') +
						'&botId=' +
						botID
					);
					// }
					LivewebSocket.onopen = () => {
						console.log('wsServer Socket opened');
						// saveState('LiveWebsocket', true)
						websocketEndPointheartbeat();
					};

					saveState('switchTeam', 'false');
					setSetupAgentChat(true);
					setCall(true);
				})

				.catch((error) => {
					console.log(error);
				});

			tenantId && dispatch(checkFreshchatStatus({ tenantId }));
		}
	}, []);

	// const getChats = () => {
	// 	let storeChats = newchats.filter(
	// 		(data) => data.tabName.toLowerCase() === 'my chats'
	// 	);
	// 	return storeChats;
	// };

	useEffect(() => {
		if (call) {
			const id = setInterval(getAllConversation, 5000);
			saveState('intervalId', id);
		}
	}, [call]);

	useEffect(() => {
		if (convData) {
			setChats(convData);
			var convIdArray: any = [];
			convData.map((data: any, i: number) => {
				// 	console.log(data)
				convIdArray.push(data.conversationId);
			});

			if (convIdArray.indexOf(loadState('convSessionId')) === -1) {
				showChatScreen('');
				if (window.location.pathname.indexOf('release') > -1) {
					navigate(-1);
				}
			}
		}
	}, [convData]);
	useEffect(() => {
		if (chats.length) {
			if (chats.length > 0) {
				let sorted_messages: SortedMessages = {};
				chats.forEach((msg) => {
					if (msg.createdDate) {
						const day = getDay(msg.createdDate);
						sorted_messages[day] = sorted_messages[day] || [];
						sorted_messages[day].push(msg);
					}
				});
				sorted_messages && setMessagesByTime(sorted_messages);
			}
		}
	}, [chats]);

	useEffect(() => {
		if (setupAgentChat) {
			LivewebSocket.onmessage = (event) => {

				agentSocketListener(event, selectedUser.conversationId);
			}
			LivewebSocket.onclose = (ev) => {
				console.log(ev);
				if (LivewebSocket === undefined || LivewebSocket) {
					LivewebSocket.close(1000, 'LivewebSocket socket closed from UI');
				}
			};
		}
	}, [selectedUser, setupAgentChat]);

	useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.scrollIntoView();
		}
	}, [scrollRef.current, chats]);


	const handleVisibilityChange =
		useCallback(() => {
			setIsTabVisible(document.visibilityState === 'visible');
		}, []);

	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	const channelDetails = () => {
		return (
			<div
				className={`incoming-channel ${selectedUser.channel.toLowerCase()} ${selectedUser.origin && selectedUser.origin.indexOf('flyfish.ai') > -1
					? 'web-link'
					: 'website'
					}`}>

				<div className='channel-icon'>
					{selectedUser.channel === 'web' ? (
						selectedUser.origin &&
							selectedUser.origin.indexOf('flyfish.ai') > -1 ? (
							<WebLink />
						) : (
							<WebsiteLogo />
						)
					) : selectedUser.channel.toLowerCase() === 'instagram' ? (
						<img alt='' src={instagram} />
					) : (
						<img alt='' src={whatsapp} />
					)}
				</div>
				<div className='caption'>
					<Tooltip
						title={
							selectedUser.channel === 'web'
								? selectedUser.origin &&
									selectedUser.origin.indexOf('flyfish.ai') > -1
									? 'Web link'
									: 'Website'
								: selectedUser.channel.toLowerCase() === 'instagram'
									? selectedUser.instaBusinessId
									: '+' + selectedUser.wabaNo
						}>
						<div className='text-flow'>
							{selectedUser.channel === 'web'
								? selectedUser.origin &&
									selectedUser.origin.indexOf('flyfish.ai') > -1
									? 'Web link'
									: 'Website'
								: selectedUser.channel.toLowerCase() === 'instagram'
									? selectedUser.instaBusinessId
									: '+' + selectedUser.wabaNo}
						</div>
					</Tooltip>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className='ff-chat-screen-container'>
				{newchats.length === 0 ? (

					<>

						<div className='ff-chat-screen-wrapper no-chats'>
							<div className='ff-chat-screen'>
								<div className='chat-screen-body'>
									<div className='chats-container'>
										<div className='chat-list'>

											<div className='no-chats-user-infor'>
												<span className='no-chats-blue-avatar'>
												</span>
												<span className='no-chats-label'>
													<span className='no-chats-title'></span>
													<span className='no-chats-body'></span>
													<span className='no-chats-timestamp'></span>
												</span>

											</div>
											<div className='no-chats-user-infor'>
												<span className='no-chats-pink-avatar'>
												</span>
												<span className='no-chats-label'>
													<span className='no-chats-title'></span>
													<span className='no-chats-body'></span>
													<span className='no-chats-timestamp'></span>
												</span>

											</div>
											<div className='no-chats-user-infor'>
												<span className='no-chats-blue-avatar'>
												</span>
												<span className='no-chats-label'>
													<span className='no-chats-title'></span>
													<span className='no-chats-body'></span>
													<span className='no-chats-timestamp'></span>
												</span>

											</div>
											<div className='no-chats-user-infor'>
												<span className='no-chats-pink-avatar'>
												</span>
												<span className='no-chats-label'>
													<span className='no-chats-title'></span>
													<span className='no-chats-body'></span>
													<span className='no-chats-timestamp'></span>
												</span>

											</div>
										</div>
									</div>

									<div className='chat-display'>

										<div className='single-chat-screen'>
											<div className='single-chat-screen-header'>
												<div className='header-left'>
													<span className='no-chats-blue-avatar'>
													</span>
													<span className='no-chats-header-title'></span>

												</div>
												<div className='header-right'>
													<span className='end-chat-button'>
														<Button
															buttonType='light'
															onClick={() => {

															}}>

														</Button>
													</span>
												</div>
											</div>
											<div className='ff-chats-holder-agent-dashboard'>
												<div className='single-chat-container'>
													<div className='single-chat-content'>

														<div className='no-chats-available'>
															<h6>No waiting users</h6>
															<p className='body2'>Chats for you will appear here when users join</p>
														</div>

													</div>
												</div>
											</div>
											<div className='no-chats-input-footer'>

											</div>
										</div>

									</div>

								</div>

							</div>
						</div>
					</>

				) : (
					<div className='ff-chat-screen-wrapper'>
						<div className='ff-chat-screen'>
							<div className='chat-screen-body'>
								<div className='chats-container'>
									<div className='chat-list'>
										{newchats &&
											newchats.map((chat) => (
												<UserInfo
													className={
														selectedUser.conversationId === chat.conversationId
															? 'active'
															: ''
													}
													chat={chat}
													showChatScreen={showChatScreen}
													handleChatSelected={handleChatSelected}
												/>
											))}
									</div>
								</div>
								{chatScreen === 'show' ? (
									<div className='chat-display'>
										{isUserSelected ? (
											<div className='single-chat-screen'>
												<div className='single-chat-screen-header'>
													<div className='header-left'>
														<span>
															<ChatAvatar
																size={38}
																sessionId={selectedUser.conversationId.toString()}
															/>
														</span>
														<h6>
															<ChatAvatarName
																sessionId={selectedUser.conversationId.toString()}
															/>
														</h6>
														{channelDetails()}
														{selectedUser.botName ? (
															<div className='bot-name'>
																<span>
																	<img
																		src={
																			selectedUser.botIconURL
																				? selectedUser.botIconURL
																				: BOT_ICONS[
																				Number(
																					selectedUser.botId
																						? selectedUser.botId
																						: '11'
																				) % BOT_ICONS.length
																				]
																		}
																		alt=''
																		className='bot-icon'
																	/>
																</span>
																{/* <span className='caption'>
																	{selectedUser.botType === 'CA' ? 'IA' : 'MS'}:
																</span> */}
																<Tooltip title={selectedUser.botName}>
																	<span className='caption text-flow'>
																		{selectedUser.botName}
																	</span>
																</Tooltip>
															</div>
														) : null}
													</div>
													<div className='header-right'>
														<span className='end-chat-button'>
															<Button
																buttonType='light'
																onClick={() => {
																	navigate('release');
																}}>
																End Chat
															</Button>
														</span>
													</div>
												</div>
												<div className='ff-chats-holder-agent-dashboard'>
													<div className='single-chat-container'>
														<div className='single-chat-content'>
															{/* {!transcriptLoader && */}
															{chats.length && chats.length > 0 ? (
																Object.keys(messagesByTime).map(
																	(key, index) => (
																		<>
																			<Fragment key={key}>
																				{messagesByTime[key].length > 0 && (
																					<span className='timestamp'>
																						{isNaN(parseInt(key))
																							? key
																							: format(
																								toDate(parseInt(key)),
																								'dd/MM/yyyy'
																							)}
																					</span>
																				)}
																				{messagesByTime[key].map((msg, i) => (
																					<>
																						<ChatBubble
																							key={msg.createdDate! + index}
																							msgObj={msg}
																							channel={selectedUser.channel.toLowerCase()}
																						/>
																						{key === 'today' ? (
																							messagesByTime[key].length - 1 ===
																								i ? (
																								<div
																									className='ref'
																									ref={scrollRef}></div>
																							) : null
																						) : null}
																					</>
																				))}
																			</Fragment>
																		</>
																	)
																)
															) : (
																<div className='transcript-loader'>
																	<FFLoader width={'20'} height={'20'} />
																</div>
															)}
														</div>
													</div>
												</div>
												<div className='input-footer'>
													<Input
														autoFocus
														fullWidth
														multiline
														value={value}
														disabled={showInputBar ? true : false}
														placeholder='Enter your message'
														endIcon={
															value ? (
																<SendButton
																	className='send-button'
																	onClick={() => {
																		if (value.length !== 0) {
																			sendAgentInput(value);
																		}
																	}}
																/>
															) : null
														}
														onChange={(event: any) =>
															setValue(event.target.value)
														}
														autoComplete='false'
														onKeyUp={(event: any) => {
															if (event.key === 'Enter') {
																if (event.shiftKey) {
																	// ignore for shift + enter
																} else {
																	if (value.trim().length !== 0) {
																		sendAgentInput(value);
																	}
																}
															}
														}}
														onKeyDown={(e: any) => {
															if (e.key === 'Enter') {
																e.preventDefault();
															}
														}}
													/>
												</div>
											</div>
										) : null}
									</div>
								) : (
									<div className='no-user-selected'></div>
								)}
							</div>
							<Outlet context={{ handleEndChat }} />
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default Chats;
