import React, { useEffect, useState } from 'react';
import './SubscriptionDetails.scss';
import Button from '../../../Components/Button';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import { Tooltip } from '@mui/material';
import { WebsiteLogo } from '../../Chats/Icons';
import whatsapp from '../../../assets/images/whatsapp.png';
import instagram from '../../../assets/images/instagram.png';
import {
	getWabaNos,
	getWabaNosKotak,
} from '../../../Features/WhatsApp-BSP/auth/authSlice';
import { useNavigate } from 'react-router';
import { getOriginData } from '../../../Features/channel/channelSlice';
import {
	NumberFormattinginLocale,
	kotakTenantCheckForWhatsapp,
	loadState,
	removeState,
	saveState,
} from '../../../utils/utils';
import { setGeneralSelectedTab } from '../../../Features/teamsetting/teamsettingSlice';
import {
	renewPlan,
	subscriptionStatusCheck,
} from '../../../Features/Subscription/subscriptionSlice';
import RedirectLoader from '../../RedirectLoader';

const SubscriptionDetails = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { websiteOriginList } = useSelector(
		(state: RootState) => state.channel
	);
	const { instaIds, wabaNos } = useSelector(
		(state: RootState) => state.BSPauth
	);
	const { generateLeadsDestination } = useSelector(
		(state: RootState) => state.experienceonweb
	);
	const { planName, conversation, planExpiry, renewPlanAPIStatus, channel } =
		useSelector((state: RootState) => state.subscription);
	const { emailData } = useSelector((state: RootState) => state.teamsetting);
	const [showContent, setShowContent] = useState(false);
	const [retryLoader, setRetryLoader] = useState(false);

	const [progressWidth, setProgressWidth] = useState(
		conversation.total - conversation.remaining
			? Number(
					(
						((conversation.total - conversation.remaining) /
							conversation.total) *
						100
					).toFixed(2)
			  )
			: 0
	);
	const channelDetails = (channel: string, name: string) => {
		return (
			<div className={`incoming-channel ${channel}`} key={channel + name}>
				<div className='channel-icon'>
					{channel === 'web' ? (
						<WebsiteLogo />
					) : channel === 'instagram' ? (
						<img alt='' src={instagram} />
					) : (
						<img alt='' src={whatsapp} />
					)}
				</div>
				<div className='caption'>
					<Tooltip title={name}>
						<div className='text-flow'>{name}</div>
					</Tooltip>
				</div>
			</div>
		);
	};

	const channelCounts = () => {
		return websiteOriginList.length + wabaNos.length + instaIds.length;
	};

	const freePlan = () => {
		return (
			loadState('planName') &&
			loadState('planName').toLowerCase().indexOf('free') > -1
		);
	};

	useEffect(() => {
		kotakTenantCheckForWhatsapp(`${tenantId}`)
			? dispatch(getWabaNosKotak({ tenantId: tenantId }))
			: dispatch(getWabaNos({ tenantId: tenantId }));
		dispatch(getOriginData({ tenantId: tenantId! }));
		dispatch(subscriptionStatusCheck({ tenantId: tenantId! }));
		if (loadState('shopifyShopName')) {
			const params = new URLSearchParams(window.location.search.substring(1));
			let chargeId = params.get('charge_id');
			if (chargeId) {
				saveState('chargeId', chargeId);

				dispatch(
					renewPlan({
						chargeId: chargeId ?? loadState('chargeId'),
					})
				);
			} else {
				setShowContent(true);
			}
		} else {
			setShowContent(true);
		}
		if (retryLoader && loadState('shopifyShopName') && loadState('chargeId')) {
			dispatch(
				renewPlan({
					chargeId: loadState('chargeId'),
				})
			);
		}
	}, []);

	useEffect(() => {
		if (renewPlanAPIStatus === 'success') {
			dispatch(subscriptionStatusCheck({ tenantId: tenantId! }));
			setShowContent(true);
			setRetryLoader(false);
			removeState('chargeId');
		} else if (renewPlanAPIStatus === 'failed') {
			setShowContent(false);
			setRetryLoader(true);
		}
	}, [renewPlanAPIStatus]);
	return (
		<div
			className={`ff-subscription-details ${
				showContent ? '' : 'shopifyLoader'
			}`}>
			{showContent ? (
				<>
					<h4>Subscription</h4>
					<div className='subscription'>
						<h5>{planName}</h5>
						<p className='body2 ending'>
							Ends on {planExpiry} |{' '}
							<button
								style={{ color: 'var(--blue-violet-600)' }}
								onClick={() =>
									navigate('/app/select-plan', { state: { via: 'modify' } })
								}>
								View plan details
							</button>
						</p>
						<div
							className={`subscription-change-buttons ${
								freePlan() ? 'free' : 'notfree'
							} ${loadState('shopifyShopName') ? 'shopify' : ''}`}>
							<Button
								disabled={loadState('shopifyShopName') === undefined}
								buttonType='light'
								onClick={() => {
									navigate('/app/select-plan', { state: { via: 'modify' } });
								}}>
								{freePlan() ? 'Upgrade' : 'Modify Plan'}
							</Button>
							<Button
								className='cancel-subscription'
								buttonType='transparent'
								disabled>
								Cancel subscription
							</Button>
						</div>
						{freePlan() ? null : (
							<div className='engagement-sales-routing'>
								<div className='engagement'>
									<div className='count'>
										<p className='body1'>Customer Engagements This Month</p>
										<p
											className={`number-of-chats ${
												progressWidth >= 100 ? 'maxLimit' : ''
											}`}>
											<span className='body1'>
												{conversation
													? NumberFormattinginLocale(
															conversation.total - conversation.remaining
													  )
													: '17,897'}{' '}
												/{' '}
											</span>
											<span className='caption'>
												{conversation
													? NumberFormattinginLocale(conversation.total)
													: '25,000'}{' '}
												({isNaN(progressWidth) ? 0 : progressWidth}%)
											</span>
										</p>
									</div>

									<p className='body2'>
										This is the number of customer engagements that you have had
										out of the total that you can have in one month as per your
										subscribed plan. Next resets on{' '}
										{planExpiry.split(',')[0].split(' ').reverse().join('th ')}.
									</p>
									<div className='counting-bar'>
										<div
											className={`progress ${
												progressWidth >= 100 ? 'maxLimit' : ''
											}`}
											style={{ width: `${progressWidth}%` }}></div>
									</div>
									{progressWidth >= 100 ? (
										<div className='limit-reached'>
											<div className='icon'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='71.999'
													height='72'
													viewBox='0 0 71.999 72'>
													<path
														id='icon'
														d='M61.513,25.514a36,36,0,1,0,36,36A36.042,36.042,0,0,0,61.513,25.514Zm3.462,56.748H58.05V75.593h6.926Zm0-13.993H58.05v-27.5h6.926Z'
														transform='translate(-25.514 -25.514)'
														fill='#c12774'
													/>
												</svg>
											</div>
											<div className='text'>
												<p className='body2'>
													You have utilized 100% of customer engagements for
													this month under your current plan. Your sales
													channels have been paused and can be enabled once this
													limit is reset on{' '}
													{planExpiry
														.split(',')[0]
														.split(' ')
														.reverse()
														.join('th ')}
													.
												</p>
												<p className='body2'>
													To increase the limit, please upgrade your plan or
													reach out to us on support@flyfish.ai
												</p>
											</div>
										</div>
									) : null}
								</div>
								<hr className={`${progressWidth >= 100 ? 'maxLimit' : ''}`} />
								<div className='sales-channel'>
									<div className='sales'>
										<p className='body1'>Active Sales Channels</p>
										<p className='number-of-channels'>
											<span className='body1'>
												{channel.total - channel.remaining}
											</span>
											/<span className='caption'>{channel.total}</span>
										</p>
									</div>

									<div className='active-channel'>
										<div className='channels'>
											{channelCounts() ? (
												<>
													{websiteOriginList &&
														websiteOriginList.map(
															(originData) =>
																originData.origin &&
																channelDetails('web', originData.origin)
														)}

													{wabaNos &&
														wabaNos.map(
															(whatsappData) =>
																whatsappData.wabaNo &&
																channelDetails('whatsapp', whatsappData.wabaNo)
														)}
													{instaIds &&
														instaIds.map(
															(instaData) =>
																instaData.instaUsername &&
																channelDetails(
																	'instagram',
																	instaData.instaUsername
																)
														)}
												</>
											) : (
												`You don't have any active sales channels`
											)}
										</div>
										<div
											className='body2'
											onClick={() => navigate('/app/launch')}>
											Manage
										</div>
									</div>
								</div>
								<hr />
								<div className='lead-routing'>
									<div className='leads'>
										<p className='body1'>Lead Routing</p>
										<p className='body1'>
											{generateLeadsDestination &&
											generateLeadsDestination.value
												? 'Active'
												: 'Inactive'}
										</p>
									</div>

									<div className='routing-crms'>
										<div className='body2'>
											{generateLeadsDestination &&
											generateLeadsDestination.value
												? `You can route your leads to email ${
														planName.toLowerCase().indexOf('advanced') > -1
															? ''
															: 'or a CRM'
												  }. Currently your leads are being routed to
												${
													generateLeadsDestination &&
													generateLeadsDestination.value.toLowerCase() ===
														'email'
														? `${emailData.crmUserId}`
														: generateLeadsDestination.value
												}`
												: `You can route your leads to email ${
														planName.toLowerCase().indexOf('advanced') > -1
															? ''
															: 'or a CRM'
												  }`}
										</div>
										<div
											className='body2'
											onClick={() => {
												saveState('selectedGeneralTab', 'All');
												dispatch(setGeneralSelectedTab('All'));
												navigate('/app/general-configuration/Integrations/all');
											}}>
											Manage
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</>
			) : (
				<RedirectLoader
					showRetry={retryLoader}
					message={'Fetching your payment details from Shopify. Please wait'}
				/>
			)}
		</div>
	);
};

export default SubscriptionDetails;
