import { useLocation, useNavigate } from 'react-router-dom';

import whatsAppBspRoutes from '../../Pages/WhatsAppBSP/whatsAppBspRoutes';

import './Header2.scss';

interface Props {
	leftComponent?: JSX.Element;
	breadcrumbs?: {
		disable?: Boolean | undefined;
		title: string;
		path: string;
		role?: string;
	}[];
	activeButtons?: JSX.Element;
	onClose?: Function;
}

const Header2 = ({
	leftComponent = <></>,
	breadcrumbs = [],
	activeButtons = <></>,
	onClose,
}: Props) => {
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<>
			<div className='header2'>
				<div>{leftComponent}</div>
				<ul>
					{breadcrumbs.map((route, i) => (
						<>
							<li
								style={{ pointerEvents: route.disable ? 'none' : 'initial' }}
								className={`body2 ${
									location.pathname.includes(route.path) ? 'active' : ''
								} ${i === 0 ? 'first' : ''} ${
									breadcrumbs.length - 1 === i ? 'last' : ''
								}`}
								key={i.toString()}
								onClick={() => {
									navigate('/' + route.path);
									onClose && onClose();
								}}>
								{route.title}
							</li>
							{breadcrumbs.length - 1 === i ? null : <hr />}
						</>
					))}
				</ul>
				<div className='header2-buttons'>{activeButtons}</div>
			</div>
		</>
	);
};

export default Header2;
