import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	getconversationTableData,
	setSelectedRow,
} from '../../../Features/Analytics/analyticsSlice';
import './conversationTable.scss';
import { DropdownValue } from '../../../Components/Dropdown';
import { ModalClose } from '../../../assets/Icons';
import Table from '../../../Components/Table';
import Pagination from '../../../Components/Table/Pagination';

const options = [
	{
		label: 10,
		value: 10,
	},
	{
		label: 20,
		value: 20,
	},
	{
		label: 30,
		value: 30,
	},
	{
		label: 40,
		value: 40,
	},
];

function ConvosationTable() {
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { activeBot } = useSelector((state: RootState) => state.bots);
	const [page, setPage] = useState(1);
	const limit = 10;
	const [offset, setOffset] = useState((page - 1) * limit);

	const [dataPerpage, setDataPerPage] = useState<DropdownValue | any>(
		options[0]
	);
	const pre: any = 'pre';
	const next: any = 'next';
	const sortOptions = [
		{ value: 'Session ID', label: 'Session ID' },
		{ value: 'IP Address', label: 'IP Address' },
		{ value: 'Country', label: 'Country' },
		{ value: 'Device', label: 'Device' },
		{ value: 'Referrer', label: 'Referrer' },
	];
	const [valueForOptions, SetValueForOptions] = useState(sortOptions[0]);
	const [UpperL, setUpperL] = useState(0);
	const [LowerL, setLowerL] = useState(0);
	const LIMIT = 20;
	const {
		selectedDates,
		analyticsConversationsTable,
		tableFilterSelected,
		showTranscript,
		selectedRow,
		TranscriptData,
		activeAnalyticsBot,
		tableFiltersearch,
	} = useSelector((state: RootState) => state.analytics);
	const dispatch = useAppDispatch();

	const currentRows = analyticsConversationsTable;

	useEffect(() => {
		tenantId &&
			dispatch(
				getconversationTableData({
					startdate: selectedDates[0],
					enddate: selectedDates[1],
					offset: offset,
					maxvalue: 500,
					filterby: tableFilterSelected !== 'Filter' ? tableFilterSelected : '',
					filtervalue: tableFiltersearch !== '' ? tableFiltersearch : 'all',
					timezone: -330,
					tenantId: tenantId,
					botId: activeAnalyticsBot.botId,
				})
			);
	}, [selectedDates, tableFilterSelected, tableFiltersearch]);

	let iframeRef: HTMLIFrameElement | null = null;
	const setIFrameListener = () => {
		iframeRef = document.getElementById(
			'transcriptIFrame'
		) as HTMLIFrameElement;
	};
	const onClickRow = (selectedRow: any) => {
		let filteredArr = analyticsConversationsTable.filter(
			(item: any) => item.sessionID === selectedRow.sessionID
		);

		let result: any = [];
		filteredArr.map((element: any, key: any) => {
			result.push({
				question: element.questions,
				answer: element.answer,
			});
		});

		dispatch(
			setSelectedRow({
				showTranscript: true,
				selectedRow: selectedRow,
				TranscriptData: result,
			})
		);
	};

	let iframeSrc = `${process.env.REACT_APP_API}/analyticResponsive.html?botID=${activeBot.encBotId}`;
	const disableClicks = () => {
		document.querySelectorAll('.msg-wrapper').forEach((item) => {
			item.classList.add('disableBotClick');
		});
		document
			.querySelector('.sf-chatinput-block')
			?.classList.add('disableBotClick');
	};
	const revenueChanges = (currentRows: any) => {
		const newArr = currentRows.map((object: { revenue: number }) => {
			if (object.revenue) {
				let value = object.revenue.toFixed(2);

				return {
					...object,

					revenue: `$${value}`.replace(
						/\B(?=(\d{3})+(?!\d))/g,

						','
					), //NOSONAR
				};
			}

			return object;
		});

		return newArr;
	};
	// const postToIframe = (message: any) => {
	// 	if (!iframeRef) {
	// 		setIFrameListener();
	// 	}

	// 	const iframeWindow = iframeRef && iframeRef.contentWindow;
	// 	const domain = [
	// 		'https://dev.flyfish.ai/',
	// 		'https://uat.flyfish.ai/',
	// 		'https://app.flyfish.ai/',
	// 		'https://preprod.flyfish.ai',
	// 	];
	// 	const currentOrigin = window.location.origin;
	// 	if (domain.indexOf(currentOrigin) > -1) {
	// 		setTimeout(() => {
	// 			iframeWindow && iframeWindow.postMessage(message, currentOrigin);
	// 		}, 1000);
	// 	}
	// };
	useEffect(() => {
		setOffset((page - 1) * limit);
	}, [page, limit]);

	useEffect(() => {
		if (currentRows.length > 0) {
			setUpperL(currentRows.length + offset);
			setLowerL(offset + 1);
		}
	}, [currentRows]);
	const column = [
		{ key: 'session_id', title: 'Session Id', width: 243 },
		{ key: 'ip_address', title: 'Ip Address', width: 243 },
		{ key: 'date_time', title: 'Date & Time', width: 123 },
		{ key: 'country', title: 'Country', width: 181 },
		{ key: 'device', title: 'Device', width: 183 },
		{ key: 'revenue', title: 'Revenue', width: 183 },
		{ key: 'user_rating', title: 'User rating', width: 183 },
		{ key: 'user_comments', title: 'User comments', width: 240 },
	];
	const [total, setTotal] = useState(currentRows.length);
	useEffect(() => {
		setOffset((page - 1) * limit);
	}, [page, limit]);
	return (
		<div style={{ padding: '17px 67px 30px 86px' }}>
			<Table
				onRowClick={(row: any) => {
					onClickRow(row);
				}}
				height={400}
				column={column}
				data={revenueChanges(currentRows)}
				page={offset / LIMIT}
				limit={LIMIT}
				totalCount={currentRows.length}
				isLoading={false}
				pagination={
					<Pagination
						disableforward={100 <= offset + total}
						disableback={offset === 0}
						nextPage={() => {}}
						previousPage={() => {}}
						gotoBegining={() => {}}
						gotoLast={() => {}}
						totalCount={currentRows.length}
						upper={currentRows.length + offset}
						lower={offset + 1}
					/>
				}
			/>
			<div
				className='ff_transcript_container'
				style={{ display: showTranscript ? 'block' : 'none' }}>
				<div className='ff_transcript'>
					<div
						className='ff_transcript_Close'
						onClick={() => {
							dispatch(
								setSelectedRow({ showTranscript: false, selectedRow: '' })
							);
						}}>
						<ModalClose width='14px' height='14px' />
					</div>
					<div className='ff_transcript_title'>Conversation Transcript</div>
					<div className='ff_transcript_sessionId'>
						Session ID: {selectedRow.sessionID}
					</div>
					<div className='ff_transcript_bot'>
						{/* <iframe
							src={iframeSrc}
							className='ff_transcript_Iframe'
							id={'transcriptIFrame'}
							title='bot'
							onLoad={() => {
								disableClicks();
								postToIframe({
									chat: TranscriptData,
								});
							}}></iframe> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ConvosationTable;
