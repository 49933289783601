import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loadState, saveState } from '../../utils/utils';
import { set } from '../message/messageSlice';
import faqService from './faqService';
import { faqData, allFAQsData, saveFAQs, MLDocumentBotData } from './modal';
interface importFAQ {
	bot_id: string;
	tenant_id: string;
	status: string;
}
// export interface categoryLists = {
// 	c_name: string;
// 	c_id: string | number;
// };
export interface dataFAQ {
	category: string;
	question: string;
	answer: string;
	variations: variations[];
	workflow_id: number;
	isEdited: boolean;
	nodeId: number;
	responseId: number;
}
export interface variations {
	variationId: number;
	variation: string;
}
interface suggestions {
	suggestion: string;
	childnodeId: number;
}

export interface categories {
	c_id: number | string;
	c_name: string;
}
export interface State {
	FAQDataStatus: string;
	FAQData: { FAQ: dataFAQ[]; categories: categories[] };
	isDataLoading: boolean;
	isFAQImporting: number;
	AllFAQsStatus: string;
	importURLData: importFAQ[];
	saveFAQsStatus: string;
	saveFAQsResponse: any;
	isSavingFAQData: boolean;
	isImporting: boolean;
	getMLBotStatus: string;
	getMLBotData: any;
	showFAQsuccessPopup: string;
}
const botDetails: any = loadState('botDetails') ? loadState('botDetails') : {};
const tenantId: any = loadState('tenantId')
	? // ?? 0
	  loadState('tenantId')
	: 0;
export const getFAQData = createAsyncThunk<
	any,
	faqData,
	{ rejectValue: string }
>('/v2/create/botUsingFAQ', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await faqService.getFAQData(data);
		saveState('FAQStatus', response.data.status);
		if (response.data) {
			if (response.data.status.toLowerCase() === 'success') {
				dispatch(
					getAllFAQData({
						botid: loadState('botId').toString(),
						tenantid: loadState('tenantId').toString()
							? loadState('tenantId').toString()
							: '',
						// ?? '',
					})
				);
			} else if (response.data.response.toLowerCase() === 'failed') {
				dispatch(set('Something went wrong! Try again later'));
			}
		}
		return response;
	} catch (error) {
		return {};
	}
});
export const getAllFAQData = createAsyncThunk<
	any,
	allFAQsData,
	{ rejectValue: string }
>('/GetAllFAQs', async (data, { rejectWithValue }) => {
	try {
		const response = await faqService.getAllFAQData(data);
		return response.data;
	} catch (error) {
		return {};
	}
});
export const saveAllFAQData = createAsyncThunk<
	any,
	saveFAQs,
	{ rejectValue: string }
>('/SaveAllFAQs', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await faqService.saveAllFAQData(data);

		if (response.data) {
			if (response.data.response.toLowerCase() === 'success') {
				dispatch(
					getAllFAQData({
						botid: loadState('botId').toString(),
						tenantid: loadState('tenantId').toString()
							? loadState('tenantId').toString()
							: '',
						// ?? '',
					})
				);
				dispatch(set('Changes Saved'));
			} else if (response.data.response.toLowerCase() === 'failed') {
				dispatch(set('Something went wrong! Try again later'));
			}
		}
		return response.data;
	} catch (error) {
		return {};
	}
});
export const getMLBot = createAsyncThunk<any, MLDocumentBotData, {}>(
	'/MLDocumentBot/index',
	async (data, { rejectWithValue }) => {
		try {
			const response = await faqService.getMLBotData(data);
			return response.data;
		} catch (error) {
			return {};
		}
	}
);

const initialState: State = {
	FAQDataStatus: '',
	FAQData: { FAQ: [], categories: [] },
	isDataLoading: false,
	isFAQImporting: 0,
	importURLData: [
		// {
		// 	bot_id: '',
		// 	tenant_id: '',
		// 	status: 'success',
		// },
	],
	AllFAQsStatus: '',
	saveFAQsStatus: '',
	saveFAQsResponse: [],
	isSavingFAQData: false,
	isImporting: false,
	getMLBotStatus: '',
	getMLBotData: [],
	showFAQsuccessPopup: '',
};

export const faqSlice = createSlice({
	name: 'faq',
	initialState,
	reducers: {
		FAQdata: (state) => {
			state.FAQData = { FAQ: [], categories: [] };
		},
		resetFAQDataStatus: (state) => {
			state.FAQDataStatus = '';
			state.saveFAQsStatus = '';
		},
		resetShowFAQsuccessPopup: (state, action) => {
			state.showFAQsuccessPopup = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getFAQData.pending, (state) => {
				state.AllFAQsStatus = 'loading';
				state.isFAQImporting = 0;
				state.isImporting = true;
			})
			.addCase(getFAQData.fulfilled, (state, action) => {
				state.importURLData = action.payload.data;
				state.AllFAQsStatus = 'fulfilled';
				// console.log(action.payload.status);
				state.isFAQImporting = action.payload.status;
				state.isImporting = false;
			})
			.addCase(getFAQData.rejected, (state, action) => {
				state.AllFAQsStatus = 'failed';
				state.isFAQImporting = 0;
				state.isImporting = false;
			})
			.addCase(getAllFAQData.pending, (state) => {
				state.FAQDataStatus = 'loading';
				state.isDataLoading = true;
			})
			.addCase(getAllFAQData.fulfilled, (state, action) => {
				// console.log(action.payload);
				state.FAQData = action.payload;
				state.FAQDataStatus = 'fulfilled';
				state.isDataLoading = false;
				state.showFAQsuccessPopup = 'success';
			})
			.addCase(getAllFAQData.rejected, (state, action) => {
				state.AllFAQsStatus = 'failed';
				state.isDataLoading = false;
			})
			.addCase(saveAllFAQData.pending, (state) => {
				state.saveFAQsStatus = 'loading';
				state.isSavingFAQData = true;
			})
			.addCase(saveAllFAQData.fulfilled, (state, action) => {
				state.saveFAQsResponse = action.payload;
				state.saveFAQsStatus = 'fulfilled';
				state.isSavingFAQData = false;
			})
			.addCase(saveAllFAQData.rejected, (state, action) => {
				state.saveFAQsStatus = 'failed';
				state.isSavingFAQData = false;
			})
			.addCase(getMLBot.pending, (state) => {
				state.getMLBotStatus = 'loading';
			})
			.addCase(getMLBot.fulfilled, (state, action) => {
				state.getMLBotData = action.payload;
				state.getMLBotStatus = 'fulfilled';
			})
			.addCase(getMLBot.rejected, (state, action) => {
				state.getMLBotStatus = 'failed';
			});
	},
});

export const { FAQdata, resetFAQDataStatus, resetShowFAQsuccessPopup } =
	faqSlice.actions;

export default faqSlice.reducer;
