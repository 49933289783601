import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/store';
import './ExpertAvailability.scss';
import Modal from '../../Components/Modal';
import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';
import {
	CheckBoxChecked,
	CheckBoxUnChecked,
	CalendarDateIcon,
	ClockIcon,
	SelectedToggleIcon,
	UnSelectedToggleIcon,
	EditIcon,
} from '../../assets/Icons/LiveChatConfigIcons/index';

import { getAvailability } from '../../Features/ChatWithHuman/ChatWithHumanSlice';
//import AgentAvailability from '../Configuration/LiveChat/LiveChatConfig/AgentAvailability';
import { AgentAvailability } from './AgentAvailability';
import TimezoneList from '../Configuration/LiveChat/LiveChatConfig/TimezoneList';
import Input from '../../Components/Input';
import {
	setEmailToNotify,
	setNotifyExpertsAvailability,
	setAgentAvailability,
	setAgentTimeZone,
	setAgentTimingSchedule,
	getAIDetails,
} from '../../Features/ExperienceOnWeb/experienceonwebSlice';
import { loadState, toLocalTime } from '../../utils/utils';

export function ExpertsAvailability() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [showAgentAvailability, setAvailability] = useState(false);
	const [agentTime, setAgentTime] = useState<{ [key: string]: any }>([]);

	const [availableType, setavailableType] = useState('');

	const [showTimeZoneList, setshowTimeZone] = useState(false);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const { notify, emailtonotify, agentAvailabilityTime, agentTimeZone } =
		useSelector((state: RootState) => state.experienceonweb);

	const [availabilityList, setavailabilityList] = useState(
		agentAvailabilityTime.weekDays ?? [{ time: '24 hrs', day: 'Mon-Sun' }]
	);

	useEffect(() => {
		if (
			agentAvailabilityTime.weekDays &&
			agentAvailabilityTime.weekDays.length > 0
		) {
			if (agentAvailabilityTime) {
				let list =
					agentAvailabilityTime &&
					agentAvailabilityTime.weekDays.map(
						(data: { startTime: string; endTime: string }) => {
							let dayTimeStart = toLocalTime(
								data.startTime.split(',')[1],
								agentTimeZone.split(' ')[0].replace('(', '').replace(')', ''),
								data.startTime.split(',')[0]
							);
							let dayTimeEnd = toLocalTime(
								data.endTime.split(',')[1],
								agentTimeZone.split(' ')[0].replace('(', '').replace(')', ''),
								data.endTime.split(',')[0]
							);
							let timeStartPM =
								Number(dayTimeStart.split(',')[1].split(':')[0]) > 12
									? 'PM'
									: 'AM';
							let timeEndPM =
								Number(dayTimeEnd.split(',')[1].split(':')[0]) > 12
									? 'PM'
									: 'AM';

							let timeStart = dayTimeStart.split(',')[1];
							let timeEnd = dayTimeEnd.split(',')[1];
							let finalTimeStart =
								Number(timeStart.split(':')[0]) > 12
									? Number(timeStart.split(':')[0]) - 12
									: Number(timeStart.split(':')[0]);
							let finalTimeEnd =
								Number(timeEnd.split(':')[0]) > 12
									? Number(timeEnd.split(':')[0]) - 12
									: Number(timeEnd.split(':')[0]);

							let joinStart = `${finalTimeStart.toString().padStart(2, '0')}:${
								timeStart.split(':')[1]
							} ${timeStartPM}`;
							let joinEnd = `${finalTimeEnd.toString().padStart(2, '0')}:${
								timeEnd.split(':')[1]
							} ${timeEndPM}`;

							return {
								time: `${joinStart}-${joinEnd}`,
								day: `${data.startTime.split(',')[0]}`,
							};
						}
					);
				setavailabilityList(list);
				setAgentTime(list);
			}
		} else {
			setAgentTime([
				{
					days: 'Mon-Sun',
					time: '24 hrs',
				},
			]);
			setavailableType('default');
			dispatch(setAgentTimeZone('(GMT+5:30) Asia/Kolkata'));
		}
	}, [agentAvailabilityTime]);

	const handleDisplaySelectedTimezone = (timezone: string) => {
		dispatch(setAgentTimeZone(timezone));
	};
	return (
		<div className='ff-expert-availability-conatiner'>
			<div className='timing-availability'>
				<div className='general-tab-w'>
					<div className='grid-system'>
						<div className='config-data-wrapper'>
							<div className='ff-availability'>
								<div className='ff-live-chat-config-header body2'>
									<h6>Timings</h6>
								</div>
								<div>
									<div className='working-hrs caption'>
										<p className='body1'>
											Handoff will be triggered by the AIs only during these
											timings
										</p>
									</div>
									<div>
										<div className='agent-availability'>
											<table className='agent-availability-data'>
												<thead>
													<tr className='agent-availability-header'>
														<th>
															<div className='th-align'>
																<div style={{ marginRight: '5px' }}>
																	<CalendarDateIcon />
																</div>
																<div>Working days</div>
															</div>
														</th>
														<th>
															<div className='th-align'>
																<div style={{ marginRight: '5px' }}>
																	<ClockIcon />
																</div>
																Working Hours
															</div>
														</th>
														<th>
															<div
																style={{ display: 'flex' }}
																onClick={() => {
																	setAvailability(true);
																}}
																className='pointing-icon'>
																<EditIcon />
															</div>
														</th>
													</tr>
												</thead>
												<tbody>
													{agentTime.map((data: any, index: number) => {
														return (
															<tr
																style={{
																	fontSize: '14px',
																	letterSpacing: '-0.21px',
																}}
																key={index}>
																<td>{data.day || data.days || data.name}</td>
																<td>{data.time}</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									</div>
									{showAgentAvailability && (
										<Modal
											headerCloseBtn={true}
											actionButtons={false}
											header='Agent availability'
											className='agent-avaialability-modal'
											onClose={() => {
												setAvailability(false);
											}}>
											<AgentAvailability
												selectedTenantId={tenantId || 0}
												onSave={(val: any) => {
													// console.log(val);
													setAvailability(!showAgentAvailability);

													if (val.weekDays) {
														setAgentTime(val.weekDays);
													} else {
														setAgentTime(val);
													}
													dispatch(setAgentTimingSchedule(val));
													// dispatch(setAgentAvailability(val));
												}}
												agentAvailabilityList={availabilityList}
												availabilityType={availableType}
											/>
										</Modal>
									)}
								</div>
							</div>
							<div className='ff-handOff-config-border'></div>
							<div className='timezone-wrapper'>
								<div className='timezone body1'>
									<h6>Time zone</h6>
								</div>
								<div className='working-hrs caption'>
									<p className='body1'>
										Select the time zone in which your experts work
									</p>
								</div>
								<div className='timezone-data '>
									<div
										className='display-timezone pointing-icon'
										onClick={() => {
											setshowTimeZone(true);
										}}>
										{agentTimeZone}
									</div>
								</div>
								{showTimeZoneList && (
									<Modal
										actionButtons={false}
										header='Select time zone'
										onClose={() => {
											setshowTimeZone(false);
										}}>
										<TimezoneList
											onClose={() => {
												setshowTimeZone(false);
											}}
											displaySelectedTimezone={handleDisplaySelectedTimezone}
											selectedTenantId={tenantId || 0}
											selectedTimeZone={agentTimeZone}
										/>
									</Modal>
								)}
							</div>
							<div className='ff-handOff-config-border'></div>

							<div className='ff-availability-mail'>
								<div
									className='ff-handOff-config-content'
									style={{ marginTop: '35px' }}>
									<h6 style={{ marginBottom: '16px', marginLeft: '12px' }}>
										Expert Unavailability During Working Hours
									</h6>
									<div className='ff-handOff-config-checkbbox'>
										<div
											onClick={() => {
												dispatch(setNotifyExpertsAvailability(!notify));
											}}
											style={{ cursor: 'pointer' }}>
											{notify ? <CheckBoxChecked /> : <CheckBoxUnChecked />}
										</div>
										<div>
											<div>
												<p className='body1'>
													Notify on email if users are waiting
												</p>
											</div>
											<div>
												<p className='body2'>
													If no expert is online during working hours, an email
													notification will be sent to the email address you
													provide.
												</p>
											</div>

											<div
												className={`ff-handOff-config-input ${
													notify ? 'show' : ''
												}`}>
												<div>
													<Input
														label='Email address'
														placeholder='Enter your Email address'
														value={emailtonotify}
														onChange={(e) =>
															dispatch(setEmailToNotify(e.target.value))
														}
														autoFocus
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
