import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../app/store';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import {
	BOTLISTDATA,
	GetAllUsersMyhubResponse,
} from '../../../../../Features/teamsetting/modal';
import { updateUserRoleMyhub } from '../../../../../Features/teamsetting/teamsettingSlice';
import { roleIds } from '../../../../../utils/constants';

const MakeTeamAdmin = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const state = location.state as {
		user: GetAllUsersMyhubResponse;
		allBotData: BOTLISTDATA[];
	};
	const { UsersMyhubbotRolesList } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const makeTeamAdmin = () => {
		let data = {
			botsRoleList: [
				{
					botId: state.user.botRoles[0].botId,
					roleId: roleIds.roleIdTeamAdmin,
				},
			],
			tenantId: tenantId!,
			userId: UsersMyhubbotRolesList.allUsers.filter(
				(v: GetAllUsersMyhubResponse) => v.userEmail === state.user.userEmail
			)[0].userId,
		};
		dispatch(updateUserRoleMyhub(data));
		navigate(-1);
	};
	const buttons = () => {
		return (
			<>
				<Button onClick={() => navigate(-1)} buttonType='light'>
					Cancel
				</Button>
				<Button onClick={makeTeamAdmin}>Proceed</Button>
			</>
		);
	};
	return (
		<div>
			<Modal header='Make user team admin?' actionButtons={buttons()}>
				<div>
					Please confirm that you want to make <b>{state.user.userEmail}</b> a
					team admin.
				</div>
			</Modal>
		</div>
	);
};

export default MakeTeamAdmin;
