import { useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../app/store';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import {
	BasicsTick,
	BasicsX,
	LoginEyeClosed,
	LoginEyeOpen,
} from '../../assets/Icons';
import ResetPasswordImage from '../../assets/images/landing_page/login-image.png';
// import maleWithSMSIcon from '../../assets/images/male-with-sms-icon.png';
import logo from '../../assets/images/logo.png';

import './ResetPassword.scss';
import {
	setErrorMessage,
	setResetPasswordSuccess,
	updatepassword,
} from '../../Features/resetpassword/resetPasswordSlice';
import { useSelector } from 'react-redux';
import { encryptText } from '../../utils/utils';
import WelcomeImg from '../../assets/images/side_welcome.svg';

const ResetPassword = () => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isPasswordVisibleCheck, setIsPasswordVisibleCheck] = useState(false);
	const [checkPassword, setCheckPassword] = useState('');
	const [password, setPassword] = useState('');
	const dispatch = useAppDispatch();
	const { resetLoader, resetPasswordSuccess, error } = useSelector(
		(state: RootState) => state.resetPassword
	);

	function getParamValFromURL(Id: string) {
		Id = Id.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); //NOSONAR
		var regex = new RegExp('[\\?&]' + Id + '=([^&#]*)'); //NOSONAR
		var results = regex.exec(window.location.search);
		return results === null
			? ''
			: decodeURIComponent(results[1].replace(/\+/g, ' '));
	}
	const userId = getParamValFromURL('userId');
	const resetPasswordToken = getParamValFromURL('resetPassword');
	const passwordValidator = (): { label: string; hasPassed: boolean }[] => {
		return [
			{
				label: '8-30 characters',
				hasPassed: password.length >= 8 && password.length <= 30,
			},

			{
				label: 'At least one uppercase character',
				hasPassed: password.search(/[A-Z]/) > -1,
			},
			{
				label: 'At least one lowercase character',
				hasPassed: password.search(/[a-z]/) > -1,
			},
			{
				label: 'At least one number',
				hasPassed: password.search(/\d/) > -1,
			},
			{
				label: 'At least one special character',
				hasPassed: password.search(/[!@#$%^&+=*]/) > -1,
			},
			{
				label: 'Please make sure that both values match',
				hasPassed: password === checkPassword && password.length !== 0,
			},
		];
	};

	const verifyPassword = () => {
		return passwordValidator().some((validator) => !validator.hasPassed);
	};
	const navigate = useNavigate();

	useEffect(() => {
		resetPasswordSuccess && navigate('/app/login');
	}, [resetPasswordSuccess]);

	return (
		<div className='ff-reset-password-container'>
			<div className='reset-left-container'>
				<img src={WelcomeImg} alt='' />
			</div>
			<div className='reset-right-container'>
				<div className='form'>
					<div className='reset-form-wrapper'>
						<h4>Create New Password</h4>
						{resetPasswordSuccess ? (
							<div className='success-message'>
								Password Reset Successful please go to Login
							</div>
						) : (
							<div className='body2 red'>{error}</div>
						)}
						<div className='input-container-signup'>
							<div className='input-container'>
								<Input
									fullWidth
									type={isPasswordVisible ? 'text' : 'password'}
									value={password}
									onChange={(event) => setPassword(event.target.value)}
									placeholder='Enter new password'
									endIcon={
										<div
											className='toggle-password-show'
											onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
											{isPasswordVisible ? (
												<LoginEyeClosed width='19px' />
											) : (
												<LoginEyeOpen width='19px' />
											)}
										</div>
									}
									autoFocus
								/>
							</div>
						</div>
						<div className='input-container-signup'>
							<div className='input-container'>
								<Input
									fullWidth
									type={isPasswordVisible ? 'text' : 'password'}
									value={checkPassword}
									onChange={(event) => setCheckPassword(event.target.value)}
									placeholder='Confirm password'
									endIcon={
										<div
											className='toggle-password-show'
											onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
											{isPasswordVisible ? (
												<LoginEyeClosed width='19px' />
											) : (
												<LoginEyeOpen width='19px' />
											)}
										</div>
									}
								/>
							</div>
						</div>

						<div className='pwd-section'>
							<span className='password-heading' style={{ paddingTop: '16px' }}>
								Your password must have
							</span>

							<ul className='password-label-not-show'>
								{passwordValidator().map((validator, index) => (
									<li
										title={
											// index === 5
											// 	? 'Currently only @, #, $, %, ^, & are allowed' :
											validator.label
										}
										key={index}
										className={
											password.length > 0 || checkPassword.length > 0
												? 'list-butn'
												: ''
										}>
										<div className='pass-li'>
											{password.length > 0 || checkPassword.length > 0 ? (
												<div>
													{validator.hasPassed ? (
														<BasicsTick width='14px' height='14px' />
													) : (
														<BasicsX width='13px' height='13px' />
													)}
												</div>
											) : null}

											{validator.label}
											{/* {index === 5 ? (
																<span
																	onClick={() =>
																		window.open(
																			'https://owasp.org/www-community/password-special-characters'
																		)
																	}
																	style={{
																		textDecoration: 'none',
																		color: 'blue',
																		cursor: 'pointer',
																	}}>
																	&nbsp;special character
																</span>
															) : null} */}
										</div>
									</li>
								))}
							</ul>

							<span className='password-heading'>For Example</span>

							<ul className='password-example extra'>
								<li>N3w.4ge@897</li>
								<li>MissionMar$4</li>
							</ul>
						</div>

						<div className='button-container'>
							<Button
								disabled={verifyPassword()}
								loading={resetLoader}
								fullWidth
								size='large'
								onClick={() => {
									dispatch(setErrorMessage(''));
									dispatch(setResetPasswordSuccess(false));
									let user = userId;
									let token = resetPasswordToken;
									dispatch(
										updatepassword({
											confirmPassword: encryptText(checkPassword),
											password: encryptText(password),
											userId: user,
											resetPasswordToken: token,
										})
									);
								}}>
								Submit
							</Button>
						</div>
					</div>
					<div className='helper-links caption'>
						<p className='caption'>
							Go to <Link to='/app/login'>Sign In</Link>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
