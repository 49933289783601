import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Outlet, useOutletContext } from 'react-router-dom';
// import { RootState, useAppDispatch } from '../../app/store';
import Header2 from '../../Components/Header2';
import IngestHeader from '../../Components/IngestHeader';
import '../Ingest/ingest.scss';
import Button from '../../Components/Button';

const IngestProduct = ({}) => {
	const navigate = useNavigate();
	const [disable, setdisable] = useState('Next');
	const path = window.location.href;

	return (
		<div className='ff-ingest-features-cnt'>
			<div className='ff-ingest-container'>
				<IngestHeader
					breadCrumbs={[
						{
							title: '1. Select Platform',
							path: 'app/Ingest/link-product/select-platform',
						},
						{
							title: '2. Link Platform',
							path: 'app/Ingest/link-product/link-platform',
							disable: true,
						},
					]}
					header='Link Product Catalog'
					prevButtonName='Back'
					prevButtonNav={() => {
						path.indexOf('select-platform') > -1
							? navigate('/app/Ingest')
							: navigate('/app/Ingest/link-product/select-platform');
					}}

					// nextButtonNav={() => {
					// 	path.indexOf('select-platform') > -1
					// 		? navigate('/app/Ingest/link-product/link-platform/shopify')
					// 		: navigate(-1);
					// }}
					// nextButtonName={(path.indexOf('/app/Ingest/link-product/select-platform') > -1) ? "Next" : ""}
				/>

				<Outlet context={setdisable} />
			</div>
		</div>
	);
};
export default IngestProduct;
