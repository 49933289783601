// export interface NotificationPermission {
//   NotificationPermission: "default" | "denied" | "granted" | string;
// }

function push(message: string) {
  new Notification("New Notification", {
    body: `${message}`
  });
}

export function notificationInit(message: string) {
  if (window.Notification) {
    if (needPermission()) {
      request().then((permission: any) => {
        if (isPermissionGranted(permission)) {
          push(message);
        }
      });
    } else {
      push(message);
    }
  }
}
function needPermission() {
  return Notification.permission !== "granted";
}
function isPermissionGranted(permission: NotificationPermission) {
  return permission === "granted";
}
function request() {
  return new Promise(resolve => {
    Notification.requestPermission().then(permission => {
      resolve(permission);
    });
  });
}
