import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../app/store';
import Modal from '../../../../Components/Modal';
import Input from '../../../../Components/Input';
import Autocomplete from '../../../../Components/AutoComplete';
import Button from '../../../../Components/Button';
import DateInput from '../../../../Components/DatePicker';
import TimeInput from '../../../../Components/TimePicker';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { format, isPast, isValid } from 'date-fns';
import JobSheetModal from './JobSheet';
import {
	getList as getApprovedTemplates,
	reset,
} from '../../../../Features/WhatsApp-BSP/template/templateSlice';
import './index.scss';
import { useSelector } from 'react-redux';
import {
	createCampaign,
	excelValidation,
	getCampaigns,
	getCampaignsKotak,
	getInProgressCampaigns,
	getInProgressCampaignsKotak,
	getScheduledCampaigns,
	getScheduledCampaignsKotak,
	getTemplateFormatExcel,
	resetCreate,
} from '../../../../Features/WhatsApp-BSP/campaign/campaignSlice';
import { Box } from '@mui/system';
import ErrorIcon from '@mui/icons-material/Error';
import DataFetchAPI from '../../../../utils/Api';
import SelectMediaModal from './SelectMedia';
import { set, setSnackModal } from '../../../../Features/message/messageSlice';
import {
	ExcelTOJSON,
	isHTMLTagsPresent,
	isValidDate,
	kotakTenantCheckForWhatsapp,
	loadState,
	saveState,
} from '../../../../utils/utils';

type TemplateListItem = {
	body: string;
	buttons?: {}[];
	headerType?: string;
	language: string;
	lastUpdatetime: string;
	status: string;
	templateId: number;
	templateName: string;
	type: string;
	wabaNo: string;
};
type PrevMedia = {
	mediaId: string;
	mediaName: string;
};

const Create = () => {
	const navigate = useNavigate();
	const [scrollToBottom, setScrollToBottom] = useState(false);
	const divRef = useRef<HTMLDivElement>(null);
	const { downloadSheetCreateCampaign, uploadedFileId } = useSelector(
		(state: RootState) => state.campaign
	);
	const [campaignName, setCampaignName] = useState('');
	const [campaignNameError, setCampaignNameError] = useState<
		string | undefined
	>(undefined);
	const [description, setDescription] = useState<string | undefined>(undefined);
	const [descriptionError, setdescriptionError] = useState<string | undefined>(
		undefined
	);
	const [template, setTemplate] = useState<TemplateListItem | null>(null);
	const [templateError, setTemplateError] = useState<string | undefined>(
		undefined
	);
	const [language, setLanguage] = useState<TemplateListItem | null>(null);
	const [languageError, setLanguageError] = useState<string | undefined>(
		undefined
	);

	const [selectMedia, setSelectMedia] = useState<boolean>(false);
	const [headerData, setHeaderData] = useState<string | null>(null);
	const fileInput = useRef(null);
	const [xlsxFileName, setXlsxFileName] = useState('');
	const [selectedxlsmFile, setSelectedxlsmFile] = useState<any>(null);
	const [xlsxfileError, setXlsxfileError] = useState('');
	const [learnMore, setLearnMore] = useState(false);
	const [offset, setOffset] = useState(0);
	const [scheduleCampaign, setScheduleCampaign] = useState<string | null>(null);
	const [date, setDate] = useState<Date | null>(null);
	const [time, setTime] = useState<Date | null | any>(null);
	const [isValidTime, setIsValidTime] = useState(false);
	const [dateError, setDateError] = useState<string | undefined>('');
	const [timeError, setTimeError] = useState('');
	const [createBtnDisabled, setCreateBtnDisabled] = useState(true);
	const { wabaNo } = useSelector((state: RootState) => state.BSPauth);
	const [mediaID, setMediaID] = useState('');
	const dispatch = useAppDispatch();

	const {
		list: approvedTemplates,
		total: totalApprovedTemplates,
		isLoading,
	} = useSelector((state: RootState) => state.template);
	const { isCampaignCreated, isExcelValied } = useSelector(
		(state: RootState) => state.campaign
	);

	const templatesData = approvedTemplates?.filter(
		(val, index, arr) =>
			index ===
			arr.findIndex((each: any) => each.templateName === val.templateName)
	);

	const [templetLanguage, setTempletLanguage] = useState<TemplateListItem[]>(
		[]
	);

	useEffect(() => {
		setScrollToBottom(false);
		dispatch(
			getApprovedTemplates({
				offset: 0,
				status: 'APPROVED',
				wabaNo,
			})
		);

		return () => {
			dispatch(resetCreate());
		};
	}, []);

	useEffect(() => {
		if (template) {
			const languages = approvedTemplates?.filter(
				(val: any) => val.templateName === template?.templateName
			);
			setTempletLanguage(languages);
			if (languages.length === 1) setLanguage(languages[0]);
		}
	}, [template]);

	useEffect(() => {
		if (language?.templateId) getTemplateFormat();
	}, [language?.templateId]);

	useEffect(() => {
		if (
			language &&
			language.headerType &&
			language.headerType.toUpperCase() !== 'TEXT' &&
			selectedxlsmFile &&
			headerData
		) {
			// const job = { templateId: language.templateId, headerData, wabaNo };
			const pickedDateAndTime = convertDate(date, time);
			const data = {
				job: {
					title: campaignName,
					description: description ? description : '',
					templateId: language?.templateId ? language?.templateId : '',
					scheduledTime:
						date && time && pickedDateAndTime
							? `${
									new Date(new Date(pickedDateAndTime).toUTCString())
										.toISOString()
										.split('T')[0]
							  } ${
									new Date(new Date(time).toUTCString())
										.toISOString()
										.split('T')[1]
										.split('.')[0]
							  }.0`
							: '',
					headerData: headerData ? headerData : '',
					wabaNo: wabaNo,
				},
				campaign: selectedxlsmFile ? selectedxlsmFile : '',
			};
			dispatch(excelValidation(data));
		}
		if (language && selectedxlsmFile) {
			// const job = { templateId: language.templateId, headerData: '', wabaNo };
			const pickedDateAndTime = convertDate(date, time);
			const data = {
				job: {
					title: campaignName,
					description: description ? description : '',
					templateId: language?.templateId ? language?.templateId : '',
					scheduledTime:
						date && time && pickedDateAndTime
							? `${
									new Date(new Date(pickedDateAndTime).toUTCString())
										.toISOString()
										.split('T')[0]
							  } ${
									new Date(new Date(time).toUTCString())
										.toISOString()
										.split('T')[1]
										.split('.')[0]
							  }.0`
							: '',
					headerData: headerData ? headerData : '',
					wabaNo: wabaNo,
				},
				campaign: selectedxlsmFile ? selectedxlsmFile : '',
			};
			dispatch(excelValidation(data));
		}
	}, [selectedxlsmFile, language, headerData]);

	useEffect(() => {
		if (
			isExcelValied &&
			isExcelValied !== 'success' &&
			isExcelValied !== 'Network Error'
		) {
			setCreateBtnDisabled(true);
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: 'Please upload valid excel',
					modalMode: 'negative',
				})
			);
		} else if (
			isExcelValied &&
			(isExcelValied === 'Something went wrong! Try again later' ||
				isExcelValied === 'Network Error')
		) {
			setCreateBtnDisabled(true);
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: 'Something went wrong! Try again later',
					modalMode: 'negative',
				})
			);
		}
	}, [isExcelValied]);

	useEffect(() => {
		const islablesFilled =
			campaignName &&
			language?.templateId &&
			selectedxlsmFile &&
			date &&
			time &&
			isExcelValied === 'success';
		if (
			islablesFilled &&
			language.headerType &&
			language.headerType.toUpperCase() !== 'TEXT' &&
			isValidTime
		) {
			if (headerData) {
				setCreateBtnDisabled(false);
				return;
			} else {
				setCreateBtnDisabled(true);
				return;
			}
		}
		if (islablesFilled) {
			setCreateBtnDisabled(false);
		}
	}, [
		campaignName,
		language,
		date,
		time,
		headerData,
		selectedxlsmFile,
		isExcelValied,
		isValidTime,
	]);

	useEffect(() => {
		if (isCampaignCreated === 'success') {
			dispatch(
				setSnackModal({ openModal: true, modalMesssage: 'Campaign created' })
			);
			navigate(-1);
		}
		if (
			isCampaignCreated &&
			isCampaignCreated !== 'success' &&
			isCampaignCreated !== 'Network Error'
		) {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: isCampaignCreated,
					modalMode: 'negative',
				})
			);
			navigate(-1);
		}
	}, [isCampaignCreated]);

	// useEffect(() => {
	// 	date && time && handleSetTime(time);
	// }, [date, time, isValidTime]);

	useEffect(() => {
		if (scrollToBottom) {
			divRef.current && divRef.current.scrollIntoView();
		}
		if (scheduleCampaign === 'Start Schedule') {
			setScrollToBottom(false);
		}
	}, [scrollToBottom, scheduleCampaign]);

	const validateTime = (time: any) => {
		if (time && !isValid(time)) {
			setTimeError('Please enter a valid time');
			return;
		}
		if (time && isValid(time)) {
			let tdate = new Date();
			let currenttime = tdate.getTime();
			let selectedtime = time.getTime();
			let d1 = date && format(date, 'dd-MM-yyyy');
			let d2 = format(new Date(), 'dd-MM-yyyy');

			if (d1 === d2) {
				if (selectedtime < currenttime) {
					setTimeError('Please enter a valid time');
				} else {
					setTimeError('');
				}
			} else {
				setTimeError('');
			}
		}
	};

	const validateCampaignName = () => {
		if (campaignName.trim().length <= 0) {
			setCampaignNameError('Required');
		} else {
			setCampaignNameError(undefined);
		}
	};

	const validateDescription = (value: string | null) => {
		if (value && value?.length > 255) {
			setdescriptionError(`Character limit exceeded ${value.length}/255`);
		} else {
			setdescriptionError(undefined);
		}
	};

	const validateTemplate = (_template: string | null) => {
		if (_template && _template.length > 0) {
			if (
				templatesData?.filter((tem) => tem.templateName === _template).length >
				0
			) {
				setTemplateError(undefined);
				return;
			}
		}
		setTemplateError('Required');
	};

	const validateLanguage = (_language: string | null) => {
		if (_language && _language.length > 0) {
			if (
				templetLanguage.filter((lang) => lang.language === _language).length > 0
			) {
				setLanguageError(undefined);
				return;
			}
		}
		setLanguageError('Required');
	};

	const capitalizeFirstLetter = (string: string) => {
		return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
	};

	const getTemplateFormat = () => {
		dispatch(
			getTemplateFormatExcel({
				data: {
					templateId: language?.templateId,
					wabaNo: wabaNo,
				},
			})
		);
	};

	const handlexlsxFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		let files: any = (event.target as HTMLInputElement).files;
		if (files[0]) {
			let { name, size } = files[0];
			if (size <= 1e8) {
				setXlsxFileName(name);
				setXlsxfileError('');
				kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
					? setSelectedxlsmFile(files[0])
					: ExcelTOJSON(files[0], setSelectedxlsmFile);
			} else setXlsxfileError('Your file must be lesser than 100 MB in size');
		}
	};

	const handleSchedule = (event: React.ChangeEvent<HTMLInputElement>) => {
		setScheduleCampaign((event.target as HTMLInputElement).value);
		if (event.target.value === 'Start Schedule') {
			setDate(new Date());
			setTime(new Date());
			setIsValidTime(true);
		}
	};

	const convertDate = (date: Date | null, time: Date | null) => {
		if (date && time) {
			if (time.toString() === 'Invalid Date') {
				const formattedDate = format(date, 'yyyy-MM-dd');
				const formattedTime = new Date().getHours();
				return new Date(`${formattedDate} ${formattedTime}`);
			} else {
				const formattedDate = format(date, 'yyyy-MM-dd');
				const formattedTime = format(time, 'HH:mm:ss');
				return new Date(`${formattedDate} ${formattedTime}`);
			}
		}
	};

	const createCampaignData = () => {
		const pickedDateAndTime = convertDate(date, time);
		const data = {
			job: {
				title: campaignName,
				description: description ? description : '',
				templateId: language?.templateId ? language?.templateId : '',
				scheduledTime:
					date && time && pickedDateAndTime
						? `${
								new Date(new Date(pickedDateAndTime).toUTCString())
									.toISOString()
									.split('T')[0]
						  } ${
								new Date(new Date(time).toUTCString())
									.toISOString()
									.split('T')[1]
									.split('.')[0]
						  }.0`
						: '',
				// date && time
				// 	? `${new Date(time).toISOString().split('T')[0]} ${
				// 			new Date(time).toISOString().split('T')[1].split('.')[0]
				// 	  }.0`
				// 	: '',
				headerData: mediaID ? mediaID : '',
				wabaNo: wabaNo,
			},
			campaign: selectedxlsmFile ? selectedxlsmFile : '',
		};

		dispatch(createCampaign(data));
		setTimeout(() => {
			let payload = {
				page: 0,
				size: 10,
				wabaNo,
				status: 'created',
				filter: '',
			};
			let kotakpayload = {
				offset: 0,
				wabaNo,
				status: 'created',
			};
			kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
				? dispatch(getScheduledCampaignsKotak(kotakpayload))
				: dispatch(getScheduledCampaigns(payload));
		}, 3000);
	};

	const onClickCreateBtn = () => {
		if (scheduleCampaign === 'Start Schedule') {
			createCampaignData();
		} else {
			if (isPast(convertDate(date, time) as Date)) {
				setTimeError('Please enter a future time');
			} else {
				createCampaignData();
			}
		}
	};
	useEffect(() => {
		if (totalApprovedTemplates > offset && offset > 0) {
			dispatch(
				getApprovedTemplates({
					offset: offset,
					status: 'APPROVED',
					wabaNo,
				})
			);
		}
	}, [offset]);
	const handleScrollBottom = () => {
		setOffset(Number(offset) + 20);
	};
	const handleSetTime = (value: Date | null) => {
		if (date && value) {
			if (scheduleCampaign === 'Schedule for later') {
				setIsValidTime(!isPast(convertDate(date, value) as Date));
				validateTime(value);
				setTime(convertDate(date, value) as Date);
			}
		} else {
			setIsValidTime(false);
		}
	};
	return (
		<>
			<Modal
				header='Create new campaign'
				onClose={() => navigate(-1)}
				actionButtons={
					<>
						<Button buttonType='light' onClick={() => navigate(-1)}>
							<p className='button-1'>Close</p>
						</Button>
						<Button
							disabled={
								createBtnDisabled ||
								!Boolean(campaignName.trim().length) ||
								isHTMLTagsPresent(campaignName) ||
								campaignName.trim().length === 0 ||
								Boolean(dateError?.length) ||
								Boolean(timeError?.length)
							}
							onClick={() => {
								onClickCreateBtn();
								setTimeout(() => {
									let payload1 = {
										page: offset,
										size: 10,
										wabaNo,
										status: 'completed',
										filter: '',
									};
									let payload2 = {
										page: 0,
										size: 10,
										wabaNo,
										status: 'in progress',
										filter: '',
									};
									let kotakpayload1 = {
										offset: offset,
										wabaNo,
										status: 'completed',
									};
									let kotakpayload2 = {
										offset: 0,
										wabaNo,
										status: 'in progress',
									};

									// dispatch(
									// 	getScheduledCampaigns({
									// 		page: 0,
									// 		size: 10,
									// 		wabaNo,
									// 		status: 'created',
									// 		filter: '',
									// 	})
									// );
									if (kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)) {
										dispatch(getCampaignsKotak(kotakpayload1));
										dispatch(getInProgressCampaignsKotak(kotakpayload2));
									} else {
										dispatch(getCampaigns(payload1));
										dispatch(getInProgressCampaigns(payload2));
									}
								}, 2000);
							}}>
							<p className='button-1'>Create</p>
						</Button>
					</>
				}>
				<div className='create-campaign'>
					<div className='input-group'>
						<Input
							label='Campaign name*'
							value={campaignName}
							onChange={(event: any) => setCampaignName(event.target.value)}
							error={Boolean(campaignNameError)}
							helperText={campaignNameError}
							onFocus={() => {
								setCampaignNameError(undefined);
							}}
							onBlur={validateCampaignName}
							fullWidth={true}
							autoFocus
						/>
					</div>
					<div className='input-group'>
						<Input
							label='Description (optional)'
							value={description}
							onChange={(event: any) => {
								setDescription(event.target.value);
								validateDescription(event.target.value);
							}}
							error={Boolean(descriptionError)}
							helperText={descriptionError}
							fullWidth={true}
						/>
					</div>
					<div className='input-group scroll'>
						<Autocomplete
							label='Select a template*'
							options={templatesData}
							labelKey='templateName'
							value={template}
							valueKey='templateName'
							onChange={(value) => {
								setTemplate(value);
								setLanguage(null);
								setLanguageError(undefined);
							}}
							error={Boolean(templateError)}
							helperText={templateError}
							onBlur={validateTemplate}
							isLoading={isLoading}
							onScrollBottom={handleScrollBottom}
						/>
					</div>
					{template && campaignName && !Boolean(descriptionError) && (
						<div className='input-group'>
							<Autocomplete
								label='Select template language*'
								options={templetLanguage}
								labelKey='language'
								value={language}
								valueKey='language'
								onChange={setLanguage}
								error={Boolean(languageError)}
								helperText={languageError}
								onBlur={validateLanguage}
							/>
						</div>
					)}
					{template &&
						campaignName &&
						!Boolean(descriptionError) &&
						language && (
							<>
								{template.headerType &&
									template.headerType?.toUpperCase() !== 'TEXT' && (
										<>
											<p className='body1 heading'>
												{template.headerType
													? capitalizeFirstLetter(template.headerType)
													: 'Document'}{' '}
												to be sent in the template*
											</p>
											<p className='caption caption-color'>
												The template you have selected requires a{' '}
												{template.headerType
													? template.headerType.toLocaleLowerCase()
													: 'document'}{' '}
												to be uploaded to be sent with the message.
											</p>
											{!headerData && (
												<p
													className='body1 blue-text upload-file'
													onClick={() => setSelectMedia(true)}>
													Select file
												</p>
											)}
											{headerData && (
												<div className='body1 upload-file'>
													{headerData.split(':')[0] === 'https' ? (
														<a
															href={headerData}
															className='blue-text'
															target='_blank'
															rel='noreferrer'>
															{headerData}
														</a>
													) : (
														<span>{headerData}</span>
													)}{' '}
													|{' '}
													<span
														className='blue-text'
														onClick={() => setSelectMedia(true)}>
														change
													</span>
												</div>
											)}
										</>
									)}
								{selectMedia && (
									<SelectMediaModal
										setSelectMedia={setSelectMedia}
										language={language}
										setHeaderData={setHeaderData}
										setMediaID={setMediaID}
									/>
								)}
								<p className='body1 heading'>Job sheet*</p>
								<p className='caption caption-color'>
									Upload a Microsoft Excel Sheet (.xlsx) with recipient phone
									numbers and the values for template placeholders as the
									columns.{' '}
									<a className='blue-text' onClick={() => setLearnMore(true)}>
										Learn more{' '}
									</a>
									|{' '}
									<a
										className='blue-text'
										href={downloadSheetCreateCampaign}
										rel='noopener noreferrer'
										download={
											template.templateName
												? `${template.templateName}.xlsx`
												: 'template.xlsx'
										}
										target='_blank'>
										Download job sheet format for the selected template
									</a>
								</p>
								<div className='body1 blue-text upload-file'>
									{!xlsxFileName && (
										<label htmlFor='xlsxFile'>Select file</label>
									)}
									{xlsxFileName && (
										<div className='body1'>
											<span>{xlsxFileName}</span> |{' '}
											<label htmlFor='xlsxFile' className='blue-text'>
												change
											</label>
										</div>
									)}
									<input
										id='xlsxFile'
										ref={() => fileInput}
										className='input-file'
										type='file'
										accept='.xlsx'
										onChange={(event) => {
											handlexlsxFileSelect(event);
											event.target.value = '';
										}}
									/>
									{xlsxfileError && (
										<Box
											component={'span'}
											sx={{
												display: 'flex',
												gap: '4px',
												alignItems: 'center',
												color: '#D16900',
											}}>
											<ErrorIcon sx={{ fontSize: '16px' }} />
											<span className='caption'>{xlsxfileError}</span>
										</Box>
									)}
								</div>
								<p className='body1 heading'>When to run the campaign</p>
								<RadioGroup
									aria-labelledby='scheduleCampaign-group-label'
									name='scheduleCampaign-group'
									value={scheduleCampaign}
									onChange={handleSchedule}>
									<FormControlLabel
										value='Start Schedule'
										control={<Radio />}
										label='Start now'
									/>
									<FormControlLabel
										value='Schedule for later'
										control={<Radio />}
										label='Schedule for later'
										onClick={() => {
											setScrollToBottom(true);
										}}
									/>
								</RadioGroup>
								{scheduleCampaign === 'Schedule for later' && (
									<>
										<div className='input-group date-input'>
											<DateInput
												inputProps={{ autoFocus: true }}
												label='Date*'
												value={date}
												disablePast
												onChange={(value: Date | null) => {
													setDate(value);
													setDateError(undefined);
													// validateTime();
													// validateDate(value);
												}}
												error={Boolean(dateError)}
												helperText={dateError}
												// onBlur={validateDate}
												closeProp={true}
											/>
										</div>
										<div className='input-group'>
											<TimeInput
												label='Consent collection time*'
												value={time}
												onChange={(value: Date | null) => {
													handleSetTime(value);
												}}
												error={Boolean(timeError.length)}
												helperText={timeError}
												onBlur={validateTime}
											/>
										</div>
									</>
								)}
								<div ref={divRef}></div>
							</>
						)}
				</div>
			</Modal>
			{learnMore && (
				<JobSheetModal
					setLearnMore={setLearnMore}
					downloadMedia={downloadSheetCreateCampaign}
					template={template}
				/>
			)}
		</>
	);
};

export default Create;
