
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../app/store';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import SnackBar from '../../../../../Components/SnackBar';
import { GetAllUsersMyhubResponse } from '../../../../../Features/teamsetting/modal';
import {
	removeFromBotStudio,
	resetRemoveUserLoader,
} from '../../../../../Features/teamsetting/teamsettingSlice';

const RemoveFromTeam = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const state = location.state as { user: GetAllUsersMyhubResponse };
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { removeUserLoader } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const [buttonLoader, setButtonLoader] = useState(false);
	const removeUser = () => {
		let data = {
			tenantId: tenantId!,
			userId: state.user.userId,
		};
		dispatch(removeFromBotStudio(data));
	};

	useEffect(() => {
		if (removeUserLoader === 'success') {
			// toast(<SnackBar type='success'>User removed successfully</SnackBar>, {
			// 	autoClose: 3000,
			// });
			setButtonLoader(false);
			navigate(-1);
		} else if (removeUserLoader === 'failed') {
			// toast(
			// 	<SnackBar type='danger'>
			// 		Couldn't remove user !!! Something Went Wrong
			// 	</SnackBar>,
			// 	{
			// 		autoClose: 3000,
			// 	}
			// );
			setButtonLoader(false);
		} else if (removeUserLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetRemoveUserLoader());
	}, [removeUserLoader]);

	const buttons = () => {
		return (
			<>
				<Button onClick={() => navigate(-1)} buttonType='light'>
					Cancel
				</Button>
				<Button loading={buttonLoader} onClick={removeUser}>
					Remove
				</Button>
			</>
		);
	};
	return (
		<div>
			<Modal header='Remove user from team?' actionButtons={buttons()}>
				<div>
					Please confirm that you want to remove the user with the email{' '}
					<b>{state.user.userEmail}</b> from the team.
				</div>
			</Modal>
		</div>
	);
};

export default RemoveFromTeam;
