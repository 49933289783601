import React, { useEffect } from 'react';
import './PersistentMenu.scss';
import { RootState, useAppDispatch } from '../../../../app/store';
import Radio from '../../../../Components/Radio';
import { useSelector } from 'react-redux';
import { updateBotdetails } from '../../../../Features/configure/configureSlice';
import {
	setMenuItems,
	setPersistentMenuCheckbox,
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import Checkbox from '../../../../Components/Checkbox';
import QuickLinks from '../StartingChat/QuickLinks';
const PersistentMenu = () => {
	const { menuItems, persistentMenuCheckbox } = useSelector(
		(state: RootState) => state.experienceonweb
	);
	const dispatch = useAppDispatch();

	return (
		<div className='ff-eow-persistent-menu'>
			<h4>Persistent Menu</h4>
			<p className='body1' style={{ marginTop: '36px' }}>
				Persistent menu is a menu that is always visible to the user in the chat
				window, and can act as a quick access to the most frequent or suggested
				actions.
			</p>
			<div className='ff-presist-menu-block'>
				<div className='ff-presist-menu-block-checkbox'>
					<Checkbox
						index={0}
						label={<p className='body1'>Enable persistent menu</p>}
						isChecked={persistentMenuCheckbox}
						onValueChange={(val) => {
							dispatch(setPersistentMenuCheckbox(val));
						}}
					/>
				</div>

				{persistentMenuCheckbox[0] ? (
					<>
						<div className='ff-config-border'></div>
						<p className='body1' style={{ marginBottom: '26px' }}>
							Menu items
						</p>

						<Radio
							className='show-title-in-header'
							label={<p className='body1'>Show same items as quick links</p>}
							isChecked={menuItems}
							onValueChange={(val) => {
								dispatch(setMenuItems(val));
							}}
							name='text-image'
							value='Show same items as quick links'
						/>
						<Radio
							label={<p className='body1'>Provide a custom menu</p>}
							isChecked={menuItems}
							onValueChange={(val) => {
								dispatch(setMenuItems(val));
							}}
							name='text-image'
							value='Provide a custom menu'
						/>
					</>
				) : null}
				{menuItems === 'Provide a custom menu' && persistentMenuCheckbox[0] ? (
					<QuickLinks persistent />
				) : null}
			</div>
		</div>
	);
};

export default PersistentMenu;
