import { DragIndicatorOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import Input from '../../../../../Components/Input';
import Button from '../../../../../Components/Button';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { useSelector } from 'react-redux';
import {
	setBookAptCheckList,
	setCheckList,
	setRatCheckList,
	setliveChatcheckList
} from '../../../../../Features/ExperienceOnWeb/experienceonwebSlice';

interface Item {
	sequence: string;
	title: string;
	data: any;
}

type Props = {
	items: Item[];
	indexNumber: number;
	setList: React.Dispatch<
		React.SetStateAction<
			{
				sequence: string;
				title: string;
				data: any;
			}[]
		>
	>;
	list: {
		sequence: string;
		title: string;
		data: any;
	}[];
	from: string;
};

const DragToReorder = ({ items, indexNumber, setList, from, list }: Props) => {
	const { checkList, bookAptCheckList, ratCheckList, liveChatcheckList } = useSelector(
		(state: RootState) => state.experienceonweb
	);

	const [optionsList, setOptionsList] = useState(list);
	const dispatch = useAppDispatch();

	useEffect(() => {
		// console.log('List updated! optionsList', optionsList);
		setList(optionsList);
	}, [optionsList]);

	useEffect(() => {
		// console.log('List updated! Generateleads optionsList', checkList);
		console.log('generateLeads', checkList)
		from === 'generateLeads' && setOptionsList(checkList);
	}, [checkList]);

	useEffect(() => {
		console.log('livechat', liveChatcheckList)
		from === 'liveChatleads' && setOptionsList(liveChatcheckList);
	}, [liveChatcheckList]);

	useEffect(() => {
		from === 'bookApt' && setOptionsList(bookAptCheckList);
	}, [bookAptCheckList]);

	useEffect(() => {
		from === 'rat' && setOptionsList(ratCheckList);
	}, [ratCheckList]);

	return (
		<section>
			<ul>
				{optionsList[indexNumber].data.options.map(
					(item: { name: string; id: string }, index: number) => (
						<li
							style={{
								display: 'flex',
								cursor: 'move',
								alignItems: 'center',
								marginLeft: '62px',
								marginBottom: '16px',
								marginRight: '32px',
							}}
							key={index}
							data-position={index}>
							<DragIndicatorOutlined
								style={{
									color: 'var(--black-40)',
									marginRight: '10px',
									position: 'relative',
									top: '3px',
								}}
							/>
							<Input
								fullWidth
								value={optionsList[indexNumber].data.options[index].name}
								onChange={(e) => {
									const updatedList = optionsList.map(
										(itemData: any, idx: number) => {
											if (idx === indexNumber) {
												const updatedOptions = itemData.data.options.map(
													(option: any, optionIndex: number) => {
														if (optionIndex === index) {
															return { ...option, name: e.target.value };
														}
														return option;
													}
												);

												return {
													...itemData,
													data: {
														...itemData.data,
														options: updatedOptions,
													},
												};
											}
											return itemData;
										}
									);

									setOptionsList(updatedList);
									if (from === 'generateLeads') {
										dispatch(setCheckList(updatedList));
									} else if (from === 'bookApt') {
										dispatch(setBookAptCheckList(updatedList));
									} else if (from === 'rat') {
										dispatch(setRatCheckList(updatedList));
									}
									else if (from === 'liveChatleads') {
										dispatch(setliveChatcheckList(updatedList));
									}

								}}
							/>
							<svg
								style={{
									position: 'relative',
									top: '3px',
									cursor: 'pointer',
									marginLeft: '8px',
								}}
								onClick={() => {
									const updatedList = optionsList.map(
										(itemData: any, idx: number) => {
											if (idx === indexNumber) {
												let updatedOptions = [...itemData.data.options];
												updatedOptions.splice(index, 1);
												return {
													...itemData,
													data: {
														...itemData.data,
														options: updatedOptions,
													},
												};
											}
											return itemData;
										}
									);
									setOptionsList(updatedList);
									if (from === 'generateLeads') {
										dispatch(setCheckList(updatedList));
									} else if (from === 'bookApt') {
										dispatch(setBookAptCheckList(updatedList));
									} else if (from === 'rat') {
										dispatch(setRatCheckList(updatedList));
									}
									else if (from === 'liveChatleads') {
										dispatch(setliveChatcheckList(updatedList));
									}

								}}
								xmlns='http://www.w3.org/2000/svg'
								data-name='close_black_24dp (1)'
								width='24'
								height='24.434'
								viewBox='0 0 24 24.434'>
								<path
									data-name='Path 14781'
									d='M0 0h24v24.434H0z'
									fill='none'
								/>
								<path
									data-name='Path 14782'
									d='M19 6.454 17.59 5 12 10.763 6.41 5 5 6.454l5.59 5.763L5 17.981l1.41 1.454L12 13.671l5.59 5.763L19 17.981l-5.59-5.763z'
									fill='rgba(0,0,0,.4)'
								/>
							</svg>
						</li>
					)
				)}
				<Button
					style={{ marginLeft: '96px' }}
					buttonType='light'
					onClick={() => {
						const updatedList = optionsList.map(
							(itemData: any, idx: number) => {
								if (idx === indexNumber) {
									let updatedOptions = [...itemData.data.options];
									updatedOptions.push({ name: '', id: '' });
									return {
										...itemData,
										data: {
											...itemData.data,
											options: updatedOptions,
										},
									};
								}
								return itemData;
							}
						);
						setOptionsList(updatedList);
						if (from === 'generateLeads') {
							dispatch(setCheckList(updatedList));
						} else if (from === 'bookApt') {
							dispatch(setBookAptCheckList(updatedList));
						} else if (from === 'rat') {
							dispatch(setRatCheckList(updatedList));
						}
						else if (from === 'liveChatleads') {
							dispatch(setliveChatcheckList(updatedList));
						}

					}}
					startIcon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							data-name='add_black_24dp (2)'
							width='20'
							height='20'
							viewBox='0 0 20 20'>
							<path data-name='Path 2860' d='M0 0h20v20H0z' fill='none' />
							<path
								data-name='Path 2861'
								d='M16.667 11.667h-5v5H10v-5H5V10h5V5h1.667v5h5z'
								transform='translate(-.833 -.833)'
								fill='#3b44c4'
							/>
						</svg>
					}>
					Add an option
				</Button>
			</ul>
		</section>
	);
};

export default DragToReorder;
