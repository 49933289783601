import { useEffect, useState } from 'react';
import Checkbox from '../../../Components/Checkbox';
import Input from '../../../Components/Input';
import Radio from '../../../Components/Radio';
import './Appearance.scss';
import HorizontalSeparator from '../../../Components/HorizontalSeparator';
import Dropdown from '../../../Components/Dropdown';
import ColorTypes from './ColorTypes';
import { fontFOptions, fontSOptions } from './font';
import Background from './Background';
import AppearanceInput from './AppearanceInput';
import Button from '../../../Components/Button';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import { useSelector } from 'react-redux';
import {
	createBotConfigurations,
	resetCreateBotConfigurationAppearanceLoader,
	resetSomethingWentWrong,
	setApearanceBackgroundImageUploadSuccess,
	setApearanceBotHeaderImageUploadSuccess,
	setApearanceWelcomeBannerImageUploadSuccess,
	setBackgroundGradientRange,
	setBackgroundGradientStartColor,
	setBackgroundGradientStopColor,
	setBackgroundSolidColor,
	setBotBackground,
	setBotMessageBubbleColor,
	setBotMessageCheckbox,
	setbotLaunchPromptValue,
	setBotMessageTextColor,
	setBotName,
	setBotTitle,
	setButtonTextAndOutlineColor,
	setButtonTextColor,
	setFontFamily,
	setFontSize,
	setHeaderColor,
	setHeaderContentType,
	setHeaderIconPosition,
	setHeaderTextAndIconColor,
	setInputBoxBackgroundColor,
	setInputBoxBckRange,
	setInputBoxTextColor,
	setInputBoxTextRange,
	setBotHeight,
	setBotWidth,
	setOptionsAndQuickLinksBckColor,
	setOptionsAndQuickLinksBckRange,
	setOptionsAndQuickLinksBorderColor,
	setOptionsAndQuickLinksBorderRange,
	setOptionsAndQuickLinksTextColor,
	setResponseLevelFeedBackBckColor,
	setResponseLevelFeedBackBckRange,
	setResponseLevelFeedBackBorderColor,
	setResponseLevelFeedBackBorderRange,
	setResponseLevelFeedBackIconColor,
	setTimeStampBckColor,
	setTimeStampBckRange,
	setTimeStampTextColor,
	setTimeStampTextRange,
	setUserMessageBubbleColor,
	setUserMessageTextColor,
	setWelcomeBannerCheckbox,
	setButtonBckColor,
} from '../../../Features/configure/configureSlice';
import GenericChatBot from './GenericChatBot';
import { UploadImageEdit } from '../../../assets/Icons';
import { hexToRGB, loadState } from '../../../utils/utils';
import { FFLoader } from '../../../assets/Icons/Loader';
import { GCBPreview } from '../../styles/Appearance/GCBPreview/gcbpreview.styled';
import { reset, set } from '../../../Features/message/messageSlice';

const Appearance = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const path = location.pathname;
	const [buttonLoader, setButtonLoader] = useState(false);
	const {
		headerContentType,
		botTitle,
		botName,
		headerIconPosition,
		botBackground,
		welcomeBannerCheckbox,
		botMessageCheckbox,
		fontFamily,
		fontSize,
		headerColor,
		headerTextAndIconColor,
		botMessageBubbleColor,
		botMessageTextColor,
		inputBoxBackgroundColor,
		inputBoxTextColor,
		inputBoxBckRange,
		inputBoxTextRange,
		userMessageBubbleColor,
		userMessageTextColor,
		optionsAndQuickLinksBckColor,
		optionsAndQuickLinksTextColor,
		optionsAndQuickLinksBorderColor,
		buttonBckColor,
		buttonTextColor,
		buttonTextAndOutlineColor,
		responseLevelFeedBackBckColor,
		responseLevelFeedBackIconColor,
		responseLevelFeedBackBorderColor,
		timeStampBckColor,
		timeStampTextColor,
		backgroundSolidColor,
		backgroundGradientStartColor,
		backgroundGradientStopColor,
		optionsAndQuickLinksBckRange,
		optionsAndQuickLinksBorderRange,
		responseLevelFeedBackBckRange,
		responseLevelFeedBackBorderRange,
		timeStampBckRange,
		timeStampTextRange,
		backgroundGradientRange,
		welcomeBannerFile,
		headerFile,
		backgroundFile,
		appearanceBotHeaderLoader,
		appearanceWelcomeBannerLoader,
		createBotConfigurationAppearanceLoader,
		apearanceBackgroundImageUploadSuccess,
		apearanceBotHeaderImageUploadSuccess,
		apearanceWelcomeBannerImageUploadSuccess,
		somethingWentWrong,
		botHeight,
		botWidth,
		botLaunchPrompt,
		botLaunchPromptCheckbox,
		botLaunchPromptValue,
		autoPopupCheckbox,
		autoPopup,
		autoPopupValue,
	} = useSelector((state: RootState) => state.configure);
	const botDetails = loadState('botDetails');
	const { tenantId } = useSelector((state: RootState) => state.teams);

	useEffect(() => {
		if (botBackground === 'image' && !backgroundFile) {
			navigate('add-image', { state: { value: 'Background Image' } });
		}
	}, [botBackground]);

	useEffect(() => {
		if (headerContentType === 'image') {
			if (!headerFile) {
				navigate('add-image', { state: { value: 'Bot Header' } });
			}
		}
	}, [headerContentType]);

	useEffect(() => {
		if (welcomeBannerCheckbox[0]) {
			if (!welcomeBannerFile) {
				navigate('add-image', { state: { value: 'Welcome Banner' } });
			}
		}
	}, [welcomeBannerCheckbox[0]]);

	useEffect(() => {
		if (botBackground === 'image') {
			if (apearanceBackgroundImageUploadSuccess) {
				dispatch(set('Image Uploaded Successfully'));
				dispatch(setApearanceBackgroundImageUploadSuccess(false));
			}
			if (somethingWentWrong === 'Background Image') {
				dispatch(set('Image Upload Failed'));
				dispatch(resetSomethingWentWrong());
				dispatch(setBotBackground('gradient'));
			}
		}
	}, [apearanceBackgroundImageUploadSuccess, somethingWentWrong]);

	useEffect(() => {
		if (apearanceBotHeaderImageUploadSuccess) {
			dispatch(set('Image Uploaded Successfully'));
			dispatch(setApearanceBotHeaderImageUploadSuccess(false));
		}
		if (somethingWentWrong === 'Bot Header') {
			headerContentType === 'image' && dispatch(set('Image Upload Failed'));
			headerContentType === 'image' && dispatch(setHeaderContentType('text'));
			dispatch(resetSomethingWentWrong());
		}
	}, [apearanceBotHeaderImageUploadSuccess, somethingWentWrong]);

	useEffect(() => {
		if (apearanceWelcomeBannerImageUploadSuccess) {
			dispatch(set('Image Uploaded Successfully'));
			dispatch(setApearanceWelcomeBannerImageUploadSuccess(false));
		}
		if (somethingWentWrong === 'Welcome Banner') {
			dispatch(set('Image Upload Failed'));
			dispatch(setWelcomeBannerCheckbox([false]));
			dispatch(resetSomethingWentWrong());
		}
	}, [apearanceWelcomeBannerImageUploadSuccess, somethingWentWrong]);

	useEffect(() => {
		if (createBotConfigurationAppearanceLoader === 'success') {
			dispatch(set('Configurations Saved Successfully'));
			setButtonLoader(false);
		} else if (createBotConfigurationAppearanceLoader === 'failed') {
			dispatch(set(`Couldn't Save !! Something Went Wrong`));
			setButtonLoader(false);
		} else if (createBotConfigurationAppearanceLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetCreateBotConfigurationAppearanceLoader());
	}, [createBotConfigurationAppearanceLoader]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	const backgroundColor = () => {
		if (botBackground === 'color') {
			return backgroundSolidColor;
		}
		if (botBackground === 'gradient') {
			return `linear-gradient(${backgroundGradientStartColor},${backgroundGradientStopColor})`;
		}
		if (botBackground === 'image') {
			return backgroundFile;
		}
	};

	return (
		<div className='ff-appearance-container-save-button-holder'>
			<div className='container-save'>
				<div className='ff-appearance-container'>
					<div className='ff-appearance'>
						<div className='content'>
							<h5 className='appearance-text-header'>
								{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}{' '}
								Header
							</h5>
							<div>
								<p className='appearance-sub-text-header body1'>
									Header content
								</p>
								<div className='text-image-div'>
									<span className='radio-text show-text'>
										<Radio
											name='text-image'
											value='text'
											isChecked={headerContentType}
											onValueChange={(value) =>
												dispatch(setHeaderContentType(value))
											}
										/>
										<p className='appearance-radio-checkbox body1'>Show Text</p>
									</span>
									<span className='radio-text show-image'>
										<Radio
											name='text-image'
											value='image'
											isChecked={headerContentType}
											onValueChange={(value) =>
												dispatch(setHeaderContentType(value))
											}
										/>
										<p className='appearance-radio-checkbox body1'>
											Show Image
										</p>
									</span>
								</div>
								{headerContentType === 'image' ? (
									<div className='bot-header-image'>
										{appearanceBotHeaderLoader ? (
											<FFLoader width='40' />
										) : (
											headerFile !== '' && (
												<>
													<img
														src={headerFile}
														alt={`${path.indexOf('intelligent-ad') > -1
																? 'Ad'
																: 'Storefront'
															} Header`}
													/>
													<span
														onClick={() =>
															navigate('add-image', {
																state: { value: 'Bot Header' },
															})
														}>
														<UploadImageEdit />
													</span>
												</>
											)
										)}
									</div>
								) : null}
							</div>
							<div>
								<p className='appearance-sub-text-header body1'>Title *</p>
								<p className='appearance-description1 body2'>
									The title is shown in the{' '}
									{path.indexOf('intelligent-ad') > -1
										? "Ad's"
										: "Storefront's"}{' '}
									header if 'show title in header' option is selected under
									header content. It is also used as the page title when you
									share the{' '}
									{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}{' '}
									using a campaign link.
								</p>
							</div>
							<div className='bot-title-div'>
								<span className='radio-text'>
									<Radio
										value='botName'
										isChecked={botName}
										onValueChange={(value) => dispatch(setBotName(value))}
										name='bot-title'
									/>
									<p className='appearance-radio-checkbox body1'>
										Use{' '}
										{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}{' '}
										name as title
									</p>
								</span>
								<span className='radio-text'>
									<Radio
										value='customName'
										isChecked={botName}
										onValueChange={(value) => dispatch(setBotName(value))}
										name='bot-title'
									/>
									<p className='appearance-radio-checkbox body1'>
										Provide a custom title
									</p>
								</span>
							</div>
							{botName === 'customName' ? (
								<div>
									<Input
										value={botTitle}
										onChange={(event) =>
											dispatch(setBotTitle(event.target.value))
										}
										placeholder='Add custom title'
										autoFocus
									/>
								</div>
							) : null}
							<div className='header-style-div'>
								<p className='appearance-sub-text-header body1'>
									Header Style *
								</p>
								<div className='header-style-radios'>
									<div className='radio-text'>
										<Radio
											value='internal'
											isChecked={headerIconPosition}
											onValueChange={(value) =>
												dispatch(setHeaderIconPosition(value))
											}
											name='inside-outside'
										/>
										<div className='icons-inside'>
											<p className='appearance-radio-checkbox body1'>
												Icons inside
											</p>
											<img
												className='ce-appearance-icon1'
												src='https://s3-ap-south-1.amazonaws.com/flyfish-resources/dont_delete_me/CeAppearance2.png2022-07-26_06:12:09.878'
												alt='icon'
											/>
										</div>
									</div>
									<div className='radio-text'>
										<Radio
											value='external'
											isChecked={headerIconPosition}
											onValueChange={(value) =>
												dispatch(setHeaderIconPosition(value))
											}
											name='inside-outside'
										/>
										<div className='icons-outside'>
											<p className='appearance-radio-checkbox body1'>
												Icons outside
											</p>
											<img
												className='ce-appearance-icon2'
												src='https://s3-ap-south-1.amazonaws.com/flyfish-resources/dont_delete_me/CeAppearance1.png2022-07-26_06:11:46.919'
												alt='icon'
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='header-icon-color-div'>
								<div>
									<span className='appearance-selected-color body1'>
										Header Color:
									</span>
									<span
										className='color-input-span'
										style={{
											backgroundColor: headerColor,
											maxWidth: '32px',
											maxHeight: '32px',
											borderRadius: '4px',
										}}>
										<AppearanceInput
											className='color-input'
											type='color'
											value={headerColor}
											onValueChange={(value) => dispatch(setHeaderColor(value))}
										/>
									</span>
								</div>
								<div>
									<span className='appearance-selected-color body1'>
										Header text &amp; icon colour:
									</span>
									<span
										className='color-input-span'
										style={{
											backgroundColor: headerTextAndIconColor,
											maxWidth: '32px',
											maxHeight: '32px',
											borderRadius: '4px',
										}}>
										<AppearanceInput
											className='color-input'
											type='color'
											value={headerTextAndIconColor}
											onValueChange={(value) =>
												dispatch(setHeaderTextAndIconColor(value))
											}
										/>
									</span>
								</div>
							</div>
							<HorizontalSeparator />
							<h5 className='appearance-text-header'>Welcome banner</h5>
							<div className='radio-text welcome-banner-checkbox'>
								<div>
									<Checkbox
										isChecked={welcomeBannerCheckbox}
										onValueChange={(value) =>
											dispatch(setWelcomeBannerCheckbox(value))
										}
										index={0}
									/>
								</div>
								<div>
									<p className='appearance-radio-checkbox body1'>
										Show welcome banner
									</p>
									<p
										className='appearance-description body2'
										style={{ paddingLeft: '11px' }}>
										Add an image to show at the beginning of the conversation.
										Recommended minimum size is 400px by 200px (aspect ratio of
										2:1).
									</p>
								</div>
							</div>
							{welcomeBannerCheckbox[0] ? (
								<div className='welcome-banner-image'>
									{appearanceWelcomeBannerLoader ? (
										<FFLoader width='40' />
									) : (
										welcomeBannerFile !== '' && (
											<>
												<img src={welcomeBannerFile} alt='Welcome Banner' />
												<span
													onClick={() =>
														navigate('add-image', {
															state: { value: 'Welcome Banner' },
														})
													}>
													<UploadImageEdit />
												</span>
											</>
										)
									)}
								</div>
							) : null}
							<HorizontalSeparator />

							<h5 className='appearance-text-header'>Background</h5>
							<div className='background-radios'>
								<span className='radio-text'>
									<Radio
										value='color'
										isChecked={botBackground}
										onValueChange={(value) => dispatch(setBotBackground(value))}
										name='background'
									/>
									<p className='appearance-radio-checkbox body1'>Solid color</p>
								</span>
								<span className='radio-text'>
									<Radio
										value='gradient'
										isChecked={botBackground}
										onValueChange={(value) => dispatch(setBotBackground(value))}
										name='background'
									/>
									<p className='appearance-radio-checkbox body1'>Gradient</p>
								</span>
								<span className='radio-text'>
									<Radio
										value='image'
										isChecked={botBackground}
										onValueChange={(value) => dispatch(setBotBackground(value))}
										name='background'
									/>
									<p className='appearance-radio-checkbox body1'>Image</p>
								</span>
							</div>
							<Background
								value1={backgroundSolidColor}
								value2={[
									backgroundGradientStartColor,
									backgroundGradientRange,
									backgroundGradientStopColor,
								]}
								onValueChange1={(value) =>
									dispatch(setBackgroundSolidColor(value))
								}
								onValueChange2={[
									(value) => dispatch(setBackgroundGradientStartColor(value)),
									(value) => dispatch(setBackgroundGradientRange(value)),
									(value) => dispatch(setBackgroundGradientStopColor(value)),
								]}
								background={botBackground}
							/>
							<HorizontalSeparator
								className={`${botBackground === 'image' ? 'background-separator' : ''
									}`}
							/>
							<h5 className='appearance-text-header'>
								{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}{' '}
								messages
							</h5>
							<div className='radio-text bot-messages'>
								<Checkbox
									isChecked={botMessageCheckbox}
									onValueChange={(value) =>
										dispatch(setBotMessageCheckbox(value))
									}
									index={0}
								/>
								<p className='appearance-radio-checkbox boyd1'>
									Show{' '}
									{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}{' '}
									Avatar with messages
								</p>
							</div>
							<ColorTypes
								value={botMessageBubbleColor}
								onValueChange={(value) =>
									dispatch(setBotMessageBubbleColor(value))
								}
								text='Message bubble colour'
							/>
							<ColorTypes
								value={botMessageTextColor}
								onValueChange={(value) =>
									dispatch(setBotMessageTextColor(value))
								}
								applyBubbleClass={false}
								text='Text colour'
							/>

							<HorizontalSeparator className='bot-user-message-separator' />
							<h5 className='appearance-text-header'>User messages</h5>
							<ColorTypes
								value={userMessageBubbleColor}
								onValueChange={(value) =>
									dispatch(setUserMessageBubbleColor(value))
								}
								text='Message bubble colour'
							/>

							<ColorTypes
								value={userMessageTextColor}
								onValueChange={(value) =>
									dispatch(setUserMessageTextColor(value))
								}
								applyBubbleClass={false}
								text='Text colour'
							/>

							<HorizontalSeparator className='bot-user-message-separator' />
							<h5 className='appearance-text-header'>
								Options &amp; quick links
							</h5>
							<ColorTypes
								value={optionsAndQuickLinksBckColor}
								onValueChange={(value) =>
									dispatch(setOptionsAndQuickLinksBckColor(value))
								}
								rangeInputV={optionsAndQuickLinksBckRange}
								rangeInputOnValueChange={(value) =>
									dispatch(setOptionsAndQuickLinksBckRange(value))
								}
								text='Background colour'
								numberOfTypes={3}
							/>
							<ColorTypes
								value={optionsAndQuickLinksTextColor}
								onValueChange={(value) =>
									dispatch(setOptionsAndQuickLinksTextColor(value))
								}
								applyBubbleClass={false}
								text='Text colour'
							/>
							<ColorTypes
								value={optionsAndQuickLinksBorderColor}
								onValueChange={(value) =>
									dispatch(setOptionsAndQuickLinksBorderColor(value))
								}
								rangeInputV={optionsAndQuickLinksBorderRange}
								rangeInputOnValueChange={(value) =>
									dispatch(setOptionsAndQuickLinksBorderRange(value))
								}
								text='Border colour'
								numberOfTypes={3}
							/>
							<HorizontalSeparator />
							<h5 className='appearance-text-header'>Buttons</h5>
							<ColorTypes
								value={buttonBckColor}
								onValueChange={(value) => dispatch(setButtonBckColor(value))}
								text='Background colour of filled buttons'
							/>
							<ColorTypes
								value={buttonTextColor}
								onValueChange={(value) => dispatch(setButtonTextColor(value))}
								text='Text colour for filled buttons'
							/>
							<ColorTypes
								value={buttonTextAndOutlineColor}
								onValueChange={(value) =>
									dispatch(setButtonTextAndOutlineColor(value))
								}
								text='Colour of text-only/outlined buttons'
								applyBubbleClass={false}
							/>
							<HorizontalSeparator />

							<h5 className='appearance-text-header'>
								Response level feedback
							</h5>
							<ColorTypes
								value={responseLevelFeedBackBckColor}
								onValueChange={(value) =>
									dispatch(setResponseLevelFeedBackBckColor(value))
								}
								rangeInputV={responseLevelFeedBackBckRange}
								rangeInputOnValueChange={(value) =>
									dispatch(setResponseLevelFeedBackBckRange(value))
								}
								text='Background colour'
								numberOfTypes={3}
							/>
							<ColorTypes
								value={responseLevelFeedBackIconColor}
								onValueChange={(value) =>
									dispatch(setResponseLevelFeedBackIconColor(value))
								}
								applyBubbleClass={false}
								text='Icon colour'
							/>
							<ColorTypes
								value={responseLevelFeedBackBorderColor}
								onValueChange={(value) =>
									dispatch(setResponseLevelFeedBackBorderColor(value))
								}
								rangeInputV={responseLevelFeedBackBorderRange}
								rangeInputOnValueChange={(value) =>
									dispatch(setResponseLevelFeedBackBorderRange(value))
								}
								text='Border colour'
								numberOfTypes={3}
							/>

							<HorizontalSeparator />
							<h5 className='appearance-text-header'>Font</h5>
							<div className='font-family-selected'>
								<p className='appearance-selected-color body1'>Font family</p>
								<div className='font-family-right-margin'>
									<Dropdown
										value={fontFamily}
										onChange={(value) => dispatch(setFontFamily(value))}
										options={fontFOptions}
									/>
								</div>
							</div>
							<div className='font-size-selected'>
								<p className='appearance-selected-color body1'>Font size</p>
								<div className='font-family-right-margin'>
									<Dropdown
										value={fontSize}
										onChange={(value) => dispatch(setFontSize(value))}
										options={fontSOptions}
									/>
								</div>
							</div>
							<HorizontalSeparator />
							<h5 className='appearance-text-header'>Time stamps</h5>
							<ColorTypes
								value={timeStampBckColor}
								onValueChange={(value) => dispatch(setTimeStampBckColor(value))}
								rangeInputV={timeStampBckRange}
								rangeInputOnValueChange={(value) =>
									dispatch(setTimeStampBckRange(value))
								}
								text='Background colour'
								numberOfTypes={3}
							/>
							<ColorTypes
								value={timeStampTextColor}
								onValueChange={(value) =>
									dispatch(setTimeStampTextColor(value))
								}
								rangeInputV={timeStampTextRange}
								rangeInputOnValueChange={(value) =>
									dispatch(setTimeStampTextRange(value))
								}
								text='Text colour'
								applyBubbleClass={false}
								numberOfTypes={3}
							/>
							<HorizontalSeparator />
							<h5 className='appearance-text-header'>Input box</h5>
							<ColorTypes
								value={inputBoxBackgroundColor}
								onValueChange={(value) =>
									dispatch(setInputBoxBackgroundColor(value))
								}
								rangeInputV={inputBoxBckRange}
								rangeInputOnValueChange={(value) =>
									dispatch(setInputBoxBckRange(value))
								}
								text='Background colour'
								numberOfTypes={3}
							/>
							<ColorTypes
								value={inputBoxTextColor}
								onValueChange={(value) => dispatch(setInputBoxTextColor(value))}
								rangeInputV={inputBoxTextRange}
								rangeInputOnValueChange={(value) =>
									dispatch(setInputBoxTextRange(value))
								}
								text='Text colour'
								numberOfTypes={3}
							/>
							<HorizontalSeparator />
							<h5 className='appearance-text-header appearance-text-header-size'>
								Size
							</h5>
							<p className='appearance-text-header-size-subtext body2 bot-width-selection'>
								Refers to the size of the chatbot when deployed on websites and
								opened on large screen devices.
								{/* You can preview the size on the
								test {path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}{' '}
								after saving the changes. */}
							</p>
							<div className='bot-width-selection'>
								<p className='body1'>Width</p>
								<div className='bot-width-container'>
									<div className='bot-width'>
										<AppearanceInput
											className='bot-width-range-input range-input'
											style={{
												background: 'var(--black-20)',
												backgroundBlendMode: 'multiply',
											}}
											type='range'
											value={botWidth}
											min={320}
											max={440}
											onValueChange={(value) => dispatch(setBotWidth(value))}
										/>
										<div className='bot-width-number-input-holder'>
											<AppearanceInput
												className='bot-width-number-input'
												style={{ maxWidth: `29px`, outline: 'none' }}
												type='number'
												value={botWidth}
												min={320}
												max={440}
												onValueChange={(value) => dispatch(setBotWidth(value))}
											/>
											<span>px</span>
										</div>
									</div>
								</div>
							</div>
							<div className='range-allowed'>
								<p>320px</p>
								<p>440px</p>
							</div>
							<div className='bot-height-selection'>
								<p className='body1'>Height</p>
								<div className='bot-height-container'>
									<div className='bot-height'>
										<AppearanceInput
											className='bot-height-range-input range-input'
											style={{
												background: 'var(--black-20)',
												backgroundBlendMode: 'multiply',
											}}
											type='range'
											value={botHeight}
											min={50}
											max={100}
											onValueChange={(value) => dispatch(setBotHeight(value))}
										/>
										<div className='bot-height-number-input-holder'>
											<AppearanceInput
												className='bot-height-number-input'
												style={{ maxWidth: `29px`, outline: 'none' }}
												type='number'
												value={botHeight}
												min={50}
												max={100}
												onValueChange={(value) => dispatch(setBotHeight(value))}
											/>
											<p>%</p>
										</div>
									</div>
								</div>
							</div>
							<div className='range-allowed'>
								<p>50%</p>
								<p>100%</p>
							</div>
						</div>
					</div>
				</div>
				<div className='appearance-save-button-div'>
					<Button
						className='appearance-save-button'
						loading={buttonLoader}
						onClick={() => {
							let botConfiguration = {
								botId: botDetails.botId,
								tenantId: tenantId,
								headerContentType: headerContentType,
								botNameAsTitle: botName === 'botName' ? 'true' : 'false',
								headerTitle: botName === 'customName' ? botTitle : '',
								headerImage: headerContentType === 'image' ? headerFile : '',
								headerStyle: headerIconPosition,
								headerColor: headerTextAndIconColor,
								headerBackground: headerColor,
								showCover: welcomeBannerCheckbox[0],
								coverImage: welcomeBannerCheckbox[0] ? welcomeBannerFile : '',
								backgroundType: botBackground,
								backgroundColor: backgroundColor(),
								showBotIcon: botMessageCheckbox[0],
								srvMsgBackground: botMessageBubbleColor,
								srvMsgColor: botMessageTextColor,
								usrMsgBackground: userMessageBubbleColor,
								usrMsgColor: userMessageTextColor,
								optionsBackground: hexToRGB(
									optionsAndQuickLinksBckColor,
									parseInt(optionsAndQuickLinksBckRange) / 100
								),
								optionsColor: optionsAndQuickLinksTextColor,
								optionsBorder: hexToRGB(
									optionsAndQuickLinksBorderColor,
									parseInt(optionsAndQuickLinksBorderRange) / 100
								),
								btnFillBackground: buttonBckColor,
								btnFillColor: buttonTextColor,
								outlineBtnColor: buttonTextAndOutlineColor,
								thumbBackground: hexToRGB(
									responseLevelFeedBackBckColor,
									parseInt(responseLevelFeedBackBckRange) / 100
								),
								thumbColor: responseLevelFeedBackIconColor,
								thumbBordercolor: hexToRGB(
									responseLevelFeedBackBorderColor,
									parseInt(responseLevelFeedBackBorderRange) / 100
								),
								fontFamily: fontFamily.value,
								fontSize: fontSize.value,
								timeStampBackground: hexToRGB(
									timeStampBckColor,
									parseInt(timeStampBckRange) / 100
								),
								timeStampColor: hexToRGB(
									timeStampTextColor,
									parseInt(timeStampTextRange) / 100
								),
								inputBackground: hexToRGB(
									inputBoxBackgroundColor,
									parseInt(inputBoxBckRange) / 100
								),
								inputBoxColor: hexToRGB(
									inputBoxTextColor,
									parseInt(inputBoxTextRange) / 100
								),
								botHeight: botHeight,
								botWidth: botWidth,
								launcherPromptMessage: botLaunchPrompt,
								launcherPromptBackground: '#2F1C9A',
								launcherPromptColor: '#FFFFFF',
								isLauncherPromptEnabled: botLaunchPromptCheckbox[0],
								isAutoPopupEnabled: autoPopupCheckbox[0],
								autoPopTime: autoPopup,
							};
							dispatch(createBotConfigurations(botConfiguration));
						}}>
						Save
					</Button>
				</div>
			</div>

			<GCBPreview
				className={headerIconPosition === 'external' ? 'assign-shadow' : ''}
				iconOutSide={headerIconPosition === 'external'}>
				<p className='overline bot-preview-header'>preview</p>
				<GenericChatBot />
			</GCBPreview>
			<Outlet />
		</div>
	);
};

export default Appearance;
