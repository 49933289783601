import React from 'react';
import './WAMultichoice.scss';
// import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
// import styled from 'styled-components';
import { metadata } from '../../../../../Features/SalesAI/model';
import {
	setMsgsArray,
	setMsgsLoading,
	setProductDetails,
} from '../../../../../Features/SalesAI/salesSlice';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { useSelector } from 'react-redux';
import { loadState } from '../../../../../utils/utils';

type Props = {
	message: string | React.ReactNode;
	metadata: metadata[];
	timeStamp: string;
};

const WAMultichoice = (props: Props) => {
	const { message, metadata, timeStamp } = props;
	let baseURL = process.env.REACT_APP_API;
	const dispatch = useAppDispatch();
	const { msgsArray } = useSelector((state: RootState) => state.sales);
	// const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	// 	<Tooltip {...props} classes={{ popper: className }} />
	// ))(({ theme }) => ({
	// 	[`& .${tooltipClasses.tooltip}`]: {
	// 		fontSize: 14,
	// 		padding: 10,
	// 		lineHeight: 1.5,
	// 		letterSpacing: 0.32,
	// 	},
	// }));

	const getShopifyProduct = (product: metadata) => {
		// let baseURL = "https://dev.flyfish.ai/shopify";
		let baseURL = window.origin + '/shopify';
		// window.origin.indexOf('preprod') > -1
		// 	? 'https://preprod.flyfish.ai/route/shop'
		// 	: window.origin.indexOf('app') > -1
		// 	? 'https://app.flyfish.ai/route/shop'
		// 	: window.origin + '/shopify';
		fetch(`${baseURL}/product/getDetails?productId=${product.product_id}`, {
			method: 'GET',
			headers: {
				'X-Tenant-Id': loadState('tenantId').toString(),
				// 'X-Tenant-Id': '687',
				Authorization: `Bearer ${loadState('token')}`,
			},
		})
			.then((res) => res.json())
			.then((data) => {
				if (data && data.response.status === 'Success') {
					dispatch(setMsgsLoading('done'));
					dispatch(setProductDetails(data.response.data.product));
					dispatch(
						setMsgsArray([
							...msgsArray,
							{ content: product.title, role: 'user' },
							{ content: 'add-to-cart', role: 'assistant' },
						])
					);
				}
			});
	};

	const getProductDetails = (product: metadata) => {
		if (product && product.product_id) {
			dispatch(
				setMsgsArray([...msgsArray, { content: product.title, role: 'user' }])
			);
			dispatch(setMsgsLoading('loading'));
			getShopifyProduct(product);
		}
	};

	return (
		<div className='ff-multichoice-container'>
			{message && (
				<div className='multichoice-msg-response'>
					<p
						className='message body1'
						dangerouslySetInnerHTML={{ __html: message.toString() }}
					/>
					<p className='timeline caption'>{timeStamp}</p>
				</div>
			)}
			<div className='multichoice-cards-container'>
				{metadata &&
					metadata.length > 0 &&
					metadata.map((product: metadata, index: number) => {
						return (
							<div className='ff-multichoice-products-container'>
								<div className='multichoice-each-product' key={index}>
									<img className='product-image' src={product.image} alt='' />
									<div className='product-details'>
										{/* <LightTooltip title={product.title} placement='bottom'> */}
										<p className='product-name body1'>
											{index + 1}. {product.title}
										</p>
										{/* </LightTooltip> */}
										<p className='product-price body1'>
											<s className='non-discount-price caption'>
												{/* {product.currency} 585 */}
											</s>{' '}
											{product.currency} {product.price}
										</p>
									</div>
									<p className='timeline caption'>{timeStamp}</p>
								</div>
								<button
									className='product-select-button'
									onClick={() => getProductDetails(product)}>
									{/* <LightTooltip title={product.title} placement='bottom'> */}
									<p className='button-text'>{product.title}</p>
									{/* </LightTooltip> */}
								</button>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default WAMultichoice;
