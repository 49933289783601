import CircularProgress from '@mui/material/CircularProgress';
import DoughnutChart from './DoughnutChart/index';
import LabelContainer from './LabelContainer';
import './index.scss';

interface DoughnutChartProps {
	isLoading: boolean;
	data?: { value: number; name: string, percentage?: number }[];
	title?: string;
	colors?: string[];
	description?: string;
}

function DoughnutChartContainer({ isLoading, data, title, colors, description }: DoughnutChartProps): JSX.Element {

	return (
		<div className='doughnutChart-Container'>
			<h6>{title}</h6>
			<p className='caption' style={{ color: '#818181' }}>{description}</p>
			{isLoading ? (
				<div className='loading'>
					<CircularProgress size='24px' />
					<p className='caption'>Loading data</p>
				</div>
			) : (
				<>
					{data?.filter((each: any) => each.value !== 0).length ? (
						<div className='doughnutchart-content'>
							<div className='data-content'></div>
							<div className='data-content1'>
								<DoughnutChart data={data} colors={colors} showLabel={true} />
							</div>
							<div className='content'>
								<LabelContainer data={data} colors={colors} />
							</div>
						</div>
					) : (
						<div className='no-data'>
							<p className='body2'>No messages in the selected date range</p>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default DoughnutChartContainer;
