import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../app/store';
import './handOffTriggers.scss';
import NumberInput from '../../Components/NumberInput';
import {
	CheckBoxChecked,
	CheckBoxUnChecked,
} from '../../assets/Icons/LiveChatConfigIcons/index';
import { useSelector } from 'react-redux';
import {
	setFailureValue,
	setNegativeSentimentCheckbox,
	setNegativeSentimentValue,
	setOnfailureCheckbox,
	setOnUserErrorCheckbox,
	setUserErrorValue,
} from '../../Features/ExperienceOnWeb/experienceonwebSlice';
const MAX_COUNT = 100;

export function HandOffTriggers() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const {
		negativeSentimentCheckbox,
		onUserErrorCheckbox,
		onfailureCheckbox,
		negativeSentimentValue,
		failureValue,
		userErrorValue,
	} = useSelector((state: RootState) => state.experienceonweb);
	return (
		<div className='ff-handoff-conatiner'>
			<div className='ff-handoff-header'>
				<p className='body1'>
					Handoff triggers are the events that initiate the handoff of the chat
					from
					<br />
					the AI to expert. Select the events that you want to trigger handoff.
				</p>
			</div>

			<div className='ff-handOff-config'>
				<div className='ff-handOff-config-content'>
					<div className='ff-handOff-config-checkbbox'>
						<div
							onClick={() => {
								dispatch(setOnfailureCheckbox(!onfailureCheckbox));
							}}
							style={{ cursor: 'pointer' }}>
							{onfailureCheckbox ? <CheckBoxChecked /> : <CheckBoxUnChecked />}
						</div>
						<div>
							<div>
								<p className='body1'>On failure to understand</p>
							</div>
							<div>
								<p className='body2'>
									When the AI fails to understand what the user says or is not
									able to provide a suitable response
								</p>
							</div>

							<div
								className={`ff-handOff-config-input ${
									onfailureCheckbox ? 'show' : ''
								}`}>
								<div>
									<p className='body1'>
										No. of consecutive errors after which to trigger handoff
									</p>
								</div>
								<div>
									<NumberInput
										fullWidth
										value={failureValue}
										onChange={(val: any) => dispatch(setFailureValue(val))}
										min={0}
										max={10}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='ff-handOff-config-border'></div>

				<div
					className='ff-handOff-config-content'
					style={{ marginTop: '35px' }}>
					<div className='ff-handOff-config-checkbbox'>
						<div
							onClick={() => {
								dispatch(setOnUserErrorCheckbox(!onUserErrorCheckbox));
							}}
							style={{ cursor: 'pointer' }}>
							{onUserErrorCheckbox ? (
								<CheckBoxChecked />
							) : (
								<CheckBoxUnChecked />
							)}
						</div>
						<div>
							<div>
								<p className='body1'>On user error</p>
							</div>
							<div>
								<p className='body2'>
									When the user is facing difficulty in responding to something
									the AI asked such as phone number, email, dates etc.
								</p>
							</div>

							<div
								className={`ff-handOff-config-input ${
									onUserErrorCheckbox ? 'show' : ''
								}`}>
								<div>
									<p className='body1'>
										No. of consecutive errors after which to trigger handoff
									</p>
								</div>
								<div>
									<NumberInput
										fullWidth
										value={userErrorValue}
										onChange={(val: any) => dispatch(setUserErrorValue(val))}
										min={0}
										max={10}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='ff-handOff-config-border'></div>

				<div
					className='ff-handOff-config-content'
					style={{ marginTop: '35px' }}>
					<div className='ff-handOff-config-checkbbox'>
						<div
							onClick={() => {
								dispatch(
									setNegativeSentimentCheckbox(!negativeSentimentCheckbox)
								);
							}}
							style={{ cursor: 'pointer' }}>
							{negativeSentimentCheckbox ? (
								<CheckBoxChecked />
							) : (
								<CheckBoxUnChecked />
							)}
						</div>
						<div style={{ flex: 1 }}>
							<div>
								<p className='body1'>When user expresses negative sentiment</p>
							</div>
							<div>
								<p className='body2'>
									When the user makes complaints or expresses criticism,
									disappointment, anger etc.
								</p>
							</div>

							<div
								className={`ff-handOff-config-input ${
									negativeSentimentCheckbox ? 'show' : ''
								}`}>
								<div>
									<p className='body1'>
										No. of negative sentiment expressions after which to trigger
										handoff
									</p>
								</div>
								<div>
									<NumberInput
										fullWidth
										value={negativeSentimentValue}
										onChange={(val: any) =>
											dispatch(setNegativeSentimentValue(val))
										}
										min={0}
										max={10}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
