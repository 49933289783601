import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../app/store';
import {
	deleteContactUser,
	getList,
	setOffset,
	setPage,
} from '../../../../../Features/WhatsApp-BSP/contact/contactSlice';
import Modal from '../../../../../Components/Modal';
import { ContactListItem } from '../../model';
import { useSelector } from 'react-redux';
import {
	kotakTenantCheckForWhatsapp,
	loadState,
} from '../../../../../utils/utils';

type State = {
	user: ContactListItem;
};

const DeleteForm = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state as State;
	const { wabaNo } = useSelector((state: RootState) => state.BSPauth);
	if (state?.user) {
		return (
			<Modal
				header='Delete contact?'
				action='Delete'
				onAction={() => {
					dispatch(setPage(0));
					dispatch(setOffset(0));
					dispatch(
						deleteContactUser({ mobileNo: state.user.mobileNo, wabaNo })
					);
					setTimeout(() => {
						kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
							? dispatch(getList({ offset: 0, wabaNo }))
							: dispatch(getList({ page: 0, size: 20, wabaNo }));
					}, 2000);
					navigate(-1);
				}}>
				<p className='body-1'>
					Please confirm that you want to delete{' '}
					<b>
						{state.user?.name?.length ? state.user.name : state.user.mobileNo}
					</b>{' '}
					from the list of contacts.
				</p>
			</Modal>
		);
	} else {
		return <Navigate to='/contacts' />;
	}
};

export default DeleteForm;
