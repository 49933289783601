import DataFetchAPI from '../../../utils/Api';
import { loadState } from '../../../utils/utils';
import {
	LinkInsta,
	LinkWaba,
	modifyPageStateReq,
	updateBotStateReq,
	wabaRequest,
} from './model';

const getWabaNos = async (data: any) => {
	return await DataFetchAPI().get(
		`/${loadState('WAEndpoint')}/${data.tenantId}/channels`,
		data
	);
};

const getWabaNosKotak = async (data: any) => {
	return await DataFetchAPI().get(
		`/${loadState('WAEndpoint')}/getWabaNos?tenantId=${data.tenantId}`,
		data
	);
};

const setWaba = async (data: any) => {
	return await DataFetchAPI().get(`/${loadState('WAEndpoint')}/setWabaNo`, {
		params: data,
	});
};

const getBusinessId = async (data: { inputToken: string }) => {
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/debugToken`,
		data
	);
};

const getInstaId = async (data: { fbresponse: any; accessToken: any }) => {
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/instaAccountDetails`,
		data
	);
};

//for removing waba
const deactiveWaba = async (data: { wabaNo: string }) => {
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/${loadState('tenantId')}/deactiveWabaNumber`,
		data
	);
};

//for removing insta
const removeInstaPage = async (data: { pageId: string }) => {
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/removeInstaPage`,
		data
	);
};

// for pause or resume - whatsapp
const updateBotState = async (data: updateBotStateReq) => {
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/${loadState('tenantId')}/updateBotState`,
		data
	);
};

// for pause or resume - instagram
const modifyPageState = async (data: modifyPageStateReq) => {
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/modifyPageState`,
		data
	);
};

const linkWaba = async (data: LinkWaba) => {
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/addWabaToBot`,
		data
	);
};

const unlinkWaba = async (data: { wabaNo: string }) => {
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/deleteWabaToBot`,
		data
	);
};

const linkInsta = async (data: LinkInsta) => {
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/addInstaPageToBot`,
		data
	);
};

const updateWabaNumber = async (data: { wabaId: string }) => {
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/${loadState('tenantId')}/createWabaProfile`,
		data
	);
};

const onBoardWabano = async (data: wabaRequest) => {
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/${data.tenantId}/onBoardWabaNo`,
		data
	);
};

const onBoardInsta = async (data: {
	pageId: any;
	accessToken: any;
	tenantId: any;
}) => {
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/onboardOnInstagram`,
		data
	);
};

const authService = {
	getWabaNos,
	setWaba,
	getBusinessId,
	linkWaba,
	unlinkWaba,
	deactiveWaba,
	updateBotState,
	updateWabaNumber,
	onBoardWabano,
	getInstaId,
	onBoardInsta,
	linkInsta,
	removeInstaPage,
	modifyPageState,
	getWabaNosKotak,
};

export default authService;
