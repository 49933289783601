import Modal from '../../../../../Components/Modal';
import Button from '../../../../../Components/Button';

import './JobSheet.scss';
import TemplateSvg from './TemplateSVG';
import JobSheetSvg from './JobSheetSVG';
import { TemplateListItem } from '../../../ManageTemplates/model';

interface Props {
	setLearnMore: any;
	downloadMedia: string;
	template: TemplateListItem | null;
}

const jobSheetModal = ({ setLearnMore, downloadMedia, template }: Props) => {
	return (
		<Modal
			header={<h6>Job Sheet</h6>}
			className='job-sheet-modal'
			onClose={() => setLearnMore(false)}
			actionButtons={
				<>
					<Button onClick={() => setLearnMore(false)}>
						<p className='button-1'>Close</p>
					</Button>
				</>
			}>
			<>
				<div className='job-sheet-body-1'>
					<p className='body2'>
						Each campaign requires a job sheet to be uploaded by you. The job
						sheet is a spreadsheet that contains the list of all the phone
						numbers that the campaign messages will be sent to. It also
						specifies what the content of the messages being sent will be. The
						content is specified by providing values for the variables present
						in the selected template. See the example below to learn more:
					</p>
					<p className='body2'>
						Suppose the selected template has the following content:
					</p>
					<TemplateSvg />
					<p className='body2'>The job sheet will follow the below format.</p>
					<JobSheetSvg />
					<p className='body2'>
						The country code of the recipient must be the first column of the
						sheet. This is followed by a column for the rest of the phone
						number.
					</p>
					<p className='body2'>
						A column must be added for each variable in the header. Same goes
						for the variables in the body.
					</p>
					<p className='body2'>
						If your template has a call-to-action button with dynamic URL, a
						column will be required for URLs also. You only need to provide the
						dynamic part of the URL.
					</p>
					<p className='body2'>
						Providing values for all placeholders is mandatory. If a row has any
						value missing, sending the message will fail for the corresponding
						phone number.
					</p>
					<p className='body2'>
						Please leave the first row of the spreadsheet blank or use it for
						headings as it will not be processed.
					</p>
					<p className='body2'>
						<a
							rel='noopener noreferrer'
							href={downloadMedia}
							download={
								template && template.templateName
									? `${template.templateName}.xlsx`
									: 'template.xlsx'
							}
							target='_blank'>
							Click here to download the job sheet format
						</a>
						&nbsp;for the template you have selected
					</p>
					<p className='body2'>
						After you upload the job sheet, and before run the campaign, you can
						check if your job sheet works correctly by clicking on ‘Message
						preview’.
					</p>
				</div>
			</>
		</Modal>
	);
};

export default jobSheetModal;
