import React from 'react';
import { Outlet } from 'react-router-dom';
import WelcomeFullScreen from './WelcomeFullScreen';
import ChatFullScreen from './ChatFullScreen';
import './FullScreenWeb.scss'
import LeftPane from './LeftPane';
import WelcomeScreen from '../../../WelcomeScreen';
import { RootState, useAppDispatch } from '../../../../app/store';
import { useSelector } from 'react-redux';

const FullScreenConfig = () => {

	return (
		<div className='ff-configure-fullscreen-experience-on-web'>
			<LeftPane />
			<div className='fullscreen-experience-on-web'>

				<Outlet />
			</div>
		</div>
	);
};

export default FullScreenConfig;
