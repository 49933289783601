import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../app/store';
import Button from '../../../Components/Button';
import Dropdown, {
	DropdownValue,
	DropdownValueProps,
} from '../../../Components/Dropdown';
import Input from '../../../Components/Input';
import Modal from '../../../Components/Modal';
import { GetAction } from '../../../Features/builder/model';
import {
	APICall,
	ConfirmCollected,
	Image,
	Keyboard,
	ModalClose,
	MultipleChoice,
	MultipleSelect,
	SendCollectedValues,
	Star,
	Text,
	TriggerQuery,
	HandoffToAgent,
} from '../../../assets/Icons';
import { actionsOptions } from './common';
import NextNewAction from './NextNewAction';

const Actions = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const state: any = location.state;
	const taskAction: GetAction = state.action;
	const actionType: number = state.actionType;

	const { actionDetails } = useSelector((state: RootState) => state.builder);

	const [selectedAction, setSelectedAction] = useState<DropdownValue>(
		actionsOptions.filter((option) => option.value === actionType.toString())[0]
	);
	const [actionName, setActionName] = useState(
		actionDetails?.taskName ?? 'Untitled'
	);
	const [className, setClassName] = useState('');
	const [nextAction, setNextAction] = useState<DropdownValue>(null);

	useEffect(() => {
		const value = selectedAction as DropdownValueProps;
		const options = { state: state, replace: true };

		switch (value.value) {
			case '1':
				setClassName('show-text-image-modal');
				navigate('display', options);
				break;

			case '3':
				setClassName('multi-select-modal');
				navigate('multi-select', options);
				break;

			case '5':
				setClassName('multi-choice-modal');
				navigate('multi-choice', options);
				break;

			case '8':
				setClassName('confirm-collected-values-modal');
				navigate('confirm-collected-values', options);
				break;

			case '10':
				setClassName('call-api-modal');
				navigate('call-an-api', options);
				break;

			case '11':
				setClassName('collect-user-rating-modal');
				navigate('collect-user-rating', options);
				break;

			case '12':
				setClassName('trigger-another-query-modal');
				navigate('trigger-another-query', options);
				break;

			case '150':
				setClassName('collect-user-input-modal');
				navigate('collect-user-input', options);
				break;

			case '14':
				setClassName('send-collected-values-modal');
				navigate('send-collected-values', options);
				break;
			case '0':
				setClassName('handoff-to-agent-modal');
				navigate('handoff-to-agent', options);
				break;
			default:
				navigate('/app/bot/builder', options);
				break;
		}
	}, [selectedAction]);

	useEffect(() => {
		setActionName(actionDetails?.taskName ?? 'Untitled');
	}, [actionDetails]);

	const getIcon = (userResponseTypeID: number) => {
		switch (userResponseTypeID) {
			case 1:
				return (
					<div className='icon type1'>
						<div className='icon'>
							<Text width='23px' />/ <Image width='23px' />
						</div>
					</div>
				);

			case 3:
				return (
					<div className='icon type3'>
						<div className='icon'>
							<MultipleSelect width='24px' />
						</div>
					</div>
				);

			case 5:
				return (
					<div className='icon type5'>
						<div className='icon'>
							<MultipleChoice width='24px' />
						</div>
					</div>
				);

			case 8:
				return (
					<div className='icon type8'>
						<div className='icon'>
							<ConfirmCollected width='22px' />
						</div>
					</div>
				);

			case 10:
				return (
					<div className='icon type10'>
						<div className='icon'>
							<APICall width='22px' />
						</div>
					</div>
				);

			case 11:
				return (
					<div className='icon type11'>
						<div className='icon'>
							<Star width='24px' />
						</div>
					</div>
				);

			case 12:
				return (
					<div className='icon type12'>
						<div className='icon'>
							<TriggerQuery width='24px' />
						</div>
					</div>
				);

			case 150:
				return (
					<div className='icon type150'>
						<div className='icon'>
							<Keyboard width='23px' />
						</div>
					</div>
				);

			case 14:
				return (
					<div className='icon type14'>
						<div className='icon'>
							<SendCollectedValues width='23px' />
						</div>
					</div>
				);
			case 0:
				return (
					<div className='icon type0'>
						<div className='icon'>
							<HandoffToAgent width='15px' />
						</div>
					</div>
				);
		}
		return null;
	};

	return (
		<>
			<Modal
				headerCloseBtn
				header={
					<header>
						<div className='header-container'>
							<div className='name-container'>
								{selectedAction &&
									// @ts-ignore
									getIcon(Number(selectedAction.value))}

								<div className='name'>
									<Input
										value={actionName}
										onChange={(event) => setActionName(event.target.value)}
										className='actions-title-input'
									/>
								</div>
							</div>

							<Dropdown
								value={selectedAction}
								options={actionsOptions}
								onChange={setSelectedAction}
								className='actions-dropdown'
							/>
						</div>
					</header>
				}
				className={`actions-modal ${className}`}
				actionButtons={false}>
				<Outlet
					context={{
						actionName,
						selectedAction,
						nextAction,
						setNextAction,
					}}
				/>
			</Modal>
		</>
	);
};
export default Actions;
