interface Props {
	width?: string;
	height?: string;
	fillIcon?: boolean;
	className?: string;
	color?: string;
	onClick?: (e: React.MouseEvent<SVGElement>) => void;
	style?: React.CSSProperties;
	rotateSVG?: boolean;
	onBlur?: (e: React.FocusEvent<SVGElement>) => void;
}

const Catalog = (props: Props) => {
	return (
		<svg
			data-name='draw_black_24dp (2)'
			xmlns='http://www.w3.org/2000/svg'
			width='32'
			height='32'
			{...props}
			viewBox='0 0 31.906 31.906'>
			<path data-name='Rectangle 6886' fill='none' d='M0 0h31.906v31.906H0z' />
			<path
				data-name='Path 6052'
				d='m24.072 12.825 1.409-1.409a2.664 2.664 0 0 0 0-3.762l-1.875-1.875a2.664 2.664 0 0 0-3.762 0l-1.409 1.409zm-7.525-3.763L4.329 21.293v5.637h5.637l12.218-12.218zm7.724 13.215c0 2.911-3.377 4.653-6.647 4.653a1.329 1.329 0 1 1 0-2.659c2.047 0 3.988-.97 3.988-1.994 0-.625-.638-1.157-1.635-1.6l1.968-1.968a4.043 4.043 0 0 1 2.326 3.568zM5.1 16.76A3.545 3.545 0 0 1 3 13.635c0-2.393 2.513-3.5 4.733-4.467 1.369-.612 3.244-1.436 3.244-2.18 0-.545-1.037-1.329-2.659-1.329a3.234 3.234 0 0 0-2.433.851 1.349 1.349 0 0 1-1.861.16 1.319 1.319 0 0 1-.2-1.835A5.651 5.651 0 0 1 8.318 3c2.978 0 5.318 1.755 5.318 3.988 0 2.486-2.566 3.616-4.839 4.613-1.25.545-3.137 1.369-3.137 2.034 0 .412.572.8 1.422 1.143z'
				transform='translate(.988 .988)'
				fill='currentColor'
			/>
		</svg>
	);
};

export const LinkIcon = (props: Props) => {
	return (
		<svg
			data-name='link_black_24dp (5)'
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'>
			<path data-name='Path 13848' d='M0 0h20v20H0z' fill='none' />
			<path
				data-name='Path 13849'
				d='M3.583 11.167a2.586 2.586 0 0 1 2.584-2.584H9.5V7H6.167a4.167 4.167 0 0 0 0 8.333H9.5V13.75H6.167a2.586 2.586 0 0 1-2.584-2.583zM7 12h6.667v-1.667H7zm7.5-5h-3.333v1.583H14.5a2.583 2.583 0 1 1 0 5.167h-3.333v1.583H14.5A4.167 4.167 0 1 0 14.5 7z'
				transform='translate(-.333 -1.167)'
				fill={props.color}
			/>
		</svg>
	);
};
export const PublishIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'>
			    <path data-name='Path 14900' d='M0 0h20v20H0z' fill='none' />   {' '}
			<path
				data-name='Path 14901'
				d='M5 4v1.667h11.667V4zm0 8.333h3.333v5h5v-5h3.333L10.833 6.5z'
				transform='translate(-.833 -.667)'
				fill='#fff'
			/>
		</svg>
	);
};
export default Catalog;
