import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../app/store';
import {
	addContactUser,
	setOffset,
	setPage,
} from '../../../../../Features/WhatsApp-BSP/contact/contactSlice';
import Modal from '../../../../../Components/Modal';
import './AddForm.scss';
// import Select from '../../../../../Components/Select';
import TimeInput from '../../../../../Components/TimePicker';
import DateInput from '../../../../../Components/DatePicker';
import Input from '../../../../../Components/Input';
// import Autocomplete from '../../../../../Components/Dropdown';
import Autocomplete from '../../../../../Components/AutoComplete';
import Button from '../../../../../Components/Button';
import { format, isPast, isValid } from 'date-fns';
import {
	convertDate,
	isHTMLTagsPresent,
	isValidDate,
	isValidTimeCheck,
	kotakTenantCheckForWhatsapp,
	loadState,
} from '../../../../../utils/utils';
// import Dropdown from '../../../../../Components/Dropdown';
import Dropdown from '../../../../../Components/Dropdown';
import { useSelector } from 'react-redux';
import { set } from '../../../../../Features/message/messageSlice';

const statusOptions = [
	{ value: 'opted in', label: 'Opted in' },
	{ value: 'opted out', label: 'Opted out' },
];

const consentChannelOptions = [
	{ value: 'email', label: 'Email' },
	{ value: 'in store', label: 'In store' },
	{ value: 'whatsapp', label: 'WhatsApp' },
	{ value: 'phone', label: 'Phone' },
	{ value: 'online - website form', label: 'Online - Website form' },
];

const AddForm = () => {
	const [mobileNo, setMobileNo] = useState('');
	const [mobileNoError, setMobileNoError] = useState<string | undefined>(
		undefined
	);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState<string | undefined>(undefined);
	const [consent, setConsent] = useState({
		value: 'opted in',
		label: 'Opted in',
	});
	const [statusError, setStatusError] = useState<string | undefined>(undefined);
	const [consentChannel, setConsentChannel] = useState({
		value: 'email',
		label: 'Email',
	});
	const [consentChannelError, setConsentChannelError] = useState<
		string | undefined
	>(undefined);
	const [date, setDate] = useState<Date | null | any>(null);
	const [dateError, setDateError] = useState('');
	const [time, setTime] = useState<Date | null>(null);
	const [timeError, setTimeError] = useState('');
	const [isValidTime, setIsValidTime] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { wabaNo } = useSelector((state: RootState) => state.BSPauth);
	const validatePhoneregex = (no: any) => {
		var newtest = /^(?!([0-9])\1+$)\d+$/;

		if (newtest.test(no)) {
			return true;
		} else {
			return false;
		}
	};
	const validatePhone = () => {
		if (
			!Boolean(mobileNo.length) ||
			!validatePhoneregex(mobileNo) ||
			mobileNo.trim().length !== 10
		) {
			if (!validatePhoneregex(mobileNo)) {
				setMobileNoError('Please enter a valid phone number');
			} else {
				setMobileNoError('Please enter a valid phone number');
			}
		} else {
			setMobileNoError(undefined);
		}
	};
	useEffect(() => {
		if (mobileNo) {
			validatePhone();
		}
	}, [mobileNo]);
	useEffect(() => {
		if (name) {
			validateName();
		}
	}, [name]);

	const validateName = () => {
		let validate = /^(?!\s+$)[A-Za-z\s_,?]*[A-Za-z][A-Za-z\s_,?]*$/i;
		if (validate.test(name.trim())) {
			setNameError(undefined);
		} else {
			setNameError('Please enter a valid Name');
		}
	};

	const validateDate = (date: string | Date | null | any) => {
		if (date) {
			setTimeError('');
			setTime(null);
			if (typeof date === 'string') {
				const dateArr = date.split('-');
				if (dateArr.length === 3) {
					if (isValidDate(`${dateArr[1]}-${dateArr[0]}-${dateArr[2]}`)) {
						setDateError('');
						return;
					}
				}
			} else if (typeof date === 'object') {
				const dateStr = date.toDateString();

				if (isValidDate(dateStr)) {
					setDateError('');
					return;
				}
			}
		}
		setDateError('Please enter a valid date');
	};

	const validateTime = (time: any) => {
		if (time && !isValid(time)) {
			setTimeError('Please enter a valid time');
			return;
		}

		let tdate = new Date();
		let currenttime = tdate.getTime();
		let selectedtime = time.getTime();
		let d1 = format(date, 'dd-MM-yyyy');
		let d2 = format(new Date(), 'dd-MM-yyyy');

		if (d1 === d2) {
			if (selectedtime > currenttime) {
				setTimeError('Please enter a valid time');
			} else {
				setTimeError('');
			}
		} else {
			setTimeError('');
		}
	};

	const validateConsentChannel = (channel: string | null) => {
		if (channel && channel.length > 0) {
			if (
				consentChannelOptions.filter((ch) => ch.value === channel).length === 1
			) {
				setConsentChannelError(undefined);
				return;
			}
		}
		setConsentChannelError('Please enter a valid consent channel');
	};

	const validateStatus = (st: string | null) => {
		if (st && st.length > 0) {
			if (statusOptions.filter((each) => each.value === st).length === 1) {
				setStatusError(undefined);
				return;
			}
		}
		setStatusError('Please enter a valid status value');
	};
	const isActionButtonActive = () => {
		if (
			Boolean(mobileNoError && mobileNoError.length) ||
			mobileNo.length <= 7 ||
			Boolean(dateError.length) ||
			!isValidDate(date?.toDateString()) ||
			Boolean(timeError.length) ||
			!isValidDate(time?.toDateString()) ||
			Boolean(consentChannelError) ||
			(consentChannel && consentChannel.value.length <= 0) ||
			Boolean(name.length <= 0)
		) {
			return false;
		}
		return true;
	};

	const createContactBtn = () => {
		const pickedDateAndTime = convertDate(date, time);
		const data = {
			name: name,
			mobileNo: mobileNo,
			channel: consentChannel ? consentChannel.value : '',
			consent: consent.value,
			consentTime:
				date && time && pickedDateAndTime
					? `${
							new Date(new Date(pickedDateAndTime).toUTCString())
								.toISOString()
								.split('T')[0]
					  } ${
							new Date(new Date(time).toUTCString())
								.toISOString()
								.split('T')[1]
								.split('.')[0]
					  }.0`
					: '',
			wabaNo: wabaNo,
		};
		dispatch(addContactUser(data));
		dispatch(setPage(0));
		dispatch(setOffset(0));
	};

	return (
		<Modal
			header='Add contact'
			actionButtons={
				<>
					<Button
						onClick={() => {
							navigate(-1);
						}}
						buttonType='light'>
						<p className='button-1'>Cancel</p>
					</Button>
					<Button
						onClick={() => {
							createContactBtn();
							navigate(-1);
						}}
						disabled={!isActionButtonActive() || isHTMLTagsPresent(name)}>
						<p className='button-1'>Add</p>
					</Button>
				</>
			}>
			<div className='add-contact'>
				<div className='input-group'>
					<Input
						autoFocus
						label='Phone number*'
						type='number'
						value={mobileNo}
						onChange={(event: any) => {
							setMobileNo(event.target.value);
							validatePhone();
						}}
						error={Boolean(mobileNoError)}
						helperText={mobileNoError}
						onBlur={validatePhone}
						fullWidth={true}
						onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
					/>
				</div>
				<div className='input-group'>
					<Input
						label='Name'
						value={name}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setName(event.target.value);
							validateName();
						}}
						error={Boolean(nameError)}
						helperText={nameError}
						onFocus={() => {
							setNameError(undefined);
						}}
						fullWidth={true}
						onBlur={validateName}
					/>
				</div>
				<div className='input-group'>
					<Autocomplete
						label='Status*'
						options={statusOptions}
						labelKey='label'
						value={consent}
						valueKey='value'
						onChange={(value) => setConsent(value)}
						error={Boolean(statusError)}
						helperText={statusError}
						onBlur={validateStatus}
					/>
				</div>
				<div className='input-group'>
					<Autocomplete
						label='Consent channel*'
						options={consentChannelOptions}
						labelKey='label'
						value={consentChannel}
						valueKey='value'
						onChange={(value) => setConsentChannel(value)}
						error={Boolean(consentChannelError)}
						helperText={consentChannelError}
						onBlur={validateConsentChannel}
					/>
				</div>
				<div className='input-group'>
					<DateInput
						label='Consent collection date*'
						value={date}
						onChange={(value: Date | null) => {
							setDate(value);
						}}
						disableFuture
						error={Boolean(dateError.length)}
						helperText={dateError}
						onBlur={validateDate}
					/>
				</div>
				<div className='input-group'>
					<TimeInput
						label='Consent collection time*'
						value={time}
						onChange={(value: Date | null) => {
							setTime(value);
							validateTime(value);
						}}
						error={Boolean(timeError.length)}
						helperText={timeError}
						onBlur={validateTime}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default AddForm;
