import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RESET, RESETPASSWORDSTATE, RESETRES } from './model';
import resetService from './resetPasswordService';

export const updatepassword = createAsyncThunk<
	RESETRES,
	RESET,
	{ rejectValue: string }
>('/updatepassword', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await resetService.updatepassword(data);
		// const response = {
		// 	id: null,
		// 	response: 'Failed',
		// 	error:
		// 		'Your new password must not match with your previous password. Please choose a different password.',
		// 	data: null,
		// };
		// console.log(response);
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(response.data.error);
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(JSON.stringify(response.data.message));
	}
});

const initialState: RESETPASSWORDSTATE = {
	resetLoader: false,
	somethingWentWrongDuringResetPassword: false,
	resetPasswordSuccess: false,
	error: '',
};

const resetPasswordSlice = createSlice({
	name: 'resetPassword',
	initialState,
	reducers: {
		setSomethingWentWrongDuringResetPassword: (state, action) => {
			state.somethingWentWrongDuringResetPassword = action.payload;
		},
		setResetPasswordSuccess: (state, action) => {
			state.resetPasswordSuccess = action.payload;
		},
		setErrorMessage: (state, action) => {
			state.error = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(updatepassword.pending, (state) => {
				state.resetLoader = true;
			})
			.addCase(updatepassword.fulfilled, (state, action) => {
				state.resetLoader = false;
				state.resetPasswordSuccess =
					action.payload.result === 'Success' ? true : false;
			})
			.addCase(updatepassword.rejected, (state, action) => {
				state.resetLoader = false;
				state.somethingWentWrongDuringResetPassword = true;
				// console.log(action.payload);

				state.error = action.payload ?? 'Something went wrong! Try again later';
			});
	},
});

export const {
	setSomethingWentWrongDuringResetPassword,
	setResetPasswordSuccess,
	setErrorMessage,
} = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
