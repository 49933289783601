import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Input from '../../../../../Components/Input';
import { Tooltip } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import {
	BackwardCarousel,
	Burger,
	ForwardCarousel,
	ImagePlaceholder
} from '../../../../../assets/Icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export type PreviewWelcomeHeader = {
	selectedColor: string;
	mode: string;
	colorScale: any;
	fontFamily: string;
};

const PreviewWelcomeHeader = styled.h4<PreviewWelcomeHeader>`
color: ${(props) => (props.mode === 'dark' ? props.selectedColor : props.colorScale[2])};
overflow-wrap: break-word;
max-width: 75%;
text-align: center;
max-height:calc(100vh - 508px);
overflow: auto;
font-size: 1.875em;
font-family: ${(props) => props.fontFamily};

`;
const InputDiv = styled.div<PreviewWelcomeHeader>`

>.ff-input {
	
	>.MuiFormControl-root{
		
	>.MuiInputBase-root{
		input{
			font-family: ${(props) => props.fontFamily};
		}
		&:not(.Mui-disabled, .Mui-error).Mui-focused {
			.MuiOutlinedInput-notchedOutline {
			border:2px solid ${(props) => (props.mode === 'dark' ? props.selectedColor : props.colorScale[2])}} !important;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.28);
			}
		}
	}
	} 
   }
`


const WelcomeFullScreen = () => {



	const { selectedColor, colorMode, colorScale, carouselFieldsDataFullscreen, fontSize, fontFamily } = useSelector(
		(state: RootState) => state.experienceonweb
	);
	const { introductoryHeading, introductoryBody } = useSelector(
		(state: RootState) => state.experienceonweb
	);

	const [lists, setLists] = useState<string[]>([]);
	const [query, setQuery] = useState<string[]>([]);
	useEffect(() => {
		let data = carouselFieldsDataFullscreen.slice().filter((value, i) => i % 2 === 0);
		setLists(data);
		let query = carouselFieldsDataFullscreen.slice().filter((value, i) => i % 2 !== 0);
		setQuery(query);
	}, [carouselFieldsDataFullscreen]);

	const slider: any = React.useRef();

	const next = () => {
		console.log("Next")
		slider.current.slickNext();
	}
	const previous = () => {
		console.log("Prev")
		slider.current.slickPrev();
	}

	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToScroll: 1,
		slidesToShow: 3,
		prevArrow: <div className='ff-back-wel' onClick={() => { previous() }}>
			<BackwardCarousel
				fill={
					colorMode === 'dark'
						? 'var(--selectedColor)'
						: colorScale[0]
				} />
		</div>,
		nextArrow: <div onClick={() => { next() }}>
			<ForwardCarousel
				fill={
					colorMode === 'dark'
						? 'var(--selectedColor)'
						: colorScale[0]
				} />
		</div>
	};
	return (
		<div className='ff-configure-experience-on-web' style={{ fontSize: fontSize.value }}>
			<div className='experience-on-web'>
				<PreviewWelcomeHeader selectedColor={selectedColor} mode={colorMode} colorScale={colorScale} fontFamily={fontFamily.value}>
					{introductoryHeading}
				</PreviewWelcomeHeader>
				<div className='body2' style={{ marginTop: '16px', maxWidth: '72%', textAlign: 'center', maxHeight: 'calc(100vh - 508px)', overflow: 'auto', overflowWrap: 'break-word', fontSize: '0.875em', fontFamily: fontFamily.value }}>{introductoryBody}</div>
			</div>
			<InputDiv className={`ff-welcome-preview-input ${colorMode}`} selectedColor={selectedColor} mode={colorMode} colorScale={colorScale} fontFamily={fontFamily.value}>
				<Input placeholder='What are you looking for?' />
			</InputDiv>
			{lists.length == 0?

            <div className='ff-carousel-template'>
				{
					Array.from(Array(3)).map((i) => {
						  return(
							<div className='ff-carousel-template-item'>
                               <div className='ff-carousel-template-item-image'>
                                 <ImagePlaceholder />
							   </div>
							   <div className='ff-carousel-template-item-title' style={{marginTop:'20px'}}>Add title</div>

							</div>
						  )
					  })
					  
				}

			</div>

			:
        <div className={`ff-welcome-preview-carousel ${lists.length > 3 ? 'ff-preview-long-card' : ''}`} >
				<Slider centerMode={true} {...settings}>
					{lists.map((item, index) => {
						
						return (
							<div>
								<img className='ff-card-img-fs' src={item} />
								<div className='ff-card-img-fs-content' style={{backgroundColor:selectedColor,color:colorMode === 'dark'?'#fff':'#000'}}>
									<div style={{textAlign:'center',padding:'0px 10px',fontWeight:'600'}}>
									<Tooltip title={query[index]}>
											<p>{query[index].length>60?query[index].slice(0,60)+'...':query[index]}</p>
									</Tooltip>
										
									</div>
								</div>
							</div>
						)

					})

					}
				</Slider>
				{/* <Carousel
					showArrows={lists.length > 2}
					showThumbs={false}
					showStatus={false}
					swipeable={true}
					centerMode={true}
					centerSlidePercentage={80}
					width='100%'
					renderArrowNext={(onClickHandler, hasNext) =>
						hasNext && (
							<button
								className={`next-arrow`}
								onClick={onClickHandler}
								style={{
									top: '45%',
									right: '24px',
									position: 'absolute',
								}}>
								<ForwardCarousel
									fill={
										colorMode === 'dark'
											? selectedColor
											: colorScale[2]
									}
								/>
							</button>
						)
					}
					renderArrowPrev={(onClickHandler, hasPrev) =>
						hasPrev && (
							<button
								className={`prev-arrow`}
								onClick={onClickHandler}
								style={{
									top: '45%',
									left: '24px',
									position: 'absolute',
									zIndex: '2',
								}}>
								<BackwardCarousel
									fill={
										colorMode === 'dark'
											? selectedColor
											: colorScale[0]
									}
								/>
							</button>
						)
					}>
					{lists.map((card: any, i: number) => {
						return (
							<>
								<div
									className='carousel-slider-list-item'
									key={card}>
									<img
										src={card}
										alt=''
										style={{
											height: '192px',
											objectFit: 'cover',
											maxWidth: '340px',
											borderRadius: '8px',
										}}
									/>
								</div>
							</>
						);
					})}
				</Carousel> */}

			</div>

			}
			
			<Outlet />
		</div>

	);
};

export default WelcomeFullScreen;
