import { Link, useLocation, useNavigate } from 'react-router-dom';
import './hbheader.scss';
import { BackIcon, ExpandIcon, ExpandedIcon, FeedbackRateIcon, ReportIcon } from '../../../../assets/Icons/HelpBot';
import { HBSetScreen, setExpandWidth, setmessageFeedbackComment,setisModalVisible } from '../../../../Features/HelpBot/helpbotSlice';
import { RootState, useAppDispatch } from '../../../../app/store';
import { useSelector } from 'react-redux';
import { useState } from 'react';

function HelpBot() {
    const {
        msgsArray,
        HBscreen,
        expandWidth,
    } = useSelector((state: RootState) => state.helpBot);
    const dispatch = useAppDispatch();

    // const openThumbsPopup = (val:any, up:any, id:any) => {
    // 	this.setState({
    // 		messageFeedbackComment: val,
    // 		thumbFilledUp: up,
    // 		messageId: id,
    // 		isModalVisible: val,
    // 	}, () => {
    // 		console.log(this.state.thumbFilledUp)
    // 	});
    // };
    return (
        <div className={`ff-helpbot-header-container ${msgsArray.length > 0 ? 'ff-hb-chatbody-header' : ''}
        ${HBscreen === 'report' ? 'ff-hb-remove-bg-header' : ''}`}>
            <div className='ff-header-top-container'>

                <h6 className='header-text'>
                    {
                        HBscreen === 'report' ?
                            <span onClick={() => {
                                dispatch(HBSetScreen('homescreen'))
                            }}
                            >
                                <BackIcon />
                                Report an issue
                            </span> :
                            'Help'}
                </h6>
                <div className='header-icons'>
                    {HBscreen !== 'report' &&
                        <span className='ff-icon-pointer' onClick={() => {
                            dispatch(HBSetScreen('report'))
                        }} title='Report an issue'>

                            <ReportIcon />
                        </span>
                    }
                    <span className='ff-icon-pointer' onClick={() => {
                        dispatch(HBSetScreen('feedback'));
                        setmessageFeedbackComment(true);
                        dispatch(setisModalVisible(true));
                    }} title='Give Ratings'>
                        <FeedbackRateIcon />
                    </span>


                    {
                        !expandWidth ? (
                            <span className='ff-icon-pointer' onClick={() => {
                                dispatch(setExpandWidth(true))

                            }} title='Expand'>
                                <ExpandIcon />
                            </span>
                        )
                            :
                            <span className='ff-icon-pointer' title='Minimise' onClick={() => {
                                dispatch(setExpandWidth(false))
                                
                            }}>
                                <ExpandedIcon />
                            </span>
                    }
                </div>
            </div>
            {HBscreen === 'report' || msgsArray.length > 0 ?
                null :
                <>
                    <h3 className='have-question'>
                        Have a Question?
                    </h3>
                    <div className='body2 ws-header-color'>
                        Type in your query or select from the menu below
                    </div>
                </>

            }


        </div>
    )
}
export default HelpBot;