import { Link, useNavigate } from 'react-router-dom';
import './SignUp.scss';
import './setPassword.scss';
import flyfishlogo from '../../assets/images/logo.png';
import SignUpImage from '../../assets/images/landing_page/login-image.png';
// import maleWithSMSIcon from '../../assets/images/male-with-sms-icon.png';
import Button from '../../Components/Button';
import {
	BasicsTick,
	BasicsX,
	LoginEyeClosed,
	LoginEyeOpen,
	TickCircle,
} from '../../assets/Icons';
import { useEffect, useState } from 'react';
import {
	encryptText,
	loadState,
	removeAllState,
	saveState,
} from '../../utils/utils';
import {
	redirectURI_Shopify,
	reset,
	setEncUserId,
} from '../../Features/auth/authSlice';
import authService from '../../Features/auth/authService';
import Timer from './Timer';
import {
	ResendOTPData,
	SetPasswordData,
	UserSignUpData,
	ValiditateOTPData,
} from '../../Features/auth/model';
import { RootState, useAppDispatch } from '../../app/store';
import Checkbox from '../../Components/Checkbox';
import Login from '../Login';
import WelcomeImg from '../../assets/images/side_welcome.svg';
import Input from '../../Components/Input';
import axios from 'axios';
import {
	setOrganizationName,
	setParams,
	setUserEmailWhileSignUp,
	shopAuth_shopify,
} from '../../Features/Subscription/subscriptionSlice';
import { useSelector } from 'react-redux';
import RedirectLoader from '../RedirectLoader';

const SignUp = () => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [password, setPassword] = useState('');
	const [checkPassword, setCheckPassword] = useState('');
	const [verification, setVerification] = useState('');
	const [checkVerification, setCheckVerification] = useState('');
	const [showLoader, setShowLoader] = useState(false);
	const [resend, setResend] = useState(false);
	const [verifyEmailDone, setVerifyEmailDone] = useState(false);
	const [verifyVerificationDone, setVerifyVerificationDone] = useState(false);
	const [verifyPasswordDone, setVerifyPasswordDone] = useState(false);
	const [checkboxValid, setCheckboxValid] = useState(false);
	const [errorGroup, setErrorGroup] = useState({
		area: '',
		errorMessage: '',
	});
	//const [encUserId, setEncUserId] = useState('');
	const [resendLoader, setResendLoader] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { userEmailWhileSignUp, organizationName, shopAuthLoader } =
		useSelector((state: RootState) => state.subscription);
	const { encUserId } = useSelector((state: RootState) => state.auth);
	const [retryLoader, setRetryLoader] = useState(false);
	const [showContent, setShowContent] = useState(false);

	useEffect(() => {
		removeAllState();

		if (window.location.search.length) {
			if (window.location.search.indexOf('plan_type') > -1) {
				// dispatch(setParams(window.location.search.substring(1)));
				saveState('parameter', window.location.search.substring(1));
			} else {
				// dispatch(setParams('notLoggedIn'));
				saveState('parameter', 'notLoggedIn');
				const params = new URLSearchParams(window.location.search.substring(1));

				let shopCode = params.get('code');
				let shopName = params.get('shop');
				if (shopCode?.length && shopName?.length) {
					saveState('shopifyShopName', params.get('shop'));

					saveState('shopCode', shopCode);
					dispatch(
						shopAuth_shopify({
							storeName: shopName,
							storeCode: shopCode,
						})
					);
				}
			}
		} else {
			// dispatch(setParams('notLoggedIn'));
			saveState('parameter', 'notLoggedIn');
		}
	}, []);

	useEffect(() => {
		if (shopAuthLoader === 'success') {
			setRetryLoader(false);
			setShowContent(true);
		} else if (shopAuthLoader === 'failed') {
			setRetryLoader(true);
			setShowContent(false);
		}
	}, [shopAuthLoader]);

	const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(reset());
		setErrorGroup({
			area: '',
			errorMessage: '',
		});
		dispatch(setUserEmailWhileSignUp(event.target.value.toLowerCase().trim()));
	};
	function isValidEmail(email: string) {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); //NOSONAR
	}

	function isValidCode(code: string) {
		return code.length >= 6;
	}
	function verifyPassword() {
		return passwordValidator().some((validator) => !validator.hasPassed);
	}
	const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(reset());
		setErrorGroup({
			area: '',
			errorMessage: '',
		});
		setPassword(event.target.value.trim());
	};
	const handleCheckPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(reset());
		setErrorGroup({
			area: '',
			errorMessage: '',
		});
		setCheckPassword(event.target.value.trim());
	};

	const handleVerification = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(reset());
		setErrorGroup({
			area: '',
			errorMessage: '',
		});
		if (event.target.value.trim().length <= 6 && !isNaN(+event.target.value)) {
			setVerification(event.target.value.trim());
			setCheckVerification(event.target.value.trim());
		} else setVerification(checkVerification);
	};
	function changeEmail() {
		setVerifyEmailDone(false);
		setVerification('');
		setCheckVerification('');
		setErrorGroup({
			area: '',
			errorMessage: '',
		});
		setVerifyVerificationDone(false);
		toggleLoader(false);
		setResend(false);
	}
	async function resendVerificationCode() {
		setResend(false);
		var data: ResendOTPData = {
			userId: encUserId,
		};
		// toggleLoader(true);
		setResendLoader(true);
		try {
			const response = await authService.resendOtp(data);
			const userData = response.data;

			switch (response.status === 200) {
				default:
					// toggleLoader(false);
					setResendLoader(false);
					break;
			}
		} catch (error: any) {
			const response = error.response;
			switch (response.status) {
				case 400:
				case 401:
				case 409:
					setVerification(verification);
					setCheckVerification(checkVerification);
					toggleLoader(false);
					setErrorGroup({
						area: 'resendOtp',
						errorMessage: 'Unable to resend OTP.',
					});
					break;
				default:
					// toggleLoader(false);
					setResendLoader(false);
					break;
			}
		}
	}
	async function verifyEmail() {
		let name = userEmailWhileSignUp.split('@')[0];
		var data: UserSignUpData = {
			email: userEmailWhileSignUp,
			firstName: name.split('.')[0] ? name.split('.')[0] : name,
			lastName: name.split('.')[1] ? name.split('.')[1] : '',
			organizationName: organizationName,
		};
		toggleLoader(true);
		try {
			const response = await authService.verifySignUpEmail(data);
			const userData = response.data;
			switch (response.status) {
				case 200:
					setVerifyEmailDone(true);
					dispatch(setEncUserId(userData.id));
					toggleLoader(false);
					break;
				default:
					setErrorGroup({
						area: 'email',
						errorMessage: 'Sorry, something went wrong. Please try again!',
					});
					toggleLoader(false);
					break;
			}
		} catch (error: any) {
			const response = error.response;
			switch (response.status) {
				case 409:
					dispatch(setUserEmailWhileSignUp(userEmailWhileSignUp));
					setErrorGroup({
						area: 'email',
						errorMessage:
							'A user account already exists for this email address. Please login instead or use a different email.',
					});
					toggleLoader(false);
					break;
				case 406:
					dispatch(setUserEmailWhileSignUp(userEmailWhileSignUp));
					setErrorGroup({
						area: 'email',
						errorMessage: 'Please Provide valid Email',
					});
					toggleLoader(false);
					break;
				default:
					setErrorGroup({
						area: 'email',
						errorMessage: 'Sorry, something went wrong. Please try again!',
					});
					toggleLoader(false);
					break;
			}
		}
	}
	function toggleLoader(value: boolean) {
		setShowLoader(value);
	}

	async function submitVerificationCode() {
		var data: ValiditateOTPData = {
			otp: verification,
			userId: encUserId,
		};
		toggleLoader(true);
		try {
			const response = await authService.validateOtp(data);

			switch (response.status) {
				case 200:
					setVerifyVerificationDone(true);
					toggleLoader(false);
					break;
				default:
					setErrorGroup({
						area: 'verification',
						errorMessage: 'Sorry, something went wrong. Please try again!',
					});
					toggleLoader(false);
					break;
			}
		} catch (error: any) {
			const response = error.response;
			switch (response.status) {
				case 409:
					setVerification(verification);
					setCheckVerification(checkVerification);
					setErrorGroup({
						area: 'verification',
						errorMessage: 'Invalid verification code. Please try again',
					});
					toggleLoader(false);
					break;
				default:
					setErrorGroup({
						area: 'verification',
						errorMessage: 'Sorry, something went wrong. Please try again!',
					});
					toggleLoader(false);
					break;
			}
		}
	}

	async function submitSetPassword() {
		var data: SetPasswordData = {
			confirmPassword: encryptText(checkPassword),
			password: encryptText(password),
			userId: encUserId,
		};
		toggleLoader(true);
		try {
			const response = await authService.submitSetPassword(data);
			const userData = response.data;

			switch (response.status) {
				case 200:
					setVerifyPasswordDone(true);
					saveState('userId', userData.data.userId);
					saveState('token', userData.data.token);
					saveState(
						'basictoken',
						btoa(
							`${encryptText(userEmailWhileSignUp)}:${encryptText(
								checkPassword
							)}`
						)
					);
					saveState(
						'crmBasictoken',
						btoa(
							`${encryptText(userEmailWhileSignUp)}:${encryptText(
								checkPassword
							)}`
						)
					);
					saveState('userEmailId', userEmailWhileSignUp);
					// let shop = loadState('shopifyShopName');
					// if (shop && shop.length) {

					// } else {
					navigate('/app/signup-select-plan');
					// }

					break;
				default:
					setErrorGroup({
						area: 'password',
						errorMessage: 'Sorry, something went wrong. Please try again!',
					});
					toggleLoader(false);
					break;
			}
		} catch (error: any) {
			const response = error.response;

			switch (response.status) {
				case 400:
				case 401:
					setErrorGroup({
						area: 'password',
						errorMessage:
							'Your new password should be different from your earlier password',
					});
					break;
				case 409:
					setPassword(password);
					setCheckPassword(checkPassword);
					toggleLoader(false);
					break;
				default:
					setErrorGroup({
						area: 'password',
						errorMessage: 'Sorry, something went wrong. Please try again!',
					});
					toggleLoader(false);
					break;
			}
		}
	}
	function passwordValidator(): { label: string; hasPassed: boolean }[] {
		return [
			{
				label: '8-30 characters',
				hasPassed: password.length >= 8 && password.length <= 30,
			},

			{
				label: 'At least one uppercase character',
				hasPassed: password.search(/[A-Z]/) > -1,
			},
			{
				label: 'At least one lowercase character',
				hasPassed: password.search(/[a-z]/) > -1,
			},
			{
				label: 'At least one number',
				hasPassed: password.search(/\d/) > -1,
			},
			{
				label: 'At least one special character',
				hasPassed: password.search(/[!@#$%^&+=*]/) > -1,
			},
			{
				label: 'Please make sure that both values match',
				hasPassed: password === checkPassword && password.length !== 0,
			},
		];
	}

	const signUpContent = () => {
		return (
			<div className='ff-signup'>
				<div className='signup-left-container'>
					<img src={WelcomeImg} alt='' />
				</div>
				<div className='signup-right-container'>
					<div className='form'>
						<h4>Sign up</h4>
						<div className='main-container-signup'>
							<div className='input-label'>
								<span className={verifyEmailDone ? 'verified' : 'unverified'}>
									1. Enter your work email ID
								</span>
								<span>
									{verifyEmailDone ? (
										<TickCircle width='20' height='20' />
									) : (
										<></>
									)}
								</span>
							</div>
							{!verifyEmailDone ? (
								<div className='input-container-signup'>
									<div className='input-container'>
										<Input
											placeholder='someone@example.com'
											label={'Email address'}
											fullWidth
											type='text'
											value={userEmailWhileSignUp}
											onChange={handleUsername}
											className='email-input-box'
											autoFocus
										/>
										<Input
											className='organisation-name'
											fullWidth
											type='text'
											label={'Organization name'}
											// placeholder='Organization name'
											value={organizationName}
											onChange={(e) =>
												dispatch(setOrganizationName(e.target.value))
											}
											disAllowSpecialChar={true}
										/>
									</div>
									<div className='button-container'>
										<Button
											fullWidth
											loading={showLoader ? true : false}
											size='large'
											className='signup-btn'
											type='submit'
											onClick={verifyEmail}
											disabled={
												isValidEmail(userEmailWhileSignUp) &&
												organizationName.trim().length > 2
													? false
													: true
											}>
											Verify
										</Button>
									</div>
								</div>
							) : (
								<></>
							)}

							{errorGroup.area === 'email' ? (
								<div className='input-description red'>
									{errorGroup.errorMessage}
								</div>
							) : (
								<></>
							)}
						</div>
						<div className='main-container-signup'>
							<div className='input-label'>
								<span
									className={
										verifyVerificationDone ? 'verified' : 'unverified'
									}>
									2. Enter the verfication code
								</span>
								<span>
									{verifyVerificationDone ? (
										<TickCircle width='21' height='21' />
									) : (
										<></>
									)}
								</span>
							</div>
							{verifyEmailDone && !verifyVerificationDone ? (
								<>
									<div className='input-description'>
										Please enter the verification code sent to{' '}
										{userEmailWhileSignUp} |
										<span className='click-button' onClick={changeEmail}>
											{' '}
											Change email
										</span>
									</div>
									<div className='input-container-signup'>
										<div className='input-container'>
											<Input
												autoFocus
												fullWidth
												type='text'
												value={verification}
												onChange={handleVerification}
												className='verify-input-box'
												placeholder='XXX XXX'
											/>
										</div>
										{!resend && !verifyVerificationDone && !resendLoader ? (
											<div
												style={{
													paddingTop: '12px',
													paddingBottom: '12px',
													color: 'var(--black-40)',
												}}
												className='click-button-wait'>
												Resend verification code (00:&nbsp;
												<Timer enableButton={() => setResend(true)} />)
											</div>
										) : (
											<div
												style={{ paddingTop: '12px', paddingBottom: '12px' }}
												className={'click-button'}
												onClick={resendVerificationCode}>
												{resendLoader
													? 'Sending...'
													: 'Resend verification code'}
											</div>
										)}
										<div className='button-container'>
											<Button
												fullWidth
												loading={showLoader ? true : false}
												size='large'
												className='signup-btn'
												type='submit'
												onClick={submitVerificationCode}
												disabled={isValidCode(verification) ? false : true}>
												Submit
											</Button>
										</div>
									</div>
								</>
							) : (
								<></>
							)}
							{errorGroup.area === 'verification' ? (
								<div className='input-description red'>
									{errorGroup.errorMessage}
								</div>
							) : (
								<></>
							)}
						</div>
						<div className='main-container-signup password-container'>
							<div className='input-label'>
								<span
									className={verifyPasswordDone ? 'verified' : 'unverified'}>
									3. Create a strong password for your account
								</span>
								<span>
									{verifyPasswordDone ? (
										<TickCircle width='21' height='21' />
									) : (
										<></>
									)}
								</span>
							</div>
							{verifyEmailDone &&
							verifyVerificationDone &&
							!verifyPasswordDone ? (
								<>
									<div className='input-container-signup'>
										<div className='input-container'>
											<Input
												fullWidth
												type={isPasswordVisible ? 'text' : 'password'}
												value={password}
												onChange={handlePassword}
												placeholder='Enter password'
												endIcon={
													<div
														className='toggle-password-show'
														onClick={() =>
															setIsPasswordVisible(!isPasswordVisible)
														}>
														{isPasswordVisible ? (
															<LoginEyeClosed width='19px' />
														) : (
															<LoginEyeOpen width='19px' />
														)}
													</div>
												}
											/>
										</div>
									</div>
									<div className='input-container-signup'>
										<div className='input-container'>
											<Input
												fullWidth
												type={isPasswordVisible ? 'text' : 'password'}
												value={checkPassword}
												onChange={handleCheckPassword}
												placeholder='Confirm password'
												endIcon={
													<div
														className='toggle-password-show'
														onClick={() =>
															setIsPasswordVisible(!isPasswordVisible)
														}>
														{isPasswordVisible ? (
															<LoginEyeClosed width='19px' />
														) : (
															<LoginEyeOpen width='19px' />
														)}
													</div>
												}
											/>
										</div>
									</div>

									<div className='pwd-section'>
										<span
											className='password-heading'
											style={{ paddingTop: '16px' }}>
											Your password must have
										</span>

										<ul className='password-label-not-show'>
											{passwordValidator().map((validator, index) => (
												<li
													title={validator.label}
													key={index}
													className={
														password.length > 0 || checkPassword.length > 0
															? 'list-butn'
															: ''
													}>
													<div className='pass-li'>
														{password.length > 0 || checkPassword.length > 0 ? (
															<div>
																{validator.hasPassed ? (
																	<BasicsTick width='14px' height='14px' />
																) : (
																	<BasicsX width='13px' height='13px' />
																)}
															</div>
														) : null}

														{validator.label}
													</div>
												</li>
											))}
										</ul>

										<span className='password-heading'>For Example</span>

										<ul className='password-example extra'>
											<li>N3w.4ge@897</li>
											<li>MissionMar$4</li>
										</ul>
									</div>
									{errorGroup.area === 'password' ? (
										<div className='input-description red'>
											{errorGroup.errorMessage}
										</div>
									) : (
										<></>
									)}
									<div className='checkbox-verification-container'>
										<div className='signup-checkbox-desc-holder '>
											<div className='checkbox-holder'>
												<Checkbox
													isChecked={[checkboxValid]}
													index={0}
													onValueChange={() => {
														setCheckboxValid(!checkboxValid);
													}}
												/>
											</div>
											<p className='caption'>
												By signing up, I confirm that I have read and accepted
												the
												<a
													href='https://www.flyfish.ai/privacy-policy'
													target='_blank'
													rel='noreferrer'>
													{' '}
													Privacy Policy
												</a>{' '}
												and{' '}
												<a
													href='https://www.flyfish.ai/terms-conditions.html'
													target='_blank'
													rel='noreferrer'>
													Terms and Conditions
												</a>
											</p>
										</div>

										<div className='button-container caption'>
											<Button
												fullWidth
												size='large'
												type='submit'
												loading={showLoader ? true : false}
												onClick={() => {
													submitSetPassword();
												}}
												disabled={
													checkboxValid &&
													errorGroup.area !== 'password' &&
													!verifyPassword()
														? false
														: true
												}>
												Sign Up
											</Button>
										</div>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
						<div className='helper-links caption'>
							<p className='caption'>
								Already have an account? <Link to='/app/login'>Sign In</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			{loadState('shopifyShopName') === undefined ? (
				signUpContent()
			) : showContent ? (
				signUpContent()
			) : (
				<div className='query-loader'>
					<RedirectLoader
						message={`Fetching your Installation details from Shopify`}
						showRetry={retryLoader}
					/>
				</div>
			)}
		</>
	);
};
export default SignUp;
