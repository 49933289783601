import './Conditions.scss';
import {
	comparesWith,
	ValidationConditions,
	numberOperations,
	textOperations,
	getIconForNextAction,
} from '../common';
import { BotBuilderPlus, Delete } from '../../../../assets/Icons';
import Dropdown, {
	DropdownValue,
	DropdownValueProps,
} from '../../../../Components/Dropdown';
import Input from '../../../../Components/Input';
import Button from '../../../../Components/Button';
import { useEffect, useState } from 'react';
import builderService from '../../../../Features/builder/builderService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import {
	ActionDetails,
	ConditionBranch,
} from '../../../../Features/builder/model';
import InteractiveButton from '../../../../Components/Button/InteractiveButton';
import NextNewAction from '../NextNewAction';
import { useOutletContext } from 'react-router-dom';
import { format } from 'date-fns';

type ConditionsProps = {
	allConditions: ValidationConditions[];
	setAllConditions: React.Dispatch<any>;
	workflowId: number;
	nextActions: DropdownValueProps[];
	addParam?: boolean;
	paramName?: string;
	setParamName?: React.Dispatch<string>;
	setOpenNextActionType: React.Dispatch<any>;
	setNextActionTypeIdForConditions: React.Dispatch<any>;
	nextActionTypeIdForConditions: number;
	openNextActionType: boolean;
	setConditionIndex?: React.Dispatch<any>;
	setCheckEmpytValue?: React.Dispatch<any>;
};

const validations = [
	{
		value: '',
		label: 'None',
	},
	{
		value: 'Text',
		label: 'Text',
	},
	{
		value: 'Number',
		label: 'Number',
	},
	{
		value: 'Date',
		label: 'Date',
	},
];

export const getConditionBranch = (
	allConditions: ValidationConditions[],
	paramName: string
) => {
	let conditionBranch: ConditionBranch[] = [];

	conditionBranch = allConditions.map((condition) => ({
		operators: condition.operators ?? '',
		sourceValidationType: condition.validation,
		sourceValidation: condition.sourcePath ? condition.sourcePath : paramName,
		connectedTaskId:
			// @ts-ignore
			condition.nextAction ? Number(condition.nextAction.value) : 0,
		conditionBranchId: condition.conditionBranchId,
		destinationValidationType: condition.compareWith ?? '',
		destinationValidtion:
			condition.compareWith === 'Values'
				? condition.value ?? ''
				: condition.variable ?? '',
		// sourceValidation: paramName,
	}));

	return conditionBranch;
};

export const updateConditions = (
	actionDetails: ActionDetails,
	nextActions: DropdownValueProps[]
) => {
	return actionDetails?.conditionBranch.map((condition) => ({
		operators: condition.operators,
		validation: condition.sourceValidationType,
		sourcePath: condition.sourceValidation,
		nextAction:
			nextActions.filter(
				(action) => action.value === condition.connectedTaskId.toString()
			)[0] ?? null,
		conditionBranchId: condition.conditionBranchId,
		compareWith: condition.destinationValidationType,
		value:
			condition.destinationValidationType === 'Values'
				? condition.destinationValidtion
				: '',
		variable:
			condition.destinationValidationType === 'Parameter'
				? condition.destinationValidtion
				: '',
	}));
};

const Conditions = ({
	allConditions,
	setAllConditions,
	workflowId,
	nextActions,
	addParam = false,
	paramName,
	setParamName,
	setOpenNextActionType,
	setNextActionTypeIdForConditions,
	nextActionTypeIdForConditions,
	openNextActionType,
	setConditionIndex,
	setCheckEmpytValue,
}: ConditionsProps) => {
	const [variableNames, setVariableNames] = useState<DropdownValue[]>([]);
	const { botId, tenantId } = useSelector((state: RootState) => state.builder);
	const [label, setLabel] = useState('Next Action');
	const context: any = useOutletContext();
	const [indexToUpdateDropdownValue, setIndexToUpdateDropdownValue] = useState<
		null | number
	>(null);
	useEffect(() => {
		if (botId && tenantId) {
			(async () => {
				const params = await builderService.getSuggestedResponseParam({
					botId,
					tenantId,
					workflowId: workflowId,
				});

				if (params.status === 200 && params.data.length > 0) {
					const variables = params.data.map((param: string) => ({
						value: param,
						label: param,
					}));

					setVariableNames(variables);
				}
			})();
		}
	}, []);

	useEffect(() => {
		//to update the dropdown value on dropdown while selecting
		let next_action: DropdownValue = {
			value: label,

			label: (
				<div className={`actions-label action${nextActionTypeIdForConditions}`}>
					{getIconForNextAction(nextActionTypeIdForConditions)}

					<p>{label}</p>
				</div>
			),

			labelValue: label ?? 'Untitled',
		};

		setAllConditions((prev: any) =>
			prev.map((valid: any, i: number) =>
				i === indexToUpdateDropdownValue
					? { ...valid, nextAction: next_action }
					: valid
			)
		);
	}, [label]);
	const handleAddCondition = () => {
		setAllConditions((prev: any) => [
			...prev,
			{ validation: '', nextAction: null, conditionBranchId: 0 },
		]);
	};

	useEffect(() => {
		const temp = allConditions.forEach(
			(each, i) =>
				each.compareWith === 'Values'
					? !Boolean(each.value?.trim().length) &&
					  setCheckEmpytValue &&
					  setCheckEmpytValue(false)
					: setCheckEmpytValue && setCheckEmpytValue(true)

			// : setCheckEmpytValue && setCheckEmpytValue(false)
		);
	}, [allConditions]);

	const handleDeleteCondition = (index: number) => {
		setAllConditions((prev: any) =>
			prev.filter((cond: any, i: number) => i !== index)
		);
	};

	const handleConditionChange = (validation: any, index: number) => {
		if (validation.nextAction) {
			setConditionIndex && setConditionIndex(index);
			setIndexToUpdateDropdownValue && setIndexToUpdateDropdownValue(index);
			if (validation.nextAction.value === 'add new action') {
				setOpenNextActionType(true);
			}
		}

		if (validation.compareWith === 'Values') {
			setCheckEmpytValue && setCheckEmpytValue(true);
		}

		setAllConditions((prev: any) =>
			prev.map((valid: any, i: number) =>
				i === index ? { ...valid, ...validation } : valid
			)
		);
	};
	const validateNumber = (e: any) => {
		return ['e', 'E', '+', '-'].indexOf(e.key) > -1 && e.preventDefault();
	};

	const validationForValue = (value: string | undefined) => {
		if (setCheckEmpytValue) {
			if (value && Boolean(value.length)) {
				setCheckEmpytValue(true);
				return value;
			} else {
				setCheckEmpytValue(false);
				return value;
			}
		} else {
			return value;
		}
	};

	const getDateValue = (value: string) => {
		if (value) {
			const dateValue = value.split('-');
			return dateValue[2] + '-' + dateValue[1] + '-' + dateValue[0];
		} else {
			return '';
		}
	};
	return (
		<>
			<h6>Conditional Next Actions</h6>
			<div className='conditions'>
				{allConditions.length > 0 &&
					allConditions.map((condition, index) => (
						<div className='condition' key={index}>
							<div className='head'>
								<p className='conditionName'>Condition {index + 1}</p>
								<InteractiveButton
									icon={<Delete width='13px' />}
									hoverable='none'
									title='Delete'
									onClick={() => handleDeleteCondition(index)}
								/>
							</div>

							{/* {addParam && paramName && setParamName && (
								<div className='add-param'>
									<Input
										placeholder='Enter variable name or JSON path'
										value={paramName}
										onChange={(event) => setParamName(event.target.value)}
									/>
								</div>
							)} */}

							{addParam && (
								<div className='add-param'>
									<Input
										key={index}
										label='Value to be checked'
										placeholder='Enter variable name or JSON path'
										value={condition.sourcePath ?? ''}
										//paramName  fullWidth
										fullWidth
										onChange={
											(event) =>
												handleConditionChange(
													{
														sourcePath: validationForValue(event.target.value),
													},
													index
												)
											//  {setParamName && setParamName(event.target.value)}
										}
									/>
								</div>
							)}
							<div className='validations'>
								<div className='row'>
									<Dropdown
										label='Type'
										value={
											validations.filter(
												(validation) =>
													validation.value === condition.validation
											)[0]
										}
										options={validations}
										onChange={(dropdown) =>
											handleConditionChange(
												/* @ts-ignore */
												{ validation: dropdown.value },
												index
											)
										}
										className='CompareWithType'
									/>

									{condition.validation !== '' && (
										<Dropdown
											label='Compare with'
											value={
												comparesWith.filter(
													(cmp) => cmp.value === condition.compareWith
												)[0]
											}
											options={comparesWith}
											onChange={(dropdown) =>
												handleConditionChange(
													/* @ts-ignore */
													{ compareWith: dropdown.value },
													index
												)
											}
											className='CompareWith'
										/>
									)}
								</div>

								{condition.validation === 'Text' && (
									<div className='row'>
										{
											/* @ts-ignore */

											condition.compareWith === 'Parameter' ? (
												<>
													<Dropdown
														label='Operation'
														value={
															textOperations.filter(
																/* @ts-ignore */

																(txt) => txt.value === condition.operators
															)[0]
														}
														options={textOperations}
														onChange={(dropdown) =>
															handleConditionChange(
																/* @ts-ignore */

																{ operators: dropdown.value },

																index
															)
														}
														className='CompareWithOperation'
													/>

													<Dropdown
														label='Variable name'
														value={
															variableNames.filter(
																/* @ts-ignore */

																(vr) => vr.value === condition.variable
															)[0]
														}
														options={variableNames}
														onChange={(dropdown) =>
															handleConditionChange(
																/* @ts-ignore */

																{ variable: dropdown.value },

																index
															)
														}
														className='CompareWithOperation'
													/>
												</>
											) : (
												<>
													<Dropdown
														label='Operation'
														value={
															textOperations.filter(
																/* @ts-ignore */

																(txt) => txt.value === condition.operators
															)[0]
														}
														options={textOperations}
														onChange={(dropdown) =>
															handleConditionChange(
																/* @ts-ignore */

																{ operators: dropdown.value },

																index
															)
														}
														className='CompareWithOperation'
													/>

													<Input
														value={condition.value ?? ''}
														onChange={(event) =>
															handleConditionChange(
																{
																	value: validationForValue(event.target.value),
																},

																index
															)
														}
														placeholder='Enter value'
														type='text'
														label='Enter value'
													/>
												</>
											)
										}
									</div>
								)}

								{(condition.validation === 'Number' ||
									condition.validation === 'Date') && (
									<div className='row'>
										{
											/* @ts-ignore */

											condition.compareWith === 'Parameter' ? (
												<>
													<Dropdown
														label='Operation'
														value={
															numberOperations.filter(
																/* @ts-ignore */

																(num) => num.value === condition.operators
															)[0]
														}
														options={numberOperations}
														onChange={(dropdown) =>
															handleConditionChange(
																/* @ts-ignore */

																{ operators: dropdown.value },

																index
															)
														}
														className='CompareWithOperation'
													/>

													<Dropdown
														label='Variable name'
														value={
															variableNames.filter(
																/* @ts-ignore */

																(vr) => vr.value === condition.variable
															)[0]
														}
														options={variableNames}
														onChange={(dropdown) => {
															handleConditionChange(
																/* @ts-ignore */

																{ variable: dropdown.value },

																index
															);
														}}
														className='CompareWithOperation'
													/>
												</>
											) : (
												<>
													<Dropdown
														label='Operation'
														value={
															numberOperations.filter(
																/* @ts-ignore */

																(num) => num.value === condition.operators
															)[0]
														}
														options={numberOperations}
														onChange={(dropdown) =>
															handleConditionChange(
																/* @ts-ignore */

																{ operators: dropdown.value },

																index
															)
														}
														className='CompareWithOperation'
													/>

													<Input
														value={condition.value ?? ''}
														onChange={(event) =>
															handleConditionChange(
																{
																	value: validationForValue(event.target.value),
																},

																index
															)
														}
														placeholder={
															condition.validation === 'Number'
																? 'Enter value'
																: ''
														}
														label={
															condition.validation === 'Number'
																? 'Enter value'
																: ''
														}
														type={
															condition.validation === 'Number'
																? 'number'
																: 'date'
														}
														onKeyDown={(event) => validateNumber(event)}
													/>
												</>
											)
										}
									</div>
								)}
							</div>
							<Dropdown
								isSearchable
								placeholder='Select Next Action'
								value={condition.nextAction}
								options={nextActions}
								onChange={(dropdown) =>
									handleConditionChange(
										/* @ts-ignore */
										{ nextAction: dropdown },
										index
									)
								}
								className='next-action-dropdown'
							/>
						</div>
					))}
				<span className='btn-container'>
					<Button
						startIcon={<BotBuilderPlus width='15px' />}
						onClick={handleAddCondition}>
						Add Condition
					</Button>
				</span>
			</div>
			<NextNewAction
				open={openNextActionType}
				setOpenNextActionType={setOpenNextActionType}
				setNextActionTypeId={setNextActionTypeIdForConditions}
				onClose={() => {
					setOpenNextActionType(false);
					if (nextActionTypeIdForConditions === 0) {
						context.setNextAction(null);
					}
				}}
				setLabel={setLabel}
			/>
		</>
	);
};

export default Conditions;
