import { Link, useLocation, useNavigate } from 'react-router-dom';
import './hbreportissue.scss';

import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import Input from '../../../../Components/Input';
import { HBReportIssueInput, chatMessage } from '../../../../Features/HelpBot/helpbotSlice';
import { loadState } from '../../../../utils/utils';


function HBReportIssue() {
    const {
        msgsArray,
        HBscreen,
        expandWidth,
        reportIssueInput,
        conversationId
    } = useSelector((state: RootState) => state.helpBot);
    const { user } = useSelector((state: RootState) => state.auth);
    const dispatch = useAppDispatch();
    return (
        <div className={`ff-hb-report-issue-container`}>
            <div className='body2'>
                Please describe your issue and we will shortly get back to you on mail.
            </div>
            <div className='ff-hb-issue-description'>
                <span>Describe your issue</span>
                <span>
                    <Input
                        // placeholder='Ask me something'
                        fullWidth
                        type='text'
                        value={reportIssueInput}
                        onChange={(event) => {
                            dispatch(HBReportIssueInput(event.target.value));
                        }}
                        autoComplete='off'
                        className='helpbot-report-input-box'
                    />
                </span>
            </div>
            <div className='ff-hb-report-button'>
                <button className='button1' 
                style={reportIssueInput.length<=2?{pointerEvents:'none',opacity:'0.5'}:{}}
                onClick={() => {
                    dispatch(chatMessage({
                        "botId": "120",
                        "country": "US",
                        "id": conversationId ? conversationId : 0,
                        "message": 'Raise a ticket',
                        "metaData": {
                            "form":
                            {
                                "Name": user && user.firstName,
                                "Email": user && user.userEmail,
                                "Issue Description": reportIssueInput
                            }
                        },
                        "version": "prod",
                        // "tenantId": 120
                    }))
                }}>Submit</button>
            </div>
        </div>
    )
}
export default HBReportIssue;
