import React, { Component } from 'react';
import './styles.scss';
import AgentAvailability from './AgentAvailability';
import {
	CalendarDateIcon,
	ClockIcon,
	SelectedToggleIcon,
	UnSelectedToggleIcon,
} from '../../../../assets/Icons/LiveChatConfigIcons';
import { EditIcon } from '../../../../assets/Icons/LiveChatConfigIcons/index';
import Button from '../../../../Components/Button';
import Modal from '../../../../Components/Modal';
import SnackBar from '../../../../Components/SnackBar';

import TimezoneList from './TimezoneList';
import {
	CheckBoxChecked,
	CheckBoxUnChecked,
} from '../../../../assets/Icons/LiveChatConfigIcons/index';

import { isHTMLTagsPresent, loadState } from '../../../../utils/utils';
import Input from '../../../../Components/Input';
import Note from '../../../../Components/Note';

type Props = {
	selectedTenantId: number;
	toasterNotify: Function;
	activeTab: string;
	freshChatLinked: boolean;
};

type State = {
	showTimeZoneList: boolean;
	showAgentAvailability: boolean;
	showTimezoneToaster: boolean;
	displaySelectedTimezone: string;
	agentAvailabilityTime: { days: string; time: string }[];
	count: number;
	triggerOptions: { id: number; triggerOption: number; selected: boolean }[];
	shwDropDown: boolean;
	shwHandoffDropDown: boolean;
	showQuickLinksAfterThankYouMsgFlag: boolean;
	nonComphrehendCountFlag: boolean;
	nonComphrehendCount: number;
	validationFailCountFlag: boolean;
	validationFailCount: number;
	detectNegativeSentimentFlag: boolean;
	requestByFlag: boolean;
	WHAgentUnAvalibilityMsg: string;
	NonWHAgentUnAvalibilityMsg: string;
	handoffConfirmationMsg: string;
	handoffMsg: string;
	waitTimeResponse: string;
	handoffCancelledMsg: string;
	showQuickLinksAfterhandoffCancelledMsgFlag: boolean;
	transferOrReleaseMsg: string;
	handoffEndMsg: string;
	showQuickLinksAfterhandoffEndMsgFlag: boolean;
	selectedIndex: number;
	checkValidationPopup: boolean;
	saveValidate: boolean;
	saveDataModalPopup: boolean;
	tabChangeDataModalPopup: boolean;
	countstate: number;
	kbotBotId: string;
	toEmail: string;
	queuehandoffMsg: string;
	notifyAgentFlag: boolean;
	collectAgentAvailability: boolean;
	collectEmailId: boolean;
	queueFlag: boolean;
	NodeList: { nodeName: string; nodeId: number }[];
	placeholders: string[];
	disableSaveBtn: boolean;
	agentAvailabilityList: {
		indexOfDay: number;
		time: string;
		day: string;
	}[];
	availabilityType: string;
	roundRobinAutoAssignmentFlag: boolean;
	isTakeOverOptedChkFlag: boolean;
};

export default class LiveChatConfig extends Component<Props, State> {
	private myRef: React.RefObject<HTMLInputElement>;
	private shwHandoffDropDown: React.RefObject<HTMLInputElement>;
	private shwDropDown: React.RefObject<HTMLInputElement>;
	constructor(props: Props) {
		super(props);
		this.state = this.setInitialState();
		this.selectTimeZone = this.selectTimeZone.bind(this);
		this.agentAvailability = this.agentAvailability.bind(this);
		this.displaySelectedTimezone = this.displaySelectedTimezone.bind(this);
		this.onClose = this.onClose.bind(this);
		this.fetchAgentTimmings = this.fetchAgentTimmings.bind(this);
		this.fetchValuesBasedonIndex = this.fetchValuesBasedonIndex.bind(this);
		this.fetchConfigData = this.fetchConfigData.bind(this);
		this.myRef = React.createRef();
		this.shwHandoffDropDown = React.createRef();
		this.shwDropDown = React.createRef();
	}
	setInitialState(): State {
		const user = loadState('user');
		return {
			showTimeZoneList: false,
			showAgentAvailability: false,
			showTimezoneToaster: false,
			displaySelectedTimezone: '',
			agentAvailabilityTime: [],
			count: 0,
			triggerOptions: [
				{ id: 0, triggerOption: 1, selected: false },
				{ id: 1, triggerOption: 2, selected: false },
				{ id: 2, triggerOption: 3, selected: false },
				{ id: 3, triggerOption: 4, selected: false },
				{ id: 4, triggerOption: 5, selected: false },
			],
			shwDropDown: false,
			shwHandoffDropDown: false,

			showQuickLinksAfterThankYouMsgFlag: false,
			nonComphrehendCountFlag: false,
			nonComphrehendCount: 1,
			validationFailCountFlag: false,
			validationFailCount: 1,
			detectNegativeSentimentFlag: false,
			requestByFlag: false,
			WHAgentUnAvalibilityMsg:
				'Sorry, none of our experts are online at the moment',
			NonWHAgentUnAvalibilityMsg:
				'Sorry, none of our experts is around currently. Please try after sometime.',
			handoffConfirmationMsg:
				'Shall I connect you with a human expert to resolve your query?',
			handoffMsg: 'Sure, connecting you to one of our experts. Please wait...',
			waitTimeResponse: 'Please, wait an agent will be with you shortly!',
			handoffCancelledMsg: 'No problem, Can I help you with something else?',
			transferOrReleaseMsg: '',
			handoffEndMsg:
				'I hope your issue was resolved. Is there something else that I can help you with?',
			showQuickLinksAfterhandoffCancelledMsgFlag: false,
			showQuickLinksAfterhandoffEndMsgFlag: false,
			selectedIndex: -1,
			checkValidationPopup: false,
			saveValidate: false,
			saveDataModalPopup: false,
			tabChangeDataModalPopup: false,
			countstate: 0,
			kbotBotId: '',
			notifyAgentFlag: false,
			toEmail: user.userEmail,
			queuehandoffMsg:
				'All agents are currently offline, but we are trying to get an agent for you. Please stay online.',
			queueFlag: false,
			collectAgentAvailability: false,
			collectEmailId: false,
			NodeList: [],
			// selectedNode: [{ nodeName: 'string', nodeId: -1 }],
			placeholders: [],
			disableSaveBtn: false,
			agentAvailabilityList: [],
			availabilityType: '',
			roundRobinAutoAssignmentFlag: false,
			isTakeOverOptedChkFlag: false,
		};
	}
	componentDidMount() {
		this.fetchAgentTimmings();
		this.fetchConfigData();
		document.addEventListener('click', this.handleClickOutside);
	}
	async fetchConfigData() {
		let encodedSelectedTenantId = encodeURIComponent(
			this.props.selectedTenantId
		);

		if (
			this.props.selectedTenantId !== null &&
			this.props.selectedTenantId !== undefined
		) {
			const response = await fetch(
				`HybridChat/getBotStudioConfiguration?tenantId=${encodedSelectedTenantId}`
			);
			const res = await response.json();
			if (res) {
				this.setState((prevState) => ({
					nonComphrehendCount:
						res.nonComphrehendCount === undefined ? 1 : res.nonComphrehendCount,
					nonComphrehendCountFlag:
						res.nonComphrehendCountFlag === undefined
							? this.state.nonComphrehendCountFlag
							: res.nonComphrehendCountFlag,
					WHAgentUnAvalibilityMsg:
						res.WHAgentUnAvalibilityMsg === undefined
							? this.state.WHAgentUnAvalibilityMsg
							: res.WHAgentUnAvalibilityMsg,
					validationFailCountFlag:
						res.validationFailCountFlag === undefined
							? this.state.validationFailCountFlag
							: res.validationFailCountFlag,
					NonWHAgentUnAvalibilityMsg:
						res.NonWHAgentUnAvalibilityMsg === undefined
							? this.state.NonWHAgentUnAvalibilityMsg
							: res.NonWHAgentUnAvalibilityMsg,
					detectNegativeSentimentFlag:
						res.detectNegativeSentimentFlag === undefined
							? false
							: res.detectNegativeSentimentFlag,
					handoffMsg:
						res.handoffMsg === undefined
							? this.state.handoffMsg
							: res.handoffMsg,
					handoffConfirmationMsg:
						res.handoffConfirmationMsg === undefined
							? this.state.handoffConfirmationMsg
							: res.handoffConfirmationMsg,
					waitTimeResponse:
						res.waitTimeResponse === undefined
							? this.state.waitTimeResponse
							: res.waitTimeResponse,
					handoffEndMsg:
						res.handoffEndMsg === undefined
							? this.state.handoffEndMsg
							: res.handoffEndMsg,
					handoffCancelledMsg:
						res.handoffCancelledMsg === undefined
							? this.state.handoffCancelledMsg
							: res.handoffCancelledMsg,
					validationFailCount:
						res.validationFailCount === undefined ? 1 : res.validationFailCount,
					triggerOptions: prevState.triggerOptions.map((obj) =>
						obj.triggerOption === res.nonComphrehendCount
							? Object.assign(obj, { selected: true })
							: obj
					),
					toEmail: res.toEmail === undefined ? this.state.toEmail : res.toEmail,
					queuehandoffMsg:
						res.queuehandoffMsg === undefined
							? this.state.queuehandoffMsg
							: res.queuehandoffMsg,
					queueFlag: res.queueFlag === undefined ? false : res.queueFlag,
					notifyAgentFlag:
						res.notifyAgentFlag === undefined ? false : res.notifyAgentFlag,
					roundRobinAutoAssignmentFlag:
						res.roundRobinAutoAssignmentFlag === undefined
							? false
							: res.roundRobinAutoAssignmentFlag,
				}));
			}
		}
	}

	async fetchAgentTimmings() {
		let encodedSelectedTenantId = encodeURIComponent(
			this.props.selectedTenantId
		);
		if (
			this.props.selectedTenantId !== null &&
			this.props.selectedTenantId !== undefined
		) {
			const response = await fetch(
				`HybridChat/getAgentAvailibilityForBot?tenantId=${encodedSelectedTenantId}`
			);
			const res = await response.json();
			let data = res.agentAvailibility;
			// console.log(
			// 	'res.agentAvailibility',
			// 	res.agentAvailibility,
			// 	Boolean(data)
			// );
			if (data) {
				type typeAvailability = {
					day: string;
					time: string;
					indexOfDay: number;
				};
				this.setState({
					displaySelectedTimezone: res.timezone,
					agentAvailabilityList: data.map(
						(availability: typeAvailability, i: number) => ({
							indexOfDay: availability.indexOfDay,
							time: availability.time,
							day: availability.day,
						})
					),
					availabilityType: res.type,
				});

				let dayTimeMapping: {
					day: string;
					time: string;
					indexOfDay: number;
				}[] = [];

				for (let i = 0; i < data.length; i++) {
					const mapping = data[i];
					const lastMapping = dayTimeMapping[dayTimeMapping.length - 1] || {
						time: '',
					};
					if (
						mapping.time === lastMapping.time &&
						mapping.indexOfDay - lastMapping.indexOfDay === 1
					) {
						dayTimeMapping[dayTimeMapping.length - 1] = {
							...lastMapping,
							day: lastMapping.day.substr(0, 3) + ' - ' + mapping.day,
							indexOfDay: mapping.indexOfDay,
						};
					} else {
						dayTimeMapping.push({
							day: mapping.day,
							time: mapping.time,
							indexOfDay: mapping.indexOfDay,
						});
					}
				}
				this.setState({
					agentAvailabilityTime: dayTimeMapping.map(
						(list: typeAvailability) => ({
							days: list.day,
							time: list.time,
						})
					),
				});
			} else {
				this.setState({
					displaySelectedTimezone: '(GMT+5:30) Asia/Kolkata',
					agentAvailabilityTime: [
						{
							days: 'Mon-Sun',
							time: '24 hrs',
						},
					],
					availabilityType: 'default',
				});
			}
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.props.selectedTenantId !== prevProps.selectedTenantId) {
			const elem = document.querySelector('.general-tab-w .grid-system');
			if (elem) {
				elem.scrollTop = 0;
			}
		}
		if (
			prevState.nonComphrehendCountFlag !==
			this.state.nonComphrehendCountFlag ||
			prevState.validationFailCountFlag !==
			this.state.validationFailCountFlag ||
			prevState.detectNegativeSentimentFlag !==
			this.state.detectNegativeSentimentFlag ||
			prevState.requestByFlag !== this.state.requestByFlag ||
			prevState.WHAgentUnAvalibilityMsg !==
			this.state.WHAgentUnAvalibilityMsg ||
			prevState.NonWHAgentUnAvalibilityMsg !==
			this.state.NonWHAgentUnAvalibilityMsg ||
			prevState.handoffConfirmationMsg !== this.state.handoffConfirmationMsg ||
			prevState.handoffMsg !== this.state.handoffMsg ||
			prevState.waitTimeResponse !== this.state.waitTimeResponse ||
			prevState.handoffCancelledMsg !== this.state.handoffCancelledMsg ||
			prevState.transferOrReleaseMsg !== this.state.transferOrReleaseMsg ||
			prevState.handoffEndMsg !== this.state.handoffEndMsg ||
			(this.state.queueFlag === true && this.state.queuehandoffMsg === '')
		) {
			this.setState({
				countstate: this.state.countstate + 1,
			});
		}

		if (
			(Object.is(
				prevState.agentAvailabilityTime,
				this.state.agentAvailabilityTime
			) &&
				prevState.count !== this.state.count) ||
			prevProps.selectedTenantId !== this.props.selectedTenantId
		) {
			this.fetchAgentTimmings();
			this.fetchConfigData();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside);
	}
	handleClickOutside = (event: any) => {
		if (
			this.shwHandoffDropDown.current &&
			!this.shwHandoffDropDown.current.contains(event.target)
		) {
			this.setState({
				shwHandoffDropDown: false,
			});
		}
		if (
			this.shwDropDown.current &&
			!this.shwDropDown.current.contains(event.target)
		) {
			this.setState({
				shwDropDown: false,
			});
		}
	};
	selectTimeZone() {
		this.setState({
			showTimeZoneList: true,
		});
	}
	selectText(id: number) {
		this.setState({
			selectedIndex: id,
		});
	}
	agentAvailability() {
		this.setState({
			showAgentAvailability: true,
		});
	}
	displaySelectedTimezone(timezone: string) {
		this.setState({
			displaySelectedTimezone: timezone,
		});
	}
	onClose() {
		this.setState({
			showTimeZoneList: false,
			showTimezoneToaster: !this.state.showTimezoneToaster,
		});
		this.fetchAgentTimmings();
		this.props.toasterNotify('Time zone updated');
	}
	fetchValuesBasedonIndex(event: any, index: number) {
		if (index === 0) {
			this.setState({
				WHAgentUnAvalibilityMsg: event.target.value,
			});
		} else if (index === 1) {
			this.setState({
				NonWHAgentUnAvalibilityMsg: event.target.value,
			});
		} else if (index === 2) {
			this.setState({
				handoffConfirmationMsg: event.target.value,
			});
		} else if (index === 3) {
			this.setState({
				handoffMsg: event.target.value,
			});
		} else if (index === 4) {
			this.setState({
				waitTimeResponse: event.target.value,
			});
		} else if (index === 5) {
			this.setState({
				handoffCancelledMsg: event.target.value,
			});
		} else if (index === 6) {
			this.setState({
				transferOrReleaseMsg: event.target.value,
			});
		} else if (index === 7) {
			this.setState({
				handoffEndMsg: event.target.value,
			});
		}
	}
	async setBotStudioSetting() {
		let data = {
			tenantId: this.props.selectedTenantId.toString(),
			allData: {
				nonComphrehendCountFlag: this.state.nonComphrehendCountFlag,
				nonComphrehendCount: this.state.nonComphrehendCount,
				validationFailCountFlag: this.state.validationFailCountFlag,
				validationFailCount: this.state.validationFailCount,
				detectNegativeSentimentFlag: this.state.detectNegativeSentimentFlag,
				WHAgentUnAvalibilityMsg: this.state.WHAgentUnAvalibilityMsg,
				NonWHAgentUnAvalibilityMsg: this.state.NonWHAgentUnAvalibilityMsg,
				handoffConfirmationMsg: this.state.handoffConfirmationMsg,
				handoffMsg: this.state.handoffMsg,
				toEmail: this.state.toEmail,
				queuehandoffMsg: this.state.queuehandoffMsg,
				queueFlag: this.state.queueFlag,
				notifyAgentFlag: this.state.notifyAgentFlag,
				roundRobinAutoAssignmentFlag: this.state.roundRobinAutoAssignmentFlag,
				handoffEndMsg: this.state.handoffEndMsg,
				handoffCancelledMsg: this.state.handoffCancelledMsg,
				waitTimeResponse: this.state.waitTimeResponse,
			},
		};
		if (
			this.state.WHAgentUnAvalibilityMsg === '' ||
			this.state.WHAgentUnAvalibilityMsg === undefined ||
			this.state.NonWHAgentUnAvalibilityMsg === '' ||
			this.state.NonWHAgentUnAvalibilityMsg === undefined ||
			this.state.handoffConfirmationMsg === '' ||
			this.state.handoffConfirmationMsg === undefined ||
			this.state.handoffMsg === '' ||
			this.state.handoffMsg === undefined ||
			this.state.waitTimeResponse === '' ||
			this.state.waitTimeResponse === undefined ||
			this.state.handoffCancelledMsg === '' ||
			this.state.handoffCancelledMsg === undefined ||
			this.state.handoffEndMsg === ''
		) {
			// toast(<SnackBar type='danger'>Please provide valid values!</SnackBar>, {
			// 	autoClose: 2000,
			// });
		} else {
			this.setState({
				disableSaveBtn: true,
			});
			await fetch(`HybridChat/setBotStudioConfiguration`, {
				method: 'POST',
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((res: any) => {
					if (res.result === true) {
						this.fetchConfigData();
						this.setState({
							disableSaveBtn: false,
						});
						// toast(<SnackBar type='success'>Changes Saved</SnackBar>, {
						// 	autoClose: 3000,
						// });
					} else {
						// toast(<SnackBar type='danger'>Something Went Wrong!</SnackBar>, {
						// 	autoClose: 3000,
						// });
					}
				});
		}
	}
	areAllHandoffTriggersDeselected = () => {
		return (
			!this.state.nonComphrehendCountFlag &&
			!this.state.validationFailCountFlag &&
			!this.state.detectNegativeSentimentFlag &&
			!this.state.requestByFlag
		);
	};

	checkEmailValidation(email: string) {
		var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //NOSONAR
		if (re.test(email)) {
			return false;
		} else {
			return true;
		}
	}
	render() {
		return (
			<div className='general-tab-w'>
				<div className='grid-system'>
					<div>
						{this.props.activeTab === 'Timings & Availability' && (
							<div className='config-data-wrapper first-config-wrapper'>
								<div className='ff-live-chat-config-header body2'>
									TIME SETTINGS
								</div>
								<div>
									<div className='timezone body1'>
										Agent availability timings
									</div>
									<div className='working-hrs caption'>
										Handoff will be triggered by the Ads/Storefronts only during
										these timings
									</div>
									<div>
										<div className='agent-availability'>
											<table className='agent-availability-data'>
												<thead>
													<tr className='agent-availability-header'>
														<th>
															<div className='th-align'>
																<div style={{ marginRight: '5px' }}>
																	<CalendarDateIcon />
																</div>
																<div>Working days</div>
															</div>
														</th>
														<th>
															<div className='th-align'>
																<div style={{ marginRight: '5px' }}>
																	<ClockIcon />
																</div>
																Working Hours
															</div>
														</th>
														<th>
															<div
																style={{ display: 'flex' }}
																onClick={this.agentAvailability}
																className='pointing-icon'>
																<EditIcon />
															</div>
														</th>
													</tr>
												</thead>
												<tbody>
													{this.state.agentAvailabilityTime.map(
														(data, index) => {
															return (
																<tr
																	style={{
																		fontSize: '14px',
																		letterSpacing: '-0.21px',
																	}}
																	key={index}>
																	<td>{data.days}</td>
																	<td>{data.time}</td>
																</tr>
															);
														}
													)}
												</tbody>
											</table>
										</div>
									</div>
									{this.state.showAgentAvailability && (
										<Modal
											headerCloseBtn={true}
											actionButtons={false}
											header='Agent availability'
											className='agent-avaialability-modal'
											onClose={() => {
												this.setState({
													showAgentAvailability: false,
												});
											}}>
											<AgentAvailability
												selectedTenantId={this.props.selectedTenantId}
												onSave={(val: string) => {
													this.setState({
														showAgentAvailability:
															!this.state.showAgentAvailability,
														count: this.state.count + 1,
													});
												}}
												agentAvailabilityList={this.state.agentAvailabilityList}
												availabilityType={this.state.availabilityType}
											/>
										</Modal>
									)}
								</div>
								<div className='timezone-wrapper'>
									<div className='timezone body1'>Agent time zone</div>
									<div className='working-hrs caption'>
										Agent working hours are in this time zone
									</div>
									<div className='timezone-data '>
										<div
											className='display-timezone pointing-icon'
											onClick={this.selectTimeZone}>
											{this.state.displaySelectedTimezone}
										</div>
									</div>
									{this.state.showTimeZoneList && (
										<Modal
											actionButtons={false}
											header='Select time zone'
											onClose={() => {
												this.setState({
													showTimeZoneList: false,
												});
											}}>
											<TimezoneList
												onClose={this.onClose}
												displaySelectedTimezone={this.displaySelectedTimezone}
												selectedTenantId={this.props.selectedTenantId}
												selectedTimeZone={this.state.displaySelectedTimezone}
											/>
										</Modal>
									)}
								</div>
							</div>
						)}

						{this.props.activeTab === 'Handoff Triggers' && (
							<div className='first-config-wrapper'>
								<div className='ff-live-chat-config-header body2'>
									HANDOFF TRIGGERS
								</div>
								{/* <div className={`third-config-wrapper`}>
									<p className='ff-automatic-trigger body2'>
										Select at least one event that should trigger automatic
										handoff
									</p>
								</div> */}
								<div className='ff-space-between-checkbox'>
									<div className='ff-checkbox-and-text'>
										<div
											className='pointing-icon'
											onClick={() => {
												this.setState({
													nonComphrehendCountFlag:
														!this.state.nonComphrehendCountFlag,
													disableSaveBtn: true,
												});
											}}>
											{' '}
											{this.state.nonComphrehendCountFlag ? (
												<CheckBoxChecked />
											) : (
												<CheckBoxUnChecked />
											)}
										</div>
										<span className='body2 third-config-wrapper'>
											Consecutive non comprehension of user’s query
										</span>
									</div>
									{this.state.nonComphrehendCountFlag && (
										<div className='ff-checkbox-and-subtext'>
											<p className='ff-border-line'></p>
											<div className='body2 third-config-wrapper'>
												No. of consecutive queries to handoff after
											</div>
											<div>
												<div
													onClick={() => {
														this.setState({
															shwHandoffDropDown:
																!this.state.shwHandoffDropDown,
															disableSaveBtn: true,
														});
													}}
													className='trigger-options'
													ref={this.shwHandoffDropDown}>
													<span style={{ fontSize: '14px' }}>
														{this.state.nonComphrehendCount}
													</span>
												</div>
												{this.state.shwHandoffDropDown && (
													<div className='body2 selection-of-user'>
														<div
															className='adding-user-options'
															style={{
																width: '53px',
																height: 'auto',
																padding: '10px',
																color: 'rgba(0,0,0,0.8)',
															}}>
															{this.state.triggerOptions.map((val, i) => {
																return (
																	<div
																		key={i}
																		onClick={() => {
																			this.setState((prevState) => ({
																				nonComphrehendCount: val.triggerOption,
																				shwHandoffDropDown:
																					!this.state.shwHandoffDropDown,
																				disableSaveBtn: true,
																			}));
																		}}
																		style={{
																			display: 'flex',
																			justifyContent: 'center',
																		}}>
																		<>{val.triggerOption}</>
																	</div>
																);
															})}
														</div>
													</div>
												)}
											</div>
										</div>
									)}
								</div>
								<div className='ff-space-between-checkbox'>
									<div className='ff-checkbox-and-text'>
										<div
											className='pointing-icon'
											onClick={() => {
												this.setState({
													validationFailCountFlag:
														!this.state.validationFailCountFlag,
													disableSaveBtn: true,
												});
											}}>
											{this.state.validationFailCountFlag ? (
												<CheckBoxChecked />
											) : (
												<CheckBoxUnChecked />
											)}
										</div>

										<span className='body2 third-config-wrapper'>
											Consecutive validation failure of a task
										</span>
									</div>
									{this.state.validationFailCountFlag && (
										<div className='ff-checkbox-and-subtext'>
											<p className='ff-border-line'></p>
											<div className='third-config-wrapper body2'>
												No. of consecutive failures to handoff after
											</div>
											<div>
												<div
													onClick={() => {
														this.setState({
															shwDropDown: !this.state.shwDropDown,
															disableSaveBtn: true,
														});
													}}
													className='trigger-options'
													ref={this.shwDropDown}>
													<span style={{ fontSize: '14px' }}>
														{this.state.validationFailCount}
													</span>
												</div>
												{this.state.shwDropDown && (
													<div className='selection-of-user body2'>
														<div
															className='adding-user-options'
															style={{
																width: '53px',
																height: 'auto',
																padding: '10px',
																color: 'rgba(0,0,0,0.8)',
																alignItems: 'center',
															}}>
															{this.state.triggerOptions.map((val, i) => {
																return (
																	<div
																		key={i}
																		onClick={() => {
																			this.setState((prevState) => ({
																				validationFailCount: val.triggerOption,
																				shwDropDown: !this.state.shwDropDown,
																				disableSaveBtn: true,
																			}));
																		}}>
																		<>{val.triggerOption}</>
																	</div>
																);
															})}
														</div>
													</div>
												)}
											</div>
										</div>
									)}
								</div>
								<div className='ff-space-between-checkbox'>
									<div className='ff-checkbox-and-text'>
										<div
											className='pointing-icon'
											onClick={() => {
												this.setState({
													detectNegativeSentimentFlag:
														!this.state.detectNegativeSentimentFlag,
													disableSaveBtn: true,
												});
											}}>
											{' '}
											{this.state.detectNegativeSentimentFlag ? (
												<CheckBoxChecked />
											) : (
												<CheckBoxUnChecked />
											)}
										</div>
										<span className='body2 third-config-wrapper'>
											Detection of negative sentiment in user’s query
										</span>
									</div>
								</div>
							</div>
						)}

						{this.props.activeTab === 'Auto Assignment' && (
							<div className='first-config-wrapper ff-auto-assign-chats'>
								<div className='ff-live-chat-config-header body2'>
									AUTO-ASSIGNING CHATS
								</div>
								<div
									style={{
										marginTop: '20px',
										display: 'flex',
										justifyContent: 'space-between',
									}}>
									<div className='config-msg-header body1'>
										Auto-assign chats to agents
									</div>
									<div
										onClick={() =>
											this.setState({
												roundRobinAutoAssignmentFlag:
													!this.state.roundRobinAutoAssignmentFlag,
												disableSaveBtn: true,
											})
										}
										style={{ marginLeft: 'auto' }}>
										{this.state.roundRobinAutoAssignmentFlag ? (
											<SelectedToggleIcon />
										) : (
											<UnSelectedToggleIcon />
										)}
									</div>
									<div></div>
								</div>
								<div className='config-msg-usecase caption'>
									The chats for which handoff is triggered will be automatically
									assigned to agents based on their availability and the queues
									that they have been assigned
								</div>
							</div>
						)}

						{this.props.activeTab === 'Timings & Availability' && (
							<div className='first-config-wrapper ff-agent-unavailability-messages'>
								<div className='ff-live-chat-config-header body2'>
									AGENT UNAVAILABILITY DURING WORKING HOURS
								</div>
								<div>
									<div className='ff-notify-agents'>
										<div className='config-msg-header body1'>
											Notify agents if users are waiting
										</div>
										<div
											onClick={() =>
												this.setState({
													notifyAgentFlag: !this.state.notifyAgentFlag,
													disableSaveBtn: true,
												})
											}>
											{this.state.notifyAgentFlag ? (
												<SelectedToggleIcon />
											) : (
												<UnSelectedToggleIcon />
											)}
										</div>
									</div>
									<div className='config-msg-usecase caption'>
										If no agent for a queue is online, an email notification
										will be sent to all agents of that queue letting them know
										that users are waiting.
									</div>
								</div>

								{this.state.notifyAgentFlag ? (
									<div className='ff-email-address-notify'>
										<div className='sec-config-hearder body2'>
											Email address for email notifications
										</div>
										<div className='config-msg-usecase caption'>
											All email notifications will be sent to this address. You
											can setup email forwarding to automatically forward
											notifications to agents.
										</div>
										<Input
											placeholder='Enter email address'
											value={this.state.toEmail}
											onChange={(event) => {
												this.setState({
													toEmail: event.target.value,
													// disableSaveBtn: false,
												});
											}}
											required
											ref={this.myRef}
											error={this.checkEmailValidation(this.state.toEmail)}
											errorText={`Please provide a valid email address`}
											autoFocus
										/>
										{/* <input
											type='text'
											style={{
												width: '100%',
												marginTop: '-3px',
												borderBottom: '1px solid #e8e8ea',
											}}
											className='config-msg-footer'
											value={this.state.toEmail}
											ref={this.myRef}
											placeholder='Enter email address'
											onChange={(event) => {
												this.setState({
													toEmail: event.target.value,
													disableSaveBtn: false,
												});
											}}
											required={true}
										/> */}
										{/* {this.state.toEmail === '' ||
											(this.checkEmailValidation(this.state.toEmail) && (
												<div className='config-empty-msg'>
													Please provide a valid email address
												</div>
											))} */}
									</div>
								) : null}

								<div>
									<div className='ff-notify-agents'>
										<div className='config-msg-header body1'>
											Put chats in queue if no agents are online.
										</div>
										<div
											onClick={() =>
												this.setState({
													queueFlag: !this.state.queueFlag,
													disableSaveBtn: true,
												})
											}>
											{this.state.queueFlag ? (
												<SelectedToggleIcon />
											) : (
												<UnSelectedToggleIcon />
											)}
										</div>
									</div>
									<div className='config-msg-usecase caption'>
										If no agents for a queue are online,handoffs and transfers
										will still happen for that queue.Users will be kept in queue
										until an agent responds.Email notifications will be sent to
										agents if enabled.
									</div>
								</div>

								{this.state.queueFlag ? (
									<div className='ff-agent-put-chats-in-queue'>
										<div className='sec-config-hearder body2'>
											Handoff message for when no agents on online.
										</div>
										<div className='config-msg-usecase caption'>
											This message will be shown instead of the regular handoff
											message.
										</div>
										<Input
											multiline
											value={this.state.queuehandoffMsg}
											onChange={(event) => {
												this.setState({
													queuehandoffMsg: event.target.value,
												});
											}}
										/>
										{/* <textarea
											className='body2 config-msg-footer'
											value={this.state.queuehandoffMsg}
											onChange={(event) => {
												this.setState({
													queuehandoffMsg: event.target.value,
												});
											}}
										/> */}

										{this.state.queuehandoffMsg === '' && (
											<div className='config-empty-msg'>
												Please provide a handoff message for when no agents are
												online
											</div>
										)}
									</div>
								) : null}
							</div>
						)}
					</div>

					{this.props.activeTab === 'Messages' && (
						<div>
							<div
								className={`first-config-wrapper ff-config-messages ${this.props.freshChatLinked ? 'freshchat' : ''
									}`}>
								{this.props.freshChatLinked ? null : (
									<div className='ff-live-chat-config-header body2'>
										MESSAGES
									</div>
								)}
								<>
									{this.props.freshChatLinked ? (
										<>
											<Note severity='info' removeCloseIcon>
												You have Freshchat enabled for live chat customer
												support. To change other configurations, please go to
												Freshchat.
											</Note>
										</>
									) : (
										<>
											<div>
												<Input
													multiline
													value={this.state.WHAgentUnAvalibilityMsg}
													label={
														'Agent non availability message (working hours)'
													}
													onChange={(event) => {
														this.setState({
															WHAgentUnAvalibilityMsg: event.target.value,
															disableSaveBtn: true,
														});
													}}
													helperText={
														'Shown to the user if no agent is online during agent working hours'
													}
													error={isHTMLTagsPresent(
														this.state.WHAgentUnAvalibilityMsg,
														true
													)}
												/>
												{/* <p
										className='body2'
										style={{ marginTop: '30px', fontWeight: 500 }}>
										Agent non availability message (working hours)
									</p>
									<p className='caption' style={{ marginTop: '5px' }}>
										Shown to the user if no agent is online during agent working
										hours
									</p> */}

												{/* <input
										type={'text'}
										className='body2 config-msg-footer'
										style={{
											width: '100%',
											height: 'auto',
											marginTop: '10px',
											borderBottom: '1px solid #e8e8ea',

											fontSize: '14px',
										}}
										value={this.state.WHAgentUnAvalibilityMsg}
										onChange={(event) => {
											this.setState({
												WHAgentUnAvalibilityMsg: event.target.value,
												disableSaveBtn: true,
											});
										}}
									/> */}
											</div>
											<div>
												<Input
													multiline
													value={this.state.NonWHAgentUnAvalibilityMsg}
													label={
														'Agent non availability message (non-working hours)'
													}
													onChange={(event) => {
														this.setState({
															NonWHAgentUnAvalibilityMsg: event.target.value,
															disableSaveBtn: true,
														});
													}}
													helperText={
														'Shown to the user if no agent is online during agent non-working hours'
													}
													error={isHTMLTagsPresent(
														this.state.NonWHAgentUnAvalibilityMsg,
														true
													)}
												/>
												{/* <p
										className='body2'
										style={{ marginTop: '30px', fontWeight: 500 }}>
										Agent non availability message (non-working hours)
									</p>
									<p className='caption' style={{ marginTop: '10px' }}>
										Shown to the user if no agent is online during agent
										non-working hours
									</p> */}
												{/* <textarea
										className='body2 config-msg-footer '
										style={{
											width: '100%',
											marginTop: '10px',
											borderBottom: '1px solid #e8e8ea',

											fontSize: '14px',
										}}
										value={this.state.NonWHAgentUnAvalibilityMsg}
										onChange={(event) => {
											this.setState({
												NonWHAgentUnAvalibilityMsg: event.target.value,
												disableSaveBtn: true,
											});
										}}
									/> */}
											</div>
										</>
									)}
								</>
								<div>
									<Input
										multiline
										value={this.state.handoffConfirmationMsg}
										label={'Handoff confirmation prompt'}
										onChange={(event) => {
											this.setState({
												handoffConfirmationMsg: event.target.value,
												disableSaveBtn: true,
											});
										}}
										helperText={`This prompt asks for confirmation for handoff from the user
											if the handoff is triggered automatically. The user can say
											'Yes' or 'No'`}
										error={isHTMLTagsPresent(
											this.state.handoffConfirmationMsg,
											true
										)}
									/>
									{/* <p
										className='body2'
										style={{ marginTop: '30px', fontWeight: 500 }}>
										Handoff confirmation prompt
									</p>
									<p className='caption' style={{ marginTop: '10px' }}>
										This prompt asks for confirmation for handoff from the user
										if the handoff is triggered automatically. The user can say
										'Yes' or 'No'
									</p> */}
									{/* <textarea
										className='body2 config-msg-footer'
										style={{
											width: '100%',
											marginTop: '10px',
											borderBottom: '1px solid #e8e8ea',
											fontSize: '14px',
										}}
										value={this.state.handoffConfirmationMsg}
										onChange={(event) => {
											this.setState({
												handoffConfirmationMsg: event.target.value,
												disableSaveBtn: true,
											});
										}}
									/> */}
								</div>
								<div>
									<Input
										multiline
										value={this.state.handoffMsg}
										label={'Handoff Message'}
										onChange={(event) => {
											this.setState({
												handoffMsg: event.target.value,
												disableSaveBtn: true,
											});
										}}
										error={isHTMLTagsPresent(this.state.handoffMsg, true)}
										helperText={`Shown if the user says "Yes" to the handoff confirmation
										prompt or if the user request for agent handoff explicitly`}
									/>
									{/* <p
										className='body2'
										style={{ marginTop: '30px', fontWeight: 500 }}>
										Handoff Message
									</p>
									<p className='caption' style={{ marginTop: '10px' }}>
										Shown if the user says "Yes" to the handoff confirmation
										prompt or if the user request for agent handoff explicitly
									</p> */}
									{/* <input
										className='body2 config-msg-footer'
										style={{
											width: '100%',
											marginTop: '10px',
											borderBottom: '1px solid #e8e8ea',
											fontSize: '14px',
										}}
										value={this.state.handoffMsg}
										onChange={(event) => {
											this.setState({
												handoffMsg: event.target.value,
												disableSaveBtn: true,
											});
										}}
									/> */}
								</div>
								<div>
									<Input
										multiline
										value={this.state.waitTimeResponse}
										label={'Wait time response'}
										onChange={(event) => {
											this.setState({
												waitTimeResponse: event.target.value,
												disableSaveBtn: true,
											});
										}}
										error={isHTMLTagsPresent(this.state.waitTimeResponse, true)}
										helperText={`This message is shown if the user asks the Ad/Storefront
										something while waiting for an agent to connect. The
										Ad/Storefront will not be able to respond during the time.`}
									/>
									{/* <p
										className='body2'
										style={{ marginTop: '30px', fontWeight: 500 }}>
										Wait time response
									</p>
									<p className='caption' style={{ marginTop: '10px' }}>
										This message is shown if the user asks the Ad/Storefront
										something while waiting for an agent to connect. The
										Ad/Storefront will not be able to respond during the time.
									</p> */}
									{/* <input
										className='body2 config-msg-footer'
										style={{
											width: '100%',
											marginTop: '10px',
											borderBottom: '1px solid #e8e8ea',
											fontSize: '14px',
										}}
										value={this.state.waitTimeResponse}
										onChange={(event) => {
											this.setState({
												waitTimeResponse: event.target.value,
												disableSaveBtn: true,
											});
										}} 
									/>*/}
								</div>
								<div>
									<Input
										multiline
										value={this.state.handoffCancelledMsg}
										label={'Handoff cancelled message'}
										onChange={(event) => {
											this.setState({
												handoffCancelledMsg: event.target.value,
												disableSaveBtn: true,
											});
										}}
										helperText={`This message is shown to the user if they choose 'No' in the
										handoff confirmation or cancel the handoff while waiting in
										the queue`}
										error={isHTMLTagsPresent(
											this.state.handoffCancelledMsg,
											true
										)}
									/>
									{/* <p
										className='body2'
										style={{ marginTop: '30px', fontWeight: 500 }}>
										Handoff cancelled message
									</p>
									<p className='caption' style={{ marginTop: '10px' }}>
										This message is shown to the user if they choose 'No' in the
										handoff confirmation or cancel the handoff while waiting in
										the queue
									</p> */}
									{/* <input
										className='body2 config-msg-footer'
										style={{
											width: '100%',
											marginTop: '10px',
											borderBottom: '1px solid #e8e8ea',
											fontSize: '14px',
										}}
										value={this.state.handoffCancelledMsg}
										onChange={(event) => {
											this.setState({
												handoffCancelledMsg: event.target.value,
												disableSaveBtn: true,
											});
										}}
									/> */}
								</div>
								<div>
									<Input
										multiline
										value={this.state.handoffEndMsg}
										label={'Handoff end message'}
										onChange={(event) => {
											this.setState({
												handoffEndMsg: event.target.value,
												disableSaveBtn: true,
											});
										}}
										helperText={`This message is shown to the user when the chat is
										transferred or released`}
										error={isHTMLTagsPresent(this.state.handoffEndMsg, true)}
									/>
									{/* <p
										className='body2'
										style={{ marginTop: '30px', fontWeight: 500 }}>
										Handoff end message
									</p>
									<p className='caption' style={{ marginTop: '10px' }}>
										This message is shown to the user when the chat is
										transferred or released
									</p> */}
									{/* <textarea
										className='body2 config-msg-footer'
										style={{
											width: '100%',
											marginTop: '10px',
											borderBottom: '1px solid #e8e8ea',
											fontSize: '14px',
										}}
										value={this.state.handoffEndMsg}
										onChange={(event) => {
											this.setState({
												handoffEndMsg: event.target.value,
												disableSaveBtn: true,
											});
										}}
									/> */}
								</div>
							</div>
						</div>
					)}
				</div>
				<div className='save-configure-details'>
					<div style={{ marginRight: '36px' }}>
						<Button
							onClick={() => {
								this.setBotStudioSetting();
							}}
							style={{ padding: '8px 24px', width: '90px' }}
							disabled={
								!this.state.disableSaveBtn ||
								isHTMLTagsPresent(this.state.WHAgentUnAvalibilityMsg, true) ||
								isHTMLTagsPresent(
									this.state.NonWHAgentUnAvalibilityMsg,
									true
								) ||
								isHTMLTagsPresent(this.state.handoffConfirmationMsg, true) ||
								isHTMLTagsPresent(this.state.handoffMsg, true) ||
								isHTMLTagsPresent(this.state.waitTimeResponse, true) ||
								isHTMLTagsPresent(this.state.handoffCancelledMsg, true) ||
								isHTMLTagsPresent(this.state.handoffEndMsg, true) ||
								isHTMLTagsPresent(this.state.queuehandoffMsg, true) ||
								this.checkEmailValidation(this.state.toEmail)
							}>
							Save
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
