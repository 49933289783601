export const NoteInfo = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='Info icon'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path data-name='Path 14762' d='M0 0h24v24H0z' fill='none' />
			<path
				data-name='Path 14763'
				d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm1 15h-2v-6h2zm0-8h-2V7h2z'
				fill='#3b44c4'
			/>
		</svg>
	);
};

export const NoteError = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='error_outline_black_24dp (1)'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path data-name='Path 14760' d='M0 0h24v24H0z' fill='none' />
			<path
				data-name='Path 14761'
				d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
				fill='#f86060'
			/>
		</svg>
	);
};

export const NoteCloseIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='close icon'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			className='notecloseicon'>
			<g data-name='Group 30351'>
				<path
					data-name='Path 14753'
					d='M13 5.806 12.194 5 9 8.194 5.806 5 5 5.806 8.194 9 5 12.194l.806.806L9 9.806 12.194 13l.806-.806L9.806 9z'
					transform='translate(-1 -1)'
					fill='rgba(0,0,0,.4)'
				/>
			</g>
		</svg>
	);
};
