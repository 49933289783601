import styled from 'styled-components';

type HeaderProps = {
	colorScale: string[];
	mode: string;
};

export const CLOSEICON = styled.svg<HeaderProps>`
	width: 24px;
	height: 24px;
	fill: ${(props) => (props.mode === 'dark' ? '#ffffff' : props.colorScale[0])};
`;
export const MINIMISEICON = styled.svg<HeaderProps>`
	width: 24px;
	height: 24px;
	fill: ${(props) => (props.mode === 'dark' ? '#ffffff' : props.colorScale[0])};
`;

export const BACKICON = styled.svg<HeaderProps>`
	width: 24px;
	height: 24px;
	fill: ${(props) => (props.mode === 'dark' ? '#ffffff' : props.colorScale[0])};
`;

export const Header = styled.div<HeaderProps>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
	height: 64px;
	padding: 20px 24px;
	background-color: unset;
	& > .header-name-bck-btn {
		display: flex;
		align-items: center;
		& > svg {
			margin-right: 8px;
		}
	}
	& > div.icons {
		display: flex;
		align-items: center;
		& > .close {
			margin-left: 24px;
		}
	}
`;
type AINAMEPROPS = HeaderProps & {
	fontSize: string;
};
export const AINAME = styled.h6<AINAMEPROPS>`
	font-size: ${(props) => Number(props.fontSize.substring(0, 2)) * 1.25}px;
	line-height:1.4
	letter-spacing:0.03em;
	font-family:inherit;
	color: ${(props) =>
		props.mode === 'dark' ? '#ffffff' : props.colorScale[0]} !important;
`;
