import DataFetchAPI from '../../../utils/Api';
import { kotakTenantCheckForWhatsapp, loadState } from '../../../utils/utils';
const getList = async (data: object) => {
	return await DataFetchAPI().get(
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? `/${loadState('WAEndpoint')}/getConsentList`
			: `/${loadState('WAEndpoint')}/${loadState('tenantId')}/contact`,
		{
			params: data,
		}
	);
};

const addContact = async (data: object) => {
	return await DataFetchAPI().post(
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? `/${loadState('WAEndpoint')}/addConsent`
			: `/${loadState('WAEndpoint')}/${loadState('tenantId')}/contact`,
		data
	);
};
// not used as of now menu dropdown does not have edit
const editContact = async (data: object) => {
	return await DataFetchAPI().post(
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? `/${loadState('WAEndpoint')}/updateConsentContact`
			: `/${loadState('WAEndpoint')}/${loadState('tenantId')}/contact`,
		data
	);
};

const deleteContact = async (data: object) => {
	if (kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)) {
		return await DataFetchAPI().post(
			`/${loadState('WAEndpoint')}/deleteConsentContact`,
			data
		);
	}
	return await DataFetchAPI().delete(
		`/${loadState('WAEndpoint')}/${loadState('tenantId')}/contact`,
		{ params: data }
	);
};

const uploadContacts = async (data: { jobs: any; file: any }) => {
	const formdata = new FormData();
	formdata.append('job', JSON.stringify(data.jobs));
	formdata.append('file', data.file);
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/consentJob`,
		formdata
	);
};
// not used as of now
const getContactsAnalytics = async (data: object) => {
	return await DataFetchAPI().get(`/${loadState('WAEndpoint')}/getConsentJob`, {
		params: data,
	});
};

const contactService = {
	getList,
	addContact,
	editContact,
	deleteContact,
	uploadContacts,
	getContactsAnalytics,
};

export default contactService;
