import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import Modal from '../../../Components/Modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { setSnackModal } from '../../../Features/message/messageSlice';
import {
	clearcreatejoinTeamset,
	createjoinTeamset,
	CreateNewTeam,
	createTeamset,
	getTenants,
} from '../../../Features/teams/TeamsSlice';
import { selectTeam } from '../../../Features/teams/TeamsSlice';
import {
	encryptText,
	isHTMLTagsPresent,
	loadState,
	saveState,
} from '../../../utils/utils';
import { CreateNewTeamImg } from '../../../assets/Icons';
import '../CreateJoinTeam/createjointeam.scss';
import {
	linkSubscriptionTenantId,
	shopSubscriptionPayment_shopify,
} from '../../../Features/Subscription/subscriptionSlice';
import { login } from '../../../Features/auth/authSlice';
import Header from '../../Header/Header';
import PageLoader from '../../../Components/PageLoader';
import RedirectLoader from '../../RedirectLoader';
interface Props {
	setRateLimitModal: (arg: boolean) => void;
	errMsg: string | undefined;
}
const RateLimiting = ({ setRateLimitModal, errMsg }: Props) => {
	return (
		<Modal
			header='You can’t create more teams'
			actionButtons={
				<Button onClick={() => setRateLimitModal(false)}>Okay</Button>
			}>
			<p>
				You are already a part of 10 or more teams. In order to create another
				team, please ensure that you are a part of less than 10 teams.
			</p>
		</Modal>
	);
};
function CreateTeam() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [teamName, setTeamName] = useState('');
	const [errMsg, setErrMsg] = useState<string | undefined>(undefined);
	const { CreateTeamRes, CreateNewTeamstatus, teams } = useSelector(
		(state: RootState) => state.teams
	);
	const [rateLimitModal, setRateLimitModal] = useState(false);
	const [enableTeam, setEnableTeam] = useState(true);
	const [showContent, setShowContent] = useState(false);
	const [retryPayment, setRetryPayment] = useState(false);

	const {
		organizationName,
		currentSubscriptionId,
		userEmailWhileSignUp,
		shopSubscriptionPaymentAPIStatus,
		subscriptionId_shopify,
	} = useSelector((state: RootState) => state.subscription);

	const handleTeamName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTeamName(e.target.value);
		setErrMsg('');
		dispatch(clearcreatejoinTeamset());
	};

	const handleCreateTeam = () => {
		if (loadState('shopifyShopName')) {
			dispatch(
				CreateNewTeam({
					name: teamName,
					subscriptionId:
						loadState('chargeId') ?? loadState('subscriptionId_shopify'),
					createdBy: loadState('userEmailId'),
				})
			);
		} else {
			dispatch(
				CreateNewTeam({
					name: teamName,
					subscriptionId: currentSubscriptionId,
					createdBy: loadState('userEmailId'),
				})
			);
		}
	};

	useEffect(() => {
		validateField();
	}, [teamName]);

	useEffect(() => {
		saveState('teamDet', CreateTeamRes);
		if (CreateTeamRes.status === 'Success') {
			saveState(
				'tenantId',
				loadState('teamDet').data && loadState('teamDet').data.id
			);
			saveState('teamname', teamName);
			dispatch(
				selectTeam({
					tenantId: CreateTeamRes.id,
					teamname: teamName,
				})
			);
			saveState('nouse', true);
			dispatch(createTeamset(false));
			//for new users that completed the signup flow
			if (loadState('parameter') !== '') {
				//currentSubscriptionId zoho and loadState('chargeId') ?? loadState('subscriptionId_shopify') is of shopify

				dispatch(
					linkSubscriptionTenantId({
						subscriptionId: loadState('subscriptionId_shopify')
							? loadState('subscriptionId_shopify')
							: loadState('chargeId') ?? currentSubscriptionId,
						tenantId: loadState('tenantId'),
					})
				);
				saveState('parameter', '');

				dispatch(login({ user: userEmailWhileSignUp, otp: false }));
				navigate('/app/login');
			}
			//for new users that didn't completed the signup flow and logged in
			else if (loadState('parameter') === '') {
				dispatch(
					linkSubscriptionTenantId({
						subscriptionId: loadState('subscriptionId_shopify')
							? loadState('subscriptionId_shopify')
							: loadState('chargeId') ?? currentSubscriptionId,
						tenantId: loadState('tenantId'),
					})
				);
				dispatch(
					getTenants({ userId: loadState('user') && loadState('user').userID })
				);
				navigate('/app/create-ai');
			}
		} else if (CreateTeamRes.status === 'Failed') {
			setErrMsg(CreateTeamRes.error);
			setRateLimitModal(true);
		}
	}, [CreateNewTeamstatus]);

	const disableButton = () => {
		if (loadState('shopifyShopName')) {
			if (shopSubscriptionPaymentAPIStatus === 'success') {
				return enableTeam && isHTMLTagsPresent(teamName);
			} else if (shopSubscriptionPaymentAPIStatus === 'failed') {
				return true;
			}
		} else {
			return enableTeam && isHTMLTagsPresent(teamName);
		}
		return false;
	};

	useEffect(() => {
		//do nothing
		if (subscriptionId_shopify) {
			setShowContent(true);
		} else {
			if (shopSubscriptionPaymentAPIStatus === 'success') {
				setShowContent(true);
				setRetryPayment(false);
			} else if (shopSubscriptionPaymentAPIStatus === 'failed') {
				setShowContent(false);
				setRetryPayment(true);
			}
		}
	}, [shopSubscriptionPaymentAPIStatus, subscriptionId_shopify]);

	const buttons = () => {
		return (
			<div className='create-team-custom-btn'>
				<Button
					onClick={() => {
						setRateLimitModal(false);
						dispatch(createTeamset(false));
						// dispatch(createjoinTeamset(true));
						// navigate('/app/create-or-join-team');
					}}
					buttonType='light'>
					Back
				</Button>
				<Button
					disabled={disableButton()}
					onClick={() => {
						dispatch(createjoinTeamset(false));
						handleCreateTeam();
					}}
					loading={CreateNewTeamstatus === 'loading'}>
					Create
				</Button>
			</div>
		);
	};

	const validateField = () => {
		if (teamName.trim().length === 0) {
			setEnableTeam(true);
		} else {
			setEnableTeam(false);
		}
	};

	useEffect(() => {
		if (organizationName.length) {
			setTeamName(organizationName);
		}
		if (loadState('shopifyShopName')) {
			const params = new URLSearchParams(window.location.search.substring(1));
			let chargeId = params.get('charge_id');
			if (chargeId) {
				saveState('chargeId', chargeId);
				let userId =
					loadState('parameter') === 'notLoggedIn'
						? loadState('userId')
						: loadState('user').userID;
				dispatch(
					shopSubscriptionPayment_shopify({
						chargeId: chargeId,
						shop: loadState('shopifyShopName'),
					})
				);
			}
		}
	}, []);

	const createTeamContent = () => {
		return (
			<>
				<div className='ff_Join_Team_container'>
					<CreateNewTeamImg width='440' height='440' />
					<div className='ff_Join_Team_text_container'>
						<div className='ff_Join_Team_text'>
							<h4> Create a Team</h4>
						</div>
						<div className='ff_Join_Team_input'>
							<Input
								value={teamName}
								label='Team name'
								onChange={handleTeamName}
								errorText={errMsg}
								error={Boolean(errMsg)}
								onBlur={validateField}
								disAllowSpecialChar={true}
								autoFocus
							/>
							<div className='ff_Join_Team_input_text'>
								<p className='caption'>Give your team a unique name</p>
							</div>
						</div>

						{buttons()}
					</div>
				</div>
				{rateLimitModal && (
					<RateLimiting
						setRateLimitModal={setRateLimitModal}
						errMsg={errMsg || CreateTeamRes.error}
					/>
				)}
			</>
		);
	};
	return (
		<>
			{loadState('basictoken') ? (
				<>
					{loadState('parameter') === 'notLoggedIn' ? (
						<Header showheaderUserbadge={false} />
					) : null}
					{loadState('shopifyShopName') === undefined ? (
						createTeamContent()
					) : showContent ? (
						createTeamContent()
					) : (
						<div className='query-loader'>
							<RedirectLoader
								showRetry={retryPayment}
								message='Please wait while we fetch your Payment Approval.'></RedirectLoader>
						</div>
					)}
				</>
			) : (
				<Navigate to='/app/login' replace />
			)}
		</>
	);
}

export default CreateTeam;
