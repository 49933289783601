import { useEffect } from 'react';
import CatalogCard from '../CatalogCard/CatalogCard';
import Catalog from '../../../assets/Icons/Catalog';
import {
	getTemplateBotsList,
	setSelectedTemplateBot,
} from '../../../Features/Bots/BotSlice';
import { RootState, useAppDispatch } from '../../../app/store';
import { useSelector } from 'react-redux';
import { cardType } from '../model';
import { loadState } from '../../../utils/utils';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import './SelectCatalog.scss';
import Search from '../../../Components/Search';
import { Masonry } from '@mui/lab';
import AccessoriesImg from '../../../assets/images/CatalogScreen/Gallery/Accessories.png';
import BakedFoods from '../../../assets/images/CatalogScreen/Gallery/BakedFoods.png';
import Cosmetics from '../../../assets/images/CatalogScreen/Gallery/Cosmetics.png';
import CreditCards from '../../../assets/images/CatalogScreen/Gallery/CreditCards.png';
import DentalHygieneProducts from '../../../assets/images/CatalogScreen/Gallery/DentalHygieneProducts.png';
import FemaleHygieneProducts from '../../../assets/images/CatalogScreen/Gallery/FemaleHygieneProducts.png';
import SportsMemberships from '../../../assets/images/CatalogScreen/Gallery/Gym&SportsMemberships.png';
import HomeAppliances from '../../../assets/images/CatalogScreen/Gallery/HomeAppliances.png';
import MedicalCheckup from '../../../assets/images/CatalogScreen/Gallery/MedicalCheckup.png';
import MutualFunds from '../../../assets/images/CatalogScreen/Gallery/MutualFunds.png';
import SeasonalSale from '../../../assets/images/CatalogScreen/Gallery/SeasonalSale.png';
import TestDrives from '../../../assets/images/CatalogScreen/Gallery/TestDrives.png';
import TourPackages from '../../../assets/images/CatalogScreen/Gallery/TourPackages.png';
import Loans from '../../../assets/images/CatalogScreen/Gallery/Loans.png';
const SelectCatalog = () => {
	const { type } = useParams();
	// const { templateBotList } = useSelector((state: RootState) => state.bots);
	const navigate = useNavigate();
	const tenantId = loadState('tenantId');
	const dispatch = useAppDispatch();
	const templateBotList = [
		{
			templateId: 1,
			tenantId: 12,
			templateName: 'Accessories',
			templateDisplayName: 'Accessories',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: AccessoriesImg,
			isFavourite: false,
		},
		{
			templateId: 1,
			tenantId: 12,
			templateName: 'Accessories',
			templateDisplayName: 'Accessories',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: AccessoriesImg,
			isFavourite: false,
		},
		{
			templateId: 2,
			tenantId: 12,
			templateName: 'Baked Foods',
			templateDisplayName: 'Baked Foods',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: BakedFoods,
			isFavourite: false,
		},
		{
			templateId: 3,
			tenantId: 12,
			templateName: 'Cosmetics',
			templateDisplayName: 'Cosmetics',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: Cosmetics,
			isFavourite: false,
		},
		{
			templateId: 3,
			tenantId: 12,
			templateName: 'Cosmetics',
			templateDisplayName: 'Cosmetics',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: Cosmetics,
			isFavourite: false,
		},
		{
			templateId: 4,
			tenantId: 12,
			templateName: 'Credit Cards',
			templateDisplayName: 'Credit Cards',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: CreditCards,
			isFavourite: false,
		},
		{
			templateId: 5,
			tenantId: 12,
			templateName: 'Dental Hygiene Products',
			templateDisplayName: 'Dental Hygiene Products',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: DentalHygieneProducts,
			isFavourite: false,
		},
		{
			templateId: 5,
			tenantId: 12,
			templateName: 'Dental Hygiene Products',
			templateDisplayName: 'Dental Hygiene Products',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: DentalHygieneProducts,
			isFavourite: false,
		},
		{
			templateId: 6,
			tenantId: 14,
			templateName: 'Female Hygiene Products',
			templateDisplayName: 'Female Hygiene Products',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: FemaleHygieneProducts,
			isFavourite: false,
		},
		{
			templateId: 6,
			tenantId: 14,
			templateName: 'Female Hygiene Products',
			templateDisplayName: 'Female Hygiene Products',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: FemaleHygieneProducts,
			isFavourite: false,
		},
		{
			templateId: 8,
			tenantId: 12,
			templateName: 'Home Appliances',
			templateDisplayName: 'Home Appliances',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: HomeAppliances,
			isFavourite: false,
		},
		{
			templateId: 8,
			tenantId: 12,
			templateName: 'Home Appliances',
			templateDisplayName: 'Home Appliances',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: HomeAppliances,
			isFavourite: false,
		},
		{
			templateId: 9,
			tenantId: 12,
			templateName: 'Medical Checkup',
			templateDisplayName: 'Medical Checkup',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: MedicalCheckup,
			isFavourite: false,
		},
		{
			templateId: 9,
			tenantId: 12,
			templateName: 'Medical Checkup',
			templateDisplayName: 'Medical Checkup',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: MedicalCheckup,
			isFavourite: false,
		},
		{
			templateId: 10,
			tenantId: 12,
			templateName: 'Seasonal Sale',
			templateDisplayName: 'Seasonal Sale',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: SeasonalSale,
			isFavourite: false,
		},
		{
			templateId: 10,
			tenantId: 12,
			templateName: 'Seasonal Sale',
			templateDisplayName: 'Seasonal Sale',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: SeasonalSale,
			isFavourite: false,
		},
		{
			templateId: 11,
			tenantId: 1205,
			templateName: 'Mutual Funds',
			templateDisplayName: 'Mutual Funds',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: MutualFunds,
			isFavourite: false,
		},
		{
			templateId: 11,
			tenantId: 1205,
			templateName: 'Mutual Funds',
			templateDisplayName: 'Mutual Funds',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: MutualFunds,
			isFavourite: false,
		},
		{
			templateId: 12,
			tenantId: 1203,
			templateName: 'Test Drives',
			templateDisplayName: 'Test Drives',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: TestDrives,
			isFavourite: false,
		},
		{
			templateId: 12,
			tenantId: 1203,
			templateName: 'Test Drives',
			templateDisplayName: 'Test Drives',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: TestDrives,
			isFavourite: false,
		},
		{
			templateId: 14,
			tenantId: 1200,
			templateName: 'Loans',
			templateDisplayName: 'Loans',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: Loans,
			isFavourite: false,
		},
		{
			templateId: 15,
			tenantId: 13,
			templateName: 'Baked Foods',
			templateDisplayName: 'Baked Foods',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: BakedFoods,
			isFavourite: false,
		},
		{
			templateId: 16,
			tenantId: 15,
			templateName: 'Credit Cards',
			templateDisplayName: 'Credit Cards',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: CreditCards,
			isFavourite: false,
		},
		{
			templateId: 17,
			tenantId: 16,
			templateName: 'Sports Memberships',
			templateDisplayName: 'Sports Memberships',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: SportsMemberships,
			isFavourite: false,
		},
		{
			templateId: 17,
			tenantId: 16,
			templateName: 'Sports Memberships',
			templateDisplayName: 'Sports Memberships',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: SportsMemberships,
			isFavourite: false,
		},
		{
			templateId: 19,
			tenantId: 12211,
			templateName: 'Tour Packages',
			templateDisplayName: 'Tour Packages',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: TourPackages,
			isFavourite: false,
		},
		{
			templateId: 19,
			tenantId: 12211,
			templateName: 'Tour Packages',
			templateDisplayName: 'Tour Packages',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'VSR',
			templateImageURL: TourPackages,
			isFavourite: false,
		},
		{
			templateId: 20,
			tenantId: 12,
			templateName: 'Loans',
			templateDisplayName: 'Loans',
			templateDomain: 'string',
			templateDescription: 'string',
			token: null,
			templateType: 'CA',
			templateImageURL: Loans,
			isFavourite: false,
		},
	];
	useEffect(() => {
		if (type && Boolean(type)) {
			let typeToSend =
				type === 'intelligent-ad'
					? 'CA'
					: type === 'multichannel-storefront'
					? 'VSR'
					: '';

			if (typeToSend !== '')
				dispatch(getTemplateBotsList({ tenantId, ca_vsr_type: typeToSend }));
		}
	}, [type, tenantId]);

	return (
		<>
			<div className='ff-header-container'>
				<header>
					<h4>
						{type === 'intelligent-ad'
							? 'Intelligent Ad Gallery'
							: 'Multichannel Storefront Gallery'}
					</h4>
					<p className='body2'>
						Pick from dozens of pre-designed conversations or design your own
					</p>
				</header>
				<div className='search-catalog'>
					<Search placeholder={'Search...'} onChange={(value) => {}} />
				</div>
			</div>
			<div className='ff-catalog-screen-body'>
				<Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing='1px'>
					<div className='ff-first-card' onClick={() => navigate('new')}>
						<Catalog color='#fff' />
						<h5>Design From Scratch</h5>
					</div>
					{templateBotList &&
						templateBotList
							.filter(
								(each) =>
									each.templateType ===
									(type === 'intelligent-ad'
										? 'CA'
										: type === 'multichannel-storefront'
										? 'VSR'
										: '')
							)
							.map((eachCard: cardType, index: number) => (
								<CatalogCard
									props={eachCard}
									index={index}
									key={index}
									onClick={() => {
										navigate('create', {
											state: { value: eachCard },
										});
										dispatch(setSelectedTemplateBot(eachCard));
									}}
								/>
							))}
				</Masonry>
			</div>
			<Outlet />
		</>
	);
};

export default SelectCatalog;
