import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import launchService from './launchService';
import { Embeddings, LaunchState } from './model';

export const generateEmbeddings = createAsyncThunk<
	any,
	Embeddings,
	{ rejectValue: string }
>('/generateEmbeddings', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await launchService.generateEmbeddings(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		console.log('Network error');
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

const initialState: LaunchState = {
	embeddingLoader: '',
	publishModal: '',
};

const launchSlice = createSlice({
	name: 'launch',
	initialState,
	reducers: {
		setPublishModal: (state, action) => {
			state.publishModal = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(generateEmbeddings.pending, (state) => {
				state.embeddingLoader = 'loading';
			})
			.addCase(generateEmbeddings.fulfilled, (state, action) => {
				state.embeddingLoader = 'success';
				state.publishModal = 'Success';
			})
			.addCase(generateEmbeddings.rejected, (state, action) => {
				state.embeddingLoader = 'failed';
				state.publishModal = 'failed';
			});
	},
});

export const { setPublishModal } = launchSlice.actions;

export default launchSlice.reducer;
