import './AddQuery.scss';
import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import Modal from '../../../Components/Modal';
import { useEffect, useState } from 'react';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	addQuery,
	addVariation,
	getQuery,
	getVariation,
	removeSelectedQuery,
	resetQueryAdded,
	resetQueryEditing,
	setActiveQueryIndex,
	updateQuery,
} from '../../../Features/builder/builderSlice';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce, isHTMLTagsPresent } from '../../../utils/utils';
import {
	CircledPlus,
	JourneyInfo,
	ModalClose,
	SearchClose,
	AddSuggestion,
	VariantPlus,
} from '../../../assets/Icons';
import { Query } from '../../../Features/builder/model';

import InteractiveButton from '../../../Components/Button/InteractiveButton';
import { set } from '../../../Features/message/messageSlice';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import styled from 'styled-components';
import { SmallDeleteIcon } from '../../../assets/Icons/Bot';
type AddQueryProps = {
	mode: 'add' | 'edit';
};

const AddQuery = ({ mode }: AddQueryProps) => {
	const [query, setQuery] = useState('');
	const [variants, setVariants] = useState<Array<string>>(['', '', '']);
	const [suggestions, setSuggestions] = useState<Array<string>>([]);
	const [filteredSuggestions, setFilteredSuggestions] = useState<Array<string>>(
		[]
	);
	const [suggestionCount, setSuggestionCount] = useState(0);

	const [showSuggestionMessage, setSuggestionMessage] = useState<string>(
		'Enter a query above to get variant suggestions'
	);

	const { tenantId } = useSelector((state: RootState) => state.teams);
	const {
		botId,
		variations,
		selectedQuery,
		isQueryAdding,
		isQueryEditing,
		queries,
	} = useSelector((state: RootState) => state.builder);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state as Query;

	useEffect(() => {
		if (isQueryAdding === 'added') {
			navigate(-1);
			dispatch(set('New query added!'));
			dispatch(setActiveQueryIndex(queries.length));
		} else if (isQueryAdding === 'failed') {
			dispatch(set('Failed to add query!'));
		}
		dispatch(resetQueryAdded());
	}, [isQueryAdding]);

	useEffect(() => {
		if (isQueryEditing === 'edited') {
			navigate(-1);
			dispatch(set('Query edited!'));
		} else if (isQueryEditing === 'failed') {
			dispatch(set('Failed to edit query!'));
		}
		dispatch(resetQueryEditing());
	}, [isQueryEditing]);

	useEffect(() => {
		if (mode === 'edit') {
			if (state && botId && tenantId) {
				dispatch(
					getQuery({
						bot_id: botId,
						tenant_id: tenantId,
						node_id: state.nodeId,
					})
				);

				setQuery(state.displayQuestion);
			}
		}

		return () => {
			dispatch(removeSelectedQuery());
		};
	}, []);

	useEffect(() => {
		if (mode === 'edit' && selectedQuery) {
			const selectedVariants = selectedQuery.nodeVariations
				.slice(1)
				.map((variation) => variation.variation);
			let fillers: Array<string> =
				selectedVariants.length < 3
					? new Array(3 - selectedVariants.length).fill('')
					: [];
			selectedVariants.push(...fillers);
			setVariants(selectedVariants);
		}
	}, [selectedQuery]);

	useEffect(() => {
		// get suggestions
		if (query.length > 0) {
			setSuggestionMessage('Generating Suggestions...');
			debounce(
				() => dispatch(getVariation({ nodeText: query, nodeId: 0 })),
				600
			);

			filteredSuggestions.length > 0 && setSuggestionMessage('');
		} else {
			setSuggestionMessage('Enter a query above to get variant suggestions');
		}
	}, [query]);

	useEffect(() => {
		setSuggestions(
			variations.map((variation) => variation.suggestedVariationText)
		);
		setFilteredSuggestions(
			variations
				.filter(
					(variation) => !variants.includes(variation.suggestedVariationText)
				)
				.map((variation) => variation.suggestedVariationText)
		);
	}, [variations]);

	useEffect(() => {
		setFilteredSuggestions(
			suggestions.filter((suggestion) => !variants.includes(suggestion))
		);
	}, [variants]);

	const handleContinue = () => {
		const variantsEmptyRemoved = variants.filter((variant) => variant !== '');
		variantsEmptyRemoved.unshift(query.trim());

		if (botId && tenantId && !isButtonDisabled()) {
			if (mode === 'add') {
				dispatch(
					addQuery({
						botId,
						tenantId,
						displayQuestion: query.trim(),
						responseTypeId: 2,
						variants: variantsEmptyRemoved,
					})
				);
			} else if (mode === 'edit') {
				dispatch(
					updateQuery({
						botId,
						tenantId,
						displayQuestion: query.trim(),
						responseTypeId: 2,
						nodeId: state.nodeId,
						workflowId: state.workflowId,
					})
				);
				dispatch(
					addVariation({
						nodeId: state.nodeId,
						variation: variantsEmptyRemoved,
					})
				);
			}
		}
	};

	const handleSuggestionsClick = (suggestion: string) => {
		setFilteredSuggestions((prev) =>
			prev.filter((value) => value !== suggestion)
		);

		let new_variants = [
			...variants.filter((value) => value !== ''),
			suggestion,
		];

		if (new_variants.length < 3) {
			let fillers: Array<string> =
				new_variants.length < 3
					? new Array(3 - new_variants.length).fill('')
					: [];
			new_variants.push(...fillers);
		}

		setVariants(new_variants);
	};

	const handleVariantsChange = (index: number = 0, variant: string) => {
		let new_variants = variants.map((value, i) =>
			index !== i ? value : variant
		);

		new_variants = new_variants.filter((value) => value !== '');

		if (new_variants.length < 3) {
			let fillers: Array<string> = new Array(3 - new_variants.length).fill('');
			new_variants.push(...fillers);
		}

		setVariants(new_variants);
	};

	const addEmptyVariant = () => {
		document.querySelector('.variants-container')?.scrollTo({
			top: 500,
			behavior: 'smooth',
		});
		variants[variants.length - 1] !== '' && setVariants([...variants, '']);
	};

	const isButtonDisabled = () => {
		return query.length <= 0 || query.trim().length <= 0;
	};
	const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			fontSize: 11,
			padding: 10,
			lineHeight: 1.5,
			letterSpacing: 0.32,
		},
	}));
	return (
		<Modal
			headerCloseBtn
			header={mode === 'add' ? 'Add Customer Journey' : 'Edit Query & Variants'}
			className='add-query-modal'
			actionButtons={
				<>
					<Button buttonType='light' onClick={() => navigate(-1)}>
						Cancel
					</Button>
					<Button
						onClick={handleContinue}
						disabled={isButtonDisabled() || isHTMLTagsPresent(query)}
						loading={
							isQueryAdding === 'adding' || isQueryEditing === 'editing'
						}>
						Done
					</Button>
				</>
			}>
			<div className='add-query-content'>
				<div className='query-input-container'>
					<Input
						fullWidth
						className='query-input'
						// placeholder='Type Query here'
						label='Triggering Query'
						helperText='The customer journey will get triggered when the user says this or any variants of this'
						value={query}
						onChange={(event) => setQuery(event.target.value)}
						autoFocus
					/>
				</div>
				<div className='extra'>
					<div className='variants'>
						<div className='header'>
							{/* <h6>Variants</h6> */}
							{/* <InteractiveButton
								hoverable='none'
								disabled={variants[variants.length - 1] === ''}
								onClick={() => addEmptyVariant()}
								icon={<CircledPlus width='18px' />}
							/> */}
							<div className='query-variants'>
								<h6>Query Variants</h6>
								<LightTooltip title='Here you can specify more ways of asking the same query. The customer journey is triggered if the what the user says matches the query or any of the query variants.'>
									<span>
										<InteractiveButton icon={<JourneyInfo width='20px' />} />
									</span>
								</LightTooltip>
							</div>
							<Button
								buttonType='light'
								disabled={variants[variants.length - 1] === ''}
								startIcon={<VariantPlus width='20px' color='#3b44c4' />}
								onClick={() => {
									addEmptyVariant();
								}}>
								<p className='button2'>Add</p>
							</Button>
						</div>
						<div className='variants-container'>
							{variants &&
								variants.map((variant, index) => (
									<div className='variant' key={index}>
										{/* <span>{index + 1}</span> */}
										<Input
											multiline
											fullWidth
											placeholder={`Variant ${index + 1}`}
											onChange={(event) =>
												handleVariantsChange(index, event.target.value)
											}
											value={variant}></Input>
										{/* {variant.length > 0 && ( */}
										<span>
											<InteractiveButton
												title='Delete variant'
												size='small'
												onClick={() => handleVariantsChange(index, '')}
												icon={<SmallDeleteIcon opacity='0.5' width='20px' />}
											/>
										</span>
										{/* )} */}
									</div>
								))}
						</div>
					</div>
					<div className='suggestions'>
						<div className='header'>
							<div className='query-variants'>
								<h6>Variant Suggestions</h6>
								<LightTooltip title='You can add variants for your query from these automatically generated suggestions'>
									<span>
										<InteractiveButton icon={<JourneyInfo width='20px' />} />
									</span>
								</LightTooltip>
							</div>
						</div>
						<div className='suggestions-container'>
							{query && filteredSuggestions.length > 0 ? (
								filteredSuggestions.map(
									(
										suggestion,
										index //Design-Ad Changes
									) => (
										<p key={index} className='body1'>
											{suggestion}

											<Tooltip title='Add Variant'>
												<span>
													<InteractiveButton
														onClick={() => {
															handleSuggestionsClick(suggestion);
															setSuggestionCount(index + 1);
														}}
														icon={<AddSuggestion width='20px' />}

														//Design-Ad Changes
													/>
												</span>
											</Tooltip>
										</p>
									)
								)
							) : (
								// : variants.length > 0 && filteredSuggestions.length < 0
								// ? <p className='no-more-suggestions'>No more suggestions</p>
								<p className='no-more-suggestions'>
									{query &&
									suggestionCount > 0 &&
									filteredSuggestions.length < 0
										? 'No more suggestions'
										: showSuggestionMessage}
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};
export default AddQuery;
