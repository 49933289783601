import React from 'react';
import './PaymentLoader.scss';

const PaymentLoader = () => {
	return (
		<div className='ff-payment-loader'>
			<div>
				<div>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='56'
						height='56'
						viewBox='0 0 56 56'>
						<g data-name='main icon'>
							<path
								data-name='Subtraction 6'
								d='M22.909 45.818a22.909 22.909 0 0 1 0-45.818v5.09a17.819 17.819 0 1 0 17.816 17.819h5.093a22.935 22.935 0 0 1-22.909 22.909z'
								fill='#3b44c4'
								transform='translate(5.093 5.09)'
							/>
						</g>
					</svg>
				</div>
				<h3>Redirecting you to payment gateway</h3>
				<p>Please don't refresh the page or press the back button</p>
			</div>
		</div>
	);
};

export default PaymentLoader;
