import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../app/store';
import { LoginEyeClosed, LoginEyeOpen } from '../../../../assets/Icons';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import Modal from '../../../../Components/Modal';
import { set, setSnackModal } from '../../../../Features/message/messageSlice';
import {
	resetlinkZohoLoader,
	setLeadScope,
	setTicketingScope,
	zohoCrm,
} from '../../../../Features/Zoho/zohoSlice';
import { ZOHOCRMSETUP } from '../../../../Features/Zoho/modal';
import { loadState, saveState } from '../../../../utils/utils';
import './Zoho.scss';
import Checkbox from '../../../../Components/Checkbox';

const Zoho = () => {
	const dispatch = useAppDispatch();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { linkState, linkZohoLoader, leadScope, ticketingScope } = useSelector(
		(state: RootState) => state.zoho
	);
	const navigate = useNavigate();
	const [clientId, setClientId] = useState('');
	const [clientSecret, setClientSecret] = useState('');
	const [setupURL, setSetupURL] = useState('');
	const [setupURLError, setSetupURLError] = useState(false);
	const setupURLRegex = new RegExp('^(https)://accounts.zoho', 'i');
	const [isSecretKeyEnabled, setIsSecretKeyEnabled] = useState(false);
	const [isClientIdEnabled, setIsClientIdEnabled] = useState(false);
	const [moreInfo, setMoreInfo] = useState(false);
	const [zohobuttonLoader, setzohoButtonLoader] = useState(false);

	useEffect(() => {
		if (linkZohoLoader === 'success') {
			setzohoButtonLoader(false);

			window.open(linkState.url);
			navigate(-1);

			// navigate('/app/zoho-consent');
		} else if (linkZohoLoader === 'failed') {
			setzohoButtonLoader(false);

			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage:
						'Unable to link account at the moment. Please try after sometime!!!',
					modalMode: 'negative',
				})
			);
		} else if (linkZohoLoader === 'loading') {
			setzohoButtonLoader(true);
		}
		dispatch(resetlinkZohoLoader());
	}, [linkZohoLoader]);

	useEffect(() => {
		if (setupURL) {
			if (setupURLRegex.test(setupURL)) {
				setSetupURLError(false);
			} else {
				setSetupURLError(true);
			}
		}
	}, [setupURL]);

	return (
		<div className='ff-zoho-link-integration-modal'>
			<Modal
				header='Link your team with Zoho'
				actionButtons={
					<>
						<Button
							buttonType='light'
							onClick={() => {
								navigate(-1);
							}}>
							Cancel
						</Button>

						<Button
							loading={zohobuttonLoader}
							disabled={
								clientId &&
								clientSecret &&
								setupURL &&
								!setupURLError &&
								(leadScope[0] || ticketingScope[0])
									? false
									: true
							}
							onClick={() => {
								let scopes = [];
								if (leadScope[0]) {
									scopes.push('LEAD');
								}
								if (ticketingScope[0]) {
									scopes.push('TICKETING');
								}
								let data = {
									clientId: clientId,
									clientSecret: clientSecret,
									setupUrl: setupURL,
									scope: scopes.toString(),
								};
								dispatch(zohoCrm(data));
							}}>
							Link
						</Button>
					</>
				}>
				<div className='modal-body'>
					<p className='body1'>
						Linking your Flyfish team with a Zoho account lets you send leads
						captured by your Intelligent Campaigns to Zoho.{' '}
						<span
							className='more-info'
							onClick={() => {
								setMoreInfo(true);
							}}>
							More information
						</span>
					</p>
					{moreInfo ? (
						<p>
							<div className='body-moreinfo'>
								<div
									style={{
										marginBottom: '16px',
										display: 'flex',
										flexDirection: 'column',
										gap: '16px',
									}}>
									Do this within minutes by simply following the below steps:
								</div>
								<div className='body-description'>
									<div>
										<ol
											style={{
												marginLeft: '20px',
												display: 'flex',
												flexDirection: 'column',
												gap: '16px',
											}}>
											<li>
												Login to{' '}
												<a
													style={{ color: '#3b44c4' }}
													href='https://api-console.zoho.com/'
													target='_blank'
													rel='noreferrer'>
													Zoho Developer Console
												</a>{' '}
											</li>
											<li>Create a new server/web based application.</li>
											<li>
												<p style={{ marginBottom: '8px' }}>
													Please enter the Client Name and Homepage URL as
													directed in{' '}
													<a
														style={{ color: '#3b44c4' }}
														href='https://www.zoho.com/crm/developer/docs/api/v2/register-client.html'
														target='_blank'
														rel='noreferrer'>
														Zoho Documentation.
													</a>
												</p>
												<p>
													Under 'Authorized Redirect URIs', please add this URI:{' '}
													<p
														style={{ color: '#3b44c4', cursor: 'pointer' }}
														onClick={() => {
															navigator.clipboard.writeText(
																`${window.location.origin}/app/zoho-consent/thank-you`
															);
															dispatch(set('URI copied'));
														}}>
														{`${window.location.origin}/app/zoho-consent/thank-you`}
													</p>
												</p>
											</li>
											<li>
												Once your application is created, you will get Client ID
												and Client Secret for it. Please provide those below and
												click on 'Link'.
											</li>
											<li>
												You can find the regional domain for your Zoho account
												by simply signing in and then referring to the address
												bar of the browser. Just see if it is zoho.com, zoho.in,
												zoho.eu etc.
											</li>
											<li>
												Select the scope of permissions that you want Flyfish to
												have.
											</li>
											<li>
												Click on 'Link'. In the screens that follow, you will be
												asked for your permission to allow your custom app to
												read and modify your data in Zoho. Please allow that.
											</li>
										</ol>
									</div>
								</div>
							</div>
						</p>
					) : null}
					<div style={{ marginTop: '8px' }}>
						<p
							className='body2'
							style={{ fontWeight: 600, marginBottom: '8px' }}>
							Client ID
						</p>
						<Input
							value={clientId}
							type={isClientIdEnabled ? 'text' : 'password'}
							id='clientId'
							autoFocus
							onChange={(event) => {
								setClientId(event.target.value.trim());
							}}
							// label='Client ID'
							limit={50}
							endIcon={
								<div
									className='toggle-password-show'
									onClick={() => setIsClientIdEnabled(!isClientIdEnabled)}>
									{clientId === '' ? null : isClientIdEnabled ? (
										<LoginEyeClosed width='19px' />
									) : (
										<LoginEyeOpen width='19px' />
									)}
								</div>
							}
							fullWidth
						/>
					</div>
					<div style={{ marginTop: '8px' }}>
						<p
							className='body2'
							style={{ fontWeight: 600, marginBottom: '8px' }}>
							Client Secret
						</p>

						<Input
							id='secretkey'
							type={isSecretKeyEnabled ? 'text' : 'password'}
							value={clientSecret}
							onChange={(event) => {
								setClientSecret(event.target.value.trim());
							}}
							// label='Client Secret'
							limit={50}
							endIcon={
								<div
									className='toggle-password-show'
									onClick={() => setIsSecretKeyEnabled(!isSecretKeyEnabled)}>
									{clientSecret === '' ? null : isSecretKeyEnabled ? (
										<LoginEyeClosed width='19px' />
									) : (
										<LoginEyeOpen width='19px' />
									)}
								</div>
							}
							fullWidth
						/>
					</div>
					<div style={{ marginTop: '8px' }}>
						<p
							className='body2'
							style={{ fontWeight: 600, marginBottom: '8px' }}>
							Regional Domain for Zoho account
						</p>

						<Input
							value={setupURL}
							errorText={'Please enter a valid URL'}
							error={setupURLError}
							onChange={(event) => {
								setSetupURL(event.target.value.trim());
							}}
							// label='Regional Domain for Zoho account'
							fullWidth
						/>
					</div>
					<div style={{ marginTop: '8px' }}>
						<p className='body2' style={{ fontWeight: 600 }}>
							Scope of Permissions for Flyfish
						</p>
						<div style={{ marginTop: '16px' }}>
							<Checkbox
								label='Lead Creation'
								isChecked={leadScope}
								index={0}
								onValueChange={(value) => dispatch(setLeadScope(value))}
							/>
						</div>
						<div style={{ marginTop: '16px' }}>
							<Checkbox
								label='Ticketing'
								isChecked={ticketingScope}
								index={0}
								onValueChange={(value) => dispatch(setTicketingScope(value))}
							/>
						</div>
					</div>
				</div>
			</Modal>
			<Outlet />
		</div>
	);
};
export default Zoho;
