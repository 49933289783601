import DataFetchAPI from '../../../utils/Api';
import { kotakTenantCheckForWhatsapp, loadState } from '../../../utils/utils';
import { getMediaData } from './campaignSlice';

const getCampaigns = async (data: object) => {
	return await DataFetchAPI().get(
		`/${loadState('WAEndpoint')}/${loadState('tenantId')}/campaign`,
		{
			params: data,
		}
	);
};
const getCampaignsKotak = async (data: object) => {
	return await DataFetchAPI().get(
		`/${loadState('WAEndpoint')}/getScheduledCampaign`,
		{
			params: data,
		}
	);
};

const getJobSheet = async (data: object, config: object) => {
	return await DataFetchAPI().get(
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? `/${loadState('WAEndpoint')}/getJobSheet`
			: `/${loadState('WAEndpoint')}/${loadState(
					'tenantId'
			  )}/campaign/jobSheet`,
		{
			params: data,
			...config,
		}
	);
};

const getCampaignDump = async (data: object) => {
	return await DataFetchAPI().get(
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? `/${loadState('WAEndpoint')}/campaignDump`
			: `/${loadState('WAEndpoint')}/${loadState('tenantId')}/campaign/dump`,
		{
			params: data,
		}
	);
};

const getMedia = async (data: getMediaData) => {
	if (kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)) {
		return await DataFetchAPI().get(`/${loadState('WAEndpoint')}/getMedia`, {
			params: {
				mediaName: data.mediaName,
				mediaType: data.mediaType,
				offset: 0,
				wabaNo: data.wabaNo,
			},
		});
	}
	return await DataFetchAPI().get(
		`/${loadState('WAEndpoint')}/${loadState('tenantId')}/media`,
		{
			params: {
				mediaName: data.mediaName,
				clientId: btoa(data.wabaNo),
				mediaType: data.mediaType,
				page: null,
				size: null,
			},
		}
	);
};

const uploadMedia = async (data: {
	fileName: string;
	contentType: string;
	filebase64Stream: string;
	wabaNo: string;
}) => {
	if (kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)) {
		return await DataFetchAPI().post(
			`/${loadState('WAEndpoint')}/uploadMedia`,
			data
		);
	}
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/${loadState('tenantId')}/media`,
		{
			client: {
				id: btoa(data.wabaNo),
				jobId: 0,
			},
			media: {
				base64Stream: data.filebase64Stream,
				fileName: data.fileName,
				mediaMime: data.contentType,
			},
		}
	);
};

const getTemplateFormat = async (data: any, config: any) => {
	const { templateId, wabaNo } = data.data;
	return await DataFetchAPI().get(
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? `/${loadState(
					'WAEndpoint'
			  )}/getTemplateFormat?templateId=${templateId}&wabaNo=${wabaNo}`
			: `/${loadState('WAEndpoint')}/${loadState(
					'tenantId'
			  )}/template/${templateId}/createExcel`,
		{
			...config,
		}
	);
};

const excelValidation = async (data: { job: any; campaign: any }) => {
	if (kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)) {
		const formdata = new FormData();
		formdata.append('job', JSON.stringify(data.job));
		formdata.append('file', data.campaign);
		return await DataFetchAPI().post(
			`/${loadState('WAEndpoint')}/excelValidation`,
			formdata
		);
	} else {
		return await DataFetchAPI().post(
			`/${loadState('WAEndpoint')}/${loadState('tenantId')}/campaign/validate`,
			data
		);
	}
};

const createCampaign = async (data: { job: any; campaign: any }) => {
	if (kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)) {
		const formdata = new FormData();
		formdata.append('job', JSON.stringify(data.job));
		formdata.append('file', data.campaign);
		return await DataFetchAPI().post(
			`/${loadState('WAEndpoint')}/campaign`,
			formdata
		);
	}
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/${loadState('tenantId')}/campaign`,
		data
	);
};

const cancelCampaign = async (data: { jobId: string | number }) => {
	if (kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)) {
		return await DataFetchAPI().post(
			`/${loadState('WAEndpoint')}/cancelScheduledJob`,
			data
		);
	}
	return await DataFetchAPI().post(
		`/${loadState('WAEndpoint')}/${loadState(
			'tenantId'
		)}/campaign/cancelScheduledJob`,
		data
	);
};

const campaignService = {
	getCampaigns,
	getMedia,
	uploadMedia,
	getTemplateFormat,
	excelValidation,
	createCampaign,
	cancelCampaign,
	getCampaignDump,
	getJobSheet,
	getCampaignsKotak,
};

export default campaignService;
