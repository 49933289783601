import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { chatService } from './ChatService';
import { State, transcriptData } from './model';

const initialState: State = {
	isLoading: true,
	convData: [],
	conLength: 0,
	liveChatNotification: false,
	selectedChat: ""
};

export const chatUploadFile = createAsyncThunk<
	any,
	any,
	{ rejectValue: string }
>('/chatUploadFile', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await chatService.chatUploadFile(data);

		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});
export const autoAssign = createAsyncThunk<any, any, { rejectValue: string }>(
	'/autoassignOnLogin',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await chatService.autoAssign();

			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const getTranscript = createAsyncThunk<
	any,
	transcriptData,
	{ rejectValue: string }
>(
	'chats/getTranscript',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await chatService.getTranscript(data);

			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const chatSlice = createSlice({
	name: "chats",
	initialState: initialState,
	reducers: {
		// reset: (state) => {
		// 	state.isLoading = false;
		// },
		setLiveChatNotification: (state, action) => {
			state.liveChatNotification = action.payload
		},
		getConData: (state, action) => {
			state.convData = action.payload
		},
		setConLength: (state, action) => {
			state.conLength = action.payload
		},
		getSelectedChat: (state, action) => {
			state.selectedChat = action.payload
		}
	},
	extraReducers: (builder) => { },
});
export const {
	getConData,
	setConLength,
	setLiveChatNotification,
	getSelectedChat
} = chatSlice.actions;

export default chatSlice.reducer;
