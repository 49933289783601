import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store';
import Button from '../../Components/Button';
import { getAllFAQData, getFAQData } from '../../Features/FAQs/faqSlice';
import { FAQ, FAQDoc, FAQWeb } from '../../assets/Icons/index';
import { Download } from '../ComponentsLibrary/Icons';
import AfterSalesSupport from './AfterSalesSupport/AfterSalesSupport';
import { loadState, removeState, saveState } from '../../utils/utils';
import './FAQs.scss';
import PageLoader from '../../Components/PageLoader';
import { uploadFile } from '../../Features/configure/configureSlice';
import { getSalesBotId } from '../../Features/SalesAI/salesSlice';
import {
	setRefreshBot,
	setTestAndOpenBot,
	trainBot,
} from '../../Features/builder/builderSlice';
import InteractiveButton from '../../Components/Button/InteractiveButton';
// import { DeleteIcon, EditIconFAQ } from '../../../assets/Icons/Bot';
import ChatBot from '../BotBuilder/ChatBot';
import { CloseIcon, RefreshIcon, TestIcon } from '../BotBuilder/ChatBot/Icons';
import Header2 from '../../Components/Header2';
import SuccessPop from './SuccessPopup';
function FAQs() {
	const navigate = useNavigate();
	const dispatch = useDispatch<any>();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { FAQData } = useSelector((state: RootState) => state.faq);
	const {
		isDataLoading,
		isFAQImporting,
		isImporting,
		saveFAQsStatus,
		showFAQsuccessPopup,
	} = useSelector((state: RootState) => state.faq);
	const { filePathURL, filePathStatus } = useSelector(
		(state: RootState) => state.configure
	);
	const botDetails = loadState('botDetails');
	// const botId = botDetails.botId;
	const fileImportRef = useRef<HTMLInputElement | null>(null);
	localStorage.setItem('channel', 'whatsapp');
	const { afterSalesBot } = useSelector((state: RootState) => state.sales);

	useEffect(() => {
		// if (tenantId === 124) {
		dispatch(
			getSalesBotId({
				tenantId: loadState('tenantId').toString(),
				ca_vsr_type: 'AS',
			})
		);
		saveState('botDetails', afterSalesBot[0]);
		// }
		// setTimeout(() => {
		// 	tenantId &&
		// 		botDetails &&
		// dispatch(
		// 	getAllFAQData({
		// 		botid: loadState('botId').toString(),
		// 		tenantid: loadState('tenantId').toString()
		// 			? loadState('tenantId').toString()
		// 			: '',
		// 		//  ?? '',
		// 	})
		// );
		// }, 500);
	}, []);
	const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		// console.log(e);
		if (fileImportRef.current) {
			let fileInput = fileImportRef.current;
			var filePath = fileInput.value;

			if (filePath) {
				e.target.files &&
					dispatch(
						uploadFile({
							uploadfile: e.target.files[0],
							uploadTo: 'General',
						})
					);

				e.target.files && saveState('fileName', e.target.files[0]);
				saveState('fileUpload', '');
				fileInput.value = '';
			}
		}
	};
	// useEffect(() => {
	// 	filePathURL &&
	// 		dispatch(
	// 			getFAQData({
	// 				botid: botId,
	// 				tenantid: tenantId?.toString() ?? '',
	// 				url: '',
	// 				filePath: filePathURL,
	// 			})
	// 		);
	// }, [filePathURL]);

	return (
		<>
			<Header2
				leftComponent={<></>}
				breadcrumbs={[
					{
						title: 'Ingest',
						path: `app/catalog/faq/design/build/FAQs`,
					},
					{
						title: 'Configure',
						path: `app/catalog/faq/design/build/configure`,
					},
					{
						title: 'Launch',
						path: `app/launch`,
					},
				]}
			// activeButtons={getActionButtons()}
			/>
			<div className='ff-faq-screen'>
				<>
					{/* {FAQData && FAQData.FAQ && FAQData.FAQ.length === 0 ? ( */}
					<h4 className='ingest-heading'>Ingest</h4>
					<div className='no-query-found'>
						{/* <FAQ /> */}

						{/* <div className='caption'>
						Answer frequently asked questions and address customer issues on
						different channels{' '}
					</div> */}

						{FAQData &&
							FAQData.FAQ &&
							FAQData.FAQ.length > 0 &&
							((!isDataLoading && saveFAQsStatus) ||
								!isImporting ||
								!filePathStatus) ? (
							<div className='imported-source-container'>
								<p className='current-source body1'>Current source:</p>
								<p className='source-name body1'>
									{loadState('fileName') ? (
										loadState('fileUpload') === 'success' ? (
											<FAQDoc
												color='var(--black-60)'
												width='24px'
												height='24px'
											/>
										) : (
											<FAQWeb
												color='var(--black-60)'
												width='24px'
												height='24px'
											/>
										)
									) : null}{' '}
									{loadState('fileName')}
								</p>
							</div>
						) : null}
						{FAQData &&
							FAQData.FAQ &&
							FAQData.FAQ.length > 0 &&
							((!isDataLoading && saveFAQsStatus) ||
								!isImporting ||
								!filePathStatus) ? (
							<p className='replace-file-path body1'>Replace source</p>
						) : null}
						<div className='FAQ-cards'>
							<div>
								<label htmlFor='ff-import-media-file'>
									<div className='upload-doc'>
										<div className='faq-Doc'>
											<FAQDoc color='#3b44c4' />
										</div>
										<div className='faq-heading'>Upload Document</div>
										<div className='caption-text'>
											Extract FAQs from a PDF, Word or Excel file
										</div>
									</div>
								</label>
								<input
									ref={fileImportRef}
									id='ff-import-media-file'
									style={{ visibility: 'hidden', width: '300px' }}
									className='input-file'
									type='file'
									accept='.xlsx, .xlsm, .csv, .tsv , .docx ,.pdf'
									onChange={handleFileSelect}
								/>
							</div>
							<div
								className='upload-doc'
								onClick={() => {
									saveState('import-screen', 'import-screen-1');
									navigate('upload-faq-web');
								}}>
								<div className='faq-Doc'>
									<FAQWeb />
								</div>
								<div className='faq-heading'>Import from Web </div>
								<div className='caption-text'>Extract FAQs from a webpage</div>
							</div>
						</div>
					</div>
					{/* // ) 
						// : (
						// 	<AfterSalesSupport />
						// )} */}
					{FAQData &&
						FAQData.FAQ &&
						FAQData.FAQ.length > 0 &&
						showFAQsuccessPopup === 'success' ? (
						<SuccessPop />
					) : null}
					{(isDataLoading && !saveFAQsStatus) ||
						isImporting ||
						filePathStatus ? (
						<div className='query-loader'>
							<PageLoader message='Importing data...' />
						</div>
					) : null}
				</>
			</div>

			<Outlet />
		</>
	);
}

export default FAQs;
