import { Masonry } from '@mui/lab';
import React, { useState } from 'react';
import { cardType } from '../model';
import './CatalogCard.scss';
interface Props {
	props: cardType;
	index: number;
	onClick: () => void;
}

const ASPECT_RATIOS = [2];

function CatalogCard({ index, props, onClick }: Props) {
	const getBgColor = () => {
		return (props.templateId % 12) + 1;
	};
	const [aspectRatio] = useState(
		ASPECT_RATIOS[Math.floor(Math.random() * ASPECT_RATIOS.length)] //NOSONAR
	);
	return (
		<div
			className={`card-item-catalog`}
			style={{
				aspectRatio: `${aspectRatio}`,
				backgroundColor: `var(--tertiary-${getBgColor()})`,
			}}
			onClick={onClick}>
			{Boolean(props.templateImageURL) && (
				<div className='template-image'>
					<img src={props.templateImageURL} alt={props.templateDisplayName} />
				</div>
			)}
			<h5>{props.templateDisplayName}</h5>
		</div>
	);
}

export default CatalogCard;
