import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRandom, loadState } from '../../utils/utils';
import { set } from '../message/messageSlice';
import deployService from './deployService';
import {
	botID,
	deleteVersionData,
	deleteVersionResponse,
	deployData,
	reDeploy,
	reDeployResponse,
	reployResponse,
	VersionsDetails,
} from './modal';

type deployedVersionList = {
	deployedVersionsList: VersionsDetails[];
	isDeployedSuccess: string;
	reDeployedLoader: boolean;
	isReDeployedSuccess: string;
	isVersionDeleted: string;
	getVersionLoader: boolean;
	deployedLoading: boolean;
	deployVersionStatus: string;
	versionDeleteLoader: boolean;
};

const initialState: deployedVersionList = {
	deployVersionStatus: '',
	deployedVersionsList: [],
	isDeployedSuccess: '',
	reDeployedLoader: false,
	isReDeployedSuccess: '',
	isVersionDeleted: '',
	versionDeleteLoader: false,
	getVersionLoader: false,
	deployedLoading: false,
};
export const deployNewVersion = createAsyncThunk<
	reployResponse,
	deployData,
	{ rejectValue: string }
>(
	'deploy/deployNewVersion',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await deployService.deployNewVersion(data);
			// console.log(response, 'response');
			if (response.data.response === 'Success') {
				dispatch(
					getVersionDetails({
						botId: loadState('botId'),
					})
				);
				dispatch(set('Changes Published'));
				return response.data;
			}
			// else if(response.data.response==='Failed' && response.data.error==='PRIVATE BOT EXCEPTION'){

			// 	dispatch(set('Private Bot cannot be deployed!'));

			// }
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			let e: any = error;
			// console.log(e.response.data, 'error');
			if (e.response.data.error === 'PRIVATE BOT EXCEPTION')
				dispatch(set(e.response.data.data));
			else
				dispatch(set('Failed to publish changes. Please retry in some time'));
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const deleteVersion = createAsyncThunk<
	deleteVersionResponse,
	deleteVersionData,
	{ rejectValue: string }
>(
	'deploy/deleteVersion',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await deployService.deleteVersion(data);
			if (response.data.response === 'Success') {
				dispatch(
					getVersionDetails({
						botId: loadState('botId'),
					})
				);
				loadState('tenantId') &&
					dispatch(
						deployNewVersion({
							botId: loadState('botDetails').encBotId,
							//activeBot
							description: 'description',
							versionName: getRandom('version', 1),
							tenantId: loadState('tenantId').toString(),
							//tenantId
						})
					);
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const reDeployVersion = createAsyncThunk<
	reDeployResponse,
	reDeploy,
	{ rejectValue: string }
>(
	'deploy/reDeployVersion',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await deployService.reDeployVersion(data);
			if (response.data.response === 'Success') {
				dispatch(
					getVersionDetails({
						botId: data.botId,
					})
				);
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const getVersionDetails = createAsyncThunk<VersionsDetails[], botID>(
	'deploy/getVersionDetails',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await deployService.getVersionDetails(data);
			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

const deploySlice = createSlice({
	name: 'deploy',
	initialState,
	reducers: {
		reset: (state) => {
			state.deployedVersionsList = [];
		},
		setIsDeployedSuccess: (state, action) => {
			state.isDeployedSuccess = action.payload;
		},
		setIsReDeployedSuccess: (state, action) => {
			state.isReDeployedSuccess = action.payload;
		},
		setIsVersionDeleted: (state, action) => {
			state.isVersionDeleted = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getVersionDetails.pending, (state) => {
				state.deployedVersionsList = [];
				state.getVersionLoader = true;
			})
			.addCase(getVersionDetails.fulfilled, (state, action) => {
				state.deployedVersionsList = action.payload;
				state.getVersionLoader = false;
			})
			.addCase(getVersionDetails.rejected, (state) => {
				state.deployedVersionsList = [];
				state.getVersionLoader = false;
			})
			.addCase(deployNewVersion.pending, (state, action) => {
				state.isDeployedSuccess = 'Pending';

				state.deployedLoading = true;
			})
			.addCase(deployNewVersion.fulfilled, (state, action) => {
				state.isDeployedSuccess = 'Success';
				state.deployedLoading = false;
			})
			.addCase(deployNewVersion.rejected, (state, action) => {
				// console.log(action.payload, 'failed');
				// console.log(action.payload, 'failed');
				state.deployedLoading = false;
				state.isDeployedSuccess = 'Failed';
			})
			.addCase(reDeployVersion.pending, (state, action) => {
				state.reDeployedLoader = true;
			})
			.addCase(reDeployVersion.fulfilled, (state, action) => {
				state.reDeployedLoader = false;
				state.isReDeployedSuccess = 'Success';
			})
			.addCase(reDeployVersion.rejected, (state, action) => {
				state.reDeployedLoader = false;
				state.isReDeployedSuccess = 'Failed';
			})
			.addCase(deleteVersion.pending, (state, action) => {
				state.versionDeleteLoader = true;
			})
			.addCase(deleteVersion.fulfilled, (state, action) => {
				state.versionDeleteLoader = false;

				state.isVersionDeleted = action.payload.response;
			})
			.addCase(deleteVersion.rejected, (state, action) => {
				state.versionDeleteLoader = false;

				state.isVersionDeleted = 'Failed';
			});
	},
});

export const {
	reset,
	setIsReDeployedSuccess,
	setIsVersionDeleted,
	setIsDeployedSuccess,
} = deploySlice.actions;
export default deploySlice.reducer;
