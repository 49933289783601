import React, { useState } from 'react';
import './ViewCart.scss';
import {
	getRemovedproducts,
	setMsgsArray,
	setMsgsLoading,
	setShopifyCall,
	setViewCartDetails,
} from '../../../../../../Features/SalesAI/salesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/store';
import { loadState } from '../../../../../../utils/utils';

type Props = {
	data: any;
	timeStamp: string;
};

const ViewCart = (props: Props) => {
	const { data, timeStamp } = props;
	const dispatch: any = useDispatch();
	const { msgsArray, storeCartDetails } = useSelector(
		(state: RootState) => state.sales
	);

	const modifyProduct = (
		cartId: string,
		variantId: string,
		quantity: number,
		title: string
	) => {
		// console.log('modify');
		// console.log(cartId, variantId, quantity);
		// let baseURL = window.origin;
		// let baseURL = "https://dev.flyfish.ai/shopify";
		let baseURL =
			window.origin.indexOf('preprod') > -1
				? 'https://preprod.flyfish.ai/route/shop'
				: window.origin.indexOf('app') > -1
				? 'https://app.flyfish.ai/route/shop'
				: window.origin + '/route/shop';
		dispatch(
			setMsgsArray([...msgsArray, { content: `Modify ${title}`, role: 'user' }])
		);
		dispatch(setMsgsLoading('loading'));
		let modifyCart = {
			data: `${variantId}$$@$$${cartId}$$@$$${quantity}`,
		};
		fetch(`${baseURL}/product/cart/updateQuantity`, {
			method: 'POST',
			headers: {
				'X-Tenant-Id': loadState('tenantId').toString(),
				// 'X-Tenant-Id': '687',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${loadState('token')}`,
			},
			body: JSON.stringify(modifyCart),
		})
			.then((res) => res.json())
			.then((data) => {
				if (data && data.response.status.toLowerCase() === 'success') {
					dispatch(setViewCartDetails(data.response.data.data.cart));
					dispatch(setMsgsLoading('done'));
					dispatch(
						setMsgsArray([
							...msgsArray,
							{ content: `Modify ${title}`, role: 'user' },
							{ content: 'add-quantity', role: 'assistant' },
						])
					);
				}
			});
	};

	const continueShopping = () => {
		// dispatch(setShopifyCall('start-analyse'));
		dispatch(
			setMsgsArray([
				...msgsArray,
				{ content: 'Continue Shopping', role: 'user' },
				{
					content:
						'Hello, how may I help you? We have wide range of beauty products to select from.',
					role: 'assistant',
				},
			])
		);
		dispatch(setViewCartDetails(storeCartDetails));
		// dispatch(setMultiProductsState('adding-multiple-products'))
	};

	const removeProductId = (
		cartId: string,
		variantId: string,
		title: string
	) => {
		dispatch(
			setMsgsArray([...msgsArray, { content: `Remove ${title}`, role: 'user' }])
		);
		dispatch(setMsgsLoading('loading'));
		dispatch(
			getRemovedproducts({
				data: `${variantId}$$@$$${cartId}`,
			})
		);
		dispatch(setShopifyCall('start-analyse'));
	};

	const productPrice = (price: any) => {
		return Number(price)
			.toFixed(2)
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	const checkoutProduct = (data: any) => {
		dispatch(
			setMsgsArray([
				...msgsArray,
				{ content: 'Checkout', role: 'user' },
				{ content: 'make-payment', role: 'assistant' },
			])
		);
	};

	const getTotalQuantity = (cartDetails: any) => {
		let totalquantityofitems = 0;
		cartDetails &&
			cartDetails.lines &&
			cartDetails.lines.edges &&
			cartDetails.lines.edges.map((product: any) => {
				totalquantityofitems += product.node && product.node.quantity;
			});
		return totalquantityofitems;
	};

	let storeProducts: any[] = [];
	const getcartCollections = (data: any) => {
		data.lines.edges.map((product: any) => {
			// let productSize = product.node.merchandise.title.split(' / ')[0]
			// 	? `| Size: ${product.node.merchandise.title.split(' / ')[0]} `
			// 	: '';
			// let productColor = product.node.merchandise.title.split(' / ')[1]      // ${productSize}${productColor}
			// 	? `| Colour: ${product.node.merchandise.title.split(' / ')[1]} `
			// 	: '';
			let productAmount = productPrice(
				storeCartDetails.cost.totalAmount.amount / product.node.quantity
			);
			let singleProduct = {
				resourceURL:
					product.node.merchandise.product.images.edges.length > 0
						? product.node.merchandise.product.images.edges[0].node.src
						: '',
				title: product.node.merchandise.product.title || 'Product 1',
				description: `${
					storeCartDetails.cost.totalAmount.currencyCode
				} ${productAmount} | Quantity: ${product.node.quantity} | 
                           Item total: ${
															storeCartDetails.cost.totalAmount.currencyCode
														} ${productPrice(
					storeCartDetails.cost.totalAmount.amount
				)}`,
				price: productPrice(storeCartDetails.cost.totalAmount.amount),
				cartId: storeCartDetails.id,
				cartCount: storeCartDetails.lines.edges.length,
				productVariantId: product.node.id,
				quantity: product.node.quantity,
			};
			storeProducts.push(singleProduct);
		});

		return storeProducts;
	};
	return (
		storeCartDetails && (
			<div className='ff-view-cart-container'>
				<div className='items-added-description'>
					<p className='items-added-text body1'>Item added. Here’s your cart</p>
					<p className='timeline caption'>{timeStamp}</p>
				</div>
				<div className='total-cart-details'>
					<p className='cart-total-amount body1'>
						Cart total:{' '}
						<b>
							{storeCartDetails.cost.totalAmount.currencyCode}{' '}
							{productPrice(storeCartDetails.cost.totalAmount.amount)}
						</b>{' '}
						| Items in Cart: <b>{getTotalQuantity(storeCartDetails)}</b>
					</p>
					<p className='timeline caption'>{timeStamp}</p>
				</div>
				{storeCartDetails.lines.edges.length > 0 &&
					getcartCollections(storeCartDetails).map((cart: any) => {
						return (
							<div className='cart-products'>
								<div className='product-details-container'>
									{/* <img src={cart.resourceURL || ''} alt=''/> */}
									<div className='product-details-inner-container'>
										<p className='product-name body1'>{cart.title}</p>
										<p className='product-properties body2'>
											{cart.description}
										</p>
										<p className='timeline caption'>{timeStamp}</p>
									</div>
								</div>
								<button
									className='modify-product'
									onClick={() => {
										modifyProduct(
											cart.cartId,
											cart.productVariantId,
											cart.quantity,
											cart.title
										);
									}}>
									<p className='button-text'>Modify {cart.title}</p>
								</button>
								<button
									className='remove-product'
									onClick={() => {
										removeProductId(
											cart.cartId,
											cart.productVariantId,
											cart.title
										);
									}}>
									<p className='button-text'>Remove {cart.title}</p>
								</button>
							</div>
						);
					})}

				<div className='next-action-response'>
					<p className='next-action-text body1'>
						Please select how would you like to continue
					</p>
					<p className='timeline caption'>{timeStamp}</p>
				</div>
				<button
					className='checkout-product'
					onClick={() => checkoutProduct(data)}>
					<p className='button-text'>Checkout</p>
				</button>
				<button
					className='continue-shopping'
					onClick={() => continueShopping()}>
					<p className='button-text'>Continue Shopping</p>
				</button>
			</div>
		)
	);
};
export default ViewCart;
