import React, { useEffect, useState } from 'react';
import {
	CHATBUBBLEDIV,
	CHATBUBBLEEXDIV,
	FORMS,
} from '../../../../styles/EOW/Body/body.styled';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { BOT_ICONS } from '../../../../CatalogScreen/AddNewBot';
import PreviewQuicklinks from './PreviewQuicklinks';
import Input from '../../../../../Components/Input';
import { useLocation } from 'react-router-dom';
import Checkbox from '../../../../../Components/Checkbox';
import Radio from '../../../../../Components/Radio';
import NumberInput from '../../../../../Components/NumberInput';
import Button from '../../../../../Components/Button';
import AutoComplete from '../../../../../Components/AutoComplete';

type Props = {
	by: 'user' | 'ai';
	message: string;
	forms?: boolean;
	workflow?: boolean;
	propmt?: boolean;
	showSelectedColor?: boolean
	widget?:string;
};

const ChatBubble = ({
	message,
	by,
	forms = false,
	workflow = false,
	propmt = false,
	showSelectedColor = false,
	widget 
}: Props) => {
	const {
		avatarImage,
		colorMode,
		colorScale,
		selectedColor,
		inputFieldsData,
		checkList,
		ratCheckList,
		bookAptCheckList,
		ratCollectionPrompt,
		bookAptCollectionPrompt,
		generateLeadsCollectionPrompt,
		liveChatLeadsCollectionPrompt,
		generateLeadsname,
		generateLeadsEmail,
		generateLeadsCountry,
		generateLeadsCompany,
		generateLeadsPhonenumber,
		bookAptname,
		bookAptCompany,
		bookAptDate,
		bookAptCountry,
		bookAptEmail,
		bookAptPhonenumber,
		bookAptTime,
		ratname,
		ratPhonenumber,
		ratEmail,
		fontFamily,
		ratIssuedescription,
		liveChatcheckList,
		liveChatLeadsCompany,
		liveChatLeadsCountry,
		liveChatLeadsEmail,
		liveChatLeadsPhonenumber,
		liveChatLeadsname,
		additionalsettingMandatoryLeads,
	} = useSelector((state: RootState) => state.experienceonweb);

	const [inputData, setInputData] = useState<string[]>([]);
	const location = useLocation();

	useEffect(() => {
		let data = inputFieldsData.filter((v, i) => i % 2 === 0 && v.length > 0);
		setInputData(data);
	}, [inputFieldsData]);

	const thumbsUp = (showSelectedColor?: boolean) => {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				data-name='thumb_up_black_24dp (1)'
				width='16'
				height='16'
				viewBox='0 0 16 16'>
				<path
					data-name='Path 15575'
					d='M0 0h16v16H0zm0 0h16v16H0z'
					fill='none'
				/>
				<path
					data-name='Path 15576'
					d='M6.333 14.333h6a1.324 1.324 0 0 0 1.227-.813l2.013-4.7a1.317 1.317 0 0 0 .093-.487V7a1.337 1.337 0 0 0-1.333-1.333h-4.206l.633-3.047.02-.213a1 1 0 0 0-.293-.707L9.78 1 5.387 5.393a1.325 1.325 0 0 0-.387.94V13a1.337 1.337 0 0 0 1.333 1.333zm0-8L9.227 3.44 8.333 7h6v1.333l-2 4.667h-6zM1 6.333h2.667v8H1z'
					transform='translate(-.333 -.333)'
					fill={showSelectedColor ? selectedColor : 'rgba(0,0,0,.6)'}
				/>
			</svg>
		);
	};

	const thumbsDown = (showSelectedColor?: boolean) => {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='16'
				height='16'
				viewBox='0 0 16 16'>
				<path
					data-name='Path 15573'
					d='M0 0h16v16H0zm0 0h16v16H0z'
					fill='none'
				/>
				<path
					data-name='Path 15574'
					d='M10.333 3h-6a1.324 1.324 0 0 0-1.227.813l-2.013 4.7A1.317 1.317 0 0 0 1 9v1.333a1.337 1.337 0 0 0 1.333 1.333H6.54l-.633 3.047-.02.213a1 1 0 0 0 .293.707l.707.7 4.393-4.393a1.325 1.325 0 0 0 .387-.94V4.333A1.337 1.337 0 0 0 10.333 3zm0 8L7.44 13.893l.893-3.56h-6V9l2-4.667h6zM13 3h2.667v8H13z'
					transform='translate(-.333 -1)'
					fill={showSelectedColor ? selectedColor : 'rgba(0,0,0,.6)'}
				/>
			</svg>
		);
	};

	const GenerateLeadsworkflowPicker = (name: string) => {
		if (name === 'name') {
			return generateLeadsname[0];
		} else if (name === 'phone number') {
			return generateLeadsPhonenumber[0];
		} else if (name === 'company') {
			return generateLeadsCompany[0];
		} else if (name === 'country') {
			return generateLeadsCountry[0];
		} else if (name === 'email') {
			return generateLeadsEmail[0];
		}
		return true;
	};
	const LiveChatLeadsworkflowPicker = (name: string) => {
		if (name === 'Name') {
			return liveChatLeadsname[0] ? 'True' : 'False';
		} else if (name.trim().toLowerCase() === 'phone number') {
			return liveChatLeadsPhonenumber[0] ? 'True' : 'False';
		} else if (name === 'company') {
			return liveChatLeadsCompany[0] ? 'True' : 'False';
		} else if (name === 'Country') {
			return liveChatLeadsCountry[0] ? 'True' : 'False';
		} else if (name === 'Email') {
			return liveChatLeadsEmail[0] ? 'True' : 'False';
		}
		return 'True';
	};
	const BookAptworkflowPicker = (name: string) => {
		if (name === 'name') {
			return bookAptname[0];
		} else if (name === 'phone number') {
			return bookAptPhonenumber[0];
		} else if (name === 'company') {
			return bookAptCompany[0];
		} else if (name === 'country') {
			return bookAptCountry[0];
		} else if (name === 'appointment date') {
			return bookAptDate[0];
		} else if (name === 'appointment time') {
			return bookAptTime[0];
		} else if (name === 'email') {
			return bookAptEmail[0];
		}
		return true;
	};

	const RatworkflowPicker = (name: string) => {
		if (name === 'name') {
			return ratname[0];
		} else if (name === 'email') {
			return ratEmail[0];
		} else if (name === 'phone number') {
			return ratPhonenumber[0];
		} else if (name === 'issue description') {
			return ratIssuedescription[0];
		}
		return true;
	};
	return (
		<CHATBUBBLEEXDIV
			className={`${by} ${forms ? 'forms' : ''} ${workflow ? 'workflow' : ''} ${widget}`}>
			<div className={`${by}-holder`}>
				{by === 'ai' && forms && message !== 'forms' ? (
					<img
						src={avatarImage.length > 0 ? avatarImage : BOT_ICONS[0]}
						alt=''
					/>
				) : null}
				{forms && message === 'forms' ? (
					<>
						<CHATBUBBLEDIV
							className={`ff-ai-preview-chatbubble forms ${workflow ? 'workflow' : ''
								}`}
							colorScale={colorScale}
							mode={colorMode}
							selectedColor={selectedColor}>
							<FORMS
								colorScale={colorScale}
								mode={colorMode}
								selectedColor={selectedColor}>
								{location.pathname.indexOf('generate-leads') > -1
									? checkList.map((list) => {
										return (
											<>
												{list.title !== 'Custom Field' ? (
													<>
														{GenerateLeadsworkflowPicker(
															list.title.trim().toLowerCase()
														) ? (
															<>
																<p className='body2'>{list.title}</p>
																<Input fullWidth value='' />
															</>
														) : null}
													</>
												) : (
													<>
														{list.data.fieldName ? (
															<>
																<p className='body2'>{list.data.fieldName}</p>
																<p className='caption'>{list.data.desc}</p>
																{list.data.fieldType.label === 'Text' ? (
																	<Input fullWidth value='' />
																) : null}
																{list.data.fieldType.label === 'Date' ? (
																	<Input
																		fullWidth
																		type='date'
																		value=''
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15612'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15613'
																	// 			d='M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Time' ? (
																	<Input
																		fullWidth
																		value=''
																		type='time'
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15609'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15610'
																	// 			d='M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15611'
																	// 			d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Number' ? (
																	<Input fullWidth value='' />
																) : null}

																{list.data.fieldType.label ===
																	'Multiple Select'
																	? list.data.options.map((val: any) => (
																		<Checkbox
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={[false]}
																			index={0}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label ===
																	'Multiple Choice'
																	? list.data.options.map((val: any) => (
																		<Radio
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={'false'}
																			name={'choice'}
																			value={''}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label === 'Drop Down' ? (
																	<>
																		<AutoComplete
																			multiple={false}
																			// label=''
																			options={list.data.options.map(
																				(opt: any) => {
																					return {
																						label: opt.name,
																						value: opt.name,
																					};
																				}
																			)}
																			valueKey='value'
																			labelKey='label'
																			value={{
																				label: list.data.options[0].name,
																				value: list.data.options[0].name,
																			}}
																			onChange={() => console.log('')}
																		/>
																	</>
																) : null}
															</>
														) : null}
													</>
												)}
											</>
										);
									})
									: null}
								{location.pathname.indexOf('book-appointment') > -1
									? bookAptCheckList.map((list) => {
										return (
											<>
												{list.title !== 'Custom Field' ? (
													<>
														{BookAptworkflowPicker(
															list.title.trim().toLowerCase()
														) ? (
															<>
																<p className='body2'>{list.title}</p>
																<Input fullWidth value='' />
															</>
														) : null}
													</>
												) : (
													<>
														{list.data.fieldName ? (
															<>
																<p className='body2'>{list.data.fieldName}</p>
																<p className='caption'>{list.data.desc}</p>
																{list.data.fieldType.label === 'Text' ? (
																	<Input fullWidth value='' />
																) : null}
																{list.data.fieldType.label === 'Date' ? (
																	<Input
																		fullWidth
																		value=''
																		type='date'
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15612'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15613'
																	// 			d='M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Time' ? (
																	<Input
																		fullWidth
																		value=''
																		type='time'
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15609'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15610'
																	// 			d='M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15611'
																	// 			d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Number' ? (
																	<Input fullWidth value='' />
																) : null}
																{list.data.fieldType.label ===
																	'Multiple Select'
																	? list.data.options.map((val: any) => (
																		<Checkbox
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={[false]}
																			index={0}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label ===
																	'Multiple Choice'
																	? list.data.options.map((val: any) => (
																		<Radio
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={'false'}
																			name={'choice'}
																			value={''}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label === 'Drop Down' ? (
																	<>
																		<AutoComplete
																			multiple={false}
																			// label=''
																			options={list.data.options.map(
																				(opt: any) => {
																					return {
																						label: opt.name,
																						value: opt.name,
																					};
																				}
																			)}
																			valueKey='value'
																			labelKey='label'
																			value={{
																				label: list.data.options[0].name,
																				value: list.data.options[0].name,
																			}}
																			onChange={() => console.log('')}
																		/>
																	</>
																) : null}
															</>
														) : null}
													</>
												)}
											</>
										);
									})
									: null}
								{location.pathname.indexOf('raise-a-ticket') > -1
									? ratCheckList.map((list) => {
										return (
											<>
												{list.title !== 'Custom Field' ? (
													<>
														{RatworkflowPicker(
															list.title.trim().toLowerCase()
														) ? (
															<>
																<p className='body2'>{list.title}</p>
																<Input fullWidth value='' />
															</>
														) : null}
													</>
												) : (
													<>
														{list.data.fieldName ? (
															<>
																<p className='body2'>{list.data.fieldName}</p>
																<p className='caption'>{list.data.desc}</p>
																{list.data.fieldType.label === 'Text' ? (
																	<Input value='' />
																) : null}
																{list.data.fieldType.label === 'Date' ? (
																	<Input
																		value=''
																		type='date'
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15612'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15613'
																	// 			d='M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Time' ? (
																	<Input
																		value=''
																		type='time'
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15609'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15610'
																	// 			d='M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15611'
																	// 			d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Number' ? (
																	<Input value='' />
																) : null}
																{list.data.fieldType.label ===
																	'Multiple Select'
																	? list.data.options.map((val: any) => (
																		<Checkbox
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={[false]}
																			index={0}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label ===
																	'Multiple Choice'
																	? list.data.options.map((val: any) => (
																		<Radio
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={'false'}
																			name={'choice'}
																			value={''}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label === 'Drop Down' ? (
																	<>
																		<AutoComplete
																			multiple={false}
																			// label=''
																			options={list.data.options.map(
																				(opt: any) => {
																					return {
																						label: opt.name,
																						value: opt.name,
																					};
																				}
																			)}
																			valueKey='value'
																			labelKey='label'
																			value={{
																				label: list.data.options[0].name,
																				value: list.data.options[0].name,
																			}}
																			onChange={() => console.log('')}
																		/>
																	</>
																) : null}
															</>
														) : null}
													</>
												)}
											</>
										);
									})
									: null}

								{location.pathname.indexOf('livechat-leads') > -1
									? liveChatcheckList.map((list) => {
										return (
											<>
												{list.title !== 'Custom Field' ? (
													<>
														{LiveChatLeadsworkflowPicker(
															list.title.trim().toLowerCase()
														) ? (
															<>
																<p className='body2'>{list.title}</p>
																<Input fullWidth value='' />
															</>
														) : null}
													</>
												) : (
													<>
														{list.data.fieldName ? (
															<>
																<p className='body2'>{list.data.fieldName}</p>
																<p className='caption'>{list.data.desc}</p>
																{list.data.fieldType.label === 'Text' ? (
																	<Input value='' />
																) : null}
																{list.data.fieldType.label === 'Date' ? (
																	<Input
																		value=''
																		type='date'
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15612'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15613'
																	// 			d='M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Time' ? (
																	<Input
																		value=''
																		type='time'
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15609'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15610'
																	// 			d='M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15611'
																	// 			d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Number' ? (
																	<Input value='' />
																) : null}
																{list.data.fieldType.label ===
																	'Multiple Select'
																	? list.data.options.map((val: any) => (
																		<Checkbox
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={[false]}
																			index={0}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label ===
																	'Multiple Choice'
																	? list.data.options.map((val: any) => (
																		<Radio
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={'false'}
																			name={'choice'}
																			value={''}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label === 'Drop Down' ? (
																	<>
																		<AutoComplete
																			multiple={false}
																			// label=''
																			options={list.data.options.map(
																				(opt: any) => {
																					return {
																						label: opt.name,
																						value: opt.name,
																					};
																				}
																			)}
																			valueKey='value'
																			labelKey='label'
																			value={{
																				label: list.data.options[0].name,
																				value: list.data.options[0].name,
																			}}
																			onChange={() => console.log('')}
																		/>
																	</>
																) : null}
															</>
														) : null}
													</>
												)}
											</>
										);
									})
									: null}
								<div className='button-holder-submit-cancel'>
									<Button fullWidth>Submit</Button>
									<Button fullWidth>Cancel</Button>
								</div>
							</FORMS>
						</CHATBUBBLEDIV>
					</>
				) : (
					<CHATBUBBLEDIV
						className={`ff-ai-preview-chatbubble ${by} ${widget}`}
						colorScale={colorScale}
						mode={colorMode}
						selectedColor={selectedColor}>
						{location.pathname.indexOf('generate-leads') > -1 &&
							message === 'generateLeadsCollectionPrompt' ? (
							<p className='body2'>{generateLeadsCollectionPrompt}</p>
						) : location.pathname.indexOf('book-appointment') > -1 &&
							message === 'bookAptCollectionPrompt' ? (
							<p className='body2'>{bookAptCollectionPrompt}</p>
						) : location.pathname.indexOf('livechat-leads') > -1 &&
							message === 'liveChatLeadsCollectionPrompt' ? (
							<p className='body2'>{liveChatLeadsCollectionPrompt}</p>
						) :

							(location.pathname.indexOf('livechat-leads') > -1 &&
								message === 'ratCollectionPrompt' ? (
								<p className='body2'>{ratCollectionPrompt}</p>
							) :
								<p className='body2' style={{ fontSize: ' 0.875em', fontFamily: fontFamily.value }}>{message}</p>
							)}
					</CHATBUBBLEDIV>
				)}
			</div>
			{by === 'ai' &&
				forms &&
				message !== 'Please share the following details' &&
				message !== 'forms' &&
				!workflow &&
				!propmt &&
				inputData.length > 0 ? (
				<div className='quicklinks'>
					<PreviewQuicklinks lists={inputData} />
				</div>
			) : null}
			<div
				className={`time-feedback ${forms && message === 'Please share the following details'
					? 'forms'
					: ''
					} ${workflow ? 'workflow' : ''}`}>
				{message === 'Please share the following details' || message === 'Great! Here are few products that can help you to liven up your home while suiting your practical needs.' ||
					(location.pathname.indexOf('generate-leads') > -1
						? message === generateLeadsCollectionPrompt
						: location.pathname.indexOf('book-appointment') > -1
							? message === bookAptCollectionPrompt
							: location.pathname.indexOf('raise-a-ticket') > -1
								? message === ratCollectionPrompt
								: location.pathname.indexOf('livechat-leads') > -1 && additionalsettingMandatoryLeads[0]
									? message === liveChatLeadsCollectionPrompt : false) ? null : (
					location.pathname.indexOf('livechat-leads') > -1 && !additionalsettingMandatoryLeads[0] ?
						null
						: <p className='timestamp' style={{ fontSize: ' 0.625em', fontFamily: fontFamily.value }}>10:00 AM</p>
				)}
				{by === 'ai' && !propmt && message !== 'Great! Here are few products that can help you to liven up your home while suiting your practical needs.' && (location.pathname.indexOf('livechat-leads') <= -1) ? (
					<div>
						{thumbsUp(showSelectedColor)}
						{thumbsDown(showSelectedColor)}
					</div>
				) : (location.pathname.indexOf('livechat-leads') > -1 && additionalsettingMandatoryLeads[0]) ?
					<div>
						{thumbsUp(showSelectedColor)}
						{thumbsDown(showSelectedColor)}
					</div>
					: null
				}
			</div>
		</CHATBUBBLEEXDIV>
	);
};

export default ChatBubble;
