import { useState } from 'react';
import BasicDateRangePicker from '../DateRangePicker';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import './Dropdown.scss';
import { DropdownProps } from '../model';
import { format } from 'date-fns';
import { CalendarIcon } from '../../../../assets/Icons';

const Dropdown = ({
	optionSelected,
	setOptionSelected,
	setDates,
	dates,
}: DropdownProps) => {
	const [open, setOpen] = useState<boolean>(false);
	const [openCalendar, setOpenCalendar] = useState<boolean>(false);
	const [customDate, setCustomDate] = useState<string>('Custom');

	const onClickP = (event: any) => {
		event.stopPropagation();

		setOpen(true);
		if (event.target.parentNode.parentNode.tagName === 'LI') {
			setOpenCalendar(!openCalendar);
		}
	};

	const pickerValuetoString = (value: Date[]) => {
		const startDate = format(value[0], 'yyyy-MM-dd 00:00:00');
		const endDate = format(value[1], 'yyyy-MM-dd 23:59:59');
		setDates([startDate, endDate]);
		setOptionSelected('Custom');
		setCustomDate(`${format(value[0], 'PP')} - ${format(value[1], 'PP')}`);
	};

	const onSelectChange = (event: SelectChangeEvent) => {
		if (event.target.value !== 'Custom') {
			setOpen(false);
			setOpenCalendar(false);
			setOptionSelected(event.target.value);
			setCustomDate('Custom');
		}
	};
	return (
		<div className='dropdown-container1'>
			<Select
				// sx={{ width: '311px' }}
				value={optionSelected}
				className='dropdown1'
				open={open}
				onClick={() => setOpen(!open)}
				onClose={() => setOpen(false)}
				onChange={onSelectChange}>
				<MenuItem value='Today'>
					<p className='menu-item body1'>
						<CalendarIcon />
						Today
					</p>
				</MenuItem>
				<MenuItem value='Yesterday'>
					<p className='menu-item body1'>
						<CalendarIcon /> Yesterday
					</p>
				</MenuItem>
				<MenuItem value='Last 7 days'>
					<p className='menu-item body1'>
						<CalendarIcon />
						Last 7 days
					</p>
				</MenuItem>
				<MenuItem value='Last 30 days'>
					<p className='menu-item body1'>
						<CalendarIcon />
						Last 30 days
					</p>
				</MenuItem>
				<MenuItem value='Custom' disableRipple>
					<div
						className='custom-date-range-select'
						onClick={(event) => {
							event.stopPropagation();
						}}>
						<p className='menu-item body1' onClick={onClickP}>
							<CalendarIcon />
							{customDate}
						</p>
						<div className={`picker1 ${!openCalendar && 'closed'}`}>
							<BasicDateRangePicker
								onClose={() => setOpen(false)}
								onChange={(date) => pickerValuetoString(date)}
								dates={
									optionSelected === 'Custom'
										? dates
										: [new Date().toString(), new Date().toString()]
								}
							/>
						</div>
					</div>
				</MenuItem>
			</Select>
		</div>
	);
};

export default Dropdown;
