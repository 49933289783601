type Props = {
	width?: string;
	height?: string;
	fill?: string;
	color?: string;
};

type Props2 = {
	width?: string;
	height?: string;
	fill?: string;
	color?: string;
	rotate?: boolean;
};
const WhatsAppSVG = (props: Props) => {
	return (
		<svg
			data-name='Group 28984'
			xmlns='http://www.w3.org/2000/svg'
			width='44'
			height='44'
			viewBox='0 0 44 44'
			{...props}>
			<defs>
				<clipPath id='oaj5z6o01a'>
					<path
						data-name='Rectangle 6756'
						fill='currentColor'
						d='M0 0h44v44H0z'
					/>
				</clipPath>
			</defs>
			<g data-name='Group 16629' clipPath='url(#oaj5z6o01a)'>
				<path
					data-name='Path 5980'
					d='M43.405 16.871A21.909 21.909 0 1 0 2.8 32.305a1.194 1.194 0 0 1 .1.984Q1.482 38.4.086 43.5c-.151.556-.151.556.407.417 3.548-.926 7.1-1.847 10.645-2.785a1.058 1.058 0 0 1 .8.087 21.925 21.925 0 0 0 31.47-24.351M25.053 39.756A14.938 14.938 0 0 1 22.54 40a18.486 18.486 0 0 1-9.9-2.611 1.313 1.313 0 0 0-1.129-.162c-1.943.533-3.9 1.007-5.852 1.523-.407.127-.448.041-.343-.33.518-1.847 1-3.676 1.536-5.5a1.5 1.5 0 0 0-.2-1.361 17.808 17.808 0 0 1 .646-20.315c4.659-6.166 10.983-8.7 18.556-7.081 7.463 1.563 12.222 6.357 13.967 13.744a18.108 18.108 0 0 1-14.768 21.849'
					transform='translate(.001 .003)'
					fillRule='evenodd'
					fill='currentColor'
				/>
				<path
					data-name='Path 5981'
					d='M18.7 22.634a7.266 7.266 0 0 1-2.28-.347 22.165 22.165 0 0 1-11.859-8.708A12.57 12.57 0 0 1 2 8.479a6.1 6.1 0 0 1 1.989-5.9 3.592 3.592 0 0 1 2.909-.405 1.143 1.143 0 0 1 .518.579c.634 1.511 1.262 3 1.885 4.51a1.033 1.033 0 0 1-.064.857 6.827 6.827 0 0 1-1.355 1.8.891.891 0 0 0-.122 1.251 15.056 15.056 0 0 0 7.463 6.386.9.9 0 0 0 1.163-.284c.535-.66 1.1-1.314 1.611-2a.889.889 0 0 1 1.239-.324c1.065.481 2.123.984 3.194 1.482.233.1.471.226.71.33 1.076.544 1.065.556.971 1.743-.18 2.31-1.92 3.451-3.99 4.018a4.28 4.28 0 0 1-1.414.11'
					transform='translate(9.028 9.691)'
					fillRule='evenodd'
					fill='currentColor'
				/>
			</g>
		</svg>
	);
};
export default WhatsAppSVG;

export const ChevronRight = (props: Props2) => {
	return (
		<svg
			style={{ transform: `${props.rotate ? 'rotate(180deg)' : ''}` }}
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : 24}
			height={props.height ? props.height : 24}
			viewBox='0 0 24 24'>
			<g data-name='chevron_right_black_24dp (9)'>
				<path data-name='Path 8108' d='M24 0H0v24h24z' fill='none' />
				<path
					data-name='Path 8109'
					d='M14.59 6 16 7.41 11.42 12 16 16.59 14.59 18l-6-6z'
					transform='translate(-.59)'
					fill='inherit'
				/>
			</g>
		</svg>
	);
};
