import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Modal from '../../../Components/Modal';
import TextBlock from '../../../Components/TextBlock';
import { setSnackModal } from '../../../Features/message/messageSlice';
import { loadState } from '../../../utils/utils';
import './GetLink.scss';

type GetLinkProps = {
	onClose: () => void;
};

const GetLink = ({ onClose }: GetLinkProps) => {
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const dispatch = useAppDispatch();
	const campaignlink = `${
		window.location.origin
	}/Popup/responsive.html?botID=${loadState('botId')}&tenantID=${tenantId}`;

	const handleCopy = () => {
		navigator.clipboard.writeText(campaignlink);

		dispatch(
			setSnackModal({
				openModal: true,
				modalMesssage: 'Link Copied',
			})
		);
	};

	return (
		<Modal
			headerCloseBtn
			onClose={onClose}
			header='Web Link'
			className='ff-get-link-modal'
			actionButtons={
				<>
					<Button
						buttonType='light'
						onClick={() => window.open(campaignlink, '_blank')}>
						Test now
					</Button>
					<Button onClick={handleCopy}>Copy link</Button>
				</>
			}>
			<>
				<p className='body1 description'>
					This is the link for directly opening your AI inside a browser tab.
					Share this link with your audience through various channels such as
					online display ads, WhatsApp, email, SMS, QR codes etc. to get them
					conversing with your AI.
				</p>
				<TextBlock text={campaignlink} />
			</>
		</Modal>
	);
};
export default GetLink;
