import './ForgotPass.scss';
import flyfishlogo from './../../assets/images/logo.png';
import maleWithQuestions from './../../assets/images/male_with_questions.png';
import Button from '../../Components/Button';
import {
	BasicsTick,
	EmailSentTick,
	LoginButtonRightArrow,
} from '../../assets/Icons';
import { useEffect, useState } from 'react';
import { removeAllState } from '../../utils/utils';
import { reset } from '../../Features/auth/authSlice';
import authService from '../../Features/auth/authService';
import { ForgotPasswordType } from '../../Features/auth/model';
import { RootState, useAppDispatch } from '../../app/store';
import { Link, useNavigate } from 'react-router-dom';
import ForgotPasswordImage from '../../assets/images/landing_page/login-image.png';
import Input from '../../Components/Input';
import WelcomeImg from '../../assets/images/side_welcome.svg';
import { useSelector } from 'react-redux';

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [showLoader, setShowLoader] = useState(false);
	const [passwordLinkSent, setPasswordLinkSent] = useState(false);
	const [errorGroup, setErrorGroup] = useState({
		area: '',
		errorMessage: '',
	});
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { encUserId } = useSelector((state: RootState) => state.auth);
	useEffect(() => {
		removeAllState();
	}, []);

	const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(reset());
		setErrorGroup({
			area: '',
			errorMessage: '',
		});
		setEmail(event.target.value.toLowerCase().trim());
	};
	function isValidEmail(email: string) {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); //NOSONAR
	}

	async function sendForgotPasswordLink() {
		var data: ForgotPasswordType = {
			userEmail: btoa(email),
		};
		toggleLoader(true);
		try {
			const response = await authService.sendForgotPasswordLink(data);
			const userData = response.data;

			switch (response.status) {
				case 200:
					setPasswordLinkSent(true);
					toggleLoader(false);
					// navigate('/app/login');
					break;
				default:
					setPasswordLinkSent(false);
					toggleLoader(false);
					break;
			}
		} catch (error: any) {
			const response = error.response;
			switch (response.status) {
				case 400:
					setPasswordLinkSent(false);
					toggleLoader(false);
					setErrorGroup({
						area: 'email',
						errorMessage:
							'If the accounts exists, The link will be sent to your inbox.',
					});
					break;
				case 404:
					setPasswordLinkSent(false);
					toggleLoader(false);
					setErrorGroup({
						area: 'email',
						errorMessage: 'User not found',
					});
					break;
				case 401:
				case 409:
					setPasswordLinkSent(false);
					toggleLoader(false);
					setErrorGroup({
						area: 'email',
						errorMessage: 'Unable to send link.',
					});
					break;
				default:
					setErrorGroup({
						area: 'email',
						errorMessage: '',
					});
					setPasswordLinkSent(false);
					toggleLoader(false);
					break;
			}
		}
	}

	function toggleLoader(value: boolean) {
		setShowLoader(value);
	}

	return (
		<div className='ff-forgot-pass'>
			<div className='forget-left-container'>
				<img src={WelcomeImg} alt='' />
			</div>
			<div className='forget-right-container'>
				<div className={`form${passwordLinkSent ? ' email-sent-form' : ''}`}>
					{passwordLinkSent ? (
						<>
							<span className='email-sent-tick'>
								<EmailSentTick width='36px' height='36px' />
							</span>
							<h4>Email Sent</h4>
							<div className='input-description'>
								<div className='body2'>
									You would have received an email from us. Please follow the
									link to set a new password.
								</div>
							</div>
							<span className='back-to-login'>
								Didn't Receive the mail?
								<span
									className='click-btn'
									onClick={() => {
										setPasswordLinkSent(false);
										setErrorGroup({
											area: '',
											errorMessage: '',
										});
									}}>
									Try again
								</span>
							</span>
						</>
					) : (
						<>
							<h4>Reset Your Password</h4>
							<div className='input-description'>
								<div className='body2'>
									Please enter the email associated with your account so that we
									can send you the link for setting a new password.
								</div>
							</div>
							<div className='input-span'>
								<div className='input-container-signup'>
									{/* <input
									autoFocus
									type='email'
									value={email}
									autoComplete='false'
									className={errorGroup.area === 'email' ? 'errorBorder' : ''}
									onChange={handleUsername}
									placeholder='Email address'
								/> */}
									<div className='input-container'>
										<Input
											placeholder='someone@example.com'
											fullWidth
											type='email'
											limit={254}
											value={email}
											onChange={handleUsername}
											label='Email address'
											autoFocus
										/>
									</div>
								</div>

								{errorGroup.area === 'email' ? (
									<div className='input-description red'>
										{errorGroup.errorMessage}
									</div>
								) : (
									<></>
								)}

								<div className='button-container'>
									<Button
										fullWidth
										size='large'
										className='forgot-pass-btn'
										loading={showLoader}
										// endIcon={
										// 	showLoader || passwordLinkSent ? undefined : (
										// 		<span className='forgot-pass-icon'>
										// 			<LoginButtonRightArrow width='16px' />
										// 		</span>
										// 	)
										// }
										type='submit'
										onClick={sendForgotPasswordLink}
										disabled={!isValidEmail(email)}>
										Get password reset link
									</Button>
								</div>

								<span className='back-to-login'>
									<span className='caption'>Back to</span>
									<span className='click-btn caption'>
										<Link to='/app/login'> Sign In</Link>
									</span>
								</span>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
export default ForgotPassword;
