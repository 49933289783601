import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import Checkbox from '../../../../Components/Checkbox';
import ColorInput from '../../../../Components/ColorInput';
import {
	setAIMessageBubbleColor,
	setAIMessageTextColor,
	setButtonBckColor,
	setButtonTextAndOutlineColor,
	setButtonTextColor,
	setOptionsAndQuickLinksBckColor,
	setOptionsAndQuickLinksBckRange,
	setOptionsAndQuickLinksBorderColor,
	setOptionsAndQuickLinksBorderRange,
	setOptionsAndQuickLinksTextColor,
	setShowAvatar,
	setUserMessageBubbleColor,
	setUserMessageTextColor,
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import './OptionsAndButtons.scss';
import Slider from '../../../../assets/images/Slider.png';
import { updateBotdetails } from '../../../../Features/configure/configureSlice';

const OptionsAndButtons = () => {
	const {
		buttonBckColor,
		buttonTextAndOutlineColor,
		buttonTextColor,
		optionsAndQuickLinksBckColor,
		optionsAndQuickLinksBckRange,
		optionsAndQuickLinksBorderColor,
		optionsAndQuickLinksBorderRange,
		optionsAndQuickLinksTextColor,
	} = useSelector((state: RootState) => state.experienceonweb);
	const dispatch = useAppDispatch();

	return (
		<div className='ff-eow-options-and-buttons'>
			<div className='options-and-quicklinks'>
				<h5>Options & Quick Links</h5>
				<p className='body1'>Background color</p>
				<div className='color-input-with-opacity'>
					<div
						className='color-input-div'
						style={{
							backgroundColor: optionsAndQuickLinksBckColor,
						}}>
						<ColorInput
							className='color-input'
							type='color'
							value={optionsAndQuickLinksBckColor}
							onValueChange={(value) => {
								dispatch(setOptionsAndQuickLinksBckColor(value));
							}}
						/>
					</div>
					<div>
						<p className='body2'>Opacity</p>
						<div className='opacity'>
							<ColorInput
								className='range-input'
								style={{
									background: `linear-gradient(to left, ${optionsAndQuickLinksBckColor} 0%, white 100%), url(${Slider})`,
									backgroundBlendMode: 'multiply',
								}}
								type='range'
								value={optionsAndQuickLinksBckRange}
								min={0}
								max={100}
								onValueChange={(value) =>
									dispatch(setOptionsAndQuickLinksBckRange(value))
								}
							/>
							<ColorInput
								className='number-input'
								type='number'
								value={optionsAndQuickLinksBckRange}
								min={0}
								max={100}
								onValueChange={(value) =>
									dispatch(setOptionsAndQuickLinksBckRange(value))
								}
							/>
							<p>%</p>
						</div>
					</div>
				</div>
				<p className='body1'>Border color</p>

				<div className='color-input-with-opacity'>
					<div
						className='color-input-div'
						style={{
							backgroundColor: optionsAndQuickLinksBorderColor,
						}}>
						<ColorInput
							className='color-input'
							type='color'
							value={optionsAndQuickLinksBorderColor}
							onValueChange={(value) => {
								dispatch(setOptionsAndQuickLinksBorderColor(value));
							}}
						/>
					</div>
					<div>
						<p className='body2'>Opacity</p>
						<div className='opacity'>
							<ColorInput
								className='range-input'
								style={{
									background: `linear-gradient(to left, ${optionsAndQuickLinksBorderColor} 0%, white 100%), url(${Slider})`,
									backgroundBlendMode: 'multiply',
								}}
								type='range'
								value={optionsAndQuickLinksBorderRange}
								min={0}
								max={100}
								onValueChange={(value) =>
									dispatch(setOptionsAndQuickLinksBorderRange(value))
								}
							/>
							<ColorInput
								className='number-input'
								type='number'
								value={optionsAndQuickLinksBorderRange}
								min={0}
								max={100}
								onValueChange={(value) =>
									dispatch(setOptionsAndQuickLinksBorderRange(value))
								}
							/>
							<p>%</p>
						</div>
					</div>
				</div>
				<p className='body1 text-color'>Text color</p>
				<div
					className='color-input-div'
					style={{
						backgroundColor: optionsAndQuickLinksTextColor,
					}}>
					<ColorInput
						className='color-input'
						type='color'
						value={optionsAndQuickLinksTextColor}
						onValueChange={(value) => {
							dispatch(setOptionsAndQuickLinksTextColor(value));
						}}
					/>
				</div>
			</div>
			<div className='buttons'>
				<h5>Buttons</h5>
				<p className='body1'>Outlined button color</p>
				<div
					className='color-input-div'
					style={{
						backgroundColor: buttonTextAndOutlineColor,
					}}>
					<ColorInput
						className='color-input'
						type='color'
						value={buttonTextAndOutlineColor}
						onValueChange={(value) => {
							dispatch(setButtonTextAndOutlineColor(value));
						}}
					/>
				</div>
				<p className='body1 text-color'>Filled button background color</p>
				<div
					className='color-input-div'
					style={{
						backgroundColor: buttonBckColor,
					}}>
					<ColorInput
						className='color-input'
						type='color'
						value={buttonBckColor}
						onValueChange={(value) => {
							dispatch(setButtonBckColor(value));
						}}
					/>
				</div>
				<p className='body1 text-color'>Filled button text color</p>
				<div
					className='color-input-div'
					style={{
						backgroundColor: buttonTextColor,
					}}>
					<ColorInput
						className='color-input'
						type='color'
						value={buttonTextColor}
						onValueChange={(value) => {
							dispatch(setButtonTextColor(value));
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default OptionsAndButtons;
