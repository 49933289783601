import React, { useEffect, useState } from 'react';
import './Conversations.scss';
import AutoComplete from '../../../Components/AutoComplete';
import Table from '../../../Components/Table';
import Pagination from '../../../Components/Table/Pagination';
import {
	getTotalConversationalDatas,
	getWholeConversation,
	resetConversationIdStatus,
	setOpenConversation,
	setSearchedValue,
	setOffset,
	setOffsetval,
	setselectedDay,
} from '../../../Features/Analytics/analyticsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import WAChatBubble from '../../SalesAI/Whatsapp/WAChatBubble';
import InteractiveButton from '../../../Components/Button/InteractiveButton';
import { CloseIcon } from '../../BotBuilder/ChatBot/Icons';
import AIIcon from '../../../assets/images/bot-icons/botIcon1.png';
import { DownloadAnalyticDatas, DownloadIcon } from '../../../assets/Icons';
import Dropdown, {
	DropdownValue,
	DropdownValueProps,
} from '../../../Components/Dropdown';
import { getCurrentDateTime, loadState, saveState } from '../../../utils/utils';
import Menu from '../../../Components/Menu';
import Input from '../../../Components/Input';
import DataFetchAPI from '../../../utils/Api';
import { FFLoader } from '../../../assets/Icons/Loader';

const ConversationsTable = () => {
	const dispatch: any = useDispatch();
	const {
		selectedDates,
		chatConversationalData,
		analyticConversationData,
		isConversationIdValid,
		conSearchedValue,
		chatDataLoading,
		openConversation,
		totalCurrentChatLength,
		totalChatLength,
		offset,
		offsetval,
		selectedDay,
	} = useSelector((state: RootState) => state.analytics);
	const { bot_AI_ID } = useSelector((state: RootState) => state.teams);
	const [page, setPage] = useState(1);
	// const limit = 10;
	// const [offset, setOffset] = useState((page - 1) * limit);
	//const [offset, setOffset] = useState(0);
	//const [offsetval, setOffsetval] = useState(0);
	const LIMIT = 1;
	const [UpperL, setUpperL] = useState(0);
	const [LowerL, setLowerL] = useState(0);
	// const [openConversation, setOpenConversation] = useState('open');
	const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
	const [open, setOpen] = useState(false);
	const [downloadMedia, setDownloadMedia] = useState('');
	const botId = loadState('botId');
	var url = '';
	const [clickedData, storeClickedData] = useState('');
	const showAnalyticRows = [
		{
			label: 10,
			value: 10,
		},
		{
			label: 8,
			value: 8,
		},
		{
			label: 6,
			value: 6,
		},
	];
	const [countofRows, setCountOfRows] = useState<DropdownValue | any>(
		showAnalyticRows[0]
	);

	const getCurrentTime = (chatTime: string) => {
		const time =
			chatTime.length > 0
				? new Date(chatTime).toLocaleTimeString().split(':')
				: new Date().toLocaleTimeString().split(':');
		return (
			time[0].padStart(2, '0') +
			':' +
			time[1].padStart(2, '0') +
			' ' +
			time[2].substring(3, 5).toLowerCase()
		);
	};

	const columnTabData = [
		{
			key: 'Engagement ID',
			title: 'Engagement ID',
			width: 135,
			render: (row: any, i: any) => (
				<p className='body2 index'>
					{row.conversationId ? row.conversationId : '-'}
				</p>
			),
		},
		{
			key: 'Date & Time',
			title: 'Date & Time',
			width: 175,
			render: (row: any, i: any) => (
				<p className='body2 index'>
					{row.startTime ? getCurrentDateTime(row.startTime) : '-'}
				</p>
			),
		},
		{
			key: 'Channel',
			title: 'Channel',
			width: 175,
			render: (row: any, i: any) => (
				<p className='body2 index'>{row.channel ? row.channel : '-'}</p>
			),
		},
		{
			key: 'Lead Generation',
			title: 'Lead Generation',
			width: 175,
			render: (row: any, i: any) => (
				<p
					className={`body1 index ${
						row.leadGenerated && row.leadGenerated.toLowerCase() === 'done'
							? 'booked-data'
							: 'non-booked-data'
					}`}>
					{row.leadGenerated ? row.leadGenerated : 'NOT DONE'}
				</p>
			),
		},
		{
			key: 'Appointment Booking',
			title: 'Appointment Booking',
			width: 175,
			render: (row: any, i: any) => (
				<p
					className={`body1 index ${
						row.appointmentbooking &&
						row.appointmentbooking.toLowerCase() === 'booked'
							? 'booked-data'
							: 'non-booked-data'
					}`}>
					{row.appointmentbooking ? row.appointmentbooking : 'NOT BOOKED'}
				</p>
			),
		},
		{
			key: 'Ticketing',
			title: 'Ticketing',
			width: 175,
			render: (row: any, i: any) => (
				<p
					className={`body1 index ${
						(row.ticketing && row.ticketing.toLowerCase() === 'raised') ||
						row.ticketing.toLowerCase() === 'booked'
							? 'booked-data'
							: 'non-booked-data'
					}`}>
					{row.ticketing ? row.ticketing : '-'}
				</p>
			),
		},

		{
			key: 'Revenue',
			title: 'Revenue',
			width: 175,
			render: (row: any, i: any) => (
				<p className='body1 index'>
					{row.revenueAdded ? row.revenueAdded : '-'}
				</p>
			),
		},
		{
			key: 'Overall Rating',
			title: 'Overall Rating',
			width: 175,
			render: (row: any, i: any) => (
				<p className='body1 index'>{row.userRating ? row.userRating : '-'}</p>
			),
		},
		{
			key: 'User comment with rating',
			title: 'User comment with rating',
			width: 175,
			render: (row: any, i: any) => (
				<p className='body1 index'>{row.userComment ? row.userComment : '-'}</p>
			),
		},
		{
			key: 'Handoff to Human Expert',
			title: 'Handoff to Human Expert',
			width: 175,
			render: (row: any, i: any) => (
				<p
					className={`body1 index ${
						row.handoffTOExpert && row.handoffTOExpert.toLowerCase() === 'no'
							? 'non-booked-data'
							: 'booked-data'
					}`}>
					{row.handoffTOExpert ? row.handoffTOExpert.toUpperCase() : 'NO'}
				</p>
			),
		},
		{
			key: 'Dropoff Point',
			title: 'Dropoff Point',
			width: 175,
			render: (row: any, i: any) => (
				<p className='body1 index'>{row.dropOff ? row.dropOff : '-'}</p>
			),
		},
		{
			key: 'Country',
			title: 'Country',
			width: 175,
			render: (row: any, i: any) => (
				// <p className='body1 index'>{row.country ? row.country : '-'}</p>
				<p className='body1 index'>-</p>
			),
		},
	];

	const onchangeCount = (row: DropdownValue) => {
		let rowCount = row as DropdownValueProps;
		// console.log(rowCount)
		setCountOfRows(row);
	};

	// useEffect(() => {
	// 	setOffset((page - 1) * limit);
	// }, [page, limit]);

	useEffect(() => {
		if (totalCurrentChatLength.length > 0) {
			setUpperL(totalCurrentChatLength.length + offsetval);
			setLowerL(offsetval + 1);
		} else {
			setUpperL(0);
			setLowerL(0);
		}
	}, [totalCurrentChatLength]);
	useEffect(() => {
		if (totalCurrentChatLength.length > 0) {
			setUpperL(totalCurrentChatLength.length + offsetval);
			setLowerL(offsetval + 1);
		} else {
			setUpperL(0);
			setLowerL(0);
		}
	}, [offsetval]);

	useEffect(() => {
		if (selectedDay == 'reset') {
			dispatch(setOffsetval(0));
		}
	}, [selectedDay]);

	useEffect(() => {
		dispatch(
			getTotalConversationalDatas({
				startTime: selectedDates[0],
				endTime: selectedDates[1],
				botId: bot_AI_ID ? bot_AI_ID : botId,
				filter: '',
				page: selectedDay == 'reset' ? '0' : offset,
				size: 10,
			})
		);
		if (selectedDay == 'reset') {
			dispatch(setOffset(0));
			dispatch(setselectedDay(''));
		}
	}, [selectedDates, offset]);

	useEffect(() => {
		if (chatConversationalData && chatConversationalData.length > 0) {
			dispatch(setOpenConversation('open'));
		} else {
			dispatch(setOpenConversation('close'));
		}
	}, [chatConversationalData]);
	useEffect(() => {
		dispatch(setOpenConversation('open'));

		return () => {
			dispatch(setOpenConversation('close'));
		};
	}, []);
	useEffect(() => {
		setTimeout(() => {
			isConversationIdValid === 'valid' &&
				chatConversationalData &&
				totalChatLength > 0 &&
				chatConversationalData[0].conversationId &&
				dispatch(
					getWholeConversation({
						conversationID: chatConversationalData[0].conversationId,
						botId: bot_AI_ID ? bot_AI_ID : botId,
					})
				);
			dispatch(resetConversationIdStatus(''));
		}, 2000);
	}, [isConversationIdValid]);

	useEffect(() => {
		return () => {
			dispatch(setSearchedValue(''));
		};
	}, []);
	const searchCATableData = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			dispatch(
				getTotalConversationalDatas({
					startTime: selectedDates[0],
					endTime: selectedDates[1],
					botId: bot_AI_ID ? bot_AI_ID : botId,
					filter: conSearchedValue,
					page: offset,
					size: 10,
				})
			);
			dispatch(setOpenConversation('close'));
		}
	};
	const getTemplateFormat = () => {
		// DataFetchAPI()
		// 	.get(
		// 		`/analytics/bot/${bot_AI_ID ? bot_AI_ID : botId}/message/download?startTime=${selectedDates[0]}&endTime=${selectedDates[1]}`,
		// 		{
		// 			responseType: 'blob',
		// 		}
		// 	)
		// 	// .then((response) => {
		// 	// 	if (response.data) {
		// 	// 		return response.data;
		// 	// 	}
		// 	// })
		// 	// .then(blob => {
		// 	.then((response) => {
		// 		const contentDisposition = response.headers && response.headers.get('Content-Disposition');
		// 		const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+?)"/);
		// 		const filename = filenameMatch ? filenameMatch[1] : 'downloaded_file.xlsx';

		// 		return response.data.blob()
		// 	})
		// 	.then((blob) => {
		// 		const url = window.URL.createObjectURL(
		// 			new Blob([blob], { type: 'application/octetstream' })
		// 		);
		// 		setDownloadMedia(url);

		// 	});
		window.open(
			`${
				window.location.origin.indexOf('localhost:3000') > -1
					? 'https://dev.flyfish.ai'
					: window.location.origin
			}/analytics/bot/${
				bot_AI_ID ? bot_AI_ID : botId
			}/message/download?startTime=${selectedDates[0]}&endTime=${
				selectedDates[1]
			}`
		);
	};
	const DataDownloadApi = (url: any) => {
		DataFetchAPI()
			.get(`${url}`, {
				responseType: 'blob',
			})
			.then((response) => {
				if (response.data) {
					return response.data;
				}
			})
			.then((blob: any) => {
				const url = window.URL.createObjectURL(
					new Blob([blob], { type: 'application/octetstream' })
				);

				setDownloadMedia(url);
			});
	};
	useEffect(() => {
		if (chatConversationalData && chatConversationalData.length) {
			storeClickedData(chatConversationalData[0].channel);
		}
	}, [chatConversationalData]);
	return (
		<div className='ff-analytic-conversations-container'>
			{chatDataLoading ? (
				<div className='dataloader'>
					<p>Loading Data...</p>
					<FFLoader />
				</div>
			) : (
				<>
					<div className='analytic-conversations-content'>
						<div
							className={`conversations-table-content ${
								openConversation === 'open' &&
								analyticConversationData &&
								analyticConversationData.length > 0
									? 'chat-opened'
									: ''
							}`}>
							<div className='conversations-table-header'>
								{/* <div className='table-paginations'>
										<span className='page-naviagtors body2'> */}
								<Pagination
									disableforward={
										totalChatLength <= offsetval + totalCurrentChatLength.length
									}
									disableback={offsetval === 0}
									nextPage={() => {
										dispatch(setOffset(offset + LIMIT));
										dispatch(setOffsetval(offsetval + 10));
									}}
									previousPage={() => {
										dispatch(setOffset(offset - LIMIT));
										dispatch(setOffsetval(offsetval - 10));
									}}
									gotoBegining={() => {
										dispatch(setOffset(0));
										dispatch(setOffsetval(0));
									}}
									gotoLast={() => {
										if (totalChatLength % 10 == 0) {
											dispatch(
												setOffsetval(Math.floor(totalChatLength / 10) * 10 - 10)
											);
											dispatch(
												setOffset(Math.ceil(totalChatLength / 10) - 1 - 10)
											);
										} else {
											dispatch(
												setOffsetval(Math.floor(totalChatLength / 10) * 10)
											);
											dispatch(setOffset(Math.ceil(totalChatLength / 10) - 1));
										}
									}}
									totalCount={totalChatLength}
									upper={UpperL}
									lower={LowerL}
								/>
								{/* {chatConversationalData && chatConversationalData.length &&
												<span>{1} - {chatConversationalData && chatConversationalData.length} of {chatConversationalData && chatConversationalData.length}</span>
											} */}
								{/* </span>
									</div> */}
								<div className='ff-analytics-search-content-shown body2'>
									<span className='download-analytics-datas'>
										<a
											// href={
											// 	downloadMedia
											// }
											// download={
											// 	'analyticsData.xlsx'
											// }
											target='_blank'
											rel='noreferrer'>
											<InteractiveButton
												title='Download raw data'
												onClick={(event: any) => {
													// downloadCampaignsData(event)
													getTemplateFormat();
												}}
												icon={<DownloadAnalyticDatas />}
											/>
										</a>
									</span>

									{/* <div>
											<Input
												onKeyDown={
													searchCATableData
												}
												value={conSearchedValue}
												onChange={(e: any) => dispatch(setSearchedValue(e.target.value))}
												placeholder='Search by EngagementId'

												endIcon={
													<>
														{conSearchedValue ? (
															// searchLoader ? (
															// 	<div style={{ color: '#3b44c4' }}>
															// 		<FFLoader fill='#3b44c4' />
															// 	</div>
															// ) : (
															<svg
																style={{ cursor: 'pointer' }}
																onClick={() => {

																	dispatch(setSearchedValue(''));
																	dispatch(
																		getTotalConversationalDatas({
																			startTime: selectedDates[0],
																			endTime: selectedDates[1],
																			botId: bot_AI_ID ? bot_AI_ID : botId,
																			filter: "",
																			page: offset,
																			size: 10
																		})
																	);
																	dispatch(setOpenConversation('open'));

																}}
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'>
																<g data-name='Group 30351'>
																	<path
																		data-name='Path 13850'
																		d='M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41zM12 2a10 10 0 1 0 10 10A9.991 9.991 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.011 8.011 0 0 1-8 8z'
																		fill='rgba(0,0,0,.4)'
																	/>
																</g>
															</svg>
															// )
														) : (
															<svg
																style={{ cursor: 'pointer' }}
																onClick={() => {
																	dispatch(
																		getTotalConversationalDatas({
																			startTime: selectedDates[0],
																			endTime: selectedDates[1],
																			botId: bot_AI_ID ? bot_AI_ID : botId,
																			filter: conSearchedValue,
																			page: offset,
																			size: 10
																		})
																	);
																	dispatch(setOpenConversation('close'));
																	dispatch(setSearchedValue(''));

																}}
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'>
																<path
																	data-name='Path 2862'
																	d='M0 0h24v24H0z'
																	fill='none'
																/>
																<path
																	data-name='Path 2863'
																	d='M15.5 14h-.79l-.28-.27a6.51 6.51 0 1 0-.7.7l.27.28v.79l5 4.99L20.49 19zm-6 0A4.5 4.5 0 1 1 14 9.5 4.494 4.494 0 0 1 9.5 14z'
																	fill='rgba(0,0,0,.6)'
																/>
															</svg>
														)}
													</>
												}
											/>
										</div> */}
								</div>
							</div>
							<>
								{chatConversationalData && chatConversationalData.length > 0 ? (
									<div className='conversations-table-body-content'>
										<Table
											isLoading={false}
											column={columnTabData}
											data={
												chatConversationalData &&
												chatConversationalData.length > 0
													? chatConversationalData
													: []
											}
											page={offset / LIMIT}
											limit={LIMIT}
											totalCount={chatConversationalData.length}
											onRowClick={(row) => {
												dispatch(
													getWholeConversation({
														conversationID: row.conversationId,
														botId: bot_AI_ID ? bot_AI_ID : botId,
														path: '/chat',
													})
												);
												dispatch(setOpenConversation('open'));
												storeClickedData(row.channel);
											}}
											height={'calc(100vh - 225px)'}
											analyticsRowClick={true}
										/>
									</div>
								) : (
									<div className='analytics-no-data-found'>
										No data found for this selected date range
									</div>
								)}
							</>
						</div>
						{openConversation === 'open' &&
							analyticConversationData &&
							analyticConversationData.length > 0 && (
								<div
									className={`conversations-chat-data ${
										clickedData &&
										clickedData.toLowerCase().indexOf('whatsapp:') > -1
											? 'whatsap-header-analyse-chat'
											: 'web-header-analyse-chat'
									}`}>
									<div className='chat-data-header'>
										<div className='conversation-id'>
											<span>
												<img
													src={AIIcon}
													alt='chatbot-icon'
													style={{
														width: '30px',
														height: '30px',
													}}
												/>
											</span>
											<span>
												<span>
													<p className='body1 title'>My AI</p>
												</span>
												<p className='chat-transcriptid'>
													Session ID:
													{analyticConversationData &&
													analyticConversationData.length > 0 &&
													analyticConversationData[0].conversationId
														? analyticConversationData[0].conversationId
														: 'ASDIOP'}
												</p>
											</span>
										</div>
										<InteractiveButton
											title='Close'
											onClick={() => {
												dispatch(setOpenConversation('close'));
											}}
											icon={<CloseIcon />}
										/>
									</div>
									<div className='chat-data-body'>
										{analyticConversationData &&
											analyticConversationData.length > 0 &&
											analyticConversationData.map(
												(chat: any, index: number) => {
													return (
														<div className={`chat-messages ${chat.source}`}>
															<WAChatBubble
																timeStamp={chat.createdDate}
																key={index}
																msgtype={chat.source}
																message={chat.body}
																metadata={
																	chat.metaData ? chat.metaData : []
																	// chat.metaData.products &&
																	// chat.metaData.products.length > 0
																	// ? chat.metaData.products
																	// : chat.metaData.form &&
																	// 	chat.metaData.form.length > 0
																	// 	?
																	// 	chat.metaData.form
																	// 	: []
																}
																conversationId={
																	analyticConversationData[0].conversationId
																}
																noOfTimeCalled={index}
																feedback={chat.feedback}
																channel={
																	clickedData &&
																	clickedData
																		.toLowerCase()
																		.indexOf('whatsapp:') > -1
																		? 'whatsapp'
																		: 'web'
																}
															/>
														</div>
													);
												}
											)}
									</div>
								</div>
							)}
					</div>
				</>
			)}
		</div>
	);
};

export default ConversationsTable;
