import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Checkbox from '../../../Components/Checkbox';
import {
	createBotConfigurations,
	resetCreateBotConfigurationLiveChatLoader,
	setLiveChatEnable,
} from '../../../Features/configure/configureSlice';
import { reset, set } from '../../../Features/message/messageSlice';
import { loadState } from '../../../utils/utils';
import './LiveChatConfig.scss';

const LiveChatConfig = () => {
	const dispatch = useAppDispatch();
	const botDetails: any = loadState('botDetails');
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { liveChatEnable, createBotConfigurationLiveChatLoader } = useSelector(
		(state: RootState) => state.configure
	);
	const [buttonLoader, setButtonLoader] = useState(false);
	const location = useLocation();
	const path = location.pathname;

	useEffect(() => {
		if (createBotConfigurationLiveChatLoader === 'success') {
			dispatch(set('Configurations Saved Successfully'));
			setButtonLoader(false);
		} else if (createBotConfigurationLiveChatLoader === 'failed') {
			dispatch(set(`Couldn't Save !! Something Went Wrong`));
			setButtonLoader(false);
		} else if (createBotConfigurationLiveChatLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetCreateBotConfigurationLiveChatLoader());
	}, [createBotConfigurationLiveChatLoader]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);
	return (
		<div className='ff-live-chat-config-container'>
			<div className='liveChatEnable'>
				<div>
					<h6 className='header-font'>Enable Live Chat</h6>
					<p className='description-font body2'>
						Let your {path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}{' '}
						loop in a human agent during chat
					</p>
				</div>
				<div>
					<Checkbox
						isChecked={liveChatEnable}
						index={0}
						onValueChange={(value) => dispatch(setLiveChatEnable(value))}
					/>
				</div>
			</div>
			<div className='liveChatEnable-save-button-div'>
				<Button
					className='liveChat-save-button'
					loading={buttonLoader}
					onClick={() => {
						let data = {
							botId: botDetails.botId,
							tenantId: tenantId,
							handoffToAgent: liveChatEnable[0] ? 'true' : 'false',
						};
						dispatch(createBotConfigurations(data));
					}}>
					Save
				</Button>
			</div>
		</div>
	);
};

export default LiveChatConfig;
