import { chatBotData, chatData } from '../model';
import './ChatBubble.scss';
import FormattedText from '../FormattedText';
import { getCurrentTime, getTime } from '../../../utils/utils';
import { DisambArrow } from '../Icons';
import StoryMention from './Instagram/StoryMention';
import StoryReply from './Instagram/StoryReply';
import Media from '../../../Components/ChatComponents/Media';
import MultiChoice from '../../../Components/ChatComponents/MultiChoice';
import MultiSelect from '../../../Components/ChatComponents/MultiSelect';
import Ratings from '../../../Components/ChatComponents/Ratings';
import Forms from '../../../Components/ChatComponents/Forms';
import ConfirmCollectedValues from '../../../Components/ChatComponents/ConfirmCollectedValues';
import { RootState } from '../../../app/store';
import { useSelector } from 'react-redux';
import Quicklinks from '../../../Components/ChatComponents/Quicklinks';

type ChatBubbleProps = {
	msgObj: chatData;
	channel: string;
};

const ChatBubble = ({ msgObj, channel }: ChatBubbleProps) => {
	const { showQuickLinksOnError, showQuickLinks } = useSelector(
		(state: RootState) => state.configure
	);
	// const responseElements = (res: chatData) => {
	// 	if (res.isRatingTask && res.responseType.toLowerCase() === 'simple') {
	// 		return (
	// 			<Ratings
	// 				ratingTypeId={res.ratingTypeId}
	// 				text={res.displayResponse.trim()}
	// 			/>
	// 		);
	// 	}
	// 	if (
	// 		res.responseType.toLowerCase() === 'simple' &&
	// 		msgObj.nonComprehendFlag &&
	// 		showQuickLinksOnError[0] &&
	// 		showQuickLinks[0]
	// 	) {
	// 		return <Quicklinks />;
	// 	}
	// 	if (
	// 		res.responseType.toLowerCase() === 'list' &&
	// 		res.isRichElement &&
	// 		res.lists
	// 	) {
	// 		return (
	// 			<Media
	// 				mediaURL={res.lists.items[0].resourceURL}
	// 				source={msgObj.source.toLowerCase()}
	// 				channel={channel}
	// 				mimeType={res.lists.items[0].resourceType}
	// 				type={msgObj.type === '' ? 'null' : msgObj.type}
	// 			/>
	// 		);
	// 	}
	// if (
	// 	(res.responseType.toLowerCase() === 'list' ||
	// 		res.responseType.toLowerCase() === 'carousel') &&
	// 	res.listResponseType?.toLowerCase() === 'multichoice' &&
	// 	res.lists
	// ) {
	// 	return (
	// 		<>
	// 			<MultiChoice isRichElement={res.isRichElement} lists={res.lists} />
	// 		</>
	// 	);
	// }
	// 	if (
	// 		(res.responseType.toLowerCase() === 'list' ||
	// 			res.responseType.toLowerCase() === 'carousel') &&
	// 		res.listResponseType?.toLowerCase() === 'multiselect' &&
	// 		res.lists
	// 	) {
	// 		return (
	// 			<>
	// 				<MultiSelect
	// 					isRichElement={res.isRichElement}
	// 					lists={res.lists}
	// 					displayResponse={res.displayResponse.trim()}
	// 				/>
	// 			</>
	// 		);
	// 	}
	// 	if (res.responseType.toLowerCase() === 'table') {
	// 		return <ConfirmCollectedValues table={res.table} />;
	// 	}
	// };
	const responseElements = (response: chatData) => {

		let obj: any = [];
		response.metaData.products &&
			response.metaData.products.map((item: any, index: number) => {
				return obj.push({
					resourceURL: item.image,
					title: item.title,
					price: item.price,
					description: '',
					cardIndex: index.toString(),
					productId: item.product_id,
					currency: item.currency,
					resourceType: "image"
				});
			});
		let proudctList = {
			title: '',
			items: obj
		}
		let imageLinks: any = [];
		response.metaData.image_links &&
			response.metaData.image_links.map((item: any, index: number) => {
				return imageLinks.push({
					resourceURL: item,
					title: '',
					cardIndex: index.toString(),
					cardLayout: '8',
					resourceType: 'image'
				});
			});
		let imageList = {
			title: '',
			items: imageLinks
		}

		if (
			response.metaData.image_links &&
			(!response.metaData.products ||
				(response.metaData.products &&
					response.metaData.products.length == 0))
		) {
			if (imageLinks) {
				return (
					<>
						<MultiChoice isRichElement={true} lists={imageList} />
					</>
				);
			}
		}
		if (response.metaData.products &&
			response.metaData.products.length > 0) {

			return (
				<>
					<MultiChoice isRichElement={true} lists={proudctList} />
				</>
			);
		}
		if (response.metaData.form && response.body && response.body.length === 0) {

			return (
				<>
					<Forms data={response.metaData} />
					{/* <footer className='text-footer'>
						<span className='time timestamp'>
							{msgObj.createdDate
								? getCurrentTime(msgObj.createdDate)
								: null}
						</span>
					</footer> */}
				</>

			);
		}



		// }

	}

	const suggestionsElements = (response: chatData) => {
		let suggestionObj: any = [];
		response.suggestions &&
			response.suggestions.map((item: any) => {
				return suggestionObj.push({
					linkText: item,
					linkValue: item,
				});
			});


		return (
			<>
				<Quicklinks quickLinks={suggestionObj} />
			</>)

	}
	const userAgentMessages = () => {
		return (
			<>
				{msgObj.source?.toLowerCase() === 'user' ||
					msgObj.source?.toLowerCase() === 'agent' ? (
					<div
						className={`chat-bubble-container ${msgObj.source?.toLowerCase()}`}>

						<>
							<div className={`chat-bubble ${msgObj.source}`}>
								{/* {msgObj.source === '' ? ( */}
								<p className={`body2 message`}>
									{msgObj.body && msgObj.body.trim().length > 0 ?

										<FormattedText text={msgObj.body.trim()} /> :

										msgObj.metaData.form ? <div className='buttons-carousel'>
											{responseElements(msgObj)}
										</div>
											:
											null
									}
									{/* {msgObj.metaData ? (
											<div className='buttons-carousel'>
												{responseElements(msgObj)}
											</div>
										) : null} */}

								</p>

								{/* ) : null} */}
								{/* {msgObj.type === 'story_mention' &&
									channel === 'instagram' ? (
										<StoryMention
											mediaURL={msgObj.mediaMeta.mediaURL}
											mimeType={msgObj.mediaMeta.type}
											channel={channel}
											source={msgObj.source.toLowerCase()}
											type={msgObj.type}
										/>
									) : null} */}
								{/* {msgObj.type === 'story_replies' &&
									channel === 'instagram' ? (
										<StoryReply
											mediaURL={msgObj.mediaMeta.mediaURL}
											mimeType={msgObj.mediaMeta.type}
											channel={channel}
											source={msgObj.source.toLowerCase()}
											type={msgObj.type}
											message={msgObj.message}
										/>
									) : null} */}
								{/* {msgObj.type === 'image' ||
									msgObj.type === 'video' ||
									msgObj.type === 'audio' ? (
										<Media
											mediaURL={msgObj.mediaMeta.mediaURL}
											mimeType={msgObj.mediaMeta.type}
											channel={channel}
											source={msgObj.source.toLowerCase()}
											type={msgObj.type}
										/>
									) : null} */}
							</div>
							<footer className='text-footer'>
								<span className='time timestamp'>
									{msgObj.createdDate
										? getCurrentTime(msgObj.createdDate)
										: null}
								</span>
							</footer>
						</>

					</div>
				) : null}
			</>
		);
	};

	const botMessages = () => {
		return (
			<>
				{msgObj.source?.toLowerCase() === 'assistant' &&
					(msgObj.metaData.form || msgObj.body.trim().length > 0) ? (
					// &&
					// msgObj.disambQuestions?.length === 0 ?
					<>
						{/* {msgObj.responseElements === '' ? ( */}
						<>
							<div
								className={`chat-bubble-container ${msgObj.source?.toLowerCase()}`}>
								<div className={`chat-bubble`}>
									<p className={`body2 message`}>
										<FormattedText text={msgObj.body && msgObj.body.trim()} />
									</p>
								</div>
								{msgObj.metaData ? (
									<div className='buttons-carousel'>
										{responseElements(msgObj)}
									</div>
								) : null}

								{
									msgObj.suggestions ?
										<div className='buttons-carousel'>

											{suggestionsElements(msgObj)}
										</div>
										: null
								}

								<footer className='text-footer'>
									<span className='time timestamp bot'>
										{msgObj.createdDate
											? getCurrentTime(msgObj.createdDate)
											: null}
									</span>
								</footer>
							</div>
						</>
						{/* // ) : (
						// 	<>
						// 		{msgObj.responseElements &&
						// 			JSON.parse(msgObj.responseElements).map(
						// 				(res: chatBotData, i: number) => {
						// 					return (
						// 						<>
						// 							<div
						// 								className={`chat-bubble-container ${msgObj.source?.toLowerCase()} ${res.responseType.toLowerCase()}`}>
						// 								{res.isRatingTask ? null : (
						// 									<>
						// 										{(res.listResponseType &&
						// 											res.listResponseType.toLowerCase() ===
						// 												'multiselect' &&
						// 											res.responseType.toLowerCase() === 'list') ||
						// 										res.responseType.toLowerCase() === 'table' ||
						// 										(res.responseType.toLowerCase() === 'list' &&
						// 											res.isRichElement) ? null : (
						// 											<>
						// 												<div
						// 													className={`chat-bubble ${res.responseType.toLowerCase()} ${
						// 														msgObj.nonComprehendFlag
						// 													}`}>
						// 													<p className={`body2 message`}>
						// 														<FormattedText
						// 															text={res.displayResponse.trim()}
						// 														/>
						// 													</p>
						// 												</div>
						// 											</>
						// 										)}
						// 									</>
						// 								)}
						// 								<div className='buttons-carousel'>
						// 									{responseElements(res)}
						// 								</div>
						// 								{msgObj.responseElements &&
						// 								JSON.parse(msgObj.responseElements).length - 1 ===
						// 									i ? (
						// 									<footer className='text-footer'>
						// 										<span className='time timestamp bot'>
						// 											{msgObj.time ? getTime(msgObj.time) : null}
						// 										</span>
						// 									</footer>
						// 								) : null}
						// 							</div>
						// 						</>
						// 					);
						// 				}
						// 			)}
						// 	</>
						// )} */}
					</>
				) :
					// msgObj.metaData.form ?
					// 	<>
					// 		<div className='chat-bubble-container assistant'>
					// 			<div className='chat-bubble'>

					// 				{
					// 					responseElements(msgObj)
					// 				}
					// 			</div>
					// 		</div>
					// 	</>
					//	:
					null

				}
			</>
		);
	};

	// const disambQuestions = () => {
	// 	return (
	// 		<>
	// 			{msgObj.source?.toLowerCase() === 'bot' &&
	// 				msgObj.disambQuestions?.length &&
	// 				msgObj.disambQuestions.length > 0 &&
	// 				msgObj.message?.length ? (
	// 				<>
	// 					<div
	// 						className={`chat-bubble-container ${msgObj.source?.toLowerCase()}`}>
	// 						<div className='ff-disambquestions'>
	// 							<div className='disambquestions-holder'>
	// 								<p className={`body2 message`}>
	// 									<FormattedText text={msgObj.message.trim()} />
	// 								</p>
	// 								<hr />
	// 								{msgObj.disambQuestions.map((disamb: any, i) => (
	// 									<>
	// 										<div>
	// 											<DisambArrow />
	// 											<div className='disamb-queries body2'>
	// 												{disamb.displayText}
	// 											</div>
	// 										</div>
	// 										{msgObj.disambQuestions?.length &&
	// 											msgObj.disambQuestions.length - 1 === i ? null : (
	// 											<hr />
	// 										)}
	// 									</>
	// 								))}
	// 							</div>
	// 							<div className='overlay'></div>
	// 						</div>
	// 						<footer className='text-footer'>
	// 							<span className='time timestamp'>
	// 								{msgObj.time ? getTime(msgObj.time) : null}
	// 							</span>
	// 						</footer>
	// 					</div>
	// 				</>
	// 			) : null}
	// 		</>
	// 	);
	// };
	return (
		<>
			{/* Agent/User message display */}
			{userAgentMessages()}
			{/* Bot message display */}
			{botMessages()}
			{/* disambQuestions display */}
			{/* {disambQuestions()} */}
		</>
	);
};
export default ChatBubble;
