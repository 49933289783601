import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import ChatBubble from '../../AIPreview/ChatBubble';
import Product1 from '../../../../../assets/images/FullScreenCarousel/product1.png'
import Product2 from '../../../../../assets/images/FullScreenCarousel/product2.png'
import Product3 from '../../../../../assets/images/FullScreenCarousel/product3.png'
import Product4 from '../../../../../assets/images/FullScreenCarousel/product4.png'
import Product5 from '../../../../../assets/images/FullScreenCarousel/product5.png'
import Product6 from '../../../../../assets/images/FullScreenCarousel/product6.png'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import styled from '@emotion/styled';
import { StarFilled } from '../../../../ComponentsLibrary/Icons';
import { StarUnFilled } from '../../../../ComponentsLibrary/Icons';
import Button from '../../../../../Components/Button';
type EnhancedProps = {
theme:string
}
export const Star = () => {
	return <div style={{ display: 'flex' }}>
		{
			Array.from({ length: 5 }, (v, i) => {
				return (
					i != 4 ? <StarFilled /> : <StarUnFilled />
				)
			})
		}
	</div>

}
const ProductDisplayEnhanced = ({theme}:EnhancedProps) => {
	const {
		avatarImage,
		colorMode,
		colorScale,
		selectedColor,
		fontSize,
		fontFamily,
		selectedTheme,
		showOnHover,
		recommendationHeading,
		additionalRecommendation,
		selectedButton,
	
		
	} = useSelector((state: RootState) => state.experienceonweb);
	const {recommendationRational,complimentaryProducts}=useSelector((state: RootState) => state.verticalization);
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [selectedProduct, setSelectedProduct] = useState('')
	const [selectedImage, setSelectedImage] = useState(Product1)
	const [selectedProduct1, setSelectedProduct1] = useState(Product1)
	const [selectedProduct2, setSelectedProduct2] = useState(Product2)
	const [selectedProduct3, setSelectedProduct3] = useState(Product3)
	const [selectedVariant, setSelectedVariant] = useState('')
    const complementaryProducts=[
		{
			title:'Product 3',
			imageSrc:Product5

		},
		{
			title:'Product 4',
			imageSrc:Product6

		},
		{
			title:'Product 5',
			imageSrc:Product6

		}
	]
	type PRODUCTITEM = {
		selectedColor: string;
	};

	type VariantItem = {
		selectedColor: string;
		colorMode: string;
		colorScale: string[];
		fontFamily: string;
	};
	type VariantMode = {

		colorMode: string;

	};

	const Productitem = styled.div<PRODUCTITEM>`
	 border: ${(props) => `2px solid ${props.selectedColor};
	 padding: 4px 16px 4px 4px;
	 border-radius: 24px;`
		};	
	`

	const VariantsButtonMode = styled.div<VariantItem>`
	&.variants-btn{
		display:flex;
        margin-left: -6px;
		>.custom-button{
			width: 275px;
			margin: 0px 10px;
		}
		>.custom-button .MuiButton-root.MuiButton-sizeSmall{
		height: 38px;
        background-color: ${(props) => props.colorMode == 'dark' ? props.selectedColor : props.colorScale[2]};
		width:100%;
		margin-top: 22px;
		font-family:${(props) => props.fontFamily};
		>.custom-button-body{
			font-family:${(props) => props.fontFamily};
			font-size: 1em;
		}
	}

}`




	const VariantsButton = styled.div<VariantItem>`
	border: ${(props) => `2px solid  ${props.colorMode == 'dark' ? props.selectedColor : props.colorScale[2]};
	padding: 4px 16px 4px 4px;
	border-radius: 24px;
	width: fit-content;
	color:${props.colorMode == 'dark' ? props.selectedColor : props.colorScale[2]};
    padding: 4px 12px;
    border-radius: 20px;
	padding: 5px;
    margin: 10px 4px;
	padding: 5px 15px;
	pointer-events:none;
	font-size: 0.875em;
	font-family:${props.fontFamily};
	&.active-variants{
		background-color:${props.colorMode == 'dark' ? props.selectedColor : props.colorScale[2]};
		color:#fff;
	}
	`
		};`

	useEffect(() => {
		if (selectedIndex == 0) {
			setSelectedProduct('Product 1')
		}
		else if (selectedIndex == 1) {
			setSelectedProduct('Product 2')
		}
		else if (selectedIndex == 2) {
			setSelectedProduct('Product 3')
		}
	}, [selectedIndex])
	return (
		<div className='ff-configure-experience-on-web enhanced' style={{ fontSize: fontSize.value }}>
			<p className="body2" style={{marginLeft:'30px',paddingBottom:'15px'}}>Sure. Personalized bangles are custom-made bracelets that are designed to suit an individual’s preferences, style, or carry a special meaning. These can be crafted from various materials such as gold, silver, or other metals, and they may be adorned with gemstones, engravings, or other personalized elements.</p>
			<div className='ff-configure-carousel-product'>
				<Productitem style={{pointerEvents:selectedTheme.value == 'Enhanced' && showOnHover?'initial':'none'}} selectedColor={selectedIndex == 0 ? selectedColor : '#D8D8D8'} className='ff-configure-carousel-product-item selected' onClick={() => { setSelectedIndex(0) }}>
					<img src={selectedProduct1} onMouseEnter={
						()=>{
							setSelectedProduct1(Product4)
							}} onMouseLeave={()=>{setSelectedProduct1(Product1)}}/>
					<div className='ff-configure-carousel-product-item-det'>
						<div className='button2' style={{ fontSize: ' 0.875em', fontFamily: fontFamily.value }}>Product 1</div>
						<div className='body2' style={{ fontSize: ' 1em', fontFamily: fontFamily.value,margin:'3px 0px' }}><sup>$</sup>99.<sup>00</sup></div>
				     {/* <Star /> */}
					</div>

				</Productitem>

				<Productitem style={{pointerEvents:selectedTheme.value == 'Enhanced' && showOnHover?'initial':'none'}} selectedColor={selectedIndex == 1 ? selectedColor : '#D8D8D8'} className='ff-configure-carousel-product-item' onClick={() => { setSelectedIndex(1) }}>
					<img src={selectedProduct2} onMouseEnter={()=>{setSelectedProduct2(Product5)}} onMouseLeave={()=>{setSelectedProduct2(Product2)}}/>
					<div className='ff-configure-carousel-product-item-det'>
						<div className='button2' style={{ fontSize: ' 0.875em', fontFamily: fontFamily.value }}>Product 2</div>
						<div className='body2' style={{ fontSize: ' 0.875em', fontFamily: fontFamily.value }}><sup>$</sup>99.<sup>00</sup></div>
						{/* <Star /> */}
					</div>

				</Productitem>

				<Productitem style={{pointerEvents:selectedTheme.value == 'Enhanced' && showOnHover?'initial':'none'}} selectedColor={selectedIndex == 2 ? selectedColor : '#D8D8D8'} className='ff-configure-carousel-product-item' onClick={() => { setSelectedIndex(2) }}>
					<img src={selectedProduct3} onMouseEnter={()=>{setSelectedProduct3(Product6)}} onMouseLeave={()=>{setSelectedProduct3(Product3)}}/>
					<div className='ff-configure-carousel-product-item-det'>
						<div className='button2' style={{ fontSize: ' 0.875em', fontFamily: fontFamily.value }}>Product 3</div>
						<div className='body2' style={{ fontSize: ' 0.875em', fontFamily: fontFamily.value }}><sup>$</sup>99.<sup>00</sup></div>
						{/* <Star /> */}
					</div>
				</Productitem>

			</div>
		   {recommendationRational &&
				<div className='ff-config-product-desc-reason'>
				<h5 style={{ fontSize: '1.5em', fontFamily: fontFamily.value }}>Why is {selectedProduct} a good  choice for you</h5>
						<div className='body2' style={{ fontSize: '0.875em', fontFamily: fontFamily.value,marginTop:'10px' }}>
							{selectedProduct} is widely considered one of the best products in home appliances. It’s a revolutionary innovation that seamlessly combines cutting-edge technology with unparalleled design. This remarkable product enhances everyday life in unimaginable ways, captivating users with its intuitive interface and remarkable versatility. Experience the perfect blend of style and functionality, as this game-changing creation takes the world by storm, setting new standards for excellence.
						</div>
				</div>	
			}
			
			<div className='ff-config-product-desc'>
				<div className='ff-config-product-desc-image'>
					<img src={selectedImage} />
					<div className='ff-config-desc-subimage'>
						<img src={Product1} onClick={() => { setSelectedImage(Product1) }} style={{ border: selectedImage == Product1 ? `2px solid ${selectedColor}` : `2px solid transparent` }} />
						<img src={Product4} onClick={() => { setSelectedImage(Product4) }} style={{ border: selectedImage == Product4 ? `2px solid ${selectedColor}` : `2px solid transparent` }} />
					</div>
					{/* <h5 style={{ fontSize: '1.5em', fontFamily: fontFamily.value }}>{selectedProduct}</h5>
					<div className='body2' style={{ fontSize: '0.875em', fontFamily: fontFamily.value }}><sup>$</sup>99.<sup>00</sup></div>
					<Star />
					<div className='body2' style={{ fontSize: '0.875em', fontFamily: fontFamily.value }}>Introducing a revolutionary innovation that seamlessly combines cutting-edge technology with unparalleled design. This remarkable product enhances everyday life in unimaginable ways, captivating users with its intuitive interface and remarkable versatility. Experience the perfect blend of style and functionality, as this game-changing creation takes the world by storm, setting new standards for excellence.</div> */}
				</div>
				<div className={`ff-config-product-desc-content ${colorMode}`}>
					<h5 style={{ fontSize: '1.5em', fontFamily: fontFamily.value }}>Product 1</h5>
					<div className='body2' style={{ fontSize: ' 0.875em', fontFamily: fontFamily.value }}><sup>$</sup>99.<sup>00</sup></div>
					<Star />
					<div className='body2' style={{ fontSize: '0.875em', fontFamily: fontFamily.value,marginTop:'10px' }}>
						Introducing a revolutionary innovation that seamlessly combines cutting-edge technology with unparalleled design. This remarkable product enhances everyday life in unimaginable ways, captivating users with its intuitive interface and remarkable versatility. Experience the perfect blend of style and functionality, as this game-changing creation takes the world by storm, setting new standards for excellence.
					</div>
					<div>
					<VariantsButtonMode selectedColor={selectedColor} colorMode={colorMode} colorScale={colorScale} className="variants-btn" fontFamily={fontFamily.value}>
						{selectedButton.map((item:any)=>{
							return(
								<Button>{item.value}</Button>
							)
						})

						}
					</VariantsButtonMode>
                    </div>
					
					{/* <div className={`ff-variant-product`}>
						<div className='body2' style={{ fontWeight: 'bold', fontSize: '0.875em', fontFamily: fontFamily.value }}>Color</div>
						<div style={{ display: 'flex' }}>
							<VariantsButton className={'active-variants'} selectedColor={selectedColor} colorMode={colorMode} colorScale={colorScale} onClick={() => { setSelectedVariant('Included') }} fontFamily={fontFamily.value}>Included</VariantsButton>
							<VariantsButton className={selectedVariant == 'Without Lens' ? 'active-variants' : ''} selectedColor={selectedColor} colorMode={colorMode} colorScale={colorScale} onClick={() => { setSelectedVariant('Without Lens') }} fontFamily={fontFamily.value}>Without Lens</VariantsButton>
						</div>

						<div className='body2' style={{ fontWeight: 'bold', fontSize: '0.875em', fontFamily: fontFamily.value }}>Size</div>
						<div style={{ display: 'flex' }}>
							<VariantsButton className={selectedVariant == 'S' ? 'active-variants' : ''} selectedColor={selectedColor} colorMode={colorMode} colorScale={colorScale} onClick={() => { setSelectedVariant('S') }} fontFamily={fontFamily.value}>S</VariantsButton>
							<VariantsButton className={selectedVariant == 'M' ? 'active-variants' : ''} selectedColor={selectedColor} colorMode={colorMode} colorScale={colorScale} onClick={() => { setSelectedVariant('M') }} fontFamily={fontFamily.value}>M</VariantsButton>
							<VariantsButton className={selectedVariant == 'L' ? 'active-variants' : ''} selectedColor={selectedColor} colorMode={colorMode} colorScale={colorScale} onClick={() => { setSelectedVariant('L') }} fontFamily={fontFamily.value}>L</VariantsButton>
							<VariantsButton className={selectedVariant == 'XL' ? 'active-variants' : ''} selectedColor={selectedColor} colorMode={colorMode} colorScale={colorScale} onClick={() => { setSelectedVariant('XL') }} fontFamily={fontFamily.value}>XL</VariantsButton>
						</div>


					</div> */}

				</div>
				
			</div>
			{complimentaryProducts == true &&
			<>
			<div className='ff-border-bottom'></div>
			<div style={{marginLeft:'34px'}}>
			<h6 style={{margin:'15px 0px'}}>{recommendationHeading}</h6>
		    <div className='ff-complementary-content'>
				{complementaryProducts.map((item)=>{
                   return(
				
				<div className='ff-complementary-content-item'>
				<div>
				<img src={item.imageSrc} />
				</div>
                <div>
				           <p className='button2'>{item.title}</p>
						   <div className='body2' style={{ fontSize: ' 0.875em', fontFamily: fontFamily.value }}><sup>$</sup>99.<sup>00</sup></div>
						   <Star />
				</div>
				</div>
				   )
				})

			}
			</div>		
			</div>	
			</>

            }
		</div>
	);
};

export default ProductDisplayEnhanced;
