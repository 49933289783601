import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import Button from '../../../../Components/Button';
import Dropdown, {
	DropdownValue,
	DropdownValueProps,
} from '../../../../Components/Dropdown';
import {
	commonActionParams,
	comparesWith,
	conditions,
	numberOperations,
	regexTypes,
	textOperations,
	ValidationConditions,
	validations,
} from '../common';
import '../ActionsModal.scss';
import './CollectUserInput.scss';
import Input from '../../../../Components/Input';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	addUserInputAction,
	getUserInputAction,
	resetActionDetails,
} from '../../../../Features/builder/builderSlice';
import {
	DefaultConnection,
	GetAction,
	UserValidations,
	TaskValidationsGroup,
} from '../../../../Features/builder/model';
import { useSelector } from 'react-redux';
import { getIconForNextAction } from '../common';
import builderService from '../../../../Features/builder/builderService';
import NextNewAction from '../NextNewAction';

import SnackBar from '../../../../Components/SnackBar';
import Conditions, {
	getConditionBranch,
	updateConditions,
} from '../Conditions';
import { format } from 'date-fns';
import { getActionName } from '../../../../utils/utils';
import { set } from '../../../../Features/message/messageSlice';

const CollectUserInput = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const context: any = useOutletContext();
	const state: any = location.state;
	const taskAction: GetAction = state.action;
	const [label, setLabel] = useState('Next Action');
	const { actionDetails, botId, tenantId, actions, activeQuery } = useSelector(
		(state: RootState) => state.builder
	);

	const nextActions = actions
		.filter(
			(action) =>
				action.task_ID !== taskAction.taskId &&
				action.taskHierarchyDTO[0] !== taskAction.taskId
		)
		.map((action) => ({
			value: action.task_ID.toString(),
			label: (
				<div className={`actions-label action${action.userResponseTypeID}`}>
					{getIconForNextAction(action.userResponseTypeID)}
					<p>{action.task_name}</p>
				</div>
			),
			labelValue: action.task_name,
		})) as DropdownValueProps[];

	nextActions.push({
		value: 'add new action',
		label: (
			<div className='actions-label new-action'>
				{getIconForNextAction(-1)}
				<p>New Action</p>
			</div>
		),
	});

	const [validation, setValidation] = useState<DropdownValue>(validations[0]);
	const [textOperation, setTextOperation] = useState<DropdownValue>(
		textOperations[0]
	);
	const [numberOperation, setNumberOperation] = useState<DropdownValue>(
		numberOperations[2]
	);
	const [compareWith, setCompareWith] = useState<DropdownValue>(
		comparesWith[1]
	);
	const [compareWithFrom, setCompareWithFrom] = useState<DropdownValue>(
		comparesWith[1]
	);
	const [compareWithTo, setCompareWithTo] = useState<DropdownValue>(
		comparesWith[1]
	);
	const [variableNames, setVariableNames] = useState<DropdownValue[]>([]);
	const [variableName, setVariableName] = useState<DropdownValue>(null);
	const [variableNameFrom, setVariableNameFrom] = useState<DropdownValue>(null);
	const [variableNameTo, setVariableNameTo] = useState<DropdownValue>(null);
	const [condition, setCondition] = useState<DropdownValue>(conditions[0]);
	const [regexType, setRegexType] = useState<DropdownValue>(null);
	const [regex, setRegex] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [value, setValue] = useState('');
	const [fromValue, setFromValue] = useState('');
	const [toValue, setToValue] = useState('');
	const variableArray: any[] = [
		{ value: '#product', label: '#product' },
		{ value: '#intent', label: '#intent' },
		{
			value: '#Collect_User_Input_12022-12-19_12:46:04',
			label: '#Collect_User_Input_12022-12-19_12:46:04',
		},
	];

	const [validationId, setValidationId] = useState(0);
	const [taskValidationsGroupId, setTaskValidationsGroupId] = useState(0);

	const [openNextActionType, setOpenNextActionType] = useState(false);
	const [nextActionTypeId, setNextActionTypeId] = useState(0);
	const [templateName, setTemplateName] = useState('');
	const [paramName, setParamName] = useState('');

	const [allConditions, setAllConditions] = useState<ValidationConditions[]>(
		[]
	);

	const [openNextActionTypeForConditions, setOpenNextActionTypeForConditions] =
		useState(false);
	const [nextActionTypeIdForConditions, setNextActionTypeIdForConditions] =
		useState(0);

	const [condtionIndex, setConditionIndex] = useState<Number | null>(null);
	const [checkEmpytValue, setCheckEmpytValue] = useState(true);
	var nextActionGlobal = {};
	useEffect(() => {
		if (botId && tenantId) {
			dispatch(
				getUserInputAction({
					botId,
					tenantId,
					taskId: taskAction.taskId,
					workflowId: taskAction.workflowId,
				})
			);
			setConditionIndex(0);
			(async () => {
				const params = await builderService.getSuggestedResponseParam({
					botId,
					tenantId,
					workflowId: taskAction.workflowId,
				});

				if (params.status === 200 && params.data.length > 0) {
					const variables = params.data.map((param: string) => ({
						value: param,
						label: param,
					}));

					setVariableNames(variables);
				}
			})();
		}

		return () => {
			dispatch(resetActionDetails());
		};
	}, []);

	useEffect(() => {
		const d = new Date();
		setParamName(
			actionDetails?.responseParamName ??
			`#${actionDetails?.taskName.replace(' ', '_')}${format(
				d,
				'Y-MM-dd_H:mm:ss'
			)}`
		);
	}, [context.selectedAction]);

	useEffect(() => {
		if (actionDetails?.templateName !== actionDetails?.taskName)
			setTemplateName(actionDetails?.templateName ?? '');

		const d = new Date();
		if (
			actionDetails?.responseParamName.length === 0 ||
			actionDetails?.responseParamName === '#'
		) {
			setParamName(
				`#${actionDetails?.taskName.replace(' ', '_')}${format(
					d,
					'Y-MM-dd_H:mm:ss'
				)}`
			);
		} else setParamName(actionDetails?.responseParamName ?? '#');

		if (actionDetails && actionDetails?.conditionBranch.length > 0) {
			setAllConditions(updateConditions(actionDetails, nextActions));
		}

		const taskGroupId =
			actionDetails?.taskValidationsGroup[0]?.taskValidationsGroupId ?? 0;
		if (taskGroupId !== 0) {
			setTaskValidationsGroupId(taskGroupId);
			const group: TaskValidationsGroup =
				actionDetails?.taskValidationsGroup[0];

			setErrorMessage(group.validationError);

			if (group?.userValidations.length > 0) {
				const validId = group.userValidations[0].validationId ?? 0;
				setValidationId(validId);

				const validTypeId = group.userValidations[0].validationTypeId ?? 0;
				const userValidations: UserValidations = group?.userValidations[0];

				switch (validTypeId) {
					case 1:
						setValidation(
							validations.filter((valid) => valid.value === 'Text')[0]
						);
						setTextOperation(
							textOperations.filter(
								(operation) => operation.value === userValidations.opertaionType
							)[0]
						);
						setCompareWith(
							comparesWith.filter(
								(cmp) => cmp.value === userValidations.operandType
							)[0]
						);
						userValidations.operandType.indexOf('Parameter') > -1
							? setVariableName(
								variableNames.filter(
									(name) =>
										// @ts-ignore
										name.value === userValidations.validationCondition
								)[0]
							)
							: setValue(userValidations.validationCondition);
						break;

					case 2:
						setValidation(
							validations.filter((valid) => valid.value === 'Number')[0]
						);
						setNumberOperation(
							numberOperations.filter(
								(operation) => operation.value === userValidations.opertaionType
							)[0]
						);
						setCompareWith(
							comparesWith.filter(
								(cmp) => cmp.value === userValidations.operandType
							)[0]
						);
						userValidations.operandType.indexOf('Parameter') > -1
							? setVariableName(
								variableNames.filter(
									(name) =>
										// @ts-ignore
										name.value === userValidations.validationCondition
								)[0]
							)
							: setValue(userValidations.validationCondition);
						break;

					case 3:
						setValidation(
							validations.filter((valid) => valid.value === 'Regex')[0]
						);
						setRegexType(
							regexTypes.filter(
								(type) => type.label === userValidations.opertaionType
							)[0]
						);
						setRegex(userValidations.validationCondition);
						break;

					case 4:
						setValidation(
							validations.filter((valid) => valid.value === 'Boolean')[0]
						);
						setCondition(
							conditions.filter(
								(cond) => cond.value === userValidations.validationCondition
							)[0]
						);
						break;

					case 5:
						setValidation(
							validations.filter((valid) => valid.value === 'Range')[0]
						);
						const cond = userValidations.validationCondition.split('AND');
						const cond1 = cond[0].replace('>=', '');
						const cond2 = cond[1].replace('>=', '');

						if (cond1.startsWith('#')) {
							setCompareWithFrom(
								comparesWith.filter((cmp) => cmp.value === 'Parameter')[0]
							);
							setVariableNameFrom(
								variableArray.filter(
									(name) =>
										// @ts-ignore
										name.value === cond1
								)[0]
							);
						} else {
							setCompareWithFrom(
								comparesWith.filter((cmp) => cmp.value === 'Values')[0]
							);
							setFromValue(cond1);
						}

						if (cond2.startsWith('#')) {
							setCompareWithTo(
								comparesWith.filter((cmp) => cmp.value === 'Parameter')[0]
							);
							setVariableNameTo(
								variableArray.filter(
									(name) =>
										// @ts-ignore
										name.value === cond2
								)[0]
							);
						} else {
							setCompareWithTo(
								comparesWith.filter((cmp) => cmp.value === 'Values')[0]
							);
							setToValue(cond2);
						}
						break;

					default:
						break;
				}
			}
		}

		const next = actionDetails?.defaultConnection;
		if (next && actions) {
			let action = actions.filter(
				(action) => action.task_ID === next.childtaskId
			)[0];

			if (action) {
				let next_action: DropdownValue = {
					value: next.childtaskId.toString(),
					label: (
						<div className={`actions-label action${action.userResponseTypeID}`}>
							{getIconForNextAction(action.userResponseTypeID)}
							<p>{action.task_name}</p>
						</div>
					),
					labelValue: next.childTaskName ?? 'Untitled',
				};
				context.setNextAction(next_action);
			}
		}
	}, [actionDetails]);

	useEffect(() => {
		const next = context.nextAction as DropdownValueProps;
		if (next && next.value === 'add new action' && !openNextActionType) {
			setOpenNextActionType(true);
		}
	}, [context.nextAction]);

	useEffect(() => {
		if (regexType) {
			const rex = regexType as DropdownValueProps;
			setRegex(rex.value);
		}
	}, [regexType]);

	useEffect(() => {
		let next_action: DropdownValue = {
			value: label,

			label: (
				<div className={`actions-label action${nextActionTypeId}`}>
					{getIconForNextAction(nextActionTypeId)}

					<p>{label}</p>
				</div>
			),

			labelValue: label ?? 'Untitled',
		};

		context.setNextAction(next_action);
	}, [label]);

	const validateText = (e: any) => {
		const { value } = e.target;

		const regex = /^[A-Za-z]+$/;

		if (value === '' || regex.test(value)) {
			setValue(value);
		}

		return value;
	};

	const validateNumber = (e: any) => {
		return ['e', 'E', '+', '-'].indexOf(e.key) > -1 && e.preventDefault();
	};

	const handleSave = async () => {
		if (actionDetails) {
			if (
				actions.filter(
					(action) =>
						action.task_name === context.actionName &&
						action.task_ID !== actionDetails.taskId
				).length > 0
			) {
				dispatch(set('Action name already exists'));
				// toast(<SnackBar type='danger'>Action name already exists</SnackBar>, {
				// 	autoClose: 3000,
				// });
				return false;
			}

			let next = context.nextAction as DropdownValueProps;
			let defaultConnection: DefaultConnection = {
				connectionId: 0,
				childtaskId: 0,
				conditionType: '',
				childTaskName: null,
			};

			if (nextActionTypeId !== 0 && botId && tenantId) {
				//call add action
				let position = actionDetails?.taskPosition.split('d');
				let task_position = '3000d4000';
				if (position) {
					task_position = `${parseInt(position[0]) + 110}d${position[1]}`;
				}
				const common = commonActionParams(actionDetails?.workflowId, false);
				const new_task = await builderService.addAction({
					...common,
					previousTaskId: 0,
					botId,
					tenantId,
					userResponseTypeID: nextActionTypeId,
					task_position,
					task_name: getActionName(actions, nextActionTypeId),
				});

				if (new_task.status === 201) {
					defaultConnection.childtaskId = Number(
						new_task.data.data.task.task_ID
					);
					defaultConnection.childTaskName = 'Untitled';
				}
			} else if (nextActionTypeIdForConditions !== 0 && botId && tenantId) {
				let position = actionDetails?.taskPosition.split('d');
				let task_position = '3000d4000';
				if (position) {
					task_position = `${parseInt(position[0]) + 110}d${position[1]}`;
				}
				const common = commonActionParams(actionDetails?.workflowId, false);
				const new_task = await builderService.addAction({
					...common,
					previousTaskId: 0,
					botId,
					tenantId,
					userResponseTypeID: nextActionTypeIdForConditions,
					task_position,
					task_name: getActionName(actions, nextActionTypeIdForConditions),
				});

				if (new_task.status === 201) {
					const nextActionVar = {
						nextAction: {
							value: new_task.data.data.task.task_ID.toString(),
							label: (
								<div
									className={`actions-label action${new_task.data.data.task.task_ID}`}>
									{getIconForNextAction(new_task.data.data.task.task_ID)}
									<p>{new_task.data.data.task.task_name}</p>
								</div>
							),
							labelValue: new_task.data.data.task.task_name,
						},
					};
					nextActionGlobal = nextActionVar;
				}
				if (next) {
					defaultConnection.childtaskId = Number(next.value);
					defaultConnection.childTaskName = next.labelValue ?? '';
				}
			} else {
				if (next) {
					defaultConnection.childtaskId = Number(next.value);
					defaultConnection.childTaskName = next.labelValue ?? '';
				}
			}

			const valid = validation as DropdownValueProps;

			const userValidations: UserValidations[] = [];

			if (valid.value !== 'None') {
				switch (valid.value) {
					case 'Text': {
						const cmpWith = compareWith as DropdownValueProps;
						const operationType = textOperation as DropdownValueProps;
						const varName = variableName as DropdownValueProps;

						// Validate
						if (cmpWith.value === 'values' && value.length <= 0) {
							dispatch(set('Enter value'));
							// toast(<SnackBar type='danger'>Enter value</SnackBar>, {
							// 	autoClose: 3000,
							// });
							return;
						}

						if (cmpWith.value === 'Parameter' && !varName) {
							dispatch(set('Select variable name'));
							// toast(<SnackBar type='danger'>Select variable name</SnackBar>, {
							// 	autoClose: 3000,
							// });
							return;
						}

						userValidations.push({
							apiOption: null,
							operandType: cmpWith.value,
							opertaionType: operationType.value,
							validationCondition:
								cmpWith.value === 'Parameter' ? varName.value : value,
							validationId,
							validationLinkType: '',
							validationTypeId: 1,
						});
						break;
					}

					case 'Number': {
						const cmpWith = compareWith as DropdownValueProps;
						const operationType = numberOperation as DropdownValueProps;
						const varName = variableName as DropdownValueProps;

						// Validate
						if (cmpWith.value === 'values' && value.length <= 0) {
							dispatch(set('Enter value'));
							// toast(<SnackBar type='danger'>Enter value</SnackBar>, {
							// 	autoClose: 3000,
							// });
							return;
						}

						if (cmpWith.value === 'parameter' && !varName) {
							dispatch(set('Select variable name'));
							// toast(<SnackBar type='danger'>Select variable name</SnackBar>, {
							// 	autoClose: 3000,
							// });
							return;
						}

						userValidations.push({
							apiOption: null,
							operandType: cmpWith.value,
							opertaionType: operationType.value,
							validationCondition:
								cmpWith.value === 'Parameter' ? varName.value : value,
							validationId,
							validationLinkType: '',
							validationTypeId: 2,
						});
						break;
					}

					case 'Regex': {
						const regType = regexType as DropdownValueProps;

						// Validate
						if (!regType) {
							dispatch(set('Select regex type'));
							// toast(<SnackBar type='danger'>Select regex type</SnackBar>, {
							// 	autoClose: 3000,
							// });
							return;
						}

						userValidations.push({
							apiOption: null,
							operandType: '',
							// @ts-ignore
							opertaionType: regType.label,
							validationCondition: regex,
							validationId,
							validationLinkType: '',
							validationTypeId: 3,
						});
						break;
					}

					case 'Boolean': {
						const boolCondition = condition as DropdownValueProps;
						userValidations.push({
							apiOption: null,
							operandType: '',
							opertaionType: '',
							validationCondition: boolCondition.value,
							validationId,
							validationLinkType: '',
							validationTypeId: 4,
						});
						break;
					}

					case 'Range': {
						const from = compareWithFrom as DropdownValueProps;
						const to = compareWithTo as DropdownValueProps;

						const fromUserValidations =
							// @ts-ignore
							from.value === 'Parameter' ? variableNameFrom.value : fromValue;
						const toUserValidations =
							// @ts-ignore
							to.value === 'Parameter' ? variableNameTo.value : toValue;

						// Validate
						if (from.value === 'Values' && fromValue.length <= 0) {
							dispatch(set('Enter value'));
							// toast(<SnackBar type='danger'>Enter value</SnackBar>, {
							// 	autoClose: 3000,
							// });
							return;
						}

						if (from.value === 'Parameter' && !variableNameFrom) {
							dispatch(set('Select variable name'));
							// toast(<SnackBar type='danger'>Select variable name</SnackBar>, {
							// 	autoClose: 3000,
							// });
							return;
						}

						if (to.value === 'Values' && toValue.length <= 0) {
							dispatch(set('Enter value'));
							// toast(<SnackBar type='danger'>Enter value</SnackBar>, {
							// 	autoClose: 3000,
							// });
							return;
						}

						if (to.value === 'Parameter' && !variableNameTo) {
							dispatch(set('Select variable name'));
							// toast(<SnackBar type='danger'>Select variable name</SnackBar>, {
							// 	autoClose: 3000,
							// });
							return;
						}

						userValidations.push({
							apiOption: null,
							operandType: '',
							opertaionType: '',
							validationCondition: `>=${fromUserValidations}AND${toUserValidations}>=`,
							validationId,
							validationLinkType: '',
							validationTypeId: 5,
						});
						break;
					}

					default:
						break;
				}

				if (errorMessage.length <= 0) {
					dispatch(set('Enter error message'));
					// toast(<SnackBar type='danger'>Enter error message</SnackBar>, {
					// 	autoClose: 3000,
					// });
					return;
				}
			}

			let taskValidationsGroup: TaskValidationsGroup[] = [];

			taskValidationsGroup.push({
				multiLingual: {},
				taskValidationsGroupId,
				taskValidationsGroupLinkType: '',
				userValidations: userValidations,
				validationError: errorMessage,
			});

			let allConditions2 = allConditions.map((each, i) =>
				i === condtionIndex ? { ...each, ...nextActionGlobal } : each
			);
			const conditionBranch = getConditionBranch(allConditions2, paramName);

			activeQuery &&
				dispatch(
					addUserInputAction({
						...actionDetails,
						taskName: context.actionName,
						currentNodeId: activeQuery.nodeId,
						templateName: templateName,
						responseParamName: paramName,
						templateType: 'New Template Type',
						defaultConnection,
						taskValidationsGroup,
						conditionBranch,
						userResponseType: 'Default',
						userResponseTypeId: 150,
						taskDescription: '',
					})
				);
		}

		navigate(-1);
	};

	return (
		<>
			<div className='body-content'>
				<div className='action-content'>
					<div className='left-pane'>
						<Input
							fullWidth
							multiline
							label='Message'
							value={templateName}
							onChange={(event) => setTemplateName(event.target.value)}
							className='text-input'
						/>
					</div>
					<div className='right-pane'>
						<div className='item'>
							<h6>Saving User Input</h6>
							<Input
								fullWidth
								value={paramName}
								onChange={(event) => setParamName(event.target.value)}
								placeholder='Variable Name'
							/>
						</div>

						<div className='item'>
							<h6>User Input Validation</h6>
							<div className='validations'>
								<div className='row'>
									<Dropdown
										label='Type'
										value={validation}
										options={validations}
										onChange={setValidation}
										className='CompareWithType'
									/>
									{/* @ts-ignore */}
									{(validation?.value === 'Text' ||
										/* @ts-ignore */
										validation?.value === 'Number') && (
											<Dropdown
												label='Compare with'
												value={compareWith}
												options={comparesWith}
												onChange={setCompareWith}
												className='CompareWith'
											/>
										)}
									{/* @ts-ignore */}
									{validation?.value === 'Boolean' && (
										<Dropdown
											label='Condition'
											value={condition}
											options={conditions}
											onChange={setCondition}
										/>
									)}
									{/* @ts-ignore */}
									{validation?.value === 'Regex' && (
										<Dropdown
											label='Select Regex Type'
											value={regexType}
											options={regexTypes}
											onChange={setRegexType}
										/>
									)}
								</div>

								{/* @ts-ignore */}
								{validation?.value === 'Text' && (
									<div className='row'>
										{
											/* @ts-ignore */
											compareWith.value === 'Values' ? (
												<>
													<Dropdown
														className='CompareWithOperation'
														label='Operation'
														value={textOperation}
														options={textOperations}
														onChange={setTextOperation}
													/>
													<Input
														className='TextInputBox'
														value={value}
														// onChange={(event) => setValue(event.target.value)}

														onChange={(event) => {
															validateText(event);
														}}
														placeholder='Enter value'
														type='text'
														label='Enter value'
													/>
												</>
											) : (
												<>
													<Dropdown
														className='CompareWithOperation'
														label='Operation'
														value={textOperation}
														options={textOperations}
														onChange={setTextOperation}
													/>
													<Dropdown
														label='Variable name'
														value={variableName}
														options={variableNames}
														onChange={setVariableName}
														className='CompareWithOperation'
													/>
												</>
											)
										}
									</div>
								)}

								{/* @ts-ignore */}
								{validation?.value === 'Number' && (
									<div className='row'>
										{
											/* @ts-ignore */
											compareWith.value === 'Values' ? (
												<>
													<Dropdown
														className='CompareWithOperation'
														label='Operation'
														value={numberOperation}
														options={numberOperations}
														onChange={setNumberOperation}
													/>
													<Input
														value={value}
														onChange={(event) => setValue(event.target.value)}
														placeholder='Enter value'
														label='Enter value'
														type='number'
														onKeyDown={(e) => validateNumber(e)}
													/>
												</>
											) : (
												<>
													<Dropdown
														className='CompareWithOperation'
														label='Operation'
														value={numberOperation}
														options={numberOperations}
														onChange={setNumberOperation}
													/>
													<Dropdown
														label='Variable name'
														value={variableName}
														options={variableNames}
														onChange={setVariableName}
													/>
												</>
											)
										}
									</div>
								)}

								{/* @ts-ignore */}
								{validation?.value === 'Regex' && regexType && (
									<Input
										fullWidth
										value={regex}
										onChange={(event) => setRegex(event.target.value)}
										label='Regex'
										disabled
									/>
								)}

								{/* @ts-ignore */}
								{validation?.value === 'Range' && (
									<>
										<div className='range'>
											<p className='range-label'>From</p>
											<div className='row'>
												<Dropdown
													label='Compare with'
													value={compareWithFrom}
													options={comparesWith}
													onChange={setCompareWithFrom}
												/>
												{
													/* @ts-ignore */

													compareWithFrom.value === 'Parameter' ? (
														<Dropdown
															label='Variable name'
															value={variableNameFrom}
															options={variableNames}
															onChange={setVariableNameFrom}
														/>
													) : (
														<Input
															value={fromValue}
															onChange={(event) =>
																setFromValue(event.target.value)
															}
															placeholder='Enter value'
														/>
													)
												}
											</div>
										</div>
										<div className='range'>
											<p className='range-label'>To</p>
											<div className='row'>
												<Dropdown
													label='Compare with'
													value={compareWithTo}
													options={comparesWith}
													onChange={setCompareWithTo}
												/>
												{
													/* @ts-ignore */
													compareWithTo.value === 'Parameter' ? (
														<Dropdown
															label='Variable name'
															value={variableNameTo}
															options={variableNames}
															onChange={setVariableNameTo}
														/>
													) : (
														<Input
															value={toValue}
															onChange={(event) =>
																setToValue(event.target.value)
															}
															placeholder='Enter value'
														/>
													)
												}
											</div>
										</div>
									</>
								)}

								{/* @ts-ignore */}
								{validation?.value !== 'None' && (
									<Input
										fullWidth
										value={errorMessage}
										onChange={(event) => setErrorMessage(event.target.value)}
										label='Error message*'
									/>
								)}
							</div>
						</div>

						<div className='item'>
							<p className='label'>Default Next Action</p>
							<Dropdown
								isSearchable
								placeholder='Select Next Action'
								value={context.nextAction}
								options={nextActions}
								onChange={context.setNextAction}
								className='next-action-dropdown'
							/>
						</div>

						<div className='item'>
							<Conditions
								allConditions={allConditions}
								setAllConditions={setAllConditions}
								workflowId={taskAction.workflowId}
								nextActions={nextActions}
								setOpenNextActionType={setOpenNextActionTypeForConditions}
								setNextActionTypeIdForConditions={
									setNextActionTypeIdForConditions
								}
								nextActionTypeIdForConditions={nextActionTypeIdForConditions}
								openNextActionType={openNextActionTypeForConditions}
								setConditionIndex={setConditionIndex}
								setCheckEmpytValue={setCheckEmpytValue}
							/>
						</div>
					</div>
				</div>

				<footer>
					<Button
						onClick={handleSave}
						disabled={
							(context.actionName.trim().length > 0 &&
								templateName.trim().length > 0
								? false
								: true) ||
							(Boolean(allConditions.length) ? !checkEmpytValue : false)
						}>
						Save
					</Button>
				</footer>
			</div>

			<NextNewAction
				open={openNextActionType}
				setOpenNextActionType={setOpenNextActionType}
				setNextActionTypeId={setNextActionTypeId}
				setLabel={setLabel}
				onClose={() => {
					setOpenNextActionType(false);
					if (nextActionTypeId === 0) {
						context.setNextAction(null);
					}
				}}
			/>
		</>
	);
};
export default CollectUserInput;
