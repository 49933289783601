import DataFetchAPI from '../../utils/Api';
import DataFetchRouteAPI from '../../utils/RouteApi';
import { loadState } from '../../utils/utils';
import {
	CHANGE_PLAN,
	FREE_PLAN,
	LinkSubscriptionTenantId,
	PLAN_CHARGEID,
	StoreDetails_SHOPIFY,
	SubscriptionDetails_SHOPIFY,
	SubscriptionInvoiceReq,
	SubscriptionPayment_SHOPIFY,
	SubscriptionReq,
	subscriptionEnquiryReq,
} from './model';

const subscriptionStatus = async (data: SubscriptionReq) => {
	return await DataFetchAPI(false, true, 'Bearer').get(`/subscription/plan`, {
		params: data,
	});
};

const subscriptionStatusInvoice = async (data: SubscriptionInvoiceReq) => {
	return await DataFetchAPI(false, true, 'Bearer').get(`/subscription/plan`, {
		params: data,
	});
};

const linkSubscriptionTenantId = async (data: LinkSubscriptionTenantId) => {
	return await DataFetchAPI(false, true, 'Bearer').post(
		`/subscription/plan`,
		data
	);
};

const subscriptionEnquiry = async (data: subscriptionEnquiryReq) => {
	return await DataFetchAPI(false, true, 'Bearer').post(
		`/configuration/email`,
		data
	);
};

const shopAuth_shopify = async (data: StoreDetails_SHOPIFY) => {
	const { storeName, storeCode } = data;
	return await DataFetchAPI().get(
		`/shop/oauth/link?shop=${storeName}&code=${storeCode}`
	);
};

const shopSubscription_shopify = async (data: SubscriptionDetails_SHOPIFY) => {
	let userId =
		loadState('parameter') === 'notLoggedIn'
			? loadState('userId')
			: loadState('user').userID;
	if (loadState('tenantId')) {
		return await DataFetchAPI().post(
			`/shop/subscription/${loadState('tenantId')}/user/${userId}`,
			data
		);
	} else {
		return await DataFetchAPI().post(`/shop/subscription/user/${userId}`, data);
	}
};

const shopSubscriptionPayment_shopify = async (
	data: SubscriptionPayment_SHOPIFY
) => {
	return await DataFetchAPI().post(`/shop/subscription/payment`, data);
};

const changePlan = async (data: CHANGE_PLAN) => {
	return await DataFetchAPI().post(
		`/shop/tenant/${loadState('tenantId')}/change_plan`,
		data
	);
};

const renewPlan = async (data: PLAN_CHARGEID) => {
	return await DataFetchAPI().put(
		`/shop/tenant/${loadState('tenantId')}/renew/subscription`,
		data
	);
};

const freePlanSubscribe = async (data: FREE_PLAN) => {
	let userId =
		loadState('parameter') === 'notLoggedIn'
			? loadState('userId')
			: loadState('user').userID;
	return await DataFetchAPI().post(
		`/subscription/freePlan/user/${userId}`,
		data
	);
};

const subscriptionService = {
	subscriptionStatus,
	subscriptionEnquiry,
	subscriptionStatusInvoice,
	linkSubscriptionTenantId,
	shopAuth_shopify,
	shopSubscription_shopify,
	shopSubscriptionPayment_shopify,
	changePlan,
	renewPlan,
	freePlanSubscribe,
};

export default subscriptionService;
