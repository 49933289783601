import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	availability
} from './model';
import ChatWithHumanService from './ChatWithHumanService'
type State = {
	agentAvailabilityTime: {  }[];
};

const initialState: State={
	agentAvailabilityTime:[]
}
export const getAvailability = createAsyncThunk<
     any,
     availability,
	{ rejectValue: string }
>('HybridChat/getAgentAvailibilityForBot', async (data, { rejectWithValue }) => {
	try {
		const response = await ChatWithHumanService.getAvailability(data);
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		console.log("Network error")
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const ChatWithHumanSlice = createSlice({
	name: 'ChatWithHuman',
	initialState,
	reducers: {
		reset: (state) => {
			
		},
		setAgentAvailability: (state, action) => {
			state.agentAvailabilityTime = action.payload;
		},
		
   
	},
	extraReducers: (builder) => {
		builder
		.addCase(getAvailability.rejected, (state,action) => {

		})
			.addCase(getAvailability.fulfilled, (state,action) => {
				state.agentAvailabilityTime = action.payload;
			})
		}
	
});

 export const { setAgentAvailability } = ChatWithHumanSlice.actions;

export default ChatWithHumanSlice.reducer;
