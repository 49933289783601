interface Props {
	width?: string;
	height?: string;
	fillIcon?: boolean;
	className?: string;
	color?: string;
	onClick?: (e: React.MouseEvent<SVGElement>) => void;
	style?: React.CSSProperties;
	opacity?: string;
	rotateSVG?: boolean;
	onBlur?: (e: React.FocusEvent<SVGElement>) => void;
}
export const HelpBotIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'>
			<defs>
				<linearGradient
					id='mnbpf6ay0a'
					x1='.094'
					y1='.797'
					x2='.935'
					y2='.237'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stopColor='#4041b3' />
					<stop offset='1' stopColor='#d74b92' />
				</linearGradient>
			</defs>
			<g data-name='Group 34018'>
				<path
					data-name='Rectangle 17435'
					style={{ fill: 'none' }}
					d='M0 0h20v20H0z'
				/>
				<path
					data-name='Path 19913'
					d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm1 17h-2v-2h2zm2.07-7.75-.9.92A3.4 3.4 0 0 0 13 15h-2v-.5a4.025 4.025 0 0 1 1.17-2.83l1.24-1.26A1.955 1.955 0 0 0 14 9a2 2 0 0 0-4 0H8a4 4 0 0 1 8 0 3.182 3.182 0 0 1-.93 2.25z'
					transform='translate(-2 -2)'
					style={{ fill: 'url(#mnbpf6ay0a)' }}
				/>
			</g>
		</svg>
	);
};

export const HelpBotCancelIcon = (props: Props) => {
	return (
		<svg
			data-name='cancel_black_24dp (1)'
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<defs>
				<linearGradient
					id='9mxso6gsua'
					x1='.094'
					y1='.797'
					x2='.935'
					y2='.237'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stopColor='#4041b3' />
					<stop offset='1' stopColor='#d74b92' />
				</linearGradient>
			</defs>
			<path
				data-name='Path 19924'
				d='M0 0h24v24H0z'
				style={{ fill: 'none', opacity: 0.87 }}
			/>
			<path
				data-name='Path 19925'
				d='M12 2a10 10 0 1 0 10 10A9.991 9.991 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.011 8.011 0 0 1-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z'
				style={{ fill: 'url(#9mxso6gsua)' }}
			/>
		</svg>
	);
};

export const ExpandIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path
				data-name='Path 19919'
				d='M0 0h24v24H0z'
				transform='rotate(90 12 12)'
				style={{ fill: 'none' }}
			/>
			<path
				data-name='Path 19920'
				d='M0 0h24v24H0z'
				transform='rotate(90 12 12)'
				style={{ fill: 'none' }}
			/>
			<path
				data-name='Path 19921'
				d='M0 18h16v2H0zM0 0h16v2H0zm9 7h3L8 3 4 7h3v6H4l4 4 4-4H9z'
				transform='rotate(90 9 13)'
				style={{ fill: '#fff' }}
			/>
		</svg>
	);
};

export const FeedbackRateIcon = (props: Props) => {
	return (
		<svg
			data-name='star_rate_black_24dp (1)'
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path
				data-name='Rectangle 17433'
				style={{ fill: 'none' }}
				d='M0 0h24v24H0z'
			/>
			<path
				data-name='Path 19916'
				d='m12 8.89.94 3.11h2.82l-2.27 1.62.93 3.01L12 14.79l-2.42 1.84.93-3.01L8.24 12h2.82L12 8.89M12 2l-2.42 8H2l6.17 4.41L5.83 22 12 17.31 18.18 22l-2.35-7.59L22 10h-7.58L12 2z'
				style={{ fill: '#fff' }}
			/>
		</svg>
	);
};

export const ReportIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path data-name='Path 19917' d='M0 0h24v24H0z' style={{ fill: 'none' }} />
			<path
				data-name='Path 19918'
				d='M20 2H4a2 2 0 0 0-1.99 2L2 22l4-4h14a2.006 2.006 0 0 0 2-2V4a2.006 2.006 0 0 0-2-2zm0 14H5.17l-.59.59-.58.58V4h16zm-9-4h2v2h-2zm0-6h2v4h-2z'
				transform='translate(0 1)'
				style={{ fill: '#fff' }}
			/>
		</svg>
	);
};

export const BackIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path data-name='Path 15579' d='M0 0h24v24H0z' style={{ fill: 'none' }} />
			<path
				data-name='Path 15580'
				d='M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z'
				style={{ fill: '#fff' }}
			/>
		</svg>
	);
};

export const ExpandedIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<g data-name='unfold_less_black_24dp (1)'>
				<path
					data-name='Path 19922'
					d='M24 0v24H0V0z'
					style={{ fill: 'none', opacity: '.87' }}
					transform='rotate(90 12 12)'
				/>
				<path
					data-name='Path 19923'
					d='M7.41 18.59 8.83 20 12 16.83 15.17 20l1.41-1.41L12 14zm9.18-13.18L15.17 4 12 7.17 8.83 4 7.41 5.41 12 10z'
					style={{ fill: '#fff' }}
					transform='rotate(90 12 12)'
				/>
			</g>
		</svg>
	);
};
