import { useEffect, useState } from 'react';
import BasicDateRangePicker from '../DateRangePicker';
import './calenderInput.scss';
import { format, subDays } from 'date-fns';
import { CalendarIcon } from '../../../../assets/Icons';
import {
	setOptionSelected,
	setPreviousStartEndDates,
	setStartEndDates,
	setOffset,
	setOffsetval,
	setselectedDay
} from '../../../../Features/Analytics/analyticsSlice';
import { RootState, useAppDispatch } from '../../../../app/store';
import { useSelector } from 'react-redux';
import Dropdown, { DropdownValue } from '../../../../Components/Dropdown';
import { saveState } from '../../../../utils/utils';

const CalendarInput = () => {
	const startDateFormating = (date: Date) => {
		return format(date, 'yyyy-MM-dd 00:00:00');
	};
	const endDateFormating = (date: Date) => {
		return format(date, 'yyyy-MM-dd 23:59:59');
	};
	const todayStart = startDateFormating(new Date());
	const todayEnd = endDateFormating(new Date());
	const [dates, setDates] = useState<string[]>(['', '']);
	const [open, setOpen] = useState<boolean>(false);
	const [openCalendar, setOpenCalendar] = useState<boolean>(false);
	const [displayNone, setdisplayNone] = useState<boolean>(false);
	const [customDate, setCustomDate] = useState<string>('Custom');
	const [previousdates, setpreviousDates] = useState<string[] | null[]>([
		null,
		null,
	]);
	const dispatch = useAppDispatch();
	const { optionSelected } = useSelector((state: RootState) => state.analytics);

	const onClickP = (event: any) => {
		event.stopPropagation();
		setOpen(true);
		setOpenCalendar(!openCalendar);
	};
	const options = [
		{
			label: (
				<div className='action-label'>
					{/* <div className=' icon '>
						<CalendarIcon />
					</div> */}
					<p>Today</p>
				</div>
			),
			value: 'Today',
		},
		{
			label: (
				<div className='action-label'>
					{/* <div className='icon'>
						<CalendarIcon />
					</div> */}
					<p>Yesterday</p>
				</div>
			),
			value: 'Yesterday',
		},
		{
			label: (
				<div className='action-label'>
					{/* <div className='icon'>
						<CalendarIcon />
					</div> */}
					<p>Last 7 days</p>
				</div>
			),
			value: 'Last 7 days',
		},
		{
			label: (
				<div className='action-label'>
					{/* <div className='icon'>
						<CalendarIcon />
					</div> */}
					<p>Last 30 days</p>
				</div>
			),
			value: 'Last 30 days',
		},
		{
			label: (
				// <div className='ff-select__option'>
				<div
					className='action-label action-label2 '
					onClick={() => {
						setOpenCalendar(!openCalendar);
					}}>
					{/* <div className='icon'>
						<CalendarIcon />
					</div> */}
					<div
						className=' custom-date-range-select'
						onClick={(event) => {
							event.stopPropagation();
						}}>
						<p onClick={onClickP} className='action-label'>
							{customDate}
						</p>
						{openCalendar && (
							<div className={`body2 picker ${!openCalendar && 'closed'}`}>
								<BasicDateRangePicker
									onClose={() => {
										setOpen(false);
										setOpenCalendar(false);
										setdisplayNone(true);
									}}
									open={open}
									onChange={(date) => pickerValuetoString(date)}
									dates={
										optionSelected === 'Custom'
											? dates
											: [new Date().toString(), new Date().toString()]
									}
								/>
							</div>
						)}
					</div>
				</div>
				// </div>
			),
			value: customDate,
		},
	];

	const [selectedfield, setSelectedField] = useState<DropdownValue | any>(
		options[0]
	);
	// dispatch(setOptionSelected(selectedfield.value));

	// useEffect(() => {
	// 	if (optionSelected !== 'Today')
	// 		dispatch(setOptionSelected(selectedfield.value));
	// 	// else dates[0] && dates[1] && dispatch(setStartEndDates(dates));
	// }, [dates]);

	useEffect(() => {
		dates[0] && dates[1] && dispatch(setStartEndDates(dates));
	}, [dates]);

	// useEffect(() => {
	// 	startAndEndDate(optionSelected);
	// }, [optionSelected]);

	useEffect(() => {
		startAndEndDate(selectedfield.value);
	}, [])

	useEffect(() => {
		previousdates[0] &&
			previousdates[1] &&
			dispatch(setPreviousStartEndDates(previousdates));

	}, [previousdates]);

	const startAndEndDate = (optionSelected: string) => {
		let d = new Date();
		switch (optionSelected) {
			case 'Today':

				setDates([todayStart, todayEnd]);
				setpreviousDates([
					startDateFormating(subDays(d, 1)),
					endDateFormating(subDays(d, 1)),
				]);

				break;
			case 'Yesterday':
				{
					setDates([
						startDateFormating(subDays(d, 1)),
						endDateFormating(subDays(d, 1)),
					]);
					setpreviousDates([
						startDateFormating(subDays(d, 2)),
						endDateFormating(subDays(d, 2)),
					]);


				}
				break;
			case 'Last 7 days':
				{
					setDates([
						startDateFormating(subDays(d, 7)),
						endDateFormating(subDays(d, 1)),
					]);
					setpreviousDates([
						startDateFormating(subDays(d, 13)),
						endDateFormating(subDays(d, 6)),
					]);

				}
				break;
			case 'Last 30 days':
				{
					setDates([
						startDateFormating(subDays(d, 30)),
						endDateFormating(subDays(d, 1)),
					]);
					setpreviousDates([
						startDateFormating(subDays(d, 59)),
						endDateFormating(subDays(d, 29)),
					]);

				}
				break;
		}
	};

	const pickerValuetoString = (value: Date[]) => {
		setCustomDate('Custom');
		const startDate = format(value[0], 'yyyy-MM-dd 00:00:00');
		const endDate = format(value[1], 'yyyy-MM-dd 23:59:59');
		setDates([startDate, endDate]);
		const dateOne: any = new Date(startDate);
		const dateTwo: any = new Date(endDate);
		const time = Math.abs(dateTwo - dateOne);
		const days = Math.abs(time / (1000 * 3600 * 24));
		setpreviousDates([
			startDateFormating(subDays(value[0], days + 1)),
			endDateFormating(subDays(value[0], 1)),
		]);
		setCustomDate(`${format(value[0], 'PP')} - ${format(value[1], 'PP')}`);
		dispatch(setOptionSelected(options[options.length - 1]));
	};

	useEffect(() => {
		if (customDate !== 'Custom') {
			setSelectedField({
				label: (
					<div className='body2 action-label'>
						{/* <div className='icon'>
							<CalendarIcon />
						</div> */}
						<p className='body2'>{customDate}</p>
					</div>
				),
				value: { customDate },
			});
		}
	}, [customDate]);

	const onchange = (field: any) => {
		// setdisplayNone(true);

		// dispatch(setOptionSelected(field.value));
		dispatch(setselectedDay("reset"))
		startAndEndDate(field.value);
		setSelectedField(field);
		setOpenCalendar(false);

		if (selectedfield !== selectedfield.length - 1) {
			// setSelectedField(options[options.length - 1]);
			setCustomDate('Custom');
			// setOpenCalendar(false);
		}
	};

	return (
		<div
			onClick={() => {
				setdisplayNone(false);
			}}
			className={
				!displayNone
					? openCalendar
						? 'dropdown-container ff_hover'
						: 'dropdown-container'
					: 'dropdown-container ff_display_menu'
			}
		>
			<div className='ff_calender_container'>
				<div>
					<Dropdown
						value={selectedfield}
						onChange={(field) => onchange(field)}
						options={options}
						className='no-of-convo '
					/>
				</div>
			</div>
		</div>
	);
};

export default CalendarInput;
