import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../../app/store';
import Button from '../../../../../../Components/Button';
import Modal from '../../../../../../Components/Modal';
import { checkFreshchatStatus } from '../../../../../../Features/teamsetting/teamsettingSlice';

const LinkSuccess = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { tenantId } = useSelector((state: RootState) => state.teams);

	return (
		<div className='ff-freshchat-linksuccess-'>
			<Modal
				onClose={() => navigate(-2)}
				header='Freshchat successfully linked'
				actionButtons={
					<Button
						onClick={() => {
							tenantId && dispatch(checkFreshchatStatus({ tenantId }));
							navigate(-2);
						}}>
						Okay
					</Button>
				}>
				<p className='body1'>
					Chats will now appear in Freshchat so that your agents can directly
					handle the chats there.
				</p>
			</Modal>
		</div>
	);
};

export default LinkSuccess;
