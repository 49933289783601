import { useEffect, useState } from 'react';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	getAnalyticsData,
	reset,
} from '../../../Features/WhatsApp-BSP/analytics/analyticsSlice';
import { useSelector } from 'react-redux';
import Dropdown from './Dropdown/Dropdown';
import { subDays } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';

import './index.scss';
import Piechart from './PieChartContainer';
import { set } from '../../../Features/message/messageSlice';

interface AnalyticsDetails {
	title?: string;
	count?: string | number;
	isLoading?: boolean;
}

const UserInfoAnalytics = ({
	title,
	count,
	isLoading,
}: AnalyticsDetails): JSX.Element => {
	return (
		<div className='analytic-info'>
			<p className='analytic-info-p'>{title}</p>
			{isLoading ? (
				<CircularProgress size='24px' />
			) : (
				<p className='analytic-info-p-1'>{count}</p>
			)}
		</div>
	);
};

const WbspAnalytics = () => {
	const startDateFormating = (date: Date) => {
		const d = new Date(
			new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)
		);
		const UTCstring = `${d.getUTCFullYear()}-${(d.getUTCMonth() + 1)
			.toString()
			.padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')} ${d
			.getUTCHours()
			.toString()
			.padStart(2, '0')}:${d.getUTCMinutes().toString().padStart(2, '0')}:${d
			.getUTCSeconds()
			.toString()
			.padStart(2, '0')}`;
		return UTCstring;
	};
	const endDateFormating = (date: Date) => {
		const d = new Date(
			new Date(new Date(new Date(date).setHours(23)).setMinutes(59)).setSeconds(
				59
			)
		);
		const UTCstring = `${d.getUTCFullYear()}-${(d.getUTCMonth() + 1)
			.toString()
			.padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')} ${d
			.getUTCHours()
			.toString()
			.padStart(2, '0')}:${d.getUTCMinutes().toString().padStart(2, '0')}:${d
			.getUTCSeconds()
			.toString()
			.padStart(2, '0')}`;
		return UTCstring;
	};
	const todayStart = startDateFormating(new Date());
	const d = new Date();
	const todayEnd = `${d.getUTCFullYear()}-${(d.getUTCMonth() + 1)
		.toString()
		.padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')} ${d
		.getUTCHours()
		.toString()
		.padStart(2, '0')}:${d.getUTCMinutes().toString().padStart(2, '0')}:${d
		.getUTCSeconds()
		.toString()
		.padStart(2, '0')}`;

	const [optionSelected, setOptionSelected] = useState('');
	const [dates, setDates] = useState<string[] | null[]>([null, null]);
	const [reloadedCount, setReloadedCount] = useState<number>(0);
	const dispatch = useAppDispatch();
	const { analyticsData, isLoading, error } = useSelector(
		(state: RootState) => state.analyticsBSP
	);
	const { wabaNo } = useSelector((state: RootState) => state.BSPauth);
	const {
		messages_by_type,
		Business_initiated_messages_by_type,
		Business_initiated_by_type,
		active_users,
		no_of_messages,
		reload,
	} = analyticsData;

	useEffect(() => {
		if (optionSelected !== 'Today') setOptionSelected('Today');
		else
			dates[0] &&
				dates[1] &&
				dispatch(
					getAnalyticsData({ startTime: dates[0], endTime: dates[1], wabaNo })
				);
	}, []);

	useEffect(() => {
		if (dates[0] && dates[1]) {
			setReloadedCount(0);
			dispatch(reset());
			dispatch(
				getAnalyticsData({ startTime: dates[0], endTime: dates[1], wabaNo })
			);
		}
	}, [dates]);

	useEffect(() => {
		startAndEndDate(optionSelected);
	}, [optionSelected]);

	useEffect(() => {
		let interval: NodeJS.Timer | number;
		if (reload) {
			interval = setInterval(() => {
				setReloadedCount(reloadedCount + 1);
			}, 30000);

			if (reloadedCount === 2) {
				clearInterval(interval as unknown as number);
			}
		}
		if (!reload) {
			return () => clearInterval(interval as number);
		}

		return () => clearInterval(interval as number);
	}, [reloadedCount, reload]);

	useEffect(() => {
		dates[0] &&
			dates[1] &&
			dispatch(
				getAnalyticsData({ startTime: dates[0], endTime: dates[1], wabaNo })
			);
	}, [reloadedCount]);

	const startAndEndDate = (optionSelected: string) => {
		switch (optionSelected) {
			case 'Today':
				setDates([todayStart, todayEnd]);
				break;
			case 'Yesterday':
				{
					let d = new Date();
					setDates([
						startDateFormating(subDays(d, 1)),
						endDateFormating(subDays(d, 1)),
					]);
				}
				break;
			case 'Last 7 days':
				{
					let d = new Date();
					setDates([
						startDateFormating(subDays(d, 7)),
						endDateFormating(subDays(d, 1)),
					]);
				}
				break;
			case 'Last 30 days':
				{
					let d = new Date();
					setDates([
						startDateFormating(subDays(d, 30)),
						endDateFormating(subDays(d, 0)),
					]);
				}
				break;
		}
	};

	return (
		<div className='analytics-container'>
			<div className='analytics-heading'>
				<h4>Analytics</h4>
				{/* <div> */}
				<Dropdown
					optionSelected={optionSelected}
					setOptionSelected={setOptionSelected}
					setDates={setDates}
					dates={dates}
				/>
				{/* </div> */}
			</div>
			<div className='analytics-detais'>
				<UserInfoAnalytics
					title='No. of messages'
					count={no_of_messages}
					isLoading={isLoading}
				/>
				<UserInfoAnalytics
					title='New users'
					count={active_users}
					isLoading={isLoading}
				/>
			</div>
			<div className='pieChart-Containers'>
				<Piechart
					isLoading={isLoading && (messages_by_type ? false : true)}
					data={messages_by_type}
					title='Messages by type'
				/>
				<Piechart
					isLoading={isLoading || (reload && reloadedCount < 2 ? true : false)}
					data={Business_initiated_messages_by_type}
					title='Outbound messages by status'
				/>
				<Piechart
					isLoading={isLoading && (Business_initiated_by_type ? false : true)}
					data={Business_initiated_by_type}
					title='Outbound messages by type'
				/>
			</div>
		</div>
	);
};

export default WbspAnalytics;
