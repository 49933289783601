import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import Modal from '../../../Components/Modal';
import {
	addNewUser,
	setUserAddSuccess,
	setUserError,
	setaddUserErrorMsg,
} from '../../../Features/users/userSlice';
import { roleIds } from '../../../utils/constants';
import { loadState } from '../../../utils/utils';
import { setSnackModal } from '../../../Features/message/messageSlice';
import { isHTMLTagsPresent } from '../../../utils/utils';
import { getUnreadNotifications } from '../../../Features/Notifications/notificationSlice';
const AddUserPopup = () => {
	const dispatch = useAppDispatch();
	const { botId } = useSelector((state: RootState) => state.builder);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { userAddSuccess, addUserError, addUserLoading, addUserErrorMsg } =
		useSelector((state: RootState) => state.user);

	const [emailId, setEmailId] = useState('');
	const [buttonDisable, setButtonDisable] = useState(false);
	const navigate = useNavigate();

	const validateUser = (event: any) => {
		setEmailId(event);
		// console.log(emailId);
	};

	useEffect(() => {
		dispatch(setUserError(false));
		dispatch(setaddUserErrorMsg(''));
		if (userAddSuccess) {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: 'User added successfully',
				})
			);
			navigate(-1);
			dispatch(setUserAddSuccess(false));
			dispatch(
				getUnreadNotifications({
					userId: loadState('user').userID,
					tenantId: tenantId!,
				})
			);
		}
	}, [userAddSuccess]);
	function isValidEmail(email: string) {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); //NOSONAR
	}
	return (
		<>
			<div>
				<Modal
					header='Add a user'
					actionButtons={
						<div style={{ display: 'flex' }}>
							<div>
								<Button
									buttonType='light'
									onClick={() => {
										navigate(-1);
									}}>
									Cancel
								</Button>
							</div>
							<div style={{ paddingLeft: '16px' }}>
								<Button
									buttonType='dark'
									loading={addUserLoading}
									disabled={
										!isValidEmail(emailId) ||
										isHTMLTagsPresent(emailId, true) ||
										buttonDisable
									}
									onClick={() => {
										let data = {
											tenantId: tenantId || 0,
											email: emailId,
										};
										dispatch(addNewUser(data));
									}}>
									Add
								</Button>
							</div>
						</div>
					}>
					<Input
						fullWidth
						label='Email Address*'
						error={addUserError === true ? true : false}
						value={emailId}
						errorText={
							!addUserErrorMsg
								? 'Please enter your work email address'
								: addUserErrorMsg
						}
						onChange={(event) => {
							validateUser(event.target.value);
						}}></Input>
					{/* {somethingWentWrong ? <div className="ff-adduser-error">Please enter your work mail id</div>:""} */}
				</Modal>
			</div>
		</>
	);
};

export default AddUserPopup;
