import styled from "styled-components";

export const Appp = styled.div`
  font-family: Inter;
//   position: absolute;
//   background: #f5f5f6;
  width:225px;
  height: 20px;
`;

export const Label = styled.div`
  position: static;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  letter-spacing: 0.4px;
  color: #000000;

  flex: none;
  order: 0;
  flex-grow: 1;
  text-align: left;
  padding: 10px;
  margin-top:4px;
`;

export const Container = styled.div`
  progress[value] {
    width: ${(props) => props.width};
    appearance: none;

    ::-webkit-progress-bar {
      width: 140px;
      height: 20px;
      margin-top: 13.1px;
      background: #e8e3df;
    //   border-radius: 5px;
    }

    ::-webkit-progress-value {
      height: 20px;
    //   border-radius: 5px;
      background-color: ${(props) => props.color};
    }
  }
`;
export const Value = styled.div`
  position: static;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #000000;

  flex: none;
  order: 0;
  flex-grow: 1;
//   margin: 0px 10px;
//   margin-top: 11px;
margin: 15px 7px 0px;
`;



