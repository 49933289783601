import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { differenceInDays } from 'date-fns';
import { useAppDispatch } from '../../../../app/store';
import { useEffect, useState } from 'react';
import { loadState, saveState } from '../../../../utils/utils';
import { set } from '../../../../Features/message/messageSlice';
// import { set } from '../../../features/message/messageSlice';

type Props = {
	onClose: () => void;
	onChange: (date: Date[]) => void;
	dates: any;
	open: boolean;
};

export default function BasicDateRangePicker({
	onChange,
	dates,
	onClose,
	open,
}: Props) {
	const dispatch = useAppDispatch();
	const handleChange = (dates: Date[]) => {
		if (differenceInDays(dates[1], dates[0]) < 30) {
			onChange(dates);

			let yesterdayBegin = new Date(
				dates[0].getFullYear(),
				dates[0].getMonth(),
				dates[0].getDate()
			);
			let todayEnd = new Date(
				dates[1].getFullYear(),
				dates[1].getMonth(),
				dates[1].getDate(),
				23,
				59,
				59,
				999
			);
			onChangeval([yesterdayBegin, todayEnd]);
			saveState('startdate', yesterdayBegin);
			saveState('enddate', todayEnd);
			onClose();
		} else {
			dispatch(set('The selected date range should not be more that 30 days'));
			onClose();
		}
	};
	const now = new Date();
	const yesterdayBegin = loadState('startdate')
		? new Date(loadState('startdate'))
		: new Date(now.getFullYear(), now.getMonth(), now.getDate());

	const todayEnd = loadState('enddate')
		? new Date(loadState('enddate'))
		: new Date(
				now.getFullYear(),
				now.getMonth(),
				now.getDate(),
				23,
				59,
				59,
				999
		  );

	const [value, onChangeval] = useState([yesterdayBegin, todayEnd]);

	return (
		<div className='Sample__container__content'>
			<DateRangePicker
				calendarType='US'
				isOpen={open}
				clearIcon={null}
				maxDate={new Date()}
				next2Label={null}
				prev2Label={null}
				onChange={handleChange}
				// value={[new Date(dates[0]), new Date(dates[1])]}
				// defaultValue={[dayjs('2022-04-20'), dayjs('2022-04-21')]}
				value={value}
			/>
		</div>
	);
}
