import { useEffect, useState } from 'react';
import { CreateNewIcon } from '../icon';
import '../../../Components/CatalogCard/CatalogScreen.scss';
import { getBotsList, resetBotList } from '../../../Features/Bots/BotSlice';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import CatalogCard from '../../../Components/CatalogCard';
import { FFLoader } from '../../../assets/Icons/Loader';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { listOfBots } from '../../../Features/Bots/model';
import Menu, { Options } from '../../../Components/Menu';
import { Masonry } from '@mui/lab';
import Rename from './Rename';
import Delete from './Delete';
import Clone from './Clone';
import Archive from './Archive';

type Props = {
	type: string;
	CreateNew?: boolean;
	icon?: boolean;
	onClick?: Function;
};

function MyConversationalVirtualSalesReps({
	type = 'CA',
	CreateNew,
	icon,
	onClick,
}: Props) {
	const { BotList, getBotAssignedLoader } = useSelector(
		(state: RootState) => state.bots
	);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<Array<Options>>([]);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const navigate = useNavigate();

	// To handle Modals
	const [openRename, setOpenRename] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [openClone, setOpenClone] = useState(false);
	const [openArchive, setOpenArchive] = useState(false);
	const location = useLocation();
	const path = location.pathname;

	useEffect(() => {
		if (type === 'CA' || type === 'VSR') {
			tenantId && dispatch(getBotsList({ tenantId, ca_vsr_type: type }));
		}

		return () => {
			dispatch(resetBotList());
		};
	}, [type]);

	const getOptions = (froze: boolean) => {
		return [
			{
				label: <p className='menu-label'>Rename</p>,
				onClick: () => {
					setOpenRename(true);
					setOpen(false);
				},
			},
			{
				label: <p className='menu-label'>Clone</p>,
				onClick: () => {
					setOpenClone(true);
					setOpen(false);
				},
			},
			{
				label: <p className='menu-label'>{froze ? 'Unarchive' : 'Archive'}</p>,
				onClick: () => {
					setOpenArchive(true);
					setOpen(false);
				},
			},
			{
				label: <p className='menu-label'>Delete</p>,
				onClick: () => {
					setOpenDelete(true);
					setOpen(false);
				},
			},
		];
	};

	const handleSetOpen = (card: listOfBots) => {
		open ? setOpen(false) : setOpen(true);
		setOptions(getOptions(card.froze));
	};
	const getFilterBotLists = (BotLists: listOfBots[]) => {
		// if (window.location.origin.indexOf('uat.flyfish.ai') > -1) {
		// 	return tenantId !== 124
		// 		? BotLists
		// 		: BotLists.filter((eachBot: listOfBots) => eachBot.botId !== 3575);
		// } else {
		// 	return tenantId !== 124
		// 		? BotLists
		// 		: BotLists.filter((eachBot: listOfBots) => eachBot.botId !== 3875);
		// }
		return BotLists;
	};
	return (
		<>
			{type === 'CA' ? (
				<>
					{BotList && (
						<div className='ff-caVsr-screen'>
							<div className='ff-caVsr-screen-body'>
								<Masonry
									columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
									spacing='1px'>
									{CreateNew && (
										<div
											className='ff-first-card'
											onClick={() =>
												navigate(
													`${
														type === 'CA'
															? '/app/catalog/intelligent-ad'
															: type === 'VSR'
															? '/app/catalog/multichannel-storefront'
															: '/app/my-studio'
													}`
												)
											}>
											<CreateNewIcon />
											<h5>Create new</h5>
										</div>
									)}
									{BotList.length !== 0 &&
										getFilterBotLists(BotList).map(
											(eachCard: listOfBots, index: number) => (
												<CatalogCard
													setAnchorEl={setAnchorEl}
													props={eachCard}
													index={index}
													key={index}
													icon={icon}
													onclick={() => {
														onClick && onClick(eachCard);
													}}
													handleSetOpen={() => handleSetOpen(eachCard)}
												/>
											)
										)}
								</Masonry>
							</div>
							{getBotAssignedLoader ? (
								<div className='myStudioLoader'>
									<p>
										Loading{' '}
										{path.indexOf('intelligent-ad') > -1
											? 'Ads'
											: 'Storefronts'}
									</p>
									<FFLoader />
								</div>
							) : (
								BotList.length === 0 && (
									<div className='ff-No-Bots'>
										No{' '}
										{path.indexOf('intelligent-ad') > -1
											? 'Ads'
											: 'Storefronts'}{' '}
										to display
									</div>
								)
							)}
						</div>
					)}
					<Menu
						anchorEl={anchorEl}
						open={open}
						setOpen={setOpen}
						options={options}
					/>
					{/* Modals */}
					{openRename && <Rename onClose={() => setOpenRename(false)} />}
					{openDelete && <Delete onClose={() => setOpenDelete(false)} />}
					{openClone && <Clone onClose={() => setOpenClone(false)} />}
					{openArchive && <Archive onClose={() => setOpenArchive(false)} />}\
				</>
			) : null}
		</>
	);
}

export default MyConversationalVirtualSalesReps;
