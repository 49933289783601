import {
    secondsToMinutesSeconds,
    loadState,
    saveState,
} from '../../../utils/utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './helpbot.scss';
import HBHeader from './HBHeader';
import HBFooter from './HBFooter';
import HBWelcomeScreen from './HBWelcomeScreen';
import HBChatBody from './HBChatBody';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import HBReportIssue from './HBReportIssue';
import MessageFeedbackComment from './HBRatingsFeedback/MessageFeedback/MessageFeedbackComment';
import Feedback from './HBRatingsFeedback/OverallFeedback/Feedback';
import { setisModalVisible, setmessageFeedbackComment } from '../../../Features/HelpBot/helpbotSlice';
import { useEffect, useState } from 'react';


function HelpBot() {
    const {
        msgsArray,
        HBscreen,
        expandWidth,
        messageFeedbackComment,
        thumbFilledUp,
        messageId,
        isModalVisible,
        thumbFilledDown
    } = useSelector((state: RootState) => state.helpBot);
    // const dispatch = useAppDispatch();
    const [visible, setvisible] = useState(false)
    //  const submitFeedback = (star = false, comment = '', msgRate) => {
    // 	this.setState({
    // 		showFeedbackPopup: false,
    // 		isModalVisible: false,
    // 	});

    // 	if (star === false && comment === '') {
    // 		this.setState({
    // 			feedbackDone: false,
    // 			showFeedback: false,
    // 			showFeedbackPopup: false,
    // 		});
    // 		return;
    // 	}


    // 	let payload = {
    // 		id: 0,
    // 		conversationId: this.state.conversationalId,
    // 		messageId: this.state.messageId ? this.state.messageId : 0,
    // 		botId: this.props.botID,
    // 		rating: star,
    // 		msgRating: msgRate ? msgRate : 0,
    // 		feedBackText: comment,
    // 	};
    // 	fetch(
    // 		portApiName +
    // 		window['CHAT_URL'] +
    // 		`/configuration/${this.props.tenantID}/feedBack`,
    // 		{
    // 			credentials: 'include',
    // 			method: 'POST',
    // 			headers: {
    // 				'Content-Type': 'application/json',
    // 				'x-tenant-id': this.props.tenantID,
    // 				Authorization: `Bearer ${this.state.bearerToken}`,
    // 			},
    // 			body: JSON.stringify(payload),
    // 		}
    // 	)
    // 		.then((data) => data.json())
    // 		.then((response) => {
    // 			// window['popUpSnackBar']('Thanks for your feedback');
    // 			this.setState({
    // 				showToaster: true,
    // 			});
    // 			setTimeout(() => {
    // 				this.setState({
    // 					showToaster: false,
    // 				});
    // 			}, 3000);
    // 		})
    // 		.catch((error) => {
    // 			console.log(error);
    // 		});
    // 	if (msgRate > 0) {
    // 		this.state.chatMessages &&
    // 			this.state.chatMessages.map((valdata: any) => {
    // 				if (Object.keys(valdata).indexOf('messageId') > -1) {
    // 					if (valdata.messageId === this.state.messageId) {
    // 						return (valdata.thumbFilledUp = msgRate === 4 ? true : false);
    // 					}
    // 				}
    // 			});
    // 	}


    // };
    const submitFeedback = () => {
        console.log('submitted')
    }
    const handleModalToggle = () => {
        setisModalVisible(false);
        setmessageFeedbackComment(false)
    }
    useEffect(() => {
        setvisible(isModalVisible)
        console.log(isModalVisible)
    }, [isModalVisible])
    return (
        <div className={`ff-helpbot-parent-container ${expandWidth ? 'expand-width' : 'normal-width'} 
                          ${HBscreen === 'report' ? 'ff-hb-report-background-image' : ''}`}>
            <HBHeader />
            {
                HBscreen === 'report' ?
                    <HBReportIssue />
                    :
                    msgsArray && msgsArray.length > 0 ?
                        <HBChatBody />
                        :

                        <HBWelcomeScreen />
            }
            {
                HBscreen !== 'report' &&
                <HBFooter />
            }
            {
                isModalVisible ?
                    <Feedback
                        submitFeedback={submitFeedback}
                      handleModalToggle={handleModalToggle}
                 
                    />
                    :
                    messageFeedbackComment === true ?
                        <MessageFeedbackComment
                       
                            submitFeedback={submitFeedback}

                            colorMode={"dark"}
                        /> :
                        null
            }
            {/* {
                messageFeedbackComment === true ?
                    <MessageFeedbackComment
                        // popUpSnackBar={this.popUpSnackBar}
                        // selectedRating={this.state.messageFeedbackRating}
                        // messageFeedbackCallback={
                        //     this.state.messageFeedbackCallback
                        // }
                        // messageFeedbackPrevVal={this.state.messageFeedbackPrevVal}
                        submitFeedback={submitFeedback}

                        colorMode={"dark"}
                    />
                    :
                    null} */}

        </div>
    )
}
export default HelpBot;
