import DataFetchRouteAPI from '../../utils/RouteApi';
import { Embeddings } from './model';

const generateEmbeddings = async (data: Embeddings) => {
	return await DataFetchRouteAPI(false, true, 'Bearer').post(
		`/ffstore/tenant/${data.data. tenant_id}/bot/${data.botId}/embeddings`,
		data.data
	);
};

const launchService = {
	generateEmbeddings,
};

export default launchService;
