import { version } from 'os';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import Modal from '../../../Components/Modal';
import { deployNewVersion } from '../../../Features/deploy/deploySlice';

const DeployNewVersion = () => {
	const navigate = useNavigate();
	const [versionName, setVersionName] = useState('');
	const [versionNameError, setVersionNameError] = useState<string | undefined>(
		undefined
	);
	const [description, setDescription] = useState('');
	const [disabled, setDisabled] = useState(true);
	const dispatch = useAppDispatch();
	const { activeBot } = useSelector((state: RootState) => state.bots);
	const { tenantId } = useSelector((state: RootState) => state.builder);
	const { deployedLoading, isDeployedSuccess, deployedVersionsList } =
		useSelector((state: RootState) => state.deploy);

	const handleDeployNewVersion = () => {
		tenantId &&
			dispatch(
				deployNewVersion({
					botId: activeBot.encBotId,
					description: description,
					versionName: versionName,
					tenantId: tenantId.toString(),
				})
			);
	};
	useEffect(() => {
		(isDeployedSuccess === 'Success' || isDeployedSuccess === 'Failed') &&
			navigate(-1);
	}, [isDeployedSuccess]);

	const handleNameChange = (name: string) => {
		const reg = new RegExp(/^$|^[a-z0-9._-]+$/gi);

		if (name.match(reg)) {
			setVersionName(name);
		}
	};

	useEffect(() => {
		if (description !== '') {
			setDisabled(false);
			if (description.length > 50) {
				setDisabled(true);
			}
		} else {
			setDisabled(true);
		}
		if (versionName !== '') {
			setDisabled(false);
			if (versionName.length > 12) {
				setDisabled(true);
				setVersionNameError(
					'Charater limit is crossed. Please delete some characters.'
				);
			} else {
				const duplicate = deployedVersionsList.filter(
					(version) => version.versionName === versionName
				);

				if (duplicate.length > 0) {
					setDisabled(true);
					setVersionNameError('Duplicate version name');
				} else {
					setVersionNameError('');
				}
			}
		} else {
			setDisabled(true);
		}
	}, [versionName, description]);

	const buttons = () => {
		return (
			<>
				<Button buttonType='light' onClick={() => navigate(-1)}>
					Cancel
				</Button>
				<Button
					disabled={disabled}
					onClick={() => handleDeployNewVersion()}
					loading={deployedLoading}>
					Save
				</Button>
			</>
		);
	};
	return (
		<Modal header='Deploy New Version' actionButtons={buttons()}>
			<div className='form'>
				<Input
					fullWidth
					autoFocus
					value={versionName}
					onChange={(event) => handleNameChange(event.target.value)}
					placeholder='Version Name*'
					limit={12}
					error={Boolean(versionNameError)}
					errorText={versionNameError}
					helperText='Allowed characters: A-Z, a-z, 0-9, ., _ and -'
				/>
				<Input
					fullWidth
					value={description}
					onChange={(event) => setDescription(event.target.value)}
					placeholder='Description'
					limit={50}
				/>
			</div>
		</Modal>
	);
};

export default DeployNewVersion;
