import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header2 from '../../../../../Components/Header2';
import Input from '../../../../../Components/Input';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { useSelector } from 'react-redux';
import {
	setIntroductoryHeading,
	setIntroductoryText,
	setProductResultCount,
	setDisableSave,
	setDisableSaveSubHead,
	setDisableSaveProductCount,
	setSelectedTheme,
	setSelectedButton,
	setSelectedVariants,
	setRecommendationType,
	setRecommendationHeading,
	setShowOnHover,
	setrecommendationCount,
	setSplashImage,
	setEnablestyleadvisor,
	setStyleAdvisorTitle,
	setWelcomeEcomImage,
	setWelcomeMonacoImage,
	setStyleError,
	setEcomlogo
} from '../../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import { setSnackModal } from '../../../../../Features/message/messageSlice';
import CarouselImage from './CarouselImage';
import { BackArrowFullScreen } from '../../../../ComponentsLibrary/Icons';
import NumberInput from '../../../../../Components/NumberInput';
import { setSelectedTab } from '../../../../../Features/teamsetting/teamsettingSlice';
import {
	setComplimentaryProducts,
	setRecommendationRational
} from '../../../../../Features/Verticalization/verticalizationSlice';
import AutoComplete from '../../../../../Components/AutoComplete';
import Radio from '../../../../../Components/Radio';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '../../../../../Components/Button';
const LeftPane = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const {
		introductoryHeading,
		introductoryBody,
		productResultCount,
		themes,
		selectedTheme,
		variants,
		selectedVariants,
		button,
		selectedButton,
		recommendationType,
		recommendationHeading,
		showOnHover,
		recommendationCount,
		additionalRecommendation,
		welcomeEcomImage,
		welcomeMonacoImage,
		welcomeEcomLogo,
		enableSplash,
		enableStyleAdvisor,
		styleAdvisorTitle,
		imageLoader,
		imageUploadStatusMsg

	} = useSelector((state: RootState) => state.experienceonweb);
	const { recommendationRational, complimentaryProducts } = useSelector(
		(state: RootState) => state.verticalization
	);

	const [value, setValue] = useState<any>([]);
	const [valueButton, setValueButton] = useState<any>([]);
	const path = location.pathname;
	const [valueText, setValueText] = useState('');
	const [errorStyle, setErrorStyle] = useState(false);
	const onDelete = (label: any) => () => {
		let variants = selectedVariants.filter((v: any) => v.label !== label);
		dispatch(setSelectedVariants(variants));
	};
	const onDeleteButton = (label: any) => () => {
		let btn = selectedButton.filter((v: any) => v.label !== label);
		dispatch(setSelectedButton(btn));
	};
	useEffect(() => {

		if (imageLoader == 'failed') {
			dispatch(setDisableSave(true))
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: imageUploadStatusMsg,
					modalMode: 'negative',
				})

			);
		}
		else {
			dispatch(setDisableSave(false))
		}
	}, [imageLoader])
	return (
		<div className='ff-configure-experience-on-web-left-pane'>
			<div style={{ position: 'relative', marginLeft: '-5%' }}>
				<div
					className='ff-configure-experience-on-web-left-arrow'
					onClick={() => {
						dispatch(setSelectedTab('Actions'));
						navigate('/app/configuration/actions');
					}}>
					<BackArrowFullScreen />
				</div>
				<h4
					style={{
						whiteSpace: 'nowrap',
						marginLeft: '-8%',
						color: 'var(--violet-450)',
						fontWeight: 600,
					}}>
					Design Theme
				</h4>
			</div>
			<div className='ff-theme-content'>
				<div className='ff-theme-title '>
					<p className='button2'>Select Theme</p>
				</div>
				<AutoComplete
					multiple={false}
					placeholder='Themes'
					options={themes}
					valueKey='value'
					labelKey='label'
					value={selectedTheme}
					onChange={(value) => dispatch(setSelectedTheme(value))}
				/>
			</div>
			<div className='ff-border'></div>
			<div className='experience-on-web-fullscreen'>
				<Header2
					breadcrumbs={[
						{
							title: 'Welcome Screen',
							path: `app/configuration/experience-on-web/full-screen-welcome-config/welcome-screen`,
						},
						{
							title: 'Chat Screen',
							path: `app/configuration/experience-on-web/full-screen-welcome-config/chat-screen`,
						},
					]}
				/>
			</div>
			{path.indexOf(
				'/app/configuration/experience-on-web/full-screen-welcome-config/welcome-screen'
			) > -1 ? (
				<>
					<div className='ff-fullscreen-welcome-input'>
						<div className='ff-fullscreen-welcome-heading'>
							<div
								className='body1'
								style={{ fontWeight: 'bold', marginBottom: '10px' }}>
								Welcome Message
							</div>
							<Input
								placeholder='Text'
								onChange={(ev) => {
									setValueText(ev.target.value.trimStart());
									dispatch(setIntroductoryHeading(ev.target.value.trimStart()));
									if (
										ev.target.value.trimStart().length > 250 ||
										ev.target.value.trimStart().length == 0
									) {
										dispatch(setDisableSave(true));
									} else {
										dispatch(setDisableSave(false));
									}
								}}
								value={introductoryHeading}
								limit={250}
								errorText='Please enter the valid'
							/>
						</div>

						<div className='ff-fullscreen-welcome-content'>
							<div
								className='body1'
								style={{ fontWeight: 'bold', marginBottom: '10px' }}>
								Introductory Text
							</div>
							<Input
								placeholder='Text'
								onChange={(ev) => {
									dispatch(setIntroductoryText(ev.target.value.trimStart()));
									if (
										ev.target.value.trimStart().length > 250 ||
										ev.target.value.trimStart().length == 0
									) {
										dispatch(setDisableSaveSubHead(true));
									} else {
										dispatch(setDisableSaveSubHead(false));
									}
								}}
								value={introductoryBody}
								limit={250}
							/>
						</div>
					</div>
					{selectedTheme.value === 'Monaco' || selectedTheme.value === 'E-commerce Chat' ?
						<div style={{ width: '100%', marginTop: '24px' }}>
							<div className='body1' style={{ fontWeight: 'bold', marginBottom: '16px' }}>Welcome Image</div>
							<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
								<div className='ff-welcome-image'>
									<img src={welcomeEcomImage || welcomeMonacoImage} />

								</div>
								{(welcomeEcomImage || welcomeMonacoImage) &&
									<svg
										onClick={() => { dispatch(setWelcomeMonacoImage('')); dispatch(setWelcomeEcomImage('')) }}
										style={{ marginTop: '-24px', cursor: 'pointer' }}
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'>
										<defs>
											<clipPath id='z23wj0weea'>
												<path
													data-name='Rectangle 15483'
													fill='rgba(0,0,0,.6)'
													d='M0 0h13.996v16H0z'
												/>
											</clipPath>
										</defs>
										<g data-name='Group 32536'>
											<g data-name='Group 31699'>
												<path
													data-name='Path 14985'
													d='M4.325 0v1.709H0v1.928h14V1.709H9.668V0z'
													fill='rgba(0,0,0,.6)'
													transform='translate(5 4)'
												/>
												<g data-name='Group 31700'>
													<g
														data-name='Group 31699'
														fill='clip-path:url(#z23wj0weea)'
														transform='translate(5 4)'>
														<path
															data-name='Path 14986'
															d='m20.842 79.607.011.112a2.306 2.306 0 0 0 2.294 2.081H28.4a2.306 2.306 0 0 0 2.294-2.071l.961-9.406H19.894zm8.676-7.361-.744 7.283a.379.379 0 0 1-.376.34h-5.25a.379.379 0 0 1-.375-.327l-.746-7.3z'
															transform='translate(-18.614 -65.796)'
															fill='rgba(0,0,0,.6)'
														/>
													</g>
												</g>
											</g>
										</g>
									</svg>
								}

							</div>
							<Button onClick={() => {
								navigate('welcome-screen/add-image', {
									state: { value: selectedTheme.value === 'Monaco' ? 'Fullscreen MonacoWelcome' : 'Fullscreen E-com' },
								});
							}}>Upload</Button>

						</div>
						:
						null

					}
					<div className='ff-fullscreen-welcome-suggestions'>
						<div className='body1' style={{ fontWeight: 'bold' }}>
							Suggestions
						</div>
						<div
							className='caption'
							style={{ color: 'var(--black-60)', marginTop: '8px' }}>
							Display a set of cards to highlight offers, promotions,
							recommendations and more.
						</div>

						<CarouselImage />
					</div>
				</>
			) : (
				<div className='ff-product-results-fullscreen'>
					<p className='body1'>Maximum number of product results</p>
					<NumberInput
						value={productResultCount}
						onChange={(val: any) => {
							dispatch(setProductResultCount(val));
							if (val.length == 0 || parseInt(val) < 1 || parseInt(val) > 12) {
								dispatch(setDisableSaveProductCount(true));
							} else dispatch(setDisableSaveProductCount(false));
						}}
						min={1}
						max={12}
						maxLimitMessage={'Please enter a number between 1 and 12'}
						minLimitMessage={'Please enter a number between 1 and 12'}
					/>
					<div className='ff-product-results-fullscreen-checkbox'>
						{selectedTheme.value === 'Enhanced' && (
							<>
								<div>
									<p className='body1'>Display image on hover</p>
								</div>
								<label className='switch' style={{ marginTop: '7px' }}>
									<input
										type='checkbox'
										onChange={(event) => {
											dispatch(setShowOnHover(event.target.checked));
										}}
										checked={showOnHover}
									/>
									<span className='slider round'></span>
								</label>
							</>
						)}
					</div>
					<div className='ff-product-results-fullscreen-checkbox'>
						<div>
							<p className='body1'>Show recommendation rationale</p>
						</div>
						<label className='switch'>
							<input
								type='checkbox'
								onChange={(event) => {
									dispatch(setRecommendationRational(event.target.checked));
								}}
								checked={recommendationRational}
							/>
							<span className='slider round'></span>
						</label>
					</div>

					<div className='ff-product-details'>
						<div
							className='body1'
							style={{ fontWeight: 'bold', marginBottom: '10px' }}>
							Product Details
						</div>
						<div>
							<Autocomplete
								multiple
								options={variants}
								getOptionLabel={(option) => option.label}
								value={selectedVariants}
								onChange={(e, newValue) => {
									setValue(newValue);
									dispatch(setSelectedVariants(newValue));
								}}
								renderTags={() => null}
								renderInput={(params) => (
									<TextField
										{...params}
										variant='outlined'
										placeholder='Product Color'
									/>
								)}
							/>
							<div className='ff-selected-list'>
								{selectedVariants.map((item: any) => (
									<div className='ff-selected-list-item'>
										{item.value} <div onClick={onDelete(item.label)}>X</div>
									</div>
								))}
							</div>
						</div>
					</div>

					<div className='ff-product-details'>
						<div
							className='body1'
							style={{ fontWeight: 'bold', marginBottom: '10px' }}>
							Button
						</div>
						<Autocomplete
							multiple
							options={button}
							getOptionLabel={(option) => option.label}
							value={selectedButton}
							onChange={(e, newValue) => {
								setValueButton(newValue);
								dispatch(setSelectedButton(newValue));
							}}
							renderTags={() => null}
							renderInput={(params) => (
								<TextField
									{...params}
									variant='outlined'
									placeholder='Button'
								/>
							)}
						/>
						<div className='ff-selected-list'>
							{selectedButton.map((item: any) => (
								<div className='ff-selected-list-item'>
									{item.value} <div onClick={onDeleteButton(item.label)}>X</div>
								</div>
							))}
						</div>
					</div>

					<div
						className='body1'
						style={{ marginTop: '24px', fontWeight: 'bold' }}>
						Additional Recommendations
					</div>
					<div
						className='ff-product-results-fullscreen-checkbox'
						style={{ marginBottom: '18px' }}>
						<div>
							<p className='body1'>Show additional product recommendations</p>
						</div>
						<label className='switch'>
							<input
								type='checkbox'
								onChange={(event) => {
									dispatch(setComplimentaryProducts(event.target.checked));
								}}
								checked={complimentaryProducts}
							/>
							<span className='slider round'></span>
						</label>
					</div>

					{(selectedTheme.value === 'E-commerce' || selectedTheme.value === 'E-commerce Chat') &&
						<>
							<div
								className='ff-product-results-fullscreen-checkbox'
								style={{ marginBottom: '18px' }}>
								<div>
									<p className='body1'>Enable style advisor</p>
								</div>
								<label className='switch'>
									<input
										type='checkbox'
										onChange={(event) => {
											dispatch(setEnablestyleadvisor(event.target.checked));
										}}
										checked={enableStyleAdvisor}
									/>
									<span className='slider round'></span>
								</label>
							</div>
							{enableStyleAdvisor &&
								<div>
									<Input
										label='Advisor title'
										defaultValue={styleAdvisorTitle}
										limit={100}
										onChange={(ev) => {
											if (!(/^[a-zA-Z0-9#*()\s]+$/.test(ev.target.value.trimStart()))) {
												setErrorStyle(true)
												dispatch(setStyleError(true))
											}
											else {
												setErrorStyle(false);
												dispatch(setStyleError(false))
											}
											dispatch(setStyleAdvisorTitle(ev.target.value.trimStart()));
										}}
										placeholder='Text'
										error={styleAdvisorTitle.trim().length > 100 || errorStyle ? true : false}
										value={styleAdvisorTitle}
										errorText='Please enter the valid'
									/>
								</div>
							}

							{selectedTheme.value === 'E-commerce Chat' &&
								<div
									className='ff-product-results-fullscreen-checkbox'
									style={{ marginBottom: '18px' }}>
									<div>
										<p className='body1'>Enable splash image</p>
									</div>
									<label className='switch'>
										<input
											type='checkbox'
											onChange={(event) => {
												dispatch(setSplashImage(event.target.checked));
											}}
											checked={enableSplash}
										/>
										<span className='slider round'></span>
									</label>
								</div>
							}

							{enableSplash &&
								<div className='ff-image-splash' style={{ marginBottom: '20px', marginTop: '20px' }}>
									<div className='body1' style={{ fontWeight: '600', marginBottom: '10px' }}>E-commerce Logo</div>
									{welcomeEcomLogo.length > 0 &&
										<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
											<div className='ff-welcome-image'>
												<img src={welcomeEcomLogo} />
											</div>

											<svg
												onClick={() => { dispatch(setEcomlogo('')); }}
												style={{ marginTop: '-24px', cursor: 'pointer' }}
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'>
												<defs>
													<clipPath id='z23wj0weea'>
														<path
															data-name='Rectangle 15483'
															fill='rgba(0,0,0,.6)'
															d='M0 0h13.996v16H0z'
														/>
													</clipPath>
												</defs>
												<g data-name='Group 32536'>
													<g data-name='Group 31699'>
														<path
															data-name='Path 14985'
															d='M4.325 0v1.709H0v1.928h14V1.709H9.668V0z'
															fill='rgba(0,0,0,.6)'
															transform='translate(5 4)'
														/>
														<g data-name='Group 31700'>
															<g
																data-name='Group 31699'
																fill='clip-path:url(#z23wj0weea)'
																transform='translate(5 4)'>
																<path
																	data-name='Path 14986'
																	d='m20.842 79.607.011.112a2.306 2.306 0 0 0 2.294 2.081H28.4a2.306 2.306 0 0 0 2.294-2.071l.961-9.406H19.894zm8.676-7.361-.744 7.283a.379.379 0 0 1-.376.34h-5.25a.379.379 0 0 1-.375-.327l-.746-7.3z'
																	transform='translate(-18.614 -65.796)'
																	fill='rgba(0,0,0,.6)'
																/>
															</g>
														</g>
													</g>
												</g>
											</svg>
										</div>

									}


									<Button
										onClick={() => {
											navigate('chat-screen/add-image', {
												state: { value: 'e-comSplashLogo' },
											});
										}}
									>Upload</Button>
								</div>

							}
						</>

					}






					<div className='input-container'>
						<Input
							label='Recommendations section heading'
							defaultValue={recommendationHeading}
							limit={100}
							onChange={(ev) => {
								dispatch(setRecommendationHeading(ev.target.value.trimStart()));
								if (ev.target.value.trimStart().length > 100) {
									dispatch(setDisableSaveSubHead(true));
								} else {
									dispatch(setDisableSaveSubHead(false));
								}
							}}
							placeholder='Text'
							error={recommendationHeading.length > 100 ? true : false}
							value={recommendationHeading}
							errorText='Please enter the valid'
						/>
					</div>
					<div style={{ marginTop: '15px' }}>
						<NumberInput
							label='Maximum number of recommendations'
							value={recommendationCount}
							onChange={(val: any) => {
								dispatch(setrecommendationCount(val));
								if (
									val.length == 0 ||
									parseInt(val) < 1 ||
									parseInt(val) > 12
								) {
									dispatch(setDisableSaveProductCount(true));
								} else dispatch(setDisableSaveProductCount(false));
							}}
							min={1}
							max={12}
							maxLimitMessage={'Please enter a number between 1 and 12'}
							minLimitMessage={'Please enter a number between 1 and 12'}
						/>
					</div>

					<div className='ff-recommendation-radio'>
						<p className='button1'>Recommendations Type</p>
						<div className='radio'>
							<Radio
								value='text'
								isChecked={recommendationType}
								onValueChange={(value) => {
									dispatch(setRecommendationType(value));
								}}
								label='Best Matches'
								name='displayStyle'
								labelDesc='Recommend products that best relate to the product being looked at'
							/>
							<Radio
								value='card'
								isChecked={recommendationType}
								onValueChange={(value) => {
									dispatch(setRecommendationType(value));
								}}
								label='Promoted products'
								name='displayStyle'
								labelDesc='Suggest related products that you want to promote, such as sponsored products or products on sale.'
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default LeftPane;
