import {
	DropdownValue,
	DropdownValueProps,
} from '../../../Components/Dropdown';
import {
	APICall,
	ConfirmCollected,
	Image,
	Keyboard,
	MultipleChoice,
	MultipleSelect,
	SendCollectedValues,
	Star,
	Text,
	TriggerQuery,
	HandoffToAgent,
} from '../../../assets/Icons';

export type ValidationConditions = {
	conditionBranchId: number;
	validation: string;
	operators?: string;
	value?: string;
	variable?: string;
	compareWith?: string;
	nextAction: null | DropdownValue;
	sourcePath?: string;
};

export const validations = [
	{
		value: 'None',
		label: 'None',
	},
	{
		value: 'Text',
		label: 'Text',
	},
	{
		value: 'Number',
		label: 'Number',
	},
	{
		value: 'Boolean',
		label: 'Boolean',
	},
	{
		value: 'Regex',
		label: 'Regex',
	},
	{
		value: 'Range',
		label: 'Range',
	},
];

export const textOperations = [
	{
		value: 'TE',
		label: 'Is exactly',
	},
	{
		value: 'TC',
		label: 'Contains',
	},
	{
		value: 'TSW',
		label: 'Starts with',
	},
	{
		value: 'TEW',
		label: 'Ends with',
	},
];

export const numberOperations = [
	{
		value: 'GT',
		label: 'Greater than',
	},
	{
		value: 'LT',
		label: 'Less than',
	},
	{
		value: 'ET',
		label: 'Equal to',
	},
	{
		value: 'GTET',
		label: 'Greater than equal to',
	},
	{
		value: 'LTET',
		label: 'Less than equal to',
	},
];

export const comparesWith = [
	{
		value: 'Parameter',
		label: 'Saved value',
	},
	{
		value: 'Values',
		label: 'Custom value',
	},
];

export const conditions = [
	{
		value: '',
		label: 'True',
	},
	{
		value: 'false',
		label: 'False',
	},
];

export const regexTypes = [
	{
		value: '[0-9]{1,}',
		label: 'Digit',
	},
	{
		value: '[A-Za-z]{1,}',
		label: 'Alpha',
	},
	{
		value: '[0-9A-Za-z_]{1,}',
		label: 'Alpha numeric',
	},
	{
		value: 'PM:MobileNumber',
		label: 'Phone',
	},
	{
		value: '^[+](1|01){1}[ -.]*[(]*[0-9]{3}[)]*[ -.]*[0-9]{3}[ -.]*[0-9]{4}$',
		label: 'Phone-US',
	},
	{
		value: '^[+][4]{2}[ -.]*[(]*[0-9]{3}[)]*[ -.]*[0-9]{3}[ -.]*[0-9]{4}$',
		label: 'Phone-UK',
	},
	{
		value: 'PM:EmailID',
		label: 'Email',
	},
	{
		value: 'PM:name',
		label: 'Name',
	},
	{
		value: 'PM:date',
		label: 'Date',
	},
	{
		value: 'PM:fromdate',
		label: 'FromDate',
	},
	{
		value: 'PM:todate',
		label: 'ToDate',
	},
	{
		value: 'PM:Location',
		label: 'Location',
	},
	{
		value: 'PM:Product Category',
		label: 'Product Category',
	},
];

export const getIconForNextAction = (userResponseTypeID: number) => {
	switch (userResponseTypeID) {
		case 0:
			return (
				<div className='type type0'>
					<div className='icon'>
						<HandoffToAgent width='10px' />
					</div>
				</div>
			);

		case 1:
			return (
				<div className='type type1'>
					<div className='icon'>
						<Text width='10px' />/ <Image width='10px' />
					</div>
				</div>
			);

		case 3:
			return (
				<div className='type type3'>
					<div className='icon'>
						<MultipleSelect width='13px' />
					</div>
				</div>
			);

		case 5:
			return (
				<div className='type type5'>
					<div className='icon'>
						<MultipleChoice width='13px' />
					</div>
				</div>
			);

		case 8:
			return (
				<div className='type type8'>
					<div className='icon'>
						<ConfirmCollected width='13px' />
					</div>
				</div>
			);

		case 10:
			return (
				<div className='type type10'>
					<div className='icon'>
						<APICall width='13px' />
					</div>
				</div>
			);

		case 11:
			return (
				<div className='type type11'>
					<div className='icon'>
						<Star width='13px' />
					</div>
				</div>
			);

		case 12:
			return (
				<div className='type type12'>
					<div className='icon'>
						<TriggerQuery width='13px' />
					</div>
				</div>
			);

		case 150:
			return (
				<div className='type type150'>
					<div className='icon'>
						<Keyboard width='13px' />
					</div>
				</div>
			);

		case 14:
			return (
				<div className='type type14'>
					<div className='icon'>
						<SendCollectedValues width='13px' />
					</div>
				</div>
			);
	}
	return null;
};

export const getIconForActions = (userResponseTypeID: number) => {
	switch (userResponseTypeID) {
		case 1:
			return (
				<div className='type type1'>
					<div className='icon'>
						<Text width='13px' />/ <Image width='13px' />
					</div>
				</div>
			);

		case 3:
			return (
				<div className='type type3'>
					<div className='icon'>
						<MultipleSelect width='15px' />
					</div>
				</div>
			);

		case 5:
			return (
				<div className='type type5'>
					<div className='icon'>
						<MultipleChoice width='15px' />
					</div>
				</div>
			);

		case 8:
			return (
				<div className='type type8'>
					<div className='icon'>
						<ConfirmCollected width='15px' />
					</div>
				</div>
			);

		case 10:
			return (
				<div className='type type10'>
					<div className='icon'>
						<APICall width='15px' />
					</div>
				</div>
			);

		case 11:
			return (
				<div className='type type11'>
					<div className='icon'>
						<Star width='15px' />
					</div>
				</div>
			);

		case 12:
			return (
				<div className='type type12'>
					<div className='icon'>
						<TriggerQuery width='15px' />
					</div>
				</div>
			);

		case 150:
			return (
				<div className='type type150'>
					<div className='icon'>
						<Keyboard width='15px' />
					</div>
				</div>
			);

		case 14:
			return (
				<div className='type type14'>
					<div className='icon'>
						<SendCollectedValues width='15px' />
					</div>
				</div>
			);
	}
	return null;
};

export const actionsOptions: Array<DropdownValueProps> = [
	{
		label: (
			<div className='actions-label action1'>
				{getIconForActions(1)}
				<p>Show Text & Media</p> {/*Design-Ad Changes*/}
			</div>
		),
		value: '1',
	},
	{
		label: (
			<div className='actions-label action5'>
				{getIconForActions(5)}
				<p>Multiple Choice Options</p>
			</div>
		),
		value: '5',
	},
	{
		label: (
			<div className='actions-label action3'>
				{getIconForActions(3)}
				<p>Multiple Select Options</p>
			</div>
		),
		value: '3',
	},
	{
		label: (
			<div className='actions-label action150'>
				{getIconForActions(150)}
				<p>Collect User Input</p>
			</div>
		),
		value: '150',
	},
	{
		label: (
			<div className='actions-label action11'>
				{getIconForActions(11)}
				<p>Collect User Rating</p>
			</div>
		),
		value: '11',
	},
	{
		label: (
			<div className='actions-label action10'>
				{getIconForActions(10)}
				<p>Call an API</p>
			</div>
		),
		value: '10',
	},
	{
		label: (
			<div className='actions-label action0'>
				{getIconForActions(0)}
				<p>Handoff To Agent</p>
			</div>
		),
		value: '0',
	},
	{
		label: (
			<div className='actions-label action12'>
				{getIconForActions(12)}
				<p>Trigger another Query</p>
			</div>
		),
		value: '12',
	},
	{
		label: (
			<div className='actions-label action14'>
				{getIconForActions(14)}
				<p>Send Collected Values</p>
			</div>
		),
		value: '14',
	},
	{
		label: (
			<div className='actions-label action8'>
				{getIconForActions(8)}
				<p>Confirm Collected Values</p>
			</div>
		),
		value: '8',
	},
];

export const commonActionParams = (
	workflow_id: number,
	taskroot: boolean = false
) => ({
	workflow_id,
	task_id: 0,
	taskroot,
	template: '',
	templateId: 0,
	template_description: '',
	template_type: '',
	userResponseType: '',
	task_description: '',
	task_name: 'Untitled',
});
