import React, { useEffect, useState } from 'react';
import Checkbox from '../../../../Components/Checkbox';
import Input from '../../../../Components/Input';
import './StartingChat.scss';
import Quick from '../../../../assets/images/quicklinksimage.png';
import Button from '../../../../Components/Button';
import { PlusIcon } from '../../../../assets/Icons/Channels';
import { RootState, useAppDispatch } from '../../../../app/store';
import { useSelector } from 'react-redux';
import {
	setAILaunchPromptCheckbox,
	setAILaunchPromptText,
	setAutoPopupTime,
	setautoPopupValue,
	setCardBody,
	setCardHeading,
	setCarouselTitle,
	setConversationOpener,
	setEOWWelcomeBannerLoader,
	setInputFields,
	setLaunchPromptBackgroundColor,
	setLaunchPromptTextColor,
	setShowQuickLinks,
	setWelcomeBannerCheckbox,
	setWelcomeMessage,
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import QuickLinks from './QuickLinks';
import ColorInput from '../../../../Components/ColorInput';
import { FFLoader } from '../../../../assets/Icons/Loader';
import { UploadImageEdit, UploadImageEditEOW } from '../../../../assets/Icons';
import { Outlet, useNavigate } from 'react-router-dom';
import NumberInput from '../../../../Components/NumberInput';
import { setSnack } from '../../../../Features/message/messageSlice';
import CardCarousels from './CardCarousels';

const StartingChat = () => {
	const {
		welcomeBannerCheckbox,
		autoPopupValue,
		welcomeMessage,
		cardHeading,
		cardBody,
		carouselTitle,
		showQuickLinks,
		aiLaunchPromptCheckbox,
		launchPromptBackgroundColor,
		launchPromptTextColor,
		aiLaunchPromptText,
		welcomeBannerFile,
		eowWelcomeBannerLoader,
		inputFieldsData,
		autoPopupTime,
		conversationOpener,
	} = useSelector((state: RootState) => state.experienceonweb);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		if (welcomeBannerCheckbox[0]) {
			if (!welcomeBannerFile) {
				navigate('add-image', { state: { value: 'WelcomeBanner' } });
			}
		}
	}, [welcomeBannerCheckbox[0]]);

	useEffect(() => {
		if (inputFieldsData.length < 2) {
			dispatch(setShowQuickLinks([false]));
		}
	}, [inputFieldsData]);

	useEffect(() => {
		if (eowWelcomeBannerLoader === 'success') {
			setLoader(false);
			// dispatch(
			// 	setSnack({
			// 		message: 'Image uploaded successfully',
			// 		testcase: 'positive',
			// 	})
			// );
		} else if (eowWelcomeBannerLoader === 'failed') {
			setLoader(false);
			// dispatch(
			// 	setSnack({
			// 		message: 'Something Went Wrong!! Please try after sometime',
			// 		testcase: 'negative',
			// 	})
			// );
		} else if (eowWelcomeBannerLoader === 'loading') {
			setLoader(true);
		}
		dispatch(setEOWWelcomeBannerLoader(''));
	}, [eowWelcomeBannerLoader]);

	return (
		<div className='ff-eow-starting-the-chat'>
			<h4>Pop-up Widget</h4>
			<div className='chat-prompt'>
				<div>
					<h6>Launch Prompt</h6>
					<Checkbox
						isChecked={aiLaunchPromptCheckbox}
						index={0}
						onValueChange={(value) => {
							dispatch(setAILaunchPromptCheckbox(value));
						}}
					/>
				</div>
				<p className='caption'>
					Show a callout alongside the avatar in the collapsed state of the AI
					prompting the user to interact with the AI.
				</p>
				{aiLaunchPromptCheckbox[0] ? (
					<div className='chat-prompt-options'>
						<div className='input'>
							<Input
								label='Prompt text'
								fullWidth
								value={aiLaunchPromptText}
								onChange={(e) => {
									dispatch(setAILaunchPromptText(e.target.value));
								}}
								autoFocus
							/>
						</div>
					</div>
				) : null}
			</div>
			<div className='auto-expand'>
				<div>
					<h6>Auto Pop Up</h6>
					<Checkbox
						isChecked={autoPopupValue}
						index={0}
						onValueChange={(value) => {
							dispatch(setautoPopupValue(value));
						}}
					/>
				</div>
				<p className='caption'>
					Automatically expand the chat window when added to a website after
					specified time.
				</p>
				{autoPopupValue[0] ? (
					<div className='auto-expand-options'>
						<Input
							label='Number of seconds'
							error={Boolean(autoPopupTime && !Number(autoPopupTime))}
							value={autoPopupTime}
							fullWidth
							onChange={(e) => {
								dispatch(setAutoPopupTime(e.target.value));
							}}
							errorText='Please enter a valid number of seconds'
						/>
					</div>
				) : null}
			</div>

			<div className='welcome-message'>
				<h6>Welcome Screen</h6>
				<p className='body1 '>Welcome Message</p>
				<Input
					placeholder='E.g. Hi! How can I help you?'
					fullWidth
					value={welcomeMessage}
					onChange={(event) => {
						dispatch(setWelcomeMessage(event.target.value));
					}}
				/>
				<hr />
				<p className='body1'>Introductory Card</p>
				<Input
					placeholder='E.g. Hi! How can I help you?'
					fullWidth
					value={cardHeading}
					label='Card Heading'
					onChange={(event) => {
						dispatch(setCardHeading(event.target.value));
					}}
					className='welcome-card-heading'
				/>
				<Input
					placeholder='E.g. Hi! How can I help you?'
					fullWidth
					value={cardBody}
					label='Card Body'
					onChange={(event) => {
						dispatch(setCardBody(event.target.value));
					}}
				/>
				<hr />
				<div className='carousels'>
					<p className='body1'>Carousel</p>
					<p className='caption'>
						Display a set of cards to highlight offers, promotions,
						recommendations and more.
					</p>
					<Input
						placeholder='E.g. Hi! How can I help you?'
						fullWidth
						value={carouselTitle}
						label='Carousel Title'
						onChange={(event) => {
							dispatch(setCarouselTitle(event.target.value));
						}}
					/>
					{/* {showQuickLinks[0] ? ( */}
					<CardCarousels />
					{/* // ) : (
					// 	<div className='image-holder'>
					// 		<img src={Quick} alt='' />
					// 		<Button
					// 			startIcon={<PlusIcon />}
					// 			buttonType='light'
					// 			onClick={() => {
					// 				dispatch(setShowQuickLinks([true]));
					// 				dispatch(setInputFields(['', '']));
					// 			}}>
					// 			Add quick link
					// 		</Button>
					// 	</div>
					// )} */}
				</div>
			</div>
			<div className='conversation-opener'>
				<h6>Conversation Opener</h6>
				<p className='caption'>
					Enter what you want your AI to show as the first message on the chat
					screen
				</p>
				<Input
					placeholder='Hi! How can I help you?'
					fullWidth
					value={conversationOpener}
					onChange={(event) => {
						dispatch(setConversationOpener(event.target.value));
					}}
				/>
			</div>
			<div className='quicklinks'>
				<h6>Quick Links</h6>
				<p className='caption'>
					Suggest some topics or questions to the user to start the
					conversation. These are shown alongside the welcome message.
				</p>
				{showQuickLinks[0] ? (
					<QuickLinks />
				) : (
					<div className='image-holder'>
						<img src={Quick} alt='' />
						<Button
							startIcon={<PlusIcon />}
							buttonType='light'
							onClick={() => {
								dispatch(setShowQuickLinks([true]));
								dispatch(setInputFields(['', '']));
							}}>
							Add quick link
						</Button>
					</div>
				)}
			</div>
			<Outlet />
		</div>
	);
};

export default StartingChat;
