import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import Modal from '../../../Components/Modal';
import { deleteVersion } from '../../../Features/deploy/deploySlice';

const DeleteVersion = () => {
	const location = useLocation();
	const state = location.state as { value: any };
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { activeBot } = useSelector((state: RootState) => state.bots);
	const { versionDeleteLoader, isVersionDeleted } = useSelector(
		(state: RootState) => state.deploy
	);
	const { tenantId } = useSelector((state: RootState) => state.builder);

	const handleDeleteVersion = () => {
		tenantId &&
			dispatch(
				deleteVersion({
					botId: activeBot.encBotId,
					versionId: state.value.versionId,
					versionName: state.value.versionName,
					tenantId: tenantId.toString(),
				})
			);
	};
	useEffect(() => {
		(isVersionDeleted === 'Success' || isVersionDeleted === 'Failed') &&
			navigate(-1);
	}, [isVersionDeleted]);

	const buttons = () => {
		return (
			<>
				<Button onClick={() => navigate(-1)} buttonType='light'>
					Cancel
				</Button>
				<Button
					onClick={() => handleDeleteVersion()}
					loading={versionDeleteLoader}>
					Delete
				</Button>
			</>
		);
	};
	return (
		<Modal header='Delete Version?' actionButtons={buttons()}>
			<p>
				Please confirm that you wish to delete the version "
				{state.value.versionName}".
			</p>
		</Modal>
	);
};

export default DeleteVersion;
