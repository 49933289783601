import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-GB';
import { makeStyles } from '@mui/styles';
import { theme } from '../../app/theme';
import { useState } from 'react';
import { TextField } from '@mui/material';

import './index.scss';

type Props = {
	label: string;
	value: Date | null;
	onChange: (arg0: Date | null) => void;
	disableFuture?: boolean;
	disablePast?: boolean;
	inputProps?: object;
	error?: boolean;
	helperText?: string | undefined;
	onBlur?: (arg0: any) => void;
	closeProp?: boolean;
};

const useStyle = makeStyles(() => ({
	date: {
		color: theme.black.black60,
	},
}));

const DateInput = ({
	label,
	value,
	onChange,
	onBlur,
	inputProps,
	error = false,
	helperText = undefined,
	closeProp,
	...rest
}: Props) => {
	const dateClasses = useStyle();
	const [open, setOpen] = useState(false);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
			<DatePicker
				className='date-picker'
				label={label}
				value={value}
				ignoreInvalidInputs
				mask={'__-__-____'}
				inputFormat='dd-MM-yyyy'
				InputAdornmentProps={{
					onClick: () => setOpen(!open),
				}}
				open={open}
				onClose={() => setOpen(false)}
				componentsProps={{
					leftArrowButton: { className: dateClasses.date },
					rightArrowButton: { className: dateClasses.date },
					switchViewButton: { className: dateClasses.date },
				}}
				onChange={(newValue) => {
					onChange(newValue ?? null);
					onBlur && onBlur(newValue);
				}}

				renderInput={(params) => (
					<TextField
						helperText={helperText}
						error={error}
						fullWidth
						onBlur={(event) => {
							onBlur && onBlur(event.target.value);
							closeProp &&
								setOpen(false)


						}}
						{...params}
					/>
				)}
				{...rest}
			/>
		</LocalizationProvider>
	);
};

export default DateInput;
