import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

const CatalogScreen = () => {
	const { type } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (
			!(
				type === 'intelligent-ad' ||
				type === 'multichannel-storefront' ||
				type === 'faq'
			)
		) {
			navigate('/app/my-studio');
		}
	}, [type]);

	return (
		<div className='ff-catalog-screen'>
			<Outlet context={{ type }} />
		</div>
	);
};

export default CatalogScreen;
