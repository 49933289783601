import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import { CollapseItUpIcon, DropItDownIcon } from '../../../../assets/Icons';
import AutoComplete from '../../../../Components/AutoComplete';
import Checkbox from '../../../../Components/Checkbox';
import Note from '../../../../Components/Note';
import {
	setCallcutomercare,
	setChatWithAgent,
	setDropdownvalueCTA,
	setRaiseaticket,
} from '../../../../Features/configure/configureSlice';
import './CallToAction.scss';

const CallToAction = () => {
	const { chatwithagent, raiseaticket, callcustomercare, dropdownValueCTA } =
		useSelector((state: RootState) => state.configure);
	const dispatch = useAppDispatch();
	const [drop, setDrop] = useState(true);
	const dropdownOptions = [
		{ label: 'Service Now', value: 'Service Now' },
		{ label: 'Freshdesk', value: 'Freshdesk' },
		{ label: 'Zendesk', value: 'Zendesk' },
		{ label: 'Jira Service Desk', value: 'Jira Service Desk' },
		{ label: 'Custom API', value: 'Custom API' },
	];

	return (
		<div className={`ff-faq-calltoaction ${drop ? 'dropped' : ''}`}>
			<div className={`calltoaction-buttons`}>
				<div
					className={`header ${drop ? 'dropped' : ''}`}
					onClick={() => setDrop(!drop)}>
					<div>
						<h6>Call to Action Buttons</h6>
						<span onClick={() => setDrop(!drop)}>
							{drop ? <CollapseItUpIcon /> : <DropItDownIcon />}
						</span>
					</div>
				</div>
				{drop ? (
					<>
						<hr />
						<p className='specify-buttons'>
							Specify up to 3 buttons that will be shown with the answers to
							your FAQs
						</p>
						<Note severity='info' removeCloseIcon>
							Please note that at the moment, any changes you do to Call to
							Action buttons take effect only when you reimport your FAQs.
						</Note>
						<div className='chatwithagent'>
							<div>
								<Checkbox
									index={0}
									isChecked={chatwithagent}
									onValueChange={(value) => dispatch(setChatWithAgent(value))}
								/>
							</div>
							<div>
								<p className='body1'>Chat with Agent</p>
								<p className='caption'>
									Let your customer support team step in and take over from the
									automated support to help resolve complex issues.
								</p>
							</div>
						</div>
						<div className='raise-a-ticket'>
							<div>
								<Checkbox
									index={0}
									isChecked={raiseaticket}
									onValueChange={(value) => dispatch(setRaiseaticket(value))}
								/>
							</div>
							<div>
								<p className='body1'>Raise a Ticket</p>
								<p className='caption'>
									Let your customers raise a customer support ticket and share a
									ticket ID for tracking.
								</p>
							</div>
						</div>
						<div className='call-customer-care'>
							<div>
								<Checkbox
									index={0}
									isChecked={callcustomercare}
									onValueChange={(value) => dispatch(setCallcutomercare(value))}
								/>
							</div>
							<div>
								<p className='body1'>Call Customer Care</p>
								<p className='caption'>
									Redirect your customers to your on-call Customer Care
								</p>
							</div>
						</div>
						{raiseaticket[0] ? (
							<>
								<hr />

								<div className='ticket-management'>
									<div className='header'>
										<span className='body1'>
											Choose what all information should be collected from the
											user when they raise a ticket
										</span>
									</div>
									<div className='collect-name'>
										<Checkbox
											index={0}
											disabled
											isChecked={[true]}
											onValueChange={(value) => [true]}
										/>
										<span className='body1'>Name</span>
									</div>
									<div className='collect-email'>
										<Checkbox
											index={0}
											disabled
											isChecked={[true]}
											onValueChange={(value) => [true]}
										/>
										<span className='body1'>Email</span>
									</div>
									<div className='collect-issue-description'>
										<Checkbox
											index={0}
											disabled
											isChecked={[true]}
											onValueChange={(value) => [true]}
										/>
										<span className='body1'>Issue description</span>
									</div>
									<div className='management-system'>
										<h6>Select Ticket Management System</h6>
										<AutoComplete
											multiple={false}
											options={dropdownOptions}
											valueKey='value'
											labelKey='label'
											value={dropdownValueCTA}
											onChange={(value) => dispatch(setDropdownvalueCTA(value))}
										/>
									</div>
								</div>
							</>
						) : null}
					</>
				) : null}
			</div>
		</div>
	);
};

export default CallToAction;
