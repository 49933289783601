import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	dialogQueriesView,
	dialogViewAll,
	highestDropOffRates,
	SelecteddialogQuery,
	setActiveAnalyticsBot,
	topQueriesViewAll,
} from '../../../Features/Analytics/analyticsSlice';
import CalendarInput from '../../Analytics/calendar/calendarInput';
import CompletionPointoffDialog from '../../Analytics/CompletionPointOffDialog';
import Conversations from '../../Analytics/Conversations';
import ConversationTableFilter from '../../Analytics/conversationtableFilter';
import DialogAnalytics from '../../Analytics/DialogAnalytics';
import DialogMinicards from '../../Analytics/DiglogMinicards';
import HighestDropOffRate from '../../Analytics/Highestdropoffrates';
import MiniCards from '../../Analytics/MiniCards';
import TopQueries from '../../Analytics/TopQueries';
import UserResponseAction from '../../Analytics/userResponseAction';
import '../../Analytics/analytics.scss';
import { ViewAllTemplatesArrow } from '../../../assets/Icons';
import { loadState } from '../../../utils/utils';
import ChevronRight from '@mui/icons-material/ChevronRight';

function CaVsrAnalytics() {
	const { dialogQueriesanalytic, selectedTriggeredQuery } = useSelector(
		(state: RootState) => state.analytics
	);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const conversationalAdsUrl = '/app/analytics/intelligent-ads/bot';
	const virtualSalesRepsUrl = '/app/analytics/multichannel-storefronts/bot';
	const path = location.pathname;

	// const onClickDialog = () => {
	// 	dispatch(topQueriesViewAll(false));
	// 	dispatch(dialogViewAll(true));
	// 	dispatch(dialogQueriesView(false));
	// 	dispatch(highestDropOffRates(false));
	// 	dispatch(SelecteddialogQuery(''));
	// };

	const headingNav = [
		{
			path: '',
			heading: 'Analyze',
		},
		{
			path: '/conversationtable',
			heading: 'Conversations',
		},
		{
			path: '/viewall-topqueries',
			heading: 'Analyze  >  Query Ranking',
		},
		{
			path: '/viewall-highestdropoffrate',
			heading: 'Analyze   >   Highest drop off rates',
		},
	];
	const onClickBack = () => {
		dialogQueriesanalytic
			? dispatch(dialogQueriesView(false))
			: navigate('/app/analytics/intelligent-ads');
		dispatch(SelecteddialogQuery(''));
	};
	const selectedCaVsrBot = loadState('selectedCaVsrBot');
	return (
		<div
			className={`${
				path === conversationalAdsUrl + '/conversationtable' ||
				path === conversationalAdsUrl + '/viewall-topqueries' ||
				path === virtualSalesRepsUrl + '/viewall-topqueries' ||
				path === virtualSalesRepsUrl + '/conversationtable' ||
				path === conversationalAdsUrl + '/viewall-dialog' ||
				path === virtualSalesRepsUrl + '/viewall-dialog' ||
				path === conversationalAdsUrl + '/viewall-highestdropoffrate' ||
				path === virtualSalesRepsUrl + '/viewall-highestdropoffrate'
					? // (dialogQueriesanalytic && path === conversationalAdsUrl)
					  ' '
					: 'ff-overflow'
			} ff_analytics_container text-styles`}>
			<>
				<div
					className={`${
						path === conversationalAdsUrl + '/conversationtable' ||
						path === conversationalAdsUrl + '/viewall-topqueries' ||
						path === virtualSalesRepsUrl + '/conversationtable' ||
						path === virtualSalesRepsUrl + '/viewall-topqueries' ||
						path === conversationalAdsUrl + '/viewall-dialog' ||
						path === virtualSalesRepsUrl + '/viewall-dialog' ||
						(dialogQueriesanalytic && path === conversationalAdsUrl) ||
						(dialogQueriesanalytic && path === virtualSalesRepsUrl) ||
						path === conversationalAdsUrl + '/viewall-highestdropoffrate' ||
						path === virtualSalesRepsUrl + '/viewall-highestdropoffrate'
							? 'none-for-back-button'
							: 'display-for-back-button'
					} ff-analytics-back-button button1`}
					onClick={onClickBack}>
					<div className='back-icon'>
						<ViewAllTemplatesArrow />
					</div>
					Back
				</div>
				<div className='ff_Analyze_header'>
					<div className='ff_analyze_heading_nav'>
						<div style={{ marginRight: '12px' }}>
							<img src={selectedCaVsrBot.botIcon} />
						</div>
						{headingNav.map((head) => {
							return (
								(path === conversationalAdsUrl + head.path ||
									path === virtualSalesRepsUrl + head.path) && (
									<h4
										className='ff_analyze '
										onClick={() => {
											navigate('/app/analytics/intelligent-ads/bot');
											if (dialogQueriesanalytic) {
												dispatch(dialogQueriesView(false));
											}
										}}>
										<span
											className={`${
												path === conversationalAdsUrl + '/conversationtable' ||
												path === conversationalAdsUrl + '/viewall-topqueries' ||
												path === virtualSalesRepsUrl + '/viewall-topqueries' ||
												(dialogQueriesanalytic &&
													(path === conversationalAdsUrl ||
														path === virtualSalesRepsUrl)) ||
												path ===
													conversationalAdsUrl +
														'/viewall-highestdropoffrate' ||
												path ===
													virtualSalesRepsUrl + '/viewall-highestdropoffrate'
													? 'hover-for-bot-name'
													: ''
											} `}
											style={{
												fontWeight: `${
													(dialogQueriesanalytic &&
														(path === conversationalAdsUrl ||
															path === virtualSalesRepsUrl)) ||
													path ===
														conversationalAdsUrl + '/conversationtable' ||
													path === virtualSalesRepsUrl + '/conversationtable' ||
													path ===
														conversationalAdsUrl + '/viewall-topqueries' ||
													path ===
														virtualSalesRepsUrl + '/viewall-topqueries' ||
													path ===
														conversationalAdsUrl +
															'/viewall-highestdropoffrate' ||
													path ===
														virtualSalesRepsUrl + '/viewall-highestdropoffrate'
														? '300'
														: ''
												}`,
											}}>
											{selectedCaVsrBot.botName}
										</span>
									</h4>
								)
							);
						})}
						{(path === conversationalAdsUrl + '/conversationtable' ||
							path === virtualSalesRepsUrl + '/conversationtable') && (
							<h4 className='ff_analyze'>
								<ChevronRight />
								Conversations
							</h4>
						)}
						{(path === conversationalAdsUrl + '/viewall-topqueries' ||
							path === virtualSalesRepsUrl + '/viewall-topqueries') && (
							<h4 className='ff_analyze'>
								<ChevronRight />
								Top Queries
							</h4>
						)}

						{dialogQueriesanalytic ? (
							(path !== conversationalAdsUrl + '/viewall-highestdropoffrate' ||
								path !==
									virtualSalesRepsUrl + '/viewall-highestdropoffrate') && (
								<div className='ff_query'>
									<h4 className='ff_analyze'>
										<ChevronRight />
										<span
											className='hover-for-customer-journey'
											style={{ fontWeight: 100 }}
											onClick={() => {
												dispatch(dialogQueriesView(false));
												if (path === conversationalAdsUrl) {
													navigate(conversationalAdsUrl + '/viewall-dialog');
												} else if (path === virtualSalesRepsUrl) {
													navigate(virtualSalesRepsUrl + '/viewall-dialog');
												} else if (
													path ===
													conversationalAdsUrl + '/viewall-highestdropoffrate'
												) {
													navigate(conversationalAdsUrl + '/viewall-dialog');
												} else if (
													path ===
													virtualSalesRepsUrl + '/viewall-highestdropoffrate'
												) {
													navigate(conversationalAdsUrl + '/viewall-dialog');
												}
											}}>
											Customer Journey Analysis
										</span>
										<ChevronRight /> {selectedTriggeredQuery.triggeredQuery}
									</h4>
									{/* < className='ff_analyze '>
										
									</> */}
								</div>
							)
						) : path === conversationalAdsUrl + '/viewall-dialog' ||
						  path === virtualSalesRepsUrl + '/viewall-dialog' ? (
							<h4 className='ff_analyze'>
								<span
									onClick={() => {
										navigate('/app/analytics/intelligent-ads/bot');
										// dispatch(dialogQueriesView(false));
									}}
									style={{ fontWeight: '300' }}
									className='hover-for-bot-name'>
									{selectedCaVsrBot.botName}
								</span>
								<ChevronRight />
								Customer Journey Analysis
							</h4>
						) : (
							''
						)}
					</div>
					<div className='ff_inputFields'>
						{(path === conversationalAdsUrl + '/conversationtable' ||
							path === virtualSalesRepsUrl + '/conversationtable') && (
							<ConversationTableFilter />
						)}
						<CalendarInput />
					</div>
				</div>
				{(path === conversationalAdsUrl || path === virtualSalesRepsUrl) &&
					(dialogQueriesanalytic ? (
						<div className='ff-analytics-body'>
							<DialogMinicards />
							<HighestDropOffRate />
							<UserResponseAction />
							<CompletionPointoffDialog />
						</div>
					) : (
						<div className='ff-analytics-body'>
							<MiniCards />
							<Conversations />
							<TopQueries />
							<DialogAnalytics />
						</div>
					))}
			</>
			<Outlet />
		</div>
	);
}

export default CaVsrAnalytics;
