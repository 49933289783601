import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Modal from '../../../../../Components/Modal';
import Button from '../../../../../Components/Button';


const DeliveryCurrentModal = () => {
    const navigate = useNavigate();
    const context: any = useOutletContext();
    return (
        <Modal
            header='Your file will be ready in few minutes'
            onClose={() => navigate(-1)}
            actionButtons={
                <>
                    <Button onClick={() => navigate(-1)} buttonType='dark'>
                        Close
                    </Button>

                </>
            }>
            <div className='ff_delivery_current_chat'>
                You can close this pop up and access the generated file later by clicking on the download button next to the table again.
            </div>
        </Modal>
    );
};

export default DeliveryCurrentModal;
