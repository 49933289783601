import DataFetchAPI from '../../utils/Api';
import { helpBotMsgReq } from './model';


const message = async (data: helpBotMsgReq) => {
    return await DataFetchAPI(false, true, 'Bearer').post('/messages/', data);
};
const helpbotService = {
    message
};

export default helpbotService;