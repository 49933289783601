import DataFetchAPI from '../../utils/Api';
import { loadState } from '../../utils/utils';
import { ZOHOCRMSETUP, ZOHOUNLINK } from './modal';

const zohoCrm = async (data: ZOHOCRMSETUP) => {
	return await DataFetchAPI(
		false,
		true,
		'Bearer',
		'application/json',
		false
	).post(`/crm/tenant/${loadState('tenantId')}/zohoSetup`, data);
};

const unlinkZoho = async (data: ZOHOUNLINK) => {
	return await DataFetchAPI(
		false,
		true,
		'Bearer',
		'application/json',
		false
	).delete(`crm/tenant/${data.tenantId}/link/${data.linkCrmId}`);
};

const linkZohoDevConsole = async (data: { state: string; code: string }) => {
	const { state, code } = data;
	return await DataFetchAPI(
		false,
		true,
		'Bearer',
		'application/json',
		false
	).get(
		`/crm/tenant/${loadState(
			'tenantId'
		)}/zoho/callback?state=${state}&code=${code}`
	);
};

const zohoService = { zohoCrm, unlinkZoho, linkZohoDevConsole };

export default zohoService;
