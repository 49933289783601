import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { IndustryState, createVerticalState, getVerticalState } from './model';
// import fullScreenService from './fullScreenService';
import { FullScreenState } from './model';
export const initialState: FullScreenState = {
	introductoryHeading: 'This is the welcome message',
	introductoryBody: 'This is the Introductory text',
	welcomeCard: [],
	productResultCount: 3,
};

const fullScreenSlice = createSlice({
	name: 'fullScreenSlice',
	initialState,
	reducers: {
		setIntroductoryHeading(state, action) {
			state.introductoryHeading = action.payload;
		},
		setIntroductoryText(state, action) {
			state.introductoryBody = action.payload;
		},
		setWelcomeCard(state, action) {
			state.welcomeCard = action.payload;
		},
		setProductResultCount(state, action) {
			state.productResultCount = action.payload;
		},
	},
});

export const {} = fullScreenSlice.actions;
export default fullScreenSlice.reducer;
