import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../../Components/Modal';
import Button from '../../../Components/Button';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './UploadModule.scss';
import Input from '../../../Components/Input';
import InteractiveButton from '../../../Components/Button/InteractiveButton';
import Note from '../../../Components/Note';
import {
	uploadFile,
	setuploadFileApiStatus,
} from '../../../Features/Ingest/IngestSlice';
import {
	isHTMLTagsPresent,
	loadState,
	removeStateSession,
} from '../../../utils/utils';
import { RootState, useAppDispatch } from '../../../app/store';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { setStoredFunction } from '../FunctionStore';

const UploadModule = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const state = location.state;
	const [files, setFiles] = useState(state.files ?? []);
	const addMoreFilesRef = useRef(null);
	const [inputTitleValues, setInputTitleValues] = useState(
		files.map((obj: any) => {
			return { title: obj.name.replace('.pdf', ''), errorType: '' };
		}) ?? []
	);
	const [inputDescValues, setInputDescValues] = useState<string[]>(
		(Array.from({ length: files.length }).fill('') as string[]) ?? []
	);
	const [showAllErrorBanner, setShowAllErrorBanner] = useState(false);
	const [categorizedIndices, setCategorizedIndices] = useState({
		allMatch: [],
		zeroOrOneMatch: [],
		twoOrThreeMatch: [],
	});
	const { uploadFileApiStatus } = useSelector(
		(state: RootState) => state.ingest
	);
	const [updateError, setUpdateError] = useState(false);
	const tableContents = useRef<any>(null);
	const [height, setHeight] = useState(0);
	const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			if (tableContents.current) {
				// setHeight(tableContents.current.offsetHeight);
				const hasScrollbar =
					tableContents.current.scrollHeight >
					tableContents.current.clientHeight;
				setIsScrollbarVisible(hasScrollbar);
			}
		};

		const resizeObserver = new ResizeObserver(handleResize);
		if (tableContents.current) {
			resizeObserver.observe(tableContents.current);
		}

		return () => {
			if (tableContents.current) {
				resizeObserver.unobserve(tableContents.current);
			}
		};
	}, []);

	useEffect(() => {
		return () => {
			removeStateSession('navigationCount');
		};
	}, []);

	useEffect(() => {
		if (inputTitleValues.length) {
			const titles = inputTitleValues.map((obj: any) => obj.title.trim());
			const indices: any = {};
			titles.forEach((title: any, index: number) => {
				if (!indices[title.trim().toLowerCase()]) {
					indices[title.trim().toLowerCase()] = [index];
				} else {
					indices[title.trim().toLowerCase()].push(index);
				}
			});

			const newCategorizedIndices = {
				allMatch: [] as any,
				zeroOrOneMatch: [] as any,
				twoOrThreeMatch: [] as any,
			};

			Object.values(indices).forEach((indexArray: any) => {
				if (indexArray.length >= 3) {
					newCategorizedIndices.allMatch.push(indexArray);
				} else if (indexArray.length >= 2) {
					newCategorizedIndices.twoOrThreeMatch.push(indexArray);
				} else {
					newCategorizedIndices.zeroOrOneMatch.push(indexArray);
				}
			});
			if (
				JSON.stringify(newCategorizedIndices) !==
				JSON.stringify(categorizedIndices)
			) {
				setCategorizedIndices(newCategorizedIndices);
			}
		}
	}, [inputTitleValues, updateError]);

	useEffect(() => {
		if (showAllErrorBanner) {
			let allMatch = categorizedIndices.allMatch as Array<number[]>;
			let twoOrThreeMatch = categorizedIndices.twoOrThreeMatch as Array<
				number[]
			>;
			let zeroOrOneMatch = categorizedIndices.zeroOrOneMatch as Array<number[]>;

			setInputTitleValues((prevInputtitleValues: any) => {
				const newInputValues = [...prevInputtitleValues];
				allMatch &&
					allMatch.forEach((val: number[]) => {
						val.forEach((idx) => {
							return (newInputValues[idx] = {
								...newInputValues[idx],
								errorType:
									newInputValues[idx].title.trim() &&
									newInputValues[idx].title.length >= 5 &&
									newInputValues[idx].title.length <= 50
										? 'duplicates'
										: '',
							});
						});
					});
				twoOrThreeMatch &&
					twoOrThreeMatch.forEach((val: number[]) => {
						val.forEach((idx) => {
							return (newInputValues[idx] = {
								...newInputValues[idx],
								errorType:
									newInputValues[idx].title.trim() &&
									newInputValues[idx].title.length >= 5 &&
									newInputValues[idx].title.length <= 50
										? 'duplicates'
										: '',
							});
						});
					});
				zeroOrOneMatch &&
					zeroOrOneMatch.forEach((val: number[]) => {
						val.forEach((idx) => {
							return (newInputValues[idx] = {
								...newInputValues[idx],
								errorType: '',
							});
						});
					});
				return newInputValues;
			});
		} else {
			let zeroOrOneMatch = categorizedIndices.zeroOrOneMatch as Array<number[]>;
			setInputTitleValues((prevInputtitleValues: any) => {
				const newInputValues = [...prevInputtitleValues];
				zeroOrOneMatch &&
					zeroOrOneMatch.forEach((val: number[]) => {
						val.forEach((idx) => {
							return (newInputValues[idx] = {
								...newInputValues[idx],
								errorType: '',
							});
						});
					});
				return newInputValues;
			});
		}
	}, [showAllErrorBanner, categorizedIndices]);
	const checkdata = (e: React.ChangeEvent<HTMLInputElement>) => {
		try {
			setTimeout(() => {
				if (addMoreFilesRef && addMoreFilesRef.current) {
					const chosenfiles = Array.prototype.slice.call(e.target.files);
					let specialCharacterCheck = chosenfiles.filter((file) => {
						return isHTMLTagsPresent(file.name);
					});
					let inValidFiles = chosenfiles.filter((file) => {
						return file.type !== 'application/pdf';
					});
					let fileSize = chosenfiles.filter((file) => {
						let kb = file.size / 1024;
						let mb = kb / 1024;
						return mb > 5;
					});

					setStoredFunction(handleFiles);
					if (specialCharacterCheck.length) {
						let nonSpecialCharacterFiles = chosenfiles.filter((file) => {
							return !isHTMLTagsPresent(file.name);
						});
						navigate('invalid-file-name-error', {
							state: {
								files: nonSpecialCharacterFiles,
								fromUploadModule: true,
							},
						});
					} else {
						if (inValidFiles.length) {
							let validFiles = chosenfiles.filter((file) => {
								return file.type === 'application/pdf';
							});
							navigate('invalid-file-format-error', {
								state: { files: validFiles, fromUploadModule: true },
							});
						} else {
							if (fileSize.length) {
								let correctfileSize = chosenfiles.filter((file) => {
									let kb = file.size / 1024;
									let mb = kb / 1024;
									return mb < 5;
								});
								navigate('invalid-file-size-error', {
									state: { files: correctfileSize, fromUploadModule: true },
								});
							} else {
								handleFiles(chosenfiles);
							}
						}
					}
					//@ts-ignore
					addMoreFilesRef.current.value = '';
				}
			}, 800);
		} catch (error) {
			console.log(error);
		}
	};
	const handleFiles = (chosenfiles: any[]) => {
		setFiles([...files, ...chosenfiles]);
		setInputTitleValues((prevInputtitleValues: any) => [
			...prevInputtitleValues,
			...chosenfiles.map((obj: any) => {
				return { title: obj.name.replace('.pdf', ''), errorType: '' };
			}),
		]);
		setInputDescValues((prevInputtitleValues: any) => [
			...prevInputtitleValues,
			...chosenfiles.map((obj: any) => {
				return '';
			}),
		]);
	};
	useEffect(() => {
		let inputValue = inputTitleValues.filter(
			(val: any) => val.title.trim().length < 5 || val.title.trim().length > 50
		);
		let descValue = inputDescValues.filter(
			(val: any) => val.trim().length < 50 || val.trim().length > 250
		);
		let inputTitleCheckInvalid = inputTitleValues.filter((val: any) =>
			isHTMLTagsPresent(val.title.trim())
		);
		let descCheckInvalid = inputDescValues.filter((val: any) =>
			isHTMLTagsPresent(val.trim())
		);

		if (
			(categorizedIndices.allMatch && categorizedIndices.allMatch.length) ||
			(categorizedIndices.twoOrThreeMatch &&
				categorizedIndices.twoOrThreeMatch.length) ||
			descValue.length ||
			inputValue.length ||
			inputTitleCheckInvalid.length ||
			descCheckInvalid.length
		) {
			setShowAllErrorBanner(true);
		} else {
			setShowAllErrorBanner(false);
		}
	}, [categorizedIndices, inputTitleValues, inputDescValues]);

	const actionButtons = () => {
		return (
			<div className='upload-module-action-buttons-holder'>
				<div className='add-more'>
					<Button
						onClick={() => {
							// navigate(-1);
							// let multiuploadRef = document.querySelector(
							// 	'#ff-ingest-item-upload-button'
							// );
							// if (multiuploadRef) {
							// 	//@ts-ignore
							// 	multiuploadRef.click();
							// }
							if (addMoreFilesRef.current) {
								//@ts-ignore
								addMoreFilesRef.current.click();
							}
						}}
						buttonType='light'>
						Select more files
					</Button>
				</div>
				<div className='cancel-upload'>
					<Button
						onClick={() => {
							navigate(-`${Number(loadState('navigationCount'))}`);
						}}
						buttonType='light'>
						<p>Cancel</p>
					</Button>
					<Button
						loading={uploadFileApiStatus === 'loading'}
						onClick={() => {
							if (files.length && !showAllErrorBanner) {
								let filterFiles = inputTitleValues.filter(
									(item: any) => item.title && item.title.length > 0
								);
								let allFiles = filterFiles.map((value: any, index: number) => {
									return {
										file_name: files[index].name,
										title: value.title.trim(),
										description: inputDescValues[index],
									};
								});
								const formData = new FormData();
								formData.append('metadata', JSON.stringify(allFiles));
								for (let i = 0; i < files.length; i++) {
									formData.append('files', files[i]);
								}
								dispatch(
									uploadFile({
										botId: loadState('botId'),
										tenantId: loadState('tenantId'),
										files: formData,
									})
								);
							}
						}}>
						<p>Upload</p>
					</Button>
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (uploadFileApiStatus === 'success') {
			dispatch(setuploadFileApiStatus(''));
			navigate(-`${Number(loadState('navigationCount'))}`);
		}
	}, [uploadFileApiStatus]);
	useEffect(() => {}, [files, inputDescValues]);
	return (
		<>
			<div className='ff-upload-module'>
				<Modal
					onClose={() => console.log()}
					header='Upload Documents'
					actionButtons={actionButtons()}>
					<p>
						For better training of your AI, please provide a title and a brief
						summary for each of your documents.
					</p>
					{showAllErrorBanner && files.length ? (
						<Note severity='error' removeCloseIcon>
							Please ensure that all fields are filled in correctly
						</Note>
					) : null}
					{files.length ? (
						<div className='ff-files-holder'>
							<div className='table'>
								<div
									className={`table-row-head table-header ${
										isScrollbarVisible ? 'scroll-added' : ''
									}`}>
									<div className='table-cell-head caption'>
										<p className='body2'>File Name</p>
									</div>
									<div className='table-cell-head caption'>
										<p className='body2'>Title</p>
									</div>
									<div className='table-cell-head caption'>
										<p className='body2'>Summary</p>
									</div>
									<div className='close-icon'>
										<InteractiveButton
											icon={
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'>
													<g id='close'>
														<path
															id='Vector'
															d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
															fill='black'
															fill-opacity='0.6'
														/>
													</g>
												</svg>
											}
										/>
									</div>
								</div>
								<div className='table-contents' ref={tableContents}>
									{files.map((value: any, index: number) => {
										return (
											<div className='table-row' key={value.name + index}>
												<div className='table-cell'>
													<div className='icon-file-name'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='25'
															viewBox='0 0 24 25'
															fill='none'>
															<g id='description'>
																<path
																	id='Vector'
																	d='M8 16.5H16V18.5H8V16.5ZM8 12.5H16V14.5H8V12.5ZM14 2.5H6C4.9 2.5 4 3.4 4 4.5V20.5C4 21.6 4.89 22.5 5.99 22.5H18C19.1 22.5 20 21.6 20 20.5V8.5L14 2.5ZM18 20.5H6V4.5H13V9.5H18V20.5Z'
																	fill='#5531D3'
																/>
															</g>
														</svg>
														<Tooltip title={value.name}>
															<p className='body1'>{value.name}</p>
														</Tooltip>
													</div>
												</div>
												<div className='table-cell'>
													<div className='input-title-box'>
														<Input
															disAllowSpecialChar
															value={
																inputTitleValues &&
																inputTitleValues[index] &&
																inputTitleValues[index].title
															}
															placeholder='Title'
															error={
																inputTitleValues &&
																inputTitleValues[index].errorType !==
																	'duplicates' &&
																inputTitleValues[index].title.trim().length >=
																	5 &&
																inputTitleValues[index].title.trim().length <=
																	50
																	? false
																	: true
															}
															limit={50}
															errorText={
																inputTitleValues &&
																inputTitleValues[index].errorType !==
																	'duplicates' &&
																inputTitleValues[index].title.trim().length >=
																	5 &&
																inputTitleValues[index].title.trim().length <=
																	50
																	? ''
																	: inputTitleValues[index].title.trim()
																			.length < 5
																	? 'Minimum 5 characters required'
																	: 'The title must be unique'
															}
															onChange={(e) => {
																setInputTitleValues(
																	(prevInputTitleValues: any) => {
																		const newInputValues = [
																			...prevInputTitleValues,
																		];
																		newInputValues[index] = {
																			title: e.target.value,
																			errorType: '',
																		};
																		return newInputValues;
																	}
																);
															}}
															onBlur={() => {
																setInputTitleValues(
																	(prevInputTitleValues: any) => {
																		const newInputValues = [
																			...prevInputTitleValues,
																		];
																		newInputValues[index].title =
																			newInputValues[index].title.trim();
																		return newInputValues;
																	}
																);
															}}
														/>
													</div>
												</div>
												<div className='table-cell'>
													<div className='input-description-box'>
														<Input
															disAllowSpecialChar
															error={
																inputDescValues &&
																inputDescValues[index].trim().length >= 50 &&
																inputDescValues[index].trim().length <= 250
																	? false
																	: showAllErrorBanner === false
																	? false
																	: true
															}
															errorText={
																inputDescValues &&
																inputDescValues[index].trim().length >= 50 &&
																inputDescValues[index].trim().length <= 250
																	? ''
																	: inputDescValues[index].trim().length < 50
																	? 'Minimum 50 characters required'
																	: ''
															}
															placeholder='Summary'
															value={inputDescValues[index]}
															multiline
															maxRows={5}
															limit={250}
															onChange={(e) => {
																setInputDescValues((prevInputDescValues) => {
																	const newInputDescValues = [
																		...prevInputDescValues,
																	];
																	newInputDescValues[index] = e.target.value;
																	return newInputDescValues;
																});
															}}
															onBlur={() => {
																setInputDescValues((prevInputDescValues) => {
																	const newInputDescValues = [
																		...prevInputDescValues,
																	];
																	newInputDescValues[index] =
																		prevInputDescValues[index].trim();
																	return newInputDescValues;
																});
															}}
														/>
													</div>
												</div>
												<div
													className='close-icon'
													onClick={() => {
														let docs = files;
														let inputVals = [...inputTitleValues];
														docs.splice(index, 1);
														inputVals.splice(index, 1);
														let inputDescVals = [...inputDescValues];
														inputDescVals.splice(index, 1);
														setInputDescValues(inputDescVals);
														setInputTitleValues(inputVals);
														setFiles([...docs]);
														setUpdateError((prev) => !prev);
													}}>
													<InteractiveButton
														icon={
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'>
																<g id='close'>
																	<path
																		id='Vector'
																		d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
																		fill='black'
																		fill-opacity='0.6'
																	/>
																</g>
															</svg>
														}
													/>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					) : (
						<Note severity='error' removeCloseIcon>
							No file selected
						</Note>
					)}
				</Modal>
				<input
					style={{ display: 'none' }}
					id='ff-upload-module-input'
					type='file'
					multiple
					accept='application/pdf'
					onChange={checkdata}
					ref={addMoreFilesRef}
				/>
			</div>
			<Outlet />
		</>
	);
};

export default UploadModule;
