export function SelectedToggleIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='20'
			viewBox='0 0 40 20'>
			<g id='Group_410' data-name='Group 410' transform='translate(193 -553)'>
				<rect
					id='Rectangle_3796'
					width='40'
					height='20'
					fill='#6b7ef2'
					data-name='Rectangle 3796'
					rx='10'
					transform='translate(-193 553)'
				/>
				<circle
					id='Ellipse_31'
					cx='9'
					cy='9'
					r='9'
					fill='#fff'
					data-name='Ellipse 31'
					transform='translate(-172 554)'
				/>
			</g>
		</svg>
	);
}

export function UnSelectedToggleIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='20'
			viewBox='0 0 40 20'>
			<g id='Group_410' data-name='Group 410' transform='translate(193 -553)'>
				<rect
					id='Rectangle_3796'
					width='40'
					height='20'
					fill='#c8c8c8'
					data-name='Rectangle 3796'
					rx='10'
					transform='translate(-193 553)'
				/>
				<circle
					id='Ellipse_31'
					cx='9'
					cy='9'
					r='9'
					fill='#fff'
					data-name='Ellipse 31'
					transform='translate(-192 554)'
				/>
			</g>
		</svg>
	);
}

export function CalendarDateIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			id='prefix__date_range-24px'
			width='20'
			height='20'
			viewBox='0 0 20 20'>
			<path
				id='prefix__Path_1542'
				fill='#6c7ef2'
				d='M8 9.5H6.333v1.667H8zm3.333 0H9.667v1.667h1.667zm3.333 0H13v1.667h1.667zm1.667-5.833H15.5V2h-1.667v1.667H7.167V2H5.5v1.667h-.833a1.659 1.659 0 0 0-1.659 1.666L3 17a1.666 1.666 0 0 0 1.667 1.667h11.666A1.672 1.672 0 0 0 18 17V5.333a1.672 1.672 0 0 0-1.667-1.666zm0 13.333H4.667V7.833h11.666z'
				data-name='Path 1542'
				transform='translate(-.5 -.333)'
			/>
			<path
				id='prefix__Path_1543'
				fill='none'
				d='M0 0h20v20H0z'
				data-name='Path 1543'
			/>
		</svg>
	);
}

export function ClockIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			id='prefix__schedule-24px'
			width='20'
			height='20'
			viewBox='0 0 20 20'>
			<defs>
				<style> </style>
			</defs>
			<path
				id='prefix__Path_1544'
				d='M10.325 2a8.333 8.333 0 1 0 8.342 8.333A8.329 8.329 0 0 0 10.325 2zm.008 15A6.667 6.667 0 1 1 17 10.333 6.665 6.665 0 0 1 10.333 17z'
				className='prefix__cls-1'
				fill='#6c7ef2'
				data-name='Path 1544'
				transform='translate(-.333 -.333)'
			/>
			<path
				id='prefix__Path_1545'
				fill='none'
				d='M0 0h20v20H0z'
				data-name='Path 1545'
			/>
			<path
				id='prefix__Path_1546'
				d='M12.25 7H11v5l4.375 2.625L16 13.6l-3.75-2.225z'
				className='prefix__cls-1'
				fill='#6c7ef2'
				data-name='Path 1546'
				transform='translate(-1.833 -1.167)'
			/>
		</svg>
	);
}

export function EditIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'>
			<g id='prefix__edit-24px_14_' data-name='edit-24px (14)' opacity='0.6'>
				<path
					id='prefix__Path_1385'
					fill='none'
					d='M0 0h20v20H0z'
					data-name='Path 1385'
				/>
				<path
					id='prefix__Path_1386'
					d='M11.6 7.682l.716.716-7.045 7.046h-.715v-.716L11.6 7.682M14.4 3a.779.779 0 0 0-.544.226l-1.421 1.423 2.917 2.917 1.423-1.423a.775.775 0 0 0 0-1.1l-1.82-1.82A.764.764 0 0 0 14.4 3zm-2.8 2.481L3 14.083V17h2.917l8.6-8.6L11.6 5.481z'
					data-name='Path 1386'
				/>
			</g>
		</svg>
	);
}
export function RadioButtonChecked() {
	return (
		<svg
			data-name='radio_button_checked_black_24dp (2)'
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path data-name='Path 13860' d='M0 0h24v24H0z' fill='none' />
			<path
				data-name='Path 13861'
				d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
				fill='#3b44c4'
			/>
			<circle
				data-name='Ellipse 592'
				cx='5'
				cy='5'
				r='5'
				transform='translate(7 7)'
				fill='#3b44c4'
			/>
		</svg>
	);
}

export function RadioButtonUnchecked() {
	return (
		<svg
			data-name='radio_button_unchecked_black_24dp (2)'
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path data-name='Path 13862' d='M0 0h24v24H0z' fill='none' />
			<path
				data-name='Path 13863'
				d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
				fill='rgba(0,0,0,.6)'
			/>
		</svg>
	);
}

export function CheckBoxChecked() {
	return (
		<svg
			data-name='check_box_black_24dp (3)'
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path data-name='Path 13864' d='M0 0h24v24H0z' fill='none' />
			<path
				data-name='Path 13865'
				d='M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-9 14-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z'
				fill='#3b44c4'
			/>
		</svg>
	);
}

export function CheckBoxUnChecked() {
	return (
		<svg
			data-name='check_box_outline_blank_black_24dp (2)'
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path data-name='Path 13856' d='M0 0h24v24H0z' fill='none' />
			<path
				data-name='Path 13857'
				d='M19 5v14H5V5h14m0-2H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2z'
				fill='rgba(0,0,0,.6)'
			/>
		</svg>
	);
}
