import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getBotService from './BotService';
import {
	frozeBot,
	ClonedBot,
	getBotListData,
	DeleteBotId,
	GetBotConfig,
	ChangeBotSession,
	Secondary,
	Template,
	makeconfig,
	RENAME,
	makeBotFavourite,
	SelectedTemplate,
	botTemplateTypeForCatalog,
	getBotTemplateData,
	listOfBots,
} from './model';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { loadState } from '../../utils/utils';
import { TeamsState } from '../teams/TeamsSlice';

export interface ActiveBot {
	botId: number;
	botName: string;
	botIcon: string;
	froze: boolean;
	assigned: boolean;
	encBotId: string;
	lastModified: number;
	role: string;
	roleId: number;
	cavSRType: 'CA' | 'VSR';
	coverImage?: string;
}

export interface BotState {
	selectedTemplateBot: any;
	makeTemplateBotsFavouritestatus: string;
	isRenaming: string;
	isDeleting: string;
	isCloning: string;
	isArchiving: string;
	templateRes: any;
	templatestatus: string;
	templateLoader: boolean;
	createsecondaryres: any;
	BotList: listOfBots[];
	botId: number;
	selectedBot: any;
	activeBot: ActiveBot;
	status: string;
	botConfig: any;
	templateBotList: any;
	getTemplateBotsListstatus: string;
	CreateBotConfigstatus: string;
	createSecondarystatus: string;
	CreateBotConfigRes: any;
	getBotAssignedLoader: boolean;
	botTemplatesForCatalog: botTemplateTypeForCatalog[];
}

export const getBotsList = createAsyncThunk<
	any,
	getBotListData,
	{ rejectValue: string }
>('rba/v1/getBotAssigned', async (data, { rejectWithValue }) => {
	try {
		const response = await getBotService.getBotList(data);
		const botlist = response.data;

		switch (response.status) {
			case 200:
				return botlist;

			default:
				return rejectWithValue('Something went wrong! Try again later1');
		}
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const getTemplateBotsList = createAsyncThunk<
	any,
	getBotListData,
	{ rejectValue: string }
>(
	'/rest/getBotTemplate',
	async (data, { rejectWithValue, getState, dispatch }) => {
		try {
			const response = await getBotService.getTemplateBotList(data);
			const templatebotlist = response.data;
			if (response.status === 200) {
				const { bots } = getState() as { bots: BotState };

				bots.selectedTemplateBot !== '' &&
					dispatch(
						setSelectedTemplateBot(
							templatebotlist.filter((bot: any) => {
								return bot.templateId === bots.selectedTemplateBot.templateId;
							})[0]
						)
					);
			}
			switch (response.status) {
				case 200:
					return templatebotlist;

				default:
					return rejectWithValue('Something went wrong! Try again later1');
			}
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const getBotTemplateCatalog = createAsyncThunk<
	any,
	getBotTemplateData,
	{ rejectValue: string }
>('/rest/getBotTemplateCatalog', async (data, { rejectWithValue }) => {
	try {
		const response = await getBotService.getTemplateBotList(data);
		const templatebotlist = response.data;
		switch (response.status) {
			case 200:
				return templatebotlist;

			default:
				return rejectWithValue('Something went wrong! Try again later1');
		}
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});
export const makeTemplateBotsFavourite = createAsyncThunk<
	any,
	makeBotFavourite,
	{ rejectValue: string }
>(
	'/template/addTemplateToFavourites',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await getBotService.addBotFavourite(data);
			const templatebotlist = response.data;
			if (response.status === 200) {
				const { teams } = getState() as { teams: TeamsState };
				const { bots } = getState() as { bots: BotState };
				dispatch(getTemplateBotsList({ tenantId: teams.tenantId }));
				bots.selectedTemplateBot !== '' &&
					dispatch(
						setSelectedTemplateBot(
							bots.templateBotList.filter((bot: any) => {
								return bot.templateId === bots.selectedTemplateBot.templateId;
							})[0]
						)
					);
			}

			switch (response.status) {
				case 200:
					return templatebotlist;

				default:
					return rejectWithValue('Something went wrong! Try again later1');
			}
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const archiveBot = createAsyncThunk<
	void,
	frozeBot,
	{ rejectValue: string }
>('bot/freezebot', async (data, { rejectWithValue, getState, dispatch }) => {
	try {
		const response = await getBotService.ArchiveBot(data);
		if (
			(response.status === 202 || response.status === 200) &&
			response.data.response === 'Success'
		) {
			const { teams } = getState() as { teams: TeamsState };
			const { bots } = getState() as { bots: BotState };
			// here in the filter we are using selectedBot.botId instead of using data.botId because
			// In the BotService for the archiveBot, we are using two api calls for now.
			const ca_vsr_type = bots.BotList.filter(
				(bot) => bot.botId === parseInt(bots.selectedBot.botId)
			)[0].cavSRType;

			teams.tenantId &&
				dispatch(getBotsList({ tenantId: teams.tenantId, ca_vsr_type }));

			return;
		}
		return rejectWithValue('something went wrong!!!');
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const BotClone = createAsyncThunk<
	void,
	ClonedBot,
	{ rejectValue: string }
>(
	'template/cloneBot',
	async (data, { rejectWithValue, getState, dispatch }) => {
		try {
			const response = await getBotService.CloneBot(data);

			if (response.status === 200 && response.data.response === 'Success') {
				const { bots } = getState() as { bots: BotState };
				const { teams } = getState() as { teams: TeamsState };

				teams.tenantId &&
					dispatch(
						CreateSecondary({
							botDesc: bots.selectedBot.botName + ' clone',
							botId: response.data.id,
							bot_displayname: bots.selectedBot.botName + ' clone',
							bot_icon: bots.selectedBot.botIcon,
							bot_name: bots.selectedBot.botName + ' clone',
							isMultiLingualEnabled: false,
							tenantId: teams.tenantId,
							triggerName: 'botClone',
							// botType: bots.selectedBot.cavSRType,
						})
					);

				return;
			}
			return rejectWithValue('something went wrong');
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const DeletedBot = createAsyncThunk<
	void,
	DeleteBotId,
	{ rejectValue: string }
>('bot/deletebot', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await getBotService.DeleteBot(data);
		if (
			(response.status === 200 || response.status === 202) &&
			response.data.response === 'Success'
		) {
			const { teams } = getState() as { teams: TeamsState };
			const { bots } = getState() as { bots: BotState };
			const ca_vsr_type = bots.BotList.filter(
				(bot) => bot.botId === data.botId
			)[0].cavSRType;

			teams.tenantId &&
				dispatch(getBotsList({ tenantId: teams.tenantId, ca_vsr_type }));
			return;
		}
		return rejectWithValue('Something went wrong!!!');
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const BotRename = createAsyncThunk<any, RENAME, { rejectValue: string }>(
	'/bot/validateConfig',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await getBotService.RenameBot(data);
			if (response.status === 200 && response.data.response === 'Success') {
				const { bots } = getState() as { bots: BotState };
				const { teams } = getState() as { teams: TeamsState };
				teams.tenantId &&
					dispatch(
						CreateSecondary({
							botDesc: data.fieldValue,
							botId: bots.selectedBot.botId,
							bot_displayname: data.fieldValue,
							bot_icon: bots.selectedBot.botIcon,
							bot_name: data.fieldValue,
							isMultiLingualEnabled: false,
							tenantId: teams.tenantId,
							triggerName: 'botRename',
							// botType: bots.selectedBot.cavSRType,
						})
					);

				return { botId: bots.selectedBot.botId, name: data.fieldValue };
			}
			return rejectWithValue('something went wrong!!!');
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const makeTemplate = createAsyncThunk<
	any,
	Template,
	{ rejectValue: string }
>('/template/createBotToTemplate', async (data, { rejectWithValue }) => {
	try {
		const response = await getBotService.makeTemplate(data);
		switch (response.status) {
			case 200:
				return response;
			default:
				return rejectWithValue('Something went wrong! Try again later1');
		}
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const CreateSecondary = createAsyncThunk<
	any,
	Secondary,
	{ rejectValue: string }
>(
	'/bot/createSecondary',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const { teams } = getState() as { teams: TeamsState };
			const response = await getBotService.createSecondary(data);
			if (response.data.response === 'Success') {
				const { bots } = getState() as { bots: BotState };
				const ca_vsr_type =
					data.triggerName === 'botClone'
						? window.location.pathname.indexOf('intelligent-ad') > -1
							? 'CA'
							: 'VSR'
						: bots.BotList.filter((bot) => bot.botId === data.botId)[0]
								.cavSRType;
				teams.tenantId &&
					dispatch(getBotsList({ tenantId: teams.tenantId, ca_vsr_type }));
				return { trigger: data.triggerName };
			}
			return rejectWithValue(JSON.stringify({ trigger: data.triggerName }));
		} catch (error: any) {
			return rejectWithValue(JSON.stringify({ trigger: data.triggerName }));
		}
	}
);

export const CreateBotConfig = createAsyncThunk<
	any,
	makeconfig,
	{ rejectValue: string }
>(
	'/bot/createBotConfigurations',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const { tenantId } = useSelector((state: RootState) => state.teams);

			const response = await getBotService.CreateBotConfig(data);
			if (response.data) {
				const { bots } = getState() as { bots: BotState };
				const ca_vsr_type = bots.BotList.filter(
					(bot) => bot.botId === data.botId
				)[0].cavSRType;

				tenantId && dispatch(getBotsList({ tenantId: tenantId, ca_vsr_type }));
				return response;
			}

			return rejectWithValue('Something went wrong! Try again later1');
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const getBotConfig = createAsyncThunk<
	any,
	GetBotConfig,
	{ rejectValue: string }
>('bot/getBotConfig', async (data, { rejectWithValue }) => {
	try {
		const response = await getBotService.getBotConfig(data);
		if (response.status === 200) return response.data;
		return rejectWithValue('Something went wrong! Try again later1');
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const changeBotSession = createAsyncThunk<
	any,
	ChangeBotSession,
	{ rejectValue: string }
>('bot/changeBotSession', async (data, { rejectWithValue }) => {
	try {
		const response = await getBotService.changeBotSession(data);
		if (response.status === 200) return response.data;
		return rejectWithValue('Something went wrong! Try again later1');
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

const botDetails: any = loadState('botDetails') ?? {};

const initialState: BotState = {
	selectedTemplateBot: '',
	makeTemplateBotsFavouritestatus: '',
	templateRes: {
		data: null,
		error: null,
		id: 0,
		response: '',
	},
	templatestatus: '',
	templateLoader: false,
	createSecondarystatus: '',
	createsecondaryres: {
		data: '',
		error: '',
		id: 0,
		response: '',
	},
	BotList: [],
	templateBotList: [],
	botId: 0,
	selectedBot: {},
	activeBot: botDetails,
	status: 'idle',
	botConfig: null,
	getTemplateBotsListstatus: '',
	CreateBotConfigstatus: '',
	CreateBotConfigRes: {
		data: '',
		error: '',
		id: 0,
		response: '',
	},
	getBotAssignedLoader: false,
	isRenaming: '',
	isDeleting: '',
	isCloning: '',
	isArchiving: '',
	botTemplatesForCatalog: [],
};

export const BotSlice = createSlice({
	name: 'botslist',
	initialState,
	reducers: {
		setSelectedTemplateBot: (state, action) => {
			state.selectedTemplateBot = action.payload;
		},
		resetIsRenaming: (state) => {
			state.isRenaming = '';
		},
		resetIsDeleting: (state) => {
			state.isDeleting = '';
		},
		resetIsCloning: (state) => {
			state.isCloning = '';
		},
		resetIsArchiving: (state) => {
			state.isArchiving = '';
		},
		botList: (state, action) => {
			state.BotList = action.payload.botList;
			state.botId = action.payload.botID;
		},
		selectedBot: (state, action) => {
			state.selectedBot = action.payload;
		},
		setActiveBot: (state, action) => {
			state.activeBot = action.payload;
		},
		resetBotActions: (state) => {
			state.createSecondarystatus = '';
			state.getTemplateBotsListstatus = '';
			state.CreateBotConfigstatus = '';
			state.templatestatus = '';
			state.templateLoader = false;
			state.templateRes = {
				data: null,
				error: null,
				id: 0,
				response: '',
			};
			state.createsecondaryres = {
				data: '',
				error: '',
				id: 0,
				response: '',
			};
		},
		resetBotList: (state) => {
			state.BotList = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getBotsList.pending, (state) => {
				state.getBotAssignedLoader = true;
			})
			.addCase(getBotsList.fulfilled, (state, action) => {
				state.getBotAssignedLoader = false;
				state.BotList = action.payload.botList;
			})
			.addCase(getBotsList.rejected, (state) => {
				state.getBotAssignedLoader = false;
			})
			.addCase(makeTemplateBotsFavourite.pending, (state) => {
				state.makeTemplateBotsFavouritestatus = 'Loading';
			})
			.addCase(makeTemplateBotsFavourite.fulfilled, (state, action) => {
				state.makeTemplateBotsFavouritestatus = 'Success';
			})
			.addCase(makeTemplateBotsFavourite.rejected, (state) => {
				state.makeTemplateBotsFavouritestatus = 'Failed';
			})
			.addCase(getTemplateBotsList.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(getTemplateBotsList.fulfilled, (state, action) => {
				state.getTemplateBotsListstatus = 'Success';
				state.templateBotList = action.payload;
			})
			.addCase(getTemplateBotsList.rejected, (state) => {
				state.status = 'failed';
			})
			.addCase(makeTemplate.pending, (state) => {
				state.templateLoader = true;

				state.templatestatus = 'loading';
			})
			.addCase(makeTemplate.fulfilled, (state, action) => {
				state.templateLoader = false;
				state.templatestatus = 'Success';
				state.templateRes = action.payload.data;
			})
			.addCase(makeTemplate.rejected, (state) => {
				state.templateLoader = false;

				state.templatestatus = 'failed';
			})
			.addCase(archiveBot.pending, (state) => {
				state.isArchiving = 'archiving';
			})
			.addCase(archiveBot.fulfilled, (state) => {
				state.isArchiving = 'archived';
			})
			.addCase(archiveBot.rejected, (state) => {
				state.isArchiving = 'failed';
			})
			.addCase(BotClone.pending, (state) => {
				state.isCloning = 'cloning';
			})
			.addCase(BotClone.fulfilled, (state) => {
				state.isCloning = 'cloned';
			})
			.addCase(BotClone.rejected, (state, action) => {
				state.isCloning = 'failed';
			})
			.addCase(DeletedBot.pending, (state) => {
				state.isDeleting = 'deleting';
			})
			.addCase(DeletedBot.fulfilled, (state) => {
				state.isDeleting = 'deleted';
			})
			.addCase(DeletedBot.rejected, (state) => {
				state.isDeleting = 'failed';
			})
			.addCase(BotRename.pending, (state) => {
				state.isRenaming = 'renaming';
			})
			.addCase(BotRename.fulfilled, (state, action) => {
				state.BotList = state.BotList.map((bot: any) =>
					bot.botId == action.payload.botId
						? { ...bot, botName: action.payload.name }
						: bot
				);
			})
			.addCase(BotRename.rejected, (state) => {
				state.isRenaming = 'failed';
			})
			.addCase(getBotConfig.pending, (state) => {
				state.botConfig = null;
			})
			.addCase(getBotConfig.fulfilled, (state, action) => {
				state.botConfig = action.payload;
			})
			.addCase(getBotConfig.rejected, (state) => {
				state.botConfig = null;
			})
			.addCase(CreateSecondary.fulfilled, (state, action) => {
				if (action.payload.trigger === 'botRename')
					state.isRenaming = 'renamed';
				else if (action.payload.trigger === 'botClone')
					state.isCloning = 'cloned';
			})
			.addCase(CreateSecondary.rejected, (state, action) => {
				if (action.payload) {
					const trigger = JSON.parse(action.payload);

					if (trigger === 'botRename') state.isRenaming = 'failed';
					else if (trigger === 'botClone') state.isCloning = 'failed';
				}
			})
			.addCase(CreateBotConfig.pending, (state) => {
				// state.status = 'loading';
			})
			.addCase(CreateBotConfig.fulfilled, (state, action) => {
				state.CreateBotConfigstatus = 'Success';
				state.CreateBotConfigRes = action.payload;
			})
			.addCase(CreateBotConfig.rejected, (state) => {
				// state.status = 'failed';
			});
	},
});

export const {
	setSelectedTemplateBot,
	botList,
	selectedBot,
	setActiveBot,
	resetBotActions,
	resetIsRenaming,
	resetIsDeleting,
	resetIsCloning,
	resetIsArchiving,
	resetBotList,
} = BotSlice.actions;

export default BotSlice.reducer;
