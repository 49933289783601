import DataFetchAPI from '../../utils/Api';
import {
	GetProductDetailsCS,
	inputChatReq,
	modifyremoveDetails,
	ProductFFReq,
	ProductReq,
	ProductsCSReq,
	SearchProductReq,
	WooCommReq,
} from './model';
import DataFetchShopifyAPI from '../../utils/ShopifyApi';
import { loadState } from '../../utils/utils';

const getProductsCS = async (data: ProductsCSReq) => {
	const { tenantId, limit, page } = data;
	return await DataFetchShopifyAPI(false, true, 'Bearer').get(
		`/${tenantId}/products?limit=${limit}&page=${page}`
	);
};

const getProductDetailsCS = async (data: GetProductDetailsCS) => {
	const { productId, tenantId } = data;
	return await DataFetchShopifyAPI(false, true, 'Bearer').get(
		`/${tenantId}/product?productId=${productId}&country=${loadState(
			'country'
		)}`
	);
};

const getProducts = async (data: ProductReq) => {
	const { callFrom, ...rest } = data;
	return await DataFetchShopifyAPI(false, true, 'Bearer').post(
		'/product/search/getProducts',
		{ ...rest }
	);
};

const getFFProducts = async (data: ProductFFReq | WooCommReq) => {
	return await DataFetchShopifyAPI(false, true, 'Bearer').post(
		'/product/search/getProducts',
		data
	);
};

const commerceLinkStatus = async (data: any) => {
	return await DataFetchShopifyAPI(false, true, 'Bearer').get('/ecom/status', {
		params: data,
	});
};

const getSearchedProducts = async (data: SearchProductReq) => {
	return await DataFetchShopifyAPI(false, true, 'Bearer').get(
		'/product/search/getAll',
		{
			params: data,
		}
	);
};

const getChatAPI = async (data: inputChatReq) => {
	return await DataFetchAPI(false, true, 'Bearer').post(
		'/route/messages/',
		data
	);
};
const getChatDEVAPI = async (data: inputChatReq) => {
	return await DataFetchAPI(false, true, 'Bearer').post(
		'/route/messages/',
		data
	);
};

const getProductIdDetails = async (data: GetProductDetailsCS) => {
	return await DataFetchShopifyAPI(false, true, 'Bearer').get(
		`/product/getDetails?productId=${data.productId}`
	);
};

const getSalesBot = async (data: {
	tenantId: number | null;
	type?: string;
	ca_vsr_type?: string;
}) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		'/rba/v1/getBotAssigned',
		{
			params: data,
		}
	);
};

const removedCartDetails = async (data: modifyremoveDetails) => {
	return await DataFetchAPI().post(
		'/route/shop/product/cart/removeItemsFromCart',
		data
	);
};
const salesService = {
	getProducts,
	commerceLinkStatus,
	getSearchedProducts,
	getSalesBot,
	getProductIdDetails,
	getChatAPI,
	getChatDEVAPI,
	removedCartDetails,
	getFFProducts,
	getProductsCS,
	getProductDetailsCS,
};

export default salesService;
