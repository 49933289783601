import styled from 'styled-components';
import { hexToRGB } from '../../../../../utils/utils';
import {
	ChatBotButtonDivProps,
	ChatBotMessageProps,
	ChatBotOptionProps,
	ChatBotTimeProps,
	ChatBubbleProps,
	ChatThumbsProps,
	ChatUserMessageProps,
	ChatUserTimeProps,
} from './model';

export const ChatBubbleDiv = styled.div<ChatBubbleProps>`
	align-self: ${(props) => props.messageby === 'user' && 'flex-end'};
`;

export const ChatBotMessage = styled.div<ChatBotMessageProps>`
	max-width: ${(props) => (props.carousel ? '' : '222px')};
	background-color: ${(props) =>
		props.carousel ? '' : props.botMessageBubbleColor ?? 'var(--button-blue)'};
	border-radius: 20px;
	width: fit-content;
	position: relative;
	.bot-message {
		padding: 12px 20px;
		font-size: inherit;
		font-family: inherit;
		line-height: 1.45;
		color: ${(props) => props.botMessageTextColor ?? 'var(--white)'};
	}
	.bot-message-image-display,
	.bot-message-image-display-carousel {
		border-radius: 50%;
		position: absolute;
		width: 28px;
		height: 28px;
		bottom: 96%;
		object-fit: cover;
	}
	.bot-message-image-display-carousel {
		bottom: 98%;
	}
`;

export const ChatBotOptionsDiv = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-width: 230px;
	margin-top: 14px;
	margin-bottom: 12px;
`;
export const ChatBotOption = styled.div<ChatBotOptionProps>`
	max-width: 104px;
	padding: 8px 14px 7px 15px;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 28%);
	border: solid 1px
		${(props) =>
			props.optionsAndQuickLinksBorderColor
				? hexToRGB(
						props.optionsAndQuickLinksBorderColor,
						parseInt(props.optionsAndQuickLinksBorderRange) / 100
				  )
				: 'rgba(0, 0, 0, 0.1)'};
	border-radius: 16px;
	background-color: ${(props) =>
		props.optionsAndQuickLinksBckColor
			? hexToRGB(
					props.optionsAndQuickLinksBckColor,
					parseInt(props.optionsAndQuickLinksBckRange) / 100
			  )
			: 'var(--white)'};
	margin-right: 11px;
	margin-bottom: 15px;
	color: ${(props) => props.optionsAndQuickLinksTextColor ?? '#444760'};
	cursor: not-allowed;
	&:hover {
		box-shadow: 0 4px 8px rgb(0 0 0 / 28%);
	}
`;

export const ChatBotButtonDiv = styled.div<ChatBotButtonDivProps>`
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-bottom: 8px;
	& button {
		font-size: inherit;
		font-family: inherit;
	}
	button:nth-of-type(1) {
		color: ${(props) => props.buttonTextColor ?? 'var(--action-colour)'};
		background-color: ${(props) => props.buttonBckColor ?? 'var(--white)'};
	}
	button:nth-of-type(2) {
		color: ${(props) => props.buttonTextAndOutlineColor ?? 'var(--white)'};
		border: 1px solid
			${(props) => props.buttonTextAndOutlineColor ?? 'var(--white)'};
	}
	button:nth-of-type(3) {
		color: ${(props) => props.buttonTextAndOutlineColor ?? 'var(--white)'};
	}
`;

export const ChatBotButton = styled.button`
	cursor: not-allowed;
	height: 36px;
	padding: 0 24px;
	min-width: 48px;
	border-radius: 18px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
	margin-top: 16px;
	white-space: nowrap;
	font-weight: 600 !important;
	&.text-only-button {
		box-shadow: unset;
	}
`;

export const ChatThumbs = styled.div<ChatThumbsProps>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 12px;
	cursor: not-allowed;
	border: solid 1px
		${(props) =>
			hexToRGB(
				props.responseLevelFeedBackBorderColor,
				parseInt(props.responseLevelFeedBackBorderRange) / 100
			) ?? 'var(--gray-4)'};
	border-radius: 16px;
	width: 76px;
	height: 36px;
	margin-top: 8px;
	background-color: ${(props) =>
		hexToRGB(
			props.responseLevelFeedBackBckColor,
			parseInt(props.responseLevelFeedBackBckRange) / 100
		) ?? ''};
`;

export const ChatBotTime = styled.div<ChatBotTimeProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 76px;
	height: 11px;
	margin-top: 12px;
	margin-bottom: 24px;
	> p {
		border-radius: 20px;
		padding: 6px;
		font-size: ${(props) =>
			parseInt(props.fontSize.split('p')[0]) - 8 + 'px' ?? '8px'};
		color: ${(props) =>
			hexToRGB(
				props.timeStampTextColor,
				parseInt(props.timeStampTextRange) / 100
			)};

		background-color: ${(props) =>
			hexToRGB(
				props.timeStampBckColor,
				parseInt(props.timeStampBckRange) / 100
			)};
	}
`;

export const ChatUserTime = styled.div<ChatUserTimeProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 11px;
	margin-top: 12px;
	margin-bottom: 24px;
	> p {
		border-radius: 20px;
		padding: 6px;

		font-size: ${(props) =>
			parseInt(props.fontSize.split('p')[0]) - 8 + 'px' ?? '8px'};
		color: ${(props) =>
			hexToRGB(
				props.timeStampTextColor,
				parseInt(props.timeStampTextRange) / 100
			)};

		background-color: ${(props) =>
			hexToRGB(
				props.timeStampBckColor,
				parseInt(props.timeStampBckRange) / 100
			)};
	}
`;

export const ChatUserMessage = styled.div<ChatUserMessageProps>`
	background-color: ${(props) =>
		props.userMessageBubbleColor ?? 'var(--white)'};
	border-radius: 20px;
	max-width: 263px;
	.user-message {
		padding: 12px 20px;
		font-size: inherit;
		font-family: inherit;
		line-height: 1.3;
		color: ${(props) => props.userMessageTextColor ?? 'var(--action-colour)'};
	}
`;
