import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import Dropdown, { DropdownValue } from '../../../../Components/Dropdown';
import CalendarInput from '../../calendar/calendarInput';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {
	getAllAgentsLiveChat,
	getOverViewAnalyticsLiveChat,
} from '../../../../Features/Analytics/analyticsSlice';
import './OverView.scss';
import { MedicalInformationOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

type Props = {
	selectedSource: { name: string; id: number; deviceInfo?: string };
	selectedAgent: { name: string; id: string };
	selectedTenant: { name: string; id: string };
	startDate: any;
	endDate: any;
	utcstartDate: any;
	utcendDate: any;
};

const OverView = ({ selectedAgent }: Props) => {
	const [newOngoingCardList, setNewOngoingCardList] = useState<any[]>([]);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const convertSecondstoTime = (secs: number) => {
		// given_seconds = 3685;

		let dateObj = new Date(secs * 1000);
		let minutes = dateObj.getUTCMinutes();
		let seconds = dateObj.getSeconds();
		let timeString;
		if (minutes !== 0o0) {
			timeString =
				minutes.toString().padStart(2, '0') +
				' min ' +
				seconds.toString().padStart(2, '0') +
				' sec ';
		} else {
			timeString = seconds.toString().padStart(2, '0') + ' sec ';
		}
		return timeString;
	};

	const list = [
		'Total Handoffs',
		'Automatic Handoffs',
		'Handoffs on User Request',
		'Handoffs Initiated by Agents',
		'Avg. Waiting Time for Users',
		'Avg. Agent Response Time',
	];
	const dispatch = useDispatch<any>();
	const {
		selectedDates,
		getOverViewAnalyticsLiveChatData,
		calendarstatus,
		analyticspreMiniCardData,
		currentMiniCardData,
	} = useSelector((state: RootState) => state.analytics);

	const [loader, setLoader] = useState<Boolean>(true);
	const navigate = useNavigate();
	const { total_convo, unique_user, avgFeedback, avg_chat_dur } =
		currentMiniCardData;

	const setDataDisplay = (response: any) => {
		// console.log('overview response', response);
		var newState = list.map((card: string) => {
			switch (card) {
				case 'Total Handoffs':
					return {
						title: card,
						count: response.ResData.TotalHandoffs,
						def: response.ResData.TotalHandoffsDef,
					};
				case 'Automatic Handoffs':
					return {
						title: card,
						count: response.ResData.AutomaticHandoff,
						def: response.ResData.AutomaticHandoffDef,
					};
				case 'Handoffs on User Request':
					return {
						title: card,
						count: response.ResData.HandoffByUser,
						def: response.ResData.HandoffByUserDef,
					};
				case 'Handoffs Initiated by Agents':
					return {
						title: card,
						count: response.ResData.AgentTakeOver,
						def: response.ResData.AgentTakeOverDef,
					};

				case 'Avg. Waiting Time for Users':
					return {
						title: card,
						count:
							response.ResData.AvgUserWaitTimeInQueue === '-'
								? '-'
								: convertSecondstoTime(response.ResData.AvgUserWaitTimeInQueue),
						def: response.ResData.AvgUserWaitTimeInQueueDef,
					};
				case 'Avg. Agent Response Time':
					return {
						title: card,
						count:
							response.ResData.AvgAgentResponseTime === '-'
								? '-'
								: convertSecondstoTime(response.ResData.AvgAgentResponseTime),
						def: response.ResData.AvgAgentResponseTimeDef,
					};
			}
		});

		setNewOngoingCardList(newState);
		setLoader(false);
	};

	const setData = () => {
		if (Object.keys(getOverViewAnalyticsLiveChatData).length) {
			setDataDisplay(getOverViewAnalyticsLiveChatData);
		}
	};
	useEffect(() => {
		const agentId = selectedAgent.id;
		//const selectedTenantId=selectedTenant.id;
		if (calendarstatus) {
			tenantId &&
				dispatch(
					getOverViewAnalyticsLiveChat({
						start: selectedDates[0],
						end: selectedDates[1],
						agentId,
						tenantId: tenantId.toString(),
					})
				);
			// setData();
		}
	}, [selectedDates]);
	useEffect(() => {
		setData();
	}, [getOverViewAnalyticsLiveChatData]);
	const getAllAgentData = () => {
		tenantId &&
			dispatch(
				getAllAgentsLiveChat({
					type: 'all',
					tenantId: tenantId,
				})
			);
	};
	const onchange = (feild: any) => {
		setSelectedField(feild);
		getAllAgentData();
	};

	const options = [
		{
			label: (
				<div className='action-label'>
					<p>All Agents</p>
				</div>
			),
			value: 'All Agents',
		},
		{
			label: (
				<div className='action-label'>
					<p>Chitra S</p>
				</div>
			),
			value: 'Chitra S',
		},
		{
			label: (
				<div className='action-label'>
					<p>Elamathi</p>
				</div>
			),
			value: 'Elamathi',
		},
		{
			label: (
				<div className='action-label'>
					<p>Antony Surya</p>
				</div>
			),
			value: 'Antony Surya',
		},
	];
	const [selectedfield, setSelectedField] = useState<DropdownValue | any>(
		options[0]
	);

	return (
		<div
			style={{ position: 'relative' }}
			className='ff_livechat_analytics_overview'>
			<div className='header-main-over'>
				<h5 style={{ marginTop: '81.5px' }}>Overview</h5>
				<div className='agent-dropDown'>
					{' '}
					<Dropdown
						value={selectedfield}
						onChange={(feild) => onchange(feild)}
						options={options}
						className='overView'
					/>
				</div>
			</div>
			<div className='calender'>
				<CalendarInput />
			</div>

			<div className='stats-wrapper-over'>
				{newOngoingCardList.map((card: any, index: number) => {
					return (
						<div key={index} className='stat-over over-stat'>
							<div className='title'>{card.title} </div>
							<div className='count' title={card.def}>
								{card.count}
							</div>
							{![
								'Avg. Waiting Time for Users',
								'Avg. Agent Response Time',
							].includes(card.title) && (
								<div
									className='list'
									onClick={() => {
										navigate(
											'/app/analytics/intelligent-ads/bot/conversationtable'
										);
									}}>
									<FormatListBulletedIcon color='primary' fontSize='inherit' />{' '}
									View list
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default OverView;
