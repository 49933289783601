import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import Modal from '../../../../Components/Modal';
import { AppearanceUpload, AppearanceURL } from '../../../../assets/Icons';
import { loadState } from '../../../../utils/utils';
import './AddImage.scss';
import { isImage } from '../../../../utils/utils';
import { setSnackModal } from '../../../../Features/message/messageSlice';
import {
	setBackgroundFile,
	setBotBackground,
	setHeaderContentType,
	setHeaderFile,
	setShowAvatar,
	setWelcomBannerFile,
	setWelcomeBannerCheckbox,
	setWelcomeCarouselFile,
	setWelcomeCarouselFileFullScreen,
	setWelcomeMonacoImage,
	setWelcomeEcomImage,
	uploadImage,
	setEcomlogo
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';

const AddImageEOW = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const state = location.state as { value: string };
	const path = useLocation().pathname;
	const welcomeRef = useRef<HTMLInputElement>(null!);
	const botMessageRef = useRef<HTMLInputElement>(null!);
	const botHeaderRef = useRef<HTMLInputElement>(null!);
	const backgroundRef = useRef<HTMLInputElement>(null!);
	const carouselRef = useRef<HTMLInputElement>(null!);
	const carouselRefFullScreen = useRef<HTMLInputElement>(null!);
	const monacoRefFullscreen = useRef<HTMLInputElement>(null!);
	const ecomRefFullscreen = useRef<HTMLInputElement>(null!);
	const ecomRefFullscreenLogo = useRef<HTMLInputElement>(null!);
	const [throughURL, setThroughURL] = useState(false);
	const [welcomeValue, setWelcomeValue] = useState('');
	const [botHeaderValue, setBotHeaderValue] = useState('');
	const [backgroundImageValue, setBackgroundImageValue] = useState('');
	const [welcomeCarousel, setWelcomeCarousel] = useState('');
	const [fullScreenWelcomeCarousel, setFullScreenWelcomeCarousel] = useState('')
	const [fullScreenWelcomeMonaco, setFullScreenWelcomeMonaco] = useState('')
	const [fullScreenWelcomeEcom, setFullScreenWelcomeEcom] = useState('')
	const [fullScreenWelcomeEcomLogo, setFullScreenWelcomeEcomLogo] = useState('')
	const [addThroughURLWB, setAddThroughURLWB] = useState(false);
	const [addThroughURLBH, setAddThroughURLBH] = useState(false);
	const [addThroughURLBI, setAddThroughURLBI] = useState(false);
	const [addThroughURLWC, setAddThroughURLWC] = useState(false);
	const [errorURL, setErrorURL] = useState(false);
	const [addThroughURLFullScreenWC, setAddThroughURLFullScreenWC] = useState(false)
	const [addThroughURLFullScreenMonaco, setAddThroughURLFullScreenMonaco] = useState(false)
	const [addThroughURLFullScreenEcom, setAddThroughURLFullScreenEcom] = useState(false)
	const [addThroughURLFullScreenEcomLogo, setAddThroughURLFullScreenEcomLogo] = useState(false)
	const { tenantId, bot_AI_ID } = useSelector(
		(state: RootState) => state.teams
	);
	const { welcomeBannerCheckbox, showavatar } = useSelector(
		(state: RootState) => state.experienceonweb
	);

	const pattern =
		new RegExp("^(http|https)://", "i");
	const uploadCancel = () => {
		state.value === 'WelcomeBanner' &&
			welcomeBannerCheckbox[0] &&
			dispatch(setWelcomeBannerCheckbox([false]));
		state.value === 'BotMessages' &&
			showavatar &&
			dispatch(setShowAvatar([false]));
		if (state.value === 'BotHeader') {
			dispatch(setHeaderContentType('text'));
		}
		if (state.value === 'BackgroundImage') {
			dispatch(setBotBackground('color'));
		}
		navigate(-1);
	};

	const fileUploadInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		let fileRef;
		if (state.value === 'WelcomeBanner') {
			fileRef = welcomeRef;
		} else if (state.value === 'BotMessages') {
			fileRef = botMessageRef;
		} else if (state.value === 'BotHeader') {
			fileRef = botHeaderRef;
		} else if (state.value === 'BackgroundImage') {
			fileRef = backgroundRef;
		} else if (state.value === 'WelcomeCarousel') {
			fileRef = carouselRef;
		}
		else if (state.value === 'Fullscreen WelcomeCarousel') {
			fileRef = carouselRefFullScreen
		}
		else if (state.value === 'Fullscreen MonacoWelcome') {
			fileRef = monacoRefFullscreen
		}
		else if (state.value === 'Fullscreen E-com') {
			fileRef = ecomRefFullscreen
		}
		else if (state.value === 'e-comSplashLogo') {
			fileRef = ecomRefFullscreenLogo
		}

		if (fileRef && fileRef.current) {
			let fileInput = fileRef.current;
			var filePath = fileInput.value;
			// Allowing file type
			var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
			if (filePath) {
				if (!allowedExtensions.exec(filePath)) {
					alert('Invalid Media :- Supported media formats are .jpg,.jpeg,.png');
					fileInput.value = '';
					return false;
				} else {
					let file = e.target.files && e.target.files[0];
					const MAX_FILE_SIZE = 5120; // 5MB in binary
					const fileSizeKiloBytes = file && file.size / 1024;
					if (fileSizeKiloBytes! > MAX_FILE_SIZE) {
						// setFileSizeExceed(true);

						alert(
							'File size should be maximum 5 MB Format should be PNG or JPEG or JPG'
						);
					} else {
						const formData = new FormData();
						e.target.files &&
							formData.append(
								'file',
								e.target.files[0],
								e.target.files[0].name
							);
						state.value === 'WelcomeBanner' &&
							dispatch(
								uploadImage({
									file: formData,
									botId: loadState('botId'),
									tenantId: loadState('tenantId'),
									uploadTo: state.value,
								})
							);
						state.value === 'BotMessages' &&
							e.target.files &&
							dispatch(
								uploadImage({
									file: formData,
									botId: loadState('botId'),
									tenantId: loadState('tenantId'),
									uploadTo: state.value,
								})
							);
						state.value === 'BotHeader' &&
							e.target.files &&
							dispatch(
								uploadImage({
									file: formData,
									botId: loadState('botId'),
									tenantId: loadState('tenantId'),
									uploadTo: state.value,
								})
							);
						state.value === 'BackgroundImage' &&
							e.target.files &&
							dispatch(
								uploadImage({
									file: formData,
									botId: loadState('botId'),
									tenantId: loadState('tenantId'),
									uploadTo: state.value,
								})
							);

						(state.value == 'Fullscreen WelcomeCarousel') &&
							e.target.files &&
							dispatch(
								uploadImage({
									file: formData,
									botId: loadState('botId'),
									tenantId: loadState('tenantId'),
									uploadTo: state.value,
								})
							);
						(state.value == 'Fullscreen MonacoWelcome') &&
							e.target.files &&
							dispatch(
								uploadImage({
									file: formData,
									botId: loadState('botId'),
									tenantId: loadState('tenantId'),
									uploadTo: state.value,
								})
							);
						(state.value == 'Fullscreen E-com') &&
							e.target.files &&
							dispatch(
								uploadImage({
									file: formData,
									botId: loadState('botId'),
									tenantId: loadState('tenantId'),
									uploadTo: state.value,
								})
							);
						(state.value === 'WelcomeCarousel') &&
							e.target.files &&
							dispatch(
								uploadImage({
									file: formData,
									botId: loadState('botId'),
									tenantId: loadState('tenantId'),
									uploadTo: state.value,
								})
							);
						(state.value === 'e-comSplashLogo') &&
							e.target.files &&
							dispatch(
								uploadImage({
									file: formData,
									botId: loadState('botId'),
									tenantId: loadState('tenantId'),
									uploadTo: state.value,
								})
							);
						fileInput.value = '';
						navigate(-1);
					}
				}
			}
		}
	};

	const buttons = () => {
		return (
			<>
				<Button onClick={uploadCancel} buttonType='light'>
					<p>Cancel</p>
				</Button>
				<Button
					disabled={(state.value == 'Fullscreen WelcomeCarousel' && fullScreenWelcomeCarousel.length == 0) || errorURL || (state.value == 'Fullscreen MonacoWelcome' && fullScreenWelcomeMonaco.length == 0) || (state.value == 'Fullscreen E-com' && fullScreenWelcomeEcom.length == 0)}
					onClick={() => {
						if (state.value === 'WelcomeBanner') {
							setAddThroughURLWB(true);
						} else if (state.value === 'BotHeader') {
							setAddThroughURLBH(true);
						} else if (state.value === 'BackgroundImage') {
							setAddThroughURLBI(true);
						} else if (state.value === 'WelcomeCarousel') {
							setAddThroughURLWC(true);
						}
						else if (state.value == 'Fullscreen WelcomeCarousel') {
							setAddThroughURLFullScreenWC(true);
						}
						else if (state.value == 'Fullscreen MonacoWelcome') {
							if (!isImage(fullScreenWelcomeMonaco)) {
								dispatch(setSnackModal({
									openModal: true,
									modalMesssage: 'Invalid Media :- Supported media formats are .jpg,.jpeg,.png',
									modalMode: 'negative',
								}))
							}
							else {
								setAddThroughURLFullScreenMonaco(true);
							}


						}
						else if (state.value == 'Fullscreen E-com') {
							if (!isImage(fullScreenWelcomeEcom)) {
								dispatch(setSnackModal({
									openModal: true,
									modalMesssage: 'Invalid Media :- Supported media formats are .jpg,.jpeg,.png',
									modalMode: 'negative',
								}))
							}
							else {
								setAddThroughURLFullScreenEcom(true);
							}

						}
						else if (state.value == 'e-comSplashLogo') {

							if (!isImage(fullScreenWelcomeEcomLogo)) {
								dispatch(setSnackModal({
									openModal: true,
									modalMesssage: 'Invalid Media :- Supported media formats are .jpg,.jpeg,.png',
									modalMode: 'negative',
								}))
							}
							else {
								setAddThroughURLFullScreenEcomLogo(true);
							}

						}
					}}>
					<p>Add</p>
				</Button >
			</>
		);
	};
	useEffect(() => {
		addThroughURLWB &&
			welcomeValue &&
			dispatch(setWelcomBannerFile(welcomeValue));
		addThroughURLWB && navigate(-1);
	}, [addThroughURLWB]);

	useEffect(() => {
		addThroughURLBH &&
			botHeaderValue &&
			dispatch(setHeaderFile(botHeaderValue));
		addThroughURLBH && navigate(-1);
	}, [addThroughURLBH]);

	useEffect(() => {
		addThroughURLBI &&
			backgroundImageValue &&
			dispatch(setBackgroundFile(backgroundImageValue));
		addThroughURLBI && navigate(-1);
	}, [addThroughURLBI]);

	useEffect(() => {
		addThroughURLWC &&
			welcomeCarousel &&
			dispatch(setWelcomeCarouselFile(welcomeCarousel));
		addThroughURLWC && navigate(-1);
	}, [addThroughURLWC]);

	useEffect(() => {
		addThroughURLFullScreenWC &&
			fullScreenWelcomeCarousel &&
			dispatch(setWelcomeCarouselFileFullScreen(fullScreenWelcomeCarousel));
		addThroughURLFullScreenWC && navigate(-1);
	}, [addThroughURLFullScreenWC]);
	useEffect(() => {
		addThroughURLFullScreenMonaco &&
			fullScreenWelcomeMonaco &&
			dispatch(setWelcomeMonacoImage(fullScreenWelcomeMonaco));
		addThroughURLFullScreenMonaco && navigate(-1);
	}, [addThroughURLFullScreenMonaco]);

	useEffect(() => {
		addThroughURLFullScreenEcom &&
			fullScreenWelcomeEcom &&
			dispatch(setWelcomeEcomImage(fullScreenWelcomeEcom));
		addThroughURLFullScreenEcom && navigate(-1);
	}, [addThroughURLFullScreenEcom]);

	useEffect(() => {
		addThroughURLFullScreenEcomLogo &&
			addThroughURLFullScreenEcomLogo &&
			dispatch(setEcomlogo(fullScreenWelcomeEcomLogo));
		addThroughURLFullScreenEcomLogo && navigate(-1);
	}, [addThroughURLFullScreenEcomLogo]);



	return (
		<div className='ff-eow-addimage'>
			{!throughURL ? (
				<Modal
					header='Add Image'
					actionButtons={' '}
					headerCloseBtn
					onClose={uploadCancel}>
					<div>
						<p>Select how you would like to add the image</p>

						{state.value === 'WelcomeBanner' ? (
							<label htmlFor='ff-eow-welcome-banner-image'>
								<div className='add-image welcome-banner'>
									<AppearanceUpload />
									<p>Upload</p>
								</div>
							</label>
						) : null}
						{state.value === 'BotHeader' ? (
							<label htmlFor='ff-eow-bot-header-image'>
								<div className='add-image bot-header'>
									<AppearanceUpload />
									<p>Upload</p>
								</div>
							</label>
						) : null}

						{state.value === 'BackgroundImage' ? (
							<label htmlFor='ff-eow-background-image'>
								<div className='add-image background-image'>
									<AppearanceUpload />
									<p>Upload</p>
								</div>
							</label>
						) : null}
						{state.value === 'WelcomeCarousel' ? (
							<label htmlFor='ff-eow-carousel-image'>
								<div className='add-image carousel-image'>
									<AppearanceUpload />
									<p>Upload</p>
								</div>
							</label>
						) : null}
						{state.value === 'Fullscreen WelcomeCarousel' ? (
							<label htmlFor='ff-eow-carousel-image-fullscreen'>
								<div className='add-image carousel-image-fullscreen'>
									<AppearanceUpload />
									<p>Upload</p>
								</div>
							</label>
						) : null}
						{state.value === 'Fullscreen MonacoWelcome' ? (
							<label htmlFor='ff-eow-image-fullscreen-monaco'>
								<div className='add-image welcome-image-monaco'>
									<AppearanceUpload />
									<p>Upload</p>
								</div>
							</label>
						) : null}
						{state.value === 'Fullscreen E-com' ? (
							<label htmlFor='ff-eow-image-fullscreen-ecom'>
								<div className='add-image welcome-image-ecom'>
									<AppearanceUpload />
									<p>Upload</p>
								</div>
							</label>
						) : null}

						{state.value === 'e-comSplashLogo' ? (
							<label htmlFor='ff-eow-image-fullscreen-ecom-logo'>
								<div className='add-image welcome-image-ecom-logo'>
									<AppearanceUpload />
									<p>Upload</p>
								</div>
							</label>
						) : null}


						<div className='add-image' onClick={() => setThroughURL(true)}>
							<AppearanceURL />
							<p>Through URL</p>
						</div>
					</div>
				</Modal>
			) : (
				<Modal header='Through URL' actionButtons={buttons()}>
					<div>
						{state.value === 'WelcomeBanner' ? (
							<Input
								fullWidth
								autoFocus
								label='Paste your URL here'
								value={welcomeValue}
								onChange={(event) => setWelcomeValue(event.target.value.trimStart())}
							/>
						) : null}
						{state.value === 'BotHeader' ? (
							<Input
								fullWidth
								autoFocus
								label='Paste your URL here'
								value={botHeaderValue}
								onChange={(event) => setBotHeaderValue(event.target.value.trimStart())}
							/>
						) : null}

						{state.value === 'BackgroundImage' ? (
							<Input
								fullWidth
								autoFocus
								label='Paste your URL here'
								value={backgroundImageValue}
								onChange={(event) =>
									setBackgroundImageValue(event.target.value.trimStart())
								}
							/>
						) : null}

						{state.value === 'WelcomeCarousel' ? (
							<Input
								fullWidth
								autoFocus
								label='Paste your URL here'
								value={welcomeCarousel}
								onChange={(event) => setWelcomeCarousel(event.target.value.trimStart())}
							/>
						) : null}
						{state.value === 'Fullscreen WelcomeCarousel' ? (
							<Input
								fullWidth
								autoFocus
								error={errorURL ? true : false}
								errorText='Please enter the valid URL'
								label='Paste your URL here'
								value={fullScreenWelcomeCarousel}
								onChange={(event) => {
									setFullScreenWelcomeCarousel(event.target.value.trimStart());
									setErrorURL(event.target.value.length == 0 || (pattern.test(event.target.value) == false))
								}}
							/>
						) : null}
						{state.value === 'Fullscreen MonacoWelcome' ? (
							<Input
								fullWidth
								autoFocus
								error={errorURL ? true : false}
								errorText='Please enter the valid URL'
								label='Paste your URL here'
								value={fullScreenWelcomeMonaco}
								onChange={(event) => {
									setFullScreenWelcomeMonaco(event.target.value.trimStart());
									setErrorURL(event.target.value.length == 0 || (pattern.test(event.target.value) == false))
								}}
							/>
						) : null}

						{state.value === 'Fullscreen E-com' ? (
							<Input
								fullWidth
								autoFocus
								error={errorURL ? true : false}
								errorText='Please enter the valid URL'
								label='Paste your URL here'
								value={fullScreenWelcomeEcom}
								onChange={(event) => {
									setFullScreenWelcomeEcom(event.target.value.trimStart());
									setErrorURL(event.target.value.length == 0 || (pattern.test(event.target.value) == false))
								}}
							/>
						) : null}
						{state.value === 'e-comSplashLogo' ? (
							<Input
								fullWidth
								autoFocus
								error={errorURL ? true : false}
								errorText='Please enter the valid URL'
								label='Paste your URL here'
								value={fullScreenWelcomeEcomLogo}
								onChange={(event) => {
									setFullScreenWelcomeEcomLogo(event.target.value.trimStart());
									setErrorURL(event.target.value.length == 0 || (pattern.test(event.target.value) == false))
								}}
							/>
						) : null}

					</div>
				</Modal>
			)}
			<input
				type='file'
				accept='image/png, image/jpg, image/jpeg'
				id='ff-eow-welcome-banner-image'
				ref={welcomeRef}
				onChange={fileUploadInput}
				name='ff-eow-welcome-banner-image'
				style={{ visibility: 'hidden' }}
			/>
			<input
				type='file'
				accept='image/png, image/jpg, image/jpeg'
				id='ff-eow-bot-header-image'
				ref={botHeaderRef}
				onChange={fileUploadInput}
				name='ff-eow-bot-header-image'
				style={{ visibility: 'hidden' }}
			/>

			<input
				type='file'
				accept='image/png, image/jpg, image/jpeg'
				id='ff-eow-background-image'
				ref={backgroundRef}
				onChange={fileUploadInput}
				name='ff-eow-background-image'
				style={{ visibility: 'hidden' }}
			/>
			<input
				type='file'
				accept='image/png, image/jpg, image/jpeg'
				id='ff-eow-carousel-image'
				ref={carouselRef}
				onChange={fileUploadInput}
				name='ff-eow-carousel-image'
				style={{ visibility: 'hidden' }}
			/>
			<input
				type='file'
				accept='image/png, image/jpg, image/jpeg'
				id='ff-eow-carousel-image-fullscreen'
				ref={carouselRefFullScreen}
				onChange={fileUploadInput}
				name='ff-eow-carousel-image-fullscreen'
				style={{ visibility: 'hidden' }}
			/>
			<input
				type='file'
				accept='image/png, image/jpg, image/jpeg'
				id='ff-eow-image-fullscreen-monaco'
				ref={monacoRefFullscreen}
				onChange={fileUploadInput}
				name='ff-eow-image-fullscreen-monaco'
				style={{ visibility: 'hidden' }}
			/>
			<input
				type='file'
				accept='image/png, image/jpg, image/jpeg'
				id='ff-eow-image-fullscreen-ecom'
				ref={ecomRefFullscreen}
				onChange={fileUploadInput}
				name='ff-eow-image-fullscreen-ecom'
				style={{ visibility: 'hidden' }}
			/>

			<input
				type='file'
				accept='image/png, image/jpg, image/jpeg'
				id='ff-eow-image-fullscreen-ecom-logo'
				ref={ecomRefFullscreenLogo}
				onChange={fileUploadInput}
				name='ff-eow-image-fullscreen-ecom-logo'
				style={{ visibility: 'hidden' }}
			/>
		</div>
	);
};

export default AddImageEOW;
