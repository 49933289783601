import React, { useEffect, useState } from 'react';
import Button from '../../../../../Components/Button';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import AutoComplete from '../../../../../Components/AutoComplete';
import Checkbox from '../../../../../Components/Checkbox';
import Input from '../../../../../Components/Input';
import styled from 'styled-components';
// import { TOASTER } from '../ChatBody';
import {
    loadState,
    loadStateSession,
    removeState,
    saveState,
    saveStateSession,
} from '../../../../../utils/utils';

const FORMS = styled.div<{ cssPtag?: any, colorMode?: any }>`
	width: 100%;
	& > div {
		& > p {
			color: ${(props) => {
        if (props.cssPtag) {
            return 'var(--colorScale-1)';
        }
        return props.colorMode === 'dark' ? 'white' : 'var(--colorScale-1)';
    }};
			margin-bottom: 8px !important;
			margin-top: 24px !important;
			font-weight: 600;
			&.ff-chatbot-caption {
				margin: 0 !important;
				margin-bottom: 16px !important;
				font-weight: normal;
				color: ${(props) => {
        if (props.cssPtag) {
            return 'var(--black-60)';
        }
        return props.colorMode === 'dark' ? 'white' : 'var(--black-60)';
    }};
			}
		}
		& > .ff-checkbox {
			margin-bottom: 16px;
           
			& > label {
				gap: 8px;
                & > .check{
                    cursor:'unset !important'
                }
				& > .check {
                    
					& path:last-child {
						fill: ${(props) => {
        if (props.cssPtag) {
            return 'var(--black-60) !important';
        }
        return props.colorMode === 'dark'
            ? 'white'
            : 'var(--colorScale-1)';
    }};
					}
					&.checked {
						& path:last-child {
							fill: ${(props) => {
        if (props.cssPtag) {
            return 'var(--colorScale-1) !important';
        }
        return props.colorMode === 'dark'
            ? 'white'
            : 'var(--colorScale-1)';
    }};
						}
					}
				}
				& > .label {
					& > p {
						margin: 0 !important;
						color: ${(props) => {
        if (props.cssPtag) {
            return 'initial !important';
        }
    }};
					}
				}
			}
		}
		& > .MuiFormGroup-root {
			margin-bottom: 16px;
			margin-left: 2px;
			gap: 16px;
			& > label {
				& > .MuiTypography-root {
					& > p {
						margin: 0 !important;
						color: ${(props) => {
        if (props.cssPtag) {
            return 'initial !important';
        }
        return props.colorMode === 'dark'
            ? 'white'
            : 'var(--colorScale-1)';
    }};
					}
				}
				& > span.MuiButtonBase-root {
					padding: 0px 9px !important;
					& path {
						fill: ${(props) => {
        if (props.cssPtag) {
            return 'var(--black-60) !important';
        }
        return props.colorMode === 'dark'
            ? 'white'
            : 'var(--colorScale-1)';
    }};
					}
					&.Mui-checked {
						& path {
							fill: ${(props) => {
        if (props.cssPtag) {
            return 'var(--colorScale-1) !important';
        }
        return props.colorMode === 'dark'
            ? 'white'
            : 'var(--colorScale-1)';
    }};
						}
					}
				}
			}
		}
		& > div > .MuiFormControl-root {
			padding: 0 !important;
			& input {
				font-size: 14px !important;
				color: var(--black-60);
			}
			& .MuiFormHelperText-root {
				margin-left: 0 !important;
			}
			& .MuiInputBase-root {
				&.Mui-error {
					.MuiOutlinedInput-notchedOutline {
						border-color: var(--colorScale-1);
					}
				}

				background-color: white;
				&:not(.Mui-disabled, .Mui-error).Mui-focused {
					.MuiOutlinedInput-notchedOutline {
						border-color: ${(props) => {
        if (props.cssPtag) {
            return 'var(--colorScale-1) !important';
        }
        return props.colorMode === 'dark'
            ? 'white'
            : 'var(--colorScale-1)';
    }};
					}
				}
			}
			& .MuiFormHelperText-root {
				color: ${(props) => {
        if (props.cssPtag) {
            return 'var(--colorScale-1) !important';
        }
        return props.colorMode === 'dark' ? 'white' : 'var(--colorScale-1)';
    }};
			}
		}

		& > div {
			& > .MuiAutocomplete-root {
				margin-bottom: 24px !important;
				& .MuiInputBase-root {
					background-color: white;
					padding: 6px !important;
					padding-left: 10px !important;

					&:not(.Mui-disabled, .Mui-error).Mui-focused {
						.MuiOutlinedInput-notchedOutline {
							border-color: var(--colorScale-1) !important;
						}
					}
				}
			}
		}
	}
	& > div {
		& > .custom-button:last-child {
			padding-top: 12px;
			width: ${(props) => (props.cssPtag ? '50%' : '90px')};
			& > button {
                background-color: var(--blue-violet-600);
				& > .custom-button-body {
                    background-color: var(--blue-violet-600);
				}
			}
		}
		& > .custom-button:first-child {
			padding-top: 12px;
			width: ${(props) => (props.cssPtag ? '50%' : '90px')};
			& > button {
                background-color: var(--blue-violet-600);
    color: var(--white);
            }
				& > .custom-button-body {
					color: ${(props) => {
        if (props.cssPtag) {
            return 'white';
        }
        return props.colorMode === 'dark'
            ? 'var(--selectedColor)'
            : 'white';
    }} !important;
				}
				& > .button-loader {
					display: flex;
					color: ${(props) => {
        if (props.cssPtag) {
            return 'white';
        }
        return props.colorMode === 'dark'
            ? 'var(--selectedColor)'
            : 'white';
    }} !important;
				}
				& > .button-loader {
					display: flex;
					color: ${(props) => {
        if (props.cssPtag) {
            return 'white';
        }
        return props.colorMode === 'dark'
            ? 'var(--selectedColor)'
            : 'white';
    }} !important;
				}
			}
		}
	}
`;

type Props = {
    // handleSubmit: Function;
    WorkflowData: any;
    colorMode: string;
    token: any;
    botId: any;
    tenantId: any;
    conversationId: any;
    cssPtag?: any;
    addUserResponse: Function;
    // currentWorkflow: string;
    // changeScreen?: Function;
    showToaster?: Function;
    disableMandatory?: Function;
    shouldDisableActions?: any;
    noOfTimeCalled?: number;
    maxIndex?: number;
    EbotId?: string;
    lang?: string;
};

const InfoIcon = (colorMode: any, cssPtag: any) => {
    let color = '';
    if (colorMode === 'dark') {
        if (cssPtag) {
            color = 'var(--colorScale-1)';
        } else {
            color = 'white';
        }
    } else {
        color = 'var(--colorScale-1)';
    }
    return (
        <svg
            style={{ position: 'relative', bottom: '1px' }}
            xmlns='http://www.w3.org/2000/svg'
            width='13.909'
            height='13.909'
            viewBox='0 0 13.909 13.909'
        >
            <path
                d='M8.259 11.041H9.65v1.391H8.259zm0-5.564H9.65V9.65H8.259zM8.947 2a6.954 6.954 0 1 0 6.961 6.954A6.951 6.951 0 0 0 8.947 2zm.007 12.518a5.564 5.564 0 1 1 5.564-5.564 5.562 5.562 0 0 1-5.564 5.564z'
                transform='translate(-2 -2)'
                fill={color}
            />
        </svg>
    );
};

const GetInTouch = ({
    // handleSubmit,
    WorkflowData,
    colorMode,
    botId,
    tenantId,
    token,
    conversationId,
    cssPtag,
    // changeScreen,
    addUserResponse,
    // currentWorkflow,
    showToaster,
    disableMandatory,
    shouldDisableActions,
    noOfTimeCalled,
    maxIndex,
    lang,
    EbotId,
}: Props) => {
    const [currentWorkflowData, setCurrentWorkflowData] = useState<any>(
        // currentWorkflow === 'generateLeads'
        //     ? WorkflowData[1]
        //     : currentWorkflow === 'raiseTicket'
        //         ? WorkflowData[0]
        //         : currentWorkflow === 'bookAppointment'
        //             ? WorkflowData[2]
        //             : currentWorkflow === 'ChatWithExpert'
        //                 ? WorkflowData[3]
        //                 : WorkflowData[1]
        WorkflowData
    );
    const [sortedWorkflowData, setSortedWorkflowData] = useState<any>();
    const [workflowValues, setWorkflowValues] = useState<any>();
    const [disableButton, setDisableButton] = useState(false);
    const [showErrorToaster, setErrorToaster] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [disableInputs, setDisableInputs] = useState(
        maxIndex !== noOfTimeCalled
    );

    const validationCheck = () => {
        let validation = sortedWorkflowData.map((list: any, index: any) => {
            if (list.enabled.toLowerCase() === 'true') {
                let validator = new RegExp(list.validation, 'i');

                if (list.validationType.toLowerCase() === 'multiple choice') {
                    return !workflowValues[index];
                } else if (list.validationType.toLowerCase() === 'multiple select') {
                    if (workflowValues[index]) {
                        let check = workflowValues[index].select.filter(
                            (v: any) => v[0] === true
                        );
                        return check[0] === undefined ? true : false;
                    }
                    return false;
                } else if (list.validationType.toLowerCase() === 'drop down') {
                    return false;
                }

                return workflowValues[index] && !validator.test(workflowValues[index]);
            }
        });
        // console.log('Validation', validation);
        let filterTrue = validation.filter((value: any) => value === true)[0];
        let filterEmpty = validation.filter((value: any) => value === '');
        // console.log('filterTrue', filterTrue);

        // console.log('filterEmpty', filterEmpty);
        let buttonDisable = false;
        if (filterTrue === undefined && filterEmpty.length === 0) {
            buttonDisable = false;
        } else {
            buttonDisable = true;
        }
        return buttonDisable;
    };

    const errTextAndIconColor = (colorMode: any) => {
        if (colorMode === 'dark') {
            if (cssPtag) {
                return 'var(--colorScale-1)';
            }
            return 'white';
        } else {
            return 'var(--colorScale-1)';
        }
    };

    useEffect(() => {
        return () => {
            if (cssPtag) {
                workflowValues &&
                    saveStateSession(
                        `FF-CSSPTAGworkflowData-${noOfTimeCalled}`,
                        workflowValues
                    );
            } else {
                workflowValues && saveStateSession('FF-workflowData', workflowValues);
            }
        };
    }, [workflowValues]);
    useEffect(() => {
        // if (cssPtag) {
        //     setTimeout(() => {
        //         const chatbodyWrapper = document.querySelector('.sf-chatbody');
        //         chatbodyWrapper.scrollTo({ top: chatbodyWrapper.scrollHeight });
        //     }, 100);
        // }
        // console.log('data', currentWorkflowData)
    }, []);

    useEffect(() => {
        // console.log(currentWorkflowData)
        if (currentWorkflowData && currentWorkflowData.length > 0) {
            let tasks = currentWorkflowData;
            // console.log(tasks)
            // tasks.sort((a: any, b: any) => parseInt(a.sequence, 10) - parseInt(b.sequence, 10));
            setSortedWorkflowData(tasks);
            // console.log(sortedWorkflowData)
            let workflowData = tasks && tasks.map((item: any) => {
                if (item.enabled.toLowerCase() === 'true') {
                    if (item.validationType.toLowerCase() === 'drop down') {
                        return { label: item.optionList[0].name, value: item.optionList[0].name };
                    } else if (item.validationType.toLowerCase() === 'multiple choice') {
                        return '';
                    } else if (item.validationType.toLowerCase() === 'multiple select') {
                        let v = item.optionList && item.optionList.map((list: any, idx: any) => {
                            return [false];
                        });
                        // console.log(v)
                        return { select: v };
                    } else if (
                        item.validationType.toLowerCase() === 'time' ||
                        item.validationType.toLowerCase() === 'date' ||
                        item.validationType.toLowerCase() === 'text' ||
                        item.validationType.toLowerCase() === 'email' ||
                        item.validationType.toLowerCase() === 'number'
                    ) {
                        return '';
                    }
                    return false;
                }
            });
            if (cssPtag) {
                let value;
                if (
                    loadStateSession(`FF-CSSPTAGworkflowData-${noOfTimeCalled}`) !==
                    undefined
                ) {
                    value = loadStateSession(`FF-CSSPTAGworkflowData-${noOfTimeCalled}`);
                }

                if (value) {
                    setWorkflowValues(value);
                } else {
                    setWorkflowValues(workflowData);
                }
            } else {
                let value;
                if (loadStateSession('FF-workflowData') !== undefined) {
                    value = loadStateSession('FF-workflowData');
                }
                if (value) {
                    setWorkflowValues(value);
                } else {
                    setWorkflowValues(workflowData);
                }
            }
        }
    }, [currentWorkflowData]);

    return (
        <div
            className='ff-getintouch-screen'
            style={{
                position: cssPtag ? 'initial' : 'absolute',
                paddingBottom: cssPtag ? '' : '20px',
            }}
        >
            {/* {showErrorToaster && (
                <>
                    <div
                        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                    >
                        <TOASTER className='ff-toaster' colorMode={colorMode}>
                            Something went wrong. Please try again
                        </TOASTER>
                    </div>
                </>
            )} */}

            <>
                {/* <p
                    className={cssPtag ? '' : 'ff-chatbot-body2'}
                    style={
                        cssPtag
                            ? cssPtag
                            : {
                                margin: 0,
                                paddingBottom: '10px',
                                color: colorMode === 'dark' ? 'white' : 'var(--colorScale-1)',
                            }
                    }
                >
                    {currentWorkflowData.message}
                </p> */}
                {/* {workflowValues !== undefined ? ( */}
                <FORMS colorMode={"dark"} cssPtag={cssPtag}>
                    {sortedWorkflowData && sortedWorkflowData.map((list: any, index: any) => {
                        if (list.enabled.toLowerCase() === 'true') {
                            let validator = new RegExp(list.validation, 'i');
                            return (
                                <div key={index}>
                                    {list.name ? (
                                        <>
                                            <p className='ff-chatbot-body2'>{list.name}</p>
                                            {list.message ? (
                                                <p className='ff-chatbot-caption'>{list.message}</p>
                                            ) : null}

                                            {list.validationType.toLowerCase() === 'text' ? (
                                                <Input
                                                    disabled={
                                                        // (disableInputs || disableButton) &&
                                                        // shouldDisableActions
                                                        true
                                                    }
                                                    error={
                                                        workflowValues[index] &&
                                                        !validator.test(workflowValues[index])
                                                    }
                                                    // errorText={
                                                    //     <div
                                                    //         style={{
                                                    //             display: 'flex',
                                                    //             alignItems: 'center',
                                                    //             gap: '5px',
                                                    //         }}
                                                    //     >
                                                    //         {InfoIcon(colorMode, cssPtag)}
                                                    //         <p
                                                    //             style={{
                                                    //                 margin: 0,
                                                    //                 color: errTextAndIconColor(colorMode),
                                                    //             }}
                                                    //         >
                                                    //             Please provide a valid text
                                                    //         </p>
                                                    //     </div>
                                                    // }
                                                    fullWidth
                                                    value={workflowValues[index]}
                                                    onChange={(e) => {
                                                        //     let v = [...workflowValues];

                                                        //     v[index] = e.target.value;

                                                        //     setWorkflowValues(v);
                                                    }}
                                                />
                                            ) : null}
                                            {list.validationType.toLowerCase() === 'email' ? (
                                                <Input
                                                    disabled={
                                                        // (disableInputs || disableButton) &&
                                                        // shouldDisableActions
                                                        true
                                                    }
                                                    error={
                                                        workflowValues[index] &&
                                                        !validator.test(workflowValues[index])
                                                    }
                                                    // errorText={
                                                    //     <div
                                                    //         style={{
                                                    //             display: 'flex',
                                                    //             alignItems: 'center',
                                                    //             gap: '5px',
                                                    //         }}
                                                    //     >
                                                    //         {InfoIcon(colorMode, cssPtag)}
                                                    //         <p
                                                    //             style={{
                                                    //                 margin: 0,
                                                    //                 color: errTextAndIconColor(colorMode),
                                                    //             }}
                                                    //         >
                                                    //             Please provide a valid email address
                                                    //         </p>
                                                    //     </div>
                                                    // }
                                                    fullWidth
                                                    value={workflowValues[index]}
                                                    onChange={(e) => {
                                                        //     let v = [...workflowValues];

                                                        //     v[index] = e.target.value;

                                                        //     setWorkflowValues(v);
                                                    }}
                                                />
                                            ) : null}
                                            {list.validationType.toLowerCase() === 'date' ? (
                                                <Input
                                                    disabled={
                                                        (disableInputs || disableButton) &&
                                                        shouldDisableActions
                                                    }
                                                    error={
                                                        workflowValues[index] &&
                                                        !validator.test(workflowValues[index])
                                                    }
                                                    // errorText={
                                                    //     <div
                                                    //         style={{
                                                    //             display: 'flex',
                                                    //             alignItems: 'center',
                                                    //             gap: '5px',
                                                    //         }}
                                                    //     >
                                                    //         {InfoIcon(colorMode, cssPtag)}
                                                    //         <p
                                                    //             style={{
                                                    //                 margin: 0,
                                                    //                 color: errTextAndIconColor(colorMode),
                                                    //             }}
                                                    //         >
                                                    //             Please provide a valid date
                                                    //         </p>
                                                    //     </div>
                                                    // }
                                                    fullWidth
                                                    placeholder='DD-MM-YYYY'
                                                    type='date'
                                                    value={workflowValues[index]}
                                                    onChange={(e) => {
                                                        //     let v = [...workflowValues];

                                                        //     v[index] = e.target.value;

                                                        //     setWorkflowValues(v);
                                                    }}
                                                />
                                            ) : null}
                                            {list.validationType.toLowerCase() === 'time' ? (
                                                <Input
                                                    disabled={
                                                        (disableInputs || disableButton) &&
                                                        shouldDisableActions
                                                    }
                                                    error={
                                                        workflowValues[index] &&
                                                        !validator.test(workflowValues[index])
                                                    }
                                                    // errorText={
                                                    //     <div
                                                    //         style={{
                                                    //             display: 'flex',
                                                    //             alignItems: 'center',
                                                    //             gap: '5px',
                                                    //         }}
                                                    //     >
                                                    //         {InfoIcon(colorMode, cssPtag)}
                                                    //         <p
                                                    //             style={{
                                                    //                 margin: 0,
                                                    //                 color: errTextAndIconColor(colorMode),
                                                    //             }}
                                                    //         >
                                                    //             Please provide a valid time
                                                    //         </p>
                                                    //     </div>
                                                    // }
                                                    fullWidth
                                                    type='time'
                                                    value={workflowValues[index]}
                                                    onChange={(e) => {
                                                        //     let v = [...workflowValues];
                                                        //     v[index] = e.target.value;

                                                        //     setWorkflowValues(v);
                                                    }}
                                                />
                                            ) : null}
                                            {list.validationType.toLowerCase() === 'number' ? (
                                                <Input
                                                    disabled={
                                                        // (disableInputs || disableButton) &&
                                                        // shouldDisableActions
                                                        true
                                                    }
                                                    error={
                                                        workflowValues[index] &&
                                                        !validator.test(workflowValues[index])
                                                    }
                                                    // errorText={
                                                    //     <div
                                                    //         style={{
                                                    //             display: 'flex',
                                                    //             alignItems: 'center',
                                                    //             gap: '5px',
                                                    //         }}
                                                    //     >
                                                    //         {InfoIcon(colorMode, cssPtag)}
                                                    //         <p
                                                    //             style={{
                                                    //                 margin: 0,
                                                    //                 color: errTextAndIconColor(colorMode),
                                                    //             }}
                                                    //         >
                                                    //             Please provide a valid phone number
                                                    //         </p>
                                                    //     </div>
                                                    // }

                                                    fullWidth
                                                    // value={workflowValues[index]}
                                                    value=''
                                                    onChange={(e) => {
                                                        //     let v = [...workflowValues];

                                                        //     v[index] = e.target.value;

                                                        //     setWorkflowValues(v);
                                                    }}
                                                />
                                            ) : null}
                                            {list.validationType.toLowerCase() ===
                                                'multiple select' ? (
                                                <>
                                                    {list.optionList.map((val: any, i: any) => {
                                                        return (
                                                            <Checkbox
                                                                disabled={
                                                                    // (disableInputs || disableButton) &&
                                                                    // shouldDisableActions
                                                                    true
                                                                }
                                                                label={
                                                                    <p
                                                                        className='ff-chatbot-body2'
                                                                        style={{
                                                                            color:
                                                                                colorMode === 'dark'
                                                                                    ? 'white'
                                                                                    : 'var(--colorScale-1)',
                                                                        }}
                                                                    >
                                                                        {val}
                                                                    </p>
                                                                }
                                                                isChecked={[false, false, false]}
                                                                // isChecked={workflowValues[index].select[i]}
                                                                index={0}
                                                                onValueChange={() => {
                                                                    //     let v = [...workflowValues];
                                                                    //     v[index].select[i][0] =
                                                                    //         !v[index].select[i][0];
                                                                    //     setWorkflowValues(v);
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </>
                                            ) : null}
                                            {list.validationType.toLowerCase() ===
                                                'multiple choice' ? (
                                                <RadioGroup
                                                    aria-labelledby={'choice' + index + 'label'}
                                                    name={'choice' + index}
                                                    value={workflowValues[index]}
                                                    onChange={(e) => {
                                                        // let v = [...workflowValues];
                                                        // v[index] = e.target.value;
                                                        // setWorkflowValues(v);
                                                    }}
                                                >
                                                    {list.optionList.map((val: any) => (
                                                        <FormControlLabel
                                                            disabled={
                                                                (disableInputs || disableButton) &&
                                                                shouldDisableActions
                                                            }
                                                            value={val}
                                                            control={<Radio />}
                                                            label={
                                                                <p
                                                                    className='ff-chatbot-body2'
                                                                    style={{
                                                                        color:
                                                                            colorMode === 'dark'
                                                                                ? 'white'
                                                                                : 'var(--colorScale-1)',
                                                                    }}
                                                                >
                                                                    {val}
                                                                </p>
                                                            }
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            ) : null}
                                            {list.validationType.toLowerCase() === 'drop down' ? (
                                                <>
                                                    <AutoComplete
                                                        disabled={
                                                            // (disableInputs || disableButton) &&
                                                            // shouldDisableActions
                                                            true
                                                        }
                                                        multiple={false}
                                                        classNameOndropLI='workflowdrop'
                                                        // label=''
                                                        options={list.optionList.map((opt: any) => {
                                                            return {
                                                                label: opt,
                                                                value: opt,
                                                            };
                                                        })}
                                                        valueKey={'value'}
                                                        labelKey={'label'}
                                                        value={
                                                            // workflowValues[index] === ''
                                                            //     ? {
                                                            //         label: list.optionList[0],
                                                            //         value: list.optionList[0],
                                                            //     }
                                                            //     : workflowValues[index]
                                                            {
                                                                label: list.optionList[0],
                                                                value: list.optionList[0],
                                                            }
                                                        }
                                                        onChange={(val) => {
                                                            // let v = [...workflowValues];
                                                            // v[index] = val;
                                                            // setWorkflowValues(v);
                                                        }}
                                                    />
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}
                                </div>
                            );
                        }
                    })}
                    <div
                        style={{
                            display: shouldDisableActions ? 'none' : 'flex',
                            gap: '15px',
                            paddingRight: '10px',
                            marginTop: cssPtag ? '' : '20px',
                        }}
                        className={cssPtag ? '' : ''}
                    >
                        <Button
                            loading={cssPtag ? false : submitLoader}
                            fullWidth
                            disabled={true}
                            onClick={() => {

                            }}
                        >
                            Submit
                        </Button>
                        {!cssPtag ? null : (
                            <Button
                                fullWidth
                                disabled={true}
                                onClick={() => {

                                }}
                            >
                                Cancel
                            </Button>
                        )}
                    </div>
                </FORMS>
                {/* ) : null} */}
            </>
        </div>
    );
};

export default GetInTouch;
