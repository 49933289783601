import './FFLoader.scss';

type Props = {
	width?: string;
	height?: string;
	fill?: string;
};

export function FFLoader(props: Props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='22'
			height='22'
			className='loading-icon'
			viewBox='0 0 22 22'
			fill='none'
			{...props}>
			<path
				fill='currentColor'
				d='M9 18A9 9 0 1 1 9 0v2a7 7 0 1 0 7 7h2a9.01 9.01 0 0 1-9 9z'
				transform='translate(2.001 2)'
			/>
		</svg>
	);
}
