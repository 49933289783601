import scheduleTimeIcon from '../../../assets/Icons/LiveChatConfigIcons/schedule_black.png';
import BoltBlack from '../../../assets/Icons/LiveChatConfigIcons/bolt_black.png';

import AltRouteBlack from '../../../assets/Icons/LiveChatConfigIcons/alt-route-black.png';

import ChatBlack from '../../../assets/Icons/LiveChatConfigIcons/chat_black.png';

export const TABS = [
	'Timings & Availability',
	'Auto Assignment',
	'Handoff Triggers',
	'Messages',
];
export const TABICONS = [scheduleTimeIcon, AltRouteBlack, BoltBlack, ChatBlack];
