import { useEffect } from 'react';
import './team.scss';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../app/store';
import { useAppDispatch } from '../../../app/store';
import { useSelector } from 'react-redux';
import { selectTeam, getTenants } from '../../../Features/teams/TeamsSlice';
import {
	loadState,
	removeState,
	saveState,
	removeAllState,
	kotakTenantCheckForWhatsapp,
} from '../../../utils/utils';
import { Tenants } from '../../../Features/teams/model';
import { Masonry } from '@mui/lab';
import TeamCard from '../../../Components/TeamCard';

import PageLoader from '../../../Components/PageLoader';
import {
	getReadNotificationsForAll,
	getUnreadNotificationsForAll,
	resetAllNotificationData,
	resetNewNotificationCount,
} from '../../../Features/Notifications/notificationSlice';

function Team() {
	const { teams, noTeam, getTenantsstatus } = useSelector(
		(state: RootState) => state.teams
	);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (getTenantsstatus === 'failed') {
			setTimeout(() => {
				removeAllState();
				window.location.reload();
			}, 2000);
		}
	}, [getTenantsstatus]);

	useEffect(() => {
		if (teams) {
			if (teams.length === 1) {
				saveState('teamDet', teams[0]);
				saveState('tenantId', teams[0].id);
				saveState('organizationName', teams[0].organizationName);
				saveState('teamname', teams[0].name);
				saveState('tenantImageURL', teams[0].backgroundImage);
				//saveState('role', teams[0].role);
				// will be used as Bearer token for whatsapp APIs
				dispatch(
					selectTeam({
						tenantId: teams[0].id,
						teamname: teams[0].name,
					})
				);
				navigate('/app/create-ai');
				kotakTenantCheckForWhatsapp(`${teams[0].id}`);
			}
		}
	}, [teams]);

	useEffect(() => {
		if (teams && teams.length === 0) {
			noTeam === true && navigate('/app/select-plan');
		}
	}, [noTeam, teams]);

	const onSelectTeam = (team: Tenants) => {
		saveState('tenantId', team.id);
		saveState('organizationName', team.organizationName);
		saveState('teamname', team.name);
		saveState('tenantImageURL', team.backgroundImage);
		saveState('role', team.role);
		// will be used as Bearer token for whatsapp APIs
		saveState('teamDet', team);
		dispatch(
			selectTeam({
				tenantId: team.id,
				teamname: team.name,
			})
		);
		removeState('unreadNotificationsCount');
		dispatch(resetNewNotificationCount());
		dispatch(resetAllNotificationData());

		navigate('/app/create-ai');
		kotakTenantCheckForWhatsapp(`${team.id}`);
	};

	useEffect(() => {
		teams.length === 0 &&
			dispatch(
				getTenants({ updateTenant: false, userId: loadState('user').userID })
			);
		dispatch(
			getUnreadNotificationsForAll({
				userId: loadState('user').userID,
			})
		);
		dispatch(
			getReadNotificationsForAll({
				userId: loadState('user').userID,
				read: 1,
			})
		);
	}, []);

	return (
		<>
			<div className='ff_team_body'>
				{teams && teams.length ? (
					<div className='ff_teams'>
						<div className='team-header'>
							<h5> Hi! Please choose a Team to start</h5>
						</div>
						<div className='team-body-cards'>
							<Masonry
								columns={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4 }}
								spacing='30px'>
								{teams.map((eachTeam, index) => (
									<TeamCard
										key={index}
										team={eachTeam}
										index={index}
										selectTeam={() => {
											onSelectTeam(eachTeam);
										}}
									/>
								))}
							</Masonry>
						</div>
					</div>
				) : (
					<div className='query-loader'>
						<PageLoader message='Loading...' />
					</div>
				)}
			</div>
		</>
	);
}

export default Team;
