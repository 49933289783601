import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
	interface Theme {
		black: {
			main: string;
			black60: string;
			black40: string;
			black20: string;
			black10: string;
			black5: string;
		};
		white: {
			main: string;
			rust: string;
		};
	}
	// allow configuration using `createTheme`
	interface ThemeOptions {
		black?: {
			main?: string;
			black60?: string;
			black40?: string;
			black20?: string;
			black10?: string;
			black5?: string;
		};
		white?: {
			main?: string;
			rust?: string;
		};
	}
}

export const theme = createTheme({
	black: {
		main: '#000',
		black60: 'rgba(0, 0, 0, 0.6)',
		black40: 'rgba(0, 0, 0, 0.4)',
		black20: 'rgba(0, 0, 0, 0.2)',
		black10: 'rgba(0, 0, 0, 0.1)',
		black5: 'rgba(0, 0, 0, 0.05)',
	},
	white: {
		main: '#FFF',
		rust: '#fdfcfc',
	},
	palette: {
		primary: {
			main: '#636bd6',
			light: '#e3e5ff',
			dark: '#3b44c4',
		},
		secondary: {
			main: '#d9a20f',
			light: '#f0e2bd',
			dark: '#986f00',
		},
		error: {
			main: '#ec1f1f',
		},
		success: {
			main: '#22A2A2',
		},
	},
	typography: {
		fontFamily: 'Exo 2',
		h3: {
			fontFamily: 'Oswald',
			fontSize: '36px',
			fontWeight: 'normal',
			letterSpacing: '0.17px',
			lineHeight: 1.22,
		},
		h4: {
			fontFamily: 'Oswald',
			fontSize: '30px',
			fontWeight: 'normal',
			letterSpacing: '0.29px',
			lineHeight: 1.33,
		},
		h5: {
			fontFamily: 'Oswald',
			fontSize: '24px',
			fontWeight: 500,
			letterSpacing: '0.48px',
			lineHeight: 1.33,
		},
		h6: {
			fontFamily: 'Oswald',
			fontSize: '20px',
			fontWeight: 'normal',
			letterSpacing: '0.6px',
			lineHeight: 1.4,
		},
		body1: {
			fontFamily: 'Exo 2',
			fontSize: '16px',
			fontWeight: 'normal',
			lineHeight: 1.5,
			letterSpacing: '0.32px',
		},
		body2: {
			fontFamily: 'Exo 2',
			fontSize: '14px',
			fontWeight: 'normal',
			lineHeight: 1.5,
			letterSpacing: '0.42px',
		},
		caption: {
			fontFamily: 'Exo 2',
			fontSize: '12px',
			fontWeight: 'normal',
			lineHeight: 1.5,
			letterSpacing: '0.4px',
		},
		overline: {
			fontFamily: 'Exo 2',
			fontSize: '10px',
			fontWeight: 600,
			lineHeight: 1.6,
			letterSpacing: '0.75px',
		},
	},
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontFamily: 'Exo 2',
					backgroundColor: '#000',
					fontSize: '12px',
					padding: '1px 4px',
					borderRadius: '4px',
				},
			},
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 700,
			md: 1000,
			lg: 1400,
			xl: 1700,
		},
	},
});
