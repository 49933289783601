import { listOfBots } from '../../Features/Bots/model';
import { CardMenuIcon } from '../../Pages/MyStudio/icon';
import { useAppDispatch } from '../../app/store';
import { selectedBot } from '../../Features/Bots/BotSlice';
import InteractiveButton from '../Button/InteractiveButton';
import Chip from '../Chip';
import { useState } from 'react';
import { Tooltip } from '@mui/material';

interface Props {
	props: listOfBots;
	handleSetOpen: () => void;
	index: number;
	icon?: boolean;
	onclick?: Function;
	setAnchorEl: React.Dispatch<HTMLElement>;
}

const ASPECT_RATIOS = [2];

const CatalogCard = ({
	props,
	icon,
	onclick,
	handleSetOpen,
	setAnchorEl,
}: Props) => {
	const dispatch = useAppDispatch();

	const [aspectRatio] = useState(
		ASPECT_RATIOS[Math.floor(Math.random() * ASPECT_RATIOS.length)] //NOSONAR
	);

	const getBgColor = () => {
		return (props.botId % 12) + 1;
	};

	return (
		<>
			<div
				className={`card-item${props.froze ? ' archived' : ''}`}
				style={{
					backgroundColor: `var(--tertiary-${getBgColor()})`,
					aspectRatio: `${aspectRatio}`,
				}}
				onClick={() => onclick && onclick()}>
				{props.coverImage && <img src={props.coverImage} alt='' />}

				<div className='name-menu-container'>
					<div className='name-archived-container'>
						<Tooltip title={props.botName}>
							<h5>{props.botName}</h5>
						</Tooltip>

						{props.froze && <Chip label='Archived' />}
					</div>

					{icon && (
						<div
							onClick={(event: any) => {
								event.stopPropagation();
							}}>
							<InteractiveButton
								icon={<CardMenuIcon width='25' />}
								title='More options'
								onClick={(event) => {
									event.stopPropagation();
									setAnchorEl(event.target as HTMLElement);
									handleSetOpen();
									dispatch(selectedBot(props));
								}}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default CatalogCard;
