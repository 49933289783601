import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import Modal from '../../../../Components/Modal';
import { setSnackModal } from '../../../../Features/message/messageSlice';
import {
	crmsLinked,
	resetIntegrationEmailLoader,
	setup,
} from '../../../../Features/teamsetting/teamsettingSlice';
import { loadState } from '../../../../utils/utils';
import { isValidEmail } from '../../AddUser';
import './Email.scss';

const Email = () => {
	const [email, setEmail] = useState('');
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const user = loadState('user');

	const [buttonLoader, setButtonLoader] = useState(false);
	const { integrationEmailLoader } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	useEffect(() => {
		if (integrationEmailLoader === 'success') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: 'Email added successfully',
				})
			);
			setButtonLoader(false);
			dispatch(crmsLinked({ tenantId: tenantId! }));
			navigate(-1);
		} else if (integrationEmailLoader === 'failed') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: `Couldn't add !!! Something Went Wrong`,
					modalMode: 'negative',
				})
			);

			setButtonLoader(false);
		} else if (integrationEmailLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetIntegrationEmailLoader());
	}, [integrationEmailLoader]);
	const buttons = () => {
		return (
			<>
				<Button onClick={() => navigate(-1)} buttonType='light'>
					Cancel
				</Button>
				<Button
					loading={buttonLoader}
					disabled={!isValidEmail(email)}
					onClick={() => {
						let data = {
							crmId: 5,
							userId: user.userID,
							config: { ACCESS_KEY: email },
							tenantId: tenantId,
						};
						dispatch(setup(data));
					}}>
					Add
				</Button>
			</>
		);
	};
	return (
		<div className='ff-email-integration-modal'>
			<Modal header='Add Email' actionButtons={buttons()}>
				<Input
					focused
					fullWidth
					value={email}
					onChange={(event) => setEmail(event.target.value)}
					label='Enter mail id'
					autoFocus
				/>
			</Modal>
		</div>
	);
};

export default Email;
