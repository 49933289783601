import { useState } from 'react';
import { RadioCheck, RadioUncheck } from '../../assets/Icons';
import './Radio.scss';

type RadioProps = {
	isChecked: string | undefined;
	value: string;
	onValueChange: React.Dispatch<string>;
	label?: string | React.ReactNode;
	name: string;
	labelDesc?:string
} & React.ComponentProps<'input'>;

const Radio = ({
	label,
	value,
	isChecked,
	onValueChange,
	name,
	labelDesc,
	...rest
}: RadioProps) => {
	const [id] = useState('radio_' + Math.random().toString(36).substring(2, 7)); //NOSONAR

	const handleChange = () => {
		onValueChange(value);
	};

	return (
		<div className='ff-radio'>
			<input
				type='radio'
				checked={isChecked === value}
				id={id}
				name={name}
				value={value}
				onChange={handleChange}
				{...rest}
			/>
			<div>
			<label htmlFor={id}>
				<div className={`check ${isChecked === value ? 'checked' : ''}`}>
					{isChecked === value ? <RadioCheck /> : <RadioUncheck />}
				</div>
				<div>
				{label && <span className='label'>{label}</span>}
				{labelDesc&&
	         <div>
				<p className='caption'>{labelDesc}</p>
			 </div>		    
     
			}
			</div>
			</label>
			
			</div>
		</div>
	);
};
export default Radio;
