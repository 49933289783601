import React from 'react';
import './NotifyMessage.scss';
import { removeState, saveState } from '../../../utils/utils';
import { selectTeam } from '../../../Features/teams/TeamsSlice';
import { RootState, useAppDispatch } from '../../../app/store';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	resetAllNotificationData,
	resetNewNotificationCount,
	setNotificationPane,
} from '../../../Features/Notifications/notificationSlice';
import { setLiveChatNotification } from '../../../Features/chats/ChatSlice';
export const NotifyInfoIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='Group 34318'
			width='36'
			height='36'
			viewBox='0 0 36 36'>
			<g data-name='Ellipse 1357' fill='#f3f2ff' stroke='#e8e6ff'>
				<circle cx='18' cy='18' r='18' stroke='none' />
				<circle cx='18' cy='18' r='17.5' fill='none' />
			</g>
			<path
				data-name='Path 20054'
				d='M0 0h24v24H0z'
				fill='none'
				transform='translate(6 6)'
			/>
			<path
				data-name='Path 20055'
				d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm1 15h-2v-6h2zm0-8h-2V7h2z'
				fill='#4b41eb'
				transform='translate(6 6)'
			/>
		</svg>
	);
};

export const NotifyAlertIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='36'
			height='36'
			viewBox='0 0 36 36'>
			<g data-name='Group 34294'>
				<g data-name='Ellipse 1358' fill='#fde5f1' stroke='#ffc6e2'>
					<circle cx='18' cy='18' r='18' stroke='none' />
					<circle cx='18' cy='18' r='17.5' fill='none' />
				</g>
				<g data-name='error_black_24dp (1)'>
					<path
						data-name='Path 20056'
						d='M0 0h24v24H0z'
						fill='none'
						transform='translate(6 6)'
					/>
					<path
						data-name='Path 20057'
						d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm1 15h-2v-2h2zm0-4h-2V7h2z'
						fill='#c12774'
						transform='translate(6 6)'
					/>
				</g>
			</g>
		</svg>
	);
};

export const NotifyLiveChat = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='36'
			height='36'
			viewBox='0 0 36 36'>
			<g data-name='Group 34239'>
				<g
					data-name='Ellipse 1295'
					style={{ fill: '#fde5f1', stroke: '#ffc6e2' }}>
					<circle cx='18' cy='18' r='18' style={{ stroke: 'none' }} />
					<circle cx='18' cy='18' r='17.5' style={{ fill: 'none' }} />
				</g>
				<path
					data-name='Union 9'
					d='M9.972 21.169a1.545 1.545 0 0 1-1.3-1.51c0-.689.612-1.548 1.3-1.548h3.437a1.813 1.813 0 0 1 1.081.566h.724a3.191 3.191 0 0 0 2.478-1.039 2.629 2.629 0 0 0 .908-2.248l-.194-6.252c0-4.13-2.647-6.776-6.777-6.776s-6.76 2.646-6.76 6.776v5.118a1.754 1.754 0 0 1-1.546 1.837H2.255A2.255 2.255 0 0 1 0 13.838v-2.863A2.255 2.255 0 0 1 2.255 8.72h.259a9.128 9.128 0 0 1 18.238 0h.259a2.255 2.255 0 0 1 2.255 2.255v2.863a2.255 2.255 0 0 1-2.255 2.253h-.306a5.484 5.484 0 0 1-5.422 4.663h-.07l-.724-.006a1.236 1.236 0 0 1-.928.421zm4.815-10.792a1.247 1.247 0 1 1 1.247 1.247 1.246 1.246 0 0 1-1.247-1.247zm-4.4 0a1.246 1.246 0 1 1 1.246 1.247 1.246 1.246 0 0 1-1.246-1.247zm-4.4 0a1.247 1.247 0 1 1 1.247 1.247 1.246 1.246 0 0 1-1.247-1.247z'
					transform='translate(6.5 7.5)'
					style={{ fill: '#c12774' }}
				/>
			</g>
		</svg>
	);
};
type Props = {
	type?: 'info' | 'alert' | 'chat' | string;
	message: string;
	link: string;
	time: string | null;
	read?: number;
	req?: boolean;
};
const NotifyMessage = ({
	type = 'info',
	message,
	link,
	time,
	read = 0,
	req = false,
}: Props) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { teams } = useSelector((state: RootState) => state.teams);

	const mandatorySetup = () => {
		removeState('unreadNotificationsCount');
		dispatch(resetNewNotificationCount());
		dispatch(resetAllNotificationData());
	};

	const CTAName = (link: string, message: string) => {
		//User & Team Management
		if (
			link === 'User added to team' &&
			message.indexOf('You are added') > -1
		) {
			return 'Go to team';
		} else if (
			link === 'User added to team' &&
			message.indexOf('You are added') === -1
		) {
			return 'View team';
		} else if (link === 'Plan Expiring') {
			return 'Renew subscription';
		}
		return 'CTA';
	};
	return (
		<div className='ff-notifymessage-container'>
			{req ? null : read === 0 ? (
				<div className='the-dot'></div>
			) : (
				<div className='place-hold'></div>
			)}

			<div className='ff-notifymessage'>
				{type === 'chat' ? (
					<NotifyLiveChat />
				) : type === 'info' ? (
					<NotifyInfoIcon />
				) : (
					<NotifyAlertIcon />
				)}
				<div className='message-timer-notify'>
					<div className='message-link'>
						<p className='caption'>{message}</p>
						<p
							className='caption'
							// href={
							// 	type === 'chat'
							// 		? '/app/chats/my-chats'
							// 		: linkSelector(link, message)
							// }
							// target={type === 'chat' ? '' : '_blank'}
							// rel='noreferrer'
							onClick={() => {
								dispatch(setNotificationPane(false));
								if (type === 'chat') {
									dispatch(setLiveChatNotification(false))
									navigate('/app/chats/my-chats');
								} else {
									if (
										link === 'User added to team' &&
										message.indexOf('You are added') > -1
									) {
										let team = teams.filter(
											(team) => message.indexOf(team.name) > -1
										)[0];
										saveState('teamDet', team);
										saveState('tenantId', team.id);
										saveState('organizationName', team.organizationName);
										saveState('teamname', team.name);
										saveState('tenantImageURL', team.backgroundImage);

										dispatch(
											selectTeam({
												tenantId: team.id,
												teamname: team.name,
											})
										);
										mandatorySetup();
										navigate('/app/create-ai');
									} else if (
										link === 'User added to team' &&
										message.indexOf('You are added') === -1
									) {
										let team = teams.filter(
											(team) => message.indexOf(team.name) > -1
										)[0];
										saveState('teamDet', team);
										saveState('tenantId', team.id);
										saveState('organizationName', team.organizationName);
										saveState('teamname', team.name);
										saveState('tenantImageURL', team.backgroundImage);

										dispatch(
											selectTeam({
												tenantId: team.id,
												teamname: team.name,
											})
										);
										saveState('selectedGeneralTab', 'Users');
										navigate('/app/general-configuration/Users');
									} else if (link === 'Plan Expiring') {
										saveState('selectedGeneralTab', 'Subscription');
										navigate('/app/general-configuration/subscription');
									}
								}
							}}>
							{CTAName(link, message)}
						</p>
					</div>
					{time ? <p className='caption'>{time}</p> : null}
				</div>
			</div>
		</div>
	);
};

export default NotifyMessage;
