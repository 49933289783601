import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Button from '../../../Components/Button';
import Modal from '../../../Components/Modal';

const ReleaseModal = () => {
	const navigate = useNavigate();
	const context: any = useOutletContext();
	return (
		<Modal
			header='End Chat?'
			onClose={() => navigate(-1)}
			actionButtons={
				<>
					<Button onClick={() => navigate(-1)} buttonType='light'>
						Cancel
					</Button>
					<Button
						onClick={() => {
							context.handleEndChat();
						}}>
						End
					</Button>
				</>
			}>
			<div className='ff_release_chat'>
				Please confirm that you wish to end this chat. You will be disconnected
				from this user.
			</div>
		</Modal>
	);
};

export default ReleaseModal;
