import './Pagination.scss';
// import '../../../../src/fonts.css';
// import '../../../../src/colors.css';
import { IconButton, Tooltip } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
type ButtonProps = {
	nextPage: (e: React.MouseEvent<HTMLButtonElement>) => void;
	previousPage: (e: React.MouseEvent<HTMLButtonElement>) => void;
	gotoBegining: (e: React.MouseEvent<HTMLButtonElement>) => void;
	gotoLast: (e: React.MouseEvent<HTMLButtonElement>) => void;
	total: number;
	upper: number;
	lower: number;
	disableforward: boolean;
	disableback: boolean;
} & React.ComponentProps<'button'>;

function Pagination({
	children,
	total,
	lower,
	upper,
	disableback,
	disableforward,
	gotoBegining,
	previousPage,
	nextPage,
	gotoLast,
}: ButtonProps) {
	return (
		<div className='sf-pagination'>
			<span className='body-2 sf-pagination-counter'>
				{lower} - {upper} of {total}
			</span>
			<span className='sf-pagination-btns'>
				<Tooltip placement='bottom' title='Go to beginning'>
					<span>
						<IconButton
							disabled={disableback}
							className='sf-pagination-button-comp'
							onClick={gotoBegining}
							aria-label='Go to beginning'>
							<FirstPageIcon fontSize='inherit' color='inherit' />
						</IconButton>
					</span>
				</Tooltip>

				<Tooltip placement='bottom' title='Previous'>
					<span>
						<IconButton
							disabled={disableback}
							className='sf-pagination-button-comp'
							onClick={previousPage}
							aria-label='previous page'>
							<KeyboardArrowLeft fontSize='small' color='inherit' />
						</IconButton>
					</span>
				</Tooltip>

				<Tooltip placement='bottom' title='Next'>
					<span>
						<IconButton
							disabled={disableforward}
							className='sf-pagination-button-comp'
							onClick={nextPage}
							aria-label='next page'>
							<KeyboardArrowRight fontSize='small' color='inherit' />
						</IconButton>
					</span>
				</Tooltip>

				<Tooltip placement='bottom' title='Go to end'>
					<span>
						<IconButton
							disabled={disableforward}
							className='sf-pagination-button-comp'
							onClick={gotoLast}
							aria-label='Go to end'>
							<LastPageIcon fontSize='small' color='inherit' />
						</IconButton>
					</span>
				</Tooltip>
			</span>
		</div>
	);
}

export default Pagination;
