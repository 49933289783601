interface Props {
	width?: string;
	height?: string;
	fillIcon?: boolean;
	className?: string;
	color?: string;
	onClick?: (e: React.MouseEvent<SVGElement>) => void;
	style?: React.CSSProperties;
	opacity?: string;
	rotateSVG?: boolean;
	onBlur?: (e: React.FocusEvent<SVGElement>) => void;
}
export const DeleteIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<g style={{ opacity: props.opacity }}>
				<path data-name='Path 14771' d='M0 0h24v24H0z' fill='none' />
				<path
					data-name='Path 14772'
					d='M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5zM18 7H6v12a2.006 2.006 0 0 0 2 2h8a2.006 2.006 0 0 0 2-2z'
				/>
			</g>
		</svg>
	);
};
export const EditIconFAQ = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<g style={{ opacity: 0.6 }}>
				<path data-name='Rectangle 15473' fill='none' d='M0 0h24v24H0z' />
				<path
					data-name='Path 14953'
					d='M3 10h11v2H3zm0-2h11V6H3zm0 8h7v-2H3zm15.01-3.13.71-.71a1 1 0 0 1 1.41 0l.71.71a1 1 0 0 1 0 1.41l-.71.71zm-.71.71-5.3 5.3V21h2.12l5.3-5.3z'
				/>
			</g>
		</svg>
	);
};
export const EditIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<g style={{ opacity: props.opacity }}>
				<path data-name='Path 14773' d='M0 0h24v24H0z' fill='none' />
				<path
					data-name='Path 14774'
					d='m14.06 9.02.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3a1 1 0 0 0-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a1 1 0 0 0 0-1.41l-2.34-2.34a.982.982 0 0 0-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z'
				/>
			</g>
		</svg>
	);
};
export const SmallEditIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'>
			<g style={{ opacity: props.opacity }}>
				<path
					data-name='Path 14773'
					d='M0 0h20v20H0z'
					style={{ fill: 'none' }}
				/>

				<path
					data-name='Path 14774'
					d='m11.717 7.517.767.767-7.55 7.55h-.767v-.767l7.55-7.55m3-5.017a.834.834 0 0 0-.583.242l-1.526 1.525 3.125 3.125 1.525-1.525a.83.83 0 0 0 0-1.175l-1.95-1.95a.818.818 0 0 0-.591-.242zm-3 2.658L2.5 14.375V17.5h3.125l9.217-9.217-3.125-3.125z'
				/>
			</g>
		</svg>
	);
};
export const SmallDeleteIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'>
			<g style={{ opacity: props.opacity }}>
				<path
					data-name='Path 14771'
					d='M0 0h20v20H0z'
					style={{ fill: 'none' }}
				/>

				<path
					data-name='Path 14772'
					d='M13.334 7.5v8.333H6.667V7.5h6.667m-1.25-5H7.917l-.833.833H4.167V5h11.667V3.333h-2.917zm2.917 3.333h-10v10A1.672 1.672 0 0 0 6.667 17.5h6.667a1.672 1.672 0 0 0 1.667-1.667z'
				/>
			</g>
		</svg>
	);
};
