import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BotState } from '../Bots/BotSlice';
import { set } from '../message/messageSlice';
import { TeamsState } from '../teams/TeamsSlice';
import channelService from './channelService';
import {
	AddOriginResponse,
	AddOriginURL,
	Channel,
	DeleteOriginResponse,
	DeleteOriginURL,
	getBotId,
	GetChannels,
	GetURL,
	OriginURL,
	VersionsDetails,
} from './modal';

type webOriginList = {
	originLinksList: OriginURL[];
	deployedVersionsList: VersionsDetails[];
	areVersionsLoaded: boolean;
	originAddSuccess: string;

	originEditedSuccess: boolean;
	originDeletedSuccess: string;
	openModalForLaunch: boolean;
	channelList: Channel[];
	websiteOrigin: string;
	websiteOriginList: { origin: string; id: number }[];
	websiteDeleteLoader: string;
	originConflict: string;
};

const initialState: webOriginList = {
	originLinksList: [],
	deployedVersionsList: [],
	areVersionsLoaded: false,
	originAddSuccess: '',

	originEditedSuccess: false,
	originDeletedSuccess: '',
	openModalForLaunch: false,
	channelList: [],
	websiteOrigin: '',
	websiteOriginList: [{ origin: '', id: 0 }],
	websiteDeleteLoader: '',
	originConflict: '',
};

export const getOriginData = createAsyncThunk<
	{ origin: string; id: number }[],
	GetURL
>(
	'channel/getOriginData',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await channelService.getOriginListData(data);
			if (response.data) {
				return response.data.data.origins;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const addOriginURL = createAsyncThunk<
	any,
	AddOriginURL,
	{ rejectValue: string }
>(
	'channel/addOriginURL',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await channelService.addOriginURL(data);
			if (
				response.status === 200 &&
				response.data.status.toLowerCase() === 'success'
			) {
				return response.data;
			}
		} catch (error: any) {
			return rejectWithValue(JSON.stringify(error.response.data.message));
		}
	}
);

export const EditOriginURL = createAsyncThunk<
	any,
	AddOriginURL,
	{ rejectValue: string }
>(
	'channel/EditOriginURL',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await channelService.addOriginURL(data);
			if (response.status === 200 && response.data.response === 'success') {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

//request parameters for ADD and DELETE origin are same (AddOriginURL,DeleteOriginURL)
export const delOriginURL = createAsyncThunk<
	any,
	DeleteOriginURL,
	{ rejectValue: string }
>(
	'channel/delOriginURL',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await channelService.delOriginURL(data);
			console.log('responseresponse', response);
			if (
				(response.status === 202 || response.status === 200) &&
				response.data.status.toLowerCase() === 'success'
			) {
				// const { bots } = getState() as { bots: BotState };
				// const { teams } = getState() as { teams: TeamsState };
				// teams.tenantId &&
				// 	dispatch(
				// 		getChannels({
				// 			botId: bots.activeBot.botId,
				// 			tenantId: teams.tenantId,
				// 		})
				// 	);
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const getVersionDetails = createAsyncThunk<VersionsDetails[], getBotId>(
	'channel/getVersionDetails',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await channelService.getVersionDetails(data);
			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const getChannels = createAsyncThunk<Channel[], GetChannels>(
	'channel/getChannels',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await channelService.getChannels(data);
			if (response.data) {
				return response.data.channels;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

const channelSlice = createSlice({
	name: 'channel',
	initialState,
	reducers: {
		setOriginAddSuccess: (state, action) => {
			state.originAddSuccess = action.payload;
		},

		setOriginEditedSuccess: (state, action) => {
			state.originEditedSuccess = action.payload;
		},
		setOriginDeletedSuccess: (state, action) => {
			state.originDeletedSuccess = action.payload;
		},
		setAreVersionsLoaded: (state, action) => {
			state.areVersionsLoaded = action.payload;
		},
		setOpenModalForLaunch: (state, action) => {
			state.openModalForLaunch = action.payload;
		},
		setWebsiteOrigin: (state, action) => {
			state.websiteOrigin = action.payload;
		},
		resetOriginAddSuccess: (state) => {
			state.originAddSuccess = '';
		},
		resetWebsiteDeleteLoader: (state) => {
			state.websiteDeleteLoader = '';
		},
		resetOriginConflict: (state) => {
			state.originConflict = '';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getOriginData.fulfilled, (state, action) => {
				// state.originLinksList = action.payload;
				state.websiteOriginList = action.payload;
			})
			.addCase(getVersionDetails.pending, (state, action) => {
				state.deployedVersionsList = [];
			})
			.addCase(getVersionDetails.fulfilled, (state, action) => {
				state.deployedVersionsList = action.payload;
				state.areVersionsLoaded = true;
			})
			.addCase(getVersionDetails.rejected, (state, action) => {
				state.deployedVersionsList = [];
			})
			.addCase(addOriginURL.pending, (state) => {
				state.originAddSuccess = 'pending';
			})
			.addCase(addOriginURL.fulfilled, (state) => {
				state.originAddSuccess = 'success';
			})
			.addCase(addOriginURL.rejected, (state, action) => {
				state.originAddSuccess = 'failed';
				if (action.payload) {
					state.originConflict = action.payload;
				}
			})
			.addCase(EditOriginURL.fulfilled, (state) => {
				state.originEditedSuccess = true;
			})
			.addCase(delOriginURL.pending, (state) => {
				// state.originDeletedSuccess = 'pending';
				state.websiteDeleteLoader = 'loading';
			})
			.addCase(delOriginURL.fulfilled, (state) => {
				// state.originDeletedSuccess = 'success';
				state.websiteDeleteLoader = 'success';
			})
			.addCase(delOriginURL.rejected, (state) => {
				// state.originDeletedSuccess = 'failed';
				state.websiteDeleteLoader = 'failed';
			})
			.addCase(getChannels.fulfilled, (state, action) => {
				state.channelList = action.payload;
			});
	},
});

export const {
	setOriginAddSuccess,
	setOriginEditedSuccess,
	setOriginDeletedSuccess,
	setAreVersionsLoaded,
	setOpenModalForLaunch,
	setWebsiteOrigin,
	resetOriginAddSuccess,
	resetWebsiteDeleteLoader,
	resetOriginConflict,
} = channelSlice.actions;

export default channelSlice.reducer;
