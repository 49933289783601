import { useEffect, useState, useMemo } from 'react';
import { useAppDispatch } from '../../app/store';
import { BotBuilderPlus } from '../../assets/Icons';
import Button from '../../Components/Button';
import InteractiveButton from '../../Components/Button/InteractiveButton';
import Chip from '../../Components/Chip';
import Input from '../../Components/Input';
import { CloseBtn } from '../../Components/Modal/icons';
import TextBlock from '../../Components/TextBlock';
import { set, setSnack } from '../../Features/message/messageSlice';
import { CardMenuIcon } from '../MyStudio/icon';
import './ComponentsLibrary.scss';
import { CheckMarkCircle, Download, SearchIcon } from './Icons';
import List from '../../Components/List';
import { ListItemGroup } from '../../Components/List';
import Avatar from '../../Components/Avatar';
import Menu from '../../Components/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Tooltip } from '@mui/material';
import Modal from '../../Components/Modal';
import { Outlet, useNavigate } from 'react-router-dom';
import AutoComplete from '../../Components/AutoComplete';
import Table from '../../Components/Table';
import Pagination from '../../Components/Table/Pagination';
import Search from '../../Components/Search';
import Note from '../../Components/Note';
import NumberInput from '../../Components/NumberInput';
import IngestHeader from '../../Components/IngestHeader';

const ComponentsLibrary = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [lightLoading, setLightLoading] = useState(false);
	const [darkLoading, setDarkLoading] = useState(false);
	const [showModal1, setShowModal1] = useState(false);
	const [showModal2, setShowModal2] = useState(false);
	const [showModal3, setShowModal3] = useState(false);
	const [showModal4, setShowModal4] = useState(false);
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [limitInput, setLimitInput] = useState('');
	const [numberInputValue, setNumberInputValue] = useState<number>(1);
	const [numberInputValue2, setNumberInputValue2] = useState<number>(1);
	const [numberInputValue3, setNumberInputValue3] = useState<number>(11);
	const [numberInputValue4, setNumberInputValue4] = useState<number>(1);
	const [numberInputValue5, setNumberInputValue5] = useState<number>(1);
	const [numberInputValue6, setNumberInputValue6] = useState<number>(1);

	const handleLightButtonClick = () => {
		setLightLoading(true);
		setTimeout(() => {
			setLightLoading(false);
		}, 5000);
	};

	const handleDarkButtonClick = () => {
		setDarkLoading(true);
		setTimeout(() => {
			setDarkLoading(false);
		}, 5000);
	};

	const listItems1: ListItemGroup = [
		{ text: 'List item 1' },
		{ text: 'List item 2' },
		{ text: 'List item 3' },
		{
			text: 'List item 4',
			icon: <BotBuilderPlus width='20' height='20' />,
			moreOptions: (
				<MoreVertIcon
					fontSize='small'
					onClick={(event) => {
						setAnchorEl(event.target);
						setOpen(true);
					}}
				/>
			),
		},
		{
			text: 'List item 5',
			icon: <BotBuilderPlus width='20' height='20' />,
			moreOptions: (
				<MoreVertIcon
					fontSize='small'
					onClick={(event) => {
						setAnchorEl(event.target);
						setOpen(true);
					}}
				/>
			),
		},
		{
			text: 'List item 6',
			icon: <BotBuilderPlus width='20' height='20' />,
		},
	];

	const section1: ListItemGroup = [
		{ text: 'Section 1 item 1' },
		{ text: 'Section 1 item 2' },
	];

	const section2: ListItemGroup = [
		{ text: 'Section 2 item 1' },
		{ text: 'Section 2 item 2' },
	];

	const section3: ListItemGroup = [
		{ text: 'Section 3 item 1' },
		{ text: 'Section 3 item 2' },
	];

	const items1: ListItemGroup[] = [listItems1];
	const items2: ListItemGroup[] = [section1, section2, section3];

	const options = [
		{
			label: <p className='menu-label'>Rename</p>,
			onClick: () => {
				setOpen(false);
				dispatch(set('Rename Clicked'));
			},
		},
		{
			label: <p className='menu-label'>Edit</p>,
			onClick: () => {
				setOpen(false);
				dispatch(set('Edit Clicked'));
			},
		},
		{
			label: <p className='menu-label'>Delete</p>,
			onClick: () => {
				setOpen(false);
				dispatch(set('Delete Clicked'));
			},
		},
	];

	// Disabled button for Modal3
	const [enable, setEnable] = useState('');
	const isDisabled = () => {
		return enable.toLowerCase() !== 'enable';
	};

	// Dropdowns
	const [dropdownValue1, setDropdownValue1] = useState<any>([]);
	const [dropdownValue2, setDropdownValue2] = useState<any>(null);
	const [dropdownValue3, setDropdownValue3] = useState<any>(null);

	const dropdownOptions = [
		{ label: 'hi@abc.com', value: 'hi@abc.com' },
		{
			label: 'hello@abc.com',
			value: 'hello@abc.com',
		},
		{ label: 'hey@abc.com', value: 'hey@abc.com' },
		{ label: 'hola@abc.com', value: 'hola@abc.com' },
	];

	const body = useMemo(
		() => [
			{
				number: '9876543210',
				name: <p className='body1'>Srikant Manisankar Ramaswami</p>,
				status: <p className='body1'>Accepted</p>,
				place: <p className='body1'>Mumbai, Maharashtra</p>,
				date: <p className='body1'>20-03-2022 12:10 am</p>,
				comment: (
					<p className='body1'>
						The staff went extra mile and the quality was superb.
					</p>
				),
			},
			{
				number: '9876543210',
				name: <p className='body1'>Srikant Manisankar Ramaswami</p>,
				status: <p className='body1'>Accepted</p>,
				place: <p className='body1'>Mumbai, Maharashtra</p>,
				date: <p className='body1'>20-03-2022 12:10 am</p>,
				comment: (
					<p className='body1'>
						The staff went extra mile and the quality was superb.
					</p>
				),
			},
			{
				number: '9876543210',
				name: <p className='body1'>Srikant Manisankar Ramaswami</p>,
				status: <p className='body1'>Accepted</p>,
				place: <p className='body1'>Mumbai, Maharashtra</p>,
				date: <p className='body1'>20-03-2022 12:10 am</p>,
				comment: (
					<p className='body1'>
						The staff went extra mile and the quality was superb.
					</p>
				),
			},
			{
				number: '9876543210',
				name: <p className='body1'>Srikant Manisankar Ramaswami</p>,
				status: <p className='body1'>Accepted</p>,
				place: <p className='body1'>Mumbai, Maharashtra</p>,
				date: <p className='body1'>20-03-2022 12:10 am</p>,
				comment: (
					<p className='body1'>
						The staff went extra mile and the quality was superb.
					</p>
				),
			},
			{
				number: '9876543210',
				name: <p className='body1'>Srikant Manisankar Ramaswami</p>,
				status: <p className='body1'>Accepted</p>,
				place: <p className='body1'>Mumbai, Maharashtra</p>,
				date: <p className='body1'>20-03-2022 12:10 am</p>,
				comment: (
					<p className='body1'>
						The staff went extra mile and the quality was superb.
					</p>
				),
			},
			{
				number: '9876543210',
				name: <p className='body1'>Srikant Manisankar Ramaswami</p>,
				status: <p className='body1'>Accepted</p>,
				place: <p className='body1'>Mumbai, Maharashtra</p>,
				date: <p className='body1'>20-03-2022 12:10 am</p>,
				comment: (
					<p className='body1'>
						The staff went extra mile and the quality was superb.
					</p>
				),
			},
			{
				number: '9876543210',
				name: <p className='body1'>Srikant Manisankar Ramaswami</p>,
				status: <p className='body1'>Accepted</p>,
				place: <p className='body1'>Mumbai, Maharashtra</p>,
				date: <p className='body1'>20-03-2022 12:10 am</p>,
				comment: (
					<p className='body1'>
						The staff went extra mile and the quality was superb.
					</p>
				),
			},
			{
				number: '9876543210',
				name: <p className='body1'>Srikant Manisankar Ramaswami</p>,
				status: <p className='body1'>Accepted</p>,
				place: <p className='body1'>Mumbai, Maharashtra</p>,
				date: <p className='body1'>20-03-2022 12:10 am</p>,
				comment: (
					<p className='body1'>
						The staff went extra mile and the quality was superb.
					</p>
				),
			},
			{
				number: '9876543210',
				name: <p className='body1'>Srikant Manisankar Ramaswami</p>,
				status: <p className='body1'>Accepted</p>,
				place: <p className='body1'>Mumbai, Maharashtra</p>,
				date: <p className='body1'>20-03-2022 12:10 am</p>,
				comment: (
					<p className='body1'>
						The staff went extra mile and the quality was superb.
					</p>
				),
			},
		],
		[]
	);

	const [total, setTotal] = useState(body.length);
	useEffect(() => {
		setTotal(body.length);
	}, [body]);

	const page = 1;
	const limit = 10;
	const totalCount = 100;
	const [offset, setOffset] = useState((page - 1) * limit);
	useEffect(() => {
		setOffset((page - 1) * limit);
	}, [page, limit]);

	const [sortValue, setSortValue] = useState<any>(null);
	const sortOptions = [
		{ label: 'Relevance', value: 'Relevance' },
		{ label: 'Date', value: 'Date' },
		{ label: 'Status', value: 'Status' },
	];

	const validateEmail = (email: any) => {
		if (String(email).length <= 0) {
			return false;
		}

		return !Boolean(
			String(email)
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)
		);
	};

	return (
		<div className='ff-components-library'>
			<div className='text-styles'>
				<h2 className='bg-gradient'>This is H2 with gradient</h2>
				<h2>This is h2 text</h2>
				<h3>This is h3 text</h3>
				<h4>This is h4 text</h4>
				<h5>This is h5 text</h5>
				<h6>This is h6 text</h6>
				<p className='body1'>This is body1 text</p>
				<p className='body2'>This is body2 text</p>
				<p className='body-mono'>This is body mono text</p>
				<p className='button1'>This is button1 text</p>
				<p className='button2'>This is button2 text</p>
				<p className='caption'>This is caption text</p>
				<p className='overline'>This is overline text</p>
				<p className='timestamp'>This is timestamp text</p>
			</div>

			<div className='colors'>
				<div className='color-boxes'>
					<div className='color-box violet-50' title='--violet-50'></div>
					<div className='color-box violet-100' title='--violet-100'></div>
					<div className='color-box violet-200' title='--violet-200'></div>
					<div className='color-box violet-300' title='--violet-300'></div>
					<div className='color-box violet-450' title='--violet-450'></div>
					<div className='color-box violet-600' title='--violet-600'></div>
					<div className='color-box violet-800' title='--violet-800'></div>
					<div className='color-box violet-1000' title='--violet-1000'></div>
				</div>

				<div className='color-boxes'>
					<div className='color-box gold-50' title='--gold-50'></div>
					<div className='color-box gold-100' title='--gold-100'></div>
					<div className='color-box gold-200' title='--gold-200'></div>
					<div className='color-box gold-300' title='--gold-300'></div>
					<div className='color-box gold-400' title='--gold-400'></div>
					<div className='color-box gold-500' title='--gold-500'></div>
					<div className='color-box gold-700' title='--gold-700'></div>
					<div className='color-box gold-800' title='--gold-800'></div>
				</div>

				<div className='color-boxes'>
					<div
						className='color-box vibrant-pink-50'
						title='--vibrant-pink-50'></div>
					<div
						className='color-box vibrant-pink-100'
						title='--vibrant-pink-100'></div>
					<div
						className='color-box vibrant-pink-200'
						title='--vibrant-pink-200'></div>
					<div
						className='color-box vibrant-pink-300'
						title='--vibrant-pink-300'></div>
					<div
						className='color-box vibrant-pink-400'
						title='--vibrant-pink-400'></div>
					<div
						className='color-box vibrant-pink-500'
						title='--vibrant-pink-500'></div>
					<div
						className='color-box vibrant-pink-700'
						title='--vibrant-pink-700'></div>
					<div
						className='color-box vibrant-pink-800'
						title='--vibrant-pink-800'></div>
				</div>

				<div className='color-boxes'>
					<div className='color-box black-5' title='--black-5'></div>
					<div className='color-box black-10' title='--black-10'></div>
					<div className='color-box black-20' title='--black-20'></div>
					<div className='color-box black-40' title='--black-40'></div>
					<div className='color-box black-60' title='--black-60'></div>
					<div className='color-box black' title='--black'></div>
				</div>

				<div className='color-boxes'>
					<div className='color-box white' title='--white'></div>
					<div className='color-box rust-white' title='--rust-white'></div>
					<div className='color-box pearl-grey' title='--pearl-grey'></div>
				</div>

				<div className='color-boxes'>
					<div className='color-box tertiary-1' title='--tertiary-1'></div>
					<div className='color-box tertiary-2' title='--tertiary-2'></div>
					<div className='color-box tertiary-3' title='--tertiary-3'></div>
					<div className='color-box tertiary-4' title='--tertiary-4'></div>
					<div className='color-box tertiary-5' title='--tertiary-5'></div>
					<div className='color-box tertiary-6' title='--tertiary-6'></div>
					<div className='color-box tertiary-7' title='--tertiary-7'></div>
					<div className='color-box tertiary-8' title='--tertiary-8'></div>
					<div className='color-box tertiary-9' title='--tertiary-9'></div>
					<div className='color-box tertiary-10' title='--tertiary-10'></div>
					<div className='color-box tertiary-11' title='--tertiary-11'></div>
					<div className='color-box tertiary-12' title='--tertiary-12'></div>
				</div>
			</div>

			<div className='buttons'>
				<div className='button-container'>
					<Button
						buttonType='light'
						loading={lightLoading}
						onClick={handleLightButtonClick}>
						Light button
					</Button>
					<Button
						buttonType='light'
						loading={lightLoading}
						onClick={handleLightButtonClick}>
						Ok
					</Button>
					<Button loading={darkLoading} onClick={handleDarkButtonClick}>
						Dark button
					</Button>
					<Button loading={darkLoading} onClick={handleDarkButtonClick}>
						Ok
					</Button>
					<Button
						buttonType='light'
						onClick={handleLightButtonClick}
						loading={lightLoading}
						startIcon={<BotBuilderPlus width='20' height='20' />}>
						Light icon
					</Button>
					<Button
						loading={darkLoading}
						onClick={handleDarkButtonClick}
						startIcon={<BotBuilderPlus width='20' height='20' />}>
						Dark icon
					</Button>
					<Button buttonType='light' onClick={handleLightButtonClick} disabled>
						Light disabled
					</Button>
					<Button onClick={handleDarkButtonClick} disabled>
						Dark disabled
					</Button>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundImage:
								'linear-gradient(to right,var(--violet-800) 0,#626bd6 101%)',
							height: '100px',
							width: '200px',
						}}>
						<Button buttonType='transparent'>Transparent</Button>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundImage:
								'linear-gradient(to right,var(--violet-800) 0,#626bd6 101%)',
							height: '100px',
							width: '200px',
						}}>
						<Button buttonType='transparent' size='large'>
							Transparent
						</Button>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundImage:
								'linear-gradient(to right,var(--violet-800) 0,#626bd6 101%)',
							height: '100px',
							width: '200px',
						}}>
						<Button buttonType='transparent' size='large' disabled>
							Transparent
						</Button>
					</div>
				</div>

				<div className='button-container'>
					<Button
						buttonType='light'
						onClick={handleLightButtonClick}
						loading={lightLoading}
						size='large'>
						Light large
					</Button>
					<Button
						size='large'
						loading={darkLoading}
						onClick={handleDarkButtonClick}>
						Dark large
					</Button>
					<Button
						buttonType='light'
						onClick={handleLightButtonClick}
						loading={lightLoading}
						size='large'
						startIcon={<BotBuilderPlus />}>
						Light icon (L)
					</Button>
					<Button
						size='large'
						loading={darkLoading}
						onClick={handleDarkButtonClick}
						startIcon={<BotBuilderPlus />}>
						Dark icon (L)
					</Button>
				</div>

				<div className='button-container full-width'>
					<Button
						fullWidth
						size='large'
						loading={darkLoading}
						onClick={handleDarkButtonClick}
						startIcon={<BotBuilderPlus />}>
						Dark icon full width
					</Button>
					<Button
						fullWidth
						buttonType='light'
						onClick={handleLightButtonClick}
						loading={lightLoading}>
						Light full width
					</Button>
				</div>

				<div className='button-container'>
					<hr />

					<h6>Interactive Buttons</h6>
				</div>
				<div className='button-container tertiary-1'>
					<h6>Normal Interactive Icons</h6>
					<InteractiveButton
						icon={<CardMenuIcon width='25' />}
						onClick={() => { }}
						title='More options'
					/>
				</div>

				<div className='button-container tertiary-5'>
					<h6>Ring Interactive Icons</h6>
					<InteractiveButton
						hoverable='ring'
						title='More options'
						icon={<CardMenuIcon width='25' />}
						onClick={() => { }}
					/>
				</div>

				<div className='button-container tertiary-10'>
					<h6>Interactive Icons: Low emphasis</h6>
					<InteractiveButton
						hoverable='none'
						title='Close'
						icon={<CloseBtn width='25' />}
						onClick={() => { }}
					/>
				</div>

				<div className='button-container'>
					<hr />

					<h6>Note</h6>
				</div>

				<div className='button-container'>
					<p className='caption'>
						*Make sure icons have proper height or height of Button will be
						increasing!
					</p>
				</div>
			</div>

			<div className='dropdowns'>
				<div className='dropdown-container'>
					<AutoComplete
						label='Email'
						placeholder='Enter email'
						multiple={true}
						validator={validateEmail}
						helperText='Provide email address to add admin'
						errorText='Some email addresses are invalid'
						options={dropdownOptions}
						valueKey='value'
						labelKey='label'
						iconKey='icon'
						value={dropdownValue1}
						onChange={setDropdownValue1}
					/>
					<AutoComplete
						multiple={false}
						label='Email'
						placeholder='Enter email'
						helperText='Provide email address to add admin'
						options={dropdownOptions}
						valueKey='value'
						labelKey='label'
						value={dropdownValue2}
						onChange={setDropdownValue2}
					/>
					<AutoComplete
						multiple={false}
						placeholder='Enter email'
						helperText='Provide email address to add admin'
						options={dropdownOptions}
						valueKey='value'
						labelKey='label'
						value={dropdownValue3}
						onChange={setDropdownValue3}
						showIcon={false}
					/>
				</div>
			</div>

			<div className='buttons'>
				<div className='button-container'>
					<h6>Snackbars</h6>
				</div>

				<div className='button-container'>
					<Button
						onClick={() => dispatch(set('Intelligent Ad created'))}
						size='large'>
						Show snackbar
					</Button>

					<Button
						onClick={() =>
							dispatch(
								set(
									'Snackbar with longer text. The text must wrap to the next line.'
								)
							)
						}
						size='large'>
						Show another snackbar
					</Button>
					<Button
						onClick={() =>
							dispatch(
								setSnack({
									message: 'positive Ad created',
									testcase: 'positive',
								})
							)
						}
						size='large'>
						Show positive snackbar
					</Button>
					<Button
						onClick={() =>
							dispatch(
								setSnack({
									message:
										'Snackbar with longer text. The text must wrap to the next line.',
									testcase: 'negative',
								})
							)
						}
						size='large'>
						Show negative snackbar
					</Button>
				</div>

				<div className='button-container'>
					<hr />
					<h6>Modals</h6>
				</div>

				<div className='button-container'>
					<Button onClick={() => setShowModal1(true)} size='large'>
						Open modal
					</Button>
					<Button onClick={() => setShowModal2(true)} size='large'>
						Open modal with scroll
					</Button>
					<Button onClick={() => setShowModal3(true)} size='large'>
						Open modal 3
					</Button>
					<Button onClick={() => setShowModal4(true)} size='large'>
						Open modal 4
					</Button>
					<Button onClick={() => navigate('modal-with-path')} size='large'>
						Modal with path
					</Button>
					<Button
						onClick={() => navigate('modal-with-processing')}
						size='large'>
						Modal with processing
					</Button>
				</div>
			</div>

			<div className='user-badges'>
				<div>
					<h6>User Badges</h6>
				</div>
				<Tooltip title='hi@abc.com'>
					<span>
						<Avatar size='small' text='hi@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='hello@abc.com'>
					<span>
						<Avatar size='small' text='hello@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='random@abc.com'>
					<span>
						<Avatar size='small' text='random@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='example@abc.com'>
					<span>
						<Avatar size='large' text='example@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='learn@abc.com'>
					<span>
						<Avatar size='large' text='learn@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='rework@abc.com'>
					<span>
						<Avatar size='large' text='rework@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='hey@abc.com'>
					<span>
						<Avatar size='large' text='hey@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='bunny@abc.com'>
					<span>
						<Avatar size='large' text='bunny@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='peerless@abc.com'>
					<span>
						<Avatar size='large' text='peerless@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='hola@abc.com'>
					<span>
						<Avatar size='large' text='hola@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='effortless@abc.com'>
					<span>
						<Avatar size='large' text='effortless@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='available@abc.com'>
					<span>
						<Avatar size='large' text='available@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='rateme@abc.com'>
					<span>
						<Avatar size='large' text='rateme@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='nice@abc.com'>
					<span>
						<Avatar size='large' text='nice@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='ball@abc.com'>
					<span>
						<Avatar size='large' text='ball@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='calculative@abc.com'>
					<span>
						<Avatar size='large' text='calculative@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='yatch@abc.com'>
					<span>
						<Avatar size='large' text='yatch@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='booking@abc.com'>
					<span>
						<Avatar size='large' text='booking@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='apple@abc.com'>
					<span>
						<Avatar size='large' text='apple@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='horizontal@abc.com'>
					<span>
						<Avatar size='large' text='horizontal@abc.com' />
					</span>
				</Tooltip>
				<Tooltip title='jaggery@abc.com'>
					<span>
						<Avatar size='large' text='jaggery@abc.com' />
					</span>
				</Tooltip>
			</div>

			<div className='inputs'>
				<div className='input-container'>
					<h6>Normal with placeholder</h6>
					<Input
						label='Name'
						placeholder='John Perry'
						helperText='Please enter your name'
						autoFocus
					/>
				</div>

				<div className='input-container'>
					<h6>With start icon</h6>
					<Input
						placeholder='Search'
						startIcon={
							<div className='search-icon'>
								<SearchIcon />
							</div>
						}
					/>
				</div>

				<div className='input-container'>
					<h6>With end icon</h6>
					<Input
						placeholder='Search'
						endIcon={
							<div className='search-icon'>
								<SearchIcon />
							</div>
						}
					/>
				</div>

				<div className='input-container'>
					<h6>Multiline</h6>
					<Input
						multiline
						label='Comment'
						helperText='Please share your experience'
					/>
				</div>

				<div className='input-container'>
					<h6>Error</h6>
					<Input
						label='Name'
						defaultValue='John Penny'
						error
						errorText='Wrong name'
					/>
				</div>

				<div className='input-container'>
					<h6>Disabled</h6>
					<Input label='Name' disabled helperText='Please enter your name' />
				</div>

				<div className='input-container'>
					<h6>Filled disabled</h6>
					<Input
						label='Name'
						defaultValue='John Penny'
						disabled
						helperText='Please enter your name'
					/>
				</div>

				<div className='input-container'>
					<h6>Limit</h6>
					<Input
						label='Name'
						autoComplete='none'
						value={limitInput}
						onChange={(event) => setLimitInput(event.target.value)}
						limit={10}
						helperText='Please enter your name'
						error={true}
						errorText='invalid'
					/>
				</div>

				<div className='input-container'>
					<hr />

					<h6>Note:</h6>
				</div>

				<div className='input-container'>
					<p className='caption'>
						*Make sure icons have proper height or height of Input will be
						increasing!
					</p>
				</div>
			</div>

			<div className='text-blocks'>
				<div className='text-blocks-container'>
					<h6>Without scrollbar</h6>
					<TextBlock
						text={`https://awareuat.senseforth.com:8443/responsive.html?botID=V7kM2W%2BDeHhW6W4ekLdPbA%3D%3D`}
					/>
				</div>
				<div className='text-blocks-container'>
					<h6>Without vertical scrollbar</h6>
					<TextBlock
						textStyle='body-mono'
						height={200}
						text={`<script>
		function initFlyfishChatSDK() {
				var senseforthSDKconfig = {
						botID: “IIP0Q8bBkLls9v0ipQ3DHg==“,
						SF_BASE_URL: “https://awareuat.senseforth.com:8443”,
						SF_CHAT_URL: “https://awareuat.senseforth.com:8443”,
						version: ‘prod’
				};
		var sdk = new SenseforthChatSDK(senseforthSDKconfig);
		sdk.init();
		}
</script>
<script src=“https://awareuat.senseforth.com:8443/chatsdk/v1/SenseforthChatSDKLoader.js”></script>`}
					/>
				</div>
				<div className='text-blocks-container'>
					<h6>Without vertical &amp; horizontal scrollbar</h6>
					<TextBlock
						textStyle='body-mono'
						height={200}
						horizontalScroll
						text={`<script>
		function initFlyfishChatSDK() {
				var senseforthSDKconfig = {
						botID: “IIP0Q8bBkLls9v0ipQ3DHg==“,
						SF_BASE_URL: “https://dev.flyfish.ai”,
						SF_CHAT_URL: “https://dev.flyfish.ai”,
						version: ‘prod’
				};
		var sdk = new SenseforthChatSDK(senseforthSDKconfig);
		sdk.init();
		}
</script>
<script src=“https://dev.flyfish.ai/chatsdk/v1/SenseforthChatSDKLoader.js”></script>`}
					/>
				</div>
			</div>

			<div className='lists'>
				<div className='list'>
					<h6>Normal List</h6>
					<List items={items1} />
				</div>

				<div className='list'>
					<h6>List with width</h6>
					<List items={items1} width={185} />
				</div>

				<div className='list'>
					<h6>Sectioned List with width</h6>
					<List items={items2} width={150} />
				</div>

				<Menu
					anchorEl={anchorEl}
					open={open}
					setOpen={setOpen}
					options={options}
				/>
			</div>

			<div className='chips'>
				<div>
					<h6>Chips</h6>
				</div>
				<div style={{ display: 'flex' }}>
					<p className='caption'>Chip without icon : </p>
					<Chip label='Archived' />
				</div>
				<div style={{ display: 'flex' }}>
					<p className='caption'>Chip with icon (default position is left): </p>
					<Chip label='Linked' icon={<CheckMarkCircle color='inherit' />} />
				</div>
				<div style={{ display: 'flex' }}>
					<p className='caption'>Chip with icon (position = right): </p>
					<Chip
						label='Linked'
						position='right'
						icon={<CheckMarkCircle color='inherit' />}
					/>
				</div>
				<div>
					<h6>Color Coded Chips</h6>
				</div>

				<Chip label='Positive' code='positive' />
				<Chip label='Negative' code='negative' />
				<Chip label='Slightly Negative' code='slightly-negative' />
				<Chip label='Neutral' code='neutral' />

				<div>
					<h6>Color Coded Chips with icons</h6>{' '}
				</div>
				<div style={{ display: 'flex' }}>
					<p className='caption'>Chip with icon (default position is left): </p>
					<Chip
						label='Positive'
						code='positive'
						icon={<CheckMarkCircle color='inherit' />}
					/>
				</div>
				<div style={{ display: 'flex' }}>
					<p className='caption'>Chip with icon (position : right): </p>
					<Chip
						label='Positive'
						code='positive'
						position='right'
						icon={<CheckMarkCircle color='inherit' />}
					/>
				</div>
			</div>

			<div className='tables'>
				<Table
					onRowClick={(row: any) => {
						alert('Row clicked: ' + row.number);
						console.log(row);
					}}
					height={300}
					column={[
						{ key: 'number', title: 'Number', width: 131 },
						{ key: 'name', title: 'Name', width: 243 },
						{ key: 'status', title: 'Status', width: 123 },
						{ key: 'place', title: 'Place', width: 181 },
						{ key: 'date', title: 'Date', width: 183 },
						{ key: 'comment', title: 'Comment' },
					]}
					data={body}
					page={2}
					limit={7}
					totalCount={totalCount}
					isLoading={false}
					pagination={
						<Pagination
							disableforward={totalCount <= offset + total}
							disableback={offset === 0}
							nextPage={() => { }}
							previousPage={() => { }}
							gotoBegining={() => { }}
							gotoLast={() => { }}
							totalCount={totalCount}
							upper={total + offset}
							lower={offset + 1}
						/>
					}
					buttons={<InteractiveButton icon={<Download />} title='download' />}
					search={<Search />}
					filters={
						<>
							<AutoComplete
								multiple={false}
								placeholder='Sort'
								options={sortOptions}
								valueKey='value'
								labelKey='label'
								value={sortValue}
								onChange={setSortValue}
							/>
						</>
					}
				/>
			</div>

			{/* Modals */}
			{showModal1 && (
				<Modal
					headerCloseBtn
					action='Done'
					onAction={() => {
						dispatch(set('Done clicked'));
						setShowModal1(false);
					}}
					onClose={() => {
						setShowModal1(false);
					}}
					header='Normal modal'>
					<p>
						Body- Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
						diam nonumy eirmod tempor invidunt ut labore et dolore magna
						aliquyam
					</p>
				</Modal>
			)}

			{showModal2 && (
				<Modal
					headerCloseBtn
					action='Done'
					onAction={() => {
						dispatch(set('Done clicked'));
						setShowModal2(false);
					}}
					onClose={() => {
						setShowModal2(false);
					}}
					header='Modal with scrollable content'>
					<p>
						Body- Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
						diam nonumy eirmod tempor invidunt ut labore et dolore magna
						aliquyam.
					</p>
					<p>
						Rem ipsum dolor sit amet, consetetur sadipscing elitr, sed nonumy
						eirmod tempor invidunt ut labore et dolore magna. Rem ipsum dolor
						sit amet, consetetur sadipscing elitr, sed nonumy eirmod tempor
						invidunt ut labore et dolore magna. Rem ipsum dolor sit amet,
						consetetur sadipscing elitr, sed nonumy eirmod tempor invidunt ut
						labore et dolore magna.Rem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed nonumy eirmod tempor invidunt ut labore et
						dolore magna.
					</p>
					<p>
						Rem ipsum dolor sit amet, consetetur sadipscing elitr, sed nonumy
						eirmod tempor invidunt ut labore et dolore magna. Rem ipsum dolor
						sit amet, consetetur sadipscing elitr, sed nonumy eirmod tempor
						invidunt ut labore et dolore magna. Rem ipsum dolor sit amet,
						consetetur sadipscing elitr, sed nonumy eirmod tempor invidunt ut
						labore et dolore magna.Rem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed nonumy eirmod tempor invidunt ut labore et
						dolore magna. Rem ipsum dolor sit amet, consetetur sadipscing elitr,
						sed nonumy eirmod tempor invidunt ut labore et dolore magna. Rem
						ipsum dolor sit amet, consetetur sadipscing elitr, sed nonumy eirmod
						tempor invidunt ut labore et dolore magna. Rem ipsum dolor sit amet,
						consetetur sadipscing elitr, sed nonumy eirmod tempor invidunt ut
						labore et dolore magna.Rem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed nonumy eirmod tempor invidunt ut labore et
						dolore magna.
					</p>
					<p>
						Rem ipsum dolor sit amet, consetetur sadipscing elitr, sed nonumy
						eirmod tempor invidunt ut labore et dolore magna. Rem ipsum dolor
						sit amet, consetetur sadipscing elitr, sed nonumy eirmod tempor
						invidunt ut labore et dolore magna. Rem ipsum dolor sit amet,
						consetetur sadipscing elitr, sed nonumy eirmod tempor invidunt ut
						labore et dolore magna.Rem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed nonumy eirmod tempor invidunt ut labore et
						dolore magna. Rem ipsum dolor sit amet, consetetur sadipscing elitr,
						sed nonumy eirmod tempor invidunt ut labore et dolore magna. Rem
						ipsum dolor sit amet, consetetur sadipscing elitr, sed nonumy eirmod
						tempor invidunt ut labore et dolore magna. Rem ipsum dolor sit amet,
						consetetur sadipscing elitr, sed nonumy eirmod tempor invidunt ut
						labore et dolore magna.Rem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed nonumy eirmod tempor invidunt ut labore et
						dolore magna.
					</p>
					<p>
						Rem ipsum dolor sit amet, consetetur sadipscing elitr, sed nonumy
						eirmod tempor invidunt ut labore et dolore magna. Rem ipsum dolor
						sit amet, consetetur sadipscing elitr, sed nonumy eirmod tempor
						invidunt ut labore et dolore magna. Rem ipsum dolor sit amet,
						consetetur sadipscing elitr, sed nonumy eirmod tempor invidunt ut
						labore et dolore magna.Rem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed nonumy eirmod tempor invidunt ut labore et
						dolore magna. Rem ipsum dolor sit amet, consetetur sadipscing elitr,
						sed nonumy eirmod tempor invidunt ut labore et dolore magna. Rem
						ipsum dolor sit amet, consetetur sadipscing elitr, sed nonumy eirmod
						tempor invidunt ut labore et dolore magna. Rem ipsum dolor sit amet,
						consetetur sadipscing elitr, sed nonumy eirmod tempor invidunt ut
						labore et dolore magna.Rem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed nonumy eirmod tempor invidunt ut labore et
						dolore magna.
					</p>
				</Modal>
			)}

			{showModal3 && (
				<Modal
					actionButtons={
						<>
							<Button buttonType='light' onClick={() => setShowModal3(false)}>
								Cancel
							</Button>
							<Button
								onClick={() => setShowModal3(false)}
								disabled={isDisabled()}>
								Done
							</Button>
						</>
					}
					onAction={() => {
						dispatch(set('Done clicked'));
						setShowModal3(false);
					}}
					onClose={() => setShowModal3(false)}
					header='Modal without close button and disabled action button'>
					<Input
						placeholder='Input'
						helperText='Type enable to activate button'
						fullWidth
						onChange={(event) => setEnable(event.target.value)}
					/>
				</Modal>
			)}

			{showModal4 && (
				<Modal
					headerCloseBtn
					actionButtons={
						<div className='footer-buttons'>
							<div className='button-container'>
								<Button buttonType='light' onClick={() => setShowModal4(false)}>
									Remove
								</Button>
							</div>
							<div className='button-container'>
								<Button buttonType='light' onClick={() => setShowModal4(false)}>
									Cancel
								</Button>
								<Button onClick={() => setShowModal4(false)}>Done</Button>
							</div>
						</div>
					}
					className='custom-footer-buttons'
					onAction={() => {
						dispatch(set('Done clicked'));
						setShowModal4(false);
					}}
					onClose={() => setShowModal4(false)}
					header='Modal with three buttons'>
					<p>
						Body- Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
						diam nonumy eirmod tempor invidunt ut labore et dolore magna
						aliquyam
					</p>
				</Modal>
			)}
			<Note severity='info'>This is random info text</Note>
			<Note severity='error'>This is random error text</Note>
			<Note severity='info' removeMainIcon>
				This is random info text without main icon
			</Note>
			<Note severity='error' removeMainIcon>
				This is random error text without main icon
			</Note>
			<Note severity='info' removeCloseIcon>
				This is random info text without close icon
			</Note>
			<Note severity='error' removeCloseIcon>
				This is random error text without close icon
			</Note>
			<Note severity='info' removeCloseIcon removeMainIcon>
				This is random info text without main & close icon
			</Note>
			<Note severity='error' removeCloseIcon removeMainIcon>
				This is random error text without main & close icon
			</Note>
			<Note severity='info'>
				This is random info text - A note is a piece of information used to give
				additional information about something to users. These can be used for
				warnings, errors or general information on different screens.
			</Note>
			<Note severity='error'>
				This is random error text - For product purchase to work, you need to
				link your Flyfish team with your store on an E-commerce platform such as
				Shopify. You can find this option under the Integrations tab of
				Configurations{' '}
			</Note>
			<NumberInput
				value={numberInputValue}
				label={'Number Input'}
				onChange={setNumberInputValue}
				min={2}
				max={10}
				helperText={'I am the helpText'}
			/>
			<NumberInput
				value={numberInputValue2}
				onChange={setNumberInputValue2}
				min={2}
				max={10}
				helperText={'I am the helpText'}
				maxLimitMessage={'I am the max limit message'}
				minLimitMessage={'I am the min limit message'}
			/>
			<NumberInput
				value={numberInputValue3}
				onChange={setNumberInputValue3}
				label={'Error Input'}
				error={true}
				errorText={
					'I am the errorText will get displayed when error is set to true'
				}
				helperText={'I am the helpText'}
				min={1}
				max={10}
				fullWidth // will fit the parent container
			/>
			<NumberInput
				value={numberInputValue4}
				onChange={setNumberInputValue4}
				helperText={'I am the helpText'}
				min={1}
				max={10}
				maxLimitMessage={'I am the max limit message'}
				minLimitMessage={'I am the min limit message'}
				fullWidth // will fit the parent container
			/>
			<NumberInput
				label='Without helperText'
				value={numberInputValue5}
				onChange={setNumberInputValue5}
				min={1}
				max={10}
			/>
			<NumberInput
				label='Without decimal value'
				value={numberInputValue6}
				onChange={setNumberInputValue6}
				min={1}
				max={10}
				dotNotation={false}
			/>
			<IngestHeader
				header='Sample Header'
				breadCrumbs={[
					{
						title: '1. Select Platform',
						path: '',
					},
					{
						title: '2. Link Platform',
						path: '',
					},
				]}
				prevButtonName='Back'
				prevButtonNav={() => { }}
				nextButtonName='Next'
				nextButtonNav={() => { }}
				disabled='Next'
			/>
			{/* for modal with path in url */}
			<Outlet />
		</div>
	);
};
export default ComponentsLibrary;
