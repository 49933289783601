import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { Outlet, useLocation, useParams } from 'react-router-dom';
// import { RootState, useAppDispatch } from '../../app/store';
import { useNavigate } from 'react-router-dom';
import Header2 from '../Header2';
import Button from '../Button';
import './ingestHeader.scss'
import { IngestBackIcon } from '../../assets/Icons';
type IngestHeaderProps = {
	breadCrumbs?: any;
	header: string;
	prevButtonName?: string;
	prevButtonNav?: Function;
	nextButtonName?: string;
	nextButtonNav?: Function;
	disabled?: string;
};
const IngestHeader = ({ prevButtonName, prevButtonNav, nextButtonName, nextButtonNav, breadCrumbs, header, disabled }: IngestHeaderProps) => {
	return (
		<div className='ff-ingest-common-component'>


			<Button
				buttonType='light'
				onClick={() => { prevButtonNav && prevButtonNav() }}
				startIcon={<IngestBackIcon width='20' height='20' />}
				disabled={disabled === "prev" ? true : false}
			>
				{prevButtonName}
			</Button>
			<div>
				<h4>{header}</h4>
				<Header2 breadcrumbs={breadCrumbs} />
			</div>
			{nextButtonName && nextButtonName !== "" ? (
				<Button
					buttonType="light"
					onClick={() => {
						nextButtonNav && nextButtonNav();
					}}
					disabled={disabled === "Next" ? true : false}
				>
					{nextButtonName}
				</Button>
			) : null}

		</div>
	);
};
export default IngestHeader;
