import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../app/store';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import { set } from '../../../../../Features/message/messageSlice';
import {
	resetUnlinkFreshChatLoader,
	unlinkFreshchat,
} from '../../../../../Features/teamsetting/teamsettingSlice';

const Unlink = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { unlinkFreshChatLoader } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const [buttonLoader, setButtonLoader] = useState(false);

	const unlinkFreshChatAccount = () => {
		tenantId && dispatch(unlinkFreshchat({ tenantId }));
	};
	const buttons = () => {
		return (
			<>
				<Button onClick={() => navigate(-1)} buttonType='light'>
					<p>Cancel</p>
				</Button>
				<Button onClick={unlinkFreshChatAccount} loading={buttonLoader}>
					<p>Unlink</p>
				</Button>
			</>
		);
	};

	useEffect(() => {
		if (unlinkFreshChatLoader === 'success') {
			setButtonLoader(false);
			dispatch(set('Freshchat account unlinked'));
			navigate(-1);
		} else if (unlinkFreshChatLoader === 'failed') {
			setButtonLoader(false);
			dispatch(
				set(
					'Unable to unlink account at the moment. Please try after sometime!!!'
				)
			);
		} else if (unlinkFreshChatLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetUnlinkFreshChatLoader());
	}, [unlinkFreshChatLoader]);

	return (
		<div className='ff-freshchat-unlink'>
			<Modal
				header='Unlink your team from this Freshchat account?'
				actionButtons={buttons()}>
				<p className='body1'>
					Chats will stop appearing in Freshchat screen. You will be able to use
					the Chats screen on Flyfish.
				</p>
			</Modal>
		</div>
	);
};

export default Unlink;
