import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalClose } from '../../assets/Icons';
import Button from '../Button';
import InteractiveButton from '../Button/InteractiveButton';
import { CloseBtn } from './icons';
import './Modal.scss';

type ModalProps = {
	children: React.ReactNode;
	header?: string | React.ReactNode;
	headerCloseBtn?: boolean;
	actionButtons?: React.ReactNode | boolean;
	action?: string;
	onAction?: () => void;
	onClose?: () => void;
	className?: string;
	modalcontainerClassName?: string;
	imagePath?: any;
	autoHideDuration?: number;
	remove?: boolean;
};

const Modal = ({
	children,
	header,
	headerCloseBtn = false,
	action,
	onAction,
	actionButtons,
	onClose,
	className,
	modalcontainerClassName,
	imagePath,
	autoHideDuration,
	remove,
}: ModalProps) => {
	const navigate = useNavigate();
	useEffect(() => {
		if (autoHideDuration) {
			setTimeout(() => {
				onClose && onClose();
			}, autoHideDuration);
		}
	}, []);
	return (
		<div
			className={`${className ? className : ''} modal-overlay`}
			onClick={onClose ?? (() => navigate(-1))}>
			<div
				className={`${
					modalcontainerClassName ? modalcontainerClassName : 'modal-container'
				}`}
				onClick={(event) => event.stopPropagation()}>
				{imagePath ? (
					<img src={imagePath} alt='' width='15' height='15' />
				) : null}
				{remove ? null : (
					<header>
						{header ? (
							<>{typeof header === 'string' ? <h5>{header}</h5> : header}</>
						) : null}

						{headerCloseBtn ? (
							<div className='top-close'>
								<InteractiveButton
									icon={<CloseBtn />}
									title='Close'
									hoverable='none'
									onClick={onClose ?? (() => navigate(-1))}
								/>
							</div>
						) : null}
					</header>
				)}
				<div className='body body1'>{children}</div>
				{actionButtons !== false && (
					<>
						{actionButtons ? (
							<footer>{actionButtons}</footer>
						) : (
							<footer>
								<Button
									onClick={onClose ?? (() => navigate(-1))}
									buttonType='light'>
									<p>Cancel</p>
								</Button>
								<Button onClick={onAction}>
									<p>{action}</p>
								</Button>
							</footer>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default Modal;
