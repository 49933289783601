import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import Modal from '../../../Components/Modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import {
	createjoinTeamset,
	JoinNewTeam,
	joinTeamset,
} from '../../../Features/teams/TeamsSlice';
import { reset, setSnackModal, setSnack } from '../../../Features/message/messageSlice';
import { Navigate, useNavigate } from 'react-router-dom';
import { isHTMLTagsPresent } from '../../../utils/utils';
import { JoinNewTeamImg } from '../../../assets/Icons';
function JoinTeam() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [teamName, setTeamName] = useState('');
	const { joinTeamRes, teams, joinTeamLoader, joinTeamFail } = useSelector(
		(state: RootState) => state.teams
	);
	const [errMsg, setErrMsg] = useState<string>('');

	useEffect(() => {
		if (
			joinTeamRes.response === 'Sent invitation' &&
			joinTeamLoader === 'success'
		) {
			dispatch(setSnack({
				testcase: 'positive',
				message: `We have sent your request to join the team ${teamName} to its admins.
				We will notify you via an email when you are added to it.`

			}))
			dispatch(joinTeamset(false));
			teams.length === 0 && dispatch(createjoinTeamset(true));
		} else if (joinTeamLoader === 'failed') {
			if (joinTeamFail === 'EMAIL_COULD_NOT_BE_SENT') {

				dispatch(
					setSnack({
						message: 'Email could not be sent !!! Please try after sometime !!!',
						testcase: 'negative',
					})
				)

			} else {
				setErrMsg(joinTeamFail);
			}
		}
	}, [joinTeamRes, joinTeamLoader]);

	useEffect(() => {
		setErrMsg('');
		dispatch(reset());
	}, []);

	const handleTeamName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTeamName(e.target.value);
		setErrMsg('');
	};

	const buttons = () => {
		return (
			<div className='create-team-custom-btn'>
				<Button
					onClick={() => {
						dispatch(joinTeamset(false));
						dispatch(createjoinTeamset(true));
						navigate('/app/create-or-join-team')
					}}
					buttonType='light'>
					Back
				</Button>
				<Button
					disabled={!Boolean(teamName) || isHTMLTagsPresent(teamName)}
					loading={joinTeamLoader === 'loading'}
					onClick={() => {
						dispatch(JoinNewTeam({ tenantName: teamName }));
						dispatch(createjoinTeamset(false));
					}}>
					Send request
				</Button>
			</div>
		);
	};
	return (
		<div className='ff_Join_Team_container'>
			<JoinNewTeamImg width='440' height='440' />

			<div className='ff_Join_Team_text_container'>
				<div className='ff_Join_Team_text ff-join_parent'>
					<div><h4>Join a Team</h4></div>
					<div><p className="body1">Search for your team to send its Admins a request to join it.</p></div>
				</div>
				<Input
					value={teamName}
					label='Team name'
					onChange={handleTeamName}
					errorText={errMsg}
					error={Boolean(errMsg)}
					disAllowSpecialChar={true}
					autoFocus
				/>
				{buttons()}
			</div>

		</div>
	);
}

export default JoinTeam;
