import React from 'react';
import { Outlet } from 'react-router-dom';
import ChatBubble from '../../AIPreview/ChatBubble';
import ProductDisplay from '../ProductDisplay';
import Input from '../../../../../Components/Input';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import styled from 'styled-components';
import ProductDisplayEnhanced from '../ProductDisplayEnhanced';

const ChatFullScreen = () => {
	const {
		avatarImage,
		colorMode,
		colorScale,
		selectedColor,
		fontSize,
		fontFamily,
		selectedTheme
	} = useSelector((state: RootState) => state.experienceonweb);
	const thumbsUp = () => {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				data-name='thumb_up_black_24dp (1)'
				width='16'
				height='16'
				viewBox='0 0 16 16'>
				<path
					data-name='Path 15575'
					d='M0 0h16v16H0zm0 0h16v16H0z'
					fill='none'
				/>
				<path
					data-name='Path 15576'
					d='M6.333 14.333h6a1.324 1.324 0 0 0 1.227-.813l2.013-4.7a1.317 1.317 0 0 0 .093-.487V7a1.337 1.337 0 0 0-1.333-1.333h-4.206l.633-3.047.02-.213a1 1 0 0 0-.293-.707L9.78 1 5.387 5.393a1.325 1.325 0 0 0-.387.94V13a1.337 1.337 0 0 0 1.333 1.333zm0-8L9.227 3.44 8.333 7h6v1.333l-2 4.667h-6zM1 6.333h2.667v8H1z'
					transform='translate(-.333 -.333)'
					fill={selectedColor}
				/>
			</svg>
		);
	};

	const thumbsDown = () => {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='16'
				height='16'
				viewBox='0 0 16 16'>
				<path
					data-name='Path 15573'
					d='M0 0h16v16H0zm0 0h16v16H0z'
					fill='none'
				/>
				<path
					data-name='Path 15574'
					d='M10.333 3h-6a1.324 1.324 0 0 0-1.227.813l-2.013 4.7A1.317 1.317 0 0 0 1 9v1.333a1.337 1.337 0 0 0 1.333 1.333H6.54l-.633 3.047-.02.213a1 1 0 0 0 .293.707l.707.7 4.393-4.393a1.325 1.325 0 0 0 .387-.94V4.333A1.337 1.337 0 0 0 10.333 3zm0 8L7.44 13.893l.893-3.56h-6V9l2-4.667h6zM13 3h2.667v8H13z'
					transform='translate(-.333 -1)'
					fill={selectedColor}
				/>
			</svg>
		);
	};
	type PreviewWelcomeHeader = {
		selectedColor: string;
		mode: string;
		colorScale: any;
		fontFamily?: string;
	};
	const InputDiv = styled.div<PreviewWelcomeHeader>`
>.ff-input {
	
	>.MuiFormControl-root{
		input{
			font-family: ${(props) => props.fontFamily};
		}
	>.MuiInputBase-root{
		&:not(.Mui-disabled, .Mui-error).Mui-focused {
			.MuiOutlinedInput-notchedOutline {
			border:1.5px solid ${(props) => (props.mode === 'dark' ? props.selectedColor : props.colorScale[2])}} !important;
			}
		}
	}
	} 
   }
`
	return (
		<div className='ff-configure-experience-on-web' style={{ fontSize: fontSize.value }}>
			<div className='experience-on-web'>
				<div className='chats'>
					<ChatBubble
						message='Hey there! I am looking for something to liven up my home'
						by='user'
						showSelectedColor={true}
						widget='fullscreen'
					/>
					<ChatBubble
						message='Great! Here are few products that can help you to liven up your home while suiting your practical needs.'
						by='ai'
						forms
						showSelectedColor={true}
						widget='fullscreen'
					/>
					{selectedTheme.label == 'Default' ?
					<ProductDisplay />
					:
                    <ProductDisplayEnhanced theme={selectedTheme.label}/>
					}

					
					<div className='ff-timestamp'>
						<div className="time-feedback">
							<p className='timestamp' style={{ fontSize: ' 0.625em', fontFamily: fontFamily.value }}>10:00 AM</p>
						</div>
						<div style={{ display: "flex" }}>
							<div style={{ marginRight: '6px' }}>{thumbsUp()}</div>
							<div>{thumbsDown()}</div>
						</div>
					</div>

				</div>
			</div>
			<InputDiv className='ff-chatInput-web' selectedColor={selectedColor} mode={colorMode} colorScale={colorScale} fontFamily={fontFamily.value}>
				<Input placeholder='Type in a message' type='text' />
			</InputDiv>
		</div>
	);
};

export default ChatFullScreen;
