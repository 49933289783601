import DataFetchAPI from '../../utils/Api';
import { RESET } from './model';

const updatepassword = async (data: RESET) => {
	return await DataFetchAPI(false, false, '').post(
		'/authentication/signup/updatepassword',
		data
	);
};

const resetPasswordService = {
	updatepassword,
};

export default resetPasswordService;
