import { useState } from 'react';
import { CheckboxTick, CheckBoxUntick } from '../../assets/Icons';
import './Checkbox.scss';

type CheckboxProps = {
	isChecked: Array<boolean>;
	index: number;
	onValueChange: React.Dispatch<Array<boolean>>;
	label?: string | React.ReactNode;
} & React.ComponentProps<'input'>;

const Checkbox = ({
	label,
	isChecked,
	index,
	onValueChange,
	...rest
}: CheckboxProps) => {
	const [id] = useState(
		'checkbox_' + Math.random().toString(36).substring(2, 7) //NOSONAR
	);

	const handleChange = () => {
		onValueChange(isChecked.map((check, i) => (i === index ? !check : check)));
	};

	return (
		<div className='ff-checkbox'>
			<input
				type='checkbox'
				name={id}
				id={id}
				checked={isChecked[index]}
				onChange={handleChange}
				{...rest}
			/>
			<label htmlFor={id}>
				<div className={`check ${isChecked[index] ? 'checked' : ''}`}>
					{isChecked[index] ? (
						<CheckboxTick />
					) : (
						// <InteractiveButton icon={<CheckboxTick />} />
						<CheckBoxUntick />
						// <InteractiveButton icon={<CheckBoxUntick />} />
					)}
				</div>
				{label && <span className='label'>{label}</span>}
			</label>
		</div>
	);
};
export default Checkbox;
