import styled from 'styled-components';

export const Footer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 3px 21px 3px 20px;
	background-color: rgba(0, 0, 0, 0.3);
	color: var(--white);
	font-size: 12px;
`;
