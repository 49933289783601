import React, { useState, useEffect, useRef } from 'react';
import './multipleUpload.scss';
import {
	UploadDocIcon,
	UploadSuccessIcon,
	Removearrow,
	UploadFailure,
	CloseIconIngest,
} from '../../../assets/Icons';
import { UploadLoader } from '../../../assets/Icons/Upload';
import { Outlet, useNavigate } from 'react-router-dom';
import Button from '../../../Components/Button';
import { useAppDispatch } from '../../../app/store';
import {
	resetShopSyncAPIStatus,
	setFile,
	setSelectedremoveEcom,
	setSelectedremoveFile,
	setUploadedFiles,
	shopSync,
	setFileDetails,
	resetRemoveFileStatus,
} from '../../../Features/Ingest/IngestSlice';

import {
	setSnack,
	setSnackModal,
} from '../../../Features/message/messageSlice';
import { useSelector } from 'react-redux';
import {
	getFileList,
	uploadFile,
	setuploadFileApiStatus,
} from '../../../Features/Ingest/IngestSlice';
import { RootState } from '../../../app/store';
import ShopifyImg from '../../../assets/images/Platform/shopify.png';
import { loadState, saveState } from '../../../utils/utils';
import { setTestAndOpenBot } from '../../../Features/builder/builderSlice';
import { Tooltip } from '@mui/material';
import { setEcomType } from '../../../Features/SalesAI/salesSlice';
import { FFStoreIcon } from '../../../assets/Icons/FlyfishStore';
import woocomerce from '../../../assets/images/Platform/woocomerce.png';

const MAX_COUNT = 100;

export function UploadMultiple() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	// const [uploadedFiles, setUploadedFiles] = useState<any>([]);
	const [fileLimit, setFileLimit] = useState(false);
	const multiuploadRef = useRef(null);
	const [fileUploadStatus, setUploadStatus] = useState('');
	const [showremove, setRemove] = useState(false);
	const [activeIndex, setIndex] = useState(-1);
	const [ecomRemove, setecomRemove] = useState(false);
	const [ecomremoved, setecomremoved] = useState(false);
	const intervalIds = useRef<any>([]);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { removeFileApiStatus } = useSelector((state: RootState) => state.ingest);


	const {
		newUpload,
		uploadedFiles,
		uploadFileApiStatus,
		uploadFileMessage,
		shopSyncAPIStatus,
	} = useSelector((state: RootState) => state.ingest);
	const [close, setClose] = useState(false);
	const [showMail, setMailPop] = useState(false);

	const callAPI = () => {
		dispatch(
			getFileList({
				botId: loadState('botId'),
				tenantId: loadState('tenantId'),
			})
		);
	};

	useEffect(() => {
		dispatch(
			getFileList({
				botId: loadState('botId'),
				tenantId: loadState('tenantId'),
			})
		);
		let incompeleteUpload =
			uploadedFiles.fileUpload &&
			uploadedFiles.fileUpload.filter(
				(item: any) =>
					item.status === '1' || item.status === null || item.status === '0'
			);
	}, []);

	useEffect(() => {
		if (close) {
			saveState('Disable-Ingest-mail-popup', 'true');
		}
	}, [close]);

	const clearAllIntervals = () => {
		intervalIds.current.forEach(clearInterval);
		intervalIds.current = [];
	};
	useEffect(() => {
		if (
			(newUpload && newUpload.fileUpload && newUpload.fileUpload.length > 0) ||
			(newUpload.ecom && newUpload.ecom.length > 0)
		) {
			dispatch(
				getFileList({
					botId: loadState('botId'),
					tenantId: tenantId,
				})
			);
		}
	}, [newUpload]);

	useEffect(() => {
		if (uploadFileApiStatus == 'failed') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: uploadFileMessage,
					modalMode: 'negative',
				})
			);
			let removedFile = uploadedFiles.fileUpload.filter(function (e: any) {
				return typeof e.id !== 'undefined';
			});
			dispatch(
				setUploadedFiles({
					...uploadedFiles,
					fileUpload: removedFile,
				})
			);

			dispatch(setuploadFileApiStatus(''));
		}
	}, [uploadFileMessage]);
	useEffect(() => {
		let incompeleteUpload =
			uploadedFiles.fileUpload &&
			uploadedFiles.fileUpload.filter(
				(item: any) =>
					item.status === '1' || item.status === null || item.status === '0'
			);
		console.log('incompeleteUpload', incompeleteUpload);
		if (incompeleteUpload && incompeleteUpload.length > 0) {
			const interval = setInterval(() => {
				callAPI();
			}, 5000);

			intervalIds.current.push(interval);
		} else {
			if (intervalIds.current.length > 0) {
				clearAllIntervals();
			}
		}
		return () => {
			if (intervalIds.current.length > 0) {
				clearAllIntervals();
			}
		};
	}, [uploadedFiles]);
	useEffect(() => {
		if (
			(uploadedFiles &&
				uploadedFiles.fileUpload &&
				uploadedFiles.fileUpload.length > 0) ||
			(uploadedFiles.ecom && uploadedFiles.ecom.length > 0)
		) {
			let processFiles = uploadedFiles.fileUpload.filter(
				(e: any) => e.status === '1' || e.status === null
			);

			let processEcom = uploadedFiles.ecom.filter(
				(e: any) => e.status === '1' || e.status === null
			);
			if (processFiles.length > 0 || processEcom.length > 0) {
				setMailPop(true);
			} else {
				setMailPop(false);
			}
			let ecomType = uploadedFiles.ecom.filter(
				(e: any) => e.ecomType === 'shopify'
			)[0];
			ecomType && saveState('storeName', ecomType.store);
			let ecomTypeFF = uploadedFiles.ecom.filter(
				(e: any) => e.ecomType.toLowerCase() === 'FFStore'
			)[0];
			ecomTypeFF && saveState('storeName', ecomTypeFF.store);
			if (ecomType) {
				dispatch(setEcomType('shopify'));
				saveState('ecomType', 'shopify');
			}
			if (ecomTypeFF) {
				dispatch(setEcomType('FFStore'));
				saveState('ecomType', 'FFStore');
			}
		}

		dispatch(setTestAndOpenBot(false));
	}, [uploadedFiles]);

	useEffect(() => {
		if (removeFileApiStatus === 'success') {
			if (
				(uploadedFiles &&
					uploadedFiles.fileUpload &&
					uploadedFiles.fileUpload.length === 0) ||
				(uploadedFiles.ecom && uploadedFiles.ecom.length === 0)
			) {
				navigate('/app/Ingest');
			} else {
				navigate(-1);
			}
		}
	}, [removeFileApiStatus])

	// useEffect(() => {
	// 	if (
	// 		(uploadedFiles &&
	// 			uploadedFiles.fileUpload &&
	// 			uploadedFiles.fileUpload.length > 0) ||
	// 		(uploadedFiles.ecom && uploadedFiles.ecom.length > 0)
	// 	) {
	// 		let processFiles = uploadedFiles.fileUpload.filter(
	// 			(e: any) => e.status === '1' || e.status === null
	// 		);

	// 		let processEcom = uploadedFiles.ecom.filter(
	// 			(e: any) => e.status === '1' || e.status === null
	// 		);
	// 		if (processFiles.length > 0 || processEcom.length > 0) {
	// 			setMailPop(true);
	// 		} else {
	// 			setMailPop(false);
	// 		}
	// 		let ecomType = uploadedFiles.ecom.filter(
	// 			(e: any) => e.ecomType === 'shopify'
	// 		)[0];
	// 		ecomType && saveState('storeName', ecomType.store);
	// 		let ecomTypeFF = uploadedFiles.ecom.filter(
	// 			(e: any) => e.ecomType.toLowerCase() === 'FFStore'
	// 		)[0];
	// 		ecomTypeFF && saveState('storeName', ecomTypeFF.store);
	// 		if (ecomType) {
	// 			dispatch(setEcomType('shopify'));
	// 			saveState('ecomType', 'shopify');
	// 		}
	// 		if (ecomTypeFF) {
	// 			dispatch(setEcomType('FFStore'));
	// 			saveState('ecomType', 'FFStore');
	// 		}
	// 	} else {
	// 		if (
	// 			(uploadedFiles.fileUpload && uploadedFiles.fileUpload.length === 0) ||
	// 			(uploadedFiles.ecom && uploadedFiles.ecom.length === 0)
	// 		) {
	// 			setTimeout(() => {
	// 				if (intervalId) {
	// 					clearInterval(intervalId);
	// 				}
	// 				navigate('/app/Ingest');
	// 			}, 1300);
	// 			setMailPop(false);
	// 		}
	// 	}
	// 	let incompeleteUpload =
	// 		uploadedFiles.fileUpload &&
	// 		uploadedFiles.fileUpload.filter(
	// 			(item: any) =>
	// 				item.status === '1' || item.status === null || item.status === '0'
	// 		);
	// 	if (incompeleteUpload && incompeleteUpload.length > 0) {
	// 		dispatch(setTestAndOpenBot(false));
	// 		const interval = setInterval(() => {
	// 			callAPI();
	// 		}, 5000);

	// 		setIntervalId(interval);
	// 	} else {
	// 		if (intervalId) {
	// 			clearInterval(intervalId);
	// 		}
	// 	}

	// 	uploadedFiles.fileUpload &&
	// 		uploadedFiles.fileUpload.length === 0 &&
	// 		intervalId &&
	// 		clearInterval(intervalId);
	// 	return () => {
	// 		if (intervalId) {
	// 			clearInterval(intervalId);
	// 		}
	// 	};
	// }, [uploadedFiles]);

	useEffect(() => {
		if (shopSyncAPIStatus === 'success') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: 'Syncing complete',
				})
			);
			dispatch(resetShopSyncAPIStatus());
		} else if (shopSyncAPIStatus === 'failed') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage:
						'Unable to Sync at the Moment. Please try after sometime',
					modalMode: 'negative',
				})
			);
			dispatch(resetShopSyncAPIStatus());
		}
	}, [shopSyncAPIStatus]);

	const storeIcon = (ecomType: string) => {
		if (ecomType.toLowerCase() === 'ffstore') {
			return <FFStoreIcon />;
		} else if (ecomType.toLowerCase() === 'shopify') {
			return <img width={38} src={ShopifyImg} alt='' />;
		} else if (ecomType.toLowerCase() === 'woocommerce') {
			return <img width={38} src={woocomerce} alt='' />;
		}
		return <></>;
	};

	return (
		<>
			<div
				className='ff-ingest-email-pop'
				style={{
					display:
						loadState('Disable-Ingest-mail-popup') === 'true' || close
							? 'none'
							: showMail &&
								!close &&
								loadState('Disable-Ingest-mail-popup') !== 'true'
								? 'flex'
								: 'none',
				}}
			>
				<div>
					One or more of your sources are being ingested. We'll notify you on
					email once ingestion completes.{' '}
				</div>
				<div
					className='ff-email-close'
					onClick={() => {
						setClose(true);
					}}
				>
					<CloseIconIngest />
				</div>
			</div>
			<div className='ff-upload-parent'>
				<div className='ff-upload-multiple-files'>
					{uploadedFiles.ecom &&
						uploadedFiles.ecom.length > 0 &&
						uploadedFiles.ecom.map((item: any, i: number) => {
							return (
								<div
									key={item.store}
									className='pdf-file-content'
									onMouseOver={() => {
										setecomRemove(true);
									}}
									onMouseLeave={() => {
										setecomRemove(false);
									}}
									style={{ width: '555px', marginLeft: '55px' }}
								>
									<div
										style={{
											display: ecomremoved ? 'none' : 'flex',
											position: 'relative',
										}}
										className={`pdf-file ${item.status === '5' ? 'ff-file-error' : ''
											}`}
										onClick={() => {
											dispatch(setEcomType(item.ecomType));
											navigate(
												'/app/catalog/multichannel-storefront/sales-ai/catalog'
											);
										}}
									>
										<div className='ff-filename'>
											{storeIcon(item.ecomType)}

											<p
												className='button2 ff-filename-text'
												style={{ marginLeft: '11px' }}
											>
												{item.name}
											</p>
											<p className='body2'>
												{item.status === '1' || item.status === null
													? 'Ingesting...'
													: item.status === '2'
														? ''
														: item.status === '5'
															? 'Error Occured'
															: ''}
											</p>
										</div>

										{ecomRemove ? (
											<>
												<Removearrow />

												<div
													className='ff-delete-icon'
													style={{
														display: 'flex',
														alignItems: 'center',
														position: 'absolute',
													}}
												></div>
											</>
										) : (
											<>
												{item.status === '1' || item.status === null ? (
													<UploadLoader />
												) : item.status === '2' ? (
													<UploadSuccessIcon />
												) : item.status === '5' ? (
													<UploadFailure />
												) : null}
											</>
										)}
									</div>

									{loadState('shopifyShopName') ? (
										<Button
											buttonType='light'
											loading={shopSyncAPIStatus === 'loading'}
											onClick={() => {
												dispatch(
													shopSync({
														tenantId: tenantId!,
														botId: loadState('botId'),
													})
												);
											}}
										>
											Refresh
										</Button>
									) : (
										<Button
											buttonType='light'
											onClick={() => {
												if (intervalIds) {
													clearAllIntervals();
												}
												dispatch(setSelectedremoveEcom(item.name));
												navigate('remove-store');
											}}
										>
											Remove
										</Button>
									)}
								</div>
							);
						})}

					{uploadedFiles.fileUpload &&
						uploadedFiles.fileUpload.length > 0 &&
						uploadedFiles.fileUpload.map((file: any, index: number) => (
							<div
								onMouseOver={() => {
									setRemove(true);
									setIndex(index);
								}}
								key={file.id}
								onMouseLeave={() => {
									setRemove(false);
								}}
								className='pdf-file-content'
								style={{ width: '555px', marginLeft: '55px' }}
							>
								<div
									className={`pdf-file ${file.status === '5' ? 'ff-file-error' : ''
										}`}
									style={{ position: 'relative' }}
									onClick={() => {
										dispatch(setFile(file || file.URL));
										navigate('/app/Ingest-tab/pdf-preview');
										dispatch(
											setFileDetails({
												file,
											})
										);
									}}
								>
									<div className='ff-filename'>
										<UploadDocIcon />
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<Tooltip title={file.title || file.Name || file.name}>
												<p className='button1 ff-filename-text' style={{ fontWeight: '500', letterSpacing: '0.42px' }}>
													{file.title || file.Name || file.name}
												</p>
											</Tooltip>
											{file.status === '2' ?
												<div>
													<Tooltip title={file?.description ?? ''}>
														<p
															className='button2 ff-filename-text ff-description'
															style={{ color: 'var(--black-60)' }}
														>
															{file?.description ?? ''}
														</p>
													</Tooltip>
												</div> :
												null
											}

										</div>
									</div>

									<div>
										<p
											className='body2'
											style={{
												marginRight: '10px',
												whiteSpace: 'nowrap',
											}}
										>
											{file.status === '1' ||
												file.status === null ||
												file.status === '0'
												? 'Ingesting...'
												: file.status === '2'
													? ''
													: file.status === '5'
														? 'Error Occured'
														: ''}
										</p>

										{showremove && activeIndex === index ? (
											<>
												<Removearrow />

												<div
													className='ff-delete-icon'
													style={{
														display: 'flex',
														alignItems: 'center',
														position: 'absolute',
													}}
												></div>
											</>
										) : (
											<>
												{file.status === '1' || file.status === '0' ? (
													<UploadLoader />
												) : file.status === '2' ? (
													<UploadSuccessIcon />
												) : file.status === '5' ? (
													<UploadFailure />
												) : null}
											</>
										)}
									</div>
								</div>

								<Button
									buttonType='light'
									onClick={() => {
										if (intervalIds) {
											clearAllIntervals();
										}

										dispatch(setSelectedremoveFile(file));
										navigate('remove-file');
									}}
								>
									Remove
								</Button>
							</div>
						))}
				</div>
			</div>
			<Outlet />
		</>
	);
}
