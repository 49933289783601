import React, { useEffect, useState } from 'react';
import ProductPreview from '../ProductPreview';
import ShopifySales from '../../../assets/images/shopifysales.png';
import NoProduct from '../../../assets/images/noproductfound.png';
import './CatalogSales.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	Product,
	ProductsFF,
	SearchProduct,
} from '../../../Features/SalesAI/model';
import { debounce, loadState } from '../../../utils/utils';
import Cube from '../../../assets/images/products-placeholder/cube.png';
import Bangle from '../../../assets/images/products-placeholder/bangle.png';
import BuckyBall from '../../../assets/images/products-placeholder/bucky-ball.png';
import Cone from '../../../assets/images/products-placeholder/cone.png';
import ConePink from '../../../assets/images/products-placeholder/cone-pink.png';
import Icosahedron from '../../../assets/images/products-placeholder/icosahedron.png';
import Sphere from '../../../assets/images/products-placeholder/sphere.png';
import Octahedron from '../../../assets/images/products-placeholder/octahedron.png';
import StarPrism from '../../../assets/images/products-placeholder/star-prism.png';
import {
	getQueriedProducts,
	setSearchedProducts,
	setSearchedValue,
	setTotalProducts,
	setTotalSearchedProducts,
	setProducts,
	commerceLinkStatus,
	getProductsCS,
	getProductDetailsCS,
} from '../../../Features/SalesAI/salesSlice';
import { FFLoader } from '../../../assets/Icons/Loader';
import Button from '../.././../Components/Button';
import { FFStoreIcon } from '../../../assets/Icons/FlyfishStore';
import woocomerce from '../../../assets/images/Platform/woocomerce.png';
import Search from '../../../Components/Search';

import { IngestBackIcon } from '../../../assets/Icons';
import PageLoader from '../../../Components/PageLoader';
const CatalogSales = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const productImg = [
		Cube,
		Bangle,
		StarPrism,
		Cone,
		ConePink,
		BuckyBall,
		Sphere,
		Icosahedron,
		Octahedron,
	];
	const {
		products,
		productsLoader,
		salesAIproductLoader,
		commerceStatus,
		hasNextPage,
		searchedValue,
		searchingLoader,
		searchedProducts,
		totalProducts,
		totalSearchedProducts,
		ecomType,
		nextPageV,
	} = useSelector((state: RootState) => state.sales);
	const { testAndOpenBot } = useSelector((state: RootState) => state.builder);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const [Loader, setLoader] = useState(false);
	const [salesLoader, setSalesLoader] = useState(false);
	const [searchLoader, setSearchLoader] = useState(false);
	const [showProducts, setShowProducts] = useState(true);
	const storeName = loadState('storeName') ?? 'Your Store';

	const checkScroll = (e: React.UIEvent<HTMLElement>) => {
		let element = e.target as HTMLDivElement;
		const { scrollHeight, scrollTop, clientHeight } = element;
		if (Math.abs(scrollHeight - clientHeight - scrollTop) <= 3) {
			searchedProducts.length === 0 &&
				!productsLoader &&
				tenantId &&
				hasNextPage &&
				dispatch(
					getProductsCS({
						tenantId: tenantId,
						limit: 30,
						page: nextPageV,
					})
				);
		}
	};

	const moreProducts = () => {
		searchedProducts.length === 0 &&
			!productsLoader &&
			tenantId &&
			hasNextPage &&
			dispatch(
				getProductsCS({
					tenantId: tenantId,
					limit: 30,
					page: nextPageV,
				})
			);
	};

	useEffect(() => {
		if (salesAIproductLoader) {
			setSalesLoader(true);
		} else {
			setSalesLoader(false);
		}
	}, [salesAIproductLoader]);

	useEffect(() => {
		if (productsLoader) {
			setLoader(true);
		} else {
			setLoader(false);
		}
	}, [productsLoader]);

	useEffect(() => {
		if (searchingLoader) {
			setSearchLoader(true);
		} else {
			setSearchLoader(false);
		}
	}, [searchingLoader]);

	useEffect(() => {
		if (products && products.length > 0) {
			dispatch(setTotalProducts([...totalProducts, ...products]));
		}
	}, [products]);

	useEffect(() => {
		dispatch(commerceLinkStatus({ tenantId }));

		return () => {
			dispatch(setTotalProducts([]));
			dispatch(setProducts([]));
		};
	}, []);

	useEffect(() => {
		if (commerceStatus === 'linked' && totalProducts.length === 0) {
			tenantId &&
				dispatch(
					getProductsCS({
						tenantId: tenantId,
						limit: 30,
						page: '0',
					})
				);
		}
	}, [commerceStatus]);

	useEffect(() => {
		if (searchedProducts && searchedProducts.length > 0) {
			dispatch(setTotalSearchedProducts(searchedProducts));
		}
	}, [searchedProducts]);

	useEffect(() => {
		if (searchedValue.length === 0) {
			dispatch(setSearchedValue(''));
			dispatch(setSearchedProducts(''));
			dispatch(setTotalSearchedProducts(''));
			setShowProducts(true);
		} else if (searchedValue.length > 0) {
			debounce(
				() => dispatch(getQueriedProducts({ title: searchedValue })),
				600
			);
		}
	}, [searchedValue]);

	const showDescription = (productID: any) => {
		let id =
			typeof productID == 'number'
				? productID
				: parseInt(productID.split('/').pop());

		navigate('product-description', { state: { productID: id } });
	};

	return (
		<>
			<div className='ff-sales-ai-catalog'>
				{commerceStatus !== 'linked' ? (
					<div className='query-loader'>
						<PageLoader message='Loading...' />
					</div>
				) : (
					<div>
						<header className={`${testAndOpenBot ? 'open' : ''}`}>
							<div className='button-container'>
								<Button
									buttonType='light'
									onClick={() => {
										navigate(-1);
									}}
									startIcon={<IngestBackIcon width='20' height='20' />}>
									Back
								</Button>
							</div>
							<div className='header-center'>
								<div>
									{loadState('ecomType') === 'shopify' ? (
										<img src={ShopifySales} alt='' />
									) : loadState('ecomType') === 'FFStore' ? (
										<FFStoreIcon />
									) : loadState('ecomType') === 'woocommerce' ? (
										<img src={woocomerce} alt='' />
									) : null}

									<h5>{storeName}</h5>
								</div>
							</div>
							<div></div>
						</header>
						<div
							className={`products-holder ${
								totalProducts.length === 0 ? 'not-found' : ''
							} ${testAndOpenBot ? 'open' : ''}`}>
							<div className='products'>
								{salesLoader ? (
									<>
										<div key={'fetchproducts'} className='first'>
											Fetching Products...
										</div>
									</>
								) : showProducts ? (
									<>
										{totalSearchedProducts.length > 0
											? totalSearchedProducts.map(
													(product: SearchProduct | ProductsFF | any, i) => (
														<div key={i}>
															<ProductPreview
																productImg={
																	product.image && product.image.length > 0
																		? product.image
																		: productImg[i % productImg.length]
																}
																productTitle={product.title}
																key={product.id}
																onClick={() => {
																	showDescription(product.id);
																}}
															/>
														</div>
													)
											  )
											: totalProducts.map(
													(product: Product | ProductsFF | any, i) => (
														<>
															<div key={i}>
																<ProductPreview
																	productImg={
																		product.image && product.image.length > 0
																			? product.image
																			: productImg[i % productImg.length]
																	}
																	productTitle={product.title}
																	key={product.id}
																	onClick={() => {
																		showDescription(product.id);
																	}}
																/>
															</div>
															{totalProducts.length - 1 === i && hasNextPage ? (
																<div
																	onClick={moreProducts}
																	style={{ width: '190px', height: '190px' }}>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		width='100%'
																		height='100%'
																		viewBox='0 0 103 102'
																		style={{
																			cursor: 'pointer',
																		}}>
																		<g
																			id='Rectangle_17592'
																			data-name='Rectangle 17592'
																			fill='#fff'
																			stroke='#7b5ce5'
																			stroke-width='1'
																			stroke-dasharray='2'>
																			<rect
																				width='103'
																				height='102'
																				rx='5'
																				stroke='none'></rect>
																			<rect
																				x='0.5'
																				y='0.5'
																				width='102'
																				height='101'
																				rx='4.5'
																				fill='none'></rect>

																			<text
																				x='50%'
																				y='50%'
																				alignment-baseline='middle'
																				text-anchor='middle'
																				fill='#7b5ce5'
																				stroke='none'
																				font-weight='bold'
																				font-size='11'>
																				Load More
																			</text>
																		</g>
																	</svg>
																</div>
															) : null}
														</>
													)
											  )}
										{Loader ? (
											<div className={`later ${testAndOpenBot ? 'open' : ''}`}>
												<FFLoader fill='#3b44c4' />
												<p>Loading...</p>
											</div>
										) : null}
									</>
								) : (
									<div className={`not-found ${testAndOpenBot ? 'open' : ''}`}>
										<img src={NoProduct} alt='' />
										<p className='body1'>No products found</p>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default CatalogSales;
