import React from 'react'

interface Props {
    size?: number
}

function JobSheetSvg(props: Props): JSX.Element {
    const { size } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1042" height="100" viewBox="0 0 1042 100">
    <defs>
        <clipPath id="jvsu4n3t6a">
            <path data-name="Rectangle 6157" transform="translate(7066 -653)" style={{fill:"#fff"}} d="M0 0h1042v100H0z"/>
        </clipPath>
    </defs>
    <g data-name="Mask Group 248" transform="translate(-7066 653)" style={{clipPath:"url(#jvsu4n3t6a)"}}>
        <g data-name="Group 8339">
            <g data-name="Rectangle 6128" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                <path style={{stroke:"none"}} d="M0 0h1042v101H0z" transform="translate(7066 -653)"/>
                <path style={{fill:"none"}} d="M.5.5h1041v100H.5z" transform="translate(7066 -653)"/>
            </g>
            <g data-name="Group 8338">
                <g data-name="Group 8313">
                    <g data-name="Group 8306">
                        <g data-name="Rectangle 6129" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h236v25H0z" transform="translate(7871 -577)"/>
                            <path style={{fill:"none"}} d="M.5.5h235v24H.5z" transform="translate(7871 -577)"/>
                        </g>
                        <text data-name="source=%1C2897e98b-8e34-20f%7D" transform="translate(7879 -573)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">source=%1C2897e98b-8e34-20f%7D </tspan></text>
                    </g>
                    <g data-name="Group 8307">
                        <g data-name="Rectangle 6130" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7735 -577)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7735 -577)"/>
                        </g>
                        <text data-name="2-3 working days" transform="translate(7743 -573)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">2-3 working days</tspan></text>
                    </g>
                    <g data-name="Group 8308">
                        <g data-name="Rectangle 6131" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7599 -577)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7599 -577)"/>
                        </g>
                        <text data-name="bed covers" transform="translate(7607 -573)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">bed covers</tspan></text>
                    </g>
                    <g data-name="Group 8309">
                        <g data-name="Rectangle 6132" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7463 -577)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7463 -577)"/>
                        </g>
                        <text data-name="₹ 4,340" transform="translate(7471 -573)" style={{fontFamily:"Helvetica",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">₹</tspan><tspan y="13"  style={{fontFamily:"OpenSans,Open Sans"}}> 4,340</tspan></text>
                    </g>
                    <g data-name="Group 8310">
                        <g data-name="Rectangle 6133" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h156v25H0z" transform="translate(7307 -577)"/>
                            <path style={{fill:"none"}} d="M.5.5h155v24H.5z" transform="translate(7307 -577)"/>
                        </g>
                        <text transform="translate(7315 -573)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">Akshay</tspan></text>
                    </g>
                    <g data-name="Group 8311">
                        <g data-name="Rectangle 6134" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7171 -577)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7171 -577)"/>
                        </g>
                        <text data-name="917897897890" transform="translate(7179 -573)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">917897897890</tspan></text>
                    </g>
                    <g data-name="Group 8312">
                        <g data-name="Rectangle 6135" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h104v25H0z" transform="translate(7067 -577)"/>
                            <path style={{fill:"none"}} d="M.5.5h103v24H.5z" transform="translate(7067 -577)"/>
                        </g>
                        <text data-name="123" transform="translate(7075 -573)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">+123</tspan></text>
                    </g>
                </g>
                <g data-name="Group 8321">
                    <g data-name="Group 8314">
                        <g data-name="Rectangle 6136" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h236v25H0z" transform="translate(7871 -602)"/>
                            <path style={{fill:"none"}} d="M.5.5h235v24H.5z" transform="translate(7871 -602)"/>
                        </g>
                        <text data-name="source=%5F2876g54x-3a15-60h%7D" transform="translate(7879 -598)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">source=%5F2876g54x-3a15-60h%7D </tspan></text>
                    </g>
                    <g data-name="Group 8315">
                        <g data-name="Rectangle 6137" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7735 -602)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7735 -602)"/>
                        </g>
                        <text data-name="4-5 working days" transform="translate(7743 -598)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">4-5 working days</tspan></text>
                    </g>
                    <g data-name="Group 8316">
                        <g data-name="Rectangle 6138" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7599 -602)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7599 -602)"/>
                        </g>
                        <text data-name="dining set of 6" transform="translate(7607 -598)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">dining set of 6</tspan></text>
                    </g>
                    <g data-name="Group 8317">
                        <g data-name="Rectangle 6139" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7463 -602)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7463 -602)"/>
                        </g>
                        <text data-name="$ 34.99" transform="translate(7471 -598)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">$ 34.99</tspan></text>
                    </g>
                    <g data-name="Group 8318">
                        <g data-name="Rectangle 6140" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h156v25H0z" transform="translate(7307 -602)"/>
                            <path style={{fill:"none"}} d="M.5.5h155v24H.5z" transform="translate(7307 -602)"/>
                        </g>
                        <text transform="translate(7315 -598)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">Joe</tspan></text>
                    </g>
                    <g data-name="Group 8319">
                        <g data-name="Rectangle 6141" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7171 -602)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7171 -602)"/>
                        </g>
                        <text data-name="188888999911" transform="translate(7179 -598)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">188888999911</tspan></text>
                    </g>
                    <g data-name="Group 8320">
                        <g data-name="Rectangle 6142" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h104v25H0z" transform="translate(7067 -602)"/>
                            <path style={{fill:"none"}} d="M.5.5h103v24H.5z" transform="translate(7067 -602)"/>
                        </g>
                        <text data-name="1" transform="translate(7075 -598)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">+1</tspan></text>
                    </g>
                </g>
                <g data-name="Group 8329">
                    <g data-name="Group 8322">
                        <g data-name="Rectangle 6143" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h236v25H0z" transform="translate(7871 -627)"/>
                            <path style={{fill:"none"}} d="M.5.5h235v24H.5z" transform="translate(7871 -627)"/>
                        </g>
                        <text data-name="source=%7B2712c72a-4d25-40f%7D" transform="translate(7879 -623)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">source=%7B2712c72a-4d25-40f%7D </tspan></text>
                    </g>
                    <g data-name="Group 8323">
                        <g data-name="Rectangle 6144" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7735 -627)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7735 -627)"/>
                        </g>
                        <text data-name="2-3 working days" transform="translate(7743 -623)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">2-3 working days</tspan></text>
                    </g>
                    <g data-name="Group 8324">
                        <g data-name="Rectangle 6145" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7599 -627)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7599 -627)"/>
                        </g>
                        <text data-name="Hamlet (paperback)" transform="translate(7607 -623)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">Hamlet (paperback)</tspan></text>
                    </g>
                    <g data-name="Group 8325">
                        <g data-name="Rectangle 6146" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7463 -627)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7463 -627)"/>
                        </g>
                        <text data-name="₹ 120" transform="translate(7471 -623)" style={{fontFamily:"Helvetica",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">₹</tspan><tspan y="13"  style={{fontFamily:"OpenSans,Open Sans"}}> 120</tspan></text>
                    </g>
                    <g data-name="Group 8326">
                        <g data-name="Rectangle 6147" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h156v25H0z" transform="translate(7307 -627)"/>
                            <path style={{fill:"none"}} d="M.5.5h155v24H.5z" transform="translate(7307 -627)"/>
                        </g>
                        <text transform="translate(7315 -623)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">Rita</tspan></text>
                    </g>
                    <g data-name="Group 8327">
                        <g data-name="Rectangle 6148" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7171 -627)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7171 -627)"/>
                        </g>
                        <text data-name="9198765432110" transform="translate(7179 -623)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">9198765432110</tspan></text>
                    </g>
                    <g data-name="Group 8328">
                        <g data-name="Rectangle 6149" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h104v25H0z" transform="translate(7067 -627)"/>
                            <path style={{fill:"none"}} d="M.5.5h103v24H.5z" transform="translate(7067 -627)"/>
                        </g>
                        <text data-name="91" transform="translate(7075 -623)" style={{fontFamily:"OpenSans,Open Sans",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">+91</tspan></text>
                    </g>
                </g>
                <g data-name="Group 8337">
                    <g data-name="Group 8330">
                        <g data-name="Rectangle 6150" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h236v25H0z" transform="translate(7871 -652)"/>
                            <path style={{fill:"none"}} d="M.5.5h235v24H.5z" transform="translate(7871 -652)"/>
                        </g>
                        <text data-name="Button URL" transform="translate(7879 -648)" style={{fontFamily:"OpenSans-Bold,Open Sans",fontWeight:"700",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">Button URL</tspan></text>
                    </g>
                    <g data-name="Group 8331">
                        <g data-name="Rectangle 6151" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7735 -652)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7735 -652)"/>
                        </g>
                        <text data-name="Body variable {{3}}" transform="translate(7743 -648)" style={{fontFamily:"OpenSans-Bold,Open Sans",fontWeight:"700",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">Body variable {'{{3}}'}</tspan></text>
                    </g>
                    <g data-name="Group 8332">
                        <g data-name="Rectangle 6152" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7599 -652)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7599 -652)"/>
                        </g>
                        <text data-name="Body variable {{2}}" transform="translate(7607 -648)" style={{fontFamily:"OpenSans-Bold,Open Sans",fontWeight:"700",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">Body variable {'{{2}}'}</tspan></text>
                    </g>
                    <g data-name="Group 8333">
                        <g data-name="Rectangle 6153" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7463 -652)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7463 -652)"/>
                        </g>
                        <text data-name="Body variable {{1}}" transform="translate(7471 -648)" style={{fontFamily:"OpenSans-Bold,Open Sans",fontWeight:"700",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">Body variable {'{{1}}'}</tspan></text>
                    </g>
                    <g data-name="Group 8334">
                        <g data-name="Rectangle 6154" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h156v25H0z" transform="translate(7307 -652)"/>
                            <path style={{fill:"none"}} d="M.5.5h155v24H.5z" transform="translate(7307 -652)"/>
                        </g>
                        <text data-name="Header variable {{1}}" transform="translate(7315 -648)" style={{fontFamily:"OpenSans-Bold,Open Sans",fontWeight:"700",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">Header variable {'{{1}}'}</tspan></text>
                    </g>
                    <g data-name="Group 8335">
                        <g data-name="Rectangle 6155" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h136v25H0z" transform="translate(7171 -652)"/>
                            <path style={{fill:"none"}} d="M.5.5h135v24H.5z" transform="translate(7171 -652)"/>
                        </g>
                        <text data-name="Phone number" transform="translate(7179 -648)" style={{fontFamily:"OpenSans-Bold,Open Sans",fontWeight:"700",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">Phone number</tspan></text>
                    </g>
                    <g data-name="Group 8336">
                        <g data-name="Rectangle 6156" style={{stroke:"rgba(0,0,0,.2)",fill:"#fff"}}>
                            <path style={{stroke:"none"}} d="M0 0h104v25H0z" transform="translate(7067 -652)"/>
                            <path style={{fill:"none"}} d="M.5.5h103v24H.5z" transform="translate(7067 -652)"/>
                        </g>
                        <text data-name="Country code" transform="translate(7075 -648)" style={{fontFamily:"OpenSans-Bold,Open Sans",fontWeight:"700",fontSize:"12px",letterSpacing:".033em"}}><tspan x="0" y="13">Country code</tspan></text>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
   
    )
}

export default JobSheetSvg