import styled from 'styled-components';

export type GCBContainerProps = {
	fontFamily: string;
	fontSize: string;
	iconOutSide: boolean;
};

export const GCBContainer = styled.div<GCBContainerProps>`
	.gcb-icon-outside {
		display: flex;
		justify-content: end;
		margin-right: 21px;
	}
	font-family: ${(props) => props.fontFamily};
	font-size: ${(props) => props.fontSize};
	position: ${(props) => (props.iconOutSide ? 'relative' : '')};
	top: ${(props) => (props.iconOutSide ? '38px' : '')};
`;
