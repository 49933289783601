import './LabelContainer.scss';

interface ListObj {
	name: string;
	value: number;
}

interface Props {
	data: ListObj[];
}

function LabelContainer({ data }: Props) {
	const color = [
		'rgba(107, 126, 242, 0.4)',
		'#6b7ef2',
		'#3b4fd0',
		'#5470c6',
		'#91cc75',
		'#fac858',
		'#ee6666',
		'#73c0de',
		'#3ba272',
		'#fc8452',
		'#9a60b4',
		'#ea7ccc',
	];
	let valueArray = data.map((each) => each.value);
	let totalValue = valueArray.reduce((a, b) => a + b, 0);
	return (
		<div className='label-container'>
			<div className='legend-container'>
				{data.map((each, index) => (
					<div className='legend' key={index.toString()}>
						<div>
							<div
								className='legend-color'
								style={{ backgroundColor: `${color[index]}` }}></div>
						</div>
						<div className='caption'>
							<p className='name'>{each.name}</p>
							<span>{each.value}</span>{' '}
							{each.value !== 0 && (
								<span>({((each.value / totalValue) * 100).toFixed(1)}%)</span>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default LabelContainer;
