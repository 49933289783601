import { Link, useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useEffect, useState } from 'react';
import { isContext } from 'vm';
import { removeState } from '../../../utils/utils';
import flyfishlogo from './../../../assets/images/logo.png';

type CatalogHeaderProps = {
	path: string;
	type: string;
};

const CatalogHeader = ({ path, type }: CatalogHeaderProps) => {
	const navigate = useNavigate();
	const [typeDisplay, setTypeDisplay] = useState('Ad');
	const [position, setPosition] = useState(1);

	useEffect(() => {
		if (type === 'multichannel-storefront') setTypeDisplay('Storefront');
		else if (type === 'intelligent-ad') setTypeDisplay('Ad');
		else if (type === 'faq') setTypeDisplay('FAQ');
		else navigate('app/catalog');
	}, [type]);

	useEffect(() => {
		let pathArr = path.split('/');
		if (pathArr.length <= 3) {
			setPosition(1);
		} else {
			if (path.indexOf('faq/design') > -1) {
				setPosition(2);
			} else if (path.indexOf('faq/launch') > -1) {
				setPosition(4);
			} else if (path.indexOf('launch') > -1) {
				setPosition(3);
			} else if (path.indexOf('faq/configuration') > -1) {
				setPosition(3);
			} else if (path.indexOf('design') > -1) {
				setPosition(2);
			} else if (path.indexOf('sales-ai') > -1) {
				setPosition(4);
			} else {
				setPosition(1);
			}
		}
	}, [path]);

	const routeClass = () => {
		if (path.indexOf('/faq/design') > -1) {
			return 'faq-design';
		} else if (path.indexOf('design/build') > -1) {
			return 'design';
		} else if (path.indexOf('faq/launch') > -1) {
			return 'faq-launch';
		} else if (path.indexOf('launch') > -1) {
			return 'launch';
		} else if (path.indexOf('faq/configuration') > -1) {
			return 'faq-configuration';
		} else {
			return '';
		}
	};

	return (
		<>
			<div className='header-back-button'>
				{/* <Link
					to='app/my-studio'
					className='caption'
					onClick={() => removeState('channel')}>
					<ChevronLeftIcon />
					Back to My Studio
				</Link> */}
				<div className='logo-container'>
					<img src={flyfishlogo} alt='flyfishLogo' />
				</div>
			</div>
			<div
				className={`header-launch-ad-breadcrumbs ${routeClass()} ${
					position === 1 ? 'gallery' : ''
				}`}>
				<ul>
					{position === 1 ? (
						<>
							<li>
								<Link
									to={`app/catalog/${type}`}
									className={`body2${' active'} ${routeClass()}`}>
									{typeDisplay} Gallery
								</Link>
							</li>
						</>
					) : (
						''
					)}
					{typeDisplay === 'FAQ' || typeDisplay === 'Storefront' ? (
						<h6 className='my-after-ss-heading'>
							{/* {typeDisplay === 'FAQ' ? 'After Sales' : 'Sales AI'} */}
						</h6>
					) : (
						<>
							<li>
								<Link
									to={`app/catalog/${type}/design/build`}
									className={`body2${position === 2 ? ' active' : ''}${
										position <= 1 ? ' disabled' : ''
									}`}
									onClick={(event) => {
										if (position <= 1) {
											event.preventDefault();
										}
									}}>
									Design the {typeDisplay}
								</Link>
							</li>

							<li>
								<Link
									to={`app/catalog/${type}/launch`}
									className={`body2${position === 3 ? ' active' : ''}${
										position <= 1 ? ' disabled' : ''
									}`}
									onClick={(event) => {
										if (position <= 1) {
											event.preventDefault();
										}
									}}>
									Launch the {typeDisplay}
								</Link>
							</li>
						</>
					)}
				</ul>
			</div>
		</>
	);
};
export default CatalogHeader;
