import {
    Container,
    Value,
    Label,
    Appp
} from "./StyledComponents";
import React, { useEffect, useState } from "react";
import ThumbsUp from '../../../assets/images/thumbsup.png';
import ThumbsDown from '../../../assets/images/thumbsdown.png'
type Props = {
    data: any;
    type?: any;
    total?: any;
};
function AnalyticsBar({ data, type, total }: Props) {
    //const [hidden, setHidden] = useState(true);
    const [selected, setSelected] = useState(false);

    // const onClickDiv = (e) => {
    //     console.log("element clicked", e);
    // };

    const percentage = (partialValue: any) => {
        return (100 * partialValue) / total;
    }

    return (
        <Appp>
            <div className="ratingBar">
                {data && data.map((e: any, index: number) => (
                    <div style={{ display: "flex", height: "27px" }}>
                        <>
                            {
                                type === "feedback" ?
                                    <Label>{e.name.toLowerCase() === "negativefeedback" ? <img style={{
                                        width: "15px",
                                        height: "20px"
                                    }} src={ThumbsDown} alt='' /> : <img style={{
                                        width: "15px",
                                        height: "20px"
                                    }} src={ThumbsUp} alt='' />}</Label>
                                    :
                                    type === "ratings" ?
                                        <Label>{e.name} ⭐ </Label>
                                        :
                                        <Label>{e.name}</Label>
                            }
                        </>

                        <div className="Bar" style={{ width: "155px" }}>
                            <Container style={{ width: "145px" }} color={"#5531d3"}>
                                <progress value={e.percentage} max={100} />
                            </Container>
                        </div>
                        <Value> {e.value}</Value>
                    </div>
                ))}
            </div>
        </Appp>
    )
}
export default AnalyticsBar