import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../app/store';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import {
	set,
	setSnack,
	setSnackModal,
} from '../../../../../Features/message/messageSlice';
import {
	resetUnlinkZohoLoader,
	unlinkZohoSetup,
} from '../../../../../Features/Zoho/zohoSlice';
import { loadState } from '../../../../../utils/utils';
import {
	crmUnlink,
	crmsLinked,
	resetZohoData,
} from '../../../../../Features/teamsetting/teamsettingSlice';

const UnlinkZoho = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { zohoData, crmUnlinkLoader } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	let popupdescription = 'Unlink your team from this Zoho account?';
	const [zohobuttonLoader, setzohoButtonLoader] = useState(false);

	useEffect(() => {
		if (crmUnlinkLoader === 'success') {
			setzohoButtonLoader(false);

			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: 'Zoho account unlinked',
				})
			);

			dispatch(crmsLinked({ tenantId: tenantId! }));

			dispatch(resetZohoData());
			navigate(-1);
		} else if (crmUnlinkLoader === 'failed') {
			setzohoButtonLoader(false);
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage:
						'Unable to unlink account at the moment. Please try after sometime!!!',
					modalMode: 'negative',
				})
			);
		} else if (crmUnlinkLoader === 'loading') {
			setzohoButtonLoader(true);
		}
		dispatch(resetUnlinkZohoLoader());
	}, [crmUnlinkLoader]);

	return (
		<Modal
			header={popupdescription}
			actionButtons={
				<>
					<Button
						buttonType='light'
						onClick={() => {
							navigate(-1);
						}}>
						Cancel
					</Button>
					<Button
						loading={zohobuttonLoader}
						onClick={() => {
							tenantId &&
								dispatch(
									crmUnlink({
										tenantId: tenantId,
										linkedCrmId: zohoData.linkCrmId,
									})
								);
						}}>
						Unlink
					</Button>
				</>
			}>
			<div className='modal-body-popup'>
				You will no longer be able to send data to this Zoho account. Any
				actions in your AI that currently send data to this account will be
				affected.
			</div>
		</Modal>
	);
};
export default UnlinkZoho;
