import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Modal from '../../../Components/Modal';
import modalHeaderBg from '../../../assets/images/CatalogModal/bg_netflix.png';
import modalChatSS from '../../../assets/images/CatalogModal/netflix_ss_bg.png';
import modalChatSS1 from '../../../assets/images/CatalogModal/netflix_ss1.png';
import modalChatSS2 from '../../../assets/images/CatalogModal/netflix_ss2.png';
import modalChatSS3 from '../../../assets/images/CatalogModal/netflix_ss3.png';
import { Carousel } from 'react-responsive-carousel';
import { useState, useEffect } from 'react';
import Button from '../../../Components/Button';
import './CatalogModal.scss';

import {
	CloseIcon,
	LeftArrowIcon,
	MoreIcon,
	RightArrowIcon,
} from '../../../assets/images/CatalogModal';
import { Tooltip } from '@mui/material';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	createBotFromScratch,
	init,
	validateConfig,
} from '../../../Features/builder/builderSlice';
import { useSelector } from 'react-redux';
import { generateBotName, saveState } from '../../../utils/utils';
import { setActiveBot } from '../../../Features/Bots/BotSlice';

function CatalogModal() {
	const navigate = useNavigate();
	const { type } = useParams();
	const location = useLocation();
	const path = location.pathname;
	const [clickedImage, setClickImage] = useState(false);
	const [imageIndex, setImageIndex] = useState(0);
	const state = location.state as { value: any };
	const dispatch = useAppDispatch();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const carouselImages = [
		modalChatSS,
		modalChatSS1,
		modalChatSS2,
		modalChatSS3,
	];

	const { selectedTemplateBot } = useSelector((state: RootState) => state.bots);
	const { BotList } = useSelector((state: RootState) => state.bots);
	const { isBotCreating, isNameValid, botId, botAdded } = useSelector(
		(state: RootState) => state.builder
	);
	useEffect(() => {
		if (botId && botAdded) {
			let activeBot = BotList.filter((bot: any) => bot.botId === botId);
			if (activeBot.length > 0) {
				saveState('botDetails', activeBot[0]);
				saveState('botId', botId);
				dispatch(
					init({
						botId,
						tenantId,
						name: activeBot[0].botName,
					})
				);
				dispatch(setActiveBot(activeBot[0]));
				navigate(`/app/catalog/${type}/design/build`);
			}
		}
	}, [botId, botAdded, BotList]);

	useEffect(() => {
		if (!tenantId || !isNameValid) return;

		let name = selectedTemplateBot.templateDisplayName;

		if (!isNameValid) {
			name = generateBotName(name, BotList);
		}

		dispatch(
			createBotFromScratch({
				botId: 0,
				bot_name: name,
				clientBrand: 'CBRAND',
				clientCustomerCareEmail: 'demo@demo.com',
				clientCustomerCareNumber: '0000000000',
				clientNationality: 'India',
				isMultiLingualEnabled: false,
				skillsID: '',
				templateID: selectedTemplateBot.templateId,
				tenantId: tenantId,
				workflows: '',
				botDesc: name,
				bot_displayname: name,
				bot_icon: '',
				botType: selectedTemplateBot.templateType, // added it as CA (temp)
			})
		);
	}, [isNameValid]);

	return (
		<>
			<div className='catalog-modal'>
				<Modal
					actionButtons={
						<>
							<Button onClick={() => navigate(-1)} buttonType='light'>
								Cancel
							</Button>
							<Button
								disabled={true}
								onClick={() => {
									dispatch(
										validateConfig({
											fieldName: 'BOT_NAME',
											fieldValue: selectedTemplateBot.templateDisplayName,
											tenantId: tenantId!,
										})
									);
								}}>
								Use this{' '}
								{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}
							</Button>
						</>
					}
					header={<></>}
					className='ff-convo-ad-modal'>
					<div className='ff-convo-ad-modal-body'>
						<div className='no-image-container'></div>
						{modalHeaderBg && (
							<img
								src={modalHeaderBg}
								className='ff-modal-header-bg-image'
								alt='bg-header'
							/>
						)}
						<h5 className='modal-heading'>{state.value.templateDisplayName}</h5>
						<div className='header-overlay'></div>
						<div className='text-container body2'>
							<p>
								Body- Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
								sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
								aliquyam
							</p>
							<p>
								Body- Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
								sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
								aliquyam
							</p>
							<p>
								Body- Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
								sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
								aliquyam
							</p>
							<p>
								Body- Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
								sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
								aliquyam
							</p>
							<p>
								Body- Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
								sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
								aliquyam
							</p>
						</div>
						{modalChatSS && (
							<img
								src={modalChatSS}
								className='left-image'
								onClick={() => {
									setClickImage(true);
									setImageIndex(0);
								}}
								alt='moda-chat-ss'
							/>
						)}
						<div className='right-image-container'>
							{modalChatSS1 && (
								<img
									src={modalChatSS1}
									className='modal-chat-ss'
									alt='chat-screen-shot'
									title='chat-image2'
									onClick={() => {
										setClickImage(true);
										setImageIndex(1);
									}}
								/>
							)}
							{modalChatSS2 && (
								<img
									src={modalChatSS2}
									className='modal-chat-ss'
									alt='chat-screen-shot'
									title='chat-image1'
									onClick={() => {
										setClickImage(true);
										setImageIndex(2);
									}}
								/>
							)}
							{modalChatSS3 && (
								<img
									src={modalChatSS3}
									className='modal-chat-ss'
									alt='chat-screen-shot'
									title='chat-image3'
									onClick={() => {
										setClickImage(true);
										setImageIndex(3);
									}}
								/>
							)}
						</div>
					</div>
				</Modal>
			</div>
			{clickedImage && (
				<Modal
					actionButtons={<></>}
					onClose={() => setClickImage(false)}
					className='modal-2'>
					<Tooltip title='Close'>
						<div className='close-icon' onClick={() => setClickImage(false)}>
							<CloseIcon />
						</div>
					</Tooltip>
					<div className='carousel-container'>
						<Carousel
							dynamicHeight={true}
							swipeable={false}
							className='carousel'
							animationHandler='fade'
							showThumbs={false}
							showIndicators={false}
							showStatus={false}
							renderArrowNext={(clickHandler, hasPrev, labelPrev) => (
								<div onClick={clickHandler} className='right-arrow'>
									{hasPrev ? (
										<Tooltip title='Next'>
											<div className='right-arrow-on-hover'>
												<RightArrowIcon />
											</div>
										</Tooltip>
									) : (
										<div className='disabled-arrow'>
											<RightArrowIcon color='rgba(255,255,255,.6)' />
										</div>
									)}
								</div>
							)}
							selectedItem={imageIndex}
							renderArrowPrev={(clickHandler, hasPrev) => (
								<div onClick={clickHandler} className='left-arrow'>
									{hasPrev ? (
										<Tooltip title='Prev'>
											<div className='left-arrow-on-hover'>
												<LeftArrowIcon />
											</div>
										</Tooltip>
									) : (
										<div className='disabled-arrow'>
											<LeftArrowIcon color='rgba(255,255,255,.6)' />
										</div>
									)}
								</div>
							)}>
							{carouselImages &&
								carouselImages.map((eachImage, index) => {
									return (
										<div key={index}>
											{eachImage && (
												<img
													src={eachImage}
													className='carousel-image'
													alt='modalchat'
													title='carousel-images'
												/>
											)}
										</div>
									);
								})}
						</Carousel>
					</div>
				</Modal>
			)}
		</>
	);
}

export default CatalogModal;
