export function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M12.59 5.375v12.704m-6.352-6.352h12.705"
        stroke="#4B50F6"
        strokeWidth="1.466"
      />
    </svg>
  );
}

export function CopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64 21.6a2.4 2.4 0 0 0 2.4 2.4h13.199c1.017 0 1.865-.78 1.95-1.794l.05-.606v-16a2.4 2.4 0 0 0-2.4-2.4h-.8v-.8a2.4 2.4 0 0 0-2.4-2.4H4.84a2.4 2.4 0 0 0-2.4 2.4v16a2.4 2.4 0 0 0 2.4 2.4h.8v.8zm0-2.4h-.8a.8.8 0 0 1-.8-.8v-16a.8.8 0 0 1 .8-.8h12.8a.8.8 0 0 1 .8.8v.8H8.04a2.4 2.4 0 0 0-2.4 2.4v13.6z"
        fill="#4B50F6"
      />
    </svg>
  );
}

export function OpenLinkArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 29 20"
      fill="none"
    >
      <path
        d="M27 2h-7.333M27 2v7.333M27 2 15 14m9.333-3.333V18H11V4.667h8.667"
        stroke="#fff"
        strokeWidth="1.433"
      />
    </svg>
  );
}

export function EditIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
      <path d="M11.063.146a.5.5 0 0 0-.708 0L.21 10.293V14.5a.5.5 0 0 0 .5.5h4.207L15.062 4.854a.5.5 0 0 0 0-.708l-4-4z" fill="#4D77FF"/>
    </svg>
  )
}

export function CrossIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="m1 1 16 16M1 17 17 1" stroke="#1D1929" stroke-width="1.889"/>
    </svg>
  )
}
