import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import Button from '../../../../Components/Button';
import Dropdown, {
	DropdownValue,
	DropdownValueProps,
} from '../../../../Components/Dropdown';
import { BotBuilderPlus } from '../../../../assets/Icons';
import {
	actionsOptions,
	commonActionParams,
	getIconForNextAction,
} from '../common';
import '../ActionsModal.scss';
import './MultipleChoice.scss';
import Input from '../../../../Components/Input';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	addMultipleChoiceAction,
	getMultipleChoiceAction,
	resetActionDetails,
} from '../../../../Features/builder/builderSlice';
import {
	AddConnectionToOptions,
	Card,
	CardList,
	CardOptionObject,
	CardOptions,
	DefaultConnection,
	GetAction,
} from '../../../../Features/builder/model';
import { useSelector } from 'react-redux';
import builderService from '../../../../Features/builder/builderService';
import NextNewAction from '../NextNewAction';
import SnackBar from '../../../../Components/SnackBar';
import {
	checkImageURL,
	checkVideoURL,
	getActionName,
} from '../../../../utils/utils';
import { format } from 'date-fns';
import Radio from '../../../../Components/Radio';
import Modal from '../../../../Components/Modal';
import { CloseBtn } from '../../../../Components/Modal/icons';
import InteractiveButton from '../../../../Components/Button/InteractiveButton';
import { DeleteIcon, EditIcon } from '../../../../assets/Icons/Bot';
import { set } from '../../../../Features/message/messageSlice';
import { DropdownProps } from '../../../Analytics/model';

const MultipleChoice = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const context: any = useOutletContext();
	const state: any = location.state;
	const taskAction: GetAction = state.action;
	const [label, setLabel] = useState('Next Action');
	const { actionDetails, botId, tenantId, activeQuery, actions } = useSelector(
		(state: RootState) => state.builder
	);
	const nextActions = actions
		.filter(
			(action) =>
				action.task_ID !== taskAction.taskId &&
				action.taskHierarchyDTO[0] !== taskAction.taskId &&
				!action.taskroot
		)
		.map((action) => ({
			value: action.task_ID.toString(),
			label: (
				<div className={`actions-label action${action.userResponseTypeID}`}>
					{getIconForNextAction(action.userResponseTypeID)}
					<p>{action.task_name}</p>
				</div>
			),
			labelValue: action.task_name,
		})) as DropdownValueProps[];

	const nextActionsWithoutNewAction = actions
		.filter(
			(action) =>
				action.task_ID !== taskAction.taskId &&
				action.taskHierarchyDTO[0] !== taskAction.taskId &&
				!action.taskroot
		)
		.map((action) => ({
			value: action.task_ID.toString(),
			label: (
				<div className={`actions-label action${action.userResponseTypeID}`}>
					{getIconForNextAction(action.userResponseTypeID)}
					<p>{action.task_name}</p>
				</div>
			),
			labelValue: action.task_name,
		})) as DropdownValueProps[];
	// nextActionsWithoutNewAction.push({
	// 	value: 'add new action',
	// 	label: (
	// 		<div className='actions-label new-action'>
	// 			{getIconForNextAction(-1)}
	// 			<p>New Action</p>
	// 		</div>
	// 	),
	// 	labelValue: 'New Action',
	// });
	nextActions.push({
		value: 'add new action',
		label: (
			<div className='actions-label new-action'>
				{getIconForNextAction(-1)}
				<p>New Action</p>
			</div>
		),
	});

	const [openNextActionType, setOpenNextActionType] = useState(false);
	const [nextActionTypeId, setNextActionTypeId] = useState(0);
	const [nextSpecificActions, setNextSpecificActions] = useState<
		Array<DropdownValue>
	>([]);
	const [option_specific, setoptionspecific] = useState<DropdownValue>();
	const [options, setOptions] = useState(['Option 1']);
	const [optionsCard, setOptionsCard] = useState<CardOptions[]>([]);
	const [templateName, setTemplateName] = useState('');
	const [paramName, setParamName] = useState('');
	const [displayStyle, setDisplayStyle] = useState('text');
	const [openImageModal, setOpenImageModal] = useState(false);
	const [currentCardOption, setCurrentCardOption] = useState<
		CardOptions | undefined
	>(undefined);
	const [edit, setEdit] = useState(false);
	const [editIndex, setEditIndex] = useState<number | null>(0);
	const [imageOrVideo, setImageOrVideo] = useState<string>('');
	const [titleNameError, setTitleNameError] = useState<string | undefined>(
		undefined
	);

	const [imageLinkError, setImageLinkError] = useState<string | undefined>(
		undefined
	);
	const [videoLinkError, setVideoLinkError] = useState<string | undefined>(
		undefined
	);
	const [youtubeVimeoError, setYoutubeVimeoError] = useState(false);
	const [nextSpecificActionState, setNextSpecificActionState] =
		useState<DropdownValue>();
	const [dropdownName, setDropDownName] = useState('Default Next Action');
	useEffect(() => {
		botId &&
			tenantId &&
			dispatch(
				getMultipleChoiceAction({
					botId,
					tenantId,
					taskId: taskAction.taskId,
					workflowId: taskAction.workflowId,
				})
			);

		return () => {
			dispatch(resetActionDetails());
		};
	}, []);
	useEffect(() => {
		const d = new Date();
		setParamName(
			actionDetails?.responseParamName ??
			`#${actionDetails?.taskName.replace(' ', '_')}${format(
				d,
				'Y-MM-dd_H:mm:ss'
			)}`
		);
	}, [context.selectedAction]);
	// actionDetails &&
	// 	actionDetails?.cardOptionObject?.lists?.items.map((each) => {
	// 		console.log({
	// 			title: each.title,
	// 			url: each.resourceURL,
	// 			description: each.description,
	// 		});
	// 	});
	useEffect(() => {
		if (actionDetails?.templateName !== actionDetails?.taskName)
			setTemplateName(actionDetails?.templateName ?? '');
		const d = new Date();
		if (
			actionDetails?.responseParamName.length === 0 ||
			actionDetails?.responseParamName === '#'
		) {
			setParamName(
				`#${actionDetails?.taskName.replace(' ', '_')}${format(
					d,
					'Y-MM-dd_H:mm:ss'
				)}`
			);
		} else setParamName(actionDetails?.responseParamName ?? '#');

		if (actionDetails && actionDetails.addConnectionToOptions?.length > 0) {
			setOptions(
				actionDetails.addConnectionToOptions.map((connection) => {
					return connection.optionText;
				})
			);

			setNextSpecificActions(
				actionDetails?.addConnectionToOptions.map((connection) => {
					return connection.childtaskID !== 0
						? {
							value: connection.childtaskID.toString(),
							label: connection.childTaskName,
						}
						: null;
				})
			);
		} else {
			setOptions(['Option 1']);
			displayStyle === 'text' &&
				setNextSpecificActions(new Array(options.length).fill(null));
		}

		if (actionDetails && actionDetails.cardOptionObject?.lists) {
			if (actionDetails.cardOptionObject?.lists?.items?.length > 0) {
				setDisplayStyle('card');
				let cards: CardOptions[] = [];
				cards = actionDetails.cardOptionObject?.lists?.items?.map((card) => ({
					title: card.title,
					url: card.resourceURL,
					description: card.description,
					resourceType: card.resourceType,
				})) as CardOptions[];
				setOptionsCard(cards ?? []);

				setNextSpecificActions(
					actionDetails.cardOptionObject?.lists?.items?.map((connection) => {
						return connection.childtaskID !== 0
							? {
								value: connection.childtaskID
									? connection.childtaskID.toString()
									: '',
								label: connection.childTaskName,
							}
							: null;
					})
				);
			}
		} else {
			displayStyle === 'card' &&
				setNextSpecificActions(new Array(optionsCard.length).fill(null));
		}

		const next = actionDetails?.defaultConnection;
		if (next && actions) {
			let action = actions.filter(
				(action) => action.task_ID === next.childtaskId
			)[0];

			if (action) {
				console.log('childtaskid', next.childtaskId);
				let next_action: DropdownValue = {
					value: next.childtaskId.toString(),
					label: (
						<div className={`actions-label action${action.userResponseTypeID}`}>
							{getIconForNextAction(action.userResponseTypeID)}
							<p>{action.task_name}</p>
						</div>
					),
					labelValue: next.childTaskName ?? 'Untitled',
				};
				context.setNextAction(next_action);
			}
		}
	}, [actionDetails]);

	useEffect(() => {
		const next = context.nextAction as DropdownValueProps;
		// const nextspecific=context.
		if (next && next.value === 'add new action' && !openNextActionType) {
			setOpenNextActionType(true);
		}
	}, [context.nextAction]);

	useEffect(() => {
		const next = nextSpecificActionState as DropdownValueProps;
		let next_action: DropdownValue = {
			value: next && next.value && next.value.toString(),

			label: (
				<div className={`actions-label action${nextActionTypeId}`}>
					{getIconForNextAction(nextActionTypeId)}

					<p>{label}</p>
				</div>
			),

			labelValue: label ?? 'Untitled',
		};

		let option_next_action: DropdownValueProps = {
			value: next && next.value && next.value.toString(),

			label: (
				<div className={`actions-label action${nextActionTypeId}`}>
					{getIconForNextAction(nextActionTypeId)}

					<p>{label}</p>
				</div>
			),

			labelValue: label ?? 'Untitled',
		};

		// console.log(option_next_action);
		if (dropdownName === 'Default Next Action') {
			console.log('default');
			context.setNextAction(next_action);
			//	nextSpecificActions.push(option_next_action);
			console.log('default option have', context.nextAction);

			console.log('next specific option has', nextSpecificActions);
		} else {
			option_next_action = {
				value: next && next.value && next.value.toString(),

				label: (
					<div className={`actions-label action${nextActionTypeId}`}>
						{getIconForNextAction(nextActionTypeId)}

						<p>{label}</p>
					</div>
				),

				labelValue: label ?? 'Untitled',
			};
			setoptionspecific(option_next_action);
			console.log('option specific');
			nextSpecificActions.pop();
			nextSpecificActions.push(option_next_action);
			console.log('default option have', context.nextAction);
			console.log('next specific option has', nextSpecificActions);
			// setNextSpecificActions(option_next_action);
		}
	}, [label]);

	useEffect(() => {
		if (currentCardOption?.url) {
			if (currentCardOption.url.length > 0) {
				currentCardOption.url.indexOf('jpeg') > -1 ||
					currentCardOption.url.indexOf('png') > -1 ||
					currentCardOption.url.indexOf('jpg') > -1 ||
					currentCardOption.url.indexOf('webp') > -1 ||
					currentCardOption.url.indexOf('gif') > -1 ||
					currentCardOption.url.indexOf('svg') > -1 ||
					currentCardOption.url.indexOf('bmp') > -1 ||
					currentCardOption.url.indexOf('apng') > -1 ||
					currentCardOption.url.indexOf('avif') > -1 ||
					currentCardOption.url.indexOf('drive.google.com') > -1
					? setImageOrVideo('image')
					: setImageOrVideo('video');
			}
		}
	}, [edit]);
	const generateName = (prefix: string, names: string[]) => {
		let count: number[] = names
			.map((name) => (name.includes(prefix) ? name : null))
			.map((name) => {
				let number = Number(name?.replace(prefix, ''));

				if (!isNaN(number)) return number;
				return 0;
			})
			.filter((num) => typeof num === 'number');

		if (count.length === 0) {
			count.push(0);
		}

		return `${prefix} ${Math.max(...count) + 1}`;
	};

	const handleAddOption = () => {
		if (displayStyle === 'text') {
			const name = generateName('Option', options);
			setOptions((prev) => [...prev, name]);
			setNextSpecificActions((prev) => [...prev, null]);
		} else {
			setImageOrVideo('');
			setTitleNameError(undefined);
			setImageLinkError(undefined);
			setOpenImageModal(true);
		}
	};
	const handleAddImage = () => {
		setImageLinkError(undefined);
		setYoutubeVimeoError(false);
		if (currentCardOption?.url) {
			if (imageOrVideo === 'image') {
				if (currentCardOption.url.split('/')[0] === 'http:') {
					setImageLinkError(
						'HTTP links are not supported. Please enter an HTTPS link'
					);
				} else if (!checkImageURL(currentCardOption.url)) {
					setImageLinkError('Please enter a link that points to an image');
				} else {
					if (Boolean(currentCardOption)) {
						setOpenImageModal(false);
						edit
							? setOptionsCard((optionsCard: any) =>
								optionsCard.map((eachValue: any, i: number) =>
									i === editIndex ? currentCardOption : eachValue
								)
							)
							: setOptionsCard((prev: any) => [...prev, currentCardOption]);
						setNextSpecificActions((prev) => [...prev, null]);
					}

					setCurrentCardOption(undefined);
				}
			} else if (imageOrVideo === 'video') {
				if (!checkVideoURL(currentCardOption.url)) {
					if (currentCardOption.url.indexOf('youtube') > -1) {
						if (!(currentCardOption.url.indexOf('embed') > -1)) {
							setVideoLinkError(undefined);
							setYoutubeVimeoError(true);
						}
					} else if (currentCardOption.url.split('/')[0] === 'http:') {
						setVideoLinkError(
							'HTTP links are not supported. Please enter an HTTPS link'
						);
					} else {
						setVideoLinkError('Please enter a link that points to a video');
					}
					//
				} else if (currentCardOption.url.split('/')[0] === 'http:') {
					setVideoLinkError(
						'HTTP links are not supported. Please enter an HTTPS link'
					);
				} else {
					if (currentCardOption.url.indexOf('youtube') > -1) {
						if (!(currentCardOption.url.indexOf('embed') > -1)) {
							setVideoLinkError(undefined);
							setYoutubeVimeoError(true);
						} else {
							setOpenImageModal(false);
							checkCard();
							if (Boolean(currentCardOption)) {
								edit
									? setOptionsCard((optionsCard: any) =>
										optionsCard.map((eachValue: any, i: number) =>
											i === editIndex ? currentCardOption : eachValue
										)
									)
									: setOptionsCard((prev: any) => [...prev, currentCardOption]);
								setNextSpecificActions((prev) => [...prev, null]);
							}

							setCurrentCardOption(undefined);
						}
					} else {
						setOpenImageModal(false);
						checkCard();
						if (Boolean(currentCardOption)) {
							edit
								? setOptionsCard((optionsCard: any) =>
									optionsCard.map((eachValue: any, i: number) =>
										i === editIndex ? currentCardOption : eachValue
									)
								)
								: setOptionsCard((prev: any) => [...prev, currentCardOption]);
							setNextSpecificActions((prev) => [...prev, null]);
						}

						setCurrentCardOption(undefined);
					}
				}
			}
		}
	};

	const handleOptionRemoved = (index: number) => {
		setOptions((prev) => prev.filter((option, i) => i !== index));
	};
	const handleRemoveCardOptions = (index: number) => {
		setOptionsCard((prev) => prev.filter((option, i) => i !== index));
		setNextSpecificActions((prev) => prev.filter((option, i) => i !== index));
	};

	const handleOptionChange = (value: string, index: number) => {
		setOptions((prev) =>
			prev.map((text, i) => {
				if (index !== i) {
					return text;
				} else {
					return value.length > 0 ? value : '';
				}
			})
		);
	};

	const checkCard = () => {
		return (
			currentCardOption &&
			currentCardOption?.title &&
			currentCardOption?.url &&
			!Boolean(
				optionsCard.filter((each) => each.title === currentCardOption?.title)
					.length
			) &&
			(edit
				? !Boolean(
					optionsCard.filter(
						(each, index) =>
							each.title === currentCardOption?.title && index !== editIndex
					).length
				)
				: true) &&
			Boolean(currentCardOption?.title.length <= 80) &&
			(currentCardOption.description?.length
				? Boolean(currentCardOption.description.length <= 300)
				: true)
		);
	};

	const checkEditCard = () => {
		return (
			currentCardOption &&
			currentCardOption?.title &&
			currentCardOption?.url &&
			!Boolean(
				optionsCard.filter(
					(each, index) =>
						each.title === currentCardOption?.title && index !== editIndex
				).length
			) &&
			Boolean(currentCardOption?.title.length <= 80) &&
			(currentCardOption.description?.length
				? Boolean(currentCardOption.description.length <= 300)
				: true)
		);
	};
	const findMediaType = (type: string, url: string) => {
		if (
			type === 'video' ||
			type === 'youtube' ||
			type === 'vimeo' ||
			type === 'video/mp4' ||
			type === 'image' ||
			type === 'image/png' ||
			type === 'image/jpeg' ||
			type === 'image/jpg' ||
			type === 'image/webp' ||
			type === 'image/gif' ||
			type === 'image/svg' ||
			type === 'image/bmp' ||
			type === 'image/apng' ||
			type === 'image/avif'
		) {
			if (url.indexOf('mp4') > -1) {
				return 'video/mp4';
			} else if (url.indexOf('embed') > -1) {
				return 'youtube';
			} else if (url.indexOf('vimeo') > -1) {
				return 'vimeo';
			} else if (url.indexOf('jpeg') > -1) {
				return 'image/jpeg';
			} else if (url.indexOf('jpg') > -1) {
				return 'image/jpg';
			} else if (url.indexOf('png') > -1) {
				return 'image/png';
			} else if (url.indexOf('webp') > -1) {
				return 'image/webp';
			} else if (url.indexOf('gif') > -1) {
				return 'image/gif';
			} else if (url.indexOf('svg') > -1) {
				return 'image/svg';
			} else if (url.indexOf('bmp')) {
				return 'image/bmp';
			} else if (url.indexOf('apng')) {
				return 'image/apng';
			} else if (url.indexOf('avif')) {
				return 'image/avif';
			} else {
				return '';
			}
		} else {
			return '';
		}
	};
	const handleSave = async () => {
		if (actionDetails) {
			if (
				actions.filter(
					(action) =>
						action.task_name === context.actionName &&
						action.task_ID !== actionDetails.taskId
				).length > 0
			) {
				dispatch(set('Action name already exists'));
				// toast(<SnackBar type='danger'>Action name already exists</SnackBar>, {
				// 	autoClose: 3000,
				// });
				return false;
			}
			console.log('in save', context.nextAction);
			let next = context.nextAction as DropdownValueProps;
			console.log('in save next value', next);
			let defaultConnection: DefaultConnection = {
				connectionId: 0,
				childtaskId: 0,
				conditionType: '',
				childTaskName: null,
			};

			if (nextActionTypeId !== 0 && botId && tenantId) {
				//call add action
				let position = actionDetails?.taskPosition.split('d');
				let task_position = '3000d4000';
				if (position) {
					task_position = `${parseInt(position[0]) + 110}d${position[1]}`;
				}
				const common = commonActionParams(actionDetails?.workflowId, false);
				const new_task = await builderService.addAction({
					...common,
					previousTaskId: 0,
					botId,
					tenantId,
					userResponseTypeID: nextActionTypeId,
					task_position,
					task_name: getActionName(actions, nextActionTypeId),
				});

				if (new_task.status === 201) {
					defaultConnection.childtaskId = Number(
						new_task.data.data.task.task_ID
					);
					defaultConnection.childTaskName = 'Untitled';
				}
			} else {
				if (next) {
					defaultConnection.childtaskId = Number(next.value);
					defaultConnection.childTaskName = next.labelValue ?? '';
				}
			}

			let connectionToOptions = nextSpecificActions as DropdownValueProps[];
			let addConnectionToOptions: AddConnectionToOptions[] = [];
			let cardOptionObject: CardOptionObject = {};
			let multiOptionTypeId = 1;
			if (displayStyle == 'text') {
				addConnectionToOptions = connectionToOptions.map((action, index) => {
					if (action) {
						return {
							childTaskName: action.labelValue ?? '',
							childtaskID: Number(action.value),
							isconnected: true,
							multiLingual: {},
							optionText: options[index],
						};
					} else {
						return {
							childTaskName: '',
							childtaskID: 0,
							isconnected: false,
							multiLingual: {},
							optionText: options[index],
						};
					}
				});
				addConnectionToOptions = addConnectionToOptions.filter(
					(connection) =>
						connection.optionText && connection.optionText.length !== 0
				);
			} else {
				// connectionToOptions = connectionToOptions.filter((connection) => {
				// 	return connection != null;
				// });
				addConnectionToOptions = connectionToOptions.map((action, index) => {
					if (action) {
						return {
							childTaskName: action.labelValue ?? '',
							childtaskID:
								connectionToOptions[index] !== null &&
									connectionToOptions[index].value
									? Number(connectionToOptions[index].value)
									: 0,
							//Number(action.value)
							isconnected: true,
							multiLingual: {},
							optionText: optionsCard[index].title ?? '',
						};
					} else {
						return {
							childTaskName: '',
							childtaskID: 0,
							isconnected: false,
							multiLingual: {},
							optionText: '',
						};
					}
				});
				addConnectionToOptions = addConnectionToOptions.filter(
					(connection) =>
						connection.optionText && connection.optionText.length !== 0
				);

				let cards: Card[] = optionsCard.map((option: any, index: number) => {
					return {
						title: option.title,
						description: option.description ? option.description.trim() : '',
						externalURL: '',
						text: '',
						value: '',
						collapsed: false,
						cardId: index,
						index: index,
						resourceURL: option.url,
						cardLayout: 6,
						multiLingual: {},
						resourceType: findMediaType(option.resourceType, option.url),
						childtaskID:
							connectionToOptions[index] !== null &&
								connectionToOptions[index].value
								? Number(connectionToOptions[index].value)
								: 0,
						childTaskName:
							connectionToOptions[index] !== null &&
								connectionToOptions[index].labelValue?.length !== 0
								? connectionToOptions[index].labelValue ?? ''
								: '',
					} as Card;
				});
				let lists: CardList = {
					title: 'Card 0',
					items: cards,
					elementId: 0,
				};

				cardOptionObject = { responseType: 'CAROUSEL', lists };
				multiOptionTypeId = 2;
			}
			activeQuery &&
				dispatch(
					addMultipleChoiceAction({
						...actionDetails,
						currentNodeId: activeQuery.nodeId,
						cardOptionObject,
						templateName: templateName,
						templateType: 'New Template Type',
						defaultConnection,
						taskName: context.actionName,
						responseParamName: paramName,
						addConnectionToOptions,
						userResponseType: 'Multiple Choice',
						userResponseTypeId: 5,
						multiOptionTypeId,
						taskDescription: '',
					})
				);
		}

		navigate(-1);
	};

	const validateTitle = () => {
		if (!Boolean(currentCardOption?.title?.length)) {
			setTitleNameError('Required');
		} else {
			let duplicateOptionCards = [];
			if (edit) {
				duplicateOptionCards = optionsCard.filter(
					(each, index) =>
						each.title === currentCardOption?.title && index !== editIndex
				);
			} else {
				duplicateOptionCards = optionsCard.filter(
					(each, index) => each.title === currentCardOption?.title
				);
			}

			if (Boolean(duplicateOptionCards.length)) {
				setTitleNameError('Please make sure that all card titles are unique');
			} else {
				setTitleNameError(undefined);
			}
		}
	};

	const validateImageURL = () => {
		setImageLinkError(undefined);
		if (!Boolean(currentCardOption?.url?.length)) {
			setImageLinkError('Required');
		} else {
			if (currentCardOption?.url) {
				if (!checkImageURL(currentCardOption?.url)) {
					if (currentCardOption?.url.split('/')[0] === 'http:') {
						setImageLinkError(
							'HTTP links are not supported. Please enter an HTTPS link'
						);
					} else {
						setImageLinkError('Please enter a link that points to an image');
					}
				}
			} else {
				setImageLinkError(undefined);
			}
		}
	};
	const changeDropdown = (event: any) => {
		console.log(event);
		context.setNextAction(event);
		setDropDownName('Default Next Action');
	};
	const validateVideoURL = () => {
		setYoutubeVimeoError(false);
		if (!Boolean(currentCardOption?.url?.length)) {
			setVideoLinkError('Required');
		} else {
			if (currentCardOption?.url) {
				if (!checkVideoURL(currentCardOption.url)) {
					if (currentCardOption.url.indexOf('youtube') > -1) {
						if (!(currentCardOption.url.indexOf('embed') > -1)) {
							setVideoLinkError(undefined);
							setYoutubeVimeoError(true);
						}
					}
					// setVideoLinkError('Please enter a link that points to a video');
				}
			} else {
				if (currentCardOption?.url) {
					if (currentCardOption.url.indexOf('youtube') > -1) {
						if (!(currentCardOption.url.indexOf('embed') > -1)) {
							setYoutubeVimeoError(true);
						}
					}
				}
				setVideoLinkError(undefined);

				setYoutubeVimeoError(false);
			}
		}
	};

	return (
		<>
			<div className='body-content'>
				<div className='action-content'>
					<div className='left-pane'>
						<Input
							fullWidth
							multiline
							placeholder='Enter message'
							value={templateName}
							onChange={(event) => setTemplateName(event.target.value)}
							className='text-input'
						/>

						<div className='options'>
							{displayStyle === 'text'
								? options.map((option, index) => (
									<div className='chip-input'>
										<Input
											fullWidth
											value={option}
											onChange={(event) =>
												handleOptionChange(event.target.value, index)
											}
											error={Boolean(option.length === 0)}
											placeholder='Option'
										/>
										<InteractiveButton
											title='Delete'
											icon={<DeleteIcon opacity='0.6' />}
											disabled={Boolean(options.length === 1)}
											onClick={() => handleOptionRemoved(index)}
										/>
									</div>
								))
								: optionsCard.map((option: any, index: number) => (
									<div className='card-and-edit-container'>
										<div className='card'>
											{option.url.indexOf('drive.google.com') > -1 ? (
												<img
													src={`https://drive.google.com/uc?export=view&id=${option.url.split('d/')[1].split('/view')[0]
														}`}
													alt=''
												/>
											) : option.url.indexOf('jpeg') > -1 ||
												option.url.indexOf('jpg') > -1 ||
												option.url.indexOf('png') > -1 ||
												option.url.indexOf('webp') > -1 ||
												option.url.indexOf('gif') > -1 ||
												option.url.indexOf('svg') > -1 ||
												option.url.indexOf('bmp') > -1 ||
												option.url.indexOf('apng') > -1 ||
												option.url.indexOf('.avif') > -1 ? (
												option.url.indexOf('.gif') > -1 ? (
													<img
														src={option.url}
														width='300px'
														height='100%'
														alt=''
													/>
												) : (
													<img src={option.url} alt='' />
												)
											) : option.url.indexOf('youtube') > -1 ||
												option.url.indexOf('vimeo') > -1 ? (
												<iframe src={option.url} title={option.url}></iframe>
											) : option.url.indexOf('mp4') > -1 ? (
												<>
													<video width='400' controls>
														<source src={option.url} type='video/mp4' />
													</video>
												</>
											) : option.url.indexOf('drive.google.com') > -1 ? (
												<>
													<iframe
														title={`${option.url}`}
														src={`${option.url.replace(
															'/view',
															'/preview'
														)}`}></iframe>
												</>
											) : null}
											<div className='card-details'>
												<p className='card-title body1'>{option.title}</p>
												<p className='card-description caption'>
													{option.description}
												</p>
											</div>
										</div>
										<div>
											<InteractiveButton
												title='Edit'
												icon={<EditIcon opacity='0.6' />}
												onClick={() => {
													setOpenImageModal(true);
													setCurrentCardOption(option);
													setTitleNameError(undefined);
													setImageLinkError(undefined);
													setVideoLinkError(undefined);
													setYoutubeVimeoError(false);
													setEdit(true);
													setEditIndex(index);
												}}
											/>
											<InteractiveButton
												title='Delete'
												icon={<DeleteIcon opacity='0.6' />}
												disabled={Boolean(optionsCard.length === 1)}
												onClick={() => handleRemoveCardOptions(index)}
											/>
										</div>
									</div>
								))}
						</div>

						<div className='button-container'>
							<Button
								startIcon={<BotBuilderPlus width='15px' />}
								onClick={() => {
									handleAddOption();
									setTitleNameError(undefined);
									setImageLinkError(undefined);
									setVideoLinkError(undefined);
									setYoutubeVimeoError(false);
									setEdit(false);
								}}>
								Add Option
							</Button>
						</div>
					</div>
					<div className='right-pane'>
						<div className='item'>
							<p className='label'>Display style</p>
							<div className='radio'>
								<Radio
									value='text'
									isChecked={displayStyle}
									onValueChange={setDisplayStyle}
									label='Text Options'
									name='displayStyle'
								/>
								<Radio
									value='card'
									isChecked={displayStyle}
									onValueChange={setDisplayStyle}
									label='Card Options'
									name='displayStyle'
								/>
							</div>
						</div>

						<div className='item'>
							<p className='label'>Saving User Input</p>
							<Input
								fullWidth
								value={paramName}
								onChange={(event) => setParamName(event.target.value)}
								placeholder='Variable Name'
							/>
						</div>

						<div className='item'>
							<p className='label'>Default Next Action</p>
							<Dropdown
								isSearchable
								placeholder='Select Next Action'
								value={context.nextAction}
								options={nextActions}
								// onChange={()=>{
								// 	context.setNextAction
								// }
								onChange={(ev) => {
									changeDropdown(ev);
								}}
								className='next-action-dropdown'
							/>
						</div>

						<div className='item'>
							<p className='label'>Option-specific Next Actions</p>
							{displayStyle === 'text' &&
								options.map((option, index) => {
									// @ts-ignore
									let conn: DropdownValueProps = option;
									return (
										<Dropdown
											isSearchable
											label={option}
											placeholder='Select Next Action'
											value={nextSpecificActions[index]}
											options={nextActionsWithoutNewAction}
											onChange={(value) => {
												setNextSpecificActions((prev) =>
													prev.map((con, i) => (index === i ? value : con))
												);
												setDropDownName('Next Action');
												setNextSpecificActionState(nextSpecificActions[index]);
												setOpenNextActionType(
													(value as DropdownValueProps).value ===
														'add new action'
														? true
														: false
												);
											}}
											className='next-action-dropdown'
											key={index}
										/>
									);
								})}
							{displayStyle === 'card' &&
								optionsCard.map((option: any, index) => {
									// @ts-ignore
									let conn: DropdownValueProps = option;
									return (
										<Dropdown
											isSearchable
											label={
												Boolean(option.title.length > 75)
													? option.title.slice(0, 70) + '...'
													: option.title
											}
											placeholder='Select Next Action'
											value={nextSpecificActions[index]}
											options={nextActionsWithoutNewAction}
											onChange={(value) => {
												setNextSpecificActions((prev) =>
													prev.map((con, i) => (index === i ? value : con))
												);
											}}
											className='next-action-dropdown'
											key={index}
										/>
									);
								})}
						</div>
					</div>
				</div>

				<footer>
					<Button
						disabled={
							(context.actionName.trim().length > 0 &&
								(templateName.trim().length > 0 ? false : true)) ||
							(options &&
								Boolean(
									options.filter((each) => each.trim().length === 0).length
								))
						}
						onClick={handleSave}>
						Save
					</Button>
				</footer>
			</div>

			<NextNewAction
				open={openNextActionType}
				setOpenNextActionType={setOpenNextActionType}
				setNextActionTypeId={setNextActionTypeId}
				setLabel={setLabel}
				onClose={() => {
					setOpenNextActionType(false);
					if (nextActionTypeId === 0) {
						context.setNextAction(null);
					}
				}}
			/>

			{openImageModal && (
				<Modal
					header={`${edit ? 'Edit Card' + ' ' : 'Add a Card Option' + ' '}`}
					action='Done'
					onClose={() => {
						setOpenImageModal(false);
						setEdit(false);
						setCurrentCardOption(undefined);
					}}
					className='add-card-modal'
					actionButtons={
						<>
							<Button
								onClick={() => {
									setOpenImageModal(false);
									setEdit(false);
									setCurrentCardOption(undefined);
								}}
								buttonType='light'>
								Cancel
							</Button>
							<Button
								disabled={edit ? !checkEditCard() : !checkCard()}
								onClick={handleAddImage}>
								Done
							</Button>
						</>
					}>
					<Input
						fullWidth
						multiline
						limit={80}
						placeholder=''
						label='Card Title'
						className='card-title'
						value={currentCardOption && currentCardOption.title}
						onChange={(event) =>
							setCurrentCardOption((prev) => ({
								...prev,
								title: event.target.value,
							}))
						}
						helperText={titleNameError}
						onFocus={() => {
							setTitleNameError(undefined);
						}}
						onBlur={validateTitle}
						error={Boolean(titleNameError)}
					/>

					<Input
						fullWidth
						multiline
						placeholder=''
						limit={300}
						label='Description'
						value={currentCardOption && (currentCardOption.description ?? '')}
						onChange={(event) =>
							setCurrentCardOption((prev) => ({
								...prev,
								description: event.target.value,
							}))
						}
						helperText='Optional'
					/>
					<div>
						<p className='body1'>Media shown</p>
						<div className='radio'>
							<Radio
								value='image'
								isChecked={imageOrVideo}
								onValueChange={() => {
									// setCurrentCardOption(undefined);
									setImageOrVideo('image');
									setCurrentCardOption((prev) => ({
										...prev,

										resourceType: 'image',
									}));
								}}
								label='Image'
								name='Image'
							/>
							{imageOrVideo === 'image' && (
								<Input
									className='media-input'
									fullWidth
									placeholder='E.g. https://example.com/example.jpeg'
									label='Image Link'
									value={currentCardOption && (currentCardOption.url ?? '')}
									onChange={(event) =>
										setCurrentCardOption((prev) => ({
											...prev,
											url: event.target.value,
										}))
									}
									helperText={imageLinkError}
									onFocus={() => {
										setImageLinkError(undefined);
									}}
									onBlur={validateImageURL}
									error={Boolean(imageLinkError)}
								/>
							)}
						</div>
						<div className='radio'>
							<Radio
								value='video'
								isChecked={imageOrVideo}
								onValueChange={() => {
									// setCurrentCardOption(undefined);
									setImageOrVideo('video');
									setCurrentCardOption((prev) => ({
										...prev,

										resourceType: 'video',
									}));
								}}
								label='Video'
								name='Video'
							/>
							{imageOrVideo === 'video' && (
								<>
									<Input
										fullWidth
										className='media-input'
										placeholder='E.g. https://example.com/example.mp4'
										label='Video Link'
										value={currentCardOption && (currentCardOption.url ?? '')}
										onChange={(event) => {
											setCurrentCardOption((prev) => ({
												...prev,
												url: event.target.value,
											}));
										}}
										helperText={videoLinkError}
										onFocus={() => {
											setVideoLinkError(undefined);
											setYoutubeVimeoError(false);
										}}
										onBlur={validateVideoURL}
										error={Boolean(videoLinkError)}
									/>
									{youtubeVimeoError && (
										<span
											className='caption media-input'
											style={{ color: 'var(--error-red)', marginLeft: '50px' }}>
											Please enter the embed code URL for{' '}
											<a
												href='https://support.google.com/youtube/answer/171780?hl=en'
												target='_blank'
												rel='noreferrer'
												style={{ textDecoration: 'underline' }}>
												YouTube
											</a>{' '}
											and{' '}
											<a
												href='https://vimeo.com/644036051'
												target='_blank'
												rel='noreferrer'
												style={{ textDecoration: 'underline' }}>
												Vimeo
											</a>{' '}
											videos
										</span>
									)}
								</>
							)}
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};
export default MultipleChoice;
