import styled from 'styled-components';
import {
	HeaderIconProps,
	HeaderImageProps,
	HeaderProps,
	HeaderTextProps,
} from './model';

export const Header = styled.div<HeaderProps>`
	position: ${(props) => (props.headerIconOutside ? 'initial' : 'relative')};
	padding: 26px 16px;
	background-color: ${(props) => props.headerColor};
	display: flex;
	align-items: center;
	justify-content: ${(props) =>
		props.headerIconOutside ? 'center' : 'space-between !important'};
	min-width: 306px;
	min-height: 72px;
	max-height: 72px;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 28%);
`;
export const HeaderText = styled.p<HeaderTextProps>`
	margin-right: ${(props) => (props.headerIconOutside ? '' : '77px')};
	color: ${(props) => props.color};
	font-size: inherit;
	font-family: inherit;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	min-height: 20px;
`;
export const HeaderIcon = styled.div<HeaderIconProps>`
	// display: ${(props) => (props.iconOutSide ? '' : 'flex')};
	display: flex;
	align-items: ${(props) => (props.iconOutSide ? '' : 'center')};
	position: ${(props) => (!props.iconOutSide ? '' : 'absolute')};
	top: ${(props) => (props.iconOutSide ? '82px' : '')};
	right: ${(props) => (props.iconOutSide ? '8px' : '')};
	cursor: not-allowed;
	& > span:first-child {
		margin-right: 8px;
		background-color: ${(props) =>
			props.iconOutSide ? 'rgba(60, 60, 60, 0.8)' : ''};
		border-radius: ${(props) => (props.iconOutSide ? '50%' : '')};
		padding: ${(props) => (props.iconOutSide ? '4px' : '')};
	}
	& > span:last-child {
		background-color: ${(props) =>
			props.iconOutSide ? 'rgba(60, 60, 60, 0.8)' : ''};
		border-radius: ${(props) => (props.iconOutSide ? '50%' : '')};
		padding: ${(props) => (props.iconOutSide ? '4px' : '')};
	}
`;
export const HeaderIconSpan = styled.span`
	color: ${(props) => props.color};
`;

export const HeaderImage = styled.img<HeaderImageProps>`
	max-height: 52px;
	max-width: 100%;
	min-height: 52px;
	min-width: 100%;
`;
