import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Modal from '../../../Components/Modal';
import { Channel } from '../../../Features/channel/modal';
import {
	linkInsta,
	linkWaba,
	wabaInsta,
} from '../../../Features/WhatsApp-BSP/auth/authSlice';
import { setActiveBot } from '../../../Features/Bots/BotSlice';
import { loadState } from '../../../utils/utils';

interface Props {
	channel: wabaInsta;
	setLinkAd: (arg: boolean) => void;
}

const LinkAd = ({ setLinkAd, channel }: Props) => {
	const location = useLocation();
	const path = location.pathname;
	const salesaiPath = path.indexOf('sales-ai') > -1;
	const faqPath = path.indexOf('faq') > -1;
	const { activeBot } = useSelector((state: RootState) => state.bots);
	const { afterSalesBot } = useSelector((state: RootState) => state.sales);
	const dispatch = useAppDispatch();
	const faqBot = loadState('botDetails');
	const handleLink = () => {
		if (channel?.wabaNo) {
			dispatch(
				linkWaba({
					wabaNo: channel?.wabaNo as string,
					botCategory: salesaiPath
						? 'VSR'
						: faqPath
							? 'CA'
							: activeBot.cavSRType,
					botEnv: 'prod',
					botId: faqPath ? faqBot.botId : (activeBot.botId as number) ?? 0,
					botName: faqPath ? faqBot.botName : activeBot?.botName ?? '',
					botIcon: faqPath ? faqBot.botIcon : activeBot?.botIcon ?? '',
					botCoverImage: faqPath
						? faqBot.coverImage
						: activeBot?.coverImage ?? '',
				})
			);
		} else {
			dispatch(
				linkInsta({
					pageId: channel?.pageId ?? '',
					botCategory: activeBot.cavSRType,
					botEnv: 'prod',
					botId: faqPath ? faqBot.botID : (activeBot.botId as number),
					botName: faqPath ? faqBot.botName : activeBot?.botName ?? '',
					botIcon: faqPath ? faqBot.botIcon : activeBot?.botIcon ?? '',
					botCoverImage: faqPath
						? faqBot.coverImage
						: activeBot?.coverImage ?? '',
				})
			);
		}

		setLinkAd(false);
	};
	return (
		<Modal
			header={`Link your ${path.indexOf('intelligent-ad') > -1
					? 'Intelligent Ad'
					: salesaiPath
						? 'Sales AI'
						: 'Storefront'
				} with ${channel?.wabaNo ? '+' + channel.wabaNo : channel?.pageName}?`}
			actionButtons={
				<>
					<Button buttonType='light' onClick={() => setLinkAd(false)}>
						Cancel
					</Button>
					<Button onClick={handleLink}>Link</Button>
				</>
			}
			onClose={() => setLinkAd(false)}>
			<div className='modal-body'>
				<p className='body2'>
					Please confirm, do you wish to link{' '}
					{salesaiPath
						? 'Sales AI'
						: faqPath
							? loadState('botDetails').botName
							: activeBot.botName}{' '}
					with the
					{channel?.wabaNo
						? ` WhatsApp number ${channel.wabaNo}?`
						: ` Instagram account ${channel.instaUsername}?`}{' '}
					This will let the{' '}
					{salesaiPath
						? 'Sales AI'
						: activeBot.cavSRType === 'CA'
							? 'Intelligent Ad'
							: 'Multichannel Storefronts'}{' '}
					respond to people whenever they message this{' '}
					{channel?.wabaNo ? 'number' : 'account'} on{' '}
					{channel?.wabaNo ? 'Whatsapp' : 'Instagram'}.
				</p>
			</div>
		</Modal>
	);
};

export default LinkAd;
