import React, { useEffect, useState } from 'react';
import './Salesforce.scss';
import Modal from '../../../../Components/Modal';
import Input from '../../../../Components/Input';
import Button from '../../../../Components/Button';
import { useNavigate } from 'react-router-dom';
import {
	crmsLinked,
	resetIntegrationSalesforceLoader,
	setup,
} from '../../../../Features/teamsetting/teamsettingSlice';
import { RootState, useAppDispatch } from '../../../../app/store';
import { useSelector } from 'react-redux';
import {
	setSnack,
	setSnackModal,
} from '../../../../Features/message/messageSlice';

const Salesforce = () => {
	const [buttonLoader, setButtonLoader] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [token, setToken] = useState('');
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { integrationSalesforceLoader } = useSelector(
		(state: RootState) => state.teamsetting
	);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (integrationSalesforceLoader === 'success') {
			setButtonLoader(false);
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: 'Salesforce account added successfully',
				})
			);
			dispatch(crmsLinked({ tenantId: tenantId! }));
			navigate(-1);
		} else if (integrationSalesforceLoader === 'failed') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: `Couldn't add !!! Something Went Wrong`,
					modalMode: 'negative',
				})
			);
			setButtonLoader(false);
		} else if (integrationSalesforceLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetIntegrationSalesforceLoader());
	}, [integrationSalesforceLoader]);

	const buttons = () => {
		return (
			<>
				<Button onClick={() => navigate(-1)} buttonType='light'>
					Cancel
				</Button>
				<Button
					loading={buttonLoader}
					onClick={() => {
						let data = {
							crmId: 2,
							config: {
								ACCESS_KEY: username,
								SECRET_KEY: password,
								TOKEN: token,
							},
							tenantId: tenantId,
						};
						dispatch(setup(data));
					}}>
					Add
				</Button>
			</>
		);
	};
	return (
		<div className='ff-salesforce-modal'>
			<Modal header='Link your team with Salesforce' actionButtons={buttons()}>
				<p className='body1 body-desc1'>
					Linking your Flyfish team with a Salesforce account lets you send
					leads captured by your AI to Salesforce.
				</p>
				<p className='body1 body-desc2'>
					Please share the following details to link your Salesforce account.
					You can find instructions on how to find your security token{' '}
					<a
						style={{ color: 'var(--violet-600)' }}
						rel='noreferrer'
						target='_blank'
						href='https://help.salesforce.com/s/articleView?id=sf.user_security_token.htm&type=5'>
						here
					</a>
					.
				</p>
				<div className='data-holder'>
					<Input
						fullWidth
						label='Salesforce username'
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
					<Input
						type='passsword'
						fullWidth
						label='Salesforce password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<Input
						type='passsword'
						fullWidth
						label='Salesforce security token'
						value={token}
						onChange={(e) => setToken(e.target.value)}
					/>
				</div>
			</Modal>
		</div>
	);
};

export default Salesforce;
