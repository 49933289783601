import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { GCBClose } from '../../../../assets/Icons';
import { loadState } from '../../../../utils/utils';
import { Body } from '../../../styles/Appearance/Body/body.styled';
import {
	ChatBotButton,
	ChatBotButtonDiv,
} from '../../../styles/Appearance/Body/Chatbubble/chatbubble.styled';
import { Footer } from '../../../styles/Appearance/Footer/footer.styled';
import { GCBContainer } from '../../../styles/Appearance/GCBContainer/gcbcontainer.styled';
import {
	Header,
	HeaderIcon,
	HeaderIconSpan,
	HeaderImage,
	HeaderText,
} from '../../../styles/Appearance/GCBContainer/Header/header.styled';
import { WelcomeBanner } from '../../../styles/Appearance/WelcomeBanner/welcomeBanner.styled';
import ChatBubble from './ChatBubble';
import { GCBCMinimise } from './Icons';
import './GenericChatBot.scss';

const GenericChatBot = () => {
	const {
		headerContentType,
		headerIconPosition,
		botBackground,
		welcomeBannerCheckbox,
		fontFamily,
		fontSize,
		headerColor,
		headerTextAndIconColor,
		buttonBckColor,
		buttonTextColor,
		buttonTextAndOutlineColor,
		backgroundSolidColor,
		backgroundGradientStartColor,
		backgroundGradientStopColor,
		headerFile,
		backgroundFile,
		welcomeBannerFile,
		botName,
		botTitle,
	} = useSelector((state: RootState) => state.configure);
	const botDetails = loadState('botDetails');

	const background = () => {
		if (botBackground === 'color') {
			return backgroundSolidColor;
		}
		if (botBackground === 'image') {
			return backgroundFile;
		}
		return ' ';
	};
	return (
		<div className='ff-generic-chat-bot'>
			{headerIconPosition === 'external' ? (
				<HeaderIcon iconOutSide={headerIconPosition === 'external'}>
					<HeaderIconSpan>
						<GCBCMinimise iconOutside={true} />
					</HeaderIconSpan>
					<HeaderIconSpan>
						<GCBClose iconOutside={true} />
					</HeaderIconSpan>
				</HeaderIcon>
			) : null}
			<GCBContainer
				fontFamily={fontFamily.value}
				fontSize={fontSize.value}
				iconOutSide={headerIconPosition === 'external'}>
				<Header
					headerColor={headerColor}
					headerIconOutside={headerIconPosition === 'external'}>
					{headerContentType === 'text' ? (
						<HeaderText
							color={headerTextAndIconColor}
							headerIconOutside={headerIconPosition === 'external'}>
							{botName === 'botName' ? botDetails.botName : botTitle}
						</HeaderText>
					) : (
						<div>
							<HeaderImage src={headerFile} />
						</div>
					)}
					{headerIconPosition !== 'external' ? (
						<HeaderIcon iconOutSide={headerIconPosition === 'external'}>
							<HeaderIconSpan>
								<GCBCMinimise color={headerTextAndIconColor} />
							</HeaderIconSpan>
							<HeaderIconSpan>
								<GCBClose color={headerTextAndIconColor} />
							</HeaderIconSpan>
						</HeaderIcon>
					) : null}
				</Header>
				<Body
					iconOutSide={headerIconPosition === 'external'}
					background={background()}
					gradient={
						botBackground === 'gradient'
							? {
									backgroundGradientStartColor: backgroundGradientStartColor,
									backgroundGradientStopColor: backgroundGradientStopColor,
							  }
							: undefined
					}>
					<div className='chats-holder'>
						<div className='banner'>
							<WelcomeBanner
								src={welcomeBannerCheckbox[0] ? welcomeBannerFile : ''}
							/>
						</div>
						<div className='chats'>
							<ChatBubble
								message='Here’s a preview of how your bot will look with the customizations.'
								messageby='bot'
							/>
							<ChatBubble message='Got it!' messageby='user' />
							<ChatBubble
								message='This is how quick links, suggestion chips and options will look.'
								messageby='bot'
								options={true}
							/>
							<ChatBubble message='Option 1' messageby='user' />
							<ChatBubble
								message={
									<>
										<p>This is how buttons will look</p>
										<ChatBotButtonDiv
											buttonBckColor={buttonBckColor}
											buttonTextColor={buttonTextColor}
											buttonTextAndOutlineColor={buttonTextAndOutlineColor}>
											<ChatBotButton className='body1'>
												Filled button
											</ChatBotButton>
											<ChatBotButton className='body1'>
												Outlined button
											</ChatBotButton>
											<ChatBotButton className='body1 text-only-button'>
												Text-only button
											</ChatBotButton>
										</ChatBotButtonDiv>
									</>
								}
								messageby='bot'
							/>
							<ChatBubble message='Okay' messageby='user' />
							<ChatBubble
								message='This is how carousels will look.'
								messageby='bot'
								carousel
							/>
						</div>
					</div>
					<ChatBubble message='' input />
					<Footer>
						<div>Give feedback</div>
						<div>Powered by Flyfish.ai</div>
					</Footer>
				</Body>
			</GCBContainer>
		</div>
	);
};

export default GenericChatBot;
