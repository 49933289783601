import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../app/store';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import {
	set,
	setSnack,
	setSnackModal,
} from '../../../../../Features/message/messageSlice';
import {
	crmUnlink,
	crmsLinked,
	resetCrmUnlinkLoader,
	resetEmailData,
	resetUnlinkFreshChatLoader,
	unlinkFreshchat,
} from '../../../../../Features/teamsetting/teamsettingSlice';

const UnlinkEmail = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { crmUnlinkLoader, emailData } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const [buttonLoader, setButtonLoader] = useState(false);

	const unlinkEmail = () => {
		tenantId &&
			dispatch(
				crmUnlink({ tenantId: tenantId, linkedCrmId: emailData.linkCrmId })
			);
	};
	const buttons = () => {
		return (
			<>
				<Button onClick={() => navigate(-1)} buttonType='light'>
					<p>Cancel</p>
				</Button>
				<Button onClick={unlinkEmail} loading={buttonLoader}>
					<p>Unlink</p>
				</Button>
			</>
		);
	};

	useEffect(() => {
		if (crmUnlinkLoader === 'success') {
			setButtonLoader(false);

			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: `Email is unlinked`,
				})
			);
			dispatch(crmsLinked({ tenantId: tenantId! }));
			dispatch(resetEmailData());
			navigate(-1);
		} else if (crmUnlinkLoader === 'failed') {
			setButtonLoader(false);
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage:
						'Unable to unlink email at the moment. Please try after sometime!!!',
					modalMode: 'negative',
				})
			);
		} else if (crmUnlinkLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetCrmUnlinkLoader());
	}, [crmUnlinkLoader]);

	return (
		<div className='ff-unlink-email'>
			<Modal header='Remove email?' actionButtons={buttons()}>
				<p className='body1'>
					You will no longer be able to send data to this email address. Any
					actions in your AI that currently send data to this email will be
					affected.
				</p>
			</Modal>
		</div>
	);
};

export default UnlinkEmail;
