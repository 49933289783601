import { useState, useEffect } from 'react';
// import Pagination from '../Pagination/Pagination';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	setFilter,
	setOffset,
	getList,
	reset,
	setPage,
} from '../../../../Features/WhatsApp-BSP/contact/contactSlice';
import { Outlet, useNavigate } from 'react-router-dom';
import {
	dateFormat,
	debounce,
	formatWaba,
	kotakTenantCheckForWhatsapp,
	loadState,
} from '../../../../utils/utils';
import { set } from '../../../../Features/message/messageSlice';
import Button from '../../../../Components/Button';
import { Add } from '@mui/icons-material';
import Search from '../../../../Components/Search';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import './Consent.scss';
import Chip from '../../../../Components/Chip';
import InteractiveButton from '../../../../Components/Button/InteractiveButton';
import Menu, { Options } from '../../../../Components/Menu';
import Table from '../../../../Components/Table';
import { MenuItem, Menu as MaterialUIMenu } from '@mui/material';
import Pagination from '../../../../Components/Table/Pagination';
const LIMIT = 20;
const SIZE = 20;

const Consent = () => {
	const [UpperL, setUpperL] = useState(0);
	const [LowerL, setLowerL] = useState(0);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<Array<Options>>([]);
	const [anchorElAdd, setAnchorElAdd] = useState<null | HTMLElement>(null);
	const openAdd = Boolean(anchorElAdd);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
		setAnchorElAdd(event.currentTarget);
	const handleClose = () => setAnchorElAdd(null);
	const { IsConsentJobUploded } = useSelector(
		(state: RootState) => state.contact
	);
	const { list, total, offset, filter, isLoading, success, error, page } =
		useSelector((state: RootState) => state.contact);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { wabaNo } = useSelector((state: RootState) => state.BSPauth);
	useEffect(() => {
		if (filter.length === 0 && offset === 0) {
			kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
				? dispatch(getList({ offset: 0, wabaNo }))
				: dispatch(getList({ page: 0, size: SIZE, wabaNo }));
		} else {
			dispatch(setOffset(0));
			dispatch(setFilter(''));
		}
	}, []);

	useEffect(() => {
		if (IsConsentJobUploded === 'success') {
			dispatch(set(IsConsentJobUploded));
		}
	}, [IsConsentJobUploded]);

	useEffect(() => {
		if (!isLoading) {
			if (kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)) {
				let data: {
					offset?: number;
					filter?: string;
					custNo?: number;
					mobileNo?: number;
					wabaNo: string;
					page?: number;
					size?: number;
					limit?: number;
				} = {
					offset,
					wabaNo,
				};
				if (filter) {
					if (!isNaN(parseInt(filter))) {
						data.mobileNo = parseInt(filter);
						data.limit = 1000;
					} else {
						data.filter = filter;
						data.limit = 1000;
					}
				}
				debounce(() => dispatch(getList(data)), 600);
			} else {
				let data = {
					page: filter.length ? 0 : page,
					size: filter.length ? 1000 : SIZE,
					wabaNo: wabaNo,
					filter: filter,
				};
				debounce(() => dispatch(getList(data)), 600);
			}
		}
	}, [offset, filter]);

	useEffect(() => {
		if (list.length > 0) {
			setUpperL(list.length + offset);
			setLowerL(offset + 1);
		}
	}, [list]);

	useEffect(() => {
		if (success.length > 0) {
			dispatch(set(success));
		} else if (error && error.length > 0) {
			dispatch(set(error));
		}
	}, [success, error]);

	useEffect(() => {
		return () => {
			dispatch(reset());
			setUpperL(0);
			setLowerL(0);
		};
	}, []);

	const pagination = () => {
		return (
			<Pagination
				disableforward={total <= offset + list.length}
				disableback={offset === 0}
				totalCount={total}
				upper={UpperL}
				lower={LowerL}
				nextPage={() => {
					dispatch(setOffset(offset + LIMIT));
					dispatch(setPage(page + 1));
				}}
				previousPage={() => {
					dispatch(setOffset(offset - LIMIT));
					dispatch(setPage(page - 1));
				}}
				gotoBegining={() => {
					dispatch(setOffset(0));
					dispatch(setPage(0));
				}}
				gotoLast={() => {
					dispatch(setOffset(Math.floor(total / LIMIT) * LIMIT));
					dispatch(setPage(Math.floor(total / LIMIT)));
				}}
			/>
		);
	};

	const column = [
		{ key: 'mobileNo', title: 'Number' },
		{
			key: 'name',
			title: 'User Name',
			render: (arg: any) => arg.name ?? '-',
		},
		{
			key: 'status',
			title: 'Status',
			render: (row: any) => (
				<Chip
					label={row.status}
					code={
						(row.status.toUpperCase() === 'OPTED IN'
							? 'positive'
							: 'negative') ?? ''
					}
				/>
			),
		},
		{ key: 'channel', title: 'Consent Channel' },
		{
			key: 'consentTime',
			title: 'Consent collection time',
			render: (arg: any) =>
				dateFormat(arg.consentTime?.split('.')[0] + '.000Z'),
		},
		{
			key: 'action',
			title: '',
			render: (arg: any) => (
				<div className='three-dot'>
					<InteractiveButton
						icon={<MoreVertOutlinedIcon />}
						onClick={(event: any) => {
							event.stopPropagation();
							setAnchorEl(event.target);

							// setOpen((prev) => !prev || anchorEl !== event.target);
							setOpen(true);
							setOptions([
								// {
								// 	label: <p className='menu-label'>Edit</p>,
								// 	onClick: () => {
								// 		navigate('edit', {
								// 			state: { user: arg },
								// 		});
								// 		setOpen(false);
								// 	},
								// },
								{
									label: <p className='menu-label'>Delete</p>,
									onClick: () => {
										setOpen(false);
										navigate('delete', {
											state: { user: arg },
										});
									},
								},
							]);
						}}
					/>
				</div>
			),
		},
	];

	return (
		<>
			<div className='sf-consent-header'>
				<h4>Contacts</h4>
				<div className='sf-consent-search-and-button'>
					<div className='sf-consent-search'>
						<Search
							isLoading={isLoading}
							placeholder={'Search by User Name or Number'}
							onChange={(value) => {
								dispatch(setFilter(value));
								dispatch(setOffset(0));
							}}
						/>
					</div>
					<div style={{ position: 'relative' }}>
						<Button
							id='Add-button'
							aria-controls={openAdd ? 'basic-menu' : undefined}
							aria-haspopup='true'
							aria-expanded={openAdd ? 'true' : undefined}
							onClick={handleClick}
							// startIcon={<Add />}
						>
							<p className='button-1'>Add contact</p>
						</Button>
						<MaterialUIMenu
							sx={{
								zIndex: 10,
								marginTop: '4px',
								borderRadius: '8px',
								boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.28)',
							}}
							id='Add-menu'
							anchorEl={anchorElAdd}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							open={openAdd}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
							}}>
							<MenuItem
								sx={{ padding: '12px' }}
								onClick={() => {
									navigate('add');
									handleClose();
								}}>
								<p className='body1'>Add individual contact</p>
							</MenuItem>
							<MenuItem
								sx={{ padding: '12px', cursor: 'not-allowed' }}
								// onClick={() => {
								// 	navigate('upload');
								// 	handleClose();
								// }}
							>
								<p className='body1'>Import from Excel</p>
							</MenuItem>
						</MaterialUIMenu>
					</div>
				</div>
			</div>

			<div className='sf-consent-tabular-data'>
				{total > 0 || isLoading ? (
					<>
						<Table
							height={'calc(100vh - 252px)'}
							column={column}
							limit={LIMIT}
							page={offset / LIMIT}
							pagination={filter.length ? <div></div> : pagination()}
							totalCount={total}
							isLoading={isLoading}
							data={list}
						/>
						<Menu
							anchorEl={anchorEl}
							open={open}
							setOpen={setOpen}
							options={options}
						/>
					</>
				) : (
					<p className='body2 not-found'>
						{filter.length > 0 ? 'No contacts found' : 'No contacts added'}
					</p>
				)}
				<Outlet />
			</div>
		</>
	);
};

export default Consent;
