import React, { useEffect, useState } from 'react';
import Modal from '../../../Components/Modal';
import { useLocation, useNavigate } from 'react-router';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import {
	delOriginURL,
	getOriginData,
	resetWebsiteDeleteLoader,
} from '../../../Features/channel/channelSlice';
import { useSelector } from 'react-redux';
import { setSnackModal } from '../../../Features/message/messageSlice';

const RemoveAIFromWebsite = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { websiteDeleteLoader } = useSelector(
		(state: RootState) => state.channel
	);

	const state = location.state as { originId: number; origin: string };
	const [buttonLoader, setButtonLoader] = useState(false);
	useEffect(() => {
		if (websiteDeleteLoader === 'success') {
			setButtonLoader(false);
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: `AI disabled on ${state.origin}`,
				})
			);
			dispatch(getOriginData({ tenantId: tenantId! }));
			navigate(-1);

			// navigate('/app/zoho-consent');
		} else if (websiteDeleteLoader === 'failed') {
			setButtonLoader(false);

			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage:
						'Unable to remove at the moment. Please try after sometime!!!',
					modalMode: 'negative',
				})
			);
		} else if (websiteDeleteLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetWebsiteDeleteLoader());
	}, [websiteDeleteLoader]);
	return (
		<div className='ff-remove-ai-from-website'>
			<Modal
				header={`Remove the AI from ${state.origin}?`}
				actionButtons={
					<>
						<Button onClick={() => navigate(-1)} buttonType='light'>
							Cancel
						</Button>
						<Button
							loading={buttonLoader}
							onClick={() => {
								dispatch(
									delOriginURL({
										tenantId: tenantId!,
										originId: state.originId,
									})
								);
							}}>
							Remove
						</Button>
					</>
				}>
				<p
					className='body1'
					style={{ marginBottom: '16px', marginTop: '12px' }}>
					Please confirm, do you wish to remove AI from {state.origin}?
				</p>
				<p className='body1'>
					This will stop the AI from working on the website but the code you
					added manually to the website while adding the AI will need to be
					removed manually by you.
				</p>
			</Modal>
		</div>
	);
};

export default RemoveAIFromWebsite;
