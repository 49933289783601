import { useAppDispatch } from '../../../app/store';
import Modal from '../../../Components/Modal';
import {
	CreateNewTeamImgSmall,
	JoinNewTeamImgSmall,
} from '../../../assets/Icons';
import './createjointeam.scss';
import { useNavigate } from 'react-router-dom';
import {
	createjoinTeamset,
	createTeamset,
	joinTeamset,
} from '../../../Features/teams/TeamsSlice';
import { useEffect } from 'react';

function CreateJoinTeam() {
	// const dispatch = useAppDispatch();
	const navigate = useNavigate();
	return (
		<div style={{ margin: 'auto' }}>
			<div className='ff-create-join-container'>
				<div className='ff-create-header'>
					<h2>Let's get started</h2>
				</div>
				<div className='ff-create-select-tab'>
					{/* <div className='ff-create-select-tab-cnt' onClick={()=>{navigate('/app/create-team')}}>
					<CreateNewTeamImgSmall width='220' height='220'/>
					<div><h4>Create a team</h4></div>
				</div> */}
					<div
						className='ff-create-select-tab-cnt'
						onClick={() => {
							navigate('/app/join-team');
						}}>
						<JoinNewTeamImgSmall width='220' height='220' />
						<div>
							<h4>Join a team</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CreateJoinTeam;
