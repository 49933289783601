export const NEWTABS = [
	{
		Tab: {
			title: 'Actions',
			path: 'actions',
		},
		SubTabs: [
			{
				title: 'Generate Leads',
				path: 'actions/generate-leads',
			},
			{
				title: 'Book Appointment',
				path: 'actions/book-appointment',
			},
			{ title: 'Call Customer Care', path: 'actions/call-customer-care' },
			{ title: 'Raise a Ticket', path: 'actions/raise-a-ticket' },
			{ title: 'Chat With a Human', path: 'actions/chat-with-human' },
		],
	},
	{
		Tab: {
			title: 'AI Model',
			path: 'AI-model',
		},
	},
	{
		Tab: {
			title: 'Multilingual Support',
			path: 'multilingual-support',
		},
		SubTabs: [],
	},
	{
		Tab: {
			title: 'Experience on web',
			path: 'experience-on-web/general',
		},
		SubTabs: [
			// { title: 'Avatar', path: 'experience-on-web/avatar' },
			{ title: 'General', path: 'experience-on-web/general' },
			// { title: 'Header', path: 'experience-on-web/header' },
			// { title: 'Background', path: 'experience-on-web/background' },
			{ title: 'Appearance', path: 'experience-on-web/appearance' },
			{
				title: 'Design Theme',
				path: 'experience-on-web/full-screen-welcome-config',
			},
	    {
				title: 'Pop-up Widget',
				path: 'experience-on-web/starting-the-chat',
		     },

			// { title: 'Messages', path: 'experience-on-web/messages' },
			// {
			// 	title: 'Options & Buttons',
			// 	path: 'experience-on-web/options-and-buttons',
			// },
			// { title: 'Input box', path: 'experience-on-web/input-box' },
			{ title: 'Persistent Menu', path: 'experience-on-web/persistent-menu' },
		],
	},
];

export const NEWTABSWITHOUTAIMODEL = [
	{
		Tab: {
			title: 'Actions',
			path: 'actions',
		},
		SubTabs: [
			{
				title: 'Generate Leads',
				path: 'actions/generate-leads',
			},
			{
				title: 'Book Appointment',
				path: 'actions/book-appointment',
			},
			{ title: 'Call Customer Care', path: 'actions/call-customer-care' },
			{ title: 'Raise a Ticket', path: 'actions/raise-a-ticket' },
			{ title: 'Chat With a Human', path: 'actions/chat-with-human' },
		],
	},

	{
		Tab: {
			title: 'Multilingual Support',
			path: 'multilingual-support',
		},
		SubTabs: [],
	},
	{
		Tab: {
			title: 'Experience on web',
			path: 'experience-on-web/general',
		},
		SubTabs: [
			// { title: 'Avatar', path: 'experience-on-web/avatar' },
			{ title: 'General', path: 'experience-on-web/general' },
			// { title: 'Header', path: 'experience-on-web/header' },
			// { title: 'Background', path: 'experience-on-web/background' },
			{ title: 'Appearance', path: 'experience-on-web/appearance' },
			{
				title: 'Full Screen Widget',
				path: 'experience-on-web/full-screen-welcome-config',
			},
			{
				title: 'Pop-up Widget',
				path: 'experience-on-web/starting-the-chat',
			},

			// { title: 'Messages', path: 'experience-on-web/messages' },
			// {
			// 	title: 'Options & Buttons',
			// 	path: 'experience-on-web/options-and-buttons',
			// },
			// { title: 'Input box', path: 'experience-on-web/input-box' },
			{ title: 'Persistent Menu', path: 'experience-on-web/persistent-menu' },
		],
	},
];
