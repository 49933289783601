import './ChannelsCard.scss';
import { CardMenuIcon } from '../../Pages/MyStudio/icon';
import InteractiveButton from '../Button/InteractiveButton';

import { useEffect, useState } from 'react';

import { Tooltip } from '@mui/material';
import Button from '../Button';

import { loadState } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../app/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { setActiveBot } from '../../Features/Bots/BotSlice';
import LinkAd from '../../Pages/Launch/LinkAd/LinkAd';
import { wabaInsta, WabaNo } from '../../Features/WhatsApp-BSP/auth/authSlice';
import { ChatLink } from '../../Pages/FF-Beta/BetaChannels/ChatLink';
import AppleBusiness from '../../assets/images/launch/apple.png';
import GoogleBusiness from '../../assets/images/launch/google_business.png';
import Instagram from '../../assets/images/launch/instagram.png';
import LineMessenger from '../../assets/images/launch/line.png';
import Telegram from '../../assets/images/launch/telegram.png';
import Tiktok from '../../assets/images/launch/tiktok.png';
import Whatsapp from '../../assets/images/launch/whatsapp.png';
import Web from '../../assets/images/launch/web.png';
import FaceBook from '../../assets/images/launch/facebook.png';
import WebLink from '../../assets/images/launch/weblink.png';
type ChannelsCardProps = {
	onClick?: () => void;
	channel: string;
	channelName: string;
	status?: React.ReactNode;
	botIcon?: string;
	botName?: string;
	onClickOptions?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	statusMessage?: string;
	forLaunchScreen?: boolean | false;
	botCoverImage?: string;
	details?: WabaNo;
	onHover?: Function;
	onHoverOut?: Function;
};

const ChannelsCard = ({
	onClick,
	channel,
	channelName,
	status,
	details,
	botIcon,
	botName,
	onClickOptions,
	statusMessage,
	forLaunchScreen,
	botCoverImage,
	onHover,
	onHoverOut,
}: ChannelsCardProps) => {
	const { afterSalesBot } = useSelector((state: RootState) => state.sales);
	const [active2, setActive2] = useState<wabaInsta | undefined>(undefined);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [linkAd, setLinkAd] = useState(false);
	const [addWebsite, setAddWebsite] = useState(false);
	const [chatLink, setChatLink] = useState(false);
	const [open, setOpen] = useState(false);
	const path = location.pathname;
	const faqPath = path.indexOf('faq') > -1;
	useEffect(() => {
		if (faqPath) {
			dispatch(setActiveBot(afterSalesBot));
		}
	}, []);
	const getOverlay = (channelType: string) => {
		switch (channelType.toLowerCase()) {
			case 'whatsapp':
				return forLaunchScreen
					? 'overlay-for-whatsapp'
					: 'overlay-for-whatsapp';
			case 'instagram':
				return 'overlay-for-instagram';
			case 'website':
				return 'overlay-for-website';
			case 'google business messages':
				return 'overlay-for-google';
			case 'facebook messenger':
				return 'overlay-for-messenger';
			case 'apple business chat':
				return 'overlay-for-apple';
			case 'web link':
				return 'overlay-for-static-web';
			case 'tiktok':
				return 'overlay-for-tiktok';
			case 'telegram':
				return 'overlay-for-telegram';
			case 'line messenger':
				return 'overlay-for-line';
			default:
				return '';
		}
	};

	//these overlay provided as images, so defining the classes separately
	const getOverlayForWithOutImg = (channelType: string) => {
		switch (channelType.toLowerCase()) {
			case 'instagram':
				return 'overlay-for-instagram';
			case 'messenger':
				return 'overlay-for-messenger';

			default:
				return '';
		}
	};

	const getChannelIcon = (channelType: string) => {
		switch (channelType.toLowerCase()) {
			case 'whatsapp':
				return forLaunchScreen ? Whatsapp : Whatsapp;
			case 'instagram':
				return Instagram;
			case 'website':
				return Web;
			case 'google business messages':
				return GoogleBusiness;
			case 'web link':
				return WebLink;
			case 'facebook messenger':
				return FaceBook;
			case 'apple business chat':
				return AppleBusiness;
			case 'tiktok':
				return Tiktok;
			case 'telegram':
				return Telegram;
			case 'line messenger':
				return LineMessenger;
			default:
				return '';
		}
	};

	//gradient is availabale for only whatsapp, apple chat, google --
	// const getLinearGradient = (channelType: string) => {
	// 	if (statusMessage?.toUpperCase() === 'ACTIVE') {
	// 		switch (channelType) {
	// 			case 'whatsapp':
	// 				return 'gradient-for-whatsapp';
	// 			case 'google':
	// 				return 'gradient-for-google';
	// 			case 'Web Link':
	// 				return 'gradient-for-static-card';
	// 			case 'web':
	// 				return 'gradient-for-web';
	// 			default:
	// 				return '';
	// 		}
	// 	}
	// };

	return (
		<div
			// onClick={() => onClick && onClick()}
			className={`channel-card ${channel === 'whatsapp' && !forLaunchScreen
				? 'pointer-for-card'
				: 'default-for-card'
				}`}
			onMouseOver={() => {
				onHover && onHover();
			}}
			onMouseLeave={() => {
				onHoverOut && onHoverOut();
			}}>
			{status !== 'addchannels' ? (
				<>
					<div
						className={`overlay ${getOverlayForWithOutImg(channel)}`}
						style={{
							backgroundColor: `${statusMessage?.toUpperCase() === 'ACTIVE' &&
								channel === 'instagram'
								? ''
								: ''
								}`,
						}}></div>
					{/* {!Boolean(botCoverImage && botCoverImage?.length > 0) &&
						statusMessage?.toUpperCase() !== 'ACTIVE' &&
						channel !== 'web' && <div className='pause-state'></div>}

					{Boolean(botCoverImage && botCoverImage?.length > 0) &&
						statusMessage?.toUpperCase() !== 'ACTIVE' &&
						channel !== 'web' && <div className='pause-state-for-image'></div>} */}

					{/* {channel === 'whatsapp' &&
						!Boolean(botCoverImage && botCoverImage?.length > 0) &&
						statusMessage?.toUpperCase() !== 'ACTIVE' && (
							<div className='pause-hover-state'></div>
						)} */}
					{/* hover state if there is background image */}
					{channel === 'whatsapp' && !forLaunchScreen && (
						<div
							className={`hover-state ${statusMessage?.toUpperCase() === 'ACTIVE'
								? Boolean(botCoverImage && botCoverImage?.length > 0)
									? `hover-state-for-img`
									: 'hover-state-for-no-img'
								: ''
								}	`}></div>
					)}
					{/* code when there is background image  */}
					{Boolean(botCoverImage && botCoverImage?.length > 0) && (
						<>
							{/* <img
								src={botCoverImage}
								alt='channel'
								className={`index-for-image ${
									channel === 'whatsapp' && !forLaunchScreen
										? 'channel-background-img'
										: 'channel-background-img-insta'
								} `}
							/> */}
							<div
								className={`overlay-for-image ${statusMessage?.toUpperCase() !== 'ACTIVE'
									? channel === 'web'
										? getOverlay(channel)
										: ''
									: getOverlay(channel)
									}`}></div>
						</>
					)}
					{/* {Boolean(botCoverImage && botCoverImage?.length > 0) &&
						channel === 'whatsapp' &&
						statusMessage?.toUpperCase() !== 'ACTIVE' && (
							<div className='pause-hover-state-for-image'></div>
						)} */}
					<div className='content'>
						<div className='channel-details-container'>
							<div className='more-options'>
								{onClickOptions && (
									<InteractiveButton
										hoverable='ring'
										title='More options'
										icon={<CardMenuIcon width='25' />}
										onClick={(event) => {
											event.preventDefault();
											onClickOptions(event);
										}}
									/>
								)}
							</div>
							<div className='icon'>
								<img src={getChannelIcon(channel)} alt={channel} />
							</div>
							{channel.toLowerCase() === 'web link' && channelName === '' && (
								<div className='body1 web-link'>{channel}</div>
							)}

							{botName && botName?.length > 0 ? (
								<Tooltip title={channelName}>
									<div className={`body1 channel-name `}>{channelName}</div>
								</Tooltip>
							) : (
								<Tooltip title={channelName}>
									<>
										<div
											className={`${channel.toLowerCase() !== 'web link'
												? 'body1 channel-name'
												: 'body1 web-link'
												}`}>
											{channelName.replace('https://', '')}
										</div>
									</>
								</Tooltip>
							)}
						</div>
						<div className={'overline status'}>
							{status}

							{(channel.toLowerCase() === 'web link' ||
								channel.toLowerCase() === 'website') && (
									<div className='get-link-button'>
										<Button buttonType='transparent' onClick={onClick}>
											Get Link
										</Button>
									</div>
								)}
							{channel.toLowerCase() === 'whatsapp' && (
								<div className='get-link-button'>
									<Button
										buttonType='transparent'

										onClick={() => {
											setChatLink(true);
											setActive2(undefined);
											setActive2(details as wabaInsta);
											setOpen(false);
										}}>
										Get Link
									</Button>
								</div>
							)}
						</div>
					</div>
				</>
			) : (
				<div className={`background ${getOverlay(channel)}`}>
					<div className='content'>
						<div>
							<div className='icon'>
								<img src={getChannelIcon(channel)} alt={channel} />
							</div>
							<div className='body1 addcards'> {channel} </div>
						</div>
						<div
							className={`get-link-button ${channel.toLowerCase() === 'whatsapp' ||

								channel.toLowerCase() === 'website'
								? ''
								: 'coming-soon-button-addcards'
								}`}>
							<Button
								buttonType='transparent'
								disabled={
									channel.toLowerCase() === 'whatsapp' ||

										channel.toLowerCase() === 'website'
										? false
										: true
								}
								onClick={() => {
									channel.toLowerCase() === 'website'
										? setAddWebsite(true)
										: navigate('/app/launch/setup-wa-insta', {
											state: { value: channel },
										});
								}}>
								{channel.toLowerCase() === 'whatsapp' ||

									channel.toLowerCase() === 'website'
									? 'Add'
									: 'Coming Soon'}
							</Button>
						</div>
					</div>
				</div>
			)}
			{linkAd && (
				<LinkAd setLinkAd={setLinkAd} channel={active2 as wabaInsta} />
			)}
			{chatLink && (
				<ChatLink Details={active2 as wabaInsta} setChat={setChatLink} />
			)}
		</div>
	);
};
export default ChannelsCard;
