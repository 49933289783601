import DataFetchAPI from '../../utils/Api';
import DataFetchRouteAPI from '../../utils/RouteApi';
import { config, uploadBot, uploadFile } from './model';

const getAIDetails = async (data: config) => {
	const { tenantId, botId } = data;
	return await DataFetchAPI(false, true, 'Bearer').get(
		`/configuration/${tenantId}/bot/${botId}`
	);
};

const updateAIDetails = async (data: uploadBot) => {
	const { tenantId, botId, details } = data;
	return await DataFetchAPI(false, true, 'Bearer').put(
		`/configuration/${tenantId}/bot/${botId}`,
		details
	);
};

//https://dev.flyfish.ai/configuration/188/taskType
const getTaskType = async (data: { tenantId: number }) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		`configuration/${data.tenantId}/taskType`
	);
};

const uploadImage = async (data: uploadFile) => {
	const { tenantId, botId, file } = data;
	return await DataFetchRouteAPI(false, true, 'Bearer').post(
		`/configuration/${tenantId}/bot/${botId}/upload`,
		file
	);
};

const experienceonwebService = {
	uploadImage,
	getAIDetails,
	updateAIDetails,
	getTaskType,
};

export default experienceonwebService;
