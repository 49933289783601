import DataFetchAPI from '../../utils/Api';
import {
	AvailableLanguages,
	GETBOTCONFIGURATION,
	GETWORKFLOWSETTING,
	SCHEDULE,
	UPLOAD,
	config,
	uploadBot,
} from './model';

const dummyApi = async (data: any) => {
	return await DataFetchAPI().post('/chatWithHuman', data);
};
const getBotDetails = async (data: config) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		`configuration/${data.tenantId}/bot/${data.botId}`
	);
};

//normal bot id
const getWorkflowSetting = async (data: GETWORKFLOWSETTING) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		'/workflow/getWorkflowSetting',
		{
			params: data,
		}
	);
};

//encrypted bot id
const getBotConfigurations = async (data: GETBOTCONFIGURATION) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		`/bot/public/getBotConfigurations`,
		{
			params: data,
		}
	);
};

//normal bot id
const schedule = async (data: SCHEDULE) => {
	return await DataFetchAPI(false, true, 'Bearer').post('/schedule', data);
};

const createWorkflowSetting = async (data: object) => {
	return await DataFetchAPI(false, true, 'Bearer').post(
		'/workflow/createWorkflowSetting',
		data
	);
};

const createSecondary = async (data: any) => {
	return await DataFetchAPI(false, true, 'Bearer').post(
		'/bot/createSecondary',
		data
	);
};

const createBotConfigurations = async (data: object) => {
	return await DataFetchAPI(false, true, 'Bearer').post(
		'/bot/createBotConfigurations',
		data
	);
};

///bot/uploadFile
const uploadFile = async (data: UPLOAD) => {
	return await DataFetchAPI(false, true, 'Bearer', 'multipart/form-data').post(
		'/bot/uploadFile',
		{ uploadfile: data.uploadfile }
	);
};
const updateBotDetails = async (data: uploadBot) => {
	return await DataFetchAPI(false, true, 'Bearer').post(
		`configuration/${data.tenantId}/bot/${data.botId}`,
		data.details
	);
};

const availableLanguages = async (data: AvailableLanguages) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		'/multilingual/availableLanguage',
		{
			params: data,
		}
	);
};

const teamsettingService = {
	getWorkflowSetting,
	getBotConfigurations,
	schedule,
	createWorkflowSetting,
	createSecondary,
	createBotConfigurations,
	uploadFile,
	availableLanguages,
	dummyApi,
	getBotDetails,
	updateBotDetails,
};
export default teamsettingService;
