import CookieConsent from 'react-cookie-consent';
import './CookiePopup.scss';
import CSS from 'csstype';

const CookiePopup = () => {
	const cookieStyle: CSS.Properties = {
		marginRight: '24px',
		marginBottom: '24px',
		width: '402px',
		height: '149px',
		borderRadius: '8px',
		boxShadow: '0 24px 48px 0 rgba(0, 0, 0, 0.28)',
		backgroundColor: 'var(--white)',
		opacity: '1',
		position: 'absolute',
		zIndex: '1000',
	};
	const acceptButtonStyle: CSS.Properties = {
		color: 'white',
		width: '190px',
		height: '34px',
		padding: '7px 16px 8px',
		marginLeft: '12px',
		marginBottom: '24px',
		borderRadius: '8px',
		backgroundColor: 'var(--violet-600)',
		fontWeight: '600',
		whiteSpace: 'nowrap'
	};
	const declineButtonStyle: CSS.Properties = {
		color: '#3b44c4',
		width: '125px',
		height: '34px',
		padding: '7px 16px 8px',
		marginBottom: '24px',
		marginLeft: '24px',
		borderRadius: '8px',
		backgroundColor: 'var(--violet-100)',
		fontWeight: '600',
		whiteSpace: 'nowrap'
	};

	return (
		<div className='cookie-popup-container'>
			<CookieConsent
				location='bottom'
				buttonText='Accept & Continue'
				declineButtonText='No, thanks'
				cookieName='Flyfish_Cookie'
				style={cookieStyle}
				buttonStyle={acceptButtonStyle}
				declineButtonStyle={declineButtonStyle}
				expires={150}
				onAccept={() => { }}
				enableDeclineButton
				onDecline={() => { }}
				disableStyles={true}
				// buttonClasses={{}}
				containerClasses='alert alert-warning col-lg-12'
				contentClasses='text-capitalize'>
				<div className='popup-desc'>
					<span>
						We use cookies to enhance your experience on our site. Read our{' '}
						<a
							style={{ color: '#3b44c4' }}
							href='https://www.flyfish.ai/privacy-policy/'
							target='_blank'
							rel='noreferrer'>
							Privacy Policy
						</a>{' '}
						to learn more.
					</span>
				</div>
			</CookieConsent>
		</div>
	);
};

export default CookiePopup;
