import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import templateService from './templateService';
import { TemplateListItem } from '../../../Pages/WhatsAppBSP/ManageTemplates/model';

type State = {
	list: TemplateListItem[];
	dummyTemplate: any;
	total: number;
	offset: number;
	filter: string;
	isLoading: boolean;
	error: string;
	success: string;
	isTemplateDeleted: string;
	isTemplateDelError: string;
	sync: string;
};

type GetList = {
	templates: TemplateListItem[];
	totalCount: number;
};

type GetListRequest = {
	wabaNo: string;
	offset: number;
	templateName?: string;
	status?: string;
};

type deleteTemplateData = {
	wabaNo: string;
	templateId: number;
};

export const getList = createAsyncThunk<
	GetList,
	GetListRequest,
	{ rejectValue: string }
>('template/getList', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await templateService.getList(data);

		if (response.data) {
			return response.data ?? [];
		}

		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const updateList = createAsyncThunk<
	GetList,
	{ wabaNo: string },
	{ rejectValue: string }
>('template/updateList', async (data, { rejectWithValue }) => {
	try {
		const response = await templateService.updateList(data);
		if (response.data.templates) {
			return response.data;
		}

		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const deleteTemplate = createAsyncThunk<
	any,
	deleteTemplateData,
	{ rejectValue: string }
>('template/deleteTemplate', async (data, { rejectWithValue }) => {
	try {
		const response = await templateService.deleteTemplate(data);
		if (response) {
			return response.data;
		}
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

const initialState: State = {
	list: [],
	total: 0,
	offset: 0,
	filter: '',
	isLoading: true,
	error: '',
	success: '',
	isTemplateDeleted: '',
	isTemplateDelError: '',
	sync: '',
	dummyTemplate: [],
};

const templateSlice = createSlice({
	name: 'template',
	initialState,
	reducers: {
		reset: (state) => {
			state.list = [];
			state.total = 0;
			state.offset = 0;
			state.filter = '';
			state.isLoading = true;
			state.error = '';
			state.success = '';
			state.isTemplateDeleted = '';
			state.isTemplateDelError = '';
			state.sync = '';
		},
		setList: (state, action) => {
			state.list = action.payload;
		},
		setOffset: (state, action) => {
			state.offset = action.payload;
		},
		setFilter: (state, action) => {
			state.filter = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getList.pending, (state) => {
				state.isLoading = true;
				state.error = '';
				state.sync = 'syncing';
			})
			.addCase(getList.fulfilled, (state: State, action) => {
				// if (state.dummyTemplate.length != 0) {
				// 	let updatedList = current(state.dummyTemplate);
				// 	action.payload.templates.map((item: any) => {
				// 		updatedList = [...updatedList, item];
				// 	});
				// 	state.list = updatedList;
				// } else {
				// 	state.list = action.payload.templates ?? [];
				// }
				state.list = action.payload.templates;
				// state.dummyTemplate = state.list;
				state.total = action.payload.totalCount ?? 0;
				state.isLoading = false;
				state.sync = 'synced';
			})
			.addCase(getList.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.message;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(updateList.pending, (state) => {
				state.error = '';
				state.success = '';
			})
			.addCase(updateList.fulfilled, (state: State, action) => {
				state.isLoading = false;
				state.success = 'Template Synced';
			})
			.addCase(updateList.rejected, (state, action) => {
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.message;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(deleteTemplate.fulfilled, (state, action) => {
				state.isTemplateDeleted = 'Template Deleted';
				state.isTemplateDelError = '';
			})
			.addCase(deleteTemplate.rejected, (state, action) => {
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.isTemplateDelError = error.message;
					state.isTemplateDeleted = '';
				} else {
					state.isTemplateDelError = 'Something went wrong! Try again later';
				}
			});
	},
});

export const { reset, setOffset, setFilter, setList } = templateSlice.actions;

export default templateSlice.reducer;
