import './ChatBot.scss';
import { useEffect } from 'react';
import Button from '../../../Components/Button';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';

import { reset, set } from '../../../Features/message/messageSlice';
import PageLoader from '../../../Components/PageLoader';
import { useLocation } from 'react-router-dom';
import { loadState } from '../../../utils/utils';

type ChatBotProps = {
	encBotId: string;
	tenantId?: string | number;
	active: boolean;
	onClose: () => void;
};
declare global {
	interface Window {
		callSDKInit: Function;
	}
}
const ChatBot = ({
	encBotId,
	active = false,
	onClose,
	tenantId,
}: ChatBotProps) => {
	const dispatch = useAppDispatch();
	const { isTraining, trainingStatus, refreshBot } = useSelector(
		(state: RootState) => state.builder
	);
	const botId = loadState('botId');
	const location = useLocation();
	const path = location.pathname;
	const pathArr = path.split('/');
	useEffect(() => {
		// if (active) {
		// @ts-ignore

		// @ts-ignore
		if (window.FlyfishChatSDK) {
			// @ts-ignore

			window.callSDKInit(encBotId, tenantId);
		}

		// }
	}, []);

	// useEffect(() => {
	// 	if (refreshBot) {
	// 		if (trainingStatus === false) {
	// 			dispatch(
	// 				set(
	// 					`Failed to update the  ${pathArr[3] === 'intelligent-ad' ? 'Ad.' : 'Storefront.'
	// 					} Please refresh again`
	// 				)
	// 			);
	// 		} else if (trainingStatus === true) {
	// 			dispatch(
	// 				set(
	// 					`${pathArr[3] === 'intelligent-ad' ? 'Ad' : 'Storefront'} refreshed`
	// 				)
	// 			);
	// 		}
	// 	}
	// }, [trainingStatus]);

	useEffect(() => {
		dispatch(reset());
	}, []);

	return active && botId ? (
		<>
			<div id='sfChatBot'>
				{/* {isTraining ? (
					<div className='loader-on-chatbot'>
						<PageLoader
							message={`${refreshBot ? 'Refreshing...' : 'Loading...'}`}
							bgColor='white'
						/>
					</div>
				) : (
					<></>
				)} */}
			</div>
		</>
	) : null;
	// return null;
};
export default ChatBot;
