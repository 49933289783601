import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import passwordService from './ChangePasswordService';
import { CurrentPassword, ChangePassword } from './model';
import { saveState } from '../../utils/utils';

export interface changePasswordState {
	status: string;
	currentPasswordResponse: any;
	changePasswordResponse: any;
	encryptedCurrentPassword: string;
	changePasswordStatus: string;
	currentPasswordStatus: string;
	changePassword: boolean;
	changePasswordSuccess: boolean;
	CurrentPasswordVerifyStatus: boolean;
	PasswordChangeStatus: boolean;
}

export const CurrentPasswordVerify = createAsyncThunk<
	any,
	CurrentPassword,
	{ rejectValue: string }
>('/rest/verifyPassword', async (data, { rejectWithValue }) => {
	try {
		const response = await passwordService.VerifyCurrentPassword(data);
		switch (response.status) {
			case 200:
				return response.data;

			default:
				return rejectWithValue('Something went wrong! Try again later1');
		}
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const PasswordChange = createAsyncThunk<
	any,
	ChangePassword,
	{ rejectValue: string }
>('/rest/changePassword', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await passwordService.ChangeCurrentPassword(data);

		switch (response.status) {
			case 200:
				return response.data;

			default:
				return rejectWithValue('Something went wrong! Try again later1');
		}
	} catch (error: any) {
		const response = error.response;
		if (response.status === 401) {
			dispatch(changePasswordResponseerr(response.data.response));
		}
		return rejectWithValue(response.data);
	}
});

const initialState: changePasswordState = {
	status: 'idle',
	currentPasswordResponse: '',
	changePasswordResponse: '',
	encryptedCurrentPassword: '',
	changePasswordStatus: '',
	currentPasswordStatus: '',
	changePassword: false,
	changePasswordSuccess: false,
	CurrentPasswordVerifyStatus: false,
	PasswordChangeStatus: false,
};

export const ChangePasswordSlice = createSlice({
	name: 'password',
	initialState,
	reducers: {
		currentPassWord: (state, action: PayloadAction<any>) => {
			state.encryptedCurrentPassword = action.payload;
		},
		currentPwdResponse: (state, action: PayloadAction<any>) => {
			state.changePassword = action.payload.changePassword;
			state.changePasswordSuccess = action.payload.changePasswordSuccess;
		},
		clearPasswordResponse: (state) => {
			state.changePassword = false;
			state.changePasswordSuccess = false;
			state.currentPasswordStatus = '';
			state.encryptedCurrentPassword = '';
			state.currentPasswordResponse = '';
			state.changePasswordResponse = '';
			state.changePasswordStatus = '';
		},
		clearcurrentPasswordStatus: (state) => {
			state.currentPasswordStatus = '';
		},
		changePasswordResponseerr: (state, action) => {
			state.changePasswordResponse = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(CurrentPasswordVerify.pending, (state) => {
				state.CurrentPasswordVerifyStatus = true;
			})
			.addCase(CurrentPasswordVerify.fulfilled, (state, action) => {
				state.currentPasswordResponse = action.payload;

				state.CurrentPasswordVerifyStatus = false;
				state.currentPasswordStatus = 'Success';
			})
			.addCase(CurrentPasswordVerify.rejected, (state) => {
				state.CurrentPasswordVerifyStatus = true;
			})
			.addCase(PasswordChange.pending, (state) => {
				state.PasswordChangeStatus = true;
			})
			.addCase(PasswordChange.fulfilled, (state, action) => {
				state.changePasswordResponse = action.payload;
				state.PasswordChangeStatus = false;
				state.changePasswordStatus =
					action.payload.result.toLowerCase() === 'Failed'
						? 'Failed'
						: 'Success';
				saveState('changePasswordStatus', 'Success');
			})
			.addCase(PasswordChange.rejected, (state, action) => {
				state.PasswordChangeStatus = false;
				state.changePasswordResponse = action.payload;
			});
	},
});

export const {
	currentPassWord,
	currentPwdResponse,
	clearPasswordResponse,
	clearcurrentPasswordStatus,
	changePasswordResponseerr,
} = ChangePasswordSlice.actions;

export default ChangePasswordSlice.reducer;
