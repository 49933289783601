import React, { ImgHTMLAttributes, useEffect } from 'react';
import {
	ConversationTable,
	MiniCardData,
	MinicardPreviousData,
	onClickMiniCardAvgUserRating,
} from '../../../Features/Analytics/analyticsSlice';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import './minicards.scss';
import {
	Conversations,
	AnalyticUsers,
	Analyticchatduration,
	ViewAllTemplatesArrow,
	Analyticrating,
	LeadGen,
	RevenueGen,
} from '../../../assets/Icons';
import { AnalyticsDecreasePercent, AnalyticsIncreasePercent } from '../icon';
import { useNavigate } from 'react-router-dom';
import { getValuewithK } from '../../../utils/utils';

type minicardData = {
	heading: string;
	data: string | number;
	dataChange: number | string | any;
	onClick?: () => void;
	icon: JSX.Element | string | any;
}[];

function MiniCards() {
	const navigate = useNavigate();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { selectedBot, activeBot } = useSelector(
		(state: RootState) => state.bots
	);
	const {
		selectedDates,
		calendarstatus,
		selectedPreviousDates,
		MiniCardpreDatastatus,
		// analyticspreMiniCardData,
		// currentMiniCardData,
		activeAnalyticsBot,
	} = useSelector((state: RootState) => state.analytics);
	const currentMiniCardData = {
		accuracy: 1,
		avgFeedback: 3,
		avg_chat_dur: 4.2,
		browsers: {},
		chatFeedback: { negative: 0, positive: 0 },
		feedback: { incorrect: 0, correct: 0 },
		feedback_count: 1,
		ipaddresses: {},
		locations: {},
		total_convo: 35,
		total_ques: 13,
		unique_queries: 8,
		unique_user: 5,
	};

	const analyticspreMiniCardData = {
		accuracy: 0,
		avgFeedback: 5,
		avg_chat_dur: 3.2,
		browsers: {},
		chatFeedback: { negative: 0, positive: 0 },
		feedback: { incorrect: 0, correct: 0 },
		feedback_count: 0,
		ipaddresses: {},
		locations: {},
		total_convo: 20,
		total_ques: 0,
		unique_queries: 0,
		unique_user: 3,
	};
	const { total_convo, unique_user, avgFeedback, avg_chat_dur } =
		currentMiniCardData;
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (calendarstatus && activeAnalyticsBot) {
			tenantId &&
				dispatch(
					MiniCardData({
						startdate: selectedPreviousDates[0],
						enddate: selectedPreviousDates[1],
						timezone: -330,
						tenantId: tenantId,
						botId: activeAnalyticsBot.botId,
						isPreviousData: true,
					})
				);

			tenantId &&
				dispatch(
					MiniCardData({
						startdate: selectedDates[0],
						enddate: selectedDates[1],
						timezone: -330,
						tenantId: tenantId,
						botId: activeAnalyticsBot.botId,
					})
				);
		}
	}, [selectedDates, activeAnalyticsBot]);

	const data = avg_chat_dur || '0.0';
	const chartDuration = data.toString().split('.');
	const min =
		chartDuration && chartDuration[0] !== '0' ? chartDuration[0] + ' min ' : '';
	const sec =
		chartDuration && (chartDuration[1] == undefined || chartDuration[1] == '0')
			? ''
			: chartDuration[1] + ' sec';
	const changeData = (avg_chat_dur - analyticspreMiniCardData.avg_chat_dur)
		.toPrecision(4)
		// .toPrecision(3)
		?.split('.');
	const min1 =
		changeData && changeData[0] !== '0'
			? changeData[0] < '0'
				? Number(changeData[0]) * -1 + ' min '
				: Number(changeData[0]) + ' min '
			: '';
	const sec1 =
		changeData && changeData[1] !== '000' ? Number(changeData[1]) + ' sec' : '';

	const getChangeRate = (num1: number, num2: number) => {
		const percent = num1 - num2;
		const [T, H] = percent.toString().split('.');
		const value = H !== undefined ? [T, H[0]].join('.') : T;
		return value;
	};

	const getChangePercent = (num1: number, num2: number) => {
		const percent =
			num1 !== 0 && num2 !== 0
				? ((num1 - num2) / num2 !== 0 ? (num1 - num2) / num2 : 1) * 100
				: '';
		const [T, H] = percent.toString().split('.');
		const value = H !== undefined ? [T, H[0]].join('.') : T;

		return value;
	};

	const minicardData: minicardData = [
		{
			heading: 'Conversations',
			data: getValuewithK(total_convo),
			dataChange: getChangePercent(
				currentMiniCardData.total_convo,
				analyticspreMiniCardData.total_convo
			),
			onClick: () => {
				navigate('conversationtable');

				// dispatch(ConversationTable(true));
			},
			icon: <Conversations />,
		},
		{
			heading: 'Users',
			data: getValuewithK(unique_user),
			dataChange: getChangePercent(
				unique_user,
				analyticspreMiniCardData.unique_user
			),
			// onClick: () => {},
			icon: <AnalyticUsers />,
		},
		{
			heading: 'Avg. user rating',
			data: avgFeedback,
			dataChange: getChangeRate(
				avgFeedback,
				analyticspreMiniCardData.avgFeedback
			),
			onClick: () => {
				document.querySelector('.ff_analytics_container')?.scrollTo({
					top: 500,
					behavior: 'smooth',
				});
				dispatch(onClickMiniCardAvgUserRating(true));
			},

			icon: <Analyticrating fillIcon={true} color='#f2994a' />,
		},

		{
			heading: 'Avg. chat duration',
			data: avg_chat_dur === 0.0 ? 0 : min + sec,
			dataChange: (
				avg_chat_dur - analyticspreMiniCardData.avg_chat_dur
			).toPrecision(4),
			// onClick: () => {},
			icon: <Analyticchatduration />,
		},
		{
			heading: 'Leads Generated',
			data: getValuewithK(139090000),
			dataChange: '-',
			icon: <LeadGen />,
		},
		{
			heading: 'Revenue Generated',
			data: getValuewithK(4566865),
			dataChange: '-',
			icon: <RevenueGen />,
		},
	];

	return (
		<div>
			<div className='ff_Analytics_miniCards'>
				{minicardData.map((card) => {
					return (
						<div
							onClick={card.onClick}
							className=' ff_Analytics_miniCard'
							style={{
								backgroundColor:
									card.heading === 'Users'
										? '#edf1ff'
										: card.heading === 'Conversations'
										? '#fbf1fe'
										: card.heading === 'Avg. chat duration'
										? '#e7f0ee'
										: card.heading === 'Revenue Generated'
										? '#e4f6f8'
										: card.heading === 'Leads Generated'
										? '#fdf8e7'
										: '#ffefe6',
							}}>
							<div className='ff_analytics_card_title'>
								<div
									className={
										card.heading === 'Avg. user rating'
											? 'ff_Analytics_miniCard_icon_pd'
											: 'ff_Analytics_miniCard_icon '
									}>
									{card.icon}
								</div>
								<p className='ff_analytics_minicard_heading body2'>
									{card.heading}
								</p>
							</div>
							<div
								className='ff_analytic_data_count'
								// style={{
								// 	paddingLeft:
								// 		card.heading === 'Avg. user rating' ? '13px' : '',
								// }}
							>
								<div>
									<div className='ff_analytics_count_icon'>
										<h3
											className='ff_analytics_count'
											style={{
												paddingLeft:
													card.heading === 'Avg. user rating' ? '13px' : '',
											}}>
											{card.heading == 'Revenue Generated'
												? '$' + card.data
												: card.data}
										</h3>
										<div className='ff_analytics_counticon'>
											{card.heading === 'Avg. user rating' && (
												<Analyticrating fillIcon={true} color='#1b2050' />
											)}
										</div>
									</div>
									<div
										className='ff_check_percent caption'
										style={{
											paddingLeft:
												card.heading === 'Avg. user rating' ? '13px' : '',
										}}>
										{/* {card.dataChange === '0.000' || card.dataChange === 0 ? (
											''
										) : */}
										{card.dataChange < 0 ? (
											<div style={{ marginRight: '8px' }}>
												<AnalyticsDecreasePercent />
											</div>
										) : card.dataChange > 0 ? (
											<div style={{ marginRight: '8px' }}>
												<AnalyticsIncreasePercent />
											</div>
										) : (
											''
										)}
										<div
											className={` ${
												card.dataChange < 0
													? 'ff_change_negitive_data'
													: card.dataChange == 0
													? 'ff_change_nochange_data'
													: 'ff_change_positive_data'
											}`}>
											{card.dataChange === 'Infinity' ||
											card.dataChange === 'NaN' ? (
												'-'
											) : card.heading === 'Avg. chat duration' &&
											  card.dataChange !== '0.000' ? (
												min1 + sec1
											) : card.dataChange !== 0 ? (
												card.dataChange < 0 ? (
													card.heading === 'Avg. user rating' ? (
														card.dataChange * -1
													) : (
														card.dataChange * -1 + '%'
													)
												) : card.heading === 'Avg. user rating' ? (
													card.dataChange ? (
														<span>No change</span>
													) : (
														''
													)
												) : (
													card.dataChange + '%'
												)
											) : (
												'No change'
											)}
										</div>
									</div>
								</div>
								<div className='ff_analytics_minicard_arrow_icon '>
									{card.onClick && <ViewAllTemplatesArrow />}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default MiniCards;
