import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	CommerceRes,
	GetProductDetailsCS,
	inputChatReq,
	modifyremoveDetails,
	ProductFFReq,
	ProductReq,
	ProductsCSReq,
	ProductsCSRes,
	ProductsFFRes,
	ProductsRes,
	SalesState,
	SearchProductReq,
	SearchProductRes,
	WooCommReq,
	WooCommRes,
} from './model';
import { saveState } from '../../utils/utils';
import salesService from './salesService';

export const getProductsCS = createAsyncThunk<
	ProductsCSRes,
	ProductsCSReq,
	{ rejectValue: string }
>('/getProductsCS', async (data, { rejectWithValue, dispatch }) => {
	try {
		if (data.page === '0') {
			dispatch(setSalesAIproductLoader(true));
		} else {
			dispatch(setProductsLoader(true));
		}

		const res: any = await salesService.getProductsCS(data);
		if (res.data.response.status.toLowerCase() === 'success') {
			if (data.page === '0') {
				dispatch(setSalesAIproductLoader(false));
			} else {
				dispatch(setProductsLoader(false));
			}

			return res.data;
		}
		return rejectWithValue('Something went wrong! Try again later');
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(JSON.stringify(response.data.message));
	}
});

export const getProductDetailsCS = createAsyncThunk<
	any,
	GetProductDetailsCS,
	{ rejectValue: string }
>('/getProductDetailsCS', async (data, { rejectWithValue }) => {
	try {
		const response = await salesService.getProductDetailsCS(data);
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({
				error: 'Something went wrong! Try again later',
			})
		);
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(JSON.stringify(response.data.message));
	}
});

// export const getProducts = createAsyncThunk<
// 	ProductsRes,
// 	ProductReq,
// 	{ rejectValue: string }
// >('/getProducts', async (data, { rejectWithValue, dispatch, getState }) => {
// 	try {
// 		if (data.callFrom === 'SalesAI') {
// 			dispatch(setSalesAIproductLoader(true));
// 		} else if (data.callFrom === 'CatalogSales') {
// 			dispatch(setProductsLoader(true));
// 		}
// 		const res: any = await salesService.getProducts(data);
// 		dispatch(setCallFrom(data.callFrom));

// 		if (res.data.response.status.toLowerCase() === 'success') {
// 			if (data.callFrom === 'SalesAI') {
// 				dispatch(setSalesAIproductLoader(false));
// 			} else if (data.callFrom === 'CatalogSales') {
// 				dispatch(setProductsLoader(false));
// 			}
// 			return { fetchedData: res.data, callFrom: data.callFrom };
// 		}
// 		return rejectWithValue(
// 			JSON.stringify({
// 				error: 'Something went wrong! Try again later',
// 			})
// 		);
// 	} catch (error: any) {
// 		const response = error.response;
// 		return rejectWithValue(JSON.stringify(response.data.message));
// 	}
// });

// export const getFFProducts = createAsyncThunk<
// 	ProductsFFRes,
// 	ProductFFReq,
// 	{ rejectValue: string }
// >('/getFFProducts', async (data, { rejectWithValue, dispatch, getState }) => {
// 	try {
// 		if (data.callFrom === 'SalesAI') {
// 			dispatch(setSalesAIproductLoader(true));
// 		} else if (data.callFrom === 'CatalogSales') {
// 			dispatch(setProductsLoader(true));
// 		}
// 		const res: any = await salesService.getFFProducts(data);
// 		if (res.data.response.status.toLowerCase() === 'success') {
// 			if (data.callFrom === 'SalesAI') {
// 				dispatch(setSalesAIproductLoader(false));
// 			} else if (data.callFrom === 'CatalogSales') {
// 				dispatch(setProductsLoader(false));
// 			}
// 			return res.data;
// 		}

// 		return rejectWithValue(
// 			JSON.stringify({
// 				error: 'Something went wrong! Try again later',
// 			})
// 		);
// 	} catch (error: any) {
// 		const response = error.response;
// 		return rejectWithValue(JSON.stringify(response.data.message));
// 	}
// });

// export const getWooComProducts = createAsyncThunk<
// 	WooCommRes,
// 	WooCommReq,
// 	{ rejectValue: string }
// >('/getWooComProducts', async (data, { rejectWithValue, dispatch }) => {
// 	try {
// 		if (data.callFrom === 'SalesAI') {
// 			dispatch(setSalesAIproductLoader(true));
// 		} else if (data.callFrom === 'CatalogSales') {
// 			dispatch(setProductsLoader(true));
// 		}
// 		const res: any = await salesService.getFFProducts(data);
// 		if (res.data.response.status.toLowerCase() === 'success') {
// 			if (data.callFrom === 'SalesAI') {
// 				dispatch(setSalesAIproductLoader(false));
// 			} else if (data.callFrom === 'CatalogSales') {
// 				dispatch(setProductsLoader(false));
// 			}
// 			return res.data;
// 		}

// 		return rejectWithValue(
// 			JSON.stringify({
// 				error: 'Something went wrong! Try again later',
// 			})
// 		);
// 	} catch (error: any) {
// 		const response = error.response;
// 		return rejectWithValue(JSON.stringify(response.data.message));
// 	}
// });

export const commerceLinkStatus = createAsyncThunk<
	CommerceRes,
	any,
	{ rejectValue: string }
>(
	'/commerceLinkStatus',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await salesService.commerceLinkStatus(data);
			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({
					error: 'Something went wrong! Try again later',
				})
			);
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(JSON.stringify(response.data.message));
		}
	}
);

// export const getshopifyProductData = createAsyncThunk<
// 	any,
// 	GetProductDetailsCS,
// 	{ rejectValue: string }
// >(
// 	'/getshopifyProductData',
// 	async (data, { rejectWithValue, dispatch, getState }) => {
// 		try {
// 			const response = await salesService.getProductIdDetails(data);
// 			if (response.data) {
// 				return response.data;
// 			}
// 			return rejectWithValue(
// 				JSON.stringify({
// 					error: 'Something went wrong! Try again later',
// 				})
// 			);
// 		} catch (error: any) {
// 			const response = error.response;
// 			return rejectWithValue(JSON.stringify(response.data.message));
// 		}
// 	}
// );
//search products
export const getQueriedProducts = createAsyncThunk<
	SearchProductRes,
	SearchProductReq,
	{ rejectValue: string }
>(
	'/getQueriedProducts',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await salesService.getSearchedProducts(data);
			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({
					error: 'Something went wrong! Try again later',
				})
			);
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(JSON.stringify(response.data.message));
		}
	}
);
export const getRemovedproducts = createAsyncThunk<
	any,
	modifyremoveDetails,
	{ rejectValue: string }
>('/cart/getDetails', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response: any = await salesService.removedCartDetails(data);

		if (response.data) {
			let removedproduct = response.data.response.data.response.data.data.cart;

			const { sales } = getState() as { sales: SalesState };

			dispatch(setMsgsLoading('done'));

			// dispatch(
			// 	setMsgsArray([...sales.msgsArray,
			// 	{ dataproducts: removedproduct, content: 'view-cart', role: 'assistant' },])
			// );
			dispatch(
				setMsgsArray([
					...sales.msgsArray,
					{ content: 'Item removed from cart', role: 'assistant' },
				])
			);

			return removedproduct;
		}
		return rejectWithValue(
			JSON.stringify({
				error: 'Something went wrong! Try again later',
			})
		);
	} catch (error) {
		return rejectWithValue(
			JSON.stringify({
				error: 'Something went wrong! Try again later',
			})
		);
	}
});

export const getChatAPI = createAsyncThunk<
	any,
	inputChatReq,
	{ rejectValue: string }
>('/chat/message', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		let response: any;
		if (
			window.origin.indexOf('preprod') > -1 ||
			window.origin.indexOf('app') > -1
		) {
			response = await salesService.getChatAPI(data);
		} else {
			response = await salesService.getChatDEVAPI(data);
		}
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({
				error: 'Something went wrong! Try again later',
			})
		);
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(JSON.stringify(response.data.message));
	}
});

export const getSalesBotId = createAsyncThunk<
	any,
	{ tenantId: number | null; type?: string; ca_vsr_type?: string },
	{ rejectValue: string }
>('/getSalesBotId', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response: any = await salesService.getSalesBot(data);
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({
				error: 'Something went wrong! Try again later',
			})
		);
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(JSON.stringify(response.data.message));
	}
});

const initialState: SalesState = {
	productsFF: [],
	products: [],
	nextPageV: '',
	endCursor: '', //for next products
	startCursor: '', //for prev products
	hasNextPage: false,
	msgsArray: [
		{
			content:
				'Hello, how may I help you? We have wide range of beauty products to select from.',
			role: 'assistant',
		},
	],
	msgsLoading: '',
	productsLoader: false,
	salesAIproductLoader: false,
	descriptionProductLoader: false,
	callFrom: '',
	commerceStatus: '',
	commerceLoader: '',
	productDetails: {},
	shopifyCall: '',
	chatInput: '',
	getCartDetails: {},
	searchedValue: '',
	searchingLoader: false,
	searchedProducts: [],
	totalProducts: [],
	totalSearchedProducts: [],
	allSetModal: '',
	afterSalesBot: [],
	storeCartDetails: {},
	specificProduct: {},
	chatData: {},
	conversationId: '0',
	ecomType: '',
};

const salesSlice = createSlice({
	name: 'sales',
	initialState,
	reducers: {
		setMsgsArray: (state, action) => {
			state.msgsArray = action.payload;
		},
		resetConversationId: (state, action) => {
			state.conversationId = action.payload;
		},
		resetMsgsArray: (state) => {
			// state.msgsArray = [
			// 	{
			// 		content:
			// 			'Hello, how may I help you? We have wide range of beauty products to select from.',
			// 		role: 'assistant',
			// 	},
			// ];
			state.msgsArray = [];
		},
		setMsgsLoading: (state, action) => {
			state.msgsLoading = action.payload;
		},
		setProducts: (state, action) => {
			state.products = action.payload;
		},
		setCallFrom: (state, action) => {
			state.callFrom = action.payload;
		},
		resetSalesAIproductLoader: (state) => {
			// state.salesAIproductLoader = '';
		},
		resetProductsLoader: (state) => {
			// state.productsLoader = '';
		},
		resetCommerceLoader: (state) => {
			state.commerceLoader = '';
		},
		setSalesAIproductLoader: (state, action) => {
			state.salesAIproductLoader = action.payload;
		},
		setdescriptionProductLoader: (state, action) => {
			state.descriptionProductLoader = action.payload;
		},
		setProductsLoader: (state, action) => {
			state.productsLoader = action.payload;
		},
		setProductDetails: (state, action) => {
			state.productDetails = action.payload;
		},
		setShopifyCall: (state, action) => {
			state.shopifyCall = action.payload;
		},
		setChatInput: (state, action) => {
			state.chatInput = action.payload;
		},
		setCartDetails: (state, action) => {
			state.getCartDetails = action.payload;
		},
		setSearchedValue: (state, action) => {
			state.searchedValue = action.payload;
		},
		setSearchingLoader: (state, action) => {
			state.searchingLoader = action.payload;
		},
		setSearchedProducts: (state, action) => {
			state.searchedProducts = action.payload;
		},
		setTotalProducts: (state, action) => {
			state.totalProducts = action.payload;
		},
		setTotalSearchedProducts: (state, action) => {
			state.totalSearchedProducts = action.payload;
		},
		setAllSetModal: (state, action) => {
			state.allSetModal = action.payload;
		},
		setViewCartDetails: (state, action) => {
			state.storeCartDetails = action.payload;
		},
		setEcomType: (state, action) => {
			state.ecomType = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder
			// .addCase(getProducts.pending, (state) => {})
			// .addCase(getProducts.fulfilled, (state, action) => {
			// 	state.products = action.payload.fetchedData.response.data.products;
			// 	state.endCursor = action.payload.fetchedData.response.data.endCursor;
			// 	state.startCursor =
			// 		action.payload.fetchedData.response.data.startCursor;
			// 	state.hasNextPage =
			// 		action.payload.fetchedData.response.data.hasNextPage;
			// })
			.addCase(getProductsCS.fulfilled, (state, action) => {
				state.products = action.payload.response.data.products;
				state.hasNextPage = action.payload.response.data.hasNextPage;
				state.nextPageV = action.payload.response.data.nextPage;
			})
			.addCase(getProductDetailsCS.pending, (state) => {
				state.descriptionProductLoader = true;
			})
			.addCase(getProductDetailsCS.fulfilled, (state, action) => {
				state.specificProduct = action.payload.response;
				state.descriptionProductLoader = false;
			})
			.addCase(getProductDetailsCS.rejected, (state) => {
				state.descriptionProductLoader = false;
			})

			// .addCase(getFFProducts.fulfilled, (state, action) => {
			// 	if (action.payload.response.data.length === 0) {
			// 		state.hasNextPage = false;
			// 	} else {
			// 		state.hasNextPage = true;
			// 	}
			// 	state.products = action.payload.response.data;
			// })
			// .addCase(getWooComProducts.fulfilled, (state, action) => {
			// 	if (action.payload.response.data.products.length === 0) {
			// 		state.hasNextPage = false;
			// 	} else {
			// 		state.hasNextPage = true;
			// 	}
			// 	state.products = action.payload.response.data.products;
			// })
			// .addCase(getshopifyProductData.pending, (state) => {
			// 	state.descriptionProductLoader = true;
			// })
			// .addCase(getshopifyProductData.fulfilled, (state, action) => {
			// 	state.specificProduct = action.payload.response;
			// 	state.descriptionProductLoader = false;
			// })
			// .addCase(getshopifyProductData.rejected, (state) => {
			// 	state.descriptionProductLoader = false;
			// })
			.addCase(commerceLinkStatus.pending, (state) => {
				state.commerceLoader = 'loading';
			})
			.addCase(commerceLinkStatus.fulfilled, (state, action) => {
				state.commerceLoader = 'success';
				state.commerceStatus =
					action.payload.status === 'success' ? 'linked' : 'notlinked';
			})
			.addCase(commerceLinkStatus.rejected, (state, action) => {
				state.commerceLoader = 'failed';
			})
			.addCase(getChatAPI.fulfilled, (state, action) => {
				state.chatData = action.payload;
				state.conversationId = state.chatData.id;
				// console.log(state.chatData.id);
			})
			.addCase(getQueriedProducts.pending, (state) => {
				state.searchingLoader = true;
			})
			.addCase(getQueriedProducts.fulfilled, (state, action) => {
				state.searchingLoader = false;
				state.searchedProducts = action.payload.response.data;
				// console.log('ase', state.searchedProducts);
			})
			.addCase(getQueriedProducts.rejected, (state, action) => {
				state.searchingLoader = false;
			})
			.addCase(getSalesBotId.pending, (state) => {})
			.addCase(getSalesBotId.fulfilled, (state, action) => {
				state.afterSalesBot = action.payload.botList;
				saveState('botDetails', action.payload.botList[0]);
				saveState('salesBotId', action.payload.botList[0].encBotId);
				saveState('botId', action.payload.botList[0].botId);
			})
			.addCase(getSalesBotId.rejected, (state, action) => {});
	},
});

export const {
	resetMsgsArray,
	setMsgsArray,
	setMsgsLoading,
	setProducts,
	setCallFrom,
	resetSalesAIproductLoader,
	resetProductsLoader,
	resetCommerceLoader,
	setSalesAIproductLoader,
	setProductsLoader,
	setProductDetails,
	setShopifyCall,
	setChatInput,
	setCartDetails,
	setSearchedValue,
	setSearchingLoader,
	setSearchedProducts,
	setTotalProducts,
	setTotalSearchedProducts,
	setAllSetModal,
	setViewCartDetails,
	setdescriptionProductLoader,
	resetConversationId,
	setEcomType,
} = salesSlice.actions;

export default salesSlice.reducer;
