import {
	AddExistingUserData,
	AddNewUserAsAdmin,
	AddNewUserData,
	GetUsers,
	RemoveUserData,
} from './modal';
import DataFetchAPI from '../../utils/Api';

const getallUsers = async (data: GetUsers) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		`/authentication/${data.tenantId}/user`
	);
};

const getUserSuggestion = async (data: GetUsers) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		'/rba/v1/getUserSuggestion',
		{
			params: data,
		}
	);
};

const addNewUser = async (data: AddNewUserData) => {
	return await DataFetchAPI(false, true, 'Bearer').post(
		`/authentication/user`,
		data
	);
};

const addTenantAdmin = async (data: AddNewUserAsAdmin) => {
	return await DataFetchAPI().post('/rba/v1/addTenantAdmin', data);
};

const addExistingUser = async (data: AddExistingUserData) => {
	return await DataFetchAPI().post('/rba/v1/addExistingUser', data);
};

const removeFromBotTeam = async (data: RemoveUserData) => {
	return await DataFetchAPI(false, true, 'Bearer').put(
		'/rba/v1/removeFromBotTeam',
		data
	);
};

const removeUserFromTeam = async (data: {
	tenantId: number;
	userId: number;
}) => {
	return await DataFetchAPI().delete(
		`/authentication/${data.tenantId}/user/${data.userId}`
	);
};

const userService = {
	getallUsers,
	getUserSuggestion,
	addNewUser,
	removeFromBotTeam,
	addExistingUser,
	addTenantAdmin,
	removeUserFromTeam,
};

export default userService;
