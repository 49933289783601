import { Agent } from 'http';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getRealTimeAnalyticsLiveChat } from '../../../../Features/Analytics/analyticsSlice';
import { teamsSlice } from '../../../../Features/teams/TeamsSlice';
import './Realtime.scss';
type Props = {
	startDate: any;
	endDate: any;
	utcstartDate: any;
	utcendDate: any;
	ongoingCardList: {
		title: string;
		count: number;
		defnitions: string;
	}[];
};

const Realtime = ({ startDate }: Props) => {
	const [newOngoingCardList, setNewOngoingCardList] = useState<any[]>([]);
	const list = ['Agents Online', 'Conversations with agents', 'Waiting users'];
	const dispatch = useDispatch<any>();
	const [loader, setLoader] = useState<Boolean>(true);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const { getRealTimeAnalyticsLiveChatData, selectedDates, calendarstatus } =
		useSelector((state: RootState) => state.analytics);

	const setDataDisplay = (response: any) => {
		// console.log(response);
		var newState = list.map((card: string) => {
			switch (card) {
				case 'Agents Online':
					return {
						title: card,
						count: response.ResData.AgentsOnline,
					};
				case 'Conversations with agents':
					return {
						title: card,
						count: response.ResData.TotalHandedOffChats,
					};
				case 'Waiting users':
					return {
						title: card,
						count: response.ResData.ChatsInQueue,
					};
			}
		});
		setNewOngoingCardList(newState);
		setLoader(false);
	};
	const setData = () => {
		if (Object.keys(getRealTimeAnalyticsLiveChatData).length) {
			setDataDisplay(getRealTimeAnalyticsLiveChatData);
		}
	};

	const getRealTimeData = () => {
		tenantId &&
			dispatch(
				getRealTimeAnalyticsLiveChat({
					utcstartDate: selectedDates[0],
					utcendDate: selectedDates[1],
					tenantId: tenantId,
				})
			);
	};

	useEffect(() => {
		if (calendarstatus) {
			getRealTimeData();
		}

		const interval = setInterval(() => {
			getRealTimeData();
		}, 5000);

		return () => clearInterval(interval);
	}, [selectedDates]);

	useEffect(() => {
		setData();
	}, [getRealTimeAnalyticsLiveChatData]);
	return (
		<div>
			<h5 className='header-main-real'> Realtime </h5>
			<div>
				{loader ? (
					<div className='loaders'>Loading Data</div>
				) : (
					<div className='stats-wrapper-real stats-wrapper'>
						{newOngoingCardList.map((card: any, index: number) => {
							return (
								<div key={index} className='stat-real'>
									<div className='title'>{card.title}</div>
									<div className='count'>{card.count}</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};
export default Realtime;
