import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Input from '../Input';

import './AutoComplete.scss';
import Chip2 from '../Chip2';
import { Box, CircularProgress } from '@mui/material';

type Props = {
	multiple?: boolean;
	validator?: (value: any) => boolean;
	label?: string;
	value: any;
	options: any[];
	valueKey: string;
	labelKey: string;
	iconKey?: string;
	error?: boolean;
	helperText?: string | undefined;
	errorText?: string | undefined;
	onChange: React.Dispatch<React.SetStateAction<any>>;
	onBlur?: (arg0: string | null) => void;
	isLoading?: boolean;
	placeholder?: string;
	showIcon?: boolean;
	onScrollBottom?: (arg0: string | null) => void;
	classNameOndropLI?: string;
	disabled?: boolean;
};

const AutoComplete = ({
	multiple = false,
	validator,
	label,
	value,
	options,
	valueKey = 'value',
	labelKey = 'name',
	iconKey,
	onChange,
	onScrollBottom,
	onBlur,
	error = false,
	helperText = undefined,
	errorText = undefined,
	isLoading = false,
	placeholder,
	showIcon = true,
	classNameOndropLI = '',
	disabled,
}: Props) => {
	const [focused, setFocused] = useState(-1);
	const [text, setText] = useState(helperText);
	const [isError, setIsError] = useState(error);

	useEffect(() => {
		return () => setFocused(-1);
	}, []);

	useEffect(() => {
		if ((error || isError) && errorText && errorText?.length > 0) {
			setText(errorText);
		} else {
			setText(helperText);
		}
	}, [error, isError]);

	useEffect(() => {
		if (multiple && validator) {
			const err = value.filter((val: any) => validator(val[valueKey]));
			if (err.length > 0) {
				setIsError(true);
			} else {
				setIsError(false);
			}
		}
	}, [value]);

	return (
		<div>
			<Autocomplete
				disabled={disabled}
				disableClearable
				ListboxProps={{
					onScroll: (event: React.SyntheticEvent) => {
						const listboxNode = event.currentTarget;
						if (
							Math.floor(listboxNode.scrollTop) + listboxNode.clientHeight ===
							listboxNode.scrollHeight
						) {
							onScrollBottom && onScrollBottom('asd');
						}
						// if (
						// 	listboxNode.scrollTop + listboxNode.clientHeight ===
						// 	listboxNode.offsetHeight
						// ) {
						// 	console.log('asdasd');
						//}
					},
				}}
				clearIcon={false}
				className={multiple ? 'multiple' : ''}
				multiple={multiple}
				filterSelectedOptions={multiple}
				forcePopupIcon={!multiple}
				freeSolo={multiple}
				options={options}
				getOptionLabel={(option) => option[labelKey]}
				isOptionEqualToValue={(option, value) =>
					option[valueKey] === value[valueKey]
				}
				value={value}
				onChange={(event: any, newValue: any | null) => {
					setFocused(-1);
					if (multiple) {
						const last = newValue[newValue.length - 1];
						if (typeof last === 'string') {
							const newOption: any = {};
							newOption[valueKey] = last;
							newOption[labelKey] = last;
							onChange([...value, newOption]);
						} else {
							onChange(newValue);
							onBlur && onBlur(newValue);
						}
					} else {
						onChange(newValue ? newValue : null);
						onBlur && onBlur(newValue ? newValue[valueKey] : null);
					}
				}}
				blurOnSelect={!multiple}
				renderInput={(params) => (
					<Input
						className={`${!showIcon ? 'showIcon' : ''}`}
						{...params}
						label={value ? label : ''}
						placeholder={placeholder ?? label}
						error={error || isError}
						onBlur={() => setFocused(-1)}
						onKeyDown={(event) => {
							const key = event.key;
							if (multiple) {
								if (key === 'ArrowLeft') {
									setFocused((prev) =>
										prev === -1 ? value.length - 1 : prev - 1
									);
								} else if (key === 'ArrowRight') {
									setFocused((prev) => (prev === value.length ? 0 : prev + 1));
								} else if (key === 'Delete' || key === 'Backspace') {
									if (focused > -1 && focused < value.length) {
										event.preventDefault();
										event.stopPropagation();
										onChange(
											value.filter((v: any, index: number) => index !== focused)
										);
										setFocused(-1);
									}
								}
							}
						}}
						InputProps={{
							...params.InputProps,
							startAdornment: iconKey ? <>{iconKey}</> : '',
							endAdornment: (
								<>
									{showIcon ? (
										<>
											<div style={{ color: '#3b4fd0' }}>
												{isLoading && (
													<CircularProgress color='inherit' size={16} />
												)}
											</div>
											{params.InputProps.endAdornment}
										</>
									) : null}
								</>
							),
						}}
					/>
				)}
				renderOption={(props, option) => (
					<Box
						component='li'
						className={`${classNameOndropLI} MuiAutocomplete-option`}
						{...props}>
						{iconKey && option[iconKey]}
						{option['icon'] && <div>{option['icon']}</div>}
						<p className='body1'>{option[valueKey]}</p>
					</Box>
				)}
				renderTags={(values, getTagProps) =>
					values.map((option, index) => {
						const err = (validator && validator(option[valueKey])) ?? false;
						let clasName = focused === index ? 'focused' : '';
						clasName += err ? ' error' : '';
						setIsError(err);

						return (
							<Chip2
								label={option[labelKey]}
								{...getTagProps({ index })}
								onClick={() => setFocused(index)}
								className={clasName}
							/>
						);
					})
				}
			/>
			{text?.length && (
				<p
					style={{ marginTop: '2px' }}
					className={`caption helper-text${error || isError ? ' error' : ''}`}>
					{text}
				</p>
			)}
		</div>
	);
};
export default AutoComplete;
