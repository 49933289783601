import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../app/store';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import {
	reset,
	resetCreateInstaWatsapp,
	resetOnBoardWabaStatus,
	resetUpdateNumbers,
} from '../../../../../Features/WhatsApp-BSP/auth/authSlice';

const SetUpComplateModalWaba = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const state = location.state as { value: any };
	let path = location.pathname;
	let pathArr = path.split('/');
	useEffect(() => {
		return () => {
			dispatch(resetUpdateNumbers());
			dispatch(resetCreateInstaWatsapp());
			dispatch(resetOnBoardWabaStatus());
		};
	});
	return (
		<Modal
			header={`Setup Complete`}
			//empty funciton for misbehaving if we click outside modal
			onClose={() => { }}
			actionButtons={
				<Button
					onClick={() => {
						if (pathArr) {
							if (pathArr[2] === 'launch') {
								navigate('/app/launch');
							} else if (pathArr[2] === 'welcome') {
								navigate('/app/welcome');
							}
						}
						// setCreate(false);
					}}>
					Done
				</Button>
			}>
			<div>
				<p className='body1'>
					Congratulations! You have successfully setup the WhatsApp profile{' '}
					{state.value.name} ({state.value.phoneNumber}) with Flyfish as the
					WhatsApp Business Solution Provider (BSP)
				</p>
				<p className='body1'>
					You can now use different Flyfish features with this WhatsApp number
					such as messaging, running campaigns, and doing conversational ads and
					sales.
				</p>
			</div>
		</Modal>
	);
};

export default SetUpComplateModalWaba;
