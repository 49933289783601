import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import InteractiveButton from '../../../Components/Button/InteractiveButton';
import Header2 from '../../../Components/Header2';
import {
	setRefreshBot,
	setTestAndOpenBot,
	trainBot,
} from '../../../Features/builder/builderSlice';
import {
	createBotConfigurations,
	createSecondary,
	getBotConfigurations,
	resetFaqbotconfigloader,
	resetFaqconfigloader,
} from '../../../Features/configure/configureSlice';
import { loadState } from '../../../utils/utils';
import ChatBot from '../../BotBuilder/ChatBot';
import {
	CloseIcon,
	RefreshIcon,
	TestIcon,
} from '../../BotBuilder/ChatBot/Icons';
import '../../Configure/Configure.scss';
import CallToAction from './CallToAction';
import Languages from './Languages';
import './FAQConfiguration.scss';
import { set } from '../../../Features/message/messageSlice';

function Configure() {
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const botDetails: any = loadState('botDetails');
	const dispatch = useAppDispatch();
	const [buttonLoader, setButtonLoader] = useState(false);
	const {
		chatwithagent,
		raiseaticket,
		callcustomercare,
		isMultiLingualEnabled,
		translationApiKey,
		availableLanguages,
		dropdownValue,
		faqconfigloader,
		faqbotconfigloader,
	} = useSelector((state: RootState) => state.configure);

	useEffect(() => {
		dispatch(getBotConfigurations({ botId: botDetails.encBotId }));
	}, []);

	useEffect(() => {
		if (faqconfigloader === 'loading') {
			setButtonLoader(true);
			dispatch(resetFaqconfigloader());
		} else if (faqconfigloader === 'success') {
			dispatch(set('Key Validated Successfully'));
			setButtonLoader(false);
			dispatch(resetFaqconfigloader());
			let selectedLanguages = dropdownValue.map(
				(lang: { label: string; value: string }) => lang.value.toLowerCase()
			);
			let detailedLanguages = availableLanguages.filter((lang) => {
				return selectedLanguages.indexOf(lang.language.toLowerCase()) > -1;
			});
			let selectedisocodes = selectedLanguages.map((lang: string) => {
				return availableLanguages.filter((lang1) =>
					lang1.language.toLowerCase() === lang ? lang1.isocode : null
				);
			});
			let isocodes = selectedisocodes.map((code: any) => code[0].isocode);
			let actionArr = [];
			chatwithagent[0] && actionArr.push('Chat with Agent');
			raiseaticket[0] && actionArr.push('Raise a Ticket');
			callcustomercare[0] && actionArr.push('Call Customer Care');
			dispatch(
				createBotConfigurations({
					detailedLanguages: JSON.stringify(detailedLanguages),
					isMultiLingualEnabled: isMultiLingualEnabled[0],
					tenantId: tenantId,
					botId: botDetails.botId,
					callToActionButtons: actionArr,
					languages: isocodes.toString(),
				})
			);
		} else if (faqconfigloader === 'failed') {
			dispatch(set(`Couldn't Save !! Something Went Wrong`));
			setButtonLoader(false);
			dispatch(resetFaqconfigloader());
		}
	}, [faqconfigloader]);

	useEffect(() => {
		if (faqbotconfigloader === 'loading') {
			setButtonLoader(true);
			dispatch(resetFaqbotconfigloader());
		} else if (faqbotconfigloader === 'success') {
			dispatch(set(`Configuration saved successfully`));
			setButtonLoader(false);
			dispatch(resetFaqbotconfigloader());
		} else if (faqbotconfigloader === 'failed') {
			dispatch(set(`Couldn't Save !! Something Went Wrong`));
			setButtonLoader(false);
			dispatch(resetFaqbotconfigloader());
		}
	}, [faqbotconfigloader]);

	const { isTraining, testAndOpenBot } = useSelector(
		(state: RootState) => state.builder
	);
	const getActionButtons = () => {
		return (
			<>
				{!testAndOpenBot ? (
					<Button
						buttonType='light'
						startIcon={<TestIcon />}
						className='bot-train-btn'
						onClick={() => {
							if (!isTraining) {
								botDetails.botId &&
									dispatch(trainBot({ botId: botDetails.botId }));
							}
							dispatch(setTestAndOpenBot(true));
						}}>
						Test
					</Button>
				) : (
					<div className='test-train-wrapper'>
						<h6 style={{ marginLeft: '16px' }}>Test</h6>
						<div className='refresh-close'>
							<div
								className={`bot-train `}
								onClick={() => {
									if (!isTraining) {
										botDetails.botId &&
											dispatch(trainBot({ botId: botDetails.botId }));
										dispatch(setRefreshBot(true));
										// @ts-ignore
										if (window.clearSession) {
											// @ts-ignore
											window.clearSession();
										}
									}
								}}>
								<InteractiveButton
									title='Refresh'
									disabled={isTraining}
									icon={<RefreshIcon opacity='0.6' />}
								/>
							</div>
							<div className='close-btn'>
								<InteractiveButton
									title='Close'
									onClick={() => {
										dispatch(setTestAndOpenBot(false));
										dispatch(setRefreshBot(false));
									}}
									icon={<CloseIcon />}
								/>
							</div>
						</div>
					</div>
				)}
			</>
		);
	};

	return (
		<div className='ff-faq-configure'>
			<Header2
				leftComponent={<></>}
				breadcrumbs={[
					{
						title: 'Ingest',
						path: `app/catalog/faq/design/build/FAQs`,
					},
					{
						title: 'Configure',
						path: `app/catalog/faq/design/build/configure`,
					},
					{
						title: 'Launch',
						path: `app/launch`,
					},
				]}
				activeButtons={getActionButtons()}
			/>
			<div
				className={`faq-configure-container ${
					testAndOpenBot ? 'bot-opened' : ''
				}`}>
				<div>
					<h4>Configure</h4>
					<Languages />
					<CallToAction />
				</div>
				<div className='save'>
					<Button
						className='call-to-action'
						loading={buttonLoader}
						size='large'
						onClick={() => {
							if (isMultiLingualEnabled[0]) {
								dispatch(
									createSecondary({
										translationApiKey: translationApiKey,
										isMultiLingualEnabled: 'true',
										tenantId: tenantId,
										botId: botDetails.botId,
									})
								);
							} else {
								let selectedLanguages = dropdownValue.map(
									(lang: { label: string; value: string }) =>
										lang.value.toLowerCase()
								);
								let detailedLanguages = availableLanguages.filter((lang) => {
									return (
										selectedLanguages.indexOf(lang.language.toLowerCase()) > -1
									);
								});
								let selectedisocodes = selectedLanguages.map((lang: string) => {
									return availableLanguages.filter((lang1) =>
										lang1.language.toLowerCase() === lang ? lang1.isocode : null
									);
								});
								let isocodes = selectedisocodes.map(
									(code: any) => code[0].isocode
								);
								let actionArr = [];
								chatwithagent[0] && actionArr.push('Chat with Agent');
								raiseaticket[0] && actionArr.push('Raise a Ticket');
								callcustomercare[0] && actionArr.push('Call Customer Care');
								dispatch(
									createBotConfigurations({
										detailedLanguages: JSON.stringify(detailedLanguages),
										isMultiLingualEnabled: isMultiLingualEnabled[0],
										tenantId: tenantId,
										botId: botDetails.botId,
										callToActionButtons: actionArr,
										languages: isocodes.toString(),
									})
								);
							}
						}}>
						Save Changes
					</Button>
				</div>
			</div>
			{/* 
			<ChatBot
				active={testAndOpenBot}
				encBotId={botDetails.encBotId}
				onClose={() => {
					dispatch(setTestAndOpenBot(false));
				}}
			/> */}
		</div>
	);
}

export default Configure;
