import AppearanceInput from '../AppearanceInput';
import Slider from '../../../../assets/images/Slider.png';

import './Background.scss';
import { UploadImageEdit } from '../../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { FFLoader } from '../../../../assets/Icons/Loader';

type Props = {
	background: string;
	value1: string;
	value2: Array<string>;
	onValueChange1: (arg0: string) => void;
	onValueChange2: Array<(arg0: string) => void>;
};

const Background = ({
	background,
	value1,
	value2,
	onValueChange1,
	onValueChange2,
}: Props) => {
	const navigate = useNavigate();
	const { backgroundFile, appearanceBackgroundLoader } = useSelector(
		(state: RootState) => state.configure
	);
	return (
		<div className='ff-background'>
			{background === 'color' ? (
				<>
					<div
						className='color-box-input'
						style={{
							backgroundColor: value1,
							maxWidth: '56px',
							maxHeight: '32px',
							borderRadius: '4px',
						}}>
						<AppearanceInput
							className='color-box'
							type='color'
							value={value1}
							onValueChange={onValueChange1}
						/>
						<div className='background-solid-color-value'>{value1}</div>
					</div>
				</>
			) : null}
			{background === 'gradient' ? (
				<div className='color-range-color'>
					<div
						className='color-box-input-start'
						style={{
							backgroundColor: value2[0],
							maxWidth: '32px',
							maxHeight: '32px',
							borderRadius: '4px',
						}}>
						<div className='background-gradient-start-color-value'>
							{value2[0]}
						</div>
						<AppearanceInput
							className='color-box'
							type='color'
							value={value2[0]}
							onValueChange={onValueChange2[0]}
						/>
					</div>

					<div
						className='range-input'
						style={{
							background: `linear-gradient(to right, ${value2[0]} 0%, ${value2[2]} 100%), url(${Slider})`,
							backgroundBlendMode: 'multiply',
							minHeight: '6px',
							minWidth: '172px',
						}}></div>

					<div
						className='color-box-input-stop'
						style={{
							backgroundColor: value2[2],
							maxWidth: '32px',
							maxHeight: '32px',
							borderRadius: '4px',
						}}>
						<AppearanceInput
							className='color-box'
							type='color'
							value={value2[2]}
							onValueChange={onValueChange2[2]}
						/>
						<div className='background-gradient-stop-color-value'>
							{value2[2]}
						</div>
					</div>
				</div>
			) : null}
			{background === 'image' ? (
				// <UploadImage uploadTo='background' />
				<div className='background-image'>
					{appearanceBackgroundLoader ? (
						<FFLoader width='40' />
					) : (
						backgroundFile !== '' && (
							<>
								<img src={backgroundFile} alt='background' />
								<span
									onClick={() =>
										navigate('add-image', {
											state: { value: 'Background Image' },
										})
									}>
									<UploadImageEdit />
								</span>
							</>
						)
					)}
				</div>
			) : null}
		</div>
	);
};

export default Background;
