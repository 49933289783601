import {
	AddNewUserAsAdmin,
	FreshChatLinkReq,
	FreshChatStatusReq,
	FreshChatUnlinkReq,
	GetAllUsersMyhub,
	GetBotAssigned,
	GETSESSIONCOUNT,
	RemoveFromBotStudio,
	SETUP,
	UPDATETENANT,
	UpdateUserRoleMyhub,
} from './modal';
import DataFetchAPI from '../../utils/Api';
import DataFetchShopifyAPI from '../../utils/ShopifyApi';

const getAllUsersMyhub = async (data: GetAllUsersMyhub) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		'/rba/v1/getAllUsersMyhub',
		{
			params: data,
		}
	);
};

const getBotAssigned = async (data: GetBotAssigned) => {
	return await DataFetchAPI().get('/rba/v1/getBotAssigned', { params: data });
};

const updateUserRoleMyhub = async (data: UpdateUserRoleMyhub) => {
	return await DataFetchAPI().put('/rba/v1/updateUserRoleMyhub', data);
};

const removeFromBotStudio = async (data: RemoveFromBotStudio) => {
	return await DataFetchAPI().put('/rba/v1/removeFromBotStudio', data);
};

const setup = async (data: SETUP) => {
	return await DataFetchAPI(
		false,
		true,
		'Bearer',
		'application/json',
		false
	).post(
		`/crm/tenant/${data.tenantId}/link`,

		{
			crmId: data.crmId,
			config: data.config,
		}
	);
};

const linkedEmail = async (data: number) => {
	return await DataFetchAPI(
		false,
		true,
		'Bearer',
		'application/json',
		false
	).get(`/crm/linked/${data}`);
};
const linkedStatus = async (data: number) => {
	return await DataFetchShopifyAPI().get(`/ecom/status?tenantId=${data}`);
};

const updateTenantProfile = async (data: UPDATETENANT) => {
	return await DataFetchAPI().put(`configuration/team/${data.id}`, data);
};

const getSessionCount = async (data: GETSESSIONCOUNT) => {
	return await DataFetchAPI(false, true, 'Bearer', 'application/json').get(
		'/session/getSessionCount',
		{ params: data }
	);
};

const addTenantAdmin = async (data: AddNewUserAsAdmin) => {
	return await DataFetchAPI().post('/rba/v1/addTenantAdmin', data);
};

const linkFreshchat = async (data: FreshChatLinkReq) => {
	return await DataFetchAPI().post('/freshchat/account/link', data);
	// false,
	// false,
	// 'Bearer',
	// 'application/json',
	// false,
	// false
};

const unlinkFreshchat = async (data: FreshChatUnlinkReq) => {
	return await DataFetchAPI().get('/freshchat/account/unlink', {
		// false,
		// false,
		// 'Bearer',
		// 'application/json',
		// false,
		// false
		params: data,
	});
};

const checkFreshchatStatus = async (data: FreshChatStatusReq) => {
	return await DataFetchAPI().get('/freshchat/account/check', {
		// false,
		// true,
		// 'Bearer',
		// 'application/json',
		// false,
		// false
		params: data,
	});
};

const crmsLinked = async (data: { tenantId: number }) => {
	return await DataFetchAPI().get(`/crm/tenant/${data.tenantId}/link`);
};

const crmUnlink = async (data: { tenantId: number; linkedCrmId: number }) => {
	return await DataFetchAPI().delete(
		`/crm/tenant/${data.tenantId}/link/${data.linkedCrmId}`
	);
};

const crmScopes = async (data: { crmId: number }) => {
	return await DataFetchAPI().get(`/crm/scope/${data.crmId}`);
};

const workflowDestination = async (data: {
	tenantId: number;
	workflow: string;
}) => {
	return await DataFetchAPI().get(`/crm/tenant/${data.tenantId}/link`, {
		params: { workflow: data.workflow },
	});
};

const teamsettingService = {
	getAllUsersMyhub,
	getBotAssigned,
	updateUserRoleMyhub,
	removeFromBotStudio,
	setup,
	linkedEmail,
	linkedStatus,
	updateTenantProfile,
	getSessionCount,
	addTenantAdmin,
	linkFreshchat,
	unlinkFreshchat,
	checkFreshchatStatus,
	crmsLinked,
	crmUnlink,
	crmScopes,
	workflowDestination,
};
export default teamsettingService;
