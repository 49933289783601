import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import Button from '../../../../Components/Button';
import Dropdown, {
	DropdownValue,
	DropdownValueProps,
} from '../../../../Components/Dropdown';
import { commonActionParams } from '../common';
import '../ActionsModal.scss';
import './SendCollectedValues.scss';
import Input from '../../../../Components/Input';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	addCRMAction,
	getCRMAction,
	getLinkedCRM,
	resetActionDetails,
} from '../../../../Features/builder/builderSlice';
import {
	DefaultConnection,
	GetAction,
} from '../../../../Features/builder/model';
import { useSelector } from 'react-redux';
import { getIconForNextAction } from '../common';
import builderService from '../../../../Features/builder/builderService';
import NextNewAction from '../NextNewAction';
import SnackBar from '../../../../Components/SnackBar';
import { getActionName } from '../../../../utils/utils';
import { set } from '../../../../Features/message/messageSlice';

const CRM_LIST: DropdownValueProps[] = [
	{
		value: '5',
		label: 'Email',
	},
	{
		value: '4',
		label: 'Google Sheets',
	},
	{
		value: '1',
		label: 'HubSpot',
	},
	{
		value: '2',
		label: 'Salesforce',
	},
	{
		value: '3',
		label: 'Zoho',
	},
	{
		value: '6',
		label: 'LeadSquared',
	},
];

const SendCollectedValues = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const context: any = useOutletContext();
	const state: any = location.state;
	const taskAction: GetAction = state.action;
	const [label, setLabel] = useState('Next Action');
	const [crmValue, setCrmValue] = useState(-1);
	const { actionDetails, botId, tenantId, actions, activeQuery } = useSelector(
		(state: RootState) => state.builder
	);
	const { user } = useSelector((state: RootState) => state.auth);
	const getId = (event: any) => {
		setCrmId(event);
		setCrmValue(event.value);
	};
	const nextActions = actions
		.filter(
			(action) =>
				action.task_ID !== taskAction.taskId &&
				action.taskHierarchyDTO[0] !== taskAction.taskId
		)
		.map((action) => ({
			value: action.task_ID.toString(),
			label: (
				<div className={`actions-label action${action.userResponseTypeID}`}>
					{getIconForNextAction(action.userResponseTypeID)}
					<p>{action.task_name}</p>
				</div>
			),
			labelValue: action.task_name,
		})) as DropdownValueProps[];

	nextActions.push({
		value: 'add new action',
		label: (
			<div className='actions-label new-action'>
				{getIconForNextAction(-1)}
				<p>New Action</p>
			</div>
		),
	});

	const [openNextActionType, setOpenNextActionType] = useState(false);
	const [nextActionTypeId, setNextActionTypeId] = useState(0);
	const [crmId, setCrmId] = useState<DropdownValue>(null);

	useEffect(() => {
		botId &&
			tenantId &&
			dispatch(
				getCRMAction({
					taskId: taskAction.taskId,
					botId,
					tenantId,
				})
			);

		tenantId && dispatch(getLinkedCRM({ tenantId }));

		return () => {
			dispatch(resetActionDetails());
		};
	}, []);

	useEffect(() => {
		actionDetails?.crmId &&
			setCrmId(
				CRM_LIST.filter((crm) => Number(crm.value) === actionDetails?.crmId)[0]
			);
		actionDetails?.crmId && setCrmValue(actionDetails.crmId);

		const next = actionDetails?.defaultConnection;
		if (next && actions) {
			let action = actions.filter(
				(action) => action.task_ID === next.childtaskId
			)[0];

			if (action) {
				let next_action: DropdownValue = {
					value: next.childtaskId.toString(),
					label: (
						<div className={`actions-label action${action.userResponseTypeID}`}>
							{getIconForNextAction(action.userResponseTypeID)}
							<p>{action.task_name}</p>
						</div>
					),
					labelValue: next.childTaskName ?? 'Untitled',
				};
				context.setNextAction(next_action);
			}
		}
	}, [actionDetails]);

	useEffect(() => {
		const next = context.nextAction as DropdownValueProps;
		if (next && next.value === 'add new action' && !openNextActionType) {
			setOpenNextActionType(true);
		}
	}, [context.nextAction]);
	useEffect(() => {
		let filterCrmId = crmId as DropdownValueProps;

		setCrmValue(filterCrmId ? Number(filterCrmId.value) : 0);
	}, [crmId, setCrmValue]);
	useEffect(() => {
		let next_action: DropdownValue = {
			value: label,

			label: (
				<div className={`actions-label action${nextActionTypeId}`}>
					{getIconForNextAction(nextActionTypeId)}

					<p>{label}</p>
				</div>
			),

			labelValue: label ?? 'Untitled',
		};

		context.setNextAction(next_action);
	}, [label]);

	const handleSave = async () => {
		if (actionDetails) {
			if (
				actions.filter(
					(action) =>
						action.task_name === context.actionName &&
						action.task_ID !== actionDetails.taskId
				).length > 0
			) {
				dispatch(set('Action name already exists'));
				// toast(<SnackBar type='danger'>Action name already exists</SnackBar>, {
				// 	autoClose: 3000,
				// });
				return false;
			}

			let next = context.nextAction as DropdownValueProps;
			let defaultConnection: DefaultConnection = {
				connectionId: 0,
				childtaskId: 0,
				conditionType: '',
				childTaskName: null,
			};

			if (nextActionTypeId !== 0 && botId && tenantId) {
				//call add action
				let position = actionDetails?.taskPosition.split('d');
				let task_position = '3000d4000';
				if (position) {
					task_position = `${parseInt(position[0]) + 110}d${position[1]}`;
				}
				const common = commonActionParams(actionDetails?.workflowId, false);
				const new_task = await builderService.addAction({
					...common,
					previousTaskId: 0,
					botId,
					tenantId,
					userResponseTypeID: nextActionTypeId,
					task_position,
					task_name: getActionName(actions, nextActionTypeId),
				});

				if (new_task.status === 201) {
					defaultConnection.childtaskId = Number(
						new_task.data.data.task.task_ID
					);
					defaultConnection.childTaskName = 'Untitled';
				}
			} else {
				if (next) {
					defaultConnection.childtaskId = Number(next.value);
					defaultConnection.childTaskName = next.labelValue ?? '';
				}
			}

			let crm = crmId as DropdownValueProps;
			let crm_id = crm ? Number(crm.value) : 0;

			activeQuery &&
				dispatch(
					addCRMAction({
						...actionDetails,
						taskName: context.actionName,
						currentNodeId: activeQuery.nodeId,
						defaultConnection,
						crmId: crm_id,
						userId: user?.userID,
						userResponseType: 'CRM',
						userResponseTypeId: 14,
						taskDescription: '',
					})
				);
		}

		navigate(-1);
	};

	return (
		<>
			<div className='body-content'>
				<div className='action-content'>
					<h6 className='send-data-heading'>Send data to</h6>
					<div className='item'>
						<Dropdown
							placeholder='Select*'
							value={crmId}
							options={CRM_LIST}
							// onChange={setCrmId}
							onChange={(e) => getId(e)}
						/>
					</div>

					{crmId && (
						<div className='item'>
							<div className='values-to-send'>
								<h6 className='valuesSend'>Values to be sent</h6>

								<p className='caption'>
									The values stored in the variable names given below will be
									sent to your selected platform and added to the field or
									column that the variable name is provided against. To send the
									values collected from the user in any Action, make sure that
									you store the value collected in that Action in an appropriate
									variable name from the table below.
								</p>

								<div className='table'>
									<div className='row'>
										<p className='headerValues'>Field</p>
										<p className='headerValues'>Variable to send</p>
									</div>
									<div className='row'>
										<p>Name</p>
										<p>
											<Input
												value='#CRMName'
												onChange={(event) => { }}
												disabled
											/>
										</p>
									</div>
									<div className='row'>
										<p>Email</p>
										<p>
											<Input
												value='#CRMEmail'
												onChange={(event) => { }}
												disabled
											/>
										</p>
									</div>
									<div className='row'>
										<p>Phone</p>
										<p>
											<Input
												value='#CRMPhone'
												onChange={(event) => { }}
												disabled
											/>
										</p>
									</div>
									{crmValue !== 6 ? (
										<>
											<div className='row'>
												<p>Role</p>
												<p>
													<Input
														value='#CRMRole'
														onChange={(event) => { }}
														disabled
													/>
												</p>
											</div>
											<div className='row'>
												<p>Organization</p>
												<p>
													<Input
														value='#CRMOrganisation'
														onChange={(event) => { }}
														disabled
													/>
												</p>
											</div>
											<div className='row'>
												<p>Company</p>
												<p>
													<Input
														value='#CRMCompany'
														onChange={(event) => { }}
														disabled
													/>
												</p>
											</div>
											<div className='row'>
												<p>Location</p>
												<p>
													<Input
														value='#CRMLocation'
														onChange={(event) => { }}
														disabled
													/>
												</p>
											</div>
											<div className='row'>
												<p>Message</p>
												<p>
													<Input
														value='#CRMMessage'
														onChange={(event) => { }}
														disabled
													/>
												</p>
											</div>
										</>
									) : null}
								</div>
							</div>
						</div>
					)}

					<div className='item'>
						<h6 className='nextactionsHeading'>Next Action</h6>
						<Dropdown
							isSearchable
							placeholder='Enter action name'
							value={context.nextAction}
							options={nextActions}
							onChange={context.setNextAction}
							label='Next Action'
							className='next-action-dropdown'
						/>
					</div>
				</div>

				<footer>
					<Button
						onClick={handleSave}
						disabled={
							context.actionName.trim().length > 0 && Number(crmId) !== 0
								? false
								: true
						}>
						Save
					</Button>
				</footer>
			</div>

			<NextNewAction
				open={openNextActionType}
				setOpenNextActionType={setOpenNextActionType}
				setNextActionTypeId={setNextActionTypeId}
				setLabel={setLabel}
				onClose={() => {
					setOpenNextActionType(false);
					if (nextActionTypeId === 0) {
						context.setNextAction(null);
					}
				}}
			/>
		</>
	);
};
export default SendCollectedValues;
