import React from 'react';

interface Props {
	size?: number;
}

function TemplateSvg(props: Props): JSX.Element {
	const { size } = props;
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='502'
			height='161'
			viewBox='0 0 502 161'>
			<defs>
				<filter
					id='5unrxpwhna'
					x='0'
					y='104'
					width='318'
					height='44'
					filterUnits='userSpaceOnUse'>
					<feOffset dy='2' />
					<feGaussianBlur stdDeviation='2' result='blur' />
					<feFlood floodOpacity='.278' />
					<feComposite operator='in' in2='blur' />
					<feComposite in='SourceGraphic' />
				</filter>
				<filter
					id='ntf90mwpab'
					x='2'
					y='8'
					width='316'
					height='104'
					filterUnits='userSpaceOnUse'>
					<feOffset dy='2' />
					<feGaussianBlur stdDeviation='2' result='blur-2' />
					<feFlood floodOpacity='.278' />
					<feComposite operator='in' in2='blur-2' />
					<feComposite in='SourceGraphic' />
				</filter>
			</defs>
			<g data-name='Group 8295'>
				<g data-name='Group 8293'>
					<g data-name='Group 8289'>
						<g data-name='Group 8285'>
							<g filter='url(#5unrxpwhna)'>
								<rect
									data-name='Rectangle 5928'
									width='306'
									height='32'
									rx='8'
									transform='translate(6 108)'
									fill='#fff'
								/>
							</g>
							<g data-name='Group 8288'>
								<text
									data-name='View order summary'
									transform='translate(170 130)'
									style={{
										fontFamily: 'OpenSans,Open Sans',
										fontSize: '14px',
										letterSpacing: '.018em',
										fill: '#3b4fd0',
									}}>
									<tspan x='-69.886' y='0'>
										View order summary
									</tspan>
								</text>
								<g data-name='open_in_new_black_24dp (1)'>
									<path
										data-name='Path 4060'
										d='M0 0h16v16H0z'
										style={{ fill: 'none' }}
										transform='translate(78 117)'
									/>
									<path
										data-name='Path 4061'
										d='M13.667 13.667H4.333V4.333H9V3H4.333A1.333 1.333 0 0 0 3 4.333v9.333A1.333 1.333 0 0 0 4.333 15h9.333A1.337 1.337 0 0 0 15 13.667V9h-1.333zM10.333 3v1.333h2.393l-6.553 6.554.94.94 6.553-6.553v2.393H15V3z'
										transform='translate(77 116)'
										style={{ fill: '#3b4fd0' }}
									/>
								</g>
							</g>
						</g>
						<g data-name='Group 8286'>
							<g style={{ filter: 'url(#ntf90mwpab)' }}>
								<rect
									data-name='Rectangle 5869'
									width='304'
									height='92'
									rx='8'
									transform='translate(8 12)'
									style={{ fill: '#fff' }}
								/>
							</g>
							<g data-name='Group 7445'>
								<g data-name='Group 8287'>
									<text
										data-name='We have received your payment of {{1}} for {{2}}. Please expect delivery in {{3}}. Thanks for shopping with us.'
										transform='translate(12 39)'
										style={{
											fontFamily: 'OpenSans,Open Sans',
											fontSize: '14px',
											letterSpacing: '.018em',
										}}>
										<tspan x='0' y='15'>
											We have received your payment of{' '}
										</tspan>
										<tspan y='15' style={{ fill: '#d64683' }}>
											{'{{1}}'}
										</tspan>
										<tspan y='15'> for </tspan>
										<tspan x='0' y='35' style={{ fill: '#d64683' }}>
											{'{{2}}'}
										</tspan>
										<tspan y='35'>. Please expect delivery in </tspan>
										<tspan y='35' style={{ fill: '#d64683' }}>
											{'{{3}}'}
										</tspan>
										<tspan y='35'>. Thanks </tspan>
										<tspan x='0' y='55'>
											for shopping with us.
										</tspan>
									</text>
									<text
										data-name='12:00 am'
										transform='translate(308 99)'
										style={{
											fill: 'rgba(0,0,0,.6)',
											fontSize: '10px',
											letterSpacing: '.04em',
											fontFamily: 'OpenSans,Open Sans',
										}}>
										<tspan x='-45.793' y='0'>
											12:00 am
										</tspan>
									</text>
								</g>
								<text
									data-name='Hi {{1}},'
									transform='translate(12 16)'
									style={{
										fontFamily: 'OpenSans-Bold,Open Sans',
										fontWeight: '700',
										fontSize: '14px',
										letterSpacing: '.018em',
									}}>
									<tspan x='0' y='15'>
										Hi{' '}
									</tspan>
									<tspan y='15' style={{ fill: '#d64683' }}>
										{'{{1}}'}
									</tspan>
									<tspan y='15'>,</tspan>
								</text>
							</g>
						</g>
					</g>
					<g data-name='Group 8290'>
						<g data-name='Group 7498'>
							<path
								data-name='Polygon 17'
								d='M4.5 0 9 8H0z'
								transform='rotate(-90 51 -20.5)'
								style={{ fill: 'rgba(0,0,0,.47)' }}
							/>
							<path
								data-name='Line 523'
								transform='translate(79.5 26)'
								style={{ stroke: '#868686', fill: 'none' }}
								d='M0 0h325.172'
							/>
						</g>
						<text
							transform='translate(411 31)'
							style={{
								fill: '#868686',
								fontFamily: 'OpenSans,Open Sans',
								fontSize: '14px',
								letterSpacing: '.018em',
							}}>
							<tspan x='0' y='0'>
								Header
							</tspan>
						</text>
					</g>
					<g data-name='Group 8291'>
						<g data-name='Group 7498'>
							<path
								data-name='Polygon 17'
								d='M4.5 0 9 8H0z'
								transform='matrix(0 -1 1 0 314 53.5)'
								style={{ fill: 'rgba(0,0,0,.47)' }}
							/>
							<path
								data-name='Line 523'
								transform='translate(322 49)'
								style={{ stroke: '#868686', fill: 'none' }}
								d='M0 0h82.672'
							/>
						</g>
						<text
							transform='translate(411 54)'
							style={{
								fill: '#868686',
								fontFamily: 'OpenSans,Open Sans',
								fontSize: '14px',
								letterSpacing: '.018em',
							}}>
							<tspan x='0' y='0'>
								Body
							</tspan>
						</text>
					</g>
					<g data-name='Group 8292'>
						<g data-name='Group 7498'>
							<path
								data-name='Polygon 17'
								d='M4.5 0 9 8H0z'
								transform='rotate(-90 221.25 -92.75)'
								style={{ fill: 'rgba(0,0,0,.47)' }}
							/>
							<path
								data-name='Line 523'
								transform='translate(322 124)'
								style={{ stroke: '#868686', fill: 'none' }}
								d='M0 0h82.672'
							/>
						</g>
						<text
							data-name='Button with dynamic URL'
							transform='translate(411 129)'
							style={{
								fill: '#868686',
								fontFamily: 'OpenSans,Open Sans',
								fontSize: '14px',
								letterSpacing: '.018em',
							}}>
							<tspan x='0' y='0'>
								Button with
							</tspan>
							<tspan x='0' y='20'>
								dynamic URL
							</tspan>
						</text>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default TemplateSvg;
