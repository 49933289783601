
import DataFetchAPI from '../../utils/Api';
import { transcriptData, uploadFileData } from './model';


const chatUploadFile = async (data: uploadFileData) => {
	// return await DataFetchAPI(true, 'abc').post('/chatUploadFile', {
	// 	uploadfile: data.uploadfile,
	// });
};
let autoAPI:any = "http://localhost:3000/HybridChat/autoassignOnLogin"
let transAPI:any = "http://localhost:3000/HybridChat/getTranscript"
const getAutoAPI=()=>{
	return autoAPI;
}
const getTransAPI=()=>{
	return transAPI;
}
const autoAssign = () => {
	return getAutoAPI().post('/HybridChat/autoassignOnLogin');
};
const getTranscript = async (data: transcriptData) => {
	return getTransAPI().post('/HybridChat/getTranscript', data);
};
export const chatService = {
	chatUploadFile,
	autoAssign,
	getTranscript,
};
