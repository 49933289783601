import './ActionsMenu.scss';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import { addAction } from '../../../../Features/builder/builderSlice';
import {
	APICall,
	ConfirmCollected,
	Image,
	Keyboard,
	MultipleChoice,
	MultipleSelect,
	SendCollectedValues,
	Star,
	Text,
	ThreeDotMenu,
	TriggerQuery,
	HandoffToAgent,
} from '../../../../assets/Icons';
import { useEffect, useState } from 'react';
import { AddAction } from '../../../../Features/builder/model';
import { getIcon } from '..';
import { TaskObject } from '../../model';
import { getActionName } from '../../../../utils/utils';

type ActionsMenuProps = {
	open: boolean;
	position: string[];
	onClose: () => void;
	previousTaskId?: number;
};

const AddLinkedActionMenu = ({
	open,
	position,
	onClose,
	previousTaskId = 0,
}: ActionsMenuProps) => {
	const [dummyTask, setDummyTask] = useState('');
	const dispatch = useAppDispatch();
	const { activeQuery, actions } = useSelector(
		(state: RootState) => state.builder
	);
	const [commonProps, setCommonProps] = useState({
		workflow_id: activeQuery?.workflowId,
		task_id: 0,
		taskroot: false,
		template: '',
		templateId: 0,
		template_description: '',
		template_type: '',
		userResponseType: '',
		task_description: '',
		task_name: 'Untitled',
	});

	useEffect(() => {
		document.addEventListener('click', onClose);

		return () => {
			document.removeEventListener('click', onClose);
			onClose();
		};
	}, []);

	useEffect(() => {
		if (activeQuery) {
			setCommonProps((props) => ({
				...props,
				workflow_id: activeQuery.workflowId,
			}));
		}
	}, [activeQuery]);

	const handleAction = (type: number) => {
		let task_description = '';
		let typeTosend = 1;
		if (type === 0) {
			task_description = 'HandOff-To-Agent';
			typeTosend = 1;
		} else {
			task_description = '';
			typeTosend = type;
		}
		activeQuery?.workflowId &&
			dispatch(
				addAction({
					task_position: `${parseInt(position[1]) + 110}d${position[0]}`,
					userResponseTypeID: typeTosend,
					previousTaskId,
					...commonProps,
					task_description,
					task_name: getActionName(actions, type),
				} as AddAction)
			);
		onClose();
	};

	return (
		<>
			<div className={`linked-action-menu${open ? ' open' : ''}`}>
				<div className='actions-list'>
					<div
						className='action action1'
						onClick={() => handleAction(1)}
						onMouseEnter={() => setDummyTask('dummy-task1')}
						onMouseLeave={() => setDummyTask('')}>
						<div className='icon'>
							<Text width='15px' />/ <Image width='15px' />
						</div>
						<p>Show Text & Media</p>
					</div>

					<div
						className='action action5'
						onClick={() => handleAction(5)}
						onMouseEnter={() => setDummyTask('dummy-task5')}
						onMouseLeave={() => setDummyTask('')}>
						<div className='icon'>
							<MultipleChoice width='22px' />
						</div>
						<p>Multiple Choice Options</p>
					</div>

					<div
						className='action action3'
						onClick={() => handleAction(3)}
						onMouseEnter={() => setDummyTask('dummy-task3')}
						onMouseLeave={() => setDummyTask('')}>
						<div className='icon'>
							<MultipleSelect width='22px' />
						</div>
						<p>Multiple Select Options</p>
					</div>

					<div
						className='action action150'
						onClick={() => handleAction(150)}
						onMouseEnter={() => setDummyTask('dummy-task150')}
						onMouseLeave={() => setDummyTask('')}>
						<div className='icon'>
							<Keyboard width='22px' />
						</div>
						<p>Collect User Input</p>
					</div>

					<div
						className='action action11'
						onClick={() => handleAction(11)}
						onMouseEnter={() => setDummyTask('dummy-task11')}
						onMouseLeave={() => setDummyTask('')}>
						<div className='icon'>
							<Star width='22px' />
						</div>
						<p>Collect User Rating</p>
					</div>

					<div
						className='action action10'
						onClick={() => handleAction(10)}
						onMouseEnter={() => setDummyTask('dummy-task10')}
						onMouseLeave={() => setDummyTask('')}>
						<div className='icon'>
							<APICall width='22px' />
						</div>
						<p>Call an API</p>
					</div>

					<div
						className='action action0'
						onClick={() => handleAction(0)}
						onMouseEnter={() => setDummyTask('dummy-task0')}
						onMouseLeave={() => setDummyTask('')}>
						<div className='icon'>
							<HandoffToAgent width='15px' />
						</div>
						<p>Handoff to Agent</p>
					</div>

					<div
						className='action action12'
						onClick={() => handleAction(12)}
						onMouseEnter={() => setDummyTask('dummy-task12')}
						onMouseLeave={() => setDummyTask('')}>
						<div className='icon'>
							<TriggerQuery width='21px' />
						</div>
						<p>Trigger another Query</p>
					</div>

					<div
						className='action action14'
						onClick={() => handleAction(14)}
						onMouseEnter={() => setDummyTask('dummy-task14')}
						onMouseLeave={() => setDummyTask('')}>
						<div className='icon'>
							<SendCollectedValues width='22px' />
						</div>
						<p>Send Collected Values</p>
					</div>

					<div
						className='action action8'
						onClick={() => handleAction(8)}
						onMouseEnter={() => setDummyTask('dummy-task8')}
						onMouseLeave={() => setDummyTask('')}>
						<div className='icon'>
							<ConfirmCollected width='22px' />
						</div>
						<p>Confirm Collected values</p>
					</div>
				</div>
			</div>

			<div className='dummy-tasks'>
				<div
					className={`ff-task-dummy${
						dummyTask === 'dummy-task1' ? ' show' : ''
					}`}>
					<div className='task-container-outer'>
						<div className={`task-container task1`}>
							{getIcon(1)}
							<div className='task-name'>Untitled</div>
							<div className='more-actions'>
								<ThreeDotMenu width='20px' />
							</div>
						</div>
					</div>
				</div>
				<div
					className={`ff-task-dummy${
						dummyTask === 'dummy-task3' ? ' show' : ''
					}`}>
					<div className='task-container-outer'>
						<div className={`task-container task3`}>
							{getIcon(3)}
							<div className='task-name'>Untitled</div>
							<div className='more-actions'>
								<ThreeDotMenu width='20px' />
							</div>
						</div>
					</div>
				</div>
				<div
					className={`ff-task-dummy${
						dummyTask === 'dummy-task5' ? ' show' : ''
					}`}>
					<div className='task-container-outer'>
						<div className={`task-container task5`}>
							{getIcon(5)}
							<div className='task-name'>Untitled</div>
							<div className='more-actions'>
								<ThreeDotMenu width='20px' />
							</div>
						</div>
					</div>
				</div>
				<div
					className={`ff-task-dummy${
						dummyTask === 'dummy-task8' ? ' show' : ''
					}`}>
					<div className='task-container-outer'>
						<div className={`task-container task8`}>
							{getIcon(8)}
							<div className='task-name'>Untitled</div>
							<div className='more-actions'>
								<ThreeDotMenu width='20px' />
							</div>
						</div>
					</div>
				</div>
				<div
					className={`ff-task-dummy${
						dummyTask === 'dummy-task10' ? ' show' : ''
					}`}>
					<div className='task-container-outer'>
						<div className={`task-container task10`}>
							{getIcon(10)}
							<div className='task-name'>Untitled</div>
							<div className='more-actions'>
								<ThreeDotMenu width='20px' />
							</div>
						</div>
					</div>
				</div>
				<div
					className={`ff-task-dummy${
						dummyTask === 'dummy-task11' ? ' show' : ''
					}`}>
					<div className='task-container-outer'>
						<div className={`task-container task11`}>
							{getIcon(11)}
							<div className='task-name'>Untitled</div>
							<div className='more-actions'>
								<ThreeDotMenu width='20px' />
							</div>
						</div>
					</div>
				</div>
				<div
					className={`ff-task-dummy${
						dummyTask === 'dummy-task12' ? ' show' : ''
					}`}>
					<div className='task-container-outer'>
						<div className={`task-container task12`}>
							{getIcon(12)}
							<div className='task-name'>Untitled</div>
							<div className='more-actions'>
								<ThreeDotMenu width='20px' />
							</div>
						</div>
					</div>
				</div>
				<div
					className={`ff-task-dummy${
						dummyTask === 'dummy-task150' ? ' show' : ''
					}`}>
					<div className='task-container-outer'>
						<div className={`task-container task150`}>
							{getIcon(150)}
							<div className='task-name'>Untitled</div>
							<div className='more-actions'>
								<ThreeDotMenu width='20px' />
							</div>
						</div>
					</div>
				</div>
				<div
					className={`ff-task-dummy${
						dummyTask === 'dummy-task14' ? ' show' : ''
					}`}>
					<div className='task-container-outer'>
						<div className={`task-container task14`}>
							{getIcon(14)}
							<div className='task-name'>Untitled</div>
							<div className='more-actions'>
								<ThreeDotMenu width='20px' />
							</div>
						</div>
					</div>
				</div>
				<div
					className={`ff-task-dummy${
						dummyTask === 'dummy-task0' ? ' show' : ''
					}`}>
					<div className='task-container-outer'>
						<div className={`task-container task1`}>
							{getIcon(1)}
							<div className='task-name'>Untitled</div>
							<div className='more-actions'>
								<ThreeDotMenu width='20px' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default AddLinkedActionMenu;
