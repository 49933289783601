import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IndustryState, createVerticalState, getVerticalState } from './model';
import verticalizationService from './verticalizationService';
import { setProductResultCount } from '../ExperienceOnWeb/experienceonwebSlice';
export const initialState: IndustryState = {
	llmConfig: [
		{ value: '', config_name: 'specialInstruction' },
		{ value: 'Casual', config_name: 'toneOfVoice' },
		{ value: '', config_name: 'Other' },
		{ value: '3', config_name: 'top_n' },
		{ value: '', config_name: 'errorMessage' },
		{ value: '', config_name: 'competitorsName' },
		{ value: 'false', config_name: 'enableProbing' },
		{ value: 'false', config_name: 'complimentaryProducts' },
		{ value: 'false', config_name: 'recommendationRational' },
	],
	industryConfig: [
		{ industry_name: 'Accessories', enabled: 0, type: 'Ecom' },
		{ industry_name: 'Cosmetics', enabled: 0, type: 'Ecom' },
		{ industry_name: 'Electronics', enabled: 0, type: 'Ecom' },
		{ industry_name: 'Fashion', enabled: 0, type: 'Ecom' },
		{ industry_name: 'Footwear', enabled: 0, type: 'Ecom' },
		{ industry_name: 'Furniture', enabled: 0, type: 'Ecom' },
		{ industry_name: 'Home Appliances', enabled: 0, type: 'Ecom' },
		{ industry_name: 'Home Decor', enabled: 0, type: 'Ecom' },
		{ industry_name: 'Food & Beverage', enabled: 0, type: 'Ecom' },
		{ industry_name: 'Jewelry', enabled: 0, type: 'Ecom' },
		{ industry_name: 'Banking', enabled: 0, type: 'Finance' },
		{ industry_name: 'Insurance', enabled: 0, type: 'Finance' },
		{ industry_name: 'Wealth', enabled: 0, type: 'Finance' },
		{ industry_name: 'RealEstate', enabled: 0, type: 'RealEstate' },
	],
	tone: { label: 'Casual', value: 'Casual' },
	instructions: '',
	updateData: '',
	AISelected: false,
	customIndustry: '',
	customFinance: '',
	radioValueState: '',
	enableProbing: [false],
	failedResponse:
		'I am sorry I could not find a product based on your inputs. Could you please elaborate on your requirements?',
	competitorNames: '',
	industry: [],
	finance: [],
	searchBussinessValue: '',
	selectedEcom: '',
	selectedFinance: '',
	selectedRealStateOrOther: '',
	otherBussiness: '',
	complimentaryProducts: false,
	recommendationRational: false,
};

export const createVertical = createAsyncThunk<
	any,
	createVerticalState,
	{ rejectValue: string }
>(
	'/llmconnector/create/config',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await verticalizationService.createVertical(data);
			if (response.status === 202 || response.status === 200) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			console.log('Network error');
			return rejectWithValue(JSON.stringify({ error: 'Network error' }));
		}
	}
);

export const getVertical = createAsyncThunk<
	any,
	getVerticalState,
	{ rejectValue: string }
>('llmconnector/get/config', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await verticalizationService.getVertical(data);
		if (response.data) {
			for (let config of response.data.llm_config) {
				if (config.config_name === 'top_n') {
					dispatch(setProductResultCount(config.value));
				}
			}
			return response.data;
		}

		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		console.log('Network error');
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const updateVertical = createAsyncThunk<
	any,
	createVerticalState,
	{ rejectValue: string }
>(
	'/llmconnector/update/config',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await verticalizationService.updateVertical(data);
			if (response.status === 202 || response.status === 200) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			console.log('Network error');
			return rejectWithValue(JSON.stringify({ error: 'Network error' }));
		}
	}
);

const verticalizationSlice = createSlice({
	name: 'verticalization',
	initialState,
	reducers: {
		setTone: (state, action) => {
			state.tone = action.payload;
		},
		setInstructions: (state, action) => {
			state.instructions = action.payload;
		},
		setIndustrySlice: (state, action) => {
			state.industryConfig = action.payload;
		},
		setLLM: (state, action) => {
			state.llmConfig = action.payload;
		},
		setUpdateData: (state, action) => {
			state.updateData = action.payload;
		},
		setcustomIndustry: (state, action) => {
			state.customIndustry = action.payload;
		},
		setcustomfinance: (state, action) => {
			state.customFinance = action.payload;
		},
		setAISelect: (state, action) => {
			state.AISelected = action.payload;
		},
		setradioValue: (state, action) => {
			state.radioValueState = action.payload;
		},
		setCompetitorNames: (state, action) => {
			state.competitorNames = action.payload;
		},
		setEnableProbing: (state, action) => {
			state.enableProbing = action.payload;
		},
		setFailedresponse: (state, action) => {
			state.failedResponse = action.payload;
		},
		setSearchBussinessValue: (state, action) => {
			state.searchBussinessValue = action.payload;
		},
		setSelectedEcom: (state, action) => {
			state.selectedEcom = action.payload;
		},
		setSelectedFinance: (state, action) => {
			state.selectedFinance = action.payload;
		},
		setSelectedRealStateOrOther: (state, action) => {
			state.selectedRealStateOrOther = action.payload;
		},
		setOtherBussiness: (state, action) => {
			state.otherBussiness = action.payload;
		},
		setComplimentaryProducts: (state, action) => {
			state.complimentaryProducts = action.payload;
		},

		setRecommendationRational: (state, action) => {
			state.recommendationRational = action.payload;
		},
		setFinance: (state, action) => {
			state.finance = action.payload;
		},
		setIndustry: (state, action) => {
			state.industry = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getVertical.pending, (state) => {})
			.addCase(getVertical.fulfilled, (state, action) => {
				let activeConfig;
				if (
					action.payload.industry_config &&
					action.payload.industry_config.length
				) {
					let industryConfig = action.payload.industry_config;
					state.industry = industryConfig.filter(
						(config: any) => config.type.toLowerCase() === 'ecom'
					);
					state.finance = industryConfig.filter(
						(config: any) => config.type.toLowerCase() === 'finance'
					);
					state.industryConfig = industryConfig;

					activeConfig = industryConfig.filter(
						(config: any) => config.enabled === 1
					)[0];
					if (activeConfig && activeConfig.type.toLowerCase() === 'ecom') {
						state.selectedEcom = activeConfig.industry_name;
					} else if (
						activeConfig &&
						activeConfig.type.toLowerCase() === 'finance'
					) {
						state.selectedFinance = activeConfig.industry_name;
					}
				}
				if (action.payload.llm_config && action.payload.llm_config.length) {
					let llmConfig = action.payload.llm_config;
					state.llmConfig = llmConfig;
					for (let config of llmConfig) {
						if (config.config_name === 'enableProbing') {
							state.enableProbing =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						} else if (config.config_name === 'competitorsName') {
							state.competitorNames = config.value;
						} else if (config.config_name === 'errorMessage') {
							state.failedResponse = config.value
								? config.value
								: 'I am sorry I could not find a product based on your inputs. Could you please elaborate on your requirements?';
						} else if (config.config_name === 'toneOfVoice') {
							state.tone = { label: config.value, value: config.value };
						} else if (config.config_name === 'specialInstruction') {
							state.instructions = config.value;
						} else if (config.config_name === 'Other') {
							state.otherBussiness = config.value;
						} else if (config.config_name === 'recommendationRational') {
							state.recommendationRational =
								config.value.toLowerCase() === 'true' ? true : false;
						} else if (config.config_name === 'complimentaryProducts') {
							state.complimentaryProducts =
								config.value.toLowerCase() === 'true' ? true : false;
						}
					}
				}
				state.selectedRealStateOrOther =
					activeConfig === undefined
						? 'Other'
						: activeConfig.industry_name === 'RealEstate'
						? 'Real Estate'
						: '';
			})
			.addCase(getVertical.rejected, (state) => {});
	},
});
export const {
	setcustomIndustry,
	setcustomfinance,
	setTone,
	setInstructions,
	setUpdateData,
	setIndustrySlice,
	setLLM,
	setAISelect,
	setradioValue,
	setCompetitorNames,
	setEnableProbing,
	setFailedresponse,
	setSearchBussinessValue,
	setOtherBussiness,
	setSelectedEcom,
	setSelectedFinance,
	setSelectedRealStateOrOther,
	setComplimentaryProducts,
	setRecommendationRational,
	setIndustry,
	setFinance,
} = verticalizationSlice.actions;
export default verticalizationSlice.reducer;
