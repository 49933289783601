import AntleredDeer from '../../../assets/images/user-avatar-icons/Antlered Deer.png';
import BigBilledToucan from '../../../assets/images/user-avatar-icons/Big-billed Toucan.png';
import BrownBear from '../../../assets/images/user-avatar-icons/Brown Bear.png';
import CombedRooster from '../../../assets/images/user-avatar-icons/Combed Rooster.png';
import DiverDuck from '../../../assets/images/user-avatar-icons/Diver Duck.png';
import FurryBunny from '../../../assets/images/user-avatar-icons/Furry Bunny.png';
import GiantMoose from '../../../assets/images/user-avatar-icons/Giant Moose.png';
import MajesticTiger from '../../../assets/images/user-avatar-icons/Majestic Tiger.png';
import MaskedRacoon from '../../../assets/images/user-avatar-icons/Masked Racoon.png';
import NimbleParrot from '../../../assets/images/user-avatar-icons/Nimble Parrot.png';
import PeerlessPenguin from '../../../assets/images/user-avatar-icons/Peerless Penguin.png';
import PouchedPelican from '../../../assets/images/user-avatar-icons/Pouched Pelican.png';
import RedFox from '../../../assets/images/user-avatar-icons/Red Fox.png';
import SilverbackGorilla from '../../../assets/images/user-avatar-icons/Silverback Gorilla.png';
import SpectacledOwl from '../../../assets/images/user-avatar-icons/Spectacled Owl.png';
import SpottedGiraffe from '../../../assets/images/user-avatar-icons/Spotted Giraffe.png';
import StripedZebra from '../../../assets/images/user-avatar-icons/Striped Zebra.png';
import TrumpetingElephant from '../../../assets/images/user-avatar-icons/Trumpeting Elephant.png';
import { Avatar } from '@mui/material';

interface Props {
	sessionId: string;
	size?: number;
}
const avatarList = [
	{ userName: 'Antlered Deer', userAvatar: AntleredDeer },
	{ userName: 'Big-billed Toucan', userAvatar: BigBilledToucan },
	{ userName: 'Brown Bear', userAvatar: BrownBear },
	{ userName: 'Combed Rooster', userAvatar: CombedRooster },
	{ userName: 'Diver Duck', userAvatar: DiverDuck },
	{ userName: 'Furry Bunny', userAvatar: FurryBunny },
	{ userName: 'Giant Moose', userAvatar: GiantMoose },
	{ userName: 'Majestic Tiger', userAvatar: MajesticTiger },
	{ userName: 'Masked Racoon', userAvatar: MaskedRacoon },
	{ userName: 'Nimble Parrot', userAvatar: NimbleParrot },
	{ userName: 'Peerless Penguin', userAvatar: PeerlessPenguin },
	{ userName: 'Pouched Pelican', userAvatar: PouchedPelican },
	{ userName: 'Red Fox', userAvatar: RedFox },
	{ userName: 'Silverback Gorilla', userAvatar: SilverbackGorilla },
	{ userName: 'Spectacled Owl', userAvatar: SpectacledOwl },
	{ userName: 'Spotted Giraffe', userAvatar: SpottedGiraffe },
	{ userName: 'Striped Zebra', userAvatar: StripedZebra },
	{ userName: 'Trumpeting Elephant', userAvatar: TrumpetingElephant },
];
export const ChatAvatar = ({ sessionId, size = 40 }: Props) => {
	const arr: Array<any> = sessionId.split('');
	const selectedAvatarNumber =
		arr.reduce((total, current) => total + parseInt(current)) %
		avatarList.length;
	return (
		<Avatar
			src={avatarList[selectedAvatarNumber].userAvatar}
			sx={{ width: size, height: size }}
		/>
	);
};

export const ChatAvatarName = ({ sessionId }: Props) => {
	const arr: Array<any> = sessionId.split('');
	const selectedAvatarNumber =
		arr.reduce((total, current) => total + parseInt(current)) %
		avatarList.length;
	return <>{avatarList[selectedAvatarNumber].userName}</>;
};
