import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { GCBThumbsDown, GCBThumbsUp } from '../../../../../assets/Icons';
import { loadState } from '../../../../../utils/utils';
import {
	CarouselBotMessages,
	CarouselInput,
} from '../../../../styles/Appearance/Body/Carousel/carousel.styled';

import {
	ChatBotMessage,
	ChatBotOption,
	ChatBotOptionsDiv,
	ChatBotTime,
	ChatBubbleDiv,
	ChatThumbs,
	ChatUserMessage,
	ChatUserTime,
} from '../../../../styles/Appearance/Body/Chatbubble/chatbubble.styled';
import CarouselComp from '../CarouselComp';
import './ChatBubble.scss';

type Props = {
	message: string | React.ReactNode;
	messageby?: 'bot' | 'user';
	options?: boolean;
	carousel?: boolean;
	input?: boolean;
};

const ChatBubble = ({
	message,
	messageby,
	options,
	carousel,
	input,
}: Props) => {
	const {
		fontSize,
		botMessageBubbleColor,
		botMessageTextColor,
		userMessageBubbleColor,
		userMessageTextColor,
		inputBoxBackgroundColor,
		inputBoxTextColor,
		inputBoxBckRange,
		inputBoxTextRange,
		optionsAndQuickLinksBckColor,
		optionsAndQuickLinksTextColor,
		optionsAndQuickLinksBorderColor,
		responseLevelFeedBackBckColor,
		responseLevelFeedBackIconColor,
		responseLevelFeedBackBorderColor,
		timeStampBckColor,
		timeStampTextColor,
		optionsAndQuickLinksBckRange,
		optionsAndQuickLinksBorderRange,
		responseLevelFeedBackBckRange,
		responseLevelFeedBackBorderRange,
		timeStampBckRange,
		timeStampTextRange,
		botAvatar,
		botMessageCheckbox,
	} = useSelector((state: RootState) => state.configure);
	const botDetails = loadState('botDetails');

	let cards = [
		{
			image:
				'https://cdn.britannica.com/74/114874-050-6E04C88C/North-Face-Mount-Everest-Tibet-Autonomous-Region.jpg',
			title: 'Himalayas - Abode of the Snow',
			description:
				'The range has some of the planet’s highest peaks, including the highest, Mount Everest.',
		},
		{
			image:
				'https://www.nationsonline.org/gallery/africa/Victoria-Falls-with-rainbow.jpg',
			title: 'Victoria falls - The Smoke That Thunders',
			description:
				'On the Zambezi river at the border of Zambia and Zimbabwe, these are the world’s largest waterfall.',
		},
		{
			image: 'https://cdn.mos.cms.futurecdn.net/deaceNXy23NF8VsCrwZPgn.jpg',
			title: 'Sahara - The Greatest Desert',
			description:
				'With an area of 9,200,000 square kilometres, it is the largest hot desert in the world.',
		},
		{
			image:
				'https://cdn.zeplin.io/60b066260c84cb7c84fcd4a7/assets/8D87F917-86B6-4090-82DB-12F544188D64.png',
			title: 'Amazon rainforest — The Most Biodiverse Region',
			description:
				'Amazon has over half of the planet’s remaining rainforests, and is home to an estimated 390 billion trees.',
		},
	];
	return (
		<>
			<ChatBubbleDiv messageby={messageby!}>
				{messageby === 'bot' ? (
					<>
						<ChatBotMessage
							carousel={carousel}
							botMessageBubbleColor={botMessageBubbleColor}
							botMessageTextColor={botMessageTextColor}>
							{(botAvatar || botDetails) && botMessageCheckbox[0] ? (
								<img
									className={
										!carousel
											? 'bot-message-image-display'
											: 'bot-message-image-display-carousel'
									}
									src={botAvatar ? botAvatar : botDetails.botIcon}
									alt=''
								/>
							) : null}
							{carousel ? (
								<>
									<CarouselBotMessages
										botMessageBubbleColor={botMessageBubbleColor}
										botMessageTextColor={botMessageTextColor}>
										{message}
									</CarouselBotMessages>
									<CarouselComp cards={cards} />
								</>
							) : (
								<div className='bot-message body1 bot-shadow'>{message}</div>
							)}
						</ChatBotMessage>
						{options ? (
							<>
								<ChatBotOptionsDiv>
									<ChatBotOption
										optionsAndQuickLinksBckColor={optionsAndQuickLinksBckColor}
										optionsAndQuickLinksBorderColor={
											optionsAndQuickLinksBorderColor
										}
										optionsAndQuickLinksTextColor={
											optionsAndQuickLinksTextColor
										}
										optionsAndQuickLinksBckRange={optionsAndQuickLinksBckRange}
										optionsAndQuickLinksBorderRange={
											optionsAndQuickLinksBorderRange
										}>
										Option 1
									</ChatBotOption>
									<ChatBotOption
										optionsAndQuickLinksBckColor={optionsAndQuickLinksBckColor}
										optionsAndQuickLinksBorderColor={
											optionsAndQuickLinksBorderColor
										}
										optionsAndQuickLinksTextColor={
											optionsAndQuickLinksTextColor
										}
										optionsAndQuickLinksBckRange={optionsAndQuickLinksBckRange}
										optionsAndQuickLinksBorderRange={
											optionsAndQuickLinksBorderRange
										}>
										Option 2
									</ChatBotOption>
									<ChatBotOption
										optionsAndQuickLinksBckColor={optionsAndQuickLinksBckColor}
										optionsAndQuickLinksBorderColor={
											optionsAndQuickLinksBorderColor
										}
										optionsAndQuickLinksTextColor={
											optionsAndQuickLinksTextColor
										}
										optionsAndQuickLinksBckRange={optionsAndQuickLinksBckRange}
										optionsAndQuickLinksBorderRange={
											optionsAndQuickLinksBorderRange
										}>
										Option 3
									</ChatBotOption>
								</ChatBotOptionsDiv>
							</>
						) : null}
						<ChatThumbs
							responseLevelFeedBackBorderColor={
								responseLevelFeedBackBorderColor
							}
							responseLevelFeedBackBckColor={responseLevelFeedBackBckColor}
							responseLevelFeedBackBckRange={responseLevelFeedBackBckRange}
							responseLevelFeedBackBorderRange={
								responseLevelFeedBackBorderRange
							}>
							<GCBThumbsUp color={responseLevelFeedBackIconColor} />
							<GCBThumbsDown color={responseLevelFeedBackIconColor} />
						</ChatThumbs>
						<ChatBotTime
							fontSize={fontSize.value}
							timeStampBckColor={timeStampBckColor}
							timeStampTextColor={timeStampTextColor}
							timeStampBckRange={timeStampBckRange}
							timeStampTextRange={timeStampTextRange}>
							<p>10:00 AM</p>
						</ChatBotTime>
					</>
				) : null}
				{messageby === 'user' ? (
					<>
						<ChatUserMessage
							userMessageBubbleColor={userMessageBubbleColor}
							userMessageTextColor={userMessageTextColor}>
							<div className='user-message body1 user-shadow'>{message}</div>
						</ChatUserMessage>
						<ChatUserTime
							fontSize={fontSize.value}
							timeStampBckColor={timeStampBckColor}
							timeStampTextColor={timeStampTextColor}
							timeStampBckRange={timeStampBckRange}
							timeStampTextRange={timeStampTextRange}>
							<p>10:00 AM</p>
						</ChatUserTime>
					</>
				) : null}
			</ChatBubbleDiv>
			{input ? (
				<CarouselInput
					inputBoxBackgroundColor={inputBoxBackgroundColor}
					inputBoxTextColor={inputBoxTextColor}
					inputBoxBckRange={inputBoxBckRange}
					inputBoxTextRange={inputBoxTextRange}>
					Type Something
				</CarouselInput>
			) : null}
		</>
	);
};

export default ChatBubble;
