import { useSelector } from "react-redux";
import { setMsgsArray, HBsetChatInput, setMsgsLoading } from "../../../../Features/HelpBot/helpbotSlice";
import { RootState, useAppDispatch } from "../../../../app/store";
import './hbchatbody.scss';
import { useEffect, useState } from "react";
import { getCurrentTime, getDay } from "../../../../utils/utils";
import { HelpBotIcon } from "../../../../assets/Icons/HelpBot";
import { ThumbRating } from "../HBRatingsFeedback/Ratings/Ratings";



function HBChatBody() {
    const {
        msgsArray,
        msgsLoading,
        chatInput,
        conversationId,
        chatData,
        expandWidth
    } = useSelector((state: RootState) => state.helpBot);
    const dispatch = useAppDispatch();
    const [HBMsgArray, setHBMsgArray] = useState<[]>([]);
    const handleSendMessage = () => {
        const updatedArray = [
            ...msgsArray,
            { content: chatInput.trim(), role: 'user' },
        ];
        dispatch(
            setMsgsArray([...msgsArray, { content: chatInput.trim(), role: 'user', time: new Date().toISOString() }])
        );

        dispatch(HBsetChatInput(''));
        dispatch(setMsgsLoading('loading'));

    };
    const scrollToBottom = () => {

        const userMessages = document.querySelectorAll(
            '.ff-hbuser'
        ) as NodeListOf<HTMLElement>;
        console.log(userMessages)
        if (userMessages.length > 0) {
            const chatbodyWrapper: any = document.querySelector('.ff-helpbot-chatbody-container');
            console.log(chatbodyWrapper)
            chatbodyWrapper.scrollTo({
                top: userMessages.item(userMessages.length - 1).offsetTop - 5,
            });
        }
    }
    useEffect(() => {
        if (msgsArray) {
            setHBMsgArray(msgsArray)
        }
    }, [msgsArray])

    useEffect(() => {
        if (chatData.message) {
            dispatch(
                setMsgsArray([...msgsArray, { content: chatData.message, role: 'server', time: new Date().toISOString() }])
            );

            scrollToBottom();


        }
    }, [chatData])


    return (
        <div className='ff-helpbot-chatbody-container'>
            <div className={`${expandWidth ? 'ff-inside-chatbot-container' : ''} `}>
                {
                    HBMsgArray && HBMsgArray.map((msgObj: any, index: number) => {

                        return (
                            <>
                                {
                                    index === 0 ?
                                        <div style={{ height: '20px' }}></div> : null
                                }

                                <div className={`bubble-container ff-hb${msgObj.role}`}>


                                    {msgObj.role === 'server' ?
                                        <div className='hb-message-bubble-inner-container'>

                                            <div className="help-bot-avatar">
                                                <HelpBotIcon />
                                            </div>

                                            <div className={`hb-message-bubble ff-hb${msgObj.role} ${expandWidth ? 'bubble-expanded' : ''}`}>

                                                <p className={`body2 message`} dangerouslySetInnerHTML={{ __html: msgObj.content.trim() }}>
                                                  
                                                </p>

                                            </div>
                                        </div>
                                        :
                                        <div className={`hb-message-bubble ff-hb${msgObj.role} ${expandWidth ? 'bubble-expanded' : ''}`}>

                                            <p className={`body2 message`} dangerouslySetInnerHTML={{ __html: msgObj.content.trim() }}>
                                            </p>

                                        </div>
                                    }

                                    <footer className='ff-hb-text-footer'>
                                        <span className='ff-hb-timestamp'>

                                            {getCurrentTime(msgObj.time)}

                                        </span>
                                        {msgObj.role === 'server' &&
                                            <ThumbRating

                                                size={'small'}

                                                isResponselevelFeedback={true}
                                            />
                                        }
                                    </footer>


                                </div>
                            </>
                        )
                    })
                }
            </div >
        </div >
    )
}
export default HBChatBody;
