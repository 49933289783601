import './WelcomeScreen.scss';
import { useNavigate } from 'react-router';
import CarouselComp from '../Configure/Appearance/GenericChatBot/CarouselComp';
import Whatsapp_Icon from '../../assets/images/welcome_screen/whatsapp.svg';
import AppleChat_Icon from '../../assets/images/welcome_screen/apple_chat.png';
import Google_Icon from '../../assets/images/welcome_screen/google.svg';
import Instagram_Icon from '../../assets/images/welcome_screen/instagram.svg';
import Campaigns_Card from '../../assets/images/welcome-cards/Campaigns-Card.png';
import Storefronts_Card from '../../assets/images/welcome-cards/Storefronts-Card.png';
import AfterSales_Card from '../../assets/images/welcome-cards/After Sales-Card.png';
import PreviousArrow_Icon from '../../assets/images/welcome_screen/previous-arrow.png';
import NextArrow_Icon from '../../assets/images/welcome_screen/next-arrow.png';
import Button from '../../Components/Button';
import { RootState, useAppDispatch } from '../../app/store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { resetCreateInstaWatsapp } from '../../Features/WhatsApp-BSP/auth/authSlice';
import CreateInsta from '../FF-Beta/BetaChannels/CreateInsta';
import Create from '../FF-Beta/BetaChannels/Create';
import CreateInstaFailed from '../FF-Beta/BetaChannels/CreateInsta/CreateInstaFailed';
import { InstaAccountAlreadyAdded } from '../FF-Beta/BetaChannels';
const channelcards = [
	{
		image: Whatsapp_Icon,
		title: 'WhatsApp',
		description: '',
	},
	{
		image: AppleChat_Icon,
		title: 'Apple Business Chat',
		description: '',
	},
	{
		image: Google_Icon,
		title: 'Google Business Messaging',
		description: '',
	},
	{
		image: Instagram_Icon,
		title: 'Instagram',
		description: '',
	},
];

const welcomeCards = [
	{
		title: 'Campaigns',
		src: Campaigns_Card,
	},
	{
		title: 'Storefronts',
		src: Storefronts_Card,
	},
	{
		title: 'AfterSales',
		src: AfterSales_Card,
	},
];

const WelcomeScreen = () => {
	const navigate = useNavigate();
	const { instaPagesRetrived, watsappIdRetrieved, instaPages, instaIds } =
		useSelector((state: RootState) => state.BSPauth);
	const [createInsta, setCreateInsta] = useState(false);
	const [createInstaFailed, setCreateInstaFailed] = useState<boolean>(false);
	const [existingAccount, setExistingAccount] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (instaPagesRetrived === 'success') {
			const tempInstaPages =
				instaPages &&
				instaPages.map((each) => {
					return each.id;
				});
			//logic to check , if insta account is already added.
			const existingInstaArray = [];
			for (const eachId of instaIds) {
				for (const eachPage of tempInstaPages) {
					if (eachPage === eachId.pageId) {
						existingInstaArray.push(eachPage);
					}
				}
			}
			if (Boolean(existingInstaArray.length)) {
				setExistingAccount(true);
			} else {
				setCreateInsta(true);
			}
		} else if (instaPagesRetrived === 'failed') {
			setCreateInstaFailed(true);
		}
		return () => {
			dispatch(resetCreateInstaWatsapp());
		};
	}, [instaPagesRetrived]);

	useEffect(() => {
		if (watsappIdRetrieved === 'success') {
			// setCreate(true);
			navigate('create-waba');
		}
		return () => {
			dispatch(resetCreateInstaWatsapp());
		};
	}, [watsappIdRetrieved]);

	const handleWelcomeOnClick = (title: string) => {
		if (title === 'WhatsApp') {
			navigate('setup-wa-insta', {
				state: { value: 'Whatsapp' },
			});
		} else if (title === 'Instagram') {
			navigate('setup-wa-insta', {
				state: { value: 'Instagram' },
			});
		}
	};
	return (
		<>
			<div className='welcome-screen-wrapper'>
				<main>
					<section className='welcome-body'>
						<header>
							<h3>Welcome to Flyfish!</h3>
							<span className='body2'>How would you like to start?</span>
						</header>
						<div className='cards-container'>
							{/* <div
								className='create-ad-card create-ad-bg-image'
								onClick={() => navigate('/app/catalog/intelligent-ad')}>
								<h5>Create an Intelligent Ad</h5>
								<span className='body2'>
									Make your ads more effective by turning them into
									conversations
								</span>
							</div>
							<div
								className='create-ad-card virtual-sales-bg-image'
								onClick={() =>
									navigate('/app/catalog/multichannel-storefront')
								}>
								<h5 className='multichannelStorefront'>
									Create a Multichannel Storefront
								</h5>
								<span className='body2'>
									Leverage conversational e-commerce experience to power your
									sales.
								</span>
							</div> */}
							{welcomeCards.map((image) => {
								return (
									<img
										src={image.src}
										alt=''
										onClick={() => {
											if (image.title === 'Campaigns') {
												// navigate('/app/my-studio/intelligent-ad');
												navigate('/app/create-ai');
											} else if (image.title === 'Storefronts') {
												// navigate('/app/catalog/multichannel-storefront')
												navigate(
													'/app/catalog/multichannel-storefront/sales-ai/catalog'
												);
											} else {
												navigate('/app/catalog/faq/design/build/FAQs');
											}
										}}
									/>
								);
							})}
						</div>
					</section>
					<section className='welcome-footer'>
						<h5>Set up Your Sales Channel</h5>
						<span className='body2'>
							Empower your sales by getting a presence on different channels
						</span>
						<div className='channel-carousel-wrapper'>
							{/* <img
							className='channel-arrow-icon'
							src={PreviousArrow_Icon}
							alt='previous-arrow'
							style={{ marginRight: '24px' }}
						/> */}

							{channelcards.map((card, index) => {
								return (
									<div
										onClick={() => {
											handleWelcomeOnClick(card.title);
										}}
										key={index.toString()}
										className={`channel-carousel-slider-list-item ${
											card.title === 'WhatsApp'
												? 'whatsapp-bg-img'
												: card.title === 'Apple Business Chat'
												? 'applechat-bg-img'
												: card.title === 'Google Business Messaging'
												? 'google-bg-img'
												: 'instagram-bg-img'
										}`}>
										<img src={card.image} alt='card' />
										<span className='body2'>{card.title}</span>
									</div>
								);
							})}
							{/* 
						<img
							className='channel-arrow-icon'
							src={NextArrow_Icon}
							alt='next-arrow'
						/> */}
						</div>
						<Button
							size='small'
							buttonType='light'
							onClick={() => navigate('/app/create-ai')}>
							Skip
						</Button>
					</section>
				</main>
			</div>
			<Outlet />
			{createInsta && <CreateInsta setCreateInsta={setCreateInsta} />}
			{createInstaFailed && (
				<CreateInstaFailed setCreateInstaFailed={setCreateInstaFailed} />
			)}
			{existingAccount && (
				<InstaAccountAlreadyAdded setExistingAccount={setExistingAccount} />
			)}
		</>
	);
};
export default WelcomeScreen;
