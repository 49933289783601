import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import { useSelector } from 'react-redux';
import Modal from '../../../Components/Modal';
import Button from '../../../Components/Button';
import {
	removeFileId,
	resetRemoveFileStatus,
	setUploadedFiles,
} from '../../../Features/Ingest/IngestSlice';
import { setSnackModal } from '../../../Features/message/messageSlice';
import { loadState } from '../../../utils/utils';

const RemoveDocument = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const {
		uploadedFiles,
		selectedremoveFile,
		removeFileApiStatus,
	} = useSelector((state: RootState) => state.ingest);
	const [buttonLoader, setButtonLoader] = useState(false);
	useEffect(() => {
		if (removeFileApiStatus === 'success') {
			setButtonLoader(false);
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: 'Your action was successful',
				})
			);

			if (
				(uploadedFiles &&
					uploadedFiles.fileUpload &&
					uploadedFiles.fileUpload.length === 0) ||
				(uploadedFiles.ecom && uploadedFiles.ecom.length === 0)
			) {
				navigate('/app/Ingest');
			} else {
				navigate(-1);
			}
		} else if (removeFileApiStatus === 'failed') {
			setButtonLoader(false);

			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage:
						'Unable to remove document at the moment. Please try after sometime',
				})
			);
		} else if (removeFileApiStatus === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetRemoveFileStatus());
	}, [removeFileApiStatus]);

	return (
		<div>
			<Modal
				headerCloseBtn
				header='Remove Document?'
				action='done'
				actionButtons={
					<>
						<Button
							buttonType='light'
							onClick={() => {
								navigate(-1);
							}}
						>
							Cancel
						</Button>
						<Button
							loading={buttonLoader}
							onClick={() => {
								let removedFile = uploadedFiles.fileUpload.filter(function(
									e: any
								) {
									return e.id !== selectedremoveFile.id;
								});
								dispatch(
									setUploadedFiles({
										...uploadedFiles,
										fileUpload: removedFile,
									})
								);

								dispatch(
									removeFileId({
										botId: loadState('botId'),
										tenantId: tenantId,
										docId: selectedremoveFile.id,
									})
								);
							}}
						>
							Remove
						</Button>
					</>
				}
			>
				<p>
					Your AI won't be able to answer user questions based on this document
					any more
				</p>
			</Modal>
		</div>
	);
};

export default RemoveDocument;
