import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store';
import WhatsAppSVG, { ChevronRight } from '../../assets/Icons/WhatsApp';
import Header2 from '../../Components/Header2';
import { loadState } from '../../utils/utils';

import './WhatsAppBsp.scss';
import whatsAppBspRoutes from './whatsAppBspRoutes';

const WhatsAppBSP = () => {
	// const wabaNo = loadState('wabaNo');
	const { wabaNo } = useSelector((state: RootState) => state.BSPauth);
	const navigate = useNavigate();
	const leftComponent = (
		<div className='left-component'>
			<div
				style={{ cursor: 'pointer' }}
				onClick={() => navigate('/app/launch')}>
				<ChevronRight />
			</div>
			<WhatsAppSVG width='36' height='36' color='#455a64' />
			<h6 style={{ marginLeft: '8px' }}>{wabaNo}</h6>
		</div>
	);
	return (
		<div className='ff-whatsappbsp'>
			<Header2 leftComponent={leftComponent} breadcrumbs={whatsAppBspRoutes} />
			<main className='whatsApp'>
				<Outlet />
			</main>
		</div>
	);
};

export default WhatsAppBSP;
