import { KampstaTenantBadgeBackground } from '../../../utils/constants';
import './teamBadge.scss';
import '../Userbadge/userBadge.scss'

type Props = {
	Team: string;
	badge?: string;
};

function TeamBadge (props:Props) {
  return (
<div
          className={ 
            props.badge==='circle' ? 'kampsta-tenant-badge' : 'kampsta-user-badge'
          }
          style={
              KampstaTenantBadgeBackground[
                ((props.Team[0] || 'Fallback Team').toUpperCase().charCodeAt(0) +
                 props.Team.charCodeAt(props.Team.length - 1) ||
                 props.Team.length) % 30
              ]
        }>
          {props.Team[0]?.toUpperCase() || 'F'}
        </div>
  )
}

export default TeamBadge





