import React from 'react';
import './PopupNotification.scss';
import NotifyMessage from '../NotifyMessage';
export type PopupProps = {
	type?: 'info' | 'alert' | 'chat' | string;
	message: string;
	link: string;
	time: string;
};
const PopupNotification = ({
	type = 'info',
	message,
	link,
	time,
}: PopupProps) => {
	return (
		<div className='ff-popup-notification'>
			<NotifyMessage
				type={type}
				message={message}
				link={link}
				time={time}
				read={1}
				req={true}
			/>
		</div>
	);
};

export default PopupNotification;
