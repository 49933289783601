import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import Modal from '../../../../Components/Modal';
import {
	deactiveWaba,
	removeInstaPage,
	reset,
	wabaInsta,
	WabaNo,
} from '../../../../Features/WhatsApp-BSP/auth/authSlice';
import '../Modal.scss';

interface Props {
	Details: wabaInsta | undefined;
	setDeactivate: (arg: boolean) => void;
}
export const Deactivate = ({ Details, setDeactivate }: Props) => {
	const [mobileOrUsername, setMobileOrUsername] = useState('');
	const [mobileOrUsernameError, setMobileOrUsernameError] = useState<
		string | undefined
	>(undefined);
	const dispatch = useAppDispatch();

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {}, []);

	const handleDelete = () => {
		if (Details?.pageName) {
			//handle insta delete
			dispatch(removeInstaPage({ pageId: Details.pageId || '' }));
		} else if (!Details?.pageName) {
			dispatch(deactiveWaba({ wabaNo: Details?.wabaNo || '' }));
		}
		setDeactivate(false);
	};

	const validatePhoneOrUsername = () => {
		// var pattern = new RegExp(/^[0-9\b]+$/);
		if (Details?.pageName) {
			mobileOrUsername !== Details?.instaUsername
				? setMobileOrUsernameError('Invalid username')
				: setMobileOrUsernameError(undefined);
		} else if (!Details?.pageName) {
			mobileOrUsername !== Details?.wabaNo
				? setMobileOrUsernameError('Invalid number')
				: setMobileOrUsernameError(undefined);
		}
	};

	return (
		<Modal
			header={`Remove ${
				Details?.pageName ? Details.instaUsername : Details?.wabaNo
			}?`}
			actionButtons={
				<>
					<Button buttonType='light' onClick={() => setDeactivate(false)}>
						Cancel
					</Button>
					<Button
						onClick={() => handleDelete()}
						disabled={
							Details?.pageName
								? !(mobileOrUsername === Details?.instaUsername)
									? true
									: false
								: !(mobileOrUsername === Details?.wabaNo)
								? true
								: false
						}>
						Remove
					</Button>
				</>
			}
			onClose={() => setDeactivate(false)}>
			<div className='modal-body'>
				{Details?.pageName ? (
					<p className='body1'>
						This will remove {Details?.pageName || '-'} from your channels in
						Flyfish. This will not delete this account completely, only remove
						it from Flyfish. You will still be able to see past conversations in
						Flyfish Analytics after removing it.
					</p>
				) : (
					<p className='body1'>
						Please confirm, do you wish to remove the WhatsApp number{' '}
						{Details?.wabaNo || '-'}
						&nbsp; from Flyfish?
					</p>
				)}

				<p className='body1'>
					{Details?.pageName
						? 'If you wish to continue removing this account, please confirm by entering the Instagram username below:'
						: 'As a safety measure, please enter the WhatsApp number to remove it.'}
				</p>
				<Input
					placeholder={`${
						Details?.pageName ? 'Enter username' : 'Enter number'
					}`}
					fullWidth
					value={mobileOrUsername}
					onChange={(event: any) => {
						setMobileOrUsername(event.target.value);
						setMobileOrUsernameError(undefined);
					}}
					error={Boolean(mobileOrUsernameError)}
					helperText={mobileOrUsernameError}
					onBlur={validatePhoneOrUsername}
				/>
			</div>
		</Modal>
	);
};
