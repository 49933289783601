import styled from 'styled-components';

export type GCBPreviewProps = {
	iconOutSide: boolean;
};

export const GCBPreview = styled.div<GCBPreviewProps>`
	max-width: 360px;
	min-width: 360px;
	border-left: 1px solid var(--black-20);
	background-color: ${(props) => (props.iconOutSide ? '' : 'var(--white)')};

	& > p.bot-preview-header {
		display: flex;
		justify-content: center;
		background: var(--pearl-grey);
		padding: 4px 0;
		color: var(--black-60);
	}
`;
