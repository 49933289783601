import React from 'react';

export const Checkbox = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='Group 4188'
			width='20'
			height='20'
			viewBox='0 0 20 20'>
			<path data-name='Path 2127' d='M0 0h20v20H0z' fill='none' />
			<path
				data-name='Path 2128'
				d='M16.333 4.667v11.666H4.667V4.667h11.666m0-1.667H4.667A1.672 1.672 0 0 0 3 4.667v11.666A1.672 1.672 0 0 0 4.667 18h11.666A1.672 1.672 0 0 0 18 16.333V4.667A1.672 1.672 0 0 0 16.333 3z'
				transform='translate(-.5 -.5)'
				fill='var(--ff-serverMsgColor)'
			/>
		</svg>
	);
};

export const ChecboxChecked = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='Group 4188'
			width='20'
			height='20'>
			<path
				data-name='Path 2127'
				d='M0 0h20v20H0z'
				fill='var(--ff-serverMsgBackground)'
			/>
			<path
				data-name='Path 2126'
				d='M16.333 3H4.667A1.666 1.666 0 0 0 3 4.667v11.666A1.666 1.666 0 0 0 4.667 18h11.666A1.666 1.666 0 0 0 18 16.333V4.667A1.666 1.666 0 0 0 16.333 3zm-7.5 11.667L4.667 10.5l1.175-1.175 2.992 2.983 6.325-6.325 1.175 1.183z'
				transform='translate(-.5 -.5)'
				fill='var(--ff-serverMsgColor)'
			/>
		</svg>
	);
};
