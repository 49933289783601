import React, { useEffect, useRef, useState } from 'react';
import { ChecboxChecked, Checkbox } from '../MultiSelect/Icons';
import './Cards.scss';
type Props = {
	cardType: 'multichoice' | 'multiselect';
	cardMediaType: string;
	src: string;
	alt?: string;
	type?: string;
	title: string;
	description?: string;
	flag?: boolean;
};

const Cards = ({
	cardType = 'multichoice',
	cardMediaType = 'image',
	src,
	alt,
	type,
	title = '',
	description = '',
	flag = false,
}: Props) => {
	const cardRef = useRef<HTMLDivElement>(null);
	const [hrWidth, setHrWidth] = useState(0);

	useEffect(() => {
		if (cardRef.current) {
			const width = cardRef.current.offsetWidth;
			setHrWidth(width);
		}
	}, [cardRef.current]);
	return (
		<div className='ff-chatbot-card-parent-container'>
			<div className='ff-chatbot-cards'>
				<header>
					{cardMediaType === 'image' ? (
						<div className='image-container'>
							<img src={src} alt={alt} />
						</div>
					) : (
						<>
							<div className='video-container'>
								{cardMediaType === 'video' ? (
									<video controls>
										<source src={src} type={type}></source>
									</video>
								) : null}
								{cardMediaType === 'youtube' || cardMediaType === 'vimeo' ? (
									<iframe src={src} title={src} />
								) : null}
							</div>
						</>
					)}
				</header>
				<div className='card-info' ref={cardRef}>
					<div className='title-description-holder'>
						<div
							className={`title button1 ${
								description.length > 0 ? '' : 'no-description'
							} ${
								cardType === 'multiselect' && description.length === 0
									? 'type-multiselect-no-description'
									: ''
							}`}>
							{title}
						</div>
						<div
							className={`description caption ${
								cardType === 'multiselect' && description.length > 0
									? 'type-multiselect-with-description'
									: ''
							}`}>
							{description}
						</div>
					</div>
					{cardType === 'multiselect' ? (
						<div className='checkbox-holder'>
							<hr style={{ width: hrWidth }} />
							<div className='multiselect-option'>
								{flag ? <ChecboxChecked /> : <Checkbox />}
								<p className='body2'>Select</p>
							</div>
						</div>
					) : null}
				</div>
			</div>
			<div className='ff-chatbot-cards-overlay'></div>
		</div>
	);
};

export default Cards;
