import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import './RedirectURI.scss';
const RedirectURI = () => {
	const { linkState } = useSelector((state: RootState) => state.zoho);
	const [isLoading, setIsLoading] = useState(true);
	const iframeStyles = {
		width: '100vw',
		height: 'calc(100vh - 62px)',
	};

	const zohoCRMLoader = () => {
		return (
			<div className='ff-zohoCRMLoader-loader'>
				<div>
					<div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='56'
							height='56'
							viewBox='0 0 56 56'>
							<g data-name='main icon'>
								<path
									data-name='Subtraction 6'
									d='M22.909 45.818a22.909 22.909 0 0 1 0-45.818v5.09a17.819 17.819 0 1 0 17.816 17.819h5.093a22.935 22.935 0 0 1-22.909 22.909z'
									fill='#3b44c4'
									transform='translate(5.093 5.09)'
								/>
							</g>
						</svg>
					</div>
					<h3>Linking your Flyfish team with Zoho CRM</h3>
					<p>Please don’t refresh the page or press the back button</p>
				</div>
			</div>
		);
	};

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 5000);
	}, []);
	return (
		<>
			<div className='ff-zoho-crm-redirectURI'>
				{isLoading ? (
					zohoCRMLoader()
				) : (
					<>
						{linkState.url.indexOf('https') > -1 ? (
							<iframe
								title={'ff-zoho-crm-redirectURI-8909'}
								src={linkState.url}
								style={iframeStyles}
							/>
						) : null}
					</>
				)}
			</div>
		</>
	);
};

export default RedirectURI;
