import CryptoJS from 'crypto-js';
import {
	differenceInDays,
	format,
	isToday,
	isYesterday,
	startOfDay,
	subDays,
	getTime as getTimestamp,
	intlFormatDistance,
	formatDistanceToNow,
	parse,
	addMinutes,
	toDate,
	subMinutes,
} from 'date-fns';
import { Action } from '../Features/builder/model';
import { cardBg, iconBG } from './constants';
import * as XLSX from 'xlsx';

export const loadState = (key: string) => {
	try {
		const serializeState = localStorage.getItem(key);
		if (!serializeState) return undefined;
		return JSON.parse(serializeState);
	} catch (error) {
		return undefined;
	}
};

export const saveState = (key: string, state: any) => {
	try {
		const serializeState = JSON.stringify(state);
		localStorage.setItem(key, serializeState);
	} catch (error) {
		// Ignore
	}
};

export const removeState = (key: string) => {
	try {
		localStorage.removeItem(key);
	} catch (error) {
		return undefined;
	}
};

export const removeAllState = () => {
	try {
		localStorage.clear();
		sessionStorage.clear();
	} catch (error) {
		return undefined;
	}
};

export const encryptText = (text: string) => {
	let key = process.env.REACT_APP_SECRET_KEY!;
	return CryptoJS.AES.encrypt(text, key).toString();
};

// export const decryptText = (text: string) => {
// 	let key = process.env.REACT_APP_SECRET_KEY!;
// 	return CryptoJS.AES.decrypt(text, key).toString();
// };

export const getBase64 = async (file: Blob) => {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result?.toString().split(',')[1] ?? '');
		};

		reader.onerror = (error) => {
			reject(error);
		};

		reader.readAsDataURL(file);
	});
};

let timeout: NodeJS.Timer | number;
export const debounce = (cb: Function, time: number) => {
	timeout && clearTimeout(timeout as number);
	timeout = setTimeout(() => {
		cb();
	}, time);
};

export const checkUrl = (url: string) => {
	const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi; //NOSONAR
	const regex = new RegExp(expression); //NOSONAR

	return url.match(regex) ? true : false;
};

export const hexToRGB = (hex: string, alpha: number) => {
	var r = parseInt(hex.slice(1, 3), 16),
		g = parseInt(hex.slice(3, 5), 16),
		b = parseInt(hex.slice(5, 7), 16);

	if (alpha || alpha === 0) {
		return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
	} else {
		return 'rgb(' + r + ', ' + g + ', ' + b + ')';
	}
};

const componentToHex = (c: any) => {
	var hex = c.toString(16);
	return hex.length === 1 ? '0' + hex : hex;
};

export const rgbToHex = (rgb: any, range: boolean = false) => {
	let value = rgb.substring(5).split(',');
	if (range && value.length === 4) {
		return `${parseInt(value[3].split(')')[0].trim()) * 100}`;
	} else {
		return (
			'#' +
			componentToHex(parseInt(value[0])) +
			componentToHex(parseInt(value[1])) +
			componentToHex(parseInt(value[2]))
		);
	}
};

export const generateBotName = (prefix: string, bots: any[]) => {
	let count: number[] = bots
		.map((bot) => (bot.botName.includes(prefix) ? bot.botName : null))
		.map((name) => {
			let number = Number(name?.replace(prefix, ''));

			if (!isNaN(number)) return number;
			return 0;
		})
		.filter((num) => typeof num === 'number');

	if (count.length === 0) {
		count.push(0);
	}

	return `${prefix} ${Math.max(...count) + 1}`;
};

const generateName = (actionName: string, actions: Action[]) => {
	let count: number[] = actions
		.map((action) =>
			action.task_name.includes(actionName) ? action.task_name : null
		)
		.map((name) => {
			let number = Number(name?.replace(actionName, ''));

			if (!isNaN(number)) return number;
			return 0;
		})
		.filter((num) => typeof num === 'number');

	if (count.length === 0) {
		count.push(0);
	}

	return `${actionName} ${Math.max(...count) + 1}`;
};

export const getActionName = (actions: Action[], actionType: number) => {
	switch (actionType) {
		case 1: {
			return generateName('Text/Media', actions); //Design-Ad
		}
		case 3: {
			return generateName('Multiple Select Options', actions);
		}
		case 5: {
			return generateName('Multiple Choice Options', actions);
		}
		case 8: {
			return generateName('Confirm Collected Values', actions);
		}
		case 10: {
			return generateName('Call an API', actions);
		}
		case 11: {
			return generateName('Collect User Rating', actions);
		}
		case 12: {
			return generateName('Trigger another Query', actions);
		}
		case 150: {
			return generateName('Collect User Input', actions);
		}
		case 14: {
			return generateName('Send Collected Values', actions);
		}
		case 0: {
			return generateName('Handoff To Agent', actions);
		}
	}
	return generateName('Untitled', actions);
};

export const getRandom = (prefix: string, length: number) => {
	return `${prefix}${Math.random() //NOSONAR
		.toString(36)
		.substring(2, length + 2)}`;
};

export const dateFormat = (date: string) => {
	const new_date = new Date(date)
		.toISOString()
		.split('T')[0]
		.split('-')
		.reverse()
		.join('-');
	const time = new Date(date).toLocaleTimeString().split(':');
	return `${new_date} ${time[0].padStart(2, '0')}:${time[1].padStart(
		2,
		'0'
	)} ${time[2].substring(3, 5)}`.toLowerCase();
};

export const DownloaddateFormat = (date: string) => {
	const currentdate = new Date(date);

	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	const cdate = currentdate.getDate();
	const cyear = currentdate.getFullYear();
	const month = months[currentdate.getMonth()];
	const day = currentdate.toString().split(' ')[0];
	// Fri, 06 Oct 2023 09:48 AM
	const formattedNewDate = cdate + ' ' + month + ' ' + cyear;

	const time = new Date(date).toLocaleTimeString().split(':');
	return `${day}, ${formattedNewDate} ${time[0].padStart(
		2,
		'0'
	)}:${time[1].padStart(2, '0')} ${time[2].substring(3, 5).toUpperCase()}`;
};

export const formatWaba = (waba: string) => {
	return `+${waba.substring(0, waba.length - 10)} ${waba.substring(
		waba.length - 10
	)}`;
};

export const isValidDate = (date: string | undefined) => {
	if (date) {
		const timestamp = Date.parse(date);

		if (!isNaN(timestamp)) {
			return true;
		}
	}
	return false;
};

export const isValidTimeCheck = (time: string | undefined | any) => {
	if (time) {
		const timestamp = Date.parse(time);

		if (!isNaN(timestamp)) {
			return true;
		}
	}
	return false;
};
export const secondsToMinutesSeconds = (seconds: number) => {
	const mins = Math.floor(seconds / 60);

	return `${mins}:${Math.floor(seconds % 60)

		.toString()

		.padStart(2, '0')}`;
};

export const convertDate = (date: Date | null, time: Date | null) => {
	if (date && time) {
		const formattedDate = format(date, 'yyyy-MM-dd');
		const formattedTime = format(time, 'HH:mm:ss');

		return new Date(`${formattedDate} ${formattedTime}`);
	}
};
export const getValuewithK = (num: number) => {
	if (num > 1000 && num < 999999) {
		const valuebyk = num / 1000;
		const [T, H] = valuebyk.toString().split('.');
		const value = H !== undefined ? [T, H[0]].join('.') : T;
		return `${value}k`;
	} else if (num > 1000000 && num < 999999999) {
		const valuebym = num / 1000000;
		const [T, H] = valuebym.toString().split('.');
		const value = H !== undefined ? [T, H[0]].join('.') : T;
		return `${value}M`;
	} else if (num > 1000000000 && num < 999999999999) {
		const valuebyB = num / 1000000000;
		const [T, H] = valuebyB.toString().split('.');
		const value = H !== undefined ? [T, H[0]].join('.') : T;
		return `${value}B`;
	} else {
		return num;
	}
};
export const formatMinsecTime = (time: any) => {
	let data = time.split(':');
	if (data[0] > 0) {
		return data[0] + ' Hr ' + data[1] + ' min  ' + data[2] + ' Sec';
	} else {
		return data[1] + ' min  ' + data[2] + ' Sec';
	}
};
export const getTime = (date: string) =>
	format(new Date(date.indexOf('Z') > -1 ? date : date + 'Z'), 'hh:mm aaa');

export const getDay = (date: string) => {
	let d = startOfDay(new Date(date));
	let now = new Date();
	let difference = differenceInDays(now, d);
	if (difference <= 6) {
		if (isToday(d)) return 'today';
		else if (isYesterday(d)) return 'yesterday';
		else {
			return format(subDays(now, difference), 'cccc');
		}
	}
	return getTimestamp(d).toString();
};

export const timeStamp = (timeRec: string) => {
	const time = timeRec.indexOf('Z') > -1 ? timeRec : timeRec + 'Z';
	const secondstoNow = (data: any) => {
		if (data.indexOf('second') > -1) {
			return 'now';
		}
		return data;
	};
	if (time) {
		const key = isToday(new Date(time))
			? 'today'
			: isYesterday(new Date(time))
			? 'yesterday'
			: time;
		return key === 'today'
			? secondstoNow(
					intlFormatDistance(new Date(time), new Date())
						.replace('ago', '')
						.replace('minutes', 'min')
						.replace('minute', 'min')
			  )
			: key === 'yesterday'
			? 'yesterday'
			: format(new Date(time), 'dd-MM-yyyy');
	} else {
		return '';
	}
};

export const checkImageURL = (url: string) => {
	if (url.indexOf('drive.google.com') > -1) {
		return url.match('https://drive.google.com/file/d/(.*)/view') !== null;
	}
	return (
		url.match(
			/^https[^\?]*.(jpeg|png|jpg|webp|gif|svg|bmp|apng|avif)(\?(.*))?$/gim
		) != null
	); //NOSONAR
};

export const checkVideoURL = (url: string) => {
	if (url.indexOf('youtube') > -1) {
		return url.match(
			/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
		); //NOSONAR
	} else if (url.indexOf('mp4') > -1) {
		return url.match(/^https[^\?]*.(mp4)(\?(.*))?$/gim) != null; //NOSONAR
	} else if (
		url.indexOf('vimeo') > -1 ||
		url.match('https://drive.google.com/file/d/(.*)/view') !== null
	) {
		return true;
	}
};
export const findMediaType = (type: string, url: string) => {
	if (type === 'video') {
		if (url.includes('mp4')) {
			return 'video/mp4';
		} else if (url.includes('embed')) {
			return 'youtube';
		} else if (url.includes('vimeo')) {
			return 'vimeo';
		}
	} else if (type === 'image') {
		if (url.includes('jpeg')) {
			return 'image/jpeg';
		} else if (url.includes('png')) {
			return 'image/png';
		} else if (url.includes('jpg')) {
			return 'image/jpg';
		} else {
			return '';
		}
	}
};

export const isHTMLTagsPresent = (
	input: string,
	isSpecialCharValidation?: boolean | false
) => {
	return (
		input.match('<("[^"]*"|\'[^\']*\'|[^\'">])*>') != null || //NO SONAR
		(!isSpecialCharValidation && input.match('^[-a-zA-Z0-9_., ]*$') === null) //NO SONAR
	); //NO SONAR
};

export const getMediaType = (mediaType: string) => {
	if (mediaType.indexOf('image') > -1) {
		return 'image';
	} else if (mediaType.indexOf('video') > -1) {
		return 'video';
	} else {
		return mediaType;
	}
};

export const getBgAndIconColor = (text: string, index: number) => {
	const randomNum = index % 8;
	return text === 'card' ? cardBg[randomNum] : iconBG[randomNum];
};

export const getPath = (cavSRType: string) => {
	return cavSRType === 'CA' ? 'intelligent-ad' : 'multichannel-storefront';
};

export const getRandomNumber = (length: number) => {
	let time = new Date().getTime();
	return `${time + length}`;
};

export const getCurrentTime = (chatTime: string) => {
	const time =
		chatTime && chatTime.length > 0
			? new Date(chatTime).toLocaleTimeString().split(':')
			: new Date().toLocaleTimeString().split(':');
	return (
		time[0].padStart(2, '0') +
		':' +
		time[1].padStart(2, '0') +
		' ' +
		time[2].substring(3, 5).toLowerCase()
	);
};
export const getCurrentDateTime = (chatTime: string) => {
	const date = chatTime && chatTime.length > 0 ? chatTime.split('T')[0] : '';
	const time =
		chatTime && chatTime.length > 0
			? new Date(chatTime).toLocaleTimeString().split(':')
			: new Date().toLocaleTimeString().split(':');

	return (
		date +
		' ' +
		time[0].padStart(2, '0') +
		':' +
		time[1].padStart(2, '0') +
		' ' +
		time[2].substring(3, 5).toLowerCase()
	);
};

export const colorPositionOnScale = (color: string) => {
	let scaleSize = 10;
	const selectedColor = color.slice(1); // Remove '#' from the beginning

	const r = parseInt(selectedColor.substring(0, 2), 16);
	const g = parseInt(selectedColor.substring(2, 4), 16);
	const b = parseInt(selectedColor.substring(4, 6), 16);
	let lum = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
	let colorPosition = Math.ceil(lum / 0.1);
	// const sum = r + g + b;
	// const step = 765 / scaleSize;
	// let colorPosition = Math.floor(sum / step) - 1;
	return {
		colorPosition: colorPosition,
		numberOfLighterColor: scaleSize - colorPosition - 1,
		numberOfDarkerColor: colorPosition,
	};
};

export const arrayOfdarkerColors = (
	originalColor: string,
	colorPosition: number,
	n: number //numberOfDarkerColor
) => {
	let darkColors = [];
	const selectedColor = originalColor.slice(1); // Remove '#' from the beginning
	const r = parseInt(selectedColor.substring(0, 2), 16);
	const g = parseInt(selectedColor.substring(2, 4), 16);
	const b = parseInt(selectedColor.substring(4, 6), 16);
	for (let i = 1; i <= colorPosition - 1; i++) {
		let red = Math.floor(r - (r / colorPosition) * (colorPosition - i));
		let green = Math.floor(g - (g / colorPosition) * (colorPosition - i));
		let blue = Math.floor(b - (b / colorPosition) * (colorPosition - i));
		darkColors.push(`rgb(${red},${green},${blue})`);
	}

	return darkColors;
};

export const arrayOflighterColors = (
	originalColor: string,
	colorPosition: number,
	n: number //numberOfLighterColor
) => {
	let lighterColors = [];
	let scaleSize = 10;
	const selectedColor = originalColor.slice(1); // Remove '#' from the beginning
	const r = parseInt(selectedColor.substring(0, 2), 16);
	const g = parseInt(selectedColor.substring(2, 4), 16);
	const b = parseInt(selectedColor.substring(4, 6), 16);
	for (let i = colorPosition + 1; i <= scaleSize; i++) {
		let red = Math.floor(
			r + ((255 - r) / (scaleSize - colorPosition + 1)) * (i - colorPosition)
		);
		let green = Math.floor(
			g + ((255 - g) / (scaleSize - colorPosition + 1)) * (i - colorPosition)
		);
		let blue = Math.floor(
			b + ((255 - b) / (scaleSize - colorPosition + 1)) * (i - colorPosition)
		);
		lighterColors.push(`rgb(${red},${green},${blue})`);
	}

	return lighterColors;
};

export const arrayOfAllColors = (color: string) => {
	let selectedColor = colorPositionOnScale(color);
	let darkerColors = arrayOfdarkerColors(
		color,
		selectedColor.colorPosition,
		selectedColor.numberOfDarkerColor
	);
	let lighterColors = arrayOflighterColors(
		color,
		selectedColor.colorPosition,
		selectedColor.numberOfDarkerColor
	);
	return [...darkerColors, color, ...lighterColors];
};

export const relativeLuminance = (color: string) => {
	const selectedColor = color.slice(1);
	const r = parseInt(selectedColor.substring(0, 2), 16);
	const g = parseInt(selectedColor.substring(2, 4), 16);
	const b = parseInt(selectedColor.substring(4, 6), 16);
	return (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255 < 0.5 ? 'dark' : 'light';
};

export const nameOfCrmsLinked = (crmsLinkedData: any) => {
	let nameOfCrms = crmsLinkedData.map((crm: any) =>
		crm.crmName.toLowerCase().indexOf('zoho') > -1
			? { label: 'Zoho', value: 'Zoho' }
			: {
					label: crm.crmName.charAt(0).toUpperCase() + crm.crmName.slice(1),
					value: crm.crmName.charAt(0).toUpperCase() + crm.crmName.slice(1),
			  }
	);
	return nameOfCrms;
};

export const TimeFormatting = (timeStamp: string, notReq: boolean = false) => {
	const utcTimestamp = timeStamp; //'2023-08-21T04:00:12.028+00:00';
	const utcDate = new Date(utcTimestamp);

	const formattedTimeAgo = formatDistanceToNow(utcDate, { addSuffix: true });
	if (formattedTimeAgo.indexOf('minute') > -1) {
		if (formattedTimeAgo === 'less than a minute ago') {
			return 'now';
		}
		return `${formattedTimeAgo.split(' ')[0]}m`;
	} else if (formattedTimeAgo.indexOf('hour') > -1) {
		return `${formattedTimeAgo.split(' ')[1]}h`;
	} else if (formattedTimeAgo.indexOf('day') > -1) {
		if (notReq) {
			return null;
		}
		if (formattedTimeAgo.indexOf('1 day') > -1) {
			return 'Yesterday';
		}
		return 'Older';
	}
	if (notReq) {
		return null;
	}
	return 'Older';
};

export const NumberFormattinginLocale = (number: number) => {
	return new Intl.NumberFormat().format(number);
};

export const calculateTimeZoneOffset = (timeZone: string) => {
	// Parse the time zone string in the "GMT+5:30" format
	const match = timeZone.match(/^GMT([+-])(\d+):?(\d*)$/);

	if (!match) {
		// Invalid format, return 0 or handle the error as needed
		return 0;
	}

	const [, sign, hours, minutes] = match;

	// Convert hours and minutes to integers
	const offsetHours = parseInt(hours, 10);
	const offsetMinutes = parseInt(minutes || '0', 10);

	// Calculate the total offset in minutes
	const totalOffsetMinutes =
		(offsetHours * 60 + offsetMinutes) * (sign === '-' ? -1 : 1);

	return totalOffsetMinutes;
};

export const toUTCTime = (
	currentTime: string,
	timeZone: string,
	day: string
) => {
	const sign = timeZone.indexOf('-') > -1 ? 1 : -1;
	const timeZ = timeZone.slice(4);

	const hours1 = parseInt(currentTime.split(':')[0]);
	const minutes1 = parseInt(currentTime.split(':')[1]);

	const gmtOffsetHours = sign * Number(timeZ.split(':')[0]);
	const gmtOffsetMinutes = sign * Number(timeZ.split(':')[1]);
	// Calculate the total offset in minutes
	const totalOffsetMinutes = gmtOffsetHours * 60 + gmtOffsetMinutes;
	const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	const indexOfDay = days.indexOf(day);
	// Create a Date object based on the total offset
	const currentUTCDate = new Date();
	const indexOfCurrentDate = days.indexOf(days[currentUTCDate.getDay()]);

	const value = indexOfCurrentDate - indexOfDay;
	currentUTCDate.setDate(currentUTCDate.getDate() - value);

	currentUTCDate.setHours(hours1, minutes1);
	const dateInGMT = new Date(
		currentUTCDate.getTime() + totalOffsetMinutes * 60 * 1000
	);

	return `${days[dateInGMT.getDay()]},${dateInGMT
		.getHours()
		.toString()
		.padStart(2, '0')}:${dateInGMT
		.getMinutes()
		.toString()
		.padStart(2, '0')}`;
};

export const toLocalTime = (
	currentTime: string,
	timeZone: string,
	day: string
) => {
	const sign = timeZone.indexOf('-') > -1 ? -1 : 1;
	const timeZ = timeZone.slice(4);

	const hours1 = parseInt(currentTime.split(':')[0]);
	const minutes1 = parseInt(currentTime.split(':')[1]);

	const gmtOffsetHours = sign * Number(timeZ.split(':')[0]);
	const gmtOffsetMinutes = sign * Number(timeZ.split(':')[1]);
	// Calculate the total offset in minutes
	const totalOffsetMinutes = gmtOffsetHours * 60 + gmtOffsetMinutes;
	const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	const indexOfDay = days.indexOf(day);

	// Create a Date object based on the total offset
	const currentUTCDate = new Date();
	const indexOfCurrentDate = days.indexOf(days[currentUTCDate.getDay()]);
	if (indexOfCurrentDate > indexOfDay) {
		currentUTCDate.setDate(currentUTCDate.getDate() - 1);
	} else if (indexOfCurrentDate < indexOfDay) {
		currentUTCDate.setDate(currentUTCDate.getDate() + 1);
	}
	currentUTCDate.setHours(hours1, minutes1);

	const dateInGMT = new Date(
		currentUTCDate.getTime() + totalOffsetMinutes * 60 * 1000
	);

	return `${days[dateInGMT.getDay()]},${dateInGMT
		.getHours()
		.toString()
		.padStart(2, '0')}:${dateInGMT
		.getMinutes()
		.toString()
		.padStart(2, '0')}`;
};

export const ExcelTOJSON = (file: any, dispatch: any) => {
	const reader = new FileReader();
	reader.onload = (e) => {
		if (e.target) {
			const data = e.target.result;

			const workbook = XLSX.read(data, { type: 'binary' });

			// Assuming you have a single sheet in the Excel file
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			// Convert the worksheet data to JSON
			const jsonData = XLSX.utils.sheet_to_json(worksheet);
			// Remove the '__rowNum__' key from each object
			const jsonDataWithoutRowNum = jsonData.map((obj: any) => {
				const newObj = { ...obj };
				delete newObj['__rowNum__'];
				return newObj;
			});
			dispatch(jsonDataWithoutRowNum);
		}
	};
	reader.readAsBinaryString(file);
};
export const loadStateSession = (key: string) => {
	try {
		const serializeState = sessionStorage.getItem(key);
		if (!serializeState) return undefined;
		return JSON.parse(serializeState);
	} catch (error) {
		return undefined;
	}
};

export const saveStateSession = (key: string, state: any) => {
	try {
		const serializeState = JSON.stringify(state);
		sessionStorage.setItem(key, serializeState);
	} catch (error) {
		// Ignore
	}
};

export const removeStateSession = (key: string) => {
	try {
		sessionStorage.removeItem(key);
	} catch (error) {
		return undefined;
	}
};

export const getCurrencySymbol = (currencyCode: string, locale = 'en-US') => {
	try {
		const currencyFormatter = new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: currencyCode,
		});

		const currencySymbol =
			currencyFormatter
				.formatToParts(1)
				.find((part) => part.type === 'currency')?.value || '';
		return currencySymbol;
	} catch (error) {
		console.error('Error:', error);
		return '';
	}
};

export const kotakTenantCheckForWhatsapp = (tenantId: string) => {
	if (
		window.origin.indexOf('dev.flyfish.ai') > -1 ||
		window.origin.indexOf('localhost') > -1
	) {
		if (
			String(process.env.REACT_APP_TENANT_ID4) === tenantId ||
			String(process.env.REACT_APP_TENANT_ID3) === tenantId ||
			String(process.env.REACT_APP_TENANT_ID2) === tenantId ||
			String(process.env.REACT_APP_TENANT_ID1) === tenantId
		) {
			saveState('WAEndpoint', 'WhatsAppInterfaces');
			return true;
		} else {
			saveState('WAEndpoint', 'whatsApp');
		}
	} else if (window.origin.indexOf('preprod.flyfish.ai') > -1) {
		if (String(process.env.REACT_APP_TENANT_ID4) === tenantId) {
			saveState('WAEndpoint', 'WhatsAppInterfaces');
			return true;
		} else {
			saveState('WAEndpoint', 'whatsApp');
		}
	} else {
		if (
			String(process.env.REACT_APP_TENANT_ID2) === tenantId ||
			String(process.env.REACT_APP_TENANT_ID1) === tenantId ||
			String(process.env.REACT_APP_TENANT_ID5) === tenantId
		) {
			saveState('WAEndpoint', 'WhatsAppInterfaces');
			return true;
		} else {
			saveState('WAEndpoint', 'whatsApp');
		}
	}
};

//it will not ask the user anything instead gives the approximate location
export const getLocationByIP = async () => {
	try {
		const response = await fetch('https://ipapi.co/json/');
		const data = await response.json();
		const country = data.country_code;
		saveState('country', country);
	} catch (error) {
		console.error('Error fetching location:', error);
	}
};

export const isImage = (url: string) => {
	return /\.(jpg|jpeg|png)(\?.*)?$/.test(url);
};
