import { Box, FormControl, InputAdornment, TextField } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import './NumberInput.scss';
import { NumberInputDownIcon, NumberInputUpIcon } from './NumberInputIcons';

type NumberInputProps = {
	label?: string;
	value: number;
	error?: boolean;
	errorText?: string;
	helperText?: string;
	fullWidth?: boolean;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	onChange: Function;
	min?: number;
	max?: number;
	minLimitMessage?: string;
	maxLimitMessage?: string;
	dotNotation?: boolean;
};

const NumberInput = ({
	value,
	label,
	helperText,
	errorText,
	error = false,
	fullWidth = false,
	startIcon,
	endIcon,
	onChange,
	min = 0,
	max,
	minLimitMessage = 'Min limit reached',
	maxLimitMessage = 'Max limit reached',
	dotNotation = true,
	...rest
}: NumberInputProps) => {
	const [text, setText] = useState(helperText);
	const [limitError, setLimitError] = useState(false);
	const numberInput = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (max && value > max) {
			setText(maxLimitMessage);
			setLimitError(true);
		} else if (Number(value) === 0 || value < min) {
			if (min >= 0) {
				setText(minLimitMessage);
				setLimitError(true);
			}
		} else {
			if (error && errorText && errorText?.length > 0) {
				setText(errorText);
			} else {
				setText(helperText);
				setLimitError(false);
			}
		}
	}, [error, limitError, value]);

	const handleKeyUp = (
		event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (event.key === 'Escape') {
			// @ts-ignore
			event.target.blur();
		}
		if (event.key === 'ArrowUp') {
			if (max && value >= max) {
				onChange && onChange(Number(max));
			} else {
				if (dotNotation) {
					onChange && onChange(Math.round((Number(value) + 1) * 1000) / 1000);
				} else {
					onChange && onChange(Math.round(Number(value) + 1));
				}
			}
		}
		if (event.key === 'ArrowDown') {
			if (min && value <= min) {
				onChange && onChange(Number(min));
			} else {
				if (dotNotation) {
					onChange && onChange(Math.round((Number(value) - 1) * 1000) / 1000);
				} else {
					onChange && onChange(Math.round(Number(value) - 1));
				}
			}
			if (value === 0 && (min || min === 0)) {
				onChange && onChange(Number(min));
			}
		}
	};

	const upIconClick = (e: React.MouseEvent<HTMLOrSVGElement>) => {
		if (max && value >= max) {
			onChange && onChange(Number(max));
			setLimitError(false);
		} else {
			if (dotNotation) {
				onChange && onChange(Math.round((Number(value) + 1) * 1000) / 1000);
			} else {
				onChange && onChange(Math.round(Number(value) + 1));
			}
		}
	};
	const downIconClick = (e: React.MouseEvent<HTMLOrSVGElement>) => {
		if (value <= min) {
			onChange && onChange(Number(min));
			setLimitError(false);
		} else {
			if (dotNotation) {
				onChange && onChange(Math.round((Number(value) - 1) * 1000) / 1000);
			} else {
				onChange && onChange(Math.round(Number(value) - 1));
			}
		}
	};

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		let regExp = dotNotation ? /^[0-9]*[0-9]*$/g : /^[0-9]*$/g; //NOSONAR
		var value;
		if (dotNotation) {
			let stringArr = event.target.value.split('');
			let noOfDot = stringArr.filter((str) => str === '.').length;
			if (noOfDot > 1) {
				// do nothing
			} else {
				if (regExp.test(event.target.value)) {
					value =
						event.target.value.length > 0 ? Number(event.target.value) : '';
					onChange && onChange(event.target.value);
				}
			}
		} else {
			if (regExp.test(event.target.value)) {
				value = event.target.value.length > 0 ? Number(event.target.value) : '';
				onChange && onChange(event.target.value);
			}
		}
		if (
			(value && max && value > max) ||
			(value && min && value < min) ||
			(Number(value) === 0 && min >= 0)
		) {
			setLimitError(true);
		} else {
			setLimitError(false);
		}
	};

	return (
		<FormControl
			variant='outlined'
			className={`ff-number-input${fullWidth ? ' fullWidth' : ''}${label ? '' : ' small'
				}`}>
			<TextField
				variant='outlined'
				label={label ? label : ''}
				value={value}
				onChange={handleChange}
				error={error || limitError}
				onFocus={(event) => event.target.select()}
				ref={numberInput}
				InputProps={{
					startAdornment: startIcon,
					endAdornment: endIcon ? (
						endIcon
					) : (
						<InputAdornment position='end'>
							<div>
								<NumberInputUpIcon
									onClick={(e: React.MouseEvent<HTMLOrSVGElement>) => {
										upIconClick(e);
									}}
								/>
								<NumberInputDownIcon
									onClick={(e: React.MouseEvent<HTMLOrSVGElement>) =>
										downIconClick(e)
									}
								/>
							</div>
						</InputAdornment>
					),
					onKeyUp: (event) => handleKeyUp(event),
				}}
				helperText={
					text?.length && (
						<Box component={'span'} className='error-container'>
							<span className='error-text'>{text}</span>
						</Box>
					)
				}
				maxRows={3}
				{...rest}
			/>
		</FormControl>
	);
};

export default NumberInput;
