import React, { useEffect, useState } from 'react';
import Modal from '../../../Components/Modal';
import { useNavigate } from 'react-router-dom';
import Button from '../../../Components/Button';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	resetShopifyUnlinkLoader,
	unlinkstore,
} from '../../../Features/Shopify/shopifySlice';
import { useSelector } from 'react-redux';
import { setUploadedFiles } from '../../../Features/Ingest/IngestSlice';
import { setSnackModal } from '../../../Features/message/messageSlice';

const RemoveStore = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { shopifyUnlinkLoader } = useSelector(
		(state: RootState) => state.shopify
	);
	const { ecomType } = useSelector((state: RootState) => state.sales);

	const { uploadedFiles, selectedremoveEcom } = useSelector(
		(state: RootState) => state.ingest
	);
	const [buttonLoader, setButtonLoader] = useState(false);
	useEffect(() => {
		if (shopifyUnlinkLoader === 'success') {
			setButtonLoader(false);
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: 'Your store removed sucessfully',
				})
			);
			navigate(-1);
		} else if (shopifyUnlinkLoader === 'failed') {
			setButtonLoader(false);

			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage:
						'Unable to remove store at the moment. Please try after sometime',
					modalMode: 'negative',
				})
			);
		} else if (shopifyUnlinkLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetShopifyUnlinkLoader());
	}, [shopifyUnlinkLoader]);

	return (
		<div>
			<Modal
				headerCloseBtn
				header={`Remove ${
					ecomType === 'shopify' ? 'Shopify' : 'Flyfish'
				} Store?`}
				action='done'
				actionButtons={
					<>
						<Button
							buttonType='light'
							onClick={() => {
								navigate(-1);
							}}>
							Cancel
						</Button>
						<Button
							loading={buttonLoader}
							onClick={() => {
								dispatch(
									unlinkstore({
										tenantId: tenantId!,
										embeddings: 'true',
									})
								);
								let removedEcom = uploadedFiles.ecom.filter(function (e: any) {
									return e.name !== selectedremoveEcom;
								});
								dispatch(
									setUploadedFiles({ ...uploadedFiles, ecom: removedEcom })
								);
							}}>
							Remove
						</Button>
					</>
				}>
				<p>Your AI won’t be able to sell items from this store anymore.</p>
			</Modal>
		</div>
	);
};

export default RemoveStore;
