import { UserResetData } from '../../Pages/ResetPassword/model';
import DataFetchAPI from '../../utils/Api';
import { loadState } from '../../utils/utils';
import {
	ResendOTPData,
	SetPasswordData,
	UserLoginData,
	ValiditateOTPData,
	UserSignUpData,
	switchData,
	ForgotPasswordType,
} from './model';

const login = async (data: UserLoginData) => {
	return await DataFetchAPI(true, true, 'Basic').get(
		'/authentication/oauth/v2/login'
	);
};

const verifySignUpEmail = async (data: UserSignUpData) => {
	return await DataFetchAPI(false, false, '').post(
		'/authentication/signup/generateVerificationCode',
		data
	);
};

const validateOtp = async (data: ValiditateOTPData) => {
	return await DataFetchAPI(false, false, '').post(
		'/authentication/signup/validateVerificationCode',
		data
	);
};

const resendOtp = async (data: ResendOTPData) => {
	return await DataFetchAPI(false, false, '').get(
		'/authentication/signup/resendVerificationCode',
		{
			params: data,
		}
	);
};

const submitSetPassword = async (data: SetPasswordData) => {
	return await DataFetchAPI(false, false, '').post(
		'/authentication/signup/register',
		data
	);
};

const sendForgotPasswordLink = async (data: ForgotPasswordType) => {
	return await DataFetchAPI(false, false, '').get(
		'/authentication/signup/forgotPassword',
		{
			params: data,
		}
	);
};

const logout = async () => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		'/authentication/signout'
	);
};

const isLoggedIn = async () => {
	return await DataFetchAPI().get('/rest/isUserDead');
};

const switchTab = async (data: switchData) => {
	return await DataFetchAPI(false, true, 'Bearer').delete(
		`/livechat/tenant/${loadState('tenantId')}/bot/${loadState(
			'botId'
		)}/socket?sessionId=${data.agentSessionID}`
	);
};

const reset = async (data: UserResetData) => {
	return await DataFetchAPI(false, false, '').post('/resetpassword', data);
};

const refreshToken = async () => {
	return await DataFetchAPI().get('/authentication/refreshToken');
};

const redirectURI_Shopify = async (storeName: string) => {
	return await DataFetchAPI(false, false).get(
		`/shop/redirectUrl?shop=${storeName}`
	);
};

const validateToken = async () => {
	return await DataFetchAPI().get(`authentication/validateToken`);
};

const authService = {
	login,
	verifySignUpEmail,
	submitSetPassword,
	sendForgotPasswordLink,
	validateOtp,
	resendOtp,
	logout,
	switchTab,
	reset,
	refreshToken,
	isLoggedIn,
	redirectURI_Shopify,
	validateToken,
};

export default authService;
