import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../app/store';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import {
	setButtonText,
	setHandoffCancellationMessage,
	setHandoffConfirmationPrompt,
	setHandoffEndMessage,
	setHandoffMessage,
	setUnavailabilityMessageInside,
	setUnavailabilityMessageOutside,
	setWaitTimeMessage,
} from '../../Features/ExperienceOnWeb/experienceonwebSlice';
import './ConfigureBotMessage.scss';
import { routes } from './routes';
const ConfigureBotMessage = () => {
	const dispatch = useAppDispatch();
	const {
		buttonText,
		unavailabilityMessageInside,
		unavailabilityMessageOutside,
		handoffConfirmationPrompt,
		handoffMessage,
		waitTimeMessage,
		handoffCancellationMessage,
		handoffEndMessage,
	} = useSelector((state: RootState) => state.experienceonweb);

	const handleDataChange = (id: any, txt: any) => {
		switch (id) {
			case 'buttonText':
				return dispatch(setButtonText(txt));

			case 'unavailabilityMessageInside':
				return dispatch(setUnavailabilityMessageInside(txt));

			case 'unavailabilityMessageOutside':
				return dispatch(setUnavailabilityMessageOutside(txt));

			case 'handoffConfirmationPrompt':
				return dispatch(setHandoffConfirmationPrompt(txt));

			case 'handoffMessage':
				return dispatch(setHandoffMessage(txt));

			case 'waitTimeMessage':
				return dispatch(setWaitTimeMessage(txt));

			case 'handoffCancellationMessage':
				return dispatch(setHandoffCancellationMessage(txt));

			case 'handoffEndMessage':
				return dispatch(setHandoffEndMessage(txt));

			default:
				return '';
		}
	};
	const handleDataSave = () => {
		let payloadData = {
			buttonText: buttonText,
			unavailabilityMessageInside: unavailabilityMessageInside,
			unavailabilityMessageOutside: unavailabilityMessageOutside,
			handoffConfirmationPrompt: handoffConfirmationPrompt,
			handoffMessage: handoffMessage,
			waitTimeMessage: waitTimeMessage,
			handoffCancellationMessage: handoffCancellationMessage,
			handoffEndMessage: handoffEndMessage,
		};
	};

	return (
		<>
			<div className='config-bot-message-container'>
				<div className='middle-pane'>
					<div className='topbar'>
						<span className='body1'>
							Configure the messages that get shown at various points during the
							handoff process.
						</span>
					</div>
					<div className='bot-message-body'>
						<div className='button-text'>
							<h6 style={{ paddingBottom: '16px' }}>Custom Name</h6>
							<span className='body1'>
								Provide a custom text for this action to be shown during the
								conversation.
							</span>
							<div style={{ paddingTop: '16px', marginBottom: '35.5px' }}>
								<Input
									id='buttonText'
									fullWidth
									autoFocus
									label='Chat with expert'
									value={buttonText}
									helperText='16/20 characters'
									onChange={(event) => {
										handleDataChange(
											event.currentTarget.id,
											event.target.value
										);
									}}
								/>
							</div>
						</div>
						<hr className='line-horizontal' />
						<div className='messages-body'>
							<h6 style={{ paddingBottom: '16px' }}>Messages</h6>
							<div style={{ paddingBottom: '36px' }}>
								<Input
									fullWidth
									id='unavailabilityMessageInside'
									multiline
									label='Unavailability message (working hours)'
									value={unavailabilityMessageInside}
									helperText='Shown to the user if no expert is online during agent working hours'
									onChange={(event) => {
										handleDataChange(
											event.currentTarget.id,
											event.target.value
										);
									}}
								/>
							</div>
							<div style={{ paddingBottom: '36px' }}>
								<Input
									fullWidth
									id='unavailabilityMessageOutside'
									multiline
									label='Unavailability message (Outside working hours)'
									value={unavailabilityMessageOutside}
									helperText='Shown to the user if they message outside working hours'
									onChange={(event) => {
										handleDataChange(
											event.currentTarget.id,
											event.target.value
										);
									}}
								/>
							</div>
							<div style={{ paddingBottom: '36px' }}>
								<Input
									fullWidth
									id='handoffConfirmationPrompt'
									multiline
									label='Handoff confirmation prompt'
									value={handoffConfirmationPrompt}
									helperText="For asking user for confirmation for handoff to expert if it is triggered automatically. 'Yes' and 'No' are presented as options to the user"
									onChange={(event) => {
										handleDataChange(
											event.currentTarget.id,
											event.target.value
										);
									}}
								/>
							</div>
							<div style={{ paddingBottom: '36px' }}>
								<Input
									fullWidth
									id='handoffMessage'
									multiline
									label='Handoff message'
									value={handoffMessage}
									helperText='Shown to the user if no expert is online during agent working hours'
									onChange={(event) => {
										handleDataChange(
											event.currentTarget.id,
											event.target.value
										);
									}}
								/>
							</div>
							<div style={{ paddingBottom: '36px' }}>
								<Input
									fullWidth
									id='waitTimeMessage'
									multiline
									label='Wait time message'
									value={waitTimeMessage}
									helperText='Shown if the user asks something while waiting for an agent to connect.'
									onChange={(event) => {
										handleDataChange(
											event.currentTarget.id,
											event.target.value
										);
									}}
								/>
							</div>
							<div style={{ paddingBottom: '36px' }}>
								<Input
									fullWidth
									id='handoffCancellationMessage'
									multiline
									label='Handoff cancellation message'
									value={handoffCancellationMessage}
									helperText="Shown if the user says 'No' to the handoff confirmation prompt"
									onChange={(event) => {
										handleDataChange(
											event.currentTarget.id,
											event.target.value
										);
									}}
								/>
							</div>
							<div style={{ paddingBottom: '36px' }}>
								<Input
									fullWidth
									id='handoffEndMessage'
									multiline
									label='Handoff end message'
									value={handoffEndMessage}
									helperText='Shown when the chat is handed off back from the human expert to the AI'
									onChange={(event) => {
										handleDataChange(
											event.currentTarget.id,
											event.target.value
										);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConfigureBotMessage;
