import Modal from '../../../../../../Components/Modal';
import Button from '../../../../../../Components/Button';

import './JobSheet.scss';
import JobSheetSvg from './JobSheetSVG';
import Table from '../../../../../../Components/Table';

const jobSheetModal = ({ setLearnMore }: Props) => {
	const column = [
		{ key: 'countryCode', title: 'Country code' },
		{ key: 'phoneNumber', title: 'Phone number' },
		{ key: 'name', title: 'Name' },
		{ key: 'status', title: 'Status' },
		{ key: 'ConsentChannel', title: 'Consent Channel' },
		{ key: 'ConsentCollectionDate', title: 'Consent Collection Date' },
		{ key: 'ConsentCollectiontime', title: 'Consent Collection time' },
	];
	const data = [
		{
			countryCode: '+91',
			phoneNumber: '9198765432110',
			name: 'Rita',
			status: 'Opted in',
			ConsentChannel: 'Website',
			ConsentCollectionDate: '01-06-2022',
			ConsentCollectiontime: '10:10',
		},
		{
			countryCode: '+91',
			phoneNumber: '188888999911',
			name: 'Joe',
			status: 'Opted in',
			ConsentChannel: 'Website',
			ConsentCollectionDate: '02-06-2022',
			ConsentCollectiontime: '14: 10',
		},
		{
			countryCode: '+91',
			phoneNumber: '917897897890',
			name: 'Akshay G',
			status: 'Opted in',
			ConsentChannel: 'Website',
			ConsentCollectionDate: '03-06-2022',
			ConsentCollectiontime: '16: 10',
		},
	];
	return (
		<Modal
			header='Import contacts from Excel file'
			className='job-sheet-modal'
			onClose={() => setLearnMore(false)}
			actionButtons={
				<>
					<Button onClick={() => setLearnMore(false)}>
						<p className='button-1'>Close</p>
					</Button>
				</>
			}>
			<>
				<div className='job-sheet-body-1'>
					<p className='body2'>
						This option allows you to add and update multiple contacts at once.
						Uploaded sheet must be in the following format.
					</p>
					<div className='table'>
						<Table
							isLoading={false}
							column={column}
							data={data}
							page={0}
							limit={0}
							totalCount={0}
						/>
					</div>
					<p className='body2'>
						The country code of the recipient must be the first column of the
						sheet, followed by a column for the rest of the phone number. These
						must be present in all the rows of the sheet. If any row has these
						missing, that row is ignored while importing.
					</p>
					<p className='body2'>
						It is mandatory to add status for new contacts. If a new contact
						doesn’t have status, the corresponding row will be ignored.
					</p>
					<p className='body2'>
						If the number you are adding is already in the list of contacts, the
						existing contact is updated to the new values. If you leave out some
						values for existing numbers, those values won’t be updated.
					</p>
					<p className='body2'>
						Date must be in dd-mm-yyyy format. Time must be in 24 hour format.
					</p>
					<p className='body2'>
						First row will not be processed and can have headers or can be left
						blank.
					</p>
				</div>
			</>
		</Modal>
	);
};

export default jobSheetModal;

interface Props {
	setLearnMore: any;
}
