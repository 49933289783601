import { useEffect, useRef, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { FAQDoc, LinkProduct } from '../../assets/Icons';
import Button from '../../Components/Button';
import Menu from '../../Components/Menu';

import './ingesttab.scss';
import IngestVoiceData from '../../assets/images/Ingest-dropdown/IngestVoiceData.png';

import { getUnreadNotifications } from '../../Features/Notifications/notificationSlice';
import { isHTMLTagsPresent, loadState, saveState } from '../../utils/utils';
import { RootState, useAppDispatch } from '../../app/store';
import { useSelector } from 'react-redux';

const IngestTab = () => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openIngest, setOpenIngest] = useState(false);
	const dispatch = useAppDispatch();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const ingestButtonUploadRef = useRef(null);
	const ingestMenu = () => {
		let menu = [
			{
				label: (
					<div>
						<div className='task-menu-option'>
							<span>
								<LinkProduct />
							</span>
							<label>Link Product Catalog</label>
						</div>
					</div>
				),
				onClick: () => {
					navigate('/app/Ingest/link-product/select-platform');
					setOpenIngest(false);
				},
			},
			{
				label: (
					<div className='upload-files'>
						<div className='task-menu-option'>
							<span>
								<FAQDoc width='24' height='24' />
							</span>
							<div>Upload Files</div>
						</div>
					</div>
				),
				onClick: () => {
					setOpenIngest(false);
					if (ingestButtonUploadRef.current) {
						//@ts-ignore
						ingestButtonUploadRef.current.click();
					}
				},
			},

			{
				label: (
					<div className='task-menu-option'>
						<span>
							<img
								src={IngestVoiceData}
								alt=''
								height='24px'
								width='24px'></img>
						</span>
						<p>Ingest Voice Data</p>
					</div>
				),
				onClick: () => {
					setOpenIngest(false);
				},
			},
		];
		if (loadState('shopifyShopName')) {
			menu.shift();
		}
		return menu;
	};

	const handleIngestButton = (event: any) => {
		setAnchorEl(event.target);
		setOpenIngest(true);
	};

	useEffect(() => {
		dispatch(
			getUnreadNotifications({
				userId: loadState('user').userID,
				tenantId: tenantId!,
			})
		);
	}, []);

	const checkdata = (e: React.ChangeEvent<HTMLInputElement>) => {
		try {
			setTimeout(() => {
				if (ingestButtonUploadRef && ingestButtonUploadRef.current) {
					const chosenfiles = Array.prototype.slice.call(e.target.files);
					let specialCharacterCheck = chosenfiles.filter((file) => {
						return isHTMLTagsPresent(file.name);
					});
					let inValidFiles = chosenfiles.filter((file) => {
						return file.type !== 'application/pdf';
					});
					let fileSize = chosenfiles.filter((file) => {
						let kb = file.size / 1024;
						let mb = kb / 1024;
						return mb > 5;
					});
					if (
						specialCharacterCheck.length &&
						inValidFiles.length &&
						fileSize.length
					) {
						saveState('navigationCount', '5');
					} else if (
						(specialCharacterCheck.length && inValidFiles.length) ||
						(specialCharacterCheck.length && fileSize.length) ||
						(inValidFiles.length && fileSize.length)
					) {
						saveState('navigationCount', '3');
					} else if (
						specialCharacterCheck.length ||
						fileSize.length ||
						inValidFiles.length
					) {
						saveState('navigationCount', '2');
					} else {
						saveState('navigationCount', '1');
					}
					if (specialCharacterCheck.length) {
						let nonSpecialCharacterFiles = chosenfiles.filter((file) => {
							return !isHTMLTagsPresent(file.name);
						});
						navigate('ingested-resources/invalid-file-name-error', {
							state: { files: nonSpecialCharacterFiles },
						});
					} else {
						if (inValidFiles.length) {
							navigate('ingested-resources/invalid-file-format-error', {
								state: { files: chosenfiles },
							});
						} else {
							if (fileSize.length) {
								navigate('ingested-resources/invalid-file-size-error', {
									state: { files: chosenfiles },
								});
							} else {
								navigate('ingested-resources/upload-module', {
									state: { files: chosenfiles },
								});
							}
						}
					}
					//@ts-ignore
					ingestButtonUploadRef.current.value = '';
				}
			}, 800);
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<div className='ff-ingest-container ff-ingest-button'>
			<div className='ff-ingest-header' style={{ height: '186px' }}>
				<input
					id='ff-ingest-item-upload-button-ingest-tab'
					style={{ display: 'none' }}
					type='file'
					multiple
					accept='application/pdf'
					onChange={checkdata}
					ref={ingestButtonUploadRef}
				/>
				<h4>Ingest Your Sources</h4>
				<p className='body2' style={{ marginTop: '5px', fontSize: '12px' }}>
					Feed different data sources to your AI to grow its knowledge &
					capabilities.
				</p>
				<div className='ingest-button-holder'>
					<Button size='large' onClick={(event) => handleIngestButton(event)}>
						<div
							className='button-container'
							style={{
								display: 'flex',
								justifyContent: 'space-around',
								gap: '8px',
							}}>
							Ingest
						</div>
					</Button>

					<Menu
						options={ingestMenu()}
						anchorEl={anchorEl}
						open={openIngest}
						setOpen={setOpenIngest}
						placement='bottom'
					/>
				</div>
			</div>
			<Outlet />
		</div>
	);
};
export default IngestTab;
