import React, { useEffect, useState } from 'react';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	downLoadFlyfishStoreExcelFormat,
	flyfishStoreLink,
	resetFFstoreLinkStatus,
	resetStoreMessage,
	resetUploadFileFlyfishStore,
} from '../../../../Features/Ingest/IngestSlice';
import { loadState } from '../../../../utils/utils';
import FlyfishUpload from '../../../../assets/images/flyfishStoreUpload.png';
import './FlyfishIngest.scss';
import Button from '../../../../Components/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSnackModal } from '../../../../Features/message/messageSlice';

function FlyfishIngest() {
	const {
		uploadFileFlyfishStore,
		ffstoreLinkStatus,
		storeMessage,
		sampleFileURLForFFSTore,
	} = useSelector((state: RootState) => state.ingest);
	const [dragging, setDragging] = useState(false);
	const [file, setFile] = useState<File | null>(null);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [excelSheet, setExcelSheet] = useState('');

	const handleDragEnter = (e: React.DragEvent) => {
		e.preventDefault();
		setDragging(true);
	};

	const handleDragLeave = (e: React.DragEvent) => {
		e.preventDefault();
		setDragging(false);
	};

	const handleDrop = (e: React.DragEvent) => {
		e.preventDefault();
		setDragging(false);
		const droppedFiles = Array.from(e.dataTransfer.files);
		if (
			e.dataTransfer.files[0].type !==
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && // For .xlsx
			e.dataTransfer.files[0].type !== 'application/vnd.ms-excel'
		) {
			// For .xls
			alert('Invalid file type. Please drop a .xlsx or .xls file.');
			return;
		}
		if (droppedFiles.length === 1) {
			setFile(droppedFiles[0]);
		}
	};

	const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length === 1) {
			setFile(e.target.files[0]);
		}
	};
	const handleUpload = () => {
		if (file) {
			const formData = new FormData();
			formData.append('file', file);

			dispatch(
				flyfishStoreLink({
					tenantId: loadState('tenantId'),
					botId: loadState('botId'),
					file: formData,
				})
			);
		}
	};

	useEffect(() => {
		if (ffstoreLinkStatus === 'success') {
			navigate('/app/Ingest/');
			dispatch(
				setSnackModal({
					modalMesssage: 'Your Flyfish Store Linked Successfully',
					openModal: true,
				})
			);
		} else if (ffstoreLinkStatus === 'failed') {
			dispatch(
				setSnackModal({
					modalMesssage:
						storeMessage ?? 'Unable to link your store. Please try sometime!!!',
					openModal: true,
					modalMode: 'negative',
				})
			);
			dispatch(resetStoreMessage());
		}
		dispatch(resetFFstoreLinkStatus());
	}, [ffstoreLinkStatus]);

	useEffect(() => {
		dispatch(
			downLoadFlyfishStoreExcelFormat({
				botId: loadState('botId'),
				tenantId: loadState('tenantId'),
			})
		);
		return () => {
			dispatch(resetUploadFileFlyfishStore());
		};
	}, []);

	useEffect(() => {
		if (sampleFileURLForFFSTore !== 'failed') {
			if (sampleFileURLForFFSTore !== 'loading') {
				setExcelSheet(sampleFileURLForFFSTore);
			}
		}
	}, [sampleFileURLForFFSTore]);

	return (
		<div className='ff-flyfishIngest'>
			<img src={FlyfishUpload} alt='' />
			<p>
				Provide your Excel file for showcasing products, for your reference,
				<a
					style={{ color: 'blue', cursor: 'pointer' }}
					href={excelSheet}
					rel='noopener noreferrer'
					download={'sample_product_catalogue.xlsx'}
					target='_blank'>
					{' '}
					download
				</a>{' '}
				a sample.
			</p>

			<label
				htmlFor='fileUp'
				className={`ff-flyfish-ingest-store drop-area ${
					dragging ? 'drag-over' : ''
				}`}
				onDragEnter={handleDragEnter}
				onDragLeave={handleDragLeave}
				onDragOver={handleDragEnter}
				onDrop={handleDrop}>
				<input
					accept='.xlsx, .xls'
					id='fileUp'
					type='file'
					onChange={handleFileSelect}
				/>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					xmlnsXlink='http://www.w3.org/1999/xlink'
					width='24'
					height='24'
					viewBox='0 0 24 24'>
					<defs>
						<clipPath id='clip-path'>
							<rect
								id='Rectangle_15806'
								data-name='Rectangle 15806'
								width='24'
								height='24'
								transform='translate(0.015)'
								fill='none'
							/>
						</clipPath>
					</defs>
					<g id='Upload' transform='translate(-0.015)'>
						<g
							id='Group_32286'
							data-name='Group 32286'
							clip-path='url(#clip-path)'>
							<path
								id='Path_15563'
								data-name='Path 15563'
								d='M11.064.391a1.334,1.334,0,0,1,1.886,0l5.337,5.337A1.334,1.334,0,1,1,16.4,7.614L13.341,4.555V16.01a1.334,1.334,0,0,1-2.668,0V4.555L7.614,7.614A1.334,1.334,0,0,1,5.727,5.728ZM1.334,14.676A1.334,1.334,0,0,1,2.668,16.01v4A1.334,1.334,0,0,0,4,21.346h16.01a1.334,1.334,0,0,0,1.334-1.334v-4a1.334,1.334,0,1,1,2.668,0v4a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4v-4a1.334,1.334,0,0,1,1.334-1.334'
								transform='translate(0 0)'
								fill='#727272'
								fill-rule='evenodd'
							/>
						</g>
					</g>
				</svg>
				<p>Drop your file here</p>
				<div style={{ color: 'blue', marginTop: '5px' }}>
					{file && file.name}
				</div>
			</label>
			<Button
				onClick={handleUpload}
				disabled={file ? false : true}
				loading={uploadFileFlyfishStore}>
				Save
			</Button>
		</div>
	);
}

export default FlyfishIngest;
