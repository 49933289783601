import DataFetchAPI from '../../utils/Api';
import DataFetchRouteAPI from '../../utils/RouteApi';
import { loadState } from '../../utils/utils';
import { getTenantAIData, createjoinTeam, joinTeam, update } from './model';

const getTenants = async (data: update) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		`/configuration/team/${data.userId}`
	);
};

const CreateaTeam = async (data: createjoinTeam) => {
	return await DataFetchAPI(false, true, 'Bearer').post(
		'/configuration/team',
		data
	);
};
const joinAnotherTeam = async (data: joinTeam) => {
	return await DataFetchAPI(false, true, 'Bearer').post(
		'/rba/v1/joinTenant',
		data
	);
};
const getTenantAi = async (data: getTenantAIData) => {
	if (loadState('token')) {
		return await DataFetchRouteAPI(false, true, 'Bearer').get(
			`/configuration/${data.tenantId}/bot`
		);
	} else {
		return await DataFetchRouteAPI(true, true, 'Basic').get(
			`/configuration/${data.tenantId}/bot`
		);
	}
};
const creatAi = async (data: { name: string; tenantId: number,domain:string }) => {
	const { tenantId, name,domain } = data;
	return await DataFetchRouteAPI(false, true, 'Bearer').post(
		`/configuration/${tenantId}/bot`,
		{ name: name,
		  domain:domain 
		}
	);
};

const getTenantsService = {
	getTenants,
	CreateaTeam,
	joinAnotherTeam,
	getTenantAi,
	creatAi,
};

export default getTenantsService;
