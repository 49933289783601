import React, { useState, useEffect } from 'react';
import './industry-vertical.scss';
import { VerticalizationIcon } from '../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import Input from '../../Components/Input';
import { SearchIcon } from '../../assets/Icons';
import { RootState, useAppDispatch } from '../../app/store';
import { useSelector } from 'react-redux';
import AutoComplete from '../../Components/AutoComplete';
import Expert from '../../assets/images/ToneOfVoice-Icon/expert.jpg';
import Playful from '../../assets/images/ToneOfVoice-Icon/playful.jpg';
import Sophisticated from '../../assets/images/ToneOfVoice-Icon/Sophisticated.jpg';
// import Supportive from '../../assets/images/ToneOfVoice-Icon/supportive.jpg';
import Button from '../../Components/Button';
import PageLoader from '../../Components/PageLoader';
import {
	createVertical,
	setCompetitorNames,
	setEnableProbing,
	setFailedresponse,
	setFinance,
	setIndustry,
	setOtherBussiness,
	setSearchBussinessValue,
	setSelectedEcom,
	setSelectedFinance,
	setSelectedRealStateOrOther,
	setTone,
} from '../../Features/Verticalization/verticalizationSlice';
import {
	creatAi,
	getTenantAi,
	resetCreateAIStatus,
} from '../../Features/teams/TeamsSlice';
import { loadState, saveState } from '../../utils/utils';
import { setInstructions } from '../../Features/Verticalization/verticalizationSlice';
import {
	linkSubscriptionTenantId,
	resetSubscriptionAPIStatus,
	shopSubscriptionPayment_shopify,
	subscriptionStatusCheck,
} from '../../Features/Subscription/subscriptionSlice';
import Checkbox from '../../Components/Checkbox';
import {
	FormControlLabel,
	RadioGroup,
	Radio,
	ListItem,
	ListItemIcon,
} from '@mui/material';
import { setShowBotPreviewCollapsed, setSelectedDomain } from '../../Features/ExperienceOnWeb/experienceonwebSlice';
import { setSnackModal } from '../../Features/message/messageSlice';

type Props = {
	configPage?: boolean;
};
export function IndustrialVerticalization({ configPage }: Props) {
	const dispatch = useAppDispatch();
	const {
		instructions,
		failedResponse,
		enableProbing,
		competitorNames,
		industry,
		finance,
		searchBussinessValue,
		selectedEcom,
		selectedFinance,
		selectedRealStateOrOther,
		otherBussiness,
		tone,
		industryConfig,
		llmConfig,
		recommendationRational,
		complimentaryProducts
	} = useSelector((state: RootState) => state.verticalization);
	const {
		productResultCount,
		selectedDomain
	} = useSelector((state: RootState) => state.experienceonweb);

	const navigate = useNavigate();
	const { navigateToIngest, getTenantAiLoader, createAIStatus } = useSelector(
		(state: RootState) => state.teams
	);
	const tenantId = loadState('tenantId');
	const {
		isPlanActive,
		susbcriptionCheckLoader,
		subscriptionAPIStatus,
		shopSubscriptionPaymentAPIStatus,
		linkSubscriptionTenantIdAPIStatus,
	} = useSelector((state: RootState) => state.subscription);

	useEffect(() => {
		if (!configPage) {
			if (subscriptionAPIStatus === 'success') {
				if (isPlanActive) {
					dispatch(getTenantAi({ tenantId: tenantId }));
				}
			} else if (subscriptionAPIStatus === 'failed') {
				navigate('/app/select-plan');
			}
			dispatch(resetSubscriptionAPIStatus());
		}
	}, [subscriptionAPIStatus, isPlanActive, tenantId]);

	useEffect(() => {
		if (navigateToIngest && !configPage) {
			navigate('/app/Ingest');
		}
	}, [navigateToIngest]);

	useEffect(() => {
		dispatch(setShowBotPreviewCollapsed(true));
		if (loadState('botId') === undefined) {
			dispatch(
				setIndustry(
					industryConfig.filter(
						(config: any) => config.type.toLowerCase() === 'ecom'
					)
				)
			);
			dispatch(
				setFinance(
					industryConfig.filter(
						(config: any) => config.type.toLowerCase() === 'finance'
					)
				)
			);
		}
		const params = new URLSearchParams(window.location.search.substring(1));
		let chargeId = params.get('charge_id');
		if (chargeId) {
			saveState('chargeId', chargeId);
			dispatch(
				shopSubscriptionPayment_shopify({
					chargeId: chargeId,
					shop: loadState('shopifyShopName'),
				})
			);
		} else {
			tenantId &&
				!configPage &&
				dispatch(subscriptionStatusCheck({ tenantId: tenantId }));
		}
	}, []);
	useEffect(() => {
		if (shopSubscriptionPaymentAPIStatus === 'success') {
			dispatch(
				linkSubscriptionTenantId({
					subscriptionId: loadState('subscriptionId_shopify')
						? loadState('subscriptionId_shopify')
						: loadState('chargeId'),
					tenantId: loadState('tenantId'),
				})
			);
		}
	}, [shopSubscriptionPaymentAPIStatus]);
	useEffect(() => {
		if (linkSubscriptionTenantIdAPIStatus === 'success') {
			tenantId &&
				!configPage &&
				dispatch(subscriptionStatusCheck({ tenantId: tenantId }));
		}
	}, [linkSubscriptionTenantIdAPIStatus]);
	const dropdownOptions = [
		{
			label: 'Casual',
			icon: <img src={Playful} width={24} height={24} alt='' />,
			value: 'Casual',
		},
		{
			label: 'Professional',
			icon: <img src={Expert} width={24} height={24} alt='' />,
			value: 'Professional',
		},
		{
			label: 'Persuasive',
			icon: <img src={Sophisticated} width={24} height={24} alt='' />,
			value: 'Persuasive',
		},
	];

	const handleInstruction = (ev: any) => {
		dispatch(setInstructions(ev.currentTarget.value));
	};

	const sendData = () => {
		let tenantData = {
			name: tenantId + '_AI',
			tenantId: tenantId,
			domain: selectedDomain
		};

		dispatch(creatAi(tenantData));
	};


	const industryConfigPicker = (config: any) => {
		if (config.industry_name.toLowerCase() === selectedEcom.toLowerCase()) {

			return { ...config, enabled: 1 };
		}
		if (config.industry_name.toLowerCase() === selectedFinance.toLowerCase()) {

			return { ...config, enabled: 1 };
		}
		if (
			config.industry_name.toLowerCase() ===
			selectedRealStateOrOther
				.split(' ')
				.join('')
				.toLowerCase()
		) {


			return { ...config, enabled: 1 };
		}

		return { ...config, enabled: 0 };
	};

	const llmConfigPicker = (config: any) => {
		if (config.config_name === 'top_n') {
			return { ...config, value: String(productResultCount) };
		} else if (config.config_name === 'errorMessage') {
			return { ...config, value: failedResponse };
		} else if (config.config_name === 'competitorsName') {
			return { ...config, value: competitorNames };
		} else if (config.config_name === 'enableProbing') {
			return { ...config, value: enableProbing[0] ? 'true' : 'false' };
		} else if (config.config_name === 'toneOfVoice') {
			return { ...config, value: tone.value };
		} else if (config.config_name === 'specialInstruction') {
			return { ...config, value: instructions };
		} else if (config.config_name === 'Other') {
			return { ...config, value: otherBussiness };
		} else if (config.config_name === 'recommendationRational') {
			return { ...config, value: recommendationRational ? 'true' : 'false' };
		} else if (config.config_name === 'complimentaryProducts') {
			return { ...config, value: complimentaryProducts ? 'true' : 'false' };
		}
		return config;
	};

	useEffect(() => {
		if (createAIStatus === 'success') {
			let updatedLLMConfig = llmConfig.map((config: any) => {
				return llmConfigPicker(config);
			});
			let updateIndustryConfig = industryConfig.map((config: any) => {
				return industryConfigPicker(config);
			});
			setTimeout(() => {
				let data = {
					bot_id: loadState('botId').toString(),
					llmConfig: updatedLLMConfig,
					industryConfig: updateIndustryConfig,
				};
				//	dispatch(createVertical(data));
				navigate('/app/Ingest');
			}, 1000);

		} else if (createAIStatus === 'failed') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: 'Sorry something went wrong. Please try again.',
					modalMode: 'negative',
				})
			);
		}
		dispatch(resetCreateAIStatus());
	}, [createAIStatus]);
	useEffect(() => {
		if (selectedEcom) {
			dispatch(setSelectedDomain(selectedEcom))
			dispatch(setSelectedFinance(''));
			dispatch(setSelectedRealStateOrOther(''));
		}
	}, [selectedEcom]);
	useEffect(() => {
		if (selectedRealStateOrOther) {
			dispatch(setSelectedDomain(selectedRealStateOrOther === 'Other' ? otherBussiness : 'Real Estate'))
			dispatch(setSelectedFinance(''));
			dispatch(setSelectedEcom(''));
		}
	}, [selectedRealStateOrOther]);
	useEffect(() => {
		if (selectedFinance) {
			dispatch(setSelectedDomain(selectedFinance))
			dispatch(setSelectedEcom(''));
			dispatch(setSelectedRealStateOrOther(''));
		}
	}, [selectedFinance]);

	return (
		<>
			{susbcriptionCheckLoader ||
				getTenantAiLoader ||
				(loadState('shopifShopName') &&
					linkSubscriptionTenantIdAPIStatus !== 'success' &&
					!configPage) ? (
				<div className='query-loader'>
					<PageLoader message='Checking your subscription status' />
				</div>
			) : (
				<div
					className={`ff-vericalization-content ${configPage ? 'ff-industry-config' : ''
						}`}>
					{configPage ? (
						<div className='ff-config-vertical-header'>
							<h4>AI Model</h4>
						</div>
					) : (
						<>
							<VerticalizationIcon />
							<h2 className='bg-gradient'>Great! Let's create your first AI</h2>
						</>
					)}

					<div className='ff-parent'>
						<div className='ff-vericalization-header'>
							<div className='ff-vericalization-business'>
								<h6>Business Verticals</h6>
								<span className='body1'>
									Select your vertical(s) to make the AI better customised for
									your use case
								</span>
								{/* <div className='input-container'>
									<Input
										autoFocus
										value={searchBussinessValue}
										placeholder='Search'
										endIcon={
											<div className='search-icon'>
												<SearchIcon />
											</div>
										}
										onChange={(e) =>
											dispatch(setSearchBussinessValue(e.target.value))
										}
									/>
								</div> */}
							</div>
						</div>
						{industry.length === 0 && finance.length === 0 && (
							<div className='body1 no-result'>No results found</div>
						)}
						<div
							className='ff-ecom'
							style={{ display: industry.length > 0 ? 'flex' : 'none' }}>
							<span className='body1'>E-commerce</span>
							<div className='action-chips body1'>
								<RadioGroup
									aria-labelledby='ecom-group-label'
									name='ecom-group'
									value={selectedEcom}
									onChange={(e) => dispatch(setSelectedEcom(e.target.value))}>
									{industry.map((e: any, i: number) => {
										const isSelected = selectedEcom === e.industry_name;

										return (
											<ListItem
												key={i}
												disablePadding
												className={`${isSelected ? 'checked' : 'not-checked'}`}>
												{isSelected && (
													<ListItemIcon>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='20'
															height='20'
															viewBox='0 0 20 20'>
															<path
																data-name='Path 15050'
																d='M0 0h20v20H0z'
																fill='none'
															/>
															<path
																data-name='Path 15051'
																d='m8.067 14.433-3.5-3.5L3.4 12.1l4.667 4.667 10-10L16.9 5.6z'
																transform='translate(-.567 -.933)'
																fill='#5531d3'
															/>
														</svg>
													</ListItemIcon>
												)}

												<FormControlLabel
													key={i}
													value={e.industry_name}
													control={<Radio />}
													label={e.industry_name}
												/>
											</ListItem>
										);
									})}
								</RadioGroup>
							</div>
						</div>
						<div
							className='ff-ecom'
							style={{ display: finance.length > 0 ? 'flex' : 'none' }}>
							<span className='body1'>Finance</span>
							<div className='action-chips body1'>
								<RadioGroup
									aria-labelledby='ecom-group-label'
									name='ecom-group'
									value={selectedFinance}
									onChange={(e) =>
										dispatch(setSelectedFinance(e.target.value))
									}>
									{finance.map((e: any, i: number) => {
										const isSelected = selectedFinance === e.industry_name;

										return (
											<ListItem
												key={i}
												disablePadding
												className={`${isSelected ? 'checked' : 'not-checked'}`}>
												{isSelected && (
													<ListItemIcon>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='20'
															height='20'
															viewBox='0 0 20 20'>
															<path
																data-name='Path 15050'
																d='M0 0h20v20H0z'
																fill='none'
															/>
															<path
																data-name='Path 15051'
																d='m8.067 14.433-3.5-3.5L3.4 12.1l4.667 4.667 10-10L16.9 5.6z'
																transform='translate(-.567 -.933)'
																fill='#5531d3'
															/>
														</svg>
													</ListItemIcon>
												)}
												<FormControlLabel
													key={i}
													value={e.industry_name}
													control={<Radio />}
													label={e.industry_name}
												/>
											</ListItem>
										);
									})}
								</RadioGroup>
							</div>
						</div>
						<div className='ff-industry-checkbox'>
							<RadioGroup
								aria-labelledby='realstate-other-group-label'
								name='realstate-other-group'
								value={selectedRealStateOrOther}
								onChange={(e) =>
									dispatch(setSelectedRealStateOrOther(e.target.value))
								}>
								<FormControlLabel
									value='Real Estate'
									control={<Radio />}
									label='Real Estate'
								/>
								<FormControlLabel
									value='Other'
									control={<Radio />}
									label='Other'
								/>
							</RadioGroup>
							{selectedRealStateOrOther === 'Other' && (
								<Input
									error={Boolean(
										otherBussiness && !/^[a-z A-Z.]+$/.test(otherBussiness)
									)}
									errorText='Please provide a valid text'
									placeholder='Please enter the details here'
									value={otherBussiness}
									onChange={(ev) => {
										dispatch(setSelectedDomain(ev.currentTarget.value.trimStart()));
										dispatch(
											setOtherBussiness(ev.currentTarget.value.trimStart())
										);
									}}
								/>
							)}
						</div>
					</div>
					<div className='ff-tone-voice'>
						<h6>Tone of Voice</h6>
						<span className='body1'>
							Select the tone to go well with your brand personality and your
							use case
						</span>
						<AutoComplete
							multiple={false}
							options={dropdownOptions}
							valueKey='value'
							labelKey='label'
							iconKey={tone.icon ?? dropdownOptions[0].icon}
							value={tone}
							onChange={(value) => {
								dispatch(setTone(value));
							}}
						/>
					</div>
					<div className='ff-industry-instruction'>
						<h6>Special Instructions</h6>
						<span className='body1'>
							Provide additional instructions to your AI to guide its behaviour
						</span>
						<Input
							placeholder='E.g. “Refer to yourself as Astro”'
							value={instructions}
							onChange={handleInstruction}
							startIcon={''}
							endIcon={''}
						/>
					</div>
					<div className='ff-competitor-names'>
						<h6>Competitor names</h6>
						<div className='body1'>
							Please provide the list of your competitors as comma separated
							values
						</div>
						<Input
							placeholder='E.g. “XYZ, ABC”'
							value={competitorNames}
							onChange={(e) => dispatch(setCompetitorNames(e.target.value))}
						/>
					</div>
					<div className='ff-failed-bot-answer'>
						<h6>Response when bot is unable to answer</h6>
						<Input
							value={failedResponse}
							onChange={(e) => dispatch(setFailedresponse(e.target.value))}
						/>
					</div>
					<div className='ff-enable-probing'>
						<h6>Enable Probing</h6>
						<div className='body1'>
							Make your bot seek additional information from the user before
							responding.
						</div>
						<Checkbox
							isChecked={enableProbing}
							label={
								<p
									className={`body1 ${enableProbing[0] ? 'enabledProbe' : 'notenabledProbe'
										}`}>
									Enable Probing
								</p>
							}
							index={0}
							onValueChange={(value) => dispatch(setEnableProbing(value))}
						/>
					</div>
					{!configPage && (
						<div className='ff-create' onClick={sendData}>
							<Button
								loading={createAIStatus === 'loading'}
								disabled={
									(!selectedEcom &&
										!selectedFinance &&
										!selectedRealStateOrOther) ||
									(selectedRealStateOrOther === 'Other' &&
										otherBussiness.length === 0)
								}>
								Create
							</Button>
						</div>
					)}
				</div>
			)}
		</>
	);
}
