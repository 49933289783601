import React, { useState } from 'react';

function Timer(props) {
  let t = React.useRef(-1);

  const [time, setTime] = useState(30);

  const decrementTime = () => {
    setTime((prevTime) => prevTime - 1);
  };
  React.useEffect(() => {
    if (t.current === -1) t.current = window.setInterval(decrementTime, 1000);

    return () => {
      clearInterval(t.current);
    };
  }, [t]);

  React.useEffect(() => {
    if (time === 0) {
      window.clearInterval(t.current);
      props.enableButton();
    }
  }, [time, t]);

  // React.useEffect((props) => {
  //     if (time === 0) {
  //         props.enablebutton()
  //     }
  //   }, [time, t]);

  return <span>{time.toString().length === 1 ? '0' + time.toString() : time}</span>;
}

export default Timer;
