import React from 'react';

{
	/*

Please insert the following into any new svg icon's main path to enable dynamic color change


fill={
  props.fill
    ? 
    '':'#fff'
}


*/
}

interface Props {
	variant?: string;
	fill?: string;
	className?: string;
	iconSize?: string;
}

//Thumbs

export function ThumbsDown(props: Props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
		>
			<path
				data-name='Subtraction 18'
				d={
					props.variant === 'filled'
						? 'M18.667 4H17a.836.836 0 0 0-.833.833v7.5a.836.836 0 0 0 .833.833h1.667zM2.142 9.933A1.65 1.65 0 0 0 2 10.6v.9a1.672 1.672 0 0 0 1.667 1.667H8.25l-.767 3.875a.835.835 0 0 0 .067.55 4 4 0 0 0 .733 1.017l.384.391 5.342-5.342a1.672 1.672 0 0 0 .492-1.183V5.95A1.953 1.953 0 0 0 12.55 4H5.8a1.668 1.668 0 0 0-1.433.808L2.142 9.933z'
						: 'm9.408 15.9.475-2.408A1.671 1.671 0 0 0 8.25 11.5H3.667v-.9l2.141-4.933h6.742a.292.292 0 0 1 .283.283v6.533L9.408 15.9M8.667 19l5.342-5.342a1.672 1.672 0 0 0 .492-1.183V5.95A1.953 1.953 0 0 0 12.55 4H5.8a1.668 1.668 0 0 0-1.433.808L2.142 9.933A1.65 1.65 0 0 0 2 10.6v.9a1.672 1.672 0 0 0 1.667 1.667H8.25l-.767 3.875a.835.835 0 0 0 .067.55 4 4 0 0 0 .733 1.017zM17 13.167h1.667V4H17a.836.836 0 0 0-.833.833v7.5a.836.836 0 0 0 .833.834z'
				}
				transform='translate(-.333 -.666)'
				fill={
					props.fill
						? props.fill
						: props.variant === 'filled'
						? '#fff'
						: '#ffffff99'
				}
			/>
		</svg>
	);
}

export function ThumbsUp(props: Props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
		>
			<path
				data-name='Subtraction 18'
				d={
					props.variant === 'filled'
						? 'M2 17h1.667a.836.836 0 0 0 .833-.833v-7.5a.836.836 0 0 0-.833-.833H2zm16.525-5.933a1.65 1.65 0 0 0 .142-.667v-.9A1.672 1.672 0 0 0 17 7.833h-4.583l.767-3.875a.835.835 0 0 0-.067-.55 4 4 0 0 0-.733-1.017L12 2 6.658 7.342a1.672 1.672 0 0 0-.492 1.183v6.533A1.951 1.951 0 0 0 8.117 17h6.758a1.678 1.678 0 0 0 1.433-.808z'
						: 'm11.258 5.1-.475 2.408A1.671 1.671 0 0 0 12.417 9.5H17v.9l-2.142 4.933H8.117a.292.292 0 0 1-.283-.283V8.517L11.258 5.1M12 2 6.658 7.342a1.672 1.672 0 0 0-.492 1.183v6.525A1.953 1.953 0 0 0 8.117 17h6.75a1.668 1.668 0 0 0 1.433-.808l2.225-5.125a1.65 1.65 0 0 0 .142-.667v-.9A1.672 1.672 0 0 0 17 7.833h-4.583l.767-3.875a.835.835 0 0 0-.067-.55 4 4 0 0 0-.733-1.017zM3.667 7.833H2V17h1.667a.836.836 0 0 0 .833-.833v-7.5a.836.836 0 0 0-.833-.834z'
				}
				transform='translate(-.333 -.333)'
				fill={
					props.fill
						? props.fill
						: props.variant === 'filled'
						? '#fff'
						: '#ffffff99'
				}
			/>
		</svg>
	);
}

//Stars

export function Star(props: Props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 32 32'
		>
			<path
				data-name='Subtraction 18'
				d={
					props.variant === 'filled'
						? 'm15.333 22.36 8.24 4.973-2.186-9.373 7.28-6.307-9.587-.813L15.333 2l-3.747 8.84L2 11.653l7.28 6.307-2.187 9.373z'
						: 'm28.667 11.653-9.587-.827L15.333 2l-3.747 8.84L2 11.653l7.28 6.307-2.187 9.373 8.24-4.973 8.24 4.973L21.4 17.96zm-13.334 8.214-5.013 3.026 1.333-5.707-4.427-3.84 5.84-.507 2.267-5.373 2.28 5.387 5.84.507-4.426 3.84 1.333 5.707z'
				}
				transform='translate(.667 .667)'
				fill={
					localStorage.getItem('channel') === 'whatsapp'
						? '#ffc83d'
						: props.fill
						? props.fill
						: props.variant === 'filled'
						? '#fff'
						: '#ffffff99'
				}
			/>
		</svg>
	);
}

//Smileys

export function Neutral(props: Props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.iconSize ? props.iconSize : '32'}
			height={props.iconSize ? props.iconSize : '32'}
			viewBox='0 0 36 36'
		>
			<path
				data-name='Subtraction 18'
				d={
					props.variant === 'filled'
						? 'M15 30a15 15 0 1 1 15-15 15.017 15.017 0 0 1-15 15zm-4.5-9.75v1.5h9v-1.5zM20.25 9a2.25 2.25 0 1 0 2.25 2.25A2.253 2.253 0 0 0 20.25 9zM9.75 9A2.25 2.25 0 1 0 12 11.249 2.253 2.253 0 0 0 9.75 9z'
						: 'M0 15a15 15 0 1 1 14.985 15A14.993 14.993 0 0 1 0 15zm3 0A12 12 0 1 0 15 3 12 12 0 0 0 3 15zm7.5 6.75v-1.5h9v1.5zm7.5-10.5a2.25 2.25 0 1 1 2.25 2.25A2.249 2.249 0 0 1 18 11.25zm-10.5 0a2.25 2.25 0 1 1 2.25 2.25 2.25 2.25 0 0 1-2.25-2.25z'
				}
				transform='translate(2.999 3)'
				fill={
					props.fill
						? props.fill
						: props.variant === 'filled'
						? '#fff'
						: '#ffffff99'
				}
			/>
		</svg>
	);
}

export function Happy(props: Props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.iconSize ? props.iconSize : '32'}
			height={props.iconSize ? props.iconSize : '32'}
			viewBox='0 0 36 36'
		>
			<path
				data-name='Subtraction 18'
				d={
					props.variant === 'filled'
						? 'M15 30a15 15 0 1 1 15-15 15.017 15.017 0 0 1-15 15zm-2.939-9.781c-.1.117-.5.57-1.215 1.385l-.3.34a8.24 8.24 0 0 0 8.925-.015c-.725-.814-.732-.825-.741-.84s-.022-.034-.775-.886A5.629 5.629 0 0 1 15 21a5.726 5.726 0 0 1-2.939-.78zM20.25 9a2.25 2.25 0 1 0 2.25 2.25A2.252 2.252 0 0 0 20.25 9zM9.751 9A2.25 2.25 0 1 0 12 11.249 2.253 2.253 0 0 0 9.751 9z'
						: 'M0 15a15 15 0 1 1 14.985 15A14.993 14.993 0 0 1 0 15zm3 0A12 12 0 1 0 15 3 12 12 0 0 0 3 15zm7.545 6.946c.224-.255 1.334-1.516 1.515-1.725a5.907 5.907 0 0 0 5.894-.015c1.5 1.694.061.089 1.516 1.725a8.248 8.248 0 0 1-8.925.015zM18 11.25a2.25 2.25 0 1 1 2.25 2.25A2.249 2.249 0 0 1 18 11.25zm-10.5 0a2.25 2.25 0 1 1 2.25 2.25 2.249 2.249 0 0 1-2.25-2.25z'
				}
				transform='translate(2.999 3)'
				fill={
					props.fill
						? props.fill
						: props.variant === 'filled'
						? '#fff'
						: '#ffffff99'
				}
			/>
		</svg>
	);
}

export function SuperHappy(props: Props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.iconSize ? props.iconSize : '32'}
			height={props.iconSize ? props.iconSize : '32'}
			viewBox='0 0 36 36'
		>
			<path
				data-name='Subtraction 19'
				d={
					props.variant === 'filled'
						? 'M15 30a15 15 0 1 1 15-15 15.017 15.017 0 0 1-15 15zM7.5 18a9.334 9.334 0 0 0 2.907 4.317 7.107 7.107 0 0 0 9.187 0A9.332 9.332 0 0 0 22.5 18zm12.75-9a2.25 2.25 0 1 0 2.249 2.25A2.253 2.253 0 0 0 20.25 9zM9.75 9A2.25 2.25 0 1 0 12 11.249 2.253 2.253 0 0 0 9.75 9z'
						: 'M0 15a15 15 0 1 1 14.985 15A14.992 14.992 0 0 1 0 15zm3 0A12 12 0 1 0 15 3 12 12 0 0 0 3 15zm4.5 3h15c-1.169 3.51-4.08 6-7.5 6s-6.33-2.49-7.5-6zM18 11.25a2.25 2.25 0 1 1 2.25 2.25A2.249 2.249 0 0 1 18 11.25zm-10.5 0a2.25 2.25 0 1 1 2.25 2.25 2.25 2.25 0 0 1-2.25-2.25z'
				}
				transform='translate(3 3)'
				fill={
					props.fill
						? props.fill
						: props.variant === 'filled'
						? '#fff'
						: '#ffffff99'
				}
			/>
		</svg>
	);
}

export function Sad(props: Props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.iconSize ? props.iconSize : '32'}
			height={props.iconSize ? props.iconSize : '32'}
			viewBox='0 0 36 36'
		>
			<path
				data-name='Subtraction 18'
				d={
					props.variant === 'filled'
						? 'M15 30a15 15 0 1 1 15-15 15.018 15.018 0 0 1-15 15zm0-8.25a5.756 5.756 0 0 1 2.954.8c.206-.24 1.293-1.473 1.516-1.71A8.108 8.108 0 0 0 15 19.5a8.318 8.318 0 0 0-4.486 1.32c.719.808.731.824.74.837s.016.022.776.873a6.015 6.015 0 0 1 2.97-.78zM20.25 9a2.25 2.25 0 1 0 2.25 2.25A2.252 2.252 0 0 0 20.25 9zM9.751 9A2.25 2.25 0 1 0 12 11.249 2.253 2.253 0 0 0 9.751 9z'
						: 'M0 15a15 15 0 1 1 14.985 15A14.993 14.993 0 0 1 0 15zm3 0A12 12 0 1 0 15 3 12 12 0 0 0 3 15zm12 6.75a6 6 0 0 0-2.97.78c-1.5-1.679-.061-.076-1.516-1.71A8.287 8.287 0 0 1 15 19.5a8.087 8.087 0 0 1 4.47 1.334c-.226.24-1.335 1.5-1.516 1.71A5.758 5.758 0 0 0 15 21.75zm3-10.5a2.25 2.25 0 1 1 2.25 2.25A2.249 2.249 0 0 1 18 11.25zm-10.5 0a2.25 2.25 0 1 1 2.25 2.25 2.249 2.249 0 0 1-2.25-2.25z'
				}
				transform='translate(2.999 3)'
				fill={
					props.fill
						? props.fill
						: props.variant === 'filled'
						? '#fff'
						: '#ffffff99'
				}
			/>
		</svg>
	);
}

export function SuperSad(props: Props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.iconSize ? props.iconSize : '32'}
			height={props.iconSize ? props.iconSize : '32'}
			viewBox='0 0 36 36'
		>
			<path
				data-name='Subtraction 19'
				d={
					props.variant === 'filled'
						? 'M15 30a15 15 0 1 1 15-15 15.017 15.017 0 0 1-15 15zm0-12a8.243 8.243 0 0 0-7.68 5.25h2.505a5.963 5.963 0 0 1 10.349 0h2.506A8.243 8.243 0 0 0 15 18zm5.25-9a2.25 2.25 0 1 0 2.25 2.25A2.253 2.253 0 0 0 20.25 9zM9.75 9A2.25 2.25 0 1 0 12 11.249 2.252 2.252 0 0 0 9.75 9z'
						: 'M0 15a15 15 0 1 1 14.985 15A14.993 14.993 0 0 1 0 15zm3 0A12 12 0 1 0 15 3 12 12 0 0 0 3 15zm17.175 8.25a5.963 5.963 0 0 0-10.349 0H7.32a8.243 8.243 0 0 1 15.36 0zM18 11.25a2.25 2.25 0 1 1 2.25 2.25A2.25 2.25 0 0 1 18 11.25zm-10.5 0a2.25 2.25 0 1 1 2.25 2.25 2.249 2.249 0 0 1-2.25-2.25z'
				}
				transform='translate(3 3)'
				fill={
					props.fill
						? props.fill
						: props.variant === 'filled'
						? '#fff'
						: '#ffffff99'
				}
			/>
		</svg>
	);
}
