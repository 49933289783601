import { availability } from './model';
import DataFetchAPI from '../../utils/Api';
const getAvailability = async (data: availability) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		'HybridChat/getAgentAvailibilityForBot',
		{
			params: data,
		}
	);
};

const ChatWithHumanService = {
	getAvailability,
};
export default ChatWithHumanService;
