import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loadState, removeAllState, saveState } from '../../utils/utils';
import authService from './authService';
import { RedirectURL_SHOPIFY, User, UserLoginData, switchData } from './model';

interface State {
	user: User | null;
	token: string;
	tokenExp: string;
	isLoading: boolean;
	error: string;
	forcedLogout: boolean;
	logoutStatus: boolean;
	isExtendedsession: string;
	encUserId: string;
	encryptPassword: string;
	loginLoader: string;
	redirectURL_shopify: string;
	redirectAPIStatus: string;
	timeToExpireAPIStatus: string;
	validateTokenAPIStatus: string;
}

export const login = createAsyncThunk<
	User,
	UserLoginData,
	{ rejectValue: string }
>('oauth/v3/login', async (data, { rejectWithValue }) => {
	try {
		const response = await authService.login(data);
		const loginData = response.data.data;
		const userData = loginData;

		switch (response.status) {
			case 200:
				let user = {} as User;

				user.encryptedUserId = userData.token;
				user.firstName = userData.firstName;
				user.lastName = userData.lastName;
				user.userEmail = userData.email;
				user.userID = userData.userId;

				const tokenExp = loginData.expiryTime;

				saveState('tokenExp', tokenExp);
				saveState('user', user);
				saveState('token', userData.token);
				saveState('userEmailId', userData.email);
				return user;

			default:
				return rejectWithValue('Something went wrong! Try again later');
		}
	} catch (error: any) {
		const response = error.response;
		switch (response.status) {
			case 401:
				return rejectWithValue('Incorrect email address or password');
			default:
				return rejectWithValue(response.data.error);
		}
	}
});
export const switchTab = createAsyncThunk<
	void,
	switchData,
	{ rejectValue: string }
>('auth/switchTab', async (data, { rejectWithValue }) => {
	try {
		await authService.switchTab(data);
		// console.log(response);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const logout = createAsyncThunk<
	void,
	undefined,
	{ rejectValue: string }
>('signout', async (data, { rejectWithValue }) => {
	try {
		await authService.logout();
	} catch (error) {
		removeAllState();

		return rejectWithValue(JSON.stringify(error));
	}
});

export const isLoggedIn = createAsyncThunk<
	void,
	undefined,
	{ rejectValue: string }
>('auth/isLoggedIn', async (data, { rejectWithValue }) => {
	try {
		const response = await authService.isLoggedIn();
		if (response.status !== 200) {
			removeAllState();
			return rejectWithValue('');
		}
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const refreshToken = createAsyncThunk<
	{ token: string; tokenExp: string },
	any,
	{ rejectValue: string }
>('authentication/refreshToken', async ({ rejectWithValue, dispatch }) => {
	try {
		const response = await authService.refreshToken();

		if (response.data) {
			const token = response.data.jwtToken;
			const tokenExp = response.data.expiryTime;
			saveState('token', token);
			saveState('tokenExp', tokenExp);
			return { tokenExp: tokenExp };
		}
		dispatch(logout());
		dispatch(setForcedLogout());
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const redirectURI_Shopify = createAsyncThunk<
	any,
	RedirectURL_SHOPIFY,
	{ rejectValue: string }
>(
	'authentication/redirectURI_Shopify',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await authService.redirectURI_Shopify(data.storeName);
			console.log('response.data', response.data);
			if (response.data.status.toLowerCase() === 'success') {
				return response.data.data.url;
			}

			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong. Please try again!' })
			);
		} catch (error: any) {
			console.log('error1', error);
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const validateToken = createAsyncThunk<
	any,
	void,
	{ rejectValue: string }
>('authentication/validateToken', async (data, { rejectWithValue }) => {
	try {
		const response = await authService.validateToken();

		if (response.data.result.toLowerCase() === 'success') {
			return;
		}
		return rejectWithValue(JSON.stringify({ error: 'Token Expired' }));
	} catch (error: any) {
		return rejectWithValue(JSON.stringify(error));
	}
});

const user: User = loadState('user');
const token: string = loadState('token');
const tokenExp: string = loadState('tokenExp');

const initialState: State = {
	user: user ? user : null,
	token: token ? token : '',
	tokenExp: tokenExp ? tokenExp : '',
	isLoading: false,
	error: '',
	forcedLogout: false,
	logoutStatus: false,
	isExtendedsession: '',
	encUserId: '',
	encryptPassword: '',
	loginLoader: '',
	redirectURL_shopify: '',
	redirectAPIStatus: '',
	timeToExpireAPIStatus: '',
	validateTokenAPIStatus: '',
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		resetAllStates: () => initialState,
		reset: (state) => {
			state.isLoading = false;
			state.error = '';
		},
		setForcedLogout: (state) => {
			state.forcedLogout = true;
		},
		resetForcedLogout: (state) => {
			state.forcedLogout = false;
		},
		setExtendedsession: (state, action) => {
			state.isExtendedsession = action.payload;
		},
		setEncryptPassword: (state, action) => {
			state.encryptPassword = action.payload;
		},
		setEncUserId: (state, action) => {
			state.encUserId = action.payload;
		},
		resetLoginLoader: (state) => {
			state.loginLoader = '';
		},
		resetRedirectStatus: (state) => {
			state.redirectAPIStatus = '';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state) => {
				state.isLoading = true;
				state.error = '';
				// state.tokenExp = '';
				state.loginLoader = 'loading';
			})
			.addCase(login.fulfilled, (state, action) => {
				state.user = action.payload;
				state.isLoading = false;
				state.tokenExp = action.payload.tokenExp;
				state.loginLoader = 'success';
				saveState('userLoginTime', new Date().getTime());
			})

			.addCase(login.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload ?? 'Something went wrong! Try again later';
				state.loginLoader = 'failed';
			})
			.addCase(logout.pending, (state) => {
				state.logoutStatus = true;
			})
			.addCase(logout.fulfilled, (state) => {
				state.logoutStatus = false;
				// state.tokenExp = '';
				state.user = null;
			})
			.addCase(logout.rejected, (state, action) => {
				state.logoutStatus = true;

				state.user = null;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
				window.location.reload();
			})
			.addCase(isLoggedIn.rejected, (state, action) => {
				state.user = null;
			})
			.addCase(refreshToken.pending, (state) => {
				state.timeToExpireAPIStatus = 'loading';
			})
			.addCase(refreshToken.fulfilled, (state, action) => {
				state.token = action.payload.token;
				state.tokenExp = action.payload.tokenExp;
				state.isExtendedsession = 'extended';
				state.timeToExpireAPIStatus = 'success';
			})
			.addCase(refreshToken.rejected, (state) => {
				state.timeToExpireAPIStatus = 'failed';
			})
			.addCase(redirectURI_Shopify.pending, (state, action) => {
				state.redirectAPIStatus = 'loading';
			})
			.addCase(redirectURI_Shopify.fulfilled, (state, action) => {
				state.redirectURL_shopify = action.payload;
				state.redirectAPIStatus = 'success';
			})
			.addCase(redirectURI_Shopify.rejected, (state, action) => {
				state.redirectAPIStatus = 'failed';
			})
			.addCase(validateToken.pending, (state, action) => {
				state.validateTokenAPIStatus = 'loading';
			})
			.addCase(validateToken.fulfilled, (state, action) => {
				state.validateTokenAPIStatus = 'success';
			})
			.addCase(validateToken.rejected, (state, action) => {
				state.validateTokenAPIStatus = 'failed';
			});
	},
});

export const {
	reset,
	setForcedLogout,
	resetForcedLogout,
	setExtendedsession,
	setEncUserId,
	setEncryptPassword,
	resetLoginLoader,
	resetAllStates,
	resetRedirectStatus,
} = authSlice.actions;

export default authSlice.reducer;
