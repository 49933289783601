import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	AvailableLanguages,
	AvailableLanguagesResponse,
	CREATEBOTCONFIGRESPONSE,
	CREATESECONDARYRESPONSE,
	CREATEWORKFLOWSETTINGRESPONSE,
	GETBOTCONFIGURATION,
	GETBOTCONFIGURATIONRESPONSE,
	GETWORKFLOWSETTING,
	GETWORKFLOWSETTINGRESPONSE,
	SCHEDULE,
	State,
	config,
	uploadBot,
} from './model';
import configureService from './configureService';
import { loadState, rgbToHex, saveState } from '../../utils/utils';
import { setName } from '../builder/builderSlice';
import { setTenantFile } from '../teamsetting/teamsettingSlice';
import {
	fontFOptions,
	fontSOptions,
} from '../../Pages/Configure/Appearance/font';
import { getAllFAQData, getFAQData, getMLBot } from '../FAQs/faqSlice';

const botDetails: any = loadState('botDetails') ?? {};
const tenantId = loadState('tenantId');

const initialState: State = {
	invalidTeamName: false,
	invalidOrgName: false,
	botDetails: {},
	somethingWentWrong: '',
	apearanceBackgroundImageUploadSuccess: false,
	apearanceBotHeaderImageUploadSuccess: false,
	generalImageUploadSuccess: false,
	teamsettingGeneralImgUploadSuccess: false,
	botconfigSaved: false,
	apearanceWelcomeBannerImageUploadSuccess: false,
	botLaunchPromptValue: false,
	autoPopupValue: false,
	changeAvatarLoader: false,
	createSecondaryGeneralLoader: '',
	createBotConfigurationAppearanceLoader: '',
	appearanceBotHeaderLoader: false,
	appearanceWelcomeBannerLoader: false,
	appearanceBackgroundLoader: false,
	teamsettingGeneralLoader: false,
	createBotConfigurationFeedbackLoader: '',
	createBotConfigurationSecurityLoader: '',
	createBotConfigurationLiveChatLoader: '',
	createWorkflowSettingCELoader: false,
	createBotConfigurationCELoader: '',
	headerContentType: 'text',
	botTitle: '',
	displayText: '',
	botLaunchPrompt: '',
	autoPopup: '',
	botName: 'botName',
	menuItems: 'menuItems',
	headerIconPosition: 'internal',
	botBackground: 'gradient',
	welcomeBannerCheckbox: [false],
	botLaunchPromptCheckbox: [false],
	autoPopupCheckbox: [true],
	persistentMenuCheckbox: [false],
	botMessageCheckbox: [false],
	fontFamily: { label: 'Open Sans', value: 'Open Sans' },
	fontSize: { label: 'Normal', value: '16px' },
	headerColor: 'var(--white)',
	headerTextAndIconColor: 'var(--text-colour)',
	botMessageBubbleColor: '#4B50F6',
	botMessageTextColor: '#ffffff',
	userMessageBubbleColor: '#ffffff',
	inputBoxBackgroundColor: '#ffffff',
	launchPromptBackgroundColor: '#ffffff',
	globalValues: [''],
	inputBoxTextColor: '#000000',
	launchPromptTextColor: '#000000',
	inputBoxBckRange: '100',
	inputBoxTextRange: '100',
	botWidth: '360',
	botHeight: '80',
	userMessageTextColor: '#444760',
	optionsAndQuickLinksBckColor: '#ffffff',
	optionsAndQuickLinksTextColor: '#444760',
	optionsAndQuickLinksBorderColor: '#ffffff',
	buttonBckColor: '#ffffff',
	buttonTextColor: '#444760',
	buttonTextAndOutlineColor: '#ffffff',
	responseLevelFeedBackBckColor: '#000000',
	responseLevelFeedBackIconColor: '#322C55',
	responseLevelFeedBackBorderColor: '#BDBDBD',
	timeStampBckColor: '#ffffff',
	timeStampTextColor: '#444760',
	backgroundSolidColor: '#EDF1FF',
	backgroundGradientStartColor: '#ffffff',
	backgroundGradientStopColor: '#EDF1FF',
	optionsAndQuickLinksBckRange: '100',
	optionsAndQuickLinksBorderRange: '0',
	responseLevelFeedBackBckRange: '0',
	responseLevelFeedBackBorderRange: '100',
	timeStampBckRange: '0',
	timeStampTextRange: '100',
	backgroundGradientRange: '100',
	headerFile: '',
	backgroundFile: '',
	welcomeBannerFile: '',
	botAvatar: '',
	maskPIIData: [false],
	liveChatEnable: [false],
	isBotPublic: false,
	welcomeMessage: 'Hi! How can I help you today?',
	showQuickLinks: [false],
	errorMessage: '',
	botAvatarLoader: false,
	showQuickLinksOnError: [false],
	workFlowExitPrompt: '',
	stopMessage: '',
	answerQueriesInMiddleOfWorkflow: [false],
	showRatingOpt: [true],
	userCommentswithFeedBack: [true],
	quickLinks: '',
	persistentMenu: '',
	inputKey: '',
	inputValue: '',
	inputFieldsData: ['', ''],
	persistentMenuInputField: ['', ''],
	handoffToAgent: 'false',
	isMultiLingualEnabled: [true],
	translationApiKey: '',
	languageOptions: [
		// { label: 'Arabic', value: 'Arabic' },
		// { label: 'Bengali (Bangla)', value: 'Bengali (Bangla)' },
		// { label: 'Chinese (Simplified)', value: 'Chinese (Simplified)' },
		// { label: 'German', value: 'German' },
		// { label: 'Hindi', value: 'Hindi' },
		// { label: 'Indonesian', value: 'Indonesian' },
		// { label: 'Japanese', value: 'Japanese' },
		// { label: 'Kannada', value: 'Kannada' },
		// { label: 'Nepali', value: 'Nepali' },
		// { label: 'Portuguese', value: 'Portuguese' },
		// { label: 'Spanish', value: 'Spanish' },
	],
	availableLanguages: [],
	chatwithagent: [true],
	raiseaticket: [true],
	callcustomercare: [true],
	dropdownValue: [],
	dropdownValueCTA: { label: 'Custom API', value: 'Custom API' },
	invalidtranslationkey: '',
	faqconfigloader: '',
	faqbotconfigloader: '',
	workflowsetting: {
		allowAnswer: true,
		baseURLS: [],
		botId: botDetails.botId,
		exitMessage: 'dont know',
		exitMessageEnable: true,
		multiLingual: {},
		postWorkflowResponse: null,
		staticResponse:
			'I was helping you with “#previousdisplayquestion”. We can continue with it below. If you don’t want to continue with that, just say cancel or stop',
		stopMessage: 'no help required',
		tenantId: tenantId,
		workflowResponse:
			'I was helping you with “#previousdisplayquestion”. Do you want to leave it and proceed with the query you just made?',
		workflowSettingId: 579,
	},
	workflowSettingId: 0,

	botConfiguration: {
		//Header
		headerContentType: 'text',
		botNameAsTitle: true,
		botMenuItems: true,
		headerTitle: '',
		headerImage: '',
		headerStyle: 'internal',
		headerColor: 'var(--text-colour)',
		headerBackground: 'var(--white)',

		//Welcome Banner
		showCover: false,
		coverImage: '',

		// Bot launch Prompt
		isLauncherPromptEnabled: false,
		launcherPromptMessage: '',
		// launcherPromptBackground: "#2F1C9A",
		// launcherPromptColor: "#FFFFFF",
		// autopopup
		isAutoPopupEnabled: false,
		autoPopTime: '0',
		// persistent menu
		isShowPersistentMenuEnabled: false,
		isShowSameItemsMenuEnabled: 'false',
		//Background
		backgroundType: 'gradient',
		backgroundColor: 'linear-gradient( #ffffff, #EDF1FF)',

		//Bot Message
		showBotIcon: false,
		srvMsgBackground: '#4B50F6',
		srvMsgColor: '#ffffff',
		//User Message
		usrMsgBackground: '#ffffff',
		usrMsgColor: '#444760',
		//options & quicklinks
		optionsBackground: '#ffffff',
		optionsColor: '#444760',
		optionsBorder: '#ffffff',
		//Buttons
		btnFillBackground: '#ffffff',
		btnFillColor: '#444760',
		outlineBtnColor: '#ffffff',
		// Response Level Feedback
		thumbBackground: '#00000000',
		thumbColor: '#322C55',
		thumbBordercolor: '#BDBDBD',
		//Font
		fontFamily: 'Open Sans',
		fontSize: '16px',
		//Time Stamps
		timeStampBackground: '#ffffff',
		timeStampColor: '#444760',
		handoffToAgent: 'false',
		inputBackground: '#ffffff',
		inputBoxColor: '#000000',
		botWidth: '360',
		botHeight: '80',
	},
	filePathURL: '',
	filePathStatus: false,
	unavailabilityMessageInside:
		'Sorry, none of our experts is around currently. Please try after sometime.',
	unavailabilityMessageOutside:
		'Sorry, none of our experts is around currently. Our experts are available on weekdays from 9 am to 5 pm',
	handoffConfirmationPrompt:
		'Shall I connect you with a human expert to resolve your query?',
	handoffMessage: 'Sure, connecting you to one of our experts. Please wait…',
	waitTimeMessage: 'Please wait, our expert will be with you shortly',
	handoffCancellationMessage: 'No problem, can I help you with something else?',
	handoffEndMessage:
		'I hope your issue was resolved. Is there something else that I can help you with?',
	buttonText: '',
	childTabs: [],
};

export const dummyApi = createAsyncThunk<any, any, { rejectValue: string }>(
	'/chatWithHuman',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await configureService.dummyApi(data);

			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const getWorkflowSetting = createAsyncThunk<
	GETWORKFLOWSETTINGRESPONSE,
	GETWORKFLOWSETTING,
	{ rejectValue: string }
>(
	'/getWorkflowSetting',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await configureService.getWorkflowSetting(data);

			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);
export const updateBotDetails = createAsyncThunk<
	any,
	uploadBot,
	{ rejectValue: string }
>(
	'/configuration/updateBotDetails',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await configureService.updateBotDetails(data);

			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			console.log('Network error');
			return rejectWithValue(JSON.stringify({ error: 'Network error' }));
		}
	}
);

export const getBotDetails = createAsyncThunk<
	any,
	config,
	{ rejectValue: string }
>(
	'/configuration/getBotDetails',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await configureService.getBotDetails(data);

			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			console.log('Network error');
			return rejectWithValue(JSON.stringify({ error: 'Network error' }));
		}
	}
);

export const getBotConfigurations = createAsyncThunk<
	GETBOTCONFIGURATIONRESPONSE,
	GETBOTCONFIGURATION,
	{ rejectValue: string }
>(
	'/getBotConfigurations',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await configureService.getBotConfigurations(data);

			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const schedule = createAsyncThunk<
	void,
	SCHEDULE,
	{ rejectValue: string }
>('/schedule', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await configureService.schedule(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const createWorkflowSetting = createAsyncThunk<
	CREATEWORKFLOWSETTINGRESPONSE,
	object,
	{ rejectValue: string }
>(
	'/createWorkflowSetting',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await configureService.createWorkflowSetting(data);

			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const createSecondary = createAsyncThunk<
	CREATESECONDARYRESPONSE,
	any,
	{ rejectValue: string }
>('/createSecondary', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await configureService.createSecondary(data);

		if (response.data) {
			dispatch(setName(data.bot_name));
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error: any) {
		if (window.location.pathname.indexOf('/faq/design/build/configure') > -1) {
			return rejectWithValue(error.response.data.error);
		}
		return rejectWithValue(JSON.stringify(error));
	}
});

export const createBotConfigurations = createAsyncThunk<
	CREATEBOTCONFIGRESPONSE,
	object,
	{ rejectValue: string }
>(
	'/createBotConfigurations',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await configureService.createBotConfigurations(data);

			if (response.data.response === 'Success') {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const availableLanguage = createAsyncThunk<
	AvailableLanguagesResponse[],
	AvailableLanguages,
	{ rejectValue: string }
>(
	'/availableLanguage',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await configureService.availableLanguages(data);

			if (response.status === 200) {
				return response.data.availableLanguage;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const uploadFile = createAsyncThunk<any, any, { rejectValue: string }>(
	'/uploadFile',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			if (data.uploadTo === 'General') {
				dispatch(setChangeAvatarLoader(true));
				dispatch(setGeneralImageUploadSuccess(false));
			}
			if (data.uploadTo === 'Bot Header') {
				dispatch(setAppearanceBotHeaderLoader(true));
				dispatch(setApearanceBotHeaderImageUploadSuccess(false));
			}
			if (data.uploadTo === 'Welcome Banner') {
				dispatch(setAppearanceWelcomeBannerLoader(true));
				dispatch(setApearanceWelcomeBannerImageUploadSuccess(false));
			}
			if (data.uploadTo === 'Background Image') {
				dispatch(setAppearanceBackgroundLoader(true));
				dispatch(setApearanceBackgroundImageUploadSuccess(false));
			}
			if (data.uploadTo === 'TeamSetting General') {
				dispatch(setTSGeneralLoader(true));
				dispatch(setTeamsettingGeneralImgUploadSuccess(false));
			}
			const response = await configureService.uploadFile(data);

			if (response.data && response.data.response === 'Success') {
				if (data.uploadTo === 'Bot Header') {
					dispatch(setHeaderFile(response.data.path));
					dispatch(setAppearanceBotHeaderLoader(false));
					dispatch(setApearanceBotHeaderImageUploadSuccess(true));
				}
				if (data.uploadTo === 'Welcome Banner') {
					dispatch(setWelcomBannerFile(response.data.path));
					dispatch(setAppearanceWelcomeBannerLoader(false));
					dispatch(setApearanceWelcomeBannerImageUploadSuccess(true));
				}
				if (data.uploadTo === 'Background Image') {
					dispatch(setBackgroundFile(response.data.path));
					dispatch(setAppearanceBackgroundLoader(false));
					dispatch(setApearanceBackgroundImageUploadSuccess(true));
				}
				if (data.uploadTo === 'General') {
					dispatch(setBotAvater(response.data.path));
					dispatch(setChangeAvatarLoader(false));
					dispatch(setGeneralImageUploadSuccess(true));
				}
				if (data.uploadTo === 'TeamSetting General') {
					// dispatch(setTenantImageURL(response.data.path));
					// saveState('tenantImageURL', response.data.path);
					dispatch(setTenantFile(response.data.path));
					dispatch(setTSGeneralLoader(false));
					dispatch(setTeamsettingGeneralImgUploadSuccess(true));
				}
				window.location.pathname.indexOf('/build/FAQs') > -1 &&
					// dispatch(
					// 	getFAQData({
					// 		botid: loadState('botDetails').botId,
					// 		tenantid: loadState('tenantId').toString()
					// 			? loadState('tenantId').toString()
					// 			: '',
					// 		//  ?? '',
					// 		url: '',
					// 		filePath: response.data.path,
					// 	})
					// );
					dispatch(
						getMLBot({
							pdfPath: response.data.path,
							botid: loadState('botDetails').botId.toString(),
						})
					);
				saveState('fileUpload', 'success');
				dispatch(
					getAllFAQData({
						botid: loadState('botDetails').botId.toString(),
						tenantid: loadState('tenantId').toString()
							? loadState('tenantId').toString()
							: '',
						//  ?? '',
					})
				);
				return { uploadTo: data.uploadTo, filePathURL: response.data.path };
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error: any) {
			if (data.uploadTo) {
				return rejectWithValue(JSON.stringify({ uploadTo: data.uploadTo }));
			}
			return rejectWithValue(
				JSON.stringify({ uploadTo: 'Something went wrong!' })
			);
		}
	}
);

export const configureSlice = createSlice({
	name: 'configure',
	initialState,
	reducers: {
		setInvalidTeamName: (state, action) => {
			state.invalidTeamName = action.payload
		},
		setInvalidOrgName: (state, action) => {
			state.invalidOrgName = action.payload
		},
		resetCreateBotConfigurationSecurityLoader: (state) => {
			state.createBotConfigurationSecurityLoader = '';
		},
		updateBotdetails: (state, action) => {
			state.botDetails = action.payload;
		},
		resetCreateBotConfigurationLiveChatLoader: (state) => {
			state.createBotConfigurationLiveChatLoader = '';
		},
		resetCreateBotConfigurationFeedbackLoader: (state) => {
			state.createBotConfigurationFeedbackLoader = '';
		},
		resetCreateBotConfigurationAppearanceLoader: (state) => {
			state.createBotConfigurationAppearanceLoader = '';
		},
		resetCreateBotConfigurationCELoader: (state) => {
			state.createBotConfigurationCELoader = '';
		},
		resetCreateSecondaryGeneralLoader: (state) => {
			state.createSecondaryGeneralLoader = '';
		},
		resetSomethingWentWrong: (state) => {
			state.somethingWentWrong = '';
		},
		setHeaderContentType: (state, action) => {
			state.headerContentType = action.payload;
		},
		setBotTitle: (state, action) => {
			state.botTitle = action.payload;
		},
		setDisplayText: (state, action) => {
			state.displayText = action.payload;
		},
		setBotLaunchPrompt: (state, action) => {
			state.botLaunchPrompt = action.payload;
		},
		setAutoPopup: (state, action) => {
			state.autoPopup = action.payload;
		},
		setBotName: (state, action) => {
			state.botName = action.payload;
		},
		setMenuItems: (state, action) => {
			state.menuItems = action.payload;
		},
		setHeaderIconPosition: (state, action) => {
			state.headerIconPosition = action.payload;
		},
		setBotBackground: (state, action) => {
			state.botBackground = action.payload;
		},
		setWelcomeBannerCheckbox: (state, action) => {
			state.welcomeBannerCheckbox = action.payload;
		},
		setBotLaunchPromptCheckbox: (state, action) => {
			state.botLaunchPromptCheckbox = action.payload;
		},
		setAutoPopupCheckbox: (state, action) => {
			state.autoPopupCheckbox = action.payload;
		},
		setPersistentMenuCheckbox: (state, action) => {
			state.persistentMenuCheckbox = action.payload;
		},
		setBotMessageCheckbox: (state, action) => {
			state.botMessageCheckbox = action.payload;
		},
		setFontFamily: (state, action) => {
			state.fontFamily = action.payload;
		},
		setFontSize: (state, action) => {
			state.fontSize = action.payload;
		},
		setHeaderColor: (state, action) => {
			state.headerColor = action.payload;
		},
		setHeaderTextAndIconColor: (state, action) => {
			state.headerTextAndIconColor = action.payload;
		},
		setBotMessageBubbleColor: (state, action) => {
			state.botMessageBubbleColor = action.payload;
		},
		setBotMessageTextColor: (state, action) => {
			state.botMessageTextColor = action.payload;
		},
		setUserMessageBubbleColor: (state, action) => {
			state.userMessageBubbleColor = action.payload;
		},
		setUserMessageTextColor: (state, action) => {
			state.userMessageTextColor = action.payload;
		},
		setInputBoxBackgroundColor: (state, action) => {
			state.inputBoxBackgroundColor = action.payload;
		},
		setLaunchPromptBackgroundColor: (state, action) => {
			state.launchPromptBackgroundColor = action.payload;
		},
		setGlobalValues: (state, action) => {
			state.globalValues = action.payload;
		},
		setInputBoxTextColor: (state, action) => {
			state.inputBoxTextColor = action.payload;
		},
		setLaunchPromptTextColor: (state, action) => {
			state.launchPromptTextColor = action.payload;
		},
		setInputBoxBckRange: (state, action) => {
			state.inputBoxBckRange = action.payload;
		},
		setInputBoxTextRange: (state, action) => {
			state.inputBoxTextRange = action.payload;
		},
		setBotWidth: (state, action) => {
			state.botWidth = action.payload;
		},
		setBotHeight: (state, action) => {
			state.botHeight = action.payload;
		},
		setOptionsAndQuickLinksBckColor: (state, action) => {
			state.optionsAndQuickLinksBckColor = action.payload;
		},
		setOptionsAndQuickLinksTextColor: (state, action) => {
			state.optionsAndQuickLinksTextColor = action.payload;
		},
		setOptionsAndQuickLinksBorderColor: (state, action) => {
			state.optionsAndQuickLinksBorderColor = action.payload;
		},
		setButtonBckColor: (state, action) => {
			state.buttonBckColor = action.payload;
		},
		setButtonTextColor: (state, action) => {
			state.buttonTextColor = action.payload;
		},
		setButtonTextAndOutlineColor: (state, action) => {
			state.buttonTextAndOutlineColor = action.payload;
		},
		setResponseLevelFeedBackBckColor: (state, action) => {
			state.responseLevelFeedBackBckColor = action.payload;
		},
		setResponseLevelFeedBackIconColor: (state, action) => {
			state.responseLevelFeedBackIconColor = action.payload;
		},
		setResponseLevelFeedBackBorderColor: (state, action) => {
			state.responseLevelFeedBackBorderColor = action.payload;
		},
		setTimeStampBckColor: (state, action) => {
			state.timeStampBckColor = action.payload;
		},
		setTimeStampTextColor: (state, action) => {
			state.timeStampTextColor = action.payload;
		},
		setBackgroundSolidColor: (state, action) => {
			state.backgroundSolidColor = action.payload;
		},
		setBackgroundGradientStartColor: (state, action) => {
			state.backgroundGradientStartColor = action.payload;
		},
		setBackgroundGradientStopColor: (state, action) => {
			state.backgroundGradientStopColor = action.payload;
		},
		setOptionsAndQuickLinksBckRange: (state, action) => {
			state.optionsAndQuickLinksBckRange = action.payload;
		},
		setOptionsAndQuickLinksBorderRange: (state, action) => {
			state.optionsAndQuickLinksBorderRange = action.payload;
		},
		setResponseLevelFeedBackBckRange: (state, action) => {
			state.responseLevelFeedBackBckRange = action.payload;
		},
		setResponseLevelFeedBackBorderRange: (state, action) => {
			state.responseLevelFeedBackBorderRange = action.payload;
		},
		setTimeStampBckRange: (state, action) => {
			state.timeStampBckRange = action.payload;
		},
		setTimeStampTextRange: (state, action) => {
			state.timeStampTextRange = action.payload;
		},
		setBackgroundGradientRange: (state, action) => {
			state.backgroundGradientRange = action.payload;
		},
		setHeaderFile: (state, action) => {
			state.headerFile = action.payload;
		},
		setBackgroundFile: (state, action) => {
			state.backgroundFile = action.payload;
		},
		setWelcomBannerFile: (state, action) => {
			state.welcomeBannerFile = action.payload;
		},
		setMastPIIData: (state, action) => {
			state.maskPIIData = action.payload;
		},
		setLiveChatEnable: (state, action) => {
			state.liveChatEnable = action.payload;
		},
		setIsBotPublic: (state, action) => {
			state.isBotPublic = action.payload;
		},
		setWelcomeMessage: (state, action) => {
			state.welcomeMessage = action.payload;
		},
		setShowQuickLinks: (state, action) => {
			state.showQuickLinks = action.payload;
		},
		setErrorMessage: (state, action) => {
			state.errorMessage = action.payload;
		},
		setShowQuickLinksOnError: (state, action) => {
			state.showQuickLinksOnError = action.payload;
		},
		setWorkFlowExitPrompt: (state, action) => {
			state.workFlowExitPrompt = action.payload;
		},
		setStopMessage: (state, action) => {
			state.stopMessage = action.payload;
		},
		setAnswerQueriesInMiddleOfWorkflow: (state, action) => {
			state.answerQueriesInMiddleOfWorkflow = action.payload;
		},
		setShowRatingOpt: (state, action) => {
			state.showRatingOpt = action.payload;
		},
		setUserCommentswithFeedBack: (state, action) => {
			state.userCommentswithFeedBack = action.payload;
		},
		setBotAvater: (state, action) => {
			state.botAvatar = action.payload;
		},
		setInputFields: (state, action) => {
			state.inputFieldsData = action.payload;
		},
		setPersistentMenuFields: (state, action) => {
			state.persistentMenuInputField = action.payload;
		},
		setChangeAvatarLoader: (state, action) => {
			state.changeAvatarLoader = action.payload;
		},
		setAppearanceBotHeaderLoader: (state, action) => {
			state.appearanceBotHeaderLoader = action.payload;
		},
		setAppearanceWelcomeBannerLoader: (state, action) => {
			state.appearanceWelcomeBannerLoader = action.payload;
		},
		setAppearanceBackgroundLoader: (state, action) => {
			state.appearanceBackgroundLoader = action.payload;
		},
		setTSGeneralLoader: (state, action) => {
			state.teamsettingGeneralLoader = action.payload;
		},
		setGeneralImageUploadSuccess: (state, action) => {
			state.generalImageUploadSuccess = action.payload;
		},
		setApearanceBotHeaderImageUploadSuccess: (state, action) => {
			state.apearanceBotHeaderImageUploadSuccess = action.payload;
		},
		setApearanceBackgroundImageUploadSuccess: (state, action) => {
			state.apearanceBackgroundImageUploadSuccess = action.payload;
		},
		setApearanceWelcomeBannerImageUploadSuccess: (state, action) => {
			state.apearanceWelcomeBannerImageUploadSuccess = action.payload;
		},
		setbotLaunchPromptValue: (state, action) => {
			state.botLaunchPromptValue = action.payload;
		},
		setautoPopupValue: (state, action) => {
			state.autoPopupValue = action.payload;
		},
		setTeamsettingGeneralImgUploadSuccess: (state, action) => {
			state.teamsettingGeneralImgUploadSuccess = action.payload;
		},
		setBotConfigSaved: (state, action) => {
			state.botconfigSaved = action.payload;
		},
		setSomethingWentWrong: (state, action) => {
			state.somethingWentWrong = action.payload;
		},
		setIsMultilingualEnabled: (state, action) => {
			state.isMultiLingualEnabled = action.payload;
		},
		setTranslationApiKey: (state, action) => {
			state.translationApiKey = action.payload;
		},
		setChatWithAgent: (state, action) => {
			state.chatwithagent = action.payload;
		},
		setRaiseaticket: (state, action) => {
			state.raiseaticket = action.payload;
		},
		setCallcutomercare: (state, action) => {
			state.callcustomercare = action.payload;
		},
		setDropdownvalue: (state, action) => {
			state.dropdownValue = action.payload;
		},
		setDropdownvalueCTA: (state, action) => {
			state.dropdownValueCTA = action.payload;
		},
		resetFaqconfigloader: (state) => {
			state.faqconfigloader = '';
		},
		resetFaqbotconfigloader: (state) => {
			state.faqbotconfigloader = '';
		},
		resetInvalidtranslationkey: (state) => {
			state.invalidtranslationkey = '';
		},
		setButtonText: (state, action) => {
			state.buttonText = action.payload;
		},
		setUnavailabilityMessageInside: (state, action) => {
			state.unavailabilityMessageInside = action.payload;
		},
		setUnavailabilityMessageOutside: (state, action) => {
			state.unavailabilityMessageOutside = action.payload;
		},
		setHandoffConfirmationPrompt: (state, action) => {
			state.handoffConfirmationPrompt = action.payload;
		},
		setHandoffMessage: (state, action) => {
			state.handoffMessage = action.payload;
		},
		setWaitTimeMessage: (state, action) => {
			state.waitTimeMessage = action.payload;
		},
		setHandoffCancellationMessage: (state, action) => {
			state.handoffCancellationMessage = action.payload;
		},
		setHandoffEndMessage: (state, action) => {
			state.handoffEndMessage = action.payload;
		},
		setChildTabs: (state, action) => {
			state.childTabs = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(availableLanguage.pending, (state) => { })
			.addCase(availableLanguage.fulfilled, (state, action) => {
				let languages = action.payload.map((lang) => {
					return { label: lang.language, value: lang.language };
				});
				state.languageOptions = languages;
				state.availableLanguages = action.payload;
			})
			.addCase(availableLanguage.rejected, (state) => { })
			.addCase(getBotDetails.fulfilled, (state, action) => {
				state.botDetails = action.payload.data;
				// console.log(botDetails)
			})
			.addCase(dummyApi.pending, (state) => { })
			.addCase(dummyApi.rejected, (state) => { })
			.addCase(dummyApi.fulfilled, (state) => { })
			.addCase(getWorkflowSetting.fulfilled, (state, action) => {
				state.answerQueriesInMiddleOfWorkflow = [action.payload.allowAnswer];
				state.workFlowExitPrompt = action.payload.exitMessage;
				state.stopMessage = action.payload.stopMessage;
				state.workflowSettingId = action.payload.workflowSettingId;
			})
			.addCase(uploadFile.pending, (state) => {
				state.filePathStatus = true;
			})
			.addCase(uploadFile.fulfilled, (state, action) => {
				// console.log(action.payload);
				state.filePathStatus = false;
				state.filePathURL = action.payload.filePathURL;
			})
			.addCase(uploadFile.rejected, (state, action) => {
				if (action.payload) {
					const uploadTo = JSON.parse(action.payload);
					state.somethingWentWrong = uploadTo.uploadTo;
				}
				state.changeAvatarLoader = false;
				state.appearanceBackgroundLoader = false;
				state.appearanceBotHeaderLoader = false;
				state.appearanceWelcomeBannerLoader = false;
				state.teamsettingGeneralLoader = false;
				state.filePathStatus = false;
			})
			.addCase(getBotConfigurations.fulfilled, (state, action) => {
				state.headerContentType = action.payload.headerContentType;
				state.botName =
					action.payload.botNameAsTitle === 'false' ? 'customName' : 'botName';
				state.menuItems =
					action.payload.botMenuItems === 'false' ? 'customMenu' : 'menuItems';
				state.botTitle =
					action.payload.botNameAsTitle === 'false'
						? action.payload.headerTitle
						: '';
				state.headerFile = action.payload.headerImage;
				state.headerIconPosition = action.payload.headerStyle;
				state.headerTextAndIconColor = action.payload.headerColor;
				state.headerColor = action.payload.headerBackground;
				state.welcomeBannerCheckbox =
					action.payload.showCover === 'false' ? [false] : [true];
				state.botLaunchPromptCheckbox =
					action.payload.isLauncherPromptEnabled === 'false' ? [false] : [true];
				state.autoPopupCheckbox =
					action.payload.isAutoPopupEnabled === 'false' ? [false] : [true];
				// state.persistentMenuCheckbox =
				//   action.payload.isShowPersistentMenuEnabled === "false"
				//     ? [false]
				//     : [true];
				state.menuItems = action.payload.isShowSameItemsMenuEnabled;
				state.welcomeBannerFile = action.payload.coverImage;
				state.botBackground = action.payload.backgroundType;
				state.backgroundGradientStartColor =
					action.payload.backgroundColor.includes('linear')
						? action.payload.backgroundColor
							.substring(16)
							.split(')')[0]
							.split(',')[0]
						: '#ffffff';
				state.backgroundGradientStopColor =
					action.payload.backgroundColor.includes('linear')
						? action.payload.backgroundColor
							.substring(16)
							.split(')')[0]
							.split(',')[1]
						: '#EDF1FF';
				state.backgroundFile =
					action.payload.backgroundType === 'image'
						? action.payload.backgroundColor
						: '';
				state.backgroundSolidColor =
					action.payload.backgroundType === 'color'
						? action.payload.backgroundColor
						: '#EDF1FF';
				state.botMessageCheckbox =
					action.payload.showBotIcon === 'false' ? [false] : [true];
				state.botMessageBubbleColor = action.payload.srvMsgBackground;
				state.botMessageTextColor = action.payload.srvMsgColor;
				state.userMessageBubbleColor = action.payload.usrMsgBackground;
				state.userMessageTextColor = action.payload.usrMsgColor;
				state.optionsAndQuickLinksBckColor =
					action.payload.optionsBackground.includes('rgba')
						? rgbToHex(action.payload.optionsBackground)
						: action.payload.optionsBackground;
				state.optionsAndQuickLinksBckRange =
					action.payload.optionsBackground.includes('rgba')
						? rgbToHex(action.payload.optionsBackground, true)
						: '100';
				state.optionsAndQuickLinksTextColor = action.payload.optionsColor;
				state.optionsAndQuickLinksBorderColor =
					action.payload.optionsBorder.includes('rgba')
						? rgbToHex(action.payload.optionsBorder)
						: action.payload.optionsBorder;
				state.optionsAndQuickLinksBorderRange =
					action.payload.optionsBorder.includes('rgba')
						? rgbToHex(action.payload.optionsBorder, true)
						: '0';
				state.buttonBckColor = action.payload.btnFillBackground;
				state.buttonTextColor = action.payload.btnFillColor;
				state.buttonTextAndOutlineColor = action.payload.outlineBtnColor;
				state.responseLevelFeedBackBckColor =
					action.payload.thumbBackground.includes('rgba')
						? rgbToHex(action.payload.thumbBackground)
						: action.payload.thumbBackground;
				state.responseLevelFeedBackBckRange =
					action.payload.thumbBackground.includes('rgba')
						? rgbToHex(action.payload.thumbBackground, true)
						: '0';
				state.responseLevelFeedBackIconColor = action.payload.thumbColor;
				state.responseLevelFeedBackBorderColor =
					action.payload.thumbBordercolor.includes('rgba')
						? rgbToHex(action.payload.thumbBordercolor)
						: action.payload.thumbBordercolor;
				state.responseLevelFeedBackBorderRange =
					action.payload.thumbBordercolor.includes('rgba')
						? rgbToHex(action.payload.thumbBordercolor, true)
						: '100';
				state.fontFamily = action.payload.fontFamily
					? {
						label: fontFOptions.filter(
							(fontF) => action.payload.fontFamily === fontF.value
						)[0].label,
						value: fontFOptions.filter(
							(fontF) => action.payload.fontFamily === fontF.value
						)[0].value,
					}
					: { label: '', value: '' };
				state.fontSize = action.payload.fontSize
					? {
						label: fontSOptions.filter(
							(fontS) => action.payload.fontSize === fontS.value
						)[0].label,
						value: fontSOptions.filter(
							(fontS) => action.payload.fontSize === fontS.value
						)[0].value,
					}
					: {
						label: '',
						value: '',
					};
				state.timeStampBckColor = action.payload.timeStampBackground.includes(
					'rgba'
				)
					? rgbToHex(action.payload.timeStampBackground)
					: action.payload.timeStampBackground;
				state.timeStampBckRange = action.payload.timeStampBackground.includes(
					'rgba'
				)
					? rgbToHex(action.payload.timeStampBackground, true)
					: '0';
				state.timeStampTextColor = action.payload.timeStampColor.includes(
					'rgba'
				)
					? rgbToHex(action.payload.timeStampColor)
					: action.payload.timeStampColor;
				state.timeStampTextRange = action.payload.timeStampColor.includes(
					'rgba'
				)
					? rgbToHex(action.payload.timeStampColor, true)
					: '100';
				state.welcomeMessage = action.payload.welcomeMessage;
				state.errorMessage = action.payload.botfailedMessage;
				state.showQuickLinksOnError =
					action.payload.quickLinksOnError === 'false' ? [false] : [true];
				state.showRatingOpt =
					action.payload.isResponseLevelFeedbackEnabled === 'false'
						? [false]
						: [true];
				state.userCommentswithFeedBack =
					action.payload.popComment === 'true' ? [true] : [false];
				state.maskPIIData =
					action.payload.maskPIIData === 'false' ? [false] : [true];
				state.isBotPublic = action.payload.isBotPublic;
				state.showQuickLinks =
					action.payload.quicklinks !== ''
						? JSON.parse(action.payload.quicklinks).length > 0
							? [true]
							: [false]
						: [false];
				state.handoffToAgent = action.payload.handoffToAgent;
				state.liveChatEnable =
					action.payload.handoffToAgent === 'true' ? [true] : [false];
				//default value given for inputBox background/Text color, botWidth and botHeight for old created bots (as backend doesn't support for old bots for now)
				state.inputBoxBackgroundColor =
					action.payload.inputBackground && action.payload.inputBackground
						? action.payload.inputBackground.includes('rgba')
							? rgbToHex(action.payload.inputBackground)
							: action.payload.inputBackground
						: '#ffffff';
				state.inputBoxTextColor =
					action.payload.inputBoxColor && action.payload.inputBoxColor
						? action.payload.inputBoxColor.includes('rgba')
							? rgbToHex(action.payload.inputBoxColor)
							: action.payload.inputBoxColor
						: '#000000';
				state.botHeight =
					action.payload.botHeight && action.payload.botHeight
						? action.payload.botHeight
						: '50';
				state.botWidth =
					action.payload.botWidth && action.payload.botWidth
						? action.payload.botWidth
						: '320';
				let inputFields: string[] = [];
				let dataReceived =
					action.payload.quicklinks && action.payload.quicklinks !== ''
						? JSON.parse(action.payload.quicklinks)
						: '';
				if (typeof dataReceived === 'object' && dataReceived.length > 0) {
					dataReceived.map((data: any) => {
						inputFields.push(data.linkText);
						inputFields.push(data.linkValue);
						// console.log(inputFields);
					});
					state.inputFieldsData = [...inputFields];
				} else if (
					typeof dataReceived === 'string' &&
					dataReceived.length >= 0
				) {
					state.inputFieldsData = ['', ''];
				}
				// persistent menu
				let persiatentMenuFields: string[] = [];

				let persistentDataReceived =
					action.payload.persistentMenu && action.payload.persistentMenu !== ''
						? JSON.parse(action.payload.persistentMenu)
						: '';
				if (
					typeof persistentDataReceived === 'object' &&
					persistentDataReceived.length > 0
				) {
					persistentDataReceived.map((data: any) => {
						persiatentMenuFields.push(data.linkText);
						persiatentMenuFields.push(data.linkValue);
						// console.log(persiatentMenuFields);
					});
					state.persistentMenuInputField = [...persiatentMenuFields];
				} else if (
					typeof persistentDataReceived === 'string' &&
					persistentDataReceived.length >= 0
				) {
					state.persistentMenuInputField = ['', ''];
				}
				state.persistentMenuCheckbox =
					action.payload.persistentMenu && action.payload.persistentMenu !== ''
						? JSON.parse(action.payload.persistentMenu).length > 0
							? [true]
							: [false]
						: [false];
				// faq-configs

				state.isMultiLingualEnabled =
					action.payload.isMultiLingualEnabled === 'true' ? [true] : [false];
				let detailedLanguages = action.payload.detailedLanguages
					? JSON.parse(action.payload.detailedLanguages).length > 0
						? JSON.parse(action.payload.detailedLanguages)
						: null
					: null;
				state.dropdownValue = detailedLanguages
					? detailedLanguages.map((lang: AvailableLanguagesResponse) => {
						return { label: lang.language, value: lang.language };
					})
					: [];
				state.translationApiKey = action.payload.translationApiKey
					? action.payload.translationApiKey
					: '';

				state.callcustomercare = action.payload.callToActionButtons
					? action.payload.callToActionButtons.indexOf('Call Customer Care') >
						-1
						? [true]
						: [false]
					: [false];
				state.raiseaticket = action.payload.callToActionButtons
					? action.payload.callToActionButtons.indexOf('Raise a Ticket') > -1
						? [true]
						: [false]
					: [false];
				state.chatwithagent = action.payload.callToActionButtons
					? action.payload.callToActionButtons.indexOf('Chat with Agent') > -1
						? [true]
						: [false]
					: [false];
			})

			// global variable

			.addCase(createSecondary.pending, (state) => {
				if (
					window.location.pathname.indexOf('/design/configuration/general') > -1
				) {
					state.createSecondaryGeneralLoader = 'loading';
				} else if (
					window.location.pathname.indexOf('/faq/design/build/configure') > -1
				) {
					state.faqconfigloader = 'loading';
					state.invalidtranslationkey = 'loading';
				}
			})
			.addCase(createSecondary.fulfilled, (state, action) => {
				if (action.payload.response === 'Success') {
					if (
						window.location.pathname.indexOf('/design/configuration/general') >
						-1
					) {
						state.createSecondaryGeneralLoader = 'success';
					} else if (
						window.location.pathname.indexOf('/faq/design/build/configure') > -1
					) {
						state.faqconfigloader = 'success';
						state.invalidtranslationkey = 'success';
					}
				}
			})
			.addCase(createSecondary.rejected, (state, action) => {
				if (
					window.location.pathname.indexOf('/design/configuration/general') > -1
				) {
					state.createSecondaryGeneralLoader = 'failed';
				} else if (
					window.location.pathname.indexOf('/faq/design/build/configure') > -1
				) {
					state.faqconfigloader = 'failed';
					if (action.payload) {
						state.invalidtranslationkey = action.payload;
						// state.invalidtranslationkey = 'failed';
					}
				}
			})
			.addCase(createBotConfigurations.pending, (state) => {
				if (
					window.location.pathname.includes('/design/configuration/appearance')
				)
					state.createBotConfigurationAppearanceLoader = 'loading';
				else if (
					window.location.pathname.includes('/design/configuration/feedback')
				)
					state.createBotConfigurationFeedbackLoader = 'loading';
				else if (
					window.location.pathname.includes('/design/configuration/security')
				)
					state.createBotConfigurationSecurityLoader = 'loading';
				else if (
					window.location.pathname.includes(
						'/design/configuration/conversation-experience'
					)
				)
					state.createBotConfigurationCELoader = 'loading';
				else if (
					window.location.pathname.includes('/design/configuration/live-chat')
				)
					state.createBotConfigurationLiveChatLoader = 'loading';
				else if (
					window.location.pathname.indexOf('faq/design/build/configure') > -1
				)
					state.faqbotconfigloader = 'loading';
			})
			.addCase(createBotConfigurations.fulfilled, (state, action) => {
				if (action.payload.response === 'Success') {
					if (
						window.location.pathname.includes(
							'/design/configuration/appearance'
						)
					)
						state.createBotConfigurationAppearanceLoader = 'success';
					else if (
						window.location.pathname.includes('/design/configuration/feedback')
					)
						state.createBotConfigurationFeedbackLoader = 'success';
					else if (
						window.location.pathname.includes('/design/configuration/security')
					)
						state.createBotConfigurationSecurityLoader = 'success';
					else if (
						window.location.pathname.includes(
							'/design/configuration/conversation-experience'
						)
					)
						state.createBotConfigurationCELoader = 'success';
					else if (
						window.location.pathname.includes('/design/configuration/live-chat')
					)
						state.createBotConfigurationLiveChatLoader = 'success';
					else if (
						window.location.pathname.indexOf('faq/design/build/configure') > -1
					)
						state.faqbotconfigloader = 'success';
				}
			})
			.addCase(createBotConfigurations.rejected, (state) => {
				if (
					window.location.pathname.includes('/design/configuration/appearance')
				)
					state.createBotConfigurationAppearanceLoader = 'failed';
				else if (
					window.location.pathname.includes('/design/configuration/feedback')
				)
					state.createBotConfigurationFeedbackLoader = 'failed';
				else if (
					window.location.pathname.includes('/design/configuration/security')
				)
					state.createBotConfigurationSecurityLoader = 'failed';
				else if (
					window.location.pathname.includes(
						'/design/configuration/conversation-experience'
					)
				)
					state.createBotConfigurationCELoader = 'failed';
				else if (
					window.location.pathname.includes('/design/configuration/live-chat')
				)
					state.createBotConfigurationLiveChatLoader = 'failed';
				else if (
					window.location.pathname.indexOf('faq/design/build/configure') > -1
				)
					state.faqbotconfigloader = 'failed';
			});
	},
});

export const {
	resetCreateBotConfigurationSecurityLoader,
	resetCreateBotConfigurationFeedbackLoader,
	resetCreateBotConfigurationAppearanceLoader,
	resetCreateBotConfigurationCELoader,
	resetCreateSecondaryGeneralLoader,
	resetCreateBotConfigurationLiveChatLoader,
	resetSomethingWentWrong,
	setHeaderContentType,
	setBotTitle,
	setBotLaunchPrompt,
	setAutoPopup,
	setBotName,
	setMenuItems,
	setHeaderIconPosition,
	setBotBackground,
	setWelcomeBannerCheckbox,
	setBotLaunchPromptCheckbox,
	setAutoPopupCheckbox,
	setBotMessageCheckbox,
	setFontFamily,
	setFontSize,
	setHeaderColor,
	setHeaderTextAndIconColor,
	setBotMessageBubbleColor,
	setBotMessageTextColor,
	setUserMessageBubbleColor,
	setInputBoxBackgroundColor,
	setLaunchPromptBackgroundColor,
	setGlobalValues,
	setInputBoxTextColor,
	setLaunchPromptTextColor,
	setInputBoxBckRange,
	setInputBoxTextRange,
	setUserMessageTextColor,
	setOptionsAndQuickLinksBckColor,
	setOptionsAndQuickLinksTextColor,
	setOptionsAndQuickLinksBorderColor,
	setButtonBckColor,
	setButtonTextColor,
	setButtonTextAndOutlineColor,
	setResponseLevelFeedBackBckColor,
	setResponseLevelFeedBackIconColor,
	setResponseLevelFeedBackBorderColor,
	setTimeStampBckColor,
	setTimeStampTextColor,
	setBackgroundSolidColor,
	setBackgroundGradientStartColor,
	setBackgroundGradientStopColor,
	setOptionsAndQuickLinksBckRange,
	setOptionsAndQuickLinksBorderRange,
	setResponseLevelFeedBackBckRange,
	setResponseLevelFeedBackBorderRange,
	setTimeStampBckRange,
	setTimeStampTextRange,
	setBackgroundGradientRange,
	setHeaderFile,
	setBackgroundFile,
	setWelcomBannerFile,
	setMastPIIData,
	setIsBotPublic,
	setWelcomeMessage,
	setShowQuickLinks,
	setShowQuickLinksOnError,
	setAnswerQueriesInMiddleOfWorkflow,
	setErrorMessage,
	setWorkFlowExitPrompt,
	setStopMessage,
	setShowRatingOpt,
	setUserCommentswithFeedBack,
	setBotAvater,
	setInputFields,
	setChangeAvatarLoader,
	setAppearanceBotHeaderLoader,
	setAppearanceWelcomeBannerLoader,
	setAppearanceBackgroundLoader,
	setTSGeneralLoader,
	setGeneralImageUploadSuccess,
	setApearanceBotHeaderImageUploadSuccess,
	setApearanceBackgroundImageUploadSuccess,
	setApearanceWelcomeBannerImageUploadSuccess,
	setbotLaunchPromptValue,
	setautoPopupValue,
	setTeamsettingGeneralImgUploadSuccess,
	setSomethingWentWrong,
	setLiveChatEnable,
	setBotWidth,
	setBotHeight,
	setPersistentMenuCheckbox,
	setDisplayText,
	setPersistentMenuFields,
	setIsMultilingualEnabled,
	setTranslationApiKey,
	setChatWithAgent,
	setRaiseaticket,
	setCallcutomercare,
	setDropdownvalue,
	setDropdownvalueCTA,
	resetFaqconfigloader,
	resetFaqbotconfigloader,
	resetInvalidtranslationkey,
	setHandoffEndMessage,
	setButtonText,
	setUnavailabilityMessageInside,
	setUnavailabilityMessageOutside,
	setHandoffCancellationMessage,
	setHandoffConfirmationPrompt,
	setHandoffMessage,
	setWaitTimeMessage,
	updateBotdetails,
	setInvalidTeamName,
	setInvalidOrgName
} = configureSlice.actions;

export default configureSlice.reducer;
