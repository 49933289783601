import React, { useEffect, useRef, useState } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
	DropdownArrow,
	DropdownFAQArrow,
	SearchClose,
} from '../../assets/Icons';
import './Dropdown.scss';

export type DropdownValue =
	| MultiValue<DropdownValueProps>
	| SingleValue<DropdownValueProps>;

export type DropdownValueProps = {
	value: string;
	label: string | React.ReactNode;
	labelValue?: string;
};

type DropdownProps = {
	value: DropdownValue;
	onChange: React.Dispatch<DropdownValue>;
	className?: string;
	options: Array<any>;
	label?: string;
	placeholder?: string;
	isMulti?: boolean;
	isClearable?: boolean;
	isSearchable?: boolean;
	isFAQ?: boolean;
};

const Dropdown = ({
	value,
	onChange,
	className,
	options,
	label,
	placeholder = 'Select',
	isMulti = false,
	isClearable = false,
	isSearchable = false,
	isFAQ = false,
}: DropdownProps) => {
	const [showClearButton, setShowClearButton] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [activeDropdown, setActiveDropdown] = useState(false);
	const onFocus = () => {
		setIsFocused(true);
	};
	const selectRef = useRef<any>(null);

	const onBlur = () => {
		setIsFocused(false);
		setIsOpen(false);
		setActiveDropdown(false);
	};

	const onClick = () => {
		setActiveDropdown(true);
		if (isOpen) {
			setIsOpen(false);
		} else {
			selectRef.current && selectRef.current.focus();
			setIsOpen(true);
		}
	};

	useEffect(() => {
		if (isMulti) {
			// @ts-ignore
			if (value && value.length > 0) {
				setShowClearButton(true);
			} else {
				setShowClearButton(false);
			}
		} else {
			if (value) {
				setShowClearButton(true);
			} else {
				setShowClearButton(false);
			}
		}
	}, [value]);

	const handleClear = (event: React.MouseEvent) => {
		event.stopPropagation();
		onChange(null);
	};

	return (
		<div
			className={`${
				activeDropdown === true ? 
				isFAQ ? '' 
				: 'active-dropdown' : ''
			} ff-dropdown ${isFocused ? 'focused' : ''} ${
				className ? className : ''
			}`}
			onClick={onClick}>
			<div className='ff-dropdown-main'>
				{label && <label>{label}</label>}
				<Select
					value={value}
					onChange={(newValue: DropdownValue) => {
						isFAQ && setIsFocused(false);
						onChange(newValue);
					}}
					ref={selectRef}
					classNamePrefix='ff-select'
					className='ff-dropdown-container'
					options={options}
					onFocus={onFocus}
					onBlur={onBlur}
					placeholder={placeholder}
					isMulti={isMulti}
					isClearable={isClearable}
					openMenuOnFocus
					isSearchable={isSearchable}
				/>
			</div>
			<div className='ff-dropdown-icons'>
				{isClearable && showClearButton ? (
					<div className='icon' onClick={handleClear}>
						<SearchClose width='15px' height='15px' />
					</div>
				) : (
					<div className='icon'>
						{/* <DropdownArrow width='15px' height='15px' />
						 */}
						{isFAQ ? (
							<DropdownFAQArrow width='15px' height='15px' />
						) : (
							<DropdownArrow width='15px' height='15px' />
						)}
					</div>
				)}
			</div>
		</div>
	);
};
export default Dropdown;
