import Agentranking from '../AgentRanking';
import OverView from '../Overview';
import Realtime from '../Realtime';

const LiveChatMainComponent = () => {
	const realtimeData = {
		selectedBotid: '0',
		selectedSource: { name: 'WhatsApp', id: 10, deviceInfo: 'mobile' },
		selectedAgent: { name: 'aa', id: '-1' },
		selectedQueue: { name: 'queue', id: '12' },
		startDate: 0,
		endDate: 0,
		utcstartDate: 0,
		utcendDate: 0,
		ongoingCardList: [
			{
				title: 'Agent-List',
				count: 1,
				defnitions: 'null',
			},
		],
	};

	const overViewData = {
		selectedBotid: '0',
		selectedSource: { name: 'WhatsApp', id: 10, deviceInfo: 'mobile' },
		agentId:'-1',
		tenantId:'116',
		selectedAgent: { name: 'aa', id: '-1' },
		selectedTenant: { name: 'bb', id: '116' },
		startDate: 0,
		endDate: 0,
		utcstartDate: 0,
		utcendDate: 0,
		ongoingCardList: [
			{
				title: 'Agent-List',
				count: 1,
				defnitions: 'null',
			},
		],
	};

	const agentRankingData = {
		selectedBotid: '0',
		selectedSource: { name: 'WhatsApp', id: 10, deviceInfo: 'mobile' },
		selectedAgentId: -1,
		selectedTenantId: 116,
		startDate: 0,
		endDate: 0,
		factorName: 'resolved handoff',
		utcstartDate: 0,
		utcendDate: 0,
		ongoingCardList: [
			{
				title: 'Agent-List',
				count: 1,
				defnitions: 'null',
			},
		],
	};
	const getAgentsData = {
		type: 'all',
		tenantId: 108,
	};

	return (
		<>
			<Realtime {...realtimeData} />
			<OverView {...overViewData}  />
			<Agentranking {...agentRankingData} />
		</>
	);
};
export default LiveChatMainComponent;
