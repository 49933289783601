import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Parameters } from '..';
import Button from '../../../../../Components/Button';
import Checkbox from '../../../../../Components/Checkbox';
import Input from '../../../../../Components/Input';
import Modal from '../../../../../Components/Modal';
import SnackBar from '../../../../../Components/SnackBar';
import {
	BotBuilderPlus,
	ModalClose,
	SearchClose,
} from '../../../../../assets/Icons';
import { useAppDispatch } from '../../../../../app/store';
import { set } from '../../../../../Features/message/messageSlice';

const APIResponseMapping = () => {
	const navigate = useNavigate();
	const context: any = useOutletContext();
	const dispatch = useAppDispatch();

	const [q, setQ] = useState('');
	const [params, setParams] = useState<Parameters[]>(
		context.apiResponseMapping.length > 0
			? context.apiResponseMapping
			: [{ key: '', value: '', id: 0 }]
	);
	const [filteredParams, setFilteredParams] = useState<Parameters[]>([]);
	let duplicates = [];

	useEffect(() => {
		if (q.length > 0) {
			setFilteredParams(
				params.filter((param) => param.key.toString().includes(q))
			);
		} else {
			setFilteredParams(params);
		}
	}, [q, params]);

	useEffect(() => {
		if (params.length === 0) handleAdd();
	}, [params]);

	const handleAdd = () => {
		setQ('');
		setParams((prev) => [...prev, { key: '', value: '', id: prev.length }]);
	};

	const handleDelete = (id: number | string) => {
		setParams((prev) => prev.filter((param) => param.id !== id));
	};

	const handleChange = (param: any, id: number | string) => {
		setParams((prev) =>
			prev.map((params) =>
				params.id === id ? { ...params, ...param } : params
			)
		);
	};

	const handleContinue = () => {
		const valid = params.filter(
			(par) =>
				(par.value.length === 0 || par.key.length === 0) &&
				!(par.value.length === 0 && par.key.length === 0)
		);

		if (valid.length === 0) {
			const p = params.filter(
				(par) => par.value.length > 0 && par.key.length > 0
			);
			// console.log(p);
			let keysPair: Array<string> = [];
			p.map((val) => {
				keysPair.push(val.key);
			});
			// console.log(keysPair);
			duplicates = keysPair.filter(
				(val: string, index: number) => index !== keysPair.indexOf(val)
			);
			// console.log('duplicates', duplicates);
			if (duplicates.length > 0) {
				dispatch(
					set(
						`Duplicate variable ${
							duplicates.length === 1 ? 'name' : 'names'
						} ${duplicates} `
					)
				);
			} else {
				context.setApiResponseMapping(p);
				navigate(-1);
			}
		} else {
			dispatch(set('Enter valid pair of key and value'));
		}
	};

	return (
		<Modal
			headerCloseBtn
			header={
				<>
					<div className='header-container'>
						<h5>API Response Mapping</h5>

						<Input
							value={q}
							onChange={(event) => setQ(event.target.value)}
							placeholder='Search by variable name'
							className='api-search'
						/>
					</div>
				</>
			}
			// action='Done'
			// onAction={handleContinue}
			actionButtons={
				<div className='api-action-buttons'>
					<div>
						<Button
							startIcon={<BotBuilderPlus width='15px' />}
							onClick={handleAdd}>
							Map another value
						</Button>
					</div>
					<div className='api-buttons'>
						<Button onClick={() => navigate(-1)} buttonType='light'>
							<p>Cancel</p>
						</Button>
						<Button onClick={handleContinue}>
							<p>Done</p>
						</Button>
					</div>
				</div>
			}
			className='call-an-api-multi-select'>
			<div className='additional-body'>
				<div className='head'>
					<div className='row'>
						<div className='cell'>
							{/* <Checkbox
								isChecked={[false]}
								index={0}
								onValueChange={() => {}}
							/> */}
						</div>
						<div className='cell1'>Variable to map to</div>
						<div className='cell1'>JSON Path of Value</div>
						<div className='cell'></div>
					</div>
				</div>
				{params.length > 0 &&
					filteredParams.length > 0 &&
					filteredParams.map((param) => (
						<div className='row' key={param.id}>
							<div className='cell'>
								{/* <Checkbox
									isChecked={[false]}
									index={0}
									onValueChange={() => {}}
								/> */}
							</div>
							<div className='cell'>
								<Input
									placeholder='Enter Variable Name'
									value={param.key}
									onChange={(event) =>
										handleChange(
											{
												key: `${
													event.target.value.indexOf('#') >= 0 ? '' : '#'
												}${event.target.value}`,
											},
											param.id
										)
									}
								/>
							</div>
							<div className='cell'>
								<Input
									placeholder='Enter JSON Path'
									value={param.value}
									onChange={(event) =>
										handleChange({ value: event.target.value }, param.id)
									}
								/>
							</div>
							<div className='cell'>
								<SearchClose
									width='12px'
									onClick={() => handleDelete(param.id)}
									className='remove-param-btn'
								/>
							</div>
						</div>
					))}
				{filteredParams.length == 0 ? (
					<div className='no-results-found'>No results found</div>
				) : null}
			</div>
		</Modal>
	);
};
export default APIResponseMapping;
