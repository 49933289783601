import { useEffect, useState } from 'react';
import {
	Outlet,
	useLocation,
	useNavigate,
	useOutletContext,
} from 'react-router-dom';
import Dropdown, {
	DropdownValue,
	DropdownValueProps,
} from '../../../../Components/Dropdown';
import { commonActionParams, ValidationConditions } from '../common';
import '../ActionsModal.scss';
import './CallApi.scss';
import Input from '../../../../Components/Input';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	addCallAPIAction,
	getCallAPIAction,
	resetActionDetails,
} from '../../../../Features/builder/builderSlice';
import {
	ApiErrorMessages,
	ApiHeader,
	ApiParam,
	ApiResponseParam,
	DefaultConnection,
	GetAction,
} from '../../../../Features/builder/model';
import { useSelector } from 'react-redux';
import { getIconForNextAction } from '../common';
import builderService from '../../../../Features/builder/builderService';
import NextNewAction from '../NextNewAction';

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

import SnackBar from '../../../../Components/SnackBar';
import Conditions, {
	getConditionBranch,
	updateConditions,
} from '../Conditions';
import MultiSelect from './MultiSelect';
import Radio from '../../../../Components/Radio';
import { getActionName } from '../../../../utils/utils';
import Button from '../../../../Components/Button';
import { set } from '../../../../Features/message/messageSlice';

export type Parameters = {
	key: string;
	value: string;
	id: string | number;
};

const CallApi = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const context: any = useOutletContext();
	const state: any = location.state;
	const taskAction: GetAction = state.action;
	const [label, setLabel] = useState('Next Action');
	const [validateURL, setValidateURL] = useState<string>('');
	const { actionDetails, botId, tenantId, actions, activeQuery } = useSelector(
		(state: RootState) => state.builder
	);

	const nextActions = actions
		.filter(
			(action) =>
				action.task_ID !== taskAction.taskId &&
				action.taskHierarchyDTO[0] !== taskAction.taskId
		)
		.map((action) => ({
			value: action.task_ID.toString(),
			label: (
				<div className={`actions-label action${action.userResponseTypeID}`}>
					{getIconForNextAction(action.userResponseTypeID)}
					<p>{action.task_name}</p>
				</div>
			),
			labelValue: action.task_name,
		})) as DropdownValueProps[];

	nextActions.push({
		value: 'add new action',
		label: (
			<div className='actions-label new-action'>
				{getIconForNextAction(-1)}
				<p>New Action</p>
			</div>
		),
	});

	const [openNextActionType, setOpenNextActionType] = useState(false);
	const [nextActionTypeId, setNextActionTypeId] = useState(0);

	const [allConditions, setAllConditions] = useState<ValidationConditions[]>(
		[]
	);

	const [paramName, setParamName] = useState('');

	const methods = [
		{
			value: 'GET',
			label: 'GET',
		},
		{
			value: 'POST',
			label: 'POST',
		},
	];

	const [apiUrl, setApiUrl] = useState('');
	const [method, setMethod] = useState<DropdownValue>(null);
	const [apiError, setApiError] = useState(
		'Sorry, something went wrong. Please try again.'
	);
	const [stopOnFailure, setStopOnFailure] = useState('continue');
	const [apiResponseId, setApiResponseId] = useState(0);

	const [parameters, setParameters] = useState<Parameters[]>([]);
	const [headerParameters, setHeaderParameters] = useState<Parameters[]>([]);
	const [apiResponseMapping, setApiResponseMapping] = useState<Parameters[]>(
		[]
	);
	const [apiErrorMessages, setApiErrorMessages] = useState<Parameters[]>([]);

	const [openNextActionTypeForConditions, setOpenNextActionTypeForConditions] =
		useState(false);
	const [nextActionTypeIdForConditions, setNextActionTypeIdForConditions] =
		useState(0);

	const [condtionIndex, setConditionIndex] = useState<Number | null>(null);
	const [checkEmpytValue, setCheckEmpytValue] = useState(true);
	var nextActionGlobal = {};
	useEffect(() => {
		botId &&
			tenantId &&
			dispatch(
				getCallAPIAction({
					botId,
					tenantId,
					taskId: taskAction.taskId,
					workflowId: taskAction.workflowId,
				})
			);

		return () => {
			dispatch(resetActionDetails());
		};
	}, []);
	useEffect(() => {
		let next_action: DropdownValue = {
			value: label,

			label: (
				<div className={`actions-label action${nextActionTypeId}`}>
					{getIconForNextAction(nextActionTypeId)}

					<p>{label}</p>
				</div>
			),

			labelValue: label ?? 'Untitled',
		};

		context.setNextAction(next_action);
	}, [label]);
	const validateApiUrl = () => {
		if (!Boolean(apiUrl?.length)) {
			setValidateURL('Required');
		} else if (!(apiUrl.indexOf('https:') > -1)) {
			setValidateURL('Please provide a valid HTTPS URL');
		} else {
			setValidateURL('');
		}
	};
	useEffect(() => {
		if (actionDetails && actionDetails?.conditionBranch.length > 0) {
			setAllConditions(updateConditions(actionDetails, nextActions));
		}

		setApiUrl(actionDetails?.apiURL ?? '');

		const filteredMethods = methods.filter(
			(method) => method.value === actionDetails?.methodType
		);

		setMethod(filteredMethods[0]);
		setApiResponseId(
			actionDetails?.apiResponses
				? actionDetails?.apiResponses.apiResponseId
				: 0
		);
		setStopOnFailure(actionDetails?.stopOnFailure ? 'continue' : 'stop');
		if (actionDetails?.apiParam && actionDetails?.apiParam.length > 0) {
			setParameters(
				actionDetails.apiParam.map((param) => ({
					key: param.paramKey,
					value: param.paramValue,
					id: param.paramId,
				}))
			);
		}
		if (actionDetails?.apiHeader && actionDetails?.apiHeader.length > 0) {
			setHeaderParameters(
				actionDetails?.apiHeader.map((param) => ({
					key: param.headerKey,
					value: param.headerValue,
					id: param.headerId,
				}))
			);
		}
		if (
			actionDetails?.apiResponseParam &&
			actionDetails?.apiResponseParam.length > 0
		) {
			setApiResponseMapping(
				actionDetails?.apiResponseParam.map((param) => ({
					key: param.reponseParamKey,
					value: param.reponseParamValue,
					id: param.reponseParamId,
				}))
			);
		}
		if (
			actionDetails?.apiErrorMessages &&
			actionDetails?.apiErrorMessages.length > 0
		) {
			setApiErrorMessages(
				actionDetails?.apiErrorMessages.map((param) => ({
					key: param.errorCode,
					value: param.errorMessage,
					id: param.errorId,
				}))
			);
		}

		const next = actionDetails?.defaultConnection;
		if (next && actions) {
			let action = actions.filter(
				(action) => action.task_ID === next.childtaskId
			)[0];

			if (action) {
				let next_action: DropdownValue = {
					value: next.childtaskId.toString(),
					label: (
						<div className={`actions-label action${action.userResponseTypeID}`}>
							{getIconForNextAction(action.userResponseTypeID)}
							<p>{action.task_name}</p>
						</div>
					),
					labelValue: next.childTaskName ?? 'Untitled',
				};
				context.setNextAction(next_action);
			}
		}
	}, [actionDetails]);

	useEffect(() => {
		const next = context.nextAction as DropdownValueProps;
		if (next && next.value === 'add new action' && !openNextActionType) {
			setOpenNextActionType(true);
		}
	}, [context.nextAction]);

	const handleSave = async () => {
		if (actionDetails) {
			if (
				actions.filter(
					(action) =>
						action.task_name === context.actionName &&
						action.task_ID !== actionDetails.taskId
				).length > 0
			) {
				dispatch(set('Action name already exists'));
				return false;
			}

			let next = context.nextAction as DropdownValueProps;
			let defaultConnection: DefaultConnection = {
				connectionId: 0,
				childtaskId: 0,
				conditionType: '',
				childTaskName: null,
			};

			if (nextActionTypeId !== 0 && botId && tenantId) {
				//call add action
				let position = actionDetails?.taskPosition.split('d');
				let task_position = '3000d4000';
				if (position) {
					task_position = `${parseInt(position[0]) + 110}d${position[1]}`;
				}
				const common = commonActionParams(actionDetails?.workflowId, false);
				const new_task = await builderService.addAction({
					...common,
					previousTaskId: 0,
					botId,
					tenantId,
					userResponseTypeID: nextActionTypeId,
					task_position,
					task_name: getActionName(actions, nextActionTypeId),
				});

				if (new_task.status === 201) {
					defaultConnection.childtaskId = Number(
						new_task.data.data.task.task_ID
					);
					defaultConnection.childTaskName = 'Untitled';
				}
			} else if (nextActionTypeIdForConditions !== 0 && botId && tenantId) {
				let position = actionDetails?.taskPosition.split('d');
				let task_position = '3000d4000';
				if (position) {
					task_position = `${parseInt(position[0]) + 110}d${position[1]}`;
				}
				const common = commonActionParams(actionDetails?.workflowId, false);
				const new_task = await builderService.addAction({
					...common,
					previousTaskId: 0,
					botId,
					tenantId,
					userResponseTypeID: nextActionTypeIdForConditions,
					task_position,
					task_name: getActionName(actions, nextActionTypeIdForConditions),
				});

				if (new_task.status === 201) {
					const nextActionVar = {
						nextAction: {
							value: new_task.data.data.task.task_ID.toString(),
							label: (
								<div
									className={`actions-label action${new_task.data.data.task.task_ID}`}>
									{getIconForNextAction(new_task.data.data.task.task_ID)}
									<p>{new_task.data.data.task.task_name}</p>
								</div>
							),
							labelValue: new_task.data.data.task.task_name,
						},
					};
					nextActionGlobal = nextActionVar;
				}
				if (next) {
					defaultConnection.childtaskId = Number(next.value);
					defaultConnection.childTaskName = next.labelValue ?? '';
				}
			} else {
				if (next) {
					defaultConnection.childtaskId = Number(next.value);
					defaultConnection.childTaskName = next.labelValue ?? '';
				}
			}

			let allConditions2 = allConditions.map((each, i) =>
				i === condtionIndex ? { ...each, ...nextActionGlobal } : each
			);
			const conditionBranch = getConditionBranch(allConditions2, paramName);

			if (apiUrl.length <= 0) {
				dispatch(set('Enter API url'));

				return false;
			}

			if (!method) {
				dispatch(set('Select a method'));

				return false;
			}

			if (apiError.length <= 0) {
				dispatch(set('Enter default error message'));

				return false;
			}

			const selectedMethod = method as DropdownValueProps;
			let selectedParams: ApiParam[] = [];
			let selectedHeaderParam: ApiHeader[] = [];
			let selectedResParam: ApiResponseParam[] = [];
			let selectedErrorMessages: ApiErrorMessages[] = [];

			if (parameters.length > 0) {
				selectedParams = parameters.map((param: any) => ({
					paramKey: param.key,
					paramValue: param.value,
					paramId: '',
				}));
			}

			if (headerParameters.length > 0) {
				selectedHeaderParam = headerParameters.map((response: any) => ({
					headerKey: response.key,
					headerValue: response.value,
					headerId: response.id,
				}));
			}

			if (apiResponseMapping.length > 0) {
				selectedResParam = apiResponseMapping.map((response: any) => ({
					reponseParamKey: response.key,
					reponseParamValue: response.value,
					reponseParamId: response.id,
				}));
			}

			if (apiErrorMessages.length > 0) {
				selectedErrorMessages = apiErrorMessages.map((response: any) => ({
					errorCode: response.key,
					errorMessage: response.value,
					errorId: response.id,
				}));
			}

			activeQuery &&
				dispatch(
					addCallAPIAction({
						...actionDetails,
						taskName: context.actionName,
						templateName: '',
						currentNodeId: activeQuery.nodeId,
						defaultConnection,
						conditionBranch,
						apiURL: apiUrl,
						methodType: selectedMethod.value,
						apiParam: selectedParams,
						apiHeader: selectedHeaderParam,
						apiResponseParam: selectedResParam,
						apiErrorMessages: selectedErrorMessages,
						apiResponses: {
							apiResponseId,
							apiResponseKey: '',
							defaultError: apiError,
							multiLingual: {},
						},
						stopOnFailure: stopOnFailure === 'continue' ? true : false,
						userResponseTypeId: 10,
						userResponseType: 'API',
						taskDescription: '',
					})
				);
		}

		navigate(-1);
	};

	return (
		<>
			<div className='body-content'>
				<div className='action-content'>
					<div className='right-pane'>
						<Accordion allowZeroExpanded preExpanded={['1']}>
							<AccordionItem uuid='1'>
								<AccordionItemHeading>
									<AccordionItemButton>
										<h5 className='Sub-Title'>API Details</h5>
										<div className='arrow'></div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<Input
										fullWidth
										placeholder='Enter API URL '
										label='API URL'
										value={apiUrl}
										onChange={(event) => setApiUrl(event.target.value)}
										type='url'
										autoFocus
										error={Boolean(validateURL)}
										helperText={validateURL}
										onBlur={validateApiUrl}
										onFocus={() => setValidateURL('')}
									// onChange={(event) => setApiUrl(event.target.value)}
									/>
									<Dropdown
										label='Method'
										value={method}
										options={methods}
										onChange={setMethod}
									/>
									<MultiSelect
										value={parameters}
										label='Payload/Parameters'
										placeholder='Select'
										onClick={() =>
											navigate('add-parameters', {
												state: {
													...state,
												},
											})
										}
									/>
									<MultiSelect
										value={headerParameters}
										label='Header Parameters'
										placeholder='Select'
										onClick={() =>
											navigate('add-header-parameters', {
												state: {
													...state,
												},
											})
										}
									/>
									<MultiSelect
										value={apiResponseMapping}
										label='API Response Mapping'
										placeholder='Select'
										onClick={() =>
											navigate('add-api-response-mapping', {
												state: {
													...state,
												},
											})
										}
									/>
								</AccordionItemPanel>
							</AccordionItem>

							<AccordionItem uuid='2'>
								<AccordionItemHeading>
									<AccordionItemButton>
										<h5 className='Sub-Title'>API Error Handling</h5>
										<div className='arrow'></div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<Input
										fullWidth
										placeholder='Default Error Message'
										label='Default Error Message'
										value={apiError}
										onChange={(event) => setApiError(event.target.value)}
									/>
									<MultiSelect
										value={apiErrorMessages}
										label='API error messages'
										placeholder='Select'
										onClick={() =>
											navigate('add-api-error-messages', {
												state: {
													...state,
												},
											})
										}
									/>
									<div>
										<div className='head'>
											<h6>Behavior on error response from API</h6>
										</div>
										<div className='radio'>
											<Radio
												value='continue'
												isChecked={stopOnFailure}
												onValueChange={setStopOnFailure}
												label='Continue to the next action'
												name='onError'
											/>
											<Radio
												value='stop'
												isChecked={stopOnFailure}
												onValueChange={setStopOnFailure}
												label='Stop the conversation'
												name='onError'
											/>
										</div>
									</div>
								</AccordionItemPanel>
							</AccordionItem>

							<AccordionItem uuid='3'>
								<AccordionItemHeading>
									<AccordionItemButton>
										<h5 className='Sub-Title'>Next Actions</h5>
										<div className='arrow'></div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className='head'>
										<h6>Default Next Action</h6>
									</div>
									<Dropdown
										isSearchable
										label='Next Action'
										value={context.nextAction}
										options={nextActions}
										onChange={context.setNextAction}
										className='next-action-dropdown'
									/>

									<Conditions
										allConditions={allConditions}
										setAllConditions={setAllConditions}
										workflowId={taskAction.workflowId}
										nextActions={nextActions}
										addParam
										paramName={paramName}
										setParamName={setParamName}
										setOpenNextActionType={setOpenNextActionTypeForConditions}
										setNextActionTypeIdForConditions={
											setNextActionTypeIdForConditions
										}
										nextActionTypeIdForConditions={
											nextActionTypeIdForConditions
										}
										openNextActionType={openNextActionTypeForConditions}
										setConditionIndex={setConditionIndex}
										setCheckEmpytValue={setCheckEmpytValue}
									/>
								</AccordionItemPanel>
							</AccordionItem>
						</Accordion>
					</div>
				</div>

				<footer>
					<Button
						onClick={handleSave}
						disabled={context.actionName.trim().length > 0 ? false : true}>
						Save
					</Button>
				</footer>
			</div>

			<NextNewAction
				open={openNextActionType}
				setOpenNextActionType={setOpenNextActionType}
				setNextActionTypeId={setNextActionTypeId}
				setLabel={setLabel}
				onClose={() => {
					setOpenNextActionType(false);
					if (nextActionTypeId === 0) {
						context.setNextAction(null);
					}
				}}
			/>

			<Outlet
				context={{
					parameters,
					setParameters,
					headerParameters,
					setHeaderParameters,
					apiResponseMapping,
					setApiResponseMapping,
					apiErrorMessages,
					setApiErrorMessages,
					workflowId: taskAction.workflowId,
				}}
			/>
		</>
	);
};

export default CallApi;
