import React, { useEffect, useState } from 'react';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { useSelector } from 'react-redux';
import {
	CHATBUBBLEDIV,
	CHATDIV,
	FORMS,
	GETINTOUCHDIV,
	HOMESCREENDIV,
} from '../../../../styles/EOW/Body/body.styled';
import Input from '../../../../../Components/Input';
import AutoComplete from '../../../../../Components/AutoComplete';
import Button from '../../../../../Components/Button';
import { NewAIBody } from '../../../../styles/EOW/Body/body.styled';
import { Carousel } from 'react-responsive-carousel';
import { carouselButton } from '../../GenericChatBot/CarouselComp';
import ChatBubble from '../ChatBubble';
import {
	BackwardCarousel,
	Burger,
	ForwardCarousel,
} from '../../../../../assets/Icons';
import InteractiveButton from '../../../../../Components/Button/InteractiveButton';
import { useLocation } from 'react-router-dom';
import Checkbox from '../../../../../Components/Checkbox';
import Radio from '../../../../../Components/Radio';
import {
	setAdditionalsettingMandatoryCollection,
	setCurrentScreen,
	setGenInTouchIconSVGFilled,
	setHomeIconSVGFilled,
	setadditionalsettingMandatoryLeads
} from '../../../../../Features/ExperienceOnWeb/experienceonwebSlice';

const AIBody = () => {
	const {
		currentScreen,
		colorMode,
		colorScale,
		welcomeMessage,
		cardHeading,
		cardBody,
		carouselTitle,
		carouselFieldsData,
		fontFamily,
		fontSize,
		conversationOpener,
		persistentMenuCheckbox,
		persistentinputFieldsData,
		inputFieldsData,
		menuItems,
		selectedColor,
		ratCollectionPrompt,
		bookAptCollectionPrompt,
		generateLeadsCollectionPrompt,
		checkList,
		ratCheckList,
		bookAptCheckList,
		additionalsettingMandatoryCollection,
		additionalsettingDetailsCollection,
		generateLeadsCompany,
		generateLeadsCountry,
		generateLeadsEmail,
		generateLeadsPhonenumber,
		generateLeadsname,
		bookAptCompany,
		bookAptCountry,
		bookAptDate,
		bookAptPhonenumber,
		bookAptEmail,
		bookAptTime,
		bookAptname,
		ratname,
		ratEmail,
		ratPhonenumber,
		liveChatLeadsCollectionPrompt,
		ratIssuedescription,
		liveChatLeadsCompany,
		liveChatLeadsCountry,
		liveChatLeadsEmail,
		liveChatLeadsPhonenumber,
		liveChatLeadsname,
		additionalsettingMandatoryLeads,
		liveChatcheckList

	} = useSelector((state: RootState) => state.experienceonweb);

	const [lists, setLists] = useState<string[]>([]);
	const [showpersistentMenu, setShowpersistentmenu] = useState(false);
	const [menudisplay, setMenudisplay] = useState<string[]>([]);
	const location = useLocation();
	const dispatch = useAppDispatch();
	useEffect(() => {
		let data = carouselFieldsData.slice().filter((value, i) => i % 2 === 0);
		setLists(data);
	}, [carouselFieldsData]);

	useEffect(() => {
		if (persistentMenuCheckbox) {
			let data;
			if (menuItems === 'Show same items as quick links') {
				data = inputFieldsData.filter((v, i) => i % 2 === 0 && v.length > 0);
				setMenudisplay(data);
			} else {
				data = persistentinputFieldsData.filter(
					(v, i) => i % 2 === 0 && v.length > 0
				);

				setMenudisplay(data);
			}
		}
	}, [
		persistentMenuCheckbox,
		persistentinputFieldsData,
		inputFieldsData,
		menuItems,
	]);

	useEffect(() => {
		if (location.pathname.indexOf('actions/') > -1) {
			if (
				location.pathname.indexOf('book-appointment') > -1 ||
				location.pathname.indexOf('raise-a-ticket') > -1
			) {
				dispatch(setCurrentScreen('homescreen'));
				dispatch(setHomeIconSVGFilled(true));
				dispatch(setGenInTouchIconSVGFilled(false));
				dispatch(setAdditionalsettingMandatoryCollection([false]));
				dispatch(setadditionalsettingMandatoryLeads([false]))

			}
		} else if (location.pathname.indexOf('experience-on-web/') > -1) {
			dispatch(setCurrentScreen('homescreen'));
			dispatch(setHomeIconSVGFilled(true));
			dispatch(setGenInTouchIconSVGFilled(false));
			dispatch(setAdditionalsettingMandatoryCollection([false]));
			dispatch(setadditionalsettingMandatoryLeads([false]))
		}
		else if (location.pathname.indexOf('generate-leads') > -1) {
			dispatch(setadditionalsettingMandatoryLeads([false]))
		}
	}, [location.pathname]);

	const GenerateLeadsworkflowPicker = (name: string) => {
		if (name === 'name') {
			return generateLeadsname[0];
		} else if (name === 'phone number') {
			return generateLeadsPhonenumber[0];
		} else if (name === 'company') {
			return generateLeadsCompany[0];
		} else if (name === 'country') {
			return generateLeadsCountry[0];
		} else if (name === 'email') {
			return generateLeadsEmail[0];
		}
		return true;
	};

	const LiveLeadsworkflowPicker = (name: string) => {
		if (name === 'name') {
			return liveChatLeadsname[0];
		} else if (name === 'phone number') {
			return liveChatLeadsPhonenumber[0];
		} else if (name === 'company') {
			return liveChatLeadsCompany[0];
		} else if (name === 'country') {
			return liveChatLeadsCountry[0];
		} else if (name === 'email') {
			return liveChatLeadsEmail[0];
		}
		return true;
	};
	const BookAptworkflowPicker = (name: string) => {
		if (name === 'name') {
			return bookAptname[0];
		} else if (name === 'phone number') {
			return bookAptPhonenumber[0];
		} else if (name === 'company') {
			return bookAptCompany[0];
		} else if (name === 'country') {
			return bookAptCountry[0];
		} else if (name === 'appointment date') {
			return bookAptDate[0];
		} else if (name === 'appointment time') {
			return bookAptTime[0];
		} else if (name === 'email') {
			return bookAptEmail[0];
		}
		return true;
	};

	const RatworkflowPicker = (name: string) => {
		if (name === 'name') {
			return ratname[0];
		} else if (name === 'email') {
			return ratEmail[0];
		} else if (name === 'phone number') {
			return ratPhonenumber[0];
		} else if (name === 'issue description') {
			return ratIssuedescription[0];
		}
		return true;
	};

	return (
		<NewAIBody
			className={`ff-ai-body ${currentScreen === 'getintouch' ? 'getintouch' : ''
				} ${(additionalsettingMandatoryCollection[0] &&
					location.pathname.indexOf('generate-leads') > -1)
					? 'mandatorySelection'
					: ''
				}`}
			colorScale={colorScale}
			mode={colorMode}>
			{(additionalsettingMandatoryCollection[0] &&
				location.pathname.indexOf('generate-leads') > -1) ? (
				<>
					<CHATBUBBLEDIV
						className={`ff-ai-preview-chatbubble forms`}
						colorScale={colorScale}
						mode={colorMode}
						selectedColor={selectedColor}>
						<p style={{ marginBottom: '24px' }}>
							{location.pathname.indexOf('generate-leads') > -1 ? (
								<p
									className='body2'
									style={{
										color: additionalsettingMandatoryCollection[0]
											? colorMode === 'dark'
												? 'white'
												: colorScale[1]
											: '',
									}}>
									{generateLeadsCollectionPrompt}
								</p>
							) : location.pathname.indexOf('book-appointment') > -1 ? (
								<p
									className='body2'
									style={{
										color: additionalsettingMandatoryCollection[0]
											? colorMode === 'dark'
												? 'white'
												: colorScale[1]
											: '',
									}}>
									{bookAptCollectionPrompt}
								</p>
							) : location.pathname.indexOf('raise-a-ticket') > -1 ? (
								<p
									className='body2'
									style={{
										color: additionalsettingMandatoryCollection[0]
											? colorMode === 'dark'
												? 'white'
												: colorScale[1]
											: '',
									}}>
									{ratCollectionPrompt}
								</p>
							) : location.pathname.indexOf('livechat-leads') > -1 ? (
								<p
									className='body2'
									style={{
										color: additionalsettingMandatoryCollection[0]
											? colorMode === 'dark'
												? 'white'
												: colorScale[1]
											: '',
									}}>
									{liveChatLeadsCollectionPrompt}
								</p>
							) : null}

						</p>
						<FORMS
							colorScale={colorScale}
							mode={colorMode}
							selectedColor={selectedColor}
							outsideChat={additionalsettingMandatoryCollection[0]}>
							{location.pathname.indexOf('generate-leads') > -1
								? checkList.map((list) => {
									return (
										<>
											{list.title !== 'Custom Field' ? (
												<>
													{GenerateLeadsworkflowPicker(
														list.title.trim().toLowerCase()
													) ? (
														<>
															<p className='body2'>{list.title}</p>
															<Input autoFocus fullWidth value='' />
														</>
													) : null}
												</>
											) : (
												<>
													{list.data.fieldName ? (
														<>
															<p className='body2'>{list.data.fieldName}</p>
															<p className='caption'>{list.data.desc}</p>
															{list.data.fieldType.label === 'Text' ? (
																<Input autoFocus fullWidth value='' />
															) : null}
															{list.data.fieldType.label === 'Date' ? (
																<Input
																	fullWidth
																	type='date'
																	value=''
																// endIcon={
																// 	<svg
																// 		xmlns='http://www.w3.org/2000/svg'
																// 		width='24'
																// 		height='24'
																// 		viewBox='0 0 24 24'>
																// 		<path
																// 			data-name='Path 15612'
																// 			d='M0 0h24v24H0z'
																// 			fill='none'
																// 		/>
																// 		<path
																// 			data-name='Path 15613'
																// 			d='M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z'
																// 			fill='rgba(0,0,0,.6)'
																// 		/>
																// 	</svg>
																// }
																/>
															) : null}
															{list.data.fieldType.label === 'Time' ? (
																<Input
																	fullWidth
																	value=''
																	type='time'
																// endIcon={
																// 	<svg
																// 		xmlns='http://www.w3.org/2000/svg'
																// 		width='24'
																// 		height='24'
																// 		viewBox='0 0 24 24'>
																// 		<path
																// 			data-name='Path 15609'
																// 			d='M0 0h24v24H0z'
																// 			fill='none'
																// 		/>
																// 		<path
																// 			data-name='Path 15610'
																// 			d='M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
																// 			fill='rgba(0,0,0,.6)'
																// 		/>
																// 		<path
																// 			data-name='Path 15611'
																// 			d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z'
																// 			fill='rgba(0,0,0,.6)'
																// 		/>
																// 	</svg>
																// }
																/>
															) : null}
															{list.data.fieldType.label === 'Number' ? (
																<Input fullWidth value='' />
															) : null}

															{list.data.fieldType.label === 'Multiple Select'
																? list.data.options.map((val: any) => (
																	<Checkbox
																		label={
																			<p className='body2'>{val.name}</p>
																		}
																		isChecked={[false]}
																		index={0}
																		onValueChange={() => console.log('')}
																	/>
																))
																: null}
															{list.data.fieldType.label === 'Multiple Choice'
																? list.data.options.map((val: any) => (
																	<Radio
																		label={
																			<p className='body2'>{val.name}</p>
																		}
																		isChecked={'false'}
																		name={'choice'}
																		value={''}
																		onValueChange={() => console.log('')}
																	/>
																))
																: null}
															{list.data.fieldType.label === 'Drop Down' ? (
																<>
																	<AutoComplete
																		multiple={false}
																		// label=''
																		options={list.data.options.map(
																			(opt: any) => {
																				return {
																					label: opt.name,
																					value: opt.name,
																				};
																			}
																		)}
																		valueKey='value'
																		labelKey='label'
																		value={{
																			label: list.data.options[0].name,
																			value: list.data.options[0].name,
																		}}
																		onChange={() => console.log('')}
																	/>
																</>
															) : null}
														</>
													) : null}
												</>
											)}
										</>
									);
								})
								: null}

							{location.pathname.indexOf('livechat-leads') > -1 && additionalsettingMandatoryLeads[0]
								? liveChatcheckList.map((list) => {
									return (
										<>
											{list.title !== 'Custom Field' ? (
												<>
													{LiveLeadsworkflowPicker(
														list.title.trim().toLowerCase()
													) ? (
														<>
															<p className='body2'>{list.title}</p>
															<Input autoFocus fullWidth value='' />
														</>
													) : null}
												</>
											) : (
												<>
													{list.data.fieldName ? (
														<>
															<p className='body2'>{list.data.fieldName}</p>
															<p className='caption'>{list.data.desc}</p>
															{list.data.fieldType.label === 'Text' ? (
																<Input autoFocus fullWidth value='' />
															) : null}
															{list.data.fieldType.label === 'Date' ? (
																<Input
																	fullWidth
																	type='date'
																	value=''
																// endIcon={
																// 	<svg
																// 		xmlns='http://www.w3.org/2000/svg'
																// 		width='24'
																// 		height='24'
																// 		viewBox='0 0 24 24'>
																// 		<path
																// 			data-name='Path 15612'
																// 			d='M0 0h24v24H0z'
																// 			fill='none'
																// 		/>
																// 		<path
																// 			data-name='Path 15613'
																// 			d='M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z'
																// 			fill='rgba(0,0,0,.6)'
																// 		/>
																// 	</svg>
																// }
																/>
															) : null}
															{list.data.fieldType.label === 'Time' ? (
																<Input
																	fullWidth
																	value=''
																	type='time'
																// endIcon={
																// 	<svg
																// 		xmlns='http://www.w3.org/2000/svg'
																// 		width='24'
																// 		height='24'
																// 		viewBox='0 0 24 24'>
																// 		<path
																// 			data-name='Path 15609'
																// 			d='M0 0h24v24H0z'
																// 			fill='none'
																// 		/>
																// 		<path
																// 			data-name='Path 15610'
																// 			d='M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
																// 			fill='rgba(0,0,0,.6)'
																// 		/>
																// 		<path
																// 			data-name='Path 15611'
																// 			d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z'
																// 			fill='rgba(0,0,0,.6)'
																// 		/>
																// 	</svg>
																// }
																/>
															) : null}
															{list.data.fieldType.label === 'Number' ? (
																<Input fullWidth value='' />
															) : null}

															{list.data.fieldType.label === 'Multiple Select'
																? list.data.options.map((val: any) => (
																	<Checkbox
																		label={
																			<p className='body2'>{val.name}</p>
																		}
																		isChecked={[false]}
																		index={0}
																		onValueChange={() => console.log('')}
																	/>
																))
																: null}
															{list.data.fieldType.label === 'Multiple Choice'
																? list.data.options.map((val: any) => (
																	<Radio
																		label={
																			<p className='body2'>{val.name}</p>
																		}
																		isChecked={'false'}
																		name={'choice'}
																		value={''}
																		onValueChange={() => console.log('')}
																	/>
																))
																: null}
															{list.data.fieldType.label === 'Drop Down' ? (
																<>
																	<AutoComplete
																		multiple={false}
																		// label=''
																		options={list.data.options.map(
																			(opt: any) => {
																				return {
																					label: opt.name,
																					value: opt.name,
																				};
																			}
																		)}
																		valueKey='value'
																		labelKey='label'
																		value={{
																			label: list.data.options[0].name,
																			value: list.data.options[0].name,
																		}}
																		onChange={() => console.log('')}
																	/>
																</>
															) : null}
														</>
													) : null}
												</>
											)}
										</>
									);
								})
								: null}
							{location.pathname.indexOf('book-appointment') > -1
								? bookAptCheckList.map((list) => {
									return (
										<>
											{list.title !== 'Custom Field' ? (
												<>
													{BookAptworkflowPicker(
														list.title.trim().toLowerCase()
													) ? (
														<>
															<p className='body2'>{list.title}</p>
															<Input fullWidth value='' />
														</>
													) : null}
												</>
											) : (
												<>
													{list.data.fieldName ? (
														<>
															<p className='body2'>{list.data.fieldName}</p>
															<p className='caption'>{list.data.desc}</p>
															{list.data.fieldType.label === 'Text' ? (
																<Input fullWidth value='' />
															) : null}
															{list.data.fieldType.label === 'Date' ? (
																<Input
																	fullWidth
																	value=''
																	type='date'
																// endIcon={
																// 	<svg
																// 		xmlns='http://www.w3.org/2000/svg'
																// 		width='24'
																// 		height='24'
																// 		viewBox='0 0 24 24'>
																// 		<path
																// 			data-name='Path 15612'
																// 			d='M0 0h24v24H0z'
																// 			fill='none'
																// 		/>
																// 		<path
																// 			data-name='Path 15613'
																// 			d='M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z'
																// 			fill='rgba(0,0,0,.6)'
																// 		/>
																// 	</svg>
																// }
																/>
															) : null}
															{list.data.fieldType.label === 'Time' ? (
																<Input
																	fullWidth
																	value=''
																	type='time'
																// endIcon={
																// 	<svg
																// 		xmlns='http://www.w3.org/2000/svg'
																// 		width='24'
																// 		height='24'
																// 		viewBox='0 0 24 24'>
																// 		<path
																// 			data-name='Path 15609'
																// 			d='M0 0h24v24H0z'
																// 			fill='none'
																// 		/>
																// 		<path
																// 			data-name='Path 15610'
																// 			d='M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
																// 			fill='rgba(0,0,0,.6)'
																// 		/>
																// 		<path
																// 			data-name='Path 15611'
																// 			d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z'
																// 			fill='rgba(0,0,0,.6)'
																// 		/>
																// 	</svg>
																// }
																/>
															) : null}
															{list.data.fieldType.label === 'Number' ? (
																<Input fullWidth value='' />
															) : null}
															{list.data.fieldType.label === 'Multiple Select'
																? list.data.options.map((val: any) => (
																	<Checkbox
																		label={
																			<p className='body2'>{val.name}</p>
																		}
																		isChecked={[false]}
																		index={0}
																		onValueChange={() => console.log('')}
																	/>
																))
																: null}
															{list.data.fieldType.label === 'Multiple Choice'
																? list.data.options.map((val: any) => (
																	<Radio
																		label={
																			<p className='body2'>{val.name}</p>
																		}
																		isChecked={'false'}
																		name={'choice'}
																		value={''}
																		onValueChange={() => console.log('')}
																	/>
																))
																: null}
															{list.data.fieldType.label === 'Drop Down' ? (
																<>
																	<AutoComplete
																		multiple={false}
																		// label=''
																		options={list.data.options.map(
																			(opt: any) => {
																				return {
																					label: opt.name,
																					value: opt.name,
																				};
																			}
																		)}
																		valueKey='value'
																		labelKey='label'
																		value={{
																			label: list.data.options[0].name,
																			value: list.data.options[0].name,
																		}}
																		onChange={() => console.log('')}
																	/>
																</>
															) : null}
														</>
													) : null}
												</>
											)}
										</>
									);
								})
								: null}
							{location.pathname.indexOf('raise-a-ticket') > -1
								? ratCheckList.map((list) => {
									return (
										<>
											{list.title !== 'Custom Field' ? (
												<>
													{RatworkflowPicker(
														list.title.trim().toLowerCase()
													) ? (
														<>
															<p className='body2'>{list.title}</p>
															<Input fullWidth value='' />
														</>
													) : null}
												</>
											) : (
												<>
													{list.data.fieldName ? (
														<>
															<p className='body2'>{list.data.fieldName}</p>
															<p className='caption'>{list.data.desc}</p>
															{list.data.fieldType.label === 'Text' ? (
																<Input value='' />
															) : null}
															{list.data.fieldType.label === 'Date' ? (
																<Input
																	value=''
																	type='date'
																// endIcon={
																// 	<svg
																// 		xmlns='http://www.w3.org/2000/svg'
																// 		width='24'
																// 		height='24'
																// 		viewBox='0 0 24 24'>
																// 		<path
																// 			data-name='Path 15612'
																// 			d='M0 0h24v24H0z'
																// 			fill='none'
																// 		/>
																// 		<path
																// 			data-name='Path 15613'
																// 			d='M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z'
																// 			fill='rgba(0,0,0,.6)'
																// 		/>
																// 	</svg>
																// }
																/>
															) : null}
															{list.data.fieldType.label === 'Time' ? (
																<Input
																	value=''
																	type='time'
																// endIcon={
																// 	<svg
																// 		xmlns='http://www.w3.org/2000/svg'
																// 		width='24'
																// 		height='24'
																// 		viewBox='0 0 24 24'>
																// 		<path
																// 			data-name='Path 15609'
																// 			d='M0 0h24v24H0z'
																// 			fill='none'
																// 		/>
																// 		<path
																// 			data-name='Path 15610'
																// 			d='M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
																// 			fill='rgba(0,0,0,.6)'
																// 		/>
																// 		<path
																// 			data-name='Path 15611'
																// 			d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z'
																// 			fill='rgba(0,0,0,.6)'
																// 		/>
																// 	</svg>
																// }
																/>
															) : null}
															{list.data.fieldType.label === 'Number' ? (
																<Input value='' />
															) : null}
															{list.data.fieldType.label === 'Multiple Select'
																? list.data.options.map((val: any) => (
																	<Checkbox
																		label={
																			<p className='body2'>{val.name}</p>
																		}
																		isChecked={[false]}
																		index={0}
																		onValueChange={() => console.log('')}
																	/>
																))
																: null}
															{list.data.fieldType.label === 'Multiple Choice'
																? list.data.options.map((val: any) => (
																	<Radio
																		label={
																			<p className='body2'>{val.name}</p>
																		}
																		isChecked={'false'}
																		name={'choice'}
																		value={''}
																		onValueChange={() => console.log('')}
																	/>
																))
																: null}
															{list.data.fieldType.label === 'Drop Down' ? (
																<>
																	<AutoComplete
																		multiple={false}
																		// label=''
																		options={list.data.options.map(
																			(opt: any) => {
																				return {
																					label: opt.name,
																					value: opt.name,
																				};
																			}
																		)}
																		valueKey='value'
																		labelKey='label'
																		value={{
																			label: list.data.options[0].name,
																			value: list.data.options[0].name,
																		}}
																		onChange={() => console.log('')}
																	/>
																</>
															) : null}
														</>
													) : null}
												</>
											)}
										</>
									);
								})
								: null}
							<div className='button-holder-submit-cancel'>
								<Button fullWidth>Submit</Button>
							</div>
						</FORMS>
					</CHATBUBBLEDIV>
				</>
			) : (
				<>
					{currentScreen === 'homescreen' ? (
						<HOMESCREENDIV
							fontSize={fontSize.value}
							className='home-screen'
							colorScale={colorScale}
							mode={colorMode}
							selectedColor={selectedColor}>
							<div className='ff-preview-home-screen'>
								<h4>{welcomeMessage}</h4>
								<div className='home-input'>
									<Input placeholder='Type in a message' disabled fullWidth />
								</div>
								<div className='introductory-card-heading'>
									<p className='body2'>{cardHeading}</p>
									<p className='body2'>{cardBody}</p>
								</div>
								{lists.length ? (
									<>
										<div className='carousel-heading caption'>
											{carouselTitle}
										</div>
										<div className='carousel-container'>
											<Carousel
												showArrows={lists.length > 1}
												showThumbs={false}
												showIndicators={lists.length > 1}
												centerSlidePercentage={53}
												showStatus={false}
												swipeable={true}
												centerMode
												width='100%'
												renderArrowNext={(onClickHandler, hasNext) =>
													hasNext && (
														<button
															className={`next-arrow`}
															onClick={onClickHandler}
															style={{
																top: '31%',
																right: '24px',
																position: 'absolute',
															}}>
															<ForwardCarousel
																fill={
																	colorMode === 'dark'
																		? selectedColor
																		: colorScale[0]
																}
															/>
														</button>
													)
												}
												renderArrowPrev={(onClickHandler, hasPrev) =>
													hasPrev && (
														<button
															className={`prev-arrow`}
															onClick={onClickHandler}
															style={{
																top: '31%',
																left: '24px',
																position: 'absolute',
																zIndex: '2',
															}}>
															<BackwardCarousel
																fill={
																	colorMode === 'dark'
																		? selectedColor
																		: colorScale[0]
																}
															/>
														</button>
													)
												}>
												{lists.map((card: any, i: number) => {
													return (
														<>
															<div
																className='carousel-slider-list-item'
																key={card}>
																<img
																	src={card}
																	alt=''
																	style={{
																		borderRadius: '20px',
																		// minWidth: '240px',
																		// maxWidth: '240px',
																		minHeight: '120px',
																		maxHeight: '120px',
																		objectFit: 'cover',
																	}}
																/>
															</div>
														</>
													);
												})}
											</Carousel>
										</div>
									</>
								) : null}
							</div>
						</HOMESCREENDIV>
					) : null}
					{currentScreen === 'getintouch' &&
						location.pathname.indexOf('generate-leads') > -1 ? (
						<CHATBUBBLEDIV
							className={`ff-ai-preview-chatbubble forms getintouch`}
							colorScale={colorScale}
							mode={colorMode}
							selectedColor={selectedColor}>
							<p
								style={{
									marginBottom: '24px',
								}}>
								{location.pathname.indexOf('generate-leads') > -1 ? (
									<p
										className='body2'
										style={{
											color: additionalsettingDetailsCollection[0]
												? colorMode === 'dark'
													? 'white'
													: colorScale[1]
												: '',
										}}>
										{generateLeadsCollectionPrompt}
									</p>
								) : location.pathname.indexOf('book-appointment') > -1 ? (
									<p
										className='body2'
										style={{
											color: additionalsettingDetailsCollection[0]
												? colorMode === 'dark'
													? 'white'
													: colorScale[1]
												: '',
										}}>
										{bookAptCollectionPrompt}
									</p>
								) : location.pathname.indexOf('raise-a-ticket') > -1 ? (
									<p
										className='body2'
										style={{
											color: additionalsettingDetailsCollection[0]
												? colorMode === 'dark'
													? 'white'
													: colorScale[1]
												: '',
										}}>
										{ratCollectionPrompt}
									</p>
								) : location.pathname.indexOf('livechat-leads') > -1 ? (
									<ChatBubble
										by='ai'
										message={
											liveChatLeadsCollectionPrompt ||
											'Please share the following details'
										}
										forms
										propmt
									/>
								) : null}

							</p>
							<FORMS
								colorScale={colorScale}
								mode={colorMode}
								selectedColor={selectedColor}
								outsideChat={additionalsettingDetailsCollection[0]}>
								{location.pathname.indexOf('generate-leads') > -1
									? checkList.map((list) => {
										return (
											<>
												{list.title !== 'Custom Field' ? (
													<>
														{GenerateLeadsworkflowPicker(
															list.title.trim().toLowerCase()
														) ? (
															<>
																<p className='body2'>{list.title}</p>
																<Input fullWidth value='' />
															</>
														) : null}
													</>
												) : (
													<>
														{list.data.fieldName ? (
															<>
																<p className='body2'>{list.data.fieldName}</p>
																<p className='caption'>{list.data.desc}</p>
																{list.data.fieldType.label === 'Text' ? (
																	<Input fullWidth value='' />
																) : null}
																{list.data.fieldType.label === 'Date' ? (
																	<Input
																		fullWidth
																		type='date'
																		value=''
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15612'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15613'
																	// 			d='M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Time' ? (
																	<Input
																		fullWidth
																		value=''
																		type='time'
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15609'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15610'
																	// 			d='M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15611'
																	// 			d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Number' ? (
																	<Input fullWidth value='' />
																) : null}

																{list.data.fieldType.label ===
																	'Multiple Select'
																	? list.data.options.map((val: any) => (
																		<Checkbox
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={[false]}
																			index={0}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label ===
																	'Multiple Choice'
																	? list.data.options.map((val: any) => (
																		<Radio
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={'false'}
																			name={'choice'}
																			value={''}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label === 'Drop Down' ? (
																	<>
																		<AutoComplete
																			multiple={false}
																			// label=''
																			options={list.data.options.map(
																				(opt: any) => {
																					return {
																						label: opt.name,
																						value: opt.name,
																					};
																				}
																			)}
																			valueKey='value'
																			labelKey='label'
																			value={{
																				label: list.data.options[0].name,
																				value: list.data.options[0].name,
																			}}
																			onChange={() => console.log('')}
																		/>
																	</>
																) : null}
															</>
														) : null}
													</>
												)}
											</>
										);
									})
									: null}
								{location.pathname.indexOf('book-appointment') > -1
									? bookAptCheckList.map((list) => {
										return (
											<>
												{list.title !== 'Custom Field' ? (
													<>
														{BookAptworkflowPicker(
															list.title.trim().toLowerCase()
														) ? (
															<>
																<p className='body2'>{list.title}</p>
																<Input fullWidth value='' />
															</>
														) : null}
													</>
												) : (
													<>
														{list.data.fieldName ? (
															<>
																<p className='body2'>{list.data.fieldName}</p>
																<p className='caption'>{list.data.desc}</p>
																{list.data.fieldType.label === 'Text' ? (
																	<Input fullWidth value='' />
																) : null}
																{list.data.fieldType.label === 'Date' ? (
																	<Input
																		fullWidth
																		value=''
																		type='date'
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15612'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15613'
																	// 			d='M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Time' ? (
																	<Input
																		fullWidth
																		value=''
																		type='time'
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15609'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15610'
																	// 			d='M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15611'
																	// 			d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Number' ? (
																	<Input fullWidth value='' />
																) : null}
																{list.data.fieldType.label ===
																	'Multiple Select'
																	? list.data.options.map((val: any) => (
																		<Checkbox
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={[false]}
																			index={0}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label ===
																	'Multiple Choice'
																	? list.data.options.map((val: any) => (
																		<Radio
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={'false'}
																			name={'choice'}
																			value={''}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label === 'Drop Down' ? (
																	<>
																		<AutoComplete
																			multiple={false}
																			// label=''
																			options={list.data.options.map(
																				(opt: any) => {
																					return {
																						label: opt.name,
																						value: opt.name,
																					};
																				}
																			)}
																			valueKey='value'
																			labelKey='label'
																			value={{
																				label: list.data.options[0].name,
																				value: list.data.options[0].name,
																			}}
																			onChange={() => console.log('')}
																		/>
																	</>
																) : null}
															</>
														) : null}
													</>
												)}
											</>
										);
									})
									: null}
								{location.pathname.indexOf('raise-a-ticket') > -1
									? ratCheckList.map((list) => {
										return (
											<>
												{list.title !== 'Custom Field' ? (
													<>
														{RatworkflowPicker(
															list.title.trim().toLowerCase()
														) ? (
															<>
																<p className='body2'>{list.title}</p>
																<Input fullWidth value='' />
															</>
														) : null}
													</>
												) : (
													<>
												    {list.data.fieldName ? (
															<>
																<p className='body2'>{list.data.fieldName}</p>
																<p className='caption'>{list.data.desc}</p>
																{list.data.fieldType.label === 'Text' ? (
																	<Input value='' />
																) : null}
																{list.data.fieldType.label === 'Date' ? (
																	<Input
																		value=''
																		type='date'
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15612'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15613'
																	// 			d='M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Time' ? (
																	<Input
																		value=''
																		type='time'
																	// endIcon={
																	// 	<svg
																	// 		xmlns='http://www.w3.org/2000/svg'
																	// 		width='24'
																	// 		height='24'
																	// 		viewBox='0 0 24 24'>
																	// 		<path
																	// 			data-name='Path 15609'
																	// 			d='M0 0h24v24H0z'
																	// 			fill='none'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15610'
																	// 			d='M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 		<path
																	// 			data-name='Path 15611'
																	// 			d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z'
																	// 			fill='rgba(0,0,0,.6)'
																	// 		/>
																	// 	</svg>
																	// }
																	/>
																) : null}
																{list.data.fieldType.label === 'Number' ? (
																	<Input value='' />
																) : null}
																{list.data.fieldType.label ===
																	'Multiple Select'
																	? list.data.options.map((val: any) => (
																		<Checkbox
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={[false]}
																			index={0}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label ===
																	'Multiple Choice'
																	? list.data.options.map((val: any) => (
																		<Radio
																			label={
																				<p className='body2'>{val.name}</p>
																			}
																			isChecked={'false'}
																			name={'choice'}
																			value={''}
																			onValueChange={() => console.log('')}
																		/>
																	))
																	: null}
																{list.data.fieldType.label === 'Drop Down' ? (
																	<>
																		<AutoComplete
																			multiple={false}
																			// label=''
																			options={list.data.options.map(
																				(opt: any) => {
																					return {
																						label: opt.name,
																						value: opt.name,
																					};
																				}
																			)}
																			valueKey='value'
																			labelKey='label'
																			value={{
																				label: list.data.options[0].name,
																				value: list.data.options[0].name,
																			}}
																			onChange={() => console.log('')}
																		/>
																	</>
																) : null}
															</>
														) : null}
													</>
												)}
											</>
										);
									})
									: null}
								<div className='button-holder-submit-cancel'>
									<Button fullWidth>Submit</Button>
								</div>
							</FORMS>
						</CHATBUBBLEDIV>
					) : null}
					{currentScreen === 'chats' ? (
						<CHATDIV
							className={`chats ${location.pathname.indexOf('generate-leads') > -1 ||
								location.pathname.indexOf('book-appointment') > -1 ||
								location.pathname.indexOf('raise-a-ticket') > -1 || location.pathname.indexOf('livechat-leads') > -1
								? 'formsdisplay'
								: ''
								}`}>
							<div className='chat-body'>
								<ChatBubble by='ai' message={conversationOpener} forms />
								<ChatBubble by='user' message='Hi' />
								{location.pathname.indexOf('generate-leads') > -1 ? (
									<ChatBubble
										by='ai'
										message={
											generateLeadsCollectionPrompt ||
											'Please share the following details'
										}
										forms
										propmt
									/>
								) : null}
								{location.pathname.indexOf('book-appointment') > -1 ? (
									<ChatBubble
										by='ai'
										message={
											bookAptCollectionPrompt ||
											'Please share the following details'
										}
										forms
										propmt
									/>
								) : null}
								{location.pathname.indexOf('raise-a-ticket') > -1 && additionalsettingMandatoryLeads[0] ? (
									<ChatBubble
										by='ai'
										message={
											ratCollectionPrompt ||
											'Please share the following details'
										}
										forms
										propmt
									/>
								) : location.pathname.indexOf('livechat-leads') > -1 && additionalsettingMandatoryLeads[0] ? (
									<ChatBubble
										by='ai'
										message={
											liveChatLeadsCollectionPrompt ||
											'Please share the following details'
										}
										forms
										propmt
									/>
								) : null}


								{location.pathname.indexOf('actions/') > -1 ? (
									<ChatBubble by='ai' message='forms' forms workflow />
								) : null}
							</div>
							<div className='chat-footer'>
								{showpersistentMenu && menudisplay.length > 0 ? (
									<div className='menus'>
										<div className='wrapper'>
											{menudisplay.map((option) => (
												<>
													<p
														className='body1'
														onClick={() => setShowpersistentmenu(false)}>
														{option}
													</p>
												</>
											))}
										</div>
									</div>
								) : null}

								<div className='inputbox'>
									<Input
										fullWidth
										placeholder='Type in a message'
										disabled
										startIcon={
											persistentMenuCheckbox[0] ? (
												<InteractiveButton
													onClick={() =>
														setShowpersistentmenu(!showpersistentMenu)
													}
													icon={<Burger />}
												/>
											) : null
										}
									/>
								</div>

								<div className='feedback-copywrite'>
									<p>Give Feedback</p>
									<p>Powered by Flyfish.ai</p>
								</div>
							</div>
						</CHATDIV>
					) : null}
				</>
			)}
		</NewAIBody>
	);
};

export default AIBody;
