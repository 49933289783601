import './InteractiveButton.scss';
import { ButtonProps, IconButton, Tooltip } from '@mui/material';

type Props = {
	disabled?: boolean;
	size?: 'small' | 'large';
	icon: React.ReactNode;
	hoverable?: 'default' | 'ring' | 'none';
	title?: string;
} & ButtonProps;

const InteractiveButton = ({
	size = 'large',
	disabled,
	icon,
	hoverable = 'default',
	title,
	...rest
}: Props) => {
	return (
		<div className='interactive-button'>
			<Tooltip title={!disabled && title ? title : ''}>
				<IconButton
					disableElevation={true}
					className={`hoverable-${hoverable}`}
					onMouseDown={(event) => event.preventDefault()}
					disableRipple
					disabled={disabled}
					size={size}
					{...rest}>
					{icon}
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default InteractiveButton;
