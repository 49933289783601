import React from 'react';
import './ProgressBar.scss';

const ProgressBar = ({ value, total, ...rest }) => {
  const getPercentage = () => (value/total)*100
  
  return (
    <progress id="progress" value={value} max={total} {...rest}>{getPercentage}%</progress>
  );
};

export default ProgressBar;