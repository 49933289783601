import Input from '../../../Components/Input';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Checkbox from '../../../Components/Checkbox';
import HorizontalSeparator from '../../../Components/HorizontalSeparator';
import {
	createBotConfigurations,
	resetCreateBotConfigurationFeedbackLoader,
	setShowRatingOpt,
	setUserCommentswithFeedBack,
	setBotLaunchPromptCheckbox,
	setAutoPopup,
	setAutoPopupCheckbox,
	setBotLaunchPrompt,
	setbotLaunchPromptValue,
	resetSomethingWentWrong,
	setautoPopupValue,
	setLaunchPromptBackgroundColor,
	setLaunchPromptTextColor,
	setPersistentMenuCheckbox,
	setMenuItems,
	setPersistentMenuFields,
} from '../../../Features/configure/configureSlice';
import { reset, set } from '../../../Features/message/messageSlice';
import { loadState } from '../../../utils/utils';
import './ConfigEnhancements.scss';
import ColorTypes from '../Appearance/ColorTypes';
import Radio from '../../../Components/Radio';
import { ConfigureDelete, ConfigurePlus } from '../../../assets/Icons';
const ConfigEnhancements = () => {
	const location = useLocation();
	const path = location.pathname;
	const items = [
		{
			category: `Show rating options with each ${path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'
				} response`,
			description: '',
		},
		{
			category: 'Collect user comments with feedback',
			description: `If users rate a response given by the ${path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'
				}, they will be given option to further add their comments. Giving comments is optional.`,
		},
	];

	const {
		showRatingOpt,
		userCommentswithFeedBack,
		createBotConfigurationFeedbackLoader,
		botLaunchPromptCheckbox,
		botLaunchPrompt,
		botLaunchPromptValue,
		autoPopup,
		autoPopupCheckbox,
		somethingWentWrong,
		autoPopupValue,
		launchPromptBackgroundColor,
		launchPromptTextColor,
		persistentMenuCheckbox,
		menuItems,
		persistentMenuInputField,
	} = useSelector((state: RootState) => state.configure);

	const botDetails = loadState('botDetails');
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const [buttonLoader, setButtonLoader] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(false);
	const [popuperror, setPopuperror] = useState(false);
	const dispatch = useAppDispatch();
	const [showMenu, setShowMenu] = useState(String);

	useEffect(() => {
		if (createBotConfigurationFeedbackLoader === 'success') {
			dispatch(set('Configurations Saved Successfully'));
			setButtonLoader(false);
		} else if (createBotConfigurationFeedbackLoader === 'failed') {
			dispatch(set(`Couldn't Save !! Something Went Wrong`));
			setButtonLoader(false);
		} else if (createBotConfigurationFeedbackLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetCreateBotConfigurationFeedbackLoader());
	}, [createBotConfigurationFeedbackLoader]);

	useEffect(() => {
		if (botLaunchPromptValue) {
			dispatch(setbotLaunchPromptValue(false));
		}
		if (somethingWentWrong === '') {
			// dispatch(setBotLaunchPromptCheckbox([false]));
			dispatch(resetSomethingWentWrong());
		}
	}, [botLaunchPromptValue, somethingWentWrong]);

	// useeffect
	useEffect(() => {
		if (autoPopupValue) {
			dispatch(setautoPopupValue(false));
		}
		if (somethingWentWrong === '') {
			dispatch(resetSomethingWentWrong());
		}
	}, [autoPopupValue, somethingWentWrong]);

	useEffect(() => {
		if (popuperror == true) {
			setButtonDisable(true);
		} else {
			setButtonDisable(false);
		}
	}, [popuperror]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	// useEffect(() => {
	//   return () => {
	//     {
	//       console.log(menuItems)
	//       setShowMenu(menuItems === "sameAspersistentMenu" || "menuItems" ? true : false);
	//     }
	//     console.log(menuItems);
	//   };
	// }, [menuItems]);

	const autoPopupValidation = (event: any) => {
		let validatingdata = event.target.value;
		// console.log(validatingdata)

		if (!(validatingdata >= 0)) {
			setPopuperror(true);
		} else {
			dispatch(setAutoPopup(validatingdata));
			setPopuperror(false);
		}

		// dispatch(setAutoPopup(event.target.value))
	};

	const handleResponseChange = (index: number, txt: string) => {
		let new_response = persistentMenuInputField.map((value, i) =>
			index !== i ? value : txt
		);

		dispatch(setPersistentMenuFields(new_response));
	};

	const removeAddText = (index: number) => {
		let data = persistentMenuInputField.filter((res, i) => index !== i);
		let data1 = data.filter((res, i) => index - 1 !== i);
		dispatch(setPersistentMenuFields(data1));
	};

	const handleAddText = () => {
		let data = [...persistentMenuInputField, '', ''];
		dispatch(setPersistentMenuFields(data));
	};

	return (
		<div className='ff-config-enhancements'>
			<div className='enhancements-rating'>
				<div>
					<h5 className='feedback-header'>Feedback Collection</h5>
				</div>
				<div className={'rating-options'}>
					<div>
						<Checkbox
							isChecked={showRatingOpt}
							index={0}
							onValueChange={(value) => dispatch(setShowRatingOpt(value))}
						/>
					</div>
					<div>
						{/* <h6>{items[0].category}</h6> */}
						<div className='show-rating-header'>{items[0].category}</div>
						<p className='body2'>{items[0].description}</p>
					</div>
				</div>
				<div className={'enhancements-with-comments'}>
					<div>
						<Checkbox
							isChecked={userCommentswithFeedBack}
							index={0}
							onValueChange={(value) =>
								dispatch(setUserCommentswithFeedBack(value))
							}
						/>
					</div>
					<div>
						{/* <h6>{items[1].category}</h6> */}
						<div className='collect-user-header'>{items[1].category}</div>
						<p className='comments-description body2'>{items[1].description}</p>
					</div>
				</div>
				<HorizontalSeparator />
				{/*  added*/}
				<h5 className='prompt-header'>Launch prompt</h5>
				<div className='radio-text bot-launch-checkbox'>
					<div>
						<Checkbox
							isChecked={botLaunchPromptCheckbox}
							onValueChange={(value) =>
								dispatch(setBotLaunchPromptCheckbox(value))
							}
							index={0}
						/>
					</div>
					<div>
						<p className='enhancements-radio-checkbox body1'>
							Show launch prompt
						</p>
						<p
							className='enhancements-description body2'
							style={{ paddingLeft: '11px' }}>
							When the webpage loads, a popup appears alongside the Ad avatar
							prompting the user to interact with the Ad.&nbsp;
						</p>
					</div>
				</div>
				{botLaunchPromptCheckbox[0] ? (
					<div>
						<Input
							value={botLaunchPrompt}
							onChange={(event) => {
								dispatch(setBotLaunchPrompt(event.target.value));
							}}
							placeholder='E.g. Hi! How can I help you?'
							label='Message to show in the prompt'
							fullWidth
							autoFocus
						/>
					</div>
				) : null}
				{botLaunchPromptCheckbox[0] ? (
					<div className='prompt-text-background'>
						<ColorTypes
							value={launchPromptTextColor}
							onValueChange={(value) =>
								dispatch(setLaunchPromptTextColor(value))
							}
							text='Text colour'
						/>
						<ColorTypes
							value={launchPromptBackgroundColor}
							onValueChange={(value) =>
								dispatch(setLaunchPromptBackgroundColor(value))
							}
							text='Background colour'
						/>
					</div>
				) : null}

				<HorizontalSeparator />

				{/* auto popup added*/}
				<h5 className='prompt-header'>Auto popup</h5>
				<div className='radio-text auto-popup-checkbox'>
					<div>
						<Checkbox
							isChecked={autoPopupCheckbox}
							onValueChange={(value) => dispatch(setAutoPopupCheckbox(value))}
							index={0}
						/>
					</div>
					<div>
						<p className='enhancements-radio-checkbox body1'>
							Enable auto popup
						</p>
						<p
							className='enhancements-description body2'
							style={{ paddingLeft: '11px' }}>
							The Ad automatically pops open after a specified time after the
							webpage loads. This works only for the web channel.
						</p>
					</div>
				</div>
				{autoPopupCheckbox[0] ? (
					<div>
						<Input
							// value={autoPopup}
							errorText='Please enter a number greater than or equal to 0'
							error={popuperror}
							onChange={(event) => autoPopupValidation(event)}
							placeholder='Enter a number'
							fullWidth
							label='Auto popup time'
							helperText='Enter the number of seconds after which the Ad should
              automatically popup'
						/>
					</div>
				) : null}

				{/* Persistent menu */}
				<HorizontalSeparator />
				<h5 className='prompt-header'>Persistent Menu</h5>
				<div className='radio-text auto-popup-checkbox'>
					<div>
						<Checkbox
							isChecked={persistentMenuCheckbox}
							onValueChange={(value) =>
								dispatch(setPersistentMenuCheckbox(value))
							}
							index={0}
						/>
					</div>
					<div>
						<p className='enhancements-radio-checkbox body1'>
							Show persistent menu
						</p>
						<p
							className='enhancements-description body2'
							style={{ paddingLeft: '11px' }}>
							An ever-accessible. collapsible menu in the bot that shows
							commonly asked queries or topics
						</p>
					</div>
				</div>
				{persistentMenuCheckbox[0] ? (
					<div className='persistent-menu-holder'>
						<p className='enhancements-radio-checkbox body1'>Menu items</p>
						<div className='persiatent-menu-div'>
							<span className='radio-text'>
								<Radio
									value='sameAspersistentMenu'
									isChecked={menuItems}
									onValueChange={(value) => {
										// console.log(value, menuItems);
										setShowMenu(
											value == 'sameAspersistentMenu' ? 'true' : 'false'
										);
										dispatch(setMenuItems(value));
									}}
									name='persiatent-menu'
								/>
								<p className='appearance-radio-checkbox body1'>
									Show same items in the menu as quick links
								</p>
							</span>
							<span className='radio-text'>
								<Radio
									value='customMenu'
									isChecked={menuItems}
									onValueChange={(value) => {
										// console.log(value, menuItems);
										setShowMenu(
											value == 'sameAspersistentMenu' ? 'true' : 'false'
										);
										dispatch(setMenuItems(value));
									}}
									name='persiatent-menu'
								/>
								<p className='appearance-radio-checkbox body1'>
									Provide a custom menu
								</p>
							</span>
						</div>

						<div>
							{menuItems === 'customMenu' ? (
								<div>
									<div className='persistent-menu-container'>
										<span className='persistent-menu-header'>
											<span>Text to display</span>
										</span>
										<span className='persistent-menu-header'>
											<span>Text to submit</span>
										</span>
										<span className='persistent-menu-header'>
											<span></span>
										</span>
										{persistentMenuInputField.map((data, index) => {
											return (
												<>
													<span className='persistent-menu-input-container'>
														<Input
															type='text'
															onChange={(event) =>
																handleResponseChange(index, event.target.value)
															}
															value={data}
															placeholder='Enter text'
															required
														/>
													</span>
													{index % 2 !== 0 ? (
														<div
															className='svg-container'
															onClick={() => removeAddText(index)}>
															<ConfigureDelete />
														</div>
													) : null}
												</>
											);
										})}
									</div>
									<Button startIcon={<ConfigurePlus />} onClick={handleAddText}>
										Add menu item
									</Button>

									{/* <table style={{justifyContent: "left"}}> 
                    <tr> */}
									{/* <div style={{display: "flex"}}>
                    <span>Text to display</span>
                    <span>Text to submit</span>
                  </div>
                  <hr></hr>
                  <table>
                    <tr>
                      <td>
                        <Input
                          value={displayText}
                          onChange={(event) =>
                            dispatch(setDisplayText(event.target.value))
                          }
                          placeholder="Add custom title"
                        />
                      </td>
                      <td>
                        <Input
                          value={displayText}
                          onChange={(event) =>
                            dispatch(setDisplayText(event.target.value))
                          }
                          placeholder="Add custom title"
                        />
                      </td>
                    </tr>
                    {additem ? (
                      <tr>
                        <td>
                          <Input
                            value={displayText}
                            onChange={(event) =>
                              dispatch(setDisplayText(event.target.value))
                            }
                            placeholder="Add custom title"
                          />
                        </td>
                        <td>
                          <Input
                            value={displayText}
                            onChange={(event) =>
                              dispatch(setDisplayText(event.target.value))
                            }
                            placeholder="Add custom title"
                          />
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>
                        <Button
                          onClick={() => {
                            setAdditem(true);
                          }}
                        >
                          Add menu Item
                        </Button>
                      </td>
                    </tr>
                  </table> */}
								</div>
							) : null}
						</div>
					</div>
				) : null}
			</div>

			<div className='config-enhancements-save-button-div'>
				<Button
					className='config-enhancements-save-button'
					loading={buttonLoader}
					disabled={buttonDisable}
					onClick={() => {
						let key: string[] = [];
						let value: string[] = [];
						let persistentMenu: {
							linkText: string;
							linkValue: string;
						}[] = [];
						persistentMenuInputField.forEach((data, i) => {
							(i === 0 || i % 2 === 0) && key.push(data);
							i % 2 !== 0 && value.push(data);
						});

						for (let i = 0; i < value.length; i++) {
							persistentMenu.push({
								linkText: key[i],
								linkValue: value[i],
							});
						}
						persistentMenu = persistentMenu.filter(
							(link) => link.linkText !== ''
						);

						let data = {
							botId: botDetails.botId,
							tenantId: tenantId,
							isResponseLevelFeedbackEnabled: showRatingOpt[0],
							popComment: userCommentswithFeedBack[0],
							// botLaunchPrompt
							launcherPromptMessage: botLaunchPrompt,
							launcherPromptBackground: launchPromptBackgroundColor,
							launcherPromptColor: launchPromptTextColor,
							isLauncherPromptEnabled: botLaunchPromptCheckbox[0],
							// autopopup
							isAutoPopupEnabled: autoPopupCheckbox[0],
							autoPopTime: autoPopup,

							// persistentMenu
							isShowPersistentMenuEnabled: persistentMenuCheckbox[0],
							persistentMenu: persistentMenuCheckbox[0]
								? `${JSON.stringify(persistentMenu)}`
								: '',
							isShowSameItemsMenuEnabled: showMenu,
						};
						// buttonValidation();
						dispatch(createBotConfigurations(data));
					}}>
					Save
				</Button>
			</div>
		</div>
	);
};

export default ConfigEnhancements;
