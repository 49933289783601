import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../app/store';
import {
	setChatInput,
	setMsgsArray,
	setMsgsLoading,
	setViewCartDetails,
} from '../../../../../../../Features/SalesAI/salesSlice';
import { loadState } from '../../../../../../../utils/utils';
import './Properties.scss';

type Props = {
	data: any;
	timeStamp: string;
	message: React.ReactFragment | 'add-size' | 'add-color' | 'add-quantity';
};

const Properties = (props: Props) => {
	const { data, timeStamp, message } = props;
	const dispatch = useDispatch();
	const { msgsArray, chatInput } = useSelector(
		(state: RootState) => state.sales
	);
	const [color, setColor] = useState('');
	const [size, setSize] = useState('');
	const [quantity, setQuantity] = useState(0);
	const [property, setProperty] = useState('quantity');
	const [isValid, setValid] = useState('');

	const handleviewCartResponse = (variantId: string, quantity: number) => {
		let viewcartData = {
			data: '',
			productVariantId: variantId,
			quantity: quantity,
		};
		// let baseURL = window.origin;
		// let baseURL = "https://dev.flyfish.ai/shopify";
		let baseURL = window.origin + '/route/shop';
		// window.origin.indexOf('preprod') > -1
		// 	? 'https://preprod.flyfish.ai/shopify'
		// 	: window.origin.indexOf('app') > -1
		// 	? 'https://app.flyfish.ai/shopify'
		// 	:
		fetch(`${baseURL}/product/cart/create`, {
			method: 'POST',
			headers: {
				'X-Tenant-Id': loadState('tenantId').toString(),
				// 'X-Tenant-Id': '687',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${loadState('token')}`,
			},
			body: JSON.stringify(viewcartData),
		})
			.then((res) => res.json())
			.then((data) => {
				if (data && data.response.status.toLowerCase() === 'success') {
					dispatch(setViewCartDetails(data.response.data.data.cart));
					dispatch(setMsgsLoading('done'));
					dispatch(
						setMsgsArray([
							...msgsArray,
							{ content: chatInput, role: 'user' },
							{ content: 'view-cart', role: 'assistant' },
						])
					);
				}
			});
	};

	const handlechatMessage = () => {
		if (property === 'size') {
			let filteredSize =
				data &&
				data.options.map(
					(option: any) =>
						option.name === 'Size' &&
						option.values.filter(
							(size: any, index: number) =>
								index + 1 === Number(chatInput) ||
								size.toLowerCase() === chatInput.toLowerCase()
						)
				);
			setSize(filteredSize[0][0]);

			if (filteredSize[0][0]) {
				dispatch(
					setMsgsArray([
						...msgsArray,
						{ content: chatInput, role: 'user' },
						{ content: 'add-color', role: 'assistant' },
					])
				);
				setProperty('color');
			} else {
				setProperty('size');
				dispatch(
					setMsgsArray([
						...msgsArray,
						{ content: chatInput, role: 'user' },
						{ content: 'add-size', role: 'assistant' },
					])
				);
			}
		} else if (property === 'color') {
			let filteredColor =
				data &&
				data.options.map(
					(option: any) =>
						option.name === 'Color' &&
						option.values.filter(
							(size: any, index: number) =>
								index + 1 === Number(chatInput) ||
								size.toLowerCase() === chatInput.toLowerCase()
						)
				);
			setColor(filteredColor[1][0]);
			if (filteredColor[1][0]) {
				dispatch(
					setMsgsArray([
						...msgsArray,
						{ content: chatInput, role: 'user' },
						{ content: 'add-quantity', role: 'assistant' },
					])
				);
				setProperty('quantity');
			} else {
				setProperty('color');
				dispatch(
					setMsgsArray([
						...msgsArray,
						{ content: chatInput, role: 'user' },
						{ content: 'add-color', role: 'assistant' },
					])
				);
			}
		} else if (property === 'quantity') {
			setQuantity(Number(chatInput));
			dispatch(
				setMsgsArray([...msgsArray, { content: chatInput, role: 'user' }])
			);
			dispatch(setMsgsLoading('loading'));
			handleviewCartResponse(
				data.variants[0].admin_graphql_api_id,
				Number(chatInput)
			);

			// let filterVariant = data.variants.filter(
			// 	(variant: any) => variant.option1 === size && variant.option2 === color
			// );
			// if (filterVariant && filterVariant.length > 0) {
			// 	dispatch(
			// 		setMsgsArray([
			// 			...msgsArray,
			// 			{ content: chatInput, role: 'user' },
			// 			{ content: 'view-cart', role: 'assistant' },
			// 		])
			// 	);
			// 	handleviewCartResponse(
			// 		filterVariant[0].admin_graphql_api_id,
			// 		Number(chatInput)
			// 	);
			// } else {
			// 	dispatch(
			// 		setMsgsArray([...msgsArray, { content: chatInput, role: 'user' }])
			// 	);
			// }
			// setProperty('');
		}
		dispatch(setChatInput(''));
		// dispatch(setMsgsLoading(''));
	};

	useEffect(() => {
		const keyDownHandler = (event: any) => {
			if (event.key === 'Enter' && !isNaN(Number(chatInput))) {
				event.preventDefault();

				if (chatInput.length >= 1) {
					handlechatMessage();
				}
			}
		};

		document.addEventListener('keydown', keyDownHandler);
		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [chatInput, msgsArray]);

	let tip = 'To select an option, you can enter its option number. E.g. ';

	return message === 'add-size' && data ? (
		<div className='product-properties-container'>
			<div className='size-inner-container'>
				{/* <p className='body1'>
                    Sorry, I didn’t get it 😞. Please make sure that you select one of the options from the following.
                </p> */}
				<p className='product-size body1'>Select size</p>
				{data &&
					data.options &&
					data.options.map((size: any, index: number) => {
						return (
							size.name === 'Size' &&
							size.values &&
							size.values.map((value: any, index: number) => {
								return (
									<p className='type body1' key={index}>
										{index + 1}. {value}
									</p>
								);
							})
						);
					})}
				<p className='size-tip body2'>
					<b>💡 Tip: </b>
					{tip}
					<i>1</i>
				</p>
			</div>
			<p className='timeline caption'>{timeStamp}</p>
		</div>
	) : message === 'add-color' && data ? (
		<div className='product-properties-container'>
			<div className='color-inner-container'>
				<p className='product-color body1'>Select color</p>
				{data &&
					data.options &&
					data.options.map((color: any, index: number) => {
						return (
							color.name === 'Color' &&
							color.values &&
							color.values.map((value: any, index: number) => {
								return (
									<p className='type body1' key={index}>
										{index + 1}. {value}
									</p>
								);
							})
						);
					})}
				<p className='color-tip body2'>
					<b>💡 Tip: </b>
					{tip}
					<i>1</i>
				</p>
			</div>
			<p className='timeline caption'>{timeStamp}</p>
		</div>
	) : message === 'add-quantity' && data ? (
		<div className='product-properties-container'>
			<p className='product-quantity body1'>Please enter quantity?</p>
			<p className='timeline caption'>{timeStamp}</p>
		</div>
	) : null;
};
export default Properties;
