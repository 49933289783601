import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { saveState } from '../../utils/utils';
import shopifyService from './shopifyService';

type State = {
	shopifydata: any;
	linkshopifydata: any;
	isLoading: boolean;
	linkShopifyLoader: string;
	error: string;
	errorMessage: string;
	shopifyLinkStatus: string;
	shopifyUnlinkLoader: string;
	shopifystorename: string;
};

export type GetData = {
	ecomId: number | string;
	ecomDomain: string;
	ecomAccess: string;
	ecomKey: string;
	tenantId: number | string;
	embeddings: string;
};
export type Unlinkdata = {
	tenantId: number | string;
	embeddings: string;
};

const initialState: State = {
	shopifydata: {},
	linkshopifydata: {},
	error: '',
	isLoading: false,
	linkShopifyLoader: '',
	errorMessage: '',
	shopifyLinkStatus: '',
	shopifyUnlinkLoader: '',
	shopifystorename: '',
};

export const linkstore = createAsyncThunk<
	any,
	GetData,
	{ rejectValue: string }
>('/route/shop/ecom/link', async (data, { rejectWithValue }) => {
	try {
		const response: any = await shopifyService.getData(data);
		if (response.data) {
			// console.log(response.data.data.storeDetails.shop.name);
			saveState('linkstatus', 'linked');
			saveState('storeName', response.data.data.storeDetails.shop.name);
			return response.data.data.storeDetails.shop.name;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	}
});

export const unlinkstore = createAsyncThunk<
	any,
	Unlinkdata,
	{ rejectValue: string }
>('/shopify/ecom/unlink', async (data, { rejectWithValue }) => {
	try {
		const response: any = await shopifyService.unlinkdata(data);
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

const shopifySlice = createSlice({
	name: 'shopify',
	initialState,
	reducers: {
		resetlink: (state) => {
			state.error = '';
		},
		resetunlink: (state) => {
			state.error = '';
		},
		reset: (state) => {
			state.linkShopifyLoader = '';
		},
		resetShopifyLinkStatus: (state) => {
			state.shopifyLinkStatus = '';
		},
		resetShopifyUnlinkLoader: (state) => {
			state.shopifyUnlinkLoader = '';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(linkstore.pending, (state) => {
				state.isLoading = true;
				state.linkShopifyLoader = 'loading';
				state.error = '';
			})
			.addCase(linkstore.fulfilled, (state: State, action) => {
				state.shopifydata = action.payload;
				state.isLoading = false;
				state.shopifyLinkStatus = action.payload.status;
				state.shopifystorename = action.payload;
				if (action.payload.status === 'failure') {
					state.errorMessage = action.payload.message;
					state.linkShopifyLoader = 'failure';
				} else {
					state.linkShopifyLoader = 'success';
				}
			})
			.addCase(linkstore.rejected, (state, action) => {
				state.linkshopifydata = {};
				state.isLoading = false;
				state.linkShopifyLoader = 'failure';
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
					if (error.error === 'Network error') {
						state.isLoading = true;
					}
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(unlinkstore.pending, (state) => {
				state.isLoading = true;
				state.error = '';
				state.shopifyUnlinkLoader = 'loading';
			})

			.addCase(unlinkstore.fulfilled, (state: State, action) => {
				state.shopifydata = action.payload;
				state.isLoading = false;
				state.shopifyUnlinkLoader = 'success';
			})
			.addCase(unlinkstore.rejected, (state, action) => {
				state.shopifydata = {};
				state.isLoading = false;
				state.shopifyUnlinkLoader = 'failed';
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
					if (error.error === 'Network error') {
						state.isLoading = true;
					}
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			});
	},
});

export const {
	resetlink,
	resetunlink,
	reset,
	resetShopifyLinkStatus,
	resetShopifyUnlinkLoader,
} = shopifySlice.actions;

export default shopifySlice.reducer;
