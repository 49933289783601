import { useState, useEffect } from 'react';
import './Templates.scss';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	setFilter,
	setOffset,
	getList,
	reset,
	updateList,
	deleteTemplate,
} from '../../../Features/WhatsApp-BSP/template/templateSlice';
import { TemplateListItem } from './model';
import { Outlet } from 'react-router-dom';
import { set } from '../../../Features/message/messageSlice';
import { debounce } from '../../../utils/utils';
import Button from '../../../Components/Button';
import { Cached } from '@mui/icons-material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Pagination from '../../../Components/Table/Pagination';
import Table from '../../../Components/Table';
import InteractiveButton from '../../../Components/Button/InteractiveButton';
import Menu, { Options } from '../../../Components/Menu';
import Chip from '../../../Components/Chip';
import Modal from '../../../Components/Modal';

const LIMIT = 20;

function Templates() {
	const [UpperL, setUpperL] = useState(0);
	const [LowerL, setLowerL] = useState(0);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<Array<Options>>([]);
	const { wabaNo } = useSelector((state: RootState) => state.BSPauth);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState<TemplateListItem>();
	const [syncButton, setSyncButton] = useState('Sync Templates');
	const {
		list,
		total,
		offset,
		filter,
		isLoading,
		error,
		success,
		isTemplateDeleted,
		isTemplateDelError,
		sync,
	} = useSelector((state: RootState) => state.template);
	const dispatch = useAppDispatch();
	// const navigate = useNavigate();

	const handleDeleteTemplate = () => {
		selectedTemplate &&
			dispatch(
				deleteTemplate({
					wabaNo,
					templateId: selectedTemplate.templateId,
				})
			);
		setModalOpen(false);
		dispatch(getList({ offset: 0, wabaNo }));
	};
	useEffect(() => {
		dispatch(setOffset(0));
		dispatch(setFilter(''));
		dispatch(getList({ offset: 0, wabaNo }));
	}, []);

	useEffect(() => {
		if (!isLoading) {
			let data: { offset: number; templateName?: string; wabaNo: string } = {
				offset,
				wabaNo,
			};
			if (filter) {
				data.templateName = filter;
			}
			debounce(() => dispatch(getList(data)), 600);
		}
	}, [offset, filter]);

	useEffect(() => {
		if (list && list.length > 0) {
			setUpperL(list.length + offset);
			setLowerL(offset + 1);
		}
	}, [list]);

	useEffect(() => {
		if (success.length > 0) {
			dispatch(set(success));
			dispatch(setOffset(0));
			dispatch(setFilter(''));
			dispatch(getList({ offset: 0, wabaNo }));
		} else if (error && error.length > 0) {
			dispatch(set(error));
		}
	}, [success, error]);

	useEffect(() => {
		if (isTemplateDeleted.length > 0) {
			dispatch(set(isTemplateDeleted));
			dispatch(setOffset(0));
			dispatch(setFilter(''));
			dispatch(getList({ offset: 0, wabaNo }));
		} else if (isTemplateDelError.length > 0) {
			dispatch(set(isTemplateDelError));
		}
	}, [isTemplateDeleted, isTemplateDelError]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (sync === 'synced') {
			setSyncButton('Sync Templates');
		} else {
			setSyncButton('Syncing...');
		}
	}, [sync]);

	const getUpdate = () => {
		dispatch(updateList({ wabaNo }));
	};

	const pagination = () => {
		if (total < 20) {
			return <></>;
		}
		return (
			<Pagination
				disableforward={total <= offset + list.length}
				disableback={offset === 0}
				nextPage={() => dispatch(setOffset(offset + LIMIT))}
				previousPage={() => dispatch(setOffset(offset - LIMIT))}
				gotoBegining={() => dispatch(setOffset(0))}
				gotoLast={() => dispatch(setOffset(Math.floor(total / LIMIT) * LIMIT))}
				totalCount={total}
				upper={UpperL}
				lower={LowerL}
			/>
		);
	};

	const getCode = (status: string) => {
		switch (status.toUpperCase()) {
			case 'APPROVED':
				return 'positive';
			case 'DELETED':
				return 'neutral';
			case 'REJECTED':
				return 'negative';
			case 'APPROVAL PENDING':
				return 'slightly-negative';
			default:
				return 'neutral';
		}
	};

	const column = [
		{ key: 'templateName', title: 'Template Name' },
		{ key: 'type', title: 'Type' },
		{ key: 'body', title: 'Preview', width: 310 },
		{
			key: 'status',
			title: 'Status',
			render: (row: any) => (
				<Chip label={row.status} code={getCode(row.status) ?? ''} />
			),
		},
		// { key: 'lastUpdatetime', title: 'Last updated' },
		{
			key: 'action',
			title: '',
			render: (arg: any) => (
				<>
					{arg.status.toLowerCase() !== 'deleted' && (
						<div className='three-dot'>
							<InteractiveButton
								icon={<MoreVertOutlinedIcon />}
								onClick={(event: any) => {
									event.stopPropagation();
									setAnchorEl(event.target);

									setOpen((prev) => !prev || anchorEl !== event.target);
									setOptions([
										// {
										// 	label: <p className='menu-label'>Edit</p>,
										// 	onClick: () => {
										// 		setModalOpen(false);
										// 	},
										// },
										{
											label: <p className='menu-label'>Delete</p>,
											onClick: () => {
												setModalOpen(true);
												setSelectedTemplate(arg);
												setOpen(false);
											},
										},
									]);
								}}
							/>
						</div>
					)}
				</>
			),
		},
	];

	return (
		<>
			<div className='sf-template-header'>
				<h4>Templates</h4>
				<div className='sf-template-search-and-button'>
					<div className='sf-template-search'>
						{/* !add later */}
						{/* <Search
								value={filter}
								isLoading={isLoading}
								onClear={() => {
									dispatch(setFilter(''));
									dispatch(setOffset(0));
								}}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									dispatch(setFilter(event.target.value));
									dispatch(setOffset(0));
								}}
							/> */}
					</div>

					<Button onClick={() => getUpdate()} startIcon={<Cached />}>
						<p className='button-1'>{syncButton}</p>
					</Button>
				</div>
			</div>
			<div className='sf-template-tabular-data'>
				{total > 0 || isLoading ? (
					<>
						<Table
							height={'calc(100vh - 205px)'}
							column={column}
							limit={LIMIT}
							page={offset / LIMIT}
							pagination={pagination()}
							totalCount={total}
							isLoading={isLoading}
							data={list}
						/>
						<Menu
							anchorEl={anchorEl}
							open={open}
							setOpen={setOpen}
							options={options}
						/>
					</>
				) : (
					<p className='body-2 not-found'>No templates found</p>
				)}
				<Outlet />
			</div>
			{modalOpen && (
				<Modal
					onClose={() => setModalOpen(false)}
					actionButtons={
						<>
							<Button buttonType='light' onClick={() => setModalOpen(false)}>
								Cancel
							</Button>
							<Button onClick={() => handleDeleteTemplate()}>Delete</Button>
						</>
					}>
					<div>
						Please confirm,do you want to delete{' '}
						<b>{selectedTemplate?.templateName}?</b>
					</div>
				</Modal>
			)}
		</>
	);
}

export default Templates;
