import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../app/store';
import { FFLoader } from '../../../../assets/Icons/Loader';
import {
	setAIName,
	setAvatarImageLoader,
	uploadImage,
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import { reset } from '../../../../Features/message/messageSlice';
import { BOT_ICONS } from '../../../CatalogScreen/AddNewBot';
import { loadState } from '../../../../utils/utils';
import './General.scss';
import Input from '../../../../Components/Input';
import DefaultAvatar from '../../../../assets/images/bot-icons/DefaultAvatar.png';

const GeneralEOW = () => {
	const { avatarImage, avatarImageLoader, aiName } = useSelector(
		(state: RootState) => state.experienceonweb
	);

	const dispatch = useAppDispatch();
	const avatarRef = useRef<HTMLInputElement>(null!);
	const [loader, setLoader] = useState(false);

	const fileUploadInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		let fileRef = avatarRef;
		if (fileRef && fileRef.current) {
			let fileInput = fileRef.current;
			var filePath = fileInput.value;
			// Allowing file type
			var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
			if (filePath) {
				if (!allowedExtensions.exec(filePath)) {
					alert('Invalid Media :- Supported media formats are .jpg,.jpeg,.png');
					fileInput.value = '';
					return false;
				} else {
					let file = e.target.files && e.target.files[0];
					const MAX_FILE_SIZE = 5120; // 5MB in binary
					const fileSizeKiloBytes = file && file.size / 1024;
					if (fileSizeKiloBytes! > MAX_FILE_SIZE) {
						// setFileSizeExceed(true);

						alert(
							'File size should be maximum 5 MB Format should be PNG or JPEG or JPG'
						);
					} else {
						if (e.target.files) {
							const formData = new FormData();
							formData.append(
								'file',
								e.target.files[0],
								e.target.files[0].name
							);
							dispatch(
								uploadImage({
									file: formData,
									botId: loadState('botId'),
									tenantId: loadState('tenantId'),
									uploadTo: '',
								})
							);

							fileInput.value = '';
						}
					}
				}
			}
		}
	};

	useEffect(() => {
		if (avatarImageLoader === 'success') {
			setLoader(false);
			// dispatch(
			// 	setSnack({
			// 		message: 'Avatar uploaded successfully',
			// 		testcase: 'positive',
			// 	})
			// );
			dispatch(reset());
		} else if (avatarImageLoader === 'failed') {
			setLoader(false);
			// dispatch(
			// 	setSnack({
			// 		message: 'Something Went Wrong!! Please try after sometime',
			// 		testcase: 'negative',
			// 	})
			// );
			dispatch(reset());
		} else if (avatarImageLoader === 'loading') {
			setLoader(true);
		}
		dispatch(setAvatarImageLoader(''));
	}, [avatarImageLoader]);
	return (
		<>
			<div className='ff-eow-avatar-container'>
				<div className='ff-eow-avatar'>
					<h4>General</h4>
					<div className='avatar'>
						<h6>Avatar</h6>
						<p className='body1'>Choose an image that represents your AI</p>
						<div>
							{loader ? (
								<FFLoader width='40' />
							) : (
								<img
									src={avatarImage.length > 0 ? avatarImage : BOT_ICONS[0]}
									alt=''
								/>
							)}

							<label htmlFor='ff-eow-avatar-image' className='button2'>
								Change
							</label>
						</div>
					</div>

					<Outlet />

					<input
						type='file'
						accept='image/png, image/jpg, image/jpeg'
						id='ff-eow-avatar-image'
						ref={avatarRef}
						onChange={fileUploadInput}
						name='ff-eow-avatar-image'
						style={{ visibility: 'hidden' }}
					/>
				</div>
				<div className='ff_header_container'>
					<h6 className='title'>AI Name</h6>
					<p className='body1'>
						This name will be shown in the header of the AI interface
					</p>
					<Input
						className='botname-input'
						value={aiName}
						onChange={(event) => {
							dispatch(
								setAIName(
									event.target.value.trim().length > 0
										? event.target.value
										: event.target.value.trim()
								)
							);
						}}
						placeholder='Enter text to be used as the title'
						fullWidth
					/>
				</div>
			</div>
		</>
	);
};

export default GeneralEOW;
