export const NEWTABS = [
	{
		Tab: {
			title: 'Team Details',
			path: 'team-details',
		},
	},
	{
		Tab: {
			title: 'Subscription',
			path: 'Subscription',
		},
		SubTabs: [],
	},
	{
		Tab: {
			title: 'Integrations',
			path: 'Integrations',
		},
		SubTabs: [
			{
				title: 'All',
				path: 'Integrations/all',
			},
			{
				title: 'General',
				path: 'Integrations/general',
			},
			{
				title: 'CRMs',
				path: 'Integrations/crms',
			},
			{
				title: 'E-commerce',
				path: 'Integrations/ecom',
			},
			{
				title: 'Ticket Management',
				path: 'Integrations/ticket-management',
			}
		],
	},
	{
		Tab: {
			title: 'Users',
			path: 'Users',
		},
		SubTabs: [],
	},
];
