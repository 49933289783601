import './ActionsMenu.scss';
import {
	APICall,
	ConfirmCollected,
	Image,
	Keyboard,
	MultipleChoice,
	MultipleSelect,
	SendCollectedValues,
	Star,
	Text,
	TriggerQuery,
	HandoffToAgent,
} from '../../../assets/Icons';

type ActionsMenuProps = {
	action1: { label: React.ReactNode; onClick: () => void };
	action3: { label: React.ReactNode; onClick: () => void };
	action5: { label: React.ReactNode; onClick: () => void };
	action8: { label: React.ReactNode; onClick: () => void };
	action10: { label: React.ReactNode; onClick: () => void };
	action11: { label: React.ReactNode; onClick: () => void };
	action12: { label: React.ReactNode; onClick: () => void };
	action150: { label: React.ReactNode; onClick: () => void };
	action14: { label: React.ReactNode; onClick: () => void };
	action0: { label: React.ReactNode; onClick: () => void };
};

const ActionsMenu = ({
	action1,
	action3,
	action5,
	action8,
	action10,
	action11,
	action12,
	action150,
	action14,
	action0,
}: ActionsMenuProps) => {
	return (
		<div className='actions-list'>
			<div className='action action1' onClick={action1.onClick}>
				<div className='icon'>
					<Text width='15px' />/ <Image width='15px' />
				</div>
				{action1.label}
			</div>
			<div className='action action5' onClick={action5.onClick}>
				<div className='icon'>
					<MultipleChoice width='22px' />
				</div>
				{action5.label}
			</div>
			<div className='action action3' onClick={action3.onClick}>
				<div className='icon'>
					<MultipleSelect width='22px' />
				</div>
				{action3.label}
			</div>

			<div className='action action150' onClick={action150.onClick}>
				<div className='icon'>
					<Keyboard width='22px' />
				</div>
				{action150.label}
			</div>

			<div className='action action11' onClick={action11.onClick}>
				<div className='icon'>
					<Star width='22px' />
				</div>
				{action11.label}
			</div>

			<div className='action action10' onClick={action10.onClick}>
				<div className='icon'>
					<APICall width='22px' />
				</div>
				{action10.label}
			</div>

			<div className='action action0' onClick={action0.onClick}>
				<div className='icon'>
					<HandoffToAgent width='15px' />
				</div>
				{action0.label}
			</div>

			<div className='action action12' onClick={action12.onClick}>
				<div className='icon'>
					<TriggerQuery width='21px' />
				</div>
				{action12.label}
			</div>

			<div className='action action14' onClick={action14.onClick}>
				<div className='icon'>
					<SendCollectedValues width='22px' />
				</div>
				{action14.label}
			</div>
			<div className='action action8' onClick={action8.onClick}>
				<div className='icon'>
					<ConfirmCollected width='22px' />
				</div>
				{action8.label}
			</div>
		</div>
	);
};
export default ActionsMenu;
