import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import AutoComplete from '../../../Components/AutoComplete';
import Checkbox from '../../../Components/Checkbox';
import Input from '../../../Components/Input';
import {
	resetInvalidtranslationkey,
	setDropdownvalue,
	setIsMultilingualEnabled,
	setShowBotPreviewCollapsed,
	setTranslationApiKey,
} from '../../../Features/ExperienceOnWeb/experienceonwebSlice';

import { reset } from '../../../Features/message/messageSlice';
import './MultilingualSupport.scss';

const MultilingualSupport = () => {
	const {
		isMultiLingualEnabled,
		translationApiKey,
		languageOptions,
		availableLanguages,
		dropdownValue,
		invalidtranslationkey,
	} = useSelector((state: RootState) => state.experienceonweb);
	const dispatch = useAppDispatch();
	const [error, setError] = useState(false);
	const dropdownOptions1 = [
		{ label: 'Google', value: 'Google' },
		{ label: 'Microsoft Azure', value: 'Microsoft Azure' },
		{ label: 'Other', value: 'Other' },
	];
	const dropdownOptions2 = [{ label: 'English', value: 'English' }];

	const [dropdownValue1, setDropdownValue1] = useState<any>(
		dropdownOptions1[0]
	);

	const [dropdownValue2, setDropdownValue2] = useState<any>(
		dropdownOptions2[0]
	);

	// const validate = (val: any) => {
	// 	if (val.length <= 0) {
	// 		return false;
	// 	}
	// 	let avl = availableLanguages.map((lang) => lang.language.toLowerCase());
	// 	return !Boolean(avl.indexOf(val.toLowerCase()) > -1);
	// };

	useEffect(() => {
		dispatch(setShowBotPreviewCollapsed(true));
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (invalidtranslationkey === 'loading') {
			setError(false);
			dispatch(resetInvalidtranslationkey());
		} else if (invalidtranslationkey === 'success') {
			setError(false);
			dispatch(resetInvalidtranslationkey());
		} else if (invalidtranslationkey === 'Invalid translate api key.') {
			setError(true);
			dispatch(resetInvalidtranslationkey());
		}
	}, [invalidtranslationkey]);

	return (
		<div className={`ff-configure-multilingual-support`}>
			<h4 className={`${isMultiLingualEnabled[0] ? 'dropped' : ''}`}>
				Multilingual Support
			</h4>

			<div
				className={`multilingual-container ${
					isMultiLingualEnabled[0] ? 'dropped' : ''
				}`}>
				<div
					className={`multilingual-support`}
					onClick={() => isMultiLingualEnabled[0]}>
					<div>
						<p className='body1'>Enable Multilingual Support</p>
					</div>
					<div>
						<Checkbox
							index={0}
							isChecked={isMultiLingualEnabled}
							onValueChange={(value) => {
								dispatch(setIsMultilingualEnabled(value));
							}}
						/>
					</div>
				</div>

				{isMultiLingualEnabled[0] ? (
					<div className='multilingual-enabled'>
						<hr />
						<h6>Translation Service</h6>
						<p className='body1'>
							Provide the details of the translation service that you want to
							use
						</p>
						<AutoComplete
							multiple={false}
							label='Translation Service'
							placeholder='Select'
							options={dropdownOptions1}
							valueKey='value'
							labelKey='label'
							value={dropdownValue1}
							onChange={setDropdownValue1}
						/>

						<div className='translation-api-key'>
							<div>
								<Input
									fullWidth
									error={error}
									errorText={'Invalid API Key'}
									label={'Translation API key'}
									autoFocus
									value={translationApiKey}
									onChange={(event) =>
										dispatch(setTranslationApiKey(event.target.value))
									}
									placeholder='Enter translation API key'
									helperText={
										'Please enter the API key for the selected translation service'
									}
								/>
							</div>
						</div>
						<hr />
						<h6>Languages</h6>
						<div className='languages'>
							<div>
								<AutoComplete
									label='Supported Languages'
									placeholder='Enter language'
									// validator={validate}
									multiple={false}
									helperText='Your users will be able to choose to chat in any of these languages besides English'
									options={languageOptions}
									valueKey='value'
									labelKey='label'
									value={dropdownValue}
									onChange={(value) => dispatch(setDropdownvalue(value))}
								/>
							</div>
						</div>
						<div className='default-language'>
							<AutoComplete
								multiple={false}
								label='Default Language'
								placeholder='English'
								options={dropdownOptions2}
								valueKey='value'
								labelKey='label'
								value={dropdownValue2}
								onChange={setDropdownValue2}
								helperText='The AI will start the conversation in this language'
							/>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default MultilingualSupport;
