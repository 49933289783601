/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import Button from '../../../../Components/Button';
import Dropdown, {
	DropdownValue,
	DropdownValueProps,
} from '../../../../Components/Dropdown';
import Modal from '../../../../Components/Modal';
import { BotBuilderPlus, SearchClose } from '../../../../assets/Icons';
import { commonActionParams } from '../common';
import '../ActionsModal.scss';
import './TextImage.scss';
import Input from '../../../../Components/Input';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	addDisplayAction,
	getDisplayAction,
	resetActionDetails,
} from '../../../../Features/builder/builderSlice';
import {
	DefaultConnection,
	GetAction,
} from '../../../../Features/builder/model';
import { useSelector } from 'react-redux';
import { getIconForNextAction } from '../common';
import {
	checkImageURL,
	checkUrl,
	checkVideoURL,
	getActionName,
	findMediaType,
} from '../../../../utils/utils';
import builderService from '../../../../Features/builder/builderService';
import NextNewAction from '../NextNewAction';
import InteractiveButton from '../../../../Components/Button/InteractiveButton';
import { CloseBtn } from '../../../../Components/Modal/icons';
import { set } from '../../../../Features/message/messageSlice';
import { SmallDeleteIcon, SmallEditIcon } from '../../../../assets/Icons/Bot';

type Response = {
	text: string;
	type: string;
};

const Display = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const context: any = useOutletContext();
	const state: any = location.state;
	const taskAction: GetAction = state.action;
	const [label, setLabel] = useState('Next Action');
	const { actionDetails, botId, tenantId, actions, activeQuery } = useSelector(
		(state: RootState) => state.builder
	);

	const nextActions = actions
		.filter(
			(action) =>
				action.task_ID !== taskAction.taskId &&
				action.taskHierarchyDTO[0] !== taskAction.taskId
		)
		.map((action) => ({
			value: action.task_ID.toString(),
			label: (
				<div className={`actions-label action${action.userResponseTypeID}`}>
					{getIconForNextAction(action.userResponseTypeID)}
					<p>{action.task_name}</p>
				</div>
			),
			labelValue: action.task_name,
		})) as DropdownValueProps[];

	nextActions.push({
		value: 'add new action',
		label: (
			<div className='actions-label new-action'>
				{getIconForNextAction(-1)}
				<p>New Action</p>
			</div>
		),
	});

	const [openNextActionType, setOpenNextActionType] = useState(false);
	const [nextActionTypeId, setNextActionTypeId] = useState(0);
	const [response, setResponse] = useState<Array<Response>>([
		{ text: 'Hey there', type: 'text' },
	]);
	const [openImageModal, setOpenImageModal] = useState(false);
	const [changeModal, setChangeModal] = useState('');
	const [changeMedia, setChangeMedia] = useState('');
	const [currentUrl, setCurrentUrl] = useState('');
	const [singleResponse, setSingleResponse] = useState('Display');
	const [edit, setEdit] = useState(false);
	const [editIndex, setEditIndex] = useState<number | null>(0);
	useEffect(() => {
		botId &&
			tenantId &&
			dispatch(
				getDisplayAction({
					botId,
					tenantId,
					taskId: taskAction.taskId,
					workflowId: taskAction.workflowId,
				})
			);

		return () => {
			dispatch(resetActionDetails());
		};
	}, []);

	// useEffect(() => {
	// 	if (currentUrl) {
	// 		setUrlErrorType('');
	// 		if (currentUrl.split('/')[0] === 'http:') {
	// 			setUrlErrorType(
	// 				'HTTP links are not supported. Please enter an HTTPS link'
	// 			);
	// 		} else if (
	// 			!checkImageURL(currentUrl) &&
	// 			changeMedia.indexOf('image') > -1
	// 		) {
	// 			setUrlErrorType('Please enter a URL pointing to an image');
	// 		} else if (
	// 			!checkVideoURL(currentUrl) &&
	// 			changeMedia.indexOf('video') > -1
	// 		) {
	// 			setUrlErrorType('Please enter a URL pointing to a video');
	// 		} else setUrlErrorType('');
	// 	} else {
	// 		setUrlErrorType('');
	// 	}
	// }, [changeMedia, currentUrl]);

	useEffect(() => {
		const next = actionDetails?.defaultConnection;
		if (next && actions) {
			let action = actions.filter(
				(action) => action.task_ID === next.childtaskId
			)[0];

			if (action) {
				let next_action: DropdownValue = {
					value: next.childtaskId.toString(),
					label: (
						<div className={`actions-label action${action.userResponseTypeID}`}>
							{getIconForNextAction(action.userResponseTypeID)}
							<p>{action.task_name}</p>
						</div>
					),
					labelValue: next.childTaskName ?? '',
				};
				context.setNextAction(next_action);
			}
		}

		actionDetails?.responseElements &&
			actionDetails?.responseElements.length > 0 &&
			setResponse(
				actionDetails?.responseElements.map((res: any) => {
					if (res.responseType === 'SIMPLE') {
						setSingleResponse(res.displayResponse);
						return {
							text:
								res.displayResponse !== actionDetails?.taskName
									? res.displayResponse
									: '',
							type: 'text',
						};
					} else if (res.responseType === 'LIST') {
						if (checkImageURL(res.lists.items[0].resourceURL)) {
							return { text: res.lists.items[0].resourceURL, type: 'image' };
						} else {
							return { text: res.lists.items[0].resourceURL, type: 'video' };
						}
					}
				})
			);
	}, [actionDetails]);

	useEffect(() => {
		const next = context.nextAction as DropdownValueProps;
		if (next && next.value === 'add new action' && !openNextActionType) {
			setOpenNextActionType(true);
		}
	}, [context.nextAction]);

	useEffect(() => {
		let next_action: DropdownValue = {
			value: label,

			label: (
				<div className={`actions-label action${nextActionTypeId}`}>
					{getIconForNextAction(nextActionTypeId)}

					<p>{label}</p>
				</div>
			),

			labelValue: label ?? 'Untitled',
		};

		context.setNextAction(next_action);
	}, [label]);

	const handleResponseChange = (index: number, txt: string) => {
		setResponse((prev) =>
			prev.map((value, i) =>
				index !== i ? value : { text: txt, type: 'text' }
			)
		);
	};

	const handleAddText = () => {
		setResponse((prev) => [...prev, { text: '', type: 'text' }]);
	};

	const handleAddImage = () => {
		setOpenImageModal(false);
		if (currentUrl.length > 0) {
			setResponse((prev) => [...prev, { text: currentUrl, type: 'image' }]);
		}
		setCurrentUrl('');
	};

	const handleAddVideo = () => {
		setOpenImageModal(false);
		if (currentUrl.length > 0) {
			setResponse((prev) => [...prev, { text: currentUrl, type: 'video' }]);
		}
		setCurrentUrl('');
	};

	const handleEditMedia = () => {
		setEdit(false);
		setOpenImageModal(false);

		setResponse((response: Response[]) =>
			response.map((eachResponse: Response, index: number) => {
				if (eachResponse.type === 'image' || eachResponse.type === 'video') {
					let mediaResponse: Response = {
						text: currentUrl,

						type: checkImageURL(currentUrl) ? 'image' : 'video',
					};

					return index === editIndex ? mediaResponse : eachResponse;
				} else {
					return eachResponse;
				}
			})
		);

		setCurrentUrl('');
	};

	const handleRemoveResponse = (index: number) => {
		setResponse((prev) => prev.filter((res, i) => index !== i));
	};

	const showError = (url: string) => {
		if (url.indexOf('http:') > -1) {
			return 'HTTP links are not supported. Please enter an HTTPS link';
		} else if (changeMedia === 'image') {
			if (!checkImageURL(url)) {
				return 'Please enter a URL pointing to an image';
			}
		} else if (changeMedia === 'video') {
			if (checkVideoURL(url)) {
				if (url.indexOf('youtube') > -1) {
					if (url.indexOf('watch?v') > -1) {
						let demoLink = (
							<span>
								Please enter the embed code URL for{' '}
								<a
									href='https://support.google.com/youtube/answer/171780?hl=en'
									style={{ textDecoration: 'underline' }}
									target='_blank'
									rel='noreferrer'>
									YouTube
								</a>{' '}
								and{' '}
								<a
									href='https://vimeo.com/644036051'
									style={{ textDecoration: 'underline' }}
									target='_blank'
									rel='noreferrer'>
									Vimeo
								</a>{' '}
								videos
							</span>
						);

						return demoLink;
						//    let youtubeEmbedLink = <a href='https://support.google.com/youtube/answer/171780?hl=en'>YouTube</a>
						//    let vimeoLink = <a href='https://vimeo.com/644036051'>Vimeo</a>
						// return `Please enter the embed code URL for YouTube and Vimeo videos`;
					}
				}
			} else {
				return 'Please enter a URL pointing to a video';
			}
		} else {
			return '';
		}
	};

	const handleSave = async () => {
		if (actionDetails) {
			if (
				actions.filter(
					(action) =>
						action.task_name === context.actionName &&
						action.task_ID !== actionDetails.taskId
				).length > 0
			) {
				dispatch(set('Action name already exists'));

				return false;
			}

			let next = context.nextAction as DropdownValueProps;
			let defaultConnection: DefaultConnection = {
				connectionId: 0,
				childtaskId: 0,
				conditionType: '',
				childTaskName: null,
			};

			if (nextActionTypeId !== 0 && botId && tenantId) {
				//call add action
				let position = actionDetails?.taskPosition.split('d');
				let task_position = '3000d4000';
				if (position) {
					task_position = `${parseInt(position[0]) + 110}d${position[1]}`;
				}
				const common = commonActionParams(actionDetails?.workflowId, false);
				const new_task = await builderService.addAction({
					...common,
					previousTaskId: 0,
					botId,
					tenantId,
					userResponseTypeID: nextActionTypeId,
					task_position,
					task_name: getActionName(actions, nextActionTypeId),
				});

				if (new_task.status === 201) {
					defaultConnection.childtaskId = Number(
						new_task.data.data.task.task_ID
					);
					defaultConnection.childTaskName = 'Untitled';
				}
			} else {
				if (next) {
					defaultConnection.childtaskId = Number(next.value);
					defaultConnection.childTaskName = next.labelValue ?? '';
				}
			}

			let responseElements: any = [];

			let responses = response.filter((res) => res.text !== '');

			if (responses.length > 0) {
				responseElements = responses.map((res, index) => {
					if (res.type === 'image' || res.type === 'video') {
						// image  // video
						return {
							actualResponse: '',
							card: {},
							displayResponse: '',
							lists: {
								title: `Card ${index}`,
								items: [
									{
										cardId: null,
										cardLayout: 8,
										collapsed: false,
										description: '',
										externalURL: '',
										imageOrVideoURL: '',
										index: 0,
										multiLingual: {},
										resourceType: findMediaType(res.type, res.text),
										resourceURL: res.text,
										text: '',
										title: 'Title 1',
										type: 'Card',
										value: '',
									},
								],
							},
							multiLingual: {},
							responseId: 0,
							responseIndex: index,
							responseType: 'LIST',
							speechResponse: '',
						};
					} else {
						// text
						return {
							actualResponse: '',
							card: {},
							displayResponse: res.text,
							lists: {},
							multiLingual: {},
							responseId: 0,
							responseIndex: index,
							responseType: 'SIMPLE',
							speechResponse: '',
						};
					}
				});
			} else {
				responseElements = [
					{
						card: null,
						displayResponse: '',
						lists: null,
						multiLingual: {},
						responseIndex: null,
						responseLayout: null,
						responseType: 'SIMPLE',
						speechResponse: '',
					},
				];
			}

			activeQuery &&
				dispatch(
					addDisplayAction({
						...actionDetails,
						taskName: context.actionName,
						currentNodeId: activeQuery.nodeId,
						defaultConnection,
						responseElements,
						userResponseTypeId: 1,
						userResponseType: 'Display',
						taskDescription: '',
					})
				);
		}

		navigate(-1);
	};

	return (
		<>
			<div className='body-content'>
				<div className='action-content'>
					<div className='left-pane'>
						{response.map((res, index) =>
							res.type === 'text' ? (
								<div className='chip-input'>
									<Input
										fullWidth
										multiline
										limit={200}
										placeholder='Enter message'
										value={res.text}
										onChange={(event) => {
											setSingleResponse(event.target.value);
											handleResponseChange(index, event.target.value);
										}}
										key={index}
										className='text-input'
									/>
									<InteractiveButton
										title='Delete'
										icon={
											<SmallDeleteIcon
												opacity='0.6'
												width='20px'
												height='20px'
											/>
										}
										onClick={() => {
											if (res.text) {
												setSingleResponse(
													response[index - 1]
														? response[index - 1].type === 'text'
															? response[index - 1].text
															: ''
														: response[index].text
												);
											} else {
												setSingleResponse(response.length > 0 ? 'Display' : '');
											}
											handleRemoveResponse(index);
										}}
									/>
								</div>
							) : res.type === 'image' ? (
								<div className='image-container' key={index}>
									<div className='show-media'>
										{res.text.indexOf('drive.google.com') > -1 ? (
											<img
												src={`https://drive.google.com/uc?export=view&id=${
													res.text.split('d/')[1].split('/view')[0]
												}`}
												alt=''
											/>
										) : (
											<img src={res.text} alt='' />
										)}
									</div>
									<div className='show-button'>
										<InteractiveButton
											title={res.type === 'image' ? 'Edit-Image' : ''}
											icon={<SmallEditIcon opacity='0.6' />}
											onClick={() => {
												setOpenImageModal(true);
												if (res.type === 'image') {
													setChangeModal('Edit-Image');
													setChangeMedia('image');
												}
												setCurrentUrl(res.text);
												setEdit(true);
												setEditIndex(index);
											}}
										/>
										<InteractiveButton
											title={res.type === 'image' ? 'Delete-Image' : ''}
											icon={
												<SmallDeleteIcon
													opacity='0.6'
													width='20px'
													height='20px'
												/>
											}
											onClick={() => handleRemoveResponse(index)}
										/>
									</div>
								</div>
							) : (
								<div className='video-container' key={index}>
									<div className='show-media'>
										{res.text.indexOf('drive.google.com') > -1 ? (
											<>
												<iframe
													src={`${res.text.replace(
														'/view',
														'/preview'
													)}`}></iframe>
											</>
										) : res.text.indexOf('mp4') > -1 ? (
											<video controls>
												<source src={res.text} type='video/mp4' />
											</video>
										) : (
											<iframe
												src={res.text}
												allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
												allowFullScreen></iframe>
										)}
									</div>
									<div className='show-button'>
										<InteractiveButton
											title={res.type === 'video' ? 'Edit-Video' : ''}
											icon={<SmallEditIcon opacity='0.6' />}
											onClick={() => {
												setOpenImageModal(true);
												if (res.type === 'video') {
													setChangeModal('Edit-Video');
													setChangeMedia('video');
												}
												setCurrentUrl(res.text);
												setEdit(true);
												setEditIndex(index);
											}}
										/>
										<InteractiveButton
											title={res.type === 'video' ? 'Delete-Video' : ''}
											icon={<SmallDeleteIcon opacity='0.6' width='20px' />}
											onClick={() => {
												handleRemoveResponse(index);
											}}
										/>
									</div>
								</div>
							)
						)}

						<div className='button-container'>
							<Button
								startIcon={<BotBuilderPlus width='15px' />}
								onClick={() => {
									handleAddText();
									setSingleResponse('');
								}}>
								Text message
							</Button>
							<Button
								startIcon={<BotBuilderPlus width='15px' />}
								onClick={() => {
									setOpenImageModal(true);
									setChangeModal('Add-Image');
									setChangeMedia('image');
								}}>
								Image
							</Button>
							<Button
								startIcon={<BotBuilderPlus width='15px' />}
								onClick={() => {
									setOpenImageModal(true);
									setChangeModal('Add-Video');
									setChangeMedia('video');
								}}>
								Video
							</Button>
						</div>
					</div>
					<div className='right-pane'>
						<Dropdown
							isSearchable
							label='Next Action'
							value={context.nextAction}
							options={nextActions}
							onChange={context.setNextAction}
							className='next-action-dropdown'
						/>
					</div>
				</div>

				<footer>
					<Button
						onClick={handleSave}
						disabled={
							context.actionName.trim().length > 0 &&
							response.length > 0 &&
							(singleResponse.trim().length > 0 ? true : false)
								? false
								: true
						}>
						Save
					</Button>
				</footer>
			</div>

			<NextNewAction
				open={openNextActionType}
				setOpenNextActionType={setOpenNextActionType}
				setNextActionTypeId={setNextActionTypeId}
				setLabel={setLabel}
				onClose={() => {
					setOpenNextActionType(false);
					if (nextActionTypeId === 0) {
						context.setNextAction(null);
					}
				}}
			/>

			{openImageModal && (
				<Modal
					header={
						changeModal.indexOf('Add-Image') > -1
							? 'Add Image'
							: edit && changeModal.indexOf('Edit-Image') > -1
							? 'Edit Image Link'
							: changeModal.indexOf('Add-Video') > -1
							? 'Add Video'
							: edit && changeModal.indexOf('Edit-Video') > -1
							? 'Edit Video Link'
							: null
					}
					action='Done'
					modalcontainerClassName='modal-image-container'
					onClose={() => {
						setOpenImageModal(false);
						setEdit(false);
						setCurrentUrl('');
					}}
					actionButtons={
						<>
							<Button
								onClick={() => {
									setOpenImageModal(false);
									setEdit(false);
									setCurrentUrl('');
								}}
								buttonType='light'>
								Cancel
							</Button>
							<Button
								disabled={Boolean(currentUrl ? showError(currentUrl) : true)}
								onClick={
									edit
										? () => handleEditMedia()
										: changeModal.indexOf('Add-Image') > -1
										? handleAddImage
										: handleAddVideo
								}>
								Done
							</Button>
						</>
					}>
					<div style={{ width: '100%' }}>
						<Input
							fullWidth
							limit={2000}
							placeholder={
								changeMedia.indexOf('image') > -1
									? 'E.g. https://example.com/example.jpeg'
									: 'E.g. https://example.com/example.mp4'
							}
							label={
								changeMedia.indexOf('image') > -1 ? 'Image Link' : 'Video Link'
							}
							value={currentUrl}
							onChange={(event) => setCurrentUrl(event.target.value)}
							autoFocus
							error={Boolean(currentUrl && showError(currentUrl))}
						/>
						{currentUrl && (
							<span
								className='caption'
								style={{ color: 'var(--error-red)', paddingLeft: '15px' }}>
								{showError(currentUrl)}
							</span>
						)}
					</div>
				</Modal>
			)}
		</>
	);
};
export default Display;
