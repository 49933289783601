import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/store';
import {
	setMsgsArray,
	setShopifyCall,
	setViewCartDetails,
} from '../../../../../../Features/SalesAI/salesSlice';
import './AddtoCart.scss';

type Props = {
	data: any;
	timeStamp: string;
};

const AddtoCart = (props: Props) => {
	const { data, timeStamp } = props;
	const dispatch = useDispatch();
	const { msgsArray } = useSelector((state: RootState) => state.sales);
	const getProductContent = (data: any) => {
		dispatch(
			setMsgsArray([
				...msgsArray,
				{ content: 'Add To Cart', role: 'user' },
				// ,{content: 'add-size', role: 'assistant'}
				{ content: 'add-quantity', role: 'assistant' },
			])
		);
		dispatch(setShopifyCall('stop-analyse'));
	};
	const continueShopping = () => {
		// dispatch(setShopifyCall('start-analyse'));
		dispatch(
			setMsgsArray([
				...msgsArray,
				{ content: 'Continue Shopping', role: 'user' },
				{
					content:
						'Hello, how may I help you? We have wide range of beauty products to select from.',
					role: 'assistant',
				},
			])
		);
		dispatch(setViewCartDetails(data));
		//dispatch(setMultiProductsState('adding-multiple-products'))
	};

	return data && data.length > 0 ? null : (
		<div className='ff-add-to-cart-container'>
			{data.images &&
				data.images.map((image: any, index: number) => {
					return (
						<div className='image-variants' key={index}>
							<img src={image.src} alt='' />
							{/* <p className='timeline caption'>{timeStamp}</p> */}
						</div>
					);
				})}
			<div className='add-to-cart-product-content'>
				<p className='product-title body1'>{data.title}</p>
				<p className='product-price body2'>{data.variants[0].price}</p>
				<p className='product-description body2'>{data.description}</p>
				<p className='timeline caption'>{timeStamp}</p>
			</div>
			<button
				className='add-to-cart-select-button'
				onClick={() => getProductContent(data)}>
				<p className='button-text'>Add To Cart</p>
			</button>
			<button
				className='add-to-cart-select-button'
				onClick={() => continueShopping()}>
				<p className='button-text'>Continue Shopping</p>
			</button>
		</div>
	);
};
export default AddtoCart;
