import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import Modal from '../../../../Components/Modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import './createNewPassword.scss';
import {
	ChangePassworderrCheck,
	ChangePasswordStrongCheck,
	LoginEyeOpen,
} from '../../../../assets/Icons';
import {
	changePasswordResponseerr,
	clearPasswordResponse,
	currentPwdResponse,
	PasswordChange,
} from '../../../../Features/ChangePassword/ChangePassSlice';
import { encryptText, loadState } from '../../../../utils/utils';

// type Props = {
// 	Cancel: Function;
// 	Next: Function;
// };

function CreateNewPassword() {
	const dispatch = useAppDispatch();
	const [showPassword, setShowPassword] = useState(false);
	const [oldPassword, setOldPassword] = useState('');
	const [errMsg, setErrMsg] = useState<string | undefined>(undefined);
	// const oldPassword = useSelector(
	// 	(state: RootState) => state.changePassword.encryptedCurrentPassword
	// );
	// const { encryptPassword } = useSelector((state: RootState) => state.auth);

	const {
		changePasswordStatus,
		changePasswordResponse,
		PasswordChangeStatus,
		encryptedCurrentPassword,
	} = useSelector((state: RootState) => state.changePassword);
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const passwordStrong = [
		{
			text: 'Password should be minimum 8 characters',
			errmsgStatus: newPassword.length >= 8 ? 'check' : 'error',
		},
		{
			text: 'Password should have atleast one special character',
			errmsgStatus: newPassword.search(/[!@#$%^&+=]/) > -1 ? 'check' : 'error',
		},
		{
			text: 'Password should have atleast one uppercase character',
			errmsgStatus: newPassword.search(/[A-Z]/) > -1 ? 'check' : 'error',
		},
		{
			text: 'Password should have atleast one lowercase character',
			errmsgStatus: newPassword.search(/[a-z]/) > -1 ? 'check' : 'error',
		},
		{
			text: 'Password should have atleast one number',
			errmsgStatus: newPassword.search(/[0-9]/) > -1 ? 'check' : 'error',
		},
		{
			text: 'Password should match',
			errmsgStatus: newPassword === confirmNewPassword ? 'check' : 'error',
		},
	];

	const { user } = useSelector((state: RootState) => state.auth);
	useEffect(() => {
		changePasswordStatus === 'Success' &&
			dispatch(
				currentPwdResponse({
					changecurrentPassword: false,
					changePassword: false,
					changePasswordSuccess: true,
				})
			);
		//  Next();
	}, [changePasswordStatus]);

	useEffect(() => {
		if (confirmNewPassword !== '') {
			newPassword !== confirmNewPassword &&
				setErrMsg('The confirmed password did not match');
		}
	}, [confirmNewPassword]);

	useEffect(() => {
		setErrMsg(changePasswordResponse && changePasswordResponse.message);
	}, [changePasswordResponse]);

	const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(changePasswordResponseerr(''));
		setConfirmNewPassword(e.target.value.trimStart());
		newPassword !== confirmNewPassword && setErrMsg(undefined);
	};

	const handleNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(changePasswordResponseerr(''));

		setNewPassword(e.target.value.trimStart());
	};

	const check = passwordStrong.map((pwd) => pwd.errmsgStatus);

	const buttons = () => {
		return (
			<>
				<Button
					onClick={() => dispatch(clearPasswordResponse())}
					buttonType='light'>
					Cancel
				</Button>
				<Button
					loading={PasswordChangeStatus}
					onClick={() => {
						if (user) {
							// const encryptUserId = decodeURIComponent(user.encryptedUserId);
							// console.log(loadState('basictoken'))
							dispatch(
								PasswordChange(
									// 	{
									// 	encryptedNewPassword: encryptText(newPassword),
									// 	encryptedOldPassword: oldPassword,
									// 	encryptedUserId: encryptUserId,
									// }

									{
										data: {
											oldPassword: encryptText(oldPassword),
											newPassword: encryptText(newPassword),
										},
										userId: loadState('user').userID,
									}
								)
							);
						}
					}}
					disabled={
						check.includes('error') ||
						Boolean(errMsg) ||
						!Boolean(confirmNewPassword)
					}>
					<p>Change Password</p>
				</Button>
			</>
		);
	};
	return (
		<div>
			<Modal header='Create a new strong password' actionButtons={buttons()}>
				<div className='ff_input_container'>
					<Input
						type={showPassword ? 'text' : 'password'}
						value={oldPassword}
						onChange={(e) => setOldPassword(e.target.value)}
						placeholder='Old password'
						autoFocus
						endIcon={
							<div
								className='ff_input_icon '
								onClick={() => setShowPassword(!showPassword)}>
								<LoginEyeOpen fillIcon={true} color='#8991a3' />
							</div>
						}
					/>
				</div>
				<div className='ff_input_container'>
					<Input
						type={showPassword ? 'text' : 'password'}
						// className='ff_create_newpassword'
						value={newPassword}
						onChange={handleNewPassword}
						placeholder='New password'
						endIcon={
							<div
								className='ff_input_icon '
								onClick={() => setShowPassword(!showPassword)}>
								<LoginEyeOpen fillIcon={true} color='#8991a3' />
							</div>
						}
					/>
				</div>
				<div className='ff_input_container'>
					<Input
						type={showPassword ? 'text' : 'password'}
						className='ff_confirm_newpassword'
						value={confirmNewPassword}
						onChange={handlePassword}
						placeholder='Confirm new password'
						errorText={errMsg}
						error={Boolean(errMsg)}
						endIcon={
							<div
								className={
									!Boolean(errMsg) ? 'ff_input_icon ' : 'ff_input_icon_error'
								}
								onClick={() => setShowPassword(!showPassword)}>
								<LoginEyeOpen fillIcon={true} color='#8991a3' />
							</div>
						}
					/>
				</div>
				<div>
					{passwordStrong.map((errmsg, i) => (
						<div className='ff_newpassword_Conditions' key={errmsg.text + i}>
							{newPassword === '' && confirmNewPassword === '' ? (
								<div className='ff_newpassword_Conditions_Pointer'></div>
							) : errmsg.errmsgStatus === 'check' ? (
								<ChangePasswordStrongCheck />
							) : (
								<ChangePassworderrCheck />
							)}
							<div
								className={
									errmsg.errmsgStatus !== 'check'
										? newPassword === ''
											? 'ff_newpassword_errcon'
											: 'ff_newpwd_errcon'
										: 'ff_newpassword_errcon'
								}>
								{errmsg.text}
							</div>
						</div>
					))}
				</div>
			</Modal>
		</div>
	);
}

export default CreateNewPassword;
