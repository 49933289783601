import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	ConsentJob,
	getContactsAnalytics,
	getContactsAnalyticsForInProgressImports,
	reset,
	setOffset,
} from '../../../../Features/WhatsApp-BSP/contact/contactSlice';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import ProgressBar from '../../../../Components/ProgressBar';
import './Imports.scss';
import { useSelector } from 'react-redux';
import { dateFormat } from '../../../../utils/utils';
import Table from '../../../../Components/Table';
import { useEffect } from 'react';
import Chip from '../../../../Components/Chip';
import Pagination from '../../../../Components/Table/Pagination';
import DataFetchAPI from '../../../../utils/Api';
const LIMIT = 10;

const Imports = () => {
	const [UpperL, setUpperL] = useState(0);
	const [LowerL, setLowerL] = useState(0);
	const [UpperL2, setUpperL2] = useState(0);
	const [LowerL2, setLowerL2] = useState(0);
	const dispatch = useAppDispatch();
	const {
		pastContactJobsheets,
		inProgressContactJobsheets,
		isLoading,
		totalPastContactJobSheets,
		totalInProgressContactJobSheets,
		isInProgressLoading,
	} = useSelector((state: RootState) => state.contact);
	const { wabaNo } = useSelector((state: RootState) => state.BSPauth);

	const [offset, setOffset] = useState(0);
	const [offset2, setOffset2] = useState(0);
	useEffect(() => {
		dispatch(
			getContactsAnalytics({ offset: offset, wabaNo, status: 'completed' })
		);

		return () => {
			dispatch(reset());
		};
	}, [offset]);

	useEffect(() => {
		dispatch(
			getContactsAnalyticsForInProgressImports({
				offset: offset2,
				wabaNo,
				status: 'inprogress',
			})
		);

		return () => {
			dispatch(reset());
		};
	}, [offset2]);
	useEffect(() => {
		if (pastContactJobsheets.length > 0) {
			setUpperL(pastContactJobsheets.length + offset);
			setLowerL(offset + 1);
		}
	}, [pastContactJobsheets]);

	useEffect(() => {
		if (inProgressContactJobsheets.length > 0) {
			setUpperL(inProgressContactJobsheets.length + offset2);
			setLowerL(offset2 + 1);
		}
	}, [inProgressContactJobsheets]);

	const getStatusProgress = (job: ConsentJob) => {
		switch (job.status.toLowerCase()) {
			case 'xlparsing':
				return (
					<>
						<div className='progress '>
							{/* <p className="body-2">Updating… (0/{job.totalCount})</p>
              <ProgressBar value={0} total={job.totalCount} /> */}
							<p className='body2'>Updating… (0/10)</p>
							<div>
								<ProgressBar value={0} total={10} />
							</div>
						</div>
					</>
				);

			case 'xlparsed':
				return (
					<>
						<div className='progress'>
							<p className='body-2'>Updating… ({job.isParsed ?? 0})</p>
							{/* <ProgressBar value={job.isParsed ?? 0} total={job.totalCount} /> */}
						</div>
					</>
				);
			case 'execution started':
			case 'execution completed':
				return (
					<>
						<div className='progress'>
							{/* <p className="body-2">
                Updating… ({job.delivered ?? 0}/{job.totalCount})
              </p>
              <ProgressBar value={job.delivered ?? 0} total={job.totalCount} /> */}
							<p className='body2'>Updating… (${job.isParsed}/10)</p>
							<ProgressBar value={job.isParsed} total={10} />
						</div>
					</>
				);

			default:
				break;
		}
	};

	const paginationForInProgress = () => {
		if (inProgressContactJobsheets.length < 10) {
			return <></>;
		}
		return (
			<Pagination
				disableforward={
					totalInProgressContactJobSheets <=
					offset2 + inProgressContactJobsheets.length
				}
				disableback={offset === 0}
				nextPage={() => setOffset2(offset2 + LIMIT)}
				previousPage={() => setOffset2(offset2 - LIMIT)}
				gotoBegining={() => setOffset2(0)}
				gotoLast={() =>
					setOffset2(
						Math.floor(totalInProgressContactJobSheets / LIMIT) * LIMIT
					)
				}
				totalCount={totalInProgressContactJobSheets}
				upper={UpperL2}
				lower={LowerL2}
			/>
		);
	};
	const pagination = () => {
		if (pastContactJobsheets.length < 10) {
			return <></>;
		}
		return (
			<Pagination
				disableforward={
					totalPastContactJobSheets <= offset + pastContactJobsheets.length
				}
				disableback={offset === 0}
				nextPage={() => setOffset(offset + LIMIT)}
				previousPage={() => setOffset(offset - LIMIT)}
				gotoBegining={() => setOffset(0)}
				gotoLast={() =>
					setOffset(Math.floor(totalPastContactJobSheets / LIMIT) * LIMIT)
				}
				totalCount={totalPastContactJobSheets}
				upper={UpperL}
				lower={LowerL}
			/>
		);
	};
	const getCode = (code: string) => {
		switch (code.toUpperCase()) {
			case 'COMPLETED':
				return 'positive';
			case 'FAILED':
				return 'negative';
			case 'CANCELLED':
				return 'neutral';
			default:
				return 'neutral';
		}
	};
	const sameColumn = [
		{ key: 'jobName', title: 'Job name	' },
		{
			key: 'description',
			title: 'Description',
			render: (arg: any) => arg.name ?? '-',
		},
		{
			key: 'start time',
			title: 'Start time',
			render: (arg: any) =>
				<>{dateFormat(arg['start time']?.split('.')[0] + '.000Z')}</> ?? '',
		},
	];
	const column = [
		...sameColumn,
		{
			key: 'status',
			title: 'Status',
			render: (row: any) => <>{getStatusProgress(row)}</>,
		},
		// {
		// 	key: 'jobSheet',
		// 	title: 'Job Sheet',
		// },
	];
	const column2 = [
		...sameColumn,
		{
			key: 'status',
			title: 'Status',
			render: (row: any) => (
				<Chip label={row.status} code={getCode(row.status) ?? ''} />
			),
		},
		// {
		// 	key: 'jobSheet',
		// 	title: 'Job Sheet',
		// },
	];

	return (
		<div className='sf-imports'>
			<div className='sf-imports-header'>
				<h4>Imports</h4>
			</div>
			<div id='sf-imports-data sf-list-of-imports'>
				{!Boolean(pastContactJobsheets.length) &&
					!Boolean(inProgressContactJobsheets.length) && (
						<p
							className='body2 imports-not-found'
							style={{ textAlign: 'center' }}>
							No Imports found
						</p>
					)}
			</div>
			{Boolean(inProgressContactJobsheets.length) && (
				<div className='sf-in-progress sf-list-of-imports'>
					<h6 className='heading '>
						<CachedOutlinedIcon />
						In progress
					</h6>
					<div id='sf-imports-data'>
						{!isLoading && !inProgressContactJobsheets.length ? (
							<p className='body2 imports-not-found'>No Imports found</p>
						) : (
							<>
								<Table
									height={400}
									column={column}
									limit={LIMIT}
									page={offset2 / LIMIT}
									totalCount={totalInProgressContactJobSheets}
									isLoading={isInProgressLoading}
									data={inProgressContactJobsheets}
									pagination={paginationForInProgress()}
								/>
							</>
						)}
					</div>
				</div>
			)}

			{Boolean(pastContactJobsheets.length) && (
				<div className='sf-list-of-imports'>
					<h6 className='heading'>
						<HistoryOutlinedIcon />
						Past Imports
					</h6>

					<div id='imports-table'>
						{!isLoading && !pastContactJobsheets.length ? (
							<p className='body2 imports-not-found'>No imports found</p>
						) : (
							<>
								<Table
									height={'calc(100vh - 205px)'}
									column={column2}
									limit={LIMIT}
									page={offset / LIMIT}
									pagination={pagination()}
									totalCount={totalPastContactJobSheets}
									isLoading={isLoading}
									data={pastContactJobsheets}
								/>
							</>
						)}
					</div>
				</div>
			)}
			<Outlet />
		</div>
	);
};

export default Imports;
