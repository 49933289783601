import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import { getSessionCount } from '../../../Features/teamsetting/teamsettingSlice';
import {
	DropdownArrow,
	TeamSettingEdit,
	TeamSettingInfo,
} from '../../../assets/Icons';
import './PlanDetails.scss';
import { useLocation } from 'react-router-dom';

const PlanDetails = () => {
	const [droptable, setDroptable] = useState(false);
	const { pricingData } = useSelector((state: RootState) => state.teamsetting);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const location = useLocation();
	const path = location.pathname;

	const dispatch = useAppDispatch();
	// let pricingData = {
	// 	botUsage: [
	// 		{
	// 			botIcon:
	// 				'https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?cs=srgb&dl=pexels-pixabay-60597.jpg&fm=jpg',
	// 			bot_name: '12',
	// 			count: 200,
	// 			isBotPublic: 'public',
	// 		},
	// 		{
	// 			botIcon:
	// 				'https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?cs=srgb&dl=pexels-pixabay-60597.jpg&fm=jpg',
	// 			bot_name: '12',
	// 			count: 200,
	// 			isBotPublic: 'public',
	// 		},
	// 		{
	// 			botIcon:
	// 				'https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?cs=srgb&dl=pexels-pixabay-60597.jpg&fm=jpg',
	// 			bot_name: '12',
	// 			count: 200,
	// 			isBotPublic: 'public',
	// 		},
	// 		{
	// 			botIcon:
	// 				'https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?cs=srgb&dl=pexels-pixabay-60597.jpg&fm=jpg',
	// 			bot_name: '12',
	// 			count: 200,
	// 			isBotPublic: 'public',
	// 		},
	// 		{
	// 			botIcon:
	// 				'https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?cs=srgb&dl=pexels-pixabay-60597.jpg&fm=jpg',
	// 			bot_name: '12',
	// 			count: 200,
	// 			isBotPublic: 'public',
	// 		},
	// 		{
	// 			botIcon:
	// 				'https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?cs=srgb&dl=pexels-pixabay-60597.jpg&fm=jpg',
	// 			bot_name: '12',
	// 			count: 200,
	// 			isBotPublic: 'public',
	// 		},
	// 		{
	// 			botIcon:
	// 				'https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?cs=srgb&dl=pexels-pixabay-60597.jpg&fm=jpg',
	// 			bot_name: '12',
	// 			count: 200,
	// 			isBotPublic: 'public',
	// 		},
	// 		{
	// 			botIcon:
	// 				'https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?cs=srgb&dl=pexels-pixabay-60597.jpg&fm=jpg',
	// 			bot_name: '12',
	// 			count: 200,
	// 			isBotPublic: 'public',
	// 		},
	// 		{
	// 			botIcon:
	// 				'https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?cs=srgb&dl=pexels-pixabay-60597.jpg&fm=jpg',
	// 			bot_name: '12',
	// 			count: 200,
	// 			isBotPublic: 'public',
	// 		},
	// 		{
	// 			botIcon:
	// 				'https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?cs=srgb&dl=pexels-pixabay-60597.jpg&fm=jpg',
	// 			bot_name: '12',
	// 			count: 200,
	// 			isBotPublic: 'public',
	// 		},
	// 		{
	// 			botIcon:
	// 				'https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?cs=srgb&dl=pexels-pixabay-60597.jpg&fm=jpg',
	// 			bot_name: '12',
	// 			count: 200,
	// 			isBotPublic: 'public',
	// 		},
	// 		{
	// 			botIcon:
	// 				'https://images.pexels.com/photos/60597/dahlia-red-blossom-bloom-60597.jpeg?cs=srgb&dl=pexels-pixabay-60597.jpg&fm=jpg',
	// 			bot_name: '12',
	// 			count: 200,
	// 			isBotPublic: 'public',
	// 		},
	// 	],
	// };

	useEffect(() => {
		dispatch(getSessionCount({ tenant_id: tenantId! }));
	}, []);

	return (
		<div className='plan-details-container'>
			<div>
				<span className='text plan-type'>Plan type</span>
				<span className='small-text'>Free Trial</span>
			</div>
			<hr className='separator' />
			<div className='text-info-icon-div'>
				<span className='text-info-icon'>
					<span className='text'>Plan limit</span>
					<span className='icon'>
						<TeamSettingInfo />
					</span>
				</span>
				<span className='small-text'>25,000 conversations for the team</span>
			</div>
			<hr className='separator' />
			<div>
				<span className='text'>Conversations</span>
				<span className='small-text'>12,000 (46.2% of the plan limit)</span>
			</div>
			<div className='conversation-table-div'>
				<div
					className='conversation-drop-icon-div'
					onClick={() => setDroptable(!droptable)}>
					<span className='small-text'>
						No. of conversations by Ad/Storefront
					</span>
					<span className='drop-icon'>
						<DropdownArrow
							width='15px'
							style={droptable ? { transform: 'rotate(180deg)' } : {}}
						/>
					</span>
				</div>
			</div>
			{droptable ? (
				<div className='tsusers-table-container'>
					<div className='theadUsers tableCell'>
						<div className='trUsers'>
							<div className='th-data'>Ad/Storefront Name</div>
							<div className='th-data'>Conversations</div>
							<div className='th-data'>Access Type</div>
						</div>
					</div>
					<div className='tbodyUsers'>
						{pricingData.botUsage !== null &&
							pricingData.botUsage.map((bot: any) => (
								<>
									<div className='trUsers tableCell'>
										<div className='td-data'>
											<div className='botIconAndName'>
												<span className='botIcon'>
													<img src={bot.botIcon} alt='botIcon' />
												</span>
												<span>{bot.bot_name}</span>
											</div>
										</div>
										<div className='td-data'>{bot.count}</div>
										<div className='td-data'>
											{bot.isBotPublic ? 'Public' : 'Private'}
										</div>
									</div>
								</>
							))}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default PlanDetails;
