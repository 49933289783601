import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './CampaignDetail.scss';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import ProgressBar from '../../../../Components/ProgressBar';
import Pagination from '../../../../Components/Pagination';
import {
	getCampainAnalytics,
	getJobSheet,
} from '../../../../Features/WhatsApp-BSP/campaign/campaignSlice';
import {
	DownloaddateFormat,
	dateFormat,
	debounce,
	kotakTenantCheckForWhatsapp,
	loadState,
	saveState,
} from '../../../../utils/utils';
import { createRef, useEffect, useRef, useState } from 'react';
import { RootState, useAppDispatch } from '../../../../app/store';
import { useSelector } from 'react-redux';
// import Table from '../../../../Components/Table';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import PieChart from '../../../../Components/PieChart';
import InteractiveButton from '../../../../Components/Button/InteractiveButton';
import { DownloadAnalyticDatas } from '../../../../assets/Icons';
import Menu from '../../../../Components/Menu';
import Input from '../../../../Components/Input';
import {
	getCampaignReport,
	setDetailsSearchedValue,
	reset,
} from '../../../../Features/WhatsApp-BSP/analytics/analyticsSlice';
import { setSnackModal } from '../../../../Features/message/messageSlice';
import Search from '../../../../Components/Search';

const CampaignDetail = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchLoader, setSearchLoader] = useState(false);

	const options = [
		{ value: 10, label: <p className='body1'>10</p> },
		{ value: 100, label: <p className='body1'>100</p> },
		{ value: 500, label: <p className='body1'>500</p> },
	];

	const [pageSize, setPageSize] = useState({
		value: 10,
		label: <p className='body1'>10</p>,
	});
	const [offset, setOffset] = useState(0);
	// const [offsetval, setOffsetval] = useState(0);
	// const [UpperL, setUpperL] = useState(0);
	// const [LowerL, setLowerL] = useState(0);
	const [campaignID, setCampaignID] = useState(
		location.pathname.split('/')[location.pathname.split('/').length - 1]
	);
	const anchorElRef = useRef<React.MutableRefObject<HTMLDivElement>[]>([]);
	const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
	const { wabaNo } = useSelector((state: RootState) => state.BSPauth);
	const { downloadMedia } = useSelector((state: RootState) => state.campaign);

	const [isOpen, setDownloadOpen] = useState(false);
	// const [newcount, setnewcount] = useState<any | undefined>(0);

	const dispatch = useAppDispatch();
	const { isAnalyticsLoading, campaign } = useSelector(
		(state: RootState) => state.campaign
	);
	const { detailssSearchedValue, isdownloadSuccess } = useSelector(
		(state: RootState) => state.analyticsBSP
	);
	const jobSheetSummaryKotak = [
		{
			name: 'Valid',
			value: (campaign.totalCount || 0) - (campaign.invalidRow || 0),
		},
		{ name: 'Invalid records', value: campaign.invalidRow || 0 },
	];
	const jobSheetSummary = [
		{
			name: 'Valid',
			value: (campaign.size || 0) - (campaign.invalidXLRow || 0),
		},
		{ name: 'Invalid records', value: campaign.invalidXLRow || 0 },
	];
	const deliverySummaryChatDataKotak = [
		{
			name: 'Successful',
			value:
				(campaign.sent || 0) +
				(campaign?.delivered || 0) +
				(campaign.read || 0),
		},
		{ name: 'Failed', value: campaign.failed || 0 },
	];

	const deliverySummaryChatData = [
		{
			name: 'Successful',
			value:
				(campaign.sent || 0) +
				(campaign?.delivered || 0) +
				(campaign.read || 0),
		},
		{ name: 'Failed', value: campaign.failed || 0 },
	];
	// const total =
	// 	(campaign?.sent || 0) +
	// 	(campaign?.read || 0) +
	// 	(campaign.delivered || 0) +
	// 	(campaign.failed || 0);
	const pagetotal = kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
		? (campaign?.totalCount || 0) - (campaign?.invalidRow || 0)
		: campaign?.campainedDataCount || 0;
	const colors = ['#3b44c4', '#f86060'];

	// useEffect(() => {
	// 	if (campaignID) {
	// 		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
	// 			? dispatch(
	// 					getCampainAnalytics({
	// 						campaing_id: campaignID,
	// 						offset: offset * 1,
	// 						limit: pageSize.value,
	// 						wabaNo,
	// 					})
	// 			  )
	// 			: dispatch(
	// 					getCampainAnalytics({
	// 						campaign_id: campaignID,
	// 						page: offset * 1,
	// 						size: pageSize.value,
	// 						wabaNo,
	// 					})
	// 			  );
	// 	}
	// }, [campaignID, offset, pageSize]);
	useEffect(() => {
		return () => {
			dispatch(setDetailsSearchedValue(''));
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (
			campaign &&
			(campaign.campaign_base_filename || campaign.campaignBaseFilename)
		) {
			campaign.campaign_base_filename &&
				getTemplateFormat(campaign.campaign_base_filename);
			campaign.campaignBaseFilename &&
				getTemplateFormat(campaign.campaignBaseFilename);
		}
	}, [campaign]);
	// useEffect(() => {
	// 	if (
	// 		campaign &&
	// 		campaign.campainedData &&
	// 		campaign.campainedData.length > 0
	// 	) {
	// 		// setnewcount(campaign && campaign.campainedData && campaign.campainedData.length)
	// 		newcount =
	// 			campaign && campaign.campainedData && campaign.campainedData.length;
	// 		setUpperL(newcount);
	// 		setLowerL(offsetval + 1);
	// 	} else {
	// 		setUpperL(0);
	// 		setLowerL(0);
	// 		// setnewcount(0);
	// 		newcount = 0;
	// 	}
	// }, [campaign && campaign.campainedData && campaign.campainedData.length]);
	const getTemplateFormat = (fileName: any) => {
		dispatch(
			getJobSheet({
				sheetName: fileName,
				wabaNo,
			})
		);
	};

	// const column3 = [
	// 	{ key: 'jobTitle', title: 'Job name	' },
	// 	{
	// 		key: 'jobTitle',
	// 		title: 'Description',
	// 	},
	// 	{
	// 		key: 'jobScheduledTime',
	// 		title: 'Start time',
	// 		render: (row: any) => (
	// 			<>{dateFormat(row.jobScheduledTime?.split('.')[0] + '.000Z')}</>
	// 		),
	// 	},
	// 	{
	// 		key: 'jobStatus',
	// 		title: 'Status',
	// 		render: (row: any) => (
	// 			<Chip label={row.jobStatus} code={getCode(row.jobStatus) ?? ''} />
	// 		),
	// 	},
	// ];
	const dowloadOptions = [
		{
			label: <p className='menu-label'>Generate data</p>,
			onClick: () => {
				setDownloadOpen(false);
				dispatch(getCampaignReport({ campaignId: campaignID, wabaNo: wabaNo }));
				navigate('download-success');
				// dispatch(setDownloadSucces(true));
			},
		},
		{
			label: (
				<p className='menu-label'>
					<a
						href={downloadMedia}
						download={
							campaign.jobReportName
								? `${campaign.jobReportName}`
								: 'template.xlsx'
						}
						target='_blank'
						rel='noreferrer'>
						Download data (
						{DownloaddateFormat(campaign.reportTime?.split('.')[0] + '.000Z')})
					</a>
				</p>
			),
			onClick: () => {
				setDownloadOpen(false);
				// navigate('download-previous-success')
				dispatch(
					setSnackModal({
						openModal: true,
						modalMesssage: 'Your file is being downloaded',
					})
				);
				getTemplateFormat(campaign.jobReportName);
			},
		},
	];
	const downloadCampaignsData = (event: any) => {
		if (campaign.jobReportName) {
			setDownloadOpen(true);
			event.stopPropagation();
			setAnchorE2(event.target);
			getTemplateFormat(campaign.jobReportName);

			// setTimeout(() => {
			// 	if (campaign.jobReportName !== '') {
			// 		if (campaignID === loadState('campID')) {
			// 			kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			// 				? dispatch(
			// 						getCampainAnalytics({
			// 							campaing_id: campaignID,
			// 							offset: offset * 1,
			// 							limit: pageSize.value,
			// 							wabaNo,
			// 						})
			// 				  )
			// 				: dispatch(
			// 						getCampainAnalytics({
			// 							campaign_id: campaignID,
			// 							page: offset * 1,
			// 							size: pageSize.value,
			// 							wabaNo,
			// 							filter: '',
			// 						})
			// 				  );
			// 		}
			// 	}
			// }, 40000);
		} else {
			// setDownloadOpen(false);
			navigate('download-success');
			// setDownloadSucces(true);
			dispatch(getCampaignReport({ campaignId: campaignID, wabaNo: wabaNo }));
			// setTimeout(() => {
			// 	if (campaign.jobReportName !== '') {
			// 		if (campaignID === loadState('campID')) {
			// 			kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			// 				? dispatch(
			// 						getCampainAnalytics({
			// 							campaing_id: campaignID,
			// 							offset: offset * 1,
			// 							limit: pageSize.value,
			// 							wabaNo,
			// 						})
			// 				  )
			// 				: dispatch(
			// 						getCampainAnalytics({
			// 							campaign_id: campaignID,
			// 							page: offset * 1,
			// 							size: pageSize.value,
			// 							wabaNo,
			// 							filter: '',
			// 						})
			// 				  );
			// 		}
			// 	}
			// }, 40000);
		}
	};

	useEffect(() => {
		if (!searchLoader && campaignID) {
			debounce(() => {
				kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
					? dispatch(
							getCampainAnalytics({
								campaing_id: campaignID,
								offset: detailssSearchedValue.length ? 0 : offset * 1,
								limit: detailssSearchedValue.length ? 1000 : pageSize.value,
								wabaNo,
								status: detailssSearchedValue,
							})
					  )
					: dispatch(
							getCampainAnalytics({
								campaign_id: campaignID,
								page: detailssSearchedValue.length ? 0 : offset * 1,
								size: detailssSearchedValue.length ? 1000 : pageSize.value,
								wabaNo,
								filter: detailssSearchedValue,
							})
					  );
			}, 600);
		}
	}, [searchLoader, detailssSearchedValue, offset, pageSize, campaignID]);
	return (
		<div className='campaign-details'>
			<div className='sf-campaign-header'>
				<Breadcrumbs
					aria-label='breadcrumb'
					separator={<NavigateNextIcon fontSize='small' />}>
					<Link to='/app/channels/whatsapp/campaigns' className='link'>
						<h4 style={{ fontWeight: '400' }}>Campaigns</h4>
					</Link>
					<h4>{campaign.jobTitle}</h4>
				</Breadcrumbs>
			</div>

			<div className='sf-campaigns-detail'>
				<div className='campaigns-detail-content'>
					<div className='details basic-details'>
						<header>
							<h6>Basic details</h6>
						</header>

						<div className='body'>
							<div className='container'>
								<div className='head overline'>Description</div>
								<p className='content body2'>{campaign.jobDesc}</p>
							</div>

							<div className='container'>
								<div className='head overline'>Created at</div>
								<p className='content body2 datetime'>
									{kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
										? campaign.job_creation_time &&
										  dateFormat(
												campaign.job_creation_time?.split('.')[0] + '.000Z'
										  )
										: campaign.jobCreationTime &&
										  dateFormat(campaign.jobCreationTime)}
								</p>
							</div>
							{/* ?.split('.')[0] + '.000Z' */}
							<div className='container'>
								<div className='head overline'>Started at</div>
								<p className='content body2 datetime'>
									{kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
										? campaign.job_scheduled_time &&
										  dateFormat(
												campaign.job_scheduled_time?.split('.')[0] + '.000Z'
										  )
										: campaign.jobScheduledTime &&
										  dateFormat(campaign.jobScheduledTime)}
								</p>
							</div>

							{campaign.jobStatus === 'completed' ? (
								<div className='container'>
									<div className='head overline'>Completed at</div>

									<p className='content body2 datetime'>
										{kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
											? campaign.job_completion_time &&
											  dateFormat(
													campaign.job_completion_time?.split('.')[0] + '.000Z'
											  )
											: campaign.jobCompletionTime &&
											  dateFormat(campaign.jobCompletionTime)}
									</p>
								</div>
							) : (
								// ?.split('.')[0] + '.000Z'
								<div className='container'>
									<div className='head overline'>Status</div>
									<p className='content body2'>{campaign?.jobStatus}</p>
								</div>
							)}

							<div className='container spacer'></div>

							<div className='container'>
								<div className='head overline'>Template</div>
								<p className='content body2'>
									{kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
										? campaign.template_name
										: campaign.templateName}
								</p>
							</div>

							<div className='container'>
								<div className='head overline'>Template language</div>
								<p className='content body2'>
									{kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
										? campaign.lang_code === 'en'
											? 'English'
											: campaign.lang_code
										: campaign.langCode === 'en'
										? 'English'
										: campaign.langCode}
								</p>
							</div>

							<div className='container'>
								{kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`) ? (
									<>
										<div className='head overline'>Job sheet</div>
										<p className='content body2'>
											<a
												href={downloadMedia}
												download={
													campaign.campaign_base_filename
														? `${campaign.campaign_base_filename}`
														: 'template.xlsx'
												}
												target='_blank'
												rel='noreferrer'>
												{campaign.campaign_base_filename}
											</a>
										</p>
									</>
								) : null}
							</div>
						</div>
					</div>
					{campaign.jobStatus?.toLocaleLowerCase() !== 'failed' &&
						campaign.jobStatus?.toLocaleLowerCase() !== 'created' &&
						campaign.jobStatus?.toLowerCase() !== 'cancelled' && (
							<div className='details delivery-summary'>
								<header>
									<h6>Job sheet summary</h6>
								</header>

								<div className='body'>
									<div className='container chart'>
										<PieChart
											data={
												kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
													? jobSheetSummaryKotak
													: jobSheetSummary
											}
											colors={colors}
											showLabel={true}
										/>
									</div>
								</div>
							</div>
						)}
					{campaign.jobStatus?.toLowerCase() !== 'failed' &&
						campaign.jobStatus?.toLowerCase() !== 'created' &&
						campaign.jobStatus?.toLowerCase() !== 'cancelled' && (
							<>
								<div className='details delivery-summary'>
									<header>
										<p className='body1'>Delivery summary</p>
									</header>

									<div className='body'>
										<div className='container chart'>
											<PieChart
												data={
													kotakTenantCheckForWhatsapp(
														`${loadState('tenantId')}`
													)
														? deliverySummaryChatDataKotak
														: deliverySummaryChatData
												}
												colors={colors}
												showLabel={true}
											/>
										</div>

										<div className='container detailed'>
											<div className='successful status'>
												<div className='overline content'>Successful</div>
												<div className='line-between'></div>
												<div className='sent content'>
													<p className='body2'>Sent</p>
													<ProgressBar
														value={campaign.sent ?? 0}
														total={
															kotakTenantCheckForWhatsapp(
																`${loadState('tenantId')}`
															)
																? campaign.totalCount
																: campaign.size
														}
														className='progress'
													/>
													{campaign && (
														<p className='body2'>
															{campaign.sent ?? 0}{' '}
															{kotakTenantCheckForWhatsapp(
																`${loadState('tenantId')}`
															)
																? campaign.totalCount && (
																		<span>
																			(
																			{(
																				((campaign?.sent ?? 0) /
																					campaign?.totalCount) *
																				100
																			).toFixed(1)}
																			%)
																		</span>
																  )
																: campaign.size && (
																		<span>
																			(
																			{(
																				((campaign?.sent ?? 0) /
																					campaign?.size) *
																				100
																			).toFixed(1)}
																			%)
																		</span>
																  )}
														</p>
													)}
												</div>

												<div className='delivered content'>
													<p className='body2'>Delivered</p>
													<ProgressBar
														value={campaign.delivered ?? 0}
														total={
															kotakTenantCheckForWhatsapp(
																`${loadState('tenantId')}`
															)
																? campaign.totalCount
																: campaign.size
														}
														className='progress'
													/>
													{campaign &&
														(campaign?.size || campaign?.totalCount) && (
															<p className='body2'>
																{campaign.delivered ?? 0}{' '}
																{kotakTenantCheckForWhatsapp(
																	`${loadState('tenantId')}`
																)
																	? campaign.totalCount && (
																			<span>
																				(
																				{(
																					((campaign?.delivered ?? 0) /
																						campaign?.totalCount) *
																					100
																				).toFixed(1)}
																				%)
																			</span>
																	  )
																	: campaign.size && (
																			<span>
																				(
																				{(
																					((campaign?.delivered ?? 0) /
																						campaign?.size) *
																					100
																				).toFixed(1)}
																				%)
																			</span>
																	  )}
															</p>
														)}
												</div>

												<div className='seen content'>
													<p className='body2'>Seen</p>
													<ProgressBar
														value={campaign.read ?? 0}
														total={
															kotakTenantCheckForWhatsapp(
																`${loadState('tenantId')}`
															)
																? campaign.totalCount
																: campaign.size
														}
														className='progress'
													/>
													{campaign &&
														(campaign?.size || campaign.totalCount) && (
															<p className='body2'>
																{campaign.read ?? 0}{' '}
																{kotakTenantCheckForWhatsapp(
																	`${loadState('tenantId')}`
																)
																	? campaign.totalCount && (
																			<span>
																				(
																				{(
																					((campaign?.read ?? 0) /
																						campaign?.totalCount) *
																					100
																				).toFixed(1)}
																				%)
																			</span>
																	  )
																	: campaign.size && (
																			<span>
																				(
																				{(
																					((campaign?.read ?? 0) /
																						campaign?.size) *
																					100
																				).toFixed(1)}
																				%)
																			</span>
																	  )}
															</p>
														)}
												</div>
											</div>

											<div className='failed status'>
												<div className='overline content'>Failed</div>

												<div className='user-not-found content'>
													<p className='body2'>Failed Count</p>
													<ProgressBar
														value={campaign.failed ?? 0}
														total={
															kotakTenantCheckForWhatsapp(
																`${loadState('tenantId')}`
															)
																? campaign.totalCount
																: campaign.size
														}
														className='progress'
													/>
													{campaign &&
														(campaign?.size || campaign?.totalCount) && (
															<p className='body2'>
																{campaign.failed ?? 0}{' '}
																{kotakTenantCheckForWhatsapp(
																	`${loadState('tenantId')}`
																)
																	? campaign.totalCount && (
																			<span>
																				(
																				{(
																					((campaign?.failed || 0) /
																						campaign?.totalCount) *
																					100
																				).toFixed(1)}
																				%)
																			</span>
																	  )
																	: campaign.size && (
																			<span>
																				(
																				{(
																					((campaign?.failed || 0) /
																						campaign?.size) *
																					100
																				).toFixed(1)}
																				%)
																			</span>
																	  )}
															</p>
														)}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='details delivery-details'>
									<header>
										<p className='body1'>Delivery details</p>
									</header>

									<div className='body'>
										<div className='top'>
											{detailssSearchedValue && detailssSearchedValue.length ? (
												<div></div>
											) : (
												<Pagination
													disableforward={
														kotakTenantCheckForWhatsapp(
															`${loadState('tenantId')}`
														)
															? offset + 10 > pagetotal
															: pagetotal <= (offset + 1) * pageSize.value
													}
													disableback={offset === 0}
													nextPage={() => {
														if (
															kotakTenantCheckForWhatsapp(
																`${loadState('tenantId')}`
															)
														) {
															setOffset(offset + 10);
														} else {
															setOffset(offset + 1);
														}
													}}
													previousPage={() => {
														if (
															kotakTenantCheckForWhatsapp(
																`${loadState('tenantId')}`
															)
														) {
															setOffset(offset - 10);
														} else {
															setOffset(offset - 1);
														}
													}}
													gotoBegining={() => setOffset(0)}
													gotoLast={() =>
														kotakTenantCheckForWhatsapp(
															`${loadState('tenantId')}`
														)
															? setOffset(
																	pagetotal - (pagetotal % pageSize.value)
															  )
															: setOffset(
																	Math.ceil(pagetotal / pageSize.value) - 1
															  )
													}
													total={pagetotal}
													upper={
														kotakTenantCheckForWhatsapp(
															`${loadState('tenantId')}`
														)
															? offset + 10 > pagetotal
																? pagetotal
																: offset + 10
															: pagetotal <=
															  offset * pageSize.value + pageSize.value
															? pagetotal
															: offset * pageSize.value + pageSize.value
													}
													lower={
														kotakTenantCheckForWhatsapp(
															`${loadState('tenantId')}`
														)
															? offset + 1
															: pagetotal === 0
															? 0
															: offset * pageSize.value + 1
													}
												/>
											)}

											<div className='delivery-content-shown body2'>
												<div>
													<Search
														isLoading={searchLoader}
														placeholder={'Search by number/status'}
														onChange={(value) => {
															// searchDeliveryData(value);
															dispatch(setDetailsSearchedValue(value));
														}}
													/>
												</div>

												<span className='download-delivery-datas'>
													{campaign.campainedData &&
													campaign.campainedData.length ? (
														<InteractiveButton
															title='Download'
															onClick={(event: any) => {
																downloadCampaignsData(event);
															}}
															icon={<DownloadAnalyticDatas />}
														/>
													) : null}
												</span>

												<Menu
													anchorEl={anchorE2}
													open={isOpen}
													setOpen={setDownloadOpen}
													options={dowloadOptions}
												/>
											</div>
										</div>

										<div className='campaigns-detail-table'>
											<TableContainer>
												<Table stickyHeader>
													<TableHead>
														<TableRow>
															<TableCell key='number'>Number</TableCell>
															<TableCell key='status'>Status</TableCell>
															<TableCell key='error_details'>
																Error details
															</TableCell>
															<TableCell key='time_of_sending'>
																Time of sending
															</TableCell>
															<TableCell key='time_of_delivery'>
																Time of delivery
															</TableCell>
															<TableCell key='seen_at' className='options'>
																Seen at
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{isAnalyticsLoading ? (
															<TableRow>
																<TableCell colSpan={6}>Loading...</TableCell>
															</TableRow>
														) : (
															<>
																{campaign.campainedData &&
																campaign.campainedData.length ? (
																	campaign.campainedData.map((item, i) => {
																		anchorElRef.current[i] =
																			anchorElRef.current[i] ?? createRef();
																		return (
																			<TableRow>
																				<TableCell>
																					{kotakTenantCheckForWhatsapp(
																						`${loadState('tenantId')}`
																					)
																						? item.cust_mob_no
																						: item.custMobNo}
																				</TableCell>
																				<TableCell>
																					<span
																						className={
																							kotakTenantCheckForWhatsapp(
																								`${loadState('tenantId')}`
																							)
																								? item.message_status &&
																								  item.message_status.toLowerCase() ===
																										'failed'
																									? 'chip failed'
																									: 'chip success'
																								: item.messageStatus &&
																								  item.messageStatus.toLowerCase() ===
																										'failed'
																								? 'chip failed'
																								: 'chip success'
																						}>
																						{kotakTenantCheckForWhatsapp(
																							`${loadState('tenantId')}`
																						)
																							? item.message_status
																							: item.messageStatus}
																					</span>
																				</TableCell>
																				<TableCell>
																					{(kotakTenantCheckForWhatsapp(
																						`${loadState('tenantId')}`
																					)
																						? item.wt_delivery_error_msg
																						: item.wtDeliveryErrorMsg) || '-'}
																				</TableCell>
																				<TableCell>
																					{(kotakTenantCheckForWhatsapp(
																						`${loadState('tenantId')}`
																					)
																						? item.wt_message_sent_time
																						: item.wtMsgApiReqTime &&
																						  dateFormat(
																								item?.wtMsgApiReqTime?.split(
																									'.'
																								)[0] + '.000Z'
																						  )) || '-'}
																				</TableCell>
																				<TableCell>
																					{(kotakTenantCheckForWhatsapp(
																						`${loadState('tenantId')}`
																					)
																						? item.wt_message_delivered_time
																						: item.wtMsgApiResTime &&
																						  dateFormat(
																								item?.wtMsgApiResTime?.split(
																									'.'
																								)[0] + '.000Z'
																						  )) || '-'}
																				</TableCell>
																				<TableCell>
																					{(kotakTenantCheckForWhatsapp(
																						`${loadState('tenantId')}`
																					)
																						? item.wt_message_read_time
																						: item.wtMessageReadTime &&
																						  dateFormat(
																								item?.wtMessageReadTime?.split(
																									'.'
																								)[0] + '.000Z'
																						  )) || '-'}
																				</TableCell>
																			</TableRow>
																		);
																	})
																) : (
																	<TableRow key='loading'>
																		<TableCell
																			style={{
																				borderBottom: 'unset !important',
																			}}>
																			<div className='details-table-no-data-found'>
																				<p className='body1'>No data found</p>
																			</div>
																		</TableCell>
																	</TableRow>
																)}
															</>
														)}
													</TableBody>
												</Table>
											</TableContainer>
										</div>
									</div>
								</div>
							</>
						)}
				</div>
			</div>
			<Outlet />
		</div>
	);
};

export default CampaignDetail;
