import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { height } from '@mui/system';

type Data = { value: number; name: string }[];
interface Props {
    data: Data;
    colors?: string[];
    showLabel?: boolean
}

function DoughnutChart({ data, colors = [], showLabel }: Props): JSX.Element {

    const defaultOptions = {
        tooltip: {
            show: true,
            trigger: 'item',
            backgroundColor: '#ccc',
            color: "#000",
            padding: [2, 6],
            textStyle: {
                color: 'black',
                fontFamily: 'OpenSans',
            },
            borderWidth: 0,
            borderRadius: 5,
            shadowBlur: 0,
            shodowColor: 'transparent',
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            formatter: "{b} : {c}<br/> ({d}%)",
            top: "-10",
            position: "10"
            // formatter: `<p class='caption'>{b}</p><p class='caption'>{c} ({d}%)</p>`,
        },
        legend: {
            show: false,
        },
        animation: true,
    };

    const chatData = [
        {
            name: 'Analytics',
            type: 'pie',

            radius: ['0%', '100%'],
            minAngle: '10',
            color: [
                ...colors,
                '#9880ff',
                '#77e2a4',
                '#3b4fd0',
                '#5470c6',
                '#91cc75',
                '#fac858',
                '#ee6666',
                '#73c0de',
                '#3ba272',
                '#fc8452',
                '#9a60b4',
                '#ea7ccc',
            ],
            animation: false,
            legendHoverLink: true,
            hoverAnimation: false,
            data: data,
            label: {
                show: showLabel || true,
                lineHeight: 56,
            },
            labelLine: {
                show: true
            },

        },
    ];

    const options = {
        ...defaultOptions,
        series: chatData,
    };


    const myChart: any = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function resizeChart() {
            chart?.resize();
        }
        window.addEventListener('resize', resizeChart);

        const chart = echarts.init(myChart.current);
        chart.setOption(options);

        return () => {
            chart?.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, [data, window]);

    if (data) {

        return (
            <div
                ref={myChart}
                style={{
                    width: '100%',
                    minHeight: '100px',
                    height: '100%',
                }}
            ></div>
        );
    }
    return <></>;
}
export default DoughnutChart;
