import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { saveState } from '../../utils/utils';
import leadSquareService from './leadsquaredService';

type State = {
	linkedCrmStatus: string;
	isLoading: boolean;
	error: string;
	isCredentialsValid: boolean;
	unlinkleadSquaredLoader: string;
};

export type GetData = {
	crmId: number | string;
	userId: number | string;
	tenantId: number | string;
	config: {
		ACCESS_KEY: string;
		SECRET_KEY: string;
		URL: string;
	};
};
export type Unlinkdata = {
	tenantId: number | string;
	linkedCrmId: number | string;
};

const initialState: State = {
	linkedCrmStatus: 'failure',
	error: '',
	isLoading: false,
	isCredentialsValid: false,
	unlinkleadSquaredLoader: '',
};

export const linkleadSquared = createAsyncThunk<
	any,
	GetData,
	{ rejectValue: string }
>('/crm/v1/setup', async (data, { rejectWithValue }) => {
	try {
		const response: any = await leadSquareService.getleadSquaredData(data);

		if (response.data) {
			// console.log(response.data);
			return response.data.status;
		}

	} catch (error) {
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	}
});

export const unlinkleadSquared = createAsyncThunk<
	any,
	Unlinkdata,
	{ rejectValue: string }
>('/tenant', async (data, { rejectWithValue }) => {
	try {
		const response: any = await leadSquareService.unlinkleadSquaredData(data);
		if (response.data) {
			// console.log('get inside unlink try');
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

const leadSquareSlice = createSlice({
	name: 'leadSquare',
	initialState,
	reducers: {
		resetlink: (state) => {
			state.error = '';
		},
		resetunlink: (state) => {
			state.error = '';
		},
		resetUnlinkleadSquaredLoader: (state) => {
			state.unlinkleadSquaredLoader = '';
		},
		isLeadCRMValid:(state,action)=>{
			state.isCredentialsValid=action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(linkleadSquared.pending, (state) => {
				state.linkedCrmStatus = '';
				state.isLoading = true;
				state.error = '';
			})
			.addCase(linkleadSquared.fulfilled, (state: State, action) => {
				state.linkedCrmStatus = 'success';
				state.isCredentialsValid = true;
				state.isLoading = false;
			})
			.addCase(linkleadSquared.rejected, (state, action) => {
				state.linkedCrmStatus = '';
				state.isCredentialsValid = false;
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
					if (error.error === 'Network error') {
						state.isLoading = true;
					}
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(unlinkleadSquared.pending, (state) => {
				state.linkedCrmStatus = '';
				state.isLoading = true;
				state.error = '';
				state.unlinkleadSquaredLoader = 'loading';
			})
			.addCase(unlinkleadSquared.fulfilled, (state: State, action) => {
				state.linkedCrmStatus = 'success';
				state.isLoading = false;
				state.unlinkleadSquaredLoader = 'success';
			})

			.addCase(unlinkleadSquared.rejected, (state, action) => {
				state.linkedCrmStatus = '';
				state.isLoading = false;
				state.unlinkleadSquaredLoader = 'failed';

				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
					if (error.error === 'Network error') {
						state.isLoading = true;
					}
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			});
	},
});

export const { resetlink, resetunlink, resetUnlinkleadSquaredLoader,isLeadCRMValid } =
	leadSquareSlice.actions;

export default leadSquareSlice.reducer;
