import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import { setActiveBot } from '../../../Features/Bots/BotSlice';
import { listOfBots } from '../../../Features/Bots/model';
import { init } from '../../../Features/builder/builderSlice';
import { loadState, saveState } from '../../../utils/utils';
import MyConversationalVirtualSalesReps from '../../MyStudio/MyConversationalVirtualSalesReps';
import AnalyticsViewAll from '../AnalyticsViewAll';
import CaVsrAnalytics from '../CaVsrAnalytics';
import ShowCaVsrAnalyticsBots from '../ShowCaVsrAnalyticsBots';

function ConversationalVirtualSales() {
	const location = useLocation();
	const path = location.pathname;
	const { activeAnalyticsBotstatus } = useSelector(
		(state: RootState) => state.analytics
	);

	return (
		<>
			{(path === '/app/analytics/intelligent-ads' ||
				path === '/app/analytics/multichannel-storefronts') && (
				<ShowCaVsrAnalyticsBots />
			)}
			<Outlet />{' '}
		</>
	);
}

export default ConversationalVirtualSales;
