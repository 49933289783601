import DataFetchAPI from '../../../utils/Api';
import { kotakTenantCheckForWhatsapp, loadState } from '../../../utils/utils';
const getList = async (data: object) => {
	// as the response structure is same hence making changes here itself for getList only one attribute is missing which is lastUpdatetime which i think UI is not using
	return await DataFetchAPI().get(
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? `/${loadState('WAEndpoint')}/getTemplates`
			: `/${loadState('WAEndpoint')}/${loadState('tenantId')}/template`,
		{
			params: { templateName: '', status: '', offset: 0, ...data },
		}
	);
};
// in new UI it is not available as of now
const updateList = async (data: { wabaNo: string }) => {
	return await DataFetchAPI().post(
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? `/${loadState('WAEndpoint')}/refreshTemplates`
			: `/${loadState('WAEndpoint')}/${loadState('tenantId')}/template`,
		data
	);
};

// for deleting template
const deleteTemplate = async (data: { wabaNo: string; templateId: number }) => {
	return await DataFetchAPI().delete(
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? `/${loadState('WAEndpoint')}/template`
			: `${loadState('WAEndpoint')}/${loadState('tenantId')}/template`,
		{
			params: data,
		}
	);
};
const templateService = {
	getList,
	updateList,
	deleteTemplate,
};

export default templateService;
