import React, { useEffect } from 'react';
import ModalPane from '../../../../../Components/Modal'
import { ThumbRating } from '../Ratings/Ratings';
// import {
// 	T,
// 	TString,
// } from '../../../../src/Components/FeatureComponents/Response/Utils';
import styled from 'styled-components';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { useSelector } from 'react-redux';
import Input from '../../../../../Components/Input';
import {
	setmessageFeedbackComment,
	setthumbFilledUp,
	setmessageId,
	setisModalVisible,
} from '../../../../../Features/HelpBot/helpbotSlice';
import Button from '../../../../../Components/Button';

interface Props {
	submitFeedback: Function;
	colorMode: string;
}

const FEEDBACKCOMMENT = styled.div`
	&.ff-message-feedback-wrapper {
		& .custom-button {
			& > button {
				background-color:'var(--colorScale-2)';
	}} !important;
				& > .custom-button-body {
					color:'var(--colorScale-9)';
	}} !important;
				}
			}
		}
		& .MuiInputBase-root {
			&:not(.Mui-disabled, .Mui-error).Mui-focused {
				.MuiOutlinedInput-notchedOutline {
					border-color:'var(--colorScale-2)';
	}} !important;
				}
			}
		}
	}
`;
export default function MessageFeedbackComment(props: Props) {
	const [comment, setComment] = React.useState('');
	const [newThumb, setNewThumb] = React.useState('');
	const {
		msgsArray,
		HBscreen,
		expandWidth,
		messageFeedbackComment,
		thumbFilledUp,
		messageId,
		isModalVisible,
		thumbFilledDown
	} = useSelector((state: RootState) => state.helpBot);
	const dispatch = useAppDispatch();
	useEffect(() => {
		setTimeout(() => {
			var el = document.querySelector('.textfield-text');
			el && (el as HTMLElement).focus();
		}, 100);
	});


	return (
		<FEEDBACKCOMMENT
			className='ff-message-feedback-wrapper'
		>
			<ModalPane
				onClose={() => {
					{

						// props.openThumbsPopup(null, null, 0)

						dispatch(setmessageFeedbackComment(null))
						dispatch(setthumbFilledUp(null))
						dispatch(setmessageId(0))
						dispatch(setisModalVisible(null))

					}
				}}
				// buttons={[
				// 	{
				// 		label: "Submit",
				// 		onClick: () => {
				// 			if (newThumb) {
				// 				// props.messageFeedbackCallback(
				// 				// 	newThumb === 'Up' ? true : false,
				// 				// 	true
				// 				// ); //have to enable
				// 			}

				// 			props.submitFeedback(
				// 				0,
				// 				comment.replace(/ {2,}/g, ' '),
				// 				thumbFilledUp === true ? 4 : 2,
				// 				messageId
				// 			);

				// 		},
				// 		variant: 'filled',
				// 		fullWidth: false,
				// 	},
				// ]}
				actionButtons={
					<Button
						fullWidth={false}
						variant='contained'
						onClick={() => {

						}}>
						Submit
					</Button>
				}
			>
				<>
					<ThumbRating
						// messageKey={props.msgKey}
						// data={props.data}
						size={'large'}
						// popUpSnackBar={props.popUpSnackBar}
						noOutlineHover
						centered
						selected={thumbFilledUp === true ? 'Up' : 'Down'}
						onClick={setNewThumb}
						isResponselevelFeedback={false}
						messageId={messageId}
						// openThumbsPopup={props.openThumbsPopup}
						thumbFilledUp={thumbFilledUp}
					/>

					<Input
						fullWidth
						placeholder={'Please enter your comment'}
						className='feedback-input'
						autoComplete='off'
						value={comment}
						onChange={(ev) => {
							setComment(ev.target.value);
						}}
					/>
				</>
			</ModalPane>
		</FEEDBACKCOMMENT>
	);
}
