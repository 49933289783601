import React, { useEffect, useState } from 'react';
import './WooCommerce.scss';
import WooComm from '../../../../assets/images/wooCommLink.png';

import Input from '../../../../Components/Input';
import Button from '../../../../Components/Button';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	resetWooCommRedirectLink,
	resetWooCommstoreLinkStatus,
	wooCommerceStoreLink,
} from '../../../../Features/Ingest/IngestSlice';
import { useSelector } from 'react-redux';
import { setSnackModal } from '../../../../Features/message/messageSlice';

const WooCommerce = () => {
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { wooCommRedirectURL, wooCommstoreLinkStatus, wooCommStoreMessage } =
		useSelector((state: RootState) => state.ingest);

	const [wooCom, setWooCom] = useState('');
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (wooCommstoreLinkStatus === 'failed') {
			dispatch(
				setSnackModal({
					modalMesssage: 'Unable to link account. Please try after sometime.',
					openModal: true,
					modalMode: 'negative',
				})
			);
		} else if (wooCommstoreLinkStatus === 'success') {
			dispatch(
				setSnackModal({
					modalMesssage:
						'You have been redirected to Woo Commerce. Please link your account',
					openModal: true,
				})
			);
			window.open(wooCommRedirectURL);
			dispatch(resetWooCommRedirectLink());
		}
		dispatch(resetWooCommstoreLinkStatus());
	}, [wooCommstoreLinkStatus]);

	return (
		<div className='ff-woo-commerce'>
			<div className='woocomm-logo'>
				<img src={WooComm} alt='' />
			</div>
			<div className='woocomm-form'>
				<Input
					fullWidth
					label={'WooCommerce URL'}
					value={wooCom}
					onChange={(event) => setWooCom(event.target.value)}
				/>
			</div>
			<div className='woo-comm-link-button'>
				<Button
					disabled={wooCom ? false : true}
					onClick={() => {
						dispatch(
							wooCommerceStoreLink({
								tenantId: tenantId!,
								url: wooCom,
							})
						);
					}}>
					Link
				</Button>
			</div>
		</div>
	);
};

export default WooCommerce;
