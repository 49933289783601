import { DropdownArrow } from "../../../../../assets/Icons";
import APIErrorMessages from "../APIErrorMessages";
import "./MultiSelect.scss";

type MultiSelectProps = {
  className?: string;
  label?: string;
  placeholder?: string;
  onClick: () => void;
  value: Array<any>;
};
const MultiSelect = ({
  label,
  placeholder,
  value,
  className,
  onClick,
}: MultiSelectProps) => {

  let errorArr:Array<number>=[];
  const errorcodes=(value:any)=>{
    value.map((val:any)=>{
      errorArr.push(val.key)
      })
      return errorArr;

  }
  return (
    <div
      className={`ff-multi-select ${className ? className : ""}`}
      onClick={onClick}
    >
      <div className="ff-multi-select-main">
        {label && <label>{label}</label>}
        <div className="selected">
          {value && value.length > 0
            ? label === "API Response Mapping"
              ? value.length == 1
                ? `${value.length} variable mapped`
                : `${value.length} variables mapped`
              : label === "API error messages"
              ? 
              `${value.length} error ${value.length>1?'messages':'message'} added. Error codes ${
                
                errorcodes(value).map((val:number)=>{
                  return ' '+val;
                })
              } `
              : value.length == 1
              ? `${value.length} parameter added`
              : `${value.length} parameters added`
            : label === "API Response Mapping"
            ? `No variables mapped`
            : label === "API error messages"
            ? `No messages added`
            : `No parameters added`}
        </div>
      </div>
      <div className="ff-multi-select-icons">
        <div className="icon">
          <DropdownArrow width="15px" height="15px" />
        </div>
      </div>
    </div>
  );
};
export default MultiSelect;
