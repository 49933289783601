import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	dialogQueriesView,
	SelecteddialogQuery,
	setActiveAnalyticsBot,
} from '../../../Features/Analytics/analyticsSlice';
import { listOfBots } from '../../../Features/Bots/model';
import { init } from '../../../Features/builder/builderSlice';
import { loadState, saveState } from '../../../utils/utils';
import MyConversationalVirtualSalesReps from '../../MyStudio/MyConversationalVirtualSalesReps';

function ShowCaVsrAnalyticsBots() {
	const location = useLocation();
	const path = location.pathname;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const selectedCaVsrBot = loadState('selectedCaVsrBot');
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const pathArr = path.split('/');
	const type = pathArr[3];
	const [typeToSend, setTypeToSend] = useState('');

	useEffect(() => {
		if (type) {
			if (type === 'intelligent-ads') {
				setTypeToSend('CA');
			} else if (type === 'multichannel-storefronts') {
				setTypeToSend('VSR');
			} else {
				navigate('/app/analytics/overview');
			}
		} else {
			navigate('/app/analytics/overview');
		}
	}, [type]);

	return (
		<div className='ff-conversational-virtualSales'>
			{' '}
			<h4 className='ff-conversational-virtualSales-heading'>
				{typeToSend === 'CA'
					? 'Choose an Intelligent Ad to analyze'
					: 'Choose a Multichannel Storefronts to analyze'}
			</h4>
			<MyConversationalVirtualSalesReps
				type={typeToSend}
				icon={false}
				onClick={(props: listOfBots) => {
					saveState('selectedCaVsrBot', props);
					dispatch(
						init({
							botId: props.botId,
							tenantId: tenantId,
							name: props.botName,
						})
					);
					dispatch(setActiveAnalyticsBot({ data: props, status: 'active' }));
					dispatch(dialogQueriesView(false));
					navigate('bot');
				}}
			/>
		</div>
	);
}

export default ShowCaVsrAnalyticsBots;
