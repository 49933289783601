import React, { useEffect, useState } from 'react';
import './Actions.scss';
import { RootState, useAppDispatch } from '../../../app/store';
import { useSelector } from 'react-redux';
import {
	getAIDetails,
	setAILaunchPromptCheckbox,
	setEnableBookAPT,
	setEnableGenerateLeads,
	setEnableRAT,
	setEnableCHE,
	setEnableCCC,
	setShowBotPreviewCollapsed,
} from '../../../Features/ExperienceOnWeb/experienceonwebSlice';
import { Tick } from '../../ComponentsLibrary/Icons';
const Actions = () => {
	// const workflowDetails = botDetails.workFlow.filter((item: any) => item.name === "Generate leads")[0].workflowAction
	const {
		enableBookAPT,
		enableRAT,
		enableGenerateLeads,
		enableCHE,
		enableCCC,
	} = useSelector((state: RootState) => state.experienceonweb);
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(setShowBotPreviewCollapsed(true));
	}, []);
	return (
		<div className='actions-container'>
			<h4>Actions</h4>
			<div className='actions-child-wrapper'>
				<span className='body1 heading'>
					Actions are additional capabilities that come prebuilt in your AI and
					get suggested to your users automatically at relevant times during
					chat.
				</span>
				<br />
				<div className='body1 heading-title' style={{ paddingTop: '16px' }}>
					Please select the actions that you want in your AI.
				</div>

				<div className='action-chips body1'>
					<span
						className={`${enableGenerateLeads ? 'config-selected ' : ''}`}
						onClick={() => {
							let value = true;
							if (enableGenerateLeads) {
								value = false;
							}

							dispatch(setEnableGenerateLeads(value));
						}}>
						{enableGenerateLeads && (
							<div className='tick-enable'>
								<Tick />
							</div>
						)}
						Generate Leads
					</span>
					<span
						className={`${enableBookAPT ? 'config-selected ' : ''}`}
						onClick={() => {
							let value = true;
							if (enableBookAPT) {
								value = false;
							}

							dispatch(setEnableBookAPT(value));
						}}>
						{enableBookAPT && (
							<div className='tick-enable'>
								<Tick />
							</div>
						)}
						Book Appointment
					</span>
					<span
						className={`${enableCCC ? 'config-selected ' : ''}`}
						onClick={() => {
							let value = true;
							if (enableCCC) {
								value = false;
							}

							dispatch(setEnableCCC(value));
						}}>
						{enableCCC && (
							<div className='tick-enable'>
								<Tick />
							</div>
						)}
						Call Customer Care
					</span>

					<span
						className={`${enableRAT ? 'config-selected ' : ''}`}
						onClick={() => {
							let value = true;
							if (enableRAT) {
								value = false;
							}
							dispatch(setEnableRAT(value));
						}}>
						{enableRAT && (
							<div className='tick-enable'>
								<Tick />
							</div>
						)}
						Raise a Ticket
					</span>

					<span
						className={`${enableCHE ? 'config-selected ' : ''}`}
						onClick={() => {
							let value = true;
							if (enableCHE) {
								value = false;
							}
							dispatch(setEnableCHE(value));
						}}>
						{enableCHE && (
							<div className='tick-enable'>
								<Tick />
							</div>
						)}
						Chat with human expert
					</span>
				</div>
			</div>
		</div>
	);
};
export default Actions;
