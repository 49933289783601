
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../app/store';
import Button from '../../Components/Button';
import SnackBar from '../../Components/SnackBar';
import {
	getVersionDetails,
	reset,
	setIsDeployedSuccess,
	setIsReDeployedSuccess,
	setIsVersionDeleted,
} from '../../Features/deploy/deploySlice';
import { DeployInfo, DeployUploadIcon } from '../../assets/Icons';
import { FFLoader } from '../../assets/Icons/Loader';
import './Deploy.scss';
import PageLoader from '../../Components/PageLoader';
import { set } from '../../Features/message/messageSlice';

type Data = {
	versionName: string;
	versionId: number;
	isDeployed: boolean;
	isDeleted: boolean;
	createOn: string;
	deleteOn: null;
	description: string;
	updateOn: string;
};
function Deploy() {
	const navigate = useNavigate();
	const [deployed, setDeployed] = useState(false);
	const [deployCount, setDeployCount] = useState(0);
	const [currentlyDeployed, setCurrentlyDeployed] = useState<Data>();
	const [otherVersions, setOtherVersions] = useState<Data>();
	const { activeBot } = useSelector((state: RootState) => state.bots);
	const {
		deployedVersionsList,
		getVersionLoader,
		deployedLoading,
		isDeployedSuccess,
		isReDeployedSuccess,
		isVersionDeleted,
	} = useSelector((state: RootState) => state.deploy);
	const dispatch = useAppDispatch();

	const handleVersions = () => {
		const versions = deployedVersionsList.filter(
			(each) => each.isDeleted === false
		);
		if (versions.length === 1) {
			setCurrentlyDeployed(versions[0]);
			setDeployCount(1);
			setDeployed(true);
		}
		if (versions.length >= 2) {
			if (versions[0].isDeployed === true) {
				setCurrentlyDeployed(versions[0]);
				setOtherVersions(versions[1]);
				setDeployCount(2);
				setDeployed(true);
			} else {
				setCurrentlyDeployed(versions[1]);
				setOtherVersions(versions[0]);
				setDeployCount(2);
				setDeployed(true);
			}
		}
	};

	useEffect(() => {
		dispatch(setIsDeployedSuccess(false));
		dispatch(setIsVersionDeleted(false));
		dispatch(setIsReDeployedSuccess(false));
		dispatch(
			getVersionDetails({
				botId: activeBot.encBotId,
			})
		);

		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		handleVersions();
	}, [deployedVersionsList]);

	useEffect(() => {
		if (isDeployedSuccess === 'Success') {
			dispatch(set('Version Deployed'));
			// toast(<SnackBar type='success'>Version Deployed</SnackBar>, {
			// 	autoClose: 3000,
			// });
		} else if (isDeployedSuccess === 'Failed') {
			dispatch(set("Couldn't Deploy!!! Something Went Wrong"));
			// toast(
			// 	<SnackBar type='danger'>
			// 		Couldn't Deploy!!! Something Went Wrong
			// 	</SnackBar>,
			// 	{
			// 		autoClose: 3000,
			// 	}
			// );
		}
		dispatch(setIsDeployedSuccess(''));
	}, [isDeployedSuccess]);

	useEffect(() => {
		if (isReDeployedSuccess === 'Success') {
			dispatch(set('Version Re-Deployed'));
			// toast(<SnackBar type='success'>Version Re-Deployed</SnackBar>, {
			// 	autoClose: 3000,
			// });
		} else if (isReDeployedSuccess === 'Failed') {
			dispatch(set("Couldn't Re-Deploy!!! Something Went Wrong"));
			// toast(
			// 	<SnackBar type='success'>
			// 		Couldn't Re-Deploy!!! Something Went Wrong
			// 	</SnackBar>,
			// 	{
			// 		autoClose: 3000,
			// 	}
			// );
		}
		dispatch(setIsReDeployedSuccess(''));
	}, [isReDeployedSuccess]);

	useEffect(() => {
		if (isVersionDeleted === 'Success') {
			dispatch(set('Version Deleted'));
			// toast(<SnackBar type='danger'>Version Deleted</SnackBar>, {
			// 	autoClose: 3000,
			// });
		} else if (isVersionDeleted === 'Failed') {
			dispatch(set("Couldn't Deleted!!! Something Went Wrong"));
			// toast(
			// 	<SnackBar type='danger'>
			// 		Couldn't Deleted!!! Something Went Wrong
			// 	</SnackBar>,
			// 	{
			// 		autoClose: 3000,
			// 	}
			// );
		}
		dispatch(setIsVersionDeleted(''));
	}, [isVersionDeleted]);

	return (
		<>
			<div className='ff-deploy'>
				<div className='header'>
					<div className='deploy-info'>
						<h4 className='deploy-header'>Deploy</h4>
					</div>
					{deployCount ? (
						<div>
							<Button
								onClick={() => navigate('deploy-new-version')}
								startIcon={<DeployUploadIcon />}
								disabled={deployCount === 2 ? true : false}>
								Deploy New Version
							</Button>
						</div>
					) : null}
				</div>
				<div className='versions-body'>
					{getVersionLoader ? (
						<div className='load-version-list'>
							<PageLoader message='Loading versions' />
						</div>
					) : !deployed ? (
						<div className='title-button-holder'>
							<span className='body-title'>No version deployed currently</span>
							<span>
								<Button
									onClick={() => navigate('deploy-new-version')}
									startIcon={<DeployUploadIcon />}>
									Deploy New Version
								</Button>
							</span>
						</div>
					) : (
						<div className='table-container'>
							{deployCount >= 1 ? (
								<div className='currently-deployed'>
									<div className='currently-deployed-header'>
										Currently Deployed
									</div>
									<table className='deploy-table'>
										<tr>
											<th>
												<div>Version Name</div>
											</th>
											<th>
												<div>Created date</div>
											</th>
											<th>
												<div>Description</div>
											</th>
										</tr>
										<tr>
											<td>
												<div>{currentlyDeployed?.versionName}</div>
											</td>
											<td>
												<div>
													<p>{currentlyDeployed?.createOn.slice(0, 10)}</p>
													<p className='timestamp'>
														{currentlyDeployed?.createOn.slice(10)}
													</p>
												</div>
											</td>
											<td>
												<div>{currentlyDeployed?.description}</div>
											</td>
										</tr>
									</table>
									{deployCount === 2 ? (
										deployedLoading ? (
											<div className='load-version-list'>
												<FFLoader />
											</div>
										) : (
											<>
												<div className='other-versions-deployed-header'>
													Other Versions
												</div>
												<table className='deploy-table'>
													<tr>
														<th>
															<div>Version Name</div>
														</th>
														<th>
															<div>Created date</div>
														</th>
														<th>
															<div>Description</div>
														</th>
														<th>
															<div></div>
														</th>
													</tr>
													<tr>
														<td>
															<div>{otherVersions?.versionName}</div>
														</td>
														<td>
															<div>
																<p>{otherVersions?.createOn.slice(0, 10)}</p>
																<p className='timestamp'>
																	{otherVersions?.createOn.slice(10)}
																</p>
															</div>
														</td>
														<td>
															<div>{otherVersions?.description}</div>
														</td>
														<td className='deploy-delete-button'>
															<Button
																onClick={() =>
																	navigate('re-deploy-version', {
																		state: { value: otherVersions },
																	})
																}
																startIcon={
																	//#4B50F6
																	<DeployUploadIcon color='#ffffff' />
																}>
																Redeploy
															</Button>
															<Button
																onClick={() =>
																	navigate('delete-version', {
																		state: { value: otherVersions },
																	})
																}>
																Delete
															</Button>
														</td>
													</tr>
												</table>
											</>
										)
									) : null}
								</div>
							) : null}
						</div>
					)}
				</div>
				<footer></footer>
				<Outlet />
			</div>
		</>
	);
}

export default Deploy;
