import React from 'react';
import Modal from '../../../Components/Modal';
import Button from '../../../Components/Button';
import { useAppDispatch } from '../../../app/store';
import { setShowFreeTrial } from '../../../Features/Subscription/subscriptionSlice';

export function SuccessCheck() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='check_circle_black_24dp (3)'
			width='44'
			height='44'
			viewBox='0 0 44 44'>
			<path data-name='Path 15054' d='M0 0h44v44H0z' fill='none' />
			<path
				data-name='Path 15055'
				d='M20.333 2a18.333 18.333 0 1 0 18.334 18.333A18.34 18.34 0 0 0 20.333 2zm-3.666 27.5L7.5 20.333l2.585-2.585 6.582 6.563L30.582 10.4l2.585 2.6z'
				transform='translate(1.667 1.667)'
				fill='#47a26e'
			/>
		</svg>
	);
}
const EnterpriseContact = () => {
	const dispatch = useAppDispatch();
	return (
		<div className='ff-subscription-enterprise'>
			<Modal
				header={
					<div>
						{SuccessCheck()}
						<h5>We’ll get back to you shortly!</h5>
					</div>
				}
				actionButtons={
					<Button onClick={() => dispatch(setShowFreeTrial(false))}>
						Got it
					</Button>
				}>
				Super excited to have you here. Your sales transformation journey starts
				now. To get a taste of what you can expect, we are organizing a
				personalized walkthrough and onboarding. Please check your email to get
				started.
			</Modal>
		</div>
	);
};

export default EnterpriseContact;
