import './header.scss';
import flyfishlogo from '../../assets/images/logo.png';
import { RootState, useAppDispatch } from '../../app/store';
import { useSelector } from 'react-redux';
import {
	logout,
	refreshToken,
	setForcedLogout,
	switchTab,
} from '../../Features/auth/authSlice';
import { useCallback, useEffect, useState } from 'react';
import '../Dashboard/Teams/team.scss';
import { Menu, Tooltip } from '@mui/material';
import {
	selectTeam,
	getTenants,
	setNavigateToIngest,
} from '../../Features/teams/TeamsSlice';
import {
	loadState,
	removeAllState,
	removeState,
	saveState,
} from '../../utils/utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { currentPwdResponse } from '../../Features/ChangePassword/ChangePassSlice';
import CreateNewPassword from './ChangePassword/CreateNewpassword';
import PasswordChangeSuccess from './ChangePassword/passchangesuccess';
import Avatar from '../../Components/Avatar';

import CatalogHeader from './CatalogHeader';
import { Tenants } from '../../Features/teams/model';

import { TeamIcon } from '../../Components/TeamIcon';
import { subscriptionStatusCheck } from '../../Features/Subscription/subscriptionSlice';
import { setUploadedFiles } from '../../Features/Ingest/IngestSlice';
import HelpBot from './HelpBot/index';
import Notification, { NotifyActiveIcon, NotifyIcon } from '../Notification';
import {
	resetAllNotificationData,
	resetNewNotificationCount,
	setNewNotification,
	setNotificationPane,
} from '../../Features/Notifications/notificationSlice';
import InteractiveButton from '../../Components/Button/InteractiveButton';
import PopupNotification from '../Notification/PopupNotification';
import { toast } from 'react-toastify';
import { notificationInit } from '../../utils/DesktopNotification';
import { setTotalProducts } from '../../Features/SalesAI/salesSlice';
import { resetMsgsArray } from '../../Features/HelpBot/helpbotSlice';
import { HelpBotCancelIcon, HelpBotIcon } from '../../assets/Icons/HelpBot';
import { setCustomPrompts } from '../../Features/ExperienceOnWeb/experienceonwebSlice';

type Props = {
	showheaderUserbadge?: boolean;
};
function Header({ showheaderUserbadge = true }: Props) {
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const [showHelpBot, setShowHelpBot] = useState<boolean>(false);
	const [anchorElem, setAnchorElem] = useState<any>();
	const { teams, tenantId } = useSelector((state: RootState) => state.teams);
	const { user, tokenExp, timeToExpireAPIStatus } = useSelector(
		(state: RootState) => state.auth
	);
	const { changePasswordSuccess, changePassword } = useSelector(
		(state: RootState) => state.changePassword
	);
	const { liveChatNotification } = useSelector(
		(state: RootState) => state.chats
	);
	const [isTeamTabVisible, setIsTeamTabVisible] = useState(true);
	const { uploadedFiles } = useSelector((state: RootState) => state.ingest);
	const {
		newNotification,
		notificationPane,
		unreadNotifications,
		newNotificationCount,
		notificationSnack,
	} = useSelector((state: RootState) => state.notification);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const tenantName = loadState('teamname');
	const tenantImageURL = loadState('tenantImageURL');
	const [givenTime, setGivenTime] = useState<any>(
		new Date(loadState('tokenExp'))
	);

	// const givenTime: any = new Date(tokenExp);
	const handleClose = () => {
		setShowMenu(!showMenu);
	};

	const [timeToExpire, setTimeToExpire] = useState(0);
	const { userEmailWhileSignUp } = useSelector(
		(state: RootState) => state.subscription
	);

	useEffect(() => {
		const intervalId = setInterval(() => {
			const currentTime: any = new Date();
			const timeDifferenceMs = givenTime - currentTime;
			let timeDifferenceMinutes = timeDifferenceMs / (1000 * 60);

			setTimeToExpire(timeDifferenceMinutes);
		}, 60000); // Update every minute (60 seconds * 1000 milliseconds)

		return () => {
			clearInterval(intervalId); // Clean up the interval when the component unmounts
		};
	}, [givenTime]);

	useEffect(() => {
		//do nothing
		let tokenExpiryTime = new Date(loadState('tokenExp'));
		// console.log('tokenExpiryTime', tokenExpiryTime);
		setGivenTime(tokenExpiryTime);
	}, [tokenExp]);

	const handleVisibilityChange = useCallback(() => {
		setIsTeamTabVisible(document.visibilityState === 'visible');
	}, []);

	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);
	useEffect(() => {
		if (timeToExpire > 4 && timeToExpire < 6) {
			dispatch(refreshToken({}));
		}
		if (timeToExpire < 0) {
			dispatch(logout());
			dispatch(setForcedLogout());
		}
		console.log('timeToExpire', timeToExpire);
	}, [timeToExpire]);

	useEffect(() => {
		if (timeToExpireAPIStatus === 'failed') {
			dispatch(logout());
			dispatch(setForcedLogout());
		}
	}, [timeToExpireAPIStatus]);

	const routes = [
		{
			title: 'Ingest',
			path:
				(uploadedFiles.fileUpload && uploadedFiles.fileUpload.length > 0) ||
					(uploadedFiles.ecom && uploadedFiles.ecom.length > 0)
					? '/app/Ingest-tab/ingested-resources'
					: '/app/Ingest',
		},
		{ title: 'Configure', path: '/app/configuration' },
		{ title: 'Launch', path: '/app/launch' },
		{ title: 'Chat', path: '/app/chats' },
		{ title: 'Analyze', path: '/app/analytics' },
	];

	const onSelectTeam = (team: Tenants) => {
		dispatch(
			switchTab({
				agentId: loadState('user') ? loadState('user').userID : '',

				agentSessionID: loadState('currentSessionID')
					? loadState('currentSessionID')
					: '',
			})
		);

		saveState('tenantId', team.id);
		saveState('organizationName', team.organizationName);
		saveState('teamname', team.name);
		saveState('tenantImageURL', team.backgroundImage);
		saveState('role', team.role);
		// will be used as Bearer token for whatsapp APIs
		dispatch(
			selectTeam({
				tenantId: team.id,
				teamname: team.name,
			})
		);

		dispatch(setNavigateToIngest(false));
		location.pathname.indexOf('create-ai') > -1 &&
			dispatch(subscriptionStatusCheck({ tenantId: team.id }));
		dispatch(setUploadedFiles([])); //need to check
		handleClose();
		removeState('unreadNotificationsCount');
		removeState('shopifyShopName');
		removeState('chargeId');
		dispatch(resetNewNotificationCount());
		dispatch(resetAllNotificationData());
		dispatch(setTotalProducts([]));
		saveState('switchTeam', 'true');
		removeState('botId');
		navigate('/app/create-ai');
		dispatch(setCustomPrompts([false]));
		sessionStorage.clear();
	};

	useEffect(() => {
		if (loadState('changePasswordStatus') === 'Success') {
			dispatch(
				currentPwdResponse({
					changePassword: false,
					changePasswordSuccess: true,
				})
			);
		}
	}, []);

	let path = location.pathname;
	let pathArr = path.split('/');

	const renderHeader = () => {
		if (pathArr[2] === 'catalog') {
			return <CatalogHeader path={path} type={pathArr[3]} />;
		} else if (
			pathArr[2] === 'welcome' ||
			pathArr[2] === 'teams' ||
			path.indexOf('select-plan') > -1 ||
			path.indexOf('payment') > -1 ||
			path.indexOf('create-team') > -1 ||
			path.indexOf('create-or-join-team') > -1 ||
			path.indexOf('thank-you') > -1 ||
			path.indexOf('zoho-consent') > -1 ||
			path.indexOf('signup-shopify-link') > -1 ||
			path.indexOf('signup-shopify-install') > -1
		) {
			return (
				<>
					<div className='logo-container inactive-logo'>
						<img src={flyfishlogo} alt='flyfishLogo' />
					</div>
					<div></div>
				</>
			);
		} else {
			return (
				<>
					<div className='logo-container'>
						<img src={flyfishlogo} alt='flyfishLogo' />
					</div>

					<div className={`ff-topbar ${routeClass()}`}>
						<ul>
							{routes.map((route) => (
								<li
									key={route.title}
									className={
										path.startsWith(route.path)
											? `active ${routeClass()}`
											: route.title == 'Chat' && liveChatNotification
												? 'notify-user-chats'
												: ''
									}>
									<Link to={route.path} className='body2'>
										{route.title}
									</Link>
									<span></span>
								</li>
							))}
						</ul>
					</div>
				</>
			);
		}
	};

	const routeClass = () => {
		if (
			path.indexOf('my-studio') > -1 ||
			path.indexOf('analytics') > -1 ||
			path.indexOf('channels/whatsapp') > -1 ||
			path.indexOf('design/deploy') > -1
		) {
			return 'mystudio-analytics-configuration-whatsapp';
		} else if (path.indexOf('faq/design') > -1) {
			return 'faq-design';
		} else if (path.indexOf('design/build') > -1) {
			return 'design';
		} else if (path.indexOf('faq/launch') > -1) {
			return 'faq-launch';
		} else if (path.indexOf('sales-ai') > -1) {
			return 'sales-ai';
		} else if (path.indexOf('launch') > -1) {
			return 'launch';
		} else if (path.indexOf('faq/configuration') > -1) {
			return 'faq-configuration';
		} else if (path.indexOf('configuration') > -1) {
			return 'mystudio-analytics-configuration-whatsapp';
		} else if (
			path.indexOf('create-ai') > -1 ||
			path.indexOf('create-or-join-team') > -1 ||
			path.indexOf('create-team') > -1 ||
			path.indexOf('join-team') > -1
		) {
			return 'create-ai-hidden';
		} else {
			return 'channels-chats';
		}
	};

	useEffect(() => {
		if (newNotificationCount) {
			let data = unreadNotifications.slice(-newNotificationCount);
			data.forEach((notification) => {
				let notify = {
					time: notification.date,
					link: notification.name || 'CTA',
					message: notification.value,
					type:
						notification.type === 'Information'
							? 'info'
							: notification.type === 'Warning'
								? 'alert'
								: 'chat',
					open: true,
				};
				if (notify.open) {
					if (
						location.pathname.indexOf('chats/my-chats') > -1 &&
						notify.type === 'chat'
					) {
						//do nothing
					} else {
						const loginTime = loadState('userLoginTime');
						const notifyTime = new Date(notify.time).getTime();

						if (Number(loginTime) <= Number(notifyTime)) {
							toast(
								<PopupNotification
									type={notify.type}
									message={notify.message}
									time={notify.time}
									link={notify.link}
								/>
							);

							saveState('unreadNotificationsCount', unreadNotifications.length);
							if (isTeamTabVisible && notify.message !== undefined) {
								notificationInit(notify.message);
							}
						}
					}
				}
			});

			dispatch(resetNewNotificationCount());
		}
	}, [newNotificationCount]);

	return (
		<>
			<div className={`ff-header ${routeClass()}`}>
				{renderHeader()}
				{showheaderUserbadge ? (
					<>
						<div className='help-bot-badge-container'>
							<div className='notifications'>
								{newNotification && !notificationPane ? (
									<InteractiveButton
										className='new-notification'
										icon={<NotifyActiveIcon />}
										title='Notifications'
										onClick={(event) => {
											event.stopPropagation();
											dispatch(setNotificationPane(true));

											dispatch(setNewNotification(false));
										}}
										hoverable='ring'
									/>
								) : (
									<InteractiveButton
										className={`old-notification ${notificationPane ? 'change' : ''
											}`}
										icon={<NotifyIcon />}
										title='Notifications'
										onClick={(event) => {
											event.stopPropagation();
											dispatch(setNotificationPane(true));
										}}
										hoverable='ring'
									/>
								)}
							</div>
							{!showHelpBot ? (
								<div
									className={`help-bot-icon ${window.location.origin.lastIndexOf('3000') > -1 ||
										window.location.origin.lastIndexOf('dev.flyfish.ai') > -1 ||
										window.location.origin.lastIndexOf('uat.flyfish.ai') > -1
										? ''
										: 'visible-help'
										}`}
									onClick={() => {
										setShowHelpBot(true);
										saveState('helpBotEnable', 'Enabled');
									}}>
									<HelpBotIcon />
								</div>
							) : (
								<div
									className='help-bot-icon helpbot-cancel-bg-icon'
									onClick={() => {
										dispatch(resetMsgsArray());
										setShowHelpBot(false);
										saveState('helpBotEnable', '');
									}}>
									<HelpBotCancelIcon />
								</div>
							)}

							<div
								className='header-user-badge'
								onClick={(event: React.MouseEvent<HTMLElement>) => {
									setAnchorElem(event.currentTarget);
									tenantId !== null &&
										teams.length === 0 &&
										dispatch(
											getTenants({
												updateTenant: false,
												userId: loadState('user') && loadState('user').userID,
											})
										);
									setShowMenu(!showMenu);
								}}>
								<div className='ff-user-info'>
									<div className='ff-user-email'>{user?.userEmail}</div>
									{tenantId !== null && (
										<Tooltip placement='bottom' title={tenantName}>
											<div className='ff-tenant-name caption'>{tenantName}</div>
										</Tooltip>
									)}
								</div>

								{user?.userEmail && !tenantImageURL ? (
									<Avatar text={user?.userEmail} />
								) : (
									<div>
										<img
											className='tenantHeaderImg'
											src={tenantImageURL}
											alt='tenantImg'
										/>
									</div>
								)}

								{/*  Code for preview image in header
					{tenantId === null ? (
						<Avatar text={user?.userEmail ?? 'F'} /> // F for flyfish
					) : tenantImageURL ? (
						<img
							className='tenantHeaderImg'
							src={tenantImageURL}
							alt='tenantImg'
						/>
					) : (
						user?.userEmail && <Avatar text={user?.userEmail} />
					)} */}
								<div>
									{showMenu && (
										<Menu
											anchorEl={anchorElem}
											PaperProps={{
												style: {
													borderRadius: '16px',
													marginTop: '10px',
													boxShadow:
														'0 4px 48px 0 rgba(0, 0, 0, 0.08), 0 8px 12px 0 rgba(0, 0, 0, 0.08)',
												},
											}}
											id='simple-menu all-menu'
											open={showMenu}
											onClose={handleClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}>
											<div className='ff_header_menu'>
												<div
													style={{
														display:
															path.indexOf('create-ai') > -1 ? 'none' : 'block',
													}}
													className='ff_header_each_menu'
													onClick={() => {
														dispatch(
															currentPwdResponse({
																changePassword: true,
																changePasswordSuccess: false,
															})
														);
														setShowMenu(!showMenu);
													}}>
													<div className='ff_header_menu_text'>
														Change Password
													</div>
												</div>
												{tenantId !== null ? (
													<>
														{loadState('isPlanActive') ? (
															<div
																style={{
																	display:
																		path.indexOf('general-configuration') >
																			-1 || path.indexOf('create-ai') > -1
																			? 'none'
																			: 'block',
																}}
																className='ff_header_each_menu'
																onClick={() => {
																	setShowMenu(!showMenu);
																	navigate('/app/general-configuration');
																}}>
																<div className='ff_header_menu_text'>
																	Settings
																</div>
															</div>
														) : null}

														<div>
															{teams.length > 1 ? (
																<div className='ff_header_switchteam'>
																	Switch Team
																</div>
															) : (
																''
															)}
															<div className='ff_header_tenant styled-scroll'>
																{teams.map((team: any, index: number) => (
																	<>
																		{team.id !== tenantId ? (
																			<div
																				key={team.name}
																				className='ff_headermenu_teambadge'
																				onClick={() => {
																					onSelectTeam(team);
																				}}>
																				{team.backgroundImage ? (
																					<img
																						className='tenantImg'
																						src={team.backgroundImage}
																						alt='tenantImg'
																					/>
																				) : (
																					<TeamIcon
																						teamName={team.name}
																						image=''
																						index={index}
																						dropdown={true}
																					/>
																				)}

																				<div className='ff_headermenu_team'>
																					{team.name}
																				</div>
																			</div>
																		) : null}
																	</>
																))}
															</div>
														</div>
													</>
												) : (
													''
												)}
												<div
													className='ff_headermenu_team_Logout'
													onClick={() => {
														if (loadState('location')) {
															dispatch(
																switchTab({
																	agentId: loadState('user')
																		? loadState('user').userID
																		: '',

																	agentSessionID: loadState('currentSessionID')
																		? loadState('currentSessionID')
																		: '',
																})
															);

															dispatch(logout());
														} else {
															dispatch(logout());
														}
														removeAllState();
														window.location.reload();
													}}>
													Logout
												</div>
											</div>
										</Menu>
									)}
								</div>
							</div>
						</div>
					</>
				) : (
					<div className='header-user-badge'>
						<div className='ff-user-info'>
							<div className='ff-user-email'>
								{loadState('userEmailId') ?? userEmailWhileSignUp}
							</div>
						</div>

						{(userEmailWhileSignUp || loadState('userEmailId')) && (
							<Avatar text={loadState('userEmailId') ?? userEmailWhileSignUp} />
						)}
					</div>
				)}
			</div>
			{changePassword && <CreateNewPassword />}
			{changePasswordSuccess && <PasswordChangeSuccess />}

			{/* {createTeam && <CreateTeam />}
			{joinTeam && <JoinTeam />} */}

			{showHelpBot && <HelpBot />}
			{notificationPane && <Notification />}
		</>
	);
}

export default Header;
