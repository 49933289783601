import DataFetchAPI from '../../../utils/Api';
import { kotakTenantCheckForWhatsapp, loadState } from '../../../utils/utils';
import { GetCampaignAnalyticsParams } from '../campaign/campaignSlice';
import { GetData } from './analyticsSlice';

const getData = async (data: GetData | GetCampaignAnalyticsParams) => {
	return await DataFetchAPI().get(
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? `/${loadState('WAEndpoint')}/analytics`
			: `/${loadState('WAEndpoint')}/${loadState('tenantId')}/analytics`,
		{
			params: data,
		}
	);
};

const getCampaignReport = async (data: any) => {
	return await DataFetchAPI().get(
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? `/${loadState('WAEndpoint')}/campaignReport`
			: `/${loadState('WAEndpoint')}/${loadState('tenantId')}/campaignReport`,
		{
			params: data,
		}
	);
};

const analyticsService = {
	getData,
	getCampaignReport,
};

export default analyticsService;
