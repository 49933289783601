import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import subscriptionService from './subscriptionService';
import {
	CHANGE_PLAN,
	FREE_PLAN,
	LinkSubscriptionTenantId,
	PLAN_CHARGEID,
	StoreDetails_SHOPIFY,
	SubscriptionDetails_SHOPIFY,
	SubscriptionInvoiceReq,
	SubscriptionPayment_SHOPIFY,
	SubscriptionReq,
	SubscriptionState,
	subscriptionEnquiryReq,
} from './model';
import { loadState, saveState } from '../../utils/utils';

export const subscriptionStatusCheck = createAsyncThunk<
	any,
	SubscriptionReq,
	{ rejectValue: string }
>('/subscriptionStatusCheck', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await subscriptionService.subscriptionStatus(data);
		if (response.data) {
			if (response.data.subProvider.indexOf('shopify') > -1) {
				saveState('shopifyShopName', 'shopify');
			}
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const subscriptionStatusInvoice = createAsyncThunk<
	any,
	SubscriptionInvoiceReq,
	{ rejectValue: string }
>('/subscriptionStatusInvoice', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await subscriptionService.subscriptionStatusInvoice(data);
		if (response.data.status.toLowerCase().trim() === 'success') {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const linkSubscriptionTenantId = createAsyncThunk<
	any,
	LinkSubscriptionTenantId,
	{ rejectValue: string }
>('/linkSubscriptionTenantId', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await subscriptionService.linkSubscriptionTenantId(data);
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const subscriptionEnquiry = createAsyncThunk<
	any,
	subscriptionEnquiryReq,
	{ rejectValue: string }
>('/subscriptionEnquiry', async (data, { rejectWithValue, dispatch }) => {
	try {
		data.template === '14DayTrialPlan'
			? dispatch(setSusbcriptionEnquiryLoaderTrial(true))
			: dispatch(setSusbcriptionEnquiryLoader(true));
		const response = await subscriptionService.subscriptionEnquiry(data);

		if (response.status === 200) {
			data.template === '14DayTrialPlan'
				? dispatch(setSusbcriptionEnquiryLoaderTrial(false))
				: dispatch(setSusbcriptionEnquiryLoader(false));

			data.template === '14DayTrialPlan'
				? dispatch(setShowFreeTrial(true))
				: dispatch(setShowEnterprise(true));
			if (data.template === '14DayTrialPlan') {
				let countfreetrial = loadState('mailSentCountfree-trail') + 1;
				saveState('mailSentCountfree-trail', countfreetrial);
			} else {
				let count = loadState('mailSentCount') + 1;
				saveState('mailSentCount', count);
			}
		}
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		data.template === '14DayTrialPlan'
			? dispatch(setSusbcriptionEnquiryLoaderTrial(false))
			: dispatch(setSusbcriptionEnquiryLoader(false));

		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const shopAuth_shopify = createAsyncThunk<
	any,
	StoreDetails_SHOPIFY,
	{ rejectValue: string }
>('/shopAuth_shopify', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await subscriptionService.shopAuth_shopify(data);
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const shopSubscription_shopify = createAsyncThunk<
	any,
	SubscriptionDetails_SHOPIFY,
	{ rejectValue: string }
>('/shopSubscription_shopify', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await subscriptionService.shopSubscription_shopify(data);
		if (response.data.status.toLowerCase() === 'success') {
			return response.data.confirmationUrl;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const shopSubscriptionPayment_shopify = createAsyncThunk<
	any,
	SubscriptionPayment_SHOPIFY,
	{ rejectValue: string }
>(
	'/shopSubscriptionPayment_shopify',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response =
				await subscriptionService.shopSubscriptionPayment_shopify(data);
			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify({ error: 'Network error' }));
		}
	}
);

export const changePlan = createAsyncThunk<
	any,
	CHANGE_PLAN,
	{ rejectValue: string }
>('/changePlan', async (data, { rejectWithValue }) => {
	try {
		const response = await subscriptionService.changePlan(data);
		if (response.data.status.toLowerCase() === 'success') {
			return response.data.confirmationUrl;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const renewPlan = createAsyncThunk<
	any,
	PLAN_CHARGEID,
	{ rejectValue: string }
>('/renewPlan', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await subscriptionService.renewPlan(data);
		if (response.data.status.toLowerCase() === 'success') {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const freePlanSubscribe = createAsyncThunk<
	any,
	FREE_PLAN,
	{ rejectValue: string }
>('/freePlanSubscribe', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await subscriptionService.freePlanSubscribe(data);
		if (response.data.status.toLowerCase() === 'success') {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

const initialState: SubscriptionState = {
	isPlanActive: false,
	susbcriptionCheckLoader: false,
	params: '',
	selectedPlan: '',
	billingDuration: 'monthly',
	showEnterprise: false,
	showFreeTrial: false,
	currency: 'dollar',
	subscriptionStatus: '',
	zohoPaymentURL: '',
	showPaymentStatus: true,
	susbcriptionEnquiryLoader: false,
	susbcriptionEnquiryLoaderTrial: false,
	planName: '',
	planCode: '',
	planCurrency: '',
	planPrice: '',
	userEmailWhileSignUp: '',
	organizationName: '',
	// invoiceId: '',
	tenantIdAvailable: false,
	susbcriptionInvoiceLoader: false,
	subscriptionInvoiceAPIStatus: '',
	currentSubscriptionId: '', //1330768000000183169
	tenantLinkedWithSubscriptionId: '',
	subscriptionAPIStatus: '',
	availableCurrency: [
		{ label: 'USD', value: 'USD' },
		{ label: 'GBP', value: 'GBP' },
		{ label: 'EUR', value: 'EUR' },
		{ label: 'AUD', value: 'AUD' },
		{ label: 'SGD', value: 'SGD' },
		{ label: 'INR', value: 'INR' },
	],
	selectedCurreny: {
		label: 'USD',
		value: 'USD',
	},
	conversation: {
		total: 0,
		remaining: 0,
	},
	channel: {
		total: 0,
		remaining: 0,
	},
	planExpiry: '',
	shopAuthLoader: '',
	shopifyPaymentRedirect: '',
	shopifyPaymentRedirectAPIStatus: '',
	shopSubscriptionPaymentAPIStatus: '',
	changePlanAPIStatus: '',
	renewPlanAPIStatus: '',
	changePlanRedirect: '',
	freePlanSubscribeAPIStatus: '',
	subscriptionId_shopify: loadState('subscriptionId_shopify') ?? '',
	linkSubscriptionTenantIdAPIStatus: '',
	leadAllowed: [],
};

const subscriptionSlice = createSlice({
	name: 'subscription',
	initialState,
	reducers: {
		resetSubscriptionState: (state) => {
			state.shopAuthLoader = '';
		},
		setIsPlanActive: (state, action) => {
			state.isPlanActive = action.payload;
		},
		setParams: (state, action) => {
			state.params = action.payload;
		},
		setSelectedPlan: (state, action) => {
			state.selectedPlan = action.payload;
		},
		setBillingDuration: (state, action) => {
			state.billingDuration = action.payload;
		},
		setShowEnterprise: (state, action) => {
			state.showEnterprise = action.payload;
		},
		setShowFreeTrial: (state, action) => {
			state.showFreeTrial = action.payload;
		},
		setSubscriptionStatus: (state, action) => {
			state.subscriptionStatus = action.payload;
		},
		setZohoPaymentURL: (state, action) => {
			state.zohoPaymentURL = action.payload;
		},
		setShowPaymentStatus: (state, action) => {
			state.showPaymentStatus = action.payload;
		},
		setUserEmailWhileSignUp: (state, action) => {
			state.userEmailWhileSignUp = action.payload;
		},
		setOrganizationName: (state, action) => {
			state.organizationName = action.payload;
		},
		resetSubscriptionInvoiceAPIStatus: (state) => {
			state.subscriptionInvoiceAPIStatus = '';
		},
		resetSubscriptionAPIStatus: (state) => {
			state.subscriptionAPIStatus = '';
		},
		setCurrency: (state, action) => {
			state.currency = action.payload;
		},
		setSelectedCurrency: (state, action) => {
			state.selectedCurreny = action.payload;
		},
		setSusbcriptionEnquiryLoader: (state, action) => {
			state.susbcriptionEnquiryLoader = action.payload;
		},
		setSusbcriptionEnquiryLoaderTrial: (state, action) => {
			state.susbcriptionEnquiryLoaderTrial = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(subscriptionStatusCheck.pending, (state) => {
				state.susbcriptionCheckLoader = true;
				state.isPlanActive = false;
				state.subscriptionAPIStatus = 'pending';
			})
			.addCase(subscriptionStatusCheck.fulfilled, (state, action) => {
				state.susbcriptionCheckLoader = false;

				if (action.payload.status.toLowerCase().trim() === 'success') {
					state.currentSubscriptionId = action.payload.subscriptionId;
					state.planName = action.payload.planName ?? '';
					state.planCode = action.payload.planCode ?? '';
					state.planCurrency = action.payload.currencyCode ?? '';
					state.planPrice = action.payload.price ?? '';
					state.subscriptionStatus = action.payload.subscriptionStatus;
					state.isPlanActive = action.payload.isPlanActive;
					state.conversation = action.payload.conversation;
					state.channel = action.payload.channel;
					state.planExpiry = action.payload.planExpiry;
					state.leadAllowed = action.payload.lead_routing.allowed;
					saveState('planName', action.payload.planName);
					saveState('planCode', action.payload.planCode);
					saveState('planCurrency', action.payload.currencyCode);
					saveState('planPrice', action.payload.price);
					saveState('isPlanActive', action.payload.isPlanActive);
					saveState('leadAllowed', action.payload.lead_routing.allowed);
					if (loadState('shopifyShopName') && !loadState('botId')) {
						saveState('chargeId', action.payload.subscriptionId);
					}

					state.subscriptionAPIStatus =
						action.payload.isPlanActive &&
						action.payload.subscriptionStatus !== 'expired'
							? 'success'
							: 'failed';
				} else {
					state.isPlanActive = false;
					state.subscriptionAPIStatus = 'failed';
				}
			})
			.addCase(subscriptionStatusCheck.rejected, (state, action) => {
				state.susbcriptionCheckLoader = false;
				state.isPlanActive = false;
				state.subscriptionAPIStatus = 'failed';
			})
			.addCase(subscriptionEnquiry.pending, (state) => {
				// state.susbcriptionEnquiryLoader = true;
			})
			.addCase(subscriptionEnquiry.fulfilled, (state, action) => {
				// state.susbcriptionEnquiryLoader = false;
			})
			.addCase(subscriptionEnquiry.rejected, (state, action) => {
				state.susbcriptionEnquiryLoader = false;
			})
			.addCase(subscriptionStatusInvoice.pending, (state) => {
				state.susbcriptionInvoiceLoader = true;
				state.subscriptionInvoiceAPIStatus = 'pending';
			})
			.addCase(subscriptionStatusInvoice.fulfilled, (state, action) => {
				state.susbcriptionInvoiceLoader = false;
				state.tenantIdAvailable = Number(action.payload.tenantId) > 0;
				state.currentSubscriptionId = action.payload.subscriptionId;
				state.planCode = action.payload.planCode ?? '';
				state.subscriptionInvoiceAPIStatus = 'success';
			})
			.addCase(subscriptionStatusInvoice.rejected, (state, action) => {
				state.susbcriptionInvoiceLoader = false;
				state.subscriptionInvoiceAPIStatus = 'failed';
			})
			.addCase(shopAuth_shopify.pending, (state) => {
				state.shopAuthLoader = 'loading';
			})
			.addCase(shopAuth_shopify.fulfilled, (state, action) => {
				state.shopAuthLoader = 'success';
			})
			.addCase(shopAuth_shopify.rejected, (state, action) => {
				state.shopAuthLoader = 'failed';
			})
			.addCase(shopSubscription_shopify.pending, (state) => {
				state.shopifyPaymentRedirectAPIStatus = 'loading';
			})
			.addCase(shopSubscription_shopify.fulfilled, (state, action) => {
				state.shopifyPaymentRedirect = action.payload;
				state.shopifyPaymentRedirectAPIStatus = 'success';
			})
			.addCase(shopSubscription_shopify.rejected, (state, action) => {
				state.shopifyPaymentRedirectAPIStatus = 'failed';
			})
			.addCase(shopSubscriptionPayment_shopify.pending, (state) => {
				state.shopSubscriptionPaymentAPIStatus = 'loading';
			})
			.addCase(shopSubscriptionPayment_shopify.fulfilled, (state, action) => {
				state.shopSubscriptionPaymentAPIStatus = 'success';
			})
			.addCase(shopSubscriptionPayment_shopify.rejected, (state, action) => {
				state.shopSubscriptionPaymentAPIStatus = 'failed';
			})
			.addCase(changePlan.pending, (state) => {
				state.changePlanAPIStatus = 'loading';
			})
			.addCase(changePlan.fulfilled, (state, action) => {
				state.changePlanAPIStatus = 'success';
				state.changePlanRedirect = action.payload;
			})
			.addCase(changePlan.rejected, (state, action) => {
				state.changePlanAPIStatus = 'failed';
			})
			.addCase(renewPlan.pending, (state) => {
				state.renewPlanAPIStatus = 'loading';
			})
			.addCase(renewPlan.fulfilled, (state, action) => {
				state.renewPlanAPIStatus = 'success';
			})
			.addCase(renewPlan.rejected, (state, action) => {
				state.renewPlanAPIStatus = 'failed';
			})
			.addCase(freePlanSubscribe.pending, (state) => {
				state.freePlanSubscribeAPIStatus = 'loading';
			})
			.addCase(freePlanSubscribe.fulfilled, (state, action) => {
				state.freePlanSubscribeAPIStatus = 'success';
				state.subscriptionId_shopify = action.payload.subscriptionId;
				action.payload.chargeId &&
					saveState('chargeId', action.payload.chargeId);
			})
			.addCase(freePlanSubscribe.rejected, (state, action) => {
				state.freePlanSubscribeAPIStatus = 'failed';
			})
			.addCase(linkSubscriptionTenantId.pending, (state) => {
				state.linkSubscriptionTenantIdAPIStatus = 'loading';
			})
			.addCase(linkSubscriptionTenantId.fulfilled, (state, action) => {
				state.linkSubscriptionTenantIdAPIStatus = 'success';
			})
			.addCase(linkSubscriptionTenantId.rejected, (state, action) => {
				state.linkSubscriptionTenantIdAPIStatus = 'failed';
			});
	},
});

export const {
	setIsPlanActive,
	setParams,
	setSelectedPlan,
	setBillingDuration,
	setShowEnterprise,
	setSubscriptionStatus,
	setZohoPaymentURL,
	setShowPaymentStatus,
	setUserEmailWhileSignUp,
	setOrganizationName,
	resetSubscriptionInvoiceAPIStatus,
	resetSubscriptionAPIStatus,
	setCurrency,
	setSelectedCurrency,
	setShowFreeTrial,
	setSusbcriptionEnquiryLoader,
	setSusbcriptionEnquiryLoaderTrial,
	resetSubscriptionState,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
