import DataFetchAPI from '../../utils/Api';
import { CurrentPassword, ChangePassword } from './model';

const VerifyCurrentPassword = async (data: CurrentPassword) => {
	return await DataFetchAPI().post('/rest/verifyPassword', data);
};
const ChangeCurrentPassword = async (data: ChangePassword) => {
	return await DataFetchAPI().post(`/authentication/user/${data.userId}/changePassword`, data.data);
};

const passwordService = {
	VerifyCurrentPassword,
	ChangeCurrentPassword,
};

export default passwordService;
