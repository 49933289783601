import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../app/store';
import Button from '../../../../../Components/Button';
import Checkbox from '../../../../../Components/Checkbox';
import Input from '../../../../../Components/Input';
import Modal from '../../../../../Components/Modal';
import {
	BOTROLES,
	GetAllUsersMyhubResponse,
	ROLELIST,
} from '../../../../../Features/teamsetting/modal';
import { updateUserRoleMyhub } from '../../../../../Features/teamsetting/teamsettingSlice';
import { addNewUser } from '../../../../../Features/users/userSlice';
import { roleIds } from '../../../../../utils/constants';
import { loadState } from '../../../../../utils/utils';
import './EditBotAccess.scss';

const EditBotAccess = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const state = location.state as {
		user: GetAllUsersMyhubResponse;
		allBotData: BOTROLES[];
		fromRevoke?: boolean;
		fromAddUser?: boolean;
		UserEmail?: string;
	};
	const [botData, setBotData] = useState(state.allBotData);
	const [selectAllCheck, setSelectAllCheck] = useState([false]);
	const [checkedState, setCheckedState] = useState(
		new Array(botData.length).fill(false)
	);
	const [disable, setDisable] = useState(true);
	const [value, setValue] = useState('');
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const botDetails = loadState('botDetails');

	useEffect(() => {
		if (value !== '') {
			setBotData([
				...state.allBotData.filter((v) =>
					v.botName.toLowerCase().includes(value.toLowerCase())
				),
			]);
			let indexArray: number[] = [];
			const temp = state.user.botRoles.filter((v: BOTROLES) => {
				return botData.forEach((v1, i) => {
					if (v1.botId === v.botId) {
						indexArray.push(i);
					}
				});
			});

			let checked = Array.from(checkedState);
			indexArray.forEach((v) => {
				checked.splice(v, 1, true);
			});
			setCheckedState(checked);
		} else {
			setBotData(state.allBotData);
		}
	}, [value]);

	useEffect(() => {
		if (selectAllCheck[0]) {
			setCheckedState(new Array(botData.length).fill(true));
		} else {
			setCheckedState(new Array(botData.length).fill(false));
		}
	}, [selectAllCheck]);

	useEffect(() => {
		let botSelectedtoAssign = 0;
		checkedState.forEach((v) => {
			if (v === true) {
				botSelectedtoAssign++;
			}
		});
		if (botSelectedtoAssign) {
			setDisable(false);
		} else {
			setDisable(true);
		}
	}, [checkedState]);

	useEffect(() => {
		if (state.fromRevoke || state.fromAddUser) {
			setSelectAllCheck([true]);
		} else {
			let indexArray: number[] = [];
			const temp = state.user.botRoles.filter((v: BOTROLES) => {
				return botData.forEach((v1, i) => {
					if (v1.botId === v.botId) {
						indexArray.push(i);
					}
				});
			});

			let checked = Array.from(checkedState);
			indexArray.forEach((v) => {
				checked.splice(v, 1, true);
			});
			setCheckedState(checked);
		}
	}, []);

	const editBotAcess = () => {
		let roleList: Array<ROLELIST> = [];
		let checked = Array.from(checkedState);
		let indexArray: number[] = [];
		checked.forEach((v, i) => {
			if (v === true) {
				indexArray.push(i);
			}
		});
		indexArray.forEach((v) =>
			state.allBotData.forEach((bot, i) => {
				if (v === i) {
					roleList.push({
						botId: loadState('botId'),
						roleId: roleIds.roleIdBotMaster,
					});
				}
			})
		);
		if (state.fromAddUser) {
			let name = state.UserEmail!.split('@');
			let data = {
				tenantId: tenantId!,
				email: state.UserEmail!,
			};
			dispatch(addNewUser(data));
			navigate(-2);
		} else {
			let data = {
				botsRoleList: roleList,
				tenantId: tenantId!,
				userId: state.user.userId,
			};
			dispatch(updateUserRoleMyhub(data));
			if (state.fromRevoke || state.fromAddUser) {
				navigate(-2);
			} else {
				navigate(-1);
			}
		}
	};

	const buttons = () => {
		return (
			<>
				<Button onClick={() => navigate(-1)} buttonType='light'>
					Cancel
				</Button>
				<Button disabled={disable} onClick={editBotAcess}>
					{state.fromRevoke || state.fromAddUser ? 'Done' : 'Add'}
				</Button>
			</>
		);
	};

	const label = (bot: any) => {
		return (
			<div className='botData'>
				<div>
					<img className='botImg' src={bot.botIcon} alt='icon' />
				</div>
				<div>{bot.botName}</div>
			</div>
		);
	};

	return (
		<div>
			<Modal header='Edit bot access' actionButtons={buttons()}>
				<div className='editBotAccess'>
					<div className='header-input'>
						<p>Select the bots which the user should have access to</p>
						<Input
							value={value}
							onChange={(event) => setValue(event.target.value)}
							placeholder='Search...'
						/>
					</div>
					<div className='checkbox-container'>
						<Checkbox
							label={<div className='select-all'>Select All</div>}
							isChecked={selectAllCheck}
							onValueChange={setSelectAllCheck}
							index={0}
						/>
						<>
							{botData.map((bot, i) => {
								return (
									<Checkbox
										index={i}
										label={label(bot)}
										isChecked={checkedState}
										onValueChange={setCheckedState}
									/>
								);
							})}
						</>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default EditBotAccess;
