import DataFetchAPI from '../../utils/Api';
import { botID, deleteVersionData, deployData, reDeploy } from './modal';

const deployNewVersion = async (data: deployData) => {
	return await DataFetchAPI().post('/version/pushversion', data);
};

const getVersionDetails = async (data: botID) => {
	return await DataFetchAPI().get('/version/getVersionDetails', {
		params: data,
	});
};

const deleteVersion = async (data: deleteVersionData) => {
	return await DataFetchAPI().post('/version/deleteversion', data);
};

const reDeployVersion = async (data: reDeploy) => {
	return await DataFetchAPI().post('/version/revertversion', data);
};

const deployService = {
	deployNewVersion,
	getVersionDetails,
	deleteVersion,
	reDeployVersion,
};

export default deployService;
