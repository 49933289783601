import './TextBlock.scss';

type TextBlockProps = {
	height?: number | 'auto';
	text: string;
	textStyle?: 'body1' | 'body-mono';
	horizontalScroll?: boolean;
};

const TextBlock = ({
	height,
	text,
	textStyle = 'body1',
	horizontalScroll = false,
}: TextBlockProps) => {
	return (
		<div className='ff-text-block' style={{ maxHeight: height }}>
			<div className='ff-text-block-container'>
				<div
					className={`${textStyle} text ${
						horizontalScroll && 'horizontal-scroll'
					}`}>
					{text}
				</div>
			</div>
		</div>
	);
};

export default TextBlock;
