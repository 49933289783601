import React from 'react';
import ModalPane from '../../../../../Components/Modal';
import * as Rating from '../Ratings/Ratings';
import { RATING_SMILEY_ID_MAPPING, RATING_TYPES } from '../../../../../utils/constants';
import Input from '../../../../../Components/Input';
import styled from 'styled-components';
import Button from '../../../../../Components/Button';

interface Props {
	options?: string[];
	skip?: boolean;
	feedbackSettings?: any;
	submitFeedback?: Function;
	handleModalToggle?: Function;
	colorMode?: string;
	currentScreen?: string;
}
const FEEDBACK = styled.div`
#FeedbackInputBox[contenteditable='true']:before {
	content: attr(placeholder);
	color: rgba(0, 0, 0, 0.6);
	font-size: 14px;
	font-weight: 600;
}
	&.ff-feedback-wrapper {
		
	}};
		& .custom-button {
			& > button {
				background-color:'var(--colorScale-2)';
	}} !important;
				& > .custom-button-body {
					color:'var(--colorScale-9)';
	}} !important;
				}
			}
		}
		& .MuiInputBase-root {
			&:not(.Mui-disabled, .Mui-error).Mui-focused {
				.MuiOutlinedInput-notchedOutline {
					border-color:'var(--colorScale-2)';
	}} !important;
				}
			}
		}
   
	}
`;
export default function Feedback(props: Props) {
	const { feedbackSettings } = props;
	const [rating, setRating] = React.useState(0);
	const [feedbackData, setFeedbackData] = React.useState('');

	let data = feedbackData.replace(/ {2,}/g, ' ');
	// let buttonList =
	// 	props.feedbackSettings && props.feedbackSettings.buttonList
	// 		? props.feedbackSettings.buttonList
	// 		: props.skip
	// 			? [
	// 				{
	// 					label: <T labelName='submitLabel' />,
	// 					onClick: props.submitFeedback.bind(this, rating, feedbackData, 0),
	// 					disabled: rating > 0 ? false : true,
	// 					buttonType: 'dark',
	// 					fullWidth: false,
	// 				},
	// 				// {
	// 				//   label: <T labelName="skipLabel" />,
	// 				//   onClick: props.submitFeedback.bind(this),
	// 				//   variant: 'filled'
	// 				// }
	// 			]
	// 			: [
	// 				{
	// 					label: <T labelName='submitLabel' />,
	// 					onClick: props.submitFeedback.bind(this, rating, feedbackData),
	// 					disabled: !rating,
	// 					buttonType: 'dark',
	// 					fullWidth: false,
	// 				},
	// 			];
	return (
		<FEEDBACK
			className='ff-feedback-wrapper'
		>
			<ModalPane
				header={
					<div style={{ textAlign: 'center' }}>
						<b>How was your chat experience?</b>
					</div>
				}
				// buttons={[...buttonList]}
				actionButtons={
					props.feedbackSettings && props.feedbackSettings.buttonList
						? props.feedbackSettings.buttonList
						: props.skip
							?
							<Button
								fullWidth={false}
								variant='contained'
								disabled={rating > 0 ? false : true}
								onClick={() => {

								}}>
								Submit
							</Button>
							:
							<Button
								fullWidth={false}
								variant='contained'
								disabled={!rating}
								onClick={() => {

								}}>
								Submit
							</Button>
				}
			// onClose={}
			>
				<>
					{feedbackSettings &&
						RATING_SMILEY_ID_MAPPING[feedbackSettings?.ratingTypeId].type ===
						RATING_TYPES.Star ? (
						<Rating.StarRating
							number={
								RATING_SMILEY_ID_MAPPING[feedbackSettings?.ratingTypeId].count
							}
							filled={rating}
							onChange={(data: any) => {
								setRating(data);
							}}
							noOutlineHover
							gap={8}
							iconSize={'32px'}
							purpose={'feedback'}
						/>
					) : (
						<Rating.StarRating
							number={5}
							filled={rating}
							onChange={(data: any) => {
								setRating(data);
							}}
							gap={8}
							noOutlineHover
							iconSize={'32px'}
							purpose={'feedback'}
						/>
					)}

					{/* <div
						style={{
							resize: 'none', maxHeight: '75px',
							width: '100%',
							padding: '8px 12px 8px 11.8px',
							borderRadius: '8px',
							border: props.colorMode === 'dark'
								? 'solid 2px var(--selectedColor)'
								: 'solid 2px var(--colorScale-2)',
							backgroundColor: "#ffffff",
							marginTop: "10px",
							overflowY: "auto"
						}}
						aria-label={'Please tell us more'}
						className='feedback-input styled-scroll body-text-1 ff-chatbot-body2'

						id="FeedbackInputBox"
						role="textbox"
						contentEditable={true}
						onInput={(ev: any) => {
							let data = ev.target.textContent;
							data = data.replace(/ {2,}/g, ' ').trim();
							setFeedbackData(data);
						}}
					> */}
						 <Input
                         placeholder='Please tell us more'
                        fullWidth
                        type='text'
                        value={feedbackData}
                        onChange={(event:any) => {
							setFeedbackData(event.target.value);
                        }}
                        autoComplete='off'
                        className='helpbot-report-input-box'
                    />

					{/* </></div> */}
				</>
			</ModalPane>
		</FEEDBACK >
	);
}
