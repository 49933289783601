import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import Button from '../../../../Components/Button';
import Dropdown, {
	DropdownValue,
	DropdownValueProps,
} from '../../../../Components/Dropdown';
import Modal from '../../../../Components/Modal';
import { BotBuilderPlus, SearchClose } from '../../../../assets/Icons';
import { commonActionParams } from '../common';
import '../ActionsModal.scss';
import './Handoff.scss';
import Input from '../../../../Components/Input';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	addDisplayAction,
	getDisplayAction,
	resetActionDetails,
} from '../../../../Features/builder/builderSlice';
import {
	DefaultConnection,
	GetAction,
} from '../../../../Features/builder/model';
import { useSelector } from 'react-redux';
import { getIconForNextAction } from '../common';
import { checkUrl, getActionName } from '../../../../utils/utils';
import builderService from '../../../../Features/builder/builderService';
import NextNewAction from '../NextNewAction';
import SnackBar from '../../../../Components/SnackBar';
import { set } from '../../../../Features/message/messageSlice';

type Response = {
	text: string;
	type: string;
};

const HandoffToAgent = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const context: any = useOutletContext();
	const state: any = location.state;
	const taskAction: GetAction = state.action;

	const { actionDetails, botId, tenantId, actions, activeQuery } = useSelector(
		(state: RootState) => state.builder
	);

	const nextActions = actions
		.filter(
			(action) =>
				action.task_ID !== taskAction.taskId &&
				action.taskHierarchyDTO[0] !== taskAction.taskId
		)
		.map((action) => ({
			value: action.task_ID.toString(),
			label: (
				<div className={`actions-label action${action.userResponseTypeID}`}>
					{getIconForNextAction(action.userResponseTypeID)}
					<p>{action.task_name}</p>
				</div>
			),
			labelValue: action.task_name,
		})) as DropdownValueProps[];

	nextActions.push({
		value: 'add new action',
		label: (
			<div className='actions-label new-action'>
				{getIconForNextAction(-1)}
				<p>New Action</p>
			</div>
		),
	});

	const [openNextActionType, setOpenNextActionType] = useState(false);
	const [nextActionTypeId, setNextActionTypeId] = useState(0);
	const [response, setResponse] = useState<Array<Response>>([
		{ text: 'Hey there', type: 'text' },
	]);
	// const [openImageModal, setOpenImageModal] = useState(false);
	// const [currentUrl, setCurrentUrl] = useState('');

	useEffect(() => {
		botId &&
			tenantId &&
			dispatch(
				getDisplayAction({
					botId,
					tenantId,
					taskId: taskAction.taskId,
					workflowId: taskAction.workflowId,
				})
			);

		return () => {
			dispatch(resetActionDetails());
		};
	}, []);

	useEffect(() => {
		const next = actionDetails?.defaultConnection;
		if (next && actions) {
			let action = actions.filter(
				(action) => action.task_ID === next.childtaskId
			)[0];

			if (action) {
				let next_action: DropdownValue = {
					value: next.childtaskId.toString(),
					label: (
						<div className={`actions-label action${action.userResponseTypeID}`}>
							{getIconForNextAction(action.userResponseTypeID)}
							<p>{action.task_name}</p>
						</div>
					),
					labelValue: next.childTaskName ?? '',
				};
				context.setNextAction(next_action);
			}
		}

		actionDetails?.responseElements &&
			actionDetails?.responseElements.length > 0 &&
			setResponse(
				actionDetails?.responseElements.map((res: any) => {
					if (res.responseType === 'SIMPLE') {
						return { text: res.displayResponse, type: 'text' };
					} else if (res.responseType === 'LIST') {
						return { text: res.lists.items[0].resourceURL, type: 'image' };
					}
				})
			);
	}, [actionDetails]);

	useEffect(() => {
		const next = context.nextAction as DropdownValueProps;
		if (next && next.value === 'add new action' && !openNextActionType) {
			setOpenNextActionType(true);
		}
	}, [context.nextAction]);

	// const handleResponseChange = (index: number, txt: string) => {
	// 	setResponse((prev) =>
	// 		prev.map((value, i) =>
	// 			index !== i ? value : { text: txt, type: 'text' }
	// 		)
	// 	);
	// };

	// const handleAddText = () => {
	// 	setResponse((prev) => [...prev, { text: '', type: 'text' }]);
	// };

	// const handleAddImage = () => {
	// 	setOpenImageModal(false);
	// 	if (currentUrl.length > 0) {
	// 		setResponse((prev) => [...prev, { text: currentUrl, type: 'image' }]);
	// 	}
	// 	setCurrentUrl('');
	// };

	// const handleRemoveResponse = (index: number) => {
	// 	setResponse((prev) => prev.filter((res, i) => index !== i));
	// };

	const handleSave = async () => {
		if (actionDetails) {
			if (
				actions.filter(
					(action) =>
						action.task_name === context.actionName &&
						action.task_ID !== actionDetails.taskId
				).length > 0
			) {
				// toast(<SnackBar type='danger'>Action name already exists</SnackBar>, {
				// 	autoClose: 3000,
				// });
				return false;
			}

			let next = context.nextAction as DropdownValueProps;
			let defaultConnection: DefaultConnection = {
				connectionId: 0,
				childtaskId: 0,
				conditionType: '',
				childTaskName: null,
			};

			if (nextActionTypeId !== 0 && botId && tenantId) {
				//call add action
				let position = actionDetails?.taskPosition.split('d');
				let task_position = '3000d4000';
				if (position) {
					task_position = `${parseInt(position[0]) + 110}d${position[1]}`;
				}
				const common = commonActionParams(actionDetails?.workflowId, false);
				const new_task = await builderService.addAction({
					...common,
					previousTaskId: 0,
					botId,
					tenantId,
					userResponseTypeID: nextActionTypeId,
					task_position,
					task_name: getActionName(actions, nextActionTypeId),
				});

				if (new_task.status === 201) {
					defaultConnection.childtaskId = Number(
						new_task.data.data.task.task_ID
					);
					defaultConnection.childTaskName = 'Untitled';
				}
			} else {
				if (next) {
					defaultConnection.childtaskId = Number(next.value);
					defaultConnection.childTaskName = next.labelValue ?? '';
				}
			}

			let responseElements: any = [];

			let responses = response.filter((res) => res.text !== '');

			if (responses.length > 0) {
				responseElements = responses.map((res, index) => {
					if (res.type === 'image') {
						// image
						return {
							actualResponse: '',
							card: {},
							displayResponse: '*$$HandOff-To-Agent$$*',
							lists: {
								items: [
									{
										cardId: null,
										cardLayout: 0,
										collapsed: false,
										description: '',
										externalURL: '',
										imageOrVideoURL: '',
										index: 0,
										multiLingual: {},
										resourceURL: res.text,
										text: '',
										title: 'Title 1',
										type: 'Card',
										value: '',
									},
								],
							},
							multiLingual: {},
							responseId: 0,
							responseIndex: index,
							responseType: 'LIST',
							speechResponse: '',
						};
					} else {
						// text
						return {
							actualResponse: '',
							card: {},
							// displayResponse: res.text,
							displayResponse: '*$$HandOff-To-Agent$$*',
							lists: {},
							multiLingual: {},
							responseId: 0,
							responseIndex: index,
							responseType: 'SIMPLE',
							speechResponse: '',
						};
					}
				});
			} else {
				responseElements = [
					{
						card: null,
						displayResponse: '*$$HandOff-To-Agent$$*',
						lists: null,
						multiLingual: {},
						responseIndex: null,
						responseLayout: null,
						responseType: 'SIMPLE',
						speechResponse: '',
					},
				];
			}

			activeQuery &&
				dispatch(
					addDisplayAction({
						...actionDetails,
						taskName: context.actionName,
						currentNodeId: activeQuery.nodeId,
						defaultConnection,
						responseElements,
						userResponseTypeId: 1,
						userResponseType: 'Display',
						taskDescription: 'HandOff-To-Agent',
					})
				);
		}

		navigate(-1);
	};

	return (
		<>
			<div className='body-content'>
				<div className='action-content'>
					<p className='body2'>
						This Customer Journey ends at this Action and{' '}
					</p>
					<p className='body2'> the conversation is handed off to an Agent.</p>
					{/* 	<div className='left-pane'>
						{response.map((res, index) =>
							res.type === 'text' ? (
								<Input
									fullWidth
									multiline
									placeholder='What should bot say?'
									value={res.text}
									onChange={(event) =>
										handleResponseChange(index, event.target.value)
									}
									key={index}
									className='text-input'
								/>
							) : (
								<div className='image-container' key={index}>
									<div>
										<img src={res.text} alt='' />
									</div>
									<span
										className='clear-btn'
										onClick={() => handleRemoveResponse(index)}>
										<SearchClose width='12px' />
									</span>
								</div>
							)
						)}

						<div className='button-container'>
							<Button
								startIcon={<BotBuilderPlus width='15px' />}
								onClick={handleAddText}>
								Add Text
							</Button>
							<Button
								startIcon={<BotBuilderPlus width='15px' />}
								onClick={() => setOpenImageModal(true)}>
								Add Image
							</Button>
						</div>
					</div>
					<div className='right-pane'>
						<Dropdown
							isSearchable
							label='Next Action'
							value={context.nextAction}
							options={nextActions}
							onChange={context.setNextAction}
							className='next-action-dropdown'
						/>
					</div>*/}
				</div>

				<footer>
					<Button
						disabled={context.actionName.trim().length > 0 ? false : true}
						onClick={handleSave}>
						Save
					</Button>
				</footer>
			</div>

			{/* <NextNewAction
				open={openNextActionType}
				setOpenNextActionType={setOpenNextActionType}
				setNextActionTypeId={setNextActionTypeId}
				onClose={() => {
					setOpenNextActionType(false);
					if (nextActionTypeId === 0) {
						context.setNextAction(null);
					}
				}}
			/> */}

			{/* {openImageModal && (
				<Modal
					header='Image URL'
					action='Done'
					onClose={() => setOpenImageModal(false)}
					actionButtons={
						<>
							<Button
								onClick={() => setOpenImageModal(false)}
								buttonType='light'>
								Cancel
							</Button>
							<Button disabled={!checkUrl(currentUrl)} onClick={handleAddImage}>
								Done
							</Button>
						</>
					}>
					<Input
						placeholder='https://www.example.com/path-to-image'
						label='Paste your URL here'
						value={currentUrl}
						onChange={(event) => setCurrentUrl(event.target.value)}
					/>
				</Modal>
			)} */}
		</>
	);
};
export default HandoffToAgent;
