import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ChatAvatar, ChatAvatarName } from '../ChatAvatarDetails';
import {
	DeliveredMessage,
	IncomingArrow,
	SeenMessage,
	SendingMessage,
	SentMessage,
	WebLink,
	WebsiteLogo,
} from '../Icons';
import { wsAllConversationChats } from '../model';
import { useSelector } from 'react-redux';
import './UserInfo.scss';
import { BOT_ICONS } from '../../CatalogScreen/AddNewBot';
import { loadState, saveState, timeStamp } from '../../../utils/utils';
import { Tooltip } from '@mui/material';
import whatsapp from '../../../assets/images/whatsapp.png';
import instagram from '../../../assets/images/instagram.png';
import { getWholeConversation } from '../../../Features/Analytics/analyticsSlice';
import { RootState, useAppDispatch } from '../../../app/store';
type Props = {
	chat: wsAllConversationChats;
	showChatScreen: React.Dispatch<any>;
	handleChatSelected: Function;
	className: string;
};

const UserInfo = ({
	chat,
	showChatScreen,
	handleChatSelected,
	className,
}: Props) => {
	const location = useLocation();
	const path = location.pathname;
	const dispatch = useAppDispatch();
	const { selectedChat } = useSelector((state: RootState) => state.chats);
	const buttonRef = useRef(document.createElement("div"))
	// const priorityClass = () => {
	// 	let value = chat.priority;
	// 	let type = chat.type;
	// 	if (type && type.indexOf('Queue') > -1) {
	// 		if (value === 0 || (value && value > 0 && value < 2)) {
	// 			return 'waiting-low';
	// 		} else if (value && value >= 2 && value < 5) {
	// 			return 'waiting-medium';
	// 		} else if (value && value >= 5) {
	// 			return 'waiting-high';
	// 		}
	// 	} else {
	// 		if (value && value >= 2) {
	// 			return 'non-waiting-high';
	// 		} else if (value && value >= 1 && value < 2) {
	// 			return 'non-waiting-low';
	// 		}
	// 	}
	// 	return 'no-priority-level';
	// };

	useEffect(() => {
		buttonRef && buttonRef.current.click();
	}, [selectedChat])
	const priorityDot = () => {
		let time, diff;

		if (chat.lastMessage && chat.lastMessage.indexOf('User:') > -1) {
			time = chat.messageTime && timeStamp(chat.messageTime);
			diff = time;
			if (
				(diff && diff.indexOf('hour') > -1) ||
				(diff && diff.indexOf('hours') > -1)
			) {
				return 'priority-high-dot';
			} else if (diff && diff.indexOf('min') > -1) {
				let minuteValue = Number(diff.split(' ')[0]);
				if (minuteValue >= 5) {
					return 'priority-high-dot';
				} else if (minuteValue >= 2 && minuteValue < 5) {
					return 'priority-medium-dot';
				} else if (minuteValue >= 0 && minuteValue < 2) {
					return 'priority-low-dot';
				}
			} else if (
				(diff && diff.indexOf('min') > -1) ||
				(diff && diff.indexOf('sec') > -1) ||
				(diff && diff.indexOf('now') > -1)
			) {
				return 'priority-low-dot';
			}
			return 'priority-high-dot';
		}
	};

	const incomingChannel = () => {
		return (
			<div
				className={`incoming-channel ${chat.channel.toLowerCase()} ${chat.origin && chat.origin.toLowerCase().indexOf('flyfish.ai') > -1
					? 'web-link'
					: 'website'
					}`}>
				<div>
					<IncomingArrow />
				</div>
				<div className='channel-id'>
					<Tooltip
						title={
							chat.channel === 'web'
								? chat.origin &&
									chat.origin.toLowerCase().indexOf('flyfish.ai') > -1
									? 'Web link'
									: 'Website'
								: chat.channel.toLowerCase() === 'instagram'
									? chat.instaBusinessId
									: '+' + chat.custNo
						}>
						<div>
							{chat.channel === 'web'
								? chat.origin &&
									chat.origin.toLowerCase().indexOf('flyfish.ai') > -1
									? 'Web link'
									: 'Website'
								: chat.channel.toLowerCase() === 'instagram'
									? chat.instaBusinessId
									: '+' + chat.custNo}
						</div>
					</Tooltip>
				</div>
				<div className='channel-icon'>
					{chat.channel === 'web' ? (
						chat.origin &&
							chat.origin.toLowerCase().indexOf('flyfish.ai') > -1 ? (
							<WebLink />
						) : (
							<WebsiteLogo />
						)
					) : chat.channel.toLowerCase() === 'instagram' ? (
						<img alt='' src={instagram} />
					) : (
						<img alt='' src={whatsapp} />
					)}
				</div>
			</div>
		);
	};
	return (
		<div
			ref={buttonRef}
			className={`ff-userinfo-hybrid-chats `}
			onClick={() => {
				handleChatSelected(chat);
				showChatScreen('show');
			}}>
			<div
				// path.indexOf('all-chats') > -1 ? priorityClass() : ''

				className={`chat-details ${className}`}>
				<div className='avatar'>
					<span>
						<ChatAvatar size={36} sessionId={chat.conversationId ? chat.conversationId.toString() : '18'} />
					</span>
				</div>
				<div className='details'>
					<div className='avatarname-timestamp'>
						<span className='body1'>
							<ChatAvatarName sessionId={chat.conversationId ? chat.conversationId.toString() : '18'} />
						</span>
						<span className='caption'>
							{/* {chat.time ? timeStamp(chat.time) : null} */}
							{incomingChannel()}
						</span>
					</div>
					<div className='status-lastmessage'>
						<div>
							<span>
								{chat.status === 'Sending' ? (
									<SendingMessage />
								) : chat.status === 'Delivered' ? (
									<DeliveredMessage />
								) : chat.status === 'Seen' ? (
									<SeenMessage />
								) : (
									<SentMessage />
								)}
							</span>
							<span className='body2 text-flow'>
								{chat.lastMessage}
								{/* // ? chat.lastMessage.startsWith('CA:')
									// 	? chat.lastMessage.replace('CA:', 'IA:')
									// 	: chat.lastMessage.replace('VSR:', 'MS:')
									// : 
								// null
								 */}
							</span>
						</div>
						{/* <div
							className={`dot-priority ${
								chat.lastMessage && chat.lastMessage.indexOf('You:') > -1
									? 'remove-dot-priority'
									: priorityDot()
							}`}></div> */}
					</div>
					<div className='icon-details-holder'>
						<span className='icon-pair-2'>
							{chat.botName ? (
								<div className='bot-name'>
									<span>
										<img
											src={
												chat.botIconURL
													? chat.botIconURL
													: BOT_ICONS[
													Number(chat.botId ? chat.botId : '11') %
													BOT_ICONS.length
													]
											}
											alt=''
											className='bot-icon'
										/>
									</span>
									{/* <span className='caption'>
										{chat.botType === 'CA' ? 'IA' : 'MS'}:
									</span> */}
									<Tooltip title={chat.botName}>
										<span className='caption text-flow'>{chat.botName}</span>
									</Tooltip>
								</div>
							) : null}
						</span>
						{/* <span className='icon-pair-1'>
							<span className='caption'>
								{chat.messageTime ? timeStamp(chat.messageTime) : null}
							</span>
						</span> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserInfo;
