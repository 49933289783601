import { CarouselDiv } from '../../../../styles/Appearance/Body/Carousel/carousel.styled';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { CarouselOption } from './model';
import { CarouselButton } from '../../../../styles/Appearance/GCBContainer/CarouselButton/carouselbutton.styled';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { useState } from 'react';
type Props = {
	cards: CarouselOption[];
};

export const carouselButton = (props: any) => {
	return (
		<CarouselButton
			xmlns='http://www.w3.org/2000/svg'
			width='64'
			height='64'
			viewBox='0 0 64 64'
			fill='none'
			{...props}>
			<g filter='url(#k2zpbunjva)'>
				<g opacity='.8'>
					<path
						className='circle-bck-color'
						d='M32 52c13.255 0 24-10.745 24-24S45.255 4 32 4 8 14.745 8 28s10.745 24 24 24z'
						fill='#fff'
					/>
					<path
						d='M32 51.5c12.979 0 23.5-10.521 23.5-23.5S44.979 4.5 32 4.5 8.5 15.021 8.5 28 19.021 51.5 32 51.5z'
						stroke='#000'
						strokeOpacity='.1'
					/>
				</g>
			</g>
			<path
				className='circle-icon-color'
				d='m29.333 20-1.88 1.88 6.11 6.12-6.11 6.12 1.88 1.88 8-8-8-8z'
				fill='#EF4077'
			/>
			<defs>
				<filter
					id='k2zpbunjva'
					x='0'
					y='0'
					width='64'
					height='64'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='4' />
					<feGaussianBlur stdDeviation='4' />
					<feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0' />
					<feBlend
						in2='BackgroundImageFix'
						result='effect1_dropShadow_1604_142978'
					/>
					<feBlend
						in='SourceGraphic'
						in2='effect1_dropShadow_1604_142978'
						result='shape'
					/>
				</filter>
			</defs>
		</CarouselButton>
	);
};

const CarouselComp = ({ cards }: Props) => {
	const {
		botMessageBubbleColor,
		botMessageTextColor,
		buttonTextColor,
		buttonBckColor,
	} = useSelector((state: RootState) => state.configure);
	const [clickedNext, setClickedNext] = useState(false);
	const nextValue = ['100', '205', '300'];
	const prevValue = ['0', '100', '200'];
	const [nextIndex, setNextIndex] = useState(-1);
	const [prevIndex, setPrevIndex] = useState(0);

	return (
		<CarouselDiv
			botMessageBubbleColor={botMessageBubbleColor}
			botMessageTextColor={botMessageTextColor}
			buttonBckColor={buttonBckColor}
			buttonTextColor={buttonTextColor}
			value={clickedNext ? nextValue[nextIndex] : prevValue[prevIndex]}
			data-clicked={clickedNext ? 'next' : 'prev'}
			data-value={[
				'nextValue',
				nextValue[nextIndex],
				'nextIndex',
				nextIndex,
				'prevValue',
				prevValue[prevIndex],
				'prevIndex',
				prevIndex,
			]}
			className={`${clickedNext ? 'prev-clicked' : 'next-clicked'}`}>
			<Carousel
				centerMode
				centerSlidePercentage={90}
				showArrows={true}
				showIndicators
				showStatus={false}
				showThumbs={false}
				selectedItem={0}
				renderArrowPrev={(clickHandler, hasPrev) =>
					hasPrev && (
						<div
							onClick={() => {
								setClickedNext(false);
								setNextIndex((nextIndex) => nextIndex - 1);
								setPrevIndex((prevIndex) => prevIndex - 1);
								clickHandler();
							}}>
							{carouselButton({
								rotate: true,
								buttonBckColor: buttonBckColor,
								buttonTextColor: buttonTextColor,
							})}
						</div>
					)
				}
				renderArrowNext={(clickHandler, hasNext) =>
					hasNext && (
						<div
							onClick={() => {
								setClickedNext(true);
								setNextIndex((nextIndex) => nextIndex + 1);
								setPrevIndex((prevIndex) => prevIndex + 1);
								clickHandler();
							}}>
							{carouselButton({
								buttonBckColor: buttonBckColor,
								buttonTextColor: buttonTextColor,
							})}
						</div>
					)
				}
				autoFocus>
				{cards.map((card) => {
					return (
						<div className='carousel-slider-list-item' key={card.title}>
							<img src={card.image} alt='card' />
							<div>
								<p className='body2 card-title'>{card.title}</p>
								<p className='caption card-desc'>{card.description}</p>
							</div>
						</div>
					);
				})}
			</Carousel>
		</CarouselDiv>
	);
};

export default CarouselComp;
