import {
	TableContainer,
	Table as T,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
} from '@mui/material';
import { FFLoader } from '../../assets/Icons/Loader';
import './Table.scss';
import { useState } from 'react';

type Column = {
	key: string;
	title: string;
	className?: string;
	width?: number;
	render?: (arg: any, i?: any) => JSX.Element;
};

type TableProps = {
	onRowClick?: (arg0: any) => void;
	isLoading: boolean;
	column: Column[];
	data: any[];
	page: number;
	limit: number;
	totalCount: number;
	height?: number | string;
	pagination?: JSX.Element;
	buttons?: JSX.Element;
	filters?: JSX.Element;
	search?: JSX.Element;
	analyticsRowClick?: boolean;
};

const Table = ({
	onRowClick,
	height,
	isLoading,
	column,
	data,
	pagination,
	buttons,
	filters,
	search,
	analyticsRowClick,
}: TableProps) => {
	// console.log('table', data)
	const [clickedRow, setClickRow] = useState(0);

	return (
		<div className='ff-table'>
			<div className='table-header'>
				<div className='left-container'>{pagination}</div>
				<div className='right-container'>
					{buttons && <div className='table-buttons'>{buttons}</div>}
					{filters && <div className='table-filters'>{filters}</div>}
					{search && <div className='table-search'>{search}</div>}
				</div>
			</div>
			<TableContainer sx={{ maxHeight: height }}>
				<T stickyHeader>
					<TableHead>
						<TableRow>
							{column.map((head) => (
								<TableCell className={head.className} key={head.key}>
									<p className='body2 head'>{head.title}</p>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow key='loading'>
								<TableCell colSpan={column.length}>
									<div className='table-loader' style={{ height: height }}>
										<div className='icon'>
											<FFLoader width='24' height='24' />
										</div>
										<p className='body1'>Loading...</p>
									</div>
								</TableCell>
							</TableRow>
						) : (
							// <>
							// 	{data && data.length > 0 ? (

							<>
								{data && data.length > 0 ? (
									<>
										{data.map((row, i) => (
											<TableRow
												key={i.toString()}
												className={
													onRowClick
														? ` ${
																analyticsRowClick && clickedRow === i
																	? 'analytics-row-click'
																	: ''
														  } hover`
														: ''
												}
												onClick={() => {
													onRowClick && onRowClick(row);
													setClickRow(i);
												}}>
												{column.map((col) => {
													return (
														<TableCell sx={{ maxWidth: col?.width }}>
															{col.render ? (
																col.render(row, i)
															) : (
																<p className='body1'>{row[col.key]}</p>
															)}
														</TableCell>
													);
												})}
											</TableRow>
										))}
									</>
								) : (
									<TableRow key='loading'>
										<TableCell colSpan={column.length}>
											<div className='table-no-data-found'>
												<p className='body1'>No data found</p>
											</div>
										</TableCell>
									</TableRow>
								)}
							</>
							// 	) : (
							// 		<TableRow key='loading'>
							// 			<TableCell colSpan={column.length}>
							// 				<div className='table-no-data-found' style={{ height: height }}>

							// 					<p className='body1'>No data found</p>
							// 				</div>
							// 			</TableCell>
							// 		</TableRow>
							// 	)}
							// </>
						)}
					</TableBody>
				</T>
			</TableContainer>
		</div>
	);
};
export default Table;
