import { Carousel } from 'react-responsive-carousel';
import './MultiChoice.scss';
import Options from '../Options';
import Cards from '../Cards';
import { getMediaType } from '../../../utils/utils';
import { carouselButton } from '../../../Pages/Configure/Appearance/GenericChatBot/CarouselComp';
import { Content } from '../../../Pages/Chats/model';

type Props = {
	isRichElement: boolean;
	lists: { title: string; items: Content[] };
};

const MultiChoice = ({ isRichElement, lists }: Props) => {
	return (
		<div className='ff-multichoice-agent-dashboard'>
			{isRichElement ? (
				<>
					{lists.items.length === 1 ? (
						<div className='single-list-item'>
							<Cards
								cardType='multichoice'
								cardMediaType={getMediaType(lists.items[0].resourceType)}
								title={lists.items[0].title}
								src={lists.items[0].resourceURL}
								alt={lists.items[0].title}
								description={lists.items[0].description}
							/>
						</div>
					) : (
						<Carousel
							centerMode
							centerSlidePercentage={70}
							showArrows={true}
							showIndicators
							showStatus={false}
							showThumbs={false}
							selectedItem={0}
							width='85%'
							renderArrowPrev={(clickHandler, hasPrev) =>
								hasPrev && (
									<div
										onClick={() => {
											clickHandler();
										}}>
										{carouselButton({
											rotate: true,
											buttonBckColor: '#fff',
											buttonTextColor: '#000',
											className: 'multichoice-prev',
										})}
									</div>
								)
							}
							renderArrowNext={(clickHandler, hasNext) =>
								hasNext && (
									<div
										onClick={() => {
											clickHandler();
										}}>
										{carouselButton({
											buttonBckColor: '#fff',
											buttonTextColor: '#000',
											className: 'multichoice-next',
										})}
									</div>
								)
							}
							autoFocus>
							{lists.items.map((card: Content, i: number) => {
								return (
									<div
										className='carousel-slider-list-item'
										key={card.title + i}>
										<Cards
											cardType='multichoice'
											cardMediaType={getMediaType(card.resourceType)}
											title={card.title}
											src={card.resourceURL}
											alt={card.title}
											description={card.description}
										/>
									</div>
								);
							})}
						</Carousel>
					)}
				</>
			) : (
				<div className='multichoice-no-richelement'>
					{lists.items.length > 0 &&
						lists.items.map((item: Content) => {
							return <Options>{item.displayText}</Options>;
						})}
				</div>
			)}
		</div>
	);
};

export default MultiChoice;
