import teamsSlice from '../Features/teams/TeamsSlice';
import botSlice from '../Features/Bots/BotSlice';
import { useDispatch } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { configureStore } from '@reduxjs/toolkit';
import userSlice from '../Features/users/userSlice';
import teamsettingSlice from '../Features/teamsetting/teamsettingSlice';
import authSlice from '../Features/auth/authSlice';
import configureSlice from '../Features/configure/configureSlice';
import ChangePasswordSlice from '../Features/ChangePassword/ChangePassSlice';
import analyticSlice from '../Features/Analytics/analyticsSlice';
import builderSlice from '../Features/builder/builderSlice';
import channelSlice from '../Features/channel/channelSlice';
import deploySlice from '../Features/deploy/deploySlice';
import resetPasswordSlice from '../Features/resetpassword/resetPasswordSlice';

import analyticsSlice from '../Features/WhatsApp-BSP/analytics/analyticsSlice';
import campaignSlice from '../Features/WhatsApp-BSP/campaign/campaignSlice';
import contactSlice from '../Features/WhatsApp-BSP/contact/contactSlice';
import messageSlice from '../Features/message/messageSlice';
import templateSlice from '../Features/WhatsApp-BSP/template/templateSlice';
import BSPauthSlice from '../Features/WhatsApp-BSP/auth/authSlice';
import ShopifySlice from '../Features/Shopify/shopifySlice';
import leadSquareSlice from '../Features/LeadSquared/leadsquaredSlice';
import faqSlice from '../Features/FAQs/faqSlice';
import salesSlice from '../Features/SalesAI/salesSlice';
import zohoSlice from '../Features/Zoho/zohoSlice';
import ingestSlice from '../Features/Ingest/IngestSlice';
import ChatWithHumanSlice from '../Features/ChatWithHuman/ChatWithHumanSlice';
import experienceonwebSlice from '../Features/ExperienceOnWeb/experienceonwebSlice';
import launchSlice from '../Features/Launch/launchSlice';
import subscriptionSlice from '../Features/Subscription/subscriptionSlice';
import verticalizaztionSlice from '../Features/Verticalization/verticalizationSlice';
import helpbotSlice from '../Features/HelpBot/helpbotSlice';
import fullScreenSlice from '../Features/FullScreenConfig/fullScreenSlice';
import notificationSlice from '../Features/Notifications/notificationSlice';
import chatSlice from '../Features/chats/ChatSlice';
export const store = configureStore({
	reducer: {
		analytics: analyticSlice,
		teams: teamsSlice,
		fullScreen: fullScreenSlice,
		verticalization: verticalizaztionSlice,
		ChatWithHuman: ChatWithHumanSlice,
		bots: botSlice,
		ingest: ingestSlice,
		auth: authSlice,
		builder: builderSlice,
		changePassword: ChangePasswordSlice,
		user: userSlice,
		teamsetting: teamsettingSlice,
		configure: configureSlice,
		channel: channelSlice,
		deploy: deploySlice,
		resetPassword: resetPasswordSlice,
		contact: contactSlice,
		analyticsBSP: analyticsSlice,
		campaign: campaignSlice,
		template: templateSlice,
		message: messageSlice,
		BSPauth: BSPauthSlice,
		shopify: ShopifySlice,
		leadsquared: leadSquareSlice,
		faq: faqSlice,
		sales: salesSlice,
		zoho: zohoSlice,
		experienceonweb: experienceonwebSlice,
		launch: launchSlice,
		subscription: subscriptionSlice,
		helpBot: helpbotSlice,
		notification: notificationSlice,
		chats: chatSlice
	},
});
setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
