import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import Input from '../../../../Components/Input';
import {
	setCCCButtonText,
	setCCCPhoneNumber,
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import './CustomerCare.scss';
const CallCustomerCare = () => {
	const { callcustomercareButtonText, callcustomercarePhonenumber } =
		useSelector((state: RootState) => state.experienceonweb);
	const dispatch = useAppDispatch();
	return (
		<div className='call-customer-container'>
			<h4>Call Customer Care</h4>
			<div className='call-customer-wrapper'>
				<p className='call-heading body1'>
					An option to place a call to customer care will be presented to the
					user.
				</p>

				<div className='call-body'>
					<h6>Button text</h6>
					<p className='body1'>
						Provide a custom text for this action to be shown during the
						conversation.
					</p>
					<Input
						value={callcustomercareButtonText}
						fullWidth
						className='ff_input_field'
						placeholder='Call Customer Care'
						onChange={(e) => dispatch(setCCCButtonText(e.target.value))}
						autoFocus
					/>
				</div>
				<hr />
				<div className='call-body'>
					<h6>Phone Number</h6>
					<p className='body1'>
						Enter the phone number that should be shared with the user
					</p>
					<Input
						value={callcustomercarePhonenumber}
						fullWidth
						className='ff_input_field'
						placeholder='Enter phone number'
						onChange={(e) => dispatch(setCCCPhoneNumber(e.target.value))}
					/>
				</div>
			</div>
		</div>
	);
};
export default CallCustomerCare;
