import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import analyticsService from './analyticsService';

interface ListObj {
	name: string;
	value: number;
}

type AnalyticsData = {
	reload?: boolean;
	messages_by_type?: ListObj[];
	Business_initiated_messages_by_type?: ListObj[];
	Business_initiated_by_type?: ListObj[];
	active_users?: number | string;
	no_of_messages?: number | string;
};

type State = {
	analyticsData: AnalyticsData;
	isLoading: boolean;
	error: string;
	detailssSearchedValue: string;
	isdownloadSuccess: boolean;
};

export type GetData = {
	startTime: string;
	endTime: string;
	wabaNo: string;
};

export type DeliveryDumpRequest = {
	campaignId: any;
	wabaNo: string;
};

export const getAnalyticsData = createAsyncThunk<
	AnalyticsData,
	GetData,
	{ rejectValue: string }
>('analytics/getAnalyticsData', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticsService.getData(data);
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});
export const getCampaignReport = createAsyncThunk<
	any,
	DeliveryDumpRequest,
	{ rejectValue: string }
>('analytics/getCampaignReport', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticsService.getCampaignReport(data);
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

const initialState: State = {
	analyticsData: {},
	isLoading: false,
	error: '',
	detailssSearchedValue: '',
	isdownloadSuccess: false,
};

const analyticsSlice = createSlice({
	name: 'analytics',
	initialState,
	reducers: {
		reset: (state) => {
			state.analyticsData = {};
			state.isLoading = false;
			state.error = '';
		},

		setDetailsSearchedValue: (state, action) => {
			state.detailssSearchedValue = action.payload;
		},
		setDownloadSucces: (state, action) => {
			state.isdownloadSuccess = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAnalyticsData.pending, (state) => {
				state.isLoading = true;
				state.error = '';
			})
			.addCase(getAnalyticsData.fulfilled, (state: State, action) => {
				state.analyticsData = action.payload;
				state.isLoading = false;
			})
			.addCase(getAnalyticsData.rejected, (state, action) => {
				state.analyticsData = {};
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
					if (error.error === 'Network error') {
						state.isLoading = true;
					}
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			});
	},
});

export const { reset, setDetailsSearchedValue, setDownloadSucces } =
	analyticsSlice.actions;

export default analyticsSlice.reducer;
