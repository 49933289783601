import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../app/store';
import { TestBotClose } from '../../assets/Icons';
import { WebsiteIcon } from '../../assets/images/cards/Logos';
import Button from '../../Components/Button';
import InteractiveButton from '../../Components/Button/InteractiveButton';
import Dropdown, { DropdownValue } from '../../Components/Dropdown';
import Header2 from '../../Components/Header2';
import {
	setRefreshBot,
	setTestAndOpenBot,
	trainBot,
} from '../../Features/builder/builderSlice';
import { set } from '../../Features/message/messageSlice';
import { loadState, removeState, saveState } from '../../utils/utils';
import ChatBot from '../BotBuilder/ChatBot';
import { RefreshIcon, TestIcon, CloseIcon } from '../BotBuilder/ChatBot/Icons';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LanguageIcon from '@mui/icons-material/Language';
import './BotDesigner.scss';

const BotDesigner = () => {
	const { type } = useParams();
	const dispatch = useAppDispatch();
	const location = useLocation();
	// const [test, setTest] = useState(false);
	const botDetails = loadState('botDetails');
	const { activeBot } = useSelector((state: RootState) => state.bots);
	const { botAvatar } = useSelector((state: RootState) => state.configure);
	const { name, botId, trainingStatus, testAndOpenBot, isTraining } =
		useSelector((state: RootState) => state.builder);

	useEffect(() => {
		removeState('channel');
	}, []);

	const path = location.pathname;
	const options = [
		{
			label: (
				<div className='dropdown-label'>
					<LanguageIcon fontSize='small' />
					<p>Website</p>
				</div>
			),
			value: 'Web',
		},
		{
			label: (
				<div className='dropdown-label'>
					<WhatsAppIcon fontSize='small' />
					<p>Whatsapp</p>
				</div>
			),
			value: 'whatsapp',
		},
	];
	const [selectedValue, setSelectedValue] = useState<DropdownValue | any>(
		options[0]
	);
	useEffect(() => {
		dispatch(setTestAndOpenBot(false));
	}, []);
	useEffect(() => {
		if (trainingStatus === false) {
			dispatch(set('Training failed'));
		}
	}, [trainingStatus]);

	useEffect(() => {
		localStorage.setItem('channel', selectedValue.value);
		dispatch(setRefreshBot(false));
		botId && dispatch(trainBot({ botId }));
	}, [selectedValue]);

	const handleOnChange = (option: any) => {
		setSelectedValue(option);
	};

	const getActionButtons = () => {
		return (
			<>
				{!testAndOpenBot ? (
					<Button
						buttonType='light'
						startIcon={<TestIcon />}
						className='bot-train-btn'
						onClick={() => {
							if (!isTraining) {
								botId && dispatch(trainBot({ botId }));
							}
							dispatch(setTestAndOpenBot(true));
						}}>
						Test
					</Button>
				) : (
					<div className='test-train-wrapper'>
						{path.indexOf('appearance') > -1 && (
							<h6 style={{ marginLeft: '16px' }}>{`Test Your ${
								path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'
							}`}</h6>
						)}
						<Dropdown
							value={selectedValue}
							onChange={(option) => handleOnChange(option)}
							options={options}
							className='bot-dropdown'
						/>
						<div className='refresh-close'>
							<div
								className={`bot-train `}
								onClick={() => {
									if (!isTraining) {
										botId && dispatch(trainBot({ botId }));
										dispatch(setRefreshBot(true));
										// @ts-ignore
										if (window.clearSession) {
											// @ts-ignore
											window.clearSession();
										}
									}
								}}>
								<InteractiveButton
									title='Refresh'
									disabled={isTraining}
									icon={<RefreshIcon opacity='0.6' />}
								/>
							</div>
							<div className='close-btn'>
								<InteractiveButton
									title='Close'
									onClick={() => {
										dispatch(setTestAndOpenBot(false));
										dispatch(setRefreshBot(false));
									}}
									icon={<CloseIcon />}
								/>
							</div>
						</div>
					</div>
				)}
			</>
		);
		// }
		// return <></>;
	};

	return (
		<div className='ff-bot-designer'>
			<Header2
				leftComponent={
					<div className='ff-bot-info'>
						<div className='bot-avatar'>
							<img src={botAvatar ? botAvatar : botDetails.botIcon} alt='' />
						</div>
						<Tooltip title={name}>
							<h6 className='bot-name'>{name}</h6>
						</Tooltip>
					</div>
				}
				breadcrumbs={[
					{
						title: 'Customer Journeys',
						path: `app/catalog/${type}/design/build`,
					},
					{
						title: 'Configuration',
						path: `app/catalog/${type}/design/configuration`,
					},
					{
						title: 'Deploy',
						path: `app/catalog/${type}/design/deploy`,
					},
				]}
				activeButtons={getActionButtons()}
			/>
			<Outlet />
			{/* <ChatBot
				active={testAndOpenBot}
				encBotId={activeBot.encBotId}
				onClose={() => {
					dispatch(setTestAndOpenBot(false));
				}}
			/> */}
		</div>
	);
};
export default BotDesigner;
