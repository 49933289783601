export const SearchIcon = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			{...props}>
			<path d='M0 0h24v24H0z' fill='none' />
			<path
				d='M15.5 14h-.79l-.28-.27a6.51 6.51 0 1 0-.7.7l.27.28v.79l5 4.99L20.49 19zm-6 0A4.5 4.5 0 1 1 14 9.5 4.494 4.494 0 0 1 9.5 14z'
				fill='currentColor'
			/>
		</svg>
	);
};

export const BackArrowFullScreen = (props: any) => {
	return (
		<svg
			{...props}
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='32'
			viewBox='0 0 14 32'>
			<path
				data-name='Path 7'
				d='M0 0h14v32H0z'
				transform='rotate(180 7 16)'
				fill='none'
			/>
			<path
				data-name='Path 8'
				d='M10.47 6 8.59 7.88 14.7 14l-6.11 6.12L10.47 22l8-8z'
				transform='rotate(180 10.235 15)'
				fill='rgba(0,0,0,.6)'
			/>
		</svg>
	);
};

export const StarFilled = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} {...props}>
			<path
				data-name='Rectangle 17511'
				style={{
					fill: 'none',
				}}
				d='M0 0h20v20H0z'
			/>
			<path
				data-name='Path 19983'
				d='M12.358 8.667 10.333 2 8.308 8.667H2l5.15 3.675-1.958 6.325 5.142-3.908 5.15 3.908-1.958-6.325 5.142-3.675z'
				transform='translate(-.333 -.333)'
				style={{
					fill: '#edb705',
				}}
			/>
			<g
				data-name='Path 19992'
				style={{
					fill: 'none',
				}}>
				<path
					d='M12.358 8.667 10.333 2 8.308 8.667H2l5.15 3.675-1.958 6.325 5.142-3.908 5.15 3.908-1.958-6.325 5.142-3.675z'
					style={{
						stroke: 'none',
					}}
					transform='translate(-.334 -.333)'
				/>
				<path
					d='M10.333 3.72 8.68 9.167H3.561l4.173 2.977-1.604 5.181 4.203-3.195 4.212 3.197-1.604-5.182 4.166-2.978h-5.12L10.334 3.72m0-1.72 2.025 6.667h6.309l-5.142 3.675 1.958 6.325-5.15-3.909-5.141 3.909 1.958-6.325L2 8.667h6.308L10.333 2z'
					style={{
						fill: '#ba8f00',
						stroke: 'none',
					}}
					transform='translate(-.334 -.333)'
				/>
			</g>
		</svg>
	);
};
export const StarUnFilled = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} {...props}>
			<path
				data-name='Rectangle 17511'
				style={{
					fill: 'none',
				}}
				d='M0 0h20v20H0z'
			/>
			<path
				data-name='Path 19983'
				d='M10.333 2 8.308 8.667H2l5.15 3.675-1.958 6.325 5.142-3.908z'
				transform='translate(-.333 -.333)'
				style={{
					fill: '#edb705',
				}}
			/>
			<g
				data-name='Path 19993'
				style={{
					fill: 'none',
				}}>
				<path
					d='M12.358 8.667 10.333 2 8.308 8.667H2l5.15 3.675-1.958 6.325 5.142-3.908 5.15 3.908-1.958-6.325 5.142-3.675z'
					style={{
						stroke: 'none',
					}}
					transform='translate(-.332 -.333)'
				/>
				<path
					d='M10.333 3.72 8.68 9.167H3.561l4.173 2.977-1.604 5.181 4.203-3.195 4.212 3.197-1.604-5.182 4.166-2.978h-5.12L10.334 3.72m0-1.72 2.025 6.667h6.309l-5.142 3.675 1.958 6.325-5.15-3.909-5.141 3.909 1.958-6.325L2 8.667h6.308L10.333 2z'
					style={{
						fill: '#ba8f00',
						stroke: 'none',
					}}
					transform='translate(-.332 -.333)'
				/>
			</g>
		</svg>
	);
};
export const CheckMarkCircle = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			viewBox='0 0 12 12'
			{...props}>
			<path d='M0 0h12v12H0z' fill='none' />
			<path
				d='M7 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zM6 9.5 3.5 7l.7-.7L6 8.085l3.8-3.8.7.71z'
				transform='translate(-1 -1)'
				fill='currentColor'
			/>
		</svg>
	);
};

export const CloseCircle = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			{...props}>
			<path
				d='M10.393 6 8.667 7.727 6.94 6 6 6.94l1.727 1.727L6 10.393l.94.94 1.727-1.726 1.727 1.727.94-.94-1.727-1.727 1.726-1.727zM8.667 2a6.667 6.667 0 1 0 6.667 6.667A6.661 6.661 0 0 0 8.667 2zm0 12A5.333 5.333 0 1 1 14 8.667 5.34 5.34 0 0 1 8.667 14z'
				transform='translate(-.667 -.667)'
				fill='currentColor'
			/>
		</svg>
	);
};

export const Download = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			{...props}>
			<path d='M24 24H0V0h24z' fill='none' />
			<g>
				<path d='M5 20h14v-2H5zM19 9h-4V3H9v6H5l7 7z' fill='currentColor' />
			</g>
		</svg>
	);
};

export const Active = (props: any) => {
	return (
		<svg
			data-name='check_circle_black_24dp (7)'
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			viewBox='0 0 12 12'>
			<path data-name='Path 13843' d='M0 0h12v12H0z' fill='none' />
			<path
				data-name='Path 13844'
				d='M7 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zM6 9.5 3.5 7l.7-.7L6 8.085l3.8-3.8.7.71z'
				transform='translate(-1 -1)'
				fill={props.hovered ? '#fff' : ' #6746d3'}
			/>
		</svg>
	);
};

export const Website = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='48'
			height='48'
			viewBox='0 0 48 48'>
			<g data-name='Group 32581' opacity='.4'>
				<path
					data-name='Path 14904'
					d='M23.976 0A24 24 0 1 0 48 24 23.988 23.988 0 0 0 23.976 0zm16.632 14.4h-7.08a37.558 37.558 0 0 0-3.312-8.544A19.271 19.271 0 0 1 40.608 14.4zM24 4.9a33.808 33.808 0 0 1 4.584 9.5h-9.168A33.808 33.808 0 0 1 24 4.9zM5.424 28.8A19.78 19.78 0 0 1 4.8 24a19.781 19.781 0 0 1 .624-4.8h8.112A39.637 39.637 0 0 0 13.2 24a39.637 39.637 0 0 0 .336 4.8zm1.968 4.8h7.08a37.558 37.558 0 0 0 3.312 8.544A19.168 19.168 0 0 1 7.392 33.6zm7.08-19.2h-7.08a19.168 19.168 0 0 1 10.392-8.544 37.558 37.558 0 0 0-3.312 8.544zM24 43.1a33.808 33.808 0 0 1-4.584-9.5h9.168A33.808 33.808 0 0 1 24 43.1zm5.616-14.3H18.384A35.31 35.31 0 0 1 18 24a35 35 0 0 1 .384-4.8h11.232A35 35 0 0 1 30 24a35.311 35.311 0 0 1-.384 4.8zm.6 13.344a37.558 37.558 0 0 0 3.312-8.544h7.08a19.271 19.271 0 0 1-10.392 8.544zM34.464 28.8A39.636 39.636 0 0 0 34.8 24a39.637 39.637 0 0 0-.336-4.8h8.112A19.78 19.78 0 0 1 43.2 24a19.78 19.78 0 0 1-.624 4.8z'
					fill='#fff'
				/>
			</g>
		</svg>
	);
};
export const WhatsApp = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={46} height={46} {...props}>
			<defs>
				<clipPath id='a'>
					<circle
						data-name='Ellipse 773'
						cx={23}
						cy={23}
						r={23}
						style={{
							fill: 'none',
						}}
					/>
				</clipPath>
				<clipPath id='c'>
					<path
						data-name='Rectangle 17124'
						style={{
							fill: 'none',
						}}
						d='M0 0h46v46H0z'
					/>
				</clipPath>
				<linearGradient
					id='b'
					y1={1}
					x2={0.007}
					y2={1}
					gradientUnits='objectBoundingBox'>
					<stop offset={0} stopColor='#78cd51' />
					<stop offset={0.616} stopColor='#95ef76' />
					<stop offset={1} stopColor='#4cc328' />
				</linearGradient>
			</defs>
			<g data-name='Group 33682'>
				<g data-name='Group 33683'>
					<g
						data-name='Group 33682'
						transform='translate(0 .001)'
						style={{
							clipPath: 'url(#a)',
						}}>
						<path
							data-name='Rectangle 17123'
							style={{
								fill: 'url(#b)',
							}}
							d='M0 0h46v46H0z'
						/>
					</g>
				</g>
				<g data-name='Group 33685'>
					<g
						data-name='Group 33684'
						transform='translate(0 .001)'
						style={{
							clipPath: 'url(#c)',
						}}>
						<path
							data-name='Path 19887'
							d='M51.669 27.549a14.712 14.712 0 0 0-25.046 10.222A14.284 14.284 0 0 0 28.581 45L26.5 52.5l7.776-2.012a14.818 14.818 0 0 0 7.01 1.761h.006a14.589 14.589 0 0 0 14.669-14.467 14.277 14.277 0 0 0-4.292-10.233M41.292 49.8h-.005a12.308 12.308 0 0 1-6.205-1.676l-.445-.261-4.614 1.194 1.232-4.436-.29-.455a11.874 11.874 0 0 1-1.864-6.4 12.228 12.228 0 0 1 20.817-8.5 11.867 11.867 0 0 1 3.568 8.506A12.126 12.126 0 0 1 41.292 49.8m6.688-9c-.367-.181-2.168-1.056-2.5-1.176s-.58-.181-.825.181-.947 1.176-1.161 1.417-.428.271-.794.09a10.032 10.032 0 0 1-2.947-1.794 10.917 10.917 0 0 1-2.039-2.5c-.214-.362-.023-.558.161-.738.165-.162.367-.422.55-.633a2.457 2.457 0 0 0 .366-.6.657.657 0 0 0-.03-.633c-.091-.181-.825-1.96-1.13-2.684-.3-.7-.6-.61-.825-.62s-.458-.013-.7-.013a1.355 1.355 0 0 0-.977.453 4.029 4.029 0 0 0-1.283 3.015 6.983 6.983 0 0 0 1.5 3.739 16.252 16.252 0 0 0 6.254 5.451 21.411 21.411 0 0 0 2.089.762 5.09 5.09 0 0 0 2.309.143 3.773 3.773 0 0 0 2.474-1.718 2.979 2.979 0 0 0 .214-1.719c-.091-.151-.336-.241-.7-.422'
							transform='translate(-18.152 -14.955)'
							style={{
								fill: '#fff',
								fillRule: 'evenodd',
							}}
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
export const Instagram = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='48'
			height='48'
			viewBox='0 0 48 48'>
			<g data-name='Group 32576'>
				<g data-name='Group 32559'>
					<g data-name='Group 30978'>
						<path
							data-name='Path 14754'
							d='M21.082 9.111a2.927 2.927 0 1 0 2.927 2.927 2.927 2.927 0 0 0-2.927-2.927'
							transform='translate(15.554 -.585)'
							fill='#fff'
						/>
						<path
							data-name='Path 14755'
							d='M22.767 10.4a12.3 12.3 0 1 0 12.3 12.3 12.311 12.311 0 0 0-12.3-12.3m0 20.173a7.877 7.877 0 1 1 7.876-7.873 7.885 7.885 0 0 1-7.876 7.876'
							transform='translate(1.42 1.305)'
							fill='#fff'
						/>
						<path
							data-name='Path 14756'
							d='M39.628 54.049H20.466A14.434 14.434 0 0 1 6.05 39.63V20.465A14.433 14.433 0 0 1 20.466 6.049h19.162a14.434 14.434 0 0 1 14.419 14.416V39.63a14.434 14.434 0 0 1-14.419 14.419M20.466 10.565a9.913 9.913 0 0 0-9.9 9.9V39.63a9.913 9.913 0 0 0 9.9 9.9h19.162a9.912 9.912 0 0 0 9.9-9.9V20.465a9.911 9.911 0 0 0-9.9-9.9z'
							transform='translate(-6.05 -6.049)'
							fill='#fff'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
export const GoogleBusiness = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='48'
			height='48'
			viewBox='0 0 48 48'>
			<g data-name='Group 32577'>
				<path
					data-name='Path 15578'
					d='M24 0a24 24 0 1 0 24 24A24 24 0 0 0 24 0m16.588 27.551a16.292 16.292 0 0 1-4.71 9.569c-2.722 2.57-6.584 4.121-11.64 4.121a17.286 17.286 0 0 1-15.221-9.489 17.013 17.013 0 0 1 0-15.5 17.285 17.285 0 0 1 15.22-9.488 16.169 16.169 0 0 1 11.6 4.623L31 16.16a9.782 9.782 0 0 0-6.762-2.56 10.068 10.068 0 0 0-9.516 7.126 10.826 10.826 0 0 0 0 6.548 10.069 10.069 0 0 0 9.516 7.126 10.417 10.417 0 0 0 6.031-1.684 9.04 9.04 0 0 0 3.531-5.165h-9.334v-6.387h16.181a19.659 19.659 0 0 1 .2 2.838 23.462 23.462 0 0 1-.262 3.549'
					transform='translate(0 -.002)'
					fill='#fff'
				/>
			</g>
		</svg>
	);
};
export const Tiktok = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='48'
			height='48'
			viewBox='0 0 48 48'>
			<g>
				<path
					d='M84.38 26.265a10.927 10.927 0 0 1-.945-.55 13.286 13.286 0 0 1-2.427-2.062 11.446 11.446 0 0 1-2.735-5.643h.01a6.951 6.951 0 0 1-.108-2.01h-8.244v31.876c0 .428 0 .851-.018 1.269 0 .052-.005.1-.008.156a.344.344 0 0 1 0 .071v.018a7 7 0 0 1-3.522 5.556 6.88 6.88 0 0 1-3.411.9 7 7 0 0 1 0-14 6.889 6.889 0 0 1 2.141.339l.01-8.394a15.313 15.313 0 0 0-11.8 3.452 16.178 16.178 0 0 0-3.53 4.353 15.084 15.084 0 0 0-1.82 6.924 16.341 16.341 0 0 0 .885 5.473v.02a16.111 16.111 0 0 0 2.238 4.082 16.752 16.752 0 0 0 3.571 3.369v-.02l.02.02A15.425 15.425 0 0 0 63.1 64a14.9 14.9 0 0 0 6.246-1.381 15.674 15.674 0 0 0 5.07-3.819 15.845 15.845 0 0 0 2.764-4.593 17.2 17.2 0 0 0 .995-5.253V32.048c.1.06 1.432.941 1.432.941a19.066 19.066 0 0 0 4.913 2.031 28.291 28.291 0 0 0 5.042.69v-8.184a10.69 10.69 0 0 1-5.182-1.261z'
					transform='translate(-44.76 -16)'
					fill='#fff'
					data-name='Group 32582'
				/>
			</g>
		</svg>
	);
};
export const Telegram = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='48'
			height='48'
			viewBox='0 0 48 48'>
			<g>
				<path
					d='M48.657 89.352q19.324-8.392 25.765-11.116c12.257-5.116 14.833-6 16.489-6.036a2.955 2.955 0 0 1 1.73.515 1.948 1.948 0 0 1 .626 1.215 6.361 6.361 0 0 1 .074 1.73c-.663 6.993-3.533 23.961-5.006 31.764-.626 3.313-1.84 4.417-3.018 4.527-2.576.221-4.527-1.693-6.993-3.313-3.9-2.54-6.073-4.122-9.864-6.625-4.38-2.871-1.546-4.454.957-7.03.663-.663 11.962-10.968 12.183-11.889a.876.876 0 0 0-.221-.773 1.014 1.014 0 0 0-.92-.074c-.4.074-6.588 4.2-18.624 12.33a8.293 8.293 0 0 1-4.785 1.767 31.86 31.86 0 0 1-6.883-1.619c-2.76-.883-4.969-1.362-4.785-2.908.11-.809 1.218-1.617 3.275-2.465z'
					fill-rule='evenodd'
					fill='#fff'
					transform='translate(-45.371 -68.2)'
					data-name='Group 32583'
				/>
			</g>
		</svg>
	);
};

export const LineMessenger = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='48'
			height='48'
			viewBox='0 0 48 48'>
			<g data-name='Group 32584'>
				<g data-name='Layer 2'>
					<g data-name='LINE LOGO'>
						<path
							data-name='Subtraction 18'
							d='M22.68 45.736a.8.8 0 0 1-.647-.251 1.761 1.761 0 0 1-.12-1.336l.009-.05.342-2.054a4.655 4.655 0 0 0-.079-2.171c-.264-.659-1.3-1.007-2.114-1.183a25.724 25.724 0 0 1-14.357-6.568 18.973 18.973 0 0 1-4.2-5.8A16.289 16.289 0 0 1 0 19.476C0 8.737 10.766 0 24 0s24 8.737 24 19.476a15.341 15.341 0 0 1-1.268 6.171 20.828 20.828 0 0 1-3.876 5.812A59.656 59.656 0 0 1 32.5 40.2a63.959 63.959 0 0 1-8.5 5.2 3.628 3.628 0 0 1-1.32.336zm-.98-31.375a.45.45 0 0 0-.449.449v10.475a.45.45 0 0 0 .449.45h1.7a.45.45 0 0 0 .449-.45V19l4.8 6.478a.45.45 0 0 0 .117.117l.027.018h.014l.023.012h.069a.5.5 0 0 0 .122.015h1.692a.451.451 0 0 0 .45-.45V14.746a.45.45 0 0 0-.45-.459h-1.7a.451.451 0 0 0-.45.45v6.222l-4.792-6.471a.275.275 0 0 0-.038-.048l-.027-.027-.024-.02h-.014l-.025-.018h-.014l-.024-.014H21.7zM8.331 14.3a.451.451 0 0 0-.45.45v10.472a.451.451 0 0 0 .45.45h6.749l-.007.006a.455.455 0 0 0 .319-.133.446.446 0 0 0 .131-.317v-1.7a.451.451 0 0 0-.45-.45h-4.582v-8.323a.451.451 0 0 0-.45-.45zm24.934 0a.45.45 0 0 0-.45.449v10.479a.451.451 0 0 0 .45.45h6.749a.452.452 0 0 0 .323-.137.444.444 0 0 0 .126-.329v-1.7a.45.45 0 0 0-.449-.45h-4.583v-1.768h4.583a.45.45 0 0 0 .449-.45v-1.719a.45.45 0 0 0-.449-.449h-4.583v-1.767h4.583a.45.45 0 0 0 .449-.449v-1.7a.45.45 0 0 0-.449-.449zm-14.984-.021a1.311 1.311 0 0 0-1.309 1.31v8.773a1.309 1.309 0 0 0 2.618 0V15.6a1.311 1.311 0 0 0-1.309-1.313z'
							transform='translate(0 1)'
							fill='#fff'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export const AppleBusiness = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='48'
			height='48'
			viewBox='0 0 48 48'>
			<defs>
				<clipPath id='y6vkrnk0fa'>
					<path
						data-name='Path 15580'
						d='M11.881 34.7a3.119 3.119 0 0 0 3.041-3.179 1.944 1.944 0 0 0-.018-.227 10.143 10.143 0 0 1-3.654-7.625A10.178 10.178 0 0 1 15 15.948l.038-.019c.282-.242.564-.482.871-.7s.657-.425 1-.624a14.176 14.176 0 0 1 7.1-1.855c7.041 0 12.742 4.882 12.742 10.918s-5.7 10.937-12.741 10.937a14.558 14.558 0 0 1-5.1-.927 6.884 6.884 0 0 1-4.321 1.571 7.057 7.057 0 0 1-2.707-.548M11.25 0A11.25 11.25 0 0 0 0 11.25v25.5A11.25 11.25 0 0 0 11.25 48h25.5A11.25 11.25 0 0 0 48 36.75v-25.5A11.25 11.25 0 0 0 36.75 0z'
						fill='#fff'
					/>
				</clipPath>
			</defs>
			<g data-name='Group 32580'>
				<g data-name='Group 32563'>
					<g data-name='Group 32562' clip-path='url(#y6vkrnk0fa)'>
						<path
							data-name='Rectangle 17082'
							fill='#fff'
							d='M0 0h47.926v47.926H0z'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
export const FaceBook = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='48'
			height='48'
			viewBox='0 0 48 48'>
			<defs>
				<clipPath id='g563ay84xa'>
					<path data-name='Rectangle 17081' fill='none' d='M0 0h48v48H0z' />
				</clipPath>
			</defs>
			<g data-name='Group 32578'>
				<g data-name='Group 32561'>
					<g data-name='Group 32560' clip-path='url(#g563ay84xa)'>
						<path
							data-name='Path 15579'
							d='M23.876 0C10.69 0 0 10 0 22.327a21.772 21.772 0 0 0 8.87 17.366v8.545l8.148-4.52a25.42 25.42 0 0 0 6.858.935c13.186 0 23.876-10 23.876-22.327S37.063 0 23.876 0m2.515 29.94-6.175-6.415L8.323 30.1l13.039-13.835 6.175 6.415 11.892-6.58z'
							transform='translate(.64 -.386)'
							fill='#fff'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
export const MenuButton = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<defs>
				<filter
					id='3xiax6ec9a'
					x='1'
					y='7'
					width='22'
					height='10'
					filterUnits='userSpaceOnUse'>
					<feOffset />
					<feGaussianBlur stdDeviation='1' result='blur' />
					<feFlood flood-opacity='.278' />
					<feComposite operator='in' in2='blur' />
					<feComposite in='SourceGraphic' />
				</filter>
			</defs>
			<g filter='url(#3xiax6ec9a)'>
				<path
					data-name='Path 13834'
					d='M6 10a2 2 0 1 0 2 2 2.006 2.006 0 0 0-2-2zm12 0a2 2 0 1 0 2 2 2.006 2.006 0 0 0-2-2zm-6 0a2 2 0 1 0 2 2 2.006 2.006 0 0 0-2-2z'
					fill='#fff'
				/>
			</g>
		</svg>
	);
};

export const WebLink = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={46} height={46} {...props}>
			<g data-name='Group 30100'>
				<path
					data-name='Path 15586'
					d='M23 0A23 23 0 1 1 0 23 23 23 0 0 1 23 0z'
					style={{
						fill: '#6746d3',
					}}
				/>
				<path
					data-name='Rectangle 17085'
					style={{
						opacity: 0.002,
						fill: '#fff',
					}}
					d='M0 0h17.999v17.999H0z'
					transform='translate(14.5 14)'
				/>
				<g data-name='Group 32557'>
					<path
						data-name='Path 14788'
						d='m243.375 38.624 2.534-2.535a4.742 4.742 0 0 0-6.706-6.705l-3.658 3.658a4.74 4.74 0 0 0 .888 7.4l1.958-1.958a2.134 2.134 0 0 1-1.017-.568 2.158 2.158 0 0 1 0-3.048l3.658-3.658a2.155 2.155 0 0 1 3.048 3.048l-1.075 1.075a5.808 5.808 0 0 1 .37 3.291z'
						transform='translate(-214.797 -13.997)'
						style={{
							fill: '#fff',
						}}
					/>
					<path
						data-name='Path 14789'
						d='m101.92 185.294-2.535 2.535a4.742 4.742 0 0 0 6.706 6.706l3.657-3.658a4.74 4.74 0 0 0-.888-7.4l-1.958 1.958a2.151 2.151 0 0 1 1.017 3.616l-3.658 3.658a2.155 2.155 0 0 1-3.048-3.048l1.075-1.075a5.806 5.806 0 0 1-.369-3.289z'
						transform='translate(-83.498 -163.922)'
						style={{
							fill: '#fff',
						}}
					/>
				</g>
			</g>
		</svg>
	);
};
export const Tick = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'>
			<path data-name='Path 15050' d='M0 0h20v20H0z' fill='none' />
			<path
				data-name='Path 15051'
				d='m8.067 14.433-3.5-3.5L3.4 12.1l4.667 4.667 10-10L16.9 5.6z'
				transform='translate(-.567 -.933)'
				fill='#5531d3'
			/>
		</svg>
	);
};
