import { useState } from 'react';
import { useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Modal from '../../../../Components/Modal';
import {
	getInstaId,
	resetCreateInstaWatsapp,
} from '../../../../Features/WhatsApp-BSP/auth/authSlice';
interface Props {
	setCreateInstaFailed: (arg: boolean) => void;
}
const CreateInstaFailed = ({ setCreateInstaFailed }: Props) => {
	const dispatch = useAppDispatch();
	const [token, setToken] = useState('');

	const fetchBusinessInstaId = (data: any, token: any) => {
		dispatch(getInstaId({ fbresponse: data, accessToken: token }));
		setToken(token);
	};
	return (
		<Modal
			//empty funciton for misbehaving if we click outside modal
			onClose={() => {}}
			header={`Failed to connect your Instagram Account with Flyfish`}
			actionButtons={
				<>
					<Button
						buttonType='light'
						onClick={() => {
							setCreateInstaFailed(false);
							dispatch(resetCreateInstaWatsapp());
						}}>
						Close
					</Button>
					<Button
						onClick={() => {
							dispatch(resetCreateInstaWatsapp());
							window.launchInstagramSignup(fetchBusinessInstaId);
							setCreateInstaFailed(false);
						}}>
						Retry
					</Button>
				</>
			}>
			<div>
				<p className='body1'>
					To add an Instagram account to Flyfish you must have a professional
					Instagram account that is already linked with a Facebook page.
				</p>
				<p className='body1' style={{ marginTop: '16px' }}>
					Please make sure that you have the above and try again.
				</p>
			</div>
		</Modal>
	);
};

export default CreateInstaFailed;
