import DataFetchAPI from '../../utils/Api';
import { GetData, Unlinkdata } from './leadsquaredSlice';

// let crmAPI: any = 'http://3.109.175.118:8082';
// const getCrmAPI = () => {
// 	return crmAPI;
// };
// const getleadSquaredData = async (data: GetData) => {
// 	return getCrmAPI().post('/crm/v1/setup', data);
// };

const getleadSquaredData = async (data: GetData) => {
	return await DataFetchAPI(
		false,
		true,
		'Bearer',
		'application/json',
		false
	).post(
		'/crm/v1/setup',

		data
	);
};

const unlinkleadSquaredData = async (data: Unlinkdata) => {
	return await DataFetchAPI(false, true, 'Bearer', 'application/json').delete(
		`/route/tenant/${data.tenantId}/link/${data.linkedCrmId}`
	);
};

const leadSquareService = {
	getleadSquaredData,
	unlinkleadSquaredData,
};

export default leadSquareService;
