import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Modal from '../../../../Components/Modal';
import {
	wabaInsta,
	WabaNo,
} from '../../../../Features/WhatsApp-BSP/auth/authSlice';
import { set, setSnackModal } from '../../../../Features/message/messageSlice';
import '../Modal.scss';

interface Props {
	Details: wabaInsta | undefined;
	setChat: (arg: boolean) => void;
}
export const ChatLink = ({ Details, setChat }: Props) => {
	const dispatch = useAppDispatch();

	return (
		<Modal
			header={`${Details?.pageName ? 'Instagram' : 'WhatsApp'} Chat Link`}
			actionButtons={
				<>
					<Button
						buttonType='light'
						onClick={() => {
							if (Details?.pageName) {
								window.open(
									`https://ig.me/m/${Details?.instaUsername}`,
									'_blank'
								);
								// setChat(false);
							} else if (!Details?.pageName) {
								window.open(`https://wa.me/${Details?.wabaNo}`, '_blank');
								// setChat(false);
							}
						}}>
						Test now
					</Button>
					<Button
						onClick={() => {
							// setChat(false);
							if (Details?.pageName) {
								navigator.clipboard.writeText(
									`https://ig.me/m/${Details?.instaUsername}`
								);
							} else {
								navigator.clipboard.writeText(
									`https://wa.me/${Details?.wabaNo}`
								);
							}
							dispatch(
								setSnackModal({
									openModal: true,
									modalMesssage: 'Link copied',
								})
							);
						}}>
						Copy link
					</Button>
				</>
			}
			onClose={() => setChat(false)}
			headerCloseBtn>
			<div className='modal-body'>
				<p className='body1'>
					Anyone can share this link to converse with your business on{' '}
					{Details?.pageName ? 'Instagram' : 'WhatsApp'}. Share this link with
					your audience by putting it on your website, in online display ads,
					SMS, QR codes etc. to get them conversing with your Ad on{' '}
					{Details?.pageName ? 'Instagram' : 'WhatsApp'}.
				</p>
				<p className='body1 chat-link'>
					{Details?.pageName
						? `https://ig.me/m/${Details?.instaUsername}`
						: `https://wa.me/${Details?.wabaNo}`}
				</p>
			</div>
		</Modal>
	);
};
