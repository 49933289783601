import React, { useEffect, useState } from 'react';
import './FeedbackConversation.scss';
import AutoComplete from '../../../Components/AutoComplete';
import Table from '../../../Components/Table';
import Pagination from '../../../Components/Table/Pagination';
import {
    getFeedbackConversationalDatas,
    getWholeConversation,
    resetConversationIdStatus,
    setFeedbackSearchedValue,
    setOpenConversation
} from '../../../Features/Analytics/analyticsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import WAChatBubble from '../../SalesAI/Whatsapp/WAChatBubble';
import InteractiveButton from '../../../Components/Button/InteractiveButton';
import { CloseIcon } from '../../BotBuilder/ChatBot/Icons';
import { DownloadAnalyticDatas, DownloadIcon } from '../../../assets/Icons';
import AIIcon from '../../../assets/images/bot-icons/botIcon1.png';
import Dropdown, {
    DropdownValue,
    DropdownValueProps,
} from '../../../Components/Dropdown';
import { getCurrentDateTime, getCurrentTime, loadState } from '../../../utils/utils';
import DataFetchAPI from '../../../utils/Api';
import Input from '../../../Components/Input';

const FeedbackConversationTab = () => {
    const dispatch: any = useDispatch();
    const {
        selectedDates,
        chatFeedbackData,
        analyticConversationData,
        isConversationIdValid,
        FeedbackDataSearchedValue,
        openConversation,
        totalFeedbackCurrentLength,
        totalFeedbackLength
    } = useSelector((state: RootState) => state.analytics);
    const { bot_AI_ID } = useSelector((state: RootState) => state.teams);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [offsetval, setOffsetval] = useState(0);
    const LIMIT = 1;
    const [UpperL, setUpperL] = useState(0);
    const [LowerL, setLowerL] = useState(0);
    // const [openConversation, setOpenConversation] = useState('open');
    const [downloadMedia, setDownloadMedia] = useState('');
    const [clickedData, storeClickedData] = useState('web')
    const botId = loadState('botId');
    const showAnalyticRows = [
        {
            label: 10,
            value: 10,
        },
        {
            label: 8,
            value: 8,
        },
        {
            label: 6,
            value: 6,
        },
    ];
    const [countofRows, setCountOfRows] = useState<DropdownValue | any>(
        showAnalyticRows[0]
    );




    const searchFATableData = (e: React.KeyboardEvent) => {

        if (e.key === 'Enter') {
            dispatch(
                getFeedbackConversationalDatas({
                    startTime: selectedDates[0],
                    endTime: selectedDates[1],
                    botId: bot_AI_ID ? bot_AI_ID : botId,
                    filter: FeedbackDataSearchedValue,
                    page: offset,
                    size: 10
                })
            );
        }
        dispatch(setOpenConversation('close'));

    };


    const getTemplateFormat = () => {
        // DataFetchAPI()
        //     .get(
        //         `/analytics/bot/${bot_AI_ID ? bot_AI_ID : botId}/feedback/download?startTime=${selectedDates[0]}&endTime=${selectedDates[1]}`,
        //         // {
        //         //     responseType: 'blob',
        //         // }
        //     )
        //     .then((response) => {
        //         // return response.data;
        //         if (response.data.status === 'Success') {
        //             debugger;
        //             const url = response.data.fileUrl;

        //             window.open(url)
        //             setDownloadMedia(url);
        //         }
        //     })
        // .then((blob) => {
        //     const url = window.URL.createObjectURL(
        //         new Blob([blob], { type: 'application/octetstream' })
        //     );
        //     setDownloadMedia(url);
        // });
        window.open(`${window.location.origin.indexOf('localhost:3000') > -1
            ? 'https://dev.flyfish.ai'
            : window.location.origin
            }/analytics/bot/${bot_AI_ID ? bot_AI_ID : botId}/feedback/download?startTime=${selectedDates[0]}&endTime=${selectedDates[1]}&page=${offset}&size=${10}`)
    };

    const columnTabData = [
        {
            key: 'Engagement ID',
            title: 'Engagement ID',
            width: 60,
            render: (row: any, i: any) => (
                <p className='body2 index' style={{ color: "color: var(--violet-600);" }}>
                    {row.chat_id ? row.chat_id : '-'}
                </p>
            ),
        },
        {
            key: 'Date & Time',
            title: 'Date & Time',
            width: 120,
            render: (row: any, i: any) => (
                <p className='body2 index'>
                    {row.date_time ? getCurrentDateTime(row.date_time) : '-'}
                </p>
            ),
        },

        {
            key: 'Feedback',
            title: 'Feedback',
            width: 100,
            render: (row: any, i: any) => (
                <p className={`body1 index ${row.feedback && row.feedback.toLowerCase() === 'yes' ? 'booked-data' : 'non-booked-data'}`}>
                    {row.feedback && row.feedback.toLowerCase() === 'yes' ? 'THUMBS UP' : 'THUMBS DOWN'}
                </p>
            ),
        },


        {
            key: 'Comment',
            title: 'Comment',
            width: 100,
            render: (row: any, i: any) => (
                <p className='body1 index'>{row.comment ? row.comment : '-'}</p>
            ),
        },
    ];

    const onchangeCount = (row: DropdownValue) => {
        let rowCount = row as DropdownValueProps;
        // console.log(rowCount)
        setCountOfRows(row);
    };


    ;

    useEffect(() => {

        if (totalFeedbackCurrentLength.length > 0) {
            setUpperL(totalFeedbackCurrentLength.length + offsetval);
            setLowerL(offsetval + 1);
        }
        else {
            setUpperL(0);
            setLowerL(0);
        }

    }, [totalFeedbackCurrentLength]);

    useEffect(() => {
        dispatch(
            getFeedbackConversationalDatas({
                startTime: selectedDates[0],
                endTime: selectedDates[1],
                botId: bot_AI_ID ? bot_AI_ID : botId,
                filter: '',
                page: offset,
                size: 10
            })
        );
    }, [selectedDates, offset]);
    useEffect(() => {
        isConversationIdValid === 'valid' &&
            chatFeedbackData &&
            chatFeedbackData.length > 0 &&
            chatFeedbackData[0].chat_id &&
            dispatch(
                getWholeConversation({
                    conversationID: chatFeedbackData[0].chat_id,
                    botId: bot_AI_ID ? bot_AI_ID : botId,
                })
            );
        dispatch(resetConversationIdStatus(''));
    }, [isConversationIdValid]);
    useEffect(() => {
        return () => {
            dispatch(setFeedbackSearchedValue(''));
        }
    }, [])
    useEffect(() => {
        dispatch(setOpenConversation('open'));
        return (() => {
            dispatch(setOpenConversation('close'));
        })
    }, [])
    return (
        <div className='ff-analytic-conversations-container'>
            {chatFeedbackData && chatFeedbackData.length > 0 ? (
                <div className='analytic-conversations-content'>
                    <div
                        className={`conversations-table-content ${openConversation === 'open' &&
                            analyticConversationData &&
                            analyticConversationData.length > 0
                            ? 'chat-opened'
                            : ''
                            }`}>
                        <div className='conversations-table-header'>
                            {/* <div className='table-paginations'>
                                <span className='page-naviagtors body2'> */}
                            <Pagination
                                disableforward={totalFeedbackLength <= offsetval + totalFeedbackCurrentLength.length}
                                disableback={offsetval === 0}
                                nextPage={() => {
                                    setOffset(offset + LIMIT)
                                    setOffsetval(offsetval + 10)
                                }}
                                previousPage={() => {
                                    setOffset(offset - LIMIT);
                                    setOffsetval(offsetval - 10)
                                }}
                                gotoBegining={() => {
                                    setOffset(0);
                                    setOffsetval(0);
                                }
                                }
                                gotoLast={() => {
                                    if (totalFeedbackLength % 10 == 0) {
                                        setOffsetval((Math.floor(totalFeedbackLength / 10) * 10) - 10)
                                        setOffset((Math.ceil(totalFeedbackLength / 10) - 1) - 10)
                                    }
                                    else {
                                        setOffsetval(Math.floor(totalFeedbackLength / 10) * 10)
                                        setOffset(Math.ceil(totalFeedbackLength / 10) - 1)
                                    }


                                }
                                }
                                totalCount={totalFeedbackLength}
                                upper={UpperL}
                                lower={LowerL}
                            />
                            {/* {chatFeedbackData && chatFeedbackData.length &&
                                        <span>{1} - {chatFeedbackData && chatFeedbackData.length} of {chatFeedbackData && chatFeedbackData.length}</span>
                                    } */}
                            {/* </span>
                            </div> */}

                            <div className='content-shown body2'>
                                <span className='download-analytics-datas'>
                                    {/* 
                                    <a
                                   
                                        target='_blank'
                                        rel='noreferrer'>
                                        <InteractiveButton
                                            title='Download feedback data'
                                            onClick={(event: any) => {
                                                // downloadCampaignsData(event)
                                                getTemplateFormat()
                                            }}
                                            icon={<DownloadAnalyticDatas />}
                                        />
                                    </a> */}
                                </span>
                                {/* <div>
                                    <Input
                                        onKeyDown={
                                            searchFATableData
                                        }
                                        value={FeedbackDataSearchedValue}
                                        onChange={(e: any) => dispatch(setFeedbackSearchedValue(e.target.value))}
                                        placeholder='Search by EngagementId'

                                        endIcon={
                                            <>
                                                {FeedbackDataSearchedValue ? (
                                                    // searchLoader ? (
                                                    // 	<div style={{ color: '#3b44c4' }}>
                                                    // 		<FFLoader fill='#3b44c4' />
                                                    // 	</div>
                                                    // ) : (
                                                    <svg
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {

                                                            dispatch(setFeedbackSearchedValue(''));
                                                            dispatch(
                                                                getFeedbackConversationalDatas({
                                                                    startTime: selectedDates[0],
                                                                    endTime: selectedDates[1],
                                                                    botId: bot_AI_ID ? bot_AI_ID : botId,
                                                                    filter: '',
                                                                    page: offset,
                                                                    size: 10
                                                                })
                                                            );
                                                            dispatch(setOpenConversation('open'));

                                                        }}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'>
                                                        <g data-name='Group 30351'>
                                                            <path
                                                                data-name='Path 13850'
                                                                d='M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41zM12 2a10 10 0 1 0 10 10A9.991 9.991 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.011 8.011 0 0 1-8 8z'
                                                                fill='rgba(0,0,0,.4)'
                                                            />
                                                        </g>
                                                    </svg>
                                                    // )
                                                ) : (
                                                    <svg
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            dispatch(
                                                                getFeedbackConversationalDatas({
                                                                    startTime: selectedDates[0],
                                                                    endTime: selectedDates[1],
                                                                    botId: bot_AI_ID ? bot_AI_ID : botId,
                                                                    filter: FeedbackDataSearchedValue,
                                                                    page: offset,
                                                                    size: 10
                                                                })
                                                            );

                                                            dispatch(setFeedbackSearchedValue(''));
                                                            dispatch(setOpenConversation('close'));

                                                        }}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'>
                                                        <path
                                                            data-name='Path 2862'
                                                            d='M0 0h24v24H0z'
                                                            fill='none'
                                                        />
                                                        <path
                                                            data-name='Path 2863'
                                                            d='M15.5 14h-.79l-.28-.27a6.51 6.51 0 1 0-.7.7l.27.28v.79l5 4.99L20.49 19zm-6 0A4.5 4.5 0 1 1 14 9.5 4.494 4.494 0 0 1 9.5 14z'
                                                            fill='rgba(0,0,0,.6)'
                                                        />
                                                    </svg>
                                                )}
                                            </>
                                        }
                                    />
                                </div> */}
                            </div>

                        </div>


                        <div className='conversations-table-body-content'>
                            <Table
                                isLoading={false}
                                column={columnTabData}
                                data={
                                    chatFeedbackData && chatFeedbackData.length > 0
                                        ? chatFeedbackData
                                        : []
                                }
                                page={offset / LIMIT}
                                limit={LIMIT}
                                totalCount={chatFeedbackData.length}
                                onRowClick={(row) => {
                                    dispatch(
                                        getWholeConversation({
                                            conversationID: row.chat_id,
                                            botId: bot_AI_ID ? bot_AI_ID : botId,
                                            path: '/chat',
                                        })
                                    );
                                    dispatch(setOpenConversation('open'));
                                    storeClickedData(row.channel)
                                }}
                                height={'calc(100vh - 200px)'}
                                analyticsRowClick={true}
                            />
                        </div>
                    </div>
                    {openConversation === 'open' &&
                        analyticConversationData &&
                        analyticConversationData.length > 0 && (
                            <div style={{ width: '304px' }} className={`conversations-chat-data feeback-conv-data ${clickedData && clickedData.toLowerCase().indexOf('whatsapp:') > -1 ? 'whatsap-header-analyse-chat' : 'web-header-analyse-chat'}`}>
                                <div className='chat-data-header'>
                                    <div className='conversation-id'>
                                        <span>
                                            <img src={AIIcon} alt='chatbot-icon' style={{
                                                width: '30px',
                                                height: '30px'
                                            }} />
                                        </span>
                                        <span>
                                            <span>
                                                <p className='body1 title'>My AI</p>
                                            </span>
                                            <p className='chat-transcriptid'>
                                                Session ID:
                                                {analyticConversationData &&
                                                    analyticConversationData.length > 0 &&
                                                    analyticConversationData[0].conversationId
                                                    ? analyticConversationData[0].conversationId
                                                    : 'ASDIOP'}
                                            </p>
                                        </span>
                                    </div>
                                    <InteractiveButton
                                        title='Close'
                                        onClick={() => {
                                            dispatch(setOpenConversation('close'));
                                        }}
                                        icon={<CloseIcon />}
                                    />
                                </div>
                                <div className='chat-data-body'>
                                    {analyticConversationData &&
                                        analyticConversationData.length > 0 &&
                                        analyticConversationData.map((chat: any, index: number) => {
                                            return (
                                                <div className={`chat-messages ${chat.source}`}>
                                                    <WAChatBubble
                                                        timeStamp={chat.createdDate}
                                                        key={index}
                                                        msgtype={chat.source}
                                                        message={chat.body}
                                                        metadata={
                                                            chat.metaData
                                                                ?
                                                                chat.metaData : []
                                                            // chat.metaData.products &&
                                                            // chat.metaData.products.length > 0
                                                            // ? chat.metaData.products
                                                            // : chat.metaData.form &&
                                                            // 	chat.metaData.form.length > 0
                                                            // 	?
                                                            // 	chat.metaData.form
                                                            // 	: []
                                                        }
                                                        conversationId={analyticConversationData[0].conversationId}
                                                        noOfTimeCalled={index}
                                                        feedback={chat.feedback}
                                                        channel={clickedData && clickedData.toLowerCase().indexOf('whatsapp:') > -1 ? 'whatsapp' : 'web'}
                                                    />
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                </div>
            ) : (
                <div className='analytics-no-data-found'>
                    No data found for this selected date range
                </div>
            )}
        </div>
    );
};

export default FeedbackConversationTab;
