import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import Modal from '../../../../Components/Modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { BotRename, resetIsRenaming } from '../../../../Features/Bots/BotSlice';
import { set } from '../../../../Features/message/messageSlice';
import { useLocation } from 'react-router-dom';
import { isHTMLTagsPresent } from '../../../../utils/utils';

type RenameProps = {
	onClose: Function;
};

const Rename = ({ onClose }: RenameProps) => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const path = location.pathname;
	const { selectedBot } = useSelector((state: RootState) => state.bots);
	const [botName] = useState(selectedBot.botName);
	const [rename, setRename] = useState(selectedBot.botName);
	const [error, setError] = useState<string | undefined>(undefined);
	const { BotList, isRenaming } = useSelector((state: RootState) => state.bots);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	useEffect(() => {
		if (isRenaming === 'renamed') {
			onClose();
			dispatch(
				set(
					`${
						path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'
					} Renamed Successfully`
				)
			);
			dispatch(resetIsRenaming());
		} else if (isRenaming === 'failed') {
			dispatch(set("Couldn't Rename!!! Something Went Wrong"));
			dispatch(resetIsRenaming());
		}
	}, [isRenaming]);

	const handleRename = () => {
		if (tenantId) {
			const filterbot = BotList.filter(
				(bot: any) => bot.botName === rename && bot.botId !== selectedBot.botId
			);

			if (filterbot.length !== 0) {
				setError(
					`There’s already ${
						path.indexOf('intelligent-ad') > -1 ? 'an Ad' : 'a Storefront'
					} with this name. Please pick a different name.`
				);
				return;
			} else if (botName === rename) {
				setError('Please change name before saving');
				return;
			} else {
				setError(undefined);
			}
			// if (rename != null) {
			// 	// setError('Invalid input');
			// } else {
			setError(undefined);
			dispatch(
				BotRename({
					fieldName: 'BOT_NAME',
					fieldValue: rename
						.replace(/:/g, ': ')
						.replace(/<(?!br)([^>]+)>/g, ''),
					tenantId: tenantId,
				})
			);
			// }
		}
	};

	const isButtonDisabled = () => {
		return rename.length === 0;
	};

	const buttons = () => {
		return (
			<>
				<Button onClick={() => onClose()} buttonType='light'>
					Cancel
				</Button>
				<Button
					loading={isRenaming === 'renaming'}
					onClick={handleRename}
					disabled={isButtonDisabled() || isHTMLTagsPresent(rename)}>
					Save
				</Button>
			</>
		);
	};
	return (
		<div>
			<Modal
				header={`Rename ${
					path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'
				}`}
				actionButtons={buttons()}
				onClose={() => onClose()}>
				<Input
					value={rename}
					onChange={(event) => {
						setRename(event.target.value);
						setError(undefined);
					}}
					label={`${
						path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'
					} Name`}
					limit={50}
					error={Boolean(error)}
					errorText={error}
					fullWidth
					autoFocus
					disAllowSpecialChar={true}
				/>
			</Modal>
		</div>
	);
};

export default Rename;
