import { ReactElement } from 'react';
import Button from '../../Components/Button';
import { FFLoader } from '../../assets/Icons/Loader';
import './RedirectLoader.scss';

type RedirectLoaderProps = {
	message: string | ReactElement;
	bgColor?: string;
	showRetry?: boolean;
	retryMessage?: string | ReactElement;
	problemMessage?: string | ReactElement;
	choosePlanButton?: ReactElement;
};

const RedirectLoader = ({
	message,
	bgColor,
	retryMessage,
	problemMessage,
	showRetry = false,
	choosePlanButton,
}: RedirectLoaderProps) => {
	return (
		<div
			className={`ff-redirection-loader ${
				bgColor === 'white' ? 'ff-redirection-loader-bg' : ''
			}`}>
			{showRetry ? null : <FFLoader />}
			<p className='body1'>{message}</p>
			{showRetry ? (
				<>
					<p>{retryMessage ?? 'Please click the button below to retry'}</p>
					<p>
						{problemMessage ?? (
							<p>
								If problem continued to be persistent then please report us at{' '}
								<a
									href='mailto:support@flyfish.ai'
									style={{ color: 'var(--violet-600)' }}>
									support@flyfish.ai
								</a>
							</p>
						)}
					</p>
					<div className={`${choosePlanButton ? 'choose-plan-button' : ''}`}>
						{choosePlanButton}
						<Button onClick={() => window.location.reload()}>Retry</Button>
					</div>
				</>
			) : null}
		</div>
	);
};

export default RedirectLoader;
