import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	NotifyState,
	UnreadNotification,
	ReadNotifications,
	UpdateNotification,
	UnreadNotificationRes,
	ReadNotificationsRes,
	UnreadNotificationForAll,
	ReadNotificationsForAll,
} from './model';
import notificationService from './notificationService';
import { loadState, saveState } from '../../utils/utils';

export const getUnreadNotifications = createAsyncThunk<
	UnreadNotificationRes,
	UnreadNotification,
	{ rejectValue: string }
>('/getUnreadNotifications', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await notificationService.getUnreadNotifications(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const getUnreadNotificationsForAll = createAsyncThunk<
	UnreadNotificationRes,
	UnreadNotificationForAll,
	{ rejectValue: string }
>(
	'/getUnreadNotificationsForAll',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await notificationService.getUnreadNotificationsForAll(
				data
			);

			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify({ error: 'Network error' }));
		}
	}
);

export const getReadNotifications = createAsyncThunk<
	ReadNotificationsRes,
	ReadNotifications,
	{ rejectValue: string }
>('/getReadNotifications', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await notificationService.getReadNotifications(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const getReadNotificationsForAll = createAsyncThunk<
	ReadNotificationsRes,
	ReadNotificationsForAll,
	{ rejectValue: string }
>(
	'/getReadNotificationsForAll',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await notificationService.getReadNotificationsForAll(
				data
			);

			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify({ error: 'Network error' }));
		}
	}
);

export const updateNotification = createAsyncThunk<
	{ status: string },
	UpdateNotification,
	{ rejectValue: string }
>('/updateNotification', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await notificationService.updateNotification(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

const initialState: NotifyState = {
	getUnreadNotificationLoader: '',
	getReadNotificationLoader: '',
	getUnreadNotificationLoaderForAll: '',
	getReadNotificationLoaderForAll: '',
	updateNotificationLoader: '',
	newNotification: false,
	newNotificationCount: 0,
	notificationPane: false,
	notificationSnack: {
		time: '',
		link: '',
		message: '',
		type: 'info',
		open: false,
	},
	unreadNotifications: [],
	readNotifications: [],
	todayGroup: [],
	yesterdayGroup: [],
	olderGroup: [],
};

const notificationSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		resetAllNotificationData: (state) => {
			state.unreadNotifications = [];
			state.readNotifications = [];
			state.todayGroup = [];
			state.yesterdayGroup = [];
			state.olderGroup = [];
		},
		resetNewNotificationCount: (state) => {
			state.newNotificationCount = 0;
		},
		setNotificationPane: (state, action) => {
			state.notificationPane = action.payload;
		},

		setNewNotification: (state, action) => {
			state.newNotification = action.payload;
		},
		setTodayGroup: (state, action) => {
			state.todayGroup = action.payload;
		},
		setYesterdayGroup: (state, action) => {
			state.yesterdayGroup = action.payload;
		},
		setOlderGroup: (state, action) => {
			state.olderGroup = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(getUnreadNotifications.pending, (state) => {
				state.getUnreadNotificationLoader = 'loading';
			})
			.addCase(getUnreadNotifications.fulfilled, (state, action) => {
				let notifications = action.payload.data.notifications;
				if (
					notifications.length >
					(loadState('unreadNotificationsCount') === undefined
						? 0
						: loadState('unreadNotificationsCount'))
				) {
					state.newNotificationCount =
						notifications.length - state.unreadNotifications.length;
				}

				state.getUnreadNotificationLoader = 'success';
				state.newNotification = notifications.length ? true : false;
				state.unreadNotifications = notifications;
			})
			.addCase(getUnreadNotifications.rejected, (state, action) => {
				state.getUnreadNotificationLoader = 'failed';
			})
			.addCase(getReadNotifications.pending, (state) => {
				state.getReadNotificationLoader = 'loading';
			})
			.addCase(getReadNotifications.fulfilled, (state, action) => {
				state.getReadNotificationLoader = 'success';
				state.readNotifications = action.payload.data.notifications;
			})
			.addCase(getReadNotifications.rejected, (state, action) => {
				state.getReadNotificationLoader = 'failed';
			})
			.addCase(getUnreadNotificationsForAll.pending, (state) => {
				state.getUnreadNotificationLoaderForAll = 'loading';
			})
			.addCase(getUnreadNotificationsForAll.fulfilled, (state, action) => {
				let notifications = action.payload.data.notifications;
				if (
					notifications.length >
					(loadState('unreadNotificationsCount') === undefined
						? 0
						: loadState('unreadNotificationsCount'))
				) {
					state.newNotificationCount =
						notifications.length - state.unreadNotifications.length;
				}

				state.getUnreadNotificationLoaderForAll = 'success';
				state.newNotification = notifications.length ? true : false;
				state.unreadNotifications = notifications;
			})
			.addCase(getUnreadNotificationsForAll.rejected, (state, action) => {
				state.getUnreadNotificationLoaderForAll = 'failed';
			})
			.addCase(getReadNotificationsForAll.pending, (state) => {
				state.getUnreadNotificationLoaderForAll = 'loading';
			})
			.addCase(getReadNotificationsForAll.fulfilled, (state, action) => {
				state.getUnreadNotificationLoaderForAll = 'success';
				state.readNotifications = action.payload.data.notifications;
			})
			.addCase(getReadNotificationsForAll.rejected, (state, action) => {
				state.getUnreadNotificationLoaderForAll = 'failed';
			})
			.addCase(updateNotification.pending, (state) => {
				state.updateNotificationLoader = 'loading';
			})
			.addCase(updateNotification.fulfilled, (state, action) => {
				state.updateNotificationLoader = 'success';
			})
			.addCase(updateNotification.rejected, (state, action) => {
				state.updateNotificationLoader = 'failed';
			});
	},
});

export const {
	setNotificationPane,

	setNewNotification,
	resetAllNotificationData,
	setTodayGroup,
	setOlderGroup,
	setYesterdayGroup,
	resetNewNotificationCount,
} = notificationSlice.actions;

export default notificationSlice.reducer;
