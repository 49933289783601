import AppearanceInput from '../AppearanceInput';
import Slider from '../../../../assets/images/Slider.png';
import './ColorTypes.scss';
type Props = {
	text: string;
	applyBubbleClass?: boolean;
	numberOfTypes?: 2 | 3;
	value: string;
	onValueChange: (arg0: string) => void;
	rangeInputV?: string;
	rangeInputOnValueChange?: (arg0: string) => void;
};
const ColorTypes = ({
	text,
	applyBubbleClass = true,
	numberOfTypes = 2,
	value,
	onValueChange,
	rangeInputV,
	rangeInputOnValueChange,
}: Props) => {
	return (
		<div className='ff-color-types'>
			{numberOfTypes === 2 ? (
				<div
					className={
						applyBubbleClass ? 'color-selected-bubble' : 'color-selected-text'
					}>
					<p className='appearance-selected-color body1'>{text}</p>
					<div className='color-hex-value'>
						<span
							className='color-box-input'
							style={{
								backgroundColor: value,
								maxWidth: '56px',
								maxHeight: '32px',
								borderRadius: '4px',
							}}>
							<AppearanceInput
								className='color-box'
								type='color'
								value={value}
								onValueChange={onValueChange}
							/>
						</span>
						<span>{value && value.toUpperCase()}</span>
					</div>
				</div>
			) : (
				<div className='color-selected-bubble'>
					<p className='appearance-selected-color body1'>{text}</p>
					<div className='color-opacity-box'>
						<div className='color-hex-value'>
							<span
								className='color-box-input'
								style={{
									backgroundColor: value,
									maxWidth: '56px',
									maxHeight: '32px',
									borderRadius: '4px',
								}}>
								<AppearanceInput
									className='color-box'
									type='color'
									value={value}
									onValueChange={onValueChange}
								/>
							</span>
							<span>{value && value.toUpperCase()}</span>
						</div>
						<div className='appearance-selected-color-font'>
							<p>Opacity</p>
							<div className='opacity'>
								<div className='opacity-box'>
									<AppearanceInput
										className='range-input'
										style={{
											background: `linear-gradient(to right, white 0%, ${value} 100%), url(${Slider})`,
											backgroundBlendMode: 'multiply',
										}}
										type='range'
										value={rangeInputV!}
										min={0}
										max={100}
										onValueChange={rangeInputOnValueChange!}
									/>
									<AppearanceInput
										className='number-input'
										style={{ borderBottom: `3px solid ${rangeInputV}` }}
										type='number'
										value={rangeInputV!}
										min={0}
										max={100}
										onValueChange={rangeInputOnValueChange!}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ColorTypes;
