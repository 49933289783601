import { useEffect, useState } from 'react';
import Button from '../../../Components/Button';
import Checkbox from '../../../Components/Checkbox';
import './VoiceCapabilities.scss';

const VoiceCapabilities = () => {
	const items = [
		{
			category: 'Allow voice input',
			description:
				'If selected, the users will be able to voice chat with the bot.',
		},
		{
			category: 'Enable voice response',
			description:
				'If user provides a voice input, the bot will speak out its messages out loud in addition to showing content on screen.',
		},
	];
	const [checked, setChecked] = useState(new Array(items.length).fill(false));

	return (
		<div className='ff-voice-capabilities'>
			{items.map((item, i) => {
				return (
					<>
						<div
							className={
								item.category === items[0].category
									? 'allow-voice-input'
									: 'enable-voice-response'
							}>
							<div>
								<p>{item.category}</p>
								<p>{item.description}</p>
							</div>
							<div>
								<Checkbox
									isChecked={checked}
									index={i}
									onValueChange={setChecked}
								/>
							</div>
						</div>
					</>
				);
			})}
			<div className='voice-capabilities-save-button-div'>
				<Button className='voice-capabilities-save-button'>Save</Button>
			</div>
		</div>
	);
};

export default VoiceCapabilities;
