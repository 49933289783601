interface Props {
	width?: string;
	height?: string;
	fillIcon?: boolean;
	className?: string;
	color?: string;
	onClick?: (e: React.MouseEvent<SVGElement>) => void;
	style?: React.CSSProperties;
	opacity?: string;
	rotateSVG?: boolean;
	onBlur?: (e: React.FocusEvent<SVGElement>) => void;
}
export const TrainIcon = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			{...props}>
			<path d='M0 0h20v20H0z' fill='none' />
			<path
				d='m16 7.333-3.333 3.333h2.5a5 5 0 0 1-5 5 4.892 4.892 0 0 1-2.333-.583L6.617 16.3a6.655 6.655 0 0 0 10.217-5.633h2.5zM5.167 10.667a5 5 0 0 1 5-5 4.892 4.892 0 0 1 2.333.583l1.217-1.217A6.655 6.655 0 0 0 3.5 10.667H1L4.333 14l3.333-3.333z'
				transform='translate(-.167 -.667)'
				fill='currentColor'
			/>
		</svg>
	);
};

export const RefreshIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			style={{ cursor: 'disabled' }}>
			<g style={{ opacity: props.opacity }}>
				<path data-name='Path 14783' d='M0 0h24v24H0z' fill='none' />
				<path
					data-name='Path 14784'
					d='M17.65 6.35A8 8 0 1 0 19.73 14h-2.08A6 6 0 1 1 12 6a5.915 5.915 0 0 1 4.22 1.78L13 11h7V4z'
				/>
			</g>
		</svg>
	);
};

export const CloseIcon = (props: Props) => {
	return (
		<svg
			data-name='close_black_24dp (1)'
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path data-name='Path 14781' d='M0 0h24v24H0z' fill='none' />
			<path
				data-name='Path 14782'
				d='M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'
				fill='#ffffff'
			/>
		</svg>
	);
};

export const TestIcon = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			{...props}>
			<path d='M0 0h20v20H0z' fill='none' />
			<path
				d='m9.667 8.033 4.392 2.8-4.392 2.8v-5.6M8 5v11.667l9.167-5.833z'
				transform='translate(-1.333 -.833)'
				fill='currentColor'
			/>
		</svg>
	);
};
