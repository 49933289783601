import React, { useState, useEffect } from 'react';
import { RootState, useAppDispatch } from '../store';
import {
	logout,
	setForcedLogout,
	validateToken,
} from '../../Features/auth/authSlice';
import { useSelector } from 'react-redux';
import { loadState } from '../../utils/utils';

const InactivityDetector = () => {
	const [isActive, setIsActive] = useState(true);
	const dispatch = useAppDispatch();
	const { validateTokenAPIStatus } = useSelector(
		(state: RootState) => state.auth
	);
	const inactivityThreshold = 5000; // 5 seconds (adjust as needed)

	let inactivityTimer: any = null;

	const handleUserActivity = () => {
		setIsActive(true);

		clearTimeout(inactivityTimer);
		inactivityTimer = setTimeout(() => {
			setIsActive(false);
		}, inactivityThreshold);
	};

	useEffect(() => {
		// Set up event listeners for user activity
		window.addEventListener('mousemove', handleUserActivity);
		window.addEventListener('keydown', handleUserActivity);
		window.addEventListener('touchstart', handleUserActivity);

		// Clean up event listeners on component unmount
		return () => {
			window.removeEventListener('mousemove', handleUserActivity);
			window.removeEventListener('keydown', handleUserActivity);
			window.removeEventListener('touchstart', handleUserActivity);
			clearTimeout(inactivityTimer);
		};
	}, []);
	useEffect(() => {
		if (validateTokenAPIStatus === 'failed' && loadState('token')) {
			dispatch(logout());
			dispatch(setForcedLogout());
		}
	}, [validateTokenAPIStatus]);

	useEffect(() => {
		// Handle inactivity
		if (!isActive) {
			// Perform your inactivity-related tasks here
		} else {
			loadState('token') && dispatch(validateToken());
		}
	}, [isActive]);

	return null; // Since we don't need any UI for this component
};

export default InactivityDetector;
