import './HorizontalSeparator.scss';

type Props = {
	className?: string; //override default margin with Parent
};
const HorizontalSeparator = ({ className }: Props) => {
	return <hr className={`horizontal-separator ${className ?? ''}`} />;
};

export default HorizontalSeparator;
