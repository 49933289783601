import axios from 'axios';
import { loadState } from './utils';

let baseURL =
	window.location.origin.indexOf('localhost') > -1
		? process.env.REACT_APP_API
		: window.location.origin;
//window.location.origin;
// window.origin.indexOf('preprod') > -1
// 	? 'https://preprod.flyfish.ai'
// 	: window.origin.indexOf('app') > -1
// 	? 'https://app.flyfish.ai'
// 	: window.origin.indexOf('localhost') > -1
// 	? 'https://dev.flyfish.ai'
// 	: window.origin;

const DataFetchRouteAPI = (
	withBasicToken: boolean = false,
	withToken: boolean = true,
	tokenType: string = 'Bearer',
	contentType = 'application/json',
	withCRM: boolean = false
) => {
	let headers: any = {
		'Content-Type': contentType,
	};
	const token = withCRM
		? loadState('crmBasictoken')
		: withBasicToken
		? loadState('basictoken')
		: loadState('token');
	const tenantId = loadState('tenantId');

	if (tenantId) {
		if (!withBasicToken && !withToken) {
			headers = {
				'Content-Type': contentType,
				'X-Tenant-Id': tenantId,
			};
		} else {
			headers = {
				Authorization: `${tokenType} ${token}`,
				'Content-Type': contentType,
				'X-Tenant-Id': tenantId,
			};
		}
	} else if (!withBasicToken && !withToken && tokenType === '') {
		headers = {
			Authorization: ``,
			'Content-Type': contentType,
		};
	} else {
		headers = {
			Authorization: `Bearer ${loadState('token')}`,
			'Content-Type': contentType,
		};
	}

	const axiosInstance = axios.create({
		baseURL: baseURL,
		//withCredentials: true,
		headers,
	});
	return axiosInstance;
};

export default DataFetchRouteAPI;
