import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/store';
import { RootState } from '../../../app/store';
import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import Modal from '../../../Components/Modal';
import './AddUser.scss';
import { useSelector } from 'react-redux';
import SnackBar from '../../../Components/SnackBar';

import {
	addTenantAdmin,
	resetAddUserLoader,
} from '../../../Features/teamsetting/teamsettingSlice';
import { isHTMLTagsPresent } from '../../../utils/utils';

export function isValidEmail(email: string) {
	return /\S+@\S+\.\S+/.test(email); //NOSONAR
}

const AddUser = () => {
	const navigate = useNavigate();
	const [emailId, setEmailId] = useState('');
	const [role, setRole] = useState('');
	const [helperText, setHelperText] = useState(false);
	const dispatch = useAppDispatch();
	// const { usersList, userAddSuccess } = useSelector(
	// 	(state: RootState) => state.user
	// );
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { botList, UsersMyhubbotRolesList, addUserLoader } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const [buttonLoader, setButtonLoader] = useState(false);

	useEffect(() => {
		if (addUserLoader === 'success') {
			// toast(<SnackBar type='success'>User added successfully</SnackBar>, {
			// 	autoClose: 3000,
			// });
			setButtonLoader(false);
			navigate(-1);
		} else if (addUserLoader === 'failed') {
			// toast(
			// 	<SnackBar type='danger'>
			// 		Couldn't add user !!! Something Went Wrong
			// 	</SnackBar>,
			// 	{
			// 		autoClose: 3000,
			// 	}
			// );
			setButtonLoader(false);
		} else if (addUserLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetAddUserLoader());
	}, [addUserLoader]);

	useEffect(() => {
		if (emailId !== '') {
			let match = [];
			UsersMyhubbotRolesList.allUsers.forEach((user) => {
				if (user.userEmail === emailId) {
					match.push(true);
					setHelperText(true);
				}
			});
			if (match.length === 0) {
				setHelperText(false);
			}
		} else {
			setHelperText(false);
		}
	}, [emailId]);
	function isValidEmail(email: string) {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); //NOSONAR
	}
	const AddNewUser = () => {
		if (role === 'bot master') {
			navigate('/app/configuration/users/edit-bot-access', {
				state: { allBotData: botList, fromAddUser: true, UserEmail: emailId },
			});
		} else {
			let name = emailId.split('@');
			let data = {
				continueAfter: true,
				firstName: name[0],
				lastName: `@${name[1]}`,
				tenantId: tenantId!,
				userEmail: emailId,
				userId: 0, //user?.userID!,
			};
			dispatch(addTenantAdmin(data));
		}
	};

	const buttons = () => {
		return (
			<>
				<Button onClick={() => navigate(-1)} buttonType='light'>
					<p>Cancel</p>
				</Button>
				<Button
					loading={buttonLoader}
					disabled={!isValidEmail(emailId) || isHTMLTagsPresent(emailId, true)}
					onClick={AddNewUser}>
					<p>Add</p>
				</Button>
			</>
		);
	};

	return (
		<div className='ff-addnewuser-modal'>
			<Modal header='Add a user' actionButtons={buttons()}>
				<div className='modal-container-addnewuser'>
					<div className='label-input'>
						<p className='labelforinput'>Enter email address</p>
						<Input
							fullWidth
							errorText={helperText ? 'Email ID already exists!' : undefined}
							autoComplete='off'
							error={helperText}
							autoFocus
							type='email'
							required
							value={emailId}
							onChange={(event) => setEmailId(event.target.value)}
							placeholder='Email Address*'></Input>
					</div>
					{/* <div className='role'>
						<div className='select-role'>Select Role</div>
						<div className='radio-buttons'>
							<div className='teamAdmin'>
								<Radio
									name='role'
									value='team admin'
									isChecked={role}
									label={
										<>
											<p>Team Admin</p>
											<p className='radio-description'>
												Has access to all bots and team settings
											</p>
										</>
									}
									onValueChange={setRole}
								/>
							</div>
							<div className='botMaster'>
								<Radio
									name='role'
									value='bot master'
									isChecked={role}
									label={
										<>
											<p>Bot Master</p>
											<p className='radio-description'>
												You select which bots will the user have access to
											</p>
										</>
									}
									onValueChange={setRole}
								/>
							</div>
						</div>
					</div> */}
				</div>
			</Modal>
		</div>
	);
};

export default AddUser;
