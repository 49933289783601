import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import Modal from '../../../../Components/Modal';
import { Channel } from '../../../../Features/channel/modal';
import {
	modifyPageState,
	reset,
	updateBotState,
	wabaInsta,
	WabaNo,
} from '../../../../Features/WhatsApp-BSP/auth/authSlice';
import '../Modal.scss';

interface Props {
	Details: wabaInsta | undefined;
	setPause: (arg: boolean) => void;
}
export const Pause = ({ Details, setPause }: Props) => {
	const [mobileNum, setMobileNum] = useState('');
	const [mobileNoError, setMobileNoError] = useState<string | undefined>(
		undefined
	);
	const dispatch = useAppDispatch();

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	const handlePause = () => {
		if (Details && Details.pageId) {
			//handle instagram
			dispatch(
				modifyPageState({
					action: `${Details?.instaStatus?.toUpperCase() === 'PAUSE'
						? 'Active'
						: 'Pause'
						}`,
					pageId: Details?.pageId,
				})
			);
		} else {
			Details?.wabaNo &&
				dispatch(
					updateBotState({
						botStatus: `${Details?.wabaStatus?.toUpperCase() === 'PAUSE'
							? 'active'
							: 'pause'
							}`,
						wabaNo: Details?.wabaNo,
					})
				);
		}
	};

	return (
		<Modal
			header={`${Details?.pageName
				? Details?.instaStatus?.toUpperCase() === 'PAUSE'
					? 'Resume'
					: 'Pause'
				: Details?.wabaStatus?.toUpperCase() === 'PAUSE'
					? 'Pause'
					: 'Resume'
				} ${Details?.pageName
					? Details.instaUsername + ' on Flyfish?'
					: Details?.wabaNo + '?'
				}`}
			actionButtons={
				<>
					<Button buttonType='light' onClick={() => setPause(false)}>
						Cancel
					</Button>
					<Button
						onClick={() => {
							handlePause();
							setPause(false);
						}}>
						{Details?.pageName
							? Details?.instaStatus?.toUpperCase() === 'ACTIVE'
								? 'Pause'
								: 'Resume'
							: Details?.wabaStatus?.toUpperCase() === 'ACTIVE'
								? 'Pause'
								: 'Resume'}
					</Button>
				</>
			}
			onClose={() => setPause(false)}>
			<div className='modal-body'>
				<p className='body1'>
					Please confirm, do you wish to{' '}
					{Details?.pageName
						? Details?.instaStatus?.toUpperCase() === 'ACTIVE'
							? 'pause'
							: 'resume'
						: Details?.wabaStatus?.toUpperCase() === 'ACTIVE'
							? 'pause'
							: 'resume'}{' '}
					the {Details?.pageName ? 'Instagram account' : 'usage of the number'}{' '}
					{Details?.pageName ? '' : Details?.wabaNo}{' '}
					{Details?.pageName ? ' on Flyfish' : 'on WhatsApp'}?
				</p>
				<p className='body1'>
					{Details?.pageName
						? Details?.instaStatus?.toUpperCase() === 'ACTIVE'
							? 'You will be unable to receive or respond to messages from your Instagram users on Flyfish. You can still use this account from outside Flyifish. You can resume it in Flyfish any time you wish.'
							: 'This will once again allow you to receive or respond to messages from your Instagram users in Flyfish'
						: Details?.wabaStatus?.toUpperCase() === 'ACTIVE'
							? 'This will temporarily disable the number, making you unable to receive or respond to messages from your users. You can resume the number any time you wish.'
							: 'This will once again allow you to receive or respond to messages from your users.'}
				</p>
			</div>
		</Modal>
	);
};
