import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';

import { set } from '../../../Features/message/messageSlice';

const Campaigns = () => {
	const dispatch = useAppDispatch();
	const { success, error } = useSelector((state: RootState) => state.campaign);

	useEffect(() => {
		if (success?.length || error?.length) {
			dispatch(set(success ?? error));
		}
	}, [success, error]);

	return <Outlet />;
};

export default Campaigns;
