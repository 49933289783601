import styled from 'styled-components';
import { hexToRGB } from '../../../../../utils/utils';
import {
	CarouselBotMessagesProps,
	CarouselDivProps,
	CarouselInputProps,
} from './model';

export const CarouselBotMessages = styled.div<CarouselBotMessagesProps>`
	background-color: ${(props) =>
		props.botMessageBubbleColor ?? 'var(--button-blue)'};
	width: fit-content;
	border-radius: 20px;
	padding: 12px 20px;
	font-size: inherit;
	line-height: 1.45;
	color: ${(props) => props.botMessageTextColor ?? 'var(--white)'};
	margin-bottom: 13px;
	max-width: 222px;
`;

export const CarouselInput = styled.div<CarouselInputProps>`
	border-radius: 24px;
	margin: 8px;
	padding: 12px 16px 12px 28px;
	font-size: inherit;
	color: ${(props) =>
		hexToRGB(props.inputBoxTextColor, parseInt(props.inputBoxTextRange) / 100)};

	background-color: ${(props) =>
		hexToRGB(
			props.inputBoxBackgroundColor,
			parseInt(props.inputBoxBckRange) / 100
		)};
`;

export const CarouselDiv = styled.div<CarouselDivProps>`
	& .control-dots {
		left: 16px !important;
		& .dot.selected {
			outline: 2px solid #000;
			margin: 4px !important;
		}
		& .dot:not(.selected) {
			outline: 1px solid #000;
			width: 6px !important;
			height: 6px !important;
			margin: 4px !important;
		}
	}
	& .slide {
		min-width: 75% !important;
	}
	.slider-wrapper {
		overflow: initial !important;
		margin: 0 !important;
		width: 248px !important;
		height: 315px !important;
		& ul.slider {
			gap: 78px;
			position: relative;
			left: 0px !important;
			transform: ${(props) => `translate3d(-${props.value}%, 0px, 0px) !important`};
			}		}
	}
	.carousel-slider {
		padding-bottom: 31px !important;
		overflow: visible !important;
	}
	.dot {
		background-color: ${(props) =>
			props.botMessageBubbleColor ?? 'blue'} !important;
	}
	.carousel-slider-list-item {
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 28%);
		background: ${(props) => props.botMessageBubbleColor ?? 'var(--button-blue)'};
		border-radius: 20px;
		width: 248px;
		height: 315px;
		& .card-title,
		.card-desc {
			font-family: inherit;
			font-size: inherit;
		}
		& .card-title {
			font-weight: 600;
		}
		& .card-desc {
			font-weight: 400;
		}
		& > img {
			border-top-right-radius: 20px;
			border-top-left-radius: 20px;
			width: 256px;
			height: 142px;
		}
		& > div {
			padding: 15px 20px 27px 12px;
			color: ${(props) => props.botMessageTextColor ?? 'var(--white)'};
			gap: 10px;
		}
		& > div > p:first-child {
			font-size: inherit;
			text-align: left;
		}
		& > div > p:last-child {
			font-size: 12px;
			text-align: left;
			margin-top: 8px;
		}
	}
`;
