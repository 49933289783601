import { WelcomeBannerProps } from './model';
import styled from 'styled-components';

export const WelcomeBanner = styled.img<WelcomeBannerProps>`
	display: ${(props) => (props.src ? 'block' : 'none')};
	object-fit: cover;
	height: auto;
	max-height: 400px;
	aspect-ratio: 2/1;
`;
