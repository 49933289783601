import { useEffect } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Modal from '../../../../Components/Modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import {
	archiveBot,
	resetIsArchiving,
} from '../../../../Features/Bots/BotSlice';

import './archive.scss';
import { set } from '../../../../Features/message/messageSlice';

type ArchiveProps = {
	onClose: Function;
};

const Archive = ({ onClose }: ArchiveProps) => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const path = location.pathname;

	const { selectedBot, isArchiving } = useSelector(
		(state: RootState) => state.bots
	);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	useEffect(() => {
		if (isArchiving === 'archived') {
			onClose();
			dispatch(
				set(
					`${path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'} ${
						selectedBot.froze ? 'Unarchived' : 'Archived'
					} Successfully`
				)
			);
			dispatch(resetIsArchiving());
		} else if (isArchiving === 'failed') {
			dispatch(
				set(
					`Couldn't ${
						selectedBot.froze ? 'Unarchive' : 'Archive'
					}!!! Something Went Wrong`
				)
			);
			dispatch(resetIsArchiving());
		}
	}, [isArchiving]);

	const buttons = () => {
		return (
			<>
				<Button onClick={() => onClose()} buttonType='light'>
					Cancel
				</Button>
				<Button
					loading={isArchiving === 'archiving'}
					onClick={() => {
						tenantId &&
							dispatch(
								archiveBot({
									action: selectedBot.froze ? 'unfreeze' : 'freeze',
									botId: selectedBot.froze
										? selectedBot.encBotId
										: selectedBot.botId,
									tenantId: tenantId,
								})
							);
					}}>
					<p>{selectedBot.froze ? 'Unarchive' : 'Archive'}</p>
				</Button>
			</>
		);
	};
	return (
		<div>
			<Modal
				header={
					selectedBot.froze
						? `Unarchive ${
								path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'
						  }`
						: `Archive ${
								path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'
						  }`
				}
				actionButtons={buttons()}
				onClose={() => onClose()}>
				{selectedBot.froze ? (
					<div className='ff_archive_confirm body1'>
						Please confirm that you want to unarchive{' '}
						<b>'{selectedBot.botName}'</b>
					</div>
				) : (
					<div>
						<div className='ff_archive_confirm body1'>
							Please confirm that you want to archive{' '}
							<b>'{selectedBot.botName}'</b>
						</div>
						<div className='ff_archive_confirm body1'>
							Campaign link for the{' '}
							{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'} will
							stop working and you won't be able to open the{' '}
							{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'} until
							you unarchive it.
						</div>
					</div>
				)}
			</Modal>
		</div>
	);
};

export default Archive;
