import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import Options from '../../../Components/ChatComponents/Options';
import './Quicklinks.scss';

type Props = {
	quickLinks: {
		linkText: string;
		linkValue: string;
	}[];
	onClick?: any;
};

const Quicklinks = (props: Props) => {
	// const { quickLinks } = useSelector((state: RootState) => state.configure);

	return (
		<div className='ff-quicklinks-agent-dashboard'>
			{props.quickLinks && props.quickLinks.length > 0 &&
				props.quickLinks.map((links: any) => (
					<span onClick={() => { props.onClick() }}>
						<Options>{links.linkText}</Options>
					</span>

				))}
		</div>
	);
};

export default Quicklinks;
