import { useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Modal from '../../../../Components/Modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { BotClone, resetIsCloning } from '../../../../Features/Bots/BotSlice';
import { useEffect } from 'react';
import { set } from '../../../../Features/message/messageSlice';
import { useLocation } from 'react-router-dom';

type CloneProps = {
	onClose: Function;
};

const Clone = ({ onClose }: CloneProps) => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const path = location.pathname;

	const { selectedBot, isCloning } = useSelector(
		(state: RootState) => state.bots
	);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	useEffect(() => {
		if (isCloning === 'cloned') {
			onClose();
			dispatch(
				set(
					`${
						path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'
					} Cloned Successfully`
				)
			);
			dispatch(resetIsCloning());
		} else if (isCloning === 'failed') {
			dispatch(set("Couldn't Clone!!! Something Went Wrong"));
			dispatch(resetIsCloning());
		}
	}, [isCloning]);

	const buttons = () => {
		return (
			<>
				<Button onClick={() => onClose()} buttonType='light'>
					Cancel
				</Button>
				<Button
					loading={isCloning === 'cloning'}
					onClick={() => {
						tenantId &&
							dispatch(
								BotClone({
									botId: selectedBot.botId,
									tenantId: tenantId,
								})
							);
					}}>
					<p>Clone</p>
				</Button>
			</>
		);
	};
	return (
		<div>
			<Modal
				header={`Clone ${
					path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'
				}`}
				actionButtons={buttons()}
				onClose={() => onClose()}>
				<div className='body1'>
					Please confirm that you want to create a clone of{' '}
					<b>'{selectedBot.botName}'</b>.
				</div>
			</Modal>
		</div>
	);
};

export default Clone;
