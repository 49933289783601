import { useCallback, useEffect, useState } from 'react';
import Button from '../../../../Components/Button';
import Modal from '../../../../Components/Modal';
import Checkbox from '../../../../Components/Checkbox';
import '../Modal.scss';
import Radio from '../../../../Components/Radio';
import AutoComplete from '../../../../Components/AutoComplete';
import { RootState, useAppDispatch } from '../../../../app/store';
import { getBotsList } from '../../../../Features/Bots/BotSlice';
import { loadState } from '../../../../utils/utils';
import {
	InstaId,
	linkInsta,
	linkWaba,
	wabaInsta,
	WabaNo,
} from '../../../../Features/WhatsApp-BSP/auth/authSlice';
import { useSelector } from 'react-redux';
import { listOfBots } from '../../../../Features/Bots/model';

interface Props {
	Details: wabaInsta | undefined;
	setLinkAd: (arg: boolean) => void;
}

export const LinkAd = ({ Details, setLinkAd }: Props) => {
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [adType, setAdType] = useState<string | 'CA' | 'VSR' | undefined>(
		undefined
	);
	const [bot, setBot] = useState<listOfBots | null>(null);
	const [botError, setBotError] = useState<string | undefined>(undefined);
	const [botName, setBotName] = useState<any>('');
	const dispatch = useAppDispatch();
	const tenantId = loadState('tenantId');
	const { BotList, getBotAssignedLoader } = useSelector(
		(state: RootState) => state.bots
	);
	const optionsList = BotList.map((each) => {
		return {
			botName: each.botName,
			botIcon: each.botIcon,
			botIconJSX: each.botIcon ? (
				<img
					src={each.botIcon}
					height='22px'
					width='22px'
					alt={`bot-icon-${each.botId}`}
				/>
			) : (
				''
			),
			botId: each.botId,
			coverImage: each.coverImage,
		};
	});

	useEffect(() => {
		if (Details?.pageName) {
			setIsChecked(true);
			Details.botCategory && setAdType(Details.botCategory);
		} else if (Details?.botId) {
			setIsChecked(true);
			Details.botCategory && setAdType(Details.botCategory);
		}
	}, []);

	const isDisabled = () =>
		!isChecked ? isChecked : !Boolean(bot?.botName) ? true : false;

	useEffect(() => {
		setBotError(undefined);
		if (Boolean(adType)) {
			dispatch(getBotsList({ tenantId, ca_vsr_type: adType }));
		}
	}, [adType]);

	useEffect(() => {
		if (BotList.length) {
			const selectedbot = BotList.filter(
				(bot) => bot?.botName === Details?.botName
			);
			selectedbot.length && setBot(selectedbot[0]);
		}
	}, [BotList]);

	const handleLink = () => {
		if (Details?.wabaNo) {
			dispatch(
				linkWaba({
					wabaNo: Details?.wabaNo ?? '',
					botCategory: adType as 'CA' | 'VSR',
					botEnv: 'prod',
					botId: bot?.botId as number,
					botName: bot?.botName ?? '',
					botIcon: bot?.botIcon ?? '',
					botCoverImage: bot?.coverImage ?? '',
					linkedBotName: botName,
				})
			);
		} else if (Details && Details.pageName) {
			//handle insta linkad
			dispatch(
				linkInsta({
					pageId: Details?.pageId ?? '',
					botCategory: adType as 'CA' | 'VSR',
					botEnv: 'prod',
					botId: bot?.botId as number,
					botName: bot?.botName ?? '',
					botIcon: bot?.botIcon ?? '',
					botCoverImage: bot?.coverImage ?? '',
					linkedBotName: botName,
				})
			);
		}

		setLinkAd(false);
	};

	const validateBot = (channel: string | null) => {
		if (channel && channel.length > 0) {
			if (BotList.filter((ch) => ch.botName === channel).length === 1) {
				setBotError(undefined);
				return;
			}
		}
		setBotError('Please enter a valid consent channel');
	};

	return (
		<Modal
			header={'Link with Ad or Storefront'}
			actionButtons={
				<>
					<Button buttonType='light' onClick={() => setLinkAd(false)}>
						Cancel
					</Button>
					<Button disabled={isDisabled()} onClick={() => handleLink()}>
						Done
					</Button>
				</>
			}
			onClose={() => setLinkAd(false)}>
			<div className='modal-body'>
				<p className='body1'>
					Let your audience interact with an Intelligent Ad or Multichannel
					Storefront when they message{' '}
					{Details?.pageName ? Details.instaUsername : Details?.wabaNo}.
				</p>
				<Checkbox
					label={'Link with Intelligent Ad or Multichannel Storefront'}
					isChecked={[isChecked]}
					index={0}
					onValueChange={() => {
						setIsChecked(!isChecked);
						setBotName(bot?.botName ?? '');
						setAdType(undefined);
						setBot(null);
					}}
				/>
				{isChecked && (
					<div className='radio'>
						<div className='input-group'>
							<Radio
								value='CA'
								isChecked={adType}
								onValueChange={() => {
									setAdType('CA');
									setBot(null);
								}}
								label='Select an Intelligent Ad'
								name='onError'
							/>
							{adType === 'CA' && (
								<AutoComplete
									isLoading={getBotAssignedLoader}
									options={optionsList}
									value={bot ?? null}
									labelKey='botName'
									valueKey='botName'
									iconKey='botIconJSX'
									onChange={(value) => setBot(value)}
									error={Boolean(botError)}
									helperText={botError}
									onBlur={validateBot}
								/>
							)}
						</div>

						<div className='input-group'>
							<Radio
								value='VSR'
								isChecked={adType}
								onValueChange={() => {
									setAdType('VSR');
									setBot(null);
								}}
								label='Select a Multichannel Storefront'
								name='onError'
							/>
							{adType === 'VSR' && (
								<AutoComplete
									isLoading={getBotAssignedLoader}
									options={optionsList}
									value={bot ?? null}
									labelKey='botName'
									valueKey='botName'
									iconKey='botIconJSX'
									onChange={(value) => setBot(value)}
									error={Boolean(botError)}
									helperText={botError}
									onBlur={validateBot}
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
};
