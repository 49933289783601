import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Modal from '../../../Components/Modal';
import {
	delOriginURL,
	setOriginDeletedSuccess,
} from '../../../Features/channel/channelSlice';
import { Channel } from '../../../Features/channel/modal';
import { set } from '../../../Features/message/messageSlice';
import './RemoveWebsite.scss';

type RemoveWebsiteProps = {
	onClose: Function;
	channel: Channel;
};

const RemoveWebsite = ({ onClose, channel }: RemoveWebsiteProps) => {
	const { type } = useParams();
	const dispatch = useAppDispatch();
	const { activeBot } = useSelector((state: RootState) => state.bots);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const activeBotId = activeBot.encBotId;
	const { originDeletedSuccess } = useSelector(
		(state: RootState) => state.channel
	);
	const [typeToShow, setTypeToShow] = useState('');
	useEffect(() => {
		if (type && Boolean(type)) {
			setTypeToShow(
				type === 'intelligent-ad'
					? 'Ad'
					: type === 'multichannel-storefront'
					? 'Storefront'
					: ''
			);
		}
	}, [type]);
	// useEffect(() => {
	// 	if (
	// 		originDeletedSuccess === 'success' ||
	// 		originDeletedSuccess === 'failed'
	// 	) {
	// 		onClose();
	// 	}
	// }, [originDeletedSuccess]);
	const handleRemove = () => {
		// tenantId &&
		// 	dispatch(
		// 		delOriginURL({
		// 			// botId: activeBotId,
		// 			// cors_whitelist_id: channel?.cors_whitelist_id ?? 0,
		// 			originId: channel?.website ?? '',
		// 			tenantId,
		// 		})
		// 	);
	};

	return (
		<Modal
			header={`Remove the ${typeToShow} from ${channel.website}?`}
			headerCloseBtn
			onClose={() => onClose()}
			className='remove-website-modal'
			actionButtons={
				<>
					<Button buttonType='light' onClick={() => onClose()}>
						Cancel
					</Button>
					<Button
						onClick={handleRemove}
						loading={originDeletedSuccess === 'pending' ? true : false}>
						Remove
					</Button>
				</>
			}>
			<p className='body1 description'>
				Please confirm, do you wish to remove {activeBot.botName} from{' '}
				{channel.website}?
			</p>
			<p className='body1 description'>
				This will stop the {type} from working on the website but the code you
				added manually to the website while adding the {type} will need to be
				removed manually by you.
			</p>
		</Modal>
	);
};
export default RemoveWebsite;
