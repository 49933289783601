import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../Components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import { loadState, saveState } from '../../../utils/utils';
import Input from '../../../Components/Input';
import './editpdferror.scss';
import Modal from '../../../Components/Modal';
import { SnackError } from '../../../assets/Icons';
import {
	editPDFAPI,
	setEditAPIStatus,
} from '../../../Features/Ingest/IngestSlice';
import { isHTMLTagsPresent } from '../../../utils/utils';
import styled from 'styled-components';
const MAX_COUNT = 100;

export const SuccessContainer = styled.div`
.modal-container footer{
	justify-content: center;
}
`
export function EditPDFError() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	return (
		<SuccessContainer>
			<Modal
				actionButtons={
					<div className='ff-input-edit-actions'>

						<Button
							onClick={() => {

								navigate('/app/Ingest-tab/pdf-preview/edit-file')
							}}
						>
							Try again
						</Button>
					</div>
				}
			>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<SnackError />
					<h5 style={{ marginTop: '8px' }}>We ran into an error</h5>
					<div style={{ marginTop: '20px', fontWeight: '400' }} className='button1'>Sorry, the document details couldn’t be edited due to an error. Please try again.</div>

				</div>
			</Modal>
		</SuccessContainer>
	);
}
