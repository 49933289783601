import DataFetchAPI from '../../utils/Api';
import { allFAQsData, faqData, MLDocumentBotData, saveFAQs } from './modal';

const getFAQData = async (data: faqData) => {
	return await DataFetchAPI().get(
		'/AutomaticFAQCreation/v2/create/botUsingFAQ',
		{
			params: data,
		}
	);
};
const getAllFAQData = async (data: allFAQsData) => {
	return await DataFetchAPI().get('/AutomaticFAQCreation/GetAllFAQs', {
		params: data,
	});
};
const saveAllFAQData = async (data: saveFAQs) => {
	return await DataFetchAPI().post('/AutomaticFAQCreation/SaveAllFaqs', data);
};
const getMLBotData = async (data: MLDocumentBotData) => {
	return await DataFetchAPI().post('/MLDocumentBot/index', data);
};

// const getAllCips = async (data: allFAQsData) => {
// 	return await DataFetchAPI().get('/AutomaticFAQCreation/GetAllFAQs', {
// 		params: data,
// 	});
// };

const faqService = {
	getFAQData,
	getAllFAQData,
	saveAllFAQData,
	getMLBotData,
};

export default faqService;
