import { FFLoader } from '../../assets/Icons/Loader';
import './PageLoader.scss';

type PageLoaderProps = {
	message: string;
	bgColor?: string;
};

const PageLoader = ({ message, bgColor }: PageLoaderProps) => {
	return (
		<div
			className={`ff-page-loader ${
				bgColor === 'white' ? 'ff-page-loader-bg' : ''
			}`}>
			<FFLoader />
			<p className='body1'>{message}</p>
		</div>
	);
};
export default PageLoader;
