import { useEffect, useState } from 'react';
import './TabGenerator.scss';
import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
interface Props {
	selectedTab?: string;
	selectedTabIndex?: number;
	badgeIndex?: number;
	badgeData?: any;
	botListLength?: number;
	userListLength?: number;
	icons?: string[];
	type?: string;
	passedStyles?: any;
	tabPassedStyles?: any;
	component?: string;
	tabNames?: any;
	isRequired?: boolean;
	onTabChange?: Function;
	onClick?: Function;
	subTabNames?: any;
}
const TabGenerator = (props: Props) => {
	const selectedTab = props.selectedTab;
	const [showTab, setToggleTab] = useState(true);
	const [actionToggle, setActionToggle] = useState(true);
	const { childTabs } = useSelector(
		(state: RootState) => state.experienceonweb
	);
	const location = useLocation();

	return (
		<div
			key={props.component}
			className={`tab-generator-container tab-generator-title${
				`-` + props.component
			}${props.type === 'vertical' ? ' tab-generator-vertical' : ''}`}
			style={props.passedStyles}>
			{props.tabNames.map((eachTab: any, index: number) => {
				return (
					<>
						<div
							key={index + eachTab.Tab.title}
							onClick={() => {
								props.onTabChange &&
									props.onTabChange(eachTab.Tab.title, index);

								if (
									eachTab.Tab.title === 'Integrations' &&
									location.pathname.indexOf('Integrations/') > -1
								) {
									setToggleTab(!showTab);
								}
								if (
									eachTab.Tab.title === 'Experience on web' &&
									location.pathname.indexOf('experience-on-web/') > -1
								) {
									setToggleTab(!showTab);
								}
								if (selectedTab === eachTab.Tab.title) {
									if (eachTab.Tab.title === 'Actions') {
										setActionToggle(!actionToggle);
									}
								}
							}}
							className={`tab body1 ${
								props.selectedTabIndex === index ||
								selectedTab === eachTab.Tab.title
									? `tab-selected`
									: ''
							} ${
								(eachTab.Tab.title === 'Integrations' &&
									location.pathname.indexOf('Integrations/') > -1) ||
								(eachTab.Tab.title === 'Experience on web' &&
									location.pathname.indexOf('experience-on-web/') > -1)
									? 'tab-selected-nobackground-color'
									: ''
							}`}>
							{eachTab.Tab.title}
						</div>
						<>
							{actionToggle && eachTab.Tab.title === 'Actions' && (
								<>
									{childTabs &&
										childTabs.map((data: any, i: number) => {
											return (
												<div
													key={i + data.title}
													className={`sub-tab body1 ${
														props.selectedTabIndex === i ||
														selectedTab === data.title
															? `tab-selected`
															: ''
													}`}
													onClick={() =>
														props.onTabChange &&
														props.onTabChange(data.title, index)
													}>
													{data.title}{' '}
												</div>
											);
										})}
								</>
							)}

							{showTab &&
								eachTab.Tab.title !== 'Actions' &&
								eachTab.SubTabs &&
								eachTab.SubTabs.map((data: any, i: number) => {
									return (
										<div
											key={i + data.title}
											className={`sub-tab body1 ${
												props.selectedTabIndex === i ||
												selectedTab === data.title
													? `tab-selected`
													: ''
											}`}
											onClick={() =>
												props.onTabChange &&
												props.onTabChange(data.title, index)
											}>
											{data.title}{' '}
										</div>
									);
								})}
						</>
					</>
				);
			})}
		</div>
	);
};

export default TabGenerator;
