import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';

import {
	set,
	setSnack,
	setSnackModal,
} from '../../../../../Features/message/messageSlice';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { useSelector } from 'react-redux';
import {
	resetUnlinkleadSquaredLoader,
	unlinkleadSquared,
} from '../../../../../Features/LeadSquared/leadsquaredSlice';
import {
	crmUnlink,
	crmsLinked,
	linkedEmail,
	resetLeadsquaredData,
} from '../../../../../Features/teamsetting/teamsettingSlice';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const UnLinkLeadSquared = () => {
	const navigate = useNavigate();
	const popupdescription = 'Unlink your team from this LeadSquared account?';
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { leadSquaredData, crmUnlinkLoader } = useSelector(
		(state: RootState) => state.teamsetting
	);

	const [buttonLoader, setButtonLoader] = useState(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (crmUnlinkLoader === 'success') {
			dispatch(crmsLinked({ tenantId: tenantId! }));
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: 'Leadsquare account unlinked',
				})
			);
			setButtonLoader(false);

			dispatch(resetLeadsquaredData());
			navigate(-1);
		} else if (crmUnlinkLoader === 'failed') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage:
						'Unable to unlink account at the moment. Please try after sometime!!!',
					modalMode: 'negative',
				})
			);
			setButtonLoader(false);
			navigate(-1);
		} else if (crmUnlinkLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetUnlinkleadSquaredLoader());
	}, [crmUnlinkLoader]);

	return (
		<Modal
			header={popupdescription}
			actionButtons={
				<>
					<Button
						buttonType='light'
						onClick={() => {
							navigate(-1);
						}}>
						Cancel
					</Button>

					<Button
						loading={buttonLoader}
						onClick={() => {
							dispatch(
								crmUnlink({
									tenantId: tenantId!,
									linkedCrmId: leadSquaredData.linkCrmId!,
								})
							);
						}}>
						Unlink
					</Button>
				</>
			}>
			<div className='modal-body-popup'>
				You will no longer be able to send data to this LeadSquared account. Any
				actions in your AI that currently send data to this account will be
				affected.
			</div>
		</Modal>
	);
};
export default UnLinkLeadSquared;
