import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../../Components/Input';
import './Link.scss';
import { RootState, useAppDispatch } from '../../../../../app/store';
import {
	linkFreshchat,
	resetLinkFreshChatLoader,
} from '../../../../../Features/teamsetting/teamsettingSlice';
import { useSelector } from 'react-redux';
import { set } from '../../../../../Features/message/messageSlice';

const Link = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [apikey, setApiKey] = useState('');
	const [freshChatUrl, setFreshChatUrl] = useState('');
	const [showInfo, setShowInfo] = useState(false);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { linkFreshChatLoader, invalidCredentials } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const [buttonLoader, setButtonLoader] = useState(false);

	const linkFreshchatAccount = () => {
		tenantId &&
			dispatch(
				linkFreshchat({
					apiKey: apikey,
					tenantId: tenantId,
					domainName: freshChatUrl,
				})
			);
	};

	const buttons = () => {
		return (
			<>
				<Button onClick={() => navigate(-1)} buttonType='light'>
					<p>Cancel</p>
				</Button>
				<Button
					onClick={linkFreshchatAccount}
					loading={buttonLoader}
					disabled={apikey && freshChatUrl ? false : true}>
					<p>Link</p>
				</Button>
			</>
		);
	};

	useEffect(() => {
		if (linkFreshChatLoader === 'success') {
			setButtonLoader(false);
			!invalidCredentials && navigate('success');
		} else if (linkFreshChatLoader === 'failed') {
			setButtonLoader(false);
			dispatch(
				set(
					'Unable to link account at the moment. Please try after sometime!!!'
				)
			);
		} else if (linkFreshChatLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetLinkFreshChatLoader());
	}, [linkFreshChatLoader]);

	return (
		<div className='ff-freshchat-link'>
			<Modal header='Link your Team with Freshchat' actionButtons={buttons()}>
				<p className='body1 freshchat'>
					<p>
						Linking your Flyfish team with Freshchat lets chats flow from
						Flyfish to Freshchat so that your agent team can handle those chats
						directly from Freshchat Interface.
					</p>
					<p className={`${showInfo ? '' : 'showInfo'}`}>
						Do this within minutes by simply providing the below information.
					</p>

					{showInfo ? (
						<div>
							<p>
								You can find the <b>Account API Key & Freshchat URL</b> under
								API settings in Freshchat’s Admin Settings{' '}
								<i className='settings'>
									(Settings → Admin Settings → Website Tracking, Integrations,
									and APIs → API settings)
								</i>
								.
							</p>
							<p>
								You can generate a new Account API key if one isn’t already
								available.
							</p>
						</div>
					) : (
						<p className='wheretofind' onClick={() => setShowInfo(true)}>
							(Where to find this?)
						</p>
					)}
				</p>
				{invalidCredentials ? (
					<p className='body1 invalid-cred'>
						The Account API Key and/or Freshchat URL you have entered are
						incorrect. Please try again.
					</p>
				) : null}
				<div className='apikey'>
					<Input
						label='Account API Key'
						value={apikey}
						onChange={(e) => setApiKey(e.target.value)}
						autoFocus
						fullWidth></Input>
				</div>
				<div className='url'>
					<Input
						label='Freshchat URL'
						value={freshChatUrl}
						onChange={(e) => setFreshChatUrl(e.target.value)}
						fullWidth></Input>
				</div>
			</Modal>
			<Outlet />
		</div>
	);
};

export default Link;
