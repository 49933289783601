import React from 'react';
import ReactDOM from 'react-dom';
import { TimePickerModalProps, TimePickerProps } from './model';

import './style.scss';

export function TimePicker(props: TimePickerProps) {
	const [selectedTime, setSelectedTime] = React.useState<string>('');

	const [isTimeListVisible, setTimeListVisibility] =
		React.useState<boolean>(false);

	const [position, setPosition] = React.useState<{ left: number; top: number }>(
		{
			left: 0,
			top: 0,
		}
	);

	const ref: React.RefObject<HTMLDivElement> = React.useRef(null);

	const rootElem = React.useMemo(
		() =>
			document.querySelector('#modal-root') || document.createElement('div'),
		[]
	);

	function handleTimeChange(ev: React.ChangeEvent<HTMLInputElement>) {
		const value = ev.target.value;
		setSelectedTime(value);
	}

	function timeParser(is24HoursFormat: boolean, selectedTime: string) {
		if (is24HoursFormat) {
			const timePart = selectedTime.split(':');

			return [...timePart, ''];
		} else {
			const parts = selectedTime.split(' ');

			const timePart = parts[0].split(':');
			return [...timePart, parts[1]];
		}
	}

	function validateTime() {
		const parts = timeParser(props.is24HoursFormat, selectedTime);

		let invalidFlag = false;

		if (!(Number(parts[1]) >= 0 && Number(parts[1]) <= 59)) {
			invalidFlag = true;
		}
		if (props.is24HoursFormat) {
			/**
			 * Check hour
			 */
			if (!(Number(parts[0]) >= 0 && Number(parts[0]) <= 23)) {
				invalidFlag = true;
			}
		} else {
			/**
			 * Check hour
			 */
			if (!(Number(parts[0]) >= 0 && Number(parts[0]) <= 12)) {
				invalidFlag = true;
			}
			/**
			 * Check AM/PM
			 */
			const part3 = (parts[2] && parts[2].toUpperCase()) || '';
			if (!(part3 === 'AM' || part3 === 'PM')) {
				invalidFlag = true;
			}
		}
		if (invalidFlag) {
			/**
			 * If invalid time is given in user input
			 * then fallback to props.selectedTime
			 */
			setSelectedTime(props.selectedTime);
		} else {
			/**
			 * If valid time
			 * then notify consumer
			 */
			props.onChange(selectedTime);
		}
	}

	function openTimeList() {
		if (ref && ref.current) {
			const { left, top } = ref.current.getBoundingClientRect();
			setPosition({ left, top });
		}
		setTimeListVisibility(true);
	}

	function handleTimePickerModalSelection(time: string) {
		const parts = timeParser(props.is24HoursFormat, props.selectedTime);
		/**
		 * Update time in input
		 */
		setSelectedTime(time + ' ' + parts[2]);
		/**
		 * Close time list
		 */
		setTimeListVisibility(false);
		/**
		 * Notify consumer
		 */
		props.onChange(time + ' ' + parts[2]);
	}

	/**
	 * Attach a clickoutside listener
	 */
	React.useEffect(() => {
		function handler(ev: Event) {
			if (ref.current) {
				if (!ref.current.contains(ev.target as HTMLElement)) {
					setTimeListVisibility(false);
				}
			}
		}
		const modalContainerEl = document.body.querySelector(
			'.agent-avaialability-modal'
		);
		modalContainerEl?.addEventListener('click', handler);
		return () => modalContainerEl?.removeEventListener('click', handler);
	}, []);

	return (
		<div className='time-picker-w' tabIndex={0} onBlur={validateTime} ref={ref}>
			<input
				value={selectedTime || props.selectedTime}
				onChange={handleTimeChange}
				onClick={openTimeList}
			/>
			{isTimeListVisible
				? ReactDOM.createPortal(
						<TimePickerModal
							{...position}
							onSelect={handleTimePickerModalSelection}
							selectedTime={selectedTime || props.selectedTime}
						/>,
						rootElem
				  )
				: null}
		</div>
	);
}

function TimePickerModal(props: TimePickerModalProps) {
	const [indexOfSelectedTime, setIndex] = React.useState<number>(-1);

	/**
	 * Make ref for modal wrapper
	 */
	const ref: React.RefObject<HTMLDivElement> = React.useRef(null);

	/**
	 * Memoize time array
	 */
	const times = React.useMemo(() => {
		const arr = [];
		for (let j = 0; j <= 3; j += 1) {
			arr.push(
				`${props.selectedTime.indexOf('AM') > -1 ? '00' : '12'}:${
					j * 15 === 0 ? '00' : j * 15
				}`
			);
		}
		for (let i = 1; i <= 11; i += 1) {
			for (let j = 0; j <= 3; j += 1) {
				arr.push(`${i <= 9 ? '0' + i : i}:${j * 15 === 0 ? '00' : j * 15}`);
			}
		}
		return arr;
	}, []);

	React.useEffect(() => {
		if (ref.current) {
			const timeElem = ref.current.querySelector(
				`div[data-time="T${props.selectedTime
					.replace(/AM|PM/, '')
					.replace(' ', '')}"]`
			);
			if (timeElem) {
				const index = timeElem.getAttribute('data-index');
				setIndex(index ? +index : -1);
				timeElem.scrollIntoView({ block: 'center' });
			}
		}
	}, [props.selectedTime]);

	return (
		/**
		 * Provide 30px offset in order to see
		 * the original input
		 */
		<div
			ref={ref}
			className='time-picker-modal-w'
			style={{ left: props.left + 'px', top: props.top + 30 + 'px' }}>
			{times.map((eachTime, index) => (
				<div
					className={indexOfSelectedTime === index ? 'selected' : ''}
					key={eachTime}
					onClick={() => props.onSelect(eachTime)}
					data-index={index}
					data-time={'T' + eachTime}>
					{eachTime}
				</div>
			))}
		</div>
	);
}
