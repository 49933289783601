import DataFetchAPI from '../../utils/Api';
import {
	frozeBot,
	ClonedBot,
	getBotListData,
	DeleteBotId,
	GetBotConfig,
	ChangeBotSession,
	Secondary,
	Template,
	makeconfig,
	RENAME,
	makeBotFavourite,
} from './model';

const getBotList = async (data: getBotListData) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		'/rba/v1/getBotAssigned',
		{
			params: data,
		}
	);
};
const getTemplateBotList = async (data: getBotListData) => {
	return await DataFetchAPI().get('/rest/getBotTemplate', {
		params: data,
	});
};
const ArchiveBot = async (data: frozeBot) => {
	if (data.action === 'unfreeze') {
		return await DataFetchAPI().post('/bot/freezebot', data);
	} else {
		return await DataFetchAPI().put(`/bot/v2/${data.botId}/archive`);
	}
};
const CreateBotConfig = async (data: makeconfig) => {
	return await DataFetchAPI().post('/bot/createBotConfigurations', data);
};
const makeTemplate = async (data: Template) => {
	return await DataFetchAPI().post('/template/createBotToTemplate', data);
};
const DeleteBot = async (data: DeleteBotId) => {
	return await DataFetchAPI().delete(`/bot/v2/${data.botId}`);
};
const CloneBot = async (data: ClonedBot) => {
	return await DataFetchAPI().post('/template/cloneBot', data);
};
const RenameBot = async (data: RENAME) => {
	return await DataFetchAPI().post('/bot/validateConfig', data);
};
const getBotConfig = async (data: GetBotConfig) => {
	return await DataFetchAPI().get('/bot/public/getBotConfigurations', {
		params: data,
	});
};
const changeBotSession = async (data: ChangeBotSession) => {
	return await DataFetchAPI().get('/rba/v1/changeSessionAtr', {
		params: data,
	});
};
const createSecondary = async (data: Secondary) => {
	return await DataFetchAPI().post('/bot/createSecondary', data);
};
const addBotFavourite = async (data: makeBotFavourite) => {
	return await DataFetchAPI().post('/template/addTemplateToFavourites', data);
};

const getBotService = {
	getBotList,
	getTemplateBotList,
	ArchiveBot,
	DeleteBot,
	CloneBot,
	RenameBot,
	getBotConfig,
	changeBotSession,
	makeTemplate,
	createSecondary,
	CreateBotConfig,
	addBotFavourite,
};

export default getBotService;
