import DataFetchAPI from '../../utils/Api';
import {
	AddQuery,
	AddVariation,
	GetActions,
	GetQueries,
	GetVariationGenerator,
	Query,
	AddAction,
	DeleteWorkFlow,
	DeleteQuery,
	DeleteAction,
	ChangeRootAction,
	CloneAction,
	UpdateActionPosition,
	CreateBotFromScratch,
	GetQuery,
	GetAction,
	AddDisplayAction,
	GetCRMAction,
	AddCRMAction,
	AddTriggerQueryAction,
	AddUserRatingAction,
	AddConfirmValuesAction,
	TrainBot,
	GetLinkedCRM,
	AddMultipleOptionAction,
	ValidateConfig,
	GetSuggestedResponseParam,
	AddUserInput,
	AddCallAPIAction,
} from './model';

const createBotFromScratch = async (data: CreateBotFromScratch) => {
	return await DataFetchAPI().post('/bot/createSecondary', data);
};

const validateConfig = async (data: ValidateConfig) => {
	return await DataFetchAPI().post('/bot/validateConfig', data);
};

const createBotConfig = async (data: any) => {
	return await DataFetchAPI().post('/bot/createBotConfigurations', data);
};

const variationGenerator = async (data: GetVariationGenerator) => {
	return await DataFetchAPI().post('/assistor/variationgenerator', data);
};

const getQuery = async (data: GetQuery) => {
	return await DataFetchAPI().get('/node/get', { params: data });
};

const addQuery = async (data: AddQuery) => {
	return await DataFetchAPI().post('/node/add', data);
};

const updateQuery = async (data: Query) => {
	return await DataFetchAPI().put('/node/update', data);
};

const deleteQuery = async (data: DeleteQuery) => {
	return await DataFetchAPI().delete('/node/delete', { params: data });
};

const addVariation = async (data: AddVariation) => {
	return await DataFetchAPI().post('/node/addVariation', data);
};

const getDisplayAction = async (data: GetAction) => {
	return await DataFetchAPI().get('/workflow/v2/getdisplaytask', {
		params: data,
	});
};

const addDisplayAction = async (data: AddDisplayAction) => {
	return await DataFetchAPI().post('/workflow/v2/displaytask/add', data);
};

const getCRMAction = async (data: GetCRMAction) => {
	return await DataFetchAPI(false, true, 'Bearer', '', true).get(
		`/workflow/v2/crm/${data.taskId}`,
		{
			params: { botId: data.botId, tenantId: data.tenantId },
		}
	);
};

const getLinkedCRM = async (data: GetLinkedCRM) => {
	return await DataFetchAPI(false, true, 'Bearer', '', true).get(
		`/linked/${data.tenantId}`
	);
};

const addCRMAction = async (data: AddCRMAction) => {
	return await DataFetchAPI(
		false,
		true,
		'Bearer',
		'application/json',
		true
	).post('/workflow/v2/crm/', data);
};

const getUserRatingAction = async (data: GetAction) => {
	return await DataFetchAPI().get('/workflow/v2/getRatingTask', {
		params: data,
	});
};

const addUserRatingAction = async (data: AddUserRatingAction) => {
	return await DataFetchAPI().post('/workflow/v2/createRatingTask', data);
};

const getCallAPIAction = async (data: GetAction) => {
	return await DataFetchAPI().get('/workflow/v2/getAPITask', {
		params: data,
	});
};

const addCallAPIAction = async (data: AddCallAPIAction) => {
	return await DataFetchAPI().post('/workflow/v2/createAPI', data);
};

const getUserInputAction = async (data: GetAction) => {
	return await DataFetchAPI().get('/workflow/v2/getDefaultTask', {
		params: data,
	});
};

const getSuggestedResponseParam = async (data: GetSuggestedResponseParam) => {
	return await DataFetchAPI().get('/workflow/v2/getSuggestedResponseParam', {
		params: data,
	});
};

const addUserInputAction = async (data: AddUserInput) => {
	return await DataFetchAPI().post('/workflow/v2/createDefaultTask', data);
};

const getMultipleChoiceAction = async (data: GetAction) => {
	return await DataFetchAPI().get('/workflow/v2/getmultichoice', {
		params: data,
	});
};

const addMultipleChoiceAction = async (data: AddMultipleOptionAction) => {
	return await DataFetchAPI().post('/workflow/v2/multichoice/add', data);
};

const getMultipleSelectAction = async (data: GetAction) => {
	return await DataFetchAPI().get('/workflow/v2/getmultichoice', {
		params: data,
	});
};

const addMultipleSelectAction = async (data: AddMultipleOptionAction) => {
	return await DataFetchAPI().post('/workflow/v2/multichoice/add', data);
};

const getConfirmValuesAction = async (data: GetAction) => {
	return await DataFetchAPI().get('/workflow/v2/getSummaryTask', {
		params: data,
	});
};

const addConfirmValuesAction = async (data: AddConfirmValuesAction) => {
	return await DataFetchAPI().post('/workflow/v2/createSummaryTask', data);
};

const getTriggerQueryAction = async (data: GetAction) => {
	return await DataFetchAPI().get('/workflow/v2/getNodeTask', {
		params: data,
	});
};

const addTriggerQueryAction = async (data: AddTriggerQueryAction) => {
	return await DataFetchAPI().post('/workflow/v2/createNodeTask', data);
};

const addAction = async (data: AddAction) => {
	return await DataFetchAPI().post('/workflow/v2/workflowTask/add', data);
};

const deleteAction = async (data: DeleteAction) => {
	return await DataFetchAPI().delete('/workflow/v2/deleteworkflowtask', {
		params: data,
	});
};

const cloneAction = async (data: CloneAction) => {
	return await DataFetchAPI().post('/workflow/v2/cloneTask', data);
};

const changeRootAction = async (data: ChangeRootAction) => {
	return await DataFetchAPI().post('/workflow/v2/changeRootTask', data);
};

const updateActionPosition = async (data: UpdateActionPosition) => {
	return await DataFetchAPI().post('/workflow/updatePosition', data);
};

const deleteWorkFlow = async (data: DeleteWorkFlow) => {
	return await DataFetchAPI().delete('/workflow/v2/deleteworkflow', {
		params: data,
	});
};

const getQueries = async (data: GetQueries) => {
	return await DataFetchAPI().get('/node/get/all', { params: data });
};

const getActions = async (data: GetActions) => {
	return await DataFetchAPI().get('/workflow/v2/getWorkflowTasks', {
		params: data,
	});
};

const trainBot = async (data: TrainBot) => {
	return await DataFetchAPI().get('/rest/saveindex', {
		params: data,
	});
};

const builderService = {
	createBotFromScratch,
	validateConfig,
	createBotConfig,
	variationGenerator,
	getQueries,
	getQuery,
	addQuery,
	updateQuery,
	deleteQuery,
	addVariation,
	addAction,
	deleteAction,
	changeRootAction,
	updateActionPosition,
	cloneAction,
	deleteWorkFlow,
	getActions,
	getDisplayAction,
	addDisplayAction,
	getLinkedCRM,
	getCRMAction,
	addCRMAction,
	getUserRatingAction,
	addUserRatingAction,
	getCallAPIAction,
	addCallAPIAction,
	getUserInputAction,
	addUserInputAction,
	getMultipleChoiceAction,
	addMultipleChoiceAction,
	getMultipleSelectAction,
	addMultipleSelectAction,
	getConfirmValuesAction,
	addConfirmValuesAction,
	getTriggerQueryAction,
	addTriggerQueryAction,
	trainBot,
	getSuggestedResponseParam,
};

export default builderService;
