import './LabelContainer.scss';

interface ListObj {
	name: string;
	value: number;
	percentage?: number;
}

interface Props {
	data: ListObj[];
	colors?: string[];
}

function LabelContainer({ data, colors }: Props) {
	const color = colors ? colors : ['#9880ff']

	let valueArray = data.map((each) => each.value);
	let totalValue = valueArray.reduce((a, b) => a + b, 0);
	return (
		<div className='doughnut-label-container'>
			<div className='doughnut-legend-container'>
				{data.map((each, index) => (
					<div className='legend' key={index.toString()}>
						<div>
							<div
								className='legend-color'
								style={{ backgroundColor: `${color[index]}` }}></div>
						</div>
						<div className='caption'>
							<p className='name'>{each.name + ' - ' + '  '}</p>
							<span style={{ marginLeft: "4px" }}>{'  ' + each.value + '  ' + "(" + each.percentage + '%' + ')'}</span>{' '}
							{/* {each.value !== 0 && (
								<span>({((each.value / totalValue) * 100).toFixed(1)}%)</span>
							)} */}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default LabelContainer;
