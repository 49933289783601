import { Link, useNavigate } from 'react-router-dom';
import './Login.scss';

import Button from '../../Components/Button';
import { LoginEyeClosed, LoginEyeOpen } from '../../assets/Icons';
import { useEffect, useState } from 'react';
import { RootState, useAppDispatch } from '../../app/store';
import { useSelector } from 'react-redux';
import {
	encryptText,
	getLocationByIP,
	removeAllState,
	saveState,
} from '../../utils/utils';
import {
	login,
	reset,
	resetAllStates,
	resetForcedLogout,
	setEncryptPassword,
} from '../../Features/auth/authSlice';
import {
	reset as resetTeams,
	teamreset,
} from '../../Features/teams/TeamsSlice';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import Checkbox from '../../Components/Checkbox';
import WelcomeImg from '../../assets/images/side_welcome.svg';
import {
	setSelectedPlan,
	shopAuth_shopify,
} from '../../Features/Subscription/subscriptionSlice';
const Login = () => {
	const [username, setUsername] = useState('');
	const [isUsernameValid, setIsUsernameValid] = useState(true);
	const [password, setPassword] = useState('');
	const [isPasswordValid, setIsPasswordValid] = useState(true);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [errorClient, setErrorClient] = useState('');
	const [checkboxValid, setCheckboxValid] = useState(false);
	const [buttonLoader, setButtonLoader] = useState(false);
	const { user, error, forcedLogout, loginLoader } = useSelector(
		(state: RootState) => state.auth
	);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(resetTeams());
		dispatch(setSelectedPlan(''));
		const params = new URLSearchParams(window.location.search.substring(1));

		let shopCode = params.get('code');
		let shopName = params.get('shop');
		if (shopCode?.length && shopName?.length) {
			saveState('shopifyShopName', params.get('shop'));

			saveState('shopCode', shopCode);
			dispatch(
				shopAuth_shopify({
					storeName: shopName,
					storeCode: shopCode,
				})
			);
		}
		getLocationByIP();
	}, []);

	useEffect(() => {
		if (loginLoader === 'success') {
			setButtonLoader(false);
		} else if (loginLoader === 'failed') {
			setButtonLoader(false);
		} else if (loginLoader === 'loading') {
			setButtonLoader(true);
		}
	}, [loginLoader]);

	useEffect(() => {
		if (user) {
			// if (tenantId) {
			// 	// navigate('/app/my-studio');
			// 	navigate('/app/create-ai');
			// } else {
			navigate('/app/teams');
			// }
		}

		dispatch(reset());
	}, [user]);

	const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(reset());
		setErrorClient('');
		setIsUsernameValid(true);
		setUsername(event.target.value.trim());
	};

	const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(reset());
		setErrorClient('');
		setIsPasswordValid(true);
		setPassword(event.target.value.trim());
	};

	const validateUsername = () => {
		if (username.length <= 0) {
			return 'Enter Username';
		} else if (
			!username.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
		) {
			return 'Invalid Username';
		} else {
			return false;
		}
	};

	const validatePassword = () => {
		if (password.length <= 0) {
			return 'Enter password';
		} else {
			return false;
		}
	};

	const isButtonDisabled = () => {
		return validateUsername()
			? true
			: false || validatePassword()
			? true
			: false || checkboxValid
			? false
			: true;
	};

	const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		//Login
		// setLoginLoader(true);
		let validateUserResponse = validateUsername();
		if (validateUserResponse !== false) {
			setErrorClient(validateUserResponse);
			setIsUsernameValid(false);
			return false;
		} else {
			setErrorClient('');
			setIsUsernameValid(true);
		}

		let validatePasswordResponse = validatePassword();
		if (validatePasswordResponse !== false) {
			setErrorClient(validatePasswordResponse);
			setIsPasswordValid(false);
			return false;
		} else {
			setErrorClient('');
			setIsPasswordValid(true);
		}

		setErrorClient('');

		const encryptUserName = encryptText(username);
		const encryptPassword = encryptText(password);
		dispatch(setEncryptPassword(encryptPassword));
		// saveState('basictoken', btoa(`${username}:${password}`));
		teamreset('');
		saveState('basictoken', btoa(`${encryptUserName}:${encryptPassword}`));
		saveState('crmBasictoken', btoa(`${username}:${password}`));
		dispatch(login({ user: username, otp: false }));
		// setLoginLoader(false);
		saveState('parameter', '');
	};

	return (
		<>
			<div className='ff-login-wrapper'>
				<div className='login-left-container'>
					<img src={WelcomeImg} alt='' />
				</div>
				<div className='login-right-container'>
					<div className='form'>
						<h4>Sign in</h4>

						<form action='' method='post' onSubmit={handleLogin}>
							{error && <p className='error'>{error}</p>}
							<div className='input-container'>
								<Input
									placeholder='someone@example.com'
									fullWidth
									type='text'
									value={username}
									onChange={handleUsername}
									label='Email address'
									autoComplete='off'
									autoFocus
								/>
							</div>
							<div className='input-container'>
								<Input
									fullWidth
									type={isPasswordVisible ? 'text' : 'password'}
									value={password}
									onChange={handlePassword}
									label='Password'
									endIcon={
										<div
											className='toggle-password-show'
											onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
											{isPasswordVisible ? (
												<LoginEyeClosed width='19px' />
											) : (
												<LoginEyeOpen width='19px' />
											)}
										</div>
									}
									autoComplete='new-password'
								/>
							</div>

							<div className='login-checkbox-desc-holder '>
								<div className='checkbox-holder'>
									<Checkbox
										isChecked={[checkboxValid]}
										index={0}
										onValueChange={() => {
											setCheckboxValid(!checkboxValid);
										}}
									/>
								</div>
								<p className='body2'>
									By signing in, you confirm that you have read and accepted the{' '}
									<a
										href='https://www.flyfish.ai/privacy-policy/'
										target='_blank'
										rel='noreferrer'>
										Privacy Policy
									</a>{' '}
									and{' '}
									<a
										href='https://www.flyfish.ai/terms-of-use/'
										target='_blank'
										rel='noreferrer'>
										Terms & Conditions
									</a>
								</p>
							</div>
							<div className='button-container caption'>
								<Button
									fullWidth
									size='large'
									className='login-btn'
									type='submit'
									loading={buttonLoader}
									disabled={isButtonDisabled()}>
									Sign In
								</Button>
							</div>

							<div className='helper-links caption'>
								{/* <p className='caption'>
									Don’t have an account? <Link to='/app/sign-up'>Sign Up</Link>
								</p> */}
								<div className='forgot-password caption'>
									<Link to='/app/forgot-password'>Forgot Password?</Link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			{forcedLogout && (
				<Modal
					header='Your session has expired'
					onClose={() => {
						dispatch(resetForcedLogout());
						removeAllState();
						window.location.reload();
					}}
					actionButtons={
						<>
							<Button
								variant='contained'
								onClick={() => {
									dispatch(resetForcedLogout());
									removeAllState();
									window.location.reload();
								}}>
								<p className='button-1'>Okay</p>
							</Button>
						</>
					}>
					<p className='body-1'>
						You were logged out because your session expired. Please login again
						to continue using Flyfish.
					</p>
				</Modal>
			)}
		</>
	);
};
export default Login;
