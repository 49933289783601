import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getTenantsService from './TeamService';
import {
	Tenants,
	createjoinTeam,
	createTeamResponse,
	joinTeamResponse,
	joinTeam,
	getTenantAIData,
	update,
} from './model';
import { loadState, removeState, saveState } from '../../utils/utils';
import { setLoader } from '../message/messageSlice';

import { getFileList } from '../Ingest/IngestSlice';
export interface TeamsState {
	teams: Tenants[];
	tenantId: number | null;
	tenantName: string;
	tenantImageURL: string;
	status: string;
	CreateTeamRes: createTeamResponse;
	joinTeamRes: joinTeamResponse;
	getTenantsstatus: string;
	createTeam: boolean;
	joinTeam: boolean;
	createjoinTeam: boolean;
	getTenantsLoaderStatus: boolean;
	CreateNewTeamstatus: string;
	teamId: number | null;
	createTeamErrorMessage: string;
	joinTeamLoader: string;
	joinTeamFail: string;
	navigateToIngest: boolean;
	teamNavigate: string;
	bot_AI_ID: number;
	bot_AI_name: string;
	getTenantAiLoader: boolean;
	noTeam: boolean;
	createAIStatus: string;
}
const tenantId = loadState('tenantId');
const tenantName = loadState('teamname');
const tenantImageURL = loadState('tenantImageURL');
const bot_AI_ID = loadState('bot_AI_ID');

const initialState: TeamsState = {
	createTeamErrorMessage: '',
	teamId: 0,
	CreateNewTeamstatus: '',
	createTeam: false,
	joinTeam: false,
	createjoinTeam: false,
	getTenantsstatus: '',
	getTenantsLoaderStatus: false,
	teams: [],
	tenantId: tenantId ?? null,
	tenantName: tenantName ?? '',
	tenantImageURL: tenantImageURL,
	status: 'idle',
	CreateTeamRes: {
		id: 0,
		status: '',
		error: '',
		data: null,
		response: '',
	},
	joinTeamRes: {
		id: null,
		response: '',
		error: null,
		responseMapping: null,
	},
	joinTeamLoader: '',
	joinTeamFail: '',
	navigateToIngest: false,
	teamNavigate: '',
	bot_AI_ID: bot_AI_ID ?? 0,
	bot_AI_name: ' ',
	getTenantAiLoader: false,
	noTeam: false,
	createAIStatus: '',
};

export const getTenants = createAsyncThunk<
	any,
	update,
	{ rejectValue: string }
>('/rest/getTenant', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await getTenantsService.getTenants(data);

		if (response.data && response.data.data.length === 0) {
			dispatch(setNoTeam(true));
			return null;
		}
		if (data.updateTenant === true && loadState('tenantId')) {
			let selectedData = response.data.data.filter(
				(e: any) => e.id === loadState('tenantId')
			);
			saveState('teamDet', selectedData[0]);
			dispatch(
				selectTeam({
					tenantId: selectedData[0].id,
					tenantName: selectedData[0].name,
				})
			);

			saveState('tenantId', selectedData[0].id);
			saveState('organizationName', selectedData[0].organizationName);
			saveState('teamname', selectedData[0].name);
			saveState('tenantImageURL', selectedData[0].backgroundImage);

			saveState('role', selectedData[0].role);
		}
		if (response.data.data.length) {
			dispatch(setNoTeam(false));
			return response.data.data;
		}

		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const CreateNewTeam = createAsyncThunk<
	any,
	createjoinTeam,
	{ rejectValue: string }
>(
	'/rba/v1/createTenantProfile',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await getTenantsService.CreateaTeam(data);
			// if (response.data.status.indexOf('Please pick a different name') > -1) {
			// 	dispatch(getTenants());
			// }
			if (response.status === 200) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.message);
		}
	}
);
export const JoinNewTeam = createAsyncThunk<
	any,
	joinTeam,
	{ rejectValue: string }
>('/rba/v1/joinTenant', async (data, { rejectWithValue }) => {
	try {
		const response = await getTenantsService.joinAnotherTeam(data);
		if (response.status === 201) {
			return response.data;
		}
		return rejectWithValue('Something went wrong! Try again later');
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.error);
	}
});

export const getTenantAi = createAsyncThunk<
	any,
	getTenantAIData,
	{ rejectValue: string }
>('/getTenantAi', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await getTenantsService.getTenantAi(data);
		if (response.data) {
			return response.data;
		}
		return rejectWithValue('Something went wrong! Try again later');
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.error);
	}
});

export const creatAi = createAsyncThunk<any, any, { rejectValue: string }>(
	'/createTenantAi',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await getTenantsService.creatAi(data);
			if (response.data) {
				return response.data;
			}
			return rejectWithValue('Something went wrong! Try again later');
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.error);
		}
	}
);

export const teamsSlice = createSlice({
	name: 'teams',
	initialState,
	reducers: {
		reset: (state) => {
			state.teams = [];
			state.tenantId = null;
			state.tenantName = '';
			state.tenantImageURL = '';
			state.status = 'idle';
		},

		selectTeam: (state, action) => {
			state.tenantId = action.payload.tenantId;
			state.tenantName = action.payload.teamname;
		},
		teamreset: (state, action) => {
			state.teamNavigate = action.payload;
		},
		joinTeamset: (state, action) => {
			state.joinTeam = action.payload;
		},
		createTeamset: (state, action) => {
			state.createTeam = action.payload;
		},
		createjoinTeamset: (state, action) => {
			state.createjoinTeam = action.payload;
		},
		clearcreatejoinTeamset: (state) => {
			state.CreateNewTeamstatus = '';
			state.CreateTeamRes = {
				id: 0,
				status: '',
				error: '',
				data: null,
				response: '',
			};
			state.joinTeamRes = {
				id: null,
				response: '',
				error: null,
				responseMapping: null,
			};
		},
		setTenantImageURL: (state, action) => {
			state.tenantImageURL = action.payload;
		},
		resetJoinTeamLoader: (state) => {
			state.joinTeamLoader = '';
		},
		setNavigateToIngest: (state, action) => {
			state.navigateToIngest = action.payload;
		},
		setNoTeam: (state, action) => {
			state.noTeam = action.payload;
		},
		resetCreateAIStatus: (state) => {
			state.createAIStatus = '';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getTenants.pending, (state, action) => {
				state.getTenantsLoaderStatus = true;
			})
			.addCase(getTenants.fulfilled, (state, action) => {
				state.getTenantsLoaderStatus = false;

				state.teams = action.payload;
			})
			.addCase(getTenants.rejected, (state) => {
				state.getTenantsLoaderStatus = false;

				state.getTenantsstatus = 'failed';
			})
			.addCase(creatAi.pending, (state, action) => {
				state.createAIStatus = 'loading';
			})
			.addCase(creatAi.rejected, (state, action) => {
				state.createAIStatus = 'failed';
			})
			.addCase(creatAi.fulfilled, (state, action) => {
				state.createAIStatus = 'success';

				state.bot_AI_ID = action.payload.data.id
					? action.payload.data.id
					: action.payload.data[0]
					? action.payload.data[0].id
					: '';
				state.bot_AI_name = action.payload.data.name
					? action.payload.data.name
					: action.payload.data[0]
					? action.payload.data[0].name
					: '';
				saveState(
					'botId',
					action.payload.data.id
						? action.payload.data.id
						: action.payload.data[0]
						? action.payload.data[0].id
						: ''
				);
			})
			.addCase(getTenantAi.pending, (state, action) => {
				state.getTenantAiLoader = true;
				state.navigateToIngest = false;
			})
			.addCase(getTenantAi.rejected, (state, action) => {
				state.getTenantAiLoader = false;
				state.navigateToIngest = false;
			})
			.addCase(getTenantAi.fulfilled, (state, action) => {
				state.getTenantAiLoader = false;

				if (action.payload.data) {
					if (action.payload.data.length === 0) {
						state.navigateToIngest = false;
						state.teamNavigate = 'create-ai';
					} else {
						state.navigateToIngest = true;
						state.teamNavigate = 'ingest';
						state.bot_AI_ID = action.payload.data.id
							? action.payload.data.id
							: action.payload.data[0]
							? action.payload.data[0].id
							: '';
						state.bot_AI_name = action.payload.data.name
							? action.payload.data.name
							: action.payload.data[0]
							? action.payload.data[0].name
							: '';
						saveState(
							'botId',
							action.payload.data.id
								? action.payload.data.id
								: action.payload.data[0]
								? action.payload.data[0].id
								: ''
						);
					}
				}
			})
			.addCase(CreateNewTeam.pending, (state, action) => {
				state.CreateNewTeamstatus = 'loading';
			})
			.addCase(CreateNewTeam.fulfilled, (state, action) => {
				state.CreateNewTeamstatus = 'success';
				state.CreateTeamRes = action.payload;
				state.teamId = action.payload.id;
			})
			.addCase(CreateNewTeam.rejected, (state, action) => {
				state.CreateNewTeamstatus = 'failed';
				// state.CreateTeamRes = {
				// 	id: 0,
				// 	status: '',
				// 	error: 'Only alphanumberic characters are allowed',
				// 	data: null,
				// 	response: '',
				// };
			})
			.addCase(JoinNewTeam.pending, (state) => {
				state.joinTeamLoader = 'loading';
			})
			.addCase(JoinNewTeam.fulfilled, (state, action) => {
				state.joinTeamRes = action.payload;
				state.joinTeamLoader = 'success';
			})
			.addCase(JoinNewTeam.rejected, (state, action) => {
				state.joinTeamLoader = 'failed';
				state.joinTeamFail = action.payload
					? action.payload
					: 'Something Went Wrong';
			});
	},
});

export const {
	reset,
	selectTeam,
	joinTeamset,
	createTeamset,
	createjoinTeamset,
	clearcreatejoinTeamset,
	setTenantImageURL,
	resetJoinTeamLoader,
	teamreset,
	setNavigateToIngest,
	setNoTeam,
	resetCreateAIStatus,
} = teamsSlice.actions;

export default teamsSlice.reducer;
