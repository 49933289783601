import './BotBuilder.scss';
import Button from '../../Components/Button';
import {
	AddActionTask,
	BotBuilderPlus,
	Flag,
	ThreeDotMenu,
	ZoomIn,
	ZoomOut,
} from '../../assets/Icons';
import Task from './Task';
import { anchorType, Xwrapper } from 'react-xarrows';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Xarrow, { useXarrow } from 'react-xarrows';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../app/store';
import {
	getQueries,
	getActions,
	setActiveQuery,
	addAction,
	deleteQuery,
	resetBotAdded,
	reset,
	setName,
	resetQueryDeleted,
	setActiveQueryIndex,
	setQueryEditIndex,
} from '../../Features/builder/builderSlice';
import { useSelector } from 'react-redux';
import { AddAction, Query } from '../../Features/builder/model';
import Menu, { Options } from '../../Components/Menu';
import ActionsMenu from './ActionsMenu';
import { changeBotSession } from '../../Features/Bots/BotSlice';
import AddLinkedActionMenu from './Task/AddLinkedActionMenu';
import createFirstQuery from '../../assets/images/create-first-query.png';
import { getActionName } from '../../utils/utils';
import { Tooltip } from '@mui/material';
import Modal from '../../Components/Modal';

import { FFLoader } from '../../assets/Icons/Loader';
import PageLoader from '../../Components/PageLoader';
import { set } from '../../Features/message/messageSlice';
import InteractiveButton from '../../Components/Button/InteractiveButton';

export type Anchor = {
	to: number;
	from: number;
	start: anchorType;
	end: anchorType;
};

const BotBuilder = () => {
	const [isMoveable, setIsMoveable] = useState(false);
	const builderPaneRef = useRef<HTMLDivElement>(null);
	const updateXarrow = useXarrow();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<Array<Options>>([]);
	const [addingTask, setAddingTask] = useState(false);
	const [center, setCenter] = useState<Array<string>>([]);
	const [openTaskMenu, setOpenTaskMenu] = useState(false);
	const [del, setDel] = useState<false | Query>(false);
	const [taskMenuAnchor, setTaskMenuAnchor] = useState<null | HTMLElement>(
		null
	);
	const [taskMenuoptions, setTaskMenuOptions] = useState<Array<Options>>([]);

	const [anchors, setAnchors] = useState<Anchor[]>([]);

	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { activeBot } = useSelector((state: RootState) => state.bots);
	const { testAndOpenBot } = useSelector((state: RootState) => state.builder);
	const [loaderOnQueryChange, setLoaderOnQueryChange] = useState(true);
	const {
		botId,
		queries,
		areQueriesLoading,
		activeQuery,
		actions,
		areActionsLoading,
		isQueryDeleting,
		isQueryEditing,
	} = useSelector((state: RootState) => state.builder);

	const [commonProps, setCommonProps] = useState({
		workflow_id: activeQuery?.workflowId,
		task_id: 0,
		taskroot: true,
		template: '',
		templateId: 0,
		template_description: '',
		template_type: '',
		userResponseType: '',
		task_description: '',
		task_name: 'Untitled',
		task_position: `${(6000 - 60) / 2}d${(8000 - 450) / 2}`,
	});

	useEffect(() => {
		dispatch(resetBotAdded());

		if (botId && tenantId) {
			document
				.getElementById('root')
				?.setAttribute('data-botid', activeBot.encBotId);
			dispatch(
				changeBotSession({
					bot_id: botId,
					tenantId: tenantId,
				})
			);
			dispatch(getQueries({ bot_id: botId, tenant_id: tenantId }));
			dispatch(setName(activeBot.botName));
		}

		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (queries.length > 0 && !activeQuery) {
			dispatch(setActiveQuery(queries[0]));
			dispatch(setActiveQueryIndex(0));
		}
	}, [queries]);

	useEffect(() => {
		if (activeQuery && botId && tenantId) {
			dispatch(
				getActions({ botId, tenantId, workflowId: activeQuery.workflowId })
			);

			setCommonProps((props) => ({
				...props,
				workflow_id: activeQuery.workflowId,
			}));
			// setLoaderOnQueryChange(true);
		}
	}, [activeQuery]);

	useEffect(() => {
		if (isQueryDeleting === 'deleted') {
			dispatch(resetQueryDeleted());
			closeDelete();
			dispatch(set('Query deleted!'));
		} else if (isQueryDeleting === 'failed') {
			dispatch(set('Failed to delete query!'));
		}
	}, [isQueryDeleting]);

	const getMenuForQuries = (query: Query) => {
		return [
			{
				label: <>Edit</>,
				onClick: () => {
					navigate('edit-query', { state: query });
					setOpen(false);
				},
			},
			{
				label: <>Delete</>,
				onClick: () => {
					setDel(query);
					setOpen(false);
				},
			},
		];
	};

	useEffect(() => {
		setAnchorEl(null);
	}, [isQueryEditing, isQueryDeleting]);

	const handleAddAction = (type: number) => {
		activeQuery?.workflowId &&
			dispatch(
				addAction({
					userResponseTypeID: type,
					...commonProps,
					task_name: getActionName(actions, type),
				} as AddAction)
			);
	};

	const handleDeleteQuery = () => {
		const query = del;

		if (query) {
			dispatch(
				deleteQuery({
					node_ids: query.nodeId,
					workflowId: query.workflowId,
				})
			);

			if (query.nodeId === activeQuery?.nodeId) {
				dispatch(setActiveQuery(null));
				dispatch(setActiveQueryIndex(null));
			}
		}
	};

	const closeDelete = () => {
		setDel(false);
	};

	const getCenter = (state: any) => {
		const builderPaneBounds = builderPaneRef.current?.getBoundingClientRect();
		builderPaneBounds &&
			setCenter([
				parseInt(
					`${Math.abs(state.positionX) + builderPaneBounds.width / 2 - 450 / 2}`
				).toString(),
				parseInt(
					`${Math.abs(state.positionY) + builderPaneBounds.height / 2 - 60 / 2}`
				).toString(),
			]);
	};

	return (
		<>
			<div className='ff-bot-builder'>
				{Boolean(botId) && (
					<div className='ff-builder'>
						{areQueriesLoading ? (
							<div className='query-loader'>
								<PageLoader message='Loading queries' />
							</div>
						) : (
							<>
								{queries.length > 0 ? (
									<>
										<div className='query-pane'>
											<div className='query-header'>
												{/* <h4>Customer Journeys</h4>{' '} */}
												{/*change bug in header and button name*/}
												<Button
													fullWidth
													startIcon={<BotBuilderPlus width='20px' />}
													onClick={() => navigate('add-query')}>
													Add Customer Journey
												</Button>
											</div>
											<div className='query-list'>
												{queries.length > 0 &&
													queries.map((query, index) => (
														<div
															className={`query${
																activeQuery &&
																activeQuery.nodeId === query.nodeId
																	? ' active'
																	: ''
															}`}
															key={query.nodeId}
															onClick={() => {
																dispatch(setActiveQuery(query));
																setLoaderOnQueryChange(true);
																dispatch(setActiveQueryIndex(index));
															}}>
															<p className='body1'>{query.displayQuestion}</p>

															<InteractiveButton
																icon={<ThreeDotMenu width='15px' />}
																title='More Options'
																onClick={(event) => {
																	event.stopPropagation();
																	setAnchorEl(event.target as HTMLElement);
																	setOpen(true);
																	setOptions(getMenuForQuries(query));
																	dispatch(setQueryEditIndex(index));
																}}
															/>
														</div>
													))}

												<Menu
													anchorEl={anchorEl}
													open={open}
													setOpen={setOpen}
													options={options}
												/>
											</div>
										</div>

										{actions.length === 0 && areActionsLoading && (
											<div className='builder-pane loading'>
												<PageLoader message='Loading actions' />
											</div>
										)}

										{actions.length > 0 && (
											<div className='builder-pane' ref={builderPaneRef}>
												<Xwrapper>
													{areActionsLoading && loaderOnQueryChange ? (
														<div className='builder-pane loading'>
															<FFLoader />
															<p className='body1'>Loading actions</p>
														</div>
													) : (
														<>
															<TransformWrapper
																onInit={(ref) => {
																	let rootTaskPotision;
																	const taskRoot = actions.filter(
																		(action: any) => action.taskroot
																	);

																	if (taskRoot.length > 0) {
																		rootTaskPotision =
																			taskRoot[0].task_position.split('d');
																	} else {
																		rootTaskPotision =
																			actions[0].task_position.split('d');
																	}

																	const builderPaneBounds =
																		builderPaneRef.current?.getBoundingClientRect();
																	builderPaneBounds &&
																		ref.setTransform(
																			-Number(rootTaskPotision[1]) -
																				450 / 2 +
																				builderPaneBounds.width / 2,
																			-Number(rootTaskPotision[0]) -
																				60 / 2 +
																				builderPaneBounds.height / 2,
																			1,
																			0
																		);
																	setLoaderOnQueryChange(false);
																	getCenter(ref.state);
																}}
																disabled={isMoveable}
																limitToBounds={false}
																minScale={0.5}
																maxScale={1}
																pinch={{ step: 5 }}
																onZoom={() => updateXarrow()}
																onZoomStop={() => updateXarrow()}
																onPanningStop={(ref) => getCenter(ref.state)}>
																{({ zoomIn, zoomOut, state, setTransform }) => (
																	<>
																		<TransformComponent contentClass='transform-container'>
																			<div className='flow-container'>
																				{actions &&
																					actions.map((task) => (
																						<Task
																							key={task.task_ID}
																							task={task}
																							scale={state.scale}
																							anchors={anchors}
																							setAnchors={setAnchors}
																							setIsMoveable={setIsMoveable}
																							setTaskMenuOptions={
																								setTaskMenuOptions
																							}
																							setOpenTaskMenu={setOpenTaskMenu}
																							setTaskMenuAnchor={
																								setTaskMenuAnchor
																							}
																						/>
																					))}
																			</div>
																		</TransformComponent>
																		<div className='arrows'>
																			{actions.length > 0 &&
																				actions.map((task, i) => {
																					if (task.taskHierarchyDTO.length > 0)
																						return task.taskHierarchyDTO.map(
																							(taskId) => {
																								let anchor = anchors.filter(
																									(anchor) =>
																										anchor.from ===
																											task.task_ID &&
																										anchor.to === taskId
																								);

																								if (anchor.length === 0)
																									return null;

																								return (
																									<Xarrow
																										key={`task_${task.task_ID}-task_${taskId}`}
																										curveness={0.5}
																										start={`task_${task.task_ID}`}
																										end={`task_${taskId}`}
																										startAnchor={
																											anchor[0].start
																										}
																										endAnchor={anchor[0].end}
																										color='#1b2050'
																										strokeWidth={1.5}
																										zIndex={-1}
																										// dashness={{
																										// 	animation: 2,
																										// 	strokeLen: 8,
																										// 	nonStrokeLen: 4,
																										// }}
																									/>
																								);
																							}
																						);

																					return null;
																				})}
																		</div>
																		<div
																			className='focus-start'
																			onClick={() => {
																				// let pos = actions
																				// 	.filter((action) => action.taskroot)[0]
																				// 	.task_position.split('d');
																				let pos;
																				const taskRoot = actions.filter(
																					(action: any) => action.taskroot
																				);

																				if (taskRoot.length > 0) {
																					pos =
																						taskRoot[0].task_position.split(
																							'd'
																						);
																				} else {
																					pos =
																						actions[0].task_position.split('d');
																				}

																				const builderPaneBounds =
																					builderPaneRef.current?.getBoundingClientRect();

																				builderPaneBounds &&
																					setTransform(
																						-Number(pos[1]) -
																							450 / 2 +
																							builderPaneBounds.width / 2,
																						-Number(pos[0]) -
																							60 / 2 +
																							builderPaneBounds.height / 2,
																						1
																					);
																			}}>
																			<Tooltip title='Center' placement='right'>
																				<div className='focus-btn'>
																					<Flag width='16px' />
																				</div>
																			</Tooltip>
																		</div>
																		<div className='zoom-control'>
																			<Tooltip
																				title={
																					state.scale === 1 ? '' : 'Zoom In'
																				}
																				placement='right'>
																				<div
																					className={`zoom-btn zoom-in${
																						state.scale === 1 ? ' disabled' : ''
																					}`}
																					onClick={() => zoomIn()}>
																					<ZoomIn width='20px' />
																				</div>
																			</Tooltip>
																			<div className='spacer'>
																				<div></div>
																			</div>
																			<Tooltip
																				title={
																					state.scale === 0.5 ? '' : 'Zoom Out'
																				}
																				placement='right'>
																				<div
																					className={`zoom-btn zoom-out${
																						state.scale === 0.5
																							? ' disabled'
																							: ''
																					}`}
																					onClick={() => zoomOut()}>
																					<ZoomOut width='20px' />
																				</div>
																			</Tooltip>
																		</div>
																		<div
																			className={`active-query-display-question ${
																				testAndOpenBot ? 'position-change' : ''
																			}`}>
																			<h6 className={`display-question `}>
																				{activeQuery?.displayQuestion}
																			</h6>
																		</div>
																	</>
																)}
															</TransformWrapper>
														</>
													)}
												</Xwrapper>
												<div
													className={`add-action-btn${
														addingTask ? ' open' : ''
													} ${
														testAndOpenBot
															? 'add-action-btn-position-change'
															: ''
													}`}>
													<AddLinkedActionMenu
														open={addingTask}
														position={center}
														onClose={() => {
															setAddingTask(false);
															setIsMoveable(false);
														}}
													/>
													<AddActionTask
														width='36px'
														onClick={(event) => {
															event.stopPropagation();
															setAddingTask(!addingTask);
														}}
													/>
												</div>
												<Menu
													anchorEl={taskMenuAnchor}
													open={openTaskMenu}
													setOpen={setOpenTaskMenu}
													options={taskMenuoptions}
												/>
											</div>
										)}

										{actions.length === 0 && !areActionsLoading && (
											<div className='builder-pane no-actions-pane'>
												<div className='actions'>
													<h6>
														Choose an Action to start your Customer Journey
													</h6>
													<ActionsMenu
														action1={{
															label: <p>Show Text & Media</p>,
															onClick: () => handleAddAction(1),
														}}
														action5={{
															label: <p>Multiple Choice Options</p>,
															onClick: () => handleAddAction(5),
														}}
														action3={{
															label: <p>Multiple Select Options</p>,
															onClick: () => handleAddAction(3),
														}}
														action150={{
															label: <p>Collect User Input</p>,
															onClick: () => handleAddAction(150),
														}}
														action11={{
															label: <p>Collect User Rating</p>,
															onClick: () => handleAddAction(11),
														}}
														action10={{
															label: <p>Call an API</p>,
															onClick: () => handleAddAction(10),
														}}
														action0={{
															label: <p>Handoff To Agent</p>,
															onClick: () => handleAddAction(0),
														}}
														action12={{
															label: <p>Trigger another Query</p>,
															onClick: () => handleAddAction(12),
														}}
														action14={{
															label: <p>Send Collected Values</p>,
															onClick: () => handleAddAction(14),
														}}
														action8={{
															label: <p>Confirm Collected Values</p>,
															onClick: () => handleAddAction(8),
														}}
													/>
												</div>
											</div>
										)}
									</>
								) : (
									<div className='no-query-found'>
										<img src={createFirstQuery} alt='' />
										<h3>Add your first Customer Journey</h3>{' '}
										{/* change bug in changing the header and button name*/}
										<Button
											fullWidth
											startIcon={<BotBuilderPlus width='20px' />}
											onClick={() => navigate('add-query')}>
											Add Customer Journey
										</Button>
									</div>
								)}
							</>
						)}
					</div>
				)}
			</div>
			<Outlet />
			{del && (
				<Modal
					header='Delete Query'
					onClose={closeDelete}
					actionButtons={
						<>
							<Button onClick={closeDelete} buttonType='light'>
								Cancel
							</Button>
							<Button
								onClick={handleDeleteQuery}
								loading={isQueryDeleting === 'deleting'}>
								Delete
							</Button>
						</>
					}>
					<div className='ff_confirm_delete'>
						Please confirm that you want to delete <b>{del.displayQuestion}</b>.
					</div>
				</Modal>
			)}
		</>
	);
};

export default BotBuilder;
