import styled from 'styled-components';
import { BodyProps } from './model';

type NewAIBodyProps = {
	colorScale: string[];
	mode: string;
};

export const Body = styled.div<BodyProps>`
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	max-height: ${(props) =>
		props.iconOutSide ? 'calc(100vh - 191px)' : 'calc(100vh - 153px)'};
	background-image: ${(props) =>
		props.background ? `url(${props.background})` : ''};
	background: ${(props) =>
		props.gradient
			? `linear-gradient(
					${props.gradient.backgroundGradientStartColor},
					${props.gradient.backgroundGradientStopColor})`
			: ''};
	background-position: 50% center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: ${(props) => props.background ?? ''};
`;

export const NewAIBody = styled.div<NewAIBodyProps>`
	&.getintouch,
	&.mandatorySelection {
		padding: 16px 24px;
		overflow: hidden auto;
		padding-bottom: 38.5px;
		& > .forms {
			width: 100%;
			& > p {
				color: ${(props) => props.colorScale[1]} !important;
			}
			& > div > .button-holder-submit-cancel {
				position: initial !important;
				& > .custom-button {
					width: 90px;
				}
			}
		}
	}
	height: calc(100vh - 271px);
	color: ${(props) =>
		props.mode === 'dark' ? '#ffffff' : props.colorScale[0]};
	& .getintouch {
		& .time-feedback {
			margin-top: 0;
		}
		& .MuiFormControl-root {
			padding: 0 !important;
		}
	}
`;
type HomeScreenProps = NewAIBodyProps & {
	fontSize: string;
	selectedColor: string;
};
export const HOMESCREENDIV = styled.div<HomeScreenProps>`
	height: 100%;
	padding: 24px;
	padding-bottom: 120px;
	background-color: unset;
	overflow-y: auto;
	color: ${(props) =>
		props.mode === 'dark' ? '#ffffff' : props.colorScale[0]};
	& > .ff-preview-home-screen {
		& > h4 {
			color: inherit;
			overflow-wrap: anywhere;
			font-family: inherit;
			font-size: ${(props) => Number(props.fontSize.substring(0, 2)) * 1.875}px;
			text-shadow: 0 2px 4px rgba(0, 0, 0, 0.28);
		}
		& > .home-input {
			margin-top: 16px;
			margin-bottom: 12px;
			padding: 12px 16px;
			border-radius: 12px;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
			background-color: var(--white);

			& .ff-input .MuiInputBase-root {
				border-radius: 20px;
				& > input {
					font-family: inherit;
					font-size: ${(props) =>
						Number(props.fontSize.substring(0, 2)) * 0.875}px;
				}
				&.Mui-disabled input {
					color: rgba(0, 0, 0, 0.6) !important;
					-webkit-text-fill-color: rgba(0, 0, 0, 0.6) !important;
				}
			}
		}
		& > .introductory-card-heading {
			padding: 16px;
			border-radius: 12px;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
			background-color: var(--white);
			margin-bottom: 16px;

			& > p:first-child {
				font-weight: 600;
				margin-bottom: 12px;
				font-family: inherit;
				font-size: ${(props) =>
					Number(props.fontSize.substring(0, 2)) * 0.875}px;
			}
			& > p:last-child {
				color: var(--black-60);
				font-family: inherit;
				font-size: ${(props) =>
					Number(props.fontSize.substring(0, 2)) * 0.875}px;
			}
		}
		& > .carousel-heading {
			margin-bottom: 8px;
			color: inherit;
			font-family: inherit;
			font-size: ${(props) => Number(props.fontSize.substring(0, 2)) * 0.75}px;
			font-weight: 600;
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.28);
		}
		& > .carousel-container {
			& > .carousel-root {
				& > .carousel-slider {
					position: relative !important;
					padding-bottom: 26px;
					& > .control-dots {
						display: flex;
						justify-content: center;
						& > .dot {
							opacity: 1;
							box-shadow: none;
							margin: 0px 3px !important;
							width: 6px !important;
							height: 6px !important;
							background: #ffffff;
							border: 1px solid
								${(props) =>
									props.mode === 'dark'
										? props.selectedColor
										: props.colorScale[0]};
							&.selected {
								background: ${(props) =>
									props.mode === 'dark'
										? props.selectedColor
										: props.colorScale[0]} !important;
							}
						}
					}
					& > .slider-wrapper {
						& > .slider {
							gap: 8px;
							left: unset !important;
							width: 100% important;
							& > li {
								& > div > img {
									border-radius: 20px;
									min-height: 120px;
									max-height: 120px;
									object-fit: cover;
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const CHATDIV = styled.div`
	background: white;
	border-bottom-left-radius: 24px;
	border-bottom-right-radius: 24px;
	position:relative;
	&.formsdisplay{
		& > .chat-footer {
	
			& > div.inputbox {
				display:none;
			}
			& > div.feedback-copywrite {
				margin-top: 44px;
			}
		}
		
	}
	& > .chat-body {
		padding: 46.5px 16px;
		display: flex;
		flex-direction: column;
		height: calc(100vh - 246px);
		overflow-y:auto;
		overflow-x:hidden;
	}
	& > .chat-footer {
		padding: 0 16px;
		position:relative;
		& > div.inputbox {
			& .MuiInputBase-root {
				border-radius: 20px;
				opacity: 0.8;
				height: 38.64px;
				& > input {
					font-family: Inter;
					font-size: 14px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.5;
					letter-spacing: 0.42px;
				}
				&>.interactive-button{
					&>button>svg{
						min-width:18px;
						max-width:18px;
						min-height:12px;
						max-height:12px;
					}
				}
			}
		}
		& > div.feedback-copywrite {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 8px;
			& > p {
				font-family: Inter;
				font-size: 10px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.8;
				letter-spacing: 0.33px;
				text-align: right;
				color: var(--black-40);
			}
		}
		&>div.menus{
				width: 344px;
				padding: 8px 0;
				border-radius: 8px;
				box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.16);
				background-color: var(--white);
				position: absolute;
				bottom: 90px;
				left: 22px;
				&::before {
					margin-bottom: 4px;
		
					content: '';
					position: absolute;
					top: 100%;
					left: 6%;
					width: 0;
					border-top: 10px solid #ffffff;
					border-right: 12px solid transparent;
				}
				& > .wrapper {
					max-height: 202px;
		
					overflow: auto;
					& > p {
						cursor: pointer;
						padding: 12px 12px 13px;
						&:hover {
							background-color: var(--black-5);
						}
						&:active,
						&:focus {
							background-color: var(--violet-100);
						}
					}
				}
	}
`;

export const CHATBUBBLEEXDIV = styled.div`
	&.workflow {
		width: 101.5%;
	}
	&.fullscreen{
        &>.user-holder{
			justify-content: flex-start;
			&>.user{
				width:100%;
				border-radius:0px;
				background-color:#fff;
				padding-left: 30px;
				>p{
					color:#212121;
				}
			}
		}
		quicklinks{
			display:none;
		}
		&>.ai-holder{
			>img{
				display:none;
			}
			>.ai{
				border-radius:0px;
				width:100%;
				display:flex;
				justify-content: flex-start;
                align-items: center;
				background-color:#E5E5E5;
				padding-left: 30px;	
				border:none;
				height:56px;
				>p{
					color:#21518D;
				}
			}
		}
		
	}
	&.user {
		align-self: flex-end;
		& > .time-feedback {
			padding-right: 11px;
		}
	}
	&.ai {
		align-self: flex-start;
	}

	&.ai:first-child {
		margin-bottom: 40px;
	}
	&.user + &.ai,
	&.ai + &.user {
		margin-bottom: 40px;
	}
	&.user + &.forms {
		margin-bottom: 0;
	}
	& > .user-holder {
		display: flex;
		justify-content: flex-end;
	}
	& > .ai-holder {
		& > img {
			min-width: 36px;
			max-width: 36px;
			min-height: 36px;
			max-height: 26px;
			margin-right: 8px;
			border-radius: 50%;
			margin-bottom: 4px;
		}
		& > div {
			& > p {
				overflow-wrap: anywhere;
			}
		}
		& .MuiFormControl-root {
			padding: 0 !important;
		}
	}
	& > .time-feedback {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-top: 16px;
		padding: 0 20px;
		padding-right: 26px;
		&.workflow {
			padding: initial;
			width: 100%;
		}
		& > div {
			display: flex;
			align-items: center;
			& > svg:first-child {
				margin-right: 8px;
			}
		}
		&.forms {
			margin-top: 12px;
		}
	}
	& > .quicklinks {
		display: flex;
		flex-wrap: wrap;
		gap: 10px 16px;
		margin-top: 16px;
	}
`;

type CHATBUBBLEDIVPROPS = {
	colorScale: string[];
	mode: string;
	selectedColor: string;
};

export const CHATBUBBLEDIV = styled.div<CHATBUBBLEDIVPROPS>`
	width: fit-content;
	padding: 12px 16px;
	border-radius: 20px;

	&.user {
		background-color: ${(props) => props.selectedColor};
		border-top-right-radius: unset !important;
		& > p {
			color: ${(props) =>
				props.mode === 'dark' ? '#ffffff' : props.colorScale[0]};
		}
	}


	&.ai {
		border: solid 1px rgba(0, 0, 0, 0.2);
		border-top-left-radius: unset !important;
	}
	&.forms {
		padding: 0;
		width: 100%;
	}
`;

type FORMSDIV = CHATBUBBLEDIVPROPS & {
	outsideChat?: boolean;
};

export const FORMS = styled.div<FORMSDIV>`
	& > p {
		color: ${(props) => {
			if (props.outsideChat) {
				return props.mode === 'dark' ? 'white' : props.colorScale[1];
			}
			return props.colorScale[1];
		}};
		margin-bottom: 8px;
		margin-top: 24px;
		font-weight: 600;
		&.caption {
			margin-top: 0px;
			margin-bottom: 16px;
			font-weight: normal;
			color: ${(props) => {
				if (props.outsideChat) {
					return props.mode === 'dark' ? 'white' : props.colorScale[1];
				}
				return 'var(--black-60)';
			}};
		}
	}
	& > .ff-checkbox {
		margin-bottom: 16px !important;
		& > label {
			gap: 8px;
			& > .check {
				& path:last-child {
					fill: ${(props) => {
						if (!props.outsideChat) {
							return `${props.colorScale[1]} !important`;
						}
						return props.mode === 'dark' ? 'white' : props.colorScale[1];
					}};
				}
			}
			& > .label {
				& > p {
					color: ${(props) => {
						if (props.outsideChat) {
							return props.mode === 'dark' ? 'white' : 'black';
						}
						return 'black';
					}};
				}
			}
		}
	}
	& > .ff-radio {
		margin-bottom: 16px !important;
		& > label {
			gap: 8px;
			& > .label {
				& > p {
					color: ${(props) => {
						if (props.outsideChat) {
							return props.mode === 'dark' ? 'white' : 'black';
						}
						return 'black';
					}};
				}
			}
			& > .check {
				& > svg > path:last-child {
					fill: ${(props) => {
						if (props.outsideChat) {
							return props.mode === 'dark' ? 'white' : props.colorScale[1];
						}
						return props.mode === 'dark' ? 'initial' : props.colorScale[1];
					}};
				}
			}
		}
	}
	& > .MuiFormControl-root {
		min-width: 278px !important;
		max-width: unset !important;
		& input {
			font-size: 14px;
			color: var(--black-60);
		}
		& .MuiInputBase-root {
			background-color: white;
			pointer-events: none;
			cursor: not-allowed;
			&:not(.Mui-disabled, .Mui-error).Mui-focused {
				.MuiOutlinedInput-notchedOutline {
					border-color: ${(props) => props.colorScale[1]} !important;
				}
			}
		}
	}
	& > .button-holder-submit-cancel {
		gap: 15px;
		display: flex;
		position: absolute;
		bottom: 46px;
		width: 92%;
		& > .custom-button:last-child {
			width: 50%;

			margin-top: 12px !important;
			pointer-events: none;
			cursor: not-allowed;
			opacity: 0.6;
			& > button {
				background-color: ${(props) =>
					props.mode === 'dark'
						? props.colorScale[9]
						: props.colorScale[7]} !important;
				& > .custom-button-body {
					color: ${(props) =>
						props.mode === 'dark'
							? props.selectedColor
							: props.colorScale[2]} !important;
				}
			}
		}
		& > .custom-button:first-child {
			width: 50%;

			margin-top: 12px !important;
			pointer-events: none;
			cursor: not-allowed;
			opacity: 0.6;
			& > button {
				background-color: ${(props) => {
					if (props.outsideChat) {
						return props.mode === 'dark' ? 'white' : props.colorScale[1];
					}
					return props.mode === 'dark'
						? props.selectedColor
						: props.colorScale[1];
				}} !important;
				& > .custom-button-body {
					color: ${(props) => {
						if (props.outsideChat) {
							return props.mode === 'dark'
								? props.selectedColor
								: 'white !important';
						}
						return 'white !important';
					}};
				}
			}
		}
	}
	& > div {
		& > .MuiAutocomplete-root {
			margin-bottom: 24px !important;
			& .MuiInputBase-root {
				background-color: white;
				padding: 0;
				padding-left: 5px;
				&:not(.Mui-disabled, .Mui-error).Mui-focused {
					.MuiOutlinedInput-notchedOutline {
						border-color: ${(props) => props.colorScale[1]} !important;
					}
				}
			}
		}
	}
`;

export const GETINTOUCHDIV = styled.div<{
	fontSize: string;
	colorScale: string[];
}>`
	padding: 16px 24px;
	color: inherit;

	& > .description {
		margin-bottom: 24px;
		color: inherit;
		font-family: inherit;
		font-size: ${(props) => Number(props.fontSize.substring(0, 2)) * 0.875}px;
	}
	& > .title {
		font-weight: bold;
		color: inherit;
		font-family: inherit;
		font-size: ${(props) => Number(props.fontSize.substring(0, 2)) * 0.875}px;
	}
	& .MuiInputBase-root {
		background-color: white;
		margin-bottom: 24px;
	}
	& > .custom-button {
		margin-top: 12px;
		margin-bottom: 20px;
		& > button {
			background-color: ${(props) => props.colorScale[1]} !important;
		}
	}
	& > .countrycode-phonenumber {
		display: flex;
		align-items: center;
		gap: 12px;
		& > .countrycode {
			width: 30%;
			& .MuiInputBase-root {
				height: 36px;
				width: 41%;
				cursor: inherit;
				pointer-events: none;
				padding: 0 !important;
				padding-left: 12px !important;
			}
		}
		& > .phonenumber {
			width: 70%;
			& .MuiInputBase-root {
				margin-right: 24px;
			}
		}
	}
`;

type PreviewQuicklinksProps = {
	colorScale: string[];
	selectedColor: string;
};

export const PreviewQuicklinksDiv = styled.div<PreviewQuicklinksProps>`
	max-width: 251px;
	overflow-wrap: anywhere;
	display: flex;
	align-items: center;
	width: fit-content;
	padding: 8px 16px;
	border-radius: 16.5px;
	// color: ${(props) => props.selectedColor};
	border: solid 1px ${(props) => props.colorScale[6]};
	background-color: ${(props) => props.colorScale[9]};
	&:hover {
		cursor: pointer;
	}

	& > p {
		color: ${(props) => props.colorScale[2]};
	}
`;
