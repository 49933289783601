import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { SuggestedUsers, Users } from '../../../Features/users/modal';
import { KampstaUserBadgeBackground } from '../../../utils/constants';
import { loadState } from '../../../utils/utils';
import { User } from '../../Login/model';
import './userBadge.scss';

type Props = {
	email: string | undefined;
};

function UserBadge({ email }: Props) {
	return (
		<div>
			{email ? (
				<div
					className='kampsta-user-badge'
					style={
						KampstaUserBadgeBackground[
							((email[0] || 'Fallback User').toUpperCase().charCodeAt(0) *
								email.charCodeAt(email.length - 1) || email.length) % 30
						]
					}>
					{email[0].toUpperCase() || 'U'}
				</div>
			) : null}
		</div>
	);
}

export default UserBadge;
