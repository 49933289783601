import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	AnalyticsTopQueries,
	topQueriesViewAll,
} from '../../../Features/Analytics/analyticsSlice';
import { ViewAllTemplatesArrow } from '../../../assets/Icons';
import './topQueries.scss';

type TopQuery = {
	query: string;
	count: number;
};

function TopQueries() {
	const location = useLocation();
	const path = location.pathname;
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { activeBot } = useSelector((state: RootState) => state.bots);
	const {
		selectedDates,
		calendarstatus,
		analyticsTopQueriesData,
		activeAnalyticsBot,
	} = useSelector((state: RootState) => state.analytics);

	const [topQueries, setTopQueries] = useState<TopQuery[]>([]);
	useEffect(() => {
		let data: any[] = [];
		Object.keys(analyticsTopQueriesData.mapObject)?.forEach(function (e) {
			data.push({ query: e, count: analyticsTopQueriesData.mapObject[e] });
		});
		setTopQueries(data);
	}, [analyticsTopQueriesData.mapObject]);
	const navigate = useNavigate();

	const sortlist = topQueries.sort(
		(a: TopQuery, b: TopQuery) => b.count - a.count
	);

	const sortedQueryList =
		path === '/app/bot/analytics/viewall-topqueries' ||
		path === '/app/analytics/multichannel-storefronts/bot/viewall-topqueries' ||
		path === '/app/analytics/intelligent-ads/bot/viewall-topqueries'
			? sortlist
			: sortlist.slice(0, 5);

	const calculateWidth = (count: number) => {
		// let newWidth = (122 / sortedQueryList[0].count) * count;
		// return newWidth + 'px';
		let newWidth = (count * 100) / sortedQueryList[0].count;
		return newWidth + '%';
	};
	const dispatch = useAppDispatch();

	const viewAll = () => {
		navigate('viewall-topqueries');
		// dispatch(topQueriesViewAll(true));
	};

	useEffect(() => {
		if (calendarstatus) {
			tenantId &&
				dispatch(
					AnalyticsTopQueries({
						startdate: selectedDates[0],
						enddate: selectedDates[1],
						timezone: -330,
						tenantId: tenantId,
						botId: activeAnalyticsBot.botId,
					})
				);
		}
	}, [selectedDates]);

	return (
		<>
			{path === '/app/bot/analytics/viewall-topqueries' ||
			path ===
				'/app/analytics/multichannel-storefronts/bot/viewall-topqueries' ||
			path === '/app/analytics/intelligent-ads/bot/viewall-topqueries' ? (
				''
			) : (
				<div>
					<div className='ff_topQueries_heading'>
						<h5>Top Queries</h5>
					</div>
				</div>
			)}
			{sortedQueryList.length === 0 ? (
				<div className='ff_topQueries_Nodata'>No data to display</div>
			) : (
				<div className='ff_topquery_table'>
					<div className='ff_topQueries_table_heading'>
						<h5 className='ff_topQueries_sub_heading'>Query</h5>
						<h5 className='ff_topQueries_sub_heading'>Count</h5>
					</div>

					{sortedQueryList.map((query: any, key) => {
						return (
							<div className='ff_topquery_data'>
								<div className='ff_topQuery body1'>
									<div
										className='
									ff_topQuery_text'>
										{query.query}
									</div>
								</div>
								<div className='ff_topQuery_count_container'>
									<div className='body1'>{query.count}</div>
									<div
										className='ff_topQuery_count_bar'
										style={{
											width:
												query.count === sortedQueryList[0].count
													? '100%'
													: calculateWidth(query.count),
										}}></div>
								</div>
							</div>
						);
					})}
				</div>
			)}
			{path === '/app/bot/analytics/viewall-topqueries' ||
			path ===
				'/app/analytics/multichannel-storefronts/bot/viewall-topqueries' ||
			path === '/app/analytics/intelligent-ads/bot/viewall-topqueries' ? (
				''
			) : (
				<div className='view-all-container'>
					{/* empty container for applying space between */}
					<div></div>
					<div className='ff_topQueries_viewall' onClick={viewAll}>
						<div className='ff_topQueries_viewall_text button1'>View all</div>
						<div className='ff_topQueries_viewall_arrow'>
							<ViewAllTemplatesArrow />
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default TopQueries;
