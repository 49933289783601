import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../app/store';
import Radio from '../../../../Components/Radio';
import {
	setBackgroundGradientRange,
	setBackgroundGradientStartColor,
	setBackgroundGradientStopColor,
	setBackgroundImageLoader,
	setBackgroundSolidColor,
	setBotBackground,
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import { setSnack } from '../../../../Features/message/messageSlice';
import './Background.scss';
import Preview from './Preview';
import { updateBotdetails } from '../../../../Features/configure/configureSlice';
const Background = () => {
	const {
		botBackground,
		backgroundSolidColor,
		backgroundGradientStartColor,
		backgroundGradientStopColor,
		backgroundGradientRange,
		backgroundFile,
		backgroundImageLoader,
	} = useSelector((state: RootState) => state.experienceonweb);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const showPlaceholder = () => {
		return (
			<Preview
				value1={backgroundSolidColor}
				value2={[
					backgroundGradientStartColor,
					backgroundGradientRange,
					backgroundGradientStopColor,
				]}
				onValueChange1={(value) => dispatch(setBackgroundSolidColor(value))}
				onValueChange2={[
					(value) => {
						dispatch(setBackgroundGradientStartColor(value));
					},
					(value) => {
						dispatch(setBackgroundGradientRange(value));
					},
					(value) => {
						dispatch(setBackgroundGradientStopColor(value));
					},
				]}
				background={botBackground}
			/>
		);
	};

	useEffect(() => {
		if (botBackground === 'image' && !backgroundFile) {
			navigate('add-image', { state: { value: 'BackgroundImage' } });
		}
	}, [botBackground]);

	return (
		<div className='ff-eow-background'>
			<h4>Background</h4>
			<div className='background'>
				<Radio
					label='Solid color'
					value='color'
					isChecked={botBackground}
					onValueChange={(value) => {
						dispatch(setBotBackground(value));
					}}
					name='background'
				/>
				{botBackground === 'color' && showPlaceholder()}
				<Radio
					label='Gradient'
					value='gradient'
					isChecked={botBackground}
					onValueChange={(value) => {
						dispatch(setBotBackground(value));
					}}
					name='background'
				/>
				{botBackground === 'gradient' && showPlaceholder()}
				<Radio
					label='Image'
					value='image'
					isChecked={botBackground}
					onValueChange={(value) => {
						dispatch(setBotBackground(value));
					}}
					name='background'
				/>
				{botBackground === 'image' && showPlaceholder()}
			</div>
			<Outlet />
		</div>
	);
};

export default Background;
