import { type } from 'os';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	getHighestDropOffRate,
	highestDropOffRates,
} from '../../../Features/Analytics/analyticsSlice';
import {
	AnalyticsDialogarrow,
	ViewAllTemplatesArrow,
} from '../../../assets/Icons';
import {
	APICallOption,
	ConfirmCollectedOption,
	KeyboardOption,
	MultiChoiceOption,
	MultiSelectOption,
	SendCollectOption,
	StarOption,
	TextOrImage,
	TriggedQueryOption,
} from '../icon';
import './dropoffrates.scss';

function HighestDropOffRate() {
	const Icons: any = {
		1: <TextOrImage width='24px' height='24px' />,
		3: <MultiSelectOption width='24px' height='24px' />,
		5: <MultiChoiceOption width='24px' height='24px' />,
		8: <ConfirmCollectedOption width='24px' height='24px' />,
		10: <APICallOption width='24px' height='24px' />,
		11: <StarOption width='24px' height='24px' />,
		12: <TriggedQueryOption width='24px' height='24px' />,
		150: <KeyboardOption width='24px' height='24px' />,
		14: <SendCollectOption width='24px' height='24px' />,
	};

	const {
		dialogviewallQueries,
		dropOffRates,
		highestDropoffRateData,
		selectedDates,
		calendarstatus,
		selectedTriggeredQuery,
		activeAnalyticsBot,
	} = useSelector((state: RootState) => state.analytics);
	const { activeBot } = useSelector((state: RootState) => state.bots);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const navigate = useNavigate();
	let newDropOffRateData: any = [];

	if (Object.keys(highestDropoffRateData.data).length !== 0) {
		highestDropoffRateData.data.map((item: any, i: any) => {
			newDropOffRateData.push({
				userResponseTypeId: item.userResponseTypeId,
				taskName: item.actionName,
				triggerCount: item.total,
				completionCount: item.success,
				dropoffRate: ((item.success / item.total) * 100).toFixed(2),
			});
		});
	}

	const sortList = newDropOffRateData.sort(
		(a: any, b: any) => b.dropoffRate - a.dropoffRate
	);
	const location = useLocation();
	const path = location.pathname;
	const sortedQueryList =
		path === '/app/bot/analytics/viewall-highestdropoffrate' ||
		path ===
			'/app/analytics/multichannel-storefronts/bot/viewall-highestdropoffrate' ||
		path === '/app/analytics/intelligent-ads/bot/viewall-highestdropoffrate'
			? sortList
			: sortList.slice(0, 5);

	const calculateWidth = (triggerCount: number, completionCount: number) => {
		let newWidth = (122 / completionCount) * triggerCount;
		return newWidth + 'px';
	};

	const dispatch = useAppDispatch();
	const viewAll = () => {
		navigate('viewall-highestdropoffrate');

		// dispatch(highestDropOffRates(true));
	};

	useEffect(() => {
		if (calendarstatus) {
			tenantId &&
				dispatch(
					getHighestDropOffRate({
						startdate: selectedDates[0],
						enddate: selectedDates[1],
						timezone: -330,
						tenantId: tenantId,
						botId: activeAnalyticsBot.botId,
						wfId: selectedTriggeredQuery.triggeredQueryId,
					})
				);
		}
	}, []);

	return (
		<>
			{path === '/app/bot/analytics/viewall-highestdropoffrate' ||
			path ===
				'/app/analytics/intelligent-ads/bot/viewall-highestdropoffrate' ||
			path ===
				'/app/analytics/multichannel-storefronts/bot/viewall-highestdropoffrate' ? (
				''
			) : (
				<div className='ff_dropOffRate_heading'>
					<h5 className='ff_dropOffRate_title'>Highest Drop Off Rates</h5>
				</div>
			)}
			{highestDropoffRateData.data.length === 0 ? (
				<div className='ff_dropOffRate_No_data'>No data to display</div>
			) : (
				<div className='ff_dropOffRate_table'>
					<div className='ff_dropOffRate_table_heading'>
						<h5 className='ff_dropOffRate_sub_heading'>Action name</h5>
						<h5 className='ff_dropOffRate_sub_heading'>
							No. of times triggered
						</h5>
						<h5 className='ff_dropOffRate_sub_heading'>
							No. of times completed
						</h5>
						<h5 className='ff_dropOffRate_sub_heading'>Dropoff rate</h5>
					</div>
					<div className='ff_dropOffRate_Container'>
						{sortedQueryList.map((query: any, key: any) => {
							return (
								<div className='ff_dropOffRate_data'>
									<div className='ff_dropOffRate_icon ff_dropOffRate'>
										<div>{Icons[query.userResponseTypeId]}</div>
										<div className='ff_dropOffRateText body1'>
											{query.taskName}
										</div>
									</div>
									<div className='ff_dropOffRate'>{query.triggerCount}</div>
									<div className='ff_dropOffRate'>{query.completionCount}</div>
									<div className='ff_dropOffRate_count_container'>
										<div className='ff_dropOffRate_count'>
											{isNaN(parseFloat(query.dropoffRate))
												? 'NA'
												: query.dropoffRate}
										</div>
										<div className='ff_completion_DropOffrate'>
											<div className='ff_dropOffRate_count_bar_bg'>
												<div
													className='ff_dropOffRate_count_bar_bgor'
													style={{
														width:
															sortedQueryList[0].dropoffRate == 0 &&
															query.dropoffRate == 0
																? '0px'
																: isNaN(parseFloat(query.dropoffRate))
																? '0px'
																: sortedQueryList[0].dropoffRate ===
																  query.dropoffRate
																? '124px'
																: calculateWidth(
																		query.dropoffRate,
																		sortedQueryList[0].dropoffRate
																  ),
													}}></div>
											</div>
											{/* <div
												className='ff_completion_DropOff_rate_details'
												onClick={() => {}}>
												<AnalyticsDialogarrow />
											</div> */}
										</div>
									</div>
								</div>
							);
						})}
						{path === '/app/bot/analytics/viewall-highestdropoffrate' ||
						path ===
							'/app/analytics/intelligent-ads/bot/viewall-highestdropoffrate' ||
						path ===
							'/app/analytics/multichannel-storefronts/bot/viewall-highestdropoffrate' ? (
							''
						) : (
							<div className='view-all-container'>
								{/* empty container for applying space between */}
								<div></div>
								<div className='ff_dropOffRate_viewall' onClick={viewAll}>
									<div className='ff_dropOffRate_viewall_text button1'>
										View all
									</div>
									<div className='ff_dropOffRate_viewall_arrow'>
										<ViewAllTemplatesArrow />
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}

export default HighestDropOffRate;
