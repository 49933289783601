import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { height } from '@mui/system';

type Data = { value: number; name: string }[];
interface Props {
	data: Data;
	colors?: string[];
	showLabel?: boolean
}

function PieChart({ data, colors = [], showLabel }: Props): JSX.Element {

	const defaultOptions = {
		tooltip: {
			show: true,
			trigger: 'item',
			backgroundColor: '#ccc',
			padding: [2, 6],
			textStyle: {
				color: '#ffffff',
				fontFamily: 'OpenSans',
			},
			borderWidth: 0,
			borderRadius: 5,
			shadowBlur: 0,
			shodowColor: 'transparent',
			shadowOffsetX: 0,
			shadowOffsetY: 0,
			formatter: "<p class='caption'>{b}</p><p class='caption'>{c} ({d}%)</p>",
		},
		legend: {
			show: false,
		},
		animation: true,
	};

	const chatData = [
		{
			name: 'Analytics',
			type: 'pie',
			radius: '75%',
			center: ['50%', '50%'],
			// minAngle: '10',
			color: [
				...colors,
				'rgba(107, 126, 242, 0.4)',
				'#6b7ef2',
				'#3b4fd0',
				'#5470c6',
				'#91cc75',
				'#fac858',
				'#ee6666',
				'#73c0de',
				'#3ba272',
				'#fc8452',
				'#9a60b4',
				'#ea7ccc',
			],
			animation: false,
			legendHoverLink: true,
			hoverAnimation: false,
			data: data,
			label: {
				show: showLabel || false,
				lineHeight: 56,
			},
			labelLine: {
				show: true
			},
		},
	];

	const options = {
		...defaultOptions,
		series: chatData,
	};


	const myChart: any = useRef<HTMLDivElement>(null);
	useEffect(() => {
		function resizeChart() {
			chart?.resize();
		}
		window.addEventListener('resize', resizeChart);

		const chart = echarts.init(myChart.current);
		chart.setOption(options);

		return () => {
			chart?.dispose();
			window.removeEventListener('resize', resizeChart);
		};
	}, [data, window]);

	if (data) {

		return (
			<div
				ref={myChart}
				style={{
					width: '100%',
					minHeight: '260px',
					height: '100%',
				}}
			></div>
		);
	}
	return <></>;
}
export default PieChart;
