import { Carousel } from 'react-responsive-carousel';
import './forms.scss';
import Options from '../Options';
import Cards from '../Cards';
import { getMediaType } from '../../../utils/utils';
import { carouselButton } from '../../../Pages/Configure/Appearance/GenericChatBot/CarouselComp';
import { Content } from '../../../Pages/Chats/model';
import { useEffect } from 'react';

type Props = {
	data: any
};

const Forms = ({ data }: Props) => {

	return (
		<div className='ff-form-parent' style={{ minWidth: '245px' }}>
			{
				Object.keys(data.form).map((innerAttr, index) => {
					console.log(innerAttr)
					return (
						<div className='ff-form-content'>
							<div>{innerAttr}</div>
							<div className='ff-form-input'>{data.form[innerAttr]}</div>
						</div>

					)
				})

			}





		</div>

	);
};

export default Forms;
