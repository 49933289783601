import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { ConfigurePlus } from '../../../../../assets/Icons';
import Button from '../../../../../Components/Button';
import Input from '../../../../../Components/Input';
import {
	setInputFields,
	setPersistentCustomInputFields,
} from '../../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import './QuickLinks.scss';

type Props = {
	persistent?: boolean;
};

const QuickLinks = ({ persistent = false }: Props) => {
	const { inputFieldsData, persistentinputFieldsData } = useSelector(
		(state: RootState) => state.experienceonweb
	);
	const dispatch = useAppDispatch();
	const inputData = persistent ? persistentinputFieldsData : inputFieldsData;

	const handleAddText = () => {
		let data = [...inputData, '', ''];

		persistent
			? dispatch(setPersistentCustomInputFields(data))
			: dispatch(setInputFields(data));
	};

	const removeAddText = (index: number) => {
		let data = inputData.filter((res, i) => index !== i);
		let data1 = data.filter((res, i) => index - 1 !== i);

		persistent
			? dispatch(setPersistentCustomInputFields(data1))
			: dispatch(setInputFields(data1));
	};

	const handleResponseChange = (index: number, txt: string) => {
		let new_response = inputData.map((value, i) => (index !== i ? value : txt));
		persistent
			? dispatch(setPersistentCustomInputFields(new_response))
			: dispatch(setInputFields(new_response));
	};

	return (
		<div className='ff-eow-quicklinks'>
			{inputData.length < 2 ? null : (
				<>
					<div className='quicklinks-container'>
						<span className='quicklinks-header'>
							<span>Text to display</span>
						</span>
						<span className='quicklinks-header'>
							<span>Text to submit</span>
						</span>
						<span className='quicklinks-header'>
							<span></span>
						</span>
						{inputData.map((data, index) => {
							return (
								<>
									<span className='quicklinks-input-container'>
										<Input
											type='text'
											onChange={(event) =>
												handleResponseChange(index, event.target.value)
											}
											value={data}
											placeholder={
												index % 2 === 0
													? 'Enter text to display'
													: 'Enter text to submit'
											}
											required
											autoFocus
										/>
									</span>
									{index % 2 !== 0 ? (
										<div
											className='svg-container'
											onClick={() => removeAddText(index)}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'>
												<defs>
													<clipPath id='z23wj0weea'>
														<path
															data-name='Rectangle 15483'
															fill='rgba(0,0,0,.6)'
															d='M0 0h13.996v16H0z'
														/>
													</clipPath>
												</defs>
												<g data-name='Group 32536'>
													<g data-name='Group 31699'>
														<path
															data-name='Path 14985'
															d='M4.325 0v1.709H0v1.928h14V1.709H9.668V0z'
															fill='rgba(0,0,0,.6)'
															transform='translate(5 4)'
														/>
														<g data-name='Group 31700'>
															<g
																data-name='Group 31699'
																fill='clip-path:url(#z23wj0weea)'
																transform='translate(5 4)'>
																<path
																	data-name='Path 14986'
																	d='m20.842 79.607.011.112a2.306 2.306 0 0 0 2.294 2.081H28.4a2.306 2.306 0 0 0 2.294-2.071l.961-9.406H19.894zm8.676-7.361-.744 7.283a.379.379 0 0 1-.376.34h-5.25a.379.379 0 0 1-.375-.327l-.746-7.3z'
																	transform='translate(-18.614 -65.796)'
																	fill='rgba(0,0,0,.6)'
																/>
															</g>
														</g>
													</g>
												</g>
											</svg>
										</div>
									) : null}
								</>
							);
						})}
					</div>
				</>
			)}
			<Button
				startIcon={<ConfigurePlus />}
				buttonType='light'
				onClick={handleAddText}>
				Add quick link
			</Button>
		</div>
	);
};

export default QuickLinks;
