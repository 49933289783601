import Button from '../../../Components/Button';
import Checkbox from '../../../Components/Checkbox';
import HorizontalSeparator from '../../../Components/HorizontalSeparator';
import Input from '../../../Components/Input';
import './ConversationalExperience.scss';
import { loadState } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';

import {
	createBotConfigurations,
	createWorkflowSetting,
	resetCreateBotConfigurationCELoader,
	setAnswerQueriesInMiddleOfWorkflow,
	setErrorMessage,
	setShowQuickLinks,
	setShowQuickLinksOnError,
	setStopMessage,
	setWelcomeMessage,
	setWorkFlowExitPrompt,
} from '../../../Features/configure/configureSlice';
import QuickLinks from './QuickLinks';
import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { reset, set } from '../../../Features/message/messageSlice';

const ConversationalExperience = () => {
	const botDetails = loadState('botDetails');
	const [buttonLoader, setButtonLoader] = useState(false);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const path = location.pathname;
	const {
		welcomeMessage,
		showQuickLinks,
		errorMessage,
		showQuickLinksOnError,
		workFlowExitPrompt,
		stopMessage,
		answerQueriesInMiddleOfWorkflow,
		createBotConfigurationCELoader,
		workflowSettingId,
	} = useSelector((state: RootState) => state.configure);
	const { inputFieldsData } = useSelector(
		(state: RootState) => state.configure
	);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (createBotConfigurationCELoader === 'success') {
			dispatch(set('Configurations Saved Successfully'));
			setButtonLoader(false);
		} else if (createBotConfigurationCELoader === 'failed') {
			dispatch(set(`Couldn't Save !! Something Went Wrong`));
			setButtonLoader(false);
		} else if (createBotConfigurationCELoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetCreateBotConfigurationCELoader());
	}, [createBotConfigurationCELoader]);

	return (
		<div className='ff-ce-save-btn'>
			<div className='ff-conversational-experience'>
				<div className='ce-header-input1'>
					<h6 className='ce-header'>Welcome message *</h6>
					<Input
						fullWidth
						value={welcomeMessage}
						onChange={(event) =>
							dispatch(setWelcomeMessage(event.target.value))
						}
						autoFocus
					/>
				</div>
				<div className='text-description-checkbox'>
					<div>
						<h6 className='ce-header'>Show quick links</h6>
						<p className='ce-description'>
							Quick links are displayed with the welcome message. These help the
							user to start the conversation by suggesting commonly asked
							queries or topics.
						</p>
					</div>
					<div>
						<Checkbox
							index={0}
							isChecked={showQuickLinks}
							onValueChange={(value) => dispatch(setShowQuickLinks(value))}
						/>
					</div>
				</div>
				{showQuickLinks[0] ? <QuickLinks /> : null}
				{!showQuickLinks[0] ? (
					<div className='image-container'>
						<img
							src='https://s3-ap-south-1.amazonaws.com/flyfish-resources/dont_delete_me/ConversationExp.png2022-07-26_06:12:27.668'
							alt='icon'
						/>
					</div>
				) : null}
				<HorizontalSeparator />
				<div>
					<h6 className='ce-header'>Error message</h6>
					<p className='ce-description'>
						This message is shown to the users when the{' '}
						{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'} isn’t
						able to comprehend the user’s query.
					</p>
				</div>
				<div className='ce-header-input1'>
					<Input
						fullWidth
						value={errorMessage}
						onChange={(event) => dispatch(setErrorMessage(event.target.value))}
						placeholder='E.g. Sorry, I couldn’t comprehend this.'
					/>
				</div>
				<div className='text-description-checkbox'>
					<div>
						<h6 className='ce-header'>Show quick links on error</h6>
						<p className='ce-description'>
							Error messages will be followed by quick links provided by you.
							These help in bringing the conversation back on track after errors
							by pointing the user towards what the{' '}
							{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'} can
							answer.
						</p>
					</div>
					<div>
						<Checkbox
							index={0}
							isChecked={showQuickLinksOnError}
							onValueChange={(value) =>
								dispatch(setShowQuickLinksOnError(value))
							}
						/>
					</div>
				</div>
				<HorizontalSeparator />
				<div>
					<h6 className='ce-header'>Customer journey exit prompt</h6>
					<p className='ce-description'>
						This prompt is shown whenever validation errors occur during
						customer journey. It is used to inform that the customer journey can
						be exited by saying ‘cancel’, ‘exit’ or ‘stop’
					</p>
				</div>
				<div className='ce-header-input2'>
					<Input
						fullWidth
						value={workFlowExitPrompt}
						onChange={(event) =>
							dispatch(setWorkFlowExitPrompt(event.target.value))
						}
						placeholder='Say exit or cancel to stop this conversation'
					/>
				</div>
				<HorizontalSeparator />
				<div>
					<h6 className='ce-header'>
						Message to show when customer journey stop abruptly
					</h6>
					<p className='ce-description'>
						This message will be shown to the user whenever a customer journey
						stops before completion. This can happen if the customer journey is
						built incompletely or the user says exit or cancel
					</p>
				</div>
				<div className='ce-header-input2'>
					<Input
						fullWidth
						value={stopMessage}
						onChange={(event) => dispatch(setStopMessage(event.target.value))}
						placeholder='Sure. Let me know how else can I help you'
					/>
				</div>
				<HorizontalSeparator />
				<div className='text-description-checkbox'>
					<div>
						<h6 className='ce-header'>
							Answer queries in middle of a customer journey
						</h6>
						<p className='ce-description'>
							This enables the{' '}
							{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'} to
							answer other queries of the user in the middle of a customer
							journey
						</p>
					</div>
					<div>
						<Checkbox
							index={0}
							isChecked={answerQueriesInMiddleOfWorkflow}
							onValueChange={(value) =>
								dispatch(setAnswerQueriesInMiddleOfWorkflow(value))
							}
						/>
					</div>
				</div>
			</div>
			<div className='ce-save-button-div'>
				<Button
					className='ce-save-button'
					loading={buttonLoader}
					onClick={() => {
						let key: string[] = [];
						let value: string[] = [];
						let quickLinks: { linkText: string; linkValue: string }[] = [];
						inputFieldsData.forEach((data, i) => {
							(i === 0 || i % 2 === 0) && key.push(data);
							i % 2 !== 0 && value.push(data);
						});

						for (let i = 0; i < value.length; i++) {
							quickLinks.push({ linkText: key[i], linkValue: value[i] });
						}
						quickLinks = quickLinks.filter((link) => link.linkText !== '');
						// dispatch(setQuickLinks(`${JSON.stringify(quickLinks)}`));
						let data1 = {
							botId: botDetails.botId,
							tenantId: tenantId,
							welcomeMessage: welcomeMessage,
							quickLinksOnError: showQuickLinksOnError[0],
							quicklinks: showQuickLinks[0]
								? `${JSON.stringify(quickLinks)}`
								: '',
							botfailedMessage: errorMessage,
						};
						let data2 = {
							botId: botDetails.botId,
							tenantId: tenantId,
							exitMessage: workFlowExitPrompt,
							stopMessage: stopMessage,
							allowAnswer: answerQueriesInMiddleOfWorkflow[0],
							workflowSettingId: workflowSettingId,
						};
						dispatch(createBotConfigurations(data1));
						dispatch(createWorkflowSetting(data2));
					}}>
					Save
				</Button>
			</div>
		</div>
	);
};

export default ConversationalExperience;
