import React from 'react';
import Button from '../../../../Components/Button';
import Modal from '../../../../Components/Modal';
import Applechat from '../../../../assets/images/cards/Applechat.png';
import Messenger from '../../../../assets/images/cards/Messenger.png';
import MessengerBg from '../../../../assets/images/cards/messenger-bg.png';
import InstagramImgBg from '../../../../assets/images/cards/instagram-bg.png';

import './SetUpNewChannelModal.scss';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import WhatsappIcon, {
	GoogleIcon,
	InstaIcon,
	WebsiteIcon,
} from '../../../../assets/images/cards/Logos';
import Chip from '../../../../Components/Chip';
import { cards } from '../../../../utils/constants';
import { getPath, loadState } from '../../../../utils/utils';
import { setOpenModalForLaunch } from '../../../../Features/channel/channelSlice';
import { useAppDispatch } from '../../../../app/store';

interface Props2 {
	title: string;
	onClick: () => void;
}

const getBgColor = (title: string) => {
	switch (title.toLowerCase()) {
		case 'whatsapp':
			return '#169f0e';
		case 'instagram':
			return 'radial-gradient(circle at 16% 99%, rgba(254, 103, 83, 0.6), #f22089 37%, #b729a8 74%, #7b42d6 114%)';
		case 'google business messages':
			return '#1c6aef';
		case 'facebook messenger':
			return '';
		case 'apple business chat':
			return '#1fae17';
	}
};

const getIcon = (title: string) => {
	switch (title.toLowerCase()) {
		case 'whatsapp':
			return <WhatsappIcon height='174' width='174' />;
		case 'instagram':
			return <InstaIcon width='174' height='174' />;
		case 'google business messages':
			return <GoogleIcon height='174' width='174' />;
		case 'facebook messenger':
			return (
				<img
					src={Messenger}
					alt='apple-chat'
					style={{ width: '174px', height: '174px' }}
				/>
			);
		case 'apple business chat':
			return (
				<img
					src={Applechat}
					alt='apple-chat'
					style={{ width: '174px', height: '174px' }}
				/>
			);
		case 'website':
			return (
				<WebsiteIcon height='180px' width='174px' className='rotate-icon' />
			);
	}
};

export const WhatsappInstaCard = ({ title, onClick }: Props2) => {
	const comingsoon = [
		'google business messages',
		'facebook messenger',
		'apple business chat',
	];

	return (
		<div
			className={`default-channel-card  ${
				title === 'Whatsapp' || title === 'Instagram' || title === 'Website'
					? 'cursor-allowed'
					: 'cursor-not-allowed'
			}`}
			style={{
				backgroundColor: getBgColor(title),
			}}
			onClick={() =>
				comingsoon.indexOf(title.toLowerCase()) > -1 ? {} : onClick()
			}>
			{/* for instagram and messenger , linear graidents are not available */}
			{title === 'Instagram' ? <div className='card-bg insta-bg'></div> : ''}
			{title === 'Website' && <div className='card-bg website-bg'></div>}
			{title === 'Facebook Messenger' ? (
				<div className='card-bg messenger-bg'></div>
			) : (
				''
			)}
			{comingsoon.indexOf(title.toLowerCase()) > -1 ? (
				<div className='coming-soon'>
					<Chip label='coming soon' />
				</div>
			) : (
				''
			)}
			<div className='content'>
				<h5
					style={{
						opacity: comingsoon.indexOf(title.toLowerCase()) > -1 ? '0.6' : '',
					}}>
					{title}
				</h5>
			</div>

			<div
				className={`card-image ${title === 'Website' && 'website-overlay-bg'}`}>
				{getIcon(title)}
			</div>

			{!Boolean(comingsoon.indexOf(title.toLowerCase()) > -1) ? (
				<div className='hover-overlay'></div>
			) : (
				<div className='overlay'></div>
			)}
		</div>
	);
};

type Props = {
	setUpFromLaunchScreen?: string;
	setAddWebsite?: (value: any) => void;
};

const SetUpNewChannelModal = (props: Props) => {
	const navigate = useNavigate();
	const cardsToDisplay =
		props.setUpFromLaunchScreen === 'Website'
			? cards
			: cards.filter((each) => each !== 'Website');
	const botDetails = loadState('botDetails');
	const dispatch = useAppDispatch();
	const location = useLocation();
	const path = location.pathname;
	return (
		<>
			<div className='set-up-new-channel-modal'>
				<Modal
					onClose={() =>
						path.indexOf('catalog') > -1
							? dispatch(setOpenModalForLaunch(false))
							: navigate(-1)
					}
					header={
						<h5>
							{props.setUpFromLaunchScreen === 'Website'
								? 'Link a new Channel'
								: 'Setup a New Channel'}
						</h5>
					}
					headerCloseBtn
					actionButtons={
						<div className='cancel-button'>
							<Button
								onClick={() =>
									path.indexOf('catalog') > -1
										? dispatch(setOpenModalForLaunch(false))
										: navigate(-1)
								}
								buttonType='light'
								size='large'>
								Cancel
							</Button>
						</div>
					}>
					<div className='set-new-channel-body'>
						<p className='body1 description'>
							{props.setUpFromLaunchScreen === 'Website'
								? 'Awesome! Let’s start by selecting which Channel you would like to link with your Ad'
								: 'Awesome! Let’s start by selecting which channel you would like to setup'}
						</p>
						<div className='modal-cards-container'>
							{cardsToDisplay.map((each) => {
								return (
									<WhatsappInstaCard
										title={each}
										onClick={() => {
											each === 'Website'
												? props.setAddWebsite && props.setAddWebsite(true)
												: props.setUpFromLaunchScreen &&
												  props.setUpFromLaunchScreen?.length > 0
												? navigate(
														`/app/catalog/${getPath(
															botDetails.cavSRType
														)}/launch/setup-channel`,
														{
															state: { value: each },
														}
												  )
												: navigate('/app/channels/setup-wa-insta', {
														state: { value: each },
												  });
										}}
									/>
								);
							})}
						</div>
					</div>
				</Modal>
			</div>

			<Outlet />
		</>
	);
};

export default SetUpNewChannelModal;
