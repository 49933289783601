import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import './ProductPreview.scss';

type Props = {
	productImg: string;
	productTitle: string;
	onClick: () => void;
};

const ProductPreview = ({ productImg, productTitle, onClick }: Props) => {
	const { testAndOpenBot } = useSelector((state: RootState) => state.builder);
	return (
		<>
			<div className='ff-product-preview-sales-ai' onClick={onClick}>
				<div className='product-preview'>
					<div>
						<img
							className={`${testAndOpenBot ? 'open' : ''}`}
							src={productImg}
							alt=''
						/>
						<div className='overlay'></div>
					</div>

					<p className={`body1 ${testAndOpenBot ? 'open' : ''}`}>
						{productTitle}
					</p>
				</div>
			</div>
		</>
	);
};

export default ProductPreview;
