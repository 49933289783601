import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import Modal from '../../../../Components/Modal';
import {
	setBotMessageCheckbox,
	setWelcomBannerFile,
	setWelcomeBannerCheckbox,
	setHeaderFile,
	setBackgroundFile,
	setBotBackground,
	uploadFile,
	setHeaderContentType,
} from '../../../../Features/configure/configureSlice';
import { AppearanceUpload, AppearanceURL } from '../../../../assets/Icons';
import './AddImage.scss';

const AddImage = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const state = location.state as { value: string };

	const welcomeRef = useRef<HTMLInputElement>(null!);
	const botMessageRef = useRef<HTMLInputElement>(null!);
	const botHeaderRef = useRef<HTMLInputElement>(null!);
	const backgroundRef = useRef<HTMLInputElement>(null!);

	const [throughURL, setThroughURL] = useState(false);
	const [welcomeValue, setWelcomeValue] = useState('');
	const [botHeaderValue, setBotHeaderValue] = useState('');
	const [backgroundImageValue, setBackgroundImageValue] = useState('');
	const [addThroughURLWB, setAddThroughURLWB] = useState(false);
	const [addThroughURLBH, setAddThroughURLBH] = useState(false);
	const [addThroughURLBI, setAddThroughURLBI] = useState(false);

	const { welcomeBannerCheckbox, botMessageCheckbox } = useSelector(
		(state: RootState) => state.configure
	);

	const uploadCancel = () => {
		state.value === 'Welcome Banner' &&
			welcomeBannerCheckbox[0] &&
			dispatch(setWelcomeBannerCheckbox([false]));
		state.value === 'Bot Messages' &&
			botMessageCheckbox[0] &&
			dispatch(setBotMessageCheckbox([false]));
		if (state.value === 'Bot Header') {
			dispatch(setHeaderContentType('text'));
		}
		if (state.value === 'Background Image') {
			dispatch(setBotBackground('color'));
		}
		navigate(-1);
	};

	const fileUploadInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		let fileRef;
		if (state.value === 'Welcome Banner') {
			fileRef = welcomeRef;
		} else if (state.value === 'Bot Messages') {
			fileRef = botMessageRef;
		} else if (state.value === 'Bot Header') {
			fileRef = botHeaderRef;
		} else if (state.value === 'Background Image') {
			fileRef = backgroundRef;
		}

		if (fileRef && fileRef.current) {
			let fileInput = fileRef.current;
			var filePath = fileInput.value;
			// Allowing file type
			var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
			if (filePath) {
				if (!allowedExtensions.exec(filePath)) {
					alert('Invalid Media :- Supported media formats are .jpg,.jpeg,.png');
					fileInput.value = '';
					return false;
				} else {
					let file = e.target.files && e.target.files[0];
					const MAX_FILE_SIZE = 5120; // 5MB in binary
					const fileSizeKiloBytes = file && file.size / 1024;
					if (fileSizeKiloBytes! > MAX_FILE_SIZE) {
						// setFileSizeExceed(true);

						alert(
							'File size should be maximum 5 MB Format should be PNG or JPEG or JPG'
						);
					} else {
						state.value === 'Welcome Banner' &&
							e.target.files &&
							dispatch(
								uploadFile({
									uploadfile: e.target.files[0],
									uploadTo: state.value,
								})
							);
						state.value === 'Bot Messages' &&
							e.target.files &&
							dispatch(
								uploadFile({
									uploadfile: e.target.files[0],
									uploadTo: state.value,
								})
							);
						state.value === 'Bot Header' &&
							e.target.files &&
							dispatch(
								uploadFile({
									uploadfile: e.target.files[0],
									uploadTo: state.value,
								})
							);
						state.value === 'Background Image' &&
							e.target.files &&
							dispatch(
								uploadFile({
									uploadfile: e.target.files[0],
									uploadTo: state.value,
								})
							);
						fileInput.value = '';
						navigate(-1);
					}
				}
			}
		}
	};

	const buttons = () => {
		return (
			<>
				<Button onClick={uploadCancel} buttonType='light'>
					<p>Cancel</p>
				</Button>
				<Button
					onClick={() => {
						if (state.value === 'Welcome Banner') {
							setAddThroughURLWB(true);
						} else if (state.value === 'Bot Header') {
							setAddThroughURLBH(true);
						} else if (state.value === 'Background Image') {
							setAddThroughURLBI(true);
						}
					}}>
					<p>Add</p>
				</Button>
			</>
		);
	};
	useEffect(() => {
		addThroughURLWB &&
			welcomeValue &&
			dispatch(setWelcomBannerFile(welcomeValue));
		addThroughURLWB && navigate(-1);
	}, [addThroughURLWB]);

	useEffect(() => {
		addThroughURLBH &&
			botHeaderValue &&
			dispatch(setHeaderFile(botHeaderValue));
		addThroughURLBH && navigate(-1);
	}, [addThroughURLBH]);

	useEffect(() => {
		addThroughURLBI &&
			backgroundImageValue &&
			dispatch(setBackgroundFile(backgroundImageValue));
		addThroughURLBI && navigate(-1);
	}, [addThroughURLBI]);

	return (
		<div className='ff-appearance-addimage'>
			{!throughURL ? (
				<Modal
					header='Add Image'
					actionButtons={' '}
					headerCloseBtn
					onClose={uploadCancel}>
					<div>
						<p>Select how you would like to add the image</p>

						{state.value === 'Welcome Banner' ? (
							<label htmlFor='ff-appearance-welcome-banner-image'>
								<div className='add-image welcome-banner'>
									<AppearanceUpload />
									<p>Upload</p>
								</div>
							</label>
						) : null}
						{state.value === 'Bot Header' ? (
							<label htmlFor='ff-appearance-bot-header-image'>
								<div className='add-image bot-header'>
									<AppearanceUpload />
									<p>Upload</p>
								</div>
							</label>
						) : null}

						{state.value === 'Background Image' ? (
							<label htmlFor='ff-appearance-background-image'>
								<div className='add-image background-image'>
									<AppearanceUpload />
									<p>Upload</p>
								</div>
							</label>
						) : null}

						<div className='add-image' onClick={() => setThroughURL(true)}>
							<AppearanceURL />
							<p>Through URL</p>
						</div>
					</div>
				</Modal>
			) : (
				<Modal header='Through URL' actionButtons={buttons()}>
					<div>
						{state.value === 'Welcome Banner' ? (
							<Input
								fullWidth
								autoFocus
								label='Paste your URL here'
								value={welcomeValue}
								onChange={(event) => setWelcomeValue(event.target.value)}
							/>
						) : null}
						{state.value === 'Bot Header' ? (
							<Input
								fullWidth
								autoFocus
								label='Paste your URL here'
								value={botHeaderValue}
								onChange={(event) => setBotHeaderValue(event.target.value)}
							/>
						) : null}

						{state.value === 'Background Image' ? (
							<Input
								fullWidth
								autoFocus
								label='Paste your URL here'
								value={backgroundImageValue}
								onChange={(event) =>
									setBackgroundImageValue(event.target.value)
								}
							/>
						) : null}
					</div>
				</Modal>
			)}
			<input
				type='file'
				id='ff-appearance-welcome-banner-image'
				ref={welcomeRef}
				onChange={fileUploadInput}
				name='ff-appearance-welcome-banner-image'
				style={{ visibility: 'hidden' }}
			/>
			<input
				type='file'
				id='ff-appearance-bot-header-image'
				ref={botHeaderRef}
				onChange={fileUploadInput}
				name='ff-appearance-bot-header-image'
				style={{ visibility: 'hidden' }}
			/>

			<input
				type='file'
				id='ff-appearance-background-image'
				ref={backgroundRef}
				onChange={fileUploadInput}
				name='ff-appearance-background-image'
				style={{ visibility: 'hidden' }}
			/>
		</div>
	);
};

export default AddImage;
