import {
	BrowserRouter,
	Route,
	Routes,
	Navigate,
	Outlet,
} from 'react-router-dom';
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';
import BotBuilder from './Pages/BotBuilder';
import Layout from './Navigation/Layout';
import Configure from './Pages/Configure';
import Deploy from './Pages/Deploy';
import './App.scss';
import Team from './Pages/Dashboard/Teams';
import DeployNewVersion from './Pages/Deploy/DeployNewVersion';
import TeamSetting from './Pages/TeamSetting';
import ProtectedRoute from './Navigation/ProtectedRoute';
import AddQuery from './Pages/BotBuilder/AddQuery';
import AddUser from './Pages/TeamSetting/AddUser';
import Integrations from './Pages/TeamSetting/Integrations';
import PlanDetails from './Pages/TeamSetting/PlanDetails';
import TSUsers from './Pages/TeamSetting/TSUsers';
import EditBotAccess from './Pages/TeamSetting/TSUsers/KababMenu/EditBotAccess';
import MakeTeamAdmin from './Pages/TeamSetting/TSUsers/KababMenu/MakeTeamAdmin';
import RemoveFromTeam from './Pages/TeamSetting/TSUsers/KababMenu/RemoveFromTeam';
import RevokeAdminAccess from './Pages/TeamSetting/TSUsers/KababMenu/RevokeAdminAccess';
import VoiceCapabilities from './Pages/Configure/VoiceCapabilities';
import ConfigEnhancements from './Pages/Configure/ConfigEnhancements';
import SecurityAndPrivacy from './Pages/Configure/SecurityAndPrivacy';
import ConfigureGeneral from './Pages/Configure/General';
import Enhancements from './Pages/Configure/Enhancements';
import ConversationalExperience from './Pages/Configure/ConversationalExperience';
import Appearance from './Pages/Configure/Appearance';
import AddImage from './Pages/Configure/Appearance/AddImage';
import Email from './Pages/TeamSetting/Integrations/Email';
import Display from './Pages/BotBuilder/Actions/Display';
import ConfirmValues from './Pages/BotBuilder/Actions/ConfirmValues';
import CollectUserRating from './Pages/BotBuilder/Actions/CollectUserRating';
import TriggerAnotherQuery from './Pages/BotBuilder/Actions/TriggerAnotherQuery';
import CallApi from './Pages/BotBuilder/Actions/CallApi';
import CollectUserInput from './Pages/BotBuilder/Actions/CollectUserInput';
import MultipleChoice from './Pages/BotBuilder/Actions/MultipleChoice';
import MultipleSelect from './Pages/BotBuilder/Actions/MultipleSelect';
import SendCollectedValues from './Pages/BotBuilder/Actions/SendCollectedValues';
import HandoffToAgent from './Pages/BotBuilder/Actions/HandoffToAgent';
import DeleteVersion from './Pages/Deploy/DeleteVersion';
import ReDeploy from './Pages/Deploy/ReDeploy';
import AddNewBot from './Pages/CatalogScreen/AddNewBot';
import ForgotPassword from './Pages/ForgotPassword';
import Parameters from './Pages/BotBuilder/Actions/CallApi/Parameters';
import HeaderParameters from './Pages/BotBuilder/Actions/CallApi/HeaderParameters';
import APIResponseMapping from './Pages/BotBuilder/Actions/CallApi/APIResponseMapping';
import APIErrorMessages from './Pages/BotBuilder/Actions/CallApi/APIErrorMessages';
import ResetPassword from './Pages/ResetPassword';
import Actions from './Pages/BotBuilder/Actions';
import TopQueries from './Pages/Analytics/TopQueries';
import HighestDropOffRate from './Pages/Analytics/Highestdropoffrates';
import DialogAnalytics from './Pages/Analytics/DialogAnalytics';
import WelcomeScreen from './Pages/WelcomeScreen';
import BetaChannels from './Pages/FF-Beta/BetaChannels';
import WhatsAppBSP from './Pages/WhatsAppBSP';
import Campaigns from './Pages/WhatsAppBSP/Campaigns';
import Campaign from './Pages/WhatsAppBSP/Campaigns/Campaign';
import Create from './Pages/WhatsAppBSP/Campaigns/Create';
import CampaginModal from './Pages/WhatsAppBSP/Campaigns/Campaign/CampaignModal';
import CampaignDetail from './Pages/WhatsAppBSP/Campaigns/Details';
import Consents from './Pages/WhatsAppBSP/ConsentManagement';
import Consent from './Pages/WhatsAppBSP/ConsentManagement/Consent';
import DeleteForm from './Pages/WhatsAppBSP/ConsentManagement/Consent/DeleteForm';
import AddForm from './Pages/WhatsAppBSP/ConsentManagement/Consent/AddForm';
import UploadContacts from './Pages/WhatsAppBSP/ConsentManagement/Consent/ImportForm';
import Templates from './Pages/WhatsAppBSP/ManageTemplates';
import Analyze from './Pages/Analyze';
import ConversationalVirtualSales from './Pages/Analyze/ConversationalVirtualSales';
import ConvosationTable from './Pages/Analytics/ConversationTable/index';
import Imports from './Pages/WhatsAppBSP/ConsentManagement/Imports';
import BspAnalytics from './Pages/WhatsAppBSP/Analytics';
import CatalogScreen from './Pages/CatalogScreen';
import CatalogModal from './Pages/CatalogScreen/CatalogModal';
import BotDesigner from './Pages/BotDesigner';
import SelectCatalog from './Pages/CatalogScreen/SelectCatalog';
import LiveChat from './Pages/Configuration/LiveChat';
import CaVsrAnalytics from './Pages/Analyze/CaVsrAnalytics';
import LiveChatMainComponent from './Pages/Analytics/LiveChats/LiveChatsMainComponent/index';
import LiveChatAnalytics from './Pages/Analytics/LiveChats';
import AgentRankingTable from './Pages/Analytics/LiveChats/AgentRankingTable/index';
import Chats from './Pages/Chats/index';
import LiveChatConfig from './Pages/Configure/LiveChatConfig';
import SetUpNewChannelModal from './Pages/FF-Beta/BetaChannels/SetUpNewChannelModal';
import SetUpWhatsappInstagram from './Pages/FF-Beta/BetaChannels/SetUpNewChannelModal/SetUpWhatsappInstagram';
import CreateWaba from './Pages/FF-Beta/BetaChannels/Create';
import { AlmostDoneModal } from './Pages/FF-Beta/BetaChannels/Create/AlmostDoneModal';
import ErrorInSetup from './Pages/FF-Beta/BetaChannels/Create/ErrorInSetup';
import SetUpComplateModalWaba from './Pages/FF-Beta/BetaChannels/Create/SetupModalSuccessWaba';
import ReleaseModal from './Pages/Chats/ReleaseModal';
import LinkLeadSquared from './Pages/TeamSetting/Integrations/LeadSquared/LinkLeadSquared';
import UnLinkLeadSquared from './Pages/TeamSetting/Integrations/LeadSquared/UnLinkLeadSquared';
import UnlinkShopify from './Pages/TeamSetting/Integrations/ShopifyStore/UnlinkShopify';
import LinkShopifyPopup from './Pages/TeamSetting/Integrations/ShopifyStore/LinkShopify/LinkShopifyPopup';
import UploadFaqsWeb from './Pages/FAQs/UploadFaqsWeb';
import FAQs from './Pages/FAQs';
import FAQConfiguration from './Pages/FAQs/FAQConfiguration';
import Ingest from './Pages/Ingest';
import CatalogSales from './Pages/SalesAI/CatalogSales';
import CatalogDescription from './Pages/SalesAI/CatalogSales/CatalogDescription';
import CookiePopup from './Pages/CookiePopup';
import Link from './Pages/TeamSetting/Integrations/FreshChat/Link';
import Unlink from './Pages/TeamSetting/Integrations/FreshChat/Unlink';
import LinkSuccess from './Pages/TeamSetting/Integrations/FreshChat/Link/LinkSuccess';
import Zoho from './Pages/TeamSetting/Integrations/Zoho/index';
import UnlinkZoho from './Pages/TeamSetting/Integrations/Zoho/UnlinkZoho';
import CreateJoinTeam from './Pages/CreateOrJoinTeam/CreateJoinTeam';
import CreateTeam from './Pages/CreateOrJoinTeam/CreateTeam';
import JoinTeam from './Pages/CreateOrJoinTeam/JoinTeam';
import IngestProduct from './Pages/IngestProduct';
import SelectPlatform from './Pages/IngestProduct/SelectPlatform';
import ShopifyIngest from './Pages/IngestProduct/LinkPlatform/ShopifyIngest';
import LinkPlatform from './Pages/IngestProduct/LinkPlatform';
import IngestTab from './Pages/IngestTab/index';
import RaiseTicket from './Pages/TeamSetting/Actions/RaiseTicket';
import MultilingualSupport from './Pages/TeamSetting/MultilingualSupport';
import { UploadMultiple } from './Pages/IngestTab/UploadMultiple';
import { PreviewPDF } from './Pages/IngestTab/PreviewPDF';
import BookAppointment from './Pages/TeamSetting/Actions/BookAppointment';
import CallCustomerCare from './Pages/TeamSetting/Actions/CallCustomerCare';
import ConfigActions from './Pages/TeamSetting/Actions';
import ChatWithHuman from './Pages/TeamSetting/Actions/ChatWithHuman';
import Background from './Pages/TeamSetting/ExperienceOnWeb/Background';
import StartingChat from './Pages/TeamSetting/ExperienceOnWeb/StartingChat';
import Messages from './Pages/TeamSetting/ExperienceOnWeb/Messages';
import OptionsAndButtons from './Pages/TeamSetting/ExperienceOnWeb/OptionsAndButtons';
import InputBox from './Pages/TeamSetting/ExperienceOnWeb/InputBox';
import PersistentMenu from './Pages/TeamSetting/ExperienceOnWeb/PersistentMenu';
import ConfigureBotMessage from './Pages/ConfigureBotMessage';
import { HandOffTriggers } from './Pages/HandOffTriggers';
import { ExpertsAvailability } from './Pages/ExpertAvailability';
import AddImageEOW from './Pages/TeamSetting/ExperienceOnWeb/AddImageEOW';
import AppearanceEOW from './Pages/TeamSetting/ExperienceOnWeb/Appearance';
import GenerateLeads from './Pages/TeamSetting/Actions/GenerateLeads';
import ChartData from './Pages/Analyze/ChartData/ChartData';
import Launch from './Pages/Launch';
import PaymentSubscription from './Pages/Subscription';
import PaymentJourney from './Pages/Subscription/PaymentJourney';
import ConversationTab from './Pages/Analyze/ConversationTab';
import { saveState } from './utils/utils';
import ThankYou from './Pages/ThankYou';
import GeneralTeamSetting from './Pages/GeneralTeamSetting';
import TeamDetails from './Pages/GeneralTeamSetting/TeamDetails';
import AddUsers from './Pages/GeneralTeamSetting/AddUser';
import AddUserPopup from './Pages/GeneralTeamSetting/AddUser/AddUserPopup';
import GeneralEOW from './Pages/TeamSetting/ExperienceOnWeb/GeneralEOW';
import RedirectURI from './Pages/TeamSetting/Integrations/Zoho/RedirectURI';
import ZThankYou from './Pages/TeamSetting/Integrations/Zoho/ZThankYou';
import UnlinkEmail from './Pages/TeamSetting/Integrations/Email/UnlinkEmail';
import DeliveryCurrentModal from './Pages/WhatsAppBSP/Campaigns/Details/DeliveryCurrentModal';
import { IndustrialVerticalization } from './Pages/IndustrialVerticalization';
import Salesforce from './Pages/TeamSetting/Integrations/Salesforce';
import UnlinkSalesforce from './Pages/TeamSetting/Integrations/Salesforce/UnlinkSalesforce';
import RemoveDocument from './Pages/IngestTab/RemoveDocument';
import RemoveStore from './Pages/IngestTab/RemoveStore';
import InactivityDetector from './app/InactivityDetector';
import AddAIToWebsite from './Pages/Launch/AddAIToWebsite';
import ModifyZohoScope from './Pages/TeamSetting/Integrations/Zoho/ModifyZohoScope';
import RemoveAIFromWebsite from './Pages/Launch/RemoveAIFromWebsite';
import FullScreenConfig from './Pages/TeamSetting/ExperienceOnWeb/FullScreenConfig';
import WelcomeFullScreen from './Pages/TeamSetting/ExperienceOnWeb/FullScreenConfig/WelcomeFullScreen';
import ChatFullScreen from './Pages/TeamSetting/ExperienceOnWeb/FullScreenConfig/ChatFullScreen';
import ChangePassword from './Pages/Header/ChangePassword/changepassword';
import SubscriptionDetails from './Pages/GeneralTeamSetting/SubscriptionDetails';
import FeedbackConversationTab from './Pages/Analyze/FeedbackConversationTab';
import FlyfishIngest from './Pages/IngestProduct/LinkPlatform/FlyfishIngest';
import LiveChatLeads from './Pages/TeamSetting/Actions/LiveChatLeads';
import WooCommerce from './Pages/IngestProduct/LinkPlatform/WooCommerce';
import {
	redirectURI_Shopify,
	resetRedirectStatus,
} from './Features/auth/authSlice';
import { RootState, useAppDispatch } from './app/store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import RedirectLoader from './Pages/RedirectLoader';
import ComponentsLibrary from './Pages/ComponentsLibrary';
import UploadModule from './Pages/Ingest/UploadModule';
import { EditPDF } from './Pages/IngestTab/EditPDF';
import { EditPDFError } from './Pages/IngestTab/EditPDFError';
import { EditPDFSuccess } from './Pages/IngestTab/EditPDFSuccess';
import InvalidFileUpload from './Pages/Ingest/InvalidFileUpload';
import InvalidFileName from './Pages/Ingest/InvalidFileName';
import InvalidFileSize from './Pages/Ingest/InvalidFileSize';
import SubscriptionError from './Pages/TeamSetting/Integrations/SubscriptionError';
import CacheBuster from 'react-cache-buster';
import packageFile from '../package.json';
function App() {
	const dispatch = useAppDispatch();
	const isProduction = process.env.NODE_ENV === 'production';
	const { redirectURL_shopify, redirectAPIStatus } = useSelector(
		(state: RootState) => state.auth
	);

	const hasShopInURL = window.location.href.includes('shop');
	const hasCodeInURL = window.location.href.includes('code');
	const [showApp, setShowApp] = useState(
		hasShopInURL && !hasCodeInURL ? false : true
	);
	const [retryButton, setRetryButton] = useState(false);

	useEffect(() => {
		if (redirectURL_shopify && redirectAPIStatus === 'success') {
			window.location.href = redirectURL_shopify;
			dispatch(resetRedirectStatus());
		} else if (redirectAPIStatus === 'failed') {
			setRetryButton(true);
			dispatch(resetRedirectStatus());
		}
	}, [redirectAPIStatus]);
	// Redirect logic
	useEffect(() => {
		if (hasShopInURL && !hasCodeInURL) {
			const params = new URLSearchParams(window.location.search.substring(1));
			let shopName = params.get('shop');
			if (shopName?.length) {
				saveState('shopifyShopName', shopName);
				dispatch(
					redirectURI_Shopify({
						storeName: `${shopName}`,
					})
				);
				setShowApp(false);
			}
		}
	}, [hasShopInURL, hasCodeInURL, dispatch]);
	useEffect(() => {
		// Preload the image using a link element
		const linkToImage = document.createElement('link');
		linkToImage.rel = 'preload';
		// @ts-ignore
		linkToImage.fetchPriority = 'high';
		linkToImage.as = 'image';
		linkToImage.href = './assets/images/side_welcome.svg';
		linkToImage.type = 'image/svg+xml';
		linkToImage.className = 'preloading image';
		document.head.appendChild(linkToImage);

		// Cleanup function to remove the link element if necessary
		return () => {
			if (linkToImage) {
				document.head.removeChild(linkToImage);
			}
		};
	}, []);
	return (
		<CacheBuster
			currentVersion={packageFile.version}
			isEnabled={isProduction} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
		>
			<>
				{' '}
				{showApp ? (
					<div className={`App current-version:${packageFile.version}`}>
						<BrowserRouter basename={'/'}>
							<Routes>
								<Route index element={<Login />} />
								<Route path='/app/' element={<Navigate to='/' replace />} />
								<Route path='/app/login' element={<Login />} />

								<Route path='/app/sign-up' element={<SignUp />} />
								<Route
									path='/app/signup-select-plan'
									element={<PaymentSubscription />}
								/>
								<Route
									path='/app/signup-payment'
									element={<PaymentJourney />}
								/>
								<Route
									path='/app/signup-create-team'
									element={<CreateTeam />}
								/>

								<Route path='/app/thank-you' element={<ThankYou />} />

								<Route path='/app/reset-password' element={<ResetPassword />} />
								<Route
									path='/app/forgot-password'
									element={<ForgotPassword />}
								/>
								<Route
									path='/'
									element={
										<ProtectedRoute>
											<Layout />
										</ProtectedRoute>
									}>
									<Route path='change-password' element={<ChangePassword />} />
									<Route
										path='/app/select-plan'
										element={<PaymentSubscription />}></Route>

									<Route path='/app/payment' element={<PaymentJourney />} />
									<Route
										path='/app/components-library'
										element={<ComponentsLibrary />}
									/>
									{/* <Route
							path='/app/components-library'
							element={<ComponentsLibrary />}>
							<Route path='modal-with-path' element={<ModalWithPath />} />
							<Route
								path='modal-with-processing'
								element={<ModalWithProcessing />}
							/>
						</Route> */}

									<Route path='/app/teams' element={<Team />} />

									{/* <Route
							path='app/my-studio'
							element={<Navigate to='/app/my-studio/intelligent-ad' replace />}
						/> */}
									{/* <Route path='/app/my-studio/:type' element={<MyStudio />} /> */}

									<Route
										path='app/create-ai'
										element={<IndustrialVerticalization />}
									/>

									<Route path='app/Ingest' element={<Ingest />}>
										<Route
											path='invalid-file-name-error'
											element={<InvalidFileName />}
										/>
										<Route
											path='invalid-file-size-error'
											element={<InvalidFileSize />}
										/>
										<Route
											path='invalid-file-format-error'
											element={<InvalidFileUpload />}
										/>
										<Route path='upload-module' element={<UploadModule />}>
											<Route
												path='invalid-file-name-error'
												element={<InvalidFileName />}
											/>
											<Route
												path='invalid-file-size-error'
												element={<InvalidFileSize />}
											/>
											<Route
												path='invalid-file-format-error'
												element={<InvalidFileUpload />}
											/>
										</Route>
									</Route>
									<Route
										path='app/Ingest/link-product'
										element={<IngestProduct />}>
										<Route
											index
											element={<Navigate to='select-platform' replace />}
										/>
										<Route
											path='select-platform'
											element={<SelectPlatform />}
										/>
										<Route path='link-platform' element={<LinkPlatform />}>
											<Route path='shopify' element={<ShopifyIngest />}>
												<Route
													path='link-shopify-info'
													element={<LinkShopifyPopup />}
												/>
											</Route>
											<Route path='flyfish' element={<FlyfishIngest />}></Route>
											<Route
												path='woocommerce'
												element={<WooCommerce />}></Route>
										</Route>
									</Route>

									<Route path='app/Ingest-tab' element={<IngestTab />}>
										<Route
											path='ingested-resources'
											element={<UploadMultiple />}>
											<Route
												path='invalid-file-name-error'
												element={<InvalidFileName />}
											/>
											<Route
												path='invalid-file-size-error'
												element={<InvalidFileSize />}
											/>
											<Route
												path='invalid-file-format-error'
												element={<InvalidFileUpload />}
											/>
											<Route path='upload-module' element={<UploadModule />}>
												<Route
													path='invalid-file-name-error'
													element={<InvalidFileName />}
												/>
												<Route
													path='invalid-file-size-error'
													element={<InvalidFileSize />}
												/>
												<Route
													path='invalid-file-format-error'
													element={<InvalidFileUpload />}
												/>
											</Route>
											<Route path='remove-file' element={<RemoveDocument />} />
											<Route path='remove-store' element={<RemoveStore />} />
										</Route>
										{/* import-url not getting used as of now */}
										<Route
											path='import-url'
											element={<UploadFaqsWeb />}></Route>
										{/* <Route path='pdf-preview' element={<PreviewPDF />}></Route> */}
									</Route>
									<Route
										path='app/Ingest-tab/pdf-preview'
										element={<PreviewPDF />}>
										<Route
											path='edit-file-error'
											element={<EditPDFError />}></Route>
										<Route
											path='edit-file-success'
											element={<EditPDFSuccess />}></Route>
										<Route path='edit-file' element={<EditPDF />}></Route>
									</Route>

									<Route
										path='app/create-or-join-team'
										element={<CreateJoinTeam />}
									/>
									<Route path='app/create-team' element={<CreateTeam />} />
									<Route path='app/join-team' element={<JoinTeam />} />

									<Route path='/app/welcome' element={<WelcomeScreen />}>
										<Route
											path='setup-wa-insta'
											element={<SetUpWhatsappInstagram />}
										/>
										<Route path='create-waba' element={<CreateWaba />} />
										<Route path='almost-done' element={<AlmostDoneModal />} />
										<Route
											path='set-up-success'
											element={<SetUpComplateModalWaba />}
										/>
										<Route path='error-in-setup' element={<ErrorInSetup />} />
									</Route>
									{/* analytics */}
									<Route path='/app/analytics' element={<Analyze />}>
										<Route
											index
											element={<Navigate to='chartdata' replace />}
										/>
										<Route path='chartdata' element={<ChartData />} />
										<Route path='conversations' element={<ConversationTab />} />
										<Route
											path='feedbackdata'
											element={<FeedbackConversationTab />}
										/>
										{/* <Route index element={<Navigate to='overview' replace />} />
							<Route path='overview' element={<MiniCards />} /> */}
										<Route
											path='intelligent-ads'
											element={<ConversationalVirtualSales />}>
											<Route path='bot' element={<CaVsrAnalytics />}>
												<Route
													path='viewall-topqueries'
													element={<TopQueries />}
												/>
												<Route
													path='viewall-dialog'
													element={<DialogAnalytics />}
												/>
												<Route
													path='viewall-highestdropoffrate'
													element={<HighestDropOffRate />}
												/>
												<Route
													path='conversationtable'
													element={<ConvosationTable />}
												/>
											</Route>
										</Route>
										<Route
											path='multichannel-storefronts'
											element={<ConversationalVirtualSales />}>
											<Route path='bot' element={<CaVsrAnalytics />}>
												<Route
													path='viewall-topqueries'
													element={<TopQueries />}
												/>
												<Route
													path='viewall-dialog'
													element={<DialogAnalytics />}
												/>
												<Route
													path='viewall-highestdropoffrate'
													element={<HighestDropOffRate />}
												/>
												<Route
													path='conversationtable'
													element={<ConvosationTable />}
												/>
											</Route>
										</Route>

										<Route path='campaigns' element={<div>Campaigns</div>} />
										<Route path='channels' element={<div>Channels</div>} />
										{/* <Route path='live-chat' element={<div>livechat</div>} /> */}

										<Route path='live-chat' element={<LiveChatAnalytics />}>
											<Route
												path='/app/analytics/live-chat'
												element={<LiveChatMainComponent />}
											/>
											<Route
												path='agents-table'
												element={<AgentRankingTable />}
											/>
										</Route>
									</Route>
									<Route path='/app/channels' element={<Outlet />}>
										<Route path='' element={<BetaChannels />}>
											<Route
												path='setup-channel'
												element={<SetUpNewChannelModal />}
											/>
											<Route
												path='setup-wa-insta'
												element={<SetUpWhatsappInstagram />}
											/>
											<Route path='create-waba' element={<CreateWaba />} />
											<Route path='almost-done' element={<AlmostDoneModal />} />
											<Route
												path='set-up-success'
												element={<SetUpComplateModalWaba />}
											/>
											<Route path='error-in-setup' element={<ErrorInSetup />} />
										</Route>

										<Route path='whatsapp' element={<WhatsAppBSP />}>
											<Route
												index
												element={<Navigate to='templates' replace />}
											/>
											<Route path='templates' element={<Templates />} />
											<Route path='analytics' element={<BspAnalytics />} />
											<Route path='contacts' element={<Consents />}>
												<Route path='' element={<Consent />}>
													<Route path='delete' element={<DeleteForm />} />
													<Route path='add' element={<AddForm />} />
													{/* <Route path='edit' element={<EditForm />} /> */}
													<Route path='upload' element={<UploadContacts />} />
												</Route>
											</Route>
											<Route path='contact-imports' element={<Imports />} />
											<Route path='campaigns' element={<Campaigns />}>
												<Route path='' element={<Campaign />}>
													<Route path='create' element={<Create />} />
													<Route
														path='campaign-success'
														element={<CampaginModal />}
													/>
													{/* <Route
											path='previous-success'
											element={<PreviousModal />}
										/> */}
												</Route>
												<Route
													path='details/:campaing_id'
													element={<CampaignDetail />}>
													<Route
														path='download-success'
														element={<DeliveryCurrentModal />}
													/>
												</Route>

												{/* <Route
										path='download-previous-success'
										element={<DeliveryPreviousModal />}
									/> */}
											</Route>
										</Route>
									</Route>

									<Route
										path='/app/catalog/:type' // type=multichannel-storefront || intelligent-ad|| faq
										element={<CatalogScreen />}>
										<Route path='design/build/FAQs' element={<FAQs />}>
											<Route path='upload-faq-web' element={<UploadFaqsWeb />}>
												{/* <Route
										path='After-sales'
										element={<AfterSalesSupport />}></Route> */}
											</Route>
										</Route>
										<Route
											path='sales-ai/catalog'
											element={<CatalogSales />}></Route>

										<Route
											path='sales-ai/catalog/product-description'
											element={<CatalogDescription />}
										/>
										{/* <Route path='sales-ai' element={<SalesAI />}>
								<Route index element={<Navigate to='catalog' />} />
								<Route path='catalog' element={<CatalogSales />}></Route>

								<Route
									path='catalog/product-description'
									element={<CatalogDescription />}
								/>
							</Route> */}

										<Route
											path='design/build/configure'
											element={<FAQConfiguration />}></Route>
										{/* <Route path='design/build/launch' element={<FAQLaunch />} /> */}

										<Route path='' element={<SelectCatalog />}>
											<Route path='create' element={<CatalogModal />} />
										</Route>

										<Route path='new' element={<AddNewBot />} />

										<Route path='design' element={<BotDesigner />}>
											<Route index element={<Navigate to='build' />} />
											<Route path='build' element={<BotBuilder />}>
												<Route path='add-new-bot' element={<AddNewBot />} />
												<Route
													path='add-query'
													element={<AddQuery mode='add' />}
												/>
												<Route
													path='edit-query'
													element={<AddQuery mode='edit' />}
												/>

												<Route path='actions' element={<Actions />}>
													<Route
														index
														element={
															<Navigate
																to='/app/catalog/multichannel-storefront/design/build' //TODO: replace redirect path
																replace
															/>
														}
													/>
													<Route path='display' element={<Display />} />
													<Route
														path='multi-choice'
														element={<MultipleChoice />}
													/>
													<Route
														path='multi-select'
														element={<MultipleSelect />}
													/>
													<Route
														path='confirm-collected-values'
														element={<ConfirmValues />}
													/>
													<Route path='call-an-api' element={<CallApi />}>
														<Route
															path='add-parameters'
															element={<Parameters />}
														/>
														<Route
															path='add-header-parameters'
															element={<HeaderParameters />}
														/>
														<Route
															path='add-api-response-mapping'
															element={<APIResponseMapping />}
														/>
														<Route
															path='add-api-error-messages'
															element={<APIErrorMessages />}
														/>
													</Route>
													<Route
														path='collect-user-rating'
														element={<CollectUserRating />}
													/>
													<Route
														path='trigger-another-query'
														element={<TriggerAnotherQuery />}
													/>
													<Route
														path='collect-user-input'
														element={<CollectUserInput />}
													/>
													<Route
														path='send-collected-values'
														element={<SendCollectedValues />}
													/>
													<Route
														path='handoff-to-agent'
														element={<HandoffToAgent />}
													/>
												</Route>
											</Route>
											<Route path='deploy' element={<Deploy />}>
												<Route
													path='deploy-new-version'
													element={<DeployNewVersion />}></Route>
												<Route
													path='delete-version'
													element={<DeleteVersion />}
												/>
												<Route
													path='re-deploy-version'
													element={<ReDeploy />}
												/>
											</Route>
											<Route path='configuration' element={<Configure />}>
												<Route index element={<Navigate to='general' />} />
												<Route path='general' element={<ConfigureGeneral />} />
												<Route
													path='conversation-experience'
													element={<ConversationalExperience />}
												/>
												<Route path='appearance' element={<Appearance />}>
													<Route path='add-image' element={<AddImage />} />
												</Route>
												<Route
													path='voice-capabilities'
													element={<VoiceCapabilities />}
												/>
												<Route
													path='config-enhancements'
													element={<ConfigEnhancements />}
												/>
												<Route path='enhancements' element={<Enhancements />} />
												<Route
													path='security-and-privacy'
													element={<SecurityAndPrivacy />}
												/>
												<Route path='live-chat' element={<LiveChatConfig />} />
											</Route>
										</Route>
									</Route>
									<Route
										path='/app/chats'
										element={<Navigate to='my-chats' replace />}></Route>
									<Route path='/app/chats/my-chats' element={<Chats />}>
										<Route path='release' element={<ReleaseModal />} />
									</Route>
									<Route path='launch' element={<Launch />}>
										<Route path='create' element={<Create />} />

										<Route
											path='setup-wa-insta'
											element={<SetUpWhatsappInstagram />}
										/>
										{/* <Route
								path='setup-channel'
								element={<SetUpWhatsappInstagram />}
							/> */}
									</Route>
									<Route path='/app/zoho-consent' element={<RedirectURI />} />
									<Route
										path='/app/zoho-consent/thank-you'
										element={<ZThankYou />}
									/>

									<Route
										path='/app/general-configuration'
										element={<GeneralTeamSetting />}>
										<Route
											index
											element={<Navigate to='team-details' replace />}
										/>
										<Route
											path='subscription'
											element={<SubscriptionDetails />}
										/>
										<Route path='team-details' element={<TeamDetails />} />

										<Route path='Integrations' element={null}>
											<Route path='all' element={<Integrations type='all' />}>
												<Route path='email' element={<Email />} />
												<Route
													path='error-subscription'
													element={<SubscriptionError />}
												/>
												<Route
													path='link-leadsquared'
													element={<LinkLeadSquared />}></Route>
												<Route
													path='unlink-leadsquared'
													element={<UnLinkLeadSquared />}
												/>
												<Route path='unlink-email' element={<UnlinkEmail />} />

												<Route
													path='unlink-shopify'
													element={<UnlinkShopify />}
												/>
												<Route path='freshchat/link' element={<Link />} />
												<Route
													path='freshchat/link/success'
													element={<LinkSuccess />}
												/>
												<Route path='freshchat/unlink' element={<Unlink />} />
												<Route path='zoho' element={<Zoho />} />

												<Route path='unlink-zoho' element={<UnlinkZoho />} />
												<Route path='salesforce' element={<Salesforce />} />
												<Route
													path='unlink-salesforce'
													element={<UnlinkSalesforce />}
												/>
												<Route
													path='zoho-scope-modify'
													element={<ModifyZohoScope />}
												/>
											</Route>
											<Route
												path='general'
												element={<Integrations type='general' />}>
												<Route path='email' element={<Email />} />
												<Route
													path='error-subscription'
													element={<SubscriptionError />}
												/>
												<Route path='unlink-email' element={<UnlinkEmail />} />
											</Route>
											<Route path='crms' element={<Integrations type='crms' />}>
												<Route path='email' element={<Email />} />
												<Route
													path='error-subscription'
													element={<SubscriptionError />}
												/>
												<Route path='unlink-email' element={<UnlinkEmail />} />

												<Route
													path='link-leadsquared'
													element={<LinkLeadSquared />}></Route>
												<Route
													path='unlink-leadsquared'
													element={<UnLinkLeadSquared />}
												/>

												<Route
													path='unlink-shopify'
													element={<UnlinkShopify />}
												/>
												<Route path='zoho' element={<Zoho />} />

												<Route path='unlink-zoho' element={<UnlinkZoho />} />
												<Route path='salesforce' element={<Salesforce />} />
												<Route
													path='unlink-salesforce'
													element={<UnlinkSalesforce />}
												/>
												<Route
													path='zoho-scope-modify'
													element={<ModifyZohoScope />}
												/>
											</Route>
											<Route path='ecom' element={<Integrations type='ecom' />}>
												<Route
													path='error-subscription'
													element={<SubscriptionError />}
												/>
												<Route
													path='unlink-shopify'
													element={<UnlinkShopify />}
												/>
												<Route path='salesforce' element={<Salesforce />} />
												<Route
													path='unlink-salesforce'
													element={<UnlinkSalesforce />}
												/>
											</Route>
											<Route
												path='ticket-management'
												element={<Integrations type='ticket-management' />}>
												<Route path='email' element={<Email />} />
												<Route
													path='error-subscription'
													element={<SubscriptionError />}
												/>
												<Route path='unlink-email' element={<UnlinkEmail />} />
												<Route path='zoho' element={<Zoho />} />

												<Route path='unlink-zoho' element={<UnlinkZoho />} />

												<Route path='freshchat/link' element={<Link />} />
												<Route
													path='freshchat/link/success'
													element={<LinkSuccess />}
												/>
												<Route path='freshchat/unlink' element={<Unlink />} />
											</Route>
											<Route
												path='live-chat'
												element={<Integrations type='live-chat' />}>
												<Route
													path='error-subscription'
													element={<SubscriptionError />}
												/>
												<Route path='freshchat/link' element={<Link />} />
												<Route
													path='freshchat/link/success'
													element={<LinkSuccess />}
												/>
												<Route path='freshchat/unlink' element={<Unlink />} />
											</Route>
										</Route>
										<Route path='Users' element={<AddUsers />}>
											<Route path='add-user-popup' element={<AddUserPopup />} />
										</Route>
									</Route>

									<Route path='/app/configuration' element={<TeamSetting />}>
										<Route index element={<Navigate to='actions' replace />} />
										<Route path='actions' element={<ConfigActions />} />
										<Route
											path='actions/generate-leads'
											element={<GenerateLeads />}
										/>
										<Route
											path='actions/book-appointment'
											element={<BookAppointment />}
										/>
										<Route
											path='actions/call-customer-care'
											element={<CallCustomerCare />}
										/>

										<Route
											path='actions/chat-with-human'
											element={<ChatWithHuman />}>
											<Route
												path='expert-availability'
												element={<ExpertsAvailability />}
											/>
											<Route
												path='buttons-and-messages'
												element={<ConfigureBotMessage />}
											/>
											<Route
												path='handoff-triggers'
												element={<HandOffTriggers />}
											/>
											<Route
												path='livechat-leads'
												element={<LiveChatLeads />}
											/>
										</Route>
										<Route
											path='actions/raise-a-ticket'
											element={<RaiseTicket />}
										/>
										<Route
											path='multilingual-support'
											element={<MultilingualSupport />}
										/>
										<Route
											path='AI-model'
											element={<IndustrialVerticalization configPage={true} />}
										/>
										{/* <Route
								path='experience-on-web'
								element={<Navigate to='avatar' />}> */}

										{/* <Route path='experience-on-web/avatar' element={<Avatar />}>
								<Route path='add-image' element={<AddImageEOW />} />
							</Route> */}
										<Route
											path='experience-on-web/full-screen-welcome-config'
											element={<FullScreenConfig />}>
											<Route
												index
												element={<Navigate to='welcome-screen' replace />}
											/>
											<Route
												path='welcome-screen'
												element={<WelcomeFullScreen />}
												index
											/>
											<Route path='chat-screen' element={<ChatFullScreen />} />
											<Route
												path='welcome-screen/add-image'
												element={<AddImageEOW />}
											/>
											<Route
												path='chat-screen/add-image'
												element={<AddImageEOW />}
											/>
										</Route>

										<Route
											path='experience-on-web/general'
											element={<GeneralEOW />}>
											<Route path='add-image' element={<AddImageEOW />} />
										</Route>
										{/* <Route path='experience-on-web/header' element={<Header />}>
								<Route path='add-image' element={<AddImageEOW />} />
							</Route> */}
										<Route
											path='experience-on-web/background'
											element={<Background />}>
											<Route path='add-image' element={<AddImageEOW />} />
										</Route>
										<Route
											path='experience-on-web/appearance'
											element={<AppearanceEOW />}
										/>
										<Route
											path='experience-on-web/starting-the-chat'
											element={<StartingChat />}>
											<Route path='add-image' element={<AddImageEOW />} />
										</Route>
										<Route
											path='experience-on-web/messages'
											element={<Messages />}
										/>
										<Route
											path='experience-on-web/options-and-buttons'
											element={<OptionsAndButtons />}
										/>
										<Route
											path='experience-on-web/input-box'
											element={<InputBox />}
										/>
										<Route
											path='experience-on-web/persistent-menu'
											element={<PersistentMenu />}
										/>
										{/* </Route> */}

										<Route path='integrations' element={<Integrations />}>
											<Route path='email' element={<Email />} />
											<Route
												path='error-subscription'
												element={<SubscriptionError />}
											/>
											<Route
												path='link-leadsquared'
												element={<LinkLeadSquared />}></Route>
											<Route
												path='unlink-leadsquared'
												element={<UnLinkLeadSquared />}
											/>
											{/* <Route path='link-shopify' element={<LinkShopify />}>
									<Route
										path='link-shopify-info'
										element={<LinkShopifyPopup />}
									/>
								</Route>
								<Route path='unlink-shopify' element={<UnlinkShopify />} /> */}
											<Route path='freshchat/link' element={<Link />} />
											<Route
												path='freshchat/link/success'
												element={<LinkSuccess />}
											/>
											<Route path='freshchat/unlink' element={<Unlink />} />
											<Route path='zoho' element={<Zoho />}></Route>
											<Route path='unlink-zoho' element={<UnlinkZoho />} />
										</Route>
										<Route path='live-chat' element={<LiveChat />} />
										<Route path='billing' element={<PlanDetails />} />
										<Route path='users' element={<TSUsers />}>
											<Route path='addnewuser' element={<AddUser />} />
											<Route
												path='edit-bot-access'
												element={<EditBotAccess />}
											/>
											<Route
												path='make-team-admin'
												element={<MakeTeamAdmin />}
											/>
											<Route
												path='remove-from-team'
												element={<RemoveFromTeam />}
											/>
											<Route
												path='revoke-admin-access'
												element={<RevokeAdminAccess />}
											/>
										</Route>
									</Route>
									<Route path='/app/launch' element={<Launch />}>
										<Route
											path='setup-wa-insta'
											element={<SetUpWhatsappInstagram />}
										/>
										<Route path='almost-done' element={<AlmostDoneModal />} />
										<Route
											path='set-up-success'
											element={<SetUpComplateModalWaba />}
										/>
										<Route path='error-in-setup' element={<ErrorInSetup />} />
										<Route path='add-ai' element={<AddAIToWebsite />} />
										<Route path='remove-ai' element={<RemoveAIFromWebsite />} />
									</Route>
								</Route>
							</Routes>
						</BrowserRouter>
						<CookiePopup />
						<InactivityDetector />
					</div>
				) : (
					<div className='query-loader'>
						<RedirectLoader
							showRetry={retryButton}
							message={
								'Checking your Installation details from Shopify. Please wait'
							}></RedirectLoader>
					</div>
				)}
			</>
		</CacheBuster>
	);
}

export default App;
