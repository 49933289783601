import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import Modal from '../../../../Components/Modal';
import {
	updateWabaNumber,
	reset,
	resetCreateInstaWatsapp,
	resetOnBoardWabaStatus,
	resetUpdateNumbers,
} from '../../../../Features/WhatsApp-BSP/auth/authSlice';
import FindBusinessIdModal from './FindBusinessIdModal';
import '../Modal.scss';
import useDebounce from '../../../../Hooks/useDebounceHook';
import './CreateWhatsapp.scss';
import Radio from '../../../../Components/Radio';
interface Props {
	setCreate: (arg: boolean) => void;
}

interface WabaId {
	name: string;
	value: string;
}

const CreateWaba = () => {
	const location = useLocation();
	let path = location.pathname;
	let pathArr = path.split('/');
	const [businessId, setBusinessId] = useState('');
	const [wabaId, setWadaId] = useState<WabaId[]>([]);
	const [selectedWabaId, setSelectedWabaId] = useState<string | undefined>(
		undefined
	);

	const [findBussinessId, setFindBussinessId] = useState(false);
	const [businessIdError, setBusinessIdError] = useState<string | undefined>(
		undefined
	);
	const dispatch = useAppDispatch();

	const { wabaIds, updatednumbers, isLoading } = useSelector(
		(state: RootState) => state.BSPauth
	);

	const debouncedSearch = useDebounce<string>(businessId, 1000);

	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [selectRadio, setSelectedRadio] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		wabaIds && setWadaId(wabaIds.map((each) => ({ name: each, value: each })));
	}, [wabaIds]);

	// useEffect(() => {
	// 	businessId && dispatch(updateWabaNumber({ wabaId: businessId }));
	// }, [debouncedSearch]);
	// useEffect(() => {
	// 	selectedWabaId && dispatch(updateWabaNumber({ wabaId: selectedWabaId }));
	// }, [selectedWabaId]);

	// useEffect(() => {
	// 	Boolean(wabaIds.length === 1) && setSelectedId(wabaIds[0]);
	// }, []);

	useEffect(() => {
		dispatch(reset());
	}, []);

	useEffect(() => {
		if (businessId) {
			setIsSubmitDisabled(false);
			if (!Boolean(updatednumbers.length)) {
				setBusinessIdError(
					'This doesn’t seem like a valid business ID. You can find it inside Facebook Manager'
				);
			} else {
				setBusinessIdError(undefined);
			}
		} else if (!businessId) {
			setIsSubmitDisabled(true);
		}
	}, [businessId, updatednumbers]);

	useEffect(() => {
		if (pathArr) {
			if (pathArr[2] === 'channels') {
				!businessId &&
					selectedWabaId &&
					Boolean(updatednumbers.length) &&
					navigate('/app/channels/almost-done', {
						state: {
							value: {
								updatednumbers,
								selectedWabaId,
							},
						},
					});
			} else if (pathArr[2] === 'welcome') {
				!businessId &&
					selectedWabaId &&
					Boolean(updatednumbers.length) &&
					navigate('/app/welcome/almost-done', {
						state: {
							value: {
								updatednumbers,
								selectedWabaId,
							},
						},
					});
			}
		}
	}, [updatednumbers]);

	useEffect(() => {
		if (pathArr) {
			if (pathArr[2] === 'channels') {
				businessId &&
					selectedWabaId &&
					Boolean(updatednumbers.length) &&
					navigate('/app/channels/almost-done', {
						state: {
							value: {
								updatednumbers,
								selectedWabaId,
							},
						},
					});
			} else if (pathArr[2] === 'welcome') {
				businessId &&
					selectedWabaId &&
					Boolean(updatednumbers.length) &&
					navigate('/app/welcome/almost-done', {
						state: {
							value: {
								updatednumbers,
								selectedWabaId,
							},
						},
					});
			}
		}
	}, [businessId, selectedWabaId]);
	const validateBusinessId = () => {
		if (businessId.length <= 0) {
			setBusinessIdError('Required');
		} else {
			setBusinessIdError(undefined);
		}
	};

	const validateId = (channel: string | null) => {
		if (channel && channel.length > 0) {
			if (wabaId.filter((ch) => ch.value === channel).length === 1) {
				setBusinessIdError(undefined);
				return;
			}
		}
		setBusinessIdError(
			'This doesn’t seem like a valid business ID. You can find it inside Facebook Manager'
		);
	};
	const handleSubmit = () => {
		setSelectedWabaId(businessId);
	};

	//Here 2 functions are used for disabling while api call
	const handleConfirm = () => {
		setSelectedWabaId(wabaIds[0]);
	};

	const handleConfirm2 = () => {
		setSelectedWabaId(selectRadio);
	};
	return (
		<>
			<Modal
				header='Complete setting up your WhatsApp number'
				//empty funciton for misbehaving if we click outside modal
				onClose={() => { }}
				actionButtons={
					<>
						{Boolean(wabaIds.length) ? (
							Boolean(wabaIds.length === 1) ? (
								<>
									<Button
										buttonType='light'
										onClick={() => {
											dispatch(resetCreateInstaWatsapp());
											dispatch(resetOnBoardWabaStatus());
											dispatch(resetUpdateNumbers());
											navigate(-1);
										}}>
										Cancel
									</Button>
									<Button
										loading={isLoading}
										onClick={handleConfirm}
										disabled={!Boolean(updateWabaNumber.length === 1)}>
										Confirm
									</Button>
								</>
							) : (
								<>
									<Button
										buttonType='light'
										onClick={() => {
											dispatch(resetCreateInstaWatsapp());
											dispatch(resetOnBoardWabaStatus());
											dispatch(resetUpdateNumbers());
											navigate(-1);
										}}>
										Cancel
									</Button>
									<Button
										loading={isLoading}
										onClick={handleConfirm2}
										disabled={!Boolean(selectRadio.length)}>
										Confirm
									</Button>
								</>
							)
						) : (
							<>
								<Button
									buttonType='light'
									onClick={() => {
										dispatch(resetCreateInstaWatsapp());
										dispatch(resetOnBoardWabaStatus());
										dispatch(resetUpdateNumbers());
										navigate(-1);
									}}>
									Cancel
								</Button>
								<Button
									loading={isLoading}
									disabled={isSubmitDisabled}
									onClick={handleSubmit}>
									Submit
								</Button>
							</>
						)}
					</>
				}>
				<div className='modal-body'>
					{/* <p className='body1'>
						Please share a few more details with us so that we can finish
						setting up your WhatsApp number.
					</p> */}
					{!Boolean(wabaIds.length) && (
						<>
							<p className='body1'>
								Please share a few more details with us so that we can finish
								setting up your WhatsApp number
							</p>
							<div>
								<Input
									label='WhatsApp Business ID'
									value={businessId}
									onChange={(event: any) => setBusinessId(event.target.value)}
									error={Boolean(businessIdError)}
									helperText={businessIdError}
									onFocus={() => {
										setBusinessIdError(undefined);
									}}
									onBlur={validateBusinessId}
									fullWidth={true}
								/>
								{/* <p
								className='caption ff-link'
								onClick={() => setFindBussinessId(true)}>
								You can find your WhatsApp Business ID inside Facebook Manager
							</p> */}
								{/* {findBussinessId && (
								<FindBusinessIdModal
									setOpenBussinessIdModal={setFindBussinessId}
								/>
							)} */}
							</div>
						</>
					)}
					{Boolean(wabaIds.length === 1) && (
						<p>
							Please confirm, do you wish to continue with connecting your
							Flyfish team with your WhatsApp Business Account{' '}
							<b>{wabaIds[0]}</b>?
						</p>
					)}
					{Boolean(wabaIds.length > 1) && (
						<>
							<p>
								Please select the WhatsApp Business Account that you want to
								connect with your Flyfish team:
							</p>
							{/* <p>Confirm your WhatsApp Business ID:</p> */}
						</>
					)}
					{Boolean(wabaIds.length > 1) &&
						wabaIds.map((each) => {
							return (
								<div className='whatsapp-numbers-radio'>
									<Radio
										label={
											<div className='label'>
												<p className='body1'>{each}</p>
											</div>
										}
										isChecked={selectRadio}
										value={each}
										onValueChange={(value) => {
											setSelectedRadio(value);
										}}
										name={''}
									/>
								</div>
							);
						})}

					{Boolean(wabaIds.length > 1) && (
						<p
							className='caption ff-link'
							onClick={() => setFindBussinessId(true)}>
							You can find your WhatsApp Business ID inside Facebook Manager
						</p>
					)}
					{findBussinessId && (
						<FindBusinessIdModal setOpenBussinessIdModal={setFindBussinessId} />
					)}
				</div>
			</Modal>
			<Outlet />
		</>
	);
};

export default CreateWaba;
