import {
	Emoji1,
	Emoji2,
	Emoji3,
	Emoji4,
	Emoji5,
	StarFilled,
} from '../../../../../assets/Icons';
import './Ratings.scss';

type RatingsProps = {
	count: number;
	active: boolean;
	type: 'star' | 'smiley';
	onClick: () => void;
};

const Ratings = ({ count, active, type, onClick }: RatingsProps) => {
	const ratings = new Array(10).fill(undefined);

	const emojis = [
		<Emoji1 width='20px' />,
		<Emoji2 width='20px' />,
		<Emoji3 width='20px' />,
		<Emoji4 width='20px' />,
		<Emoji5 width='20px' />,
	];
	return (
		<div
			className={`ff-ratings${active ? ' active' : ''} ${type}`}
			onClick={onClick}>
			{ratings.map((rating, index) => {
				if (type === 'star') {
					if (index < count) {
						return (
							<div className='rating' key={index}>
								<StarFilled width='23px' />
							</div>
						);
					} else {
						return null;
					}
				} else {
					if (count === 3 && index % 2 !== 0) {
						return null;
					} else {
						return (
							<div className='rating' key={index}>
								{emojis[index]}
							</div>
						);
					}
				}
			})}
		</div>
	);
};
export default Ratings;
