import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loadState } from '../../utils/utils';
import { State as BuilderState } from '../builder/builderSlice';
import { getAllUsersMyhub } from '../teamsetting/teamsettingSlice';
import {
	AddExistingUserData,
	AddExistingUserDataResponse,
	AddNewUserAsAdmin,
	AddNewUserData,
	AddNewUserDataAsAdminResponse,
	AddNewUserDataResponse,
	GetAllUsersResponse,
	GetUsers,
	GetUserSuggestionResponse,
	RemoveUserData,
	RemoveUserDataResponse,
	SuggestedUsers,
	Users,
} from './modal';
import userService from './userService';
import { getTenants } from '../teams/TeamsSlice';
import { setSnackModal } from '../message/messageSlice';
type State = {
	getAllUsersLoading: boolean;
	suggestionsLoading: boolean;
	removeUserLoading: boolean;
	addUserLoading: boolean;
	addExistingUserLoading: boolean;
	botId: string;
	tenantId: string;
	error: string;
	usersList: Users[];
	suggestedUserlist: SuggestedUsers[];
	userAddSuccess: boolean;
	botDetails: any;
	somethingWentWrong: boolean;
	addUserError: boolean;
	addUserErrorMsg: string;
	removeUserId: number;
};

export const getallUsers = createAsyncThunk<
	GetAllUsersResponse,
	GetUsers,
	{ rejectValue: string }
>('/getallUsers', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await userService.getallUsers(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const getUserSuggestion = createAsyncThunk<
	GetUserSuggestionResponse,
	GetUsers,
	{ rejectValue: string }
>(
	'/getUserSuggestion',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await userService.getUserSuggestion(data);
			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const addNewUser = createAsyncThunk<
	AddNewUserDataResponse,
	AddNewUserData,
	{ rejectValue: string }
>('/addNewUser', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await userService.addNewUser(data);
		const { builder } = getState() as { builder: BuilderState };

		if (response.data) {
			dispatch(
				getallUsers({ botId: loadState('botId'), tenantId: data.tenantId })
			);
			// dispatch(
			// 	getUserSuggestion({ botId: builder.botId!, tenantId: data.tenantId })
			// );
			//dispatch(getTenants({ updateTenant:true }));
			return response.data;
		}

		return rejectWithValue(response.data);
	} catch (error: any) {
		return rejectWithValue(error.response.data.message);
	}
});

export const addTenantAdmin = createAsyncThunk<
	AddNewUserDataAsAdminResponse,
	AddNewUserAsAdmin,
	{ rejectValue: string }
>('/addTenantAdmin', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await userService.addTenantAdmin(data);
		const { builder } = getState() as { builder: BuilderState };

		if (response.data) {
			dispatch(
				getallUsers({ botId: loadState('botId'), tenantId: data.tenantId })
			);
			dispatch(
				getUserSuggestion({ botId: builder.botId!, tenantId: data.tenantId })
			);
			dispatch(getAllUsersMyhub({ tenantId: data.tenantId }));
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const addExistingUser = createAsyncThunk<
	AddExistingUserDataResponse,
	AddExistingUserData,
	{ rejectValue: string }
>('/addExistingUser', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await userService.addExistingUser(data);
		const { builder } = getState() as { builder: BuilderState };

		if (response.data) {
			dispatch(
				getallUsers({ botId: loadState('botId'), tenantId: data.tenantId })
			);
			dispatch(
				getUserSuggestion({ botId: builder.botId!, tenantId: data.tenantId })
			);
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const removeFromBotTeam = createAsyncThunk<
	RemoveUserDataResponse,
	RemoveUserData,
	{ rejectValue: string }
>(
	'/removeFromBotTeam',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await userService.removeFromBotTeam(data);
			const { builder } = getState() as { builder: BuilderState };

			if (response.data) {
				dispatch(
					setSnackModal({
						openModal: true,
						modalMesssage: 'User removed successfully',
					})
				);
				dispatch(
					getallUsers({ botId: loadState('botId'), tenantId: data.tenantId })
				);

				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const removeUserFromTeam = createAsyncThunk<
	any,
	{ tenantId: number; userId: number },
	{ rejectValue: string }
>(
	'/removeUserFromTeam',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await userService.removeUserFromTeam(data);
			if (response.data) {
				dispatch(setRemoveUserId(data.userId));
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

const botDetails: any = loadState('botDetails') ?? {};

const initialState: State = {
	getAllUsersLoading: false,
	suggestionsLoading: false,
	removeUserLoading: false,
	addUserLoading: false,
	addExistingUserLoading: false,
	error: '',
	botId: '',
	tenantId: '',
	usersList: [],
	suggestedUserlist: [],
	userAddSuccess: false,
	botDetails: botDetails,
	somethingWentWrong: false,
	addUserError: false,
	addUserErrorMsg: '',
	removeUserId: -1,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		reset: (state) => {
			state.getAllUsersLoading = false;
			state.error = '';
			state.usersList = [];
		},
		setUserAddSuccess: (state, action) => {
			state.userAddSuccess = action.payload;
		},
		setUserError: (state, action) => {
			state.addUserError = action.payload;
		},
		setaddUserErrorMsg: (state, action) => {
			state.addUserErrorMsg = action.payload;
		},
		setRemoveUserId: (state, action) => {
			state.removeUserId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getallUsers.pending, (state) => {
				state.somethingWentWrong = false;
				state.getAllUsersLoading = true;
				state.error = '';
			})
			.addCase(getallUsers.fulfilled, (state, action) => {
				state.getAllUsersLoading = false;
				state.usersList = action.payload.data;
			})
			.addCase(getallUsers.rejected, (state, action) => {
				state.somethingWentWrong = true;
				state.getAllUsersLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})

			.addCase(getUserSuggestion.pending, (state) => {
				state.somethingWentWrong = false;
				state.suggestionsLoading = true;
				state.error = '';
			})
			.addCase(getUserSuggestion.fulfilled, (state, action) => {
				state.suggestedUserlist = action.payload.suggestedUserlist;
			})
			.addCase(getUserSuggestion.rejected, (state, action) => {
				state.somethingWentWrong = true;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(addNewUser.pending, (state) => {
				state.somethingWentWrong = false;
				state.addUserLoading = true;
				state.error = '';
			})
			.addCase(addNewUser.fulfilled, (state, action) => {
				state.userAddSuccess = true;
				state.addUserError = false;
				state.addUserLoading = false;
			})
			.addCase(addNewUser.rejected, (state, action) => {
				state.somethingWentWrong = true;
				state.addUserLoading = false;
				state.addUserError = true;
				if (action.payload) {
					state.addUserErrorMsg = action.payload;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(removeFromBotTeam.pending, (state) => {
				state.somethingWentWrong = false;
				state.removeUserLoading = true;
				state.error = '';
			})
			.addCase(removeFromBotTeam.fulfilled, (state, action) => {})
			.addCase(removeFromBotTeam.rejected, (state, action) => {
				state.somethingWentWrong = true;
				state.removeUserLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(addExistingUser.pending, (state) => {
				state.somethingWentWrong = false;
				state.addExistingUserLoading = true;
				state.error = '';
			})
			.addCase(addExistingUser.fulfilled, (state, action) => {
				state.userAddSuccess = true;
			})
			.addCase(addExistingUser.rejected, (state, action) => {
				state.somethingWentWrong = true;
				state.addExistingUserLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			});
	},
});

export const {
	reset,
	setUserAddSuccess,
	setUserError,
	setaddUserErrorMsg,
	setRemoveUserId,
} = userSlice.actions;

export default userSlice.reducer;
