import DataFetchAPI from '../../utils/Api';
import {
	UnreadNotification,
	ReadNotifications,
	UpdateNotification,
	UnreadNotificationForAll,
	ReadNotificationsForAll,
} from './model';

const getUnreadNotifications = async (data: UnreadNotification) => {
	const { tenantId, userId } = data;
	return await DataFetchAPI().get(`/notifications/${tenantId}/user/${userId}`);
};

const getUnreadNotificationsForAll = async (data: UnreadNotificationForAll) => {
	const { userId } = data;
	return await DataFetchAPI().get(`/notifications/user/${userId}`);
};

const getReadNotifications = async (data: ReadNotifications) => {
	const { tenantId, userId, read } = data;
	return await DataFetchAPI().get(`/notifications/${tenantId}/user/${userId}`, {
		params: { read: read },
	});
};

const getReadNotificationsForAll = async (data: ReadNotificationsForAll) => {
	const { userId, read } = data;
	return await DataFetchAPI().get(`/notifications/user/${userId}`, {
		params: { read: read },
	});
};

const updateNotification = async (data: UpdateNotification) => {
	const { userId, notificationId } = data;
	return await DataFetchAPI().put(
		`/notifications/user/${userId}`,
		notificationId
	);
};

const notificationService = {
	getUnreadNotifications,
	getReadNotifications,
	updateNotification,
	getReadNotificationsForAll,
	getUnreadNotificationsForAll,
};

export default notificationService;
