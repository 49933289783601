import Modal from '../../../../../Components/Modal';
import WBHomePage from '../../../../../assets/images/Embedded/WBHomePage.png';
import ShowingWBId from '../../../../../assets/images/Embedded/ShowingWBId.png';
import HomePageWB from '../../../../../assets/images/Embedded/HomePageWB.png';
import Button from '../../../../../Components/Button';

import './index.scss';

const FindBusinessIdModal = ({ setOpenBussinessIdModal }) => {
	return (
		<Modal
			header='How to find WhatsApp business ID on Facebook business manager?'
			className='job-sheet-modal'
			onClose={() => setOpenBussinessIdModal(false)}
			actionButtons={
				<>
					<Button onClick={() => setOpenBussinessIdModal(false)}>
						<p className='button-1'>Close</p>
					</Button>
				</>
			}>
			<>
				<div className='job-sheet-body-1'>
					<p className='body-2'>
						After you finish creating a WhatsApp business account, please open
						the verification email received from Facebook Business. Click the
						Confirm now button. You will be taken to to Facebook Business page.
					</p>
					<p className='body-2'>
						Click on WhatsApp Accounts in the left pane. Select the WhatsApp
						account that you wish to see the ID of. You will find the ID on the
						right as shown.
					</p>
					<div className='Image-1'>
						<img src={WBHomePage} alt='ShowingWBPage' />
					</div>
					<p className='body-2'>
						Select the WhatsApp account that you wish to see the ID of. You will
						find the ID on the right as shown.
					</p>
					<div className='Image-1'>
						<img src={ShowingWBId} alt='ShowingWBPage' />
					</div>
					<p className='body-2'>
						If you didn’t receive the email, go to{' '}
						<a
							href='https://business.facebook.com'
							target='_blank'
							rel='noreferrer'>
							business.facebook.com
						</a>{' '}
						and login.
					</p>
					<p className='body-2'>
						Navigate to settings by clicking on the settings icon.
					</p>
					<div className='Image-1'>
						<img src={HomePageWB} alt='ShowingWBPage' />
					</div>
					<p className='body-2'>
						Click on WhatsApp Accounts in the left pane. Select the WhatsApp
						account that you wish to see the ID of. You will find the ID on the
						right as shown.
					</p>
					<div className='Image-1'>
						<img src={ShowingWBId} alt='ShowingWBPage' />
					</div>
				</div>
			</>
		</Modal>
	);
};

export default FindBusinessIdModal;
