import { CloseCircle } from '../../Pages/ComponentsLibrary/Icons';
import InteractiveButton from '../Button/InteractiveButton';
import './Chip2.scss';

type ChipProps = {
	className?: string;
	label: JSX.Element | string;
	icon?: JSX.Element;
	onDelete?: (event: any) => void;
	onClick?: (event: any) => void;
};

const Chip2 = ({
	label,
	icon,
	onDelete,
	onClick,
	className = '',
}: ChipProps) => {
	return (
		<div className={`ff-chip-2 ${className}`} onClick={onClick}>
			{icon}
			<span className='body1'>{label}</span>
			<div className='close-icon'>
				<InteractiveButton
					hoverable='none'
					icon={<CloseCircle />}
					onClick={onDelete}
				/>
			</div>
		</div>
	);
};

export default Chip2;
