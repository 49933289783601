import DataFetchAPI from '../../utils/Api';
import DataFetchRouteAPI from '../../utils/RouteApi';
import { IndustryState, createVerticalState, getVerticalState } from './model';
const createVertical = async (data: createVerticalState) => {
	return await DataFetchRouteAPI(false, true, 'Bearer').post(
		`llmconnector/create/config`,
		data
	);
};

const getVertical = async (data: getVerticalState) => {
	return await DataFetchRouteAPI(false, true, 'Bearer').post(
		`llmconnector/get/config`,
		data
	);
};

const updateVertical = async (data: createVerticalState) => {
	return await DataFetchRouteAPI(false, true, 'Bearer').post(
		`llmconnector/update/config`,
		data
	);
};

const verticalizationService = {
	createVertical,
	getVertical,
	updateVertical,
};
export default verticalizationService;
