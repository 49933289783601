import React, { useState } from 'react';
import './ColorInput.scss';

type Props = {
	variant?: 'small' | 'large';
	value: string;
	onValueChange: (arg0: string) => void;
	placeholder?: string;
	label?: string;
	limit?: number;
	error?: boolean;
	helperText?: string | React.ReactNode;
	limitError?: string | React.ReactNode;
	startIcon?: React.ReactElement;
	endIcon?: React.ReactElement;
	fullWidth?: boolean;
} & React.ComponentProps<'input'>;

const ColorInput = ({
	variant = 'large',
	value,
	onValueChange,
	placeholder,
	label,
	className,
	startIcon,
	endIcon,
	error,
	helperText,
	limitError,
	limit,
	...rest
}: Props) => {
	const [id] = useState('input_' + Math.random().toString(36).substring(2, 7)); //NOSONAR
	const [isFocused, setIsFocused] = useState(false);
	const [isLimitError, setIsLimitError] = useState(false);

	const onFocus = () => {
		setIsFocused(true);
	};

	const onBlur = () => {
		setIsFocused(false);
	};

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = event.target.value;
		onValueChange(val);
		if (limit && limit > 0) {
			setIsLimitError(limit < val.length);
		}
	};

	return (
		<div className={`ff-color-input ${className ?? ''} `}>
			<div
				className={`color-input color-input-${variant} ${
					isFocused ? 'focused' : ''
				} ${error || isLimitError ? 'error' : ''}`}>
				<div className='color-input-container'>
					{label ? <label htmlFor={id}>{label}</label> : null}
					<input
						className={`color-input ${className ?? ''}`}
						value={value}
						name={id}
						id={id}
						placeholder={placeholder}
						onChange={onChange}
						onFocus={onFocus}
						onBlur={onBlur}
						{...rest}
					/>
				</div>
			</div>
		</div>
	);
};

export default ColorInput;
