import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import { CollapseItUpIcon, DropItDownIcon } from '../../../../assets/Icons';
import AutoComplete from '../../../../Components/AutoComplete';
import Checkbox from '../../../../Components/Checkbox';
import Input from '../../../../Components/Input';
import {
	availableLanguage,
	resetInvalidtranslationkey,
	setDropdownvalue,
	setIsMultilingualEnabled,
	setTranslationApiKey,
} from '../../../../Features/configure/configureSlice';
import { reset } from '../../../../Features/message/messageSlice';
import './Languages.scss';

const Languages = () => {
	const {
		isMultiLingualEnabled,
		translationApiKey,
		languageOptions,
		availableLanguages,
		dropdownValue,
		invalidtranslationkey,
	} = useSelector((state: RootState) => state.configure);
	const dispatch = useAppDispatch();
	const [drop, setDrop] = useState(true);
	const [error, setError] = useState(false);

	const validate = (val: any) => {
		if (val.length <= 0) {
			return false;
		}
		let avl = availableLanguages.map((lang) => lang.language.toLowerCase());
		return !Boolean(avl.indexOf(val.toLowerCase()) > -1);
	};

	useEffect(() => {
		dispatch(availableLanguage({ isInternal: true }));
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (invalidtranslationkey === 'loading') {
			setError(false);
			dispatch(resetInvalidtranslationkey());
		} else if (invalidtranslationkey === 'success') {
			setError(false);
			dispatch(resetInvalidtranslationkey());
		} else if (invalidtranslationkey === 'Invalid translate api key.') {
			setError(true);
			dispatch(resetInvalidtranslationkey());
		}
	}, [invalidtranslationkey]);

	useEffect(() => {
		if (isMultiLingualEnabled[0]) {
			setDrop(true);
		} else {
			setDrop(false);
		}
	}, [isMultiLingualEnabled[0]]);

	return (
		<div className={`ff-multilingual-languages ${drop ? 'dropped' : ''}`}>
			<div
				className={`multilingual-support ${drop ? 'dropped' : ''} ${isMultiLingualEnabled[0] ? 'checked' : 'unchecked'
					}`}
				onClick={() => isMultiLingualEnabled[0] && setDrop(!drop)}>
				<div>
					<h6 className='header'>Multilingual support</h6>
				</div>
				<div>
					<Checkbox
						index={0}
						isChecked={isMultiLingualEnabled}
						onValueChange={(value) => {
							dispatch(setIsMultilingualEnabled(value));
						}}
					/>
					<span onClick={() => isMultiLingualEnabled[0] && setDrop(!drop)}>
						{drop ? (
							<CollapseItUpIcon />
						) : isMultiLingualEnabled[0] ? (
							<DropItDownIcon />
						) : null}
					</span>
				</div>
			</div>

			{drop ? (
				<>
					<hr />

					<div className='languages'>
						<div>
							<AutoComplete
								label='Supported Languages'
								placeholder='Enter language'
								validator={validate}
								multiple={true}
								helperText='Customer will be able to select from these languages at the time of chat'
								options={languageOptions}
								valueKey='value'
								labelKey='label'
								value={dropdownValue}
								onChange={(value) => dispatch(setDropdownvalue(value))}
							/>
						</div>
					</div>
					<div className='translation-api-key'>
						<div>
							<Input
								fullWidth
								error={error}
								errorText={'Invalid API Key'}
								label={'Translation API key'}
								value={translationApiKey}
								onChange={(event) =>
									dispatch(setTranslationApiKey(event.target.value))
								}
								placeholder='Enter translation API key'
								autoFocus
							/>
						</div>
					</div>
				</>
			) : null}
		</div>
	);
};

export default Languages;
