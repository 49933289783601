import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	AnalyticsDialog,
	dialogQueriesView,
	dialogViewAll,
	SelecteddialogQuery,
} from '../../../Features/Analytics/analyticsSlice';
import { DialogData } from '../../../Features/Analytics/model';
import {
	AnalyticsDialogarrow,
	ViewAllTemplatesArrow,
} from '../../../assets/Icons';
import './dialoganalytics.scss';

type dialogList = {
	triggeredQueryId: number;
	timesCompleted: number;
	triggeredQuery: string;
	triggeredQueryCount: number;
	completeRate: number;
};

function DialogAnalytics() {
	const location = useLocation();
	const path = location.pathname;
	const navigate = useNavigate();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const [dialogList, setDialogList] = useState<dialogList[]>([]);
	const [sortList, setsortList] = useState<dialogList[]>([]);
	const [sortTriggeredQueryCount, setsortTriggeredQueryCount] = useState<
		dialogList[]
	>([]);

	const { selectedDates, calendarstatus, analyticsDialog, activeAnalyticsBot } =
		useSelector((state: RootState) => state.analytics);

	const twosortlist = (arr: dialogList[], prop1: string, prop2: string) => {
		const sort1 = [...arr].sort((a: any, b: any) => b[prop1] - a[prop1]);
		const sort2 = [...arr].sort((a: any, b: any) => b[prop2] - a[prop2]);
		setsortList(sort1);
		setsortTriggeredQueryCount(sort2);
	};

	useEffect(() => {
		twosortlist(dialogList, 'completeRate', 'triggeredQueryCount');
	}, [dialogList]);

	const sortedQueryList =
		path === '/app/analytics/multichannel-storefronts/bot/viewall-dialog' ||
		path === '/app/analytics/intelligent-ads/bot/viewall-dialog'
			? sortList
			: sortList.slice(0, 5);

	const calculateWidth = (timesCompleted: number, count: number) => {
		let newWidth = (timesCompleted * 100) / count;
		return newWidth + 'px';
	};
	const calculateWidth2 = (timesCompleted: number, count: number) => {
		let newWidth = (timesCompleted * 100) / count;
		const newWidth2 = (newWidth * 100) / 200;
		return newWidth2 + '%';
	};

	useEffect(() => {
		let newDialogData: any = [];
		const analyticsDialog = {
			total: null,
			data: [
				{
					wfStart: 95,
					wfEnd: 80,
					wfId: 5968,
					triggerQuery: 'Test - 1',
				},
				{
					wfStart: 80,
					wfEnd: 70,
					wfId: 7297,
					triggerQuery: 'Test - 2',
				},
				{
					wfStart: 10,
					wfEnd: 0,
					wfId: 5474,
					triggerQuery: 'Test - 3',
				},
				{
					wfStart: 0,
					wfEnd: 10,
					wfId: 6276,
					triggerQuery: 'Test - 4',
				},
				{
					wfStart: 200,
					wfEnd: 80,
					wfId: 5968,
					triggerQuery: 'Test - 6',
				},
				{
					wfStart: 250,
					wfEnd: 70,
					wfId: 7297,
					triggerQuery: 'Test - 2',
				},
				{
					wfStart: 290,
					wfEnd: 80,
					wfId: 5968,
					triggerQuery: 'Test - 5',
				},
				{
					wfStart: 300,
					wfEnd: 70,
					wfId: 7297,
					triggerQuery: 'Test - 2',
				},
				{
					wfStart: 195,
					wfEnd: 80,
					wfId: 5968,
					triggerQuery: 'Test - 4',
				},
				{
					wfStart: 170,
					wfEnd: 70,
					wfId: 7297,
					triggerQuery: 'Test - 2',
				},
			],
		};
		analyticsDialog.data &&
			analyticsDialog.data.map((item: DialogData, key: any) => {
				newDialogData.push({
					triggeredQueryId: item.wfId,
					timesCompleted: item.wfEnd,
					triggeredQuery: item.triggerQuery,
					triggeredQueryCount: item.wfStart,
					completeRate:
						item.wfEnd === 0 || item.wfStart === 0
							? 0
							: ((item.wfEnd / item.wfStart) * 100).toFixed(2),
				});
			});

		setDialogList(newDialogData);
	}, [analyticsDialog.data]);

	useEffect(() => {
		if (calendarstatus && tenantId) {
			dispatch(
				AnalyticsDialog({
					startdate: selectedDates[0],
					enddate: selectedDates[1],
					timezone: -330,
					tenantId: tenantId,
					botId: activeAnalyticsBot.botId,
				})
			);
		}
	}, [selectedDates]);

	const dispatch = useAppDispatch();
	const viewAll = () => {
		navigate('viewall-dialog');
		// dispatch(dialogViewAll(true));
	};

	const viewDialogAnalytics = (selected: any) => {
		(path === '/app/analytics/multichannel-storefronts/bot/viewall-dialog' ||
			path === '/app/analytics/intelligent-ads/bot/viewall-dialog') &&
			navigate(-1);
		dispatch(SelecteddialogQuery(selected));
		dispatch(dialogQueriesView(true));
		document.querySelector('.ff_analytics_container')?.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<>
			{path === '/app/bot/analytics/viewall-dialog' ||
			path === '/app/analytics/multichannel-storefronts/bot/viewall-dialog' ||
			path === '/app/analytics/intelligent-ads/bot/viewall-dialog' ? (
				''
			) : (
				<div className='ff_DialogAnalytics_heading'>
					{/* <h5>Dialog Analysis</h5> */}
					<h5> Customer Journey Analysis</h5>
				</div>
			)}
			{sortedQueryList.length === 0 ? (
				<div className='ff_topQueries_Nodata'>No data to display</div>
			) : (
				<div className='ff_DialogAnalytics_table'>
					<div className='ff_DialogAnalytics_table_heading '>
						<h5 className='ff_DialogAnalytics_sub_heading triggering_query_heading'>
							Triggering Query
						</h5>
						<h5 className='ff_DialogAnalytics_sub_heading'>
							No. of times triggered
						</h5>
						<h5 className='ff_DialogAnalytics_sub_heading'>Completion Rate</h5>
					</div>

					<div>
						{sortedQueryList.map((query: any) => {
							return (
								<div
									className='ff_DialogAnalytics_data'
									onClick={() => {
										viewDialogAnalytics(query);
										document
											.querySelector('.ff_analytics_container ')
											?.scrollTo({
												top: 0,
												behavior: 'smooth',
											});
									}}
									style={{ cursor: 'pointer' }}>
									<div className='ff_DialogAnalytics body1'>
										{query.triggeredQuery}
									</div>
									<div className='ff_DialogAnalytics_count_container'>
										<div className='body1'>{query.triggeredQueryCount}</div>
										<div
											className='ff_DialogAnalytics_count_bar'
											style={{
												width:
													sortTriggeredQueryCount[0].triggeredQueryCount ===
													query.triggeredQueryCount
														? '200px'
														: calculateWidth(
																query.triggeredQueryCount,
																sortTriggeredQueryCount[0].triggeredQueryCount
														  ),
											}}></div>
									</div>
									<div className='ff_DialogAnalytics_count_container_2'>
										<div className='body1'>
											{query.completeRate === 0
												? '-'
												: query.completeRate + ' '}
											{query.completeRate === 0 ? '' : '%'}
										</div>
										<div className='ff_dialog_percent_trigger_Query'>
											<div className='ff_completion_rate'>
												<div className='ff_DialogAnalytics_count_bar_bg'>
													<div
														className='ff_DialogAnalytics_count_bar_bgor'
														style={{
															width:
																sortedQueryList[0].completeRate === 0 &&
																query.completeRate === 0
																	? '0px'
																	: sortedQueryList[0].completeRate ===
																	  query.completeRate
																	? '122px'
																	: calculateWidth(
																			query.completeRate,
																			sortedQueryList[0].completeRate
																	  ),
														}}></div>
												</div>
											</div>
											<div
												style={{ cursor: 'pointer' }}
												className='ff_completion_rate_details'>
												<AnalyticsDialogarrow style={{ cursor: 'pointer' }} />
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			)}
			{path === '/app/bot/analytics/viewall-dialog' ||
			path === '/app/analytics/multichannel-storefronts/bot/viewall-dialog' ||
			path === '/app/analytics/intelligent-ads/bot/viewall-dialog' ? (
				''
			) : (
				<div className='view-all-container'>
					{/* empty container for applying space between */}
					<div></div>
					<div className='ff_DialogAnalytics_viewall' onClick={viewAll}>
						<div className='ff_DialogAnalytics_viewall_text button1'>
							View all
						</div>
						<div className='ff_DialogAnalytics_viewall_arrow'>
							<ViewAllTemplatesArrow />
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default DialogAnalytics;
