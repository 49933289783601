import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../app/store';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import { setOpenModalForLaunch } from '../../../../../Features/channel/channelSlice';
import {
	getBusinessId,
	getInstaId,
} from '../../../../../Features/WhatsApp-BSP/auth/authSlice';
import { getPath, loadState } from '../../../../../utils/utils';
import { updateWabaNumber, setSelectedWabaId } from '../../../../../Features/WhatsApp-BSP/auth/authSlice';
import './SetUpWhatsapp.scss';

const SetUpWhatsappInstagram = () => {
	const [token, setToken] = useState('');
	const [wabaId, setWabaId] = useState('');
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const botDetails = loadState('botDetails');
	const state = location.state as { value: any };
	//function sending to index.html
	const sessionInfoListener = (event: any) => {
		if (event.origin !== 'https://www.facebook.com') return;
		try {
			const data = JSON.parse(event.data);
			if (data.type === 'WA_EMBEDDED_SIGNUP') {
				// if user finishes the embedded sign up flow
				if (data.event === 'FINISH') {
					const { phoneID, wabaID } = data.data;
					dispatch(setSelectedWabaId(data.data.waba_id));
					dispatch(updateWabaNumber({ wabaId: data.data.waba_id }));

					// console.log(data.data);
				}
				// if user cancels the embedded sign up flow
				else {
					const { currentStep } = data.data;
					// console.log('current step');
				}
			}
		} catch {
			// Don’t parse info that’s not a JSON
			// console.log('Non JSON Response', event.data);
		}
	};
	useEffect(() => {
		window.addEventListener('message', sessionInfoListener);
	}, []);
	const fetchBusinessId = (accessToken: string) => {
		console.log('token business', accessToken);
	};
	const fetchBusinessInstaId = (data: any, token: any) => {
		dispatch(getInstaId({ fbresponse: data, accessToken: token }));
		setToken(token);
	};
	const { wabaNos, instaIds } = useSelector(
		(state: RootState) => state.BSPauth
	);
	let path = location.pathname;
	let pathArr = path.split('/');

	return (
		<>
			<Modal
				onClose={() => navigate(-1)}
				header={
					state.value === 'Whatsapp' ? (
						<h5>Setup a new WhatsApp Business Number</h5>
					) : (
						<h5>Add a new Instagram account</h5>
					)
				}
				actionButtons={
					<div className='action-buttons-container'>
						<Button
							size='large'
							buttonType='light'
							onClick={() => {
								// if (path) {
								// 	if (path.indexOf('channels') > -1) {
								// 		navigate('/app/channels');
								// 	} else if (path.indexOf('welcome') > -1) {
								// 		navigate('/app/welcome');
								// 	} else if (path.indexOf('catalog') > -1) {
								// 		navigate(
								// 			`/app/catalog/${getPath(botDetails.cavSRType)}/launch`
								// 		);
								// 		dispatch(setOpenModalForLaunch(false));
								// 	}
								// }

								navigate(-1);
							}}>
							Cancel
						</Button>
						<div className='buttons-back-proceed'>
							{(wabaNos.length !== 0 ||
								instaIds.length !== 0 ||
								path.indexOf('launch') > -1) && (
									<Button
										size='large'
										buttonType='light'
										onClick={() => navigate(-1)}>
										Back
									</Button>
								)}
							<Button
								size='large'
								onClick={() => {
									state.value === 'Whatsapp'
										? window.launchWhatsAppSignup(fetchBusinessId)
										: window.launchInstagramSignup(fetchBusinessInstaId);
									if (path) {
										if (path.indexOf('channels') > -1) {
											navigate('/app/channels');
										} else if (path.indexOf('welcome') > -1) {
											navigate('/app/welcome');
										} else if (path.indexOf('catalog') > -1) {
											navigate(
												`/app/catalog/${getPath(botDetails.cavSRType)}/launch`
											);
											dispatch(setOpenModalForLaunch(false));
										}
									}
									navigate(-1);
								}}>
								Proceed
							</Button>
						</div>
					</div>
				}>
				<div>
					<p className='body1'>
						{state.value === 'Whatsapp'
							? 'To setup a WhatsApp Business number, you need to login to Facebook and register and connect your WhatsApp Business account to Flyfish.'
							: 'To add an Instagram account to Flyfish, you must have a professional Instagram account linked with a Facebook page.'}
					</p>
					<p className='body1 wa-insta-modal-paragraph'>
						{state.value === 'Whatsapp'
							? 'On clicking proceed, a Meta (Facebook) window will open and guide	you through the above steps.'
							: 'On clicking proceed, you will be asked to log in to Facebook and select the Instagram account and the Facebook page that you want to link with Flyfish.'}
					</p>
				</div>
			</Modal>
		</>
	);
};

export default SetUpWhatsappInstagram;
