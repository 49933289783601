import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { RootState, useAppDispatch } from '../../app/store';
import './TeamSetting.scss';
import { NEWTABS, NEWTABSWITHOUTAIMODEL } from './constants';
import TabGenerator from '../../Components/TabGenerator/TabGenerator';
import Button from '../../Components/Button';
import {
	GCBPreview,
	GCBPreviewCollapsed,
} from '../styles/EOW/GCBPreview/gcbpreview.styled';
import { setAISelect } from '../../Features/Verticalization/verticalizationSlice';
import { BOT_ICONS } from '../CatalogScreen/AddNewBot';
import { getVertical } from '../../Features/Verticalization/verticalizationSlice';
import {
	getAIDetails,
	setColorMode,
	setColorScale,
	setShowBotPreviewCollapsed,
	updateAIDetails,
	setChildTabs,
	setCheckList,
	setBookAptCheckList,
	setRatCheckList,
	getTaskType,
	setCurrentScreen,
	setliveChatcheckList,
} from '../../Features/ExperienceOnWeb/experienceonwebSlice';
import {
	arrayOfAllColors,
	hexToRGB,
	loadState,
	nameOfCrmsLinked,
	relativeLuminance,
	saveState,
	toUTCTime,
} from '../../utils/utils';
import AIHeader from './ExperienceOnWeb/AIPreview/AIHeader';
import AIBody from './ExperienceOnWeb/AIPreview/AIBody';
import AIFooter from './ExperienceOnWeb/AIPreview/AIFooter';
import { updateVertical } from '../../Features/Verticalization/verticalizationSlice';
import {
	crmsLinked,
	setCrmsLinkedDataLBV,
	setSelectedTab,
	workflowDestination,
} from '../../Features/teamsetting/teamsettingSlice';
import {
	getReadNotifications,
	getUnreadNotifications,
} from '../../Features/Notifications/notificationSlice';
import PageLoader from '../../Components/PageLoader';

const TeamSetting = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { AISelected } = useSelector(
		(state: RootState) => state.verticalization
	);
	const {
		disableSave,
		disableSaveProductCount,
		disableSaveSubHead,
		headerIconPosition,
		showavatar,
		avatarImage,
		fontFamily,
		fontSize,
		aiLaunchPromptCheckbox,
		launchPromptBackgroundColor,
		launchPromptTextColor,
		aiLaunchPromptText,
		autoPopupTime,
		autoPopupValue,
		botBackground,
		backgroundSolidColor,
		backgroundGradientStartColor,
		backgroundGradientStopColor,
		backgroundFile,
		headerContentType,
		aiName,
		headerColor,
		headerTextAndIconColor,
		headerFile,
		inputBoxBackgroundColor,
		inputBoxTextColor,
		userMessageBubbleColor,
		userMessageTextColor,
		aiMessageBubbleColor,
		aiMessageTextColor,
		timeStampBckColor,
		timeStampTextColor,
		timeStampBckRange,
		responseLevelFeedBackBckColor,
		responseLevelFeedBackBckRange,
		responseLevelFeedBackBorderColor,
		responseLevelFeedBackBorderRange,
		responseLevelFeedBackIconColor,
		userComment,
		userFeedBack,
		buttonBckColor,
		buttonTextAndOutlineColor,
		buttonTextColor,
		optionsAndQuickLinksBckColor,
		optionsAndQuickLinksBckRange,
		optionsAndQuickLinksBorderColor,
		optionsAndQuickLinksBorderRange,
		optionsAndQuickLinksTextColor,
		menuItems,
		persistentMenuCheckbox,
		welcomeBannerCheckbox,
		welcomeMessage,
		cardHeading,
		cardBody,
		carouselTitle,
		welcomeBannerFile,
		inputFieldsData,
		carouselFieldsData,
		carouselFieldsDataFullscreen,
		persistentinputFieldsData,
		aiDetails,
		ratButtonText,
		ratEmail,
		ratIssuedescription,
		ratPhonenumber,
		ratname,
		isMultiLingualEnabled,
		translationApiKey,
		bookAptButtonText,
		bookAptCompany,
		bookAptCountry,
		bookAptDate,
		bookAptPhonenumber,
		bookAptTime,
		bookAptEmail,
		bookAptname,
		callcustomercareButtonText,
		callcustomercarePhonenumber,
		generateLeadsButtonText,
		generateLeadsCompany,
		generateLeadsCountry,
		generateLeadsEmail,
		generateLeadsPhonenumber,
		generateLeadsname,
		saveButtonLoader,
		enableGenerateLeads,
		enableRAT,
		enableBookAPT,
		enableCHE,
		enableCCC,
		childTabs,
		agentAvailabilityTime,
		agentTimeSchedule,
		agentTimeZone,
		notify,
		emailtonotify,
		onfailureCheckbox,
		onUserErrorCheckbox,
		negativeSentimentCheckbox,
		failureValue,
		userErrorValue,
		negativeSentimentValue,
		buttonText,
		unavailabilityMessageInside,
		unavailabilityMessageOutside,
		handoffConfirmationPrompt,
		handoffMessage,
		waitTimeMessage,
		handoffCancellationMessage,
		handoffEndMessage,
		showBotPreviewCollapsed,
		//newBot preview
		currentScreen,
		colorScale,
		selectedColor,
		colorMode,
		conversationOpener,
		checkList,
		ratCheckList,
		bookAptCheckList,
		liveChatcheckList,
		ratCollectionPrompt,
		bookAptCollectionPrompt,
		generateLeadsCollectionPrompt,
		taskTypes,
		bookAptManagement,
		generateLeadsDestination,
		ratTicketManagement,
		additionalsettingMandatoryCollection,
		additionalsettingMandatoryLeads,
		additionalsettingDetailsCollection,
		introductoryHeading,
		introductoryBody,
		productResultCount,
		dropdownValue,
		liveChatLeadsCompany,
		liveChatLeadsCountry,
		liveChatLeadsEmail,
		liveChatLeadsPhonenumber,
		liveChatLeadsname,
		liveChatLeadsCollectionPrompt,
		customPrompts,
		getAIDetailsLoader,
		selectedTheme,
		selectedVariants,
		selectedButton,
		additionalRecommendation,
		recommendationType,
		showOnHover,
		recommendationHeading,
		recommendationCount,
		welcomeMonacoImage,
		welcomeEcomImage,
		enableSplash,
		welcomeEcomLogo,
		enableStyleAdvisor,
		styleAdvisorTitle,
		imageLoader,
		styleError
	} = useSelector((state: RootState) => state.experienceonweb);
	const {
		failedResponse,
		enableProbing,
		competitorNames,
		llmConfig,
		industryConfig,
		selectedEcom,
		selectedFinance,
		selectedRealStateOrOther,
		tone,
		instructions,
		otherBussiness,
		recommendationRational,
		complimentaryProducts,
	} = useSelector((state: RootState) => state.verticalization);
	const tenantId = loadState('tenantId');
	const { selectedTab } = useSelector((state: RootState) => state.teamsetting);
	const dispatch = useAppDispatch();

	useEffect(() => {
		let v = location.pathname.split('/');
		if (v[v.length - 1] === 'actions' || v[v.length - 1] === 'configuration') {
			saveState('selectedConfigTab', 'Actions');
			dispatch(setSelectedTab(NEWTABS[0].Tab.title));
		}
	}, [selectedTab]);

	useEffect(() => {
		dispatch(setAISelect(false));
	}, []);

	useEffect(() => {
		let dataTab = [];
		if (enableGenerateLeads) {
			dataTab.push({
				title: 'Generate Leads',
				path: 'actions/generate-leads',
			});
		}
		if (enableBookAPT) {
			dataTab.push({
				title: 'Book Appointment',
				path: 'actions/book-appointment',
			});
		}
		if (enableCCC) {
			dataTab.push({
				title: 'Call Customer Care',
				path: 'actions/call-customer-care',
			});
		}
		if (enableRAT) {
			dataTab.push({ title: 'Raise a Ticket', path: 'actions/raise-a-ticket' });
		}
		if (enableCHE) {
			dataTab.push({
				title: 'Chat With a Human',
				path: 'actions/chat-with-human',
			});
		}
		dispatch(setChildTabs(dataTab));
	}, [enableBookAPT, enableGenerateLeads, enableRAT, enableCHE, enableCCC]);

	const onTabChangeHandler = (tabName: string) => {
		let TABS = NEWTABS;
		if (customPrompts[0]) {
			TABS = NEWTABSWITHOUTAIMODEL;
		}
		TABS.map((eachTab, index) => {
			if (eachTab.Tab.title === tabName) {
				if (eachTab.Tab.title === 'Experience on web') {
					dispatch(setSelectedTab('General'));
					saveState('selectedConfigTab', 'General');
					navigate(eachTab.Tab.path);
					// navigate('experience-on-web/general');
				} else {
					dispatch(setSelectedTab(eachTab.Tab.title));
					saveState('selectedConfigTab', eachTab.Tab.title);
					navigate(eachTab.Tab.path);
				}
			}

			eachTab.SubTabs &&
				eachTab.SubTabs.map((data: any, i: number) => {
					if (data.title === tabName) {
						dispatch(setSelectedTab(data.title));
						saveState('selectedConfigTab', data.title);
						navigate(data.path);
					}
				});
		});
	};
	const errors = {
		displayName: '',
		type: '',
	};
	const TabStyle = {
		pointerEvents: 'none',
	};

	const namingClass = () => {
		if (location.pathname.includes('configuration/users/')) {
			return 'usersPath';
		} else if (
			location.pathname.includes('general-configuration/Integrations/')
		) {
			return 'integrations';
		} else if (location.pathname.includes('general-configuration/live-chat/')) {
			return 'live-chat';
		} else {
			let a = location.pathname.split('/');
			let nameOfClass = a[a.length - 1];
			return nameOfClass;
		}
	};
	const convertArraytoString = (array: any) => {
		let key: string[] = [];
		let value: string[] = [];
		let carouselsData: { linkText: string; linkValue: string }[] = [];
		array.forEach((data: any, i: number) => {
			(i === 0 || i % 2 === 0) && key.push(data);
			i % 2 !== 0 && value.push(data);
		});

		for (let i = 0; i < value.length; i++) {
			carouselsData.push({ linkText: key[i], linkValue: value[i] });
		}
		carouselsData = carouselsData.filter((link) => link.linkText !== '');
		return carouselFieldsData;
	};

	const configPicker = (name: string) => {
		if (name === 'agentSupport') {
			return enableCHE ? 'true' : 'false';
		} else if (name === 'customercareSupport') {
			return enableCCC ? 'true' : 'false';
		} else if (name === 'cusomercareCaption') {
			return callcustomercareButtonText;
		} else if (name === 'cusomercareContact') {
			return callcustomercarePhonenumber;
		} else if (name === 'showAvatar') {
			return showavatar[0] ? 'True' : 'False';
		} else if (name === 'avatarImage') {
			return avatarImage;
		} else if (name === 'headerContentType') {
			return headerContentType;
		} else if (name === 'headerImage') {
			return headerFile;
		} else if (name === 'aiName') {
			return aiName;
		} else if (name === 'headerIconPosition') {
			return headerIconPosition;
		} else if (name === 'headerBackgroundColor') {
			return headerColor;
		} else if (name === 'headerTextAndIconColor') {
			return headerTextAndIconColor;
		} else if (name === 'backgroundType') {
			return botBackground;
		} else if (name === 'backgroundColor') {
			if (botBackground === 'color') {
				return backgroundSolidColor;
			}
			if (botBackground === 'gradient') {
				return `linear-gradient(${backgroundGradientStartColor},${backgroundGradientStopColor})`;
			}
			if (botBackground === 'image') {
				return backgroundFile;
			}
		} else if (name === 'backgroundImage' && botBackground === 'image') {
			return backgroundFile;
		} else if (name === 'fontFamily') {
			return fontFamily.value;
		} else if (name === 'fontSize') {
			return fontSize.value;
		} else if (name === 'inputBoxBackgroundColor') {
			return inputBoxBackgroundColor;
		} else if (name === 'inputBoxTextColor') {
			return inputBoxTextColor;
		} else if (name === 'userMessageBubbleColor') {
			return userMessageBubbleColor;
		} else if (name === 'userMessageTextColor') {
			return userMessageTextColor;
		} else if (name === 'aiMessageBubbleColor') {
			return aiMessageBubbleColor;
		} else if (name === 'aiMessageTextColor') {
			return aiMessageTextColor;
		} else if (name === 'timeStampBckColor') {
			return hexToRGB(timeStampBckColor, parseInt(timeStampBckRange) / 100);
		} else if (name === 'timeStampTextColor') {
			return timeStampTextColor;
		} else if (name === 'responseLevelFeedBackBckColor') {
			return hexToRGB(
				responseLevelFeedBackBckColor,
				parseInt(responseLevelFeedBackBckRange) / 100
			);
		} else if (name === 'responseLevelFeedBackBorderColor') {
			return hexToRGB(
				responseLevelFeedBackBorderColor,
				parseInt(responseLevelFeedBackBorderRange) / 100
			);
		} else if (name === 'responseLevelFeedBackIconColor') {
			return responseLevelFeedBackIconColor;
		} else if (name === 'userComment') {
			return userComment[0] ? 'True' : 'False';
		} else if (name === 'userFeedBack') {
			return userFeedBack[0] ? 'True' : 'False';
		} else if (name === 'optionsAndQuickLinksBckColor') {
			return hexToRGB(
				optionsAndQuickLinksBckColor,
				parseInt(optionsAndQuickLinksBckRange) / 100
			);
		} else if (name === 'optionsAndQuickLinksTextColor') {
			return optionsAndQuickLinksTextColor;
		} else if (name === 'optionsAndQuickLinksBorderColor') {
			return hexToRGB(
				optionsAndQuickLinksBorderColor,
				parseInt(optionsAndQuickLinksBorderRange) / 100
			);
		} else if (name === 'buttonBckColor') {
			return buttonBckColor;
		} else if (name === 'buttonTextColor') {
			return buttonTextColor;
		} else if (name === 'buttonTextAndOutlineColor') {
			return buttonTextAndOutlineColor;
		} else if (name === 'FSWelcomeHeading') {
			return introductoryHeading;
		} else if (name === 'productResultCount') {
			return productResultCount;
		} else if (name === 'FSWelcomeSubHeader') {
			return introductoryBody;
		} else if (name === 'enablePersistentMenu') {
			return persistentMenuCheckbox[0] ? 'True' : 'False';
		} else if (name === 'menuItems') {
			return menuItems;
		} else if (name === 'quickLinks') {
			let key: string[] = [];
			let value: string[] = [];
			let quickLinks: { linkText: string; linkValue: string }[] = [];
			inputFieldsData.forEach((data, i) => {
				(i === 0 || i % 2 === 0) && key.push(data);
				i % 2 !== 0 && value.push(data);
			});

			for (let i = 0; i < value.length; i++) {
				quickLinks.push({ linkText: key[i], linkValue: value[i] });
			}
			quickLinks = quickLinks.filter((link) => link.linkText !== '');
			return JSON.stringify(quickLinks);
		} else if (name === 'carouselFieldsData') {
			let key: string[] = [];
			let value: string[] = [];
			let carouselsData: { linkText: string; linkValue: string }[] = [];
			carouselFieldsData.forEach((data, i) => {
				(i === 0 || i % 2 === 0) && key.push(data);
				i % 2 !== 0 && value.push(data);
			});

			for (let i = 0; i < value.length; i++) {
				carouselsData.push({ linkText: key[i], linkValue: value[i] });
			}
			carouselsData = carouselsData.filter((link) => link.linkText !== '');
			return JSON.stringify(carouselsData);
		} else if (name === 'FSCarouselFieldsData') {
			let key: string[] = [];
			let value: string[] = [];
			let carouselsData: { linkText: string; linkValue: string }[] = [];
			carouselFieldsDataFullscreen.forEach((data, i) => {
				(i === 0 || i % 2 === 0) && key.push(data);
				i % 2 !== 0 && value.push(data);
			});

			for (let i = 0; i < value.length; i++) {
				carouselsData.push({ linkText: key[i], linkValue: value[i] });
			}
			carouselsData = carouselsData.filter((link) => link.linkText !== '');
			return JSON.stringify(carouselsData);
		} else if (name === 'persistentMenuCustomQuicklinks') {
			let key: string[] = [];
			let value: string[] = [];
			let quickLinks: { linkText: string; linkValue: string }[] = [];
			persistentinputFieldsData.forEach((data, i) => {
				(i === 0 || i % 2 === 0) && key.push(data);
				i % 2 !== 0 && value.push(data);
			});

			for (let i = 0; i < value.length; i++) {
				quickLinks.push({ linkText: key[i], linkValue: value[i] });
			}
			quickLinks = quickLinks.filter((link) => link.linkText !== '');
			return JSON.stringify(quickLinks);
		} else if (name === 'aiLaunchPromptCheckbox') {
			return aiLaunchPromptCheckbox[0] ? 'True' : 'False';
		} else if (name === 'aiLaunchPromptText') {
			return aiLaunchPromptText;
		} else if (name === 'launchPromptBackgroundColor') {
			return launchPromptBackgroundColor;
		} else if (name === 'launchPromptTextColor') {
			return launchPromptTextColor;
		} else if (name === 'autoPopupValue') {
			return autoPopupValue[0] ? 'True' : 'False';
		} else if (name === 'autoPopupTime') {
			return autoPopupTime;
		} else if (name === 'showWelcomeBanner') {
			return welcomeBannerCheckbox[0] ? 'True' : 'False';
		} else if (name === 'welcomeBannerImage') {
			return welcomeBannerFile;
		} else if (name === 'welcomeMessage') {
			return welcomeMessage;
		} else if (name === 'cardHeading') {
			return cardHeading;
		} else if (name === 'cardBody') {
			return cardBody;
		} else if (name === 'carouselTitle') {
			return carouselTitle;
		} else if (name === 'autoPopupTime') {
			return autoPopupTime;
		} else if (name === 'autoPopupTime') {
			return autoPopupTime;
		} else if (name == 'supportedLanguage') {
			return JSON.stringify(dropdownValue);
		} else if (name == 'MonacoWelcomeImage') {
			return welcomeMonacoImage
		} else if (name == 'E-comChatWelcomeImg') {
			return welcomeEcomImage
		} else if (name === 'enableMultiLingual') {
			return isMultiLingualEnabled[0] ? 'True' : 'False';

		}
		else if (name === 'enableSplashImage') {
			return enableSplash
		}
		else if (name === 'e-comSplashLogo') {
			return welcomeEcomLogo
		}
		else if (name === 'enableStyleAdvisor') {
			return enableStyleAdvisor
		}
		else if (name === 'advisorTitle') {
			return styleAdvisorTitle
		}
		else if (name === 'translationkey') {
			return translationApiKey;
		} else if (name === 'agentTimings') {
			if (agentTimeSchedule.weekDays) {
				let convertTimeToUTCValue = agentTimeSchedule.weekDays.map(
					(data: any) => {
						let startisPM = data.time.split('-')[0].split(' ')[1] === 'PM';
						let endisPM = data.time.split('-')[1].split(' ')[1] === 'PM';

						let startTime = data.time.split('-')[0].split(' ')[0];
						let endTime = data.time.split('-')[1].split(' ')[0];
						let start = Number(startTime.split(':')[0]) + (startisPM ? 12 : 0);
						let end = Number(endTime.split(':')[0]) + (endisPM ? 12 : 0);
						let finalStart = `${start.toString().padStart(2, '0')}:${startTime.split(':')[1]
							}`;
						let finalEnd = `${end.toString().padStart(2, '0')}:${endTime.split(':')[1]
							}`;

						return {
							startTime: toUTCTime(
								finalStart,
								agentTimeZone
									.split(' ')[0]
									.replace('(', '')
									.replace(')', ''),
								data.days
							),
							endTime: toUTCTime(
								finalEnd,
								agentTimeZone
									.split(' ')[0]
									.replace('(', '')
									.replace(')', ''),
								data.days
							),
						};
					}
				);

				return JSON.stringify({ weekDays: convertTimeToUTCValue });
			} else {
				return JSON.stringify(agentTimeSchedule);
			}
		} else if (name === 'agentTimezone') {
			return agentTimeZone;
		} else if (name === 'agentMailNotification') {
			return notify ? 'True' : 'False';
		} else if (name === 'agentEmailAddress') {
			return emailtonotify;
		} else if (name === 'enableHandOffTriggersOnFailure') {
			return onfailureCheckbox ? 'True' : 'False';
		} else if (name === 'enableHandOffTriggersOnError') {
			return onUserErrorCheckbox ? 'True' : 'False';
		} else if (name === 'enableHandOffTriggersOnNegative') {
			return negativeSentimentCheckbox ? 'True' : 'False';
		} else if (name === 'handOffTriggersOnFailureValue') {
			return failureValue;
		} else if (name === 'handOffTriggersOnErrorValue') {
			return userErrorValue;
		} else if (name === 'handOffTriggersOnNegativeValue') {
			return negativeSentimentValue;
		} else if (name === 'chatWithHumanButtonText') {
			return buttonText;
		} else if (name === 'agentUnavailabilityMessageWorkHours') {
			return unavailabilityMessageInside;
		} else if (name === 'agentUnavailabilityMessageOutsideWorkHours') {
			return unavailabilityMessageOutside;
		} else if (name === 'handOffConfirmationPrompt') {
			return handoffConfirmationPrompt;
		} else if (name === 'handOffmessage') {
			return handoffMessage;
		} else if (name === 'waitTimeMessage') {
			return waitTimeMessage;
		} else if (name === 'handOffCancellationMessage') {
			return handoffCancellationMessage;
		} else if (name === 'handOffEndMessage') {
			return handoffEndMessage;
		} else if (name === 'colorScale') {
			return JSON.stringify(colorScale);
		} else if (name === 'colorMode') {
			return colorMode;
		} else if (name === 'selectedColor') {
			return selectedColor;
		} else if (name === 'conversationOpener') {
			return conversationOpener;
		} else if (name === 'modegradientColor') {
			return '';
		} else if (name === 'additionalsettingDetailsCollection') {
			return additionalsettingDetailsCollection[0] ? 'True' : 'False';
		} else if (name === 'additionalsettingMandatoryCollection') {
			return additionalsettingMandatoryCollection[0] ? 'True' : 'False';
		} else if (name === 'additionalsettingMandatoryLeads') {
			return additionalsettingMandatoryLeads[0] ? 'True' : 'False';
		} else if (name === 'selectedTheme') {
			if (selectedTheme.value.toLowerCase() === 'monaco') {
				return selectedTheme.value.toLowerCase();
			}
			return selectedTheme.value;
		} else if (name === 'selectedVariants') {
			return JSON.stringify(selectedVariants);
		} else if (name === 'selectedButton') {
			return JSON.stringify(selectedButton);
		} else if (name === 'additionalRecommendation') {
			return additionalRecommendation.toString();
		} else if (name === 'recommendationType') {
			return recommendationType;
		} else if (name === 'showOnHover') {
			return showOnHover;
		} else if (name === 'recommendationHeading') {
			return recommendationHeading;
		} else if (name === 'recommendationCount') {
			return recommendationCount.toString();
		}
	};

	const RatworkflowPicker = (name: string) => {
		if (name === 'Name') {
			return ratname[0] ? 'True' : 'False';
		} else if (name === 'Email') {
			return ratEmail[0] ? 'True' : 'False';
		} else if (name === 'Phone Number') {
			return ratPhonenumber[0] ? 'True' : 'False';
		} else if (name === 'Issue Description') {
			return ratIssuedescription[0] ? 'True' : 'False';
		}
		return 'True';
	};

	const BookAptworkflowPicker = (name: string) => {
		if (name === 'Name') {
			return bookAptname[0] ? 'True' : 'False';
		} else if (name.trim().toLowerCase() === 'phone number') {
			return bookAptPhonenumber[0] ? 'True' : 'False';
		} else if (name === 'company') {
			return bookAptCompany[0] ? 'True' : 'False';
		} else if (name === 'Country') {
			return bookAptCountry[0] ? 'True' : 'False';
		} else if (name === 'Appointment Date') {
			return bookAptDate[0] ? 'True' : 'False';
		} else if (name === 'Appointment Time') {
			return bookAptTime[0] ? 'True' : 'False';
		} else if (name === 'Email') {
			return bookAptEmail[0] ? 'True' : 'False';
		}
		return 'True';
	};

	const GenerateLeadsworkflowPicker = (name: string) => {
		if (name === 'Name') {
			return generateLeadsname[0] ? 'True' : 'False';
		} else if (name.trim().toLowerCase() === 'phone number') {
			return generateLeadsPhonenumber[0] ? 'True' : 'False';
		} else if (name === 'company') {
			return generateLeadsCompany[0] ? 'True' : 'False';
		} else if (name === 'Country') {
			return generateLeadsCountry[0] ? 'True' : 'False';
		} else if (name === 'Email') {
			return generateLeadsEmail[0] ? 'True' : 'False';
		}
		return 'True';
	};

	const LiveChatLeadsworkflowPicker = (name: string) => {
		if (name === 'Name') {
			return liveChatLeadsname[0] ? 'True' : 'False';
		} else if (name.trim().toLowerCase() === 'phone number') {
			return liveChatLeadsPhonenumber[0] ? 'True' : 'False';
		} else if (name === 'Company') {
			return liveChatLeadsCompany[0] ? 'True' : 'False';
		} else if (name === 'Country') {
			return liveChatLeadsCountry[0] ? 'True' : 'False';
		} else if (name === 'Email') {
			return liveChatLeadsEmail[0] ? 'True' : 'False';
		}
		return 'True';
	};

	const saveConfig = () => {
		const { workFlow, botConfig } = aiDetails;
		let updatedbotConfig = botConfig.map((config) => {
			if (config.configName === 'content_prefix') {
				return {
					...config,
				};
			}
			if (config.configName === 'content_suffix') {
				return {
					...config,
				};
			}
			if (config.configName === 'query_suffix') {
				return {
					...config,
				};
			}
			if (config.configName === 'query_prefix') {
				return {
					...config,
				};
			}
			if (config.configName === 'ipAddressCheckEnabled') {
				return {
					...config,
				};
			}
			if (config.configName === 'IpRange') {
				return {
					...config,
				};
			}

			return {
				...config,
				value: configPicker(config.configName),
			};
		});
		let Ratworkflow: any = [];
		let BookAptworkflow: any = [];
		let GenerateLeadsworkflow: any = [];
		let Chatwithexpert: any = [];
		workFlow.forEach((work) => {
			if (work.name === 'Raise a Ticket') {
				// Ratworkflow = [...work.task.slice(0, 4)];
				let finalcheckList = ratCheckList
					.map((list: any, index) => {
						if (list.title === 'Custom Field') {
							if (list.data.fieldName) {
								if (
									list.data.fieldType.label.toLowerCase() === 'drop down' ||
									list.data.fieldType.label.toLowerCase() ===
									'multiple select' ||
									list.data.fieldType.label.toLowerCase() === 'multiple choice'
								) {
									if (
										list.data.options[0] === undefined ||
										list.data.options[0].name === ''
									) {
										return null;
									}
								}
								let loop = taskTypes
									.map((v) => {
										if (v.name === 'Email' || v.name === 'Number') {
											return null;
										}
										return v;
									})
									.filter(
										(v) => v !== null && v.name === list.data.fieldType.value
									)[0];
								let validationValue = loop!.validation;
								let typeIdValue = loop!.id;
								let id = work.task.filter(
									(task) =>
										task.sequence.trim().toLowerCase() === list.sequence.toLowerCase()
								)[0];
								return {
									id: id ? id.id : 0,
									enabled: 'True',
									message: list.data.desc,
									name: list.data.fieldName,
									sequence: index + 1,
									options: list.data.options.map(
										(opt: { name: string; id: string }) => {
											return {
												name: opt.name,
												id: opt.id ? opt.id : 0,
											};
										}
									),
									validationType: list.data.fieldType.value,
									validation: validationValue,
									typeId: typeIdValue,
								};
							} else {
								return null;
							}
						}

						return {
							...work.task.filter(
								(task) =>
									task.name.trim().toLowerCase() === list.title.toLowerCase()
							)[0],
							sequence: index + 1,
						};
					})
					.filter((v) => v !== null);
				Ratworkflow = [...finalcheckList];
			}
			if (work.name === 'Generate leads') {
				// GenerateLeadsworkflow = [...work.task.slice(0, 5)];
				let finalcheckList = checkList
					.map((list: any, index) => {
						if (list.title === 'Custom Field') {
							if (list.data.fieldName) {
								if (
									list.data.fieldType.label.toLowerCase() === 'drop down' ||
									list.data.fieldType.label.toLowerCase() ===
									'multiple select' ||
									list.data.fieldType.label.toLowerCase() === 'multiple choice'
								) {
									if (
										list.data.options[0] === undefined ||
										list.data.options[0].name === ''
									) {
										return null;
									}
								}
								let loop = taskTypes
									.map((v) => {
										if (v.name === 'Email' || v.name === 'Number') {
											return null;
										}
										return v;
									})
									.filter(
										(v) => v !== null && v.name === list.data.fieldType.value
									)[0];
								let validationValue = loop!.validation;
								let typeIdValue = loop!.id;
								let id = work.task.filter(
									(task) =>
										task.sequence.trim().toLowerCase() === list.sequence.toLowerCase()
								)[0];
								return {
									id: id ? id.id : 0,
									enabled: 'True',
									message: list.data.desc,
									name: list.data.fieldName,
									sequence: index + 1,
									options: list.data.options.map(
										(opt: { name: string; id: string }) => {
											return {
												name: opt.name,
												id: opt.id ? opt.id : 0,
											};
										}
									),
									validationType: list.data.fieldType.value,
									validation: validationValue,
									typeId: typeIdValue,
								};
							} else {
								return null;
							}
						}

						return {
							...work.task.filter(
								(task) =>
									task.name.trim().toLowerCase() === list.title.toLowerCase()
							)[0],
							sequence: index + 1,
						};
					})
					.filter((v) => v !== null);

				GenerateLeadsworkflow = [...finalcheckList];
			}
			if (work.name === 'Book Appointment') {
				// BookAptworkflow = [...work.task.slice(0, 7)];
				let finalcheckList = bookAptCheckList
					.map((list: any, index) => {
						if (list.title === 'Custom Field') {
							if (list.data.fieldName) {
								if (
									list.data.fieldType.label.toLowerCase() === 'drop down' ||
									list.data.fieldType.label.toLowerCase() ===
									'multiple select' ||
									list.data.fieldType.label.toLowerCase() === 'multiple choice'
								) {
									if (
										list.data.options[0] === undefined ||
										list.data.options[0].name === ''
									) {
										return null;
									}
								}
								let loop = taskTypes
									.map((v) => {
										if (v.name === 'Email' || v.name === 'Number') {
											return null;
										}
										return v;
									})
									.filter(
										(v) => v !== null && v.name === list.data.fieldType.value
									)[0];
								let validationValue = loop!.validation;
								let typeIdValue = loop!.id;
								let id = work.task.filter(
									(task) =>
										task.sequence.trim().toLowerCase() === list.sequence.toLowerCase()
								)[0];
								return {
									id: id ? id.id : 0,
									enabled: 'True',
									message: list.data.desc,
									name: list.data.fieldName,
									sequence: index + 1,
									options: list.data.options.map(
										(opt: { name: string; id: string }) => {
											return {
												name: opt.name,
												id: opt.id ? opt.id : 0,
											};
										}
									),
									validationType: list.data.fieldType.value,
									validation: validationValue,
									typeId: typeIdValue,
								};
							} else {
								return null;
							}
						}

						return {
							...work.task.filter(
								(task) =>
									task.name.trim().toLowerCase() === list.title.toLowerCase()
							)[0],
							sequence: index + 1,
						};
					})
					.filter((v) => v !== null);
				BookAptworkflow = [...finalcheckList];
			}
			if (work.name === 'Chat with expert') {
				Chatwithexpert = [...work.task];
				let finalcheckList = liveChatcheckList
					.map((list: any, index) => {
						if (list.title === 'Custom Field') {
							if (list.data.fieldName) {
								if (
									list.data.fieldType.label.toLowerCase() === 'drop down' ||
									list.data.fieldType.label.toLowerCase() ===
									'multiple select' ||
									list.data.fieldType.label.toLowerCase() === 'multiple choice'
								) {
									if (
										list.data.options[0] === undefined ||
										list.data.options[0].name === ''
									) {
										return null;
									}
								}
								let loop = taskTypes
									.map((v) => {
										if (v.name === 'Email' || v.name === 'Number') {
											return null;
										}
										return v;
									})
									.filter(
										(v) => v !== null && v.name === list.data.fieldType.value
									)[0];
								let validationValue = loop!.validation;
								let typeIdValue = loop!.id;
								let id = work.task.filter(
									(task) =>
										task.sequence.trim().toLowerCase() === list.sequence.toLowerCase()
								)[0];

								return {
									id: id ? id.id : 0,
									enabled: 'True',
									message: list.data.desc,
									name: list.data.fieldName,
									sequence: index + 1,
									options: list.data.options.map(
										(opt: { name: string; id: string }) => {
											return {
												name: opt.name,
												id: opt.id ? opt.id : 0,
											};
										}
									),
									validationType: list.data.fieldType.value,
									validation: validationValue,
									typeId: typeIdValue,
								};
							} else {
								return null;
							}
						}

						return {
							...work.task.filter(
								(task) =>
									task.name.trim().toLowerCase() === list.title.toLowerCase()
							)[0],
							sequence: index + 1,
						};
					})
					.filter((v) => v !== null);
				Chatwithexpert = [...finalcheckList];
			}
		});
		let updatedRatworkflow = Ratworkflow.map((ratw: any) => {
			return {
				...ratw,
				enabled: RatworkflowPicker(ratw.name),
			};
		});

		let updatedBookAptworkflow = BookAptworkflow.map((bookApt: any) => {
			return {
				...bookApt,
				enabled: BookAptworkflowPicker(bookApt.name),
			};
		});
		let updatedGenerateLeadsworkflow = GenerateLeadsworkflow.map(
			(genLeads: any) => {
				return {
					...genLeads,
					enabled: GenerateLeadsworkflowPicker(genLeads.name),
				};
			}
		);
		let updatedLivechatLeadsworkflow = Chatwithexpert.map(
			(chatWithExpert: any) => {
				return {
					...chatWithExpert,
					enabled: LiveChatLeadsworkflowPicker(chatWithExpert.name),
				};
			}
		);

		let updatedworkflow = workFlow.map((work) => {
			if (work.name === 'Raise a Ticket') {
				return {
					...work,
					// enabled: ratVal ? 'True' : 'False',
					enabled: enableRAT ? 'True' : 'False',
					customName: ratButtonText,
					task: updatedRatworkflow,
					message: ratCollectionPrompt || 'Please share the following details',
					destination: ratTicketManagement ? ratTicketManagement.label : '',
				};
			}
			if (work.name === 'Generate leads') {
				return {
					...work,
					// enabled: glVal ? 'True' : 'False',
					enabled: enableGenerateLeads ? 'True' : 'False',
					customName: generateLeadsButtonText,
					task: updatedGenerateLeadsworkflow,
					message:
						generateLeadsCollectionPrompt ||
						'Please share the following details',
					destination: generateLeadsDestination
						? generateLeadsDestination.label
						: '',
				};
			}
			if (work.name === 'Book Appointment') {
				return {
					...work,
					// enabled: baVal ? 'True' : 'False',
					enabled: enableBookAPT ? 'True' : 'False',
					customName: bookAptButtonText,
					task: updatedBookAptworkflow,
					message:
						bookAptCollectionPrompt || 'Please share the following details',
					destination: bookAptManagement ? bookAptManagement.label : '',
				};
			}
			if (work.name === 'Chat with expert') {
				return {
					...work,
					// enabled: baVal ? 'True' : 'False',
					enabled: enableCHE ? 'True' : 'False',
					message:
						liveChatLeadsCollectionPrompt ||
						'Please share the following details',
					customName: buttonText,
					task: updatedLivechatLeadsworkflow,
				};
			}
			if (work.name === 'pickup status') {
				return work;
			}
			if (work.name === 'Power Automate') {
				return work;
			}
		});

		let data = {
			workFlow: updatedworkflow,
			botConfig: updatedbotConfig,
		};
		dispatch(
			updateAIDetails({
				botId: loadState('botId').toString(),
				tenantId: loadState('tenantId'),
				details: data,
			})
		);
		let updatedLLMConfig = llmConfig.map((config: any) => {
			return llmConfigPicker(config);
		});
		let updateIndustryConfig = industryConfig.map((config: any) => {
			return industryConfigPicker(config);
		});

		dispatch(
			updateVertical({
				bot_id: loadState('botId'),
				industryConfig: updateIndustryConfig,
				llmConfig: updatedLLMConfig,
			})
		);
	};

	const industryConfigPicker = (config: any) => {
		if (config.industry_name.toLowerCase() === selectedEcom.toLowerCase()) {
			return { ...config, enabled: 1 };
		}
		if (config.industry_name.toLowerCase() === selectedFinance.toLowerCase()) {
			return { ...config, enabled: 1 };
		}
		if (
			config.industry_name.toLowerCase() ===
			selectedRealStateOrOther
				.split(' ')
				.join('')
				.toLowerCase()
		) {
			return { ...config, enabled: 1 };
		}

		return { ...config, enabled: 0 };
	};

	const llmConfigPicker = (config: any) => {
		if (config.config_name === 'top_n') {
			return { ...config, value: String(productResultCount) };
		} else if (config.config_name === 'errorMessage') {
			return { ...config, value: failedResponse };
		} else if (config.config_name === 'competitorsName') {
			return { ...config, value: competitorNames };
		} else if (config.config_name === 'enableProbing') {
			return { ...config, value: enableProbing[0] ? 'true' : 'false' };
		} else if (config.config_name === 'toneOfVoice') {
			return { ...config, value: tone.value };
		} else if (config.config_name === 'specialInstruction') {
			return { ...config, value: instructions };
		} else if (config.config_name === 'Other') {
			return { ...config, value: otherBussiness };
		} else if (config.config_name === 'recommendationRational') {
			return { ...config, value: recommendationRational ? 'true' : 'false' };
		} else if (config.config_name === 'complimentaryProducts') {
			return { ...config, value: complimentaryProducts ? 'true' : 'false' };
		}
		return config;
	};

	const getColorFromScale = (color: string, mode: string) => {
		if (mode === 'dark') {
			// dispatch(setColorApply('#ffffff'));
			dispatch(setColorMode('dark'));
			let colorArray = arrayOfAllColors(color);

			dispatch(setColorScale(colorArray));
		} else {
			let colorArray = arrayOfAllColors(color);
			dispatch(setColorScale(colorArray));
			// dispatch(setColorApply(colorArray[0]));
			dispatch(setColorMode('light'));
		}
	};
	useEffect(() => {
		dispatch(getAIDetails({ botId: loadState('botId'), tenantId: tenantId })); //Bot config API
		dispatch(crmsLinked({ tenantId: tenantId! }));
		dispatch(getTaskType({ tenantId: tenantId! })); //Dropdown for task type in Forms
		dispatch(getVertical({ bot_id: loadState('botId') })); //LLM config API
		dispatch(workflowDestination({ tenantId: tenantId!, workflow: 'LEAD' }));
		dispatch(
			workflowDestination({ tenantId: tenantId!, workflow: 'TICKETING' })
		);
		dispatch(
			workflowDestination({ tenantId: tenantId!, workflow: 'APPOINTMENT' })
		);
		//for platform notification
		dispatch(
			getUnreadNotifications({
				userId: loadState('user').userID,
				tenantId: tenantId!,
			})
		);
		// dispatch(
		// 	getReadNotifications({
		// 		userId: loadState('user').userID,
		// 		tenantId: tenantId!,
		// 		read: 1,
		// 	})
		// );

		return () => {
			dispatch(setCheckList(checkList.slice(0, 5)));
			dispatch(setBookAptCheckList(bookAptCheckList.slice(0, 7)));
			dispatch(setRatCheckList(ratCheckList.slice(0, 4)));
			dispatch(setliveChatcheckList(liveChatcheckList.slice(0, 5)));
		};
	}, []);

	useEffect(() => {
		if (relativeLuminance(selectedColor) === 'dark') {
			getColorFromScale(selectedColor, 'dark');
		} else {
			getColorFromScale(selectedColor, 'light');
		}
	}, [selectedColor]);

	// useEffect(() => {
	// 	if (crmsLinkedData) {
	// 		dispatch(
	// 			setCrmsLinkedDataLBV([
	// 				...nameOfCrmsLinked(crmsLinkedData),
	// 				{ label: 'Add platform', value: 'Add platform' },
	// 			])
	// 		);
	// 	}
	// }, [crmsLinkedData]);

	useEffect(() => {
		if (location.pathname.indexOf('actions/') > -1) {
			dispatch(setCurrentScreen('chats'));
		}
	}, [location.pathname]);

	return (
		<>
			{getAIDetailsLoader ? (
				<div className='query-loader'>
					<PageLoader message='Loading your configurations...' />
				</div>
			) : (
				<div className='configuration-container'>
					<div
						className={`ff-configure-save-button ${!showBotPreviewCollapsed &&
							(location.pathname.indexOf('experience-on-web/') > -1 ||
								location.pathname.indexOf('generate-leads') > -1 ||
								location.pathname.indexOf('raise-a-ticket') > -1 ||
								location.pathname.indexOf('book-appointment') > -1 ||
								location.pathname.indexOf('livechat-leads') > -1) &&
							location.pathname.indexOf('full-screen-welcome-config') <= -1
							? 'shift'
							: ''
							}`}
					>
						<Button
							disabled={
								AISelected ||
									disableSave ||
									disableSaveProductCount || styleError ||
									disableSaveSubHead || styleAdvisorTitle.trim().length > 100 || (enableStyleAdvisor && styleAdvisorTitle.trim().length === 0)
									? true
									: false
							}
							onClick={saveConfig}
							loading={saveButtonLoader}
						>
							Save Changes
						</Button>
					</div>
					<div
						className={`tabs-and-setting-holder ${!showBotPreviewCollapsed &&
							(location.pathname.indexOf('experience-on-web/') > -1 ||
								location.pathname.indexOf('generate-leads') > -1 ||
								location.pathname.indexOf('raise-a-ticket') > -1 ||
								location.pathname.indexOf('book-appointment') > -1 ||
								location.pathname.indexOf('livechat-leads') > -1)
							? location.pathname.indexOf('full-screen-welcome-config') > -1
								? 'ff-fullscreen'
								: 'shift'
							: location.pathname.indexOf('full-screen-welcome-config') > -1
								? 'ff-fullscreen'
								: ''
							}`}
					>
						<div className='config-bot-profile-tabs'>
							<h5>Configure</h5>
							<TabGenerator
								type={'vertical'}
								selectedTab={selectedTab}
								onTabChange={(tabName: string) => onTabChangeHandler(tabName)}
								component={'configprofile'}
								tabNames={customPrompts[0] ? NEWTABSWITHOUTAIMODEL : NEWTABS}
								tabPassedStyles={errors.type === 'max' ? TabStyle : null}
							/>
						</div>
						<div className='ff-teamsetting'>
							<div
								className={`settings-container ${namingClass() === 'live-chat'
									? 'settings-container-live-chat'
									: ''
									} ${namingClass() === 'integrations'
										? 'settings-container-integrations'
										: ''
									}`}
								style={{
									width: showBotPreviewCollapsed
										? '100%'
										: window.innerWidth > 1366
											? 'calc(100% - 464px)'
											: 'calc(100% - 424px)',
								}}
							>
								<div
									className={`right-result-${namingClass()}`}
									style={{ width: '840px', maxWidth: '1000px' }}
								>
									<Outlet />
								</div>
							</div>
						</div>
					</div>
					{(location.pathname.indexOf('experience-on-web/') > -1 ||
						location.pathname.indexOf('generate-leads') > -1 ||
						location.pathname.indexOf('raise-a-ticket') > -1 ||
						location.pathname.indexOf('book-appointment') > -1 ||
						location.pathname.indexOf('livechat-leads') > -1) &&
						location.pathname.indexOf('full-screen-welcome-config') <= -1 ? (
						<div
							style={{
								display: 'flex',
								alignItems: 'flex-end',
								position: 'absolute',
								right: '24px',
								bottom: '24px',
								fontFamily: `${fontFamily.value}`,
								fontSize: `${fontSize.value}`,
								zIndex: 2,
							}}
						>
							{showBotPreviewCollapsed ? (
								<GCBPreviewCollapsed
									selectedColor={selectedColor}
									mode={colorMode}
									calloutContentColor={launchPromptBackgroundColor}
									colorScale={colorScale}
									className={
										headerIconPosition === 'external' ? 'assign-shadow' : ''
									}
									iconOutSide={headerIconPosition === 'external'}
								>
									<div className='ai-preview-holder'>
										<>
											<div
												className='callout'
												style={{
													backgroundColor: colorScale && colorScale[9],
													border: `1px solid ${colorMode === 'dark'
														? selectedColor
														: colorScale && colorScale[4]
														}`,
													fontFamily: fontFamily.value,
													fontSize: fontSize.value,
												}}
											>
												<p
													style={{
														color: colorScale && colorScale[0],
														fontFamily: fontFamily.value,
														fontSize: fontSize.value,
													}}
												>
													{aiLaunchPromptText
														? aiLaunchPromptText
														: 'Hi! How can I help you?'}
												</p>
											</div>
										</>

										<img
											onClick={() =>
												dispatch(setShowBotPreviewCollapsed(false))
											}
											src={avatarImage ? avatarImage : BOT_ICONS[0]}
											alt=''
										/>
									</div>
								</GCBPreviewCollapsed>
							) : (
								<GCBPreview selectedColor={selectedColor} mode={colorMode}>
									<AIHeader />
									<AIBody />
									{currentScreen !== 'chats' ? <AIFooter /> : null}
								</GCBPreview>
							)}
						</div>
					) : null}
				</div>
			)}
		</>
	);
};

export default TeamSetting;
