import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '../../app/store';
import { Outlet, useNavigate } from 'react-router-dom';
import {
	IngestUpload,
	IngestUrl,
	IngestVoice,
	IngestCart,
} from '../../assets/Icons';

import {
	uploadFile,
	getFileList,
	setUploadedFiles,
	shopImport_Shopify,
	resetShopImportShopifyAPIStatus,
	setuploadFileApiStatus,
} from '../../Features/Ingest/IngestSlice';
import { isHTMLTagsPresent, loadState, saveState } from '../../utils/utils';
import { setSnackModal } from '../../Features/message/messageSlice';

import './ingest.scss';
import { getUnreadNotifications } from '../../Features/Notifications/notificationSlice';
import PageLoader from '../../Components/PageLoader';

const Ingest = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const MAX_COUNT = 100;
	const [fileLimit, setFileLimit] = useState(false);
	const multiuploadRef = useRef(null);
	const {
		uploadedFiles,
		shopImportShopifyAPIStatus,
		getFileListApiStatus,
		shopImportStatus,
		uploadFileApiStatus,
	} = useSelector((state: RootState) => state.ingest);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const [retryImport, setRetryImport] = useState(0);

	useEffect(() => {
		dispatch(setUploadedFiles([]));

		//for platform notification
		dispatch(
			getUnreadNotifications({
				userId: loadState('user').userID,
				tenantId: tenantId!,
			})
		);
		dispatch(
			getFileList({
				botId: loadState('botId'),
				tenantId: loadState('tenantId'),
			})
		);
	}, []);
	useEffect(() => {
		if (getFileListApiStatus === 'success') {
			if (loadState('chargeId') && loadState('botId') && tenantId!) {
				let shopify = uploadedFiles.ecom.filter(
					(ecom: any) => ecom.ecomType.toLowerCase() === 'shopify'
				)[0];
				if (uploadedFiles.ecom && uploadedFiles.ecom.length === 0) {
					dispatch(
						shopImport_Shopify({
							tenantId: tenantId!,
							botId: loadState('botId'),
							chargeId: loadState('chargeId'),
						})
					);
				} else if (
					uploadedFiles.ecom &&
					uploadedFiles.ecom.length &&
					shopify === undefined
				) {
					dispatch(
						shopImport_Shopify({
							tenantId: tenantId!,
							botId: loadState('botId'),
							chargeId: loadState('chargeId'),
						})
					);
				}
			}
		} else if (getFileListApiStatus === 'failed') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage:
						'We are unable to retrieve your ingested resources at the moment. Please try again later',
					modalMode: 'negative',
				})
			);
		}
	}, [getFileListApiStatus]);
	useEffect(() => {
		if (
			shopImportShopifyAPIStatus === 'success' &&
			shopImportStatus &&
			shopImportStatus.toLowerCase() === 'success'
		) {
			dispatch(
				getFileList({
					botId: loadState('botId'),
					tenantId: loadState('tenantId'),
				})
			);
		} else if (shopImportShopifyAPIStatus === 'failed') {
			if (retryImport > 3) {
				dispatch(
					setSnackModal({
						openModal: true,
						modalMesssage:
							'We are unable to import your Shop. Please reach us out at support@flyfish.ai',
						modalMode: 'negative',
					})
				);
			} else {
				setRetryImport((value) => value + 1);
				dispatch(
					shopImport_Shopify({
						tenantId: tenantId!,
						botId: loadState('botId'),
						chargeId: loadState('chargeId'),
					})
				);
			}
		}
		dispatch(resetShopImportShopifyAPIStatus());
	}, [shopImportShopifyAPIStatus]);

	const checkdata = (e: React.ChangeEvent<HTMLInputElement>) => {
		try {
			setTimeout(() => {
				if (multiuploadRef && multiuploadRef.current) {
					const chosenfiles = Array.prototype.slice.call(e.target.files);
					let specialCharacterCheck = chosenfiles.filter((file) => {
						return isHTMLTagsPresent(file.name);
					});
					let inValidFiles = chosenfiles.filter((file) => {
						return file.type !== 'application/pdf';
					});
					let fileSize = chosenfiles.filter((file) => {
						let kb = file.size / 1024;
						let mb = kb / 1024;
						return mb > 5;
					});
					if (
						specialCharacterCheck.length &&
						inValidFiles.length &&
						fileSize.length
					) {
						saveState('navigationCount', '5');
					} else if (
						(specialCharacterCheck.length && inValidFiles.length) ||
						(specialCharacterCheck.length && fileSize.length) ||
						(inValidFiles.length && fileSize.length)
					) {
						saveState('navigationCount', '3');
					} else if (
						specialCharacterCheck.length ||
						fileSize.length ||
						inValidFiles.length
					) {
						saveState('navigationCount', '2');
					} else {
						saveState('navigationCount', '1');
					}

					if (specialCharacterCheck.length) {
						let nonSpecialCharacterFiles = chosenfiles.filter((file) => {
							return !isHTMLTagsPresent(file.name);
						});
						navigate('invalid-file-name-error', {
							state: { files: nonSpecialCharacterFiles },
						});
					} else {
						if (inValidFiles.length) {
							navigate('invalid-file-format-error', {
								state: { files: chosenfiles },
							});
						} else {
							if (fileSize.length) {
								navigate('invalid-file-size-error', {
									state: { files: chosenfiles },
								});
							} else {
								navigate('upload-module', { state: { files: chosenfiles } });
							}
						}
					}
					//@ts-ignore
					multiuploadRef.current.value = '';
				}
			}, 800);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (
			(uploadedFiles.fileUpload && uploadedFiles.fileUpload.length > 0) ||
			(uploadedFiles.ecom && uploadedFiles.ecom.length > 0)
		) {
			navigate('/app/Ingest-tab/ingested-resources');
			dispatch(setuploadFileApiStatus(''));
		}
	}, [uploadedFiles, uploadFileApiStatus]);

	return (
		<>
			{getFileListApiStatus === 'loading' ? (
				<div className='query-loader'>
					<PageLoader message='Fetching your ingested resources...' />
				</div>
			) : (
				<div className='ff-ingest-features-cnt ff-homepage'>
					<div className='ff-ingest-container'>
						<div className='ff-ingest-header'>
							<h4>Ingest Your Sources</h4>
							<p
								className='body2'
								style={{ marginTop: '5px', fontSize: '12px' }}>
								Feed different data sources to your AI to grow its knowledge &
								capabilities.
							</p>
						</div>

						<div className='ff-ingest-features'>
							<div
								className='ff-ingest-item'
								onClick={() => {
									loadState('shopifyShopName') === undefined &&
										navigate('link-product');
								}}>
								<div className='ff-ingest-item-content'>
									<div>
										<h6 style={{ paddingBottom: '8px' }}>
											Link Product Catalog
										</h6>
									</div>
									<div>
										<p className='caption'>
											Import your products from your e-commerce catalog to start
											selling them everywhere
										</p>
									</div>
								</div>
								<IngestCart />
							</div>
							<div
								className='ff-ingest-item'
								id='ff-ingest-item-upload-button'
								onClick={() => {
									if (multiuploadRef.current) {
										//@ts-ignore
										multiuploadRef.current.click();
									}
								}}>
								<div className='ff-ingest-item-content'>
									<input
										style={{ display: 'none' }}
										type='file'
										multiple
										accept='application/pdf'
										onChange={checkdata}
										disabled={fileLimit}
										ref={multiuploadRef}
									/>
									<label style={{ cursor: 'pointer' }}>
										<div>
											<h6 style={{ paddingBottom: '8px' }}>Upload Documents</h6>
										</div>
										<div>
											<p className='caption'>
												Upload FAQs, policies, marketing collaterals and more
											</p>
										</div>
									</label>
								</div>
								<IngestUpload />
							</div>

							<div className='ff-ingest-item' style={{ cursor: 'not-allowed' }}>
								<div className='ff-ingest-item-content'>
									<div>
										<h6 style={{ paddingBottom: '8px' }}>Fetch From URL</h6>
									</div>
									<div>
										<p className='caption'>
											Ingest online resources such as your website
										</p>
										<div className='ff-feature-soon'>
											<p className='caption'>Coming Soon</p>
										</div>
									</div>
								</div>
								<IngestUrl />
							</div>

							<div className='ff-ingest-item' style={{ cursor: 'not-allowed' }}>
								<div className='ff-ingest-item-content'>
									<div>
										<h6 style={{ paddingBottom: '8px' }}>Ingest Voice Data</h6>
									</div>
									<div>
										<p className='caption'>
											Train your AI using call recordings with humans and more
										</p>
										<div className='ff-feature-soon'>
											<p className='caption'>Coming Soon</p>
										</div>
									</div>
								</div>
								<IngestVoice />
							</div>
						</div>
					</div>
				</div>
			)}
			<Outlet />
		</>
	);
};
export default Ingest;
