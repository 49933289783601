import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import AutoComplete from '../../../../Components/AutoComplete';
import Button from '../../../../Components/Button';
import Checkbox from '../../../../Components/Checkbox';
import Input from '../../../../Components/Input';
import {
	setBookAptButtonText,
	setBookAptCollectionPrompt,
	setBookAptCompany,
	setBookAptCountry,
	setBookAptDate,
	setBookAptEmail,
	setBookAptManagement,
	setBookAptName,
	setBookAptPhonenumber,
	setBookAptTime,
	setEnableBookAPT,
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import './BookAppointment.scss';
import { updateBotdetails } from '../../../../Features/configure/configureSlice';
import { useNavigate } from 'react-router';
import DragToReorderList from '../DragToReorderList';
import { setBookingDestination } from '../../../../Features/teamsetting/teamsettingSlice';
import { saveState } from '../../../../utils/utils';

const BookAppointment = () => {
	const {
		bookAptButtonText,
		bookAptCompany,
		bookAptCountry,
		bookAptDate,
		bookAptManagement,
		bookAptPhonenumber,
		bookAptTime,
		bookAptname,
		enableBookAPT,
		bookAptEmail,
		bookAptCollectionPrompt,
		bookAptCheckList,
	} = useSelector((state: RootState) => state.experienceonweb);
	const { bookingDestination } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const navigate = useNavigate();
	const [dropdownOptions, setDropdownoptions] = useState<
		{ label: string; value: string }[]
	>([]);
	const dispatch = useAppDispatch();

	const [customField, setCustomField] = useState(bookAptCheckList);
	// const dropdownOptions = [{ label: 'Email', value: 'Email' }];
	const getStateF = (type: string) => {
		if (type === 'Name') {
			return bookAptname;
		} else if (type === 'Phone Number') {
			return bookAptPhonenumber;
		} else if (type === 'Appointment Date') {
			return bookAptDate;
		} else if (type === 'Appointment Time') {
			return bookAptTime;
		} else if (type === 'Email') {
			return bookAptEmail;
		} else if (type === 'Country') {
			return bookAptCountry;
		} else if (type === 'Company') {
			return bookAptCompany;
		}
		return [false];
	};
	const getStateSetter = (type: string, value: any) => {
		if (type === 'Name') {
			return dispatch(setBookAptName(value));
		} else if (type === 'Phone Number') {
			return dispatch(setBookAptPhonenumber(value));
		} else if (type === 'Appointment Date') {
			return dispatch(setBookAptDate(value));
		} else if (type === 'Appointment Time') {
			return dispatch(setBookAptTime(value));
		} else if (type === 'Email') {
			return dispatch(setBookAptEmail(value));
		} else if (type === 'Country') {
			return dispatch(setBookAptCountry(value));
		} else if (type === 'Company') {
			return dispatch(setBookAptCompany(value));
		}
		return [false];
	};
	const PlusIcon = () => {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				data-name='add_black_24dp (2)'
				width='20'
				height='20'
				viewBox='0 0 20 20'>
				<path data-name='Path 2860' d='M0 0h20v20H0z' fill='none' />
				<path
					data-name='Path 2861'
					d='M16.667 11.667h-5v5H10v-5H5V10h5V5h1.667v5h5z'
					transform='translate(-.833 -.833)'
					fill='white'
				/>
			</svg>
		);
	};

	// useEffect(() => {
	// 	setDropdownoptions(crmsLinkedDataLBV);
	// }, [crmsLinkedDataLBV]);

	useEffect(() => {
		if (bookAptCheckList.length !== customField.length) {
			setCustomField(bookAptCheckList);
		}
	}, [bookAptCheckList]);

	useEffect(() => {
		if (bookingDestination.length) {
			setDropdownoptions(bookingDestination);
		}
	}, [bookingDestination]);

	// useEffect(()=>{
	//     if(bookAptCompany||bookAptCountry||bookAptname||bookAptDate||bookAptTime||bookAptPhonenumber){
	// 		dispatch(setEnableBookAPT(false))
	// 	} else {
	// 		dispatch(setEnableBookAPT(true))

	// 	}
	// },[bookAptCompany,bookAptCountry, bookAptname,bookAptDate,bookAptTime,bookAptPhonenumber
	// ])
	return (
		<div className='book-appointment-container'>
			<div className='book-appointment-header'>
				<h4>Book Appointment</h4>
				<div className='collect-user-details'>
					<p className='body1 info'>
						Collect details from your users and pass those on to your system.
					</p>
					<h6>Detail collection prompt</h6>
					<p className='body1 before-user-details'>
						This text is shown before asking users for details.
					</p>
					<Input
						placeholder='E.g. Please share the following details'
						fullWidth
						autoFocus
						value={bookAptCollectionPrompt}
						onChange={(e) =>
							dispatch(setBookAptCollectionPrompt(e.target.value))
						}
					/>
					<hr />
					<div className='details-to-be-collected'>
						<h6>Details to be collected</h6>
						<DragToReorderList
							items={customField}
							getStateF={getStateF}
							getStateSetter={getStateSetter}
							negation={7}
							// setter={setCustomField}
							from='bookApt'
						/>
						{/* {customField.map((data: any) => (
							<div className='details-with-checkbox'>
								<Checkbox
									label={<p className='body1'>{data}</p>}
									isChecked={getStateF(data)}
									index={0}
									onValueChange={(val) => getStateSetter(data, val)}
								/>
							</div>
						))} */}
						<Button
							className='custom-field-button'
							startIcon={<PlusIcon />}
							onClick={() => {
								let val = [...bookAptCheckList];
								val.push({
									sequence: `${bookAptCheckList.length + 1}`,
									title: 'Custom Field',
									data: {
										fieldName: '',
										fieldType: { label: 'Text', value: 'Text' },
										desc: '',
										options: [{ name: '', id: '' }],
									},
								});
								setCustomField(val);
							}}>
							Add custom field
						</Button>
					</div>
					<hr />
					<div className='appointment-management'>
						<h6>Appointment Management</h6>
						<p className='body1'>Select your appointment management system</p>
						<AutoComplete
							multiple={false}
							placeholder='Select'
							options={dropdownOptions}
							valueKey='value'
							labelKey='label'
							value={bookAptManagement}
							onChange={(val) => {
								if (val.value === 'Add platform') {
									navigate('/app/general-configuration/Integrations/all');
									saveState('selectedGeneralTab', 'All');
									dispatch(setBookAptManagement(null));
								} else {
									dispatch(setBookAptManagement(val));
								}
							}}
						/>
					</div>
					<hr />
					<div className='button-text'>
						<h6>Button text</h6>
						<p className='body1'>
							Provide a custom text for this action to be shown during the
							conversation.
						</p>
						<Input
							//    disabled={enableBookAPT}
							value={bookAptButtonText}
							limit={20}
							fullWidth
							helperText={'Maximum 20 Characters'}
							onChange={(e) => dispatch(setBookAptButtonText(e.target.value))}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BookAppointment;
