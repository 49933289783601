import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
	Analyticsdata,
	completionPointDialog,
	ConversationChatData,
	DialogAnalyticstype,
	getBotCountData,
	getOverViewAnalytics,
	getRealtimeAnalytics,
	getAgentRanking,
	getAgents,
	minicardResponse,
	ReferrerData,
	convAnalytics,
	getTimeDetails,
	conversationID,
} from './model';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useLocation, useNavigate } from 'react-router-dom';
import analyticService from './analyticsService';
import { listOfBots } from '../Bots/model';
import { loadState, saveState } from '../../utils/utils';
import { lastDayOfDecade } from 'date-fns/fp';
import { refreshToken } from '../auth/authSlice';

export interface State {
	offset:any;
	selectedDay:any,
	offsetval:any;
	activeAnalyticsBotstatus: string;
	getBotCountstatus: string;
	showTranscript: boolean;
	tableFilterSelected: string;
	tableFiltersearch: string;
	UserResponseActionstatus: string;
	UserResponseActionstatusChatData: any;
	conversationSelectedField: string;
	conversationChatData: any;
	analyticsTopQueriesstatus: string;
	conversationChatDatastatus: string;
	highestDropoffRateStatus: string;
	highestDropoffRateData: any;
	completionPointDialogData: completionPointDialog;
	completionDialogstatus: string;
	selectedPreviousDates: string[];
	MiniCardDatastatus: string;
	analyticsMiniCardData: any;
	allTopQueries: boolean;
	dialogviewallQueries: boolean;
	dialogQueriesanalytic: boolean;
	selectedTriggeredQuery: any;
	selectedDates: string[];
	calendarstatus: boolean;
	dropOffRates: boolean;
	analyticsDialogstatus: string;
	analyticsDialog: DialogAnalyticstype;
	analyticsoldDialog: DialogAnalyticstype;
	MiniCardpreDatastatus: string;
	analyticspreMiniCardData: minicardResponse | any;
	analyticsTopQueriesData: any;
	topQueriesDatastatus: string;
	analyticsConversationsTable: any;
	optionSelected: string;
	conversationTable: boolean;
	todayMiniCardData: any;
	currentMiniCardData: minicardResponse | any;
	conversationTablestatus: string;
	MiniCardpreviousApiData: boolean;
	MiniCardAvgUserRating: boolean;
	selectedRow: any;
	TranscriptData: any;
	getCaVsrBotCount: {
		caBotCount: number;
		vsrBotCount: number;
	};
	activeAnalyticsBot: listOfBots;
	RealTimeAnalyticsstatus: string;
	getRealTimeAnalyticsLiveChatData: any;
	OverViewAnalyticsstatus: string;
	AgentRankingstatus: string;
	getAgentRankingData: any;
	getOverViewAnalyticsLiveChatData: any;
	getAllAgentsData: any;
	getAgentsStatus: string;
	dashboardDate: any;
	analyticConversationData: any;
	chatConversationalData: any;
	chatFeedbackData: any;
	openChatContainer: string;
	isConversationIdValid: string;
	FeedbackDataSearchedValue: string;
	conSearchedValue: string;
	getchartLoading: boolean;
	chatDataLoading: boolean;
	openConversation: string;
	totalCurrentChatLength: any;
	totalChatLength: number;
	totalFeedbackCurrentLength: any;
	totalFeedbackLength: number;
	// referrerData: ReferrerData[];
}

export const MiniCardData = createAsyncThunk<
	minicardResponse,
	Analyticsdata,
	{ rejectValue: string }
>(
	'/analytics/v1/getanalytics',
	async (data, { rejectWithValue, getState, dispatch }) => {
		try {
			const { analytics } = getState() as {
				analytics: State;
			};
			const response = await analyticService.MiniCardData(data);
			if (data.isPreviousData) {
				dispatch(MinicardPreviousData(response.data));
			}
			return response.data;
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const AnalyticsDialog = createAsyncThunk<
	any,
	Analyticsdata,
	{ rejectValue: string }
>(
	'/analytics/v2/workflow',
	async (data, { rejectWithValue, getState, dispatch }) => {
		try {
			const { analytics } = getState() as {
				analytics: State;
			};
			const response = await analyticService.DialogAnalytics(data);
			if (data.isPreviousData) {
				dispatch(dialogMinicardPreviousData(response.data));
			}
			return response.data;
		} catch (error: any) {
			const response = { total: 0, data: 0 };
			if (data.isPreviousData) {
				dispatch(dialogMinicardPreviousData(response.data));
			}
			return response.data;
		}
	}
);
// export const getDownloadFile = async () => {
//   return axios.get('/getAllAgentReport ', {
//       responseType: 'blob',
//   })
//   .then(response => response.blob())
// }
export const getBotCount = createAsyncThunk<
	any,
	getBotCountData,
	{ rejectValue: string }
>('/rba/v1/getBotCount', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.getBotCount(data);
		const token = response.data.jwtToken;

		return response.data;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});
export const AnalyticsTopQueries = createAsyncThunk<
	any,
	Analyticsdata,
	{ rejectValue: string }
>('/analytics/v1/getPopularkeywords', async (data, { rejectWithValue }) => {
	let analyticsTopQueriesData = {
		mapObject: {
			'What can you do?': 83,
			'Book an appointment': 45,
			'Im not able to access': 62,
			'What is your phone number': 62,
			'Where is your store located?': 12,
			workflow: 36,
			'How to change my pin?': 23,
			'Check my Balance': 42,
			'card/single card': 10,
			'Can i please send me more details?': 89,
			'card/video': 27,
			'Generic Question': 12,
			'suggestion chips': 24,
			'Did not comprehend': 60,
			'text message/multiple text messages': 42,
			'workflow/rating task': 29,
			'Ambiguous Question': 39,
			'workflow/API based auto suggest': 9,
		},
	};
	try {
		const response = await analyticService.AnalyticsTopQueries(data);
		return analyticsTopQueriesData;
		// switch (response.status) {
		// 	case 200:
		// 		return response;
		// 		break;

		// 	default:
		// 		return rejectWithValue('Something went wrong! Try again later1');
		// 		break;
		// }
	} catch (error: any) {
		return analyticsTopQueriesData;
		// const response = error.response;
		// return rejectWithValue(response.data.message);
	}
});
export const getconversationTableData = createAsyncThunk<
	any,
	Analyticsdata,
	{ rejectValue: string }
>('/analytics/v1/getConvoChatData', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.getconversationTableData(data);
		switch (response.status) {
			case 200:
				return response;

			default:
				return rejectWithValue('Something went wrong! Try again later1');
		}
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});
export const getCompletionPointofDialog = createAsyncThunk<
	any,
	Analyticsdata,
	{ rejectValue: string }
>('/analytics/v2/dialog', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.getCompletionPointofDialog(data);
		switch (response.status) {
			case 200:
				return response;

			default:
				return rejectWithValue('Something went wrong! Try again later1');
		}
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});
export const getHighestDropOffRate = createAsyncThunk<
	any,
	Analyticsdata,
	{ rejectValue: string }
>('/analytics/v2/task', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.getHighestDropOffRate(data);
		switch (response.status) {
			case 200:
				return response;

			default:
				return rejectWithValue('Something went wrong! Try again later1');
		}
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});
export const getconversationChatData = createAsyncThunk<
	any,
	ConversationChatData,
	{ rejectValue: string }
>('/analytics/v1/getconvocount', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.getconversationChatData(data);
		switch (response.status) {
			case 200:
				return response;

			default:
				return rejectWithValue('Something went wrong! Try again later1');
		}
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const DashboardAnalytics = createAsyncThunk<
	any,
	convAnalytics,
	{ rejectValue: string }
>('/dashboardAnalytics', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.DashboardAnalytics(data);
		switch (response.status) {
			case 200:
				return response;

			default:
				return rejectWithValue('Something went wrong! Try again later1');
		}
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const getUserResponseAction = createAsyncThunk<
	any,
	Analyticsdata,
	{ rejectValue: string }
>('/analytics/v2/user_input', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.getUserResponseAction(data);
		switch (response.status) {
			case 200:
				return response;

			default:
				return rejectWithValue('Something went wrong! Try again later1');
		}
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});
const selectedCaVsrBot = loadState('selectedCaVsrBot');

// export const getRealTimeAnalyticsLiveChat = createAsyncThunk<
// 	any,
// 	getRealtimeAnalytics,
// 	{ rejectValue: string }
// >('/getRealtimeAnalyticsForWebAware', async (data, { rejectWithValue }) => {
// 	try {
// 		const response = await analyticService.getRealtimeAnalyticsLiveChat(data);
// 		return response.data;
// 	} catch (error) {
// 		return { AgentsOnline: 5, waiting: 39, total: 59 };
// 	}
// });
export const getRealTimeAnalyticsLiveChat = createAsyncThunk<
	any,
	getRealtimeAnalytics,
	{ rejectValue: string }
>(
	'/HybridChat/getRealtimeAnalytics',
	async (data, { rejectWithValue, getState }) => {
		try {
			const { teams } = getState() as { teams: any };
			const { tenantId } = teams;
			const response = await analyticService.getRealtimeAnalyticsLiveChat(data);
			return response.data;
		} catch (error) {
			return {
				ResData: { AgentsOnline: 3, TotalHandedOffChats: 0, ChatsInQueue: 0 },
				ResType: 'Success',
				ResCode: 'a5430c2e-5429-11ed-95c7-0a57c01caabc',
			};
		}
	}
);

export const getAgentRankingLiveChat = createAsyncThunk<
	any,
	getAgentRanking,
	{ rejectValue: string }
>('/HybridChat/agentRanking', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.getAgentRankingLiveChat(data);
		return response.data;
	} catch (error) {
		return {};
	}
});

export const downloadReport = () => {
	fetch(`/HybridChat/getAllAgentReport`, {
		method: 'POST',
	})
		.then((response) => response.blob())

		.then((blob) => {
			const url = window.URL.createObjectURL(blob);

			const a = document.createElement('a');

			a.href = url;

			a.download = `report_${new Date().getTime()}.xlsx`;

			document.body.appendChild(a);

			a.click();

			a.remove();
		});
};

export const getOverViewAnalyticsLiveChat = createAsyncThunk<
	any,
	getOverViewAnalytics,
	{ rejectValue: string }
>('/HybridChat/getAnalyticsOverView', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.getOverviewLiveChat(data);
		return response.data;
	} catch (error) {
		return {
			ResData: {
				TotalHandoffs: 0,

				TotalHandoffsDef: 'Shows the total number of handoffs triggered',

				AutomaticHandoff: 0,

				AutomaticHandoffDef: 'Shows the automatic handoffs triggered',

				AgentTakeOver: 0,

				AgentTakeOverDef: 'Show the number of chats taken over by agent',

				AvgAgentResponseTime: '0',

				AvgAgentResponseTimeDef:
					'Shows the average amount of time it takes an agent to respond after a user sends their message',

				HandoffByUser: '-',

				HandoffByUserDef:
					'Shows the average amount of time a user has to wait before an agent starts chatting with them',

				AvgUserWaitTimeInQueue: 0,

				AvgUserWaitTimeInQueueDef: 'Shows the average amount of time a user',
			},

			ResType: 'Success',

			ResCode: 'a5430c2e-5429-11ed-95c7-0a57c01caabc',
		};
	}
});
export const getAllAgentsLiveChat = createAsyncThunk<
	any,
	getAgents,
	{ rejectValue: string }
>('/getAgents', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.getAllAgentsLiveChat(data);
		return response.data;
	} catch (error) {
		return;
	}
});

export const getTotalConversationalDatas = createAsyncThunk<
	any,
	getTimeDetails,
	{ rejectValue: string }
>('/getAnalyticConversation', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.getConversationDataDetails(data);
		return response.data;
	} catch (error) {
		return {};
	}
});



export const getFeedbackConversationalDatas = createAsyncThunk<
	any,
	getTimeDetails,
	{ rejectValue: string }
>('/getFeedbackConversations', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.getFeedbackConvDetails(data);
		return response.data;
	} catch (error) {
		return {};
	}
});

export const getWholeConversation = createAsyncThunk<
	any,
	conversationID,
	{ rejectValue: string }
>('/getChatConversation', async (data, { rejectWithValue }) => {
	try {
		const response = await analyticService.getChatDetails(data);
		// dispatch(refreshToken({}));
		return response.data;
	} catch (error) {
		return {};
	}
});

const initialState: State = {
	getBotCountstatus: '',
	offsetval:0,
	offset:0,
	selectedDay:"",
	getCaVsrBotCount: {
		caBotCount: 0,
		vsrBotCount: 0,
	},
	conversationSelectedField: '',
	// conversationChatData: [
	// {
	// 	origin: 'https://awareuat.senseforth.com:8443',
	// 	count: 823,
	// 	referers: [
	// 		{
	// 			count: 823,
	// 			pageTitle: '',
	// 			value:
	// 				'https://awareuat.senseforth.com:8443/chatsdk.html?botID=IIP0Q8bBkLls9v0ipQ3DHg%3D%3D',
	// 		},
	// 	],
	// },
	// {
	// 	origin: 'https://aware-commons.s3.ap-south-1.amazonaws.com',
	// 	count: 184,
	// 	referers: [
	// 		{
	// 			count: 184,
	// 			pageTitle: '',
	// 			value: 'https://aware-commons.s3.ap-south-1.amazonaws.com',
	// 		},
	// 	],
	// },
	// {
	// 	origin: 'http://localhost',
	// 	count: 7,
	// 	referers: [{ count: 7, pageTitle: '', value: 'http://localhost:3000/' }],
	// },
	// ],
	conversationChatData: {
		'Whatsapp  ': 50,
		'Web (Tablet)': 49,
		'Web (Mobile)': 45,
		'Web (Desktop)': 40,
		'Slack  ': 30,
		'Google Assistant': 25,
		'alexa  ': 2,
		'Facebook Messenger': 0,
	},
	MiniCardAvgUserRating: false,
	conversationChatDatastatus: '',
	highestDropoffRateData: {
		data: [
			{
				actionName: 'Text or images 1',
				success: 39,
				switched_flow: 344,
				total: 80,
				userResponseTypeId: 1,
				validation_fail: 34888,
			},
			{
				actionName: 'Multi Select',
				success: 20,
				switched_flow: 344,
				total: 60,
				userResponseTypeId: 1,
				validation_fail: 34888,
			},
			{
				actionName: 'Multi Choice',
				success: 39,
				switched_flow: 344,
				total: 49,
				userResponseTypeId: 1,
				validation_fail: 34888,
			},
			{
				actionName: 'Collect User Input',
				success: 39,
				switched_flow: 344,
				total: 49,
				userResponseTypeId: 1,
				validation_fail: 34888,
			},
			{
				actionName: 'API Call 1',
				success: 0,
				switched_flow: 0,
				total: 0,
				userResponseTypeId: 10,
				validation_fail: 0,
			},
			{
				actionName: 'Multiple Choice 1',
				success: 0,
				switched_flow: 0,
				total: 0,
				userResponseTypeId: 5,
				validation_fail: 0,
			},
			{
				actionName: 'Text or images 2',
				success: 0,
				switched_flow: 0,
				total: 0,
				userResponseTypeId: 1,
				validation_fail: 0,
			},
			{
				actionName: 'User Input 1',
				success: 0,
				switched_flow: 0,
				total: 0,
				userResponseTypeId: 150,
				validation_fail: 0,
			},
			{
				actionName: 'User Input 2',
				success: 0,
				switched_flow: 0,
				total: 0,
				userResponseTypeId: 150,
				validation_fail: 0,
			},
		],
		total: null,
	},
	highestDropoffRateStatus: '',
	completionPointDialogData: {
		total: 800,
		data: [
			{
				count: 1500,
				userResponseTypeId: 3,
				taskName: 'Date of Birth',
			},
			{
				count: 3000,
				userResponseTypeId: 150,
				taskName: 'Newsletter subscription resp Newsletter subscription resp',
			},
			{
				count: 63,
				userResponseTypeId: 3,
				taskName: 'Names',
			},
			{
				count: 500,
				userResponseTypeId: 5,
				taskName: 'Interests',
			},
			{
				count: 603,
				userResponseTypeId: 5,
				taskName: 'User Rating',
			},
		],
	},
	completionDialogstatus: '',

	UserResponseActionstatusChatData: [
		{
			userResponseTypeId: 5,
			taskName: 'Gender',
			totalResponse: 2000,
			data: {
				Male: 1100,
				Female: 800,
				Other: 100,
			},
		},
		{
			userResponseTypeId: 150,
			taskName: 'jnjbh',
			totalResponse: 2000,
			data: {
				Male: 100,
				Female: 400,
				Other: 160,
			},
		},
		{
			userResponseTypeId: 12,
			taskName: 'Query Trigger',
			totalResponse: 2000,
			data: {
				Male: 100,
				Female: 3340,
				Other: 120,
			},
		},
	],
	optionSelected: '',
	analyticsTopQueriesstatus: '',
	tableFilterSelected: '',
	tableFiltersearch: '',
	todayMiniCardData: '',
	UserResponseActionstatus: '',
	selectedPreviousDates: [],
	MiniCardDatastatus: '',
	analyticsMiniCardData: {},
	allTopQueries: false,
	dialogviewallQueries: false,
	dialogQueriesanalytic: false,
	selectedTriggeredQuery: {},
	selectedDates: [],
	calendarstatus: false,
	dropOffRates: false,
	conversationTable: false,
	analyticsDialogstatus: '',
	conversationTablestatus: '',
	currentMiniCardData: {
		// accuracy: 1,
		// avgFeedback: 3,
		// avg_chat_dur: 4.2,
		// browsers: {},
		// chatFeedback: { negative: 0, positive: 0 },
		// feedback: { incorrect: 0, correct: 0 },
		// feedback_count: 1,
		// ipaddresses: {},
		// locations: {},
		// total_convo: 35,
		// total_ques: 13,
		// unique_queries: 8,
		// unique_user: 5,
	},
	analyticsConversationsTable: [
		// {
		// 	answer: '{"responseElements":[{"responseType":"SIMPLE",',
		// 	user_comments: 'dfdfdfdfdewn jn jkvn jk',
		// 	country: 'India',
		// 	device: 'mobile',
		// 	revenue: '$189000',
		// 	ip_address: '136.226.255.19',
		// 	session_id: 'aware-web-849E6C31E0F4',
		// 	date_time: '2022-06-16T12:23:13.000Z',
		// 	user_rating: 2,
		// },
		// {
		// 	answer: '{"responseElements":[{"responseType":"SIMPLE",',
		// 	user_comments: 'dfdfdfdfdewn jn jkvn jk',
		// 	country: 'India',
		// 	device: 'mobile',
		// 	revenue: '$189000',
		// 	ip_address: '136.226.255.19',
		// 	session_id: 'aware-web-849E6C31E0F4',
		// 	date_time: '2022-06-16T12:23:13.000Z',
		// 	user_rating: 2,
		// },
		// {
		// 	answer: '{"responseElements":[{"responseType":"SIMPLE",',
		// 	user_comments: 'dfdfdfdfdewn jn jkvn jk',
		// 	country: 'India',
		// 	device: 'mobile',
		// 	revenue: '$189000',
		// 	ip_address: '136.226.255.19',
		// 	session_id: 'aware-web-849E6C31E0F4',
		// 	date_time: '2022-06-16T12:23:13.000Z',
		// 	user_rating: 2,
		// },
		// {
		// 	answer: '{"responseElements":[{"responseType":"SIMPLE",',
		// 	user_comments: 'dfdfdfdfdewn jn jkvn jk',
		// 	country: 'India',
		// 	device: 'mobile',
		// 	revenue: '$189000',
		// 	ip_address: '136.226.255.19',
		// 	session_id: 'aware-web-849E6C31E0F4',
		// 	date_time: '2022-06-16T12:23:13.000Z',
		// 	user_rating: 2,
		// },
		// {
		// 	answer: '{"responseElements":[{"responseType":"SIMPLE",',
		// 	user_comments: 'Good Very interesting',
		// 	country: 'India',
		// 	device: 'mobile',
		// 	revenue: '$189000',
		// 	ip_address: '136.226.255.19',
		// 	session_id: 'aware-web-849E6C31E0F4',
		// 	date_time: '2022-06-16T12:23:13.000Z',
		// 	user_rating: 2,
		// },
		// {
		// 	answer: '{"responseElements":[{"responseType":"SIMPLE",',
		// 	user_comments: 'Good Very interesting',
		// 	country: 'India',
		// 	device: 'mobile',
		// 	revenue: '$189000',
		// 	ip_address: '136.226.255.19',
		// 	session_id: 'aware-web-849E6C31E0F4',
		// 	date_time: '2022-06-16T12:23:13.000Z',
		// 	user_rating: 2,
		// },
		// {
		// 	answer: '{"responseElements":[{"responseType":"SIMPLE",',
		// 	user_comments: 'Good Very interesting',
		// 	country: 'India',
		// 	device: 'mobile',
		// 	revenue: '$189000',
		// 	ip_address: '136.226.255.19',
		// 	session_id: 'aware-web-849E6C31E0F4',
		// 	date_time: '2022-06-16T12:23:13.000Z',
		// 	user_rating: 2,
		// },
		// {
		// 	user_comments: 'cnjknbjkdbjksndkj',
		// 	country: 'India',
		// 	device: 'mobile',
		// 	revenue: '$189000',
		// 	ip_address: '136.226.255.19',
		// 	session_id: 'aware-web-849E6C31E0F4',
		// 	date_time: '2022-06-16T12:23:13.000Z',
		// 	user_rating: '',
		// },
		// {
		// 	user_comments: 'dcnbjndjsn',
		// 	country: 'India',
		// 	device: 'mobile',
		// 	revenue: '$189000',
		// 	ip_address: '136.226.255.19',
		// 	session_id: 'aware-web-849E6C31E0F4',
		// 	date_time: '2022-06-16T12:23:13.000Z',
		// 	user_rating: '',
		// },
	],
	analyticsTopQueriesData: {
		mapObject: {
			'What can you do?': 83,
			'Book an appointment': 45,
			'Im not able to access': 62,
			'What is your phone number?': 62,
			'Where is your store located?': 12,
			workflow: 36,
			'How to change my pin?': 23,
			'Check my Balance': 42,
			'card/single card': 10,
			'Can you please send me more details?': 89,
			'card/video': 27,
			'Generic Question': 12,
			'suggestion chips': 24,
			'Did not comprehend': 60,
			'text message/multiple text messages': 42,
			'workflow/rating task': 29,
			'Ambiguous Question': 39,
			'workflow/API based auto suggest': 9,
		},
	},
	topQueriesDatastatus: '',
	analyticsDialog: {
		total: null,
		data: [
			{
				wfStart: 95,
				wfEnd: 80,
				wfId: 5968,
				triggerQuery: 'Test - 1',
			},
			{
				wfStart: 80,
				wfEnd: 70,
				wfId: 7297,
				triggerQuery: 'Test - 2',
			},
			{
				wfStart: 10,
				wfEnd: 0,
				wfId: 5474,
				triggerQuery: 'Test - 3',
			},
			{
				wfStart: 0,
				wfEnd: 10,
				wfId: 6276,
				triggerQuery: 'Test - 4',
			},
			{
				wfStart: 200,
				wfEnd: 80,
				wfId: 5968,
				triggerQuery: 'Test - 6',
			},
			{
				wfStart: 250,
				wfEnd: 70,
				wfId: 6565,
				triggerQuery: 'Test - 2',
			},
			{
				wfStart: 290,
				wfEnd: 80,
				wfId: 5968,
				triggerQuery: 'Test - 5',
			},
			{
				wfStart: 300,
				wfEnd: 70,
				wfId: 7297,
				triggerQuery: 'Test - 2',
			},
			{
				wfStart: 195,
				wfEnd: 80,
				wfId: 5968,
				triggerQuery: 'Test - 4',
			},
			{
				wfStart: 170,
				wfEnd: 70,
				wfId: 7297,
				triggerQuery: 'Test - 2',
			},
		],
	},
	analyticsoldDialog: {
		total: null,
		data: [
			{
				wfStart: 80,
				wfEnd: 95,
				wfId: 5968,
				triggerQuery: '',
			},
			{
				wfStart: 29,
				wfEnd: 88,
				wfId: 7297,
				triggerQuery: 'test1',
			},
			{
				wfStart: 87,
				wfEnd: 10,
				wfId: 5474,
				triggerQuery: 'test',
			},
			{
				wfStart: 15,
				wfEnd: 17,
				wfId: 6276,
				triggerQuery: 'video',
			},
			{
				wfStart: 23,
				wfEnd: 14,
				wfId: 7295,
				triggerQuery: 'check',
			},
		],
	},
	MiniCardpreDatastatus: '',
	activeAnalyticsBotstatus: '',
	activeAnalyticsBot: selectedCaVsrBot,
	analyticspreMiniCardData: {
		// accuracy: 0,
		// avgFeedback: 5,
		// avg_chat_dur: 3.2,
		// browsers: {},
		// chatFeedback: { negative: 0, positive: 0 },
		// feedback: { incorrect: 0, correct: 0 },
		// feedback_count: 0,
		// ipaddresses: {},
		// locations: {},
		// total_convo: 20,
		// total_ques: 0,
		// unique_queries: 0,
		// unique_user: 3,
	},
	MiniCardpreviousApiData: false,
	selectedRow: '',
	showTranscript: false,
	TranscriptData: '',
	RealTimeAnalyticsstatus: '',
	getRealTimeAnalyticsLiveChatData: {},
	OverViewAnalyticsstatus: '',
	getOverViewAnalyticsLiveChatData: {},
	getAllAgentsData: {},
	getAgentsStatus: '',
	AgentRankingstatus: '',
	getAgentRankingData: {},
	dashboardDate: {
		// "noOfConversation": 1,
		// "leadGenerated": 0,
		// "revenueGenerated": null,
		// "avgChatDuration": "0:0:0",
		// "avgChatLength": 6,
		// "avgUserRating": null,
		// "handsOffs": null,
		// "dropOffRate": null,
		// "conversationByChannel": [
		// 	{
		// 		"value": "Web",
		// 		"number": 100
		// 	},
		// 	{
		// 		"value": "Whatsapp",
		// 		"number": 200
		// 	},
		// ],
		// "conversationByCountry": null,
		// "conversationByUserRating": null
	},
	analyticConversationData: [],
	chatConversationalData: [],
	chatFeedbackData: [],
	openChatContainer: '',
	isConversationIdValid: '',
	FeedbackDataSearchedValue: '',
	conSearchedValue: '',
	getchartLoading: false,
	chatDataLoading: false,
	openConversation: "",
	totalCurrentChatLength: [],
	totalChatLength: 0,
	totalFeedbackCurrentLength: [],
	totalFeedbackLength: 0
};

export const analyticSlice = createSlice({
	name: 'analytics',
	initialState,
	reducers: {
		topQueriesViewAll: (state, action) => {
			state.allTopQueries = action.payload;
		},
		dialogViewAll: (state, action) => {
			state.dialogviewallQueries = action.payload;
		},
		dialogQueriesView: (state, action) => {
			state.dialogQueriesanalytic = action.payload;
		},
		SelecteddialogQuery: (state, action) => {
			state.selectedTriggeredQuery = action.payload;
		},
		highestDropOffRates: (state, action) => {
			state.dropOffRates = action.payload;
		},
		setStartEndDates: (state, action) => {
			state.calendarstatus = true;
			state.selectedDates = action.payload;
			saveState('startdate', new Date(state.selectedDates[0]));
			saveState('enddate', new Date(state.selectedDates[1]));
		},
		setPreviousStartEndDates: (state, action) => {
			state.calendarstatus = true;
			state.selectedPreviousDates = action.payload;

		},
		ConversationTable: (state, action) => {
			state.conversationTable = action.payload;
		},
		setOptionSelected: (state, action) => {
			state.optionSelected = action.payload;
		},
		conversationChatSelected: (state, action) => {
			state.conversationSelectedField = action.payload;
		},
		setOffsetval:(state, action) => {
			state.offsetval = action.payload;
		},
		conversationTableFilterSelected: (state, action) => {
			state.tableFilterSelected = action.payload.filter;
			state.tableFiltersearch = action.payload.search;
		},
		MinicardPreviousData: (state, action) => {
			state.analyticspreMiniCardData = action.payload;
		},
		dialogMinicardPreviousData: (state, action) => {
			state.analyticsoldDialog = action.payload;
		},
		setSelectedRow: (state, action) => {
			state.showTranscript = action.payload.showTranscript;
			state.selectedRow = action.payload.selectedRow;
			state.TranscriptData = action.payload.TranscriptData;
		},
		setActiveAnalyticsBot: (state, action) => {
			state.activeAnalyticsBot = action.payload.data;
			state.activeAnalyticsBotstatus = action.payload.status;
		},
		setselectedDay:(state, action) => {
			state.selectedDay=action.payload;
		},
		onClickMiniCardAvgUserRating: (state, action) => {
			state.MiniCardAvgUserRating = action.payload;
		},
		resetConversationIdStatus: (state, action) => {
			state.isConversationIdValid = action.payload;
		},
		setOffset:(state,action)=>{
			state.offset = action.payload;
		},
		resetCalenderStatus: (state, action) => {
			state.calendarstatus = action.payload;
		},
		setFeedbackSearchedValue: (state, action) => {
			state.FeedbackDataSearchedValue = action.payload;
		},
		setSearchedValue: (state, action) => {
			state.conSearchedValue = action.payload;
		},
		setOpenConversation: (state, action) => {
			state.openConversation = action.payload;
		},

	},
	extraReducers: (builder) => {
		builder
			.addCase(MiniCardData.pending, (state) => {
				state.MiniCardDatastatus = 'loading';
			})
			.addCase(MiniCardData.fulfilled, (state, action) => {
				// state.analyticsMiniCardData = action.payload;
				// if (state.optionSelected === 'Today') {
				// 	state.todayMiniCardData = action.payload;
				// }
				if (state.MiniCardpreviousApiData) {
					state.analyticspreMiniCardData = action.payload;
				}
				state.currentMiniCardData = action.payload;
				state.currentMiniCardData = action.payload;
				state.MiniCardDatastatus = 'Success';
			})
			.addCase(MiniCardData.rejected, (state) => {
				state.MiniCardDatastatus = 'failed';
			})
			.addCase(getconversationTableData.pending, (state) => {
				state.conversationTablestatus = 'loading';
			})
			.addCase(getconversationTableData.fulfilled, (state, action) => {
				console.log(action.payload)
				state.analyticsConversationsTable = action.payload.conversationList;

				state.conversationTablestatus = 'Success';
			})


			.addCase(getconversationTableData.rejected, (state) => {
				state.conversationTablestatus = 'failed';
				state.analyticsConversationsTable = [
					{
						answer: '{"responseElements":[{"responseType":"SIMPLE",',
						user_comments: '1.comments for example -1 ',
						country: 'India',
						device: 'mobile',
						revenue: 189000,
						ip_address: '136.226.255.19', //NOSONAR
						session_id: 'aware-web-849E6C31E0F4',
						date_time: '2022-06-16T12:23:13.000Z',
						user_rating: 2,
					},
					{
						answer: '{"responseElements":[{"responseType":"SIMPLE",',
						user_comments: '2.comments for example -2',
						country: 'India',
						device: 'mobile',
						revenue: 189000,
						ip_address: '136.226.255.19', //NOSONAR
						session_id: 'aware-web-849E6C31E0F4',
						date_time: '2022-06-16T12:23:13.000Z',
						user_rating: 2,
					},
					{
						answer: '{"responseElements":[{"responseType":"SIMPLE",',
						user_comments: '3.comments for example -3',
						country: 'India',
						device: 'mobile',
						revenue: 189000,
						ip_address: '136.226.255.19', //NOSONAR
						session_id: 'aware-web-849E6C31E0F4',
						date_time: '2022-06-16T12:23:13.000Z',
						user_rating: 2,
					},
					{
						answer: '{"responseElements":[{"responseType":"SIMPLE",',
						user_comments: '4.comments for example -4',
						country: 'India',
						device: 'mobile',
						revenue: 189000,
						ip_address: '136.226.255.19', //NOSONAR
						session_id: 'aware-web-849E6C31E0F4377D8F5B9D6BD31F263D',
						date_time: '2022-06-16T12:23:13.000Z',
						user_rating: 2,
					},
					{
						answer: '{"responseElements":[{"responseType":"SIMPLE",',
						user_comments: '5.comments for example -5',
						country: 'India',
						device: 'mobile',
						revenue: 189000,
						ip_address: '136.226.255.19', //NOSONAR
						session_id: 'aware-web-849E6C31E0F4',
						date_time: '2022-06-16T12:23:13.000Z',
						user_rating: 2,
					},
					{
						answer: '{"responseElements":[{"responseType":"SIMPLE",',
						user_comments: '6.comments for example -1 ',
						country: 'India',
						device: 'mobile',
						revenue: 189000,
						ip_address: '136.226.255.19', //NOSONAR
						session_id: 'aware-web-849E6C31E0F4',
						date_time: '2022-06-16T12:23:13.000Z',
						user_rating: 2,
					},
					{
						answer: '{"responseElements":[{"responseType":"SIMPLE",',
						user_comments: '7.comments for example -2',
						country: 'India',
						device: 'mobile',
						revenue: 189000,
						ip_address: '136.226.255.19', //NOSONAR
						session_id: 'aware-web-849E6C31E0F4',
						date_time: '2022-06-16T12:23:13.000Z',
						user_rating: 2,
					},
					{
						answer: '{"responseElements":[{"responseType":"SIMPLE",',
						user_comments: '8.comments for example -3',
						country: 'India',
						device: 'mobile',
						revenue: 189000,
						ip_address: '136.226.255.19', //NOSONAR
						session_id: 'aware-web-849E6C31E0F4',
						date_time: '2022-06-16T12:23:13.000Z',
						user_rating: 2,
					},
					{
						answer: '{"responseElements":[{"responseType":"SIMPLE",',
						user_comments: '9.comments for example -4',
						country: 'India',
						device: 'mobile',
						revenue: 189000,
						ip_address: '136.226.255.19', //NOSONAR
						session_id: 'aware-web-849E6C31E0F4',
						date_time: '2022-06-16T12:23:13.000Z',
						user_rating: 2,
					},
					{
						answer: '{"responseElements":[{"responseType":"SIMPLE",',
						user_comments: '10.comments for example -5',
						country: 'India',
						device: 'mobile',
						revenue: 189000,
						ip_address: '136.226.255.19', //NOSONAR
						session_id: 'aware-web-849E6C31E0F4',
						date_time: '2022-06-16T12:23:13.000Z',
						user_rating: 2,
					},
				];
			})
			.addCase(getBotCount.pending, (state) => {
				state.getBotCountstatus = 'loading';
			})
			.addCase(getBotCount.fulfilled, (state, action) => {
				state.getCaVsrBotCount = action.payload;
				state.getBotCountstatus = 'Success';
			})
			.addCase(getBotCount.rejected, (state) => {
				state.getBotCountstatus = 'failed';
			})
			.addCase(getCompletionPointofDialog.pending, (state) => {
				state.completionDialogstatus = 'loading';
			})
			.addCase(getCompletionPointofDialog.fulfilled, (state, action) => {
				// state.completionPointDialogData = action.payload.data;
				state.completionDialogstatus = 'Success';
			})
			.addCase(getCompletionPointofDialog.rejected, (state) => {
				state.completionDialogstatus = 'failed';
				state.completionPointDialogData = {
					total: 800,
					data: [
						{
							count: 1500,
							userResponseTypeId: 3,
							taskName: 'Date of Birth',
						},
						{
							count: 3000,
							userResponseTypeId: 150,
							taskName:
								'Newsletter subscription resp Newsletter subscription resp',
						},
						{
							count: 63,
							userResponseTypeId: 3,
							taskName: 'Names',
						},
						{
							count: 500,
							userResponseTypeId: 5,
							taskName: 'Interests',
						},
						{
							count: 603,
							userResponseTypeId: 5,
							taskName: 'User Rating',
						},
					],
				};
			})
			.addCase(getHighestDropOffRate.pending, (state) => {
				state.highestDropoffRateStatus = 'loading';
			})
			.addCase(getHighestDropOffRate.fulfilled, (state, action) => {
				// state.highestDropoffRateData = action.payload.data;
				state.highestDropoffRateStatus = 'Success';
			})
			.addCase(getHighestDropOffRate.rejected, (state) => {
				state.highestDropoffRateStatus = 'failed';
				state.highestDropoffRateData = {
					data: [
						{
							actionName: 'Text or images 1',
							success: 39,
							switched_flow: 344,
							total: 80,
							userResponseTypeId: 1,
							validation_fail: 34888,
						},
						{
							actionName: 'Multi Select',
							success: 20,
							switched_flow: 344,
							total: 60,
							userResponseTypeId: 1,
							validation_fail: 34888,
						},
						{
							actionName: 'Multi Choice',
							success: 39,
							switched_flow: 344,
							total: 49,
							userResponseTypeId: 1,
							validation_fail: 34888,
						},
						{
							actionName: 'Collect User Input',
							success: 39,
							switched_flow: 344,
							total: 49,
							userResponseTypeId: 1,
							validation_fail: 34888,
						},
						{
							actionName: 'API Call 1',
							success: 0,
							switched_flow: 0,
							total: 0,
							userResponseTypeId: 10,
							validation_fail: 0,
						},
						{
							actionName: 'Multiple Choice 1',
							success: 0,
							switched_flow: 0,
							total: 0,
							userResponseTypeId: 5,
							validation_fail: 0,
						},
						{
							actionName: 'Text or images 2',
							success: 0,
							switched_flow: 0,
							total: 0,
							userResponseTypeId: 1,
							validation_fail: 0,
						},
						{
							actionName: 'User Input 1',
							success: 0,
							switched_flow: 0,
							total: 0,
							userResponseTypeId: 150,
							validation_fail: 0,
						},
						{
							actionName: 'User Input 2',
							success: 0,
							switched_flow: 0,
							total: 0,
							userResponseTypeId: 150,
							validation_fail: 0,
						},
					],
					total: null,
				};
			})
			.addCase(AnalyticsDialog.pending, (state) => {
				state.analyticsDialogstatus = 'loading';
			})
			.addCase(AnalyticsDialog.fulfilled, (state, action) => {
				state.analyticsDialog = action.payload;
				state.analyticsDialogstatus = 'Success';
			})
			.addCase(AnalyticsDialog.rejected, (state) => {
				state.analyticsDialogstatus = 'failed';
			})
			.addCase(getconversationChatData.pending, (state) => {
				state.conversationChatDatastatus = 'loading';
			})
			.addCase(getconversationChatData.fulfilled, (state, action) => {
				// state.conversationChatData = action.payload.data;

				state.conversationChatDatastatus = 'Success';
			})
			.addCase(getconversationChatData.rejected, (state) => {
				state.conversationChatDatastatus = 'failed';
				state.conversationChatData = {
					'Whatsapp  ': 50,
					'Web (Tablet)': 49,
					'Web (Mobile)': 45,
					'Web (Desktop)': 40,
					'Slack  ': 30,
					'Google Assistant': 25,
					'alexa  ': 2,
					'Facebook Messenger': 0,
				};
			})
			.addCase(getUserResponseAction.pending, (state) => {
				state.UserResponseActionstatus = 'loading';
			})
			.addCase(getUserResponseAction.fulfilled, (state, action) => {
				// state.UserResponseActionstatusChatData = action.payload.data;
				state.UserResponseActionstatus = 'Success';
			})
			.addCase(getUserResponseAction.rejected, (state) => {
				state.UserResponseActionstatus = 'failed';
				state.UserResponseActionstatusChatData = [
					{
						userResponseTypeId: 5,
						taskName: 'Gender',
						totalResponse: 2000,
						data: {
							Male: 1100,
							Female: 800,
							Other: 100,
						},
					},
					{
						userResponseTypeId: 150,
						taskName: 'Example',
						totalResponse: 2000,
						data: {
							Male: 100,
							Female: 400,
							Other: 160,
						},
					},
					{
						userResponseTypeId: 12,
						taskName: 'Query Trigger',
						totalResponse: 2000,
						data: {
							Male: 100,
							Female: 3340,
							Other: 120,
						},
					},
				];
			})
			.addCase(AnalyticsTopQueries.pending, (state) => {
				state.analyticsTopQueriesstatus = 'loading';
			})
			.addCase(AnalyticsTopQueries.fulfilled, (state, action) => {
				// state.analyticsTopQueriesData = action.payload.data;
				state.analyticsTopQueriesstatus = 'Success';
			})
			.addCase(AnalyticsTopQueries.rejected, (state) => {
				state.analyticsTopQueriesstatus = 'failed';
			})
			.addCase(getRealTimeAnalyticsLiveChat.pending, (state) => {
				state.RealTimeAnalyticsstatus = 'loading';
			})
			.addCase(getRealTimeAnalyticsLiveChat.fulfilled, (state, action) => {
				state.RealTimeAnalyticsstatus = 'fulfilled';
				state.getRealTimeAnalyticsLiveChatData = action.payload;
			})
			.addCase(getRealTimeAnalyticsLiveChat.rejected, (state, action) => {
				state.RealTimeAnalyticsstatus = 'failed';
				state.getRealTimeAnalyticsLiveChatData = action.payload;
			})

			.addCase(getOverViewAnalyticsLiveChat.pending, (state) => {
				state.OverViewAnalyticsstatus = 'loading';
			})
			.addCase(getOverViewAnalyticsLiveChat.fulfilled, (state, action) => {
				state.OverViewAnalyticsstatus = 'fulfilled';
				state.getOverViewAnalyticsLiveChatData = action.payload;
			})
			.addCase(getOverViewAnalyticsLiveChat.rejected, (state, action) => {
				state.OverViewAnalyticsstatus = 'failed';
				state.getOverViewAnalyticsLiveChatData = action.payload;
			})
			.addCase(getAgentRankingLiveChat.pending, (state) => {
				state.AgentRankingstatus = 'loading';
			})
			.addCase(getAgentRankingLiveChat.fulfilled, (state, action) => {
				state.AgentRankingstatus = 'fulfilled';
				state.getAgentRankingData = action.payload;
			})
			.addCase(getAgentRankingLiveChat.rejected, (state, action) => {
				state.AgentRankingstatus = 'failed';
				state.getAgentRankingData = action.payload;
			})
			.addCase(getAllAgentsLiveChat.pending, (state) => {
				state.getAgentsStatus = 'loading';
			})
			.addCase(getAllAgentsLiveChat.fulfilled, (state, action) => {
				state.getAgentsStatus = 'fulfilled';
				state.getAllAgentsData = action.payload;
			})
			.addCase(getAllAgentsLiveChat.rejected, (state, action) => {
				state.getAgentsStatus = 'failed';
				state.getAllAgentsData = action.payload;
			})
			.addCase(DashboardAnalytics.pending, (state) => {
				state.getchartLoading = true;
			})
			.addCase(DashboardAnalytics.rejected, (state) => {
				state.getchartLoading = true;
			})
			.addCase(DashboardAnalytics.fulfilled, (state, action) => {
				state.getchartLoading = false;
				state.dashboardDate = action.payload.data;

			})
			.addCase(getWholeConversation.pending, (state) => { })
			.addCase(getWholeConversation.fulfilled, (state, action) => {
				state.openChatContainer = 'open';
				state.analyticConversationData = action.payload;
			})
			.addCase(getWholeConversation.rejected, (state) => { })
			.addCase(getTotalConversationalDatas.rejected, (state) => {
				state.chatDataLoading = true
			})
			.addCase(getTotalConversationalDatas.pending, (state) => {
				state.chatDataLoading = true
			})
			.addCase(getTotalConversationalDatas.fulfilled, (state, action) => {

				state.chatConversationalData = action.payload.conversationList;

				state.totalCurrentChatLength = action.payload.conversationList
					? action.payload.conversationList
					: [];
				state.totalChatLength = action.payload.totalCount
					? action.payload.totalCount
					: 0;
				state.isConversationIdValid = 'valid';
				state.chatDataLoading = false
			})

			.addCase(getFeedbackConversationalDatas.pending, (state) => { })
			.addCase(getFeedbackConversationalDatas.fulfilled, (state, action) => {
				state.chatFeedbackData = action.payload.feedbackList;
				state.totalFeedbackCurrentLength = action.payload.feedbackList
					? action.payload.feedbackList
					: [];
				state.totalFeedbackLength = action.payload.totalCount
					? action.payload.totalCount
					: 0;
				state.isConversationIdValid = 'valid';
			})

			.addCase(getFeedbackConversationalDatas.rejected, (state) => { });
	},
});

export const {
	setOffset,
	topQueriesViewAll,
	dialogViewAll,
	dialogQueriesView,
	SelecteddialogQuery,
	setStartEndDates,
	highestDropOffRates,
	setPreviousStartEndDates,
	ConversationTable,
	setOptionSelected,
	conversationChatSelected,
	conversationTableFilterSelected,
	MinicardPreviousData,
	setSelectedRow,
	dialogMinicardPreviousData,
	setActiveAnalyticsBot,
	onClickMiniCardAvgUserRating,
	resetConversationIdStatus,
	resetCalenderStatus,
	setFeedbackSearchedValue,
	setSearchedValue,
	setOpenConversation,
	setOffsetval,
	setselectedDay
	
} = analyticSlice.actions;

export default analyticSlice.reducer;
