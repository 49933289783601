import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import './analyze.scss';
import { DashboardAnalytics, resetCalenderStatus, setOpenConversation } from '../../Features/Analytics/analyticsSlice';
import { RootState, useAppDispatch } from '../../app/store';
import CalendarInput from '../Analytics/calendar/calendarInput';
import { useSelector } from 'react-redux';
import { loadState } from '../../utils/utils';
import { BackToAnalytics, GoArrow } from '../../assets/Icons';
import { setStartEndDates } from '../../Features/Analytics/analyticsSlice';


function Analyze() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {
		selectedDates,
		calendarstatus,
		openConversation
	} = useSelector((state: RootState) => state.analytics);
	const { bot_AI_ID } = useSelector((state: RootState) => state.teams);
	const botId = loadState('botId');
	useEffect(
		() => {
			if (calendarstatus && window.location.pathname.indexOf('/app/analytics/chartdata') > -1) {

				dispatch(
					DashboardAnalytics({
						startTime: selectedDates[0],
						endTime: selectedDates[1],
						botId: bot_AI_ID ? bot_AI_ID : botId
					})
				);
				dispatch(resetCalenderStatus(false))
			}
		},
		[selectedDates]
	);
	return (
		<div className="ff_analyze-header-container">
			<div className={`ff_analyze-header ${window.location.pathname.indexOf('app/analytics/chartdata') > -1 ? 'ff-chartdata-header' : ''}`}>
				<div className="conversations-header-title">
					{(window.location.pathname.indexOf('app/analytics/conversations') > -1 || window.location.pathname.indexOf('app/analytics/feedbackdata') > -1) && (
						<span className="back-to-analytics" onClick={() => {
							navigate('/app/analytics/chartdata');
							dispatch(
								DashboardAnalytics({
									startTime: selectedDates[0],
									endTime: selectedDates[1],
									botId: bot_AI_ID ? bot_AI_ID : botId
								})
							);

						}

						}>
							<BackToAnalytics />
						</span>
					)}
					{
						window.location.pathname.indexOf('app/analytics/feedbackdata') > -1 ?
							<>
								<h4 title="Analyze" className='analyze-header'>Analyze</h4>
								<GoArrow />
								<h4 title="Feedback on Responses" className={`${openConversation === 'open' ? 'feedback-elip' : ''}`}>Feedback on Responses</h4>
							</>
							:
							window.location.pathname.indexOf('app/analytics/conversations') > -1 ?
								<>
									<h4 title="Analyze" className='analyze-header'>Analyze</h4>
									<GoArrow />
									<h4 title="Total Customer Engagements" className={`${openConversation === 'open' ? 'text-flow-elip' : ''}`}>Total Customer Engagements</h4>
								</>
								:
								<h4>Analyze</h4>
					}

				</div>
				<span className={`chart-calendar ${openConversation === 'open' ? 'open-calendar-conv' : ''}`}>
					<CalendarInput />
				</span>
			</div>
			<Outlet />
		</div>
	);
}

export default Analyze;
