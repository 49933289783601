import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Modal from '../../../Components/Modal';
import { resetShowFAQsuccessPopup } from '../../../Features/FAQs/faqSlice';
import { loadState, saveState } from '../../../utils/utils';
import './SuccessPopup.scss';

const ShopifySales = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const fileName = loadState('fileName');
	const { showFAQsuccessPopup } = useSelector((state: RootState) => state.faq);
	// const [openPopup, setOpen] = useState(true);
	return (
		<>
			{showFAQsuccessPopup === 'success' ? (
				<div className='import-successful'>
					<Modal
						onClose={() => {
							dispatch(resetShowFAQsuccessPopup(''));
						}}
						header='Import Successful!'
						actionButtons={
							<>
								{/* <Button
									buttonType='light'
									onClick={() => {
										dispatch(resetShowFAQsuccessPopup(''));
									}}>
									Replace Data Source
								</Button> */}
								<Button
									onClick={() => {
										dispatch(resetShowFAQsuccessPopup(''));
									}}>
									Okay
								</Button>
							</>
						}>
						<div className='ff-success-modal-body'>
							<p className='file-link-description body1'>
								Your data has been successfully imported from-
							</p>
							<p className='source-name body1'>Data Source: {fileName}</p>
						</div>
					</Modal>
					<Outlet />
				</div>
			) : null}
		</>
	);
};

export default ShopifySales;
