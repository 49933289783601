import React, { useEffect, useRef, useState } from 'react';
import './Notification.scss';
import NotifyMessage from './NotifyMessage';
import { TimeFormatting, loadState, removeState } from '../../utils/utils';
import { RootState, useAppDispatch } from '../../app/store';
import {
	getReadNotifications,
	getReadNotificationsForAll,
	getUnreadNotifications,
	getUnreadNotificationsForAll,
	resetAllNotificationData,
	setNewNotification,
	setNotificationPane,
	setOlderGroup,
	setTodayGroup,
	setYesterdayGroup,
	updateNotification,
} from '../../Features/Notifications/notificationSlice';
import { useSelector } from 'react-redux';
import PageLoader from '../../Components/PageLoader';
import { useLocation } from 'react-router';
export const NotifyIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path
				d='M12 22a2.006 2.006 0 0 0 2-2h-4a2 2 0 0 0 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4a1.5 1.5 0 0 0-3 0v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1z'
				transform='translate(0 -.25)'
				fill='rgba(0,0,0,.6)'
				data-name='Group 34032'
			/>
		</svg>
	);
};
export const NotifyActiveIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<defs>
				<linearGradient
					id='z2njrt0ada'
					x1='.5'
					x2='.5'
					y2='1'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stopColor='#e54e9a' />
					<stop offset='1' stopColor='#5531d3' />
				</linearGradient>
			</defs>
			<g data-name='Group 34032'>
				<path
					d='M12 22a2.006 2.006 0 0 0 2-2h-4a2 2 0 0 0 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4a1.5 1.5 0 0 0-3 0v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1z'
					transform='translate(0 -.25)'
					fill='url(#z2njrt0ada)'
				/>
				<g
					data-name='Ellipse 1349'
					transform='translate(14 2)'
					fill='#c12774'
					stroke='#fff'>
					<circle cx='4' cy='4' r='4' stroke='none' />
					<circle cx='4' cy='4' r='3.5' fill='none' />
				</g>
			</g>
		</svg>
	);
};

const Notification = () => {
	const dispatch = useAppDispatch();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const {
		readNotifications,
		unreadNotifications,
		todayGroup,
		yesterdayGroup,
		olderGroup,
		updateNotificationLoader,
		getReadNotificationLoader,
		getUnreadNotificationLoader,
		getReadNotificationLoaderForAll,
		getUnreadNotificationLoaderForAll,
	} = useSelector((state: RootState) => state.notification);
	const componentRef = useRef<HTMLDivElement | null>(null);
	const location = useLocation();

	useEffect(() => {
		if (updateNotificationLoader === 'success') {
			if (location.pathname.indexOf('/app/teams') > -1) {
				dispatch(
					getUnreadNotificationsForAll({
						userId: loadState('user').userID,
					})
				);
				dispatch(
					getReadNotificationsForAll({
						userId: loadState('user').userID,
						read: 1,
					})
				);
			} else {
				dispatch(
					getUnreadNotifications({
						userId: loadState('user').userID,
						tenantId: tenantId!,
					})
				);
				dispatch(
					getReadNotifications({
						userId: loadState('user').userID,
						tenantId: tenantId!,
						read: 1,
					})
				);
			}
		}
	}, [updateNotificationLoader]);

	useEffect(() => {
		dispatch(resetAllNotificationData());
		if (location.pathname.indexOf('/app/teams') > -1) {
			dispatch(
				getUnreadNotificationsForAll({
					userId: loadState('user').userID,
				})
			);
			dispatch(
				getReadNotificationsForAll({
					userId: loadState('user').userID,
					read: 1,
				})
			);
		} else {
			dispatch(
				getUnreadNotifications({
					userId: loadState('user').userID,
					tenantId: tenantId!,
				})
			);
			dispatch(
				getReadNotifications({
					userId: loadState('user').userID,
					tenantId: tenantId!,
					read: 1,
				})
			);
		}
		const handleOutsideClick = (event: MouseEvent) => {
			if (componentRef.current !== event.currentTarget) {
				dispatch(setNotificationPane(false));
				let unreadIDs =
					unreadNotifications &&
					unreadNotifications.length &&
					unreadNotifications.map((notification) => notification.id);

				if (unreadIDs && unreadIDs.length) {
					dispatch(
						updateNotification({
							userId: loadState('user').userID,
							notificationId: unreadIDs,
						})
					);
					dispatch(setNewNotification(false));
				}
			}
		};
		const handleInsideClick = (event: MouseEvent) => {
			event.stopPropagation();
		};
		if (componentRef.current) {
			componentRef.current.addEventListener('mousedown', handleInsideClick);
		}
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			if (componentRef.current) {
				componentRef.current.removeEventListener(
					'mousedown',
					handleInsideClick
				);
			}
			document.removeEventListener('mousedown', handleOutsideClick);
			dispatch(resetAllNotificationData());
		};
	}, []);

	const sortArray = (array: any) => {
		return array.sort((a: any, b: any) => {
			const dateA: any = new Date(a.arr.date);
			const dateB: any = new Date(b.arr.date);
			return dateB - dateA;
		});
	};

	useEffect(() => {
		if (unreadNotifications.length) {
			let tgroup: any = [];
			let ygroup: any = [];
			let ogroup: any = [];
			unreadNotifications.forEach((notification) => {
				let time = TimeFormatting(notification.date);
				if (time === 'Yesterday') {
					ygroup.push({ read: 0, arr: notification });
				} else if (time === 'Older') {
					ogroup.push({ read: 0, arr: notification });
				} else {
					tgroup.push({ read: 0, arr: notification });
				}
			});
			let stgroup = tgroup && tgroup.length ? sortArray(tgroup) : [];
			let sygroup = ygroup && ygroup.length ? sortArray(ygroup) : [];
			let sogroup = ogroup && ogroup.length ? sortArray(ogroup) : [];
			if (readNotifications && readNotifications.length) {
				stgroup = [...todayGroup, ...stgroup];
				sygroup = [...yesterdayGroup, ...sygroup];
				sogroup = [...olderGroup, ...sogroup];
				stgroup = sortArray(stgroup);
				sygroup = sortArray(sygroup);
				sogroup = sortArray(sogroup);
			}
			dispatch(setTodayGroup(stgroup));
			dispatch(setYesterdayGroup(sygroup));
			dispatch(setOlderGroup(sogroup));
		}
	}, [unreadNotifications]);

	useEffect(() => {
		if (readNotifications.length) {
			let tgroup: any = [];
			let ygroup: any = [];
			let ogroup: any = [];
			readNotifications.forEach((notification) => {
				let time = TimeFormatting(notification.date);
				if (time === 'Yesterday') {
					ygroup.push({ read: 1, arr: notification });
				} else if (time === 'Older') {
					ogroup.push({ read: 1, arr: notification });
				} else {
					tgroup.push({ read: 1, arr: notification });
				}
			});
			let stgroup = tgroup && tgroup.length ? sortArray(tgroup) : [];
			let sygroup = ygroup && ygroup.length ? sortArray(ygroup) : [];
			let sogroup = ogroup && ogroup.length ? sortArray(ogroup) : [];
			if (unreadNotifications && unreadNotifications.length) {
				stgroup = [...todayGroup, ...stgroup];
				sygroup = [...yesterdayGroup, ...sygroup];
				sogroup = [...olderGroup, ...sogroup];
				stgroup = sortArray(stgroup);
				sygroup = sortArray(sygroup);
				sogroup = sortArray(sogroup);
			}
			dispatch(setTodayGroup(stgroup));
			dispatch(setYesterdayGroup(sygroup));
			dispatch(setOlderGroup(sogroup));
		}
	}, [readNotifications]);

	const notificationExists = () => {
		if (todayGroup.length || yesterdayGroup.length || olderGroup.length) {
			return true;
		}
		return false;
	};

	const showNotification = () => {
		if (location.pathname.indexOf('/app/teams') > -1) {
			return (
				getReadNotificationLoaderForAll === 'success' ||
				getUnreadNotificationLoaderForAll === 'success'
			);
		}
		return (
			getReadNotificationLoader === 'success' ||
			getUnreadNotificationLoader === 'success'
		);
	};

	return (
		<div className='ff-notification-service'>
			<div
				className={`ff-notification ${!notificationExists() ? 'no' : 'yes'}`}
				ref={componentRef}>
				<h5>Notifications</h5>
				{showNotification() ? (
					<>
						<div className='notifications-container'>
							{todayGroup.length ? (
								<p className='overline today'>Today</p>
							) : null}
							{todayGroup.map((data: any) => {
								return (
									<NotifyMessage
										link={data.arr.name}
										message={data.arr.value}
										time={data.arr.date ? TimeFormatting(data.arr.date) : ''}
										type={data.arr.type === 'Information' ? 'info' : 'alert'}
										read={data.read}
									/>
								);
							})}

							{yesterdayGroup.length ? (
								<p className='overline'>Yesterday</p>
							) : null}
							{yesterdayGroup.map((data: any) => {
								return (
									<NotifyMessage
										link={data.arr.name}
										message={data.arr.value}
										time={
											data.arr.date ? TimeFormatting(data.arr.date, true) : ''
										}
										type={data.arr.type === 'Information' ? 'info' : 'alert'}
										read={data.read}
									/>
								);
							})}

							{olderGroup.length ? <p className='overline'>Older</p> : null}
							{olderGroup.map((data: any) => {
								return (
									<NotifyMessage
										link={data.arr.name}
										message={data.arr.value}
										time={
											data.arr.date ? TimeFormatting(data.arr.date, true) : ''
										}
										type={data.arr.type === 'Information' ? 'info' : 'alert'}
										read={data.read}
									/>
								);
							})}

							<p className='caption'>
								{location.pathname.indexOf('/app/teams') > -1
									? 'To see more notifications, please choose a team'
									: notificationExists()
									? "That's all your notifications from the past 30 days"
									: "You don't have any notifications yet"}
							</p>
						</div>
					</>
				) : (
					<div className='query-loader'>
						<PageLoader message='Loading...' />
					</div>
				)}
			</div>
		</div>
	);
};

export default Notification;
