import DataFetchAPI from '../../utils/Api';
import DataFetchRouteAPI from '../../utils/RouteApi';
import {
	Analyticsdata,
	ConversationChatData,
	getBotCountData,
	getRealtimeAnalytics,
	getOverViewAnalytics,
	getAgentRanking,
	getAgents,
	convAnalytics,
	getTimeDetails,
	conversationID,
} from './model';

const MiniCardData = async (data: Analyticsdata) => {
	return await DataFetchAPI().get('/analytics/v1/getanalytics', {
		params: data,
	});
};
const DialogAnalytics = async (data: Analyticsdata) => {
	return await DataFetchAPI().get('/analytics/v2/workflow', {
		params: data,
	});
};
const AnalyticsTopQueries = async (data: Analyticsdata) => {
	return await DataFetchAPI().get('/analytics/v1/getPopularkeywords', {
		params: data,
	});
};
const getconversationTableData = async (data: Analyticsdata) => {
	return await DataFetchAPI().get('/analytics/v1/getConvoChatData', {
		params: data,
	});
};
const getCompletionPointofDialog = async (data: Analyticsdata) => {
	return await DataFetchAPI().get('/analytics/v2/dialog', {
		params: data,
	});
};

const getHighestDropOffRate = async (data: Analyticsdata) => {
	return await DataFetchAPI().get('/analytics/v2/task', {
		params: data,
	});
};
const getconversationChatData = async (data: ConversationChatData) => {
	return await DataFetchAPI().post('/analytics/v1/getconvocount', data);
};
const DashboardAnalytics = async (data: convAnalytics) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		`/analytics/bot/${data.botId}?startTime=${data.startTime}&endTime=${data.endTime}`
	);
};

const getUserResponseAction = async (data: Analyticsdata) => {
	return await DataFetchAPI().get('/analytics/v2/user_input', {
		params: data,
	});
};
const getBotCount = async (data: getBotCountData) => {
	return await DataFetchAPI(false, true, 'Bearer').get('/rba/v1/getBotCount', {
		params: data,
	});
};
const getRealtimeAnalyticsLiveChat = async (data: getRealtimeAnalytics) => {
	return await DataFetchAPI().get(
		`/HybridChat/getRealtimeAnalytics?start=${data.utcstartDate}&end=${data.utcendDate}&tenantId=${data.tenantId}`
	);
};

const getOverviewLiveChat = async (data: getOverViewAnalytics) => {
	return await DataFetchAPI().post(`/HybridChat/getAnalyticsOverView`, data);
};

const getAgentRankingLiveChat = async (data: getAgentRanking) => {
	return await DataFetchAPI().get(
		`/HybridChat/agentRanking?start=${data.start}&end=${data.end}&agentId=${data.agentId}&tenantId=${data.tenantId}&factorName=${data.factorName}`
	);
};

const getAllAgentsLiveChat = async (data: getAgents) => {
	return await DataFetchAPI().get(
		`/HybridChat/getAgents?type=${data.type}&tenantId=${data.tenantId}`
	);
};

const getConversationDataDetails = async (data: getTimeDetails) => {
	return await DataFetchAPI().get(
		`/analytics/bot/${data.botId}/conversation?startTime=${data.startTime}&endTime=${data.endTime}&filter=${data.filter}&page=${data.page}&size=${data.size}`
	);
};
const getFeedbackConvDetails = async (data: getTimeDetails) => {
	return await DataFetchAPI().get(
		`/analytics/bot/${data.botId}/feedback?startTime=${data.startTime}&endTime=${data.endTime}&filter=${data.filter}&page=${data.page}&size=${data.size}`
	);
};


const getChatDetails = async (data: conversationID) => {
	return await DataFetchAPI(false, true, 'Bearer').get(
		`/messages/bot/${data.botId}/conversation/${data.conversationID}`
	);
};

const analyticService = {
	MiniCardData,
	DialogAnalytics,
	AnalyticsTopQueries,
	getconversationTableData,
	getCompletionPointofDialog,
	getHighestDropOffRate,
	getconversationChatData,
	getUserResponseAction,
	getBotCount,
	getRealtimeAnalyticsLiveChat,
	getOverviewLiveChat,
	getAgentRankingLiveChat,
	getAllAgentsLiveChat,
	DashboardAnalytics,
	getConversationDataDetails,
	getChatDetails,
	getFeedbackConvDetails
};

export default analyticService;

