import React, { useState } from 'react';
import Checkbox from '../../../../Components/Checkbox';
import { DragIndicatorOutlined } from '@mui/icons-material';
import { RootState, useAppDispatch } from '../../../../app/store';
import { useSelector } from 'react-redux';
import {
	setBookAptCheckList,
	setCheckList,
	setRatCheckList,
	setliveChatcheckList
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import Input from '../../../../Components/Input';
import AutoComplete from '../../../../Components/AutoComplete';
import './DragToReorderList.scss';
import DragToReorder from './DragReorder';

interface Item {
	sequence: string;
	title: string;
	data: any;
}

interface DragAndDropState {
	draggedFrom: number | null;
	draggedTo: number | null;
	isDragging: boolean;
	originalOrder: Item[];
	updatedOrder: Item[];
}

const initialDnDState: DragAndDropState = {
	draggedFrom: null,
	draggedTo: null,
	isDragging: false,
	originalOrder: [],
	updatedOrder: [],
};
type Props = {
	getStateF: Function;
	getStateSetter: Function;
	items: Item[];
	negation: number;
	// setter: React.Dispatch<
	// 	React.SetStateAction<
	// 		{
	// 			sequence: string;
	// 			title: string;
	// 			data: any;
	// 		}[]
	// 	>
	// >;
	from: string;
};
const DragToReorderList = ({
	getStateF,
	getStateSetter,
	items,
	negation,
	// setter,
	from,
}: Props) => {
	const [showDrop, setShowDrop] = useState(new Array(items.length).fill(false));
	const {
		checkList,
		taskTypesDropdown,
		bookAptCheckList,
		ratCheckList,
		taskTypes,
		liveChatcheckList
	} = useSelector((state: RootState) => state.experienceonweb);
	const [list, setList] = useState(
		from === 'generateLeads'
			? checkList
			: from === 'bookApt'
				? bookAptCheckList
				: from === 'rat'
					? ratCheckList
					: from == 'liveChatleads' ?
						liveChatcheckList
						:
						[]

	);
	const dispatch = useAppDispatch();
	const [dragAndDrop, setDragAndDrop] =
		useState<DragAndDropState>(initialDnDState);

	const onDragStart = (
		event: React.DragEvent<HTMLLIElement>,
		position: number
	) => {
		event.dataTransfer.setData('text/html', '');
		setDragAndDrop({
			...dragAndDrop,
			draggedFrom: position,
			isDragging: true,
			originalOrder: list,
		});
	};

	const onDragOver = (
		event: React.DragEvent<HTMLLIElement>,
		position: number
	) => {
		event.preventDefault();
		let newList = dragAndDrop.originalOrder;
		const draggedFrom = dragAndDrop.draggedFrom;
		const draggedTo = position;
		const itemDragged = newList[draggedFrom!];
		const remainingItems = newList.filter(
			(item, index) => index !== draggedFrom
		);
		newList = [
			...remainingItems.slice(0, draggedTo),
			itemDragged,
			...remainingItems.slice(draggedTo),
		];
		if (draggedTo !== dragAndDrop.draggedTo) {
			setDragAndDrop({
				...dragAndDrop,
				updatedOrder: newList,
				draggedTo: draggedTo,
			});
		}
	};

	const onDrop = () => {
		setList(dragAndDrop.updatedOrder);
		if (from === 'generateLeads') {
			dispatch(setCheckList(dragAndDrop.updatedOrder));
		} else if (from === 'bookApt') {
			dispatch(setBookAptCheckList(dragAndDrop.updatedOrder));
		} else if (from === 'rat') {
			dispatch(setRatCheckList(dragAndDrop.updatedOrder));
		}
		else if (from == 'liveChatleads') {
			dispatch(setliveChatcheckList(dragAndDrop.updatedOrder))
		}

		setDragAndDrop({
			...dragAndDrop,
			draggedFrom: null,
			draggedTo: null,
			isDragging: false,
		});
	};

	const onDragLeave = () => {
		setDragAndDrop({
			...dragAndDrop,
			draggedTo: null,
		});
	};

	React.useEffect(() => {
		if (from === 'generateLeads') {
			dispatch(setCheckList(list));
		} else if (from === 'bookApt') {
			dispatch(setBookAptCheckList(list));
		} else if (from === 'rat') {
			dispatch(setRatCheckList(list));
		}
		else if (from == 'liveChatleads') {
			dispatch(setliveChatcheckList(list));
		}

	}, [list]);

	React.useEffect(() => {
		let list = [...items];
		setList(list);

		if (items.length === negation) {
		} else {
			let drops = [...showDrop, false];
			setShowDrop(drops);
		}
	}, [items]);

	return (
		<section className='ff-drag-feature'>
			<ul>
				{list.map((item, index) => (
					<div key={index + `${item.sequence}`}>
						<li
							style={{
								display: 'flex',
								cursor: 'move',
								alignItems: item.title.indexOf('Custom') > -1 ? '' : 'center',
								justifyContent:
									item.title.indexOf('Custom') > -1 ? 'space-between' : '',
								flexDirection:
									item.title.indexOf('Custom') > -1 ? 'column' : 'row',
							}}
							key={index + `${item.sequence}`}
							data-position={index}
							draggable
							onDragStart={(e) => onDragStart(e, index)}
							onDragOver={(e) => onDragOver(e, index)}
							onDrop={onDrop}
							onDragLeave={onDragLeave}
							className={dragAndDrop.draggedTo === index ? 'dropArea' : ''}>
							{item.title.indexOf('Custom') > -1 ? (
								<>
									{/* <p>{index}</p> */}
									<div
										className='custom-title'
										style={{ marginBottom: showDrop[index] ? '18px' : '' }}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
											}}>
											<DragIndicatorOutlined
												style={{ color: 'var(--black-40)', marginRight: '4px' }}
											/>
											{showDrop[index] ? (
												<svg
													style={{ cursor: 'pointer', marginRight: '10px' }}
													onClick={() => {
														let v = [...showDrop];
														v[index] = false;
														setShowDrop(v);
													}}
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'>
													<path
														data-name='Path 7'
														d='M0 0h24v24H0z'
														fill='none'
														transform='rotate(90 12 12)'
													/>
													<path
														data-name='Path 8'
														d='M1.41 0 0 1.41 4.58 6 0 10.59 1.41 12l6-6z'
														transform='rotate(90 4.705 13.295)'
														fill='rgba(0,0,0,.6)'
													/>
												</svg>
											) : (
												<svg
													style={{ cursor: 'pointer', marginRight: '10px' }}
													onClick={() => {
														let v = [...showDrop];
														v[index] = true;
														setShowDrop(v);
													}}
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'>
													<path
														data-name='Path 7'
														d='M0 0h24v24H0z'
														fill='none'
													/>
													<path
														data-name='Path 8'
														d='M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'
														fill='rgba(0,0,0,.6)'
													/>
												</svg>
											)}
											<p
												className='body1'
												style={{
													cursor: 'pointer',
													fontWeight: showDrop[index] ? 'bold' : 'normal',
												}}
												onClick={() => {
													let v = [...showDrop];
													v[index] = !v[index];
													setShowDrop(v);
												}}>
												{item.title +
													`${!showDrop[index] && item.data.fieldName
														? ': ' + item.data.fieldName
														: ''
													}`}
											</p>
										</div>
										<svg
											style={{ cursor: 'pointer' }}
											onClick={() => {
												let v = [...list];
												v.splice(index, 1);
												setList(v);

												// setter(v);
											}}
											xmlns='http://www.w3.org/2000/svg'
											data-name='Group 32536'
											width='24'
											height='24'
											viewBox='0 0 24 24'>
											<defs>
												<clipPath id='zlgduw9o1a'>
													<path
														data-name='Rectangle 15483'
														fill='rgba(0,0,0,.6)'
														d='M0 0h14v16H0z'
													/>
												</clipPath>
											</defs>
											<g data-name='Group 31699'>
												<path
													data-name='Path 14985'
													d='M4.325 0v1.709H0v1.928h14V1.709H9.668V0z'
													fill='rgba(0,0,0,.6)'
													transform='translate(5 4)'
												/>
												<g data-name='Group 31700'>
													<g
														data-name='Group 31699'
														clipPath='url(#zlgduw9o1a)'
														transform='translate(5 4)'>
														<path
															data-name='Path 14986'
															d='m20.842 79.607.011.112a2.306 2.306 0 0 0 2.294 2.081H28.4a2.306 2.306 0 0 0 2.294-2.071l.961-9.406H19.894zm8.676-7.361-.744 7.283a.379.379 0 0 1-.376.34h-5.25a.379.379 0 0 1-.375-.327l-.746-7.3z'
															transform='translate(-18.614 -65.796)'
															fill='rgba(0,0,0,.6)'
														/>
													</g>
												</g>
											</g>
										</svg>
									</div>

									{showDrop[index] ? (
										<>
											<div className='custom-data-entry'>
												<div
													className='input-with-dropdown'
													style={{ display: 'flex', alignItems: 'center' }}>
													<Input
														label={'Field name'}
														className='fieldName'
														style={{
															marginBottom: '19px',
															marginRight: '12px',
														}}
														value={item.data.fieldName}
														onChange={(e) => {
															let regex = taskTypes.filter(
																(v) => v.name.toLowerCase() === 'text'
															)[0].validation;
															let validator = new RegExp(regex, 'i');

															const updatedList = list.map((itemData, idx) => {
																if (idx === index) {
																	return {
																		...itemData,
																		data: {
																			...itemData.data,
																			fieldName: validator.test(e.target.value)
																				? e.target.value
																				: '',
																		},
																	};
																}
																return itemData;
															});
															setList(updatedList);
														}}
													/>
													<AutoComplete
														multiple={false}
														label='Field Type'
														options={taskTypesDropdown}
														valueKey='value'
														labelKey='label'
														value={item.data.fieldType}
														onChange={(val) => {
															const updatedList = list.map((itemData, idx) => {
																if (idx === index) {
																	return {
																		...itemData,
																		data: {
																			...itemData.data,
																			fieldType: val,
																		},
																	};
																}
																return itemData;
															});
															setList(updatedList);
														}}
													/>
												</div>
												<div style={{ paddingRight: '8px' }}>
													<Input
														label={'Description (optional)'}
														className='description'
														fullWidth
														value={item.data.desc}
														onChange={(e) => {
															const updatedList = list.map((itemData, idx) => {
																if (idx === index) {
																	return {
																		...itemData,
																		data: {
																			...itemData.data,
																			desc: e.target.value,
																		},
																	};
																}
																return itemData;
															});
															setList(updatedList);
															if (from === 'generateLeads') {
																dispatch(setCheckList(updatedList));
															} else if (from === 'bookApt') {
																dispatch(setBookAptCheckList(updatedList));
															} else if (from === 'rat') {
																dispatch(setRatCheckList(updatedList));
															}
															else if (from === 'liveChatleads') {
																dispatch(setliveChatcheckList(updatedList));
															}

														}}
													/>
												</div>
											</div>
											{item.data.fieldType.label
												.toLowerCase()
												.indexOf('choice') > -1 ||
												item.data.fieldType.label
													.toLowerCase()
													.indexOf('select') > -1 ||
												item.data.fieldType.label
													.toLowerCase()
													.indexOf('drop down') > -1 ? (
												<>
													<p
														className='caption'
														style={{
															fontWeight: 600,
															color: 'var(--violet-600)',
															marginTop: '24px',
															marginLeft: '62px',
															marginBottom: '16px',
														}}>
														Options
													</p>
													<DragToReorder
														items={item.data.options}
														indexNumber={index}
														list={list}
														setList={setList}
														from={from}
													/>
												</>
											) : null}
										</>
									) : null}
								</>
							) : (
								<>
									{/* <p>{index}</p> */}

									<DragIndicatorOutlined
										style={{ color: 'var(--black-40)', marginRight: '4px' }}
									/>
									<Checkbox
										label={item.title}
										index={0}
										isChecked={getStateF(item.title)}
										onValueChange={(val) => getStateSetter(item.title, val)}
									/>
								</>
							)}
						</li>
						<hr
							style={{
								backgroundColor: 'var(--black-20)',
								height: '1px',
								margin: '23.5px 0',
							}}
						/>
					</div>
				))}
			</ul>
		</section>
	);
};

export default DragToReorderList;
