interface Route {
  title: string;
  path: string;
  role:
  | 'chat'
  | 'user managemnt'
  | 'analyst'
  | 'consent management'
  | 'campaign';
}

export interface Routes extends Route {
  childeren?: Route[]
}

const whatsAppBspRoutes: Routes[] = [
  // { title: 'Chats', path: 'chats', role: 'chat' },
  // { title: 'Link', path: 'app/channels/whatsapp/link', role: 'chat' },
  { title: 'Campaigns', path: 'app/channels/whatsapp/campaigns', role: 'campaign' },
  { title: 'Templates', path: 'app/channels/whatsapp/templates', role: 'campaign' },
  { title: 'Contacts', path: 'app/channels/whatsapp/contacts', role: 'consent management' },
  //{ title: 'Contact imports', path: 'app/channels/whatsapp/contact-imports', role: 'consent management' },

 // { title: 'Analytics', path: 'app/channels/whatsapp/analytics', role: 'analyst' },
];

export default whatsAppBspRoutes;
