import React, { useEffect, useState } from 'react';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';

import {
	resetShopifyUnlinkLoader,
	unlinkstore,
} from '../../../../../Features/Shopify/shopifySlice';
import {
	set,
	setSnack,
	setSnackModal,
} from '../../../../../Features/message/messageSlice';
import { RootState, useAppDispatch } from '../../../../../app/store';
import {
	crmsLinked,
	linkedStatus,
	resetShopifyData,
} from '../../../../../Features/teamsetting/teamsettingSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LinkShopifyPopup = () => {
	const navigate = useNavigate();
	const popupdescription = 'Unlink your team from this Shopify Store?';
	const dispatch = useAppDispatch();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { shopifyUnlinkLoader } = useSelector(
		(state: RootState) => state.shopify
	);
	const [buttonLoader, setButtonLoader] = useState(false);

	useEffect(() => {
		if (shopifyUnlinkLoader === 'success') {
			// dispatch(
			// 	setSnack({ message: 'Shopify store unlinked', testcase: 'positive' })
			// );
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: 'Shopify store unlinked',
				})
			);
			setButtonLoader(false);
			dispatch(crmsLinked({ tenantId: tenantId! }));
			dispatch(linkedStatus(tenantId));
			dispatch(resetShopifyData());
			navigate(-1);
		} else if (shopifyUnlinkLoader === 'failed') {
			// dispatch(
			// 	setSnack({
			// 		message:
			// 			'Unable to unlink account at the moment. Please try after sometime!!!',
			// 		testcase: 'negative',
			// 	})
			// );
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage:
						'Unable to unlink account at the moment. Please try after sometime!!!',
					modalMode: 'negative',
				})
			);
			setButtonLoader(false);
			navigate(-1);
		} else if (shopifyUnlinkLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetShopifyUnlinkLoader());
	}, [shopifyUnlinkLoader]);

	return (
		<Modal
			header={popupdescription}
			actionButtons={
				<>
					<div
						style={{
							backgroundColor: '#fff',
							width: '75px',
							height: '30px',
							cursor: 'pointer',
							marginRight: '70px',
						}}
						onClick={() => {
							dispatch(
								unlinkstore({
									tenantId: tenantId!,
									embeddings: 'true',
								})
							);
						}}></div>
					<Button
						buttonType='light'
						onClick={() => {
							navigate(-1);
						}}>
						Cancel
					</Button>

					<Button
						loading={buttonLoader}
						onClick={() => {
							dispatch(
								unlinkstore({
									tenantId: tenantId!,
									embeddings: 'false',
								})
							);
						}}>
						Unlink
					</Button>
				</>
			}>
			<div className='modal-body-popup'>
				You will no longer be able to sell items from this store on your
				Storefronts or Ads. Any existing Storefronts or Ads using this
				integration will be affected.
			</div>
		</Modal>
	);
};

export default LinkShopifyPopup;
