import React, { useState, useEffect } from 'react';
import Modal from '../../../Components/Modal';
import Input from '../../../Components/Input';
import TextBlock from '../../../Components/TextBlock';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import './AddAIToWebsite.scss';
import { setSnackModal } from '../../../Features/message/messageSlice';
import {
	addOriginURL,
	getOriginData,
	resetOriginAddSuccess,
	resetOriginConflict,
	setWebsiteOrigin,
} from '../../../Features/channel/channelSlice';
import { useNavigate } from 'react-router-dom';
import { loadState } from '../../../utils/utils';

const AddAIToWebsite = () => {
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const urlPattern =
		/^(?:(https?):\/\/)(?:[\w-]+\.)+[a-z]{2,}(?::\d{1,5})?(?:\/[^\s]*)*(?:\?[^\s#]*)?(?:#[^\s]*)?$/i;

	const { websiteOrigin, originAddSuccess, originConflict } = useSelector(
		(state: RootState) => state.channel
	);
	const [errorURL, setErrorURL] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (originAddSuccess === 'success') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: `AI enabled on ${websiteOrigin}`,
				})
			);
			dispatch(resetOriginAddSuccess());
			dispatch(
				getOriginData({
					tenantId: tenantId!,
				})
			);
			navigate(-1);
		} else if (originAddSuccess === 'failed') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage:
						originConflict.length > 0
							? `AI is already enabled on ${websiteOrigin}`
							: 'Something Went Wrong!!! Please try again',
					modalMode: 'negative',
				})
			);
			dispatch(resetOriginAddSuccess());
			dispatch(resetOriginConflict());
		}
	}, [originAddSuccess]);

	useEffect(() => {
		return () => {
			dispatch(setWebsiteOrigin(''));
		};
	}, []);

	const copyToClipboard = (text: string) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				dispatch(
					setSnackModal({
						openModal: true,
						modalMesssage: 'Code copied',
					})
				);
			})
			.catch(() => {
				dispatch(
					setSnackModal({
						openModal: true,
						modalMesssage: 'Failed to copy the code. Please try again!!!',
					})
				);
			});
	};

	const webHTMLCode = `<script>
	function initFlyfishChatSDK() {
	var flyfishSDKconfig = {
	botID: "${loadState('botId')}",	
	SF_BASE_URL: "${window.location.origin}",	
	tenantID:${tenantId},
	SF_CHAT_URL: "${window.location.host}",
	version: 'prod',
	source: "webs",
    };	
    var sdk = new FlyfishChatSDK(flyfishSDKconfig);
		sdk.init();
    }
</script>
<script src="${
		window.location.origin
	}/Popup/chatsdk/v1/SenseforthChatSDKLoader.js"></script>`;

	const webIframeHTMLCode = `<iframe src='${
		window.location.origin
	}/Fullscreen/responsive.html?botID=${loadState(
		'botId'
	)}&tenantID=${tenantId}'></iframe>`;

	return (
		<div className='ff-add-ai-to-website'>
			<Modal
				header='Add the AI to your a website'
				headerCloseBtn
				actionButtons={
					<Button
						loading={originAddSuccess === 'pending'}
						disabled={websiteOrigin.length === 0}
						onClick={() => {
							if (!urlPattern.test(websiteOrigin)) {
								setErrorURL(true);
							} else {
								if (websiteOrigin.indexOf('https') === -1) {
									setErrorURL(true);
								} else {
									setErrorURL(false);

									dispatch(
										addOriginURL({
											origins: [websiteOrigin],
											tenantId: tenantId!,
										})
									);
								}
							}
						}}>
						Done
					</Button>
				}>
				<p className='body1 origin'>
					Please provide the URL (
					<a
						className='body1'
						style={{ color: 'var(--violet-600)' }}
						href='https://developer.mozilla.org/en-US/docs/Glossary/Origin'
						target='_blank'
						rel='noreferrer'>
						origin
					</a>
					) of the website on which you would like to enable the Flyfish AI.
				</p>
				<Input
					value={websiteOrigin}
					onPaste={(e) => {
						e.preventDefault();
						dispatch(setWebsiteOrigin(e.clipboardData.getData('text').trim()));
					}}
					error={errorURL}
					onKeyDown={() => setErrorURL(false)}
					errorText={
						websiteOrigin.indexOf('https') === -1
							? 'Please enter an HTTPS URL'
							: 'Please enter a valid URL'
					}
					onChange={(e) => {
						dispatch(setWebsiteOrigin(e.target.value));
					}}
					required
					fullWidth
					placeholder='Website URL'
					helperText='e.g. https://example.com'
				/>
				<p className='body1 ai-type'>
					Please choose how you would like to add the AI to your website:
				</p>
				<h6>Add as a Pop-up Widget</h6>
				<p className='body1 pop-up'>
					Add this code to your website's HTML code to add the Flyfish AI as a
					widget on your Website.
				</p>
				<TextBlock
					text={webHTMLCode}
					horizontalScroll
					textStyle='body-mono'
					height={166}
				/>
				<Button
					buttonType='light'
					onClick={() => {
						copyToClipboard(webHTMLCode);
					}}>
					Copy code
				</Button>

				<h6>Add as Full-Screen Experience</h6>
				<p className='body1 fullscreen'>
					Add the following iframe code to your website for a full screen chat
					experience.
				</p>
				<TextBlock
					text={webIframeHTMLCode}
					horizontalScroll
					textStyle='body-mono'
				/>
				<div>
					<Button
						buttonType='light'
						onClick={() => {
							copyToClipboard(webIframeHTMLCode);
						}}>
						Copy code
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default AddAIToWebsite;
