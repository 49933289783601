import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import {
	AnalyticsThumbsDown,
	AnalyticsThumbsDownFilled,
	AnalyticsThumbsUp,
	AnalyticsThumbsUpFilled,
	DeliveryIcon,
} from '../../../../assets/Icons';
import { metadata } from '../../../../Features/SalesAI/model';
import { setMsgsArray } from '../../../../Features/SalesAI/salesSlice';
import AddtoCart from './ShopifyProducts/AddtoCart';
import Properties from './ShopifyProducts/AddtoCart/Properties';
import ViewCart from './ShopifyProducts/ViewCart';
import './WAChatBubble.scss';
import WAMultichoice from './WAMultichoice';
import {
	getCurrentTime,
	getMediaType,
	loadState,
	loadStateSession,
	saveState,
	saveStateSession,
} from '../../../../utils/utils';
import GetInTouch from './GetInTouch';
import MultiChoice from '../../../../Components/ChatComponents/MultiChoice';
import { Carousel } from 'react-responsive-carousel';
import { carouselButton } from '../../../Configure/Appearance/GenericChatBot/CarouselComp';
import Cards from '../../../../Components/ChatComponents/Cards';
import { Content } from '../../../Chats/model';

type Props = {
	timeStamp: string;
	message: string | React.ReactNode;
	msgtype: string | 'user' | 'assistant' | 'server' | 'Agent';
	metadata: metadata[] | any;
	conversationId?: any;
	noOfTimeCalled?: any;
	feedback?: any;
	channel?: any;
};
const WAChatBubble = (props: Props) => {
	const {
		msgtype,
		metadata,
		timeStamp,
		conversationId,
		noOfTimeCalled,
		feedback,
		channel,
	} = props;
	var { message } = props;
	const dispatch = useDispatch();
	const { productDetails, msgsArray } = useSelector(
		(state: RootState) => state.sales
	);

	const getCheckout = () => {
		dispatch(
			setMsgsArray([
				...msgsArray,
				{ content: 'Make Payment', role: 'user' },
				{
					content: 'The payment is successful, thank you for shopping with us!',
					role: 'assistant',
				},
			])
		);
	};

	useEffect(() => {
		if (msgtype === 'user') {
			saveState('userResponse', message);
		} else if (msgtype === 'assitant') {
			let chatInput = loadState('userResponse');
			if (metadata.form) {
				if (loadStateSession('FF-formCount') === undefined) {
					saveStateSession('FF-formCount', '0');
				} else {
					let val = parseInt(loadStateSession('FF-formCount')) + 1;
					saveStateSession('FF-formCount', `${val}`);
				}
				message =
					message +
					`WORKFLOW-${chatInput}-${parseInt(loadStateSession('FF-formCount'))}`;
			}
		}
	}, []);
	const loadForms = (msg: any) => {
		const cssPtag = {
			outline: '1px solid rgba(128, 128, 128, 0.3)',
			borderRadius: '16px',
			padding: '10px',
			borderTopLeftRadius: 0,
			marginBottom: '20px',
		};

		let obj: any = [];
		metadata.products &&
			metadata.products.map((item: any, index: number) => {
				return obj.push({
					resourceURL: item?.media_link ?? '',
					title: item.title,
					price: item.price,
					description: '',
					cardIndex: index.toString(),
					productId: item.product_id,
					currency: item.currency,
					resourceType: item?.media_type ?? '',
				});
			});
		let proudctList = {
			title: '',
			items: obj,
		};
		let imageLinks: any = [];
		metadata.image_links &&
			metadata.image_links.map((item: any, index: number) => {
				return imageLinks.push({
					resourceURL: item,
					title: '',
					cardIndex: index.toString(),
					cardLayout: '8',
					resourceType: 'image',
				});
			});
		let imageList = {
			title: '',
			items: imageLinks,
		};

		if (
			metadata.image_links &&
			(!metadata.products ||
				(metadata.products && metadata.products.length == 0))
		) {
			if (imageLinks) {
				return (
					<>
						<MultiChoice isRichElement={true} lists={imageList} />
					</>
				);
			}
		}
		if (metadata.products && metadata.products.length > 0) {
			return (
				<>
					{/* <MultiChoice isRichElement={true} lists={proudctList} /> */}
					<div className='analytics-transcript-carousel'>
						<Carousel
							centerMode
							centerSlidePercentage={93}
							showArrows={true}
							showIndicators
							showStatus={false}
							showThumbs={false}
							selectedItem={0}
							width='85%'
							renderArrowPrev={(clickHandler, hasPrev) =>
								hasPrev && (
									<div
										onClick={() => {
											clickHandler();
										}}
									>
										{carouselButton({
											rotate: true,
											buttonBckColor: '#fff',
											buttonTextColor: '#000',
											className: 'multichoice-prev',
										})}
									</div>
								)
							}
							renderArrowNext={(clickHandler, hasNext) =>
								hasNext && (
									<div
										onClick={() => {
											clickHandler();
										}}
									>
										{carouselButton({
											buttonBckColor: '#fff',
											buttonTextColor: '#000',
											className: 'multichoice-next',
										})}
									</div>
								)
							}
							autoFocus
						>
							{proudctList.items.map((card: Content, i: number) => {
								return (
									<div
										className='carousel-slider-list-item'
										key={card.title + i}
									>
										<Cards
											cardType='multichoice'
											cardMediaType={getMediaType(card.resourceType)}
											title={card.title}
											src={card.resourceURL}
											alt={card.title}
											description={card.description}
										/>
									</div>
								);
							})}
						</Carousel>
					</div>
				</>
			);
		}
		if (metadata.form) {
			return (
				<>
					<GetInTouch
						WorkflowData={metadata.form}
						colorMode={'dark'}
						token={loadState('token')}
						botId={loadState('botId')}
						tenantId={loadState('tenantId')}
						conversationId={conversationId}
						addUserResponse={msg}
						// currentWorkflow={currentWorkflow}
						// changeScreen={''}
						// disableMandatory={true}
						EbotId={loadState('botId')}
						cssPtag={cssPtag}
						noOfTimeCalled={noOfTimeCalled}
					/>
				</>
			);
		}
		// }
	};

	const loadSubmittedData = (data: any) => {
		return (
			<div className='ff-form-parent' style={{ minWidth: '245px' }}>
				{Object.keys(data).map((innerAttr, index) => {
					return (
						<div className='ff-form-content'>
							<div>{innerAttr}</div>
							<div className='ff-form-input'>{data[innerAttr]}</div>
						</div>
					);
				})}
			</div>
		);
	};
	return (
		<div className='ff-WAChatBubble'>
			{(msgtype === 'user' || msgtype === 'assistant') &&
			((message && message.toString().length > 0) || metadata) ? (
				msgtype === 'assistant' && metadata ? (
					(metadata.form || metadata.products || metadata.image_links) &&
					msgtype === 'assistant' ? (
						<>
							<div className={`message-bubble server`}>
								<div
									className='body2 message'
									dangerouslySetInnerHTML={{
										__html: message?.toString() || '',
									}}
								/>
								<>
									{metadata.form ||
									metadata.products ||
									metadata.image_links ? (
										<>{loadForms(message)}</>
									) : null}
								</>
								{channel !== 'web' && (
									<div className='time-stamp-bubble'>
										{getCurrentTime(timeStamp)}
									</div>
								)}
							</div>
							{channel === 'web' && (
								<div className={`time-stamp-bubble user-time-stamp-load`}>
									<>
										{feedback === null ? (
											<div className='thumbs-analytics-chat'>
												<span>
													<AnalyticsThumbsUp />
												</span>
												<span>
													<AnalyticsThumbsDown />
												</span>
											</div>
										) : feedback.toLowerCase() === 'yes' ? (
											<div className='thumbs-analytics-chat'>
												<span>
													<AnalyticsThumbsUpFilled />
												</span>
												<span>
													<AnalyticsThumbsDown />
												</span>
											</div>
										) : (
											<div className='thumbs-analytics-chat'>
												<span>
													<AnalyticsThumbsUp />
												</span>
												<span>
													<AnalyticsThumbsDownFilled />
												</span>
											</div>
										)}
									</>

									<>{getCurrentTime(timeStamp)}</>
								</div>
							)}
						</>
					) : (
						<WAMultichoice
							message={message}
							metadata={metadata}
							timeStamp={getCurrentTime(timeStamp)}
						/>
					)
				) : message === 'add-to-cart' ? (
					<AddtoCart
						data={productDetails}
						timeStamp={getCurrentTime(timeStamp)}
					/>
				) : message === 'view-cart' ? (
					<ViewCart
						data={productDetails}
						timeStamp={getCurrentTime(timeStamp)}
					/>
				) : message === 'add-size' ? (
					<Properties
						data={productDetails}
						timeStamp={getCurrentTime(timeStamp)}
						message={message}
					/>
				) : message === 'add-color' ? (
					<Properties
						data={productDetails}
						timeStamp={getCurrentTime(timeStamp)}
						message={message}
					/>
				) : message === 'add-quantity' ? (
					<Properties
						data={productDetails}
						timeStamp={getCurrentTime(timeStamp)}
						message={message}
					/>
				) : message === 'make-payment' ? (
					<div className='make-payment-bubble'>
						<div className='text-container'>
							<p className='payment-text'>Please make the payment</p>
							<p className='timeline caption-2'>{getCurrentTime(timeStamp)}</p>
						</div>
						<button
							className='payment-button'
							onClick={() => {
								getCheckout();
							}}
						>
							<p className='button-text body1'>Make Payment</p>
						</button>
					</div>
				) : (
					<>
						<div
							className={`message-bubble ${
								msgtype === 'user' ? 'user' : 'server'
							}`}
						>
							<div
								className='body2 message'
								dangerouslySetInnerHTML={{ __html: message?.toString() || '' }}
							/>
							<>
								{metadata.form && msgtype === 'user'
									? loadSubmittedData(metadata.form)
									: null}
							</>
							{channel !== 'web' && (
								<div className='time-stamp-bubble'>
									{getCurrentTime(timeStamp)}{' '}
									{msgtype === 'user' || channel !== 'web' ? (
										<DeliveryIcon />
									) : null}
								</div>
							)}
						</div>
						{channel === 'web' && (
							<div
								className={`time-stamp-bubble ${
									msgtype === 'user' ? '' : 'user-time-stamp-load'
								}`}
							>
								{msgtype === 'user' || channel !== 'web' ? null : (
									<>
										{feedback === null ? (
											<div className='thumbs-analytics-chat'>
												<span>
													<AnalyticsThumbsUp />
												</span>
												<span>
													<AnalyticsThumbsDown />
												</span>
											</div>
										) : feedback.toLowerCase() === 'yes' ? (
											<div className='thumbs-analytics-chat'>
												<span>
													<AnalyticsThumbsUpFilled />
												</span>
												<span>
													<AnalyticsThumbsDown />
												</span>
											</div>
										) : (
											<div className='thumbs-analytics-chat'>
												<span>
													<AnalyticsThumbsUp />
												</span>
												<span>
													<AnalyticsThumbsDownFilled />
												</span>
											</div>
										)}
									</>
								)}

								<>
									{getCurrentTime(timeStamp)}{' '}
									{msgtype === 'user' || channel !== 'web' ? (
										<DeliveryIcon />
									) : null}
								</>
							</div>
						)}
					</>
				)
			) : null}

			{msgtype.toLowerCase() === 'agent' &&
			message &&
			message.toString().length > 0 ? (
				<div className={`message-bubble ${'server'}`}>
					<div
						className='body2 message'
						dangerouslySetInnerHTML={{ __html: message?.toString() || '' }}
					/>
					<div className='time-stamp-bubble'>{getCurrentTime(timeStamp)}</div>
				</div>
			) : null}
		</div>
	);
};

export default WAChatBubble;
