import './Chip.scss';

type ChipProps = {
	label: string;
	icon?: JSX.Element;
	code?: string | 'positive' | 'negative' | 'slightly-negative' | 'neutral';
	position?: 'left' | 'right';
};

const getColorCode = (code: string) => {
	switch (code) {
		case 'positive':
			return { color: 'var(--violet-600)', border: 'var(--violet-200)' };
		case 'negative':
			return {
				color: 'var(--vibrant-pink-400)',
				border: 'var(--vibrant-pink-200)',
			};
		case 'slightly-negative':
			return { color: 'var(--gold-500)', border: 'var(--gold-400)' };
		case 'neutral':
			return { color: 'var(--black-60)', border: 'rgba(0, 0, 0, 0.2)' };
		default:
			return { color: 'var(--black)', border: 'var(--black)' };
	}
};

const Chip = ({ label, icon, code, position }: ChipProps) => {
	return (
		<>
			{!Boolean(code) ? (
				//before chip (original)
				//if the icon is on right side - padding should be 0 2 0 4 ,
				//padding is 0 4 0 2 if the position is left
				<div
					className={`ff-chip ${
						Boolean(position?.length)
							? position === 'left'
								? 'ff-padding-for-icon-left'
								: 'ff-padding-for-icon-right'
							: ''
					} ${icon ? 'ff-padding-for-icon-left' : ''}`}>
					{position === 'left' || !Boolean(position?.length) ? icon : ''}
					<span className='overline-2'>{label}</span>
					{position === 'right' ? icon : ''}
				</div>
			) : (
				<div
					className={`ff-chip ff-chip-colored ${
						Boolean(position?.length)
							? position === 'left'
								? 'ff-padding-for-icon-left'
								: 'ff-padding-for-icon-right'
							: ''
					} ${icon ? 'ff-padding-for-icon-left' : ''}`}
					style={{
						border: `1px solid ${code && getColorCode(code).border}`,
						color: code && getColorCode(code).color,
					}}>
					{position === 'left' || !Boolean(position?.length) ? icon : ''}
					<span
						className='overline-2'
						style={{ color: code && getColorCode(code).color }}>
						{label}
					</span>
					{position === 'right' ? icon : ''}
				</div>
			)}
		</>
	);
};

export default Chip;
