import InteractiveButton from '../Button/InteractiveButton';
import './List.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

type ListProps = {
	items: ListItemGroup[];
	width?: number;
};

export type ListItemGroup = Item[];

export type Item = {
	icon?: React.ReactNode;
	text: string;
	moreOptions?: React.ReactNode;
	route?: string;
	onClick?: () => void;
};

const List = ({ items, width }: ListProps) => {
	const location = useLocation();
	const path = location.pathname;
	let pathArr = path.split('/');
	const navigate = useNavigate();
	return (
		<div className='ff-list' style={{ minWidth: width ?? 'none' }}>
			{items.map((group, index) => {
				return (
					<>
						<span className='list-group'>
							{group.map((item) => {
								return (
									<span
										className={`list-item ${
											item.route && pathArr.indexOf(item.route) > -1
												? 'active'
												: ''
										} ${item.moreOptions || item.icon ? 'moreoptions' : ''}`}
										onClick={() => item.route && navigate(item.route)}>
										{item.icon && <div className='list-icon'>{item.icon}</div>}
										<div className='list-text body2'>{item.text}</div>

										{item.moreOptions && (
											<div className='more-options'>
												<Tooltip title='More options'>
													<span>{item.moreOptions}</span>
												</Tooltip>
											</div>
										)}
									</span>
								);
							})}
						</span>
						{items.length > 1 && items.length - 1 !== index && (
							<div className='list-spacer'></div>
						)}
					</>
				);
			})}
		</div>
	);
};
export default List;
