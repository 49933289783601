import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import Realtime from "./Realtime";
import './LiveChats.scss'
import OverView from "./Overview";
import Agentranking from "./AgentRanking";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";

function LiveChat(){
    const { selectedBot } = useSelector((state: RootState) => state.bots);
    const {
		selectedDates,
        optionSelected,
		conversationChatData,
		calendarstatus,
		activeAnalyticsBot,
	} = useSelector((state: RootState) => state.analytics);
    
   
    return(
    <div className="container-main">
        <div className="container">
        <Outlet />
        </div>
   </div>
    )
    
}

export default LiveChat