import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import ErrorIcon from '@mui/icons-material/Error';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../app/store';
import {
	getMedia,
	uploadMedia,
} from '../../../../../Features/WhatsApp-BSP/campaign/campaignSlice';
import Input from '../../../../../Components/Input';
import AutoComplete from '../../../../../Components/AutoComplete';

type PrevMedia = {
	mediaId: string;
	mediaName: string;
};

const SelectMediaModal = ({
	setSelectMedia,
	language,
	setHeaderData,
	setMediaID,
}: any) => {
	const [prevMedia, setPrevMedia] = useState<PrevMedia | null>(null);
	const [prevMediaError, setPrevMediaError] = useState<string | undefined>(
		undefined
	);
	const [fileName, setFileName] = useState<string>('');
	// const [Base64string, setBase64string] = useState<string | null>(null);
	const [fileError, setFileError] = useState('');
	const [url, setUrl] = useState<string>('');
	const [urlError, setUrlError] = useState<string | undefined>(undefined);
	const [addBtnDisabled, setaddBtnDisabled] = useState(true);
	const [uploadType, setUploadType] = useState<string | null>(null);
	const [mediaData, setMediaData] = useState<string | PrevMedia | null>(null);
	const { wabaNo } = useSelector((state: RootState) => state.BSPauth);
	const {
		mediaList,
		totalMediaCount,
		isMediaLoading,
		isMediaUploading,
		uploadedFileId,
		uploadErrorMsg,
	} = useSelector((state: RootState) => state.campaign);
	const dispatch = useAppDispatch();

	const setMedia = () => {
		dispatch(
			getMedia({
				mediaName: '',
				mediaType: `${language.headerType}`,
				offset: 0,
				mediaId: uploadedFileId,
				wabaNo,
			})
		);
	};

	useEffect(() => {
		setMedia();
	}, []);

	useEffect(() => {
		if (uploadType === 'Previous document') {
			setMedia();
		}
	}, [language, uploadType]);

	useEffect(() => {
		if (uploadedFileId) {
			setMediaData(uploadedFileId);
			setMediaID(uploadedFileId);
		}
	}, [uploadedFileId]);
	useEffect(() => {
		if (!prevMediaError && !fileError && !urlError && mediaData) {
			setaddBtnDisabled(false);
		} else {
			setaddBtnDisabled(true);
		}
	}, [prevMediaError, fileError, urlError, mediaData]);

	const validatePrevMedia = (_prevMedia: string | null) => {
		if (_prevMedia && _prevMedia.length > 0) {
			if (
				mediaList.filter((media) => media.mediaName === _prevMedia).length > 0
			) {
				setPrevMediaError(undefined);
				return;
			}
		}
		setPrevMediaError('Required');
	};

	const validateUrl = () => {
		if (url) {
			if (url.split(':')[0] === 'https') {
				setUrlError(undefined);
				setMediaData(url);
				setMediaID(url);
				return;
			} else {
				setUrlError('Please provide valid url(https)');
				return;
			}
		}
		setUrlError('Required');
	};

	const handleSelectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		let files: any = (event.target as HTMLInputElement).files;
		if (files[0]) {
			let { name, size } = files[0];
			if (size <= 16777216) {
				setFileName(name);
				setFileError('');
				let fileReader = new FileReader();
				fileReader.readAsDataURL(files[0]);
				fileReader.onload = () => {
					const filebase64Stream = fileReader.result?.toString().split(',')[1];
					const contentType = fileReader?.result
						?.toString()
						.split(';')[0]
						.replace('data:', '');
					if (filebase64Stream && contentType) {
						dispatch(
							uploadMedia({
								fileName: name,
								contentType,
								filebase64Stream,
								wabaNo,
							})
						);
					}
				};
			} else if (size > 16777216) {
				setFileError('Your file must be lesser than 16MB in size');
			}
		}
	};

	const onClickAdd = () => {
		if (uploadType === 'Previous document') {
			setHeaderData(prevMedia?.mediaName);
		} else {
			url ? setHeaderData(url) : setHeaderData(fileName);
		}
		setSelectMedia(false);
	};

	return (
		<Modal
			header='Add document'
			onClose={() => setSelectMedia(false)}
			actionButtons={
				<>
					<Button
						buttonType='light'
						onClick={() => {
							setSelectMedia(false);
						}}>
						<p className='button-1'>Close</p>
					</Button>
					<Button
						disabled={addBtnDisabled}
						onClick={onClickAdd}
						loading={isMediaUploading}>
						<p className='button-1'>Add</p>
					</Button>
				</>
			}>
			<RadioGroup
				aria-labelledby='radio-buttons-group-label'
				name='buttons-group'
				value={uploadType}
				onChange={(event) => {
					setUploadType(event.target.value);
					setPrevMedia(null);
					setPrevMediaError(undefined);
					setUrl('');
					setUrlError(undefined);
					setFileName('');
					setMediaData(null);
				}}>
				{Boolean(mediaList && mediaList.length) && (
					<FormControlLabel
						value='Previous document'
						control={<Radio />}
						label='Select a previously uploaded media'
					/>
				)}
				{uploadType === 'Previous document' && (
					<div className='input-group media-input'>
						<AutoComplete
							label='Select*'
							options={mediaList}
							labelKey='mediaName'
							value={prevMedia}
							valueKey='mediaName'
							onChange={(value) => {
								setPrevMedia(value);
								value && setMediaData(value.mediaId);
								value && setMediaID(value.mediaId);
							}}
							error={Boolean(prevMediaError)}
							helperText={prevMediaError}
							onBlur={validatePrevMedia}
							isLoading={isMediaLoading}
						/>
					</div>
				)}
				<FormControlLabel value='Upload' control={<Radio />} label='Upload' />
				{uploadType === 'Upload' && (
					<>
						<div className='body-1 blue-text upload-file media-input'>
							<input
								className='input-file'
								accept={
									language?.headerType?.toLocaleLowerCase() === 'image'
										? '.jpeg, .png'
										: language?.headerType?.toLocaleLowerCase() === 'video'
										? '.mp4, .3gp'
										: language?.headerType?.toLocaleLowerCase() === 'audio'
										? '.ogg, .mp4'
										: language?.headerType?.toLocaleLowerCase() === 'document'
										? '.pdf, .txt, .xlsx, .docx, .docm, .doc, .dotx, .xml, .odt, .xps, .rtf'
										: ''
								}
								id='files'
								type='file'
								onChange={(event) => handleSelectedFile(event)}
							/>
							{!fileName && <label htmlFor='files'>Select file</label>}
							{fileName && (
								<div className='body-1'>
									<span>{fileName}</span> |{' '}
									<label htmlFor='files' className='blue-text'>
										change
									</label>
								</div>
							)}
							{Boolean(fileError.length) && (
								<Box
									component={'span'}
									sx={{
										display: 'flex',
										gap: '4px',
										alignItems: 'center',
										color: '#D16900',
									}}>
									<ErrorIcon sx={{ fontSize: '16px' }} />
									<span className='caption'>{fileError}</span>
								</Box>
							)}
						</div>
					</>
				)}
				<FormControlLabel
					value='Provide URL'
					control={<Radio />}
					label='Provide URL'
				/>
				{uploadType === 'Provide URL' && (
					<div className='input-group media-input'>
						<Input
							label=''
							fullWidth
							placeholder='Enter URL*'
							value={url}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								setUrl(event.target.value)
							}
							error={Boolean(urlError)}
							helperText={urlError}
							onBlur={validateUrl}
						/>
					</div>
				)}
			</RadioGroup>
		</Modal>
	);
};

export default SelectMediaModal;
