import {
	MenuItemProps,
	PopperPlacementType,
	MenuItem,
	Popper,
	ClickAwayListener,
	Paper,
	MenuList,
	Fade,
} from '@mui/material';
import { useEffect, useState } from 'react';
import './Menu.scss';

export type Options = {
	label: React.ReactNode;
	onClick: () => void;
} & MenuItemProps;

export type MenuProps = {
	anchorEl: HTMLElement | null;
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	options: Array<Options>;
	placement?: PopperPlacementType;
	height?: number | 'auto';
};

const Menu = ({
	anchorEl,
	open,
	setOpen,
	options,
	placement = 'bottom-end',
	height = 'auto',
	...rest
}: MenuProps) => {
	const [element, setElement] = useState(anchorEl);

	useEffect(() => {
		setOpen(Boolean(anchorEl));
		setElement(anchorEl);

		return () => {
			setOpen(false);
			setElement(null);
		};
	}, [anchorEl, setOpen]);

	return (
		<Popper
			{...rest}
			sx={{ zIndex: 2 }}
			open={open}
			anchorEl={anchorEl}
			role={undefined}
			placement={placement}
			modifiers={[
				{
					name: 'offset',
					options: {
						offset: [0, 4],
					},
				},
				{
					name: 'flip',
					enabled: true,
				},
				{
					name: 'preventOverflow',
					options: {
						boundary: 'window',
					},
				},
			]}
			transition>
			{({ TransitionProps, placement }) => (
				<Fade
					{...TransitionProps}
					style={{
						transformOrigin: placement.startsWith('bottom')
							? 'center top'
							: 'center bottom',
					}}>
					<Paper sx={{ maxHeight: height }}>
						<ClickAwayListener
							onClickAway={() => {
								setOpen(false || anchorEl !== element);
							}}>
							<MenuList>
								{options.map(({ onClick, label, ...rest }, index) => (
									<MenuItem onClick={onClick} {...rest} key={index}>
										<div className='body1'>{label}</div>
									</MenuItem>
								))}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Fade>
			)}
		</Popper>
	);
};

export default Menu;
