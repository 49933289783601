import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import contactService from './contactService';
import {
	ContactListItem,
	GetContactList,
} from '../../../Pages/WhatsAppBSP/ConsentManagement/model';
import { kotakTenantCheckForWhatsapp, loadState } from '../../../utils/utils';

type State = {
	list: ContactListItem[];
	total: number;
	offset: number;
	page: number;
	filter: string;
	isLoading: boolean;
	isScheduledImportsLoading: boolean;
	isInProgressLoading: boolean;
	error: string;
	success: string;
	IsConsentJobUploded: string | null;
	inProgressContactJobsheets: ConsentJob[];
	pastContactJobsheets: ConsentJob[];
	scheduledContactJobsheets: ConsentJob[];
	totalPastContactJobSheets: number;
	totalScheduledContactJobSheets: number;
	totalInProgressContactJobSheets: number;
};

type GetList = {
	consentList: ContactListItem[];
	totalCount: number;
};

type ConsentUserData = {
	channel: string;
	consent: string;
	name: string;
	custNo: string;
	consentTime: string;
	wabaNo: string;
};
type ConsentUserNewData = {
	channel: string;
	consent: string;
	name: string;
	mobileNo: string;
	consentTime: string;
	wabaNo: string;
};
type DeleteConsentUserData = {
	mobileNo: string | undefined;
	wabaNo: string;
};
type UploadStatus = {
	status: string;
	jobId: string | number;
	desc: string;
};

export type UploadData = {
	jobs: { description: string; title: string; wabaNo: string };
	file: string | Blob;
};
type getContactsAnalyticsRequest = {
	offset: number;
	wabaNo: string;
	status?: string;
};
export interface ConsentJob {
	jobName: string;
	isParsed: number;
	'start time': string;
	description: string;
	id: number;
	wabaNo: string;
	jobSheet?: string;
	status: string;
}

const INPROGRESS = [
	'xlparseing',
	'xlparsed',
	'execution started',
	'execution completed',
];
const PAST = ['cancelled', 'completed', 'failed'];

export const getList = createAsyncThunk<
	GetList,
	GetContactList,
	{ rejectValue: string }
>('contact/getList', async (data, { rejectWithValue }) => {
	try {
		const response = await contactService.getList(data);
		if (response.data.consentList) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error: any) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const addContactUser = createAsyncThunk<
	ConsentUserNewData,
	ConsentUserNewData,
	{ rejectValue: string }
>(
	'contact/addContactUser',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await contactService.addContact(data);
			if (response.data.status === 'Consent Accepted') {
				const { contact } = getState() as { contact: State };
				if (kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)) {
					let updateData: {
						offset: number;
						filter?: string;
						custNo?: number;
						wabaNo: string;
					} = { offset: contact.offset, wabaNo: data.wabaNo };
					if (contact.filter) {
						if (!isNaN(parseInt(contact.filter)))
							updateData.custNo = parseInt(contact.filter);
						else updateData.filter = contact.filter;
					}
					dispatch(getList(updateData));
				} else {
					dispatch(getList({ page: 0, size: 20, wabaNo: data.wabaNo }));
				}
				return data;
			} else if (response.data.status === 'Duplicate user')
				return rejectWithValue(JSON.stringify({ error: 'Duplicate user' }));

			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const editContactUser = createAsyncThunk<
	ConsentUserNewData,
	ConsentUserNewData,
	{ rejectValue: string }
>(
	'contact/editContactUser',
	async (data, { rejectWithValue, getState, dispatch }) => {
		try {
			const response = await contactService.editContact(data);
			if (response.data.status === 'success') {
				const { contact } = getState() as { contact: State };
				let updateData: {
					offset: number;
					filter?: string;
					mobileNo?: number;
					wabaNo: string;
				} = { offset: contact.offset, wabaNo: data.wabaNo };
				if (contact.filter) {
					if (!isNaN(parseInt(contact.filter)))
						updateData.mobileNo = parseInt(contact.filter);
					else updateData.filter = contact.filter;
				}
				dispatch(getList(updateData));
				return data;
			} else if (response.data.status === 'Duplicate user')
				return rejectWithValue(JSON.stringify({ error: 'Duplicate user' }));

			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const deleteContactUser = createAsyncThunk<
	DeleteConsentUserData,
	DeleteConsentUserData,
	{ rejectValue: string }
>(
	'contact/deleteContactUser',
	async (data, { rejectWithValue, getState, dispatch }) => {
		try {
			const response = await contactService.deleteContact(data);
			if (response.data.status === 'success') {
				const { contact } = getState() as { contact: State };
				if (kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)) {
					let updateData: {
						offset: number;
						filter?: string;
						custNo?: number;
						wabaNo: string;
					} = { offset: contact.offset, wabaNo: data.wabaNo };
					if (contact.filter) {
						if (!isNaN(parseInt(contact.filter)))
							updateData.custNo = parseInt(contact.filter);
						else updateData.filter = contact.filter;
					}
					dispatch(getList(updateData));
				} else {
					dispatch(getList({ page: 0, size: 20, wabaNo: data.wabaNo }));
				}
				return data;
			} else if (response.data.status === 'fail')
				return rejectWithValue(JSON.stringify({ error: 'failed' }));

			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const uploadConsentJob = createAsyncThunk<
	UploadStatus,
	UploadData,
	{ rejectValue: string }
>('contact/uploadConsentJob', async (data, { rejectWithValue }) => {
	try {
		const response = await contactService.uploadContacts(data);
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

type responsetypForGetAnalytics = {
	consentJob: ConsentJob[];
	passed: number;
};

export const getContactsAnalytics = createAsyncThunk<
	responsetypForGetAnalytics,
	getContactsAnalyticsRequest,
	{ rejectValue: string }
>('contacts/getContactsAnalytics', async (data, { rejectWithValue }) => {
	try {
		const response = await contactService.getContactsAnalytics({
			...data,
			status: 'completed',
		});
		if (response.data) {
			return response.data ?? [];
		}

		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

type responsetypForContactsAnalyticsForScheduledImports = {
	consentJob: ConsentJob[];
	created: number;
};

export const getContactsAnalyticsForScheduledImports = createAsyncThunk<
	responsetypForContactsAnalyticsForScheduledImports,
	getContactsAnalyticsRequest,
	{ rejectValue: string }
>(
	'contacts/getContactsAnalyticsForScheduledImports',
	async (data, { rejectWithValue }) => {
		try {
			const response = await contactService.getContactsAnalytics({
				...data,
				status: 'created',
			});
			if (response.data) {
				return response.data ?? [];
			}

			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

type responseTypeForInProgress = {
	consentJob: ConsentJob[];
	inprogress: number;
};

export const getContactsAnalyticsForInProgressImports = createAsyncThunk<
	responseTypeForInProgress,
	getContactsAnalyticsRequest,
	{ rejectValue: string }
>(
	'contacts/getContactsAnalyticsForInProgressImports',
	async (data, { rejectWithValue }) => {
		try {
			const response = await contactService.getContactsAnalytics({
				...data,
				status: 'inprogress',
			});
			if (response.data) {
				return response.data ?? [];
			}

			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

const initialState: State = {
	list: [],
	total: 0,
	offset: 0,
	page: 0,
	filter: '',
	isLoading: true,
	isScheduledImportsLoading: false,
	isInProgressLoading: false,
	error: '',
	success: '',
	IsConsentJobUploded: null,
	inProgressContactJobsheets: [],
	pastContactJobsheets: [],
	scheduledContactJobsheets: [],
	totalPastContactJobSheets: 0,
	totalScheduledContactJobSheets: 0,
	totalInProgressContactJobSheets: 0,
};

const contactSlice = createSlice({
	name: 'contact',
	initialState,
	reducers: {
		reset: (state) => {
			state.isLoading = true;
			state.isScheduledImportsLoading = false;
			state.isInProgressLoading = false;
			state.error = '';
			state.success = '';
			state.offset = 0;
			state.page = 0;
			state.total = 0;
			state.filter = '';
			state.list = [];
			state.IsConsentJobUploded = null;
		},
		setOffset: (state, action) => {
			state.offset = action.payload;
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
		setFilter: (state, action) => {
			state.filter = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getList.pending, (state) => {
				state.isLoading = true;
				state.error = '';
				state.success = '';
			})
			.addCase(getList.fulfilled, (state: State, action) => {
				state.list = action.payload.consentList;
				state.total = action.payload.totalCount;
				state.isLoading = false;
			})
			.addCase(getList.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.message;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(addContactUser.pending, (state) => {
				state.error = '';
				state.success = '';
			})
			.addCase(addContactUser.fulfilled, (state: State, action) => {
				let user: ContactListItem = {
					mobileNo: action.payload.mobileNo,
					name: action.payload.name,
					channel: action.payload.channel,
					consentTime: action.payload.consentTime,
					status: action.payload.consent,
				};
				state.list = [...state.list, user];
				state.success = 'Contact added';
			})
			.addCase(addContactUser.rejected, (state, action) => {
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.message;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(editContactUser.pending, (state) => {
				state.error = '';
				state.success = '';
			})
			.addCase(editContactUser.fulfilled, (state: State, action) => {
				let user: ContactListItem = {
					mobileNo: action.payload.mobileNo,
					name: action.payload.name,
					channel: action.payload.channel,
					consentTime: action.payload.consentTime,
					status: action.payload.consent,
				};
				state.list = state.list.map((consentUser) =>
					consentUser.mobileNo === user.mobileNo ? user : consentUser
				);
				state.success = 'Contact updated';
			})
			.addCase(editContactUser.rejected, (state, action) => {})
			.addCase(deleteContactUser.pending, (state) => {
				state.error = '';
				state.success = '';
			})
			.addCase(deleteContactUser.fulfilled, (state: State, action) => {
				state.success = 'Contact deleted';
			})
			.addCase(deleteContactUser.rejected, (state, action) => {
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.message;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(uploadConsentJob.pending, (state) => {
				state.IsConsentJobUploded = null;
			})
			.addCase(uploadConsentJob.fulfilled, (state: State, action) => {
				if (action.payload.status === 'success') {
					state.IsConsentJobUploded = 'success';
				}
				if (action.payload.status !== 'success') {
					state.IsConsentJobUploded = 'Upload failed';
				}
			})
			.addCase(uploadConsentJob.rejected, (state, action) => {
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.IsConsentJobUploded = error.message;
				} else {
					state.IsConsentJobUploded = 'Something went wrong! Try again later';
				}
			})
			.addCase(getContactsAnalytics.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getContactsAnalytics.fulfilled, (state, action) => {
				state.isLoading = false;
				state.pastContactJobsheets = action.payload?.consentJob
					? action.payload?.consentJob
					: [];
				state.totalPastContactJobSheets = action.payload.passed;
			})
			.addCase(getContactsAnalytics.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})

			.addCase(getContactsAnalyticsForScheduledImports.pending, (state) => {
				state.isScheduledImportsLoading = true;
			})
			.addCase(
				getContactsAnalyticsForScheduledImports.fulfilled,
				(state, action) => {
					state.isScheduledImportsLoading = false;
					state.scheduledContactJobsheets = action.payload.consentJob
						? action.payload.consentJob
						: [];
					state.totalScheduledContactJobSheets = action.payload.created
						? action.payload.created
						: 0;
					// state.scheduledImports = action.payload
					// state.inProgressContactJobsheets = action.payload?.filter((jobSheet) =>
					// 	INPROGRESS.includes(jobSheet.status.toLocaleLowerCase())
					// );
					// state.pastContactJobsheets = action.payload?.filter((jobSheet) =>
					// 	PAST.includes(jobSheet.status.toLocaleLowerCase())
					// );
				}
			)
			.addCase(
				getContactsAnalyticsForScheduledImports.rejected,
				(state, action) => {
					state.isScheduledImportsLoading = false;
					if (action.payload) {
						let error = JSON.parse(action.payload);
						state.error = error.error;
					} else {
						state.error = 'Something went wrong! Try again later';
					}
				}
			)

			.addCase(getContactsAnalyticsForInProgressImports.pending, (state) => {
				state.isInProgressLoading = true;
			})
			.addCase(
				getContactsAnalyticsForInProgressImports.fulfilled,
				(state, action) => {
					state.isInProgressLoading = false;
					state.inProgressContactJobsheets = action.payload.consentJob
						? action.payload.consentJob
						: [];
					state.totalInProgressContactJobSheets = action.payload.inprogress
						? action.payload.inprogress
						: 0;
					// state.scheduledImports = action.payload
					// state.inProgressContactJobsheets = action.payload?.filter((jobSheet) =>
					// 	INPROGRESS.includes(jobSheet.status.toLocaleLowerCase())
					// );
					// state.pastContactJobsheets = action.payload?.filter((jobSheet) =>
					// 	PAST.includes(jobSheet.status.toLocaleLowerCase())
					// );
				}
			)
			.addCase(
				getContactsAnalyticsForInProgressImports.rejected,
				(state, action) => {
					state.isInProgressLoading = false;
					if (action.payload) {
						let error = JSON.parse(action.payload);
						state.error = error.error;
					} else {
						state.error = 'Something went wrong! Try again later';
					}
				}
			);
	},
});

export const { reset, setOffset, setFilter, setPage } = contactSlice.actions;

export default contactSlice.reducer;
