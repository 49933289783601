import React, { Component, useEffect, useState, useRef } from 'react';
import { TimePicker } from '../Configuration/LiveChat/TimePicker';
import {
	CheckBoxChecked,
	CheckBoxUnChecked,
	RadioButtonChecked,
	RadioButtonUnchecked,
} from '../../assets/Icons/LiveChatConfigIcons/index';

import Button from '../../Components/Button';
import SnackBar from '../../Components/SnackBar';

import { setWeek } from 'date-fns';
type Props = {
	selectedTenantId: number;
	onSave: Function;
	agentAvailabilityList: any;
	availabilityType: string;
};
type State = {
	defaultCheckRadioBtn: boolean;
	customCheckedBtn: boolean;
	weekDays: {
		name: string;
		id: number;
		check: boolean;
		startTime: string;
		endTime: string;
	}[];
	checkSameTime: boolean;
	sameStartTime: string;
	sameEndTime: string;
};

export function AgentAvailability({
	selectedTenantId,
	onSave,
	agentAvailabilityList,
	availabilityType,
}: Props) {
	const [defaultCheckRadioBtn, setdefaultCheckRadioBtn] = useState(
		agentAvailabilityList[0].time.indexOf('24') > -1 ? true : false
	);
	const [customCheckedBtn, setcustomCheckedBtn] = useState(
		agentAvailabilityList[0].time.indexOf('24') > -1 ? false : true
	);
	const [weekDays, setweekDays] = useState({
		weekDays: [
			{
				name: 'Mon',
				id: 0,
				check: false,
				startTime: '09:00 AM',
				endTime: '05:30 PM',
			},
			{
				name: 'Tue',
				id: 1,
				check: false,
				startTime: '09:00 AM',
				endTime: '05:30 PM',
			},
			{
				name: 'Wed',
				id: 2,
				check: false,
				startTime: '09:00 AM',
				endTime: '05:30 PM',
			},
			{
				name: 'Thu',
				id: 3,
				check: false,
				startTime: '09:00 AM',
				endTime: '05:30 PM',
			},
			{
				name: 'Fri',
				id: 4,
				check: false,
				startTime: '09:00 AM',
				endTime: '05:30 PM',
			},
			{
				name: 'Sat',
				id: 5,
				check: false,
				startTime: '09:00 AM',
				endTime: '05:30 PM',
			},
			{
				name: 'Sun',
				id: 6,
				check: false,
				startTime: '09:00 AM',
				endTime: '05:30 PM',
			},
		],
	});
	const [checkSameTime, setcheckSameTime] = useState(false);
	const [sameStartTime, setsameStartTime] = useState('');
	const [sameEndTime, setsameEndTime] = useState('');
	const [selectedDays, setSelectedDays] = useState<{ [key: string]: any }>([]);
	const previousValue = useRef({
		weekDays: [{ name: '', id: 0, check: false, startTime: '', endTime: '' }],
	});
	useEffect(() => {
		previousValue.current = weekDays;
	}, [weekDays]);
	useEffect(() => {
		if (agentAvailabilityList) {
			agentAvailabilityList.map((data: any, i: number) => {
				setweekDays({
					weekDays: previousValue.current.weekDays.map((obj) => {
						return obj.name === data.day
							? Object.assign(obj, {
									check: !obj.check,
									startTime: data.time.split('-')[0],
									endTime: data.time.split('-')[1],
							  })
							: obj;
					}),
				});
			});
		}
		// if (availabilityType) {
		// 	if (availabilityType === 'custom') {
		// 		setcustomCheckedBtn(true);
		// 		setdefaultCheckRadioBtn(false);
		// 	} else {
		// 		setcustomCheckedBtn(false);
		// 		setdefaultCheckRadioBtn(true);
		// 	}
		// }
	}, []);

	const fetchDaysChecked = () => {
		let days: any = [];
		let sameStartTime: any = [];
		let sameEndTime: any = [];
		let select: any = [];
		weekDays.weekDays.forEach((data, index) => {
			if (data.check == true) {
				select.push({
					// indexOfDay: data.id,
					days: data.name,
					time: `${data.startTime}-${data.endTime} `,
				});
			}
		});
		//setSelectedDays(select)

		if (checkSameTime) {
			weekDays.weekDays.forEach((data, index) => {
				if (data.check === true) {
					days.push(data.name);
					sameStartTime.push(sameStartTime);
					sameEndTime.push(sameEndTime);
				}
			});
		} else {
			weekDays.weekDays.forEach((data, index) => {
				if (data.check === true) {
					days.push(data.name);
					sameStartTime.push(data.startTime);
					sameEndTime.push(data.endTime);
				}
			});
		}
		return select;
	};
	const saveAgentAvailability = () => {
		let type;
		let val;
		let data;
		if (defaultCheckRadioBtn) {
			type = 'default';
			val = '';
		} else {
			type = 'custom';
			val = fetchDaysChecked();
		}
		if (defaultCheckRadioBtn) {
			data = [{ indexOfDay: 8, time: '24 Hrs', days: 'Mon-Sun' }];
		} else {
			data = {
				type: type,
				weekDays: val,
			};
		}

		onSave(data);
	};

	return (
		<div className='agent-availablity-wrapper'>
			<div className='main-wrapper default-wrapper'>
				<div
					onClick={() => {
						setdefaultCheckRadioBtn(true);
						setcustomCheckedBtn(false);
					}}>
					{defaultCheckRadioBtn ? (
						<RadioButtonChecked />
					) : (
						<RadioButtonUnchecked />
					)}
				</div>

				<div className='availability-timmings'>24/7</div>
			</div>
			<div className='custom-wrapper main-wrapper'>
				<div className='custom-sub-wrapper'>
					<div
						onClick={() => {
							setdefaultCheckRadioBtn(false);
							setcustomCheckedBtn(true);
						}}>
						{customCheckedBtn ? (
							<RadioButtonChecked />
						) : (
							<RadioButtonUnchecked />
						)}
					</div>

					<div className='availability-timmings'>Custom</div>
				</div>
				<div
					className={`custom-sub-sec-wrapper ${
						customCheckedBtn === false ? 'disable-data' : ''
					}`}>
					<div style={{ lineHeight: '2.99', position: 'relative' }}>
						{weekDays.weekDays.map((data, index) => {
							return (
								<div key={index} className='week-days'>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}>
										<div
											onClick={() => {
												setweekDays({
													weekDays: previousValue.current.weekDays.map(
														(obj) => {
															return obj.id === index
																? Object.assign(obj, { check: !data.check })
																: obj;
														}
													),
												});
											}}>
											{data.check ? <CheckBoxChecked /> : <CheckBoxUnChecked />}
										</div>
										<div style={{ marginLeft: '7px' }}>{data.name}</div>
									</div>

									{checkSameTime === false && data.check && (
										<div
											style={{ display: 'flex', alignItems: 'center' }}
											className='test'>
											<TimePicker
												is24HoursFormat={false}
												onChange={(selectedTime: string) => {
													setweekDays({
														weekDays: previousValue.current.weekDays.map(
															(obj) => {
																return obj.id === index
																	? Object.assign(obj, {
																			startTime: selectedTime,
																	  })
																	: obj;
															}
														),
													});
													setsameStartTime(selectedTime);
												}}
												selectedTime={
													agentAvailabilityList.find(
														(e: any) => e.day === data.name
													)
														? agentAvailabilityList
																.find((e: any) => e.day === data.name)
																.time.split('-')[0]
														: '09:00 AM'
												}
											/>
											<span>&nbsp;-&nbsp;</span>
											<TimePicker
												is24HoursFormat={false}
												onChange={(selectedTime: string) => {
													setweekDays({
														weekDays: previousValue.current.weekDays.map(
															(obj) => {
																return obj.id === index
																	? Object.assign(obj, {
																			endTime: selectedTime,
																	  })
																	: obj;
															}
														),
													});
													setsameEndTime(selectedTime);
												}}
												selectedTime={
													agentAvailabilityList.find(
														(e: any) => e.day === data.name
													)
														? agentAvailabilityList
																.find((e: any) => e.day === data.name)
																.time.split('-')[1]
														: '05:30 PM'
												}
											/>
										</div>
									)}
								</div>
							);
						})}
						<div>
							{checkSameTime && (
								<div
									style={
										checkSameTime
											? { position: 'absolute', right: '0', top: '0' }
											: {}
									}>
									<div className='selected-time-wrapper'>
										<TimePicker
											is24HoursFormat={false}
											onChange={(selectedTime: string) => {
												setsameStartTime(selectedTime);
											}}
											selectedTime={sameStartTime}
										/>
										<span>&nbsp;-&nbsp;</span>
										<TimePicker
											is24HoursFormat={false}
											onChange={(selectedTime: string) => {
												setsameEndTime(selectedTime);
											}}
											selectedTime={sameEndTime}
										/>
									</div>
								</div>
							)}
							<div
								className='same-timmings-wrapper'
								style={checkSameTime ? { marginTop: '-14px' } : {}}>
								<div
									onClick={() => {
										setcheckSameTime(!checkSameTime);
										fetchDaysChecked();
									}}
									style={{ width: '20px' }}>
									{checkSameTime ? <CheckBoxChecked /> : <CheckBoxUnChecked />}
								</div>
								<div className='same-timmings'>Same timings on all days</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='save-wrapper save-agent-wrapper'>
				<Button
					size='large'
					style={{ marginBottom: '15px' }}
					onClick={saveAgentAvailability}
					disabled={
						defaultCheckRadioBtn === true ||
						weekDays.weekDays.filter((each) => each.check === true).length > 0
							? false
							: true
					}>
					Done
				</Button>
			</div>
		</div>
	);
}
