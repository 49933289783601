import React, { useEffect, useState, useRef } from 'react';
import './Subscription.scss';
import Button from '../../Components/Button';
import { useNavigate } from 'react-router';
import Save from '../../assets/images/save-offer.png';
import Tick from '../../assets/images/Tick.png';
import Cross from '../../assets/images/Nil.png';
import Header from '../Header/Header';
import EnterpriseContact from './EnterpriseContact';
import { RootState, useAppDispatch } from '../../app/store';
import { useSelector } from 'react-redux';
import {
	setBillingDuration,
	setSelectedCurrency,
	setSelectedPlan,
	setShowPaymentStatus,
	subscriptionEnquiry,
	subscriptionStatusCheck,
} from '../../Features/Subscription/subscriptionSlice';
import { loadState, saveState } from '../../utils/utils';
import { Navigate, useLocation } from 'react-router-dom';
import TrialContact from './TrialContact';

import { ArrowBackIosNew } from '@mui/icons-material';

const Subscription = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const premiumRef = useRef<HTMLParagraphElement>(null);
	const [height, setHeight] = useState('');
	const {
		selectedPlan,
		showEnterprise,
		billingDuration,
		subscriptionStatus,
		planName,
		organizationName,
		availableCurrency,
		selectedCurreny,
		showFreeTrial,
		susbcriptionEnquiryLoader,
		susbcriptionEnquiryLoaderTrial,
	} = useSelector((state: RootState) => state.subscription);
	const [showCurrency, setShowCurrency] = useState(false);
	const { navigateToIngest } = useSelector((state: RootState) => state.teams);
	const { user } = useSelector((state: RootState) => state.auth);
	const location = useLocation();
	const state: any = location.state;

	const baseURLPicker = (currency: string, plan_type: string) => {
		if (
			window.location.origin.indexOf('preprod.flyfish.ai') > -1 ||
			window.location.origin.indexOf('dev.flyfish.ai') > -1 ||
			window.location.origin.indexOf('uat.flyfish.ai') > -1 ||
			window.location.origin.indexOf('localhost:3000') > -1
		) {
			if (billingDuration === 'monthly') {
				if (loadState('shopifyShopName')) {
					//for free trail
					if (plan_type === 'trial') {
						return '006';
					}
					//for starter monthly
					if (plan_type === 'starter') {
						return '011';
					}
					//for advance monthly
					if (plan_type === 'advance') {
						return '007';
					}
					//for premium monthly
					if (plan_type === 'premium') {
						return '008';
					}
				} else {
					//for trial monthly
					if (plan_type === 'trial') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b71a2a84730d4307956a731e35e1d19e23/006';
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/9d35bbd18f9574d69ed776fe8aee593491dc2335d5de7070dbde1ccf1c7a189d/006';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b74a04b4b1d192f2a5ad0208f919135dd0/006';
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b732b813e9f5406fbe4b7fef0c8fc57c86/006';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7053e4415f26e2a75e42b10dac1c492f5/006';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7f1563ff4b9261e8dd2790ea3b7ff5531/006';
						}
					}
					//for starter monthly
					if (plan_type === 'starter') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7615009875f9e2e815e9cd4301e2cdaa1/013'; //wrong amount & currency
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/9d35bbd18f9574d69ed776fe8aee593491dc2335d5de7070dbde1ccf1c7a189d/011';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7e7298e3f865c380bc3e49eda1d6d58a0/013'; //wrong amount & currency
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7e7298e3f865c380bc3e49eda1d6d58a0/013';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b71815ffbabc94db0cf5dbf5f47a65200b/013';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7a11cae17e046440f8ee43c28dd4a706d/013';
						}
					}
					//for advance monthly
					if (plan_type === 'advance') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7bbb56581b9472c08484f929af890a040/007';
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/9d35bbd18f9574d69ed776fe8aee593491dc2335d5de7070dbde1ccf1c7a189d/002';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7708869c5f1612edab44b378f458fd618/007';
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b746e05fdaee713dd5693de75ee5b526e2/007';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b75d8550f60dffc31cf874c791cf898aba/007';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7c09169ead6ec9a6e16bc8e53fede1909/007';
						}
					}
					//for premium monthly
					if (plan_type === 'premium') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/9d35bbd18f9574d69ed776fe8aee593491dc2335d5de7070dbde1ccf1c7a189d/008'; //wrong amount & currency
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/9d35bbd18f9574d69ed776fe8aee593491dc2335d5de7070dbde1ccf1c7a189d/003';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7f637c56bdc6ca87832febb85eb82f6eb/008'; //wrong amount & currency
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b700527326d515cb5decb10e8b4477d9c6/008';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b79a2bdde47d3baedc76d16351dae3403e/008';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b73bb695a162d50ce534f3c2765b9cac02/008';
						}
					}
				}
			}
			if (billingDuration === 'annual') {
				if (loadState('shopifyShopName')) {
					//for free trail
					if (plan_type === 'trial') {
						return '006';
					}
					//for starter yearly
					if (plan_type === 'starter') {
						return '012';
					}
					//for advance yearly
					if (plan_type === 'advance') {
						return '009';
					}
					//for premium yearly
					if (plan_type === 'premium') {
						return '010';
					}
				} else {
					//for starter yearly
					if (plan_type === 'starter') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b71fde50026d083feccf2c04b69a0e3ce7/014';
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/9d35bbd18f9574d69ed776fe8aee593491dc2335d5de7070dbde1ccf1c7a189d/012';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7f5f2d0feb4d49ebcfb2c13fb3aa31170/014';
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7a3c22dc65d2854f03d46de635612d921/014';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7ff0b2c627067927d103cb67f289eb36e/014';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7623eb00638d7f8aac3ca2a780ad9e6e5/014';
						}
					}
					//for advance yearly
					if (plan_type === 'advance') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7fa7114050470ed04c9da60f92863a87c/009';
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/9d35bbd18f9574d69ed776fe8aee593491dc2335d5de7070dbde1ccf1c7a189d/004';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b74bcf3c75dd7aa16ec896ff73a9ba2bd8/009';
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7fba53a387ec8e46b8f4b8f8fb923abbb/009';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7a3ee889dfabb0f95589bdbeab402ccce/009';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b70934d2355560410629f8bbb574e2e615/009';
						}
					}
					//for premium yearly
					if (plan_type === 'premium') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7f95cb416392a431351b2f8cd59d4a75d/010';
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/9d35bbd18f9574d69ed776fe8aee593491dc2335d5de7070dbde1ccf1c7a189d/005';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b718f2d13529bb2d58fbb1d3f7f6536ef8/010';
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b704af01214bb2dc1abdaa78eb12113375/010';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7befb893554a03715a0afd06a985c6b69/010';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/d6a196be41213470bdf047921526d9b7c2aecd6ec5db2479898dc03f5637cd39/010';
						}
					}
				}
			}
		} else {
			//prod

			if (billingDuration === 'monthly') {
				if (loadState('shopifyShopName')) {
					//for free trail
					if (plan_type === 'trial') {
						return 'FF006';
					}
					//for starter monthly
					if (plan_type === 'starter') {
						return 'FF502';
					}
					//for advance monthly
					if (plan_type === 'advance') {
						return 'FF102';
					}
					//for premium monthly
					if (plan_type === 'premium') {
						return 'FF202';
					}
				} else {
					//for trial monthly
					if (plan_type === 'trial') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c039dab6738062ac74ed57bf9fa459515/FF-Trial-Plan';
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/32f7708be72857ee23e101f00155e1684cf950c1ab70d4d4dbde1ccf1c7a189d/FF-Trial-Plan';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372cd0eca1fc848300a4f6d7cfff76c15699/FF-Trial-Plan';
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372cf3de6db68b4facdc97141e65d93ed3ef/FF-Trial-Plan';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c0d372e1aa673f2b94268cd6fdb943993/FF-Trial-Plan';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372cb0a7fa0dd47248da95403038ac08d336/FF-Trial-Plan';
						}
					}
					//for starter monthly
					if (plan_type === 'starter') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372cabb7fe28dd61299f1337d6a38cdc99da/FF502';
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/32f7708be72857ee23e101f00155e1684cf950c1ab70d4d4dbde1ccf1c7a189d/FF501';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372cb541161382c01bf353b13f865901020e/FF502';
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c572493c71954cccfa1dbf70d74174fda/FF502';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c6048a3d6f3edf84d55d3793e7baeba8f/FF502';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c4eb9e88ff7d7c6abff5e60e377806d1c/FF502';
						}
					}
					//for advance monthly
					if (plan_type === 'advance') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c6aa57e7ce47e6d5c88335ef092a91984/FF102';
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/32f7708be72857ee23e101f00155e1684cf950c1ab70d4d4dbde1ccf1c7a189d/FF101';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372cb9a319b6e8baae100965d00cd6d41c27/FF102';
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c4e55026aad2d1cfeca43d0349ebff484/FF102';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372ccff227eb85caf548b33bf1c6f1be4533/FF102';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c87f5037aaa438e14f6b573eca701f606/FF102';
						}
					}
					//for premium monthly
					if (plan_type === 'premium') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372cf9df48e6be1f21db39e6484bed3f3497/FF202';
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/32f7708be72857ee23e101f00155e1684cf950c1ab70d4d4dbde1ccf1c7a189d/FF201';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c4fe8c4e834d17c268cf2705ccd76fcae/FF202';
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c08552ced625d0ae3c2c17e016595af93/FF202';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372cef49fb97f34495f45b165bb937de7499/FF202';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372cef49fb97f34495f45b165bb937de7499/FF202';
						}
					}
				}
			}
			if (billingDuration === 'annual') {
				if (loadState('shopifyShopName')) {
					//for free trail
					if (plan_type === 'trial') {
						return 'FF006';
					}
					//for starter yearly
					if (plan_type === 'starter') {
						return 'FF602';
					}
					//for advance yearly
					if (plan_type === 'advance') {
						return 'FF302';
					}
					//for premium yearly
					if (plan_type === 'premium') {
						return 'FF402';
					}
				} else {
					//for starter yearly
					if (plan_type === 'starter') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372cd38b657da8743ed5b9a777fecc6e3f0f/FF602';
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/32f7708be72857ee23e101f00155e1684cf950c1ab70d4d4dbde1ccf1c7a189d/FF601';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c7e3e98cd94da962b01148702b1b4614e/FF602';
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c0d4fb730b7fc4b9ce7cfc50096888a5e/FF602';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372ce44264287749dd69694cab2d1b94283a/FF602';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c026f4152e3c746c94262a7dd8307403d/FF602';
						}
					}
					//for advance yearly
					if (plan_type === 'advance') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c7f39166dd73b452fe2d552cef88c5f86/FF302';
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/32f7708be72857ee23e101f00155e1684cf950c1ab70d4d4dbde1ccf1c7a189d/FF301';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c50494a3ec03fd3ff29117bf16cb44caa/FF302';
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372ce79d2f5c491e3f5ed082826c71a62678/FF302';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372ce052b3ddf5f34cacf185552b08fe10e4/FF302';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c8fed2250baaf4b9319ea2ead8375d73b/FF302';
						}
					}
					//for premium yearly
					if (plan_type === 'premium') {
						if (currency === 'usd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c137d68aa631857dce6c1207918b450af/FF402';
						} else if (currency === 'inr') {
							return 'https://subscriptions.zoho.in/subscribe/32f7708be72857ee23e101f00155e1684cf950c1ab70d4d4dbde1ccf1c7a189d/FF401';
						} else if (currency === 'gbp') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372ccac6e8be5bf7b1d92b1c9b4ccc40d5da/FF402';
						} else if (currency === 'eur') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c93e32772926948ee20fd99424838b750/FF402';
						} else if (currency === 'aud') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c086775d493b2a1ab2c502d4a8c4d7ecc/FF402';
						} else if (currency === 'sgd') {
							return 'https://subscriptions.zoho.in/subscribe/9d25ddaaca9734ca051223c7a1ce372c5fad9dcf525aa454a2ab0468284a2ef5/FF402';
						}
					}
				}
			}
		}
		return '';
	};
	const currencySymbolPicker = (currency: string) => {
		if (currency === 'usd') {
			return 'USD ';
		} else if (currency === 'inr') {
			return 'INR ';
		} else if (currency === 'gbp') {
			return 'GBP ';
		} else if (currency === 'eur') {
			return 'EUR ';
		} else if (currency === 'aud') {
			return 'AUD ';
		} else if (currency === 'sgd') {
			return 'SGD ';
		}
	};
	const pricePicker = (plan_type: string, currency: string) => {
		if (billingDuration === 'monthly') {
			if (plan_type === 'advanced') {
				if (currency === 'usd') {
					return '199';
				} else if (currency === 'inr') {
					return '16,449';
				} else if (currency === 'gbp') {
					return '159';
				} else if (currency === 'eur') {
					return '179';
				} else if (currency === 'aud') {
					return '299';
				} else if (currency === 'sgd') {
					return '269';
				}
			} else if (plan_type === 'premium') {
				if (currency === 'usd') {
					return '499';
				} else if (currency === 'inr') {
					return '41,499';
				} else if (currency === 'gbp') {
					return '399';
				} else if (currency === 'eur') {
					return '459';
				} else if (currency === 'aud') {
					return '739';
				} else if (currency === 'sgd') {
					return '669';
				}
			} else if (plan_type === 'starter') {
				if (currency === 'usd') {
					return '99';
				} else if (currency === 'inr') {
					return '8200';
				} else if (currency === 'gbp') {
					return '78';
				} else if (currency === 'eur') {
					return '90';
				} else if (currency === 'aud') {
					return '148';
				} else if (currency === 'sgd') {
					return '130';
				}
			}
		} else {
			if (plan_type === 'advanced') {
				if (currency === 'usd') {
					return '179';
				} else if (currency === 'inr') {
					return '14,859';
				} else if (currency === 'gbp') {
					return '149';
				} else if (currency === 'eur') {
					return '159';
				} else if (currency === 'aud') {
					return '269';
				} else if (currency === 'sgd') {
					return '249';
				}
			} else if (plan_type === 'premium') {
				if (currency === 'usd') {
					return '449';
				} else if (currency === 'inr') {
					return '37,349';
				} else if (currency === 'gbp') {
					return '369';
				} else if (currency === 'eur') {
					return '419';
				} else if (currency === 'aud') {
					return '669';
				} else if (currency === 'sgd') {
					return '599';
				}
			} else if (plan_type === 'starter') {
				if (currency === 'usd') {
					return '90';
				} else if (currency === 'inr') {
					return '7460';
				} else if (currency === 'gbp') {
					return '71';
				} else if (currency === 'eur') {
					return '83';
				} else if (currency === 'aud') {
					return '135';
				} else if (currency === 'sgd') {
					return '120';
				}
			}
		}
	};
	const prodPlans = [
		{
			button_text: 'Get Started',
			plan_header: '14-Day Free Trial',
			plan_subheader: '',
			plan_description:
				'This plan offers a limited-time free trial of our product, allowing you to test out its features before committing to a paid plan.',
			plan_price: '0',
			plan_currency: currencySymbolPicker(selectedCurreny.value.toLowerCase()),
			plan_duration: '',
			base_URL: baseURLPicker(selectedCurreny.value.toLowerCase(), 'trial'),
		},
		{
			button_text: 'Get Started',
			plan_header: 'Starter',
			plan_subheader: '',
			plan_description:
				'Ideal for small businesses and startups aiming to kickstart their digital presence and establish a solid foundation for growth.',
			plan_price: pricePicker('starter', selectedCurreny.value.toLowerCase()),
			plan_currency: currencySymbolPicker(selectedCurreny.value.toLowerCase()),

			plan_duration: billingDuration === 'monthly' ? '/mo' : '/mo',
			base_URL: baseURLPicker(selectedCurreny.value.toLowerCase(), 'starter'),
		},
		{
			button_text: 'Get Started',
			plan_header: 'Advanced',
			plan_subheader: '',
			plan_description:
				'Perfect for emerging businesses that are looking for powerful features to automate sales and increase their topline.',
			plan_price: pricePicker('advanced', selectedCurreny.value.toLowerCase()),
			plan_currency: currencySymbolPicker(selectedCurreny.value.toLowerCase()),

			plan_duration: billingDuration === 'monthly' ? '/mo' : '/mo',
			base_URL: baseURLPicker(selectedCurreny.value.toLowerCase(), 'advance'),
		},
		{
			button_text: 'Get Started',
			plan_header: 'Premium',
			plan_subheader: 'Most Popular',
			plan_description:
				'Our Premium plan delivers top-of-the-line features to help scale your business. Get more customization options and multilingual support.',
			plan_price: pricePicker('premium', selectedCurreny.value.toLowerCase()),
			plan_currency: currencySymbolPicker(selectedCurreny.value.toLowerCase()),

			plan_duration: billingDuration === 'monthly' ? '/mo' : '/mo',
			base_URL: baseURLPicker(selectedCurreny.value.toLowerCase(), 'premium'),
		},
		{
			button_text: 'Get in Touch',
			plan_header: 'Enterprise',
			plan_subheader: 'Flyfish Plus',
			plan_description:
				'Handcrafted for large enterprises that need scale, performance and flexibility to drive digital sales on multiple storefronts.',
			plan_price: 'Custom',
			plan_currency: '',
			plan_duration: billingDuration === 'monthly' ? '/mo' : '/yr',
			base_URL: baseURLPicker(
				selectedCurreny.value.toLowerCase(),
				'enterprise'
			),
		},
	];
	const preprodPlans = [
		{
			button_text: 'Get Started',
			plan_header: '14-Day Free Trial',
			plan_subheader: '',
			plan_description:
				'This plan offers a limited-time free trial of our product, allowing you to test out its features before committing to a paid plan.',
			plan_price: '0',
			plan_currency: currencySymbolPicker(selectedCurreny.value.toLowerCase()),

			plan_duration: '',
			base_URL: baseURLPicker(selectedCurreny.value.toLowerCase(), 'trial'),
		},
		{
			button_text: 'Get Started',
			plan_header: 'Starter',
			plan_subheader: '',
			plan_description:
				'Ideal for small businesses and startups aiming to kickstart their digital presence and establish a solid foundation for growth.',
			plan_price: pricePicker('starter', selectedCurreny.value.toLowerCase()),
			plan_currency: currencySymbolPicker(selectedCurreny.value.toLowerCase()),

			plan_duration: billingDuration === 'monthly' ? '/mo' : '/mo',
			base_URL: baseURLPicker(selectedCurreny.value.toLowerCase(), 'starter'),
		},
		{
			button_text: 'Get Started',
			plan_header: 'Advanced',
			plan_subheader: '',
			plan_description:
				'Perfect for emerging businesses that are looking for powerful features to automate sales and increase their topline.',
			plan_price: pricePicker('advanced', selectedCurreny.value.toLowerCase()),
			plan_currency: currencySymbolPicker(selectedCurreny.value.toLowerCase()),

			plan_duration: billingDuration === 'monthly' ? '/mo' : '/mo',
			base_URL: baseURLPicker(selectedCurreny.value.toLowerCase(), 'advance'),
		},
		{
			button_text: 'Get Started',
			plan_header: 'Premium',
			plan_subheader: 'Most Popular',
			plan_description:
				'Our Premium plan delivers top-of-the-line features to help scale your business. Get more customization options and multilingual support.',
			plan_price: pricePicker('premium', selectedCurreny.value.toLowerCase()),
			plan_currency: currencySymbolPicker(selectedCurreny.value.toLowerCase()),

			plan_duration: billingDuration === 'monthly' ? '/mo' : '/mo',
			base_URL: baseURLPicker(selectedCurreny.value.toLowerCase(), 'premium'),
		},
		{
			button_text: 'Get in Touch',
			plan_header: 'Enterprise',
			plan_subheader: 'Flyfish Plus',
			plan_description:
				'Handcrafted for large enterprises that need scale, performance and flexibility to drive digital sales on multiple storefronts.',
			plan_price: 'Custom',
			plan_currency: '',
			plan_duration: billingDuration === 'monthly' ? '/mo' : '/yr',
			base_URL: baseURLPicker(
				selectedCurreny.value.toLowerCase(),
				'enterprise'
			),
		},
	];
	if (loadState('shopifyShopName')) {
		preprodPlans.pop();
		prodPlans.pop();
	}
	const plansSelected =
		window.location.origin.indexOf('preprod.flyfish.ai') > -1 ||
		window.location.origin.indexOf('dev.flyfish.ai') > -1 ||
		window.location.origin.indexOf('localhost:3000') > -1 ||
		window.location.origin.indexOf('uat.flyfish.ai') > -1
			? [...preprodPlans]
			: [...prodPlans];
	const plansDetail = [...plansSelected];
	const plans = plansDetail.map((v, i) => (i !== 0 ? v : null));
	// useEffect(() => {
	// 	let plans = plansDetail.map((v, i) => (i !== 0 ? v : null));
	// subscriptionStatus === 'expired' ||
	// (subscriptionStatus === 'live' &&
	// 	(planName.toLowerCase().indexOf('free') === -1 ||
	// 		planName.toLowerCase().indexOf('free') > -1))
	// 	? plansDetail.map((v, i) => (i !== 0 ? v : null))
	// 	: plansDetail;
	// setPlans(plans);
	// }, []);
	const addOnPicker = (currency: string) => {
		if (currency === 'usd') {
			return '*USD 99/mo per add-on per month';
		} else if (currency === 'inr') {
			return '*INR 8099/mo per add-on per month';
		} else if (currency === 'gbp') {
			return '*GBP 79/mo per add-on per month';
		} else if (currency === 'eur') {
			return '*EUR 89/mo per add-on per month';
		} else if (currency === 'aud') {
			return '*AUD 149/mo per add-on per month';
		} else if (currency === 'sgd') {
			return '*SGD 139/mo per add-on per month';
		}
	};
	useEffect(() => {
		if (premiumRef.current) {
			setHeight(`${premiumRef.current.offsetHeight}px`);
		}
	}, [premiumRef, height, window.innerWidth]);

	useEffect(() => {
		if (selectedPlan) {
			loadState('parameter') !== ''
				? navigate('/app/signup-payment')
				: navigate('/app/payment', {
						state: { via: state && state.via, planCode: selectedPlan },
				  });
		}
	}, [selectedPlan]);

	useEffect(() => {
		if (
			loadState('parameter') !== '' &&
			loadState('parameter') !== 'notLoggedIn' &&
			loadState('parameter') !== undefined
		) {
			let plan_type_and_currency = loadState('parameter').split('&');
			let plan_type = plan_type_and_currency[0].split('=')[1];
			let plan_currency = 'usd';
			if (plan_type_and_currency.length > 1) {
				plan_currency = plan_type_and_currency[1].split('=')[1];
				if (!plan_currency) {
					plan_currency = 'usd';
				}
			}

			let baseURL: string = '';
			if (plan_type === 'advanced_monthly') {
				baseURL = baseURLPicker(plan_currency.toLowerCase(), 'advance');
			} else if (plan_type === 'advanced_annual') {
				baseURL = baseURLPicker(plan_currency.toLowerCase(), 'advance');
			} else if (plan_type === 'premium_monthly') {
				baseURL = baseURLPicker(plan_currency.toLowerCase(), 'premium');
			} else if (plan_type === 'premium_annual') {
				baseURL = baseURLPicker(plan_currency.toLowerCase(), 'premium');
			} else if (plan_type === 'free') {
				baseURL = baseURLPicker(plan_currency.toLowerCase(), 'trial');
			}

			dispatch(setSelectedPlan(baseURL));
		}
	}, [loadState('parameter'), billingDuration]);

	useEffect(() => {
		dispatch(setShowPaymentStatus(true));
		if (
			loadState('parameter') !== '' &&
			loadState('parameter') !== 'notLoggedIn' &&
			loadState('parameter') !== undefined
		) {
			let plan_type = loadState('parameter').split('&')[0].split('=')[1];
			plan_type.indexOf('monthly') > -1
				? dispatch(setBillingDuration('monthly'))
				: dispatch(setBillingDuration('annual'));
		}
		state &&
			state.via !== 'modify' &&
			dispatch(subscriptionStatusCheck({ tenantId: loadState('tenantId') }));
	}, []);

	useEffect(() => {
		if (navigateToIngest && state && state.via !== 'modify') {
			navigate('/app/Ingest');
		}
	}, [navigateToIngest]);

	const planSelection = (base_URL: string) => {
		dispatch(setSelectedPlan(base_URL));
		saveState('selectedPlan', base_URL);
	};

	const SubscriptionContent = () => {
		return (
			<div
				className='ff-subscription'
				onClick={() => showCurrency && setShowCurrency(false)}>
				<>
					{loadState('parameter') === 'notLoggedIn' ? (
						<Header showheaderUserbadge={false} />
					) : null}

					<div className='ff-subscription-models'>
						{state && state.via === 'modify' ? (
							<Button
								onClick={() => navigate(-1)}
								startIcon={<ArrowBackIosNew style={{ fontSize: '15px' }} />}>
								Back
							</Button>
						) : null}
						<div className='header'>
							<h1
								style={{
									color: '#7a5ce0',
									textAlign: 'center',
									fontSize: subscriptionStatus !== 'expired' ? '64px' : '48px',
								}}>
								{subscriptionStatus !== 'expired'
									? 'Our Flexible Pricing Options' //'Tailored to Fit Your Business'
									: 'Your subscription has ended.'}
							</h1>
							<h1
								style={{
									color: '#7a5ce0',
									textAlign: 'center',
									fontSize: '48px',
								}}>
								{subscriptionStatus !== 'expired'
									? ''
									: 'Choose a plan to continue using Flyfish.'}
							</h1>
						</div>
						<div className='toggle-button'>
							<div>
								<div
									className={`monthly ${
										billingDuration === 'monthly' ? 'active' : ''
									}`}
									onClick={() => dispatch(setBillingDuration('monthly'))}>
									Billed Monthly
								</div>
								<div
									className={`annually ${
										billingDuration === 'annual' ? 'active' : ''
									}`}
									onClick={() => dispatch(setBillingDuration('annual'))}>
									Billed Annually
								</div>
							</div>
						</div>
						<div
							className={`currency-dropdown ${
								loadState('shopifyShopName') ? 'hide' : ''
							}`}
							onClick={() => setShowCurrency(!showCurrency)}>
							<div className='select'>
								<p>{selectedCurreny.value}</p>
								{!showCurrency ? (
									<svg
										// onClick={() => setShowCurrency(true)}
										xmlns='http://www.w3.org/2000/svg'
										width='11'
										height='6'
										viewBox='0 0 11 6'>
										<g
											id='Polygon_1'
											data-name='Polygon 1'
											transform='translate(11 6) rotate(180)'
											fill='#4b42eb'>
											<path
												d='M 9.863381385803223 5.5 L 1.136618375778198 5.5 L 5.5 0.7399472594261169 L 9.863381385803223 5.5 Z'
												stroke='none'
											/>
											<path
												d='M 5.5 1.479883670806885 L 2.273226737976074 5 L 8.726773262023926 5 L 5.5 1.479883670806885 M 5.5 0 L 11 6 L 0 6 L 5.5 0 Z'
												stroke='none'
												fill='#4b42eb'
											/>
										</g>
									</svg>
								) : (
									<svg
										// onClick={() => setShowCurrency(false)}
										xmlns='http://www.w3.org/2000/svg'
										width='11'
										height='6'
										viewBox='0 0 11 6'>
										<g id='Polygon_1' data-name='Polygon 1' fill='#4b42eb'>
											<path
												d='M 9.863381385803223 5.5 L 1.136618375778198 5.5 L 5.5 0.7399472594261169 L 9.863381385803223 5.5 Z'
												stroke='none'
											/>
											<path
												d='M 5.5 1.479883670806885 L 2.273226737976074 5 L 8.726773262023926 5 L 5.5 1.479883670806885 M 5.5 0 L 11 6 L 0 6 L 5.5 0 Z'
												stroke='none'
												fill='#4b42eb'
											/>
										</g>
									</svg>
								)}
							</div>
							{showCurrency ? (
								<div className='values'>
									{availableCurrency.map((curr, i) => (
										<div key={i}>
											{selectedCurreny.value === curr.value ? null : (
												<p onClick={() => dispatch(setSelectedCurrency(curr))}>
													{curr.value}
												</p>
											)}
											{availableCurrency.length - 1 !== i ? (
												<hr
													style={{
														backgroundColor: '#D6D3FC',
														height: '1px',
													}}
												/>
											) : null}
										</div>
									))}
								</div>
							) : null}
						</div>
						<div className='subscriptiontable'>
							<table
								className={`body2 subscriptiontable ${
									subscriptionStatus === 'expired' ? 'expired' : ''
								}`}>
								<thead className='primary'>
									<tr>
										<th className='fix'>
											<h5>Plan</h5>
										</th>
										<th
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<h5>{plans && plans[0]?.plan_header}</h5>
										</th>
										<th className='starter'>
											<h5>{plans && plans[1]?.plan_header}</h5>
										</th>
										<th>
											<h5>{plans && plans[2]?.plan_header}</h5>
											<p
												className='m-popular mb-0'
												style={{ color: '#7a5ce0', marginTop: '8px' }}>
												Most Popular
											</p>
										</th>
										<th>
											<h5>{plans && plans[3]?.plan_header}</h5>
											<p
												className='m-popular mb-0'
												style={{ color: '#7a5ce0', marginTop: '8px' }}>
												Flyfish Plus
											</p>
										</th>
										<th
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<h5>{plans && plans[4]?.plan_header}</h5>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr className='fc-card-table '>
										<td className='fix'></td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<p className='card-text '>
												{plans && plans[0]?.plan_description}
											</p>
										</td>
										<td className='starter'>
											<p className='card-text '>
												{plans && plans[1]?.plan_description}
											</p>
										</td>
										<td>
											<p className='card-text '>
												{plans && plans[2]?.plan_description}
											</p>
										</td>
										<td>
											<p className='card-text '>
												{plans && plans[3]?.plan_description}
											</p>
										</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<p className='card-text '>
												{plans && plans[4]?.plan_description}
											</p>
										</td>
									</tr>
									<tr className='fc-card-table monthly'>
										<td className='fix'>
											<h5>Price</h5>
										</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<h3 className='card-title'>Free</h3>
											<div className='py-3'></div>
										</td>
										<td className='starter'>
											<h3 className='card-title'>
												<span className='price_m_y dollar'>
													{plans[1]?.plan_currency}
												</span>
												{plans[1]?.plan_price}
												<span className='price_m_y cycle'>/mo</span>
											</h3>
											<div className='py-3 save-on-annual'>
												<img
													width='40'
													height='40'
													src={Save}
													className='img-fluid'
													alt=''
												/>
												<p className='body2 sub-heading  text-center ps-2'>
													on annual subscription
												</p>
											</div>
										</td>
										<td>
											<h3 className='card-title'>
												<span className='price_m_y dollar'>
													{plans[2]?.plan_currency}
												</span>
												{plans[2]?.plan_price}
												<span className='price_m_y cycle'>/mo</span>
											</h3>
											<div className='py-3 save-on-annual'>
												<img
													width='40'
													height='40'
													src={Save}
													className='img-fluid'
													alt=''
												/>
												<p className='body2 sub-heading  text-center ps-2'>
													on annual subscription
												</p>
											</div>
										</td>
										<td>
											<h3 className='card-title'>
												<span className='price_m_y dollar'>
													{plans[3]?.plan_currency}
												</span>
												{plans[3]?.plan_price}
												<span className='price_m_y cycle'>/mo</span>
											</h3>
											<div className='py-3 save-on-annual'>
												<img
													width='40'
													height='40'
													src={Save}
													className='img-fluid'
													alt=''
												/>
												<p className='body2 sub-heading  text-center ps-2'>
													on annual subscription
												</p>
											</div>
										</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<h3 className='card-title'>Custom</h3>

											<div className='py-3'>
												<img
													width='40'
													height='40'
													src={Save}
													className='img-fluid'
													alt=''
													style={{ visibility: 'hidden' }}
												/>
												<p
													className='body2 sub-heading  text-center ps-2'
													style={{ marginTop: '16px' }}>
													Contact for Pricing
												</p>
											</div>
										</td>
									</tr>

									<tr className='fc-card-table monthly'>
										<td className='fix'></td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='btn-div'>
												{loadState('shopifyShopName') ? (
													<Button
														// disabled={state && state.via === 'modify'}
														disabled={
															subscriptionStatus !== 'expired' &&
															plans[0] &&
															plans[0].base_URL.indexOf(
																`${loadState('planCode')}`
															) > -1 &&
															loadState('planName') &&
															loadState('planName')
																.toLowerCase()
																.indexOf('free') > -1
														}
														className='btn default-btn plan-type-click'
														onClick={() =>
															plans[0] && planSelection(plans[0].base_URL)
														}>
														GET STARTED
													</Button>
												) : (
													<Button
														className='btn default-btn plan-type-click'
														loading={susbcriptionEnquiryLoaderTrial}
														disabled={
															loadState('mailSentCountfree-trail') > 1 ||
															(state && state.via === 'modify')
														}
														onClick={() => {
															if (
																loadState('mailSentCountfree-trail') ===
																undefined
															) {
																saveState('mailSentCountfree-trail', 0);
															}
															dispatch(
																subscriptionEnquiry({
																	data: {
																		orgName: organizationName ?? 'NA',
																		email: loadState('userEmailId')
																			? loadState('userEmailId')
																			: user
																			? user.userEmail
																			: '',
																		teamName: loadState('teamname') ?? 'NA',
																		subscriptionStatus: planName ?? 'NA',
																		lastActivePlan:
																			subscriptionStatus === 'expired'
																				? ''
																				: 'NA',
																	},
																	mailSubject:
																		'Request for 14-Day Free Trial plan',
																	template: '14DayTrialPlan',
																})
															);
														}}>
														GET IN TOUCH
													</Button>
												)}
											</div>
										</td>
										<td className='starter'>
											<div className='btn-div'>
												<Button
													disabled={
														plans[1] &&
														plans[1].base_URL.indexOf(
															`${loadState('planCode')}`
														) > -1 &&
														loadState('planName') &&
														loadState('planName')
															.toLowerCase()
															.indexOf('starter') > -1
													}
													className='btn default-btn plan-type-click'
													onClick={() =>
														plans[1] && planSelection(plans[1].base_URL)
													}>
													GET STARTED
												</Button>
											</div>
										</td>
										<td>
											<div className='btn-div'>
												<Button
													disabled={
														subscriptionStatus !== 'expired' &&
														plans[2] &&
														plans[2].base_URL.indexOf(
															`${loadState('planCode')}`
														) > -1 &&
														loadState('planName') &&
														loadState('planName')
															.toLowerCase()
															.indexOf('advance') > -1
													}
													className='btn default-btn plan-type-click'
													onClick={() => {
														plans[2] && planSelection(plans[2].base_URL);
													}}>
													GET STARTED
												</Button>
											</div>
										</td>
										<td>
											<div className='btn-div'>
												<Button
													disabled={
														subscriptionStatus !== 'expired' &&
														plans[3] &&
														plans[3].base_URL.indexOf(
															`${loadState('planCode')}`
														) > -1 &&
														loadState('planName') &&
														loadState('planName')
															.toLowerCase()
															.indexOf('premium') > -1
													}
													className='btn default-btn plan-type-click'
													onClick={() => {
														plans[3] && planSelection(plans[3].base_URL);
													}}>
													GET STARTED
												</Button>
											</div>
										</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<div className='btn-div'>
												<Button
													className='btn default-btn plan-type-click'
													loading={susbcriptionEnquiryLoader}
													disabled={loadState('mailSentCount') > 1}
													onClick={() => {
														if (loadState('mailSentCount') === undefined) {
															saveState('mailSentCount', 0);
														}
														dispatch(
															subscriptionEnquiry({
																data: {
																	orgName: organizationName ?? 'NA',
																	email: loadState('userEmailId')
																		? loadState('userEmailId')
																		: user
																		? user.userEmail
																		: '',
																	teamName: loadState('teamname') ?? 'NA',
																	subscriptionStatus: planName ?? 'NA',
																	lastActivePlan:
																		subscriptionStatus === 'expired'
																			? ''
																			: 'NA',
																},
																mailSubject: 'Request for Enterprise plan',
																template: 'Enterprise plan3',
															})
														);
													}}>
													GET IN TOUCH
												</Button>
											</div>
										</td>
									</tr>

									<tr>
										<td className='fix addone-title pb-3'>
											<h5>Features</h5>
										</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}></td>
										<td className='starter'></td>
										<td></td>
										<td></td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}></td>
									</tr>
									<tr>
										<td className='fix'>
											<b>AI Sales Advisor</b>
											<br />
											(E-Commerce and Payments)
										</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td className='starter'>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
									</tr>
									<tr>
										<td className='fix'>
											<b>Pre-Sales AI</b>
											<br /> (Lead Generation and Lead Qualification)
										</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td className='starter'>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
									</tr>
									<tr>
										<td className='fix'>
											<b>After Sales AI</b> <br />
											(Customer Support)
										</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td className='starter'>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
									</tr>
									<tr>
										<td className='fix'>
											Sales Channels
											<br /> (Web, WhatsApp, IG etc.)
										</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td className='starter'>1 Channel</td>
										<td>1 Channel</td>
										<td>3 Channels</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											Custom
										</td>
									</tr>
									<tr>
										<td className='fix'>Consumers Engaged Per Month</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td className='starter'>1000</td>
										<td>2000</td>
										<td>5000</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											Custom
										</td>
									</tr>
									<tr>
										<td className='fix'>Auto Lead Routing</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td className='starter'>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>Email</td>
										<td>CRM or Email</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											CRM or Custom Routing
										</td>
									</tr>
									<tr>
										<td className='fix'>Multi-lingual Experience</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td className='starter'>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
									</tr>
									<tr>
										<td className='fix'>Analytics</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td className='starter'>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>Basic</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											Advanced
										</td>
									</tr>
									<tr>
										<td className='fix'>Role Based Access Control</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td className='starter'>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
									</tr>
									<tr>
										<td className='fix'>Agent Logins</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td className='starter'>1</td>
										<td>1</td>
										<td>3</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											Custom
										</td>
									</tr>
									<tr>
										<td className='fix'>Dedicated Customer Success Manager</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td className='starter'>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
									</tr>
									<tr>
										<td className='fix'>Support</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td className='starter'>2 Tickets per month</td>
										<td>3 Tickets per month</td>
										<td>5 Tickets per month</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											Email + Chat + Call
											<br /> (Unlimited Tickets)
										</td>
									</tr>

									<tr>
										<td className='fix addone-title'>
											<h5>Add-ons*</h5>
										</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}></td>
										<td className='starter'></td>
										<td></td>
										<td></td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}></td>
									</tr>
									<tr>
										<td className='fix'>
											<b>Document Collection</b>
										</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td className='starter'>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
									</tr>
									<tr>
										<td className='fix'>
											<b>Speech</b>
										</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td className='starter'>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
									</tr>
									<tr>
										<td className='fix'>
											<b>Additional Sales Channel Integration</b>
										</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td className='starter'>
											<div className='center-image'>
												<img className='img-fluid' src={Cross} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}>
											<div className='center-image'>
												<img className='img-fluid' src={Tick} alt='' />
											</div>
										</td>
									</tr>
									<tr style={{ borderColor: 'transparent' }}>
										<td className='text-start b-0 fix'>
											<p className='add-on'>
												{addOnPicker(selectedCurreny.value.toLowerCase())}
											</p>
										</td>
										<td
											className={`${subscriptionStatus} ${
												subscriptionStatus === 'live' &&
												(planName.toLowerCase().indexOf('free') === -1 ||
													planName.toLowerCase().indexOf('free') > -1)
													? 'expired'
													: ''
											} ${subscriptionStatus === '' ? 'expired' : ''}`}></td>
										<td className='starter'></td>
										<td></td>
										<td></td>
										<td
											className={`enterprise ${
												loadState('shopifyShopName') ? 'hide' : ''
											}`}></td>
									</tr>
								</tbody>
							</table>
						</div>

						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: '24px',
							}}>
							Already subscribed? Please reach out to us on&nbsp;
							<a
								href='mailto:support@flyfish.ai'
								style={{ color: 'var(--violet-600)' }}>
								support@flyfish.ai
							</a>
							&nbsp;for help with your subscription.
						</div>
					</div>
				</>
				{showEnterprise ? <EnterpriseContact /> : null}
				{showFreeTrial ? <TrialContact /> : null}
			</div>
		);
	};

	return (
		<>
			{loadState('basictoken') ? (
				<>{SubscriptionContent()}</>
			) : (
				<Navigate to='/app/login' replace />
			)}
		</>
	);
};

export default Subscription;
