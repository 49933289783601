import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Shopify from '../../../assets/images/Platform/shopify.png';
import salesforce from '../../../assets/images/Platform/salesforce.png';
import woocomerce from '../../../assets/images/Platform/woocomerce.png';
import adobe from '../../../assets/images/Platform/adobe.png';
import bigcommerce from '../../../assets/images/Platform/bigcommerce.png';
import ONDC from '../../../assets/images/Platform/ONDC.png';
import SAP from '../../../assets/images/Platform/SAP.png';
import '../../Ingest/ingest.scss';
import { RootState, useAppDispatch } from '../../../app/store';
import { getUnreadNotifications } from '../../../Features/Notifications/notificationSlice';
import { loadState } from '../../../utils/utils';

const SelectPlatform = () => {
	const navigate = useNavigate();
	const [activeIndex, setIndex] = useState(-1);
	const dispatch = useAppDispatch();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const data = [
		{
			header: 'Shopify',
			navigate: '/app/Ingest/link-product/link-platform/shopify',
			img: Shopify,
			bg: '#eeffd1',
			enable: 'true',
		},
		{
			header: 'Flyfish Store',
			navigate: '../link-platform/flyfish',
			img: (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					xmlnsXlink='http://www.w3.org/1999/xlink'
					width='60.228'
					height='42.205'
					viewBox='0 0 60.228 42.205'>
					<defs>
						<linearGradient
							id='linear-gradient'
							x1='0.118'
							y1='1'
							x2='0.867'
							y2='-0.111'
							gradientUnits='objectBoundingBox'>
							<stop offset='0' stop-color='#2c43bb' />
							<stop offset='0.808' stop-color='#d03387' />
							<stop offset='1' stop-color='#e46ea5' />
						</linearGradient>
						<clipPath id='clip-path'>
							<path
								id='Path_15562'
								data-name='Path 15562'
								d='M16.091,21.522l9.939-9.939a1.514,1.514,0,0,1,2.131,0L38.1,21.522A1.508,1.508,0,0,1,37.034,24.1H30.867V34.657a1.513,1.513,0,0,1-1.509,1.509H24.832a1.513,1.513,0,0,1-1.509-1.509V24.1H17.156a1.506,1.506,0,0,1-1.066-2.574M9.009,15.807A13.233,13.233,0,0,0,0,28.482v.031A13.41,13.41,0,0,0,13.517,42.2h34.7c14.57-.389,16.492-20.679,2.414-23.894A9.068,9.068,0,0,0,37.156,7.519,14.676,14.676,0,0,0,24.269-.005C16.4-.005,8.63,6.006,9.009,15.807'
								transform='translate(0 0.005)'
								fill='url(#linear-gradient)'
							/>
						</clipPath>
					</defs>
					<g id='Upload_ico' data-name='Upload ico' clip-path='url(#clip-path)'>
						<rect
							id='Rectangle_15791'
							data-name='Rectangle 15791'
							width='64.709'
							height='42.205'
							transform='translate(0 0)'
							fill='url(#linear-gradient)'
						/>
					</g>
				</svg>
			),
			bg: '#DBDDFF',
			enable: 'true',
		},

		{
			header: 'WooCommerce',
			navigate: '../link-platform/woocommerce',
			img: woocomerce,
			bg: '#f3e8ff',
			enable: 'true',
		},
		{
			header: 'Salesforce Commerce Cloud',
			navigate: '',
			img: salesforce,
			bg: '#ddf5ff',
			enable: 'false',
		},
		{
			header: 'Adobe Commerce (Magento)',
			navigate: '',
			img: adobe,
			bg: '#ffe3d5',
			enable: 'false',
		},
		{
			header: 'Big Commerce',
			navigate: '',
			img: bigcommerce,
			bg: '#ededf5',
			enable: 'false',
		},
		{
			header: 'ONDC',
			navigate: '',
			img: ONDC,
			bg: '#ddf5ff',
			enable: 'false',
		},
		{
			header: 'SAP Commerce Cloud',
			navigate: '',
			img: SAP,
			bg: '#ddf5ff',
			enable: 'false',
		},
	];

	useEffect(() => {
		dispatch(
			getUnreadNotifications({
				userId: loadState('user').userID,
				tenantId: tenantId!,
			})
		);
	}, []);

	return (
		<div className='ff-ingest-features-cnt platform'>
			<div className='ff-ingest-container'>
				<div className='ff-ingest-features'>
					{data.map((item, index) => {
						return (
							<div
								key={index}
								className={`ff-ingest-item ${
									item.enable === 'false'
										? 'coming-soon'
										: index === activeIndex
										? 'selected'
										: ''
								}`}
								onClick={() => {
									setIndex(index);
									navigate(data[index].navigate);
								}}
								//style={{ backgroundColor: item.bg }}
								>
								<div className='ff-ingest-item-content'>
									<div>
										<p className='button1'>{item.header}</p>
										<div className='overline-border'>
											<p className='overline'>Coming Soon</p>
										</div>
									</div>
								</div>
								{typeof item.img !== 'string' ? (
									<div style={{ marginRight: '28px', paddingLeft: '7px' }}>
										{item.img}
									</div>
								) : (
									<img src={item.img} alt='category' />
								)}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
export default SelectPlatform;
