export const KampstaUserBadgeBackground = [
	{ color: '#5e63f7', backgroundColor: '#d2d9f5' },
	{
		color: '#cd610a',
		backgroundColor: '#f7ede5',
	},
	{ color: '#cf4848', backgroundColor: '#f8dede' },
	{
		color: '#9a8d18',
		backgroundColor: '#f7f7e6',
	},
	{ color: '#cd610a', backgroundColor: '#f7ede5' },
	{
		color: '#cd610a',
		backgroundColor: '#f7ede5',
	},
	{ color: '#3b5ac6', backgroundColor: '#e5e9f7' },
	{
		color: '#cd1599',
		backgroundColor: '#f7e5f2',
	},
	{ color: '#2f6616', backgroundColor: '#e9f7e5' },
	{
		color: '#137c55',
		backgroundColor: '#e5f8f1',
	},
	{ color: '#5e63f7', backgroundColor: '#d2d9f5' },
	{
		color: '#cd610a',
		backgroundColor: '#f7ede5',
	},
	{ color: '#cf4848', backgroundColor: '#f8dede' },
	{
		color: '#9a8d18',
		backgroundColor: '#f7f7e6',
	},
	{ color: '#cd610a', backgroundColor: '#f7ede5' },
	{
		color: '#cd610a',
		backgroundColor: '#f7ede5',
	},
	{ color: '#3b5ac6', backgroundColor: '#e5e9f7' },
	{
		color: '#cd1599',
		backgroundColor: '#f7e5f2',
	},
	{ color: '#2f6616', backgroundColor: '#e9f7e5' },
	{
		color: '#137c55',
		backgroundColor: '#e5f8f1',
	},
	{ color: '#5e63f7', backgroundColor: '#d2d9f5' },
	{
		color: '#cd610a',
		backgroundColor: '#f7ede5',
	},
	{ color: '#cf4848', backgroundColor: '#f8dede' },
	{
		color: '#9a8d18',
		backgroundColor: '#f7f7e6',
	},
	{ color: '#cd610a', backgroundColor: '#f7ede5' },
	{
		color: '#cd610a',
		backgroundColor: '#f7ede5',
	},
	{ color: '#3b5ac6', backgroundColor: '#e5e9f7' },
	{
		color: '#cd1599',
		backgroundColor: '#f7e5f2',
	},
	{ color: '#2f6616', backgroundColor: '#e9f7e5' },
	{
		color: '#137c55',
		backgroundColor: '#e5f8f1',
	},
];

export const KampstaTenantBadgeBackground = [
	{ backgroundColor: '#5e63f7', color: '#d2d9f5' },
	{
		backgroundColor: '#cd610a',
		color: '#f7ede5',
	},
	{ backgroundColor: '#cf4848', color: '#f8dede' },
	{
		backgroundColor: '#9a8d18',
		color: '#f7f7e6',
	},
	{ backgroundColor: '#cd610a', color: '#f7ede5' },
	{
		backgroundColor: '#cd610a',
		color: '#f7ede5',
	},
	{ backgroundColor: '#3b5ac6', color: '#e5e9f7' },
	{
		backgroundColor: '#cd1599',
		color: '#f7e5f2',
	},
	{ backgroundColor: '#2f6616', color: '#e9f7e5' },
	{
		backgroundColor: '#137c55',
		color: '#e5f8f1',
	},
	{ backgroundColor: '#5e63f7', color: '#d2d9f5' },
	{
		backgroundColor: '#cd610a',
		color: '#f7ede5',
	},
	{ backgroundColor: '#cf4848', color: '#f8dede' },
	{
		backgroundColor: '#9a8d18',
		color: '#f7f7e6',
	},
	{ backgroundColor: '#cd610a', color: '#f7ede5' },
	{
		backgroundColor: '#cd610a',
		color: '#f7ede5',
	},
	{ backgroundColor: '#3b5ac6', color: '#e5e9f7' },
	{
		backgroundColor: '#cd1599',
		color: '#f7e5f2',
	},
	{ backgroundColor: '#2f6616', color: '#e9f7e5' },
	{
		backgroundColor: '#137c55',
		color: '#e5f8f1',
	},
	{ backgroundColor: '#5e63f7', color: '#d2d9f5' },
	{
		backgroundColor: '#cd610a',
		color: '#f7ede5',
	},
	{ backgroundColor: '#cf4848', color: '#f8dede' },
	{
		backgroundColor: '#9a8d18',
		color: '#f7f7e6',
	},
	{ backgroundColor: '#cd610a', color: '#f7ede5' },
	{
		backgroundColor: '#cd610a',
		color: '#f7ede5',
	},
	{ backgroundColor: '#3b5ac6', color: '#e5e9f7' },
	{
		backgroundColor: '#cd1599',
		color: '#f7e5f2',
	},
	{ backgroundColor: '#2f6616', color: '#e9f7e5' },
	{
		backgroundColor: '#137c55',
		color: '#e5f8f1',
	},
];

export const emailRegex = /[a-zA-Z0-9_%.+-]+@[a-z0-9]+[\.][a-z]+/; //NOSONAR
export const workEmailRegex =
	/^([\w-.]+@(?!gmail\.com)(?!yahoo\.com)(?!hotmail\.com)(?!mail\.ru)(?!yandex\.ru)(?!mail\.com)([\w-]+.)+[\w-]{2,4})?$/; //NOSONAR

export const roleIds = {
	roleIdTeamAdmin: 1,
	roleIdBotMaster: 6,
};

export const cardBg = [
	'#878cdf',
	'#d1a339',
	'#96c350',
	'#59c5cd',
	'#6ca7e0',
	'#b361c2',
	'#609f72',
	'#d661a7',
];
export const iconBG = [
	'#101fde',
	'#bf6f1c',
	'#459d2a',
	'#0e8d98',
	'#0057af',
	'#c61bb2',
	'#286539',
	'#c40a79',
];
export const cards = [
	'Whatsapp',
	'Instagram',
	'Website',
	'Google Business Messages',
	'Facebook Messenger',
	'Apple Business Chat',
];
export enum RATING_TYPES {
	Star,
	Smiley,
}
export const RATING_SMILEY_ID_MAPPING: {
	[id: number]: { type: number; count: number };
} = {
	1: { type: RATING_TYPES.Star, count: 3 },
	2: { type: RATING_TYPES.Star, count: 5 },
	3: { type: RATING_TYPES.Star, count: 10 },
	4: { type: RATING_TYPES.Smiley, count: 3 },
	5: { type: RATING_TYPES.Smiley, count: 5 },
};