import React, { useEffect, useState } from 'react';

interface Props {
	text: string;
}

const FormattedText = ({ text }: Props) => {
	const [formattedText, setFormattedText] = useState<Array<string>>([]);

	useEffect(() => {
		let re = new RegExp(/(=?\*.*?=?\*)|(=?`{3}.*?=?`{3})|(=?_.*?=?_)/, 'gim');
		setFormattedText(text.split(re).filter((str) => str && str.length));
	}, [text]);

	const renderHTML = (msg: any) => {
		return <span dangerouslySetInnerHTML={{ __html: msg }}></span>;
	};

	return (
		<>
			{formattedText.map((msg, index) => {
				if (msg && msg.length > 0) {
					if ((msg.match(/_(.*)_/g) || []).length === 1)
						//italic
						return (
							<i key={msg + index}>
								{renderHTML(msg.substring(1, msg.length - 1))}
							</i>
						);
					else if ((msg.match(/\*(.*)\*/g) || []).length === 1)
						return (
							<b key={msg + index}>
								{renderHTML(msg.substring(1, msg.length - 1))}
							</b> //bold
						);
					else if ((msg.match(/`{3}(.*)`{3}/g) || []).length === 1)
						//monospace
						return (
							<code key={msg + index}>
								{renderHTML(msg.substring(3, msg.length - 3))}
							</code>
						);
					else if ((msg.match(/~(.*)~/g) || []).length === 1)
						//strikethrough
						return (
							<del key={msg + index}>
								{renderHTML(msg.substring(1, msg.length - 1))}
							</del>
						);
					return (
						<React.Fragment key={msg + index}>{renderHTML(msg)}</React.Fragment>
					);
				} else {
					return null;
				}
			})}
		</>
	);
};
export default React.memo(FormattedText);
