import {
	getDoc,
	uploadDoc,
	removeDoc,
	ingestURL,
	ImportShop_SHOPIFY,
	SHOPIFY_SYNC,
	editDoc,
} from './Model';

import DataFetchAPI from '../../utils/Api';
import DataFetchRouteAPI from '../../utils/RouteApi';

const getFile = async (data: getDoc) => {
	return await DataFetchRouteAPI(false, true, 'Bearer').get(
		`/configuration/${data.tenantId}/bot/${data.botId}/ingest`
	);
};
const uploadFile = async (data: uploadDoc) => {
	return await DataFetchRouteAPI(false, true, 'Bearer').post(
		`/configuration/${data.tenantId}/bot/${data.botId}/doc`,
		data.files
	);
};
const removeFile = async (data: removeDoc) => {
	return await DataFetchRouteAPI(false, true, 'Bearer').delete(
		`/configuration/${data.tenantId}/bot/${data.botId}/ingest/${data.docId}`
	);
};
const ingestLink = async (data: ingestURL) => {
	return await DataFetchRouteAPI(false, true, 'Bearer').post(
		`/configuration/${data.tenantId}/bot/${data.botId}/ingest/link`,
		{
			url: data.url,
		}
	);
};

const flyfishStoreParse = async (data: {
	tenantId: number;
	botId: number;
	file: any;
}) => {
	return await DataFetchRouteAPI(false, true, 'Bearer').post(
		`/shop/ecom/tenant/${data.tenantId}/bot/${data.botId}/xl/link`,
		data.file
	);
};

const downloadStoreFormat = async (data: getDoc) => {
	return await DataFetchRouteAPI(false, true, 'Bearer').get(
		`/configuration/${data.tenantId}/bot/${data.botId}/doc?path=sample_product_catalogue.xlsx`,
		{
			responseType: 'blob', // This sets the response type to blob
		}
	);
};

const wooCommStoreLink = async (data: { tenantId: number; url: string }) => {
	return await DataFetchRouteAPI(
		false,
		true,
		'Bearer'
	).post(`/shop/${data.tenantId}/oauth/woocom/url`, { ecomDomain: data.url });
};

const shopImport_Shopify = async (data: ImportShop_SHOPIFY) => {
	return await DataFetchRouteAPI(false, true, 'Bearer').get(
		`/shop/tenant/${data.tenantId}/bot/${data.botId}/importShop?chargeId=${data.chargeId}`
	);
};

const shopSync = async (data: SHOPIFY_SYNC) => {
	return await DataFetchRouteAPI().post(
		`/shop/ecom/tenant/${data.tenantId}/bot/${data.botId}/syncStoreData`,
		{}
	);
};
const editPDF = async (data: editDoc) => {
	return await DataFetchRouteAPI(false, true, 'Bearer').put(
		`/configuration/${data.tenantId}/bot/${data.botId}/doc/${data.id}`,
		{
			title: data.title,
			description: data.description,
		}
	);
};

const IngestService = {
	getFile,
	uploadFile,
	removeFile,
	ingestLink,
	flyfishStoreParse,
	downloadStoreFormat,
	wooCommStoreLink,
	shopImport_Shopify,
	shopSync,
	editPDF
};
export default IngestService;
