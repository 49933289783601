import React, { useState, useEffect, useRef } from 'react';
import {
	ButtonPrevIcon,
	UploadDocIcon,
	DownloadIcon,
} from '../../../assets/Icons';
import Button from '../../../Components/Button';
import { useNavigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import { loadState, saveState } from '../../../utils/utils';
import './preview.scss';
import PageLoader from '../../../Components/PageLoader';
import Modal from '../../../Components/Modal';
import { set, setSnackModal } from '../../../Features/message/messageSlice';
import { setEditAPIStatus } from '../../../Features/Ingest/IngestSlice';
const MAX_COUNT = 100;

export function PreviewPDF() {
	const navigate = useNavigate();

	const [previewLoader, setPreviewLoader] = useState(false);
	const [downloadLoader, setDownloadLoader] = useState(false);

	const [base64, setbase64] = useState<any>('');
	const dispatch = useAppDispatch();
	const { file, fileDetails, editAPIStatus, editAPIStatusMsg } = useSelector(
		(state: RootState) => state.ingest
	);
	let objectURL = file.url ? file.url : URL.createObjectURL(file);
	let name = file.name ? file.name : file.Name;

	useEffect(() => {
		if (editAPIStatus === 'success') {
			navigate(-1);
			dispatch(setEditAPIStatus(''));
			setTimeout(() => {
				navigate('edit-file-success')
			}, 100);
		}
		else if (editAPIStatus === 'failed' && editAPIStatusMsg) {

		}
		else if (editAPIStatus === 'failed') {
			navigate(-1);
			setTimeout(() => {
				navigate('edit-file-error')
			}, 100);

		}



	}, [editAPIStatus]);

	const handleDownload = () => {
		if (file.url) {
			setDownloadLoader(true);
			fetch(file.url, {
				headers: {
					'X-Tenant-Id': loadState('tenantId').toString(),
					Authorization: `Bearer ${loadState('token')}`,
				},
			}).then((response) => {
				response.blob().then((blob) => {
					const fileURL = window.URL.createObjectURL(blob);
					let alink = document.createElement('a');
					alink.href = fileURL;
					alink.download = file.name;
					alink.click();
					setDownloadLoader(false);
				});
			});
		} else {
			let link = document.getElementById('ff-download-preview');
			link && link.click();
		}
	};
	useEffect(() => {
		setPreviewLoader(true);
		fetch(file.url, {
			headers: {
				'X-Tenant-Id': loadState('tenantId').toString(),
				Authorization: `Bearer ${loadState('token')}`,
			},
		}).then((response) => {
			response.blob().then((blob) => {
				var reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onload = function () {
					setbase64(reader.result);
					setPreviewLoader(false);
				};
			});
		});
	}, []);
	return (
		<>
			{previewLoader ? (
				<div className='query-loader'>
					<PageLoader message='Loading...' />
				</div>
			) : (
				<div className='ff-preview-container'>
					<div className='ff-preview-header'>
						<Button
							buttonType='light'
							onClick={() => {
								navigate('/app/Ingest');
							}}
							startIcon={<ButtonPrevIcon width='20' height='20' />}
						>
							Back
						</Button>
						{/* <div className='ff-preview-name'>
							<UploadDocIcon />
							<h6>{name}</h6>
						</div> */}
					</div>
					<div
						style={{ height: 'calc(100vh - 108px)' }}
						className='ff-pdf-action'
					>
						<div>
							{file.url ? (
								<embed

									style={{
										position: 'relative',
										left: 0,
										top: '0px',
										height: '100%',
									}}
									width='100%'
									height='100%'
									src={`${base64}#navpanes=0&scrollbar=0`}
									type='application/pdf'
								/>
							) : (
								<embed
									style={{
										position: 'relative',
										left: 0,
										top: '0px',
										height: '100%',
									}}
									width='100%'
									height='100%'
									src={`${objectURL}#navpanes=0&scrollbar=0`}
									type='application/pdf'
								/>
							)}
						</div>
						<div>
							<h6>{fileDetails.file.title || fileDetails.file.name}</h6>
							{fileDetails.file.description &&
								fileDetails.file.description.length > 0 ? (
								<div className='body2' style={{ wordWrap: 'break-word', paddingRight: '20px', letterSpacing: '0.42px' }}>
									{fileDetails.file.description}
								</div>
							) : (
								<div className='button2' style={{ color: 'var(--black-60)', fontWeight: '400', letterSpacing: '0.42px' }}>
									No description added
								</div>
							)}

							<div>
								<Button
									buttonType='light'
									onClick={() => {
										navigate('edit-file');
									}}
								>
									Edit
								</Button>
								<div>
									{' '}
									<Button
										buttonType='light'
										onClick={() => {
											handleDownload();
										}}

									>
										Download
									</Button>
									<a
										href={objectURL}
										download={name}
										id='ff-download-preview'
										style={{ display: 'none' }}
									>
										Download
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<Outlet />
		</>
	);
}
