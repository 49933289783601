import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { loadState, nameOfCrmsLinked, saveState } from '../../utils/utils';
import { CommerceRes } from '../SalesAI/model';
import { getTenants, selectTeam } from '../teams/TeamsSlice';
import {
	AddNewUserAsAdmin,
	AddNewUserDataAsAdminResponse,
	ALLUSERS,
	BOTLISTDATA,
	FreshChatLinkReq,
	FreshChatLinkRes,
	FreshChatStatusReq,
	FreshChatStatusRes,
	FreshChatUnlinkReq,
	FreshChatUnlinkRes,
	GetAllUsersMyhub,
	GetBotAssigned,
	GetBotAssignedResponse,
	GETSESSIONCOUNT,
	GETSESSIONCOUNTRESPONSE,
	GETTENANTRESPONSE,
	LINKEDMAIL,
	RemoveFromBotStudio,
	RemoveFromBotStudioResponse,
	SETUP,
	UPDATETENANT,
	UPDATETENANTRESPONSE,
	UpdateUserRoleMyhub,
	UpdateUserRoleMyhubResponse,
} from './modal';
import teamsettingService from './teamsettingService';
import { NEWTABS } from '../../Pages/TeamSetting/constants';
import { NEWTABS as NEWGENTAB } from '../../Pages/GeneralTeamSetting/constants';

type State = {
	getAllUsersMyHubLoader: boolean;
	error: string;
	UsersMyhubbotRolesList: ALLUSERS;
	tenant: GETTENANTRESPONSE[];
	botAssigned: GetBotAssignedResponse;
	botList: BOTLISTDATA[];
	crmemail: {
		email: string;
		googleSheets: string;
		hubSpot: string;
		salesForce: string;
		zoho: string;
		shopify: string | boolean;
		leadsquare: string;
	};
	tenantName: string;
	customTenantName: string;
	newTenantNameAdded: boolean;
	customOrganizationName: string;
	tenantFile: string;
	profileUpdated: boolean;
	removeEmail: boolean;
	pricingLoader: boolean;
	pricingData: GETSESSIONCOUNTRESPONSE;
	integrationEmailLoader: string;
	integrationSalesforceLoader: string;
	addUserLoader: string;
	removeUserLoader: string;
	updateTenantProfileFail: string;
	updateTenantProfileError: string;
	statusData: any;
	leadSquaredData: LINKEDMAIL;
	zohoData: LINKEDMAIL;
	shopifyData: LINKEDMAIL;
	emailData: LINKEDMAIL;
	salesforceData: LINKEDMAIL;
	wooCommData: LINKEDMAIL;
	linkFreshChatLoader: string;
	unlinkFreshChatLoader: string;
	checkFreshChatStatusLoader: string;
	freshChatLinked: boolean;
	invalidCredentials: boolean;
	crmsLinkedData: any;
	crmsLinkedDataLBV: any;
	crmsLinkedAPIStatus: string;
	crmUnlinkLoader: string;
	leadsDestination: { label: string; value: string }[];
	ticketingDestination: { label: string; value: string }[];
	selectedTab: string;
	bookingDestination: { label: string; value: string }[];
	generalselectedTab: string;
};

export const getAllUsersMyhub = createAsyncThunk<
	ALLUSERS,
	GetAllUsersMyhub,
	{ rejectValue: string }
>(
	'/getAllUsersMyhub',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await teamsettingService.getAllUsersMyhub(data);

			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const getBotAssigned = createAsyncThunk<
	GetBotAssignedResponse,
	GetBotAssigned,
	{ rejectValue: string }
>('/getBotAssigned', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await teamsettingService.getBotAssigned(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const updateUserRoleMyhub = createAsyncThunk<
	UpdateUserRoleMyhubResponse,
	UpdateUserRoleMyhub,
	{ rejectValue: string }
>(
	'/updateUserRoleMyhub',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await teamsettingService.updateUserRoleMyhub(data);

			if (response.data) {
				dispatch(getAllUsersMyhub({ tenantId: data.tenantId }));
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const removeFromBotStudio = createAsyncThunk<
	RemoveFromBotStudioResponse,
	RemoveFromBotStudio,
	{ rejectValue: string }
>(
	'/removeFromBotStudio',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await teamsettingService.removeFromBotStudio(data);

			if (response.data) {
				dispatch(getAllUsersMyhub({ tenantId: data.tenantId }));
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const setup = createAsyncThunk<void, SETUP, { rejectValue: string }>(
	'/setup',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await teamsettingService.setup(data);

			if (response.data) {
				dispatch(
					setCrmEmail({
						email: data.config.ACCESS_KEY,
						googleSheets: '',
						hubSpot: '',
						salesForce: '',
						zoho: '',
					})
				);
				dispatch(setRemoveEmail(false));
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const linkedEmail = createAsyncThunk<
	LINKEDMAIL[],
	number,
	{ rejectValue: string }
>('/linked', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await teamsettingService.linkedEmail(data);

		if (response.data) {
			// console.log(response.data);
			return response.data;
		}
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});
export const linkedStatus = createAsyncThunk<
	CommerceRes,
	any,
	{ rejectValue: string }
>('shopify/ecom/status', async (data, { rejectWithValue }) => {
	try {
		const response = await teamsettingService.linkedStatus(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const updateTenantProfile = createAsyncThunk<
	UPDATETENANTRESPONSE,
	UPDATETENANT,
	{ rejectValue: string }
>(
	'/updateTenantProfile',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await teamsettingService.updateTenantProfile(data);

			if (response.data) {
				dispatch(
					getTenants({ updateTenant: true, userId: loadState('user').userID })
				);
				dispatch(setCustomtenantname(data.name));
				saveState('teamname', data.name);

				dispatch(selectTeam({ teamname: data.name, tenantId: data.id }));
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error: any) {
			const response = error.response;
			if (response.status === 409) {
				return rejectWithValue(
					'This team name is already taken please try with different name'
				);
			}
			return rejectWithValue(response.data.message);
		}
	}
);

export const getSessionCount = createAsyncThunk<
	GETSESSIONCOUNTRESPONSE,
	GETSESSIONCOUNT,
	{ rejectValue: string }
>('/getSessionCount', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await teamsettingService.getSessionCount(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const addTenantAdmin = createAsyncThunk<
	AddNewUserDataAsAdminResponse,
	AddNewUserAsAdmin,
	{ rejectValue: string }
>('/addTenantAdmin', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await teamsettingService.addTenantAdmin(data);
		if (response.data) {
			dispatch(getAllUsersMyhub({ tenantId: data.tenantId }));
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const linkFreshchat = createAsyncThunk<
	FreshChatLinkRes,
	FreshChatLinkReq,
	{ rejectValue: string }
>('/linkfreshchat', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await teamsettingService.linkFreshchat(data);
		if (response.data.response.toLowerCase() === 'success') {
			return response.data;
		} else if (response.data.response.toLowerCase() === 'failed') {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const unlinkFreshchat = createAsyncThunk<
	FreshChatUnlinkRes,
	FreshChatUnlinkReq,
	{ rejectValue: string }
>('/unlinkfreshchat', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await teamsettingService.unlinkFreshchat(data);
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const checkFreshchatStatus = createAsyncThunk<
	FreshChatStatusRes,
	FreshChatStatusReq,
	{ rejectValue: string }
>(
	'/freshchat/status',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response: any = await teamsettingService.checkFreshchatStatus(data);
			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const crmsLinked = createAsyncThunk<
	any,
	{ tenantId: number },
	{ rejectValue: string }
>('/crmsLinked', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await teamsettingService.crmsLinked(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const crmUnlink = createAsyncThunk<
	any,
	{ tenantId: number; linkedCrmId: number },
	{ rejectValue: string }
>('/crmUnlink', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await teamsettingService.crmUnlink(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const workflowDestination = createAsyncThunk<
	any,
	{ tenantId: number; workflow: string },
	{ rejectValue: string }
>(
	'/workflowDestination',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await teamsettingService.workflowDestination(data);

			if (response.data) {
				let res = response.data;
				let crmSupported = nameOfCrmsLinked(res);
				crmSupported.push({ label: 'Add platform', value: 'Add platform' });

				if (data.workflow === 'LEAD') {
					dispatch(setLeadsDestination(crmSupported));
				}
				if (data.workflow === 'TICKETING') {
					dispatch(setTicketingDestination(crmSupported));
				}
				if (data.workflow === 'APPOINTMENT') {
					dispatch(setBookingDestination(crmSupported));
				}
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

const initialState: State = {
	getAllUsersMyHubLoader: false,
	error: '',
	UsersMyhubbotRolesList: { allUsers: [] },
	tenant: [],
	botAssigned: {
		botID: 0,
		botList: [],
		tenantID: 0,
		userName: '',
		roleId: 0,
	},
	botList: [],
	crmemail: {
		email: '',
		googleSheets: '',
		hubSpot: '',
		salesForce: '',
		zoho: '',
		shopify: '' || false,
		leadsquare: '',
	},
	tenantName: loadState('teamname') ?? '',
	customTenantName: loadState('teamname') ?? '',
	newTenantNameAdded: false,
	customOrganizationName: loadState('organizationName') ?? '',
	tenantFile: '',
	profileUpdated: false,
	removeEmail: false,
	pricingLoader: false,
	pricingData: {
		tenantId: 0,
		allottedSessions: 0,
		planName: '',
		usedSessions: 0,
		botUsage: [
			{
				bot_name: '',
				count: 0,
				botId: 0,
				isBotPublic: false,
				botIcon: '',
			},
		],
	},
	integrationEmailLoader: '',
	integrationSalesforceLoader: '',
	addUserLoader: '',
	removeUserLoader: '',
	updateTenantProfileFail: '',
	updateTenantProfileError: '',
	statusData: '',
	leadSquaredData: {
		crmId: 0,
		crmName: '',
		tenantId: 0,
		linkCrmId: 0,
		userId: 0,
		crmUserId: '',
		active: false,
	},
	zohoData: {
		linkCrmId: 0,
		crmId: 0,
		tenantId: 0,
		crmName: '',
		userId: 0,
		crmUserId: '',
		active: false,
	},
	salesforceData: {
		linkCrmId: 0,
		crmId: 0,
		tenantId: 0,
		crmName: '',
		userId: 0,
		crmUserId: '',
		active: false,
	},
	shopifyData: {
		linkCrmId: 0,
		crmId: 0,
		tenantId: 0,
		crmName: '',
		userId: 0,
		crmUserId: '',
		active: false,
	},
	wooCommData: {
		linkCrmId: 0,
		crmId: 0,
		tenantId: 0,
		crmName: '',
		userId: 0,
		crmUserId: '',
		active: false,
	},
	linkFreshChatLoader: '',
	unlinkFreshChatLoader: '',
	checkFreshChatStatusLoader: '',
	freshChatLinked: false,
	invalidCredentials: false,
	crmsLinkedData: '',
	crmsLinkedAPIStatus: '',
	crmsLinkedDataLBV: [],
	emailData: {
		linkCrmId: 0,
		crmId: 0,
		tenantId: 0,
		crmName: '',
		userId: 0,
		crmUserId: '',
		active: false,
	},
	crmUnlinkLoader: '',
	leadsDestination: [],
	ticketingDestination: [],
	selectedTab: loadState('selectedConfigTab') ?? NEWTABS[0].Tab.title,
	bookingDestination: [],
	generalselectedTab: NEWGENTAB[0].Tab.title,
};

const teamsettingSlice = createSlice({
	name: 'teamsetting',
	initialState,
	reducers: {
		reset: (state) => {
			state.getAllUsersMyHubLoader = false;
			state.error = '';
		},
		setCrmEmail: (state, action) => {
			state.crmemail = action.payload;
		},
		setCustomtenantname: (state, action) => {
			state.customTenantName = action.payload;
		},
		setcustomOrganizationName: (state, action) => {
			state.customOrganizationName = action.payload;
		},
		setTenantFile: (state, action) => {
			state.tenantFile = action.payload;
		},
		setUpdateTenantProfileError: (state, action) => {
			state.updateTenantProfileError = action.payload;
		},
		setProfileUpdated: (state, action) => {
			state.profileUpdated = action.payload;
		},
		setRemoveEmail: (state, action) => {
			state.removeEmail = action.payload;
		},
		resetIntegrationEmailLoader: (state) => {
			state.integrationEmailLoader = '';
		},
		resetIntegrationSalesforceLoader: (state) => {
			state.integrationSalesforceLoader = '';
		},
		resetAddUserLoader: (state) => {
			state.addUserLoader = '';
		},
		resetRemoveUserLoader: (state) => {
			state.removeUserLoader = '';
		},
		resetLinkFreshChatLoader: (state) => {
			state.linkFreshChatLoader = '';
		},
		resetUnlinkFreshChatLoader: (state) => {
			state.unlinkFreshChatLoader = '';
		},
		setCrmsLinkedDataLBV: (state, action) => {
			state.crmsLinkedDataLBV = action.payload;
		},
		resetCrmUnlinkLoader: (state) => {
			state.crmUnlinkLoader = '';
		},
		resetEmailData: (state) => {
			state.emailData = {
				linkCrmId: 0,
				crmId: 0,
				tenantId: 0,
				crmName: '',
				userId: 0,
				crmUserId: '',
				active: false,
			};
		},
		resetZohoData: (state) => {
			state.zohoData = {
				linkCrmId: 0,
				crmId: 0,
				tenantId: 0,
				crmName: '',
				userId: 0,
				crmUserId: '',
				active: false,
			};
		},
		resetSalesforceData: (state) => {
			state.salesforceData = {
				linkCrmId: 0,
				crmId: 0,
				tenantId: 0,
				crmName: '',
				userId: 0,
				crmUserId: '',
				active: false,
			};
		},
		resetLeadsquaredData: (state) => {
			state.leadSquaredData = {
				linkCrmId: 0,
				crmId: 0,
				tenantId: 0,
				crmName: '',
				userId: 0,
				crmUserId: '',
				active: false,
			};
		},
		resetShopifyData: (state) => {
			state.shopifyData = {
				linkCrmId: 0,
				crmId: 0,
				tenantId: 0,
				crmName: '',
				userId: 0,
				crmUserId: '',
				active: false,
			};
		},
		resetWooCommData: (state) => {
			state.wooCommData = {
				linkCrmId: 0,
				crmId: 0,
				tenantId: 0,
				crmName: '',
				userId: 0,
				crmUserId: '',
				active: false,
			};
		},
		setLeadsDestination: (state, action) => {
			state.leadsDestination = action.payload;
		},
		setTicketingDestination: (state, action) => {
			state.ticketingDestination = action.payload;
		},
		setSelectedTab: (state, action) => {
			state.selectedTab = action.payload;
		},
		setBookingDestination: (state, action) => {
			state.bookingDestination = action.payload;
		},
		setGeneralSelectedTab: (state, action) => {
			state.generalselectedTab = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllUsersMyhub.pending, (state) => {
				state.getAllUsersMyHubLoader = true;
			})
			.addCase(getAllUsersMyhub.fulfilled, (state, action) => {
				state.getAllUsersMyHubLoader = false;
				state.UsersMyhubbotRolesList = action.payload;
			})
			.addCase(getAllUsersMyhub.rejected, (state) => {
				state.getAllUsersMyHubLoader = false;
			})
			.addCase(getBotAssigned.fulfilled, (state, action) => {
				state.botAssigned = action.payload;
				state.botList = action.payload.botList;
			})
			.addCase(linkedEmail.fulfilled, (state, action) => {
				let emailCRM = action.payload.filter(
					(obj: LINKEDMAIL) => obj.crmName === 'email'
				);
				let leadSquareCRM = action.payload.filter(
					(obj: LINKEDMAIL) => obj.crmName === 'LeadSquared'
				);
				let shopifyCRM = action.payload.filter(
					(obj: LINKEDMAIL) => obj.crmName === 'shopify'
				);
				let wooCommCRM = action.payload.filter(
					(obj: LINKEDMAIL) => obj.crmName === 'woocommerce'
				);
				if (emailCRM[0]) {
					state.crmemail.email = emailCRM[0].crmUserId;
				}
				if (leadSquareCRM[0]) {
					state.leadSquaredData = leadSquareCRM[0];
				}
				if (shopifyCRM[0]) {
					state.crmemail.shopify = shopifyCRM[0].active;
				}
				let zohoStatus = action.payload.filter(
					(obj: LINKEDMAIL) => obj.crmName.indexOf('Zoho') > -1
				);
				if (zohoStatus[0]) {
					state.zohoData = zohoStatus[0];
				}
				if (wooCommCRM[0]) {
					state.wooCommData = wooCommCRM[0];
				}
			})
			.addCase(linkedStatus.pending, (state, action) => {
				state.statusData = '';
			})
			.addCase(linkedStatus.fulfilled, (state, action) => {
				state.statusData = action.payload;
			})
			.addCase(updateTenantProfile.pending, (state) => {
				state.newTenantNameAdded = true;
				state.profileUpdated = false;
				state.updateTenantProfileFail = '';
				state.updateTenantProfileError = '';
			})
			.addCase(updateTenantProfile.fulfilled, (state) => {
				state.newTenantNameAdded = false;
				state.profileUpdated = true;
				state.updateTenantProfileFail = '';
				state.updateTenantProfileError = '';
			})
			.addCase(updateTenantProfile.rejected, (state, action) => {
				state.newTenantNameAdded = false;
				state.profileUpdated = false;
				state.updateTenantProfileError = 'failed';
				state.updateTenantProfileFail = action.payload
					? action.payload
					: 'Something Went Wrong Please try after sometime';
			})
			.addCase(getSessionCount.pending, (state) => {
				state.pricingLoader = true;
			})
			.addCase(getSessionCount.fulfilled, (state, action) => {
				state.pricingLoader = false;
				if (action.payload) {
					state.pricingData = action.payload;
				}
			})
			.addCase(getSessionCount.rejected, (state) => {
				state.pricingLoader = false;
			})
			.addCase(setup.pending, (state) => {
				state.integrationEmailLoader = 'loading';
				state.integrationSalesforceLoader = 'loading';
			})
			.addCase(setup.fulfilled, (state) => {
				state.integrationEmailLoader = 'success';
				state.integrationSalesforceLoader = 'success';
			})
			.addCase(setup.rejected, (state) => {
				state.integrationEmailLoader = 'failed';
				state.integrationSalesforceLoader = 'failed';
			})
			.addCase(addTenantAdmin.pending, (state) => {
				state.addUserLoader = 'loading';
			})
			.addCase(addTenantAdmin.fulfilled, (state) => {
				state.addUserLoader = 'success';
			})
			.addCase(addTenantAdmin.rejected, (state) => {
				state.addUserLoader = 'failed';
			})
			.addCase(removeFromBotStudio.pending, (state) => {
				state.removeUserLoader = 'loading';
			})
			.addCase(removeFromBotStudio.fulfilled, (state) => {
				state.removeUserLoader = 'success';
			})
			.addCase(removeFromBotStudio.rejected, (state) => {
				state.removeUserLoader = 'failed';
			})
			.addCase(linkFreshchat.pending, (state) => {
				state.linkFreshChatLoader = 'loading';
			})
			.addCase(linkFreshchat.fulfilled, (state, action) => {
				state.linkFreshChatLoader = 'success';
				state.freshChatLinked =
					action.payload.response === 'Success' ? true : false;
				state.invalidCredentials =
					action.payload.error === 'Invalid Credentials' ? true : false;
			})
			.addCase(linkFreshchat.rejected, (state, action) => {
				state.linkFreshChatLoader = 'failed';
			})
			.addCase(unlinkFreshchat.pending, (state) => {
				state.unlinkFreshChatLoader = 'loading';
			})
			.addCase(unlinkFreshchat.fulfilled, (state, action) => {
				state.unlinkFreshChatLoader = 'success';
				state.freshChatLinked =
					action.payload.response === 'Success' ? false : true;
			})
			.addCase(unlinkFreshchat.rejected, (state, action) => {
				state.unlinkFreshChatLoader = 'failed';
			})
			.addCase(checkFreshchatStatus.pending, (state) => {
				state.checkFreshChatStatusLoader = 'loading';
			})
			.addCase(checkFreshchatStatus.fulfilled, (state, action) => {
				state.checkFreshChatStatusLoader = 'success';
				state.freshChatLinked =
					action.payload.data.message === 'Account Available' ? true : false;
			})
			.addCase(checkFreshchatStatus.rejected, (state, action) => {
				state.checkFreshChatStatusLoader = 'failed';
			})
			.addCase(crmsLinked.pending, (state) => {
				state.crmsLinkedAPIStatus = 'loading';
			})
			.addCase(crmsLinked.fulfilled, (state, action) => {
				state.crmsLinkedAPIStatus = 'success';
				state.crmsLinkedData = action.payload;
				let emailCRM = action.payload.filter(
					(obj: LINKEDMAIL) => obj.crmName === 'email'
				);
				let leadSquareCRM = action.payload.filter(
					(obj: LINKEDMAIL) => obj.crmName === 'LeadSquared'
				);
				let shopifyCRM = action.payload.filter(
					(obj: LINKEDMAIL) => obj.crmName === 'shopify'
				);
				if (emailCRM[0]) {
					state.emailData = emailCRM[0];
					// state.crmemail.email =
					// 	emailCRM[0].linkedEmail ?? loadState('user').userEmail;
				}
				if (leadSquareCRM[0]) {
					state.leadSquaredData = leadSquareCRM[0];
				}
				if (shopifyCRM[0]) {
					// state.crmemail.shopify = shopifyCRM[0].active;
					state.shopifyData = shopifyCRM[0];
				}
				let zohoStatus = action.payload.filter(
					(obj: LINKEDMAIL) => obj.crmName.indexOf('Zoho') > -1
				);
				if (zohoStatus[0]) {
					state.zohoData = zohoStatus[0];
				}
				let salesforceStatus = action.payload.filter(
					(obj: LINKEDMAIL) => obj.crmName === 'salesforce'
				);
				if (salesforceStatus[0]) {
					state.salesforceData = salesforceStatus[0];
				}
				let wooCommCRM = action.payload.filter(
					(obj: LINKEDMAIL) => obj.crmName === 'woocommerce'
				);
				if (wooCommCRM[0]) {
					state.wooCommData = wooCommCRM[0];
				}
			})
			.addCase(crmsLinked.rejected, (state, action) => {
				state.crmsLinkedAPIStatus = 'failed';
			})
			.addCase(crmUnlink.pending, (state) => {
				state.crmUnlinkLoader = 'loading';
			})
			.addCase(crmUnlink.fulfilled, (state, action) => {
				state.crmUnlinkLoader = 'success';
			})
			.addCase(crmUnlink.rejected, (state, action) => {
				state.crmUnlinkLoader = 'failed';
			});
	},
});

export const {
	reset,
	setCrmEmail,
	setTenantFile,
	setProfileUpdated,
	setRemoveEmail,
	resetIntegrationEmailLoader,
	resetAddUserLoader,
	resetRemoveUserLoader,
	resetLinkFreshChatLoader,
	resetUnlinkFreshChatLoader,
	setCustomtenantname,
	setcustomOrganizationName,
	setCrmsLinkedDataLBV,
	resetCrmUnlinkLoader,
	resetEmailData,
	resetLeadsquaredData,
	resetShopifyData,
	resetZohoData,
	resetIntegrationSalesforceLoader,
	resetSalesforceData,
	setLeadsDestination,
	setUpdateTenantProfileError,
	setTicketingDestination,
	setSelectedTab,
	setBookingDestination,
	setGeneralSelectedTab,
} = teamsettingSlice.actions;

export default teamsettingSlice.reducer;
