import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	getWabaNos,
	getWabaNosKotak,
	InstaId,
	resetCreateInstaWatsapp,
	resetWabaStatus,
	setWabaNo,
	wabaInsta,
} from '../../../Features/WhatsApp-BSP/auth/authSlice';
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';

import './BetaChannel.scss';
import { CreateNewIcon } from '../../MyStudio/icon';
import ChannelsCard from '../../../Components/ChannelsCard';
import Menu, { Options } from '../../../Components/Menu';
import Chip from '../../../Components/Chip';
import { Deactivate } from './Deactivate';
import { Pause } from './Pause';
import { ChatLink } from './ChatLink';
import { LinkAd } from './LinkAd';
import { kotakTenantCheckForWhatsapp, loadState } from '../../../utils/utils';
import CreateInsta from './CreateInsta';
import Create from './Create';
import CreateInstaFailed from './CreateInsta/CreateInstaFailed';
import Button from '../../../Components/Button';
import Modal from '../../../Components/Modal';
import { CheckCircleIcon } from '../../../assets/images/cards/Logos';
import { cards } from '../../../utils/constants';
import { WhatsappInstaCard } from './SetUpNewChannelModal';
import { Masonry } from '@mui/lab';
import PageLoader from '../../../Components/PageLoader';
declare global {
	interface Window {
		launchWhatsAppSignup: any;
		launchInstagramSignup: any;
	}
}

interface Props {
	setExistingAccount: (arg: boolean) => void;
}
export const InstaAccountAlreadyAdded = ({ setExistingAccount }: Props) => {
	const dispatch = useAppDispatch();
	return (
		<Modal
			onClose={() => {
				setExistingAccount(false);
				dispatch(resetCreateInstaWatsapp());
			}}
			header={`Error: Instagram account already added`}
			actionButtons={
				<>
					<Button
						onClick={() => {
							dispatch(resetCreateInstaWatsapp());
							setExistingAccount(false);
						}}>
						Okay
					</Button>
				</>
			}>
			<div>
				<p className='body1'>
					The Instagram account that you are trying to add has already been
					added to the team.
				</p>
			</div>
		</Modal>
	);
};

const BetaChannels = () => {
	const [active, setActive] = useState<wabaInsta | undefined>(undefined);
	const [linkModal, setLinkModal] = useState<boolean>(false);
	const [chatModal, setChatModal] = useState<boolean>(false);
	const [pauseModal, setPauseModal] = useState<boolean>(false);
	const [deactivateModal, setDeactivateModal] = useState<boolean>(false);
	const [open, setOpen] = useState(true);
	const [options, setOptions] = useState<Array<Options>>([]);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const tenantId = loadState('tenantId');
	const [createInsta, setCreateInsta] = useState(false);

	const [createInstaFailed, setCreateInstaFailed] = useState<boolean>(false);
	const [existingAccount, setExistingAccount] = useState<boolean>(false);
	const cardsToDisplay = [
		'Whatsapp',
		'Instagram',
		'Google Business Messages',
		'Facebook Messenger',
		'Apple Business Chat',
	];
	const getOptions: (wabaInstaObj: wabaInsta) => Array<Options> = (
		wabaInstaObj
	) => [
		{
			label: `${wabaInstaObj.botName ? 'Relink/Unlink' : 'Link'}`,
			onClick: () => {
				setOpen(false);
				setLinkModal(true);
			},
		},
		{
			label: 'Get Chat link',
			onClick: () => {
				setOpen(false);
				setChatModal(true);
			},
		},
		{
			label: `${
				wabaInstaObj?.pageName
					? wabaInstaObj?.instaStatus?.toUpperCase() === 'ACTIVE'
						? 'Pause'
						: 'Resume'
					: wabaInstaObj?.wabaStatus?.toUpperCase() === 'ACTIVE'
					? 'Pause'
					: 'Resume'
			}`,
			onClick: () => {
				setOpen(false);
				setPauseModal(true);
			},
		},
		{
			label: `Remove`,
			onClick: () => {
				setOpen(false);
				setDeactivateModal(true);
			},
		},
	];

	const {
		wabaNos,
		instaIds,
		setWabaStatus,
		updatedStatus,
		instaPagesRetrived,
		watsappIdRetrieved,
		instaPages,
		isLoading,
	} = useSelector((state: RootState) => state.BSPauth);

	useEffect(() => {
		kotakTenantCheckForWhatsapp(`${tenantId}`)
			? dispatch(getWabaNosKotak({ tenantId: tenantId }))
			: dispatch(getWabaNos({ tenantId: tenantId }));
	}, [updatedStatus]);

	useEffect(() => {
		if (setWabaStatus) navigate('/app/channels/whatsapp/templates');
		return () => {
			dispatch(resetWabaStatus());
		};
	}, [setWabaStatus]);

	useEffect(() => {
		if (instaPagesRetrived === 'success') {
			const tempInstaPages =
				instaPages &&
				instaPages.map((each) => {
					return each.id;
				});
			//logic to check , if insta account is already added.
			const existingInstaArray = [];
			for (const eachId of instaIds) {
				for (const eachPage of tempInstaPages) {
					if (eachPage === eachId.pageId) {
						existingInstaArray.push(eachPage);
					}
				}
			}
			if (Boolean(existingInstaArray.length)) {
				setExistingAccount(true);
			} else {
				setCreateInsta(true);
			}
		} else if (instaPagesRetrived === 'failed') {
			setCreateInstaFailed(true);
		}
		return () => {
			dispatch(resetCreateInstaWatsapp());
		};
	}, [instaPagesRetrived]);

	useEffect(() => {
		if (watsappIdRetrieved === 'success') {
			// setCreate(true);
			navigate('create-waba');
		}
		return () => {
			dispatch(resetCreateInstaWatsapp());
		};
	}, [watsappIdRetrieved]);
	return (
		<div className='channels-beta'>
			{Boolean(wabaNos.length > 0 || instaIds.length > 0) && (
				<h4 className='channel'>Channels</h4>
			)}
			{isLoading ? (
				<div className='channel-loader'>
					{' '}
					<PageLoader message='Loading channels' />
				</div>
			) : wabaNos.length > 0 || instaIds.length > 0 ? (
				<div className='cards-container'>
					<>
						<div
							className='create-card'
							onClick={() => navigate('setup-channel')}
							style={{ cursor: 'pointer' }}>
							<CreateNewIcon />
							<h5>Set up new Channel</h5>
						</div>
						{wabaNos.map((details) => (
							<ChannelsCard
								key={`+${details.wabaNo}`}
								botIcon={details.botIcon ?? ''}
								botName={details.botName ?? ''}
								status={
									details.wabaStatus && (
										<Chip
											icon={
												details.wabaStatus.toUpperCase() === 'ACTIVE' ? (
													<CheckCircleIcon />
												) : (
													<PauseCircleFilledOutlinedIcon
														fontSize='inherit'
														color='inherit'
													/>
												)
											}
											label={details.wabaStatus}
										/>
									)
								}
								statusMessage={details.wabaStatus}
								onClick={() => {
									dispatch(setWabaNo(details.wabaNo));
									navigate('/app/channels/whatsapp/templates');
								}}
								onClickOptions={(event) => {
									event.stopPropagation();
									setAnchorEl(event.target as HTMLElement);
									setActive(details);
									setOptions(getOptions(details));
									setOpen(true);
								}}
								channel='whatsapp'
								channelName={`+${details.wabaNo}`}
								botCoverImage={details.botCoverImage}
							/>
						))}
						{instaIds &&
							instaIds.map((details) => (
								<ChannelsCard
									key={`+${details.pageName}`}
									botIcon={details.botIcon ?? ''}
									botName={details.botName ?? ''}
									statusMessage={details.instaStatus}
									status={
										details.instaStatus && (
											<Chip
												icon={
													details.instaStatus.toUpperCase() === 'ACTIVE' ? (
														<CheckCircleIcon />
													) : (
														<PauseCircleFilledOutlinedIcon
															fontSize='inherit'
															color='inherit'
														/>
													)
												}
												label={details.instaStatus}
											/>
										)
									}
									onClick={() => {}}
									onClickOptions={(event) => {
										event.stopPropagation();
										setAnchorEl(event.target as HTMLElement);
										setActive(details);
										setOptions(getOptions(details));
										setOpen(true);
									}}
									channel='instagram'
									channelName={`${details.instaUsername}`}
									botCoverImage={details.botCoverImage}
								/>
							))}
					</>
				</div>
			) : (
				<>
					<div className='text-container'>
						<h4>Looks Like You Haven’t Set Up Any Channels Yet.</h4>
						<p className='body2'>
							Please choose a channel you would like to set up
						</p>
					</div>
					<div className='default-container'>
						<Masonry
							columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
							spacing='1px'>
							{cardsToDisplay.map((each) => {
								return (
									<WhatsappInstaCard
										title={each}
										onClick={() => {
											navigate('/app/channels/setup-wa-insta', {
												state: { value: each },
											});
										}}
									/>
								);
							})}
						</Masonry>
					</div>
				</>
			)}
			<Menu
				anchorEl={anchorEl}
				open={open}
				setOpen={setOpen}
				options={options}
			/>
			{linkModal && <LinkAd Details={active} setLinkAd={setLinkModal} />}
			{chatModal && <ChatLink Details={active} setChat={setChatModal} />}
			{pauseModal && <Pause Details={active} setPause={setPauseModal} />}
			{deactivateModal && (
				<Deactivate Details={active} setDeactivate={setDeactivateModal} />
			)}
			{createInsta && <CreateInsta setCreateInsta={setCreateInsta} />}
			{createInstaFailed && (
				<CreateInstaFailed setCreateInstaFailed={setCreateInstaFailed} />
			)}
			{existingAccount && (
				<InstaAccountAlreadyAdded setExistingAccount={setExistingAccount} />
			)}
			<Outlet />
		</div>
	);
};

export default BetaChannels;
