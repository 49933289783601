import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import AutoComplete from '../../../../Components/AutoComplete';
import {
	setFontFamily,
	setFontSize,
	setSelectedColor,
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import { fontFOptions, fontSOptions } from '../availableFonts';
import './Appearance.scss';
import ColorInput from '../../../../Components/ColorInput';
import { loadState } from '../../../../utils/utils';
const Appearance = () => {
	const { fontFamily, fontSize, selectedColor } = useSelector(
		(state: RootState) => state.experienceonweb
	);
	const dispatch = useAppDispatch();

	const optionsFilter = () => {
		//based on tenant id specific to BTSE 21 and 33 using 72 for testing locally
		if (loadState('tenantId') === 21 || loadState('tenantId') === 33) {
			return fontFOptions;
		}
		return fontFOptions.filter((font) => font.label.indexOf('BTSE') === -1);
	};

	return (
		<div className='ff-eow-font'>
			<h4>Appearance</h4>
			<div className='brand'>
				<h6>Brand Color</h6>
				<div
					className='color-input-div'
					style={{
						backgroundColor: selectedColor,
					}}>
					<ColorInput
						className='color-input'
						type='color'
						value={selectedColor}
						onValueChange={(value) => {
							dispatch(setSelectedColor(value));
						}}
					/>
				</div>
			</div>
			<div className='font'>
				<h6>Font</h6>
				<AutoComplete
					multiple={false}
					label='Font family'
					options={optionsFilter()}
					valueKey='value'
					labelKey='label'
					value={fontFamily}
					onChange={(val) => {
						dispatch(setFontFamily(val));
					}}
				/>
				<AutoComplete
					multiple={false}
					label='Font size'
					options={fontSOptions}
					valueKey='value'
					labelKey='label'
					value={fontSize}
					onChange={(val) => {
						dispatch(setFontSize(val));
					}}
				/>
			</div>
		</div>
	);
};

export default Appearance;
