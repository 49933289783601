import './Task.scss';
import {
	AddTaskPlus,
	APICall,
	Clone,
	ConfirmCollected,
	Delete,
	Image,
	Keyboard,
	MultipleChoice,
	MultipleSelect,
	SendCollectedValues,
	Star,
	StartFlag,
	Text,
	ThreeDotMenu,
	TriggerQuery,
	HandoffToAgent,
} from '../../../assets/Icons';
import { TaskObject } from '../model';
import Draggable from 'react-draggable';
import { anchorType, useXarrow } from 'react-xarrows';
import React, { useEffect, useState } from 'react';
import AddLinkedActionMenu from './AddLinkedActionMenu';
import { Options } from '../../../Components/Menu';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	changeRootAction,
	cloneAction,
	deleteAction,
	updateActionPosition,
} from '../../../Features/builder/builderSlice';
import { useSelector } from 'react-redux';
import { Anchor } from '..';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import InteractiveButton from '../../../Components/Button/InteractiveButton';

type TaskProps = {
	task: TaskObject;
	scale: number;
	setIsMoveable: React.Dispatch<boolean>;
	anchors: Anchor[];
	setAnchors: React.Dispatch<React.SetStateAction<Anchor[]>>;
	setTaskMenuAnchor: React.Dispatch<null | HTMLElement>;
	setTaskMenuOptions: React.Dispatch<React.SetStateAction<Options[]>>;
	setOpenTaskMenu: React.Dispatch<boolean>;
};

const OFFSET = 50;

export const getIcon = (userResponseTypeID: number) => {
	switch (userResponseTypeID) {
		case 1:
			return (
				<div className='type type1'>
					<div className='icon'>
						<Text width='15px' />/ <Image width='15px' />
					</div>
					<p>Show Text/Media -&nbsp;</p>
				</div>
			);

		case 3:
			return (
				<div className='type type3'>
					<div className='icon'>
						<MultipleSelect width='22px' />
					</div>
					<p>Multiple select options -&nbsp;</p>
				</div>
			);

		case 5:
			return (
				<div className='type type5'>
					<div className='icon'>
						<MultipleChoice width='22px' />
					</div>
					<p>Multiple choice options -&nbsp;</p>
				</div>
			);

		case 8:
			return (
				<div className='type type8'>
					<div className='icon'>
						<ConfirmCollected width='22px' />
					</div>
					<p>Confirm collected values -&nbsp;</p>
				</div>
			);

		case 10:
			return (
				<div className='type type10'>
					<div className='icon'>
						<APICall width='22px' />
					</div>
					<p>Call an API -&nbsp;</p>
				</div>
			);

		case 11:
			return (
				<div className='type type11'>
					<div className='icon'>
						<Star width='22px' />
					</div>
					<p>Collect user rating -&nbsp;</p>
				</div>
			);

		case 12:
			return (
				<div className='type type12'>
					<div className='icon'>
						<TriggerQuery width='21px' />
					</div>
					<p>Trigger another query -&nbsp;</p>
				</div>
			);

		case 150:
			return (
				<div className='type type150'>
					<div className='icon'>
						<Keyboard width='22px' />
					</div>
					<p>Collect User Input -&nbsp;</p>
				</div>
			);

		case 14:
			return (
				<div className='type type14'>
					<div className='icon'>
						<SendCollectedValues width='22px' />
					</div>
					<p>Send Collected Values -&nbsp;</p>
				</div>
			);
		case 0:
			return (
				<div className='type type0'>
					<div className='icon'>
						<HandoffToAgent width='15px' />
					</div>
					<p>Handoff to Agent -&nbsp;</p>
				</div>
			);
	}
	return null;
};

const Task = ({
	task,
	scale,
	setIsMoveable,
	anchors,
	setAnchors,
	setTaskMenuAnchor,
	setTaskMenuOptions,
	setOpenTaskMenu,
}: TaskProps) => {
	const updateXarrow = useXarrow();
	// task_position is 'ydx' (3000d2000) format and not 'xdy' (2000d3000) so
	// x = position[1] = 2000, y = position[0] = 3000
	const [position] = useState(task.task_position.split('d'));
	const [draggedPosition, setDraggedPosition] = useState(
		task.task_position.split('d').reverse()
	);
	const [addingTask, setAddingTask] = useState(false);
	const [taskPosition, setTaskPosition] = useState<string>('');
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { actions } = useSelector((state: RootState) => state.builder);
	const [cloneValue, setCloneValue] = useState(1);
	useEffect(() => {
		isItLinked();
	}, [task]);
	useEffect(() => {
		setTaskMenuAnchor(null);
	}, []);
	const handleAddTask = () => {
		setIsMoveable(!addingTask);
		setAddingTask(!addingTask);
	};

	const getMenuOptions = () => {
		return [
			{
				label: (
					<div className='task-menu-option'>
						<span>
							<StartFlag width='13px' />
						</span>
						<p>Set as start point</p>
					</div>
				),
				onClick: () => {
					!task.taskroot &&
						dispatch(
							changeRootAction({
								workflowId: task.workflow_ID,
								taskId: task.task_ID,
							})
						);
					setOpenTaskMenu(false);
				},
				disabled: task.taskroot,
			},
			{
				label: (
					<div className='task-menu-option'>
						<span>
							<Clone width='13px' />
						</span>
						<p>Clone</p>
					</div>
				),
				onClick: () => {
					dispatch(
						cloneAction({
							workflowId: task.workflow_ID,
							taskId: task.task_ID,
							task_name: `${task.task_name} clone ${cloneValue}`,
							task_position: `${Number(draggedPosition[1]) + 80}d${
								Number(draggedPosition[0]) + 80
							}`,
						})
					);
					setCloneValue(cloneValue + 1);
					setOpenTaskMenu(false);
				},
				disabled: task.userResponseTypeID !== 0 ? false : true,
			},
			{
				label: (
					<div className='task-menu-option'>
						<span>
							<Delete width='13px' />
						</span>
						<p>Delete</p>
					</div>
				),
				onClick: () => {
					!task.taskroot &&
						dispatch(
							deleteAction({
								workflowId: task.workflow_ID,
								taskId: task.task_ID,
							})
						);
					setOpenTaskMenu(false);
				},
				disabled: task.taskroot,
			},
		];
	};

	const handleOptionsClicked = (event: React.MouseEvent<SVGElement>) => {
		setTaskMenuOptions(getMenuOptions().filter((option) => !option.disabled));
		event.stopPropagation();
		// setTaskMenuOptions(getMenuOptions());
		setTaskMenuAnchor(event.target as HTMLElement);
		setOpenTaskMenu(true);
	};

	const handleActionClicked = () => {
		navigate('actions', {
			state: {
				action: { taskId: task.task_ID, workflowId: task.workflow_ID },
				actionType: task.userResponseTypeID,
			},
		});
	};

	const isItLinked = () => {
		const linked = actions.filter((action) =>
			action.taskHierarchyDTO.includes(task.task_ID)
		);

		if (task.taskHierarchyDTO.length > 0) {
			task.taskHierarchyDTO.forEach((id) =>
				updateAnchorPositions(id.toString(), task.task_ID.toString())
			);
		}

		if (linked.length > 0) {
			linked.forEach((link) => {
				updateAnchorPositions(task.task_ID.toString(), link.task_ID.toString());
			});
		}
	};

	const updateAnchorPositions = (target: string, dragged: string) => {
		if (target.length > 0 && dragged.length > 0) {
			const startBoundingRect = document
				.getElementById(dragged)
				?.getBoundingClientRect();
			const endBoundingRect = document
				.getElementById(target)
				?.getBoundingClientRect();

			if (startBoundingRect && endBoundingRect) {
				let startX = startBoundingRect.x;
				let startXMiddle = startBoundingRect.x + startBoundingRect.width / 2;
				let startXEnd = startBoundingRect.right;
				let startY = startBoundingRect.y;
				let startYMiddle = startBoundingRect.y + startBoundingRect.height / 2;
				let startYEnd = startBoundingRect.bottom;

				let endX = endBoundingRect.x;
				let endXMiddle = endBoundingRect.x + endBoundingRect.width / 2;
				let endXEnd = endBoundingRect.right;
				let endY = endBoundingRect.y;
				let endYMiddle = endBoundingRect.y + endBoundingRect.height / 2;
				let endYEnd = endBoundingRect.bottom;

				let start: anchorType = 'auto';
				let end: anchorType = 'auto';

				if (startYEnd < endY + OFFSET && startXEnd + OFFSET < endX) {
					// top-left
					start = 'right';
					end = 'left';
				} else if (startYEnd < endY + OFFSET && startXMiddle + OFFSET < endX) {
					// transition from left to middle
					start = 'bottom';
					end = 'left';
				} else if (startYEnd < endY + OFFSET && startX < endXMiddle + OFFSET) {
					// top-middle
					start = 'bottom';
					end = 'top';
				} else if (startYEnd < endY + OFFSET && startX < endXEnd + OFFSET) {
					// transition from middle to right
					start = 'bottom';
					end = 'right';
				} else if (startYEnd < endY + OFFSET && startX + OFFSET > endX) {
					// top-right
					start = 'left';
					end = 'right';
				} else if (startYEnd + OFFSET > endY && startXEnd + OFFSET < endX) {
					// bottom-left
					start = 'right';
					end = 'left';
				} else if (startYEnd + OFFSET > endY && startXMiddle + OFFSET < endX) {
					// transition from left to middle
					start = 'top';
					end = 'left';
				} else if (startYEnd + OFFSET > endY && startX < endXMiddle + OFFSET) {
					// bottom-middle
					start = 'top';
					end = 'bottom';
				} else if (startYEnd + OFFSET > endY && startX < endXEnd + OFFSET) {
					// transition from middle to right
					start = 'left';
					end = 'bottom';
				} else if (startYEnd + OFFSET > endY && startX + OFFSET > endX) {
					// bottom-right
					start = 'left';
					end = 'right';
				} else {
					// not covered / on top of each other
					start = 'auto';
					end = 'auto';
				}

				const isId = anchors.filter(
					(anchor) =>
						anchor.from === Number(dragged) && anchor.to === Number(target)
				);

				if (isId.length > 0) {
					setAnchors((prev) =>
						prev.map((anchor) =>
							anchor.to === Number(target) && anchor.from === Number(dragged)
								? {
										from: Number(dragged),
										to: Number(target),
										start: start,
										end: end,
								  }
								: anchor
						)
					);
				} else {
					setAnchors((prev) => [
						...prev,
						{
							from: Number(dragged),
							to: Number(target),
							start: start,
							end: end,
						},
					]);
				}
			}
		}
	};

	return (
		<>
			<Draggable
				onMouseDown={() => {
					setIsMoveable(true);
				}}
				onDrag={() => {
					isItLinked();
					updateXarrow();
				}}
				onStop={(event, data) => {
					isItLinked();
					updateXarrow();
					setIsMoveable(false);
					setDraggedPosition([data.x.toString(), data.y.toString()]);
					dispatch(
						updateActionPosition({
							task_ID: task.task_ID,
							task_position: `${data.y}d${data.x}`,
						})
					);
				}}
				disabled={addingTask}
				handle='.task-container-outer'
				defaultPosition={{ x: Number(position[1]), y: Number(position[0]) }}
				scale={scale}>
				<div
					className={`ff-task${task.taskroot ? ' root-task' : ''}${
						addingTask ? ' adding-task' : ''
					}`}
					id={task.task_ID.toString()}>
					<div id={`task_${task.task_ID}`} onDoubleClick={handleActionClicked}>
						<div className='task-container-outer'>
							{task.taskroot && (
								<div className='flag'>
									<StartFlag width='15px' /> <span>Start</span>
								</div>
							)}
							<div className={`task-container task${task.userResponseTypeID}`}>
								{getIcon(task.userResponseTypeID)}
								<Tooltip title={task.task_name}>
									<div className={'task-name'}>{task.task_name}</div>
								</Tooltip>
								<div className='more-actions'>
									<InteractiveButton
										title='More Options'
										icon={
											<ThreeDotMenu
												width='20px'
												onClick={handleOptionsClicked}
											/>
										} // Design-Ad Changes
									/>
								</div>
							</div>
						</div>
					</div>
					{task.userResponseTypeID !== 0 &&
						task.taskHierarchyDTO.length === 0 && (
							<div
								className='add-task'
								onClick={(event) => {
									event.stopPropagation();
									handleAddTask();
									if (event.pageY > 600 && event.pageX < 971) {
										setTaskPosition('position-down');
									} else if (event.pageY > 600 && event.pageX > 970) {
										setTaskPosition('position-right');
									} else {
										setTaskPosition('');
									}
								}}>
								<AddTaskPlus width='16px' />
							</div>
						)}

					<div
						className={
							taskPosition === 'position-down'
								? 'actions-lists-top'
								: taskPosition === 'position-right'
								? 'actions-lists-left'
								: 'open-actions-lists'
						}>
						<AddLinkedActionMenu
							open={addingTask}
							position={draggedPosition}
							onClose={() => {
								setAddingTask(false);

								setIsMoveable(false);
							}}
							previousTaskId={task.task_ID}
						/>
					</div>
				</div>
			</Draggable>
		</>
	);
};
export default Task;
