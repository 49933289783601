import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import {
	createSecondary,
	resetCreateSecondaryGeneralLoader,
	resetSomethingWentWrong,
	setGeneralImageUploadSuccess,
	uploadFile,
} from '../../../Features/configure/configureSlice';
import { FFLoader } from '../../../assets/Icons/Loader';
import { loadState } from '../../../utils/utils';
import './General.scss';
import { useLocation } from 'react-router-dom';
import { set, reset } from '../../../Features/message/messageSlice';

const ConfigureGeneral = () => {
	const fileRef = useRef<HTMLInputElement>(null);
	const botDetails = loadState('botDetails');
	const [value, setValue] = useState(botDetails.botName);
	const [resetV, setReset] = useState(false);
	const [fileSizeExceed, setFileSizeExceed] = useState(false);
	const dispatch = useAppDispatch();
	const {
		botAvatar,
		createSecondaryGeneralLoader,
		changeAvatarLoader,
		generalImageUploadSuccess,
		somethingWentWrong,
	} = useSelector((state: RootState) => state.configure);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const [variable, setVariable] = useState('');
	const [buttonLoader, setButtonLoader] = useState(false);
	const location = useLocation();
	const path = location.pathname;

	const fileUploadInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setVariable('Image');
		if (fileRef.current) {
			let fileInput = fileRef.current;
			var filePath = fileInput.value;
			// Allowing file type
			var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
			if (filePath) {
				if (!allowedExtensions.exec(filePath)) {
					alert('Invalid Media :- Supported media formats are .jpg,.jpeg,.png');
					fileInput.value = '';
					return false;
				} else {
					let file = e.target.files && e.target.files[0];
					const MAX_FILE_SIZE = 5120; // 5MB in binary
					const fileSizeKiloBytes = file && file.size / 1024;
					if (fileSizeKiloBytes! > MAX_FILE_SIZE) {
						setFileSizeExceed(true);

						alert(
							'File size should be maximum 5 MB Format should be PNG or JPEG or JPG'
						);
					} else {
						// e.target.files && setFile(URL.createObjectURL(file!));
						e.target.files &&
							dispatch(
								uploadFile({
									uploadfile: e.target.files[0],
									uploadTo: 'General',
								})
							);
						fileInput.value = '';
					}
				}
			}
		}
	};

	useEffect(() => {
		if (generalImageUploadSuccess) {
			dispatch(set('Image Uploaded Successfully'));
			dispatch(setGeneralImageUploadSuccess(false));
		}

		if (somethingWentWrong === 'General') {
			variable === 'Image' && dispatch(set('Upload Image Failed'));
			dispatch(resetSomethingWentWrong());
		}
	}, [generalImageUploadSuccess, somethingWentWrong]);

	useEffect(() => {
		if (createSecondaryGeneralLoader === 'success') {
			dispatch(set('Configurations Saved Successfully'));
			setButtonLoader(false);
		} else if (createSecondaryGeneralLoader === 'failed') {
			dispatch(set(`Couldn't Save !! Something Went Wrong`));
			setButtonLoader(false);
		} else if (createSecondaryGeneralLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetCreateSecondaryGeneralLoader());
	}, [createSecondaryGeneralLoader]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	return (
		<div className='ff-general-save-btn-div'>
			<div className='configure-general-container'>
				<div className='configure-teamName-input'>
					<h6>
						{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'} Name
					</h6>
					<div className='configure-name-input'>
						<Input
							className='general-input'
							value={value}
							onChange={(event) => setValue(event.target.value)}
							autoFocus
						/>
					</div>
				</div>
				<hr className='configure-separator' />
				<div className='configure-teamIcon-upload'>
					<div>
						<h6>
							{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'} Avatar
						</h6>
					</div>
					<div className={`avatar-reset-buttons reset-hide`}>
						<label htmlFor='configure-ff-media-file'>
							<div className='configure-ff-preview-upload'>
								<div className='configure-ff-file-preview'>
									<img
										src={botAvatar ? botAvatar : botDetails.botIcon}
										alt=''
										onLoad={() => {
											botAvatar && setReset(true);
										}}
									/>
								</div>
								<div className='configure-avatar-reset'>
									<div className={`configure-ff-media-upload`}>
										<>
											{changeAvatarLoader ? (
												<FFLoader width='40' />
											) : resetV ? (
												'Change avatar'
											) : (
												'Add avatar'
											)}
										</>
									</div>
									<p className='file-size-message'>
										File size should be maximum 5 MB Format should be PNG or
										JPEG
									</p>
								</div>
							</div>
						</label>
					</div>
				</div>

				<input
					type='file'
					id='configure-ff-media-file'
					ref={fileRef}
					onChange={fileUploadInput}
					name='ff-media-file'
					style={{ visibility: 'hidden' }}
				/>
			</div>
			<div className='ff-general-save-btn'>
				<Button
					loading={buttonLoader}
					onClick={() => {
						setVariable('Save');
						let data = {
							botDesc: value,
							botId: botDetails.botId,
							bot_displayname: value,
							bot_icon: botAvatar,
							bot_name: value,
							tenantId: tenantId,
							// botType: botDetails.cavSRType,
						};
						dispatch(createSecondary(data));
					}}>
					Save
				</Button>
			</div>
		</div>
	);
};

export default ConfigureGeneral;
