import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import Modal from '../../../../Components/Modal';
import { SnackError } from '../../../../assets/Icons';
import { loadState } from '../../../../utils/utils';
import './subscriptionError.scss'
const SubscriptionError = () => {

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const {
		planName
	} = useSelector((state: RootState) => state.subscription);


	const buttons = () => {
		return (
			<>
				<Button onClick={() => { navigate('/app/select-plan', { state: { via: 'modify' } }) }}>
					Upgrade
				</Button>
			</>
		);
	};
	return (
		<div className='ff-subscription-integration-modal'>
			<Modal actionButtons={buttons()}>
				<SnackError />
				<h4>Upgrade Your Subscription</h4>
				<div className='body1' style={{ marginTop: '10px' }}>{`You are currently on ${loadState('planName')} plan. To use this feature, please upgrade your plan or reach out to us on `}
					<a
						href='mailto:support@flyfish.ai'
						style={{ color: 'var(--violet-600)' }}>
						support@flyfish.ai
					</a></div>
			</Modal>
		</div>
	);
};

export default SubscriptionError;
