import { Alert, Collapse, IconButton } from '@mui/material';
import React from 'react';
import './Note.scss';
import { NoteCloseIcon, NoteError, NoteInfo } from './NoteIcons';

type NoteProps = {
	removeMainIcon?: boolean;
	removeCloseIcon?: boolean;
	severity: 'error' | 'info';
	onClose?: () => void;
	children: React.ReactNode;
};

const Note = ({
	children,
	removeMainIcon = false,
	removeCloseIcon = false,
	onClose,
	severity,
}: NoteProps) => {
	const [open, setOpen] = React.useState(true);
	const iconSelector = () => {
		if (!removeMainIcon) {
			if (severity === 'info') {
				return <NoteInfo />;
			} else if (severity === 'error') {
				return <NoteError />;
			} else {
				return null;
			}
		} else {
			return false;
		}
	};
	const closeNote = () => {
		if (onClose) {
			setOpen(false);
			setTimeout(() => {
				onClose();
			}, 500);
		} else {
			setOpen(false);
		}
	};
	return (
		<>
			<div className='ff-alert-box'>
				<Collapse in={open}>
					<Alert
						icon={iconSelector()}
						onClose={() => closeNote()}
						action={
							removeCloseIcon ? (
								''
							) : (
								<IconButton
									aria-label='close'
									color='inherit'
									size='small'
									onClick={() => {
										closeNote();
									}}>
									<NoteCloseIcon />
								</IconButton>
							)
						}
						severity={severity}>
						{children}
					</Alert>
				</Collapse>
			</div>
		</>
	);
};

export default Note;
