import './InvalidFileSize.scss';
import Modal from '../../../Components/Modal';
import Button from '../../../Components/Button';
import InteractiveButton from '../../../Components/Button/InteractiveButton';
import { CloseBtn } from '../../../Components/Modal/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { getStoredFunction } from '../FunctionStore';

const InvalidFileSize = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const state = location.state;
	const files = state.files;
	const fromUploadModule = state.fromUploadModule;
	const navigateCount = state.navigateCount;

	const actionButtons = () => {
		return (
			<div className='select-again'>
				<Button
					onClick={() => {
						navigate(-1);
						if (fromUploadModule) {
							let multiuploadRef = document.querySelector(
								'#ff-upload-module-input'
							);
							if (multiuploadRef) {
								//@ts-ignore
								multiuploadRef.click();
							}
						} else {
							let multiuploadRef =
								document.querySelector(
									'#ff-ingest-item-upload-button-ingest-tab'
								) || document.querySelector('#ff-ingest-item-upload-button');
							if (multiuploadRef) {
								//@ts-ignore
								multiuploadRef.click();
							}
						}
					}}
					buttonType='light'>
					<p>Select again</p>
				</Button>
				<Button
					onClick={() => {
						let func = getStoredFunction();
						let filteredFiles = files.filter((file: any) => {
							let kb = file.size / 1024;
							let mb = kb / 1024;
							return mb < 5;
						});
						if (fromUploadModule) {
							if (func) {
								func(filteredFiles);
								if (navigateCount) {
									navigate(-`${Number(navigateCount)}`);
								} else {
									navigate(-1);
								}
							}
						} else {
							navigate('../upload-module', {
								state: { files: filteredFiles },
							});
						}
					}}>
					<p>Skip large files</p>
				</Button>
			</div>
		);
	};
	return (
		<div className='ff-invalid-file-size-error'>
			<Modal remove actionButtons={actionButtons()}>
				<InteractiveButton
					icon={<CloseBtn />}
					title='Close'
					hoverable='none'
					onClick={() => navigate(-1)}
				/>
				<div className='error-content'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='44'
						height='44'
						viewBox='0 0 44 44'
						fill='none'>
						<g id='error'>
							<path
								id='Vector'
								d='M21.9998 3.66666C11.8798 3.66666 3.6665 11.88 3.6665 22C3.6665 32.12 11.8798 40.3333 21.9998 40.3333C32.1198 40.3333 40.3332 32.12 40.3332 22C40.3332 11.88 32.1198 3.66666 21.9998 3.66666ZM23.8332 31.1667H20.1665V27.5H23.8332V31.1667ZM23.8332 23.8333H20.1665V12.8333H23.8332V23.8333Z'
								fill='#F86060'
							/>
						</g>
					</svg>
					<h5>Some files exceed size limit</h5>
					<p className='body1'>
						Please make sure that each of your files is less than 5 MB in size.
					</p>
				</div>
			</Modal>
		</div>
	);
};

export default InvalidFileSize;
