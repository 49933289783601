import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import './chartData.scss';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import { DashboardAnalytics } from '../../../Features/Analytics/analyticsSlice';
import { formatMinsecTime, getValuewithK } from '../../../utils/utils';
import Piechart from '../../WhatsAppBSP/Analytics/PieChartContainer';
import DoughnutChart from '../../../Components/DoughtChartContainer';
import BarChart from '../../../Components/BarChartContainer/BarChart';
import star from '../../../assets/images/star.svg';
import { GoArrow } from '../../../assets/Icons';
import ProgressBar from '../../../Components/ProgressBar';
import AnalyticsBar from '../AnalyticsBar';
import { FFLoader } from '../../../assets/Icons/Loader';
import { Tooltip } from '@mui/material';

const ChartData = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // let charDataTest: any = [];
  const { dashboardDate, getchartLoading } = useSelector((state: RootState) => state.analytics);



  const gethighest = (data?: any) => {
    var largest = 0;

    for (let i = 0; i < data.length; i++) {
      if (data[i].percentage > largest) {
        largest = data[i].percentage;
      }
    }
    // let d = data
    return largest
    // console.log(highValue)
  }
  return (
    <div className="chart-data-container">
      {getchartLoading ? (
        <div className='dataloader'>
          <p>Loading Data...</p>
          <FFLoader />
        </div>
      ) : (
        <div className="chart-data-header">
          <Tooltip title={"Total no of conversations"} placement='top-start'>
            <div
              className='ff-analytics-each-card'
            >
              <span className="box-heading body2">Total Customer engagements</span>
              <span className="box-generated total-cust-chats">
                {dashboardDate.noOfConversation ? getValuewithK(dashboardDate.noOfConversation) : '-'}
                <span onClick={() => {
                  navigate('/app/analytics/conversations');
                }}
                  style={{ cursor: 'pointer' }}
                ><GoArrow /></span>
              </span>
              <span className={`${dashboardDate.comp_noOfConversation && dashboardDate.comp_noOfConversation.toString().indexOf('-') > -1
                ? 'negative-percent '
                : ''
                }box-footer`}>{dashboardDate.comp_noOfConversation ? parseInt(dashboardDate.comp_noOfConversation) : '0'}%</span>
            </div>
          </Tooltip>
          <Tooltip title={"Total no of leads generated"} placement='top-start'>
            <div className='ff-analytics-each-card'>
              <span className="box-heading body2">Leads Generated</span>
              <span className="box-generated">
                {dashboardDate.leadGenerated ? getValuewithK(dashboardDate.leadGenerated) : '-'}
              </span>
              <span className={`${dashboardDate.comp_leadGenerated && dashboardDate.comp_leadGenerated.toString().indexOf('-') > -1
                ? 'negative-percent '
                : ''
                }box-footer`}>{dashboardDate.comp_leadGenerated ? parseInt(dashboardDate.comp_leadGenerated) : '0'}%</span>
            </div>
          </Tooltip>
          <Tooltip title={"Average time for a session"} placement='top-start'>
            <div className='ff-analytics-each-card'>
              <span className="box-heading body2">Avg. Conversation Duration</span>
              <span className="box-generated">
                {dashboardDate.avgChatDuration ? formatMinsecTime(dashboardDate.avgChatDuration) : '-'}
              </span>
              <span className={`${dashboardDate.comp_avgChatDuration && dashboardDate.comp_avgChatDuration.toString().indexOf('-') > -1
                ? 'negative-percent '
                : ''
                }box-footer`}>{dashboardDate.comp_avgChatDuration && dashboardDate.comp_avgChatDuration.toString().indexOf('-') > -1 ? `- ${formatMinsecTime(dashboardDate.comp_avgChatDuration)}` : dashboardDate.comp_avgChatDuration ? formatMinsecTime(dashboardDate.comp_avgChatDuration) : '0:0:0'}</span>
            </div>
          </Tooltip>
          <Tooltip title={"Average no of messages per session"} placement='top-start'>
            <div className='ff-analytics-each-card'>
              <span className="box-heading body2">Avg. Conversation Length</span>
              <span className="box-generated">
                {dashboardDate.avgChatLength ? getValuewithK(dashboardDate.avgChatLength) + ' messages' : '-'}
              </span>
              <span className={`${dashboardDate.comp_avgChatLength && dashboardDate.comp_avgChatLength.toString().indexOf('-') > -1
                ? 'negative-percent '
                : ''
                }box-footer`}>{dashboardDate.comp_avgChatLength ? parseInt(dashboardDate.comp_avgChatLength) : '0'}%</span>
            </div>
          </Tooltip>
          <Tooltip title={"Total no of handoff's to live agent/expert"} placement='top-start'>
            <div className='ff-analytics-each-card'

            >
              <span className="box-heading body2">Handoffs to Live Experts</span>
              <span className="box-generated">
                {dashboardDate.handsOffs ? getValuewithK(dashboardDate.handsOffs) : '-'}
              </span>
              <span className={`${dashboardDate.comp_handsOffs && dashboardDate.comp_handsOffs.toString().indexOf('-') > -1
                ? 'negative-percent '
                : ''
                }box-footer`}>{dashboardDate.comp_handsOffs ? parseInt(dashboardDate.comp_handsOffs) : '0'}%</span>
            </div>
          </Tooltip>
          {/* <div className='ff-analytics-each-card'>
            <span className="box-heading body2">Avg. Customer Ratings</span>
            <span className="box-generated">
              {dashboardDate.avgUserRating ? getValuewithK(dashboardDate.avgUserRating) : '-'}
            </span>
            <span className={`${dashboardDate.comp_avgUserRating && dashboardDate.comp_avgUserRating.toString().indexOf('-') > -1
              ? 'negative-percent '
              : ''
              }box-footer`}>{dashboardDate.comp_avgUserRating ? parseInt(dashboardDate.comp_avgUserRating) : '0'}%</span>
          </div> */}
          <Tooltip title={"Different no of channels used for interacting with FlyFish"} placement='top-start'>
            <div className='ff-analytics-each-card channel-card-analytics'>
              <span className="box-heading body2">
                Channels
              </span>

              <DoughnutChart
                isLoading={false}
                data={dashboardDate.conversationByChannel}
                // data={testdata}
                title="Channels"
                description='Conversation by channel'
                colors={['#5531d3', '#e54e9a']}
              />

            </div>
          </Tooltip>
          <Tooltip title={"Total no of Feedbacks received for messages"} placement='top-start'>
            <div className='ff-analytics-each-card total-chart-feedback'
            >
              <span className="box-heading body2">Feedback Responses</span>
              <>
                {
                  dashboardDate.conversationFeedback ?

                    <>
                      <div className="data-content1-feedback" style={{ width: '100%', height: "130px !important" }}>
                        <AnalyticsBar
                          data={dashboardDate.conversationFeedback || 0}
                          type="feedback"
                          total={gethighest(dashboardDate.conversationFeedback)}
                        />
                      </div>

                    </>

                    :
                    "-"
                }
              </>
              <span className='box-footer'
                style={{ cursor: 'pointer' }} onClick={() => {
                  navigate('/app/analytics/feedbackdata');
                }}>See Chats</span>
            </div>
          </Tooltip>
          <Tooltip title={"Ratings provided by the user for a session"} placement='top-start'>
            <div className="ff-analytics-each-card ff-channel-user-ratings-card">

              {

                dashboardDate.total_ratings ?
                  <>
                    <div className='box-content-chart'>
                      <span className="box-heading body2">User Ratings</span>
                      <span className="box-generated">
                        <span style={{ display: "flex" }}>{dashboardDate.overall_userRating ? dashboardDate.overall_userRating.toFixed(1) : 0}
                          <img width='16' height='16' src={star} alt='star' /><p>/5</p>
                        </span>
                        <span className='total-ratings-span'>({dashboardDate.total_ratings ? dashboardDate.total_ratings : '0'} ratings)</span>

                      </span>
                      <span className={`${dashboardDate.comp_userrating && dashboardDate.comp_userrating.toString().indexOf('-') > -1
                        ? 'negative-percent '
                        : ''
                        }box-footer`}>{dashboardDate.comp_userrating ? parseInt(dashboardDate.comp_userrating) : '0'}%</span>
                    </div>
                    <div className='line-break-chart'></div>
                    <div className="data-content1" style={{ width: '100%' }}>

                      <AnalyticsBar
                        data={dashboardDate.conversationByUserRating || 0}
                        type="ratings"
                        total={gethighest(dashboardDate.conversationByUserRating)}
                      />
                    </div>
                  </>
                  :
                  <div className='no-data'>
                    <p className='body2'>No ratings in the selected date range</p>
                  </div>
              }
            </div>
          </Tooltip>
          <div className='ff-analytics-each-card channel-card-analytics'>
            <span className="box-heading body2">
              Countries
            </span>
            <span className="box-generated dropoff-coming-soon">
              %%
            </span>
            <span className="box-heading body2 dropoff-coming-soon">
              Coming Soon
            </span>
            {/* <DoughnutChart
              isLoading={false}
              data={dashboardDate.conversationByCountry}
              // data={testcoun}
              title="Countries"
              description='Conversation by countries'
              colors={['#5531d3', '#e54e9a']}
            /> */}

          </div>
          <div className='ff-analytics-each-card'>
            <span className="box-heading body2">
              Revenue Generated
            </span>
            <span className="box-generated dropoff-coming-soon">
              {dashboardDate.revenueGenerated ? getValuewithK(dashboardDate.revenueGenerated) : '$$'}
            </span>
            <span className="box-heading body2 dropoff-coming-soon">
              Coming Soon
            </span>
          </div>
          <div className='ff-analytics-each-card'>
            <span className="box-heading body2">
              Dropoff Rate
            </span>
            <span className="box-generated dropoff-coming-soon">
              {dashboardDate.dropOffRate ? getValuewithK(dashboardDate.dropOffRate) : '%%'}
            </span>
            <span className="box-heading body2 dropoff-coming-soon">
              Coming Soon
            </span>
          </div>


        </div>
      )}
    </div>
  );
};

export default ChartData;
