import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../app/store';
import Modal from '../../../../Components/Modal';
import { logout } from '../../../../Features/auth/authSlice';
import '../changepassword/changepassword.scss';
import { clearPasswordResponse } from '../../../../Features/ChangePassword/ChangePassSlice';

function PasswordChangeSuccess() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		setTimeout(() => {
			dispatch(clearPasswordResponse());
			dispatch(logout());
		}, 3000);
	}, []);

	return (
		<div>
			<Modal header='' actionButtons={false}>
				<div className='ff-change-password'>Redirecting to Login Page</div>
			</Modal>
		</div>
	);
}

export default PasswordChangeSuccess;
