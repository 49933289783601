import { useAppDispatch } from '../../../../app/store';
import Modal from '../../../../Components/Modal';
import ActionsMenu from '../../ActionsMenu';
import './NextNewAction.scss';

type NextNewActionProps = {
	open: boolean;
	onClose: () => void;
	setNextActionTypeId: React.Dispatch<number>;
	setOpenNextActionType: React.Dispatch<boolean>;
	setLabel: React.Dispatch<string>;
};

const NextNewAction = ({
	open,
	setOpenNextActionType,
	onClose,
	setNextActionTypeId,
	setLabel,
}: NextNewActionProps) => {
	const handleNextActionType = (type: number, label: string) => {
		setNextActionTypeId(type);
		setOpenNextActionType(false);
		setLabel(label);
	};

	return open ? (
		<Modal
			header='Add Action'
			actionButtons={<></>}
			onClose={onClose}
			className='next-new-action-modal'>
			<ActionsMenu
				action1={{
					label: <p>Show Text & Media</p>, //Design-Ad Changes
					onClick: () => handleNextActionType(1, 'Show Text & Media'),
				}}
				action5={{
					label: <p>Multiple Choice Options</p>,
					onClick: () => handleNextActionType(5, 'Multiple Choice Options'),
				}}
				action3={{
					label: <p>Multiple Select Options</p>,
					onClick: () => handleNextActionType(3, 'Multiple Select Options'),
				}}
				action150={{
					label: <p>Collect User Input</p>,
					onClick: () => handleNextActionType(150, 'Collect User Input'),
				}}
				action11={{
					label: <p>Collect User Rating</p>,
					onClick: () => handleNextActionType(11, 'Collect User Rating'),
				}}
				action10={{
					label: <p>Call an API</p>,
					onClick: () => handleNextActionType(10, 'Call an API'),
				}}
				action0={{
					label: <p>Handoff To Agent</p>,
					onClick: () => handleNextActionType(0, 'Handoff To Agent'),
				}}
				action12={{
					label: <p>Trigger another Query</p>,
					onClick: () => handleNextActionType(12, 'Trigger another Query'),
				}}
				action14={{
					label: <p>Send Collected Values</p>,
					onClick: () => handleNextActionType(14, 'Send Collected Values'),
				}}
				action8={{
					label: <p>Confirm Collected Values</p>,
					onClick: () => handleNextActionType(8, 'Confirm Collected Values'),
				}}
			/>
		</Modal>
	) : (
		<></>
	);
};
export default NextNewAction;
