import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { config, EOWState, uploadBot, uploadFile } from './model';
import experienceonwebService from './experienceonwebService';
import { BOT_ICONS } from '../../Pages/CatalogScreen/AddNewBot';
import { rgbToHex } from '../../utils/utils';
import { setTenantFile } from '../teamsetting/teamsettingSlice';

export const uploadImage = createAsyncThunk<
	any,
	uploadFile,
	{ rejectValue: string }
>('/eow/uploadImage', async (data, { rejectWithValue, dispatch }) => {
	try {
		if (data.uploadTo === '') {
			dispatch(setAvatarImageLoader('loading'));
		}
		if (data.uploadTo === 'BotHeader') {
			dispatch(setHeaderImageLoader('loading'));
		}
		if (data.uploadTo === 'WelcomeBanner') {
			dispatch(setEOWWelcomeBannerLoader('loading'));
		}
		if (data.uploadTo === 'BackgroundImage') {
			dispatch(setBackgroundImageLoader('loading'));
		}
		if (data.uploadTo === 'WelcomeCarousel') {
			dispatch(setWelcomeCarouselLoader('loading'));
		}
		if (data.uploadTo == 'Fullscreen WelcomeCarousel') {
			dispatch(setWelcomeCarouselLoaderFullScreen('loading'));
		}
		const response = await experienceonwebService.uploadImage(data);
		if (response.status === 202 || response.status === 200) {
			let imageURL = response.data.url;
			if (data.uploadTo === '') {
				dispatch(setAvatarImageLoader('success'));
				dispatch(setAvatarImage(imageURL));
			}
			if (data.uploadTo === 'BotHeader') {
				dispatch(setHeaderImageLoader('success'));
				dispatch(setHeaderFile(imageURL));
			}
			if (data.uploadTo === 'WelcomeBanner') {
				dispatch(setEOWWelcomeBannerLoader('success'));
				dispatch(setWelcomBannerFile(imageURL));
			}
			if (data.uploadTo === 'BackgroundImage') {
				dispatch(setBackgroundImageLoader('success'));
				dispatch(setBackgroundFile(imageURL));
			}
			if (data.uploadTo === 'TeamSetting General') {
				dispatch(setTenantFile(response.data.url));
			}
			if (data.uploadTo === 'WelcomeCarousel') {
				dispatch(setWelcomeCarouselLoader('success'));
				dispatch(setWelcomeCarouselFile(imageURL));
			}
			if (data.uploadTo === 'Fullscreen WelcomeCarousel') {
				dispatch(setWelcomeCarouselLoaderFullScreen('success'));
				dispatch(setWelcomeCarouselFileFullScreen(imageURL));
			}
			if (data.uploadTo === 'Fullscreen MonacoWelcome') {
				dispatch(setWelcomeMonacoImage(imageURL));
			}
			if (data.uploadTo === 'Fullscreen E-com') {
				dispatch(setWelcomeEcomImage(imageURL));
			}
			if (data.uploadTo === 'e-comSplashLogo') {
				dispatch(setEcomlogo(imageURL));
			}
		}
	} catch (error:any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const getAIDetails = createAsyncThunk<
	any,
	config,
	{ rejectValue: string }
>('/getAIDetails', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await experienceonwebService.getAIDetails(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		console.log('Network error');
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const updateAIDetails = createAsyncThunk<
	any,
	uploadBot,
	{ rejectValue: string }
>('/updateAIDetails', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await experienceonwebService.updateAIDetails(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		console.log('Network error');
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const getTaskType = createAsyncThunk<
	any,
	{ tenantId: number },
	{ rejectValue: string }
>('/getTaskType', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await experienceonwebService.getTaskType(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		console.log('Network error');
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

const initialState: EOWState = {
	//just a placeholder remove with respective states
	disableSave: false,
	disableSaveSubHead: false,
	disableSaveProductCount: false,
	commerceStatus: '',
	commerceLoader: '',
	//Avatar section
	showavatar: [false],
	avatarImage: '',
	//header section
	headerContentType: 'text',
	aiName: 'My AI',
	headerIconPosition: 'internal',
	headerColor: 'var(--white)',
	headerTextAndIconColor: 'var(--text-colour)',
	//background section
	botBackground: 'gradient',
	backgroundSolidColor: '#EDF1FF',
	backgroundGradientStartColor: '#ffffff',
	backgroundGradientStopColor: '#EDF1FF',
	backgroundGradientRange: '100',
	appearanceBackgroundLoader: false,
	backgroundFile: '',
	headerFile: '',
	headerContentLoader: false,
	//font section
	fontFamily: { label: 'Open Sans', value: 'Open Sans' },
	fontSize: { label: 'Normal', value: '16px' },
	//messges section
	aiMessageBubbleColor: '#4B50F6',
	aiMessageTextColor: '#ffffff',
	userMessageBubbleColor: '#ffffff',
	userMessageTextColor: '#444760',
	timeStampBckColor: '#ffffff',
	timeStampTextColor: '#444760',
	timeStampBckRange: '0',
	timeStampTextRange: '100',
	responseLevelFeedBackBckColor: '#000000',
	responseLevelFeedBackIconColor: '#322C55',
	responseLevelFeedBackBorderColor: '#BDBDBD',
	responseLevelFeedBackBckRange: '0',
	responseLevelFeedBackBorderRange: '100',
	userComment: [true],
	userFeedBack: [false],
	//options & buttons section
	optionsAndQuickLinksBckColor: '#ffffff',
	optionsAndQuickLinksTextColor: '#444760',
	optionsAndQuickLinksBorderColor: '#ffffff',
	optionsAndQuickLinksBckRange: '100',
	optionsAndQuickLinksBorderRange: '0',
	buttonBckColor: '#ffffff',
	buttonTextColor: '#444760',
	buttonTextAndOutlineColor: '#ffffff',
	//Input box section
	inputBoxBackgroundColor: '#ffffff',
	inputBoxTextColor: '#000000',
	inputBoxBckRange: '100',
	inputBoxTextRange: '100',
	//Starting the Chat section
	welcomeBannerCheckbox: [false],
	welcomeBannerFile: '',
	welcomeCarouselFile: '',
	welcomeCarouselFileFullScreen: '',
	welcomeMonacoImage: '',
	welcomeEcomImage: '',
	welcomeEcomLogo: '',
	autoPopupValue: [false],
	welcomeMessage: 'Hi! How can I help you?',
	cardHeading: 'Hi! How can I help you?',
	cardBody: 'Hi! How can I help you?',
	carouselTitle: 'Carousel title',
	showQuickLinks: [false],
	inputFieldsData: ['', ''],
	carouselFieldsData: [],
	carouselFieldsDataFullscreen: [],
	aiLaunchPromptCheckbox: [true], //botLaunchPromptCheckbox
	aiLaunchPromptText: 'Hi! How can I help you?', //botLaunchPrompt
	launchPromptTextColor: '#000000',
	launchPromptBackgroundColor: '#ffffff',
	eowWelcomeBannerLoader: '',
	welcomeCarouselLoader: '',
	welcomeCarouselLoaderFullScreen: '',
	botAvatar: '',
	//persistent Menu section
	persistentMenuCheckbox: [false],
	menuItems: 'Show same items as quick links',
	autoPopupTime: '',
	persistentinputFieldsData: ['', ''],
	imageLoader: '',
	imageLoadFailMsg: '',
	imageURL: '',
	headerImageLoader: '',
	backgroundImageLoader: '',
	avatarImageLoader: '',

	//Multilingual
	isMultiLingualEnabled: [true],
	translationApiKey: '',
	languageOptions: [
		{ label: 'Arabic', value: 'Arabic' },
		{ label: 'Bengali (Bangla)', value: 'Bengali (Bangla)' },
		{ label: 'Chinese (Simplified)', value: 'Chinese (Simplified)' },
		{ label: 'German', value: 'German' },
		{ label: 'Hindi', value: 'Hindi' },
		{ label: 'Indonesian', value: 'Indonesian' },
		{ label: 'Japanese', value: 'Japanese' },
		{ label: 'Kannada', value: 'Kannada' },
		{ label: 'Nepali', value: 'Nepali' },
		{ label: 'Portuguese', value: 'Portuguese' },
		{ label: 'Spanish', value: 'Spanish' },
	],
	availableLanguages: [],
	dropdownValue: [],
	invalidtranslationkey: '',

	//Actions
	//--Raise a Ticket
	ratname: [true],
	ratPhonenumber: [true],
	ratEmail: [true],
	ratIssuedescription: [true],
	ratTicketManagement: null,
	ratButtonText: 'Raise a Ticket',
	enableRAT: false,
	//--Generate Leads
	generateLeadsname: [true],
	generateLeadsCompany: [true],
	generateLeadsCountry: [true],
	generateLeadsPhonenumber: [true],
	generateLeadsEmail: [true],
	generateLeadsDestination: null,
	generateLeadsButtonText: 'Generate Leads',
	enableGenerateLeads: false,

	//--BookAppointment
	bookAptname: [true],
	bookAptCompany: [true],
	bookAptCountry: [true],
	bookAptPhonenumber: [true],
	bookAptEmail: [true],
	bookAptDate: [true],
	bookAptTime: [true],
	bookAptManagement: null,
	bookAptButtonText: 'Book Appointment',
	enableBookAPT: false,
	enableCCC: false,
	childTabs: [],
	//--Callcustomer care
	callcustomercareButtonText: '',
	callcustomercarePhonenumber: '',
	//chat with human enable
	enableCHE: false,
	//--Chat with human -- expert availability
	notify: true,
	emailtonotify: '',
	agentTimeZone: '',
	agentAvailabilityTime: [],
	//--Chat with human -- handoff triggers
	onfailureCheckbox: true,
	onUserErrorCheckbox: true,
	negativeSentimentCheckbox: true,
	failureValue: 3,
	userErrorValue: 3,
	negativeSentimentValue: 3,
	//--Chat with human -- buttons & messages

	unavailabilityMessageInside:
		'Sorry, none of our experts is around currently. Please try after sometime.',
	unavailabilityMessageOutside:
		'Sorry, none of our experts is around currently. Our experts are available on weekdays from 9 am to 5 pm',
	handoffConfirmationPrompt:
		'Shall I connect you with a human expert to resolve your query?',
	handoffMessage: 'Sure, connecting you to one of our experts. Please wait…',
	waitTimeMessage: 'Please wait, our expert will be with you shortly',
	handoffCancellationMessage: 'No problem, can I help you with something else?',
	handoffEndMessage:
		'I hope your issue was resolved. Is there something else that I can help you with?',
	buttonText: '',
	//API response of getAIDetails
	aiDetails: { workFlow: [], botConfig: [] },
	saveButtonLoader: false,
	//botPreview
	showBotPreviewCollapsed: true,

	//newBOT preview
	homeiconsvgfilled: true,
	getintouchiconsvgfilled: false,
	currentScreen: 'homescreen',
	conversationOpener: 'Hi! How can I help you?',
	//colors to apply
	colorScale: [
		'rgb(36,0,93)',
		'#4800BA',
		'rgb(92,28,193)',
		'rgb(112,56,201)',
		'rgb(133,85,209)',
		'rgb(153,113,216)',
		'rgb(173,141,224)',
		'rgb(194,170,232)',
		'rgb(214,198,239)',
		'rgb(234,226,247)',
	],
	selectedColor: '#4800BA',
	colorMode: 'dark',
	//DragToReorderList container
	checkList: [
		{ sequence: '1', title: 'Name', data: {} },
		{ sequence: '2', title: 'Company', data: {} },
		{ sequence: '3', title: 'Country', data: {} },
		{ sequence: '4', title: 'Phone number', data: {} },
		{ sequence: '5', title: 'Email', data: {} },
	],
	bookAptCheckList: [
		{ sequence: '1', title: 'Name', data: {} },
		{ sequence: '2', title: 'Company', data: {} },
		{ sequence: '3', title: 'Country', data: {} },
		{ sequence: '4', title: 'Phone number', data: {} },
		{ sequence: '5', title: 'Email', data: {} },
		{ sequence: '6', title: 'Appointment date', data: {} },
		{ sequence: '7', title: 'Appointment time', data: {} },
	],
	ratCheckList: [
		{ sequence: '1', title: 'Name', data: {} },
		{ sequence: '2', title: 'Phone number', data: {} },
		{ sequence: '3', title: 'Email', data: {} },
		{ sequence: '4', title: 'Issue description', data: {} },
	],

	taskTypes: [
		{
			id: 0,
			name: '',
			validation: '',
		},
	],
	taskTypesDropdown: [],
	taskTypeSelected: { label: '', value: '' },
	generateLeadsCollectionPrompt: '',
	bookAptCollectionPrompt: '',
	ratCollectionPrompt: '',
	additionalsettingDetailsCollection: [false],
	additionalsettingMandatoryCollection: [false],
	//Fullscreen
	introductoryHeading: 'This is the welcome message',
	introductoryBody: 'This is the Introductory text',
	productResultCount: 3,
	currentConfigTab: '',
	optionsCheckList: [],
	optionsBookAptChecklist: [],
	optionsRatCheckList: [],
	agentTimeSchedule: [],
	liveChatLeadsname: [false],
	liveChatLeadsCompany: [false],
	liveChatLeadsCountry: [false],
	liveChatLeadsPhonenumber: [false],
	liveChatLeadsEmail: [false],
	liveChatLeadsCollectionPrompt: '',
	additionalsettingMandatoryLeads: [false],
	liveChatcheckList: [
		{ sequence: '1', title: 'Name', data: {} },
		{ sequence: '2', title: 'Company', data: {} },
		{ sequence: '3', title: 'Country', data: {} },
		{ sequence: '4', title: 'Phone number', data: {} },
		{ sequence: '5', title: 'Email', data: {} },
	],
	themes: [
		{ label: 'Default', value: 'Default' },
		{ label: 'Basic', value: 'Basic' },
		{ label: 'Enhanced', value: 'Enhanced' },
		{ label: 'Monaco', value: 'Monaco' },
		{ label: 'E-commerce', value: 'E-commerce' },
		{ label: 'E-commerce Chat', value: 'E-commerce Chat' },
		{ label: 'Ecom New Search', value: 'Ecom New Search' },
		{ label: 'Herovired Leadgen', value: 'Herovired Leadgen' },
	],
	selectedTheme: { label: 'Default', value: 'Default' },
	variants: [
		{ label: 'Product Color', value: 'Product Color' },
		{ label: 'Size', value: 'Size' },
	],
	selectedVariants: [],
	button: [
		{ label: 'Add to Cart', value: 'Add to Cart' },
		{ label: 'Add to Favourites', value: 'Add to Favourites' },
	],
	selectedButton: [],
	recommendationType: '',
	recommendationHeading: 'You may also need',
	showOnHover: false,
	recommendationCount: 1,
	additionalRecommendation: false,
	customPrompts: [false],
	getAIDetailsLoader: false,
	enableSplash: false,
	enableStyleAdvisor: false,
	styleAdvisorTitle: 'Style Advisor',
	selectedDomain: '',
	imageUploadStatusMsg: '',
	styleError: false,
};

const experienceonwebSlice = createSlice({
	name: 'experienceonweb',
	initialState,
	reducers: {
		setDisableSave: (state, action) => {
			state.disableSave = action.payload;
		},
		setVariants: (state, action) => {
			state.variants = action.payload;
		},
		setDisableSaveSubHead: (state, action) => {
			state.disableSaveSubHead = action.payload;
		},
		setDisableSaveProductCount: (state, action) => {
			state.disableSaveProductCount = action.payload;
		},
		setCurrentConfigTab: (state, action) => {
			state.currentConfigTab = action.payload;
		},
		//Avatar section
		setShowAvatar: (state, action) => {
			state.showavatar = action.payload;
		},
		setAvatarImage: (state, action) => {
			state.avatarImage = action.payload;
		},
		//Header section
		setAIName: (state, action) => {
			state.aiName = action.payload;
		},
		setHeaderContentType: (state, action) => {
			state.headerContentType = action.payload;
		},
		setHeaderIconPosition: (state, action) => {
			state.headerIconPosition = action.payload;
		},
		setHeaderColor: (state, action) => {
			state.headerColor = action.payload;
		},
		setHeaderTextAndIconColor: (state, action) => {
			state.headerTextAndIconColor = action.payload;
		},
		//Background section
		setBotBackground: (state, action) => {
			state.botBackground = action.payload;
		},
		setBackgroundSolidColor: (state, action) => {
			state.backgroundSolidColor = action.payload;
		},
		setBackgroundGradientStartColor: (state, action) => {
			state.backgroundGradientStartColor = action.payload;
		},
		setBackgroundGradientStopColor: (state, action) => {
			state.backgroundGradientStopColor = action.payload;
		},
		setBackgroundGradientRange: (state, action) => {
			state.backgroundGradientRange = action.payload;
		},
		setBackgroundFile: (state, action) => {
			state.backgroundFile = action.payload;
		},
		setAppearanceBackgroundLoader: (state, action) => {
			state.appearanceBackgroundLoader = action.payload;
		},
		setHeaderFile: (state, action) => {
			state.headerFile = action.payload;
		},
		setWelcomBannerFile: (state, action) => {
			state.welcomeBannerFile = action.payload;
		},
		setWelcomeCarouselFile: (state, action) => {
			state.welcomeCarouselFile = action.payload;
		},
		setWelcomeCarouselFileFullScreen: (state, action) => {
			state.welcomeCarouselFileFullScreen = action.payload;
		},
		setWelcomeMonacoImage: (state, action) => {
			state.welcomeMonacoImage = action.payload;
		},
		setWelcomeEcomImage: (state, action) => {
			state.welcomeEcomImage = action.payload;
		},
		setEcomlogo: (state, action) => {
			state.welcomeEcomLogo = action.payload;
		},
		//font section
		setFontFamily: (state, action) => {
			state.fontFamily = action.payload;
		},
		setFontSize: (state, action) => {
			state.fontSize = action.payload;
		},
		//messages section
		setAIMessageBubbleColor: (state, action) => {
			state.aiMessageBubbleColor = action.payload;
		},
		setAIMessageTextColor: (state, action) => {
			state.aiMessageTextColor = action.payload;
		},
		setUserMessageBubbleColor: (state, action) => {
			state.userMessageBubbleColor = action.payload;
		},
		setUserMessageTextColor: (state, action) => {
			state.userMessageTextColor = action.payload;
		},
		//options & buttons section
		setOptionsAndQuickLinksBckColor: (state, action) => {
			state.optionsAndQuickLinksBckColor = action.payload;
		},
		setOptionsAndQuickLinksTextColor: (state, action) => {
			state.optionsAndQuickLinksTextColor = action.payload;
		},
		setOptionsAndQuickLinksBorderColor: (state, action) => {
			state.optionsAndQuickLinksBorderColor = action.payload;
		},
		setOptionsAndQuickLinksBckRange: (state, action) => {
			state.optionsAndQuickLinksBckRange = action.payload;
		},
		setOptionsAndQuickLinksBorderRange: (state, action) => {
			state.optionsAndQuickLinksBorderRange = action.payload;
		},
		setButtonBckColor: (state, action) => {
			state.buttonBckColor = action.payload;
		},
		setButtonTextColor: (state, action) => {
			state.buttonTextColor = action.payload;
		},
		setButtonTextAndOutlineColor: (state, action) => {
			state.buttonTextAndOutlineColor = action.payload;
		},
		//inputbox section
		setInputBoxBckRange: (state, action) => {
			state.inputBoxBckRange = action.payload;
		},
		setInputBoxTextRange: (state, action) => {
			state.inputBoxTextRange = action.payload;
		},
		setInputBoxTextColor: (state, action) => {
			state.inputBoxTextColor = action.payload;
		},
		setInputBoxBackgroundColor: (state, action) => {
			state.inputBoxBackgroundColor = action.payload;
		},
		//Starting the Chat section
		setWelcomeBannerCheckbox: (state, action) => {
			state.welcomeBannerCheckbox = action.payload;
		},
		setautoPopupValue: (state, action) => {
			state.autoPopupValue = action.payload;
		},
		setWelcomeMessage: (state, action) => {
			state.welcomeMessage = action.payload;
		},
		setCardHeading: (state, action) => {
			state.cardHeading = action.payload;
		},
		setCardBody: (state, action) => {
			state.cardBody = action.payload;
		},
		setCarouselTitle: (state, action) => {
			state.carouselTitle = action.payload;
		},
		setShowQuickLinks: (state, action) => {
			state.showQuickLinks = action.payload;
		},
		setInputFields: (state, action) => {
			state.inputFieldsData = action.payload;
		},
		setCarouselFields: (state, action) => {
			state.carouselFieldsData = action.payload;
		},
		setCarouselFieldsDataFullscreen: (state, action) => {
			state.carouselFieldsDataFullscreen = action.payload;
		},
		setAILaunchPromptCheckbox: (state, action) => {
			state.aiLaunchPromptCheckbox = action.payload;
		},
		setAILaunchPromptText: (state, action) => {
			state.aiLaunchPromptText = action.payload;
		},
		setLaunchPromptBackgroundColor: (state, action) => {
			state.launchPromptBackgroundColor = action.payload;
		},
		setLaunchPromptTextColor: (state, action) => {
			state.launchPromptTextColor = action.payload;
		},
		setEOWWelcomeBannerLoader: (state, action) => {
			state.eowWelcomeBannerLoader = action.payload;
		},
		setBotAvater: (state, action) => {
			state.botAvatar = action.payload;
		},
		setPersistentMenuCheckbox: (state, action) => {
			state.persistentMenuCheckbox = action.payload;
		},
		setMenuItems: (state, action) => {
			state.menuItems = action.payload;
		},
		setTimeStampBckColor: (state, action) => {
			state.timeStampBckColor = action.payload;
		},
		setTimeStampTextColor: (state, action) => {
			state.timeStampTextColor = action.payload;
		},
		setTimeStampBckRange: (state, action) => {
			state.timeStampBckRange = action.payload;
		},
		setTimeStampTextRange: (state, action) => {
			state.timeStampTextRange = action.payload;
		},
		setResponseLevelFeedBackBckColor: (state, action) => {
			state.responseLevelFeedBackBckColor = action.payload;
		},
		setResponseLevelFeedBackIconColor: (state, action) => {
			state.responseLevelFeedBackIconColor = action.payload;
		},
		setResponseLevelFeedBackBorderColor: (state, action) => {
			state.responseLevelFeedBackBorderColor = action.payload;
		},
		setResponseLevelFeedBackBckRange: (state, action) => {
			state.responseLevelFeedBackBckRange = action.payload;
		},
		setResponseLevelFeedBackBorderRange: (state, action) => {
			state.responseLevelFeedBackBorderRange = action.payload;
		},
		setUserComment: (state, action) => {
			state.userComment = action.payload;
		},
		setUserFeedback: (state, action) => {
			state.userFeedBack = action.payload;
		},
		setAutoPopupTime: (state, action) => {
			state.autoPopupTime = action.payload;
		},
		setPersistentCustomInputFields: (state, action) => {
			state.persistentinputFieldsData = action.payload;
		},
		setImageLoader: (state, action) => {
			state.imageLoader = action.payload;
		},
		setHeaderImageLoader: (state, action) => {
			state.headerImageLoader = action.payload;
		},
		setBackgroundImageLoader: (state, action) => {
			state.backgroundImageLoader = action.payload;
		},
		setAvatarImageLoader: (state, action) => {
			state.avatarImageLoader = action.payload;
		},
		setIsMultilingualEnabled: (state, action) => {
			state.isMultiLingualEnabled = action.payload;
		},
		setTranslationApiKey: (state, action) => {
			state.translationApiKey = action.payload;
		},
		setDropdownvalue: (state, action) => {
			state.dropdownValue = action.payload;
		},
		resetInvalidtranslationkey: (state) => {
			state.invalidtranslationkey = '';
		},
		setRatName: (state, action) => {
			state.ratname = action.payload;
		},
		setRatPhoneNumber: (state, action) => {
			state.ratPhonenumber = action.payload;
		},
		setRatEmail: (state, action) => {
			state.ratEmail = action.payload;
		},
		setRatIssueDescription: (state, action) => {
			state.ratIssuedescription = action.payload;
		},
		setRatTicketManagement: (state, action) => {
			state.ratTicketManagement = action.payload;
		},
		setRatButtonText: (state, action) => {
			state.ratButtonText = action.payload;
		},
		//Genrate leads
		setGLName: (state, action) => {
			state.generateLeadsname = action.payload;
		},
		setGenerateLeadsCompany: (state, action) => {
			state.generateLeadsCompany = action.payload;
		},
		setGenerateLeadsCountry: (state, action) => {
			state.generateLeadsCountry = action.payload;
		},
		setGenerateLeadsPhonenumber: (state, action) => {
			state.generateLeadsPhonenumber = action.payload;
		},
		setGenerateLeadsEmail: (state, action) => {
			state.generateLeadsEmail = action.payload;
		},
		setGenerateLeadsDestination: (state, action) => {
			state.generateLeadsDestination = action.payload;
		},
		setGenerateLeadsButtonText: (state, action) => {
			state.generateLeadsButtonText = action.payload;
		},
		//Book Apt
		setBookAptName: (state, action) => {
			state.bookAptname = action.payload;
		},
		setBookAptCompany: (state, action) => {
			state.bookAptCompany = action.payload;
		},
		setBookAptCountry: (state, action) => {
			state.bookAptCountry = action.payload;
		},
		setBookAptPhonenumber: (state, action) => {
			state.bookAptPhonenumber = action.payload;
		},
		setBookAptEmail: (state, action) => {
			state.bookAptEmail = action.payload;
		},
		setBookAptDate: (state, action) => {
			state.bookAptDate = action.payload;
		},
		setBookAptTime: (state, action) => {
			state.bookAptTime = action.payload;
		},
		setBookAptManagement: (state, action) => {
			state.bookAptManagement = action.payload;
		},
		setBookAptButtonText: (state, action) => {
			state.bookAptButtonText = action.payload;
		},
		setCCCButtonText: (state, action) => {
			state.callcustomercareButtonText = action.payload;
		},
		setCCCPhoneNumber: (state, action) => {
			state.callcustomercarePhonenumber = action.payload;
		},
		setNotifyExpertsAvailability: (state, action) => {
			state.notify = action.payload;
		},
		setEmailToNotify: (state, action) => {
			state.emailtonotify = action.payload;
		},
		setOnfailureCheckbox: (state, action) => {
			state.onfailureCheckbox = action.payload;
		},
		setOnUserErrorCheckbox: (state, action) => {
			state.onUserErrorCheckbox = action.payload;
		},
		setNegativeSentimentCheckbox: (state, action) => {
			state.negativeSentimentCheckbox = action.payload;
		},
		setFailureValue: (state, action) => {
			state.failureValue = action.payload;
		},
		setUserErrorValue: (state, action) => {
			state.userErrorValue = action.payload;
		},
		setNegativeSentimentValue: (state, action) => {
			state.negativeSentimentValue = action.payload;
		},
		//Chat with human --buttons & messages
		setButtonText: (state, action) => {
			state.buttonText = action.payload;
		},
		setAgentAvailability: (state, action) => {
			state.agentAvailabilityTime = action.payload;
		},

		setAgentTimeZone: (state, action) => {
			state.agentTimeZone = action.payload;
		},
		setUnavailabilityMessageInside: (state, action) => {
			state.unavailabilityMessageInside = action.payload;
		},
		setUnavailabilityMessageOutside: (state, action) => {
			state.unavailabilityMessageOutside = action.payload;
		},
		setHandoffConfirmationPrompt: (state, action) => {
			state.handoffConfirmationPrompt = action.payload;
		},
		setHandoffMessage: (state, action) => {
			state.handoffMessage = action.payload;
		},
		setWaitTimeMessage: (state, action) => {
			state.waitTimeMessage = action.payload;
		},
		setHandoffCancellationMessage: (state, action) => {
			state.handoffCancellationMessage = action.payload;
		},
		setHandoffEndMessage: (state, action) => {
			state.handoffEndMessage = action.payload;
		},
		setEnableRAT: (state, action) => {
			state.enableRAT = action.payload;
		},

		setEnableCHE: (state, action) => {
			state.enableCHE = action.payload;
		},
		setEnableCCC: (state, action) => {
			state.enableCCC = action.payload;
		},
		setEnableGenerateLeads: (state, action) => {
			state.enableGenerateLeads = action.payload;
		},
		setEnableBookAPT: (state, action) => {
			state.enableBookAPT = action.payload;
		},
		setShowBotPreviewCollapsed: (state, action) => {
			state.showBotPreviewCollapsed = action.payload;
		},
		setHomeIconSVGFilled: (state, action) => {
			state.homeiconsvgfilled = action.payload;
		},
		setGenInTouchIconSVGFilled: (state, action) => {
			state.getintouchiconsvgfilled = action.payload;
		},

		setCurrentScreen: (state, action) => {
			state.currentScreen = action.payload;
		},

		setColorScale: (state, action) => {
			state.colorScale = action.payload;
		},
		setSelectedColor: (state, action) => {
			state.selectedColor = action.payload;
		},
		setColorMode: (state, action) => {
			state.colorMode = action.payload;
		},
		setChildTabs: (state, action) => {
			state.childTabs = action.payload;
		},

		setConversationOpener: (state, action) => {
			state.conversationOpener = action.payload;
		},
		setWelcomeCarouselLoader: (state, action) => {
			state.welcomeCarouselLoader = action.payload;
		},
		setWelcomeCarouselLoaderFullScreen: (state, action) => {
			state.welcomeCarouselLoaderFullScreen = action.payload;
		},
		setCheckList: (state, action) => {
			state.checkList = action.payload;
		},
		setBookAptCheckList: (state, action) => {
			state.bookAptCheckList = action.payload;
		},
		setRatCheckList: (state, action) => {
			state.ratCheckList = action.payload;
		},
		setTaskTypes: (state, action) => {
			state.taskTypes = action.payload;
		},
		setTaskTypeSelected: (state, action) => {
			state.taskTypeSelected = action.payload;
		},
		setGenerateLeadsCollectionPrompt: (state, action) => {
			state.generateLeadsCollectionPrompt = action.payload;
		},
		setBookAptCollectionPrompt: (state, action) => {
			state.bookAptCollectionPrompt = action.payload;
		},
		setRatCollectionPrompt: (state, action) => {
			state.ratCollectionPrompt = action.payload;
		},
		setAdditionalsettingDetailsCollection: (state, action) => {
			state.additionalsettingDetailsCollection = action.payload;
		},
		setAdditionalsettingMandatoryCollection: (state, action) => {
			state.additionalsettingMandatoryCollection = action.payload;
		},
		//Fullscreen
		setIntroductoryHeading(state, action) {
			state.introductoryHeading = action.payload;
		},
		setIntroductoryText(state, action) {
			state.introductoryBody = action.payload;
		},
		setProductResultCount(state, action) {
			state.productResultCount = action.payload;
		},
		setOptionsCheckList: (state, action) => {
			state.optionsCheckList = action.payload;
		},
		setOptionsBookAptCheckList: (state, action) => {
			state.optionsBookAptChecklist = action.payload;
		},
		setOptionsRatCheckList: (state, action) => {
			state.optionsRatCheckList = action.payload;
		},
		//Livechatleads
		setadditionalsettingMandatoryLeads: (state, action) => {
			state.additionalsettingMandatoryLeads = action.payload;
		},
		setliveChatLeadsCollectionPrompt: (state, action) => {
			state.liveChatLeadsCollectionPrompt = action.payload;
		},
		setliveChatLeadsCompany: (state, action) => {
			state.liveChatLeadsCompany = action.payload;
		},
		setliveChatLeadsCountry: (state, action) => {
			state.liveChatLeadsCountry = action.payload;
		},
		setliveChatLeadsEmail: (state, action) => {
			state.liveChatLeadsEmail = action.payload;
		},
		setliveChatLeadsPhonenumber: (state, action) => {
			state.liveChatLeadsPhonenumber = action.payload;
		},
		setliveChatcheckList: (state, action) => {
			state.liveChatcheckList = action.payload;
		},
		setliveChatLeadsname: (state, action) => {
			state.liveChatLeadsname = action.payload;
		},

		setAgentTimingSchedule: (state, action) => {
			state.agentTimeSchedule = action.payload;
		},
		setSelectedTheme: (state, action) => {
			state.selectedTheme = action.payload;
		},
		setSelectedVariants: (state, action) => {
			state.selectedVariants = action.payload;
		},
		setSelectedButton: (state, action) => {
			state.selectedButton = action.payload;
		},
		setRecommendationType: (state, action) => {
			state.recommendationType = action.payload;
		},
		setRecommendationHeading: (state, action) => {
			state.recommendationHeading = action.payload;
		},
		setShowOnHover: (state, action) => {
			state.showOnHover = action.payload;
		},
		setrecommendationCount: (state, action) => {
			state.recommendationCount = action.payload;
		},
		setAdditionalRecommendation: (state, action) => {
			state.additionalRecommendation = action.payload;
		},
		setCustomPrompts: (state, action) => {
			state.customPrompts = action.payload;
		},
		setSplashImage: (state, action) => {
			state.enableSplash = action.payload;
		},
		setEnablestyleadvisor: (state, action) => {
			state.enableStyleAdvisor = action.payload;
		},
		setStyleAdvisorTitle: (state, action) => {
			state.styleAdvisorTitle = action.payload;
		},
		setSelectedDomain: (state, action) => {
			state.selectedDomain = action.payload;
		},
		setImageUploadStatusMsg: (state, action) => {
			state.imageUploadStatusMsg = action.payload;
		},
		setStyleError: (state, action) => {
			state.styleError = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(uploadImage.pending, (state) => {
				state.imageLoader = 'loading';
			})
			.addCase(uploadImage.fulfilled, (state, action) => {
				state.imageLoader = 'success';
				state.imageURL = action.payload;
			})
			.addCase(uploadImage.rejected, (state, action) => {
				state.imageLoader = 'failed';
				state.avatarImageLoader = 'failed';
				state.backgroundImageLoader = 'failed';
				state.headerImageLoader = 'failed';
				state.eowWelcomeBannerLoader = 'failed';
				state.imageUploadStatusMsg = action.payload ? action.payload : '';
				state.imageLoadFailMsg = action.payload ? action.payload : '';
			})
			.addCase(getAIDetails.pending, (state) => {
				state.getAIDetailsLoader = true;
			})
			.addCase(getAIDetails.fulfilled, (state, action) => {
				state.getAIDetailsLoader = false;

				state.aiDetails = action.payload.data;
				let botConfig = action.payload.data.botConfig;
				let workFlow = action.payload.data.workFlow;
				//Experience on web
				if (botConfig.length > 0) {
					for (let config of botConfig) {
						//--Avatar
						if (config.configName === 'showAvatar') {
							state.showavatar =
								config.value.toLowerCase() === 'false' ? [false] : [true];
						}
						if (config.configName === 'avatarImage') {
							state.avatarImage =
								config.value === '' ? BOT_ICONS[0] : config.value;
						}
						//--Header
						if (config.configName === 'headerContentType') {
							state.headerContentType =
								config.value === 'image' ? 'image' : 'text';
						}
						if (config.configName === 'aiName') {
							state.aiName = config.value ? config.value : 'My AI';
						}
						if (config.configName === 'headerIconPosition') {
							state.headerIconPosition =
								config.value === 'external' ? 'external' : 'internal';
						}
						if (config.configName === 'headerBackgroundColor') {
							state.headerColor = config.value ? config.value : '#ffffff';
						}
						if (config.configName === 'headerTextAndIconColor') {
							state.headerTextAndIconColor = config.value
								? config.value
								: '#000000';
						}
						if (config.configName === 'headerImage') {
							state.headerFile = config.value ? config.value : '';
						}
						//--Background
						if (config.configName === 'backgroundType') {
							state.botBackground = config.value ? config.value : 'gradient';
						}
						if (
							config.configName === 'backgroundColor' &&
							state.botBackground === 'color'
						) {
							state.backgroundSolidColor = config.value
								? config.value
								: 'var(--violet-50)';
						}
						if (config.configName === 'backgroundColor') {
							state.backgroundGradientStartColor = config.value.includes(
								'linear'
							)
								? config.value
										.substring(16)
										.split(')')[0]
										.split(',')[0]
								: '#ffffff';
							state.backgroundGradientStopColor = config.value.includes(
								'linear'
							)
								? config.value
										.substring(16)
										.split(')')[0]
										.split(',')[1]
								: 'var(--violet-50)';
							state.backgroundGradientRange = `linear-gradient(${state.backgroundGradientStartColor},${state.backgroundGradientStopColor})`;
						}
						if (config.configName === 'backgroundImage') {
							state.backgroundFile = config.value ? config.value : '';
						}
						//--Font
						if (config.configName === 'fontFamily') {
							state.fontFamily =
								config.value !== ''
									? { label: config.value, value: config.value }
									: { label: 'Open Sans', value: 'Open Sans' };
						}
						if (config.configName === 'fontSize') {
							state.fontSize =
								config.value !== ''
									? { label: config.value, value: config.value }
									: { label: 'Normal', value: '16px' };
						}
						//--InputBox
						if (config.configName === 'inputBoxBackgroundColor') {
							state.inputBoxBackgroundColor = config.value
								? config.value
								: 'var(--white)';
						}
						if (config.configName === 'inputBoxTextColor') {
							state.inputBoxTextColor = config.value
								? config.value
								: ' var(--black-60)';
						}
						//--Messages
						if (config.configName === 'userMessageBubbleColor') {
							state.userMessageBubbleColor = config.value
								? config.value
								: 'var(--white)';
						}
						if (config.configName === 'userMessageTextColor') {
							state.userMessageTextColor = config.value
								? config.value
								: '#192033';
						}
						if (config.configName === 'aiMessageBubbleColor') {
							state.aiMessageBubbleColor = config.value
								? config.value
								: 'var(--violet-1000)';
						}
						if (config.configName === 'aiMessageTextColor') {
							state.aiMessageTextColor = config.value
								? config.value
								: 'var(--white)';
						}
						if (config.configName === 'timeStampBckColor') {
							state.timeStampBckColor = config.value
								? config.value
								: 'var(--white)';
							state.timeStampBckRange = config.value.includes('rgba')
								? rgbToHex(config.value, true)
								: '0';
						}
						if (config.configName === 'timeStampTextColor') {
							state.timeStampTextColor = config.value
								? config.value
								: 'var(--black)';
						}
						if (config.configName === 'responseLevelFeedBackBckColor') {
							state.responseLevelFeedBackBckColor = config.value
								? config.value
								: 'var(--white)';
							state.responseLevelFeedBackBckRange = config.value.includes(
								'rgba'
							)
								? rgbToHex(config.value, true)
								: '0';
						}
						if (config.configName === 'responseLevelFeedBackBorderColor') {
							state.responseLevelFeedBackBorderColor = config.value
								? config.value
								: '#b7b7d8';
							state.responseLevelFeedBackBorderRange = config.value.includes(
								'rgba'
							)
								? rgbToHex(config.value, true)
								: '0';
						}
						if (config.configName === 'responseLevelFeedBackIconColor') {
							state.responseLevelFeedBackBckColor = config.value
								? config.value
								: '#555591';
						}
						if (config.configName === 'userComment') {
							state.userComment =
								config.value.toLowerCase() === 'false' ? [false] : [true];
						}
						if (config.configName === 'userFeedBack') {
							state.userFeedBack =
								config.value.toLowerCase() === 'false' ? [false] : [true];
						}
						//--Options & Buttons
						if (config.configName === 'optionsAndQuickLinksBckColor') {
							state.optionsAndQuickLinksBckColor = config.value
								? config.value
								: 'var(--white)';
							state.optionsAndQuickLinksBckRange = config.value.includes('rgba')
								? rgbToHex(config.value, true)
								: '0';
						}
						if (config.configName === 'optionsAndQuickLinksBorderColor') {
							state.optionsAndQuickLinksBorderColor = config.value
								? config.value
								: 'var(--black)';
							state.optionsAndQuickLinksBorderRange = config.value.includes(
								'rgba'
							)
								? rgbToHex(config.value, true)
								: '0';
						}
						if (config.configName === 'optionsAndQuickLinksTextColor') {
							state.optionsAndQuickLinksTextColor = config.value
								? config.value
								: '#ec008c';
						}
						if (config.configName === 'buttonBckColor') {
							state.buttonBckColor = config.value
								? config.value
								: 'var(--white)';
						}
						if (config.configName === 'buttonTextColor') {
							state.buttonTextColor = config.value ? config.value : '#ef4078';
						}
						if (config.configName === 'buttonTextAndOutlineColor') {
							state.buttonTextAndOutlineColor = config.value
								? config.value
								: 'var(--white)';
						}
						//PersistentMenu
						if (config.configName === 'menuItems') {
							state.menuItems = config.value
								? config.value
								: 'Show same items as quick links';
						}
						if (config.configName === 'enablePersistentMenu') {
							state.persistentMenuCheckbox =
								config.value.toLowerCase() === 'false' ? [false] : [true];
						}
						if (config.configName === 'persistentMenuCustomQuicklinks') {
							let persistentinputFields: string[] = [];
							let dataReceived =
								config.value && config.value !== ''
									? JSON.parse(config.value)
									: '';
							if (
								typeof dataReceived === 'object' &&
								dataReceived.length > 0 &&
								dataReceived[0].linkText
							) {
								dataReceived.map((data: any) => {
									persistentinputFields.push(data.linkText);
									persistentinputFields.push(data.linkValue);
								});
								state.persistentinputFieldsData = [...persistentinputFields];
							} else if (
								typeof dataReceived === 'string' &&
								dataReceived.length >= 0
							) {
								state.persistentinputFieldsData = ['', ''];
							}
						}
						//Starting the Chat
						if (config.configName === 'aiLaunchPromptCheckbox') {
							state.aiLaunchPromptCheckbox =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
						if (config.configName === 'MonacoWelcomeImage') {
							state.welcomeMonacoImage = config.value;
						}
						if (config.configName === 'e-comSplashLogo') {
							state.welcomeEcomLogo = config.value;
						}
						if (config.configName === 'E-comChatWelcomeImg') {
							state.welcomeEcomImage = config.value;
						}
						if (config.configName === 'aiLaunchPromptText') {
							state.aiLaunchPromptText = config.value
								? config.value
								: 'Hi! How can I help you?';
						}
						if (config.configName === 'launchPromptBackgroundColor') {
							state.launchPromptBackgroundColor = config.value
								? config.value
								: 'var(--violet-1000)';
						}
						if (config.configName === 'launchPromptTextColor') {
							state.launchPromptTextColor = config.value
								? config.value
								: 'var(--white)';
						}
						if (config.configName === 'autoPopupValue') {
							state.autoPopupValue =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
						if (config.configName === 'autoPopupTime') {
							state.autoPopupTime = config.value ? config.value : '0';
						}
						if (config.configName === 'showWelcomeBanner') {
							state.welcomeBannerCheckbox =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
						if (config.configName === 'welcomeBannerImage') {
							state.welcomeBannerFile = config.value ? config.value : '';
						}
						//chat

						if (config.configName === 'agentSupport') {
							state.enableCHE =
								config.value.toLowerCase() === 'true' ? true : false;
						}
						if (config.configName === 'customercareSupport') {
							state.enableCCC =
								config.value.toLowerCase() === 'true' ? true : false;
						}
						if (config.configName === 'agentTimings') {
							state.agentAvailabilityTime = config.value
								? JSON.parse(config.value)
								: '';
						}
						if (config.configName === 'agentTimezone') {
							state.agentTimeZone = config.value ? config.value : '';
						}
						if (config.configName === 'agentMailNotification') {
							state.notify =
								config.value.toLowerCase() === 'true' ? true : false;
						}
						if (config.configName === 'agentEmailAddress') {
							state.emailtonotify = config.value ? config.value : '';
						}
						if (config.configName === 'enableHandOffTriggersOnFailure') {
							state.onfailureCheckbox =
								config.value.toLowerCase() === 'true' ? true : false;
						}
						if (config.configName === 'enableHandOffTriggersOnError') {
							state.onUserErrorCheckbox =
								config.value.toLowerCase() === 'true' ? true : false;
						}
						if (config.configName === 'enableHandOffTriggersOnNegative') {
							state.negativeSentimentCheckbox =
								config.value.toLowerCase() === 'true' ? true : false;
						}
						if (config.configName === 'handOffTriggersOnFailureValue') {
							state.failureValue = config.value ? config.value : '';
						}
						if (config.configName === 'handOffTriggersOnErrorValue') {
							state.userErrorValue = config.value ? config.value : '';
						}
						if (config.configName === 'handOffTriggersOnNegativeValue') {
							state.negativeSentimentValue = config.value ? config.value : '';
						}
						if (config.configName === 'chatWithHumanButtonText') {
							state.buttonText = config.value ? config.value : '';
						}
						if (config.configName === 'agentUnavailabilityMessageWorkHours') {
							state.unavailabilityMessageInside = config.value
								? config.value
								: '';
						}
						if (
							config.configName === 'agentUnavailabilityMessageOutsideWorkHours'
						) {
							state.unavailabilityMessageOutside = config.value
								? config.value
								: '';
						}
						if (config.configName === 'handOffConfirmationPrompt') {
							state.handoffConfirmationPrompt = config.value
								? config.value
								: '';
						}
						if (config.configName === 'handOffmessage') {
							state.handoffMessage = config.value ? config.value : '';
						}
						if (config.configName === 'waitTimeMessage') {
							state.waitTimeMessage = config.value ? config.value : '';
						}
						if (config.configName === 'handOffCancellationMessage') {
							state.handoffCancellationMessage = config.value
								? config.value
								: '';
						}
						if (config.configName === 'handOffEndMessage') {
							state.handoffEndMessage = config.value ? config.value : '';
						}

						if (config.configName === 'welcomeMessage') {
							state.welcomeMessage = config.value
								? config.value
								: 'Hi! How can I help you?';
						}
						if (config.configName === 'cardHeading') {
							state.cardHeading = config.value
								? config.value
								: 'Hi! How can I help you?';
						}
						if (config.configName === 'cardBody') {
							state.cardBody = config.value
								? config.value
								: 'Hi! How can I help you?';
						}
						if (config.configName === 'carouselTitle') {
							state.carouselTitle = config.value
								? config.value
								: 'Hi! How can I help you?';
						}

						if (config.configName === 'selectedColor') {
							state.selectedColor = config.value ? config.value : '#4800BA';
						}
						if (config.configName === 'colorScale') {
							state.colorScale = config.value
								? JSON.parse(config.value)
								: [
										'rgb(36,0,93)',
										'#4800BA',
										'rgb(92,28,193)',
										'rgb(112,56,201)',
										'rgb(133,85,209)',
										'rgb(153,113,216)',
										'rgb(173,141,224)',
										'rgb(194,170,232)',
										'rgb(214,198,239)',
										'rgb(234,226,247)',
								  ];
						}
						if (config.configName === 'conversationOpener') {
							state.conversationOpener = config.value
								? config.value
								: 'Hi! How can I help you?';
						}
						if (config.configName === 'colorMode') {
							state.colorMode = config.value ? config.value : 'dark';
						}
						if (config.configName === 'quickLinks') {
							let inputFields: string[] = [];
							let dataReceived =
								config.value && config.value !== ''
									? JSON.parse(config.value)
									: '';
							if (
								typeof dataReceived === 'object' &&
								dataReceived.length > 0 &&
								dataReceived[0].linkText
							) {
								dataReceived.map((data: any) => {
									inputFields.push(data.linkText);
									inputFields.push(data.linkValue);
								});
								state.showQuickLinks = [true];
								state.inputFieldsData = [...inputFields];
							} else if (
								typeof dataReceived === 'string' &&
								dataReceived.length >= 0
							) {
								state.inputFieldsData = ['', ''];
							}
						}
						//config fullscreen
						if (config.configName === 'FSCarouselFieldsData') {
							let inputFields: string[] = [];
							let dataReceived =
								config.value && config.value !== ''
									? JSON.parse(config.value)
									: '';
							if (
								typeof dataReceived === 'object' &&
								dataReceived.length > 0 &&
								dataReceived[0].linkText
							) {
								dataReceived.map((data: any) => {
									inputFields.push(data.linkText);
									inputFields.push(data.linkValue);
								});
								state.showQuickLinks = [true];
								state.carouselFieldsDataFullscreen = [...inputFields];
							} else if (
								typeof dataReceived === 'string' &&
								dataReceived.length >= 0
							) {
								state.carouselFieldsDataFullscreen = [];
							}
						}
						if (config.configName === 'FSWelcomeHeading') {
							state.introductoryHeading = config.value
								? config.value
								: 'This is the welcome message';
						}
						if (config.configName === 'FSWelcomeSubHeader') {
							state.introductoryBody = config.value
								? config.value
								: 'This is the Introductory text';
						}
						if (config.configName === 'productResultCount') {
							state.productResultCount = config.value
								? Number(config.value)
								: 3;
						}
						if (config.configName === 'selectedTheme') {
							state.selectedTheme = config.value
								? {
										label: config.value === 'monaco' ? 'Monaco' : config.value,
										value: config.value,
								  }
								: state.selectedTheme;
						}
						if (config.configName === 'selectedVariants') {
							state.selectedVariants = config.value
								? JSON.parse(config.value)
								: state.selectedVariants;
						}
						if (config.configName === 'selectedButton') {
							state.selectedButton = config.value
								? JSON.parse(config.value)
								: state.selectedButton;
						}
						if (config.configName === 'additionalRecommendation') {
							state.additionalRecommendation = config.value
								? Boolean(config.value).valueOf()
								: state.additionalRecommendation;
						}
						if (config.configName === 'recommendationType') {
							state.recommendationType = config.value
								? config.value
								: state.recommendationType;
						}
						if (config.configName === 'recommendationHeading') {
							state.recommendationHeading = config.value
								? config.value
								: state.recommendationHeading;
						}
						if (config.configName === 'enableSplashImage') {
							state.enableSplash = config.value
								? JSON.parse(config.value)
								: state.enableSplash;
						}

						if (config.configName === 'enableStyleAdvisor') {
							state.enableStyleAdvisor = config.value
								? JSON.parse(config.value)
								: state.enableStyleAdvisor;
						}

						if (config.configName === 'advisorTitle') {
							state.styleAdvisorTitle = config.value
								? config.value
								: state.styleAdvisorTitle;
						}

						if (config.configName === 'recommendationCount') {
							state.recommendationCount = config.value
								? parseInt(config.value)
								: state.recommendationCount;
						}
						if (config.configName === 'showOnHover') {
							state.showOnHover = config.value
								? config.value === 'true'
									? true
									: false
								: state.showOnHover;
						}

						//carousel cards
						if (config.configName === 'carouselFieldsData') {
							let inputFields: string[] = [];
							let dataReceived =
								config.value && config.value !== ''
									? JSON.parse(config.value)
									: '';
							if (
								typeof dataReceived === 'object' &&
								dataReceived.length > 0 &&
								dataReceived[0].linkText
							) {
								dataReceived.map((data: any) => {
									inputFields.push(data.linkText);
									inputFields.push(data.linkValue);
								});
								state.showQuickLinks = [true];
								state.carouselFieldsData = [...inputFields];
							} else if (
								typeof dataReceived === 'string' &&
								dataReceived.length >= 0
							) {
								state.carouselFieldsData = [];
							}
						}
						//--Actions
						//----CCC
						if (config.configName === 'cusomercareCaption') {
							state.callcustomercareButtonText = config.value
								? config.value
								: 'Call Customer Care';
						}
						if (config.configName === 'cusomercareContact') {
							state.callcustomercarePhonenumber = config.value
								? config.value
								: '';
						}
						//----CWH  -- experts availability
						//---CWH -- buttons & messages
						//---CWH -- Handofftriggers
						//--Multilingual
						if (config.configName === 'supportedLanguage') {
							state.dropdownValue = config.value
								? JSON.parse(config.value)
								: '';
						}
						if (config.configName === 'enableMultiLingual') {
							state.isMultiLingualEnabled =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
						if (config.configName === 'translationkey') {
							state.translationApiKey = config.value ? config.value : '';
						}
						if (config.configName === 'additionalsettingDetailsCollection') {
							state.additionalsettingDetailsCollection =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
						if (config.configName === 'additionalsettingMandatoryLeads') {
							state.additionalsettingMandatoryLeads =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}

						if (config.configName === 'additionalsettingMandatoryCollection') {
							state.additionalsettingMandatoryCollection =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
						//New Configs
						if (config.configName === 'customPrompts') {
							state.customPrompts =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
					}
				}
				if (workFlow.length > 0) {
					for (let workflow of workFlow) {
						if (workflow.name.toLowerCase() === 'raise a ticket') {
							state.ratButtonText = workflow.customName
								? workflow.customName
								: 'Raise a ticket';
							state.enableRAT =
								workflow.enabled.toLowerCase().trim() === 'false'
									? false
									: true;
							state.ratTicketManagement = workflow.destination
								? { label: workflow.destination, value: workflow.destination }
								: null;
							state.ratCollectionPrompt =
								workflow.message || 'Please share the following details';
							let finalList: any = [];
							for (let flow of workflow.task) {
								let title;
								if (flow.name.toLowerCase() === 'name') {
									state.ratname =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Name';
								} else if (flow.name.toLowerCase() === 'email') {
									state.ratEmail =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Email';
								} else if (flow.name.toLowerCase() === 'phone number') {
									state.ratPhonenumber =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Phone Number';
								} else if (flow.name.toLowerCase() === 'issue description') {
									state.ratIssuedescription =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Issue Description';
								}
								let checkListing = {
									sequence: flow.sequence,
									title: title ? title : 'Custom Field',
									data: {
										fieldName: flow.name,
										fieldType: {
											label: flow.validationType,
											value: flow.validationType,
										},
										desc: flow.message,
										options: flow.options,
									},
								};

								finalList.push(checkListing);
							}
							finalList.sort(
								(a: any, b: any) =>
									parseInt(a.sequence, 10) - parseInt(b.sequence, 10)
							);

							state.ratCheckList = [...finalList];
						}
						if (workflow.name.toLowerCase() === 'generate leads') {
							state.generateLeadsButtonText = workflow.customName
								? workflow.customName
								: 'Generate leads';
							state.enableGenerateLeads =
								workflow.enabled.toLowerCase().trim() === 'false'
									? false
									: true;
							state.generateLeadsDestination = workflow.destination
								? { label: workflow.destination, value: workflow.destination }
								: null;
							state.generateLeadsCollectionPrompt =
								workflow.message || 'Please share the following details';
							let finalList: any = [];

							for (let flow of workflow.task) {
								let title;

								if (flow.name.toLowerCase() === 'name') {
									state.generateLeadsname =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Name';
								} else if (flow.name.toLowerCase() === 'email') {
									state.generateLeadsEmail =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Email';
								} else if (flow.name.toLowerCase().trim() === 'phone number') {
									state.generateLeadsPhonenumber =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Phone Number';
								} else if (flow.name.toLowerCase() === 'company') {
									state.generateLeadsCompany =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Company';
								} else if (flow.name.toLowerCase() === 'country') {
									state.generateLeadsCountry =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Country';
								}
								let checkListing = {
									sequence: flow.sequence,
									title: title ? title : 'Custom Field',
									data: {
										fieldName: flow.name,
										fieldType: {
											label: flow.validationType,
											value: flow.validationType,
										},
										desc: flow.message,
										options: flow.options,
									},
								};

								finalList.push(checkListing);
							}

							finalList.sort(
								(a: any, b: any) =>
									parseInt(a.sequence, 10) - parseInt(b.sequence, 10)
							);

							state.checkList = [...finalList];
						}
						if (workflow.name.toLowerCase() === 'book appointment') {
							state.bookAptButtonText = workflow.customName
								? workflow.customName
								: 'Book appointment';
							state.enableBookAPT =
								workflow.enabled.toLowerCase().trim() === 'false'
									? false
									: true;
							state.bookAptManagement = workflow.destination
								? { label: workflow.destination, value: workflow.destination }
								: null;
							state.bookAptCollectionPrompt =
								workflow.message || 'Please share the following details';
							let finalList: any = [];

							for (let flow of workflow.task) {
								let title;
								if (flow.name.toLowerCase() === 'name') {
									state.bookAptname =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Name';
								} else if (flow.name.toLowerCase() === 'company') {
									state.bookAptCompany =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Company';
								} else if (flow.name.toLowerCase().trim() === 'phone number') {
									state.bookAptPhonenumber =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Phone Number';
								} else if (flow.name.toLowerCase() === 'country') {
									state.bookAptCountry =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Country';
								} else if (flow.name.toLowerCase() === 'appointment date') {
									state.bookAptDate =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Appointment Date';
								} else if (flow.name.toLowerCase() === 'appointment time') {
									state.bookAptTime =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Appointment Time';
								} else if (flow.name.toLowerCase() === 'email') {
									state.bookAptEmail =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Email';
								}
								let checkListing = {
									sequence: flow.sequence,
									title: title ? title : 'Custom Field',
									data: {
										fieldName: flow.name,
										fieldType: {
											label: flow.validationType,
											value: flow.validationType,
										},
										desc: flow.message,
										options: flow.options,
									},
								};

								finalList.push(checkListing);
							}
							finalList.sort(
								(a: any, b: any) =>
									parseInt(a.sequence, 10) - parseInt(b.sequence, 10)
							);

							state.bookAptCheckList = [...finalList];
						}
						if (workflow.name.toLowerCase() === 'chat with expert') {
							state.buttonText = workflow.customName
								? workflow.customName
								: 'Chat with Experts';
							state.enableCHE =
								workflow.enabled.toLowerCase().trim() === 'false'
									? false
									: true;
							state.liveChatLeadsCollectionPrompt =
								workflow.message || 'Please share the following details';
							let finalList: any = [];

							for (let flow of workflow.task) {
								let title;

								if (flow.name.toLowerCase() === 'name') {
									state.liveChatLeadsname =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Name';
								} else if (flow.name.toLowerCase() === 'email') {
									state.liveChatLeadsEmail =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Email';
								} else if (flow.name.toLowerCase().trim() === 'phone number') {
									state.liveChatLeadsPhonenumber =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Phone Number';
								} else if (flow.name.toLowerCase() === 'company') {
									state.liveChatLeadsCompany =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Company';
								} else if (flow.name.toLowerCase() === 'country') {
									state.liveChatLeadsCountry =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Country';
								}
								let checkListing = {
									sequence: flow.sequence,
									title: title ? title : 'Custom Field',
									data: {
										fieldName: flow.name,
										fieldType: {
											label: flow.validationType,
											value: flow.validationType,
										},
										desc: flow.message,
										options: flow.options,
									},
								};

								finalList.push(checkListing);
							}

							finalList.sort(
								(a: any, b: any) =>
									parseInt(a.sequence, 10) - parseInt(b.sequence, 10)
							);

							state.liveChatcheckList = [...finalList];
						}
					}
				}
			})
			.addCase(getAIDetails.rejected, (state, action) => {
				state.getAIDetailsLoader = false;
			})
			.addCase(updateAIDetails.pending, (state) => {
				state.saveButtonLoader = true;
			})
			.addCase(updateAIDetails.fulfilled, (state, action) => {
				state.saveButtonLoader = false;

				state.aiDetails = action.payload.data;
				let botConfig = action.payload.data.botConfig;
				let workFlow = action.payload.data.workFlow;
				//Experience on web
				if (botConfig.length > 0) {
					for (let config of botConfig) {
						//--Avatar
						if (config.configName === 'showAvatar') {
							state.showavatar =
								config.value.toLowerCase() === 'false' ? [false] : [true];
						}
						if (config.configName === 'avatarImage') {
							state.avatarImage =
								config.value === '' ? BOT_ICONS[0] : config.value;
						}
						//--Header
						if (config.configName === 'headerContentType') {
							state.headerContentType =
								config.value === 'image' ? 'image' : 'text';
						}
						if (config.configName === 'aiName') {
							state.aiName = config.value ? config.value : 'My AI';
						}
						if (config.configName === 'headerIconPosition') {
							state.headerIconPosition =
								config.value === 'external' ? 'external' : 'internal';
						}
						if (config.configName === 'headerBackgroundColor') {
							state.headerColor = config.value ? config.value : '#ffffff';
						}
						if (config.configName === 'headerTextAndIconColor') {
							state.headerTextAndIconColor = config.value
								? config.value
								: '#000000';
						}
						if (config.configName === 'headerImage') {
							state.headerFile = config.value ? config.value : '';
						}
						//--Background
						if (config.configName === 'backgroundType') {
							state.botBackground = config.value ? config.value : 'gradient';
						}
						if (
							config.configName === 'backgroundColor' &&
							state.botBackground === 'color'
						) {
							state.backgroundSolidColor = config.value
								? config.value
								: 'var(--violet-50)';
						}
						if (config.configName === 'backgroundColor') {
							state.backgroundGradientStartColor = config.value.includes(
								'linear'
							)
								? config.value
										.substring(16)
										.split(')')[0]
										.split(',')[0]
								: '#ffffff';
							state.backgroundGradientStopColor = config.value.includes(
								'linear'
							)
								? config.value
										.substring(16)
										.split(')')[0]
										.split(',')[1]
								: 'var(--violet-50)';
							state.backgroundGradientRange = `linear-gradient(${state.backgroundGradientStartColor},${state.backgroundGradientStopColor})`;
						}
						if (config.configName === 'backgroundImage') {
							state.backgroundFile = config.value ? config.value : '';
						}
						//--Font
						if (config.configName === 'fontFamily') {
							state.fontFamily =
								config.value !== ''
									? { label: config.value, value: config.value }
									: { label: 'Open Sans', value: 'Open Sans' };
						}
						if (config.configName === 'fontSize') {
							state.fontSize =
								config.value !== ''
									? { label: config.value, value: config.value }
									: { label: 'Normal', value: '16px' };
						}
						//--InputBox
						if (config.configName === 'inputBoxBackgroundColor') {
							state.inputBoxBackgroundColor = config.value
								? config.value
								: 'var(--white)';
						}
						if (config.configName === 'inputBoxTextColor') {
							state.inputBoxTextColor = config.value
								? config.value
								: ' var(--black-60)';
						}
						//--Messages
						if (config.configName === 'userMessageBubbleColor') {
							state.userMessageBubbleColor = config.value
								? config.value
								: 'var(--white)';
						}
						if (config.configName === 'userMessageTextColor') {
							state.userMessageTextColor = config.value
								? config.value
								: '#192033';
						}
						if (config.configName === 'aiMessageBubbleColor') {
							state.aiMessageBubbleColor = config.value
								? config.value
								: 'var(--violet-1000)';
						}
						if (config.configName === 'aiMessageTextColor') {
							state.aiMessageTextColor = config.value
								? config.value
								: 'var(--white)';
						}
						if (config.configName === 'timeStampBckColor') {
							state.timeStampBckColor = config.value
								? config.value
								: 'var(--white)';
							state.timeStampBckRange = config.value.includes('rgba')
								? rgbToHex(config.value, true)
								: '0';
						}
						if (config.configName === 'timeStampTextColor') {
							state.timeStampTextColor = config.value
								? config.value
								: 'var(--black)';
						}
						if (config.configName === 'responseLevelFeedBackBckColor') {
							state.responseLevelFeedBackBckColor = config.value
								? config.value
								: 'var(--white)';
							state.responseLevelFeedBackBckRange = config.value.includes(
								'rgba'
							)
								? rgbToHex(config.value, true)
								: '0';
						}
						if (config.configName === 'responseLevelFeedBackBorderColor') {
							state.responseLevelFeedBackBorderColor = config.value
								? config.value
								: '#b7b7d8';
							state.responseLevelFeedBackBorderRange = config.value.includes(
								'rgba'
							)
								? rgbToHex(config.value, true)
								: '0';
						}
						if (config.configName === 'responseLevelFeedBackIconColor') {
							state.responseLevelFeedBackBckColor = config.value
								? config.value
								: '#555591';
						}
						if (config.configName === 'userComment') {
							state.userComment =
								config.value.toLowerCase() === 'false' ? [false] : [true];
						}
						if (config.configName === 'userFeedBack') {
							state.userFeedBack =
								config.value.toLowerCase() === 'false' ? [false] : [true];
						}
						//--Options & Buttons
						if (config.configName === 'optionsAndQuickLinksBckColor') {
							state.optionsAndQuickLinksBckColor = config.value
								? config.value
								: 'var(--white)';
							state.optionsAndQuickLinksBckRange = config.value.includes('rgba')
								? rgbToHex(config.value, true)
								: '0';
						}
						if (config.configName === 'optionsAndQuickLinksBorderColor') {
							state.optionsAndQuickLinksBorderColor = config.value
								? config.value
								: 'var(--black)';
							state.optionsAndQuickLinksBorderRange = config.value.includes(
								'rgba'
							)
								? rgbToHex(config.value, true)
								: '0';
						}
						if (config.configName === 'optionsAndQuickLinksTextColor') {
							state.optionsAndQuickLinksTextColor = config.value
								? config.value
								: '#ec008c';
						}
						if (config.configName === 'buttonBckColor') {
							state.buttonBckColor = config.value
								? config.value
								: 'var(--white)';
						}
						if (config.configName === 'buttonTextColor') {
							state.buttonTextColor = config.value ? config.value : '#ef4078';
						}
						if (config.configName === 'buttonTextAndOutlineColor') {
							state.buttonTextAndOutlineColor = config.value
								? config.value
								: 'var(--white)';
						}
						//PersistentMenu
						if (config.configName === 'menuItems') {
							state.menuItems = config.value
								? config.value
								: 'Show same items as quick links';
						}
						if (config.configName === 'enablePersistentMenu') {
							state.persistentMenuCheckbox =
								config.value.toLowerCase() === 'false' ? [false] : [true];
						}
						if (config.configName === 'persistentMenuCustomQuicklinks') {
							let persistentinputFields: string[] = [];
							let dataReceived =
								config.value && config.value !== ''
									? JSON.parse(config.value)
									: '';
							if (
								typeof dataReceived === 'object' &&
								dataReceived.length > 0 &&
								dataReceived[0].linkText
							) {
								dataReceived.map((data: any) => {
									persistentinputFields.push(data.linkText);
									persistentinputFields.push(data.linkValue);
								});
								state.persistentinputFieldsData = [...persistentinputFields];
							} else if (
								typeof dataReceived === 'string' &&
								dataReceived.length >= 0
							) {
								state.persistentinputFieldsData = ['', ''];
							}
						}
						//Starting the Chat
						if (config.configName === 'aiLaunchPromptCheckbox') {
							state.aiLaunchPromptCheckbox =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
						if (config.configName === 'MonacoWelcomeImage') {
							state.welcomeMonacoImage = config.value;
						}
						if (config.configName === 'e-comSplashLogo') {
							state.welcomeEcomLogo = config.value;
						}
						if (config.configName === 'E-comChatWelcomeImg') {
							state.welcomeEcomImage = config.value;
						}
						if (config.configName === 'aiLaunchPromptText') {
							state.aiLaunchPromptText = config.value
								? config.value
								: 'Hi! How can I help you?';
						}
						if (config.configName === 'launchPromptBackgroundColor') {
							state.launchPromptBackgroundColor = config.value
								? config.value
								: 'var(--violet-1000)';
						}
						if (config.configName === 'launchPromptTextColor') {
							state.launchPromptTextColor = config.value
								? config.value
								: 'var(--white)';
						}
						if (config.configName === 'autoPopupValue') {
							state.autoPopupValue =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
						if (config.configName === 'autoPopupTime') {
							state.autoPopupTime = config.value ? config.value : '0';
						}
						if (config.configName === 'showWelcomeBanner') {
							state.welcomeBannerCheckbox =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
						if (config.configName === 'welcomeBannerImage') {
							state.welcomeBannerFile = config.value ? config.value : '';
						}
						//chat

						if (config.configName === 'agentSupport') {
							state.enableCHE =
								config.value.toLowerCase() === 'true' ? true : false;
						}
						if (config.configName === 'customercareSupport') {
							state.enableCCC =
								config.value.toLowerCase() === 'true' ? true : false;
						}
						if (config.configName === 'agentTimings') {
							state.agentAvailabilityTime = config.value
								? JSON.parse(config.value)
								: '';
						}
						if (config.configName === 'agentTimezone') {
							state.agentTimeZone = config.value ? config.value : '';
						}
						if (config.configName === 'agentMailNotification') {
							state.notify =
								config.value.toLowerCase() === 'true' ? true : false;
						}
						if (config.configName === 'agentEmailAddress') {
							state.emailtonotify = config.value ? config.value : '';
						}
						if (config.configName === 'enableHandOffTriggersOnFailure') {
							state.onfailureCheckbox =
								config.value.toLowerCase() === 'true' ? true : false;
						}
						if (config.configName === 'enableHandOffTriggersOnError') {
							state.onUserErrorCheckbox =
								config.value.toLowerCase() === 'true' ? true : false;
						}
						if (config.configName === 'enableHandOffTriggersOnNegative') {
							state.negativeSentimentCheckbox =
								config.value.toLowerCase() === 'true' ? true : false;
						}
						if (config.configName === 'handOffTriggersOnFailureValue') {
							state.failureValue = config.value ? config.value : '';
						}
						if (config.configName === 'handOffTriggersOnErrorValue') {
							state.userErrorValue = config.value ? config.value : '';
						}
						if (config.configName === 'handOffTriggersOnNegativeValue') {
							state.negativeSentimentValue = config.value ? config.value : '';
						}
						if (config.configName === 'chatWithHumanButtonText') {
							state.buttonText = config.value ? config.value : '';
						}
						if (config.configName === 'agentUnavailabilityMessageWorkHours') {
							state.unavailabilityMessageInside = config.value
								? config.value
								: '';
						}
						if (
							config.configName === 'agentUnavailabilityMessageOutsideWorkHours'
						) {
							state.unavailabilityMessageOutside = config.value
								? config.value
								: '';
						}
						if (config.configName === 'handOffConfirmationPrompt') {
							state.handoffConfirmationPrompt = config.value
								? config.value
								: '';
						}
						if (config.configName === 'handOffmessage') {
							state.handoffMessage = config.value ? config.value : '';
						}
						if (config.configName === 'waitTimeMessage') {
							state.waitTimeMessage = config.value ? config.value : '';
						}
						if (config.configName === 'handOffCancellationMessage') {
							state.handoffCancellationMessage = config.value
								? config.value
								: '';
						}
						if (config.configName === 'handOffEndMessage') {
							state.handoffEndMessage = config.value ? config.value : '';
						}

						if (config.configName === 'welcomeMessage') {
							state.welcomeMessage = config.value
								? config.value
								: 'Hi! How can I help you?';
						}
						if (config.configName === 'cardHeading') {
							state.cardHeading = config.value
								? config.value
								: 'Hi! How can I help you?';
						}
						if (config.configName === 'cardBody') {
							state.cardBody = config.value
								? config.value
								: 'Hi! How can I help you?';
						}
						if (config.configName === 'carouselTitle') {
							state.carouselTitle = config.value
								? config.value
								: 'Hi! How can I help you?';
						}

						if (config.configName === 'selectedColor') {
							state.selectedColor = config.value ? config.value : '#4800BA';
						}
						if (config.configName === 'colorScale') {
							state.colorScale = config.value
								? JSON.parse(config.value)
								: [
										'rgb(36,0,93)',
										'#4800BA',
										'rgb(92,28,193)',
										'rgb(112,56,201)',
										'rgb(133,85,209)',
										'rgb(153,113,216)',
										'rgb(173,141,224)',
										'rgb(194,170,232)',
										'rgb(214,198,239)',
										'rgb(234,226,247)',
								  ];
						}
						if (config.configName === 'conversationOpener') {
							state.conversationOpener = config.value
								? config.value
								: 'Hi! How can I help you?';
						}
						if (config.configName === 'colorMode') {
							state.colorMode = config.value ? config.value : 'dark';
						}
						if (config.configName === 'quickLinks') {
							let inputFields: string[] = [];
							let dataReceived =
								config.value && config.value !== ''
									? JSON.parse(config.value)
									: '';
							if (
								typeof dataReceived === 'object' &&
								dataReceived.length > 0 &&
								dataReceived[0].linkText
							) {
								dataReceived.map((data: any) => {
									inputFields.push(data.linkText);
									inputFields.push(data.linkValue);
								});
								state.showQuickLinks = [true];
								state.inputFieldsData = [...inputFields];
							} else if (
								typeof dataReceived === 'string' &&
								dataReceived.length >= 0
							) {
								state.inputFieldsData = ['', ''];
							}
						}
						//config fullscreen
						if (config.configName === 'FSCarouselFieldsData') {
							let inputFields: string[] = [];
							let dataReceived =
								config.value && config.value !== ''
									? JSON.parse(config.value)
									: '';
							if (
								typeof dataReceived === 'object' &&
								dataReceived.length > 0 &&
								dataReceived[0].linkText
							) {
								dataReceived.map((data: any) => {
									inputFields.push(data.linkText);
									inputFields.push(data.linkValue);
								});
								state.showQuickLinks = [true];
								state.carouselFieldsDataFullscreen = [...inputFields];
							} else if (
								typeof dataReceived === 'string' &&
								dataReceived.length >= 0
							) {
								state.carouselFieldsDataFullscreen = [];
							}
						}
						if (config.configName === 'FSWelcomeHeading') {
							state.introductoryHeading = config.value
								? config.value
								: 'This is the welcome message';
						}
						if (config.configName === 'FSWelcomeSubHeader') {
							state.introductoryBody = config.value
								? config.value
								: 'This is the Introductory text';
						}
						if (config.configName === 'productResultCount') {
							state.productResultCount = config.value
								? Number(config.value)
								: 3;
						}
						if (config.configName === 'selectedTheme') {
							state.selectedTheme = config.value
								? {
										label: config.value === 'monaco' ? 'Monaco' : config.value,
										value: config.value,
								  }
								: state.selectedTheme;
						}
						if (config.configName === 'selectedVariants') {
							state.selectedVariants = config.value
								? JSON.parse(config.value)
								: state.selectedVariants;
						}
						if (config.configName === 'selectedButton') {
							state.selectedButton = config.value
								? JSON.parse(config.value)
								: state.selectedButton;
						}
						if (config.configName === 'additionalRecommendation') {
							state.additionalRecommendation = config.value
								? Boolean(config.value).valueOf()
								: state.additionalRecommendation;
						}
						if (config.configName === 'recommendationType') {
							state.recommendationType = config.value
								? config.value
								: state.recommendationType;
						}
						if (config.configName === 'recommendationHeading') {
							state.recommendationHeading = config.value
								? config.value
								: state.recommendationHeading;
						}
						if (config.configName === 'enableSplashImage') {
							state.enableSplash = config.value
								? JSON.parse(config.value)
								: state.enableSplash;
						}

						if (config.configName === 'enableStyleAdvisor') {
							state.enableStyleAdvisor = config.value
								? JSON.parse(config.value)
								: state.enableStyleAdvisor;
						}

						if (config.configName === 'advisorTitle') {
							state.styleAdvisorTitle = config.value
								? config.value
								: state.styleAdvisorTitle;
						}

						if (config.configName === 'recommendationCount') {
							state.recommendationCount = config.value
								? parseInt(config.value)
								: state.recommendationCount;
						}
						if (config.configName === 'showOnHover') {
							state.showOnHover = config.value
								? config.value === 'true'
									? true
									: false
								: state.showOnHover;
						}

						//carousel cards
						if (config.configName === 'carouselFieldsData') {
							let inputFields: string[] = [];
							let dataReceived =
								config.value && config.value !== ''
									? JSON.parse(config.value)
									: '';
							if (
								typeof dataReceived === 'object' &&
								dataReceived.length > 0 &&
								dataReceived[0].linkText
							) {
								dataReceived.map((data: any) => {
									inputFields.push(data.linkText);
									inputFields.push(data.linkValue);
								});
								state.showQuickLinks = [true];
								state.carouselFieldsData = [...inputFields];
							} else if (
								typeof dataReceived === 'string' &&
								dataReceived.length >= 0
							) {
								state.carouselFieldsData = [];
							}
						}
						//--Actions
						//----CCC
						if (config.configName === 'cusomercareCaption') {
							state.callcustomercareButtonText = config.value
								? config.value
								: 'Call Customer Care';
						}
						if (config.configName === 'cusomercareContact') {
							state.callcustomercarePhonenumber = config.value
								? config.value
								: '';
						}
						//----CWH  -- experts availability
						//---CWH -- buttons & messages
						//---CWH -- Handofftriggers
						//--Multilingual
						if (config.configName === 'supportedLanguage') {
							state.dropdownValue = config.value
								? JSON.parse(config.value)
								: '';
						}
						if (config.configName === 'enableMultiLingual') {
							state.isMultiLingualEnabled =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
						if (config.configName === 'translationkey') {
							state.translationApiKey = config.value ? config.value : '';
						}
						if (config.configName === 'additionalsettingDetailsCollection') {
							state.additionalsettingDetailsCollection =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
						if (config.configName === 'additionalsettingMandatoryLeads') {
							state.additionalsettingMandatoryLeads =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}

						if (config.configName === 'additionalsettingMandatoryCollection') {
							state.additionalsettingMandatoryCollection =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
						//New Configs
						if (config.configName === 'customPrompts') {
							state.customPrompts =
								config.value.toLowerCase() === 'true' ? [true] : [false];
						}
					}
				}
				if (workFlow.length > 0) {
					for (let workflow of workFlow) {
						if (workflow.name.toLowerCase() === 'raise a ticket') {
							state.ratButtonText = workflow.customName
								? workflow.customName
								: 'Raise a ticket';
							state.enableRAT =
								workflow.enabled.toLowerCase().trim() === 'false'
									? false
									: true;
							state.ratTicketManagement = workflow.destination
								? { label: workflow.destination, value: workflow.destination }
								: null;
							state.ratCollectionPrompt =
								workflow.message || 'Please share the following details';
							let finalList: any = [];
							for (let flow of workflow.task) {
								let title;
								if (flow.name.toLowerCase() === 'name') {
									state.ratname =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Name';
								} else if (flow.name.toLowerCase() === 'email') {
									state.ratEmail =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Email';
								} else if (flow.name.toLowerCase() === 'phone number') {
									state.ratPhonenumber =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Phone Number';
								} else if (flow.name.toLowerCase() === 'issue description') {
									state.ratIssuedescription =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Issue Description';
								}
								let checkListing = {
									sequence: flow.sequence,
									title: title ? title : 'Custom Field',
									data: {
										fieldName: flow.name,
										fieldType: {
											label: flow.validationType,
											value: flow.validationType,
										},
										desc: flow.message,
										options: flow.options,
									},
								};

								finalList.push(checkListing);
							}
							finalList.sort(
								(a: any, b: any) =>
									parseInt(a.sequence, 10) - parseInt(b.sequence, 10)
							);

							state.ratCheckList = [...finalList];
						}
						if (workflow.name.toLowerCase() === 'generate leads') {
							state.generateLeadsButtonText = workflow.customName
								? workflow.customName
								: 'Generate leads';
							state.enableGenerateLeads =
								workflow.enabled.toLowerCase().trim() === 'false'
									? false
									: true;
							state.generateLeadsDestination = workflow.destination
								? { label: workflow.destination, value: workflow.destination }
								: null;
							state.generateLeadsCollectionPrompt =
								workflow.message || 'Please share the following details';
							let finalList: any = [];

							for (let flow of workflow.task) {
								let title;

								if (flow.name.toLowerCase() === 'name') {
									state.generateLeadsname =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Name';
								} else if (flow.name.toLowerCase() === 'email') {
									state.generateLeadsEmail =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Email';
								} else if (flow.name.toLowerCase().trim() === 'phone number') {
									state.generateLeadsPhonenumber =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Phone Number';
								} else if (flow.name.toLowerCase() === 'company') {
									state.generateLeadsCompany =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Company';
								} else if (flow.name.toLowerCase() === 'country') {
									state.generateLeadsCountry =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Country';
								}
								let checkListing = {
									sequence: flow.sequence,
									title: title ? title : 'Custom Field',
									data: {
										fieldName: flow.name,
										fieldType: {
											label: flow.validationType,
											value: flow.validationType,
										},
										desc: flow.message,
										options: flow.options,
									},
								};

								finalList.push(checkListing);
							}

							finalList.sort(
								(a: any, b: any) =>
									parseInt(a.sequence, 10) - parseInt(b.sequence, 10)
							);

							state.checkList = [...finalList];
						}
						if (workflow.name.toLowerCase() === 'book appointment') {
							state.bookAptButtonText = workflow.customName
								? workflow.customName
								: 'Book appointment';
							state.enableBookAPT =
								workflow.enabled.toLowerCase().trim() === 'false'
									? false
									: true;
							state.bookAptManagement = workflow.destination
								? { label: workflow.destination, value: workflow.destination }
								: null;
							state.bookAptCollectionPrompt =
								workflow.message || 'Please share the following details';
							let finalList: any = [];

							for (let flow of workflow.task) {
								let title;
								if (flow.name.toLowerCase() === 'name') {
									state.bookAptname =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Name';
								} else if (flow.name.toLowerCase() === 'company') {
									state.bookAptCompany =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Company';
								} else if (flow.name.toLowerCase().trim() === 'phone number') {
									state.bookAptPhonenumber =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Phone Number';
								} else if (flow.name.toLowerCase() === 'country') {
									state.bookAptCountry =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Country';
								} else if (flow.name.toLowerCase() === 'appointment date') {
									state.bookAptDate =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Appointment Date';
								} else if (flow.name.toLowerCase() === 'appointment time') {
									state.bookAptTime =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Appointment Time';
								} else if (flow.name.toLowerCase() === 'email') {
									state.bookAptEmail =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Email';
								}
								let checkListing = {
									sequence: flow.sequence,
									title: title ? title : 'Custom Field',
									data: {
										fieldName: flow.name,
										fieldType: {
											label: flow.validationType,
											value: flow.validationType,
										},
										desc: flow.message,
										options: flow.options,
									},
								};

								finalList.push(checkListing);
							}
							finalList.sort(
								(a: any, b: any) =>
									parseInt(a.sequence, 10) - parseInt(b.sequence, 10)
							);

							state.bookAptCheckList = [...finalList];
						}
						if (workflow.name.toLowerCase() === 'chat with expert') {
							state.buttonText = workflow.customName
								? workflow.customName
								: 'Chat with Experts';
							state.enableCHE =
								workflow.enabled.toLowerCase().trim() === 'false'
									? false
									: true;
							state.liveChatLeadsCollectionPrompt =
								workflow.message || 'Please share the following details';
							let finalList: any = [];

							for (let flow of workflow.task) {
								let title;

								if (flow.name.toLowerCase() === 'name') {
									state.liveChatLeadsname =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Name';
								} else if (flow.name.toLowerCase() === 'email') {
									state.liveChatLeadsEmail =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Email';
								} else if (flow.name.toLowerCase().trim() === 'phone number') {
									state.liveChatLeadsPhonenumber =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Phone Number';
								} else if (flow.name.toLowerCase() === 'company') {
									state.liveChatLeadsCompany =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Company';
								} else if (flow.name.toLowerCase() === 'country') {
									state.liveChatLeadsCountry =
										flow.enabled.toLowerCase() === 'true' ? [true] : [false];
									title = 'Country';
								}
								let checkListing = {
									sequence: flow.sequence,
									title: title ? title : 'Custom Field',
									data: {
										fieldName: flow.name,
										fieldType: {
											label: flow.validationType,
											value: flow.validationType,
										},
										desc: flow.message,
										options: flow.options,
									},
								};

								finalList.push(checkListing);
							}

							finalList.sort(
								(a: any, b: any) =>
									parseInt(a.sequence, 10) - parseInt(b.sequence, 10)
							);

							state.liveChatcheckList = [...finalList];
						}
					}
				}
			})
			.addCase(updateAIDetails.rejected, (state, action) => {
				state.saveButtonLoader = false;
			})
			.addCase(getTaskType.pending, (state) => {})
			.addCase(getTaskType.fulfilled, (state, action) => {
				state.taskTypes = action.payload;
				state.taskTypesDropdown = action.payload
					.map((task: { id: number; name: string; validation: string }) => {
						let data = task.name;
						if (task.name === 'Email' || task.name === 'Number') {
							return null;
						}
						return {
							label: data,
							value: data,
						};
					})
					.filter((v: any) => v !== null);
			})
			.addCase(getTaskType.rejected, (state, action) => {});
	},
});

export const {
	setShowAvatar,
	setAvatarImage,
	setAIName,
	setHeaderContentType,
	setHeaderIconPosition,
	setHeaderColor,
	setHeaderTextAndIconColor,
	setBotBackground,
	setBackgroundGradientStartColor,
	setBackgroundGradientStopColor,
	setBackgroundSolidColor,
	setBackgroundGradientRange,
	setBackgroundFile,
	setAppearanceBackgroundLoader,
	setHeaderFile,
	setWelcomBannerFile,
	setFontFamily,
	setFontSize,
	setAIMessageBubbleColor,
	setAIMessageTextColor,
	setUserMessageBubbleColor,
	setUserMessageTextColor,
	setOptionsAndQuickLinksBckColor,
	setOptionsAndQuickLinksBckRange,
	setOptionsAndQuickLinksBorderColor,
	setOptionsAndQuickLinksBorderRange,
	setOptionsAndQuickLinksTextColor,
	setButtonBckColor,
	setButtonTextAndOutlineColor,
	setButtonTextColor,
	setInputBoxBackgroundColor,
	setInputBoxBckRange, //not available in new design will take care later
	setInputBoxTextColor,
	setInputBoxTextRange, //not available in new design will take care later
	setWelcomeBannerCheckbox,
	setautoPopupValue,
	setWelcomeMessage,
	setCardBody,
	setCardHeading,
	setCarouselTitle,
	setShowQuickLinks,
	setInputFields,
	setCarouselFields,
	setCarouselFieldsDataFullscreen,
	setAILaunchPromptCheckbox,
	setAILaunchPromptText,
	setLaunchPromptTextColor,
	setLaunchPromptBackgroundColor,
	setMenuItems,
	setBotAvater,
	setEOWWelcomeBannerLoader,
	setPersistentMenuCheckbox,
	setTimeStampBckColor,
	setTimeStampBckRange,
	setTimeStampTextColor,
	setTimeStampTextRange,
	setResponseLevelFeedBackBckColor,
	setResponseLevelFeedBackBckRange,
	setResponseLevelFeedBackBorderColor,
	setResponseLevelFeedBackBorderRange,
	setResponseLevelFeedBackIconColor,
	setUserComment,
	setUserFeedback,
	setAutoPopupTime,
	setPersistentCustomInputFields,
	setHeaderImageLoader,
	setAvatarImageLoader,
	setBackgroundImageLoader,
	setDropdownvalue,
	setIsMultilingualEnabled,
	setTranslationApiKey,
	resetInvalidtranslationkey,
	setRatButtonText,
	setRatEmail,
	setRatIssueDescription,
	setRatPhoneNumber,
	setRatName,
	setRatTicketManagement,
	setGenerateLeadsButtonText,
	setGenerateLeadsCompany,
	setGLName,
	setGenerateLeadsDestination,
	setGenerateLeadsEmail,
	setGenerateLeadsPhonenumber,
	setGenerateLeadsCountry,
	setBookAptButtonText,
	setBookAptCompany,
	setBookAptCountry,
	setBookAptDate,
	setBookAptManagement,
	setBookAptName,
	setBookAptPhonenumber,
	setBookAptTime,
	setCCCButtonText,
	setCCCPhoneNumber,
	setEmailToNotify,
	setNotifyExpertsAvailability,
	setNegativeSentimentCheckbox,
	setOnUserErrorCheckbox,
	setOnfailureCheckbox,
	setUserErrorValue,
	setFailureValue,
	setNegativeSentimentValue,
	setButtonText,
	setUnavailabilityMessageInside,
	setHandoffCancellationMessage,
	setHandoffConfirmationPrompt,
	setHandoffEndMessage,
	setAgentAvailability,
	setHandoffMessage,
	setUnavailabilityMessageOutside,
	setWaitTimeMessage,
	setEnableBookAPT,
	setEnableGenerateLeads,
	setEnableRAT,
	setEnableCHE,
	setAgentTimeZone,
	setBookAptEmail,
	setShowBotPreviewCollapsed,
	setGenInTouchIconSVGFilled,
	setHomeIconSVGFilled,
	setCurrentScreen,
	setColorScale,
	setSelectedColor,
	setColorMode,
	setChildTabs,
	setEnableCCC,
	setConversationOpener,
	setWelcomeCarouselFile,
	setWelcomeCarouselFileFullScreen,
	setWelcomeCarouselLoader,
	setWelcomeCarouselLoaderFullScreen,
	setCheckList,
	setTaskTypes,
	setTaskTypeSelected,
	setRatCollectionPrompt,
	setBookAptCollectionPrompt,
	setGenerateLeadsCollectionPrompt,
	setRatCheckList,
	setBookAptCheckList,
	setAdditionalsettingDetailsCollection,
	setAdditionalsettingMandatoryCollection,
	setIntroductoryHeading,
	setIntroductoryText,
	setProductResultCount,
	setCurrentConfigTab,
	setDisableSave,
	setDisableSaveProductCount,
	setDisableSaveSubHead,
	setImageLoader,
	setOptionsBookAptCheckList,
	setOptionsCheckList,
	setOptionsRatCheckList,
	setAgentTimingSchedule,
	setadditionalsettingMandatoryLeads,
	setliveChatLeadsCollectionPrompt,
	setliveChatLeadsCompany,
	setliveChatLeadsCountry,
	setliveChatLeadsEmail,
	setliveChatLeadsPhonenumber,
	setliveChatcheckList,
	setliveChatLeadsname,
	setSelectedTheme,
	setSelectedVariants,
	setSelectedButton,
	setRecommendationType,
	setRecommendationHeading,
	setVariants,
	setrecommendationCount,
	setShowOnHover,
	setAdditionalRecommendation,
	setCustomPrompts,
	setWelcomeMonacoImage,
	setWelcomeEcomImage,
	setSplashImage,
	setEnablestyleadvisor,
	setEcomlogo,
	setStyleAdvisorTitle,
	setSelectedDomain,
	setStyleError,
	setImageUploadStatusMsg,
} = experienceonwebSlice.actions;

export default experienceonwebSlice.reducer;
