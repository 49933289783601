import Zoho from '../../../assets/images/Zoho.png';
import Salesforce from '../../../assets/images/Salesforce.png';
import Shopify from '../../../assets/images/Shopify.png';
import HubSpot from '../../../assets/images/HubSpot.png';
import LeadSquared from '../../../assets/images/LeadSquared.png';
import Email from '../../../assets/images/email.png';
import GoogleSheets from '../../../assets/images/googlesheets.png';
import WooCommerce from '../../../assets/images/woocommerce.png';
import AdobeCommerce from '../../../assets/images/adobecommerce.png';
import BigCommerce from '../../../assets/images/bigcommerce.png';
import FreshChat from '../../../assets/images/freshchat.png';
import LiveChat from '../../../assets/images/live-chat.png';
import Zendesk from '../../../assets/images/zendesk.png';
import { useEffect } from 'react';
import './Integrations.scss';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	checkFreshchatStatus,
	crmsLinked,
	linkedEmail,
	linkedStatus,
} from '../../../Features/teamsetting/teamsettingSlice';
import CRMCard from './CRMCard';
import { FFStoreIcon } from '../../../assets/Icons/FlyfishStore';
import { loadState } from '../../../utils/utils';

type Props = {
	type?: string;
};

const Integrations = ({ type }: Props) => {
	const { crmemail, crmsLinkedData } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const dispatch = useAppDispatch();

	const crmsPicker = () => {
		const allCrms = [
			{
				task: 'Zoho',
				icon: Zoho,
				email: crmemail.zoho,
				type: 'png',
				name: 'zoho',
				status: 'in-use',
			},
			{
				task: 'LeadSquared',
				icon: LeadSquared,
				email: crmemail.leadsquare,
				type: 'png',
				name: 'leadsquared',
				status: 'in-use',
			},
			{
				task: 'Email',
				icon: Email,
				email: crmemail.email,
				type: 'png',
				name: 'email',
				status: 'in-use',
			},
			{
				task: 'Salesforce',
				icon: Salesforce,
				email: crmemail.salesForce,
				type: 'png',
				name: 'salesforce',
				status: 'in-use',
			},
			{
				task: 'HubSpot',
				icon: HubSpot,
				email: crmemail.hubSpot,
				type: 'png',
				name: 'hubspot',
				status: 'coming-soon', //'in-use',
			},

			{
				task: 'Google Sheets',
				icon: GoogleSheets,
				email: crmemail.googleSheets,
				type: 'png',
				name: 'google-sheets',
				status: 'coming-soon', //'in-use',
			},
			{
				task: 'Shopify',
				icon: Shopify,
				email: crmemail.shopify,
				type: 'png',
				name: 'shopify',
				status: 'in-use',
			},
			{
				task: 'WooCommerce',
				icon: WooCommerce,
				email: '',
				type: 'png',
				name: 'woocommerce',
				status: 'in-use',
			},
			{
				task: 'Flyfish Store',
				icon: <FFStoreIcon />,
				email: '',
				type: 'svg',
				name: 'ffstore',
				status: 'in-use',
			},
			{
				task: 'Adobe Commerce\n(Magento)',
				icon: AdobeCommerce,
				email: crmemail.shopify,
				type: 'png',
				name: 'adobe-commerce',
				status: 'coming-soon',
			},
			{
				task: 'Big Commerce',
				icon: BigCommerce,
				email: crmemail.shopify,
				type: 'png',
				name: 'big-commerce',
				status: 'coming-soon',
			},
			{
				task: 'Freshchat',
				icon: FreshChat,
				name: 'freshchat',
				status: 'in-use',
			},
			{
				task: 'Zendesk',
				icon: Zendesk,
				name: 'zendesk',
				status: 'coming-soon',
			},
		];
		allCrms.sort((a, b) => a.task.localeCompare(b.task));
		if (type === 'general') {
			let find = ['Email', 'Google Sheets'];
			let result = find.map((text) => {
				let final = allCrms.filter((crm, i) => {
					if (crm.task === text) {
						return allCrms[i];
					}
				});
				return final[0];
			});

			return result;
		} else if (type === 'crms') {
			let find = [
				'Email',
				'Google Sheets',
				'HubSpot',
				'LeadSquared',
				'Salesforce',
				'Zoho',
			];
			let result = find.map((text) => {
				let final = allCrms.filter((crm, i) => {
					if (crm.task === text) {
						return allCrms[i];
					}
				});
				return final[0];
			});

			return result;
		} else if (type === 'ecom') {
			let find = [
				'Adobe Commerce\n(Magento)',
				'Big Commerce',
				'Shopify',
				'WooCommerce',
				'Salesforce',
				// 'ONDC',
				// 'SAP Commerce Cloud',
			];
			let result = find.map((text) => {
				let final = allCrms.filter((crm, i) => {
					if (crm.task === text) {
						return allCrms[i];
					}
				});
				return final[0];
			});

			return result;
		} else if (type === 'ticket-management') {
			let find = ['Email', 'Freshchat', 'Zendesk', 'Zoho'];
			let result = find.map((text) => {
				let final = allCrms.filter((crm, i) => {
					if (crm.task === text) {
						return allCrms[i];
					}
				});
				return final[0];
			});

			return result;
		} else if (type === 'live-chat') {
			let find = ['Freshchat', 'Live Chat', 'Zendesk'];
			let result = find.map((text) => {
				let final = allCrms.filter((crm, i) => {
					if (crm.task === text) {
						return allCrms[i];
					}
				});
				return final[0];
			});

			return result;
		}

		return allCrms;
	};

	useEffect(() => {
		if (tenantId) {
			// dispatch(linkedEmail(tenantId)); //all crms linked status -- we have two APIs giving same response and we decided to go with crmsLinked. Just keeping this comment for future discussion that might come up.
			dispatch(linkedStatus(tenantId)); //specifically checking shopify related linking
			dispatch(checkFreshchatStatus({ tenantId })); //specifically checking freshchat related linking
			dispatch(crmsLinked({ tenantId: tenantId })); //all crms linked status
		}
	}, []);

	useEffect(() => {}, [crmsLinkedData]); //don't remove kept intentionally for component updation over user interaction on enable/disable

	return (
		<div className='integrations-container'>
			<h4>Integrations</h4>
			<div className='crm'>
				{crmsPicker().map((crm: any, index: number) => {
					return (
						<>
							{loadState('shopifyShopName') &&
							(crm.task.toLowerCase().indexOf('commerce') > -1 ||
								crm.task.toLowerCase().indexOf('flyfish') > -1) ? null : (
								<div key={crm.name + index}>
									<CRMCard
										crmname={crm.name!}
										icon={crm.icon}
										cardname={crm.task}
										status={crm.status}
										index={index}
										type={crm.type}
									/>
								</div>
							)}
						</>
					);
				})}
			</div>
			<Outlet />
		</div>
	);
};

export default Integrations;
