import './hbwelcomescreen.scss';
import Quicklinks from '../../../../Components/ChatComponents/Quicklinks';
import { useState } from 'react';
import { chatMessage, setMsgsArray } from '../../../../Features/HelpBot/helpbotSlice';
import { RootState, useAppDispatch } from '../../../../app/store';
import { useSelector } from 'react-redux';

function HelpBot() {
    const quickLinks: any = [{
        linkText: 'How do I ingest my e-commerce storefront?',
        linkValue: 'How do I ingest my e-commerce storefront?',
    },
    {
        linkText: 'How do I create my first Sales AI',
        linkValue: 'How do I create my first Sales AI',
    },
    {
        linkText: 'How do I configure my Sales AI',
        linkValue: 'How do I configure my Sales AI',
    },
    {
        linkText: 'How do I launch my Sales AI?',
        linkValue: 'How do I launch my Sales AI?',
    }];
    const {
        msgsArray,
        msgsLoading,
        chatInput,
        conversationId,
    } = useSelector((state: RootState) => state.helpBot);
    const dispatch = useAppDispatch();
    const handleQuickLink = (text: string) => {
        dispatch(
            setMsgsArray([...msgsArray, { content: text.trim(), role: 'user' }])
        );
        const botID = window.location.origin.lastIndexOf('uat.flyfish.ai') > -1 ? '46' : "120";
        dispatch(chatMessage({
            "botId": botID,
            "country": "US",
            "id": conversationId ? conversationId : 0,
            "message": text.trim(),
            "metaData": null,
            "version": "prod"
        }))
    }
    return (
        <div className='ff-hb-welcome-screen-container'>
            {/* <Quicklinks quickLinks={quickLinks} /> */}
            <div className='welcome-screen-button body2'>
                {
                    quickLinks && quickLinks.map((data: any) => {
                        return (
                            <button onClick={() => {
                                handleQuickLink(data.linkValue)
                            }}>{data.linkText}</button>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default HelpBot;