import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import Dropdown, { DropdownValue } from '../../../Components/Dropdown';
import './conversations.scss';
import '../calendar/calendarInput/calenderInput.scss';

import {
	DropdownIcon,
	MobileIcon,
	ReferrerIcon,
	UserRatingsIcon,
} from '../icon';
import { RootState, useAppDispatch } from '../../../app/store';
import {
	conversationChatSelected,
	getconversationChatData,
	onClickMiniCardAvgUserRating,
} from '../../../Features/Analytics/analyticsSlice';
import { useSelector } from 'react-redux';
ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);
const options = [
	{
		label: (
			<div className='action-label'>
				<div className=' icon '>
					<DropdownIcon />
				</div>
				<p>Country</p>
			</div>
		),
		value: 'country',
	},
	{
		label: (
			<div className='action-label'>
				<div className='icon'>
					<MobileIcon />
				</div>
				<p>Device</p>
			</div>
		),
		value: 'device',
	},
	{
		label: (
			<div className='action-label'>
				<div className='icon'>
					<UserRatingsIcon />
				</div>
				<p>User Ratings</p>
			</div>
		),
		value: 'user',
	},
	{
		label: (
			<div className='action-label'>
				<div className='icon'>
					<ReferrerIcon />
				</div>
				<p>Referrer</p>
			</div>
		),
		value: 'referrer',
	},
];

function Conversations() {
	const [selectedfield, setSelectedField] = useState<DropdownValue | any>(
		options[0]
	);
	const dispatch = useAppDispatch();
	const {
		selectedDates,
		conversationChatData,
		calendarstatus,
		activeAnalyticsBot,
		MiniCardAvgUserRating,
	} = useSelector((state: RootState) => state.analytics);

	dispatch(conversationChatSelected(selectedfield.value));
	// const chatLabel = Object.keys(conversationChatData);
	const [chatLabel, setchatLabel] = useState(Object.keys(conversationChatData));
	// const chatLabelvalue = Object.values(conversationChatData);

	const sortObject = (obj: any) => {
		const sorter = (a: any, b: any) => {
			return obj[a] - obj[b];
		};
		const keys = Object.keys(obj);
		keys.sort(sorter);
		const res: any = {};
		keys.forEach((key: any) => {
			res[key] = obj[key];
		});
		return res;
	};
	const sortedValues = sortObject(conversationChatData);
	const [chatLabelvalue, setchatLabelvalue] = useState(
		Object.keys(sortedValues)
	);

	const { selectedBot } = useSelector((state: RootState) => state.bots);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	useEffect(() => {
		MiniCardAvgUserRating && setSelectedField(options[2]);
		if (calendarstatus && tenantId) {
			dispatch(
				getconversationChatData({
					startDate: selectedDates[0],
					endDate: selectedDates[1],
					timezone: -330,
					tenantId: tenantId,
					botId: activeAnalyticsBot.botId,
					type: selectedfield.value,
					bySession: false,
				})
			);
		}
	}, [selectedfield, selectedDates, MiniCardAvgUserRating]);

	useEffect(() => {
		if (selectedfield.value === 'Referrer') {
			let originData: any = {};
			if (conversationChatData.length !== 0) {
				conversationChatData.map((item: any) => {
					item.referers.map((e: any) => {
						originData[e.value] = e.count;
						// originData[e.value.substring(0, 36) + '...'] = e.count;
					});
					//  originData[item.origin] = item.count
				});
				//     console.log("data...")
			}
			setchatLabelvalue(Object.values(originData));
			setchatLabel(Object.keys(originData));
		}
		if (selectedfield.value !== 'Referrer') {
			setchatLabel(Object.keys(conversationChatData));
			setchatLabelvalue(Object.values(conversationChatData));
		}
	}, [selectedfield, selectedDates]);

	const data = {
		labels: chatLabel,
		datasets: [
			{
				barPercentage: 10,
				barThickness: 100,
				maxBarThickness: 34,
				minBarLength: 0,
				data: chatLabelvalue,
				backgroundColor: ['#3f3fef'],
				borderRadius: 2,
			},
		],
	};

	return (
		<div>
			<div className='ff_ananlytics_no_of_convo'>
				<h5>Number of Conversations</h5>
				<Dropdown
					value={selectedfield}
					onChange={(el: any) => {
						dispatch(onClickMiniCardAvgUserRating(false));
						setSelectedField(el);
					}}
					options={options}
					className='no-of-convo'
				/>
			</div>
			{chatLabel.length === 0 ||
			chatLabelvalue.length === 0 ||
			conversationChatData.length === 0 ? (
				<div className='ff_conversation_Nodata'>
					{' '}
					No Data for {selectedfield.value} to display
				</div>
			) : (
				<div className='ff_analytics_bar_chart'>
					<Bar
						className='analytics-bar'
						height={310}
						width={250}
						data={data}
						options={{
							scales: {
								x: {
									grid: {
										display: false,
										drawBorder: false,
									},
									ticks: {
										color: 'var(--black-60)',
										font: {
											size: 12,
											weight: '400',
											family: "'Exo 2', sans-serif'",
											lineHeight: '1.5',
										},
									},
								},
								y: {
									// callback: function (value, index, values) {
									// 	return value / 1000 + 'k';
									// },

									ticks: {
										color: 'var(--black-60)',
										maxTicksLimit: 7,
										font: {
											size: 12,
											weight: '400',
											family: "'Exo 2'",
											lineHeight: '1.5',
										},
									},
								},
							},
							maintainAspectRatio: false,
							responsive: true,

							plugins: {
								legend: {
									display: false,
								},
							},
						}}
					/>
				</div>
			)}
		</div>
	);
}

export default Conversations;
