import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Navigate, useLocation } from 'react-router-dom';
import { removeAllState } from '../../utils/utils';

interface Props {
	children: JSX.Element;
}

const ProtectedRoute = ({ children }: Props): JSX.Element => {
	const location = useLocation();
	const { user } = useSelector((state: RootState) => state.auth);
	const { tenantId, teams } = useSelector((state: RootState) => state.teams);

	if (user) {
		if (
			!tenantId &&
			location.pathname !== '/app/teams' &&
			teams &&
			teams.length > 0
		) {
			return <Navigate to='/app/teams' replace />;
		} else return children;
	}

	// removeAllState();
	return <Navigate to='/app/login' replace />;
};

export default ProtectedRoute;
