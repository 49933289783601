import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from '../../Pages/Header/Header';

import './Layout.scss';
import { RootState, useAppDispatch } from '../../app/store';
import { useSelector } from 'react-redux';
import { Snackbar } from '@mui/material';
import {
	reset,
	setBg,
	setSnackModal,
} from '../../Features/message/messageSlice';
import { useEffect, useState } from 'react';
import PageLoader from '../../Components/PageLoader';
import { SnackError, SnackModal } from '../../assets/Icons';
import Modal from '../../Components/Modal';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
	const location = useLocation();
	const {
		open,
		message,
		testcase,
		isLoader,
		openModal,
		modalMesssage,
		modalMode,
	} = useSelector((state: RootState) => state.message);
	const { notificationSnack } = useSelector(
		(state: RootState) => state.notification
	);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [duration, setDuration] = useState(0);

	useEffect(() => {
		if (message) {
			let task = `${message}`;
			let noOfWords = task.split(' ').length;
			if (noOfWords > 3) {
				setDuration(7000);
			} else {
				setDuration(4000);
			}
		}
	}, [message]);

	const messageDecision = () => {
		if (testcase === 'positive') {
			return (
				<div className='snack'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						data-name='check_circle_black_24dp (3)'
						width='20'
						height='20'
						viewBox='0 0 20 20'>
						<path data-name='Path 15054' d='M0 0h20v20H0z' fill='none' />
						<path
							data-name='Path 15055'
							d='M10.333 2a8.333 8.333 0 1 0 8.333 8.333A8.336 8.336 0 0 0 10.333 2zM8.667 14.5 4.5 10.333l1.175-1.175 2.992 2.983 6.325-6.325L16.167 7z'
							transform='translate(-.333 -.333)'
							fill='#47a26e'
						/>
					</svg>
					<p className='caption'>{message}</p>
				</div>
			);
		} else if (testcase === 'negative') {
			return (
				<div className='snack'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						data-name='error_black_24dp (1)'
						width='20'
						height='20'
						viewBox='0 0 20 20'>
						<path data-name='Path 15052' d='M0 0h20v20H0z' fill='none' />
						<path
							data-name='Path 15053'
							d='M10.333 2a8.333 8.333 0 1 0 8.333 8.333A8.336 8.336 0 0 0 10.333 2zm.833 12.5H9.5v-1.667h1.667zm0-3.333H9.5v-5h1.667z'
							transform='translate(-.333 -.333)'
							fill='#f86060'
						/>
					</svg>
					<p className='caption'>{message}</p>
				</div>
			);
		} else {
			return message;
		}
	};

	return (
		<>
			<Header />
			<div className='layout-child'>
				{/* {isLoader ? (
					<div className='query-loader'>
						<PageLoader message='Loading...' />
					</div>
				) : null} */}
				<div
					style={{
						backgroundColor:
							location.pathname.indexOf('Ingest') > -1 ||
								location.pathname.indexOf(
									'catalog/multichannel-storefront/sales-ai/catalog/product-description'
								) > -1
								? 'var(--white)'
								: '',
					}}
					className={`layout-component ${location.pathname.includes('/app/configuration/users')
							? 'overflowUnset'
							: ''
						}`}>
					<Outlet />

					<Snackbar
						color='inherit'
						sx={{
							position: 'absolute',
							bottom: '72px !important',
						}}
						open={open}
						message={messageDecision()}
						onClick={() => dispatch(reset())}
						onClose={() => dispatch(reset())}
						autoHideDuration={duration}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
					/>
					<div className='ff-plaform-pop-notification'>
						<ToastContainer
							position='bottom-left'
							autoClose={5000}
							hideProgressBar
							newestOnTop={false}
							closeOnClick={false}
							onClick={() => {
								if (notificationSnack.type === 'chat') {
									navigate('/app/chats/my-chats');
								}
							}}
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover={false}
							theme='light'
							limit={3}
						/>
					</div>

					{openModal ? (
						<Modal
							headerCloseBtn
							actionButtons={false}
							// autoHideDuration={1000}
							className='ff-snack-bar-modal'
							onClose={() => {
								dispatch(
									setSnackModal({
										openModal: false,
									})
								);
							}}
							header=''>
							{modalMode === 'negative' ? <SnackError /> : <SnackModal />}
							<h5>{modalMesssage}</h5>
						</Modal>
					) : null}
				</div>
			</div>
		</>
	);
};

export default Layout;
