import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { Box } from '@mui/system';
import ErrorIcon from '@mui/icons-material/Error';
import Modal from '../../../../../Components/Modal';
import Button from '../../../../../Components/Button';
import Input from '../../../../../Components/Input';
import JobSheetModal from './JobSheet';
import { RootState, useAppDispatch } from '../../../../../app/store';
import {
	reset,
	uploadConsentJob,
} from '../../../../../Features/WhatsApp-BSP/contact/contactSlice';
import { useSelector } from 'react-redux';
import { set } from '../../../../../Features/message/messageSlice';
import { isHTMLTagsPresent } from '../../../../../utils/utils';

const Upload = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	useEffect(() => {
		reset();
	}, []);

	const [jobName, setJobName] = useState('');
	const [jobNameError, setJobNameError] = useState<string | undefined>(
		undefined
	);
	const [isJobCaption, setIsJobCaption] = useState(false);
	const [description, setDescription] = useState('');
	const [learnMore, setLearnMore] = useState(false);

	const [xlsxFileName, setXlsxFileName] = useState('');
	const [selectedxlsmFile, setSelectedxlsmFile] = useState<string | null>(null);
	const [xlsxfileError, setXlsxfileError] = useState('');
	const { wabaNo } = useSelector((state: RootState) => state.BSPauth);
	const validatejobName = () => {
		if (jobName.length <= 0) {
			setJobNameError('Required');
		} else {
			setJobNameError(undefined);
		}
	};

	const handlexlsxFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		let files: any = (event.target as HTMLInputElement).files;
		let { name, size } = files[0];
		if (size <= 104857600) {
			setXlsxFileName(name);
			setXlsxfileError('');
			setSelectedxlsmFile(files[0]);
		} else setXlsxfileError('Your file must be lesser than 100 MB in size');
	};

	const onClickImport = () => {
		const data = {
			jobs: {
				title: jobName,
				description: description ? description : '',
				wabaNo,
			},
			file: selectedxlsmFile ? selectedxlsmFile : '',
		};
		dispatch(uploadConsentJob(data));
	};

	return (
		<>
			<Modal
				header='Import contacts from Excel file'
				onClose={() => navigate(-1)}
				actionButtons={
					<>
						<Button buttonType='light' onClick={() => navigate(-1)}>
							<p className='button-1'>Cancel</p>
						</Button>
						<Button
							disabled={
								!Boolean(jobName && selectedxlsmFile) ||
								isHTMLTagsPresent(jobName) ||
								isHTMLTagsPresent(description)
							}
							onClick={() => {
								onClickImport();
								navigate(-1);
							}}>
							<p className='button-1'>Import</p>
						</Button>
					</>
				}>
				<div className='upload-contacts'>
					<p className='body-2 paragraph1'>
						This option allows you to add and update multiple contacts at once
						by importing those from an Excel file.
					</p>
					<div className='input-group'>
						<Input
							autoFocus
							fullWidth
							label='Job name*'
							value={jobName}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								setJobName(event.target.value)
							}
							error={Boolean(jobNameError)}
							helperText={jobNameError}
							onFocus={() => {
								// setJobNameError(undefined);
								setIsJobCaption(true);
							}}
							onBlur={() => {
								validatejobName();
								setIsJobCaption(false);
							}}
						/>
						{isJobCaption && (
							<p className='caption caption-color'>
								Provide a name to identify this upload job later
							</p>
						)}
					</div>
					<div className='input-group'>
						<Input
							fullWidth
							limit={255}
							label='Description'
							value={description}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setDescription(event.target.value);
							}}
							helperText={'Optional'}
						/>
					</div>
					<p className='body-1 heading'>File to import contacts from*</p>
					<p className='caption caption-color'>
						To learn more about preparing the file correctly,{' '}
						<span className='blue-text' onClick={() => setLearnMore(true)}>
							click here{' '}
						</span>
					</p>
					<div className='body-1 blue-text upload-file'>
						{!xlsxFileName && <label htmlFor='xlsxFile'>Select file</label>}
						{xlsxFileName && (
							<div className='body-1'>
								<span>{xlsxFileName}</span> |{' '}
								<label htmlFor='xlsxFile' className='blue-text'>
									change
								</label>
							</div>
						)}
						<input
							id='xlsxFile'
							className='input-file'
							type='file'
							accept='.xlsx, .xlsm, .csv, .tsv'
							onChange={(event) => {
								handlexlsxFileSelect(event);
								event.target.value = '';
							}}
						/>
						{xlsxfileError && (
							<Box
								component={'span'}
								sx={{
									display: 'flex',
									gap: '4px',
									alignItems: 'center',
									color: '#D16900',
								}}>
								<ErrorIcon sx={{ fontSize: '16px' }} />
								<span className='caption'>{xlsxfileError}</span>
							</Box>
						)}
					</div>
				</div>
			</Modal>
			{learnMore && <JobSheetModal setLearnMore={setLearnMore} />}
		</>
	);
};

export default Upload;
