import { Outlet, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	CampaignItem,
	cancelCampaign,
	getCampaigns,
	setwabaNoForCancelCampaign,
	getScheduledCampaigns,
	getInProgressCampaigns,
	setCampaignSearchedValue,
	getCampaignDump,
	getScheduledCampaignsKotak,
	getInProgressCampaignsKotak,
	getCampaignsKotak,
	getJobSheet,
	setDownloadMedia,
	reset,
} from '../../../../Features/WhatsApp-BSP/campaign/campaignSlice';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ProgressBar from '../../../../Components/ProgressBar';
import './Campaign.scss';
import { useSelector } from 'react-redux';
import {
	DownloaddateFormat,
	dateFormat,
	kotakTenantCheckForWhatsapp,
	loadState,
	removeState,
	saveState,
} from '../../../../utils/utils';
import Button from '../../../../Components/Button';
import { useEffect, useState } from 'react';
import Chip from '../../../../Components/Chip';
import InteractiveButton from '../../../../Components/Button/InteractiveButton';
import Menu, { Options } from '../../../../Components/Menu';
import Table from '../../../../Components/Table';
import Pagination from '../../../../Components/Table/Pagination';
import { DownloadAnalyticDatas } from '../../../../assets/Icons';
import Input from '../../../../Components/Input';
import DataFetchAPI from '../../../../utils/Api';
import { setSnackModal } from '../../../../Features/message/messageSlice';
import { dataTool } from 'echarts';
import Search from '../../../../Components/Search';

type Props = {
	campaign: CampaignItem;
};
const LIMIT = 20;
const SIZE = 20;
const GetStatusProgress = ({ campaign }: Props) => {
	switch (campaign.jobStatus.toLowerCase()) {
		case 'xlparsing':
			return (
				<>
					<div className='progress'>
						<p>Processing jobsheet…</p>
						<ProgressBar
							value={campaign.totalCount - (campaign.executionNotStarted ?? 0)}
							total={campaign.totalCount}
						/>
					</div>
					<div className='progress disabled'>
						<p>
							Building payload and queueing messages… (0/{campaign.totalCount})
						</p>
						<ProgressBar value={0} total={campaign.totalCount} />
					</div>
					{/* <div className="progress disabled">
            <p className="body2">Sending messages… (0/{campaign.totalCount})</p>
            <ProgressBar value={0} total={campaign.totalCount} />
          </div> */}
				</>
			);
		case 'xlparsed':
			return (
				<>
					<div className='progress'>
						<p>
							Building payload and queueing messages… (
							{campaign.executionCompleted ?? 0}/
							{campaign.totalCount - campaign.invalidRow})
						</p>
						<ProgressBar
							value={campaign.executionCompleted || 0}
							total={campaign.totalCount - campaign.invalidRow}
						/>
					</div>
					{/* <div className="progress">
            <p className="body2">
              Sending messages… ({(campaign.delived || 0)}/{campaign.totalCount})
            </p>
            <ProgressBar value={campaign.delived || 0} total={campaign.totalCount} />
          </div> */}
				</>
			);
		case 'execution started':
		case 'execution completed':
			return (
				<>
					<div className='progress'>
						<p>
							Building payload and queueing messages… (
							{campaign.executionCompleted ?? 0}/
							{campaign.totalCount - campaign.invalidRow})
						</p>
						<ProgressBar
							value={campaign.executionCompleted || 0}
							total={campaign.totalCount - campaign.invalidRow}
						/>
					</div>
					<div className='progress'>
						<p>
							Sending messages… ({campaign.delivered ?? 0}/
							{campaign.totalCount - campaign.invalidRow})
						</p>
						<ProgressBar
							value={campaign.delivered || 0}
							total={campaign.totalCount - campaign.invalidRow}
						/>
					</div>
				</>
			);

		default:
			return <></>;
	}
};

const Campaign = () => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
	const [open, setOpen] = useState(false);
	const dispatch = useAppDispatch();
	const [options, setOptions] = useState<Array<Options>>([]);
	const {
		scheduledCampaigns,
		inProgressCampaigns,
		pastCampaigns,
		isLoading,
		isScheduledCampaignsLoading,
		isInProgressLoading,
		totalPastCampaigns,
		totalScheduledCampaigns,
		totalInProgressCampaigns,
		campaignsSearchedValue,
		campaignDump,
		reportTime,
		downloadMedia,
	} = useSelector((state: RootState) => state.campaign);
	const { wabaNo } = useSelector((state: RootState) => state.BSPauth);
	const [offset, setOffset] = useState(0);
	const [offset2, setOffset2] = useState(0);
	const [offset3, setOffset3] = useState(0);
	const [page, setPage] = useState(0);
	//page2 is not used because in new whatsapp we do not have Scheduled table
	const [page2, setPage2] = useState(0);
	const [page3, setPage3] = useState(0);
	const [UpperL, setUpperL] = useState(0);
	const [LowerL, setLowerL] = useState(0);
	const [UpperL2, setUpperL2] = useState(0);
	const [LowerL2, setLowerL2] = useState(0);
	const [UpperL3, setUpperL3] = useState(0);
	const [LowerL3, setLowerL3] = useState(0);
	const [isOpen, setDownloadOpen] = useState(false);
	const [searchLoader, setSearchLoader] = useState(false);
	const [isCampaignDump, setCampaignDump] = useState('');
	// const showRows = [
	// 	{
	// 		label: 10,
	// 		value: 10,
	// 	},
	// 	{
	// 		label: 8,
	// 		value: 8,
	// 	},
	// 	{
	// 		label: 6,
	// 		value: 6,
	// 	},
	// ];
	// const [countRows, setCountOfRows] = useState<DropdownValue | any>(
	// 	showRows[0]
	// );
	useEffect(() => {
		dispatch(setwabaNoForCancelCampaign(wabaNo));
	});

	useEffect(() => {
		let payload = {
			page: page,
			size: SIZE,
			wabaNo,
			status: 'completed',
			filter: '',
		};
		let kotakpayload = {
			offset: offset,
			wabaNo,
			status: 'completed',
		};
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? dispatch(getCampaignsKotak(kotakpayload))
			: dispatch(getCampaigns(payload));
	}, [offset]);

	useEffect(() => {
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`) &&
			dispatch(
				getScheduledCampaignsKotak({
					offset: offset2,
					wabaNo,
					status: 'created',
				})
			);
	}, [offset2]);

	useEffect(() => {
		let payload = {
			page: page3,
			size: SIZE,
			wabaNo,
			status: 'in progress',
			filter: '',
		};
		let kotakPayload = {
			offset: offset3,
			wabaNo,
			status: 'in progress',
		};
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? dispatch(getInProgressCampaignsKotak(kotakPayload))
			: dispatch(getInProgressCampaigns(payload));
	}, [offset3]);
	const column1 = [
		{ key: 'jobTitle', title: 'Job name' },
		{
			key: 'jobDescription',
			title: 'Description',
			render: (row: any) => <>{row.jobDesc}</>,
		},
		{
			key: 'jobScheduledTime',
			title: 'Start Time',
			render: (row: any) => (
				<>{dateFormat(row.jobScheduledTime?.split('.')[0] + '.000Z')}</>
			),
		},
		{
			key: 'jobStatus',
			title: 'Status',
			render: (row: any) => (
				<>
					{row.jobStatus === 'XLParseing' || row.jobStatus === 'XLParsed' ? (
						<>
							{loadState('jobId') !== row.jobId ? (
								<GetStatusProgress campaign={row} />
							) : (
								<>Scheduled</>
							)}
						</>
					) : (
						<GetStatusProgress campaign={row} />
					)}
				</>
			),
		},
		{
			key: 'action',
			title: '',
			render: (row: any) => (
				<div
					className={`three-dots ${
						row.jobStatus === 'XLParseing' || row.jobStatus === 'XLParsed'
							? ''
							: 'no-three-dots'
					}`}>
					<InteractiveButton
						icon={<MoreVertOutlinedIcon />}
						onClick={(event: any) => {
							event.stopPropagation();
							setAnchorEl(event.target);
							setOpen((prev) => !prev || anchorEl !== event.target);
							setOptions([
								{
									label: <p className='menu-label'>Cancel</p>,
									onClick: () => {
										dispatch(
											cancelCampaign({
												jobId: row.jobId,
											})
										);
										setOpen(false);
										setTimeout(() => {
											let payload1 = {
												page: 0,
												size: SIZE,
												wabaNo,
												status: 'inprogress',
												filter: '',
											};
											let kotakpayload1 = {
												offset: 0,

												wabaNo,
												status: 'inprogress',
											};
											let payload2 = {
												page: 0,
												size: SIZE,
												wabaNo,
												status: 'completed',
												filter: '',
											};
											let kotakpayload2 = {
												offset: 0,

												wabaNo,
												status: 'completed',
											};
											if (
												kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
											) {
												dispatch(getInProgressCampaignsKotak(kotakpayload1));
												dispatch(getCampaignsKotak(kotakpayload2));
											} else {
												dispatch(getInProgressCampaigns(payload1));
												dispatch(getCampaigns(payload2));
											}
										}, 3000);
									},
								},
							]);
						}}
					/>
				</div>
			),
		},
	];

	const column2 = [
		{ key: 'jobTitle', title: 'Job name' },
		{
			key: 'jobDescription',
			title: 'Description',
			render: (row: any) => <>{row.jobDesc}</>,
		},
		{
			key: 'jobScheduledTime',
			title: 'Scheduled for',
			render: (row: any) => (
				<>{dateFormat(row.jobScheduledTime?.split('.')[0] + '.000Z')}</>
			),
		},
		{
			key: 'action',
			title: '',
			render: (arg: any) => (
				<div className='three-dots'>
					<InteractiveButton
						icon={<MoreVertOutlinedIcon />}
						onClick={(event: any) => {
							event.stopPropagation();
							setAnchorEl(event.target);
							setOpen((prev) => !prev || anchorEl !== event.target);
							setOptions([
								{
									label: <p className='menu-label'>Cancel</p>,
									onClick: () => {
										dispatch(
											cancelCampaign({
												jobId: arg.jobId,
											})
										);
										setOpen(false);
										setTimeout(() => {
											if (
												kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
											) {
												dispatch(
													getScheduledCampaignsKotak({
														offset: 0,
														wabaNo,
														status: 'created',
													})
												);
											} else {
												dispatch(
													getScheduledCampaigns({
														page: 0,
														size: SIZE,
														wabaNo,
														status: 'created',
														filter: '',
													})
												);
											}
										}, 3000);
									},
								},
							]);
						}}
					/>
				</div>
			),
		},
	];

	const getCode = (jobStatus: string) => {
		switch (jobStatus.toUpperCase()) {
			case 'COMPLETED':
				return 'positive';
			case 'FAILED':
				return 'negative';
			case 'CANCELLED':
				return 'neutral';
			default:
				return 'neutral';
		}
	};
	const column3 = [
		{ key: 'jobTitle', title: 'Job name	' },
		{
			key: 'jobDescription',
			title: 'Description',
			render: (row: any) => <>{row.jobDesc}</>,
		},
		{
			key: 'jobScheduledTime',
			title: 'Start time',
			render: (row: any) => (
				<>{dateFormat(row.jobScheduledTime?.split('.')[0] + '.000Z')}</>
			),
		},
		{
			key: 'jobStatus',
			title: 'Status',
			render: (row: any) => (
				<Chip label={row.jobStatus} code={getCode(row.jobStatus) ?? ''} />
			),
		},
	];
	// useEffect(() => {
	// 	campaign && getTemplateFormat();
	// }, [campaign]);
	useEffect(() => {
		return () => {
			dispatch(setCampaignSearchedValue(''));
			setUpperL(0);
			setLowerL(0);
			setUpperL2(0);
			setLowerL2(0);
			setUpperL3(0);
			setLowerL3(0);
			dispatch(reset());
		};
	}, []);

	const getTemplateFormat = () => {
		dispatch(
			getJobSheet({
				sheetName: campaignDump,
				wabaNo,
			})
		);
	};
	const dowloadOptions = [
		{
			label: <p className='menu-label'>Generate data</p>,
			onClick: () => {
				setDownloadOpen(false);
				dispatch(getCampaignDump({ wabaNo: wabaNo }));
				navigate('campaign-success');
			},
		},
		{
			label: (
				<p className='menu-label'>
					<a
						href={downloadMedia}
						download={isCampaignDump ? `${isCampaignDump}` : 'template.xlsx'}
						target='_blank'
						rel='noreferrer'>
						Download data (
						{DownloaddateFormat(reportTime?.split('.')[0] + '.000Z')})
					</a>
				</p>
			),
			onClick: () => {
				setDownloadOpen(false);
				// navigate('previous-success')
				dispatch(
					setSnackModal({
						openModal: true,
						modalMesssage: 'Your file is being downloaded',
					})
				);
				// navigate(-1);
				getTemplateFormat();
			},
		},
	];
	// useEffect(() => {
	// 	if (searchingLoader) {
	// 		setSearchLoader(true);
	// 	} else {
	// 		setSearchLoader(false);
	// 	}
	// }, [searchingLoader]);

	useEffect(() => {
		if (pastCampaigns.length > 0) {
			setUpperL(pastCampaigns.length + offset);
			setLowerL(offset + 1);
		}
	}, [pastCampaigns]);
	useEffect(() => {
		if (scheduledCampaigns.length > 0) {
			setUpperL2(scheduledCampaigns.length + offset2);
			setLowerL2(offset2 + 1);
		}
	}, [scheduledCampaigns]);

	// useEffect(() => {
	// 	if (inProgressCampaigns.length > 0) {
	// 		setUpperL3(inProgressCampaigns.length + offset3);
	// 		setLowerL3(offset3 + 1);
	// 	}
	// }, [inProgressCampaigns]);

	useEffect(() => {
		inProgressCampaigns.map((data) => {
			if (data.jobStatus === 'XLParseing' || data.jobStatus === 'XLParsed') {
				let scheduleformatdate = dateFormat(
					data.jobScheduledTime.split('.')[0] + '.000Z'
				).split(' ');
				let schedule_date = scheduleformatdate[0];
				// let schedule_time = scheduleformatdate[1];
				let currentformatdate = new Date()
					.toLocaleString()
					.replaceAll('/', '-')
					.split(', ');
				let current_date = currentformatdate[0];
				// let current_time = currentformatdate[1].split(':');
				// let dcurrent = `${current_time[0]}:${current_time[1]} ${
				// 	current_time[2].split(' ')[1]
				// }`;

				if (schedule_date === current_date) {
					let a = data.jobScheduledTime.split('.')[0] + '.000Z';
					let formatst = new Date(a).getTime();
					let formatct = new Date().getTime();

					let futureTime = formatst - formatct;

					if (futureTime > 0) {
						saveState('jobId', data.jobId);
						setTimeout(() => {
							removeState('jobId');
							let payload1: any = {
								page: 0,
								size: SIZE,
								wabaNo,
								status: 'inprogress',
								filter: '',
							};
							let payload2: any = {
								page: 0,
								size: SIZE,
								wabaNo,
								status: 'completed',
								filter: '',
							};
							let kotakpayload1 = {
								offset: 0,

								wabaNo,
								status: 'inprogress',
							};
							let kotakpayload2 = {
								offset: 0,

								wabaNo,
								status: 'completed',
							};
							if (kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)) {
								dispatch(getInProgressCampaignsKotak(kotakpayload1));
								dispatch(getCampaignsKotak(kotakpayload2));
							} else {
								dispatch(getInProgressCampaigns(payload1));
								dispatch(getCampaigns(payload2));
							}

							// saveState('scheduleStatus', 'started')
						}, futureTime);
					}
				}
			}
		});
		if (inProgressCampaigns.length > 0) {
			setUpperL3(inProgressCampaigns.length + offset3);
			setLowerL3(offset3 + 1);
		} else {
			setUpperL3(0);
			setLowerL3(0);
		}
		// setTimeout(() => {
		// 	dispatch(
		// 		getInProgressCampaigns({ page: 0, size: SIZE, wabaNo, status: 'inprogress', filter: '' })
		// 	);
		// 	dispatch(
		// 		getCampaigns({
		// 			page: 0,
		// 			size: SIZE,
		// 			wabaNo,
		// 			status: 'completed',
		// 			filter: '',
		// 		})
		// 	);
		// }, 3000)
	}, [inProgressCampaigns]);

	useEffect(() => {
		campaignDump && setCampaignDump(campaignDump);
	}, [campaignDump]);

	const downloadCampaignsData = (event: any) => {
		if (isCampaignDump) {
			setDownloadOpen(true);
			event.stopPropagation();
			setAnchorE2(event.target);
			getTemplateFormat();
		} else {
			setDownloadOpen(false);
			dispatch(getCampaignDump({ wabaNo: wabaNo }));
			navigate('campaign-success');

			// setInterval(() => {
			// 	if (isCampaignDump !== '') {
			// 		let payload = {
			// 			page: offset2,
			// 			size: SIZE,
			// 			wabaNo,
			// 			status: 'created',
			// 			filter: '',
			// 		};
			// 		let kotakpayload = {
			// 			offset: offset2,

			// 			wabaNo,
			// 			status: 'created',
			// 		};
			// 		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			// 			? dispatch(getScheduledCampaignsKotak(kotakpayload))
			// 			: dispatch(getScheduledCampaigns(payload));
			// 	}
			// }, 40000);
		}
	};

	const searchCampaignsData = (value: any) => {
		// if (e.key === 'Enter') {

		let payload = {
			page: value.length === 0 ? page : 0,
			size: value.length === 0 ? SIZE : 1000,
			wabaNo,
			status: 'completed',
			filter: value,
		};
		let kotakpayload = {
			offset: value.length === 0 ? offset : 0,
			limit: value.length === 0 ? SIZE : 1000,
			wabaNo,
			status: 'completed',
			filter: value,
		};
		kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`)
			? dispatch(getCampaignsKotak(kotakpayload))
			: dispatch(getCampaigns(payload));
		setTimeout(() => {
			setSearchLoader(false);
		}, 500);
	};

	const paginationForPastCampaigns = () => {
		return (
			<>
				<div className='campign-pagination-header'>
					{campaignsSearchedValue.length === 0 ? (
						<Pagination
							disableforward={
								totalPastCampaigns <= offset + pastCampaigns.length
							}
							disableback={offset === 0}
							nextPage={() => {
								setOffset(offset + LIMIT);
								setPage((page) => page + 1);
							}}
							previousPage={() => {
								setOffset(offset - LIMIT);
								setPage((page) => page - 1);
							}}
							gotoBegining={() => {
								setOffset(0);
								setPage(0);
							}}
							gotoLast={() => {
								setOffset(Math.floor(totalPastCampaigns / LIMIT) * LIMIT);
								setPage(Math.floor(totalPastCampaigns / LIMIT));
							}}
							totalCount={totalPastCampaigns}
							upper={UpperL}
							lower={LowerL}
						/>
					) : (
						<div></div>
					)}

					<div className='campaign-content-shown body2'>
						<div>
							<Search
								isLoading={searchLoader}
								placeholder={'Search by Jobname'}
								onChange={(value) => {
									setSearchLoader(true);
									searchCampaignsData(value);
									dispatch(setCampaignSearchedValue(value));
								}}
							/>
						</div>

						<span className='download-campaign-datas'>
							{pastCampaigns && pastCampaigns.length === 0 ? null : (
								<InteractiveButton
									title='Download'
									onClick={(event: any) => {
										downloadCampaignsData(event);
									}}
									icon={<DownloadAnalyticDatas />}
								/>
							)}
						</span>

						<Menu
							anchorEl={anchorE2}
							open={isOpen}
							setOpen={setDownloadOpen}
							options={dowloadOptions}
						/>
					</div>
				</div>
			</>
		);
	};

	const paginationForScheduledCampaigns = () => {
		return (
			<Pagination
				disableforward={
					totalScheduledCampaigns <= offset2 + scheduledCampaigns.length
				}
				disableback={offset2 === 0}
				nextPage={() => {
					setOffset2(offset2 + LIMIT);
					setPage2((page) => page + 1);
				}}
				previousPage={() => {
					setOffset2(offset2 - LIMIT);
					setPage2((page) => page - 1);
				}}
				gotoBegining={() => {
					setOffset2(0);
					setPage2(0);
				}}
				gotoLast={() => {
					setOffset2(Math.floor(totalScheduledCampaigns / LIMIT) * LIMIT);
					setPage2(Math.floor(totalScheduledCampaigns / LIMIT));
				}}
				totalCount={totalScheduledCampaigns}
				upper={UpperL2}
				lower={LowerL2}
			/>
		);
	};
	const paginationForInProgress = () => {
		return (
			<Pagination
				disableforward={
					totalInProgressCampaigns <= offset3 + inProgressCampaigns.length
				}
				disableback={offset3 === 0}
				nextPage={() => {
					setOffset3(offset3 + LIMIT);
					setPage3((page) => page + 1);
				}}
				previousPage={() => {
					setOffset3(offset3 - LIMIT);
					setPage3((page) => page - 1);
				}}
				gotoBegining={() => {
					setOffset3(0);
					setPage3(0);
				}}
				gotoLast={() => {
					setOffset3(Math.floor(totalInProgressCampaigns / LIMIT) * LIMIT);
					setPage3(Math.floor(totalInProgressCampaigns / LIMIT));
				}}
				totalCount={totalInProgressCampaigns}
				upper={UpperL3}
				lower={LowerL3}
			/>
		);
	};

	return (
		<>
			<div className='sf-campaign-header'>
				<h4>Campaigns</h4>
				<div className='sf-campaign-button'>
					<Button onClick={() => navigate('create')}>
						<p className='button-1'>Create new campaign</p>
					</Button>
				</div>
			</div>

			<div className='sf-campaigns'>
				{!Boolean(scheduledCampaigns.length) &&
					!Boolean(inProgressCampaigns.length) &&
					!Boolean(pastCampaigns.length) && (
						<p
							className='body2 campaign-not-found'
							style={{ textAlign: 'center' }}>
							No campaigns found
						</p>
					)}
				{Boolean(inProgressCampaigns.length) && (
					<div className='sf-list-of-campaigns'>
						<div className='heading'>
							<CachedOutlinedIcon />
							<h6>In progress</h6>
						</div>

						<div id='sf-campaign-data'>
							{!isInProgressLoading && !Boolean(inProgressCampaigns.length) ? (
								<p className='body2 campaign-not-found'>No campaigns found</p>
							) : (
								<>
									<Table
										height={400}
										isLoading={isInProgressLoading}
										column={column1}
										data={inProgressCampaigns}
										page={offset3 / LIMIT}
										pagination={paginationForInProgress()}
										limit={LIMIT}
										totalCount={totalInProgressCampaigns}
									/>
								</>
							)}
						</div>
						<Menu
							anchorEl={anchorEl}
							open={open}
							setOpen={setOpen}
							options={options}
						/>
					</div>
				)}

				{Boolean(scheduledCampaigns.length) &&
					kotakTenantCheckForWhatsapp(`${loadState('tenantId')}`) && (
						<div className='sf-scheduled sf-list-of-campaigns'>
							<div className='heading'>
								<AccessTimeOutlinedIcon />
								<h6>Scheduled</h6>
							</div>
							<div id='sf-campaign-data'>
								{!isScheduledCampaignsLoading &&
								!Boolean(scheduledCampaigns.length) ? (
									<p className='body2 campaign-not-found'>No campaigns found</p>
								) : (
									<>
										<Table
											height={400}
											column={column2}
											limit={LIMIT}
											page={offset2 / LIMIT}
											pagination={paginationForScheduledCampaigns()}
											totalCount={totalScheduledCampaigns}
											isLoading={isScheduledCampaignsLoading}
											data={scheduledCampaigns}
											onRowClick={(row: any) =>
												navigate(`details/${row.jobId}`, {
													state: { ...row },
												})
											}
										/>
										<Menu
											anchorEl={anchorEl}
											open={open}
											setOpen={setOpen}
											options={options}
										/>
									</>
								)}
							</div>
						</div>
					)}

				{/* {Boolean(pastCampaigns.length) && ( */}
				<div className='sf-list-of-campaigns'>
					<div className='heading body1'>
						<HistoryOutlinedIcon />
						<h6>Past campaigns</h6>
					</div>
					<div id='sf-campaign-data'>
						{/* {!isLoading && !Boolean(pastCampaigns.length) ? (
							<p className='body2 campaign-not-found'>No campaigns found</p>
						) : ( */}
						<>
							<Table
								height={400}
								column={column3}
								limit={LIMIT}
								page={offset / LIMIT}
								pagination={paginationForPastCampaigns()}
								totalCount={totalPastCampaigns}
								isLoading={isLoading}
								data={pastCampaigns}
								onRowClick={(row: any) =>
									navigate(`details/${row.jobId}`, {
										state: { ...row },
									})
								}
							/>
						</>
						{/* )} */}
					</div>
				</div>
				{/* )} */}
				<Outlet />
			</div>
		</>
	);
};

export default Campaign;
