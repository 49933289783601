import { Tooltip } from '@mui/material';
import { getBgAndIconColor } from '../../utils/utils';
import { TeamIcon } from '../TeamIcon';
import './TeamCard.scss';
import { WhiteArrowTeams } from '../../assets/Icons';
type eachTeam = {
	id: number;
	name: string;
	backgroundImage: string;
	role: string;
	roleId: number;
	showWelcome: boolean;
};
interface Props {
	team: eachTeam;
	selectTeam: Function;
	index: number;
	icon?: boolean;
}

const TeamCard = ({ team, index, selectTeam }: Props) => {
	return (
		<div
			className='team-card'
			key={index}
			onClick={() => selectTeam && selectTeam()}
			//style={{ backgroundColor: getBgAndIconColor('card', index) }}
		>
			<div className='overlay'></div>
			<TeamIcon
				image={team.backgroundImage ?? ''}
				teamName={team.name}
				index={index}
			/>
			<div className='team-name' key={team.name + index}>
				<Tooltip title={team.name}>
					<span className='body1'>{team.name}</span>
				</Tooltip>
			</div>
			<div className='team-arrow'>
				<WhiteArrowTeams />
			</div>
		</div>
	);
};

export default TeamCard;
