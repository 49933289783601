import styled from 'styled-components';

export const Footer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 3px 21px 3px 20px;
	background-color: rgba(0, 0, 0, 0.3);
	color: var(--white);
	font-size: 12px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
`;

type SVGProps = {
	colorScale: string[];
	enableGenerateLeads?: boolean;
};
type NewAIFooterProps = {
	colorScale: string[];
	enableGenerateLeads?: boolean;
	fontSize: string;
	mode: string;
};

export const NewAIFooter = styled.div<NewAIFooterProps>`
	border-bottom-left-radius: 24px;
	border-bottom-right-radius: 24px;
	height: 103px;
	background-color: var(--white);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-top: 1px solid var(--black-20);
	color: ${(props) => (props.mode === 'dark' ? 'black' : props.colorScale[0])};
	& > div:first-child {
		display: flex;
		align-items: center;
		height: 71px;
		& > div {
			display: flex;
			flex-direction: column;
			align-items: center;
			// width: ${(props) => (props.enableGenerateLeads ? '25%' : '33.33%')};
			width: 50%;
			cursor: pointer;
			& > p {
				font-family: inherit;
				color: inherit;
				font-size: ${(props) =>
					Number(props.fontSize.substring(0, 2)) * 0.75}px;
				white-space: nowrap;
			}
			&.selected {
				& > p {
					font-weight: 600;
					color: ${(props) => props.colorScale[0]};
				}
			}
		}
	}
	& > .timestamp {
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 1.8;
		letter-spacing: 0.33px;
		color: var(--black-40);
		margin-bottom: 8px;
	}
`;

export const HOMESVG = styled.svg<SVGProps>`
	width: 28px;
	height: 28px;
	fill: ${(props) => props.colorScale[0]};
`;

export const HOMESVGNOTFILLED = styled.svg<SVGProps>`
	width: 28px;
	height: 28px;
	opacity: 0.6;

	fill: ${(props) => props.colorScale[0]};
`;

type CHATPROPS = SVGProps & {
	colorMode: string;
};

export const CHATSVG = styled.svg<CHATPROPS>`
	width: 28px;
	height: 28px;
	opacity: 0.6;

	fill: ${(props) =>
		props.colorMode === 'dark' ? 'black' : props.colorScale[0]};
`;

export const FEEDBACKSVG = styled.svg<CHATPROPS>`
	width: 28px;
	height: 28px;
	opacity: 0.6;

	fill: ${(props) =>
		props.colorMode === 'dark' ? 'black' : props.colorScale[0]};
`;

export const GETINTOUCH = styled.svg<CHATPROPS>`
	width: 28px;
	height: 28px;

	fill: ${(props) =>
		props.colorMode === 'dark' ? 'black' : props.colorScale[0]};
`;

export const GETINTOUCHNOTFILLED = styled.svg<CHATPROPS>`
	width: 28px;
	height: 28px;
	opacity: 0.6;

	fill: ${(props) =>
		props.colorMode === 'dark' ? 'black' : props.colorScale[0]};
`;
