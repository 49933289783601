type Props = {
	width?: string;
	height?: string;
	fillIcon?: boolean;
	className?: string;
	color?: string;
	onClick?: (e: React.MouseEvent<SVGElement>) => void;
	style?: React.CSSProperties;
	rotateSVG?: boolean;
	onBlur?: (e: React.FocusEvent<SVGElement>) => void;
	iconOutside?: boolean;
};

export function GCBCMinimise(props: Props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 24 24'>
			<g data-name='Group 5394'>
				<path
					fill={
						!props.iconOutside ? (props.color ? props.color : '#000') : '#fff'
					}
					fill-opacity='.6'
					data-name='Path 2314'
					d='M22 3.41 16.71 8.7 20 12h-8V4l3.29 3.29L20.59 2zM3.41 22l5.29-5.29L12 20v-8H4l3.29 3.29L2 20.59z'></path>
			</g>
		</svg>
	);
}
