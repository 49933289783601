import builderService from './builderService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { State as configureState } from '../configure/model';
import {
	Action,
	ActionDetails,
	AddAction,
	CreateBotFromScratch,
	AddDisplayAction,
	AddQuery,
	AddVariation,
	ChangeRootAction,
	CloneAction,
	DeleteAction,
	DeleteQuery,
	DeleteWorkFlow,
	GetActions,
	GetQueries,
	GetQuery,
	GetVariationGenerator,
	Query,
	SelectedQuery,
	UpdateActionPosition,
	Variation,
	GetAction,
	AddCRMAction,
	GetCRMAction,
	GetLinkedCRM,
	AddTriggerQueryAction,
	AddUserRatingAction,
	AddConfirmValuesAction,
	TrainBot,
	AddMultipleOptionAction,
	LinkedCRM,
	ValidateConfig,
	GetSuggestedResponseParam,
	AddUserInput,
	AddCallAPIAction,
} from './model';
import { loadState } from '../../utils/utils';
import { changeBotSession, getBotsList } from '../Bots/BotSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { set } from '../message/messageSlice';

export type State = {
	isBotCreating: boolean;
	isQueryAdding: string;
	isQueryDeleting: string;
	isQueryEditing: string;
	areQueriesLoading: boolean;
	areActionsLoading: boolean;
	botId: null | number;
	name: string;
	tenantId: null | number;
	activeQuery: null | Query;
	selectedQuery: null | SelectedQuery;
	queries: Query[];
	actions: Action[];
	actionDetails: null | ActionDetails;
	variations: Variation[];
	linkedCRMs: LinkedCRM[];
	botAdded: boolean;
	isNameValid: boolean | null;
	isTraining: boolean;
	trainingStatus: boolean | null;
	trainingResponse: string;

	refreshBot: boolean;
	testAndOpenBot: boolean;
	queryEditIndex: number | null; // queryEditIndex and activeQueryIndex are needed for updating query name indicator on Ad/Storefront page.
	activeQueryIndex: number | null;
	deleteResponse: string;
};

export const createBotFromScratch = createAsyncThunk<
	void,
	CreateBotFromScratch,
	{ rejectValue: string }
>(
	'builder/createBotFromScratch',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const welcomeMessage = data.welcomeMessage;
			delete data.welcomeMessage;
			const response = await builderService.createBotFromScratch(data);
			const bot = response.data;
			const { configure } = getState() as { configure: configureState };
			const { teams } = getState() as { teams: any };
			const { tenantId } = teams;

			if (response.status === 201 && bot.response === 'Success' && tenantId) {
				dispatch(
					createBotConfig({
						...configure.botConfiguration,
						botId: bot.id,
						tenantId,
						welcomeMessage,
					})
				);
			}

			return;
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const validateConfig = createAsyncThunk<
	boolean,
	ValidateConfig,
	{ rejectValue: string }
>('builder/validateConfig', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await builderService.validateConfig(data);
		if (response.status === 200) {
			if (response.data.response === 'Success') return true;
			if (response.data.response === 'Failed') return false;
			return false;
		}

		return rejectWithValue('Something went wrong! Try again later1');
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const createBotConfig = createAsyncThunk<
	number,
	any,
	{ rejectValue: string }
>('builder/createBotConfig', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await builderService.createBotConfig(data);
		if (response.status === 200) {
			dispatch(getBotsList({ tenantId: data.tenantId }));
			dispatch(
				changeBotSession({ tenantId: data.tenantId, bot_id: data.botId })
			);
			dispatch(getQueries({ tenant_id: data.tenantId, bot_id: data.botId }));
			return data.botId;
		}

		return rejectWithValue('Something went wrong! Try again later1');
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const getVariation = createAsyncThunk<
	Variation[],
	GetVariationGenerator,
	{ rejectValue: string }
>('builder/getVariation', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.variationGenerator(data);
		const variations = response.data.output;

		return variations;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const getQueries = createAsyncThunk<
	Query[],
	GetQueries,
	{ rejectValue: string }
>('builder/getQueries', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getQueries(data);
		const queries = response.data.nodes;

		return queries;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const getQuery = createAsyncThunk<
	SelectedQuery,
	GetQuery,
	{ rejectValue: string }
>('builder/getQuery', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getQuery(data);
		const query = response.data;

		return query;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const addQuery = createAsyncThunk<
	Query,
	AddQuery,
	{ rejectValue: string }
>('builder/addQuery', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response = await builderService.addQuery(data);

		if (response.status === 200) {
			const res = response.data.response;

			if (res === 'Node(s) created successfully') {
				const { builder } = getState() as { builder: State };
				const nodeId = response.data.id;
				const { botId, tenantId } = builder;

				const variants = data?.variants ?? [];

				dispatch(
					addVariation({
						nodeId: nodeId,
						variation: variants,
					})
				);
				if (botId && tenantId) {
					dispatch(getQueries({ bot_id: botId, tenant_id: tenantId }));
				}
				const nodeData = response.data.data;
				const query: Query = {
					...data,
					nodeId: nodeData.nodeId,
					workflowId: nodeData.workflowId,
				};
				return query;
			}
		}
		return rejectWithValue('Something went wrong!');
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const updateQuery = createAsyncThunk<
	Query,
	Query,
	{ rejectValue: string }
>(
	'builder/updateQuery',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await builderService.updateQuery(data);

			if (response.status === 200) {
				const res = response.data.response;
				const { builder } = getState() as { builder: State };
				const { botId, tenantId, queryEditIndex, activeQueryIndex } = builder;

				if (res === 'success') {
					if (queryEditIndex === activeQueryIndex) {
						dispatch(setActiveQuery(data));
					}

					botId &&
						tenantId &&
						dispatch(getQueries({ bot_id: botId, tenant_id: tenantId }));
					return response.data.id;
				}
			}
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
		return rejectWithValue('Something went wrong!');
	}
);

export const deleteQuery = createAsyncThunk<
	void,
	DeleteQuery,
	{ rejectValue: string }
>(
	'builder/deleteQuery',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const { builder } = getState() as { builder: State };
			// const { botId, tenantId } = builder;
			if (
				// botId && tenantId
				loadState('botId') &&
				loadState('tenantId')
			) {
				const response = await builderService.deleteQuery({
					...data,
					bot_id: loadState('botId'),
					// botId,
					tenant_id: loadState('tenantId'),
					// tenantId,
				});

				if (response.status === 200) {
					dispatch(
						deleteWorkFlow({
							//  botId,
							//  tenantId,
							botId: Number(loadState('botId')),
							tenantId: Number(loadState('tenantId')),
							workflowId: data.workflowId,
						})
					);
					dispatch(
						getQueries({
							//botId
							// tenantId
							bot_id: Number(loadState('botId')),
							tenant_id: Number(loadState('tenantId')),
						})
					);
					dispatch(
						getQueries({
							//botId
							// tenantId
							bot_id: Number(loadState('botId')),
							tenant_id: Number(loadState('tenantId')),
						})
					);
					return response.data;
				}
			}
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
		return rejectWithValue('Something went wrong!');
	}
);

export const addVariation = createAsyncThunk<
	void,
	AddVariation,
	{ rejectValue: string }
>('builder/addVariation', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.addVariation(data);

		if (response.status === 200) {
			const res = response.data.response;

			if (res === 'success') {
				return;
			}
		}
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const deleteWorkFlow = createAsyncThunk<
	void,
	DeleteWorkFlow,
	{ rejectValue: string }
>(
	'builder/deleteWorkFlow',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const { builder } = getState() as { builder: State };
			const { botId, tenantId } = builder;
			if (botId && tenantId) {
				const response = await builderService.deleteWorkFlow({
					...data,
					botId,
					tenantId,
				});

				if (response.status === 201) {
					if (response.data.response === 'Success') {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflowId })
						);
						return;
					}
				}
			}
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
		return rejectWithValue('Something went wrong!');
	}
);

export const getActions = createAsyncThunk<
	Action[],
	GetActions,
	{ rejectValue: string }
>('builder/getActions', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getActions(data);
		const actions = response.data;

		return actions;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const getDisplayAction = createAsyncThunk<
	ActionDetails,
	GetAction,
	{ rejectValue: string }
>('builder/getDisplayAction', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getDisplayAction(data);
		const action = response.data;

		return action;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const addDisplayAction = createAsyncThunk<
	void,
	AddDisplayAction,
	{ rejectValue: string }
>(
	'builder/addDisplayAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await builderService.addDisplayAction(data);

			if (response.status === 201) {
				if (response.data.response === 'Success') {
					const { builder } = getState() as { builder: State };
					const { botId, tenantId } = builder;
					if (botId && tenantId) {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflowId })
						);
					}
				}
			}
			return;
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const getLinkedCRM = createAsyncThunk<
	LinkedCRM[],
	GetLinkedCRM,
	{ rejectValue: string }
>('builder/getLinkedCRM', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getLinkedCRM(data);
		if (response.status === 200) {
			const action = response.data;

			return action;
		}
		return rejectWithValue('something went wrong');
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const getCRMAction = createAsyncThunk<
	ActionDetails,
	GetCRMAction,
	{ rejectValue: string }
>('builder/getCRMAction', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getCRMAction(data);
		const action = response.data;

		return action;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const addCRMAction = createAsyncThunk<
	void,
	AddCRMAction,
	{ rejectValue: string }
>(
	'builder/addCRMAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await builderService.addCRMAction(data);

			if (response.status === 201) {
				const { builder } = getState() as { builder: State };
				const { botId, tenantId } = builder;
				if (botId && tenantId) {
					dispatch(
						getActions({ botId, tenantId, workflowId: data.workflowId })
					);
				}
			}
			return;
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const getTriggerQueryAction = createAsyncThunk<
	ActionDetails,
	GetAction,
	{ rejectValue: string }
>('builder/getTriggerQueryAction', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getTriggerQueryAction(data);
		const action = response.data;

		return action;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const addTriggerQueryAction = createAsyncThunk<
	void,
	AddTriggerQueryAction,
	{ rejectValue: string }
>(
	'builder/addCRMAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await builderService.addTriggerQueryAction(data);

			if (response.status === 201) {
				if (response.data.response === 'Success') {
					const { builder } = getState() as { builder: State };
					const { botId, tenantId } = builder;
					if (botId && tenantId) {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflowId })
						);
					}
				}
			}
			return;
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const getUserRatingAction = createAsyncThunk<
	ActionDetails,
	GetAction,
	{ rejectValue: string }
>('builder/getUserRatingAction', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getUserRatingAction(data);
		const action = response.data;

		return action;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const addUserRatingAction = createAsyncThunk<
	void,
	AddUserRatingAction,
	{ rejectValue: string }
>(
	'builder/addUserRatingAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await builderService.addUserRatingAction(data);

			if (response.status === 201) {
				if (response.data.response === 'Success') {
					const { builder } = getState() as { builder: State };
					const { botId, tenantId } = builder;
					if (botId && tenantId) {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflowId })
						);
					}
				}
			}
			return;
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const getConfirmValuesAction = createAsyncThunk<
	ActionDetails,
	GetAction,
	{ rejectValue: string }
>('builder/getConfirmValuesAction', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getConfirmValuesAction(data);
		const action = response.data;

		return action;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const addConfirmValuesAction = createAsyncThunk<
	void,
	AddConfirmValuesAction,
	{ rejectValue: string }
>(
	'builder/addConfirmValuesAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await builderService.addConfirmValuesAction(data);

			if (response.status === 201) {
				if (response.data.response === 'Success') {
					const { builder } = getState() as { builder: State };
					const { botId, tenantId } = builder;
					if (botId && tenantId) {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflowId })
						);
					}
				}
			}
			return;
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const getCallAPIAction = createAsyncThunk<
	ActionDetails,
	GetAction,
	{ rejectValue: string }
>('builder/getCallAPIAction', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getCallAPIAction(data);
		const action = response.data;

		return action;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const addCallAPIAction = createAsyncThunk<
	void,
	AddCallAPIAction,
	{ rejectValue: string }
>(
	'builder/addCallAPIAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await builderService.addCallAPIAction(data);

			if (response.status === 201) {
				if (response.data.response === 'Success') {
					const { builder } = getState() as { builder: State };
					const { botId, tenantId } = builder;
					if (botId && tenantId) {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflowId })
						);
					}
				}
			}
			return;
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const getMultipleChoiceAction = createAsyncThunk<
	ActionDetails,
	GetAction,
	{ rejectValue: string }
>('builder/getMultipleChoiceAction', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getMultipleChoiceAction(data);
		const action = response.data;

		return action;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const addMultipleChoiceAction = createAsyncThunk<
	void,
	AddMultipleOptionAction,
	{ rejectValue: string }
>(
	'builder/addMultipleChoiceAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await builderService.addMultipleChoiceAction(data);

			if (response.status === 201) {
				if (response.data.response === 'Success') {
					const { builder } = getState() as { builder: State };
					const { botId, tenantId } = builder;
					if (botId && tenantId) {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflowId })
						);
					}
				}
			}
			return;
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const getMultipleSelectAction = createAsyncThunk<
	ActionDetails,
	GetAction,
	{ rejectValue: string }
>('builder/getMultipleSelectAction', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getMultipleSelectAction(data);
		const action = response.data;

		return action;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const addMultipleSelectAction = createAsyncThunk<
	void,
	AddMultipleOptionAction,
	{ rejectValue: string }
>(
	'builder/addMultiplSelectAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await builderService.addMultipleSelectAction(data);

			if (response.status === 201) {
				if (response.data.response === 'Success') {
					const { builder } = getState() as { builder: State };
					const { botId, tenantId } = builder;
					if (botId && tenantId) {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflowId })
						);
					}
				}
			}
			return;
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const getUserInputAction = createAsyncThunk<
	ActionDetails,
	GetAction,
	{ rejectValue: string }
>('builder/getUserInputAction', async (data, { rejectWithValue }) => {
	try {
		const response = await builderService.getUserInputAction(data);
		const action = response.data;

		return action;
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

export const addUserInputAction = createAsyncThunk<
	void,
	AddUserInput,
	{ rejectValue: string }
>(
	'builder/addUserInputAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await builderService.addUserInputAction(data);

			if (response.status === 201) {
				if (response.data.response === 'Success') {
					const { builder } = getState() as { builder: State };
					const { botId, tenantId } = builder;
					if (botId && tenantId) {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflowId })
						);
					}
				}
			}
			return;
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
	}
);

export const addAction = createAsyncThunk<
	void,
	AddAction,
	{ rejectValue: string }
>(
	'builder/addAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const { builder } = getState() as { builder: State };
			const { botId, tenantId } = builder;

			if (botId && tenantId) {
				const response = await builderService.addAction({
					...data,
					botId,
					tenantId,
				});

				if (response.status === 201) {
					if (response.data.response === 'Success') {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflow_id })
						);
						return;
					}
				}
			}
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
		return rejectWithValue('Something went wrong!');
	}
);

export const deleteAction = createAsyncThunk<
	void,
	DeleteAction,
	{ rejectValue: string }
>(
	'builder/deleteAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const { builder } = getState() as { builder: State };
			const { botId, tenantId } = builder;
			if (botId && tenantId) {
				const response = await builderService.deleteAction({
					...data,
					botId,
					tenantId,
				});

				if (response.status === 200) {
					if (response.data.response === 'Success') {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflowId })
						);
						return;
					}
				}
			}
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
		return rejectWithValue('Something went wrong!');
	}
);

export const changeRootAction = createAsyncThunk<
	void,
	ChangeRootAction,
	{ rejectValue: string }
>(
	'builder/changeRootAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const { builder } = getState() as { builder: State };
			const { botId, tenantId } = builder;
			if (botId && tenantId) {
				const response = await builderService.changeRootAction({
					...data,
					botId,
					tenantId,
				});

				if (response.status === 200) {
					if (response.data.response === 'Success') {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflowId })
						);
						return;
					}
				}
			}
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
		return rejectWithValue('Something went wrong!');
	}
);

export const cloneAction = createAsyncThunk<
	void,
	CloneAction,
	{ rejectValue: string }
>(
	'builder/cloneAction',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const { builder } = getState() as { builder: State };
			const { botId, tenantId } = builder;
			if (botId && tenantId) {
				const response = await builderService.cloneAction({
					...data,
					botId,
					tenantId,
				});

				if (response.status === 201) {
					if (response.data.response === 'Success') {
						dispatch(
							getActions({ botId, tenantId, workflowId: data.workflowId })
						);
						return;
					}
				}
			}
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
		return rejectWithValue('Something went wrong!');
	}
);

export const updateActionPosition = createAsyncThunk<
	UpdateActionPosition,
	UpdateActionPosition,
	{ rejectValue: string }
>(
	'builder/updateActionPosition',
	async (data, { rejectWithValue, getState }) => {
		try {
			const { builder } = getState() as { builder: State };
			const { botId, tenantId } = builder;
			if (botId && tenantId) {
				await builderService.updateActionPosition({
					...data,
					botId,
					tenantId,
				});

				return data;
			}
		} catch (error: any) {
			const response = error.response;
			return rejectWithValue(response.data.message);
		}
		return rejectWithValue('Something went wrong!');
	}
);

export const trainBot = createAsyncThunk<
	any,
	TrainBot,
	{ rejectValue: string }
>('builder/trainBot', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await builderService.trainBot(data);
		if (response.status === 200) {
			// return;
			return response.data;
		} else {
			dispatch(set('Failed to publish changes. Please retry in some time'));
		}
		return rejectWithValue('failed');
	} catch (error: any) {
		const response = error.response;
		return rejectWithValue(response.data.message);
	}
});

const botId: number = parseInt(loadState('botId'));
const tenantId: number = parseInt(loadState('tenantId'));
const details = loadState('botDetails');
const name: string = details ? details.botName : 'Bot Name';

const initialState: State = {
	isBotCreating: false,
	isQueryAdding: '',
	isQueryDeleting: '',
	isQueryEditing: '',
	areQueriesLoading: true,
	areActionsLoading: true,
	botId: botId ?? null,
	name: name,
	tenantId: tenantId ?? null,
	activeQuery: null,
	selectedQuery: null,
	queries: [],
	actions: [],
	actionDetails: null,
	variations: [],
	linkedCRMs: [],
	botAdded: false,
	isNameValid: null,
	isTraining: false,
	trainingStatus: null,
	trainingResponse: '',

	refreshBot: false,
	testAndOpenBot: false,
	queryEditIndex: null,
	activeQueryIndex: null,
	deleteResponse: '',
};

const builderSlice = createSlice({
	name: 'builder',
	initialState,
	reducers: {
		resetActionDetails: (state) => {
			state.actionDetails = null;
		},
		resetLinkedCRMs: (state) => {
			state.linkedCRMs = [];
		},
		resetBotAdded: (state) => {
			state.botAdded = false;
		},
		resetQueryAdded: (state) => {
			state.isQueryAdding = '';
		},
		resetQueryDeleted: (state) => {
			state.isQueryDeleting = '';
		},
		resetQueryEditing: (state) => {
			state.isQueryEditing = '';
		},
		init: (state, action) => {
			state.botId = action.payload.botId;
			state.tenantId = action.payload.tenantId;
			state.name = action.payload.name;
			state.botAdded = false;
		},
		setName: (state, action) => {
			state.name = action.payload;
		},
		setActiveQuery: (state, action) => {
			state.activeQuery = action.payload;
			// state.displayQuestion = action.payload.displayQuestion;
		},
		removeSelectedQuery: (state) => {
			state.selectedQuery = null;
		},
		reset: (state) => {
			state.activeQuery = null;
			state.queries = [];
			state.actions = [];
			state.actionDetails = null;
			state.isNameValid = null;
			state.refreshBot = false;
			state.activeQueryIndex = null;
			state.queryEditIndex = null;
		},
		resetTrainBotResponse: (state) => {
			state.trainingResponse = '';
		},

		setRefreshBot: (state, action) => {
			state.refreshBot = action.payload;
		},
		setTestAndOpenBot: (state, action) => {
			state.testAndOpenBot = action.payload;
		},
		setQueryEditIndex: (state, action) => {
			state.queryEditIndex = action.payload;
		},
		setActiveQueryIndex: (state, action) => {
			state.activeQueryIndex = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(createBotFromScratch.pending, (state) => {
				state.isBotCreating = true;
			})
			.addCase(validateConfig.fulfilled, (state, action) => {
				state.isNameValid = action.payload;
			})
			.addCase(validateConfig.rejected, (state) => {
				state.isNameValid = null;
			})
			.addCase(createBotConfig.pending, (state) => {
				state.botAdded = false;
			})
			.addCase(createBotConfig.fulfilled, (state, action) => {
				state.botId = action.payload;
				state.botAdded = true;
				state.isBotCreating = false;
			})
			.addCase(createBotConfig.rejected, (state) => {
				state.botAdded = false;
				state.isBotCreating = false;
			})
			.addCase(getVariation.fulfilled, (state, action) => {
				state.variations = action.payload;
			})
			.addCase(getQueries.pending, (state) => {
				state.areQueriesLoading = true;
			})
			.addCase(getQueries.fulfilled, (state, action) => {
				state.areQueriesLoading = false;
				state.queries = action.payload;
			})
			.addCase(getQueries.rejected, (state) => {
				state.areQueriesLoading = false;
			})
			.addCase(getQuery.fulfilled, (state, action) => {
				state.selectedQuery = action.payload;
			})
			.addCase(addQuery.pending, (state) => {
				state.isQueryAdding = 'adding';
			})
			.addCase(addQuery.fulfilled, (state, action) => {
				state.activeQuery = action.payload;
				state.isQueryAdding = 'added';
			})
			.addCase(addQuery.rejected, (state) => {
				state.isQueryAdding = 'failed';
			})
			.addCase(deleteQuery.pending, (state) => {
				state.isQueryDeleting = 'deleting';
				state.deleteResponse = '';
			})
			.addCase(deleteQuery.fulfilled, (state, action) => {
				state.isQueryDeleting = 'deleted';
			})
			.addCase(deleteQuery.rejected, (state) => {
				state.isQueryDeleting = 'failed';
				state.deleteResponse = '';
			})
			.addCase(updateQuery.pending, (state) => {
				state.isQueryEditing = 'editing';
			})
			.addCase(updateQuery.fulfilled, (state, action) => {
				state.isQueryEditing = 'edited';
			})
			.addCase(updateQuery.rejected, (state) => {
				state.isQueryEditing = 'failed';
			})
			.addCase(getActions.pending, (state) => {
				state.areActionsLoading = true;
			})
			.addCase(getActions.fulfilled, (state, action) => {
				state.areActionsLoading = false;
				state.actions = action.payload;
				state.actions = state.actions.map((action) =>
					action.task_description === 'HandOff-To-Agent' &&
					action.userResponseTypeID === 1
						? { ...action, userResponseTypeID: 0 }
						: action
				);
			})
			.addCase(getActions.rejected, (state) => {
				state.areActionsLoading = false;
			})
			.addCase(getDisplayAction.fulfilled, (state, action) => {
				state.actionDetails = action.payload;
				state.actionDetails.userResponseTypeId =
					state.actionDetails.taskDescription === 'HandOff-To-Agent' ? 0 : 1;
			})
			.addCase(getLinkedCRM.pending, (state) => {
				state.linkedCRMs = [];
			})
			.addCase(getLinkedCRM.fulfilled, (state, action) => {
				state.linkedCRMs = action.payload;
			})
			.addCase(getLinkedCRM.rejected, (state) => {
				state.linkedCRMs = [];
			})
			.addCase(getCRMAction.fulfilled, (state, action) => {
				state.actionDetails = action.payload;
			})
			.addCase(getTriggerQueryAction.fulfilled, (state, action) => {
				state.actionDetails = action.payload;
			})
			.addCase(getUserRatingAction.fulfilled, (state, action) => {
				state.actionDetails = action.payload;
			})
			.addCase(getConfirmValuesAction.fulfilled, (state, action) => {
				state.actionDetails = action.payload;
			})
			.addCase(getCallAPIAction.fulfilled, (state, action) => {
				state.actionDetails = action.payload;
			})
			.addCase(getMultipleChoiceAction.fulfilled, (state, action) => {
				state.actionDetails = action.payload;
			})
			.addCase(getMultipleSelectAction.fulfilled, (state, action) => {
				state.actionDetails = action.payload;
			})
			.addCase(getUserInputAction.fulfilled, (state, action) => {
				state.actionDetails = action.payload;
			})
			.addCase(updateActionPosition.fulfilled, (state, action) => {
				state.actions = state.actions.map((act) =>
					act.task_ID === action.payload.task_ID
						? { ...act, task_position: action.payload.task_position }
						: act
				);
			})
			.addCase(trainBot.pending, (state) => {
				state.isTraining = true;
				state.trainingStatus = null;
			})
			.addCase(trainBot.fulfilled, (state, action) => {
				state.isTraining = false;
				state.trainingResponse = action.payload.response;
				state.trainingResponse = action.payload.response;
				state.trainingStatus = true;
			})
			.addCase(trainBot.rejected, (state) => {
				state.isTraining = false;
				state.trainingStatus = false;
			});
	},
});

export const {
	init,
	setActiveQuery,
	removeSelectedQuery,
	resetActionDetails,
	resetLinkedCRMs,
	resetBotAdded,
	resetQueryAdded,
	resetQueryDeleted,
	resetQueryEditing,
	reset,
	setName,
	setRefreshBot,
	setTestAndOpenBot,
	setQueryEditIndex,
	setActiveQueryIndex,
	resetTrainBotResponse,
} = builderSlice.actions;

export default builderSlice.reducer;
