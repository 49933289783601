import Slider from '../../../../../assets/images/Slider.png';
import './Preview.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ColorInput from '../../../../../Components/ColorInput';
import { FFLoader } from '../../../../../assets/Icons/Loader';
import {
	UploadImageEdit,
	UploadImageEditEOW,
} from '../../../../../assets/Icons';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { useState, useEffect } from 'react';
import { reset, setSnack } from '../../../../../Features/message/messageSlice';
import { setBackgroundImageLoader, setBotBackground } from '../../../../../Features/ExperienceOnWeb/experienceonwebSlice';

type Props = {
	background: string;
	value1: string;
	value2: Array<string>;
	onValueChange1: (arg0: string) => void;
	onValueChange2: Array<(arg0: string) => void>;
};

const Preview = ({
	background,
	value1,
	value2,
	onValueChange1,
	onValueChange2,
}: Props) => {
	const navigate = useNavigate();
	const { backgroundFile, appearanceBackgroundLoader, backgroundImageLoader } =
		useSelector((state: RootState) => state.experienceonweb);
	const [loader, setLoader] = useState(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (backgroundImageLoader === 'success') {
			setLoader(false);
			// dispatch(
			// 	setSnack({
			// 		message: 'Image uploaded successfully',
			// 		testcase: 'positive',
			// 	})
			// );
		} else if (backgroundImageLoader === 'failed') {
			setLoader(false);
			// dispatch(
			// 	setSnack({
			// 		message: 'Something Went Wrong!! Please try after sometime',
			// 		testcase: 'negative',
			// 	})
			// );
		} else if (backgroundImageLoader === 'loading') {
			setLoader(true);
		}
		dispatch(setBackgroundImageLoader(''));
	}, [backgroundImageLoader]);

	return (
		<div className='ff-preview'>
			{background === 'color' ? (
				<>
					<div
						className='color-box-input'
						style={{
							backgroundColor: value1,
						}}>
						<ColorInput
							className='color-box'
							type='color'
							value={value1}
							onValueChange={onValueChange1}
						/>
						{/* <div className='background-solid-color-value'>{value1}</div> */}
					</div>
				</>
			) : null}
			{background === 'gradient' ? (
				<div className='color-range-color'>
					<div
						className='color-box-input-start'
						style={{
							backgroundColor: value2[0],
						}}>
						{/* <div className='background-gradient-start-color-value'>
							{value2[0]}
						</div> */}
						<ColorInput
							className='color-box'
							type='color'
							value={value2[0]}
							onValueChange={onValueChange2[0]}
						/>
					</div>

					<div
						className='range-input'
						style={{
							background: `linear-gradient(to right, ${value2[0]} 0%, ${value2[2]} 100%), url(${Slider})`,
							backgroundBlendMode: 'multiply',
							minHeight: '6px',
							minWidth: '172px',
						}}></div>

					<div
						className='color-box-input-stop'
						style={{
							backgroundColor: value2[2],
						}}>
						<ColorInput
							className='color-box'
							type='color'
							value={value2[2]}
							onValueChange={onValueChange2[2]}
						/>
						{/* <div className='background-gradient-stop-color-value'>
							{value2[2]}
						</div> */}
					</div>
				</div>
			) : null}
			{background === 'image' ? (
				// <UploadImage uploadTo='background' />
				<div className='background-image'>
					{loader ? (
						<FFLoader width='40' />
					) : (
						backgroundFile !== '' && (
							<>
								<img src={backgroundFile} alt='background' />
								<span
									onClick={() =>
										navigate('add-image', {
											state: { value: 'BackgroundImage' },
										})
									}>
									<UploadImageEditEOW />
								</span>
							</>
						)
					)}
				</div>
			) : null}
		</div>
	);
};

export default Preview;
