import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../Components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import { loadState, saveState } from '../../../utils/utils';
import Input from '../../../Components/Input';
import './editpdf.scss';
import Modal from '../../../Components/Modal';
import {
	editPDFAPI,
	setEditTitle,
	setEditDescription,
	setEditAPIStatus,
} from '../../../Features/Ingest/IngestSlice';
import { isHTMLTagsPresent } from '../../../utils/utils';
const MAX_COUNT = 100;

export function EditPDF() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { fileDetails, editAPIStatus, editAPIStatusMsg, editTitle, editDescription } = useSelector(
		(state: RootState) => state.ingest
	);
	const [titleError, setTitleError] = useState(false);
	const [descError, setDescError] = useState(false);
	const [titleErrorText, setTitleErrorText] = useState('');
	const [descErrorText, setDescErrorText] = useState('');
	const [disableBtn, setDisableBtn] = useState(false);
	const [title, setTitle] = useState(
		fileDetails.file.title || fileDetails.file.name
	);
	const [description, setDescription] = useState(
		fileDetails.file.description || ''
	);
	useEffect(() => {
		if (editTitle.length) {
			setTitle(editTitle)
		}
		if (editDescription.length) {
			setDescription(editDescription)
		}
	}, [])

	const handleData = () => {
		if (
			title.trim().length === 0 ||
			description.trim().length === 0 ||
			title.trim().length < 5 ||
			description.trim().length < 50
		) {

			if (title.trim().length < 5 || title.trim().length === 0) {
				setTitleError(true);
				setTitleErrorText('Minimum 5 characters required');
			}
			if (description.trim().length < 50 || description.trim().length === 0) {
				setDescError(true);
				setDescErrorText('Minimum 50 characters required');
			}


		} else {

			dispatch(setEditTitle(title))
			dispatch(setEditDescription(description))
			dispatch(
				editPDFAPI({
					name: fileDetails.file.name,
					url: fileDetails.file.url,
					id: fileDetails.file.id,
					status: fileDetails.file.status,
					title: title.trim(),
					description: description.trim(),
					botId: loadState('botId'),
					tenantId: loadState('tenantId').toString(),
				})
			);
		}
	};
	useEffect(() => {
		if (editAPIStatus === 'failed' && editAPIStatusMsg) {
			setTitleError(true);
			setTitleErrorText(editAPIStatusMsg);
		}

	}, [editAPIStatus]);
	return (
		<>
			<Modal
				actionButtons={
					<div className='ff-input-edit-actions'>
						<Button
							onClick={() => {
								navigate('/app/Ingest-tab/pdf-preview');
								dispatch(setEditAPIStatus(''));
							}}
							buttonType='light'
						>
							<p>Cancel</p>
						</Button>
						<Button
							onClick={() => {
								handleData();
								dispatch(setEditAPIStatus(''));
							}}
							disabled={disableBtn}
							loading={editAPIStatus === 'loading'}
						>
							Done
						</Button>
					</div>
				}
			>
				<div className='ff-input-edit-content'>
					<h5>Edit document details</h5>
					<div>
						<Input
							label='Title'
							placeholder='Title'
							autoFocus
							disAllowSpecialChar
							value={title}
							onChange={(ev) => {
								setTitleError(false);
								setTitle(ev.target.value.trimStart());

								if (ev.target.value.trim().length > 50 || isHTMLTagsPresent(ev.target.value)) {
									setDisableBtn(true);
								} else {
									setDisableBtn(false);
								}

							}}
							error={titleError}
							errorText={titleErrorText}
							limit={50}
						/>
					</div>
					<div>
						<Input
							label='Summary'
							placeholder='Summary'
							value={description}
							disAllowSpecialChar
							error={descError}
							onChange={(ev) => {
								setDescError(false);
								setDescription(ev.target.value.trimStart());
								if (ev.target.value.trim().length > 250 || isHTMLTagsPresent(ev.target.value)) {
									setDisableBtn(true);
								} else {
									setDisableBtn(false);
								}
							}}
							errorText={descErrorText}
							limit={250}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
}
