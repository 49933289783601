import { useEffect, useState } from 'react';
import Button from '../../../Components/Button';
import Checkbox from '../../../Components/Checkbox';
import HorizontalSeparator from '../../../Components/HorizontalSeparator';
import Input from '../../../Components/Input';
import Radio from '../../../Components/Radio';
import { ConfigureDelete, ConfigurePlus } from '../../../assets/Icons';
import './Enhancements.scss';

const Enhancements = () => {
	const items = [
		{
			category: 'Predictive search',
			description:
				'Let the bot predict the queries of users based on what the users are typing. A list of suggestions is shown from which the user can pick one. This works only for the web channel',
		},
		// {
		// 	category: 'Persistent menu',
		// 	description:
		// 		'An ever-accessible, collapsible menu in the bot that shows commonly asked queries or topics.',
		// },
	];
	const [checked, setChecked] = useState(new Array(items.length).fill(false));
	const [enhancements, setEnhancements] = useState('');
	const [val1, setVal1] = useState('');
	const [val2, setVal2] = useState('');

	return (
		<div className='ff-enhancements'>
			{items.map((item, i) => {
				return (
					<>
						<div
							className={
								item.category === items[0].category
									? 'predictive-search'
									: 'persistent-menu'
							}>
							<div>
								<p>{item.category}</p>
								<p>{item.description}</p>
							</div>
							<div>
								<Checkbox
									isChecked={checked}
									index={i}
									onValueChange={setChecked}
								/>
							</div>
						</div>
						{items.length - 1 !== i ? (
							<HorizontalSeparator className='enhance-separator' />
						) : null}
					</>
				);
			})}
			{enhancements === 'custom' ? (
				<>
					<div className='enhance-radio-quicklinks'>
						<Radio
							value='quick links'
							isChecked={enhancements}
							onValueChange={setEnhancements}
							name='enhance-radio'
						/>
						<p className='quicklinks'>Same as quick links</p>
					</div>
					<div className='enhance-radio-custom'>
						<Radio
							value='custom'
							isChecked={enhancements}
							onValueChange={setEnhancements}
							name='enhance-radio'
						/>
						<p className='custom'>Custom</p>
					</div>
				</>
			) : null}
			{enhancements === 'custom' ? (
				<>
					<div className='enhance-text'>
						<p className='display'>Text to display</p>
						<p className='submit'>Text to submit</p>
					</div>
					<div className='enhance-input'>
						<div className='enhance-input-box1'>
							<Input
								value={val1}
								onChange={(event) => setVal1(event.target.value)}
								placeholder='Enter here...'
								autoFocus
							/>
						</div>
						<div className='enhance-input-box2'>
							<Input
								value={val2}
								onChange={(event) => setVal2(event.target.value)}
								placeholder='Enter here...'
							/>
						</div>
						<div>
							<ConfigureDelete />
						</div>
					</div>
				</>
			) : null}
			{enhancements === 'custom' ? (
				<div>
					<Button
						className='configure-add-button'
						startIcon={<ConfigurePlus />}>
						Add menu item
					</Button>
				</div>
			) : null}
			<div className='enhancement-save-button-div'>
				<Button className='enhancement-save-button'>Save</Button>
			</div>
		</div>
	);
};

export default Enhancements;
