import { CheckBox } from '@mui/icons-material';
import React, { Component } from 'react';
import SnackBar from '../../../../Components/SnackBar';
import { loadState } from '../../../../utils/utils';
import PageLoader from '../../../../Components/PageLoader';

type Props = {
	onClose: Function;
	displaySelectedTimezone: Function;
	selectedTenantId: number;
	selectedTimeZone: string;
};

type State = {
	timeZoneList: { timezone: string; id: number }[];
	selectedTimeZone: string;
	saveNotify: boolean;
	timeZoneFetching: boolean;
};

export default class TimezoneList extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = this.setInitialState();
		this.saveTimezone = this.saveTimezone.bind(this);
	}
	componentDidMount() {
		this.setState({
			timeZoneFetching: true,
		});
		this.fetchTimezoneList();
	}
	setInitialState(): State {
		return {
			timeZoneList: [],
			selectedTimeZone: '(GMT+5:30) Asia/Kolkata',
			saveNotify: false,
			timeZoneFetching: false,
		};
	}
	async fetchTimezoneList() {
		const response = await fetch(
			`${
				window.location.origin.indexOf('localhost:3000') > -1
					? 'https://dev.flyfish.ai'
					: window.location.origin
			}/configuration/${this.props.selectedTenantId}/timezone`,
			{
				headers: {
					Authorization: `Bearer ${loadState('token')}`,
				},
			}
		);
		const res = await response.json();
		if (res) {
			this.setState({
				timeZoneFetching: false,
			});
			this.setState({
				timeZoneList: res.map((timezone: any, index: any) => ({
					timezone: timezone,
					id: index,
				})),
			});
		} else {
			this.setState({
				timeZoneFetching: false,
			});
			console.log('Failed to fetch the list. Please try again!!');
		}
	}
	async saveTimezone() {
		// let data = {
		// 	timezone: this.state.selectedTimeZone,
		// 	tenantId: this.props.selectedTenantId.toString(),
		// };
		// this.setState({
		// 	saveNotify: true,
		// });
		this.setState(
			{
				saveNotify: false,
			},
			() => {
				this.props.onClose();
				this.props.displaySelectedTimezone(this.state.selectedTimeZone);
			}
		);

		// await fetch(`HybridChat/setTimezone`, {
		// 	method: 'POST',
		// 	body: JSON.stringify(data),
		// }).then((response) => {
		// 	if (response) {
		// 		this.setState(
		// 			{
		// 				saveNotify: false,
		// 			},
		// 			() => {
		// 				this.props.onClose();
		// 				this.props.displaySelectedTimezone(this.state.selectedTimeZone);
		// 			}
		// 		);
		// 		// toast(<SnackBar type='success'>Time zone updated</SnackBar>, {
		// 		// 	autoClose: 2000,
		// 		// });
		// 	}
		// });
	}
	componentDidUpdate(prevProps: Props, _: State) {
		if (prevProps.selectedTimeZone !== this.props.selectedTimeZone) {
			this.setState({
				selectedTimeZone: this.props.selectedTimeZone,
			});
		}
	}
	render() {
		return (
			<>
				{this.state.timeZoneFetching ? (
					<div>
						<PageLoader message='Loading...' />
					</div>
				) : (
					<div style={{ minWidth: '452px' }}>
						<div className='list-of-timezone-wrapper'>
							{this.state.timeZoneList.map((val, index) => {
								return (
									<div
										onClick={() => {
											this.setState({ selectedTimeZone: val.timezone }, () =>
												this.saveTimezone()
											);
										}}
										key={index}
										className={`list-of-timezone ${
											(
												this.props.selectedTimeZone
													? this.props.selectedTimeZone === val.timezone
													: this.state.selectedTimeZone === val.timezone
											)
												? 'selected'
												: ''
										} `}>
										{val.timezone}
										{this.state.selectedTimeZone === val.timezone ||
											(this.props.selectedTimeZone === val.timezone && (
												<CheckBox />
											))}
									</div>
								);
							})}
						</div>
					</div>
				)}
			</>
		);
	}
}
