import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	LinkWaba,
	Roles,
	SetWaba,
	updateBotStateReq,
	User,
	UserLoginData,
	wabaRequest,
	eachInstaObject,
	LinkInsta,
	modifyPageStateReq,
} from './model';
import {
	encryptText,
	loadState,
	removeAllState,
	saveState,
} from '../../../utils/utils';
import authService from './authService';
import { set, setSnackModal } from '../../message/messageSlice';
import { useLocation } from 'react-router-dom';

interface State {
	wabaNos: WabaNo[];
	wabaNo: string;
	expiry: string;
	roles: string[];
	getWabaStatue: boolean;
	isLoading: boolean;
	error: string;
	forcedLogout: boolean;
	setWabaStatus: boolean;
	wabaIds: string[];
	updatedStatus: string | undefined;
	updatednumbers: { phoneNumber: string; name: string }[];
	instaPages: eachInstaObject[];
	instaPagesRetrived: string;
	watsappIdRetrieved: string;
	accessToken: string;
	onBoardInstaStatus: string;
	onBoardWabaStatus: string;
	instaIds: InstaId[];
	selectWabaId:any;
}
export interface InstaId {
	botCategory: string | null; //'CA' | 'VSR';
	botIcon: null | string;
	botId: number | string;
	botName: string;
	resources: string[];
	roleName: string;
	pageId: string;
	pageName: string;
	instaStatus: 'Active' | 'Pause';
	instaUsername: string;
	botCoverImage?: string;
}
export interface GetWabaNos {
	wabaNos: WabaNo[];
	status: string;
	instaIds: InstaId[];
	// tenantId: string;
}

export interface WabaNo {
	botCategory: string | null; //'CA' | 'VSR';
	botIcon: null | string;
	botId: number | string;
	botName: string;
	resources: string[];
	roleName: string;
	wabaNo: string;
	wabaStatus: 'Active' | 'Pause' | string;
	botCoverImage: string;
}

export interface wabaInsta {
	botCategory: string | null; //'CA' | 'VSR';
	botIcon: null | string;
	botId: number | string;
	botName: string;
	resources: string[];
	roleName: string;
	wabaNo?: string;
	wabaStatus?: 'Active' | 'Pause' | string;
	pageId?: string;
	pageName?: string;
	instaStatus?: 'Active' | 'Pause' | string;
	instaUsername?: string;
}
export interface GetBusinessId {
	status: string;
	wabaIds: [];
}

export const getWabaNos = createAsyncThunk<
	GetWabaNos,
	{ tenantId: any },
	{ rejectValue: string }
>('auth/getWabaNos', async (data, { rejectWithValue }) => {
	try {
		const response = await authService.getWabaNos(data);
		if (response.data.status === 'success') {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong!', type: 'common' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const getWabaNosKotak = createAsyncThunk<
	GetWabaNos,
	{ tenantId: any },
	{ rejectValue: string }
>('auth/getWabaNosKotak', async (data, { rejectWithValue }) => {
	try {
		const response = await authService.getWabaNosKotak(data);
		if (response.data.status === 'success') {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong!', type: 'common' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const setWaba = createAsyncThunk<
	{ wabaNo: string; expiry: string },
	SetWaba,
	{ rejectValue: string }
>('auth/setWaba', async (data, { rejectWithValue }) => {
	try {
		const response = await authService.setWaba(data);

		if (response.status !== 200) {
			removeAllState();
		} else {
			if (response.data.status === 'success') {
				const token = response.data.token;
				const expiry = response.data.expiry;

				saveState('BSPtoken', token);
				saveState('BSPexpiry', expiry);
				saveState('wabaNo', data.wabaNo);
				return { wabaNo: data.wabaNo, expiry: expiry };
			} else if (response.data.status === 'fail') {
				removeAllState();
			}
		}

		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong!', type: 'common' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const getBusinessId = createAsyncThunk<
	GetBusinessId,
	{ inputToken: string },
	{ rejectValue: string }
>('auth/getBusinessId', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await authService.getBusinessId(data);
		if (response.data.status === 'success') {
			return response.data;
		}
		dispatch(set('Something went wrong! Please try again later'));
		return rejectWithValue(JSON.stringify({ error: 'Something went wrong!' }));
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const getInstaId = createAsyncThunk<
	any,
	{ fbresponse: any; accessToken: any },
	// { name: string; id: string },
	{ rejectValue: string }
>('auth/getInstaId', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await authService.getInstaId(data);
		if (response.data.status === 'success') {
			return response.data;
		}
		return rejectWithValue(JSON.stringify({ error: 'Something went wrong!' }));
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const onBoardInsta = createAsyncThunk<
	any,
	{ pageId: any; accessToken: any; tenantId: any },
	{ rejectValue: string }
>('auth/onBoardInsta', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await authService.onBoardInsta(data);
		if (response.data.status === 'success') {
			return response.data;
		}
		return rejectWithValue(JSON.stringify({ error: 'Something went wrong!' }));
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const onBoardWabano = createAsyncThunk<
	any,
	wabaRequest,
	{ rejectValue: string }
>(
	'auth/onboardwabano',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await authService.onBoardWabano(data);
			if (response.data.status.toUpperCase() === 'SUCCESS') {
				// dispatch(set('WhatsApp channel removed'));
				return response.data;
			}
			dispatch(set('Something went wrong! Try again later'));
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const updateBotState = createAsyncThunk<
	{ status: string },
	updateBotStateReq,
	{ rejectValue: string }
>('auth/updateBotState', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await authService.updateBotState(data);

		if (response.data.toUpperCase() === 'SUCCESS') {
			dispatch(
				setSnackModal({
					modalMesssage: `WhatsApp number ${
						data.botStatus === 'pause' ? 'paused' : 'active'
					}`,
					openModal: true,
				})
			);
			return response.data;
		}
		dispatch(set('Something went wrong! Try again later'));
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		dispatch(set((error as { message: string }).message));
		return rejectWithValue(JSON.stringify(error));
	}
});

export const modifyPageState = createAsyncThunk<
	{ status: string },
	modifyPageStateReq,
	{ rejectValue: string }
>('auth/modifyPageState', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await authService.modifyPageState(data);

		if (response.data.status.toUpperCase() === 'SUCCESS') {
			dispatch(set(`Instagram account ${data.action.toLowerCase()}`));
			return response.data;
		}
		dispatch(set('Something went wrong! Try again later'));
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		dispatch(set((error as { message: string }).message));
		return rejectWithValue(JSON.stringify(error));
	}
});

export const deactiveWaba = createAsyncThunk<
	any,
	{ wabaNo: string },
	{ rejectValue: string }
>('auth/deactiveWaba', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await authService.deactiveWaba(data);

		if (response.data.status.toUpperCase() === 'SUCCESS') {
			dispatch(set('WhatsApp number deactivated'));
			return response.data;
		}
		dispatch(set('Something went wrong! Try again later'));
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		dispatch(set((error as { message: string }).message));
		return rejectWithValue(JSON.stringify(error));
	}
});

export const removeInstaPage = createAsyncThunk<
	any,
	{ pageId: string },
	{ rejectValue: string }
>('auth/removeInstaPage', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await authService.removeInstaPage(data);

		if (response.data.status.toUpperCase() === 'SUCCESS') {
			dispatch(set('Instagram account removed'));
			return response.data;
		}
		dispatch(set('Something went wrong! Try again later'));
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		dispatch(set((error as { message: string }).message));
		return rejectWithValue(JSON.stringify(error));
	}
});

export const linkWaba = createAsyncThunk<
	any,
	LinkWaba,
	{ rejectValue: string }
>('auth/linkWaba', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await authService.linkWaba(data);
		const salesaiPath = window.location.pathname.indexOf('sales-ai') > -1;
		if (response.data.status.toUpperCase() === 'SUCCESS') {
			dispatch(
				set(
					salesaiPath
						? `Sales AI linked with ${data.wabaNo} on Whatsapp`
						: Boolean(data.botName)
						? `WhatsApp number linked with ${data.botName}`
						: `Unlinked from ${data.linkedBotName}`
				)
			);
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});
export const linkInsta = createAsyncThunk<
	any,
	LinkInsta,
	{ rejectValue: string }
>('auth/linkInsta', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await authService.linkInsta(data);

		if (response.data.status.toUpperCase() === 'SUCCESS') {
			dispatch(
				set(
					Boolean(data.botName)
						? `Instagram linked with ${data.botName}`
						: `Unlinked from ${data.linkedBotName}`
				)
			);
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});
export const unlinkWaba = createAsyncThunk<
	any,
	{ wabaNo: string },
	{ rejectValue: string }
>('auth/unlinkWaba', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await authService.unlinkWaba(data);

		if (response.data.status.toUpperCase() === 'SUCCESS') {
			dispatch(set(`Storefront removed from WhatsApp number ${data.wabaNo}`));
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const updateWabaNumber = createAsyncThunk<
	any,
	{ wabaId: string },
	{ rejectValue: string }
>('auth/updateWabaNumber', async (data, { rejectWithValue }) => {
	try {
		const response = await authService.updateWabaNumber(data);

		if (response.data.status.toUpperCase() === 'SUCCESS') {
			return response.data;
		}

		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

// const user: User = loadState('user');
const wabaNo: string = loadState('wabaNo');
const expiry: string = loadState('expiry');

const initialState: State = {
	wabaNos: [],
	getWabaStatue: false,
	wabaNo: wabaNo
		? wabaNo
		: loadState('wabaNoNew') &&
		  atob(loadState('wabaNoNew').split('$$$@@@:::$$$')[1]),
	expiry: expiry ? expiry : '',
	roles: [],
	isLoading: false,
	error: '',
	forcedLogout: false,
	setWabaStatus: false,
	wabaIds: [],
	updatedStatus: '',
	updatednumbers: [],
	instaPages: [],
	instaPagesRetrived: '',
	watsappIdRetrieved: '',
	accessToken: '',
	onBoardInstaStatus: '',
	onBoardWabaStatus: '',
	instaIds: [],
	selectWabaId:''
};

const BSPauthSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		reset: (state) => {
			state.isLoading = false;
			state.error = '';
			state.updatedStatus = '';
			state.updatednumbers = [];
		},
		setForcedLogout: (state) => {
			state.forcedLogout = true;
		},
		resetForcedLogout: (state) => {
			state.forcedLogout = false;
		},
		resetWabaStatus: (state) => {
			state.getWabaStatue = false;
			state.wabaNos = [];
			state.setWabaStatus = false;
			state.instaIds = [];
		},
		resetCreateInstaWatsapp: (state) => {
			state.instaPagesRetrived = '';
			state.watsappIdRetrieved = '';
		},
		resetOnBoardInstaStatus: (state) => {
			state.onBoardInstaStatus = '';
		},
		resetOnBoardWabaStatus: (state) => {
			state.onBoardWabaStatus = '';
		},
		setWabaNo: (state, action) => {
			state.wabaNo = action.payload;
			saveState(
				'wabaNoNew',
				'A:E:R:D:F:G:U:$$$@@@:::$$$' +
					btoa(action.payload) +
					'$$$@@@:::$$$A:E:R:D:F:G:U'
			);
		},
		resetUpdateNumbers: (state) => {
			state.updatednumbers = [];
		},
		setSelectedWabaId:(state, action) => {
			state.selectWabaId=action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getWabaNosKotak.pending, (state) => {
				state.error = '';
				state.isLoading = true;
			})
			.addCase(getWabaNosKotak.fulfilled, (state, action) => {
				state.wabaNos = action.payload.wabaNos;
				state.instaIds = action.payload.instaIds;
				state.isLoading = false;
				state.getWabaStatue = true;
			})
			.addCase(getWabaNosKotak.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong!';
				}
			})

			.addCase(getWabaNos.pending, (state) => {
				state.error = '';
				state.isLoading = true;
			})
			.addCase(getWabaNos.fulfilled, (state, action) => {
				state.wabaNos = action.payload.wabaNos;
				state.instaIds = action.payload.instaIds;
				state.isLoading = false;
				state.getWabaStatue = true;
			})
			.addCase(getWabaNos.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong!';
				}
			})
			.addCase(setWaba.pending, (state) => {
				state.error = '';
			})
			.addCase(setWaba.fulfilled, (state, action) => {
				state.wabaNo = action.payload.wabaNo;

				state.expiry = action.payload.expiry;
				state.roles =
					state.wabaNos.filter((user) => user.wabaNo === state.wabaNo)[0]
						.resources ?? [];
				state.setWabaStatus = true;
			})
			.addCase(setWaba.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong!';
				}
			})
			.addCase(getBusinessId.pending, (state) => {
				state.isLoading = true;
				state.error = '';
				state.watsappIdRetrieved = 'processing';
			})
			.addCase(getBusinessId.fulfilled, (state, action) => {
				state.wabaIds =
					action.payload.status === 'success' ? action.payload.wabaIds : [];
				state.isLoading = false;
				state.watsappIdRetrieved = 'success';
			})
			.addCase(getBusinessId.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(getInstaId.pending, (state, action) => {
				state.isLoading = true;
				state.instaPagesRetrived = 'processing';
			})
			.addCase(getInstaId.fulfilled, (state, action) => {
				state.instaPages =
					action.payload.status === 'success' ? action.payload.instaPages : [];
				state.isLoading = false;
				state.accessToken = action.payload.accessToken;
				state.instaPagesRetrived = 'success';
			})
			.addCase(getInstaId.rejected, (state, action) => {
				state.isLoading = false;
				state.accessToken = '';
			})
			.addCase(onBoardWabano.pending, (state) => {
				state.isLoading = true;
				state.error = '';
				state.updatedStatus = '';
				state.onBoardWabaStatus = 'processing';
			})
			.addCase(onBoardWabano.fulfilled, (state, action) => {
				state.updatedStatus = action.payload.status;
				state.onBoardWabaStatus = 'success';
			})
			.addCase(onBoardWabano.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
				state.onBoardWabaStatus = 'failed';
			})
			.addCase(onBoardInsta.pending, (state) => {
				state.isLoading = true;
				state.onBoardInstaStatus = 'processing';
			})
			.addCase(onBoardInsta.fulfilled, (state, action) => {
				state.isLoading = false;
				state.updatedStatus = action.payload.status;
				state.onBoardInstaStatus = 'success';
			})
			.addCase(onBoardInsta.rejected, (state) => {
				state.isLoading = false;
				state.onBoardInstaStatus = 'failed';
			})
			.addCase(linkWaba.pending, (state) => {
				state.isLoading = true;
				state.error = '';
				state.updatedStatus = '';
			})
			.addCase(linkWaba.fulfilled, (state, action) => {
				// console.log(
				// 	'updatedStatus updatedStatus',
				// 	state.updatedStatus,
				// 	action.payload.status
				// );
				state.updatedStatus = action.payload.status;
			})
			.addCase(linkWaba.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(unlinkWaba.pending, (state) => {
				state.isLoading = true;
				state.error = '';
				state.updatedStatus = '';
			})
			.addCase(unlinkWaba.fulfilled, (state, action) => {
				state.updatedStatus = action.payload.status;
			})
			.addCase(unlinkWaba.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(linkInsta.pending, (state) => {
				state.isLoading = true;
				state.error = '';
				state.updatedStatus = '';
			})
			.addCase(linkInsta.fulfilled, (state, action) => {
				state.updatedStatus = action.payload.status;
			})
			.addCase(linkInsta.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(updateBotState.pending, (state) => {
				state.isLoading = true;
				state.error = '';
				state.updatedStatus = '';
			})
			.addCase(updateBotState.fulfilled, (state, action) => {
				state.updatedStatus = action.payload.status;
			})
			.addCase(updateBotState.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(modifyPageState.pending, (state) => {
				state.isLoading = true;
				state.error = '';
				state.updatedStatus = '';
			})
			.addCase(modifyPageState.fulfilled, (state, action) => {
				state.updatedStatus = action.payload.status;
			})
			.addCase(modifyPageState.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(deactiveWaba.pending, (state) => {
				state.isLoading = true;
				state.error = '';
			})
			.addCase(deactiveWaba.fulfilled, (state, action) => {
				state.updatedStatus = action.payload.status;
			})
			.addCase(deactiveWaba.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(removeInstaPage.pending, (state) => {
				state.isLoading = true;
				state.error = '';
			})
			.addCase(removeInstaPage.fulfilled, (state, action) => {
				state.updatedStatus = action.payload.status;
			})
			.addCase(removeInstaPage.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			})
			.addCase(updateWabaNumber.pending, (state) => {
				state.isLoading = true;
				state.error = '';
				state.updatednumbers = [];
			})
			.addCase(updateWabaNumber.fulfilled, (state, action) => {
				state.isLoading = false;
				state.updatednumbers = action.payload.wabaNos;
			})
			.addCase(updateWabaNumber.rejected, (state, action) => {
				state.isLoading = false;
				if (action.payload) {
					let error = JSON.parse(action.payload);
					state.error = error.error;
				} else {
					state.error = 'Something went wrong! Try again later';
				}
			});
	},
});

export const {
	reset,
	setForcedLogout,
	resetForcedLogout,
	resetWabaStatus,
	setWabaNo,
	resetCreateInstaWatsapp,
	resetOnBoardInstaStatus,
	resetOnBoardWabaStatus,
	resetUpdateNumbers,
	setSelectedWabaId
} = BSPauthSlice.actions;

export default BSPauthSlice.reducer;
