import React, { useEffect, useState } from 'react';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { useSelector } from 'react-redux';
import {
	AINAME,
	BACKICON,
	Header,
	MINIMISEICON,
} from '../../../../styles/EOW/Header/header.styled';
import {
	setCurrentScreen,
	setGenInTouchIconSVGFilled,
	setHomeIconSVGFilled,
	setShowBotPreviewCollapsed,
} from '../../../../../Features/ExperienceOnWeb/experienceonwebSlice';

const AIHeader = () => {
	//newBot preview
	const {
		currentScreen,
		colorMode,
		colorScale,
		fontFamily,
		fontSize,
		aiName,
		additionalsettingMandatoryCollection,
	} = useSelector((state: RootState) => state.experienceonweb);
	const dispatch = useAppDispatch();

	return (
		<Header className='ff-ai-header' colorScale={colorScale} mode={colorMode}>
			<div className='header-name-bck-btn'>
				{currentScreen !== 'homescreen' ? (
					<>
						{additionalsettingMandatoryCollection[0] ? null : (
							<BACKICON
								fontFamily={fontFamily.value}
								fontSize={fontSize.value}
								colorScale={colorScale}
								mode={colorMode}
								style={{
									cursor: 'pointer',
								}}
								onClick={() => {
									dispatch(setCurrentScreen('homescreen'));
									dispatch(setHomeIconSVGFilled(true));
									dispatch(setGenInTouchIconSVGFilled(false));
								}}
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 24 24'>
								<path data-name='Path 15579' d='M0 0h24v24H0z' fill='none' />
								<path
									data-name='Path 15580'
									d='M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z'
								/>
							</BACKICON>
						)}
					</>
				) : null}
				<AINAME
					colorScale={colorScale}
					mode={colorMode}
					fontSize={fontSize.value}>
					{aiName}
				</AINAME>
			</div>
			<div className='icons'>
				<MINIMISEICON
					colorScale={colorScale}
					style={{ cursor: 'pointer' }}
					onClick={() => dispatch(setShowBotPreviewCollapsed(true))}
					mode={colorMode}
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 24 24'>
					<path data-name='Rectangle 17142' fill='none' d='M0 0h24v24H0z' />
					<path
						data-name='Path 23'
						d='M22 3.41 16.71 8.7 20 12h-8V4l3.29 3.29L20.59 2zM3.41 22l5.29-5.29L12 20v-8H4l3.29 3.29L2 20.59z'
						// fill='#fff'
					/>
				</MINIMISEICON>
				{/* // ) : (
					// <CLOSEICON
					// 	colorScale={colorScale}
					// 	mode={colorMode}
					// 	className='close'
					// 	style={{ cursor: 'pointer' }}
					// 	onClick={() => {
					// 		dispatch(setCurrentScreen('homescreen'));
					// 		dispatch(setHomeIconSVGFilled(true));
					// 		dispatch(setGenInTouchIconSVGFilled(false));
					// 		dispatch(setAdditionalsettingMandatoryCollection([false]));
					// 	}}
					// 	xmlns='http://www.w3.org/2000/svg'
					// 	viewBox='0 0 24 24'>
					// 	<path data-name='Path 24' d='M0 0h24v24H0z' fill='none' />
					// 	<path
					// 		data-name='Path 25'
					// 		d='M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'
					// 		// fill='#fff'
					// 	/>
					// </CLOSEICON>
				// ) */}
			</div>
		</Header>
	);
};

export default AIHeader;
