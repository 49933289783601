import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState } from '../../../app/store';
import { AddUserIcon, TeamSettingDottedMenu } from '../../../assets/Icons';
import Button from '../../../Components/Button';
import Menu, { Options } from '../../../Components/Menu';
import {
	BOTROLES,
	GetAllUsersMyhubResponse,
} from '../../../Features/teamsetting/modal';
import UserBadge from '../../Dashboard/Userbadge';
import './TSUsers.scss';

const TSUsers = () => {
	const navigate = useNavigate();
	const { UsersMyhubbotRolesList } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const { botList } = useSelector((state: RootState) => state.teamsetting);
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<Array<Options>>([]);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const getMenuForQuries = (
		user: GetAllUsersMyhubResponse,
		botData?: Array<BOTROLES>
	) => {
		if (botData) {
			let data = botData.map((bot, i) => {
				return {
					label: (
						<>
							<div className='botMenu'>
								<div className='botData'>
									<div>
										<img
											className='botMenuDropImg'
											src={bot.botIcon}
											alt='icon'
										/>
									</div>
									<div>{bot.botName}</div>
								</div>
							</div>
							{i !== botData.length - 1 ? <hr /> : null}
						</>
					),
					onClick: () => {
						setOpen(false);
					},
				};
			});

			return data;
		}
		return [
			{
				label: <p className='menu-label-users'>Remove from team</p>,
				onClick: () => {
					navigate('remove-from-team', {
						state: { user, allBotData: botList },
					});

					setOpen(false);
				},
			},
		];
		// if (user.botRoles[0].roleId === 1) {
		// 	return [
		// 		{
		// 			label: <p className='menu-label-users'>Revoke admin access</p>,
		// 			onClick: () => {
		// 				navigate('revoke-admin-access', {
		// 					state: { user, allBotData: botList },
		// 				});
		// 				setOpen(false);
		// 			},
		// 		},
		// 		{
		// 			label: <p className='menu-label-users'>Remove from team</p>,
		// 			onClick: () => {
		// 				navigate('remove-from-team', {
		// 					state: { user, allBotData: botList },
		// 				});

		// 				setOpen(false);
		// 			},
		// 		},
		// 	];
		// } else {
		// 	return [
		// 		{
		// 			label: <p className='menu-label-users'>Edit bot access</p>,
		// 			onClick: () => {
		// 				navigate('edit-bot-access', {
		// 					state: { user, allBotData: botList },
		// 				});
		// 				setOpen(false);
		// 			},
		// 		},
		// 		{
		// 			label: <p className='menu-label-users'>Make team admin</p>,
		// 			onClick: () => {
		// 				navigate('make-team-admin', {
		// 					state: { user, allBotData: botList },
		// 				});
		// 				setOpen(false);
		// 			},
		// 		},
		// 		{
		// 			label: <p className='menu-label-users'>Remove from team</p>,
		// 			onClick: () => {
		// 				navigate('remove-from-team', {
		// 					state: { user, allBotData: botList },
		// 				});
		// 				setOpen(false);
		// 			},
		// 		},
		// 	];
		// }
	};

	return (
		<div className='tsusers-container'>
			<div className='tsusers-header'>
				<p>Users ({UsersMyhubbotRolesList.allUsers.length})</p>{' '}
				<Button
					onClick={() => navigate('addnewuser')}
					variant='contained'
					startIcon={<AddUserIcon />}
					fullWidth>
					Add User
				</Button>
			</div>
			<div className='tsusers-table-container'>
				<div className='theadUsers tableCell'>
					<div className='trUsers'>
						<div className='th-data'>User</div>
						<div className='th-data'>Role</div>
						<div className='th-data'></div>
					</div>
				</div>
				<div
					className='tbodyUsers'
					onScroll={() => {
						setOpen(false);
					}}>
					{UsersMyhubbotRolesList.allUsers
						.slice(0)
						.reverse()
						.map((user: any, i) => (
							<>
								<div className='trUsers tableCell'>
									<div className='td-data'>
										<div className='userbadgeEmail'>
											<span>
												<UserBadge email={user.userEmail} />
											</span>
											<span>{user.userEmail}</span>
										</div>
									</div>
									<div className='td-data'>Team admin</div>
									<div className='td-data'>
										<TeamSettingDottedMenu
											onClick={(event) => {
												event.stopPropagation();
												setAnchorEl(event.target as HTMLElement);
												setOpen(true);
												setOptions(getMenuForQuries(user));
											}}
										/>
									</div>
								</div>
							</>
						))}
				</div>
			</div>
			<Menu
				anchorEl={anchorEl}
				open={open}
				setOpen={setOpen}
				options={options}
			/>
			<Outlet />
		</div>
	);
};

export default TSUsers;
