import { Avatar as AV } from '@mui/material';
import './Avatar.scss';

type AvatarProps = {
	text: string;
	size?: 'small' | 'large';
};

const Avatar = ({ text, size = 'large' }: AvatarProps) => {
	const getBgColor = () => {
		return (
			(text
				.split('')
				.reduce(
					(sum, t) => sum + t.toUpperCase().charCodeAt(0),
					text.charCodeAt(0)
				) +
				text.length) %
			12
		);
	};

	return (
		<AV
			className={`ff-avatar ${size}`}
			sx={{
				bgcolor: `var(--tertiary-${getBgColor() + 1})`,
			}}>
			<p className='body1'>{text.toUpperCase().charAt(0)}</p>
		</AV>
	);
};
export default Avatar;
