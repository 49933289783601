import { getBgAndIconColor } from '../../utils/utils';
import './TeamIcon.scss';

type TeamIconProps = {
	image: string;
	teamName: string;
	index: number;
	dropdown?: boolean | false;
};
export const TeamIcon = ({
	image,
	teamName,
	index,
	dropdown,
}: TeamIconProps) => {
	return (
		<div className='team-icon-parent'>
			<div
				className='team-icon'
				key={teamName}
				style={{
					backgroundColor: !image?getBgAndIconColor('icon', index):'transparent',
					minWidth: dropdown ? '32px' : '46px',
					minHeight: dropdown ? '32px' : '46px',
					maxWidth: dropdown ? '32px' : '46px',
					maxHeight: dropdown ? '32px' : '46px',
				}}>
				{image ? (
					<img
						src={image}
						alt='team'
						style={{
							height: dropdown ? '32px' : '32px',
							width: dropdown ? '32px' : '32px',
						}}
					/>
				) : (
					<h6 style={{ fontSize: dropdown ? '18px' : '20px' }}>
						{teamName && teamName.toUpperCase().charAt(0)}
					</h6>
				)}
			</div>
		</div>
	);
};
