import { Tooltip } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import Avatar from '../../../Components/Avatar';
import Button from '../../../Components/Button';
import './AddUser.scss';
import { useEffect } from 'react';
import { RootState, useAppDispatch } from '../../../app/store';
import { useSelector } from 'react-redux';
import { loadState } from '../../../utils/utils';

import {
	getallUsers,
	removeUserFromTeam,
	reset,
} from '../../../Features/users/userSlice';
import PageLoader from '../../../Components/PageLoader';
const AddUsers = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(reset());
		dispatch(
			getallUsers({
				botId: loadState('botId'),
				tenantId: loadState('tenantId'),
			})
		);
	}, []);

	const { usersList, removeUserId, getAllUsersLoading } = useSelector(
		(state: RootState) => state.user
	);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const showRemoveOption = () => {
		let check = usersList.filter(
			(user) => user.email === loadState('userEmailId')
		)[0];
		if (check && check.role.toLowerCase() === 'admin') {
			return true;
		} else if (check && check.role.toLowerCase() === 'user') {
			return false;
		}
	};

	return (
		<>
			<div className='adduser-container'>
				<div className='adduser-heading'>
					<span>
						<h4>Users</h4>
					</span>
					<span>
						<Button
							onClick={() => {
								navigate('add-user-popup');
							}}>
							Add a user
						</Button>
					</span>
				</div>
				<div
					className={`adduser-holder ${
						getAllUsersLoading ? '' : 'usersPopulated'
					}`}>
					<>
						{getAllUsersLoading ? (
							<div className='query-loader'>
								<PageLoader message='Loading' />
							</div>
						) : (
							usersList.map((user: any, i) => {
								return (
									<div
										className={`user-list-holder ${
											usersList.length - 1 === i ? 'no-bottom-margin' : ''
										}`}>
										<div className='avatar-user-holder'>
											<Tooltip title={user.email}>
												<span>
													<Avatar size='large' text={user.email} />
												</span>
											</Tooltip>
											<div className='body1 user-list'>{user.email}</div>
										</div>
										{/* {showRemoveOption() ? (
										<Button
											buttonType='light'
											onClick={() => {
												dispatch(
													removeUserFromTeam({
														tenantId: tenantId!,
														userId: user.id,
													})
												);
											}}>
											{user.id === removeUserId ? 'Removing' : 'Remove'}
										</Button>
									) : null} */}
									</div>
								);
							})
						)}
					</>
				</div>
			</div>
			<Outlet />
		</>
	);
};

export default AddUsers;
