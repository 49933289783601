import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown, { DropdownValue } from '../../../../Components/Dropdown';
import GetAppIcon from '@mui/icons-material/GetApp';
import CalendarInput from '../../calendar/calendarInput';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './AgentRankingTable.scss';
import { downloadReport, getAgentRankingLiveChat } from '../../../../Features/Analytics/analyticsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';

const AgentRankingTable = () => {
	const navigate = useNavigate();
	const options = [
		{
			label: (
				<div className='action-label'>
					<p>Answered Handsoff</p>
				</div>
			),
			value: 'Answered Handsoff',
		},
		{
			label: (
				<div className='action-label'>
					<p>Unanswered Handoff</p>
				</div>
			),
			value: 'Unanswered Handoff',
		},
		{
			label: (
				<div className='action-label'>
					<p>User Feedback</p>
				</div>
			),
			value: 'User Feedback',
		},
		{
			label: (
				<div className='action-label'>
					<p>Average User Wait Time in Queue</p>
				</div>
			),
			value: 'Average User Wait Time in Queue',
		},
	];
	const [selectedfield, setSelectedField] = useState<DropdownValue | any>(
		options[0]
	);
	const [fieldValue,setFieldValue]=useState('Answered Handoffs');
	const onchange = (feild: any) => {
		setSelectedField(feild);
		getAgentRanking();
		setFieldValue(feild.value);	
		
	};
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const dispatch = useDispatch<any>();
	const selectedfactor = selectedfield.value;
	const {
		getAgentRankingData,
		selectedDates,
		calendarstatus,
		getAllAgentsData,
	} = useSelector((state: RootState) => state.analytics);

	const getAgentRanking = () => {
		//getAllAgentData();
		tenantId &&
			dispatch(
				getAgentRankingLiveChat({
					start: selectedDates[0],
					end: selectedDates[1],
					agentId:-1,
					tenantId: tenantId,
					factorName: selectedfactor,
				})
			);
			
			
	};
	return (
		<div>
			<div className='agent-ranking-header'>
				<div
					className='bac-to-prev-screen'
					onClick={() => navigate('/app/analytics/live-chat')}>
					<ArrowBackIcon color='primary' fontSize='small' />
					Back
				</div>
				<div className='agent-ranking-sub-header'>
					<div style={{ marginLeft: '19px' }}>Agent Rankings</div>
				</div>
			</div>

			<div
				style={{
					overflowY: 'auto',
					height: 'calc(100vh - 154px)',
					marginLeft: '30px',
				}}>
				<div className='filter-download-area'>
					<div
						style={{
							display: 'flex',
							margin: '22px 0',
							justifyContent: 'space-between',
							width: '566px',
							marginRight: '24px',
						}}>
						<>
							<div
								style={{
									fontSize: '14px',
									display: 'flex',
									alignItems: 'center',
									fontWeight: 'normal',
									letterSpacing: '-0.21px',
									whiteSpace: 'nowrap',
									marginRight: '8px',
								}}>
								Rank By:
							</div>

							<div className='sf-dropdown-wrapper'>
								<Dropdown
									onChange={(feild) => onchange(feild)}
									options={options}
									value={selectedfield}
								/>
							</div>
						</>

						<div style={{ marginLeft: '14px' }}>
							<CalendarInput />
						</div>
					</div>

					<div className='download-icon' title='Download data' onClick={downloadReport}>
						<GetAppIcon />
					</div>
				</div>

				<div className='rank-card-view-list'>
					<div className='rank-card-header'>
						<div
							style={{
								width: '25%',
								display: 'flex',
								justifyContent: 'flex-start',
							}}>
							Rank
						</div>
						<div
							style={{
								width: '50%',
								display: 'flex',
								justifyContent: 'flex-start',
							}}>
							Agents
						</div>
						<div
							style={{
								width: '50%',
								display: 'flex',
								justifyContent: 'flex-start',
							}}>
								{fieldValue}
						</div>
					</div>
					<div className='rank-card-content'>
						<div className='row' style={{ display: 'flex' }}>
							<div
								style={{
									width: '25%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								-
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								Chitra
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								<span style={{ marginLeft: '5px' }}> 2</span>
							</div>
						</div>
						<div className='row' style={{ display: 'flex' }}>
							<div
								style={{
									width: '25%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								-
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								Krystle
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								<span style={{ marginLeft: '5px' }}> 9</span>
							</div>
						</div>

						<div className='row' style={{ display: 'flex' }}>
							<div
								style={{
									width: '25%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								-
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								Schiller
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								<span style={{ marginLeft: '5px' }}> 6</span>
							</div>
						</div>
						<div className='row' style={{ display: 'flex' }}>
							<div
								style={{
									width: '25%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								-
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								Chitra
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								<span style={{ marginLeft: '5px' }}> 2</span>
							</div>
						</div>
						<div className='row' style={{ display: 'flex' }}>
							<div
								style={{
									width: '25%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								-
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								Krystle
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								<span style={{ marginLeft: '5px' }}> 9</span>
							</div>
						</div>

						<div className='row' style={{ display: 'flex' }}>
							<div
								style={{
									width: '25%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								-
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								Schiller
							</div>
							<div
								style={{
									width: '50%',
									display: 'flex',
									justifyContent: 'flex-start',
								}}>
								<span style={{ marginLeft: '5px' }}> 6</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AgentRankingTable;
