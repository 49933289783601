import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../app/store';
import Dropdown, { DropdownValue } from '../../../Components/Dropdown';
import Input from '../../../Components/Input';
import { conversationTableFilterSelected } from '../../../Features/Analytics/analyticsSlice';
import { FilterIcon } from '../../../assets/Icons';
import './conversationTableFilter.scss';

const options = [
	{
		label: (
			<div className='action-label'>
				<div className=' icon '>
					<FilterIcon />
				</div>
				<p>Session ID</p>
			</div>
		),
		value: 'sessionID',
	},
	{
		label: (
			<div className='action-label'>
				<div className=' icon '>
					<FilterIcon />
				</div>
				<p>IP Address</p>
			</div>
		),
		value: 'IPaddress',
	},
	{
		label: (
			<div className='action-label'>
				<div className=' icon '>
					<FilterIcon />
				</div>
				<p>Country</p>
			</div>
		),
		value: 'country ',
	},
	{
		label: (
			<div className='action-label'>
				<div className=' icon '>
					<FilterIcon />
				</div>
				<p>Device</p>
			</div>
		),
		value: 'source',
	},
	{
		label: (
			<div className='action-label'>
				<div className=' icon '>
					<FilterIcon />
				</div>
				<p>Referrer</p>
			</div>
		),
		value: 'referer',
	},
];

function ConversationTableFilter() {
	const [searchFieldItem, setSearchFieldItem] = useState('');
	const [selectedfield, setSelectedField] = useState<DropdownValue | any>({
		label: (
			<div className='action-label'>
				<div className=' icon '>
					<FilterIcon />
				</div>
				<p>Filter</p>
			</div>
		),
		value: 'Filter',
	});
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(
			conversationTableFilterSelected({
				filter: selectedfield.value,
				search: searchFieldItem,
			})
		);
	}, [selectedfield.value, searchFieldItem]);

	return (
		<div className='ff_conversation_filter'>
			<Dropdown
				value={selectedfield}
				onChange={setSelectedField}
				options={options}
				className={
					selectedfield.value !== 'Filter'
						? 'ff_border_radius no-of-convo'
						: 'no-of-convo'
				}
			/>
			{selectedfield.value !== 'Filter' && (
				<Input
					value={searchFieldItem}
					onChange={(event) => setSearchFieldItem(event.target.value)}
					className='ff_input_field'
				/>
			)}
			{/* <div className='ff_filter_input_Container'>
				<div className='ff_filter_title'>
					<CalendarIcon />
					<div className='ff_title'>{selectedfield.value}</div>
				</div>
				<Input
					value={filterValue}
					onValueChange={setfilterValue}
					// label={selectedfield.value}
					variant='large'
				/>
			</div> */}
		</div>
	);
}

export default ConversationTableFilter;
