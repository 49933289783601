import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { NEWTABS } from './constants';
import { RootState, useAppDispatch } from '../../app/store';
import { loadState, saveState } from '../../utils/utils';
import TabGenerator from '../../Components/TabGenerator/TabGenerator';
import Button from '../../Components/Button';
import './GeneralTeamSetting.scss';
import { setTenantImageURL } from '../../Features/teams/TeamsSlice';
import {
	updateTenantProfile,
	setUpdateTenantProfileError,
	setGeneralSelectedTab,
} from '../../Features/teamsetting/teamsettingSlice';
import { getUnreadNotifications } from '../../Features/Notifications/notificationSlice';
import { setSnackModal } from '../../Features/message/messageSlice';
const GeneralTeamSetting = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const {
		updateTenantProfileFail,
		updateTenantProfileError,
		tenantFile,
		customTenantName,
		customOrganizationName,
		generalselectedTab,
	} = useSelector((state: RootState) => state.teamsetting);
	const { invalidOrgName, invalidTeamName } = useSelector(
		(state: RootState) => state.configure
	);
	const onTabChangeHandler = (tabName: string) => {
		NEWTABS.map((eachTab, index) => {
			if (eachTab.Tab.title === tabName) {
				if (eachTab.Tab.title === 'Integrations') {
					dispatch(setGeneralSelectedTab('All'));
					saveState('selectedGeneralTab', 'All');
					navigate('Integrations/all');
				} else {
					dispatch(setGeneralSelectedTab(eachTab.Tab.title));
					saveState('selectedGeneralTab', eachTab.Tab.title);
					navigate(eachTab.Tab.path);
				}
			}

			eachTab.SubTabs &&
				eachTab.SubTabs.map((data: any, i: number) => {
					if (data.title === tabName) {
						dispatch(setGeneralSelectedTab(data.title));
						saveState('selectedGeneralTab', data.title);
						navigate(data.path);
					}
				});
		});
	};

	const namingClass = () => {
		if (location.pathname.includes('configuration/users/')) {
			return 'usersPath';
		} else if (
			location.pathname.includes('general-configuration/Integrations')
		) {
			return 'integrations';
		} else if (location.pathname.includes('general-configuration/live-chat/')) {
			return 'live-chat';
		} else {
			let a = location.pathname.split('/');
			let nameOfClass = a[a.length - 1];
			return nameOfClass;
		}
	};
	useEffect(() => {
		if (updateTenantProfileError == 'failed') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: updateTenantProfileFail,
					modalMode: 'negative',
				})
			);
			dispatch(setUpdateTenantProfileError(''));
		}
	}, [updateTenantProfileFail, updateTenantProfileError]);

	useEffect(() => {
		NEWTABS.map((eachTab, index) => {
			if (eachTab.Tab.title === loadState('selectedGeneralTab')) {
				dispatch(setGeneralSelectedTab(eachTab.Tab.title));
				saveState('selectedGeneralTab', eachTab.Tab.title);
				navigate(eachTab.Tab.path);
			}
			eachTab.SubTabs &&
				eachTab.SubTabs.map((data: any, i: number) => {
					if (eachTab.SubTabs[i].title === loadState('selectedGeneralTab')) {
						dispatch(setGeneralSelectedTab(data.title));
						saveState('selectedGeneralTab', data.title);
						navigate(data.path);
					}
				});
		});
		dispatch(
			getUnreadNotifications({
				userId: loadState('user').userID,
				tenantId: tenantId!,
			})
		);
	}, []);
	return (
		<>
			<div className='configuration-container general-team'>
				<div
					style={{
						display:
							location.pathname.indexOf('/general-configuration/team-details') >
							-1
								? 'flex'
								: 'none',
						pointerEvents:
							!invalidOrgName && !invalidTeamName ? 'initial' : 'none',
						opacity: !invalidOrgName && !invalidTeamName ? '1' : '0.5',
					}}
					className='ff-configure-save-button'>
					<Button
						onClick={() => {
							//tenantId &&
							dispatch(
								updateTenantProfile({
									id: loadState('tenantId'),
									name: customTenantName || loadState('teamDet').name,
									backgroundImage:
										tenantFile || loadState('teamDet').backgroundImage,
									organizationName:
										customOrganizationName ||
										loadState('teamDet').organizationName,
									createdBy: loadState('teamDet').createdBy,
									subcriptionId: loadState('teamDet').subcriptionId,
									disabled: false,
								})
							);
							dispatch(setTenantImageURL(tenantFile));
							saveState('tenantImageURL', tenantFile);
						}}>
						Save Changes
					</Button>
				</div>
				<div className='tabs-and-setting-holder'>
					<div className='config-bot-profile-tabs'>
						<h5>Settings</h5>
						<TabGenerator
							type={'vertical'}
							selectedTab={generalselectedTab}
							onTabChange={(tabName: string) => onTabChangeHandler(tabName)}
							component={'configprofile'}
							tabNames={NEWTABS}
							tabPassedStyles={null}
						/>
					</div>
					<div className='ff-general-teamsetting'>
						<div
							className={`settings-container ${
								namingClass() === 'integrations'
									? 'settings-container-integrations'
									: ''
							}
								`}>
							<div className={`right-result`}>
								<Outlet />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default GeneralTeamSetting;
