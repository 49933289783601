import styled from 'styled-components';

export type CarouselButtonProps = {
	rotate?: boolean;
	buttonBckColor: string;
	buttonTextColor: string;
};

export const CarouselButton = styled.svg<CarouselButtonProps>`
	cursor: pointer;
	transform: ${(props) => (props.rotate ? 'rotate(180deg)' : '')};
	position: absolute;
	z-index: 1;
	top: ${(props) => (props.rotate ? '52.7%' : '54%')};
	right: ${(props) => (props.rotate ? '' : '-86px ')};
	left: ${(props) => (props.rotate ? '-19px' : '')};
	.circle-bck-color {
		fill: ${(props) => props.buttonBckColor};
		outline: 0.5px solid ${(props) => props.buttonTextColor};
		border-radius: 50%;
		opacity: 0.8;
		box-shadow: 0 4px 8px 0 rgb(0 0 0 / 28%);
		&:hover {
			opacity: 1;
			outline: none;
		}
	}
	.circle-icon-color {
		fill: ${(props) => props.buttonTextColor};
	}
`;
