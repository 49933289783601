import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './ChatWithHuman.scss';
import {
	getAIDetails,
	setShowBotPreviewCollapsed,
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import { loadState } from '../../../../utils/utils';
import { RootState, useAppDispatch } from '../../../../app/store';
import { useSelector } from 'react-redux';

const ChatWithHuman = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const path = useLocation().pathname.split('/');
	const [currentTab, setCurrentTab] = useState(path[path.length - 1]);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const tabName = [
		'Expert availability',
		'Handoff triggers',
		'Button & Messages',
		'LiveChat Leads',
	];
	const navigator = (tab: string) => {
		if (tab === tabName[0]) {
			setCurrentTab('expert-availability');
			return navigate('expert-availability');
		} else if (tab === tabName[1]) {
			setCurrentTab('handoff-triggers');
			return navigate('handoff-triggers');
		} else if (tab === tabName[2]) {
			setCurrentTab('buttons-and-messages');
			return navigate('buttons-and-messages');
		} else if (tab === tabName[3]) {
			setCurrentTab('livechat-leads');
			return navigate('livechat-leads');
		}
	};

	const headerName = () => {
		return currentTab === 'expert-availability'
			? 'Expert Availability'
			: currentTab === 'handoff-triggers'
			? 'Handoff Triggers'
			: currentTab === 'buttons-and-messages'
			? 'Buttons & Messages'
			: currentTab === 'buttons-and-messages'
			? 'livechat-leads'
			: null;
	};

	const RightArrow = () => {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'>
				<g data-name='chevron_right_black_24dp (9)'>
					<path
						data-name='Path 8108'
						d='M24 24H0V0h24z'
						fill='none'
						transform='rotate(180 12 12)'
					/>
					<path
						data-name='Path 8109'
						d='m6 12 1.41-1.41L2.83 6l4.58-4.59L6 0 0 6z'
						transform='rotate(180 8 9)'
						fill='#7b5ce5'
					/>
				</g>
			</svg>
		);
	};

	const BackArrow = () => {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'>
				<path
					xmlns='http://www.w3.org/2000/svg'
					data-name='Path 15566'
					d='M0 0h24v24H0z'
					fill='none'
				/>
				<path
					xmlns='http://www.w3.org/2000/svg'
					data-name='Path 15567'
					d='M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z'
					fill='#7b5ce5'
				/>
			</svg>
		);
	};

	useEffect(() => {
		dispatch(setShowBotPreviewCollapsed(true));
		// dispatch(getAIDetails({ botId: loadState('botId'), tenantId: tenantId }));
	}, []);

	return (
		<div className='ff-configure-chat-with-human'>
			<div className='chat-with-human'>
				{path[path.length - 1] !== 'chat-with-human' ? (
					<>
						<div className='heading'>
							<div
								className='arrow'
								onClick={() => {
									dispatch(
										getAIDetails({
											botId: loadState('botId'),
											tenantId: tenantId,
										})
									);
									navigate(-1);
									dispatch(setShowBotPreviewCollapsed(true));
								}}>
								<BackArrow />
							</div>
							<h5
								className='chat-human'
								onClick={() => {
									dispatch(
										getAIDetails({
											botId: loadState('botId'),
											tenantId: tenantId,
										})
									);
									navigate(-1);
									dispatch(setShowBotPreviewCollapsed(true));
								}}
								style={{ cursor: 'pointer' }}>
								Chat with a Human
							</h5>{' '}
							<RightArrow /> <h5 className='header-name'>{headerName()}</h5>
						</div>
					</>
				) : (
					<h4>Chat with a Human</h4>
				)}
				{path[path.length - 1] !== 'chat-with-human' ? null : (
					<p className='body1'>
						Let a human expert step in and take over the chat from your AI at
						relevant times.
					</p>
				)}
				{path[path.length - 1] !== 'chat-with-human'
					? null
					: tabName.map((tab) => (
							<div
								key={tab}
								className='chat-tab body1'
								onClick={() => navigator(tab)}>
								{tab}
								{RightArrow()}
							</div>
					  ))}
				<Outlet />
			</div>
		</div>
	);
};

export default ChatWithHuman;
