import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import enLocale from 'date-fns/locale/en-US';
import { useState } from 'react';
import { TextField } from '@mui/material';
import './index.scss';

type Props = {
	error?: boolean;
	helperText?: string | undefined;
	label: string;
	value: Date | null;
	onChange: (arg0: Date | null | any) => void;
	onBlur?: (arg0: any) => void;
	closeProp?: boolean;
	disablePast?: boolean;
	defaultValue?: any;
};

const TimeInput = ({
	label,
	value,
	onChange,
	onBlur,
	error = false,
	helperText = undefined,
	closeProp,
	...rest
}: Props) => {
	const [open, setOpen] = useState(false);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
			<TimePicker
				className='time-picker'
				ampm={true}
				openTo='hours'
				label={label}
				value={value}
				InputAdornmentProps={{
					onClick: () => setOpen(!open),
				}}
				open={open}
				onChange={(newValue) => onChange(newValue)}
				onClose={() => {
					setOpen(false);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						helperText={helperText}
						error={error}
						onBlur={(event) => {
							onBlur && onBlur(value);
							closeProp && setOpen(false);
						}}
					/>
				)}
				{...rest}
			/>
		</LocalizationProvider>
	);
};

export default TimeInput;
