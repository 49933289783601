type Props = {
	color?: string;
};

export const RightArrowIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='56'
			height='56'
			viewBox='0 0 48 48'>
			<defs>
				<filter
					id='ka9a404vsa'
					x='14.18'
					y='9'
					width='20.82'
					height='30'
					filterUnits='userSpaceOnUse'>
					<feOffset />
					<feGaussianBlur stdDeviation='1' result='blur' />
					<feFlood flood-opacity='.278' />
					<feComposite operator='in' in2='blur' />
					<feComposite in='SourceGraphic' />
				</filter>
			</defs>
			<path data-name='Path 14754' d='M0 0h48v48H0z' fill='none' />
			<g filter='url(#ka9a404vsa)'>
				<path
					data-name='Path 14755'
					d='M11.41 6 8.59 8.82 17.75 18l-9.16 9.18L11.41 30l12-12z'
					transform='translate(8.59 6)'
					fill={props.color ? props.color : '#ffffff'}
				/>
			</g>
		</svg>
	);
};

export const LeftArrowIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='56'
			height='56'
			viewBox='0 0 48 48'>
			<defs>
				<filter
					id='b4p5qdde7a'
					x='13'
					y='9'
					width='20.82'
					height='30'
					filterUnits='userSpaceOnUse'>
					<feOffset />
					<feGaussianBlur stdDeviation='1' result='blur' />
					<feFlood flood-opacity='.278' />
					<feComposite operator='in' in2='blur' />
					<feComposite in='SourceGraphic' />
				</filter>
			</defs>
			<path data-name='Path 14754' d='M48 0H0v48h48z' fill='none' />
			<g filter='url(#b4p5qdde7a)'>
				<path
					data-name='Path 14755'
					d='m20.59 6 2.82 2.82L14.25 18l9.16 9.18L20.59 30l-12-12z'
					transform='translate(7.41 6)'
					fill={props.color ? props.color : '#fff'}
				/>
			</g>
		</svg>
	);
};

export const CloseIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='48'
			height='48'
			viewBox='0 0 48 48'>
			<defs>
				<filter
					id='d1offtkp5a'
					x='7'
					y='7'
					width='34'
					height='34'
					filterUnits='userSpaceOnUse'>
					<feOffset />
					<feGaussianBlur stdDeviation='1' result='blur' />
					<feFlood flood-opacity='.278' />
					<feComposite operator='in' in2='blur' />
					<feComposite in='SourceGraphic' />
				</filter>
			</defs>
			<g filter='url(#d1offtkp5a)' data-name='Group 30367'>
				<path
					data-name='Path 14753'
					d='M33 7.82 30.18 5 19 16.18 7.82 5 5 7.82 16.18 19 5 30.18 7.82 33 19 21.82 30.18 33 33 30.18 21.82 19z'
					transform='translate(5 5)'
					fill='#ffffff'
				/>
			</g>
		</svg>
	);
};

export const MoreIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='48'
			height='48'
			viewBox='0 0 48 48'>
			<path
				data-name='Path 13834'
				d='M8 10a4 4 0 1 0 4 4 4.012 4.012 0 0 0-4-4zm24 0a4 4 0 1 0 4 4 4.012 4.012 0 0 0-4-4zm-12 0a4 4 0 1 0 4 4 4.012 4.012 0 0 0-4-4z'
				transform='translate(4 10)'
				fill='rgb(255,255,255)'
			/>
		</svg>
	);
};
