import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Checkbox from '../../../Components/Checkbox';
import HorizontalSeparator from '../../../Components/HorizontalSeparator';
import Radio from '../../../Components/Radio';
import {
	createBotConfigurations,
	resetCreateBotConfigurationSecurityLoader,
	setIsBotPublic,
	setMastPIIData,
} from '../../../Features/configure/configureSlice';
import { reset, set } from '../../../Features/message/messageSlice';
import { loadState } from '../../../utils/utils';
import './SecurityAndPrivacy.scss';

const SecurityAndPrivacy = () => {
	const botDetails: any = loadState('botDetails');
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { maskPIIData, isBotPublic, createBotConfigurationSecurityLoader } =
		useSelector((state: RootState) => state.configure);
	const [buttonLoader, setButtonLoader] = useState(false);

	const dispatch = useAppDispatch();
	const location = useLocation();
	const path = location.pathname;

	const [isBotVisible, setIsBotVisible] = useState(
		isBotPublic ? 'public' : 'private'
	);

	useEffect(() => {
		dispatch(setIsBotPublic(isBotVisible === 'private' ? false : true));
	}, [isBotVisible]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (createBotConfigurationSecurityLoader === 'success') {
			dispatch(set('Configurations Saved Successfully'));
			setButtonLoader(false);
		} else if (createBotConfigurationSecurityLoader === 'failed') {
			dispatch(set(`Couldn't Save !! Something Went Wrong`));
			setButtonLoader(false);
		} else if (createBotConfigurationSecurityLoader === 'loading') {
			setButtonLoader(true);
		}
		dispatch(resetCreateBotConfigurationSecurityLoader());
	}, [createBotConfigurationSecurityLoader]);

	return (
		<div className='ff-security-and-privacy'>
			<div className='mask-data-access-bot'>
				<div className='mask-pii-data'>
					<div>
						<h6 className='header-font'>Mask PII Data</h6>
						<p className='description-font body2'>
							Personal Identifiable Information (PII) of chatbot users such as
							email and phone is masked while storing it for analytics.
						</p>
					</div>
					<div>
						<Checkbox
							isChecked={maskPIIData}
							index={0}
							onValueChange={(value) => dispatch(setMastPIIData(value))}
						/>
					</div>
				</div>
				<HorizontalSeparator className='security-separator' />
				<div className='access-to-bot'>
					<h6 className='header-font'>
						Access to{' '}
						{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}
					</h6>
					<p className='description-font body2'>
						Restrict access to{' '}
						{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'} to only
						within Flyfish by marking it private
					</p>
					<div>
						<div className='radio-flex'>
							<Radio
								value='private'
								isChecked={isBotVisible}
								onValueChange={setIsBotVisible}
								name='bot-access'
							/>
							<div className='radio-description'>
								<p className='radio-font body1'>
									Make{' '}
									{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}{' '}
									private
								</p>
								<p className='description-font body2'>
									The{' '}
									{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}{' '}
									can only be tested within the platform
								</p>
							</div>
						</div>
						<div className='radio-flex'>
							<Radio
								value='public'
								isChecked={isBotVisible}
								onValueChange={setIsBotVisible}
								name='bot-access'
							/>
							<div className='radio-description'>
								<p className='radio-font body1'>
									Make{' '}
									{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}{' '}
									public
								</p>
								<p className='description-font body2'>
									This makes the{' '}
									{path.indexOf('intelligent-ad') > -1 ? 'Ad' : 'Storefront'}{' '}
									accessible on all channels that it is deployed to, and to
									everyone who has the shareable link
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='security-save-button-div'>
				<Button
					className='security-save-button'
					loading={buttonLoader}
					onClick={() => {
						let data = {
							botId: botDetails.botId,
							isBotPublic: isBotVisible === 'private' ? false : true,
							maskPIIData: maskPIIData[0],
							tenantId: tenantId,
						};
						dispatch(createBotConfigurations(data));
					}}>
					Save
				</Button>
			</div>
		</div>
	);
};

export default SecurityAndPrivacy;
