import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { ConfigurePlus, UploadImageEditEOW } from '../../../../../assets/Icons';
import Button from '../../../../../Components/Button';
import Input from '../../../../../Components/Input';
import {
	setCarouselFields,
	setEOWWelcomeBannerLoader,
	setInputFields,
	setPersistentCustomInputFields,
	setWelcomeCarouselFile,
	setWelcomeCarouselLoader,
} from '../../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import './CardCarousels.scss';
import { FFLoader } from '../../../../../assets/Icons/Loader';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PlaceholderImage from '../../../../../assets/images/placeholder-image.png';
type Props = {
	persistent?: boolean;
};

const CardCarousels = ({ persistent = false }: Props) => {
	const { carouselFieldsData, welcomeCarouselLoader, welcomeCarouselFile } =
		useSelector((state: RootState) => state.experienceonweb);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [carouselInputData, setCarouselInputData] = useState<string[]>([]);

	const [loader, setLoader] = useState(false);
	const [indexAt, setIndexAt] = useState(-1);

	const handleAddCarouselText = () => {
		navigate('add-image', {
			state: { value: 'WelcomeCarousel' },
		});
	};
	useEffect(() => {
		setCarouselInputData(carouselFieldsData);
	}, [carouselFieldsData]);
	useEffect(() => {
		if (welcomeCarouselFile.length) {
			if (indexAt > -1 && carouselFieldsData[indexAt].length) {
				let data = carouselFieldsData.slice();
				data[indexAt] = welcomeCarouselFile;

				dispatch(setCarouselFields(data));
				dispatch(setWelcomeCarouselFile(''));
				setIndexAt(-1);
			} else {
				let data = [...carouselFieldsData, welcomeCarouselFile, ''];
				dispatch(setCarouselFields(data));
				dispatch(setWelcomeCarouselFile(''));
			}
		}
	}, [welcomeCarouselFile]);

	useEffect(() => {
		carouselFieldsData.length === 0 && dispatch(setCarouselFields([]));
	}, []);

	const removeCarouselText = (index: number) => {
		let data = carouselInputData.filter((res, i) => index !== i);
		let data1 = data.filter((res, i) => index - 1 !== i);

		dispatch(setCarouselFields(data1));
	};

	const handleCarouselResChange = (index: number, txt: string) => {
		let new_response = carouselInputData.map((value, i) =>
			index !== i ? value : txt
		);
		dispatch(setCarouselFields(new_response));
	};
	useEffect(() => {
		if (welcomeCarouselLoader === 'success') {
			setLoader(false);
			// dispatch(
			// 	setSnack({
			// 		message: 'Image uploaded successfully',
			// 		testcase: 'positive',
			// 	})
			// );
		} else if (welcomeCarouselLoader === 'failed') {
			setLoader(false);
			// dispatch(
			// 	setSnack({
			// 		message: 'Something Went Wrong!! Please try after sometime',
			// 		testcase: 'negative',
			// 	})
			// );
		} else if (welcomeCarouselLoader === 'loading') {
			setLoader(true);
		}
		dispatch(setWelcomeCarouselLoader(''));
	}, [welcomeCarouselLoader]);
	return (
		<div className='ff-eow-carousels'>
			{carouselInputData.length < 2 ? null : (
				<>
					<div className='quicklinks-container'>
						<span className='quicklinks-header'>
							<span className='body1'>Image</span>
						</span>
						<span className='quicklinks-header'>
							<span className='body1'>Text submitted in chat on click</span>
						</span>
						<span className='quicklinks-header'>
							<span></span>
						</span>
						{carouselInputData.map((data, index) => {
							return (
								<>
									<span className='quicklinks-input-container'>
										{index % 2 === 0 ? (
											<div className='image-input'>
												<>
													<img
														src={data.length ? data : PlaceholderImage}
														alt=''
													/>
													<span
														onClick={() => {
															navigate('add-image', {
																state: { value: 'WelcomeCarousel' },
															});
															setIndexAt(index);
														}}>
														<UploadImageEditEOW />
													</span>
												</>
											</div>
										) : (
											<Input
												type='text'
												onChange={(event) =>
													handleCarouselResChange(index, event.target.value)
												}
												value={data}
												placeholder={'Enter text'}
												required
											/>
										)}
									</span>
									{index % 2 !== 0 ? (
										<div
											className='svg-container'
											onClick={() => removeCarouselText(index)}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'>
												<defs>
													<clipPath id='z23wj0weea'>
														<path
															data-name='Rectangle 15483'
															fill='rgba(0,0,0,.6)'
															d='M0 0h13.996v16H0z'
														/>
													</clipPath>
												</defs>
												<g data-name='Group 32536'>
													<g data-name='Group 31699'>
														<path
															data-name='Path 14985'
															d='M4.325 0v1.709H0v1.928h14V1.709H9.668V0z'
															fill='rgba(0,0,0,.6)'
															transform='translate(5 4)'
														/>
														<g data-name='Group 31700'>
															<g
																data-name='Group 31699'
																fill='clip-path:url(#z23wj0weea)'
																transform='translate(5 4)'>
																<path
																	data-name='Path 14986'
																	d='m20.842 79.607.011.112a2.306 2.306 0 0 0 2.294 2.081H28.4a2.306 2.306 0 0 0 2.294-2.071l.961-9.406H19.894zm8.676-7.361-.744 7.283a.379.379 0 0 1-.376.34h-5.25a.379.379 0 0 1-.375-.327l-.746-7.3z'
																	transform='translate(-18.614 -65.796)'
																	fill='rgba(0,0,0,.6)'
																/>
															</g>
														</g>
													</g>
												</g>
											</svg>
										</div>
									) : null}
								</>
							);
						})}
					</div>
				</>
			)}
			<Button
				startIcon={<ConfigurePlus />}
				buttonType='light'
				onClick={handleAddCarouselText}>
				Add Image
			</Button>
		</div>
	);
};

export default CardCarousels;
