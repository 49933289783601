import CircularProgress from '@mui/material/CircularProgress';
import PieChart from '../../../../Components/PieChart';
import LabelContainer from './LabelContainer';
import './index.scss';

interface PiechartProps {
	isLoading: boolean;
	data?: { value: number; name: string }[];
	title?: string;
}

function Piechart({ isLoading, data, title }: PiechartProps): JSX.Element {
	return (
		<div className='pieChart-Container'>
			<h6>{title}</h6>
			{isLoading ? (
				<div className='loading'>
					<CircularProgress size='24px' />
					<p className='caption'>Loading data</p>
				</div>
			) : (
				<>
					{data?.filter((each) => each.value !== 0).length ? (
						<div className='piechart-content'>
							<div className='content'></div>
							<div className='content1'>
								<PieChart data={data} />
							</div>
							<div className='content'>
								<LabelContainer data={data} />
							</div>
						</div>
					) : (
						<div className='no-data'>
							<p className='body-2'>No messages in the selected date range</p>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default Piechart;
