import { useEffect, useState } from 'react';
import Dropdown, { DropdownValue } from '../../../../Components/Dropdown';
import './Agentranking.scss';
import { RootState } from '../../../../app/store';
import {
	getAgentRankingLiveChat,
	getAllAgentsLiveChat,
} from '../../../../Features/Analytics/analyticsSlice';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setDatasets } from 'react-chartjs-2/dist/utils';

type Props = {
	selectedAgentId: number;
	selectedTenantId: number;
	startDate: any;
	endDate: any;
	factorName: string;
};

const Agentranking = ({ selectedAgentId }: Props) => {
	const navigate = useNavigate();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const getAllAgentData = () => {
		tenantId &&
			dispatch(
				getAllAgentsLiveChat({
					type: 'all',
					tenantId: tenantId,
				})
			);
	};
	const [fieldValue, setFieldValue] = useState('Answered Handoffs');
	const onchange = (feild: any) => {
		setSelectedField(feild);
		getAgentRanking();
		setFieldValue(feild.value);
	};
	const { getAgentRankingData, selectedDates } = useSelector(
		(state: RootState) => state.analytics
	);

	const options = [
		{
			label: (
				<div className='action-label'>
					<p>No. of Handoffs</p>
				</div>
			),
			value: 'No. of Handoffs',
		},

		{
			label: (
				<div className='action-label'>
					<p>Unanswered Handoff</p>
				</div>
			),
			value: 'Unanswered Handoff',
		},
		{
			label: (
				<div className='action-label'>
					<p>User Feedback</p>
				</div>
			),
			value: 'User Feedback',
		},
		{
			label: (
				<div className='action-label'>
					<p>Average User Wait Time in Queue</p>
				</div>
			),
			value: 'Average User Wait Time in Queue',
		},
	];
	const [selectedfield, setSelectedField] = useState<DropdownValue | any>(
		options[0]
	);
	const selectedfactor = selectedfield.value;

	const dispatch = useDispatch<any>();

	const getAgentRanking = () => {
		//getAllAgentData();
		tenantId &&
			dispatch(
				getAgentRankingLiveChat({
					start: selectedDates[0],
					end: selectedDates[1],
					agentId: selectedAgentId,
					tenantId: tenantId,
					factorName: selectedfactor,
				})
			);
	};

	return (
		<div className='AgentRanking-main-div'>
			<div className='filter-bar'>
				<h5>Agent Rankings</h5>
				<div style={{ display: 'flex' }}>
					<div
						style={{
							fontSize: '14px',
							display: 'flex',
							alignItems: 'center',
							fontWeight: 'normal',
							letterSpacing: '-0.21px',
							padding: '0px 5px ',
						}}>
						Rank By:
					</div>
					<div className='sf-dropdown-wrapper'>
						<Dropdown
							onChange={(feild) => onchange(feild)}
							options={options}
							value={selectedfield}
						/>
					</div>
				</div>
			</div>
			<div className='rankings' style={{ marginLeft: '36.5px' }}>
				<div className='ranking'>
					<div className='rank-title'>Top Ranked Agents</div>
					<div className='rank-card'>
						<div className='rank-card-header'>
							<div
								className='rank-card-header-contents'
								style={{
									width: '25%',
								}}>
								Rank
							</div>
							<div
								className='rank-card-header-contents'
								style={{
									width: '50%',
								}}>
								Agents
							</div>
							<div
								className='rank-card-header-contents'
								style={{
									width: '50%',
								}}>
								{fieldValue}
							</div>
						</div>

						{
							getAgentRankingData.QueueRankingNoOfHandoffs ? (
								getAgentRankingData.QueueRankingNoOfHandoffs.JsonStringResp.map(
									(card: any) => {
										// console.log(card);

										return (
											<div className='rank-card-content'>
												<div className='row' style={{ display: 'flex' }}>
													<div
														className='rank-card-header-contents'
														style={{
															width: '25%',
														}}>
														{card.queueCount}
													</div>
													<div
														className='rank-card-header-contents'
														style={{
															width: '50%',
														}}>
														{card.queueName}
													</div>

													<div
														className='rank-card-header-contents'
														style={{
															width: '50%',
														}}>
														<span style={{ marginLeft: '5px' }}>
															{card.count}
														</span>
													</div>
												</div>
											</div>
										);
									}
								)
							) : (
								<div className='rank-card-content'>
									<div className='row' style={{ display: 'flex' }}>
										<div
											className='rank-card-header-contents'
											style={{
												width: '25%',
											}}>
											-
										</div>
										<div
											className='rank-card-header-contents'
											style={{
												width: '50%',
											}}>
											-
										</div>

										<div
											className='rank-card-header-contents'
											style={{
												width: '50%',
											}}>
											<span style={{ marginLeft: '5px' }}>-</span>
										</div>
									</div>
								</div>
							)

							// <div className='rank-card-header-contents'
							// style={{
							// 	width: '50%',
							// }}>
							// 	No data
							// </div>
						}
					</div>
				</div>

				<div className='ranking'>
					<div className='rank-title'>Lowest Ranked Agents</div>
					<div className='rank-card'>
						<div className='rank-card-header'>
							<div
								className='rank-card-header-contents'
								style={{
									width: '25%',
								}}>
								Rank
							</div>
							<div
								className='rank-card-header-contents'
								style={{
									width: '50%',
								}}>
								Agents
							</div>
							<div
								className='rank-card-header-contents'
								style={{
									width: '50%',
								}}>
								{fieldValue}
							</div>
						</div>

						{
							getAgentRankingData.QueueRankingNoOfHandoffs ? (
								getAgentRankingData.QueueRankingNoOfHandoffs.JsonStringResp.map(
									(card: any) => {
										// console.log(card);

										return (
											<div className='rank-card-content'>
												<div className='row' style={{ display: 'flex' }}>
													<div
														className='rank-card-header-contents'
														style={{
															width: '25%',
														}}>
														{card.queueCount}
													</div>
													<div
														className='rank-card-header-contents'
														style={{
															width: '50%',
														}}>
														{card.queueName}
													</div>

													<div
														className='rank-card-header-contents'
														style={{
															width: '50%',
														}}>
														<span style={{ marginLeft: '5px' }}>
															{card.count}
														</span>
													</div>
												</div>
											</div>
										);
									}
								)
							) : (
								<div className='rank-card-content'>
									<div className='row' style={{ display: 'flex' }}>
										<div
											className='rank-card-header-contents'
											style={{
												width: '25%',
											}}>
											-
										</div>
										<div
											className='rank-card-header-contents'
											style={{
												width: '50%',
											}}>
											-
										</div>

										<div
											className='rank-card-header-contents'
											style={{
												width: '50%',
											}}>
											<span style={{ marginLeft: '5px' }}>-</span>
										</div>
									</div>
								</div>
							)

							// <div className='rank-card-header-contents'
							// style={{
							// 	width: '50%',
							// }}>
							// 	No data
							// </div>
						}
					</div>
				</div>
			</div>
			<div
				className='list-ranking'
				onClick={() => {
					navigate('/app/analytics/live-chat/agents-table');
				}}>
				<div
					className='view-list'
					onClick={() => {
						document.querySelector('.container-main')?.scrollTo({
							top: 0,
							behavior: 'smooth',
						});
					}}>
					<FormatListBulletedIcon color='primary' fontSize='inherit' /> View
					Complete list
				</div>
			</div>
		</div>
	);
};
export default Agentranking;
