import React, { useEffect } from 'react';
import {
	CHATSVG,
	FEEDBACKSVG,
	NewAIFooter,
	GETINTOUCH,
	GETINTOUCHNOTFILLED,
	HOMESVG,
	HOMESVGNOTFILLED,
} from '../../../../styles/EOW/Footer/footer.styled';
import {
	setCurrentScreen,
	setGenInTouchIconSVGFilled,
	setHomeIconSVGFilled,
} from '../../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const AIFooter = () => {
	const {
		homeiconsvgfilled,
		getintouchiconsvgfilled,
		generateLeadsButtonText,
		enableGenerateLeads,
		colorScale,
		currentScreen,
		fontSize,
		colorMode,
		additionalsettingDetailsCollection,
		additionalsettingMandatoryCollection,
		additionalsettingMandatoryLeads
	} = useSelector((state: RootState) => state.experienceonweb);
	const dispatch = useAppDispatch();
	const location = useLocation();

	return (
		<>
			{(additionalsettingMandatoryCollection[0] &&
				location.pathname.indexOf('generate-leads') > -1) || (additionalsettingMandatoryLeads[0] && location.pathname.indexOf('livechat-leads') > -1) ? (
				<></>
			) : (
				<NewAIFooter
					className='ff-ai-footer'
					colorScale={colorScale}
					fontSize={fontSize.value}
					mode={colorMode}
					enableGenerateLeads={enableGenerateLeads}>
					<div>
						<div
							className={`${currentScreen === 'homescreen' ? 'selected' : ''}`}
							onClick={() => {
								dispatch(setHomeIconSVGFilled(true));
								dispatch(setGenInTouchIconSVGFilled(false));
								dispatch(setCurrentScreen('homescreen'));
							}}>
							{homeiconsvgfilled ? (
								<HOMESVG
									colorScale={colorScale}
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 28 28'>
									<path data-name='Path 11' d='M0 0h28v28H0z' fill='none' />
									<path
										data-name='Path 12'
										d='M13.333 3 4 10v14h18.667V10z'
										transform='translate(.667 .5)'
									// fill='#3b44c4'
									/>
								</HOMESVG>
							) : (
								<HOMESVGNOTFILLED
									colorScale={colorScale}
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 28 28'>
									<g data-name='Group 17915'>
										<path data-name='Path 9' d='M0 0h28v28H0z' fill='none' />
									</g>
									<g data-name='Group 17916'>
										<path
											data-name='Path 10'
											d='m13.333 5.917 7 5.25v10.5h-14v-10.5l7-5.25m0-2.917L4 10v14h18.667V10z'
											transform='translate(.667 .5)'
										// fill='rgba(0,0,0,.6)'
										/>
									</g>
								</HOMESVGNOTFILLED>
							)}
							<p className='caption'>Home</p>
						</div>
						<div
							className={`${currentScreen === 'chats' ? 'selected' : ''}`}
							onClick={() => {
								dispatch(setCurrentScreen('chats'));
								dispatch(setGenInTouchIconSVGFilled(false));
								dispatch(setHomeIconSVGFilled(false));
							}}>
							<CHATSVG
								style={{ opacity: currentScreen === 'chats' ? 1 : 0.6 }}
								colorScale={colorScale}
								colorMode={colorMode}
								xmlns='http://www.w3.org/2000/svg'
								width='28'
								height='28'
								viewBox='0 0 28 28'>
								<path data-name='Path 15' d='M0 0h28v28H0z' fill='none' />
								<path
									data-name='Path 16'
									d='M17.167 4.333V12.5H5.7l-.688.688-.677.677V4.333h12.832M18.333 2H3.167A1.17 1.17 0 0 0 2 3.167V19.5l4.667-4.667h11.666a1.17 1.17 0 0 0 1.167-1.166v-10.5A1.17 1.17 0 0 0 18.333 2zm5.833 4.667h-2.333v10.5H6.667V19.5a1.17 1.17 0 0 0 1.167 1.167h12.833l4.667 4.667V7.833a1.17 1.17 0 0 0-1.167-1.166z'
									transform='translate(.333 .333)'
								/>
							</CHATSVG>
							<p className='caption'>Chat</p>
						</div>
						{(additionalsettingDetailsCollection[0] &&
							location.pathname.indexOf('generate-leads') > -1) || (additionalsettingMandatoryLeads[0] && location.pathname.indexOf('livechat-leads') > -1) ? (
							<div
								className={`${currentScreen === 'getintouch' ? 'selected' : ''
									}`}
								onClick={() => {
									dispatch(setGenInTouchIconSVGFilled(true));
									dispatch(setHomeIconSVGFilled(false));
									dispatch(setCurrentScreen('getintouch'));
								}}>
								<>
									{enableGenerateLeads ? (
										<>
											{getintouchiconsvgfilled ? (
												<GETINTOUCH
													colorScale={colorScale}
													colorMode={colorMode}
													xmlns='http://www.w3.org/2000/svg'
													data-name='perm_phone_msg_black_24dp (1)'
													width='28'
													height='28'
													viewBox='0 0 28 28'>
													<path
														data-name='Path 15585'
														d='M0 0h28v28H0z'
														fill='none'
													/>
													<path
														data-name='Path 15586'
														d='M22.833 17.583a13.254 13.254 0 0 1-4.165-.665 1.191 1.191 0 0 0-1.19.28l-2.567 2.567a17.587 17.587 0 0 1-7.688-7.677L9.79 9.51a1.145 1.145 0 0 0 .292-1.178 13.254 13.254 0 0 1-.665-4.165A1.17 1.17 0 0 0 8.25 3H4.167A1.17 1.17 0 0 0 3 4.167 19.832 19.832 0 0 0 22.833 24 1.17 1.17 0 0 0 24 22.833V18.75a1.17 1.17 0 0 0-1.167-1.167z'
														transform='translate(.5 .5)'
													// fill='#3b44c4'
													/>
													<path
														data-name='Path 15587'
														d='M24 6H14v7h10zm-1.429 2.8L19 11.25 15.429 8.8V7.4L19 9.85l3.571-2.45z'
														transform='translate(0 -2.5)'
													// fill='#3b44c4'
													/>
												</GETINTOUCH>
											) : (
												<GETINTOUCHNOTFILLED
													colorScale={colorScale}
													colorMode={colorMode}
													xmlns='http://www.w3.org/2000/svg'
													viewBox='0 0 28 28'>
													<path
														data-name='Path 15577'
														d='M0 0h28v28H0z'
														fill='none'
													/>
													<path
														data-name='Path 15578'
														d='M22.833 17.583a13.254 13.254 0 0 1-4.165-.665 1.3 1.3 0 0 0-.362-.058 1.2 1.2 0 0 0-.828.338l-2.567 2.567a17.587 17.587 0 0 1-7.688-7.677L9.79 9.51a1.145 1.145 0 0 0 .292-1.178 13.254 13.254 0 0 1-.665-4.165A1.17 1.17 0 0 0 8.25 3H4.167A1.17 1.17 0 0 0 3 4.167 19.832 19.832 0 0 0 22.833 24 1.17 1.17 0 0 0 24 22.833V18.75a1.17 1.17 0 0 0-1.167-1.167zM5.368 5.333h1.75a15.47 15.47 0 0 0 .537 3.022l-1.4 1.412a17.487 17.487 0 0 1-.887-4.433zm16.3 16.3a17.385 17.385 0 0 1-4.433-.887l1.4-1.4a14.878 14.878 0 0 0 3.033.525z'
														transform='translate(.5 .5)'
													// fill='rgba(0,0,0,.6)'
													/>
													<path
														data-name='Path 15584'
														d='M24 6H14v7h10zm-1.429 2.8L19 11.25 15.429 8.8V7.4L19 9.85l3.571-2.45z'
														transform='translate(0 -2.5)'
													// fill='rgba(0,0,0,.6)'
													/>
												</GETINTOUCHNOTFILLED>
											)}
											<p className='caption'>{generateLeadsButtonText}</p>
										</>
									) : null}
								</>
							</div>
						) : null}
						{/* <div style={{ cursor: 'not-allowed' }}>
					<FEEDBACKSVG
						colorScale={colorScale}
						colorMode={colorMode}
						style={{ cursor: 'not-allowed' }}
						xmlns='http://www.w3.org/2000/svg'
						width='28'
						height='28'
						viewBox='0 0 28 28'>
						<path data-name='Path 15' d='M0 0h28v28H0z' fill='none' />
						<path
							data-name='Path 16'
							d='M17.167 4.333V12.5H5.7l-.688.688-.677.677V4.333h12.832M18.333 2H3.167A1.17 1.17 0 0 0 2 3.167V19.5l4.667-4.667h11.666a1.17 1.17 0 0 0 1.167-1.166v-10.5A1.17 1.17 0 0 0 18.333 2zm5.833 4.667h-2.333v10.5H6.667V19.5a1.17 1.17 0 0 0 1.167 1.167h12.833l4.667 4.667V7.833a1.17 1.17 0 0 0-1.167-1.166z'
							transform='translate(.333 .333)'
							// fill='rgba(0,0,0,.6)'
						/>
					</FEEDBACKSVG>
					<p className='caption'>Feedback</p>
				</div> */}
					</div>
					<div className='timestamp'>Powered by Flyfish.ai</div>
				</NewAIFooter>
			)}
		</>
	);
};

export default AIFooter;
