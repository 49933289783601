import styled from 'styled-components';
import { obj } from '../../../Configure/Appearance/model';
import { BodyProps } from './model';

export const Body = styled.div<BodyProps>`
	display: flex;
	flex-direction: column;
	max-height: ${(props) =>
		props.iconOutSide ? 'calc(100vh - 247px)' : 'calc(100vh - 209px)'};
	background-image: ${(props) =>
		props.background ? `url(${props.background})` : ''};
	background: ${(props) =>
		props.gradient
			? `linear-gradient(
					${props.gradient.backgroundGradientStartColor},
					${props.gradient.backgroundGradientStopColor})`
			: ''};
	background-position: 50% center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: ${(props) => props.background ?? ''};
`;
