import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import { loadState, saveState } from '../../../../utils/utils';
import AutoComplete from '../../../../Components/AutoComplete';
import Button from '../../../../Components/Button';
import Checkbox from '../../../../Components/Checkbox';
import Input from '../../../../Components/Input';
import {
	getTaskType,
	setadditionalsettingMandatoryLeads,
	setliveChatLeadsCollectionPrompt,
	setliveChatLeadsCompany,
	setliveChatLeadsCountry,
	setliveChatLeadsEmail,
	setliveChatLeadsPhonenumber,
	setliveChatcheckList,
	setliveChatLeadsname
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import './LiveChatLeads.scss';
import DragToReorderList from '../DragToReorderList';
import { useNavigate } from 'react-router-dom';
const LiveChatLeads = () => {
	const {
		// generateLeadsButtonText,
		liveChatLeadsCompany,
		liveChatLeadsCountry,
		liveChatLeadsEmail,
		liveChatLeadsPhonenumber,
		liveChatLeadsname,
		liveChatcheckList,
		liveChatLeadsCollectionPrompt,
		additionalsettingMandatoryLeads,
	} = useSelector((state: RootState) => state.experienceonweb);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [customField, setCustomField] = useState(liveChatcheckList);
	const [dropdownOptions, setDropdownoptions] = useState<
		{ label: string; value: string }[]
	>([]);

	const { crmsLinkedDataLBV, leadsDestination } = useSelector(
		(state: RootState) => state.teamsetting
	);

	const getStateF = (type: string) => {
		if (type === 'Name') {
			return liveChatLeadsname;
		} else if (type === 'Phone Number') {
			return liveChatLeadsPhonenumber;
		} else if (type === 'Email') {
			return liveChatLeadsEmail;
		} else if (type === 'Country') {
			return liveChatLeadsCountry;
		} else if (type === 'Company') {
			return liveChatLeadsCompany;
		}
		return [false];
	};
	const getStateSetter = (type: string, value: any) => {
		if (type === 'Name') {
			return dispatch(setliveChatLeadsname(value));
		} else if (type === 'Phone Number') {
			return dispatch(setliveChatLeadsPhonenumber(value));
		} else if (type === 'Email') {
			return dispatch(setliveChatLeadsEmail(value));
		} else if (type === 'Country') {
			return dispatch(setliveChatLeadsCountry(value));
		} else if (type === 'Company') {
			return dispatch(setliveChatLeadsCompany(value));
		}
		return [false];
	};
	const PlusIcon = () => {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				data-name='add_black_24dp (2)'
				width='20'
				height='20'
				viewBox='0 0 20 20'>
				<path data-name='Path 2860' d='M0 0h20v20H0z' fill='none' />
				<path
					data-name='Path 2861'
					d='M16.667 11.667h-5v5H10v-5H5V10h5V5h1.667v5h5z'
					transform='translate(-.833 -.833)'
					fill='white'
				/>
			</svg>
		);
	};

	// useEffect(() => {
	// 	setDropdownoptions(crmsLinkedDataLBV);
	// }, [crmsLinkedDataLBV]);

	useEffect(() => {
		if (leadsDestination.length) {
			setDropdownoptions(leadsDestination);
		}
	}, [leadsDestination]);
	useEffect(() => {
		if (liveChatcheckList.length !== customField.length) {
			setCustomField(liveChatcheckList);
		}
	}, [liveChatcheckList]);

	return (
		<div className='generate-leads-container'>
			<div className='generate-leads-header'>
				<h4>LiveChat Leads</h4>
				<div className='collect-user-details'>
					<p className='body1 info'>
						Collect details from your users and pass those on to your system.
					</p>
					<h6>Detail collection prompt</h6>
					<p className='body1 before-user-details'>
						This text is shown before asking users for details.
					</p>
					<Input
						placeholder='E.g. Please share the following details'
						fullWidth
						value={liveChatLeadsCollectionPrompt}
						onChange={(e) =>
							dispatch(setliveChatLeadsCollectionPrompt(e.target.value))
						}
						autoFocus
					/>
					<hr />

					<div className='details-to-be-collected'>
						<h6>Details to be collected</h6>
						<DragToReorderList
							items={customField}
							getStateF={getStateF}
							getStateSetter={getStateSetter}
							negation={5}
							// setter={setCustomField}
							from='liveChatleads'
						/>
						{/* {customField.map((data: any) => (
							<div className='details-with-checkbox'>
								<Checkbox
									isChecked={getStateF(data)}
									index={0}
									onValueChange={(val) => getStateSetter(data, val)}
									label={<p className='body1'>{data}</p>}
								/>
							</div>
						))} */}
						<Button
							className='custom-field-button'
							startIcon={<PlusIcon />}
							onClick={() => {
								let val = [...liveChatcheckList];
								val.push({
									sequence: `${liveChatcheckList.length + 1}`,
									title: 'Custom Field',
									data: {
										fieldName: '',
										fieldType: { label: 'Text', value: 'Text' },
										desc: '',
										options: [{ name: '', id: '' }],
									},
								});
								setCustomField(val);
							}}>
							Add custom field
						</Button>
					</div>
					{/* <hr /> */}
					{/* <div className='generate-leads-destination'>
						<h6>Destination</h6>
						<p className='body1'>
							Select where you want to send the collected leads.
						</p>
						<AutoComplete
							multiple={false}
							placeholder='Select'
							options={dropdownOptions}
							valueKey='value'
							labelKey='label'
							value={generateLeadsDestination}
							onChange={(val) => {
								if (val.value === 'Add platform') {
									navigate('/app/general-configuration/Integrations');
									saveState('selectedGeneralTab', 'Integrations');
									dispatch(setGenerateLeadsDestination(null));
								} else {
									dispatch(setGenerateLeadsDestination(val));
								}
							}}
						/>
					</div> */}
					{/* <hr /> */}
					{/* <div className='button-text'>
						<h6>Button Name</h6>
						<p className='body1'>
							Provide a custom text for this action to be shown during the
							conversation.
						</p>
						<Input
							// disabled={enableGenerateLeads}
							value={generateLeadsButtonText}
							limit={20}
							fullWidth
							helperText={'Maximum 20 Characters'}
							onChange={(e) =>
								dispatch(setGenerateLeadsButtonText(e.target.value))
							}
						/>
					</div> */}
					<hr />
					<div className='additional-settings'>
						<h6>Additional Settings</h6>
						<Checkbox
							label={
								<p className='body2'>
									Make it mandatory to share these details before connecting to the Agent
								</p>
							}
							isChecked={additionalsettingMandatoryLeads}
							index={0}
							onValueChange={(val) =>
								dispatch(setadditionalsettingMandatoryLeads(val))
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LiveChatLeads;
