import React, { Component } from 'react';
import { TimePicker } from '../TimePicker';
import {
	CheckBoxChecked,
	CheckBoxUnChecked,
	RadioButtonChecked,
	RadioButtonUnchecked,
} from '../../../../assets/Icons/LiveChatConfigIcons/index';

import Button from '../../../../Components/Button';
import SnackBar from '../../../../Components/SnackBar';

type Props = {
	selectedTenantId: number;
	onSave: Function;
	agentAvailabilityList: any;
	availabilityType: string;
};
type State = {
	defaultCheckRadioBtn: boolean;
	customCheckedBtn: boolean;
	weekDays: {
		name: string;
		id: number;
		check: boolean;
		startTime: string;
		endTime: string;
	}[];
	checkSameTime: boolean;
	sameStartTime: string;
	sameEndTime: string;
};

export default class AgentAvailability extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = this.setInitialState();
		this.fetchDaysChecked = this.fetchDaysChecked.bind(this);
		this.saveAgentAvailability = this.saveAgentAvailability.bind(this);
	}
	setInitialState(): State {
		return {
			defaultCheckRadioBtn: true,
			customCheckedBtn: false,
			weekDays: [
				{
					name: 'Mon',
					id: 0,
					check: false,
					startTime: '09:00 AM',
					endTime: '05:30 PM',
				},
				{
					name: 'Tue',
					id: 1,
					check: false,
					startTime: '09:00 AM',
					endTime: '05:30 PM',
				},
				{
					name: 'Wed',
					id: 2,
					check: false,
					startTime: '09:00 AM',
					endTime: '05:30 PM',
				},
				{
					name: 'Thu',
					id: 3,
					check: false,
					startTime: '09:00 AM',
					endTime: '05:30 PM',
				},
				{
					name: 'Fri',
					id: 4,
					check: false,
					startTime: '09:00 AM',
					endTime: '05:30 PM',
				},
				{
					name: 'Sat',
					id: 5,
					check: false,
					startTime: '09:00 AM',
					endTime: '05:30 PM',
				},
				{
					name: 'Sun',
					id: 6,
					check: false,
					startTime: '09:00 AM',
					endTime: '05:30 PM',
				},
			],
			checkSameTime: false,
			sameStartTime: '09:00 AM',
			sameEndTime: '05:30 PM',
		};
	}
	componentDidMount() {
		if (this.props.agentAvailabilityList) {
			this.props.agentAvailabilityList.map((data: any, i: number) => {
				this.setState((prevState) => ({
					weekDays: prevState.weekDays.map((obj) =>
						obj.name === data.day
							? Object.assign(obj, {
								check: !obj.check,
								startTime: data.time.split('-')[0],
								endTime: data.time.split('-')[1],
							})
							: obj
					),
				}));
			});
		}
		if (this.props.availabilityType) {
			if (this.props.availabilityType === 'custom') {
				this.setState({
					customCheckedBtn: true,
					defaultCheckRadioBtn: false,
				});
			} else {
				this.setState({
					defaultCheckRadioBtn: true,
					customCheckedBtn: false,
				});
			}
		}
	}
	fetchDaysChecked() {
		let days: any = [];
		let sameStartTime: any = [];
		let sameEndTime: any = [];
		if (this.state.checkSameTime) {
			this.state.weekDays.forEach((data, index) => {
				if (data.check === true) {
					days.push(data.name);
					sameStartTime.push(this.state.sameStartTime);
					sameEndTime.push(this.state.sameEndTime);
				}
			});
		} else {
			this.state.weekDays.forEach((data, index) => {
				if (data.check === true) {
					days.push(data.name);
					sameStartTime.push(data.startTime);
					sameEndTime.push(data.endTime);
				}
			});
		}
		return {
			checkedDays:
				days.length !== 0
					? days
					: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
			startTime:
				sameStartTime.length !== 0
					? sameStartTime
					: this.state.sameStartTime
						? new Array(7).fill(this.state.sameStartTime)
						: [],
			endTime:
				sameEndTime.length !== 0
					? sameEndTime
					: this.state.sameEndTime
						? new Array(7).fill(this.state.sameEndTime)
						: [],
		};
	}
	async saveAgentAvailability() {
		this.props.onSave()
		let type;
		let val;
		if (this.state.defaultCheckRadioBtn) {
			type = 'default';
			val = '';
		} else {
			type = 'custom';
			val = this.fetchDaysChecked();
		}
		let data = {
			type: type,
			tenantId: this.props.selectedTenantId.toString(),
			data: val,
		};

		// const response = await fetch(`HybridChat/setAgentAvailability`, {
		// 	method: 'POST',
		// 	body: JSON.stringify(data),
		// })
		// 	.then((response) => response.json())
		// 	.then((response) => {
		// 		if (response.response === 'success') {
		// 			this.props.onSave('Agent Availability Updated');
		// 			toast(
		// 				<SnackBar type='success'>Agent Availability Updated</SnackBar>,
		// 				{
		// 					autoClose: 3000,
		// 				}
		// 			);
		// 		}
		// 	});
	}
	render() {
		return (
			<div className='agent-availablity-wrapper'>
				<div className='main-wrapper default-wrapper'>
					<div
						onClick={() => {
							this.setState({
								defaultCheckRadioBtn: true,
								customCheckedBtn: false,
							});
						}}>
						{this.state.defaultCheckRadioBtn ? (
							<RadioButtonChecked />
						) : (
							<RadioButtonUnchecked />
						)}
					</div>

					<div className='availability-timmings'>24/7</div>
				</div>
				<div className='custom-wrapper main-wrapper'>
					<div className='custom-sub-wrapper'>
						<div
							onClick={() => {
								this.setState({
									customCheckedBtn: true,
									defaultCheckRadioBtn: false,
								});
							}}>
							{this.state.customCheckedBtn ? (
								<RadioButtonChecked />
							) : (
								<RadioButtonUnchecked />
							)}
						</div>

						<div className='availability-timmings'>Custom</div>
					</div>
					<div
						className={`custom-sub-sec-wrapper ${this.state.customCheckedBtn === false ? 'disable-data' : ''
							}`}>
						<div style={{ lineHeight: '2.99', position: 'relative' }}>
							{this.state.weekDays.map((data, index) => {
								return (
									<div key={index} className='week-days'>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
											}}>
											<div
												onClick={() => {
													this.setState((prevState) => ({
														weekDays: prevState.weekDays.map((obj) =>
															obj.id === index
																? Object.assign(obj, { check: !data.check })
																: obj
														),
													}));
												}}>
												{data.check ? (
													<CheckBoxChecked />
												) : (
													<CheckBoxUnChecked />
												)}
											</div>
											<div style={{ marginLeft: '7px' }}>{data.name}</div>
										</div>

										{this.state.checkSameTime === false && data.check && (
											<div
												style={{ display: 'flex', alignItems: 'center' }}
												className='test'>
												<TimePicker
													is24HoursFormat={false}
													onChange={(selectedTime: string) => {
														this.setState((prevState) => ({
															weekDays: prevState.weekDays.map((obj) =>
																obj.id === index
																	? Object.assign(obj, {
																		startTime: selectedTime,
																	})
																	: obj
															),
															sameStartTime: selectedTime,
														}));
													}}
													selectedTime={
														this.props.agentAvailabilityList.find(
															(e: any) => e.day === data.name
														)
															? this.props.agentAvailabilityList
																.find((e: any) => e.day === data.name)
																.time.split('-')[0]
															: '09:00 AM'
													}
												/>
												<span>&nbsp;-&nbsp;</span>
												<TimePicker
													is24HoursFormat={false}
													onChange={(selectedTime: string) => {
														this.setState((prevState) => ({
															weekDays: prevState.weekDays.map((obj) =>
																obj.id === index
																	? Object.assign(obj, {
																		endTime: selectedTime,
																	})
																	: obj
															),
															sameEndTime: selectedTime,
														}));
													}}
													selectedTime={
														this.props.agentAvailabilityList.find(
															(e: any) => e.day === data.name
														)
															? this.props.agentAvailabilityList
																.find((e: any) => e.day === data.name)
																.time.split('-')[1]
															: '05:30 PM'
													}
												/>
											</div>
										)}
									</div>
								);
							})}
							<div>
								{this.state.checkSameTime && (
									<div
										style={
											this.state.checkSameTime
												? { position: 'absolute', right: '0', top: '0' }
												: {}
										}>
										<div className='selected-time-wrapper'>
											<TimePicker
												is24HoursFormat={false}
												onChange={(selectedTime: string) => {
													this.setState({
														sameStartTime: selectedTime,
													});
												}}
												selectedTime={this.state.sameStartTime}
											/>
											<span>&nbsp;-&nbsp;</span>
											<TimePicker
												is24HoursFormat={false}
												onChange={(selectedTime: string) => {
													this.setState({
														sameEndTime: selectedTime,
													});
												}}
												selectedTime={this.state.sameEndTime}
											/>
										</div>
									</div>
								)}
								<div
									className='same-timmings-wrapper'
									style={
										this.state.checkSameTime ? { marginTop: '-14px' } : {}
									}>
									<div
										onClick={() => {
											this.setState({
												checkSameTime: !this.state.checkSameTime,
											});
											this.fetchDaysChecked();
										}}
										style={{ width: '20px' }}>
										{this.state.checkSameTime ? (
											<CheckBoxChecked />
										) : (
											<CheckBoxUnChecked />
										)}
									</div>
									<div className='same-timmings'>Same timings on all days</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='save-wrapper save-agent-wrapper'>
					<Button
						size='large'
						style={{ marginBottom: '15px' }}
						onClick={this.saveAgentAvailability}
						disabled={
							this.state.defaultCheckRadioBtn === true ||
								this.state.weekDays.filter((each) => each.check === true).length >
								0
								? false
								: true
						}>
						Done
					</Button>
				</div>
			</div>
		);
	}
}
