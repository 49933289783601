import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import Button from '../../../../Components/Button';
import Dropdown, {
	DropdownValue,
	DropdownValueProps,
} from '../../../../Components/Dropdown';
import Modal from '../../../../Components/Modal';
import { ModalClose, TriggerQuery } from '../../../../assets/Icons';
import { actionsOptions } from '../common';
import '../ActionsModal.scss';
import './TriggerAnotherQuery.scss';
import Input from '../../../../Components/Input';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	addTriggerQueryAction,
	getTriggerQueryAction,
	resetActionDetails,
} from '../../../../Features/builder/builderSlice';
import { GetAction } from '../../../../Features/builder/model';
import { useSelector } from 'react-redux';
import SnackBar from '../../../../Components/SnackBar';
import { set } from '../../../../Features/message/messageSlice';

const TriggerAnotherQuery = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const context: any = useOutletContext();
	const state: any = location.state;
	const taskAction: GetAction = state.action;

	const { actionDetails, botId, tenantId, activeQuery, queries, actions } =
		useSelector((state: RootState) => state.builder);

	const nextQueries = queries
		.filter((query) => query.nodeId !== activeQuery?.nodeId)
		.map((query) => ({
			value: query.nodeId.toString(),
			label: query.displayQuestion,
		})) as DropdownValueProps[];
	const [query, setQuery] = useState<DropdownValue>(null);

	useEffect(() => {
		botId &&
			tenantId &&
			dispatch(
				getTriggerQueryAction({
					botId,
					tenantId,
					taskId: taskAction.taskId,
					workflowId: taskAction.workflowId,
				})
			);

		return () => {
			dispatch(resetActionDetails());
		};
	}, []);

	useEffect(() => {
		actionDetails?.nodeDetails &&
			setQuery(
				nextQueries.filter(
					(query) => Number(query.value) === actionDetails?.nodeDetails.nodeId
				)[0]
			);
	}, [actionDetails]);

	const handleSave = () => {
		if (actionDetails) {
			if (
				actions.filter(
					(action) =>
						action.task_name === context.actionName &&
						action.task_ID !== actionDetails.taskId
				).length > 0
			) {
				dispatch(set('Action name already exists'));
				// toast(<SnackBar type='danger'>Action name already exists</SnackBar>, {
				// 	autoClose: 3000,
				// });
				return false;
			}

			let nextNodeId = 0;

			let q = query as DropdownValueProps;
			if (q) {
				nextNodeId = Number(q.value);
			}
			activeQuery &&
				dispatch(
					addTriggerQueryAction({
						...actionDetails,
						nodeDetails: {
							nodeId: nextNodeId,
						},
						taskName: context.actionName,
						userResponseType: 'Node',
						userResponseTypeId: 12,
						taskDescription: '',
					})
				);
		}

		navigate(-1);
	};

	return (
		<div className='body-content'>
			<div className='action-content'>
				<Dropdown
					isSearchable
					label='Query to be triggered'
					placeholder='Query name'
					value={query}
					options={nextQueries}
					onChange={setQuery}
				/>
			</div>

			<footer>
				<Button
					disabled={context.actionName.trim().length > 0 ? false : true}
					onClick={handleSave}>
					Save
				</Button>
			</footer>
		</div>
	);
};
export default TriggerAnotherQuery;
