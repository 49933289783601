import TabGenerator from '../../../Components/TabGenerator/TabGenerator';
import { TABS, TABICONS } from './constants';
import { useState, useEffect } from 'react';
import './livechat.scss';
import LiveChatConfig from './LiveChatConfig/LiveChatConfig';
import { loadState } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import { checkFreshchatStatus } from '../../../Features/teamsetting/teamsettingSlice';

function LiveChat() {
	const [selectedTab, setSelectedTab] = useState(TABS[0]);
	const [isFormSubmitting, setFormSubmitting] = useState(false);
	const dispatch = useAppDispatch();
	const { freshChatLinked } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const onTabChangeHandler = (tabName: string) => {
		if (isFormSubmitting || tabName === selectedTab) {
			return;
		}
		setSelectedTab(tabName);
	};
	const errors = {
		displayName: '',
		type: '',
	};
	const TabStyle = {
		pointerEvents: 'none',
	};
	const tenantId = loadState('tenantId');

	useEffect(() => {
		freshChatLinked && setSelectedTab(TABS[3]);
		dispatch(checkFreshchatStatus({ tenantId }));
	}, [freshChatLinked]);

	return (
		<div className='livechat-panel'>
			{freshChatLinked ? (
				<></>
			) : (
				<div className='bot-profile-tabs'>
					<TabGenerator
						type={'vertical'}
						selectedTab={selectedTab}
						onTabChange={(tabName: string) => onTabChangeHandler(tabName)}
						component={'botprofile'}
						icons={TABICONS}
						tabNames={TABS}
						tabPassedStyles={errors.type === 'max' ? TabStyle : null}
					/>
				</div>
			)}
			<div
				className={`timing-availability config ${freshChatLinked ? 'freshchat' : ''}`}>
				<LiveChatConfig
					selectedTenantId={tenantId}
					toasterNotify={() => {}}
					activeTab={selectedTab}
					freshChatLinked={freshChatLinked}
				/>
			</div>
		</div>
	);
}
export default LiveChat;
