import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import Button from '../../../../Components/Button';
import Dropdown, {
	DropdownValue,
	DropdownValueProps,
} from '../../../../Components/Dropdown';
import {
	commonActionParams,
	getIconForNextAction,
	ValidationConditions,
} from '../common';
import '../ActionsModal.scss';
import './CollectUserRating.scss';
import Input from '../../../../Components/Input';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	addUserRatingAction,
	getUserRatingAction,
	resetActionDetails,
} from '../../../../Features/builder/builderSlice';
import {
	AddUserRatingAction,
	DefaultConnection,
	GetAction,
	RatingConnections,
} from '../../../../Features/builder/model';
import { useSelector } from 'react-redux';
import Ratings from './Ratings';
import builderService from '../../../../Features/builder/builderService';
import NextNewAction from '../NextNewAction';
import Conditions, {
	getConditionBranch,
	updateConditions,
} from '../Conditions';
import {
	Emoji1,
	Emoji2,
	Emoji3,
	Emoji4,
	Emoji5,
} from '../../../../assets/Icons';
import { getActionName } from '../../../../utils/utils';
import { format } from 'date-fns';
import { set } from '../../../../Features/message/messageSlice';

const CollectUserRating = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const context: any = useOutletContext();
	const state: any = location.state;
	const taskAction: GetAction = state.action;
	const [label, setLabel] = useState('Next Action');
	const { actionDetails, botId, tenantId, activeQuery, actions } = useSelector(
		(state: RootState) => state.builder
	);
	const [openNextActionTypeForConditions, setOpenNextActionTypeForConditions] =
		useState(false);
	const [nextActionTypeIdForConditions, setNextActionTypeIdForConditions] =
		useState(0);
	const emojis = [
		<Emoji1 width='20px' />,
		<Emoji2 width='20px' />,
		<Emoji3 width='20px' />,
		<Emoji4 width='20px' />,
		<Emoji5 width='20px' />,
	];
	const [condtionIndex, setConditionIndex] = useState<Number | null>(null);
	//for storing nextAction object while creating next action under Conditions
	var nextActionGlobal = {};
	const nextActions = actions
		.filter(
			(action) =>
				// action.task_ID !== state.taskId &&
				// action.taskHierarchyDTO[0] !== state.taskId
				action.task_ID !== taskAction.taskId &&
				action.taskHierarchyDTO[0] !== taskAction.taskId &&
				!action.taskroot
		)
		.map((action) => ({
			value: action.task_ID.toString(),
			label: (
				<div className={`actions-label action${action.userResponseTypeID}`}>
					{getIconForNextAction(action.userResponseTypeID)}
					<p>{action.task_name}</p>
				</div>
			),
			labelValue: action.task_name,
		})) as DropdownValueProps[];

	const nextActionsWithoutNewAction = actions
		.filter(
			(action) =>
				action.task_ID !== taskAction.taskId &&
				action.taskHierarchyDTO[0] !== taskAction.taskId
		)
		.map((action) => ({
			value: action.task_ID.toString(),
			label: (
				<div className={`actions-label action${action.userResponseTypeID}`}>
					{getIconForNextAction(action.userResponseTypeID)}
					<p>{action.task_name}</p>
				</div>
			),
			labelValue: action.task_name,
		})) as DropdownValueProps[];

	nextActions.push({
		value: 'add new action',
		label: (
			<div className='actions-label new-action'>
				{getIconForNextAction(-1)}
				<p>New Action</p>
			</div>
		),
	});

	const [openNextActionType, setOpenNextActionType] = useState(false);
	const [nextActionTypeId, setNextActionTypeId] = useState(0);
	const [nextSpecificActions, setNextSpecificActions] = useState<
		Array<DropdownValue>
	>([]);
	const [templateName, setTemplateName] = useState('');
	const [type, setType] = useState(2);
	const [count, setCount] = useState(5);
	const [paramName, setParamName] = useState('');
	const [ratingName, setRatingName] = useState<'star' | 'smiley'>('star');
	const [allConditions, setAllConditions] = useState<ValidationConditions[]>(
		[]
	);
	const [checkEmpytValue, setCheckEmpytValue] = useState(true);
	useEffect(() => {
		botId &&
			tenantId &&
			dispatch(
				getUserRatingAction({
					botId,
					tenantId,
					taskId: taskAction.taskId,
					workflowId: taskAction.workflowId,
				})
			);
		setConditionIndex(0);
		return () => {
			dispatch(resetActionDetails());
		};
	}, []);

	useEffect(() => {
		const d = new Date();
		setParamName(
			actionDetails?.responseParamName ??
				`#${actionDetails?.taskName.replace(' ', '_')}${format(
					d,
					'Y-MM-dd_H:mm:ss'
				)}`
		);
	}, [context.selectedAction]);
	useEffect(() => {
		let next_action: DropdownValue = {
			value: label,

			label: (
				<div className={`actions-label action${nextActionTypeId}`}>
					{getIconForNextAction(nextActionTypeId)}

					<p>{label}</p>
				</div>
			),

			labelValue: label ?? 'Untitled',
		};

		context.setNextAction(next_action);
	}, [label]);
	useEffect(() => {
		const d = new Date();
		if (
			actionDetails?.responseParamName.length === 0 ||
			actionDetails?.responseParamName === '#'
		) {
			if (actionDetails?.templateName !== actionDetails?.taskName)
				setParamName(
					`#${actionDetails?.taskName.replace(' ', '_')}${format(
						d,
						'Y-MM-dd_H:mm:ss'
					)}`
				);
		} else setParamName(actionDetails?.responseParamName ?? '#');

		setTemplateName(actionDetails?.templateName ?? '');
		setCount(actionDetails?.ratingCount ? actionDetails?.ratingCount : 5);
		setType(actionDetails?.ratingTypeId ? actionDetails?.ratingTypeId : 2);
		setRatingName(
			actionDetails?.ratingTypeName ? actionDetails?.ratingTypeName : 'star'
		);
		setNextSpecificActions(
			actionDetails?.addConnectionToRating &&
				actionDetails?.addConnectionToRating.length > 0
				? actionDetails?.addConnectionToRating.map((connection) => {
						return connection.childtaskID !== 0
							? {
									value: connection.childtaskID.toString(),
									label: connection.childTaskName,
							  }
							: null;
				  })
				: new Array(10).fill(null)
		);

		if (actionDetails && actionDetails?.conditionBranch.length > 0) {
			setAllConditions(updateConditions(actionDetails, nextActions));
		}

		const next = actionDetails?.defaultConnection;
		if (next && actions) {
			let action = actions.filter(
				(action) => action.task_ID === next.childtaskId
			)[0];

			if (action) {
				let next_action: DropdownValue = {
					value: next.childtaskId.toString(),
					label: (
						<div className={`actions-label action${action.userResponseTypeID}`}>
							{getIconForNextAction(action.userResponseTypeID)}
							<p>{action.task_name}</p>
						</div>
					),
					labelValue: next.childTaskName ?? 'Untitled',
				};
				context.setNextAction(next_action);
			}
		}
	}, [actionDetails]);

	useEffect(() => {
		const next = context.nextAction as DropdownValueProps;
		if (next && next.value === 'add new action' && !openNextActionType) {
			setOpenNextActionType(true);
		}
	}, [context.nextAction]);

	const handleSave = async () => {
		if (actionDetails) {
			if (
				actions.filter(
					(action) =>
						action.task_name === context.actionName &&
						action.task_ID !== actionDetails.taskId
				).length > 0
			) {
				dispatch(set('Action name already exists'));
				// toast(<SnackBar type='danger'>Action name already exists</SnackBar>, {
				// 	autoClose: 3000,
				// });
				return false;
			}

			let next = context.nextAction as DropdownValueProps;
			let defaultConnection: DefaultConnection = {
				connectionId: 0,
				childtaskId: 0,
				conditionType: '',
				childTaskName: null,
			};

			if (nextActionTypeId !== 0 && botId && tenantId) {
				//call add action
				let position = actionDetails?.taskPosition.split('d');
				let task_position = '3000d4000';
				if (position) {
					task_position = `${parseInt(position[0]) + 110}d${position[1]}`;
				}
				const common = commonActionParams(actionDetails?.workflowId, false);
				const new_task = await builderService.addAction({
					...common,
					previousTaskId: 0,
					botId,
					tenantId,
					userResponseTypeID: nextActionTypeId,
					task_position,
					task_name: getActionName(actions, nextActionTypeId),
				});

				if (new_task.status === 201) {
					defaultConnection.childtaskId = Number(
						new_task.data.data.task.task_ID
					);
					defaultConnection.childTaskName = 'Untitled';
				}
			} else if (nextActionTypeIdForConditions !== 0 && botId && tenantId) {
				let position = actionDetails?.taskPosition.split('d');
				let task_position = '3000d4000';
				if (position) {
					task_position = `${parseInt(position[0]) + 110}d${position[1]}`;
				}
				const common = commonActionParams(actionDetails?.workflowId, false);
				const new_task = await builderService.addAction({
					...common,
					previousTaskId: 0,
					botId,
					tenantId,
					userResponseTypeID: nextActionTypeIdForConditions,
					task_position,
					task_name: getActionName(actions, nextActionTypeIdForConditions),
				});

				if (new_task.status === 201) {
					const nextActionVar = {
						nextAction: {
							value: new_task.data.data.task.task_ID.toString(),
							label: (
								<div
									className={`actions-label action${new_task.data.data.task.task_ID}`}>
									{getIconForNextAction(new_task.data.data.task.task_ID)}
									<p>{new_task.data.data.task.task_name}</p>
								</div>
							),
							labelValue: new_task.data.data.task.task_name,
						},
					};
					nextActionGlobal = nextActionVar;
				}
				if (next) {
					defaultConnection.childtaskId = Number(next.value);
					defaultConnection.childTaskName = next.labelValue ?? '';
				}
			} else {
				if (next) {
					defaultConnection.childtaskId = Number(next.value);
					defaultConnection.childTaskName = next.labelValue ?? '';
				}
			}

			let addConnectionToRating = nextSpecificActions as DropdownValueProps[];

			let connectionToRating: RatingConnections[] = addConnectionToRating.map(
				(action, index) => {
					if (action) {
						if (action) {
							return {
								childTaskName: index < count ? action.labelValue ?? '' : '',

								childtaskID: index < count ? Number(action.value) : 0,

								isconnected: index < count ? true : false,

								multiLingual: {},

								optionIndex: index + 1,

								optionText: `${index + 1}`,
							};
						} else {
							return {
								childTaskName: '',

								childtaskID: 0,

								isconnected: false,

								multiLingual: {},

								optionIndex: index + 1,

								optionText: `${index + 1}`,
							};
						}
						// return {
						// 	childTaskName: action.labelValue ?? '',
						// 	childtaskID: Number(action.value),
						// 	isconnected: true,
						// 	multiLingual: {},
						// 	optionIndex: index + 1,
						// 	optionText: `${index + 1}`,
						// };
					} else {
						return {
							childTaskName: '',
							childtaskID: 0,
							isconnected: false,
							multiLingual: {},
							optionIndex: index + 1,
							optionText: `${index + 1}`,
						};
					}
				}
			);

			let allConditions2 = allConditions.map((each, i) =>
				i === condtionIndex ? { ...each, ...nextActionGlobal } : each
			);
			const conditionBranch = getConditionBranch(allConditions2, paramName);

			activeQuery &&
				dispatch(
					addUserRatingAction({
						...actionDetails,
						currentNodeId: activeQuery.nodeId,
						addConnectionToRating: connectionToRating,
						ratingCount: count,
						ratingTypeId: type,
						ratingTypeName: ratingName,
						fallBackMessage: 'Please provide a correct rating',
						templateName: templateName,
						templateType: 'New Template Type',
						defaultConnection,
						taskName: context.actionName,
						responseParamName: paramName,
						conditionBranch,
						userResponseType: 'Rating',
						userResponseTypeId: 11,
						taskDescription: '',
					})
				);
		}
		navigate(-1);
	};

	return (
		<>
			<div className='body-content'>
				<div className='action-content'>
					<div className='left-pane'>
						<Input
							fullWidth
							multiline
							placeholder='Enter message'
							value={templateName}
							onChange={(event) => setTemplateName(event.target.value)}
							className='text-input'
						/>
						{/* <Ratings
							count={3}
							type='star'
							active={type === 1}
							onClick={() => {
								setType(1);
								setCount(3);
								setRatingName('star');
							}}
						/> */}
						<Ratings
							count={5}
							type='star'
							active={type === 2}
							onClick={() => {
								setType(2);
								setCount(5);
								setRatingName('star');
							}}
						/>
						<Ratings
							count={10}
							type='star'
							active={type === 3}
							onClick={() => {
								setType(3);
								setCount(10);
								setRatingName('star');
							}}
						/>
						<Ratings
							count={3}
							type='smiley'
							active={type === 4}
							onClick={() => {
								setType(4);
								setCount(3);
								setRatingName('smiley');
							}}
						/>
						<Ratings
							count={5}
							type='smiley'
							active={type === 5}
							onClick={() => {
								setType(5);
								setCount(5);
								setRatingName('smiley');
							}}
						/>
					</div>
					<div className='right-pane'>
						<div className='item'>
							<h6>Saving User Input</h6>
							<Input
								fullWidth
								value={paramName}
								onChange={(event) => setParamName(event.target.value)}
								placeholder='Variable Name'
							/>
						</div>

						<div className='item'>
							<h6>Default Next Action</h6>
							<Dropdown
								isSearchable
								placeholder='Select Next Action'
								value={context.nextAction}
								options={nextActions}
								onChange={context.setNextAction}
								className='next-action-dropdown'
							/>
						</div>

						<div className='item'>
							<h6>Rating-specific Next Actions</h6>
							{nextSpecificActions.map((connection, index) => {
								// @ts-ignore
								let conn: DropdownValueProps = connection;
								return count > index ? (
									<div
										className={
											type === 4 || type === 5 ? 'rating-next-actions' : ''
										}>
										{type === 4 || type === 5 ? (
											<div className='smiley-emoji'>
												{count === 3 ? emojis[2 * index] : emojis[index]}
											</div>
										) : null}

										<Dropdown
											isSearchable
											label={
												// type === 1

												// ? `${index + 1} Star${index === 0 ? '' : 's'}`

												// :

												type === 2
													? `${index + 1} Star${index === 0 ? '' : 's'}`
													: type === 3
													? `${index + 1} Star${index === 0 ? '' : 's'}`
													: type === 4
													? `${index + 1} Smiley${index === 0 ? '' : 's'}`
													: type === 5
													? `${index + 1} Smiley${index === 0 ? '' : 's'}`
													: undefined
											}
											placeholder='Select Next Action'
											value={
												conn
													? nextActionsWithoutNewAction.filter(
															(action) =>
																action && conn && action.value === conn.value
													  )[0]
													: null
											}
											options={nextActionsWithoutNewAction}
											onChange={(value) =>
												setNextSpecificActions((prev) =>
													prev.map((con, i) => (index === i ? value : con))
												)
											}
											className='next-action-dropdown'
											key={index}
										/>
									</div>
								) : null;
							})}
						</div>

						{/* <div className='item'>
							<Conditions
								allConditions={allConditions}
								setAllConditions={setAllConditions}
								workflowId={taskAction.workflowId}
								nextActions={nextActions}
								setOpenNextActionType={setOpenNextActionTypeForConditions}
								setNextActionTypeIdForConditions={
									setNextActionTypeIdForConditions
								}
								nextActionTypeIdForConditions={nextActionTypeIdForConditions}
								openNextActionType={openNextActionTypeForConditions}
								setConditionIndex={setConditionIndex}
								setCheckEmpytValue={setCheckEmpytValue}
							/>
						</div> */}
					</div>
				</div>

				<footer>
					<Button
						onClick={handleSave}
						disabled={
							context.actionName.trim().length > 0 &&
							templateName.trim().length > 0
								? false
								: true
						}>
						Save
					</Button>
				</footer>
			</div>

			<NextNewAction
				open={openNextActionType}
				setOpenNextActionType={setOpenNextActionType}
				setNextActionTypeId={setNextActionTypeId}
				setLabel={setLabel}
				onClose={() => {
					setOpenNextActionType(false);
					if (nextActionTypeId === 0) {
						context.setNextAction(null);
					}
				}}
			/>
		</>
	);
};
export default CollectUserRating;
