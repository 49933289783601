import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { State, ZOHOCRMSETUP, ZOHOUNLINK } from './modal';
import zohoService from './zohoService';
import { set } from '../../Features/message/messageSlice';
import { useAppDispatch } from '../../app/store';

const initialState: State = {
	zoholinked: false,
	linkState: {
		status: '',
		url: '',
	},
	unlinkSnackbar: false,
	zohoUnlink: {
		status: '',
	},
	linkZohoLoader: '',
	unlinkZohoLoader: '',
	linkZohoDevConsoleStatus: '',
	leadScope: [false],
	ticketingScope: [false],
	modifyLeadScope: [false],
	modifyTicketingScope: [false],
};

export const zohoCrm = createAsyncThunk<
	any,
	ZOHOCRMSETUP,
	{ rejectValue: string }
>('/zohoSetup', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response: any = await zohoService.zohoCrm(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const unlinkZohoSetup = createAsyncThunk<
	any,
	ZOHOUNLINK,
	{ rejectValue: string }
>('unlink', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const response: any = await zohoService.unlinkZoho(data);

		if (response.data) {
			return response.data;
		}
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		return rejectWithValue(JSON.stringify(error));
	}
});

export const linkZohoDevConsole = createAsyncThunk<
	any,
	any,
	{ rejectValue: string }
>(
	'/linkZohoDevConsole',
	async (data, { rejectWithValue, dispatch, getState }) => {
		try {
			const response: any = await zohoService.linkZohoDevConsole(data);

			if (response.data) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			return rejectWithValue(JSON.stringify(error));
		}
	}
);

export const zohoSlice = createSlice({
	name: 'zohoSlice',
	initialState,
	reducers: {
		setZoholinked: (state, action) => {
			state.zoholinked = action.payload;
		},
		resetUnlinkZohoLoader: (state) => {
			state.unlinkZohoLoader = '';
		},
		resetlinkZohoLoader: (state) => {
			state.linkZohoLoader = '';
		},
		setLeadScope: (state, action) => {
			state.leadScope = action.payload;
		},
		setTicketingScope: (state, action) => {
			state.ticketingScope = action.payload;
		},
		setModifyLeadScope: (state, action) => {
			state.modifyLeadScope = action.payload;
		},
		setModifyTicketingScope: (state, action) => {
			state.modifyTicketingScope = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(zohoCrm.pending, (state, action) => {
				state.linkZohoLoader = 'loading';
			})
			.addCase(zohoCrm.fulfilled, (state, action) => {
				state.linkState = action.payload;
				state.linkZohoLoader = 'success';
			})
			.addCase(zohoCrm.rejected, (state, action) => {
				state.linkZohoLoader = 'failed';
			})
			.addCase(unlinkZohoSetup.pending, (state, action) => {
				state.unlinkZohoLoader = 'loading';
			})
			.addCase(unlinkZohoSetup.fulfilled, (state, action) => {
				state.unlinkZohoLoader = 'success';
				if (action.payload.status === 'Success') {
					state.linkState.status = '';
				}
			})
			.addCase(unlinkZohoSetup.rejected, (state, action) => {
				state.unlinkZohoLoader = 'failed';
			})
			.addCase(linkZohoDevConsole.pending, (state, action) => {
				state.linkZohoDevConsoleStatus = 'loading';
			})
			.addCase(linkZohoDevConsole.fulfilled, (state, action) => {
				state.linkZohoDevConsoleStatus = 'success';
			})
			.addCase(linkZohoDevConsole.rejected, (state, action) => {
				state.linkZohoDevConsoleStatus = 'failed';
			});
	},
});

export const {
	setZoholinked,
	resetUnlinkZohoLoader,
	resetlinkZohoLoader,
	setTicketingScope,
	setLeadScope,
	setModifyLeadScope,
	setModifyTicketingScope,
} = zohoSlice.actions;

export default zohoSlice.reducer;
