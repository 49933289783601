
import { useSelector } from 'react-redux';
import Input from '../../../../Components/Input';
import { chatMessage, HBsetChatInput, setMsgsArray } from '../../../../Features/HelpBot/helpbotSlice';
import './hbfooter.scss';
import { RootState, useAppDispatch } from '../../../../app/store';
function HelpBot() {
    const {
        msgsArray,
        msgsLoading,
        chatInput,
        conversationId,
    } = useSelector((state: RootState) => state.helpBot);
    const dispatch = useAppDispatch();
    const handleSendMessage = () => {

        dispatch(
            setMsgsArray([...msgsArray, { content: chatInput.trim(), role: 'user', time: new Date().toISOString() }])
        );
        const botID = window.location.origin.lastIndexOf('uat.flyfish.ai') > -1 ? '46' : "120";
        dispatch(chatMessage({
            "botId": botID,
            "country": "US",
            "id": conversationId ? conversationId : 0,
            "message": chatInput.trim(),
            "metaData": null,
            "version": "prod"
        }))
        dispatch(HBsetChatInput(''));

        // dispatch(setMsgsLoading('loading'));
    };



    return (
        <div className='ff-helpbot-footer-container'>
            <Input
                placeholder='Ask me something'
                fullWidth
                type='text'
                value={chatInput}
                onChange={(event) => {
                    dispatch(HBsetChatInput(event.target.value));
                }}
                onKeyDown={(evt) => {
                    if (evt.keyCode === 13 || evt.key === 'Enter') {
                        handleSendMessage();

                    }
                }}
                autoComplete='off'
                autoFocus
                className='helpbot-input-box'
            />
        </div>
    )
}
export default HelpBot;
