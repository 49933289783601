import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../app/store';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import Radio from '../../../../../Components/Radio';
import {
	onBoardWabano,
	resetCreateInstaWatsapp,
	resetOnBoardWabaStatus,
	resetUpdateNumbers,
} from '../../../../../Features/WhatsApp-BSP/auth/authSlice';
import { loadState } from '../../../../../utils/utils';

interface PropsForAlmostDone {
	setAlmostDoneModal: (arg: boolean) => void;
	updatednumbers: { phoneNumber: string; name: string }[];
	selectedWabaId: string;
	setSelectedWabaNumber: (arg: { phoneNumber: string; name: string }) => void;
}
export const AlmostDoneModal = () => {
	const [selectRadio, setSelectedRadio] = useState('');
	const [confirmation, setConfirmation] = useState(false);
	const [companyName, setCompanyName] = useState('');
	const [selectedWabaDetails, setSelectWabaDetails] = useState({});
	const dispatch = useAppDispatch();
	const tenantId = loadState('tenantId');
	const botId = loadState('botId');
	const { isLoading, selectWabaId } = useSelector((state: RootState) => state.BSPauth);
	const location = useLocation();
	const state = location.state as { value: any };
	const navigate = useNavigate();
	const { onBoardWabaStatus } = useSelector(
		(state: RootState) => state.BSPauth
	);

	let path = location.pathname;
	let pathArr = path.split('/');
	useEffect(() => {
		if (pathArr) {
			if (pathArr[2] === 'launch') {
				if (onBoardWabaStatus === 'success') {
					navigate('/app/launch/set-up-success', {
						state: { value: selectedWabaDetails },
					});
					resetOnBoardWabaStatus();
				} else if (onBoardWabaStatus === 'failed') {
					navigate('/app/launch/error-in-setup');
				}
			} else if (pathArr[2] === 'welcome') {
				if (onBoardWabaStatus === 'success') {
					navigate('/app/welcome/set-up-success', {
						state: { value: selectedWabaDetails },
					});
					resetOnBoardWabaStatus();
				} else if (onBoardWabaStatus === 'failed') {
					navigate('/app/welcome/error-in-setup');
				}
			}
		}
		return () => {
			dispatch(resetOnBoardWabaStatus());
		};
	}, [onBoardWabaStatus]);
	const handleConfirm = () => {
		if (Boolean(state.value.updatednumbers.length === 1)) {
			dispatch(
				onBoardWabano({
					wabaNo: state.value.updatednumbers[0].phoneNumber || '',
					wabaId: selectWabaId,
					tenantId,
					botId,
				})
			);
			setSelectWabaDetails({
				phoneNumber: state.value.updatednumbers[0].phoneNumber,
				name: state.value.updatednumbers[0].name,
			});
		} else {
			dispatch(
				onBoardWabano({
					wabaNo: selectRadio || '',
					wabaId: selectWabaId,
					tenantId,
					botId,
				})
			);
			setSelectWabaDetails({
				phoneNumber: selectRadio,
				name: companyName,
			});
		}
	};

	const handleBackButton = () => {
		dispatch(resetUpdateNumbers());
		navigate(-1);
	};
	return (
		<>
			<div>
				<Modal
					//empty funciton to prevent misbehaving if we click outside modal
					onClose={() => {
						navigate(-1);
						dispatch(resetOnBoardWabaStatus());
						dispatch(resetCreateInstaWatsapp());
						dispatch(resetUpdateNumbers());
					}}
					header='Almost done...'
					headerCloseBtn
					actionButtons={
						confirmation || Boolean(state.value.updatednumbers.length === 1) ? (
							<>
								{/* <Button
									buttonType='light'
									onClick={() => {
										Boolean(state.value.updatednumbers.length === 1)
											? handleBackButton()
											: setConfirmation(false);
									}}>
									Back
								</Button> */}

								<Button onClick={() => handleConfirm()} loading={isLoading}>
									Confirm
								</Button>
							</>
						) : (
							<>
								{/* <Button buttonType='light' onClick={() => navigate(-1)}>
									Back
								</Button> */}

								<Button
									disabled={!Boolean(selectRadio.length)}
									onClick={() => setConfirmation(true)}>
									Confirm
								</Button>
							</>
						)
					}>
					<p className='body1'>
						{Boolean(state.value.updatednumbers.length === 1) ? (
							<p>
								`One last step. Please confirm, you want to proceed with adding
								your WhatsApp profile{' '}
								<b>
									{state.value.updatednumbers &&
										state.value.updatednumbers[0].name}{' '}
									(
									{state.value.updatednumbers &&
										state.value.updatednumbers[0].phoneNumber}
									){' '}
								</b>
								to Flyfish?
							</p>
						) : confirmation ? (
							<p>
								`One last step. Please confirm, you want to proceed with adding{' '}
								<b>
									{companyName} ({selectRadio}){' '}
								</b>
								to Flyfish?
							</p>
						) : (
							'One last step. Please confirm the WhatsApp Profile that you want to add to Flyfish:'
						)}
					</p>
					{Boolean(state.value.updatednumbers.length === 1) ? (
						<></>
					) : (
						<div style={{ marginTop: '20px' }}>
							{!confirmation &&
								state.value.updatednumbers.map((each: any) => {
									return (
										<div className='whatsapp-numbers-radio'>
											<Radio
												label={
													<div className='label'>
														<p className='body1'>{each.name}</p>
														<p className='caption'>{each.phoneNumber}</p>
													</div>
												}
												isChecked={selectRadio}
												value={each.phoneNumber}
												onValueChange={(value) => {
													setSelectedRadio(value);
													setCompanyName(each.name);
												}}
												name={''}
											/>
										</div>
									);
								})}
						</div>
					)}
				</Modal>
			</div>
			<Outlet />
		</>
	);
};
