export const FFStoreIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			width='38'
			height='38'
			viewBox='0 0 38 38'>
			<defs>
				<clipPath id='clip-path'>
					<rect
						id='Rectangle_15814'
						data-name='Rectangle 15814'
						width='38'
						height='38'
						transform='translate(0.311)'
						fill='none'
					/>
				</clipPath>
				<linearGradient
					id='linear-gradient'
					x1='0.082'
					y1='0.792'
					x2='1.053'
					y2='0.315'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stop-color='#2c43bb' />
					<stop offset='0.808' stop-color='#d03387' />
					<stop offset='1' stop-color='#e46ea5' />
				</linearGradient>
				<linearGradient
					id='linear-gradient-2'
					x1='0.438'
					y1='1.14'
					x2='0.961'
					y2='0.305'
					xlinkHref='#linear-gradient'
				/>
			</defs>
			<g
				id='Store_icon'
				data-name='Store icon'
				transform='translate(-474 -255)'>
				<g
					id='Group_32290'
					data-name='Group 32290'
					transform='translate(474.205 255.226)'>
					<g
						id='Group_32288'
						data-name='Group 32288'
						transform='translate(-0.516 -0.226)'
						clip-path='url(#clip-path)'>
						<path
							id='Path_15566'
							data-name='Path 15566'
							d='M37.336,18.668A18.668,18.668,0,1,1,18.668,0,18.668,18.668,0,0,1,37.336,18.668'
							transform='translate(0.448 0.241)'
							fill='url(#linear-gradient)'
						/>
						<path
							id='Path_15567'
							data-name='Path 15567'
							d='M302.317,413V395.027a.383.383,0,0,0-.383-.383H279.751a.383.383,0,0,0-.383.383V413a2.854,2.854,0,0,0,2.854,2.854h17.242A2.854,2.854,0,0,0,302.317,413'
							transform='translate(-271.727 -384.242)'
							fill='#fff'
						/>
						<rect
							id='Rectangle_15813'
							data-name='Rectangle 15813'
							width='22.949'
							height='0.729'
							transform='translate(7.641 10.402)'
							fill='#ceddea'
						/>
						<path
							id='Path_15568'
							data-name='Path 15568'
							d='M281.849,268.165h17.988a2.483,2.483,0,0,1,2.481,2.481v.915H279.368v-.915a2.482,2.482,0,0,1,2.481-2.481'
							transform='translate(-271.727 -261.019)'
							fill='#ebf6ff'
						/>
						<path
							id='Path_15569'
							data-name='Path 15569'
							d='M489.735,309.493a.668.668,0,1,0-.668-.668.668.668,0,0,0,.668.668'
							transform='translate(-476.026 -299.982)'
							fill='#89c147'
						/>
						<path
							id='Path_15570'
							data-name='Path 15570'
							d='M416.275,309.493a.668.668,0,1,0-.668-.668.668.668,0,0,0,.668.668'
							transform='translate(-404.458 -299.982)'
							fill='#ff9700'
						/>
						<path
							id='Path_15571'
							data-name='Path 15571'
							d='M342.805,309.493a.668.668,0,1,0-.668-.668.668.668,0,0,0,.668.668'
							transform='translate(-332.88 -299.982)'
							fill='#e81d62'
						/>
						<path
							id='Path_15572'
							data-name='Path 15572'
							d='M386.269,525.974l-1.048,2.756a.677.677,0,0,1-.637.445h-6.656a.682.682,0,0,1-.633-.431l-2.542-6.5a5.714,5.714,0,0,0-2.288-2.766.68.68,0,1,1,.72-1.155,7.027,7.027,0,0,1,2.547,2.787h6.51c-.162,3.48,2.02,4.739,4.027,4.867'
							transform='translate(-362.114 -504.632)'
							fill='url(#linear-gradient-2)'
						/>
						<path
							id='Path_15573'
							data-name='Path 15573'
							d='M557.606,968.752a1.689,1.689,0,1,0,1.688-1.688,1.69,1.69,0,0,0-1.688,1.688'
							transform='translate(-542.801 -941.923)'
							fill='url(#linear-gradient-2)'
						/>
						<path
							id='Path_15574'
							data-name='Path 15574'
							d='M763.507,967.064a1.688,1.688,0,1,0,1.689,1.688,1.69,1.69,0,0,0-1.689-1.688'
							transform='translate(-741.754 -941.923)'
							fill='url(#linear-gradient-2)'
						/>
						<path
							id='Path_15575'
							data-name='Path 15575'
							d='M712.352,550.04a3.915,3.915,0,1,0,3.914,3.916,3.916,3.916,0,0,0-3.914-3.916m-.386,5.712-1.728-1.73.72-.721,1.009,1.009,1.771-1.773.725.725Z'
							transform='translate(-689.747 -535.636)'
							fill='#89c147'
						/>
					</g>
				</g>
				<g
					id='Ellipse_812'
					data-name='Ellipse 812'
					transform='translate(492 269)'
					fill='none'
					stroke='#fff'
					stroke-width='1'>
					<circle cx='4.5' cy='4.5' r='4.5' stroke='none' />
					<circle cx='4.5' cy='4.5' r='4' fill='none' />
				</g>
			</g>
		</svg>
	);
};
