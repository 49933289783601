import './Pagination.scss';
import InteractiveButton from '../../Button/InteractiveButton';
import { FirstPage } from '../../../assets/Icons/Pagination';
import { ChevronRight } from '../../../assets/Icons/WhatsApp';
import { IconButton, Tooltip } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
type PaginationProps = {
	nextPage: (e: React.MouseEvent<HTMLButtonElement>) => void;
	previousPage: (e: React.MouseEvent<HTMLButtonElement>) => void;
	gotoBegining: (e: React.MouseEvent<HTMLButtonElement>) => void;
	gotoLast: (e: React.MouseEvent<HTMLButtonElement>) => void;
	totalCount: number;
	upper: number;
	lower: number;
	disableforward: boolean;
	disableback: boolean;
};

function Pagination({
	totalCount,
	lower,
	upper,
	disableback,
	disableforward,
	gotoBegining,
	previousPage,
	nextPage,
	gotoLast,
}: PaginationProps) {
	return (
		<div className='table-pagination'>
			<span className='pagination-btns'>
				<Tooltip placement='bottom' title='Go to beginning'>
					<span>
						<InteractiveButton
							key={'Go to beginning'}
							disabled={disableback}
							onClick={gotoBegining}
							aria-label='Go to beginning'
							title='Go to beginning'
							icon={<FirstPageIcon fontSize='medium' color='inherit' />}
						/>
					</span>
				</Tooltip>

				<Tooltip placement='bottom' title='Previous'>
					<span>
						<InteractiveButton
							key={'Previous'}
							disabled={disableback}
							onClick={previousPage}
							aria-label='previous page'
							icon={<KeyboardArrowLeft fontSize='medium' color='inherit' />}
						/>
					</span>
				</Tooltip>

				<Tooltip placement='bottom' title='Next'>
					<span>
						<InteractiveButton
							key={'Next'}
							disabled={disableforward}
							onClick={nextPage}
							aria-label='next page'
							icon={<KeyboardArrowRight fontSize='medium' color='inherit' />}
						/>
					</span>
				</Tooltip>

				<Tooltip placement='bottom' title='Go to end'>
					<span>
						<InteractiveButton
							key={'Go to end'}
							disabled={disableforward}
							onClick={gotoLast}
							aria-label='Go to end'
							icon={<LastPageIcon fontSize='medium' color='inherit' />}
						/>
					</span>
				</Tooltip>
				<span className='body2 pagination-counter'>
					{lower} - {upper} of {totalCount}
				</span>
			</span>
		</div>
	);
}

export default Pagination;
