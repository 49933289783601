import React, { useEffect, useState } from 'react';
import './ThankYou.scss';
import { SuccessCheck } from '../Subscription/EnterpriseContact';
import Header from '../Header/Header';
import Button from '../../Components/Button';
import { RootState, useAppDispatch } from '../../app/store';
import { useSelector } from 'react-redux';
import {
	linkSubscriptionTenantId,
	subscriptionStatusInvoice,
} from '../../Features/Subscription/subscriptionSlice';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import Note from '../../Components/Note';
import { encryptText, loadState, saveState } from '../../utils/utils';

const ThankYou = () => {
	const [countdown, setCountdown] = useState(5);
	const [status, setStatus] = useState('Pending');
	const [intervalId, setIntervalId] = useState<number | NodeJS.Timer>(0);
	const [showLonger, setShowLonger] = useState(false);
	const [rateLimit, setRateLimit] = useState(0);
	const [errorPage, setErrorPage] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const transactionId = searchParams.get('transactionId');
	const {
		tenantIdAvailable,
		subscriptionInvoiceAPIStatus,
		susbcriptionInvoiceLoader,
		currentSubscriptionId,
	} = useSelector((state: RootState) => state.subscription);
	// const handleUser = () => {
	// 	dispatch(
	// 		subscriptionStatusInvoice({
	// 			transactionId: transactionId ?? '',
	// 			user: encryptText(loadState('userEmailId')),
	// 		})
	// 	);
	// };

	useEffect(() => {
		startCountdown();

		return () => {
			clearInterval(intervalId! as number);
		};
	}, []);

	useEffect(() => {
		if (status === 'Pending') {
			if (countdown === 0) {
				handleCheckStatus();
			}
		}
	}, [countdown]);

	const startCountdown = () => {
		setStatus('Pending');
		setCountdown(5);

		const id = setInterval(() => {
			setCountdown((prevCountdown) => prevCountdown - 1);
		}, 1000);

		setIntervalId(id);
	};

	const handleCheckStatus = async () => {
		if (status === 'Pending') {
			dispatch(
				subscriptionStatusInvoice({
					transactionId: transactionId ?? '',
					user: btoa(encryptText(loadState('userEmailId'))),
				})
			);
			clearInterval(intervalId! as number);
		}
	};
	useEffect(() => {
		if (subscriptionInvoiceAPIStatus === 'success') {
			setStatus('Success');
			if (loadState('tenantId') || tenantIdAvailable) {
				//for existing users
				loadState('tenantId') &&
					dispatch(
						linkSubscriptionTenantId({
							tenantId: loadState('tenantId'),
							subscriptionId: currentSubscriptionId,
						})
					);
				navigate('/app/create-ai');
			} else if (!tenantIdAvailable) {
				loadState('parameter') !== ''
					? navigate('/app/signup-create-team')
					: navigate('/app/create-team');
			}
		} else if (subscriptionInvoiceAPIStatus === 'failed') {
			setStatus('Failure');
			setShowLonger(true);
			let count = rateLimit + 1;
			setRateLimit(count);

			rateLimit < 6 && startCountdown();
			if (rateLimit === 6) {
				setErrorPage(true);
				saveState('errorPage', true);
			}
		}
	}, [subscriptionInvoiceAPIStatus]);

	return (
		<>
			{loadState('basictoken') ? (
				<>
					<Header showheaderUserbadge={false} />
					<div className='ff-thank-you'>
						<>
							{!loadState('errorPage') && !errorPage ? (
								<>
									<div>{SuccessCheck()}</div>
									<h3>Congrats! You are now subscribed to Flyfish</h3>
									{!showLonger ? (
										<p>
											You will be redirected in {countdown} seconds. Please
											don’t refresh the page or <br /> press the back button
										</p>
									) : (
										<>
											<p>
												This is taking longer than expected. Please don’t
												refresh the page or press the <br /> back button
											</p>
											<div className='svg-loader'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'>
													<g data-name='main icon'>
														<path
															data-name='Subtraction 6'
															d='M9.818 19.636A9.818 9.818 0 0 1 9.818 0v2.182a7.637 7.637 0 1 0 7.636 7.637h2.183a9.829 9.829 0 0 1-9.819 9.817z'
															fill='#3b44c4'
															transform='translate(2.183 2.182)'
														/>
													</g>
												</svg>
											</div>
										</>
									)}
								</>
							) : (
								<>
									<div>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											data-name='error_black_24dp (1)'
											width='56'
											height='56'
											viewBox='0 0 56 56'>
											<path
												data-name='Path 15052'
												d='M0 0h56v56H0z'
												fill='none'
											/>
											<path
												data-name='Path 15053'
												d='M25.333 2a23.333 23.333 0 1 0 23.334 23.333A23.342 23.342 0 0 0 25.333 2zm2.333 35H23v-4.667h4.667zm0-9.333H23v-14h4.667z'
												transform='translate(2.667 2.667)'
												fill='#f86060'
											/>
										</svg>
									</div>
									<h3>We ran into an issue</h3>
									<p>
										Please reach out to us on support@flyfish.ai for help with
										your subscription.
									</p>
								</>
							)}

							{/* <Button loading={susbcriptionInvoiceLoader} onClick={handleUser}>
							Continue
						</Button> */}
						</>
					</div>
				</>
			) : (
				<Navigate to='/app/login' replace />
			)}
		</>
	);
};

export default ThankYou;
