import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import AutoComplete from '../../../../Components/AutoComplete';
import Button from '../../../../Components/Button';
import Checkbox from '../../../../Components/Checkbox';
import Input from '../../../../Components/Input';
import {
	setRatButtonText,
	setRatCollectionPrompt,
	setRatEmail,
	setRatIssueDescription,
	setRatName,
	setRatPhoneNumber,
	setRatTicketManagement,
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import './RaiseTicket.scss';
import DragToReorderList from '../DragToReorderList';
import { useNavigate } from 'react-router-dom';
import { saveState } from '../../../../utils/utils';

const RaiseTicket = () => {
	const {
		ratButtonText,
		ratEmail,
		ratIssuedescription,
		ratTicketManagement,
		ratPhonenumber,
		ratname,
		enableRAT,
		ratCollectionPrompt,
		ratCheckList,
	} = useSelector((state: RootState) => state.experienceonweb);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [customField, setCustomField] = useState(ratCheckList);
	const [dropdownOptions, setDropdownoptions] = useState<
		{ label: string; value: string }[]
	>([]);

	const { crmsLinkedDataLBV, ticketingDestination } = useSelector(
		(state: RootState) => state.teamsetting
	);

	const getStateF = (type: string) => {
		if (type === 'Name') {
			return ratname;
		} else if (type === 'Phone Number') {
			return ratPhonenumber;
		} else if (type === 'Email') {
			return ratEmail;
		} else if (type === 'Issue Description') {
			return ratIssuedescription;
		}
		return [false];
	};
	const getStateSetter = (type: string, value: any) => {
		if (type === 'Name') {
			return dispatch(setRatName(value));
		} else if (type === 'Phone Number') {
			return dispatch(setRatPhoneNumber(value));
		} else if (type === 'Email') {
			return dispatch(setRatEmail(value));
		} else if (type === 'Issue Description') {
			return dispatch(setRatIssueDescription(value));
		}
		return [false];
	};
	const PlusIcon = () => {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				data-name='add_black_24dp (2)'
				width='20'
				height='20'
				viewBox='0 0 20 20'>
				<path data-name='Path 2860' d='M0 0h20v20H0z' fill='none' />
				<path
					data-name='Path 2861'
					d='M16.667 11.667h-5v5H10v-5H5V10h5V5h1.667v5h5z'
					transform='translate(-.833 -.833)'
					fill='white'
				/>
			</svg>
		);
	};

	// useEffect(() => {
	// 	setDropdownoptions(crmsLinkedDataLBV);
	// }, [crmsLinkedDataLBV]);

	useEffect(() => {
		if (ticketingDestination.length) {
			setDropdownoptions(ticketingDestination);
		}
	}, [ticketingDestination]);

	useEffect(() => {
		if (ratCheckList.length !== customField.length) {
			setCustomField(ratCheckList);
		}
	}, [ratCheckList]);

	return (
		<div className='ff-configure-raise-a-ticket'>
			<div className='raise-a-ticket'>
				<h4>Raise a Ticket</h4>
				<div className='collect-user-details'>
					<p className='body1 info'>
						Collect issue details from the user and log a ticket
					</p>
					<h6>Detail collection prompt</h6>
					<p className='body1 before-user-details'>
						This text is shown before asking users for details.
					</p>
					<Input
						placeholder='E.g. Please share the following details'
						fullWidth
						value={ratCollectionPrompt}
						onChange={(e) => dispatch(setRatCollectionPrompt(e.target.value))}
						autoFocus
					/>
					<hr />
					<div className='details-to-be-collected'>
						<h6>Details to be collected</h6>
						{/* {customField.map((data: any) => (
							<div className='details-with-checkbox'>
								<Checkbox
									label={<p className='body1'>{data}</p>}
									isChecked={getStateF(data)}
									index={0}
									onValueChange={(val) => getStateSetter(data, val)}
								/>
							</div>
						))} */}
						<DragToReorderList
							items={customField}
							getStateF={getStateF}
							getStateSetter={getStateSetter}
							negation={4}
							// setter={setCustomField}
							from='rat'
						/>
						<Button
							className='custom-field-button'
							startIcon={<PlusIcon />}
							onClick={() => {
								let val = [...ratCheckList];
								val.push({
									sequence: `${ratCheckList.length + 1}`,
									title: 'Custom Field',
									data: {
										fieldName: '',
										fieldType: { label: 'Text', value: 'Text' },
										desc: '',
										options: [{ name: '', id: '' }],
									},
								});
								setCustomField(val);
							}}>
							Add custom field
						</Button>
					</div>
					<hr />
					<div className='ticket-management'>
						<h6>Ticket Management</h6>
						<p className='body1'>Select your ticket management system</p>
						<AutoComplete
							multiple={false}
							placeholder='Select'
							options={dropdownOptions}
							valueKey='value'
							labelKey='label'
							value={ratTicketManagement}
							onChange={(val) => {
								if (val.value === 'Add platform') {
									navigate('/app/general-configuration/Integrations/all');
									saveState('selectedGeneralTab', 'All');
									dispatch(setRatTicketManagement(null));
								} else {
									dispatch(setRatTicketManagement(val));
								}
							}}
						/>
					</div>
					<hr />
					<div className='button-text'>
						<h6>Button text</h6>
						<p className='body1'>
							Provide a custom text for this action to be shown during the
							conversation.
						</p>
						<Input
							// disabled={enableRAT}
							value={ratButtonText}
							limit={20}
							fullWidth
							helperText={'Maximum 20 Characters'}
							onChange={(e) => dispatch(setRatButtonText(e.target.value))}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RaiseTicket;
