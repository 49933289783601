import { FFLoader } from '../../assets/Icons/Loader';
import './Button.scss';
import { Button as Btn, ButtonProps } from '@mui/material';

type Props = {
	className?: string;
	size?: 'small' | 'large';
	buttonType?: 'dark' | 'light' | 'transparent';
	loading?: boolean;
	fullWidth?: boolean;
	startIcon?: React.ReactNode;
	disabled?: boolean;
} & ButtonProps;

const Button = ({
	className,
	children,
	size = 'small',
	buttonType = 'dark',
	loading,
	fullWidth = false,
	startIcon,
	disabled,
	...rest
}: Props) => {
	return (
		<div className='custom-button'>
			
			<Btn
				fullWidth={fullWidth}
				variant='contained'
				size={size}
				className={`${size === 'small' ? 'button2' : 'button1'} ${buttonType}${
					startIcon ? ' icon' : ''
				}${loading ? ' loading' : ''}`}
				disableElevation={true}
				onMouseDown={(event) => event.preventDefault()}
				disableRipple
				disabled={disabled || loading}
				startIcon={
					startIcon ? (
						loading ? (
							<FFLoader
								width={`${size === 'small' ? 20 : 24}`}
								height={`${size === 'small' ? 20 : 24}`}
							/>
						) : (
							startIcon
						)
					) : null
				}
				{...rest}>
				{loading && !startIcon && (
					<span className='button-loader'>
						<FFLoader
							width={`${size === 'small' ? 20 : 24}`}
							height={`${size === 'small' ? 20 : 24}`}
						/>
					</span>
				)}
				<div
					className={`custom-button-body ${
						size === 'small' ? 'button2' : 'button1'
					} ${buttonType}`}>
					{children}
				</div>
			</Btn>
		</div>
	);
};

export default Button;
