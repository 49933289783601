import React, { useEffect, useState } from 'react';
import './PaymentJourney.scss';
import Header from '../../Header/Header';
import { RootState, useAppDispatch } from '../../../app/store';
import { useSelector } from 'react-redux';
import {
	changePlan,
	freePlanSubscribe,
	setSelectedPlan,
	shopSubscription_shopify,
} from '../../../Features/Subscription/subscriptionSlice';
import Button from '../../../Components/Button';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { loadState, saveState } from '../../../utils/utils';
import PaymentLoader from '../PaymentLoader';
import { logout } from '../../../Features/auth/authSlice';
import RedirectLoader from '../../RedirectLoader';

const PaymentJourney = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {
		selectedPlan,
		selectedCurreny,
		shopifyPaymentRedirect,
		shopifyPaymentRedirectAPIStatus,
		changePlanRedirect,
		changePlanAPIStatus,
		freePlanSubscribeAPIStatus,
		subscriptionId_shopify,
	} = useSelector((state: RootState) => state.subscription);
	const [showPaymentLoader, setShowPaymentLoader] = useState(true);
	const [retryLoader, setRetryLoader] = useState(false);
	const location = useLocation();
	const state: any = location.state;

	useEffect(() => {
		if (loadState('shopifyShopName')) {
			if (state && state.via === 'modify') {
				let planCode;
				if (state && state.planCode) {
					planCode = state.planCode;
				} else {
					planCode = selectedPlan;
				}
				dispatch(changePlan({ planCode: planCode }));
			} else {
				let selectedPlan = loadState('selectedPlan');
				if (selectedPlan === 'FF006' || selectedPlan === '006') {
					dispatch(
						freePlanSubscribe({
							subProvider: 'shopify',
							planCode: selectedPlan,
							shop: loadState('shopifyShopName'),
						})
					);
				} else {
					dispatch(
						shopSubscription_shopify({
							shop: loadState('shopifyShopName'),
							planCode: selectedPlan,
							currency: selectedCurreny.value,
						})
					);
				}
			}
		}
		setTimeout(() => {
			setShowPaymentLoader(false);
		}, 5000);
	}, []);

	useEffect(() => {
		if (state && state.via === 'modify') {
			if (changePlanAPIStatus === 'success' && changePlanRedirect) {
				window.location.href = changePlanRedirect;
				setRetryLoader(false);
			} else if (changePlanAPIStatus === 'failed') {
				setRetryLoader(true);
			}
		} else {
			let selectedPlan = loadState('selectedPlan');
			if (selectedPlan === 'FF006' || selectedPlan === '006') {
				if (freePlanSubscribeAPIStatus === 'success') {
					setRetryLoader(false);
					loadState('parameter') !== ''
						? navigate('/app/signup-create-team')
						: navigate('/app/create-team');
					saveState('subscriptionId_shopify', subscriptionId_shopify);
				} else if (freePlanSubscribeAPIStatus === 'failed') {
					setRetryLoader(true);
				}
			} else {
				if (
					shopifyPaymentRedirectAPIStatus === 'success' &&
					shopifyPaymentRedirect
				) {
					window.location.href = shopifyPaymentRedirect;
					setRetryLoader(false);
				} else if (shopifyPaymentRedirectAPIStatus === 'failed') {
					setRetryLoader(true);
				}
			}
		}
	}, [
		shopifyPaymentRedirectAPIStatus,
		changePlanAPIStatus,
		freePlanSubscribeAPIStatus,
	]);

	useEffect(() => {
		if (loadState('errorPage')) {
			dispatch(logout());
		}
	}, [loadState('errorPage')]);

	const subscriptionMode = () => {
		if (loadState('shopifyShopName')) {
			return null;
		} else {
			return <iframe title={selectedPlan} src={selectedPlan} />;
		}
	};

	return (
		<>
			{' '}
			{loadState('basictoken') ? (
				<>
					{showPaymentLoader ? (
						<>
							<PaymentLoader />
						</>
					) : (
						<>
							{loadState('basictoken') ? (
								<div className='ff-payment-journey'>
									{loadState('parameter') === 'notLoggedIn' ||
									loadState('parameter').indexOf('plan') > -1 ? (
										<Header showheaderUserbadge={false} />
									) : null}

									{loadState('shopifyShopName') === undefined ? (
										<div className='ff-zoho-payment-journey'>
											<Button
												buttonType='light'
												startIcon={
													<svg
														xmlns='http://www.w3.org/2000/svg'
														data-name='chevron_left_black_24dp (1)'
														width='20'
														height='20'
														viewBox='0 0 20 20'>
														<path
															data-name='Path 19863'
															d='M0 0h20v20H0z'
															fill='none'
														/>
														<path
															data-name='Path 19864'
															d='M14.175 7.175 13 6l-5 5 5 5 1.175-1.175L10.358 11z'
															transform='translate(-1.333 -1)'
															fill='#3b44c4'
														/>
													</svg>
												}
												onClick={() => {
													dispatch(setSelectedPlan(''));
													if (loadState('parameter').indexOf('plan') > -1) {
														saveState('parameter', 'notLoggedIn'); // in  case redirected user wants to select other plans
														navigate('/app/signup-select-plan');
													} else {
														navigate(-1);
													}
												}}>
												Choose Another Plan
											</Button>
											{subscriptionMode()}
										</div>
									) : (
										<>
											<div className='query-loader'>
												<RedirectLoader
													message={`Oops! It looks like we're experiencing some technical difficulties redirecting to our payment system on Shopify at the moment.`}
													showRetry={retryLoader}
													choosePlanButton={
														<Button
															buttonType='light'
															onClick={() => {
																dispatch(setSelectedPlan(''));
																if (
																	loadState('parameter').indexOf('plan') > -1
																) {
																	saveState('parameter', 'notLoggedIn'); // in  case redirected user wants to select other plans
																	navigate('/app/signup-select-plan');
																} else {
																	navigate(-1);
																}
															}}>
															Choose Another Plan
														</Button>
													}
												/>
											</div>
										</>
									)}
								</div>
							) : (
								<Navigate to='/app/login' replace />
							)}
						</>
					)}
				</>
			) : (
				<>
					<Navigate to='/app/login' replace />
				</>
			)}
		</>
	);
};

export default PaymentJourney;
