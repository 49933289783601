interface Props {
	width?: string;
	height?: string;
	fillIcon?: boolean;
	className?: string;
	color?: string;
	onClick?: (e: React.MouseEvent<SVGElement>) => void;
	style?: React.CSSProperties;
	rotateSVG?: boolean;
	onBlur?: (e: React.FocusEvent<SVGElement>) => void;
}
export const WebIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='Group 29997'
			width='18'
			height='18'
			viewBox='0 0 18 18'>
			<path data-name='Path 13868' d='M0 0h18v18H0z' fill='none' />
			<path
				data-name='Path 13869'
				d='M9.493 2A7.5 7.5 0 1 0 17 9.5 7.5 7.5 0 0 0 9.493 2zm5.2 4.5h-2.215a11.737 11.737 0 0 0-1.035-2.67A6.022 6.022 0 0 1 14.69 6.5zM9.5 3.53a10.565 10.565 0 0 1 1.433 2.97H8.068A10.565 10.565 0 0 1 9.5 3.53zM3.7 11a5.867 5.867 0 0 1 0-3h2.53a12.386 12.386 0 0 0-.105 1.5A12.386 12.386 0 0 0 6.23 11zm.615 1.5h2.208a11.737 11.737 0 0 0 1.035 2.67A5.99 5.99 0 0 1 4.31 12.5zm2.213-6H4.31a5.99 5.99 0 0 1 3.247-2.67A11.737 11.737 0 0 0 6.523 6.5zM9.5 15.47a10.565 10.565 0 0 1-1.432-2.97h2.865A10.565 10.565 0 0 1 9.5 15.47zM11.255 11h-3.51a11.035 11.035 0 0 1-.12-1.5 10.939 10.939 0 0 1 .12-1.5h3.51a10.939 10.939 0 0 1 .12 1.5 11.035 11.035 0 0 1-.12 1.5zm.188 4.17a11.737 11.737 0 0 0 1.035-2.67h2.212a6.022 6.022 0 0 1-3.247 2.67zM12.77 11a12.386 12.386 0 0 0 .1-1.5 12.386 12.386 0 0 0-.1-1.5h2.535a5.867 5.867 0 0 1 0 3z'
				transform='translate(-.499 -.499)'
				fill={props.color ? props.color : '#5e0074'}
			/>
		</svg>
	);
};

export const WhatsAppIcon = (props: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='Group 29998'
			width={props.width ? props.width : '18'}
			height={props.height ? props.height : '18'}
			viewBox={`0 0 ${props.width} ${props.height}`}>
			<defs>
				<clipPath id='v1rdhshzra'>
					<path
						data-name='Rectangle 14663'
						fill='none'
						d='M0 0h15.394v15.468H0z'
					/>
				</clipPath>
			</defs>
			<g data-name='Group 29664'>
				<g data-name='Group 29663' transform='translate(1.105 1.105)'>
					<path
						data-name='Path 8110'
						d='M13.151 2.248A7.667 7.667 0 0 0 1.087 11.5L0 15.468 4.063 14.4a7.658 7.658 0 0 0 3.663.933 7.668 7.668 0 0 0 5.425-13.085M7.729 14.041a6.361 6.361 0 0 1-3.243-.888l-.233-.138-2.411.632.642-2.347-.151-.241a6.371 6.371 0 1 1 5.4 2.986m3.494-4.771c-.192-.1-1.133-.559-1.309-.623s-.3-.1-.431.1-.495.623-.606.751-.223.144-.415.048a5.229 5.229 0 0 1-1.54-.951 5.772 5.772 0 0 1-1.069-1.335c-.112-.192-.012-.3.084-.391s.192-.224.287-.336a1.306 1.306 0 0 0 .192-.319.353.353 0 0 0-.02-.336c-.048-.1-.431-1.039-.59-1.422s-.313-.323-.431-.329-.239-.007-.367-.007a.7.7 0 0 0-.511.24 2.148 2.148 0 0 0-.67 1.6 3.725 3.725 0 0 0 .782 1.981 8.537 8.537 0 0 0 3.272 2.892 10.992 10.992 0 0 0 1.092.4 2.625 2.625 0 0 0 1.206.076 1.972 1.972 0 0 0 1.293-.911 1.6 1.6 0 0 0 .112-.911c-.048-.08-.176-.128-.367-.224'
					/>
				</g>
			</g>
		</svg>
	);
};

export const DeliveredMessage = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='Group 7564'
			width='18'
			height='18'
			viewBox='0 0 18 18'>
			<path data-name='Path 2871' d='M0 0h18v18H0z' fill='none' />
			<path
				data-name='Path 3832'
				d='M13.6 6.648 12.545 5.59 7.79 10.345 8.847 11.4zm3.18-1.057-7.933 7.934L5.713 10.4l-1.058 1.055 4.192 4.193 9-9zM.41 11.455l4.19 4.193 1.06-1.058-4.185-4.19z'
				transform='translate(-.102 -1.398)'
				fill='rgba(0,0,0,.4)'
			/>
		</svg>
	);
};

export const SentMessage = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='Group 7564'
			width='18'
			height='18'
			viewBox='0 0 18 18'>
			<path data-name='Path 2871' d='M0 0h18v18H0z' fill='none' />
			<path
				data-name='Path 2872'
				d='M7.6 13.55 4.45 10.4 3.4 11.45l4.2 4.2 9-9-1.05-1.05z'
				transform='translate(-.85 -1.4)'
				fill='rgba(0,0,0,.4)'
			/>
		</svg>
	);
};

export const SeenMessage = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='Group 7564'
			width='18'
			height='18'
			viewBox='0 0 18 18'>
			<path data-name='Path 2871' d='M0 0h18v18H0z' fill='none' />
			<path
				data-name='Path 3833'
				d='M13.6 6.648 12.545 5.59 7.79 10.345 8.847 11.4zm3.18-1.057-7.933 7.934L5.713 10.4l-1.058 1.055 4.192 4.193 9-9zM.41 11.455l4.19 4.193 1.06-1.058-4.185-4.19z'
				transform='translate(-.102 -1.398)'
				fill='#3b44c4'
			/>
		</svg>
	);
};

export const SendingMessage = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='Group 7564'
			width='18'
			height='18'
			viewBox='0 0 18 18'>
			<path data-name='Path 2871' d='M0 0h18v18H0z' fill='none' />
			<path
				data-name='Path 3830'
				d='M9.493 2A7.5 7.5 0 1 0 17 9.5 7.5 7.5 0 0 0 9.493 2zM9.5 15.5a6 6 0 1 1 6-6 6 6 0 0 1-6 6z'
				transform='translate(-.5 -.5)'
				fill='rgba(0,0,0,.4)'
			/>
			<path
				data-name='Path 3831'
				d='M12.125 7H11v4.5l3.938 2.362.563-.922-3.375-2z'
				transform='translate(-2.75 -1.75)'
				fill='rgba(0,0,0,.4)'
			/>
		</svg>
	);
};

export const DisambArrow = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='arrow_forward_black_24dp (1)'
			width='16'
			height='16'
			viewBox='0 0 16 16'>
			<path data-name='Path 2305' d='M0 0h16v16H0z' fill='none' />
			<path
				data-name='Path 2306'
				d='m9.333 4-.94.94 3.72 3.727H4V10h8.113l-3.72 3.727.94.94 5.333-5.333z'
				transform='translate(-1.333 -1.333)'
				fill='var(--ff-serverMsgColor)'
			/>
		</svg>
	);
};

export const AtTheRate = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='14'
			viewBox='0 0 14 14'>
			<path data-name='Path 14844' d='M0 0h14v14H0z' fill='none' />
			<path
				data-name='Path 14845'
				d='M7.833 1.95a5.833 5.833 0 1 0 0 11.667h2.917V12.45H7.833a4.726 4.726 0 0 1-4.666-4.667 4.726 4.726 0 0 1 4.666-4.666A4.726 4.726 0 0 1 12.5 7.783v.834a.943.943 0 0 1-.875.916.943.943 0 0 1-.875-.916v-.834a2.918 2.918 0 1 0-.85 2.059 2.16 2.16 0 0 0 1.727.858 2.049 2.049 0 0 0 2.042-2.082v-.835A5.835 5.835 0 0 0 7.833 1.95zm0 7.583a1.75 1.75 0 1 1 1.75-1.75 1.748 1.748 0 0 1-1.75 1.75z'
				transform='translate(-.833 -.813)'
				fill='var(--black-60)'
			/>
		</svg>
	);
};

export const ReplyIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='14' viewBox='0 0 14 14'>
			<path data-name='Path 14842' d='M0 0h14v14H0z' fill='none' />
			<path
				data-name='Path 14843'
				d='M7.083 7.333V5L3 9.083l4.083 4.083v-2.391c2.917 0 4.958.933 6.417 2.975-.583-2.917-2.333-5.833-6.417-6.417z'
				transform='translate(-1.25 -2.083)'
				fill='var(--black-60)'
			/>
		</svg>
	);
};

export const InstagramIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			width='18'
			height='18'
			viewBox='0 0 18 18'>
			<defs>
				<radialGradient
					id='74gbshgxva'
					cx='.006'
					cy='1.097'
					r='1.43'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stop-color='#fec053' />
					<stop offset='.327' stop-color='#f2203e' />
					<stop offset='.648' stop-color='#b729a8' />
					<stop offset='1' stop-color='#5342d6' />
				</radialGradient>
				<radialGradient
					id='avat4bjiyb'
					cx='-.465'
					cy='1.666'
					r='2.793'
					xlinkHref='#74gbshgxva'
				/>
				<radialGradient
					id='ci314q84lc'
					cx='-5.687'
					cy='7.551'
					r='11.619'
					xlinkHref='#74gbshgxva'
				/>
			</defs>
			<g data-name='Group 30546'>
				<g data-name='Group 30550'>
					<g data-name='Group 30549'>
						<g data-name='Group 30546'>
							<path
								data-name='Path 14756'
								d='M563.528 267.111h-6.461a4.274 4.274 0 0 1-4.269-4.269v-6.462a4.274 4.274 0 0 1 4.269-4.269h6.461a4.274 4.274 0 0 1 4.269 4.269v6.462a4.274 4.274 0 0 1-4.269 4.269zm-6.462-13.711a2.987 2.987 0 0 0-2.984 2.983v6.462a2.987 2.987 0 0 0 2.984 2.984h6.461a2.987 2.987 0 0 0 2.984-2.984v-6.465a2.987 2.987 0 0 0-2.984-2.983z'
								transform='translate(-551.297 -250.611)'
								fill='url(#74gbshgxva)'
							/>
						</g>
						<g data-name='Group 30547'>
							<path
								data-name='Path 14757'
								d='M595.146 298.3a3.841 3.841 0 1 1 3.841-3.841 3.845 3.845 0 0 1-3.841 3.841zm0-6.4a2.555 2.555 0 1 0 2.555 2.555 2.558 2.558 0 0 0-2.555-2.555z'
								transform='translate(-586.146 -285.46)'
								fill='url(#avat4bjiyb)'
							/>
						</g>
						<g data-name='Group 30548'>
							<path
								data-name='Path 14758'
								d='M666 279.516a.923.923 0 1 1-.923-.923.923.923 0 0 1 .923.923z'
								transform='translate(-652.075 -274.576)'
								fill='url(#ci314q84lc)'
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export const AudioPlay = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<g style={{ opacity: 0.6 }}>
				<g data-name='Group 31157'>
					<path data-name='Rectangle 15250' fill='none' d='M0 0h24v24H0z' />
				</g>
				<g data-name='Group 31158'>
					<path
						data-name='Path 14788'
						d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.011 8.011 0 0 1-8 8zm-2.5-3.5 7-4.5-7-4.5z'
					/>
				</g>
			</g>
		</svg>
	);
};

export const AudioPause = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<g data-name='Group 31107'>
				<path data-name='Rectangle 15240' fill='none' d='M0 0h24v24H0z' />
			</g>
			<g data-name='Group 31109'>
				<g data-name='Group 31108'>
					<path
						data-name='Path 14784'
						d='M9 16h2V8H9zm3-14a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.011 8.011 0 0 1-8 8zm1-4h2V8h-2z'
						fill='rgba(0,0,0,.6)'
					/>
				</g>
			</g>
		</svg>
	);
};

export const IncomingArrow = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='14'
			viewBox='0 0 14 14'>
			<path data-name='Rectangle 15377' fill='none' d='M0 0h14v14H0z' />
			<path
				data-name='Path 14964'
				d='M12.75 6.917h-1.167v3.844L4.823 4 4 4.823l6.761 6.761H6.917v1.166h5.833z'
				transform='translate(-1.667 -1.667)'
				fill='rgba(0,0,0,.6)'
			/>
		</svg>
	);
};

export const WebsiteLogo = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='27'
			height='27'
			viewBox='0 0 27 27'>
			<defs>
				<linearGradient
					id='ewz2qmau2a'
					x1='.5'
					x2='.5'
					y2='1'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stop-color='#d1a43a' />
					<stop offset='1' stop-color='#846100' />
				</linearGradient>
			</defs>
			<g data-name='Group 31337' transform='translate(2163 -610)'>
				<circle
					data-name='Ellipse 770'
					cx='12.375'
					cy='12.375'
					r='12.375'
					transform='translate(-2161.875 611.125)'
					fill='url(#ewz2qmau2a)'
				/>
				<path
					data-name='Path 14906'
					d='M0 0h22.5v22.5H0z'
					fill='none'
					transform='translate(-2160.75 612.25)'
				/>
				<path
					data-name='Path 14907'
					d='M8.176 0a8.184 8.184 0 1 0 8.193 8.184A8.18 8.18 0 0 0 8.176 0zm5.672 4.911h-2.414A12.808 12.808 0 0 0 10.3 2a6.572 6.572 0 0 1 3.548 2.911zM8.184 1.67a11.529 11.529 0 0 1 1.564 3.241H6.621A11.529 11.529 0 0 1 8.184 1.67zM1.85 9.821a6.746 6.746 0 0 1-.213-1.637 6.746 6.746 0 0 1 .213-1.636h2.766A13.517 13.517 0 0 0 4.5 8.184a13.517 13.517 0 0 0 .115 1.637zm.671 1.637h2.414a12.808 12.808 0 0 0 1.129 2.914 6.537 6.537 0 0 1-3.544-2.914zm2.414-6.547H2.521A6.537 6.537 0 0 1 6.065 2a12.808 12.808 0 0 0-1.13 2.911zM8.184 14.7a11.529 11.529 0 0 1-1.563-3.241h3.127A11.529 11.529 0 0 1 8.184 14.7zM10.1 9.821H6.269a12.042 12.042 0 0 1-.131-1.637 11.937 11.937 0 0 1 .131-1.637H10.1a11.937 11.937 0 0 1 .131 1.637 12.041 12.041 0 0 1-.131 1.637zm.2 4.551a12.808 12.808 0 0 0 1.129-2.914h2.414a6.572 6.572 0 0 1-3.543 2.914zm1.449-4.551a13.518 13.518 0 0 0 .115-1.637 13.518 13.518 0 0 0-.115-1.637h2.766a6.746 6.746 0 0 1 .213 1.637 6.746 6.746 0 0 1-.213 1.637z'
					transform='translate(-2157.684 615.316)'
					fill='#fff'
				/>
			</g>
		</svg>
	);
};

export const WebLink = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='27'
			height='27'
			viewBox='0 0 27 27'>
			<defs>
				<linearGradient
					id='gmhzwkliaa'
					x1='.5'
					x2='.5'
					y2='1'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stop-color='#626bd6' />
					<stop offset='1' stop-color='#3b44c4' />
				</linearGradient>
			</defs>
			<g data-name='Group 31337' transform='translate(2163 -610)'>
				<circle
					data-name='Ellipse 770'
					cx='12.375'
					cy='12.375'
					r='12.375'
					transform='translate(-2161.875 611.125)'
					fill='url(#gmhzwkliaa)'
				/>
				<path
					data-name='Path 14906'
					d='M0 0h22.5v22.5H0z'
					fill='none'
					transform='translate(-2160.75 612.25)'
				/>
				<g data-name='Group 31338'>
					<path
						data-name='Path 14788'
						d='m241.839 36.853 2.112-2.112a3.951 3.951 0 0 0-5.588-5.588l-3.048 3.047a3.95 3.95 0 0 0 .74 6.169l1.632-1.632a1.778 1.778 0 0 1-.848-.473 1.8 1.8 0 0 1 0-2.54l3.048-3.048a1.8 1.8 0 1 1 2.54 2.54l-.9.9a4.841 4.841 0 0 1 .312 2.737z'
						transform='translate(-2386.791 588.318)'
						fill='#fff'
					/>
					<path
						data-name='Path 14789'
						d='m101.266 184.991-2.112 2.109a3.952 3.952 0 0 0 5.588 5.588l3.048-3.048a3.95 3.95 0 0 0-.74-6.169l-1.632 1.632a1.792 1.792 0 0 1 .848 3.013l-3.048 3.048a1.8 1.8 0 0 1-2.54-2.54l.9-.9a4.838 4.838 0 0 1-.307-2.741z'
						transform='translate(-2254.682 437.468)'
						fill='#fff'
					/>
				</g>
			</g>
		</svg>
	);
};
