import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import ColorInput from '../../../../Components/ColorInput';
import {
	setInputBoxBackgroundColor,
	setInputBoxTextColor,
} from '../../../../Features/ExperienceOnWeb/experienceonwebSlice';
import './InputBox.scss';
import { updateBotdetails } from '../../../../Features/configure/configureSlice';
const InputBox = () => {
	const { inputBoxBackgroundColor, inputBoxTextColor } = useSelector(
		(state: RootState) => state.experienceonweb
	);
	const dispatch = useAppDispatch();

	return (
		<div className='ff-eow-input-box'>
			<h4>InputBox</h4>
			<div className='input-box'>
				<p className='body1'>Background color</p>
				<div
					className='color-input-div'
					style={{
						backgroundColor: inputBoxBackgroundColor,
					}}>
					<ColorInput
						className='color-input'
						type='color'
						value={inputBoxBackgroundColor}
						onValueChange={(value) => {
							dispatch(setInputBoxBackgroundColor(value));
						}}
					/>
				</div>
				<p className='body1 text-color'>Text and icon color</p>
				<div
					className='color-input-div'
					style={{
						backgroundColor: inputBoxTextColor,
					}}>
					<ColorInput
						className='color-input'
						type='color'
						value={inputBoxTextColor}
						onValueChange={(value) => {
							dispatch(setInputBoxTextColor(value));
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default InputBox;
