import axios from 'axios';
import { loadState, removeState } from './utils';

let baseURL =
	window.location.origin.indexOf('localhost') > -1
		? process.env.REACT_APP_API
		: window.location.origin;

//window.location.origin;
// window.origin.indexOf('preprod') > -1
// 	? 'https://preprod.flyfish.ai'
// 	: window.origin.indexOf('prod') > -1
// 	? 'https://app.flyfish.ai'
// 	: window.location.origin;

//process.env.REACT_APP_API;
// let baseURL = "https://flyfishuat.senseforth.com/";
// let baseURL = 'https://dev.flyfish.ai/';
// multipart/form-data; boundary=----WebKitFormBoundaryLHzdhn5j5iAJzxbI

const DataFetchAPI = (
	withBasicToken: boolean = false,
	withToken: boolean = true,
	tokenType: string = 'Bearer',
	contentType = 'application/json',
	withCRM: boolean = false,
	XTenantId: boolean = true
) => {
	let headers: any = {
		'Content-Type': contentType,
	};
	const token = withCRM
		? loadState('crmBasictoken')
		: withBasicToken
		? loadState('basictoken')
		: loadState('token');
	const tenantId = loadState('tenantId');
	const helpbotTenantIdNum =
		window.location.origin.lastIndexOf('uat.flyfish.ai') > -1 ? 1 : 91;
	const helpbotTenantId =
		loadState('helpBotEnable') === 'Enabled' ? true : false;
	if (helpbotTenantId) {
		headers = {
			Authorization: `${tokenType} ${token}`,
			'Content-Type': contentType,
			'X-Tenant-Id': helpbotTenantIdNum,
		};
	} else {
		if (!withBasicToken && !withToken && !tenantId) {
		} else if (tenantId) {
			if (!withBasicToken && !withToken && !XTenantId) {
				headers = {
					'Content-Type': contentType,
				};
			} else if (!withBasicToken && !withToken) {
				headers = {
					'Content-Type': contentType,
					'X-Tenant-Id': tenantId,
				};
			} else {
				headers = {
					Authorization: `${tokenType} ${token}`,
					'Content-Type': contentType,
					'X-Tenant-Id': tenantId,
				};
			}
		} else if (!withBasicToken && !withToken && tokenType === '') {
			headers = {
				'Content-Type': contentType,
			};
		} else if (token) {
			headers = {
				Authorization: `${tokenType} ${token}`,
				'Content-Type': contentType,
			};
		} else {
			headers = {
				Authorization: `Basic ${loadState('basictoken')}`,
				'Content-Type': contentType,
			};
		}
	}
	// if (withToken && token) {
	// 	headers = {
	// 		Authorization: `Basic ${loadState('basictoken')}`,
	// 		'Content-Type': contentType,
	// 	};
	// }
	const axiosInstance = axios.create({
		baseURL: baseURL,
		// withCredentials: true,
		headers,
	});
	return axiosInstance;
};

export default DataFetchAPI;
