import './CatalogDescription.scss';
import { getCurrencySymbol, loadState } from '../../../../utils/utils';
import ShopifySales from '../../../../assets/images/shopifysales.png';
import { useLocation, useNavigate } from 'react-router-dom';
import Cube from '../../../../assets/images/products-placeholder/cube.png';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import {
	getProductDetailsCS,
	setdescriptionProductLoader,
} from '../../../../Features/SalesAI/salesSlice';
import PageLoader from '../../../../Components/PageLoader';
import Button from '../../../../Components/Button';
import { FFStoreIcon } from '../../../../assets/Icons/FlyfishStore';
import woocomerce from '../../../../assets/images/woocommerce.png';
import { IngestBackIcon } from '../../../../assets/Icons';
import { useEffect } from 'react';
const CatalogDescription = () => {
	const storeName = loadState('storeName') ?? 'Your Store';
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const state = location.state as { productID: string };
	const { descriptionProductLoader, specificProduct } = useSelector(
		(state: RootState) => state.sales
	);
	const { testAndOpenBot } = useSelector((state: RootState) => state.builder);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	useEffect(() => {
		dispatch(
			getProductDetailsCS({
				productId: `${state.productID}`,
				tenantId: tenantId!,
			})
		);
	}, []);
	return (
		<div
			className={`ff-sales-ai-catalog-description ${
				testAndOpenBot ? 'open' : ''
			}`}>
			<header
				className={`catalog-description-header ${
					testAndOpenBot ? 'open' : ''
				}`}>
				<Button
					buttonType='light'
					startIcon={<IngestBackIcon width='20' height='20' />}
					onClick={() => {
						dispatch(setdescriptionProductLoader(false));
						navigate(-1);
					}}>
					Back
				</Button>
				<div className='shopify-desc-header'>
					{loadState('ecomType') === 'shopify' ? (
						<img src={ShopifySales} alt='' />
					) : loadState('ecomType') === 'FFStore' ? (
						<FFStoreIcon />
					) : loadState('ecomType') === 'woocommerce' ? (
						<img src={woocomerce} alt='' />
					) : null}
					<h5>{storeName}</h5>
				</div>
			</header>
			<>
				{descriptionProductLoader ? (
					<div className='description-loader'>
						<PageLoader message='Fetching product details...' />
					</div>
				) : (
					<div className='catalog-product-description'>
						<div className='catalog-left-wrapper'>
							<span className='product-image-container'>
								{
									<img
										src={
											specificProduct.data &&
											specificProduct.data.variants[0].image
												? specificProduct.data.variants[0].image
												: Cube
										}
										alt=''
										className='product-image'
									/>
									// )
								}
							</span>
							<span className='variants-button'></span>
						</div>
						<div className='catalog-right-wrapper'>
							<span className='Product-title'>
								<h5>{specificProduct.data && specificProduct.data.title}</h5>
							</span>
							<h6 className='product-price'>
								{specificProduct.data &&
									specificProduct.data.variants &&
									specificProduct.data.variants.length &&
									specificProduct.data.variants[0].price !== '0' &&
									`${getCurrencySymbol(
										specificProduct.data.variants[0].currency
									)} ${specificProduct.data.variants[0].price}`}
							</h6>
							{
								<div
									className='product-description body1'
									dangerouslySetInnerHTML={{
										__html:
											specificProduct.data && specificProduct.data.description,
									}}></div>
							}
						</div>
					</div>
				)}
			</>
		</div>
	);
};

export default CatalogDescription;
