import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	getDoc,
	removeDoc,
	uploadDoc,
	ingestURL,
	FlyfishStoreReq,
	WooComStoreLinkReq,
	ImportShop_SHOPIFY,
	SHOPIFY_SYNC,
	editDoc,
} from './Model';
import IngestService from './IngestService';

type State = {
	file: any;
	fileList: any;
	newUpload: any;
	isDocRemoved: boolean;
	uploadedFiles: any;
	selectedremoveEcom: any;
	selectedremoveFile: any;
	uploadFileApiStatus: string;
	removeFileApiStatus: string;
	uploadFileMessage: any;
	uploadFileFlyfishStore: boolean;
	ffstoreLinkStatus: string;
	storeMessage: string;
	sampleFileURLForFFSTore: string;
	wooCommstoreLinkStatus: string;
	wooCommStoreMessage: string;
	wooCommRedirectURL: string;
	shopSyncAPIStatus: string;
	shopImportShopifyAPIStatus: string;
	getFileListApiStatus: string;
	shopImportStatus: string;
	fileDetails: any;
	editAPIStatusMsg: any;
	editAPIStatus: any;
	editTitle: string;
	editDescription: string;
};

const initialState: State = {
	file: '',
	fileList: [],
	newUpload: [],
	isDocRemoved: false,
	uploadedFiles: [],
	selectedremoveEcom: [],
	selectedremoveFile: [],
	uploadFileApiStatus: '',
	removeFileApiStatus: '',
	uploadFileMessage: '',
	uploadFileFlyfishStore: false,
	ffstoreLinkStatus: '',
	storeMessage: '',
	sampleFileURLForFFSTore: '',
	wooCommstoreLinkStatus: '',
	wooCommStoreMessage: '',
	wooCommRedirectURL: '',
	shopSyncAPIStatus: '',
	shopImportShopifyAPIStatus: '',
	getFileListApiStatus: '',
	shopImportStatus: '',
	fileDetails: '',
	editAPIStatus: '',
	editAPIStatusMsg: '',
	editTitle: '',
	editDescription: ''
};

export const getFileList = createAsyncThunk<
	any,
	getDoc,
	{ rejectValue: string }
>('/configuration/ingest', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await IngestService.getFile(data);
		if (response.status === 202 || response.status === 200) {
			return response.data;
		}

		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		const response = (error as any)?.response;
		console.log('Network error');
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const removeFileId = createAsyncThunk<
	any,
	removeDoc,
	{ rejectValue: string }
>(
	'/configuration/ingest/remove',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await IngestService.removeFile(data);

			if (response) {
				return response.data;
			}
			return rejectWithValue(
				JSON.stringify({ error: 'Something went wrong! Try again later' })
			);
		} catch (error) {
			const response = (error as any)?.response;
			console.log('Network error');
			return rejectWithValue(JSON.stringify({ error: 'Network error' }));
		}
	}
);

export const uploadFile = createAsyncThunk<
	any,
	uploadDoc,
	{ rejectValue: string }
>('/ingest', async (data, { rejectWithValue, dispatch, getState }) => {
	try {
		const upload: any = getState();

		const response = await IngestService.uploadFile(data);
		if (response.status === 202 || response.status === 200) {
			if (
				upload.ingest.uploadedFiles.length == 0 ||
				(upload.ingest.uploadedFiles.fileUpload &&
					upload.ingest.uploadedFiles.fileUpload.length == 0 &&
					upload.ingest.uploadedFiles.ecom &&
					upload.ingest.uploadedFiles.ecom.length == 0)
			) {
				dispatch(setUploadedFiles(response.data));
			} else dispatch(setNewUpload(response.data));

			return response.data;
		}

		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		const response = (error as any)?.response;
		return rejectWithValue(response.data.message);
	}
});
export const ingestLink = createAsyncThunk<
	any,
	ingestURL,
	{ rejectValue: string }
>('/ingest/link', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await IngestService.ingestLink(data);
		return rejectWithValue(
			JSON.stringify({ error: 'Something went wrong! Try again later' })
		);
	} catch (error) {
		const response = (error as any)?.response;
		console.log('Network error');
		return rejectWithValue(JSON.stringify({ error: 'Network error' }));
	}
});

export const editPDFAPI = createAsyncThunk<any, editDoc, { rejectValue: string }>(
	'/editPDF',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await IngestService.editPDF(data);
			if (response.data) {
				return response.data;
			}
		} catch (error) {
			const response = (error as any)?.response;

			return rejectWithValue(response.data.message);

		}
	}
);

export const flyfishStoreLink = createAsyncThunk<
	any,
	FlyfishStoreReq,
	{ rejectValue: string }
>('/flyfishStoreLink', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await IngestService.flyfishStoreParse(data);
		if (response.data) {
			return response.data;
		}
	} catch (error) {
		const response = (error as any)?.response;
		return rejectWithValue(response.data.message);
	}
});

export const downLoadFlyfishStoreExcelFormat = createAsyncThunk<
	any,
	any,
	{ rejectValue: string }
>(
	'/downLoadFlyfishStoreExcelFormat',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await IngestService.downloadStoreFormat(data);
			if (response.data) {
				const url = window.URL.createObjectURL(
					new Blob([response.data], {
						type: 'application/octetstream',
					})
				);
				return url;
			}
		} catch (error) {
			const response = (error as any)?.response;
			return rejectWithValue(JSON.stringify({ error: 'Network error' }));
		}
	}
);

export const wooCommerceStoreLink = createAsyncThunk<
	any,
	WooComStoreLinkReq,
	{ rejectValue: string }
>('/wooCommStoreLink', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await IngestService.wooCommStoreLink(data);
		if (response.data) {
			return response.data;
		}
	} catch (error) {
		const response = (error as any)?.response;
		return rejectWithValue(response.data.message);
	}
});

export const shopImport_Shopify = createAsyncThunk<
	any,
	ImportShop_SHOPIFY,
	{ rejectValue: string }
>('/shopImport_Shopify', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await IngestService.shopImport_Shopify(data);
		if (response.data) {
			return response.data;
		}
		return rejectWithValue(JSON.stringify({ error: 'Something went wrong' }));
	} catch (error) {
		const response = (error as any)?.response;
		return rejectWithValue(response.data.message);
	}
});

export const shopSync = createAsyncThunk<
	any,
	SHOPIFY_SYNC,
	{ rejectValue: string }
>('/shopSync', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await IngestService.shopSync(data);
		if (response.data.status.toLowerCase() === 'success') {
			return response.data;
		}
		return rejectWithValue(JSON.stringify({ error: 'Something went wrong' }));
	} catch (error) {
		const response = (error as any)?.response;
		return rejectWithValue(response.data.message);
	}
});

export const IngestSlice = createSlice({
	name: 'ingest',
	initialState,
	reducers: {
		reset: (state) => { },
		setFile: (state, action) => {
			state.file = action.payload;
		},
		getFile: (state, action) => {
			state.fileList = action.payload;
		},
		setFileDetails: (state, action) => {
			state.fileDetails = action.payload;
		},
		setuploadFileApiStatus: (state, action) => {
			state.uploadFileApiStatus = action.payload;
		},
		setEditAPIStatus: (state, action) => {
			state.editAPIStatus = action.payload;
		},
		setNewUpload: (state, action) => {
			state.newUpload = action.payload;
		},
		setEditTitle: (state, action) => {
			state.editTitle = action.payload;
		},
		setEditDescription: (state, action) => {
			state.editDescription = action.payload;
		},
		setRemoveDoc: (state, action) => {
			state.isDocRemoved = action.payload;
		},
		setUploadedFiles: (state, action) => {
			state.uploadedFiles = action.payload;
		},
		setSelectedremoveFile: (state, action) => {
			state.selectedremoveFile = action.payload;
		},
		setSelectedremoveEcom: (state, action) => {
			state.selectedremoveEcom = action.payload;
		},
		resetUploadFileStatus: (state) => {
			state.uploadFileApiStatus = '';
		},
		resetRemoveFileStatus: (state) => {
			state.removeFileApiStatus = '';
		},
		resetUploadFileMessage: (state) => {
			state.uploadFileMessage = '';
		},
		resetUploadFileFlyfishStore: (state) => {
			state.uploadFileFlyfishStore = false;
		},
		resetFFstoreLinkStatus: (state) => {
			state.ffstoreLinkStatus = '';
		},
		resetStoreMessage: (state) => {
			state.storeMessage = '';
		},
		resetSampleURLFFStore: (state) => {
			state.sampleFileURLForFFSTore = '';
		},
		resetWooCommstoreLinkStatus: (state) => {
			state.wooCommstoreLinkStatus = '';
		},
		resetWooCommStoreMessage: (state) => {
			state.wooCommStoreMessage = '';
		},
		resetWooCommRedirectLink: (state) => {
			state.wooCommRedirectURL = '';
		},
		resetShopSyncAPIStatus: (state) => {
			state.shopSyncAPIStatus = '';
		},
		resetShopImportShopifyAPIStatus: (state) => {
			state.shopImportShopifyAPIStatus = '';
		},
		resetGetFileAPIStatus: (state) => {
			state.getFileListApiStatus = '';
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getFileList.pending, (state, action) => {
				state.getFileListApiStatus = 'loading';
			})
			.addCase(getFileList.fulfilled, (state, action) => {
				state.uploadedFiles = action.payload;
				state.getFileListApiStatus = 'success';
			})
			.addCase(getFileList.rejected, (state, action) => {
				state.getFileListApiStatus = 'failed';
			})
			.addCase(uploadFile.pending, (state) => {
				state.uploadFileApiStatus = 'loading';
				state.uploadFileMessage = '';
			})
			.addCase(uploadFile.fulfilled, (state, action) => {
				state.uploadedFiles = action.payload;
				state.uploadFileApiStatus = 'success';
			})
			.addCase(uploadFile.rejected, (state, action) => {
				state.uploadFileApiStatus = 'failed';
				state.uploadFileMessage = action.payload;
			})
			.addCase(editPDFAPI.pending, (state, action) => {
				state.editAPIStatus = 'loading';
			})
			.addCase(editPDFAPI.fulfilled, (state, action) => {
				state.fileDetails = { file: action.payload };
				state.editAPIStatus = 'success';
			})
			.addCase(editPDFAPI.rejected, (state, action) => {
				state.editAPIStatus = 'failed';
				state.editAPIStatusMsg = action.payload
			})
			.addCase(removeFileId.pending, (state) => {
				state.removeFileApiStatus = 'loading';
			})
			.addCase(removeFileId.fulfilled, (state) => {
				state.removeFileApiStatus = 'success';
			})
			.addCase(removeFileId.rejected, (state) => {
				state.removeFileApiStatus = 'failed';
			})
			.addCase(flyfishStoreLink.pending, (state) => {
				state.ffstoreLinkStatus = 'loading';
				state.uploadFileFlyfishStore = true;
			})
			.addCase(flyfishStoreLink.fulfilled, (state, action) => {
				state.ffstoreLinkStatus = 'success';
				if (action.payload.status === 'success') {
					state.uploadFileFlyfishStore = false;
				} else {
					state.uploadFileFlyfishStore = false;
					state.ffstoreLinkStatus = 'failed';
					state.storeMessage = action.payload.message;
				}
			})
			.addCase(flyfishStoreLink.rejected, (state, action) => {
				state.ffstoreLinkStatus = 'failed';
				state.uploadFileFlyfishStore = false;
				if (action.payload) {
					state.storeMessage = action.payload;
				} else {
					state.storeMessage = 'Something Went Wrong !!!';
				}
			})
			.addCase(downLoadFlyfishStoreExcelFormat.pending, (state) => {
				state.sampleFileURLForFFSTore = 'loading';
			})
			.addCase(downLoadFlyfishStoreExcelFormat.fulfilled, (state, action) => {
				state.sampleFileURLForFFSTore = action.payload;
			})
			.addCase(downLoadFlyfishStoreExcelFormat.rejected, (state, action) => {
				state.sampleFileURLForFFSTore = 'failed';
			})
			.addCase(wooCommerceStoreLink.pending, (state) => {
				state.wooCommstoreLinkStatus = 'loading';
			})
			.addCase(wooCommerceStoreLink.fulfilled, (state, action) => {
				state.wooCommstoreLinkStatus = 'success';
				if (action.payload) {
					state.wooCommRedirectURL = action.payload.data.url;
				}
			})
			.addCase(wooCommerceStoreLink.rejected, (state, action) => {
				state.wooCommstoreLinkStatus = 'failed';

				if (action.payload) {
					state.wooCommStoreMessage = action.payload;
				} else {
					state.wooCommStoreMessage = 'Something Went Wrong !!!';
				}
			})
			.addCase(shopSync.pending, (state) => {
				state.shopSyncAPIStatus = 'loading';
			})
			.addCase(shopSync.fulfilled, (state) => {
				state.shopSyncAPIStatus = 'success';
			})
			.addCase(shopSync.rejected, (state) => {
				state.shopSyncAPIStatus = 'failed';
			})
			.addCase(shopImport_Shopify.pending, (state) => {
				state.shopImportShopifyAPIStatus = 'loading';
			})
			.addCase(shopImport_Shopify.fulfilled, (state, action) => {
				state.shopImportShopifyAPIStatus = 'success';
				state.shopImportStatus = action.payload.status;
			})
			.addCase(shopImport_Shopify.rejected, (state) => {
				state.shopImportShopifyAPIStatus = 'failed';
			});
	},
});

export const {
	setFile,
	getFile,
	setNewUpload,
	setUploadedFiles,
	setSelectedremoveFile,
	setSelectedremoveEcom,
	resetUploadFileStatus,
	setuploadFileApiStatus,
	resetRemoveFileStatus,
	resetUploadFileFlyfishStore,
	resetFFstoreLinkStatus,
	resetStoreMessage,
	resetSampleURLFFStore,
	resetWooCommstoreLinkStatus,
	resetWooCommStoreMessage,
	resetWooCommRedirectLink,
	resetShopSyncAPIStatus,
	resetShopImportShopifyAPIStatus,
	resetGetFileAPIStatus,
	setFileDetails,
	setEditAPIStatus,
	resetUploadFileMessage,
	setEditTitle,
	setEditDescription
} = IngestSlice.actions;

export default IngestSlice.reducer;
