import React from 'react';
import Modal from '../../../../../Components/Modal';
import Checkbox from '../../../../../Components/Checkbox';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../app/store';
import {
	setModifyLeadScope,
	setModifyTicketingScope,
} from '../../../../../Features/Zoho/zohoSlice';
import Button from '../../../../../Components/Button';
import { useNavigate } from 'react-router-dom';

const ModifyZohoScope = () => {
	const { modifyLeadScope, modifyTicketingScope } = useSelector(
		(state: RootState) => state.zoho
	);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const actionButtons = () => {
		return (
			<>
				<Button buttonType='light' onClick={() => navigate(-1)}>
					Cancel
				</Button>
				<Button>Update Permissions</Button>
			</>
		);
	};
	return (
		<div className='ff-modify-zoho-scope'>
			<Modal header='Modify Permission Scope' actionButtons={actionButtons()}>
				<div>
					<p className='body1'>
						Please select which permissions do you wish to give to Flyfish for
						your Zoho account:
					</p>

					<div style={{ marginTop: '24px' }}>
						<Checkbox
							label='Lead Creation'
							isChecked={modifyLeadScope}
							index={0}
							onValueChange={(value) => dispatch(setModifyLeadScope(value))}
						/>
					</div>
					<div style={{ marginTop: '16px' }}>
						<Checkbox
							label='Ticketing'
							isChecked={modifyTicketingScope}
							index={0}
							onValueChange={(value) =>
								dispatch(setModifyTicketingScope(value))
							}
						/>
					</div>
					<p className='body1' style={{ marginTop: '24px' }}>
						Once you click on 'Done', you will be taken to Zoho to confirm this
						change.
					</p>
				</div>
			</Modal>
		</div>
	);
};

export default ModifyZohoScope;
