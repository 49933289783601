import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../app/store';
import Button from '../../../Components/Button';
import Modal from '../../../Components/Modal';
import {
	linkInsta,
	linkWaba,
	unlinkWaba,
	wabaInsta,
} from '../../../Features/WhatsApp-BSP/auth/authSlice';

interface Props {
	channel: wabaInsta;
	setUnlinkAd: (arg: boolean) => void;
}

const UnlinkAd = ({ setUnlinkAd, channel }: Props) => {
	const { activeBot } = useSelector((state: RootState) => state.bots);

	const dispatch = useAppDispatch();
	const location = useLocation();
	const path = location.pathname;
	const salesaiPath = path.indexOf('sales-ai') > -1;
	const [botName, setBotName] = useState('');
	useEffect(() => {
		setBotName(channel.botName);
	}, [channel]);
	const handleLink = () => {
		if (channel?.wabaNo) {
			dispatch(
				linkWaba({
					wabaNo: channel?.wabaNo ?? '',
					botCategory: activeBot.cavSRType,
					botEnv: 'prod',
					botId: channel.botId,
					botName: '',
					botIcon: '',
					botCoverImage: '',
					linkedBotName: botName,
				})
			);
		} else {
			dispatch(
				linkInsta({
					pageId: channel?.pageId ?? '',
					botCategory: activeBot.cavSRType,
					botEnv: 'prod',
					botId: activeBot.botId as number,
					botName: '',
					botIcon: '',
					botCoverImage: '',
					linkedBotName: botName,
				})
			);
		}

		setUnlinkAd(false);
	};
	return (
		<Modal
			header={`Unlink ${path.indexOf('intelligent-ad') > -1
					? 'Ad'
					: salesaiPath
						? 'Sales AI'
						: 'Storefront'
				} from ${channel?.wabaNo ? '+' + channel.wabaNo : channel.pageName}?`}
			actionButtons={
				<>
					<Button buttonType='light' onClick={() => setUnlinkAd(false)}>
						Cancel
					</Button>
					<Button onClick={handleLink}>
						Unlink{' '}
						{path.indexOf('intelligent-ad') > -1
							? 'Ad'
							: salesaiPath
								? 'Sales AI'
								: 'Storefront'}
					</Button>
				</>
			}
			onClose={() => setUnlinkAd(false)}>
			<div className='modal-body'>
				<p className='body1'>
					Please confirm, do you wish to unlink{' '}
					{salesaiPath ? 'Sales AI' : activeBot.botName} from the
					{channel?.wabaNo
						? ` WhatsApp number ${channel.wabaNo}?`
						: ` Instagram account ${channel.instaUsername}?`}
				</p>
				<p className='body1'>
					The{' '}
					{path.indexOf('intelligent-ad') > -1
						? 'Ad'
						: salesaiPath
							? 'Sales AI'
							: 'Storefront'}{' '}
					will no longer work on this {channel?.wabaNo ? 'number' : 'account'}{' '}
					but people will still be able to send messages to your business on it.
				</p>
			</div>
		</Modal>
	);
};

export default UnlinkAd;
