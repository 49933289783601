import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import { setInputFields } from '../../../../Features/configure/configureSlice';
import { ConfigureDelete, ConfigurePlus } from '../../../../assets/Icons';
import './QuickLinks.scss';

const QuickLinks = () => {
	const { inputFieldsData } = useSelector(
		(state: RootState) => state.configure
	);
	const dispatch = useAppDispatch();

	const handleAddText = () => {
		let data = [...inputFieldsData, '', ''];
		dispatch(setInputFields(data));
	};

	const removeAddText = (index: number) => {
		let data = inputFieldsData.filter((res, i) => index !== i);
		let data1 = data.filter((res, i) => index - 1 !== i);
		dispatch(setInputFields(data1));
	};

	const handleResponseChange = (index: number, txt: string) => {
		let new_response = inputFieldsData.map((value, i) =>
			index !== i ? value : txt
		);

		dispatch(setInputFields(new_response));
	};
	const placeholder = 'Enter Here...';

	return (
		<div className='ff-quicklinks'>
			<div className='quicklinks-container'>
				<span className='quicklinks-header'>
					<span>Text to display</span>
				</span>
				<span className='quicklinks-header'>
					<span>Text to submit</span>
				</span>
				<span className='quicklinks-header'>
					<span></span>
				</span>
				{inputFieldsData.map((data, index) => {
					return (
						<>
							<span className='quicklinks-input-container'>
								<Input
									type='text'
									onChange={(event) =>
										handleResponseChange(index, event.target.value)
									}
									value={data}
									placeholder={placeholder}
									required
									autoFocus
								/>
							</span>
							{index % 2 !== 0 ? (
								<div
									className='svg-container'
									onClick={() => removeAddText(index)}>
									<ConfigureDelete />
								</div>
							) : null}
						</>
					);
				})}
			</div>

			<Button startIcon={<ConfigurePlus />} onClick={handleAddText}>
				Add quick link
			</Button>
		</div>
	);
};

export default QuickLinks;
