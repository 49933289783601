import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../app/store';
import Button from '../../Components/Button';
import ChannelsCard from '../../Components/ChannelsCard';
import Chip from '../../Components/Chip';

import Menu, { Options } from '../../Components/Menu';
import {
	getOriginData,
	getVersionDetails,
} from '../../Features/channel/channelSlice';
import { Channel } from '../../Features/channel/modal';
import {
	getRandomNumber,
	kotakTenantCheckForWhatsapp,
	loadState,
	saveState,
} from '../../utils/utils';

import RemoveWebsite from './RemoveWebsite';
import GetLink from './GetLink';
import './Launch.scss';
import LinkAd from './LinkAd/LinkAd';
import UnlinkAd from './UnlinkAd/UnlinkAd';
import {
	getWabaNos,
	getWabaNosKotak,
	resetCreateInstaWatsapp,
	resetWabaStatus,
	setWabaNo,
	wabaInsta,
	deactiveWaba
} from '../../Features/WhatsApp-BSP/auth/authSlice';
import { Active } from '../ComponentsLibrary/Icons';
import SetUpNewChannelModal from '../FF-Beta/BetaChannels/SetUpNewChannelModal';
import { InstaAccountAlreadyAdded } from '../FF-Beta/BetaChannels';
import CreateInstaFailed from '../FF-Beta/BetaChannels/CreateInsta/CreateInstaFailed';
import CreateInsta from '../FF-Beta/BetaChannels/CreateInsta';

import { Pause } from '../FF-Beta/BetaChannels/Pause';
import { Deactivate } from '../FF-Beta/BetaChannels/Deactivate';
import { ChatLink } from '../FF-Beta/BetaChannels/ChatLink';
import { set, setSnackModal } from '../../Features/message/messageSlice';
import {
	deleteVersion,
	deployNewVersion,
} from '../../Features/deploy/deploySlice';
import { resetTrainBotResponse } from '../../Features/builder/builderSlice';

import {
	generateEmbeddings,
	setPublishModal,
} from '../../Features/Launch/launchSlice';
import Modal from '../../Components/Modal';
import ChatBot from '../BotBuilder/ChatBot';
import { getUnreadNotifications } from '../../Features/Notifications/notificationSlice';
type Data = {
	versionName: string;
	versionId: number;
	isDeployed: boolean;
	isDeleted: boolean;
	createOn: string;
	deleteOn: null;
	description: string;
	updateOn: string;
};
const Launch = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const path = location.pathname;

	const { bot_AI_name, bot_AI_ID } = useSelector(
		(state: RootState) => state.teams
	);

	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { embeddingLoader } = useSelector((state: RootState) => state.launch);
	const name = bot_AI_name ?? '';

	const { openModalForLaunch, websiteOriginList } = useSelector(
		(state: RootState) => state.channel
	);
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<Array<Options>>([]);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const [botActive, setBotActive] = useState(false);
	const [active, setActive] = useState<
		string | Channel | wabaInsta | undefined
	>(undefined);
	const [active2, setActive2] = useState<wabaInsta | undefined>(undefined);
	const [openLink, setOpenLink] = useState(false);
	const [addWebsite, setAddWebsite] = useState(false);
	const [removeWebsite, setRemoveWebsite] = useState(false);
	const [linkAd, setLinkAd] = useState(false);
	const [unlinkAd, setUnlinkAd] = useState(false);
	const [chatLink, setChatLink] = useState(false);
	const [existingAccount, setExistingAccount] = useState<boolean>(false);
	const [createInsta, setCreateInsta] = useState(false);
	const [createInstaFailed, setCreateInstaFailed] = useState<boolean>(false);
	const [pauseModal, setPauseModal] = useState<boolean>(false);
	const [deactivateModal, setDeactivateModal] = useState<boolean>(false);
	const [isButtonClicked, setIsButtonClicked] = useState(false);
	const [onHover, setHover] = useState(-1);
	const [onHoverWhatsapp, setHoverWhatsapp] = useState(-1);
	const {
		instaIds,
		setWabaStatus,
		updatedStatus,
		instaPagesRetrived,
		watsappIdRetrieved,
		instaPages,
		wabaNos,
		updatednumbers,
		selectWabaId
	} = useSelector((state: RootState) => state.BSPauth);

	const { deployedVersionsList } = useSelector(
		(state: RootState) => state.deploy
	);
	const [deployed, setDeployed] = useState(false);
	const [deployCount, setDeployCount] = useState(0);
	const [currentlyDeployed, setCurrentlyDeployed] = useState<Data>();
	const [otherVersions, setOtherVersions] = useState<Data>();
	const { trainingResponse } = useSelector((state: RootState) => state.builder);
	const { isVersionDeleted } = useSelector((state: RootState) => state.deploy);
	const { publishModal } = useSelector((state: RootState) => state.launch);
	const pathSplit = location.pathname;
	let pathArr = pathSplit.split('/');

	const channelCards = [
		'Website',
		'Whatsapp',
		'Instagram',
		'Google Business Messages',
		'Facebook Messenger',
		'Apple Business Chat',
		'TikTok',
		'Telegram',
		'Line Messenger',
	];

	useEffect(() => {
		kotakTenantCheckForWhatsapp(`${tenantId}`)
			? dispatch(getWabaNosKotak({ tenantId: tenantId }))
			: dispatch(getWabaNos({ tenantId: tenantId }));
	}, [updatedStatus]);

	useEffect(() => {
		if (publishModal.toLowerCase() === 'success') {
			//do nothing
		}
		if (publishModal.toLowerCase() === 'failed') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage:
						'We are unable to Publish the changes at the Moment. Please try after sometime',
					modalMode: 'negative',
				})
			);
		}
		dispatch(setPublishModal(''));
	}, [publishModal]);

	useEffect(() => {
		if (window.location.href.indexOf('/app/launch') > -1) {
			setBotActive(true);
		} else {
			setBotActive(false);
		}
		dispatch(getOriginData({ tenantId: tenantId! }));
		//for platform notification
		dispatch(
			getUnreadNotifications({
				userId: loadState('user').userID,
				tenantId: tenantId!,
			})
		);

		return () => {
			if (window.location.href.indexOf('/app/launch') > -1) {
				setBotActive(true);
			} else {
				setBotActive(false);
			}
		};
	}, []);

	const getOptions = (originId: number, origin: string) => {
		// switch (channel.type) {
		// case 'web':
		setOptions([
			{
				label: (
					<p className='menu-label'>
						Remove Website {/*{type === 'CA' ? 'the Ad' : 'Storefront'}*/}
					</p>
				),
				onClick: () => {
					// setActive(channel);
					// setRemoveWebsite(true);
					navigate('remove-ai', {
						state: { originId: originId, origin: origin },
					});
					setOpen(false);
				},
			},
		]);
		// break;
		// }
	};

	const getOptions2 = (channel: wabaInsta) => {
		if (
			channel?.wabaStatus?.toUpperCase() !== 'PAUSE'
			// ||
			// channel.instaStatus?.toUpperCase() === 'ACTIVE'
		) {
			setOptions([
				{
					label: <p className='menu-label'>Campaign</p>,
					onClick: () => {
						dispatch(setWabaNo(channel.wabaNo));
						navigate('/app/channels/whatsapp/campaigns');
					},
				},
				{
					label: `${channel?.pageName
						? channel?.instaStatus?.toUpperCase() === 'PAUSE'
							? 'Resume'
							: 'Pause'
						: channel?.wabaStatus?.toUpperCase() === 'PAUSE'
							? 'Resume'
							: 'Pause'
						}`,
					onClick: () => {
						setOpen(false);
						setPauseModal(true);
					},
				},
				{
					label: `Remove `,
					onClick: () => {
						setOpen(false);
						setDeactivateModal(true);
					},
				},
			]);
		} else {
			setOptions([
				{
					label: `${channel?.pageName
						? channel?.instaStatus?.toUpperCase() === 'PAUSE'
							? 'Resume'
							: 'Pause'
						: channel?.wabaStatus?.toUpperCase() === 'PAUSE'
							? 'Resume'
							: 'Pause'
						}`,
					onClick: () => {
						setOpen(false);
						setPauseModal(true);
					},
				},
				{
					label: `Remove`,
					onClick: () => {
						setOpen(false);
						setDeactivateModal(true);
					},
				},
			]);
		}
	};
	useEffect(() => {
		if (setWabaStatus) navigate('/app/channels/whatsapp/templates');
		return () => {
			dispatch(resetWabaStatus());
		};
	}, [setWabaStatus]);
	useEffect(() => {
		if (instaPagesRetrived === 'success') {
			const tempInstaPages =
				instaPages &&
				instaPages.map((each) => {
					return each.id;
				});
			//logic to check , if insta account is already added.
			const existingInstaArray = [];
			for (const eachId of instaIds) {
				for (const eachPage of tempInstaPages) {
					if (eachPage === eachId.pageId) {
						existingInstaArray.push(eachPage);
					}
				}
			}
			if (Boolean(existingInstaArray.length)) {
				setExistingAccount(true);
			} else {
				setCreateInsta(true);
			}
		} else if (instaPagesRetrived === 'failed') {
			setCreateInstaFailed(true);
		}
		return () => {
			dispatch(resetCreateInstaWatsapp());
		};
	}, [instaPagesRetrived]);

	useEffect(() => {
		if (pathArr) {
			if (pathArr[2] === 'launch') {

				Boolean(updatednumbers.length) &&
					navigate('/app/launch/almost-done', {
						state: {
							value: {
								updatednumbers,
								selectWabaId,
							},
						},
					});
			} else if (pathArr[2] === 'welcome') {
				Boolean(updatednumbers.length) &&
					navigate('/app/welcome/almost-done', {
						state: {
							value: {
								updatednumbers,
								selectWabaId,
							},
						},
					});
			}
		}
		return () => {
			dispatch(resetCreateInstaWatsapp());
		};
	}, [updatednumbers]);

	useEffect(() => {
		handleVersions();
	}, [deployedVersionsList]);
	const handleVersions = () => {
		const versions = deployedVersionsList.filter(
			(each) => each.isDeleted === false
		);
		// console.log('versions', versions);
		if (versions.length === 1) {
			setCurrentlyDeployed(versions[0]);
			setDeployCount(1);
			setDeployed(true);
		}
		if (versions.length >= 2) {
			if (versions[0].isDeployed === true) {
				setCurrentlyDeployed(versions[0]);
				setOtherVersions(versions[1]);
				setDeployCount(2);
				setDeployed(true);
			} else {
				setCurrentlyDeployed(versions[1]);
				setOtherVersions(versions[0]);
				setDeployCount(2);
				setDeployed(true);
			}
		}
	};
	const [versionValue, setversionValue] = useState(1);
	const handleDeployNewVersion = () => {
		tenantId &&
			dispatch(
				deployNewVersion({
					botId:
						//activeBot
						loadState('botDetails').encBotId,
					description: 'description',
					versionName: getRandomNumber(1),
					tenantId:
						//tenantId
						loadState('tenantId').toString(),
				})
			);
		setversionValue(versionValue + 1);
	};
	useEffect(() => {
		if (trainingResponse === 'Success' && isButtonClicked) {
			dispatch(
				getVersionDetails({
					botId:
						//activeBot.encBotId
						loadState('botDetails').encBotId,
				})
			);
			setIsButtonClicked(false);
			// console.log('other versions', otherVersions);
			if (otherVersions?.isDeleted === false && deployCount > 1) {
				tenantId &&
					dispatch(
						deleteVersion({
							botId:
								//activeBot
								loadState('botDetails').encBotId,
							versionId: otherVersions.versionId,
							versionName: otherVersions.versionName,
							tenantId:
								//tenantId
								loadState('tenantId').toString(),
						})
					);
			} else {
				handleDeployNewVersion();
			}
		}
	}, [trainingResponse]);

	useEffect(() => {
		if (isVersionDeleted === 'Failed') {
			dispatch(set('Failed to publish changes. Please retry in some time'));
		}
	}, [isVersionDeleted]);

	const publishBot = () => {
		dispatch(
			generateEmbeddings({
				botId: loadState('botId'),
				data: {
					source: 'all',
					tenant_id: `${tenantId}`,
					version: 'prod',
				},
			})
		);
	};
	useEffect(() => {
		loadState('botId') &&
			loadState('tenantId') &&
			document
				.getElementById('root')
				?.setAttribute('data-botid', loadState('botId'));
		document
			.getElementById('root')
			?.setAttribute('data-tenantid', loadState('tenantId'));
	});

	useEffect(() => {
		dispatch(resetTrainBotResponse());
	}, [trainingResponse]);
	return (
		<div className='ff-launch'>
			<div className='ff-header-publish'>
				<div className='text-button-container'>
					<div className='launch-desc'>
						<h4>Launch</h4>
						<p className='body2'>
							Put your AI on different channels and bring it to your customers
						</p>
					</div>
					<Button
						buttonType='dark'
						loading={embeddingLoader === 'loading'}
						className='bot-test-btn'
						onClick={() => {
							setIsButtonClicked(true);
							publishBot();
						}}>
						Publish
					</Button>
				</div>
			</div>

			<div className='launch-screen-wrapper'>
				<header>
					<div className='faq-head-button'>
						<div className='header-content'>
							<p className='channels-heading button1'>Your Channels</p>
						</div>
					</div>
				</header>
				<div className='channels channel-card-item '>
					{bot_AI_ID && !Boolean(bot_AI_ID > 0) ? (
						<div></div>
					) : (
						<>
							<ChannelsCard
								channel={'Web Link'}
								channelName={''}
								botCoverImage={''}
								status={
									<Chip
										icon={<Active hovered={onHover === -2 ? true : false} />}
										label='Active'
									/>
								}
								statusMessage={'ACTIVE'}
								onClick={() => setOpenLink(true)}
								forLaunchScreen={true}
								botName={''}
								onHover={() => {
									setHover(-2);
								}}
								onHoverOut={() => {
									setHover(-1);
								}}
							/>
							{websiteOriginList &&
								websiteOriginList.map(
									(channel: { origin: string; id: number }, index: number) => {
										if (channel.origin === '') {
											return null;
										} else {
											return (
												<ChannelsCard
													channelName={channel.origin}
													status={
														<Chip
															icon={
																<Active
																	hovered={onHover === index ? true : false}
																/>
															}
															label='Active'
														/>
													}
													channel={'website'}
													onClick={() => {
														navigator.clipboard.writeText(channel.origin);
														dispatch(
															setSnackModal({
																openModal: true,
																modalMesssage:
																	'Website link copied to clipboard',
															})
														);
													}}
													onClickOptions={(event: any) => {
														event.stopPropagation();
														getOptions(channel.id, channel.origin);
														setAnchorEl(event.target as HTMLElement);
														setOpen(true);
													}} // set options
													statusMessage={'ACTIVE'}
													onHover={() => {
														setHover(index);
													}}
													onHoverOut={() => {
														setHover(-1);
													}}
												/>
											);
										}
									}
								)}
							{wabaNos &&
								wabaNos.map((details, index) => (
									<ChannelsCard
										key={`+${details.wabaNo}`}
										botIcon={details.botIcon ?? ''}
										botName={details.botName ?? ''}
										details={details}
										status={
											// details?.botName?.toUpperCase() === name.toUpperCase() ? (
											<Chip
												label={
													details.wabaStatus.toUpperCase()
												}
												//'Linked'
												icon={
													details.wabaStatus.toUpperCase() !== 'PAUSE' ? (
														<Active
															hovered={onHoverWhatsapp == index ? true : false}
														/>
													) : (
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='12'
															height='12'
															viewBox='0 0 12 12'>
															<g data-name='Group 34056'>
																<path
																	data-name='Rectangle 17169'
																	fill='none'
																	d='M0 0h12v12H0z'
																/>
															</g>
															<g data-name='Group 34058'>
																<g data-name='Group 34057'>
																	<path
																		data-name='Path 20009'
																		d='M6.877 2a4.877 4.877 0 1 0 4.877 4.877A4.879 4.879 0 0 0 6.877 2zM6.39 8.828h-.976v-3.9h.976zm1.951 0h-.976v-3.9h.975z'
																		transform='translate(-1.025 -1.025)'
																		fill='#c12774'
																	/>
																</g>
															</g>
														</svg>
													)
												}
											/>
											//) : ()
										}
										onHover={() => {
											setHoverWhatsapp(index);
										}}
										onHoverOut={() => {
											setHoverWhatsapp(-1);
										}}
										forLaunchScreen={true}
										statusMessage={details.wabaStatus}
										onClick={() => {
											dispatch(setWabaNo(details.wabaNo));
											navigate('/app/channels/whatsapp/templates');
										}}
										onClickOptions={(event) => {
											event.stopPropagation();
											setAnchorEl(event.target as HTMLElement);
											setActive2(details);
											getOptions2(details);
											setOpen(true);
										}}
										channel='whatsapp'
										channelName={`+${details.wabaNo}`}
										botCoverImage={details.botCoverImage}
									/>
								))}
							{instaIds &&
								instaIds.map((details) => (
									<ChannelsCard
										key={`+${details.pageName}`}
										botIcon={details.botIcon ?? ''}
										botName={details.botName ?? ''}
										statusMessage={details.instaStatus}
										status={<Chip label='Active' icon={<Active />} />}
										forLaunchScreen={true}
										onClick={() => { }}
										onClickOptions={(event) => {
											event.stopPropagation();
											setAnchorEl(event.target as HTMLElement);
											setActive2(details);
											getOptions2(details);
											setOpen(true);
										}}
										channel='instagram'
										channelName={`${details.instaUsername}`}
										botCoverImage={details.botCoverImage}
									/>
								))}
						</>
					)}
				</div>

				<header>
					<div className='faq-head-button'>
						<div className='header-content'>
							<p className='button1'>Add Channels</p>
						</div>
					</div>
				</header>
				<div className='channels add-channel channel-card-item'>
					{
						<>
							{channelCards &&
								channelCards.map((ele, i) => {
									return (
										<div
											key={ele + i}
											onClick={() => {
												if (ele === 'Website') {
													navigate('add-ai');
												}
											}}>
											<ChannelsCard
												channel={ele}
												channelName={bot_AI_name ?? ''}
												botCoverImage={''}
												status={'addchannels'}
												statusMessage={''}
												onClick={() => setOpenLink(false)}
												forLaunchScreen={false}
												botName={''}
											/>
										</div>
									);
								})}
						</>
					}
				</div>
			</div>

			{/* Modals */}
			{openLink && <GetLink onClose={() => setOpenLink(false)} />}
			{publishModal.length > 0 && publishModal.toLowerCase() === 'success' ? (
				<Modal
					headerCloseBtn
					actionButtons={false}
					onClose={() => {
						dispatch(setPublishModal(''));
					}}
					header='Publish Status'>
					<p>Published Successfully</p>
				</Modal>
			) : null}
			{removeWebsite && (
				<RemoveWebsite
					onClose={() => setRemoveWebsite(false)}
					channel={active as Channel}
				/>
			)}
			{linkAd && (
				<LinkAd setLinkAd={setLinkAd} channel={active2 as wabaInsta} />
			)}
			{unlinkAd && (
				<UnlinkAd setUnlinkAd={setUnlinkAd} channel={active2 as wabaInsta} />
			)}
			{chatLink && (
				<ChatLink Details={active2 as wabaInsta} setChat={setChatLink} />
			)}
			{pauseModal && <Pause Details={active2} setPause={setPauseModal} />}
			{deactivateModal && (
				<Deactivate Details={active2} setDeactivate={setDeactivateModal} />
			)}
			{openModalForLaunch && (
				<SetUpNewChannelModal
					setUpFromLaunchScreen='Website'
					setAddWebsite={setAddWebsite}
				/>
			)}
			{/* {addWebsite && <AddWebsite onClose={() => setAddWebsite(false)} />} */}
			{createInsta && <CreateInsta setCreateInsta={setCreateInsta} />}
			{createInstaFailed && (
				<CreateInstaFailed setCreateInstaFailed={setCreateInstaFailed} />
			)}
			{existingAccount && (
				<InstaAccountAlreadyAdded setExistingAccount={setExistingAccount} />
			)}

			<Outlet />
			<Menu
				anchorEl={anchorEl}
				open={open}
				setOpen={setOpen}
				options={options}
			/>
			{path.indexOf('/app/launch') > -1 && botActive && (
				<ChatBot
					active={botActive}
					encBotId={
						// botDetails.encBotId}
						loadState('botId')
					}
					onClose={() => {
						// dispatch(setTestAndOpenBot(false));
					}}
					tenantId={loadState('tenantId')}
				/>
			)}
		</div>
	);
};
export default Launch;
