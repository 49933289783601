type Props = {
	onClick: (e: React.MouseEvent<HTMLOrSVGElement>) => void;
};

export const NumberInputUpIcon = ({ onClick }: Props) => {
	return (
		<svg
			className='NumberInputUpIcon'
			onClick={(e: React.MouseEvent<HTMLOrSVGElement>) => onClick(e)}
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='12'
			viewBox='0 0 24 12'>
			<path data-name='Path 14754' d='M0 0h24v12H0z' fill='none' />
			<path data-name='Path 14755' d='m7 8 5-5 5 5z' />
		</svg>
	);
};

export const NumberInputDownIcon = ({ onClick }: Props) => {
	return (
		<svg
			className='NumberInputDownIcon'
			onClick={(e: React.MouseEvent<HTMLOrSVGElement>) => onClick(e)}
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='12'
			viewBox='0 0 24 12'>
			<path data-name='Path 14754' d='M0 12h24V0H0z' fill='none' />
			<path data-name='Path 14755' d='m7 4 5 5 5-5z' />
		</svg>
	);
};
