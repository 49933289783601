import '../Loader/FFLoader.scss';

type Props = {
  width?: string;
  height?: string;
  fill?: string;
};

export function UploadLoader(props: Props) {
  return (
    <svg id="group" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='loading-icon'>
      <g id="container" fill="#3b4fd0" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      </g>
      <g id="main_icon" data-name="main icon" transform="translate(2.183 2.182)">
        <path id="Subtraction_6" data-name="Subtraction 6" d="M9.818,19.636A9.818,9.818,0,0,1,9.818,0V2.182a7.637,7.637,0,1,0,7.636,7.637h2.183A9.829,9.829,0,0,1,9.818,19.636Z" fill="#5531d3" />
      </g>
    </svg>

  );
}
