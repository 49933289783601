import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../app/store';
import List, { ListItemGroup } from '../../Components/List';
import {
	getBotConfigurations,
	getWorkflowSetting,
} from '../../Features/configure/configureSlice';
import { getVertical } from '../../Features/Verticalization/verticalizationSlice';
import { loadState } from '../../utils/utils';
import './Configure.scss';
import TabGenerator from '../../Components/TabGenerator/TabGenerator';
import { TABS } from './constants';

function Configure() {
	const location = useLocation();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { botId } = useSelector((state: RootState) => state.builder);
	const botDetails: any = loadState('botDetails');
	const dispatch = useAppDispatch();
	const path = location.pathname;

	useEffect(() => {
		tenantId &&
			dispatch(getWorkflowSetting({ botId: botId, tenantId: tenantId }));
		dispatch(getBotConfigurations({ botId: botDetails.encBotId }));
		
	}, []);

	const listItems1: ListItemGroup = [
		{ text: 'General', route: 'general' },
		{ text: 'Conversational experience', route: 'conversation-experience' },
		{ text: 'Appearance', route: 'appearance' },
		{ text: 'Enhancements', route: 'config-enhancements' },
		{ text: 'Security & privacy', route: 'security-and-privacy' },
		{ text: 'Live Chat', route: 'live-chat' },
	];
	const items1: ListItemGroup[] = [listItems1];
	const { testAndOpenBot } = useSelector((state: RootState) => state.builder);
	const [selectedTab, setSelectedTab] = useState(TABS[0]);

	const onTabChangeHandler = (tabName: string) => {
		if (tabName === selectedTab) {
			return;
		}
		setSelectedTab(tabName);
	};
	const errors = {
		displayName: '',
		type: '',
	};
	const TabStyle = {
		pointerEvents: 'none',
	};
	return (
		<div className='ff-configure'>
			{/* <div
				className={`configure-container ${
					testAndOpenBot
						? path.indexOf('appearance') > -1
							? ''
							: 'configure-panel-width'
						: ''
				}`}>
				{' '}
				<div className='configure-left-navigation'>
					<div>
						<h4>Configuration</h4>
					</div>
					<div>
						<List items={items1} width={239} />
					</div>
				</div>
				<Outlet />
			</div> */}
			<div className='bot-profile-tabs'>
				<TabGenerator
					type={'vertical'}
					selectedTab={selectedTab}
					onTabChange={(tabName: string) => onTabChangeHandler(tabName)}
					component={'botprofile'}
					// icons={TABICONS}
					tabNames={TABS}
					tabPassedStyles={errors.type === 'max' ? TabStyle : null}
				/>
			</div>
		</div>
	);
}

export default Configure;
