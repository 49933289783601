import './ShopifyIngest.scss';
import shopifyIngest from '../../../../assets/images/ShopifyIngest.png';
import Input from '../../../../Components/Input';
import { LoginEyeClosed, LoginEyeOpen } from '../../../../assets/Icons';
import { useEffect, useState } from 'react';
import Button from '../../../../Components/Button';
import { RootState, useAppDispatch } from '../../../../app/store';
import { linkstore, reset } from '../../../../Features/Shopify/shopifySlice';
import { useSelector } from 'react-redux';
import { setSnackModal } from '../../../../Features/message/messageSlice';
import { useNavigate } from 'react-router-dom';
import { getUnreadNotifications } from '../../../../Features/Notifications/notificationSlice';
import { loadState } from '../../../../utils/utils';

const ShopifyIngest = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [showMoreinfobutton, setShowMoreinfobutton] = useState(false);
	const [storefrontapi, setStorefrontapi] = useState('');
	const [adminapi, setAdminapi] = useState('');
	const [storeurl, setStoreurl] = useState('');
	const [buttonEnable, setButtonEnable] = useState(false);
	const [isShopifyToken, setIsShopifyToken] = useState(false);
	const { linkShopifyLoader } = useSelector(
		(state: RootState) => state.shopify
	);

	let ecomIdnumber = 1;
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const handlestorefrontapi = (txt: any) => {
		setStorefrontapi(txt);
	};
	const handleadminapi = (txt: any) => {
		setAdminapi(txt);
	};
	const handlestoreurl = (txt: any) => {
		setStoreurl(txt);
	};

	useEffect(() => {
		if (storefrontapi && adminapi && storeurl) setButtonEnable(true);
		else setButtonEnable(false);
	}, [handlestorefrontapi, handleadminapi, handlestoreurl]);

	useEffect(() => {
		// console.log(linkShopifyLoader);
		if (linkShopifyLoader === 'failure') {
			// navigate('/app/Ingest-tab/ingested-resources');
			dispatch(
				setSnackModal({
					modalMesssage: 'Unable to link account. Please try after sometime.',
					openModal: true,
				})
			);
			dispatch(reset());
		} else if (linkShopifyLoader === 'success') {
			// navigate('link-shopify-info');
			dispatch(
				setSnackModal({
					modalMesssage: 'Shopify store linked succesfully',
					openModal: true,
				})
			);
			navigate('/app/Ingest-tab/ingested-resources');

			dispatch(reset());
		}
	}, [linkShopifyLoader]);

	useEffect(() => {
		dispatch(
			getUnreadNotifications({
				userId: loadState('user').userID,
				tenantId: tenantId!,
			})
		);
	}, []);

	return (
		<>
			<div className='shopify-ingest-container'>
				<div className='shopify-ingest-body'>
					<div className='shopify-injest-body-holder'>
						<div className='img-holder'>
							<img src={shopifyIngest} alt=''></img>
						</div>
						<div className='shopify-ingest-cnt'>
							<div>
								<p className='body1 heading'>
									Just provide the following information to link your Shopify
									account with Flyfish.
									<span
										className='click-btn'
										onClick={() => {
											setShowMoreinfobutton((current) => !current);
										}}>
										&nbsp;
										{showMoreinfobutton ? null : 'More Information'}
									</span>
								</p>
								{showMoreinfobutton ? (
									<p>
										<div className='body-moreinfo body1'>
											Get this done with the following steps that will take just
											a few minutes:
										</div>
										<div className='body-description body1'>
											<div>
												<ol>
													<li>
														In your Shopify store’s settings, create a custom
														Shopify app. This app will be used to exchange
														information between your Flyfish team and your
														Shopify Store.
													</li>
													<li>
														Under the app’s configuration, please enable all
														scopes under both Admin and Storefront API access
														scopes.
													</li>
													<li>
														Install the app. Once done, please share the access
														tokens that have been requested below.
													</li>
												</ol>
											</div>
										</div>
										<div className='body1'>
											You can find more information about creating a custom
											Shopify app
											<div className='click-btn'>
												<a
													href='https://help.shopify.com/en/manual/apps/custom-apps#:~:text=Create%20the%20app%201%20From%20your%20Shopify%20admin%2C,the%20Develop%20apps%20permission.%205%20Click%20Create%20app'
													target='blank'>
													here.
												</a>
											</div>
										</div>
									</p>
								) : null}
								<div className='input-container'>
									<Input
										value={storefrontapi}
										id='storefrontapi'
										onChange={(event) =>
											handlestorefrontapi(event.target.value)
										}
										label='Shopify Store URL'
										limit={50}
										style={{ width: '552px', height: '48px' }}
										autoComplete='off'
										autoFocus
									/>
								</div>

								<div className='input-container'>
									<Input
										id='adminapi'
										type={isShopifyToken ? 'text' : 'password'}
										value={adminapi}
										onChange={(event) => handleadminapi(event.target.value)}
										label='Custom app’s Admin API access token'
										limit={50}
										endIcon={
											<div
												className='toggle-password-show'
												onClick={() => setIsShopifyToken(!isShopifyToken)}>
												{adminapi === '' ? null : isShopifyToken ? (
													<LoginEyeClosed width='19px' />
												) : (
													<LoginEyeOpen width='19px' />
												)}
											</div>
										}
										style={{ width: '552px', height: '48px' }}
										autoComplete='off'
									/>
								</div>
								<div className='input-container'>
									<Input
										id='storeurl'
										value={storeurl}
										onChange={(event) => handlestoreurl(event.target.value)}
										label='Custom app’s Storefront API access token'
										limit={50}
										style={{ width: '552px', height: '48px' }}
										autoComplete='off'
									/>
								</div>
							</div>
						</div>
						<div className='link-button'>
							<Button
								disabled={buttonEnable ? false : true}
								loading={linkShopifyLoader === 'loading'}
								onClick={() => {
									dispatch(
										linkstore({
											ecomId: ecomIdnumber,
											ecomKey: storeurl,
											ecomAccess: adminapi,
											ecomDomain: storefrontapi,
											tenantId: tenantId!,
											embeddings: 'true',
										})
									);
								}}>
								{linkShopifyLoader === 'loading' ? 'Linking' : 'Link'}
							</Button>
						</div>
					</div>
					{/* {linkShopifyLoader === 'failure' ? ( */}
					{/* <div className='shopify-loader'>
						<PageLoader message='Linking your Shopify store with Flyfish...' />
					</div> */}
					{/* ) : null} */}
				</div>
			</div>
		</>
	);
};

export default ShopifyIngest;
