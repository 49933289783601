import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Modal from '../../../../Components/Modal';
import Radio from '../../../../Components/Radio';
import { set } from '../../../../Features/message/messageSlice';
import {
	getWabaNos,
	getWabaNosKotak,
	onBoardInsta,
	reset,
	resetOnBoardInstaStatus,
} from '../../../../Features/WhatsApp-BSP/auth/authSlice';
import { kotakTenantCheckForWhatsapp } from '../../../../utils/utils';
interface Props {
	setCreateInsta: (arg: boolean) => void;
}
interface Props2 {
	setSetUpComplete: (arg: boolean) => void;
	setCreateInsta: (arg: boolean) => void;
}
interface Props3 {
	setSetUpComplete: (arg: boolean) => void;
}
export const SetUpCompleteModal = ({
	setSetUpComplete,
	setCreateInsta,
}: Props2) => {
	const navigate = useNavigate();
	return (
		<Modal
			header='Setup Complete'
			onClose={() => {}}
			actionButtons={
				<Button
					onClick={() => {
						setSetUpComplete(false);
						setCreateInsta(false);
						navigate('/app/channels');
					}}>
					Done
				</Button>
			}>
			<div>
				<p className='body1'>
					Congratulations! You have successfully added your Instagram Account to
					Flyfish.
				</p>
				<p className='body1'>
					You can now use different Flyfish features on this account such as
					messaging and doing Intelligent ads and sales.
				</p>
			</div>
		</Modal>
	);
};

//if the user has only one business account then we are displaying confirm modal
const ConfirmModal = ({ setSetUpComplete, setCreateInsta }: Props2) => {
	const { instaPages, isLoading, accessToken, onBoardInstaStatus } =
		useSelector((state: RootState) => state.BSPauth);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		dispatch(reset());
	}, [isLoading]);

	return (
		<Modal
			header='Almost done…'
			onClose={() => {}}
			actionButtons={
				<>
					<Button
						buttonType='light'
						onClick={() => {
							setCreateInsta(false);
						}}>
						Back
					</Button>
					<Button
						loading={isLoading}
						onClick={() => {
							dispatch(
								onBoardInsta({
									pageId: instaPages[0].id,
									accessToken: accessToken,
									tenantId,
								})
							);
						}}>
						Confirm
					</Button>
				</>
			}>
			<div>
				<p className='body1'>
					One last step. Please confirm, you want to use the Facebook page{' '}
					<b>{instaPages[0].name}</b> with Flyfish?
				</p>
			</div>
		</Modal>
	);
};
const CreateInsta = ({ setCreateInsta }: Props) => {
	const [selectRadio, setSelectedRadio] = useState('');
	const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true);
	const [setUpComplete, setSetUpComplete] = useState(false);
	const { tenantId } = useSelector((state: RootState) => state.teams);

	const dispatch = useAppDispatch();
	const { instaPages, isLoading, accessToken, onBoardInstaStatus } =
		useSelector((state: RootState) => state.BSPauth);

	useEffect(() => {
		if (selectRadio) {
			setConfirmBtnDisabled(false);
		}
	}, [selectRadio]);

	useEffect(() => {
		dispatch(reset());
	}, [isLoading]);
	useEffect(() => {
		if (onBoardInstaStatus === 'success') {
			setSetUpComplete(true);
			kotakTenantCheckForWhatsapp(`${tenantId}`)
				? dispatch(getWabaNosKotak({ tenantId: tenantId }))
				: dispatch(getWabaNos({ tenantId: tenantId }));
			resetOnBoardInstaStatus();
		} else if (onBoardInstaStatus === 'failed') {
			dispatch(set('Something Went Wrong ! Please try again Later'));
			setCreateInsta(false);
		}
		return () => {
			dispatch(resetOnBoardInstaStatus());
		};
	}, [onBoardInstaStatus]);
	const handleConfirm = () => {
		dispatch(
			onBoardInsta({
				pageId: selectRadio,
				accessToken: accessToken,
				tenantId,
			})
		);
	};
	return (
		<>
			{instaPages && instaPages.length === 1 ? (
				<ConfirmModal
					setSetUpComplete={setSetUpComplete}
					setCreateInsta={setCreateInsta}
				/>
			) : (
				<Modal
					header='Almost done…'
					onClose={() => {
						// setCreateInsta(false);
					}}
					actionButtons={
						<>
							<Button onClick={() => setCreateInsta(false)}>
								<p className='button-1'>Close</p>
							</Button>
							<Button
								// loading={}
								disabled={confirmBtnDisabled}
								onClick={() => {
									handleConfirm();
								}}>
								<p className='button-1'>Confirm</p>
							</Button>
						</>
					}>
					<div className='modal-body'>
						<p className='body1'>
							One last step. Please confirm the Facebook page that you want to
							use with Flyfish:
						</p>
						{instaPages &&
							instaPages.map((each) => {
								return (
									<Radio
										label={
											<div className='label'>
												<p className='body1'>{each.name}</p>
												<p className='caption'>{each.id}</p>
											</div>
										}
										isChecked={selectRadio}
										value={each.id}
										onValueChange={(value) => setSelectedRadio(value)}
										name={''}
									/>
								);
							})}
					</div>
				</Modal>
			)}
			{setUpComplete && (
				<SetUpCompleteModal
					setSetUpComplete={setSetUpComplete}
					setCreateInsta={setCreateInsta}
				/>
			)}
		</>
	);
};
export default CreateInsta;
