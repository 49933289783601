import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import { useEffect, useRef, useState } from 'react';
import BotImage from '../../../../src/assets/images/bot-icons/botIcon6.png';
import { RootState, useAppDispatch } from '../../../app/store';
import { useSelector } from 'react-redux';
import { loadState, saveState } from '../../../utils/utils';
import TeamBadge from '../../Dashboard/Teambadge';
import { FFLoader } from '../../../assets/Icons/Loader';
import {
	setTeamsettingGeneralImgUploadSuccess,
	setInvalidOrgName,
	setInvalidTeamName,
} from '../../../Features/configure/configureSlice';
import {
	uploadImage,
	setImageLoader,
} from '../../../Features/ExperienceOnWeb/experienceonwebSlice';
import {
	setCustomtenantname,
	setcustomOrganizationName,
} from '../../../Features/teamsetting/teamsettingSlice';
import { getallUsers } from '../../../Features/users/userSlice';
import { setSnackModal } from '../../../Features/message/messageSlice';
import './TeamDetails.scss';
const TeamDetails = () => {
	const { tenantName } = useSelector((state: RootState) => state.teams);
	const { imageLoader, imageLoadFailMsg } = useSelector(
		(state: RootState) => state.experienceonweb
	);
	const {
		teamsettingGeneralLoader,
		teamsettingGeneralImgUploadSuccess,
		somethingWentWrong,
	} = useSelector((state: RootState) => state.configure);

	const { tenantFile, customTenantName, customOrganizationName } = useSelector(
		(state: RootState) => state.teamsetting
	);
	const [isCustomName, setCustomName] = useState(false);
	const [isCustomOrg, setCustomOrg] = useState(false);
	const tenantImg = loadState('tenantImageURL');
	const fileRef = useRef<HTMLInputElement>(null);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (imageLoader == 'failed') {
			dispatch(
				setSnackModal({
					openModal: true,
					modalMesssage: imageLoadFailMsg,
					modalMode: 'negative',
				})
			);

			dispatch(setImageLoader(''));
		}
	}, [imageLoader]);
	const fileUploadInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (fileRef.current) {
			let fileInput = fileRef.current;
			var filePath = fileInput.value;
			// Allowing file type
			var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
			if (filePath) {
				if (!allowedExtensions.exec(filePath)) {
					dispatch(
						setSnackModal({
							openModal: true,
							modalMesssage:
								'Invalid Media :- Supported media formats are .jpg,.jpeg,.png',
							modalMode: 'negative',
						})
					);
					fileInput.value = '';
					return false;
				} else {
					let file = e.target.files && e.target.files[0];
					const MAX_FILE_SIZE = 5120; // 5MB in binary
					const fileSizeKiloBytes = file && file.size / 1024;
					if (fileSizeKiloBytes! > MAX_FILE_SIZE) {
						dispatch(
							setSnackModal({
								openModal: true,
								modalMesssage:
									'File size should be maximum 5 MB Format should be PNG or JPEG or JPG',
								modalMode: 'negative',
							})
						);
					} else {
						const formData = new FormData();
						e.target.files &&
							formData.append(
								'file',
								e.target.files[0],
								e.target.files[0].name
							);
						dispatch(
							uploadImage({
								file: formData,
								botId: loadState('botId'),
								tenantId: loadState('tenantId'),
								uploadTo: 'TeamSetting General',
							})
						);
						fileInput.value = '';
					}
				}
			}
		}
	};

	return (
		<div className='ff-team-details-container'>
			<div className='team-details-header'>
				<h4>Team Details</h4>
			</div>
			<div className='team-details'>
				<span>
					<h6>Organization</h6>
				</span>
				<Input
					fullWidth
					autoFocus
					limit={50}
					value={
						isCustomOrg ? customOrganizationName : loadState('organizationName')
					}
					onChange={(ev) => {
						setCustomOrg(true);
						dispatch(setcustomOrganizationName(ev.target.value.trimStart()));
						if (ev.target.value.trimStart().length > 50) {
							dispatch(setInvalidOrgName(true));
						} else {
							dispatch(setInvalidOrgName(false));
						}
					}}
					style={{ margin: '24px 0px 35.5px' }}></Input>
				<hr className='line-horizontal' />
				<span>
					<h6>Team Name</h6>
				</span>
				<Input
					fullWidth
					limit={50}
					value={isCustomName ? customTenantName : loadState('teamname')}
					style={{ margin: '24px 0px 35.5px' }}
					onChange={(ev) => {
						setCustomName(true);
						dispatch(setCustomtenantname(ev.target.value.trimStart()));
						if (ev.target.value.trimStart().length > 50) {
							dispatch(setInvalidTeamName(true));
						} else {
							dispatch(setInvalidTeamName(false));
						}
					}}></Input>
				<hr className='line-horizontal' />
				<span>
					<h6>Team Icon</h6>
				</span>

				<label htmlFor='ff-media-file'>
					<div className='ff-preview-upload'>
						<div className='ff-file-preview'>
							{tenantFile ? (
								<img src={tenantFile} alt='tenantImg' width={72} height={72} />
							) : tenantImg ? (
								<img src={tenantImg} alt='tenantImg' width={72} height={72} />
							) : (
								loadState('teamname') && (
									<TeamBadge Team={loadState('teamname')} />
								)
							)}
						</div>
						<div className='ff-media-upload'>
							{teamsettingGeneralLoader ? <FFLoader width='40' /> : 'Change'}
						</div>
					</div>
				</label>
				<input
					type='file'
					id='ff-media-file'
					ref={fileRef}
					onChange={fileUploadInput}
					name='sf-media-file'
					style={{ visibility: 'hidden' }}
				/>

				{/* <Button
          size="small"
          buttonType="light"
          style={{ marginBottom: "36px" }}
        >
          Change
        </Button> */}
			</div>
		</div>
	);
};

export default TeamDetails;
