import DataFetchAPI from '../../utils/Api';
import DataFetchShopifyAPI from '../../utils/ShopifyApi';
import { GetData, Unlinkdata } from './shopifySlice';

const getData = async (data: GetData) => {
	return await DataFetchShopifyAPI(false, true, 'Bearer').post(
		'ecom/link',
		data
	);
};

const unlinkdata = async (data: Unlinkdata) => {
	return await DataFetchShopifyAPI(false, true, 'Bearer').get('ecom/unlink', {
		params: data,
	});
};

const shopifyService = {
	getData,
	unlinkdata,
};

export default shopifyService;
