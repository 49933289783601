import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/store';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import Modal from '../../../../Components/Modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import './changepassword.scss';
import {
	CurrentPasswordVerify,
	currentPassWord,
	currentPwdResponse,
	clearPasswordResponse,
	clearcurrentPasswordStatus,
} from '../../../../Features/ChangePassword/ChangePassSlice';
import { LoginEyeOpen } from '../../../../assets/Icons';
import { encryptText, isHTMLTagsPresent } from '../../../../utils/utils';


function ChangePassword() {
	const [errmsg, setErrMsg] = useState<string | undefined>(undefined);
	const [showPassword, setShowPassword] = useState(false);
	const dispatch = useAppDispatch();

	const { user } = useSelector((state: RootState) => state.auth);
	const {
		currentPasswordStatus,
		currentPasswordResponse,
		CurrentPasswordVerifyStatus,
		encryptedCurrentPassword,
	} = useSelector((state: RootState) => state.changePassword);

	const [currentPassword, setCurrentPassword] = useState<any>('');

	const onChangepassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(clearcurrentPasswordStatus());
		dispatch(currentPassWord(e.target.value));
		setCurrentPassword(e.target.value);
		setErrMsg(undefined);
	};

	const onClickNext = () => {
		dispatch(
			currentPwdResponse({
				changecurrentPassword: false,
				changePassword: true,
				changePasswordSuccess: false,
			})
		);
		setErrMsg(undefined);
	};

	useEffect(() => {
		if (currentPasswordStatus === 'Success') {
			currentPasswordResponse.response === 'Success'
				? onClickNext()
				: setErrMsg('password not matching the current password');
		}
	}, [currentPasswordStatus]);

	const buttons = () => {
		return (
			<>
				<Button
					onClick={() => dispatch(clearPasswordResponse())}
					buttonType='light'>
					Cancel
				</Button>
				<Button
					onClick={() => {
						if (user) {
							const encryptCurrentpwd = encryptText(currentPassword);
							const encryptUserId = decodeURIComponent(user.encryptedUserId);
							onClickNext();

							// dispatch(currentPassWord(encryptCurrentpwd));
							// dispatch(
							// 	CurrentPasswordVerify({
							// 		encryptedPassword: encryptText(currentPassword),
							// 		encryptedUserId: encryptUserId,
							// 	})
							// );
						}
						setCurrentPassword('');
					}}
					disabled={currentPassword === '' ? true : false}
					loading={CurrentPasswordVerifyStatus}>
					<p>Next</p>
				</Button>
			</>
		);
	};

	return (
		<div>
			<Modal
				header='Change Password'
				onClose={() => dispatch(clearPasswordResponse())}
				actionButtons={buttons()}>
				<div className='ff-change-password'>
					Please enter your current password to proceed. You will have to log in
					again after your password is changed.
				</div>
				<div className='ff_input_container'>
					<Input
						// startIcon={<LoginEyeClosed />}
						type={showPassword ? 'text' : 'password'}
						onChange={onChangepassword}
						value={currentPassword}
						label='Current password'
						error={Boolean(errmsg)}
						errorText={errmsg}
						autoFocus
						endIcon={
							<div
								className={
									errmsg === undefined || errmsg === ''
										? 'ff_input_icon'
										: 'ff_input_icon_error'
								}
								onClick={() => setShowPassword(!showPassword)}>
								<LoginEyeOpen fillIcon={true} color='#8991a3' />
							</div>
						}
					/>
				</div>
			</Modal>
		</div>
	);
}

export default ChangePassword;
