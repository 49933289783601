import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../app/store';
import { getCompletionPointofDialog } from '../../../Features/Analytics/analyticsSlice';
import {
	APICallOption,
	ConfirmCollectedOption,
	KeyboardOption,
	MultiChoiceOption,
	MultiSelectOption,
	SendCollectOption,
	StarOption,
	TextOrImage,
	TriggedQueryOption,
} from '../icon';
import './completionpointofDialog.scss';

function CompletionPointoffDialog() {
	const Icons: any = {
		1: <TextOrImage width='22px' />,
		3: <MultiSelectOption width='22px' />,
		5: <MultiChoiceOption width='22px' />,
		8: <ConfirmCollectedOption width='22px' />,
		10: <APICallOption width='22px' />,
		11: <StarOption width='22px' />,
		12: <TriggedQueryOption width='21px' />,
		150: <KeyboardOption width='22px' />,
		14: <SendCollectOption width='22px' />,
	};

	const {
		// completionPointDialogData,
		selectedDates,
		calendarstatus,
		selectedTriggeredQuery,
		activeAnalyticsBot,
	} = useSelector((state: RootState) => state.analytics);
	const { selectedBot, activeBot } = useSelector(
		(state: RootState) => state.bots
	);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const dispatch = useAppDispatch();
	const completionPointDialogData = {
		total: 800,
		data: [
			{
				count: 1500,
				userResponseTypeId: 3,
				taskName: 'Date of Birth',
			},
			{
				count: 3000,
				userResponseTypeId: 150,
				taskName: 'Newsletter subscription resp Newsletter subscription resp',
			},
			{
				count: 63,
				userResponseTypeId: 3,
				taskName: 'Names',
			},
			{
				count: 500,
				userResponseTypeId: 5,
				taskName: 'Interests',
			},
			{
				count: 603,
				userResponseTypeId: 5,
				taskName: 'User Rating',
			},
		],
	};
	const sortedDialogList = completionPointDialogData?.data
		?.slice()
		.sort((a: any, b: any) => b.count - a.count);

	const calculateWidth = (count: number) => {
		// let newWidth = (278 / sortedDialogList[0].count) * count;
		// return newWidth + 'px';
		let newWidth = (count * 100) / sortedDialogList[0].count;
		return newWidth + '%';
	};
	const calPercent = (count: number) => {
		return completionPointDialogData.total
			? (count / completionPointDialogData.total).toFixed(1)
			: 0;
	};

	useEffect(() => {
		if (calendarstatus && tenantId) {
			dispatch(
				getCompletionPointofDialog({
					startdate: selectedDates[0],
					enddate: selectedDates[1],
					timezone: -330,
					tenantId: tenantId,
					botId: activeAnalyticsBot.botId,
					wfId: selectedTriggeredQuery.triggeredQueryId,
				})
			);
		}
	}, [selectedDates]);

	return (
		<div className='ff_analytics_completion_dialog_container'>
			<h5 className='ff_ananlytics_Completion_dialog'>
				Completion points of Customer Journey
			</h5>
			{sortedDialogList.length === 0 ? (
				<div className='ff_nodata_to_display'>No data to Display</div>
			) : (
				<div className='ff_completion_table_container'>
					<div className='ff_completion_table_header'>
						<h5 className='ff_completion_table_sub_header'>Action name</h5>
						<h5 className='ff_completion_table_sub_header'>
							Customer Journey completion at this action
						</h5>
					</div>

					<div>
						{sortedDialogList.map((dialog, key) => {
							return (
								<div className='ff_table_data_container'>
									<div className='ff_Dialog_action_name'>
										<span>{Icons[dialog.userResponseTypeId]}</span>
										<div className='Dialog_title body1'>{dialog.taskName}</div>
									</div>
									<div className='ff_completion_dialog_bar'>
										<p className='ff_completion_count'>{`${calPercent(
											dialog.count
										)}% (${dialog.count.toLocaleString()})`}</p>
										<div className='ff_max_complete_dialog'>
											<div
												className='completion_dialog'
												style={{
													width:
														sortedDialogList[0].count === 0 &&
														dialog.count === 0
															? '0%'
															: sortedDialogList[0].count === dialog.count
															? '100%'
															: calculateWidth(dialog.count),
												}}></div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
}

export default CompletionPointoffDialog;
