import styled from 'styled-components';

// export type GCBPreviewProps = {
// 	iconOutSide: boolean;
// };

export type GCBPreviewCollapsedProps = {
	calloutContentColor: string;
	iconOutSide: boolean;
	selectedColor: string;
	mode: string;
	colorScale: string[];
};
type GCBPreviewProps = {
	selectedColor: string;
	mode: string;
};
export const GCBPreview = styled.div<GCBPreviewProps>`
	// max-width: calc(100vw - 260px);
	// min-width: calc(100vw - 260px);
	width:32vw;
	max-width:440px;
	min-width:400px;
	height: calc(100vh - 104px);
	border-radius: 24px;
	box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.28);
	background: ${(props) => `radial-gradient(
		circle at 0% 0%,
		${props.selectedColor},
		${props.selectedColor} 25%,
		white 75%`}
	);
	color: ${(props) => (props.mode === 'dark' ? '#ffffff' : props.selectedColor)};
`;

export const GCBPreviewCollapsed = styled.div<GCBPreviewCollapsedProps>`
	max-width: 360px;
	min-width: 360px;

	& > .ai-preview-holder {
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		align-items: flex-end;
		& > img {
			width: 72px;
			aspect-ratio: 1;
			cursor: pointer;
			border-radius: 50%;
		}
		& > .callout {
			margin-bottom: 24px;
			width: fit-content;
			padding: 12px 20px;
			border-radius: 20px;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
			overflow-wrap: anywhere;
			position: relative;

			&::before {
				display: block;
				content: '';
				height: 0;
				width: 0;
				border: 13px solid transparent;
				border-top: 13px solid
					${(props) =>
						props.mode === 'dark'
							? props.selectedColor
							: props.colorScale && props.colorScale[4]};
				position: absolute;
				top: 100%;
				right: 30px;
				border-right: 10px;
			}
			&::after {
				content: '';
				display: block;
				height: 0;
				width: 0;
				border: 12px solid transparent;
				border-top: 12px solid
					${(props) => props.colorScale && props.colorScale[9]};
				position: absolute;
				top: 97%;
				right: 31px;
				border-right: 10px;
			}
		}
	}
`;
