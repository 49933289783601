import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loadState, removeAllState, saveState } from '../../utils/utils';
import helpbotService from './helpbotService';
import { helpBotMsgReq } from './model';
// import { User, UserLoginData, UserSignUpData, switchData } from './model';

interface State {
    msgsArray: any,
    msgsLoading: string,
    chatInput: string,
    conversationId: number,
    chatData: any;
    HBscreen: string;
    expandWidth: boolean;
    reportIssueInput: string;
    messageFeedbackComment: any;
    thumbFilledUp: any;
    messageId: number;
    isModalVisible: any;
    thumbFilledDown: any;
}


export const chatMessage = createAsyncThunk<
    void,
    helpBotMsgReq,
    { rejectValue: string }
>('chatMessage', async (data, { rejectWithValue, dispatch, getState }) => {
    try {

        const response = await helpbotService.message(data);
        if (response.data.status === 'Success') {
            return response.data;
        }
    } catch (error) {

        return rejectWithValue(JSON.stringify(error));
    }
});


const initialState: State = {
    msgsArray: [
        // {
        //     content:
        //         'Hi, how may I help you?',
        //     role: 'server',
        // },
    ],
    msgsLoading: '',
    chatInput: '',
    conversationId: 0,
    chatData: {},
    HBscreen: 'welcome',
    expandWidth: false,
    reportIssueInput: '',
    messageFeedbackComment: '',
    thumbFilledUp: false,
    messageId: 0,
    isModalVisible: false,
    thumbFilledDown: false
};

const salesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        setMsgsArray: (state, action) => {
            state.msgsArray = action.payload;
        },
        resetConversationId: (state, action) => {
            state.conversationId = action.payload;
        },
        resetMsgsArray: (state) => {
            // state.msgsArray = [
            // 	{
            // 		content:
            // 			'Hello, how may I help you? We have wide range of beauty products to select from.',
            // 		role: 'assistant',
            // 	},
            // ];
            state.msgsArray = [];
        },
        setMsgsLoading: (state, action) => {
            state.msgsLoading = action.payload;
        },
        HBSetScreen: (state, action) => {
            state.HBscreen = action.payload
        },

        HBsetChatInput: (state, action) => {
            console.log(action.payload)
            state.chatInput = action.payload;
        },
        setExpandWidth: (state, action) => {
            state.expandWidth = action.payload
        },
        HBReportIssueInput: (state, action) => {
            state.reportIssueInput = action.payload
        },
        setmessageFeedbackComment: (state, action) => {
            state.messageFeedbackComment = action.payload
        },

        setthumbFilledUp: (state, action) => {
            state.thumbFilledUp = action.payload
        },

        setmessageId: (state, action) => {
            state.messageId = action.payload
        },
        setisModalVisible: (state, action) => {
            state.isModalVisible = action.payload
        },
        setthumbFilledDown: (state, action) => {
            state.thumbFilledDown = action.payload
        },

    },

    extraReducers: (builder) => {
        builder
            // .addCase(getProducts.pending, (state) => {})
            .addCase(chatMessage.fulfilled, (state, action) => {
                state.chatData = action.payload
                state.conversationId = state.chatData.id;
            })
        // .addCase(getProducts.rejected, (state, action) => {})


    },
});

export const {
    resetMsgsArray,
    setMsgsArray,
    setMsgsLoading,
    resetConversationId,
    HBsetChatInput,
    HBSetScreen,
    setExpandWidth,
    HBReportIssueInput,
    setmessageFeedbackComment,
    setthumbFilledUp,
    setmessageId,
    setisModalVisible,
    setthumbFilledDown
} = salesSlice.actions;

export default salesSlice.reducer;
