import React, { useEffect, useState } from 'react';
import Modal from '../../../../../Components/Modal';
import Input from '../../../../../Components/Input';
import Button from '../../../../../Components/Button';
import { LoginEyeClosed, LoginEyeOpen } from '../../../../../assets/Icons';
import { RootState, useAppDispatch } from '../../../../../app/store';
import './LinkLeadSquared.scss';
import { useDispatch, useSelector } from 'react-redux';
import { isHTMLTagsPresent, loadState } from '../../../../../utils/utils';
import { Outlet, useNavigate } from 'react-router-dom';
import { isLeadCRMValid } from '../../../../../Features/LeadSquared/leadsquaredSlice';
import { crmsLinked } from '../../../../../Features/teamsetting/teamsettingSlice';
import { setSnackModal } from '../../../../../Features/message/messageSlice';
let leadSquare_CRM_ID: number = 6;
const LinkLeadSquared = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [isapikey, setIsapikey] = useState(false);
	const [isapisecretkey, setIsapisecretkey] = useState(false);
	const [apihosturl, setApihosturl] = useState('');
	const [apikey, setApikey] = useState('');
	const [apisecretkey, setApisecretkey] = useState('');
	const [buttonLoader, setButtonLoader] = useState(false);
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { leadSquaredData } = useSelector(
		(state: RootState) => state.teamsetting
	);

	const handleapihosturl = (event: React.ChangeEvent<HTMLInputElement>) => {
		setApihosturl(event.target.value.trim());
	};

	const handleapikey = (event: React.ChangeEvent<HTMLInputElement>) => {
		setApikey(event.target.value.trim());
	};

	const handleapisecretkey = (event: React.ChangeEvent<HTMLInputElement>) => {
		setApisecretkey(event.target.value.trim());
	};

	const validateapihosturl = () => {
		if (apihosturl.length <= 0) {
			return 'Enter Username';
		} else {
			return false;
		}
	};
	const validateapikey = () => {
		if (apikey.length <= 0) {
			return 'Enter password';
		} else {
			return false;
		}
	};
	const validatesecretkey = () => {
		if (apisecretkey.length <= 0) {
			return 'Enter password';
		} else {
			return false;
		}
	};

	const isdisabled = () => {
		return validateapihosturl()
			? true
			: false || validateapikey()
				? true
				: false || validatesecretkey()
					? true
					: false;
	};
	const crmAPI = () => {
		let apiBaseURL =
			window.location.origin.indexOf('localhost') > -1
				? process.env.REACT_APP_API + '/'
				: window.location.origin + '/';
		let requestLeadSquared = {
			crmId: leadSquaredData.crmId ? leadSquaredData.crmId : leadSquare_CRM_ID,
			config: {
				ACCESS_KEY: apikey,
				SECRET_KEY: apisecretkey,
				URL: apihosturl,
			},
		};
		setButtonLoader(true);
		fetch(apiBaseURL + `crm/tenant/${tenantId!}/link`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${loadState('token')}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(requestLeadSquared),
		})
			.then((data) => data.json())
			.then((response) => {
				setButtonLoader(false);
				if (response.status == 'Success') {
					dispatch(isLeadCRMValid(true));
					dispatch(crmsLinked({ tenantId: tenantId! }));

					//  navigate('link-leadsquared-info');
					dispatch(
						setSnackModal({
							openModal: true,
							modalMesssage: 'LeadSquared successfully linked',
						})
					);
					dispatch(crmsLinked({ tenantId: tenantId! }));

					navigate(-1);
				} else {
					dispatch(isLeadCRMValid(false));
					// navigate('link-leadsquared-info');
					dispatch(
						setSnackModal({
							openModal: true,
							modalMesssage: 'Failed to link the LeadSquared account',
							modalMode: 'negative',
						})
					);
				}
			})
			.catch((error) => {
				setButtonLoader(false);
				dispatch(isLeadCRMValid(false));
				// navigate('link-leadsquared-info');
				dispatch(
					setSnackModal({
						openModal: true,
						modalMesssage: 'Failed to link the LeadSquared account',
						modalMode: 'negative',
					})
				);
			});
	};

	return (
		<div className='ff-lead-squared-link-integration-modal'>
			<Modal
				header='Link your team with LeadSquared'
				actionButtons={
					<>
						<Button
							buttonType='light'
							onClick={() => {
								navigate(-1);
							}}>
							Cancel
						</Button>

						<Button
							loading={buttonLoader}
							disabled={
								isdisabled() ||
								isHTMLTagsPresent(apihosturl, true) ||
								isHTMLTagsPresent(apikey, true) ||
								isHTMLTagsPresent(apisecretkey, true)
							}
							onClick={() => {
								crmAPI();
							}}>
							Link
						</Button>
					</>
				}>
				<div className='modal-body'>
					<p className='body1'>
						Linking your Flyfish team with a LeadSquared account lets you send
						the leads you capture with your Ads and Storefronts to LeadSquared.
					</p>
					<p>
						<div className='body-moreinfo'>
							Please fill in the fields given below to link the account. See the
							<button>
								&nbsp;
								<a
									href='https://apidocs.leadsquared.com/api-host/#api'
									target='blank'
									style={{ textDecoration: 'none', color: 'blue' }}>
									API Host
								</a>
								&nbsp;
							</button>
							<span>and </span>
							{ }
							<button>
								<a
									href='https://apidocs.leadsquared.com/authentication/#api'
									target='blank'
									style={{ textDecoration: 'none', color: 'blue' }}>
									Authentication
								</a>
								&nbsp;
							</button>
							pages in LeadSquared Developer Centre for more information about
							these fields
						</div>
					</p>
					<div>
						<Input
							value={apihosturl}
							id='apihosturl'
							autoFocus
							onChange={handleapihosturl}
							label='API Host'
							limit={50}
							style={{ width: '552px', height: '48px', marginBottom: '8px' }}
						/>
					</div>

					<div>
						<Input
							id='apikey'
							type={isapikey ? 'text' : 'password'}
							value={apikey}
							onChange={handleapikey}
							label='API Key'
							limit={50}
							endIcon={
								<div
									className='toggle-password-show'
									onClick={() => setIsapikey(!isapikey)}>
									{apikey === '' ? null : isapikey ? (
										<LoginEyeClosed width='19px' />
									) : (
										<LoginEyeOpen width='19px' />
									)}
								</div>
							}
							style={{ width: '552px', height: '48px', marginBottom: '8px' }}
						/>
					</div>
					<div>
						<Input
							id='apisecretkey'
							type={isapisecretkey ? 'text' : 'password'}
							value={apisecretkey}
							onChange={handleapisecretkey}
							label='Secret Key'
							limit={50}
							style={{ width: '552px', height: '48px', marginBottom: '8px' }}
							endIcon={
								<div
									className='toggle-password-show'
									onClick={() => setIsapisecretkey(!isapisecretkey)}>
									{apisecretkey === '' ? null : isapisecretkey ? (
										<LoginEyeClosed width='19px' />
									) : (
										<LoginEyeOpen width='19px' />
									)}
								</div>
							}
						/>
					</div>
				</div>
			</Modal>
			<Outlet />
		</div>
	);
};
export default LinkLeadSquared;
