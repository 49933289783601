import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { linkZohoDevConsole } from '../../../../../Features/Zoho/zohoSlice';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { SuccessCheck } from '../../../../Subscription/EnterpriseContact';
import './ZThankYou.scss';
import { loadState } from '../../../../../utils/utils';

const ZThankYou = () => {
	const [searchParams] = useSearchParams();
	const [countdown, setCountdown] = useState(5);
	const [status, setStatus] = useState('Pending');
	const [intervalId, setIntervalId] = useState<number | NodeJS.Timer>(0);
	const stateV = searchParams.get('state');
	const codeV = searchParams.get('code');
	const { linkZohoDevConsoleStatus } = useSelector(
		(state: RootState) => state.zoho
	);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(linkZohoDevConsole({ state: stateV, code: codeV }));
		startCountdown();

		return () => {
			clearInterval(intervalId! as number);
		};
	}, []);

	useEffect(() => {
		if (status === 'Pending') {
			if (countdown === 0) {
				handleCheckStatus();
			}
		}
	}, [countdown]);

	const startCountdown = () => {
		setStatus('Pending');
		setCountdown(5);

		const id = setInterval(() => {
			setCountdown((prevCountdown) => prevCountdown - 1);
		}, 1000);

		setIntervalId(id);
	};

	const handleCheckStatus = async () => {
		if (status === 'Pending') {
			clearInterval(intervalId! as number);
		} else if (status === 'failed') {
			console.log('We ran into issue');
			clearInterval(intervalId! as number);
		}
	};
	useEffect(() => {
		if (linkZohoDevConsoleStatus === 'success') {
			setStatus('Success');
			navigate(
				`/app/general-configuration/Integrations/${
					loadState('selectedGeneralTab') === 'Ticket Management'
						? 'ticket-management'
						: loadState('selectedGeneralTab') === 'All'
						? 'all'
						: ''
				}`
			);
		} else if (linkZohoDevConsoleStatus === 'failed') {
			setStatus('Failure');
			setTimeout(() => {
				clearInterval(intervalId! as number);
				// navigate('/app/general-configuration/Integrations');
			}, countdown * 1000);
		}
	}, [linkZohoDevConsoleStatus]);
	return (
		<div className='ff-zoho-consent-thank-you'>
			<div>
				<div>{SuccessCheck()}</div>
				<h3>Congrats! Your Flyfish team is now linked</h3>
				<h3>with Zoho CRM</h3>
				<p>
					You will be redirected in {countdown} seconds. Please don't refresh
					the page or <br /> press the back button
				</p>
			</div>
		</div>
	);
};

export default ZThankYou;
