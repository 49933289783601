import { Box, FormControl, TextField, TextFieldProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { isHTMLTagsPresent } from '../../utils/utils';
import './Input.scss';

type Props = {
	value?: string | undefined;
	label?: string;
	error?: boolean;
	errorText?: string;
	helperText?: string;
	fullWidth?: boolean;
	limit?: number;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	onChange?: Function;
	disAllowSpecialChar?: boolean;
} & TextFieldProps;

const Input = ({
	value,
	label,
	helperText,
	errorText,
	error = false,
	fullWidth = false,
	limit,
	startIcon,
	endIcon,
	onChange,
	disAllowSpecialChar = false,
	...rest
}: Props) => {
	const handleKeyUp = (
		event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (event.key === 'Escape') {
			// @ts-ignore
			event.target.blur();
		}
	};

	const [text, setText] = useState(helperText);
	const [limitError, setLimitError] = useState(false);
	const [inputError, setInputError] = useState('');
	useEffect(() => {
		if (limit && value && value.trim()?.length > limit) {
			setText('Character limit exceeded');
			setLimitError(true);
		} else {
			if (error && errorText && errorText?.length > 0) {
				setText(errorText);
			} else {
				setText(helperText);
			}
		}
	}, [error, limitError]);

	const handleChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		onChange && onChange(event);
		if (disAllowSpecialChar) {
			if (isHTMLTagsPresent(event.target.value)) {
				if (!disAllowSpecialChar) {
					setInputError('');
				} else {
					setInputError(`Only " , . - _ " allowed`);
				}
			} else {
				setInputError('');
			}
		}

		if (limit) {
			if (event.target.value.trim().length > limit) {
				setLimitError(true);
			} else {
				setLimitError(false);
			}
		}
	};

	return (
		<FormControl
			variant='outlined'
			className={`ff-input${fullWidth ? ' fullWidth' : ''}${
				label ? '' : ' small'
			}`}>
			<TextField
				variant='outlined'
				label={label}
				value={value}
				onChange={handleChange}
				error={error || limitError || Boolean(inputError.length)}
				onFocus={(event) => event.target.select()}
				InputProps={{
					startAdornment: startIcon,
					endAdornment: endIcon,
					onKeyUp: (event) => handleKeyUp(event),
				}}
				helperText={
					(text?.length && (
						<Box component={'span'} className='error-container'>
							<span className='error-text'>{text}</span>
							{limit && limitError && (
								<span>
									{value?.length}/{limit}
								</span>
							)}
						</Box>
					)) ||
					(inputError && Boolean(inputError.length) && (
						<Box component={'span'} className='error-container'>
							<span
								className='error-text'
								style={{ color: 'var(--error-red)' }}>
								{inputError}
							</span>
						</Box>
					))
				}
				maxRows={3}
				{...rest}
			/>
		</FormControl>
	);
};

export default Input;
