import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { RootState } from '../../../app/store';
import { FFLoader } from '../../../assets/Icons/Loader';
import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import Modal from '../../../Components/Modal';
import PageLoader from '../../../Components/PageLoader';
import {
	getAllFAQData,
	getFAQData,
	resetFAQDataStatus,
} from '../../../Features/FAQs/faqSlice';
import { set } from '../../../Features/message/messageSlice';
import { checkUrl, loadState, saveState } from '../../../utils/utils';
import IngestTab from '../../IngestTab';
import AfterSalesSupport from '../AfterSalesSupport/AfterSalesSupport';
import {
	setUploadedFiles,
	ingestLink,
} from '../../../Features/Ingest/IngestSlice';
import './UploadFaqsWeb.scss';

function UploadFaqsWeb() {
	const navigate = useNavigate();
	const [webURL, setWebURL] = useState('');
	const context: any = useOutletContext();
	// const [loader, setLoader] = useState(false);
	const dispatch = useDispatch<any>();
	const { tenantId } = useSelector((state: RootState) => state.teams);
	const { uploadedFiles } = useSelector((state: RootState) => state.ingest);
	const { FAQDataStatus, FAQData, isFAQImporting, isDataLoading, isImporting } =
		useSelector((state: RootState) => state.faq);
	const [openImport, setOpenImport] = useState(false);

	const getFAQdata = () => {
		// tenantId &&
		// 	dispatch(
		// 		getFAQData({
		// 			botid: loadState('botId').toString(),
		// 			tenantid: loadState('tenantId').toString()
		// 				? loadState('tenantId').toString()
		// 				: '',
		// 			// ?? '',
		// 			url: webURL,
		// 			filePath: '',
		// 		})
		// 	);
		// console.log('closed');
	};

	useEffect(() => {
		setOpenImport(openImport);
	}, [openImport]);

	const handleImport = () => {
		setOpenImport(false);
		navigate(-1);
	};

	useEffect(() => {
		openImport &&
			//  && isImporting === false
			handleImport();
	}, [isImporting]);

	return (
		<>
			<div>
				<IngestTab />
			</div>
			<div className='upload-faq-web'>
				{/* {context.loader === false ? ( */}
				<Modal
					header='Import FAQs from a Webpage'
					className='add-query-modal-faq'
					actionButtons={
						<>
							<Button buttonType='light' onClick={() => navigate(-1)}>
								Cancel
							</Button>
							<Button
								buttonType={isImporting ? 'light' : 'dark'}
								onClick={() => {
									setOpenImport(true);
									saveState('fileName', webURL);
									dispatch(
										ingestLink({
											tenantId: tenantId,
											botId: loadState('botID'),
											url: webURL,
										})
									);
									navigate('/app/Ingest-tab/ingested-resources');
									getFAQdata();
								}}
								disabled={
									webURL.length !== 0 && checkUrl(webURL) ? false : true
								}
								startIcon={isImporting ? <FFLoader /> : ''}
								loading={isImporting ? true : false}>
								{isImporting ? 'Importing...' : 'Import'}
							</Button>
						</>
					}>
					<div className='query-input-container'>
						<Input
							fullWidth
							placeholder='E.g. https://example.com/example.mp4'
							className='query-input'
							label='Webpage URL'
							value={webURL}
							onChange={(event) => {
								setWebURL(event.target.value);
							}}
						/>
					</div>
				</Modal>
				{/* ) : null} */}
			</div>
		</>
	);
}

export default UploadFaqsWeb;
