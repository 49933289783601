import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { differenceInDays } from 'date-fns';
import { useAppDispatch } from '../../../../app/store';
import { set } from '../../../../Features/message/messageSlice';

type Props = {
	onClose: () => void;
	onChange: (date: Date[]) => void;
	dates: any;
};

export default function BasicDateRangePicker({
	onChange,
	dates,
	onClose,
}: Props) {
	const dispatch = useAppDispatch();
	const handleChange = (dates: Date[]) => {
		if (differenceInDays(dates[1], dates[0]) < 30) {
			onChange(dates);
			onClose();
		} else {
			dispatch(set('The selected date range should not be more that 30 days'));
			onClose();
		}
	};

	return (
		<DateRangePicker
			calendarType='US'
			isOpen={true}
			clearIcon={null}
			maxDate={new Date()}
			next2Label={null}
			prev2Label={null}
			onChange={handleChange}
			value={[new Date(dates[0]), new Date(dates[1])]}
		/>
	);
}
