import DataFetchAPI from '../../utils/Api';
import {
	GetURL,
	AddOriginURL,
	getBotId,
	DeleteOriginURL,
	GetChannels,
} from './modal';

const getOriginListData = async (data: GetURL) => {
	return await DataFetchAPI().get(`/configuration/${data.tenantId}/origin`);
};

const addOriginURL = async (data: AddOriginURL) => {
	return await DataFetchAPI().post(
		`/configuration/${data.tenantId}/origin`,
		data
	);
};

const delOriginURL = async (data: DeleteOriginURL) => {
	return await DataFetchAPI().delete(
		`/configuration/${data.tenantId}/origin/${data.originId}`
	);
};

const getVersionDetails = async (data: getBotId) => {
	return await DataFetchAPI().get('/version/getVersionDetails', {
		params: data,
	});
};

const getChannels = async (data: GetChannels) => {
	return await DataFetchAPI(false, true, 'Bearer').get('/rba/v1/getChannels', {
		params: data,
	});
};
const channelService = {
	getOriginListData,
	addOriginURL,
	getVersionDetails,
	delOriginURL,
	getChannels,
};

export default channelService;
