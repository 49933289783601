import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Parameters as ParametersProps } from '..';
import { RootState, useAppDispatch } from '../../../../../app/store';
import Button from '../../../../../Components/Button';
import Checkbox from '../../../../../Components/Checkbox';
import Dropdown, { DropdownValue } from '../../../../../Components/Dropdown';
import Input from '../../../../../Components/Input';
import Modal from '../../../../../Components/Modal';
import SnackBar from '../../../../../Components/SnackBar';
import builderService from '../../../../../Features/builder/builderService';
import {
	BotBuilderPlus,
	ModalClose,
	SearchClose,
} from '../../../../../assets/Icons';
import { set } from '../../../../../Features/message/messageSlice';
import { getRandom } from '../../../../../utils/utils';

const Parameters = () => {
	const navigate = useNavigate();
	const context: any = useOutletContext();
	const dispatch = useAppDispatch();
	let duplicates = [];
	const { botId, tenantId } = useSelector((state: RootState) => state.builder);
	const [variableNames, setVariableNames] = useState<DropdownValue[]>([]);

	const [q, setQ] = useState('');
	const [params, setParams] = useState<ParametersProps[]>(
		context.parameters.length > 0
			? context.parameters
			: [{ key: '', value: '', id: getRandom('id-', 6) }]
	);

	const [filteredParams, setFilteredParams] = useState<ParametersProps[]>([]);

	useEffect(() => {
		if (botId && tenantId) {
			(async () => {
				const params = await builderService.getSuggestedResponseParam({
					botId,
					tenantId,
					workflowId: context.workflowId,
				});

				if (params.status === 200 && params.data.length > 0) {
					const variables = params.data.map((param: string) => ({
						value: param,
						label: param,
					}));

					setVariableNames(variables);
				}
			})();
		}
	}, []);

	useEffect(() => {
		if (q.length > 0) {
			setFilteredParams(
				params.filter((param) => param.key.toString().includes(q))
			);
		} else {
			setFilteredParams(params);
		}
	}, [q, params]);

	useEffect(() => {
		if (params.length === 0) handleAdd();
	}, [params]);

	const handleAdd = () => {
		setQ('');
		setParams((prev) => [...prev, { key: '', value: '', id: prev.length }]);
	};

	const handleDelete = (id: number) => {
		setParams((prev) => prev.filter((param) => param.id !== id));
	};

	const handleChange = (param: any, id: number) => {
		setParams((prev) =>
			prev.map((params) =>
				params.id === id ? { ...params, ...param } : params
			)
		);
	};

	const handleChange2 = (event: any, id: number) => {
		let data: any = [...params];
		data[id][event.target.name] = event.target.value;
		setParams(data);
	};

	const handleContinue = () => {
		const valid = params.filter(
			(par) =>
				(par.value.length === 0 || par.key.length === 0) &&
				!(par.value.length === 0 && par.key.length === 0)
		);

		if (valid.length === 0) {
			const p = params.filter(
				(par) => par.value.length > 0 && par.key.length > 0
			);
			let keys: Array<string> = [];
			p.map((val) => {
				keys.push(val.key.toString());
			});
			duplicates = keys.filter(
				(val: string, index: number) => index !== keys.indexOf(val)
			);
			// console.log("duplicates", duplicates);
			if (duplicates.length > 0) {
				dispatch(
					set(
						`Duplicate ${
							duplicates.length === 1 ? 'key' : 'keys'
						} ${duplicates} `
					)
				);
			} else {
				context.setParameters(p);
				navigate(-1);
			}
		} else {
			dispatch(set('Enter valid pair of key and value'));
			// toast(
			// 	<SnackBar type='danger'>Enter valid pair of key and value</SnackBar>,
			// 	{
			// 		autoClose: 3000,
			// 	}
			// );
		}
	};

	return (
		<Modal
			headerCloseBtn
			header={
				<>
					<div className='header-container'>
						<h5>Parameters</h5>

						<Input
							value={q}
							onChange={(event) => setQ(event.target.value)}
							placeholder='Search by Key...'
							className='api-search'
						/>
					</div>
				</>
			}
			actionButtons={
				<div className='api-action-buttons'>
					<div>
						<Button
							startIcon={<BotBuilderPlus width='15px' />}
							onClick={handleAdd}>
							Add a Parameter
						</Button>
					</div>
					<div className='api-buttons'>
						<Button onClick={() => navigate(-1)} buttonType='light'>
							<p>Cancel</p>
						</Button>
						<Button onClick={handleContinue}>
							<p>Done</p>
						</Button>
					</div>
				</div>
			}
			// action='Done'
			// onAction={handleContinue}
			className='call-an-api-multi-select'>
			<div className='additional-body'>
				<div className='head'>
					<div className='row'>
						<div className='cell'></div>
						<div className='cell1'>Key</div>
						<div className='cell1'>Value</div>
						<div className='cell'></div>
					</div>
				</div>
				{filteredParams.length > 0 &&
					filteredParams.map((param: any, index: number) => (
						<div className='row' key={param.id}>
							<div className='cell'>
								{/* <Checkbox
									isChecked={[false]}
									index={0}
									onValueChange={() => {}}
								/> */}
							</div>
							<div className='cell'>
								<Input
									name='key'
									placeholder='Enter Key'
									value={param.key}
									onChange={(event) => handleChange2(event, index)}
								/>
							</div>
							<div className='cell'>
								<Dropdown
									label='Enter Variable Name'
									value={
										variableNames.filter(
											/* @ts-ignore */
											(vr) => vr.value === param.value
										)[0]
									}
									options={variableNames}
									onChange={(dropdown) =>
										handleChange(
											/* @ts-ignore */
											{ value: dropdown.value },
											param.id
										)
									}
								/>
							</div>
							<div className='cell'>
								<SearchClose
									width='12px'
									onClick={() => handleDelete(param.id)}
									className='remove-param-btn'
								/>
							</div>
						</div>
					))}

				{filteredParams.length == 0 ? (
					<div className='no-results-found'>No results found</div>
				) : null}
			</div>
		</Modal>
	);
};
export default Parameters;
