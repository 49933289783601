import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../../../Components/Button';
import Modal from '../../../../../Components/Modal';
import {
	BOTROLES,
	GetAllUsersMyhubResponse,
} from '../../../../../Features/teamsetting/modal';

const RevokeAdminAccess = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location.state as {
		user: GetAllUsersMyhubResponse;
		allBotData: BOTROLES[];
	};

	const buttons = () => {
		const RevokeAccess = () => {
			navigate('/app/configuration/users/edit-bot-access', {
				state: { ...state, fromRevoke: true },
			});
		};
		return (
			<>
				<Button onClick={() => navigate(-1)} buttonType='light'>
					Cancel
				</Button>
				<Button onClick={RevokeAccess}>Revoke</Button>
			</>
		);
	};
	return (
		<div>
			<Modal
				header='Revoke admin access of the user?'
				actionButtons={buttons()}>
				<div>
					Please confirm that you want to revoke admin access of the user{' '}
					<b>{state.user.userEmail}</b>. The user can still remain a bot master
					for the bots that you select next.
				</div>
			</Modal>
		</div>
	);
};

export default RevokeAdminAccess;
