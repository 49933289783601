import React from 'react';
import * as RatingIcons from './RatingIcons';
import styled from 'styled-components';
import { RootState, useAppDispatch } from '../../../../../app/store';
import { useSelector } from 'react-redux';
import { setisModalVisible, setmessageFeedbackComment, setmessageId, setthumbFilledUp } from '../../../../../Features/HelpBot/helpbotSlice';


interface Props {
	number?: number | 3 | 5 | 10;
	filled?: number;
	onChange?: any;

	description?: any;
	noLabel?: boolean;
	noOutlineHover?: boolean;
	name?: '';
	width?: string;
	disable?: boolean;
	iconSize?: string;
	gap?: number;
	isClickable?: boolean;
	purpose?: string;

	voiceResponse?: boolean;
	isResponselevelFeedback?: boolean;
	channel?: string;
	openThumbsPopup?: Function;
	thumbFilledUp?: boolean;
	messageId?: number;
	colorMode?: string;
}

interface ThumbsProps {
	size: 'small' | 'normal' | 'large';
	thumbColor?: string;
	selected?: any;
	centered?: boolean;
	onClick?: any;

}

const tenDescription = {
	0: ' ',
	1: 'Poor',
	2: 'Average',
	3: 'Good',
	4: 'Excellent!',
	5: 'OutStanding',
	6: 'Very good',
	7: 'Excellent',
	8: 'Superb',
	9: 'Outstanding',
	10: 'Magnificent',
};
// const reviewTranslator = (data: string) => {
// 	if (data === 'Very Bad') {
// 		return <T labelName='VeryBadLabel' />;
// 	} else if (data === 'Bad') {
// 		return <T labelName='BadLabel' />;
// 	} else if (data === 'Okay') {
// 		return <T labelName='OkayLabel' />;
// 	} else if (data === 'Good') {
// 		return <T labelName='GoodLabel' />;
// 	} else if (data === 'Excellent') {
// 		return <T labelName='ExcellentLabel' />;
// 	}
// };
const fiveDescription = {
	0: ' ',
	1: 'Poor',
	2: 'Average',
	3: 'Good',
	4: 'Excellent',
	5: 'Outstanding',
};

const threeDescription = {
	0: ' ',
	1: 'Bad',
	3: 'Okay',
	5: 'Good',
};


const FFRatingDIV = styled.div`
.rating-label {
	color:'var(--colorScale-2)';
	}} !important;
}
`;
export const StarRating = (props: Props) => {
	const { number = 5, filled = 0, onChange, description, noLabel } = props;
	const [filledStars, setFilledStars] = React.useState(0);
	const [hoveredStars, setHoveredStars] = React.useState(0);
	const [clickable, setClickable] = React.useState(true);
	const [space, setSpace] = React.useState(false);
	const {
		msgsArray,
		HBscreen,
		expandWidth,
		messageFeedbackComment,
		thumbFilledUp,
		messageId,
		isModalVisible,
		thumbFilledDown
	} = useSelector((state: RootState) => state.helpBot);

	const ratingLabel: any = description
		? description
		: number === 10
			? tenDescription
			: number === 5
				? fiveDescription
				: number === 3
					? threeDescription
					: null;
	React.useEffect(() => {
		setFilledStars(filled);
		setHoveredStars(0);
	}, [filled]);

	// React.useEffect(() => {
	// 	if (props.foregroundColor)
	// 		r.style.setProperty('--selectedColor', props.foregroundColor || '#ffffff');
	// }, [props.foregroundColor]);

	React.useEffect(() => {
		if (props.isClickable === true || props.isClickable === false)
			setClickable(props.isClickable);
	}, [props.isClickable]);
	React.useEffect(() => {
		if (props.purpose) setSpace(true);
	}, [props.purpose]);
	// const foreground =
	// 	styleVars.getPropertyValue('--selectedColor') ||
	// 	props.foregroundColor ||
	// 	'#ffffff';
	const foreground = 'rgba(0,0,0,0.4)';
	const modeGroundColor = props.colorMode === 'dark'
		? 'var(--selectedColor)'
		: 'var(--colorScale-2)';
	const rangeArray = [];
	for (let i = 0; i < number; i += 1) {
		rangeArray.push(i);
	}
	return (
		<FFRatingDIV
			className={`${props.channel === 'whatsapp' ? 'rating-box-whatsapp' : 'rating-box'
				}`}
		>
			<div
				className={`rating-items ${space ? 'rating-space' : ''} `}
				// aria-label={
				// 	!clickable &&
				// 	`${filledStars} ${'star selected'}`
				// }
				style={{
					width: props.width ? props.width : '',
					// gap: props.number > 5 ? 0 : '8px',
					gridGap: props.gap ? props.gap + 'px' : '8px',
					cursor: props.disable ? 'not-allowed' : '',
				}}
			>
				{rangeArray.map((e, i) => {
					return (
						<button
							key={e}
							// title={
							// 	clickable && `${i + 1} ${TString({ labelName: 'starLabel' })}`
							// }
							disabled={props.disable}
							className={`${clickable ? 'clickable' : 'not-clickable'}  ${props.channel ? 'button-whatsapp' : ''
								}
                ${number && number > 5 && !props.iconSize ? 'small' : ''}`}
							style={
								props.iconSize
									? { width: props.iconSize, height: props.iconSize }
									: {}
							}
							onClick={() => {
								if (!props.disable) {
									if (props.onChange) {
										if (props.name) onChange(props.name, e + 1);
										else onChange(e + 1);
									} else {
										setFilledStars(e + 1);
									}
								}
							}}
							onFocus={() => setHoveredStars(filledStars !== 0 ? 0 : e + 1)}
							onBlur={() => setHoveredStars(0)}
							onMouseOver={() => setHoveredStars(filledStars !== 0 ? 0 : e + 1)}
							onMouseOut={() => setHoveredStars(0)}
						>
							<RatingIcons.Star
								fill={
									(hoveredStars !== 0 && e < hoveredStars) ||
										(hoveredStars === 0 && e < filledStars)
										? modeGroundColor
										: foreground
								}
								variant={
									(hoveredStars !== 0 &&
										e < hoveredStars &&
										props.noOutlineHover) ||
										(hoveredStars === 0 && e < filledStars)
										? 'filled'
										: ''
								}
							/>
						</button>
					);
				})}
			</div>
			{noLabel ? null : (
				<div
					className={`${props.channel === 'whatsapp'
						? 'rating-label-whatsapp'
						: 'rating-label'
						} ff-chatbot-caption`}
				>
					{ratingLabel ? (
						ratingLabel[hoveredStars !== 0 ? hoveredStars : filledStars] || ''
					) : hoveredStars !== 0 || filledStars !== 0 ? (
						<div>
							<span style={{ fontWeight: 600, color: 'var(--selectedColor)' }}>
								{hoveredStars !== 0 ? hoveredStars : filledStars}
							</span>
							/{number}
						</div>
					) : (
						' '
					)}
				</div>
			)}
		</FFRatingDIV>
	);
};



export const ThumbRating = (props: Props & ThumbsProps) => {
	const [filledThumb, setFilledThumb] = React.useState('');
	const [hoveredThumb, setHoveredThumb] = React.useState('');
	const {
		msgsArray,
		HBscreen,
		expandWidth,
		messageFeedbackComment,
		thumbFilledUp,
		messageId,
		isModalVisible,
		thumbFilledDown
	} = useSelector((state: RootState) => state.helpBot);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		setFilledThumb(props.selected);
	}, [props.selected]);

	// React.useEffect(() => {
	// 	if (props.foregroundColor)
	// 		r.style.setProperty('--srvMsgColor', props.foregroundColor || '#ffffff');
	// }, [props.foregroundColor]);
	const foreground = 'rgba(0,0,0,0.4)';
	const modeGroundColor = props.colorMode === 'dark'
		? 'var(--selectedColor)'
		: 'var(--colorScale-2)';
	// const background = styleVars.getPropertyValue('--thumbBackground');
	// const border = props.isResponselevelFeedback
	// 	? styleVars.getPropertyValue('--thumbBordercolor')
	// 	: 'transparent';


	function selectThumbs(bool: any, data: any, msgKey: any, callbackIndicator?: any) {
		if ((bool && filledThumb !== 'Up') || (!bool && filledThumb !== 'Down')) {
			let popCommentOnFeedback = data.popComment
				? JSON.parse(data.popComment)
				: false;

			const setFilledThumbsCalculation = () => {
				setFilledThumb(
					((filledThumb === 'Up' && bool) ||
						(filledThumb === 'Down' && !bool)) &&
						!callbackIndicator
						? ''
						: bool
							? 'Up'
							: 'Down'
				);
			};


			if (props.selected) {
				setFilledThumbsCalculation();
				props.onClick(
					((filledThumb === 'Up' && bool) ||
						(filledThumb === 'Down' && !bool)) &&
						!callbackIndicator
						? ''
						: bool
							? 'Up'
							: 'Down'
				);
				return;
			}

			setFilledThumbsCalculation();
		} else {
			if (props.isResponselevelFeedback) {
				setFilledThumb('');
			}
		}
	}

	return (
		<div
			className={`sf-thumbs-wrapper ${props.size}`}
			style={{
				margin: props.centered ? '0 auto' : '',
				// backgroundColor: "red",
				// border: `1px solid black`,
				right: "80px",
				position: "relative"
			}}
		>
			<button
				title={"ThumbsUp"}
				className='sf-thumbs sf-thumbs-up clickable'
				disabled={props.voiceResponse}
				onClick={(ev) => {
					selectThumbs(true, msgsArray, messageFeedbackComment);

					// props.openThumbsPopup(true, true, props.messageId)
					dispatch(setmessageFeedbackComment(true));
					dispatch(setthumbFilledUp(true));
					dispatch(setmessageId(messageId));
					dispatch(setisModalVisible(true));

				}}
				onFocus={() => {
					setHoveredThumb('Up');
				}}
				onMouseOver={() => {
					setHoveredThumb('Up');
				}}
				onMouseOut={() => setHoveredThumb('')}
			>
				<RatingIcons.ThumbsUp
					fill={

						hoveredThumb === 'Up' || props.thumbFilledUp === true
							? modeGroundColor
							: foreground
					}
					variant={
						(hoveredThumb === 'Up' && props.noOutlineHover) || props.thumbFilledUp === true
							? 'filled'
							: ''

					}

				/>
			</button>
			<button
				title={"ThumbsDown"}
				className='sf-thumbs sf-thumbs-down clickable'
				disabled={props.voiceResponse}
				onClick={(ev) => {
					selectThumbs(false, msgsArray, messageFeedbackComment);
					// props.openThumbsPopup(true, false, props.messageId)
					dispatch(setmessageFeedbackComment(true));
					dispatch(setthumbFilledUp(false));
					dispatch(setmessageId(messageId));
					dispatch(setisModalVisible(true));

				}}
				onFocus={() => {
					setHoveredThumb('Down');
				}}
				onMouseOver={() => {
					setHoveredThumb('Down');
				}}
				onMouseOut={() => setHoveredThumb('')}
			>
				<RatingIcons.ThumbsDown
					fill={
						// foreground
						hoveredThumb === 'Down' || props.thumbFilledUp === false
							? modeGroundColor
							: foreground
					}
					variant={
						(hoveredThumb === 'Down' && props.noOutlineHover) || props.thumbFilledUp === false
							? 'filled'
							: ''

					}

				/>
			</button>
		</div>
	);
};
